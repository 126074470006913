import React, { Component } from 'react';
import PropTypes from 'prop-types';
import EditEmbedModal from './EditEmbedModal';
import showModal from '../../../utils/showModal';
import EditBlockTitle from '../../Utils/EditBlockTitle';
import StudentBlockEmbedShow from '../../Student/BlockEmbedShow';
import BlockEditOptionsContainer from './BlockEditOptionsContainer';
import EmbeddedContent from '../../Utils/EmbeddedContent';
import MultipleEditor from '../../Blocks/MultipleEditor';

export default class BlockEmbedShow extends Component {
  handleEdit = () => {
    const { block } = this.props;
    showModal(EditEmbedModal, { block });
  };

  render() {
    const { block, isEditMode, index, totalBlocks, onReorder } = this.props;

    if (!isEditMode) {
      return <StudentBlockEmbedShow block={block} />;
    }

    return (
      <>
        <BlockEditOptionsContainer
          block={block}
          index={index}
          total={totalBlocks}
          onReorder={onReorder}
          onEdit={this.handleEdit}
        />

        <MultipleEditor blockId={block.id} className="section">
          <EditBlockTitle block={block} tagName="h2" />
        </MultipleEditor>

        <EmbeddedContent block={block} />
      </>
    );
  }
}
BlockEmbedShow.propTypes = {
  block: PropTypes.object.isRequired,
  isEditMode: PropTypes.bool,
  index: PropTypes.number,
  totalBlocks: PropTypes.number,
  onReorder: PropTypes.func,
};
