import { applicationApiExecute } from './applicationActions';
import { normalize } from 'normalizr';
import Schemas from '../api/schemas';
import { updateEntities } from './entityActions';
import { fetchCurrentTenant } from './tenantActions';
import { startRequest, endRequest } from './requestActions';
import EvaluatorQueueItem from '../api/evaluatorQueueItem';

const evaluatorQueueApiExecute = (apiFunction, requestType) => (...args) => dispatch => {
  if (requestType === 'save') dispatch(startRequest(requestType));
  return fetchCurrentTenant()(dispatch).then(tenant =>
    apiFunction(tenant.id, ...args)
      .then(response => {
        if (requestType === 'save') {
          dispatch(endRequest(requestType));
        }
        if (response.entities && response.entities_x) {
          const { entities } = normalize(response.entities, Schemas.entities);
          const { entities: entitiesX } = normalize(response.entities_x, Schemas.entities);

          dispatch(updateEntities({ entities, entitiesX }));
        }

        return { entities: response.value, meta: response.meta } || response;
      })
      .catch(err => {
        if (requestType === 'save') dispatch(endRequest(requestType));
        if (err.response?.data) {
          throw err.response.data;
        }
        throw err;
      }),
  );
};
const applicationFetchAll = api => evaluatorQueueApiExecute(api.all);

export const fetchAllQueueItems = applicationFetchAll(EvaluatorQueueItem);
export const startLease = applicationApiExecute(EvaluatorQueueItem.startLease);
export const returnToQueue = applicationApiExecute(EvaluatorQueueItem.returnToQueue);
export const extendLease = applicationApiExecute(EvaluatorQueueItem.extendLease);
export const regradeItem = applicationApiExecute(EvaluatorQueueItem.regradeItem);
