/* global initSqlJs */

const handleScriptLoad = callback => {
  initSqlJs({
    locateFile: f => `/${f}`,
  }).then(SQL => {
    if (callback) callback(new SQL.Database());
  });
};

export const initSql = callback => {
  let script = document.querySelector('#sqlJsScript');
  if (script) {
    return handleScriptLoad(callback);
  }

  // eslint-disable-next-line no-console
  console.log('Loading SQL script');
  script = document.createElement('script');
  script.id = 'sqlJsScript';
  script.src = '/sql-wasm.js';
  script.onload = () => handleScriptLoad(callback);
  document.body.appendChild(script);
};
