import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { map as _map, uniq as _uniq, values as _values } from 'lodash';
import { FormattedMessage } from 'react-intl';
import * as analytics from '../../../utils/analytics';
import Loading from '../../Utils/Loading';
import LtiSectionTileContainer from './LtiSectionTileContainer';
import Errors from '../../Utils/Errors';
import { toastSuccess } from '../../../utils/toastHelper';
import intl from '../../../utils/intl';
import useLtiLineItems from '../../../hooks/useLtiLineItems';
import useFetchCohort from '../../../hooks/useFetchCohort';
import useLtiAssignmentMappings from '../../../hooks/useLtiAssignmentMappings';
import ContentWrapperContainer from '../../v2/Nav/ContentWrapperContainer';
import useFetchBootcamp from '../../../hooks/useFetchBootcamp';
import { getLtiCohortId } from '../../../helpers/ltiParams';
import { createAssignmentMappings } from '../../../actions/ltiV13AssignmentMappingActions';

export default function LtiAssignmentMapping() {
  const cohortId = getLtiCohortId();
  const cohort = useFetchCohort(cohortId);

  const bootcampId = cohort ? cohort.bootcamp_id : null;
  const { bootcamp, sections } = useFetchBootcamp(bootcampId);

  const lineItems = useLtiLineItems(cohort ? cohort.tenant_id : null);
  const [errors, setErrors] = useState([]);
  const [assignmentMappings, setAssignmentMappings] = useLtiAssignmentMappings(cohortId);
  const dispatch = useDispatch();

  useEffect(() => {
    analytics.pageStart('Select Lti Assignments');
    return () => {
      analytics.pageEnd();
    };
  }, []);

  const handleSelect = (assignment, lineItem) => {
    if (lineItem) {
      const mapping = assignmentMappings[assignment.id] || {
        assignment_id: assignment.id,
        tenant_id: assignment.tenant_id,
        bootcamp_instance_id: cohortId,
      };
      assignmentMappings[assignment.id] = {
        ...mapping,
        line_item_id: lineItem.id,
        resource_link_id: lineItem.resourceLinkId,
      };
    } else {
      delete assignmentMappings[assignment.id];
    }

    setAssignmentMappings({ ...assignmentMappings });
  };

  const handleSubmit = () => {
    const values = _values(assignmentMappings);
    const uniqLineItemIds = _uniq(_map(values, 'line_item_id'));
    if (values.length !== uniqLineItemIds.length) {
      setErrors([<FormattedMessage id="lti.messages.duplicateAssignmentMapping" />]);
      return;
    }
    setErrors([]);
    createAssignmentMappings(
      cohortId,
      _values(assignmentMappings),
    )(dispatch)
      .then(() => toastSuccess(intl.formatMessage({ id: 'lti.messages.assignmentMappingSaved' })))
      .catch(response => {
        if (response.errors) {
          setErrors(response.errors);
        }
      });
  };

  if (!bootcamp) {
    return (
      <ContentWrapperContainer hideStaffNav className="course-overview">
        <Loading />
      </ContentWrapperContainer>
    );
  }

  return (
    <ContentWrapperContainer hideStaffNav scrollHeaderText={bootcamp.title} showLogo className="course-overview">
      <div className="course-overview-hero">
        <div className="course-overview-sub-header" style={{ background: 'none' }}>
          <div className="course-overview-sub-header__content">
            <div className="grid-row">
              <div className="md-7 md-offset-1">
                <div className="course-overview-headings">
                  <div className="heading-wrapper">
                    <h1>{bootcamp.title}</h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {sections.map((section, index) => (
        <LtiSectionTileContainer
          section={section}
          index={index}
          key={section.id}
          onSelect={handleSelect}
          assignmentMappings={assignmentMappings}
          lineItems={lineItems}
        />
      ))}

      <div className="" style={{ marginTop: 20 }}>
        <Errors errors={errors} />
        <button
          type="button"
          style={{ margin: 'auto' }}
          className="button-standard button-standard--blue2 button-standard--medium"
          onClick={handleSubmit}>
          <FormattedMessage id="common.submit" />
        </button>
      </div>
    </ContentWrapperContainer>
  );
}
