import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { FormattedMessage } from 'react-intl';

export default class QuizOnLab extends Component {
  constructor(props) {
    super(props);

    this.state = {
      show: false,
      blockId: null,
    };
  }

  show = blockId => this.setState({ show: true, blockId });

  hide = () => this.setState({ show: false });

  isOpen = blockId => this.state.show && this.state.blockId === blockId;

  getActions = () => ({
    show: this.show,
    hide: this.hide,
    isOpen: this.isOpen,
  });

  renderQuiz = (QuizComponent, extraProps) => {
    const { show, blockId } = this.state;
    if (!blockId || !show) return null;

    return (
      <div className={classnames('full-content-inner quiz-container-on-lab buttons-lessons knowledge-check-wrapper')}>
        <div className="grid-nine section">
          <div className="lesson-part">
            <div className="lesson-part__container">
              <div className="lesson-part__content">
                <div className="lesson-part__check">
                  <div className="block quiz">
                    <div className="block-label__content">
                      <div className="knowledge-check-wrapper">
                        <div className="knowledge-check">
                          <QuizComponent blockId={blockId} {...extraProps} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <button
            type="button"
            className="back-to-lab button-standard--short button-standard--outlined"
            onClick={this.hide}>
            <FormattedMessage id="common.backToLab" />
          </button>
        </div>
      </div>
    );
  };

  render() {
    return this.props.render(this.getActions(), this.renderQuiz);
  }
}

QuizOnLab.propTypes = {
  render: PropTypes.func.isRequired,
};
