import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TimeAgo from 'react-timeago';
import UserPicture from '../../Utils/UserPicture';
import ExtraOptions from '../../Student/ExtraOptions';
import intl from '../../../utils/intl';
import SingleTextInputForm from '../../Utils/SingleTextInputForm';
import Loading from '../../Utils/Loading';

export default class Comment extends Component {
  constructor(props) {
    super(props);

    this.state = { editing: false, destroying: false };
  }

  isEdited = () => {
    const { comment } = this.props;
    return comment.created_at !== comment.updated_at;
  };

  handleEdit = () => {
    this.setState({ editing: true });
  };

  updateComment = content => this.props.onEdit(content).then(() => this.setState({ editing: false }));

  handleDestroy = () => {
    this.setState({ destroying: true });
    return this.props.onDestroy().then(() => this.setState({ destroying: false }));
  };

  render() {
    const { comment, author, canEdit, canDestroy } = this.props;

    if (this.state.destroying) {
      return (
        <div className="single-comment">
          <Loading />
        </div>
      );
    }

    if (this.state.editing) {
      return (
        <div className="single-comment comment-thread__header">
          <div className="input-wrapper" style={{ padding: 0 }}>
            <div className="input">
              <div className="icon">
                <UserPicture user={author} className="user-profile-picture" />
              </div>
              <SingleTextInputForm onSubmit={this.updateComment} initialContent={comment.content} />
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className="single-comment">
        <div className="single-comment__header">
          <div className="column">
            <UserPicture user={author} className="user-profile-picture" />
            <span className="user-name">{author.name}</span>
          </div>
          <div className="column timestamp-wrapper">
            <span className="timestamp">
              <TimeAgo className="published" date={comment.created_at} minPeriod="60" />
              {this.isEdited() && <span className="edited"> {intl.formatMessage({ id: 'comment.edited' })}</span>}
            </span>
            <ExtraOptions
              onEdit={canEdit ? this.handleEdit : undefined}
              onDestroy={canDestroy ? this.handleDestroy : undefined}
            />
          </div>
        </div>

        <div className="single-comment__body">
          <div dangerouslySetInnerHTML={{ __html: comment.content }} />
        </div>
      </div>
    );
  }
}
Comment.propTypes = {
  author: PropTypes.object.isRequired,
  comment: PropTypes.object.isRequired,
  canEdit: PropTypes.bool.isRequired,
  canDestroy: PropTypes.bool.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDestroy: PropTypes.func.isRequired,
};
