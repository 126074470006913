import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { emit as socketEmit } from '../../sockets';
import { subscribe as subscribeToEventType, unsubscribe as unsubscribeFromEventType } from '../../sockets/events';

export default function Channel({ channelName, enabled, onChannelUpdate, onMemberJoined, onMemberLeft }) {
  const [isConnected, setIsConnected] = useState(false);
  const channelJoinedCb = useState(() => channelJoinedCallback)[0];
  const memberJoinedCb = useState(() => memberEventCallback(onMemberJoined))[0];
  const memberLeftCb = useState(() => memberEventCallback(onMemberLeft))[0];

  useEffect(() => {
    if (enabled && !isConnected) {
      connect();
      return () => disconnect();
    }
  }, [enabled]);

  function connect() {
    socketEmit('channel:join', { name: channelName });
    subscribeToEventType('channel:joined', channelJoinedCb);
    subscribeToEventType('channel:memberJoined', memberJoinedCb);
    subscribeToEventType('channel:memberLeft', memberLeftCb);
  }

  function disconnect() {
    unsubscribeFromEventType('channel:joined', channelJoinedCb);
    unsubscribeFromEventType('channel:memberJoined', memberJoinedCb);
    unsubscribeFromEventType('channel:memberLeft', memberLeftCb);

    socketEmit('channel:leave', { name: channelName });
    setIsConnected(false);
    onChannelUpdate(null, channelName);
  }

  function channelJoinedCallback(channel) {
    if (channel.channelName !== channelName) return;

    setIsConnected(true);
    onChannelUpdate(channel, channelName);
  }

  function memberEventCallback(onMemberAction) {
    return message => {
      const { channelName: messageChannel, member } = message;

      if (messageChannel !== channelName) return;

      onMemberAction(member, channelName);
    };
  }

  return <div className="channel" />;
}

Channel.propTypes = {
  onChannelUpdate: PropTypes.func.isRequired,
  onMemberJoined: PropTypes.func.isRequired,
  onMemberLeft: PropTypes.func.isRequired,
  channelName: PropTypes.string.isRequired,
  enabled: PropTypes.bool,
};

Channel.defaultProps = {
  enabled: true,
};
