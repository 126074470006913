import { connect } from 'react-redux';
import CurrentLesson from './CurrentLesson';
import { selectProgressForPath } from '../../../../selectors/pathProgressSelectors';
import { getPathPrefix } from '../../../../helpers/pathHelper';
import { withRouter } from 'react-router-dom';
import { startPathProgress } from '../../../../actions/pathProgressActions';
import { selectCohortForEnrollment } from '../../../../selectors/cohortSelectors';

const mapStateToProps = (state, ownProps) => {
  const { currentUser, currentEnrollment } = ownProps;
  const pathId = currentEnrollment?.path_id;
  const pathProgress = selectProgressForPath(state, pathId);
  const resuming = currentEnrollment && !!currentEnrollment.started_at;

  const pathPrefix = getPathPrefix(currentUser);
  const resumeUrlPath = currentEnrollment?.next_course_step?.url_path;
  const baseUrlPath = `/bootcamps/${currentEnrollment.bootcamp_id}/paths/${currentEnrollment.path_id}`;
  const resumeUrl = `${pathPrefix}${resumeUrlPath || baseUrlPath}`;
  const lessonTitle = currentEnrollment?.next_course_step?.title;

  const cohort = selectCohortForEnrollment(state, currentEnrollment?.id);
  const accessDenied = new Date(cohort?.access_date) > new Date();
  const currentEnrollmentAccessDate = cohort?.access_date;

  return {
    currentUser,
    resumeUrl,
    lessonTitle,
    resuming,
    pathProgress,
    accessDenied,
    currentEnrollmentAccessDate,
  };
};

export default withRouter(connect(mapStateToProps, { startPathProgress })(CurrentLesson));
