import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Icons from '../../../Utils/Icons';
import { Link } from 'react-router-dom';

const CompletedCourseCard = ({ title, url, type }) => {
  return (
    <div className="completed-course">
      {type === 'completeOptional' && (
        <div className="label">
          <FormattedMessage id="bootcamp.optional" />
        </div>
      )}
      <div className="trophy">
        <div className="trophy-circle">
          <Icons.Trophy />
        </div>
      </div>
      <div className="complete-title">{title}</div>
      <div className="congrats">
        <FormattedMessage id="bootcamp.courseComplete" />
      </div>
      <Link className="review" to={url} aria-label={title}>
        <FormattedMessage id="bootcamp.reviewCourse" />
      </Link>
    </div>
  );
};

CompletedCourseCard.propTypes = {
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
};

export default CompletedCourseCard;
