import { isEmpty as _isEmpty } from 'lodash';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { toastException } from '../../utils/toastHelper';
import CodeEditor from '../Utils/CodeEditor';
import Errors from '../Utils/Errors';
import { initSql } from '../../helpers/sqlJsHelper';
import SqlResults from './SqlResults';
import useDebounce from '../../hooks/useDebounce';
import { selectProgressForBlock } from '../../selectors/blockProgressSelectors';
import { saveBlockProgress } from '../../actions/blockProgressActions';
import { contentForStudent } from '../../helpers/blockHelper';

export default function BlockSqlShow({ block }) {
  const blockProgress = useSelector(s => (block ? selectProgressForBlock(s, block.id) : null));
  const [script, setScript] = useState((blockProgress && blockProgress.script) || '');
  const [errors, setErrors] = useState([]);
  const [results, setResults] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    if (blockProgress && _isEmpty(script) && !_isEmpty(blockProgress.script)) {
      setScript(blockProgress.script);
    }
  }, [blockProgress]);

  const debouncedSave = useDebounce(async newScript => {
    if (!blockProgress) return;

    try {
      await saveBlockProgress({ ...blockProgress, script: newScript })(dispatch);
    } catch (err) {
      toastException(err, 'Unable to save sql');
    }
  }, 500);

  const handleSqlChange = newScript => {
    setScript(newScript);
    debouncedSave(newScript);
  };

  const handleExecute = () => {
    const fullScript = `${block.script};\n${script}`;

    handleClear();

    initSql(db => {
      try {
        setResults(db.exec(fullScript));
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error('Error executing sql: ', e);
        setErrors([e.message]);
      } finally {
        db.close();
      }
    });
  };

  const handleClear = () => {
    setErrors([]);
    setResults([]);
  };

  return (
    <div className="section">
      <div className="block-label">
        <div className="block-label__content">
          <div className="knowledge-check-wrapper">
            {!_isEmpty(block.title) && (
              <div className="knowledge-check__header">
                <h1 className="heading" dangerouslySetInnerHTML={{ __html: block.title }} />
              </div>
            )}
            <div className="knowledge-check__body">
              <div dangerouslySetInnerHTML={{ __html: contentForStudent(block.content) }} />

              <CodeEditor value={script} onChange={handleSqlChange} mode="sql" />

              <div style={{ margin: 10, display: 'flex', justifyContent: 'space-between' }}>
                <button
                  type="button"
                  className="button-standard button-standard--blue2 button-standard--short"
                  onClick={handleExecute}>
                  <FormattedMessage id="sqlBlock.execute" />
                </button>
                <button
                  type="button"
                  className="button-standard button-standard--transparent button-standard--short"
                  onClick={handleClear}>
                  <FormattedMessage id="sqlBlock.clear" />
                </button>
              </div>

              <Errors errors={errors} />

              <SqlResults results={results} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

BlockSqlShow.propTypes = {
  block: PropTypes.object.isRequired,
};
