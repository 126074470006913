import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { formatDuration, getProgressStatus } from '../../utils/utils';
import ActionButtons from '../Utils/ActionButtons';
import { confirmInput } from '../../utils/confirmInput';

import Icons from '../Utils/Icons';
import ReorderButtons from '../Utils/ReorderButtons';

export default class ComponentItem extends Component {
  componentDidMount() {
    const { lesson, fetchLesson, component } = this.props;

    if (!lesson && component.external_id) {
      fetchLesson(component.external_id);
    }
  }

  handleDelete = () => {
    const { component, unitId } = this.props;

    confirmInput({
      message: <FormattedMessage id="component.confirmDelete" values={{ name: component.title }} />,
      onOk: () => {
        this.props.destroyComponent(unitId, component);
      },
    });
  };

  getUrl = () => {
    const { unit, section, component, match } = this.props;

    return `${match.url}/sections/${section.id}/units/${unit.id}/components/${component.id}`;
  };

  handleComponentClick = e => {
    e.preventDefault();

    const { history } = this.props;
    history.push(this.getUrl());
  };

  render() {
    const { component, componentProgress, isEditMode, hasLab, index, totalComponents } = this.props;
    const { isComplete } = getProgressStatus(componentProgress);
    const iconClass = classnames('unit-item__check-icon', isComplete && 'unit-item__check-icon--completed');

    return (
      <div className="unit-item" onClick={this.handleComponentClick}>
        <div className="unit-item-left">
          <span className={iconClass}>{isComplete ? <Icons.Tick /> : <div className="unit-item__dot-icon"></div>}</span>
          <div className="unit-title">
            <Link to={this.getUrl()}>{component.title}</Link>
          </div>
        </div>
        <div className="unit-item-right">
          {hasLab && (
            <div className="unit-item__lesson-icon">
              <div className="icon-element icon-element--fill-transparent icon-element--background-transparent  icon-element--size-small">
                <Icons.ElixirSmall />
              </div>
            </div>
          )}
          {isEditMode && (
            <>
              <div className="learning-path-item-reorder-button">
                <ReorderButtons index={index} total={totalComponents} onReorder={this.props.onReorder} />
              </div>

              <div className="left-header-navigator-unit-authoring" style={{ marginLeft: 20 }}>
                <ActionButtons
                  isEditMode={isEditMode}
                  onEdit={() => this.props.editComponent(component)}
                  onDelete={this.handleDelete}
                />
              </div>
            </>
          )}
          <span className="unit-item__time">{formatDuration(component.duration)}</span>
        </div>
      </div>
    );
  }
}

ComponentItem.propTypes = {
  unitId: PropTypes.string.isRequired,
  section: PropTypes.object.isRequired,
  unit: PropTypes.object.isRequired,
  component: PropTypes.object.isRequired,
  lesson: PropTypes.object,
  componentProgress: PropTypes.object,
  match: PropTypes.object,
  history: PropTypes.object,
  editComponent: PropTypes.func,
  destroyComponent: PropTypes.func.isRequired,
  fetchLesson: PropTypes.func.isRequired,
  onReorder: PropTypes.func.isRequired,
  isEditMode: PropTypes.bool,
  hasLab: PropTypes.bool,
  index: PropTypes.number,
  totalComponents: PropTypes.number,
};
