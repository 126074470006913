import axios from './axios';

export default class QuizProgress {
  static submit(tenantId, quizProgress) {
    return axios.put(`/assessments/quiz_progresses/${quizProgress.id}/submit`).then(response => response.data);
  }

  static retry(tenantId, quizProgress) {
    return axios.post(`/assessments/quiz_progresses/${quizProgress.id}/try_again`).then(response => response.data);
  }

  static setCurrentQuestionToPrevious(tenantId, quizProgress, questionProgressId) {
    return axios
      .post(`/assessments/quiz_progresses/${quizProgress.id}/set_current_question_to_previous`, {
        question_progress_id: questionProgressId,
      })
      .then(response => response.data);
  }

  static async allowResubmission(tenantId, quizProgressId) {
    const response = await axios.post(`/assessments/quiz_progresses/${quizProgressId}/allow_resubmission`);
    return response.data;
  }
}
