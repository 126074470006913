import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { fetchAssignment } from '../../actions/assignmentActions';
import { selectCriteriaByAssignment } from '../../selectors/criterionSelectors';
import { selectAssignment } from '../../selectors/assignmentSelectors';

export const useCriteriaByAssignment = assignmentId => {
  const dispatch = useDispatch();

  const assignment = useSelector(state => selectAssignment(state, assignmentId));
  const criteria = useSelector(state => assignment && selectCriteriaByAssignment(state, assignment));

  React.useEffect(() => {
    if (!assignmentId) {
      return;
    }

    // if assignment was already available in the store, don't re-fetch
    if (assignment) {
      return;
    }

    fetchAssignment(assignmentId)(dispatch);
  }, [assignmentId, assignment, dispatch]);

  return criteria || [];
};
