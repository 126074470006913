import { map as _map } from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import AdminWrapper from '../v2/Nav/Admin/AdminWrapper';
import BootcampListItemContainer from '../Bootcamp/BootcampListItemContainer';
import * as analytics from '../../utils/analytics';
import { setPageTitle } from '../../helpers/pageHelper';
import intl from '../../utils/intl';
import Loading from '../Utils/Loading';

export default class BootcampList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoaded: false,
    };
  }

  componentDidMount() {
    const { bootcamps, cohorts } = this.props;
    if (bootcamps.length > 0 && cohorts.length > 0) {
      this.setState({ isLoaded: true });
    }
    this.props.fetchCurrentEnrollments().then(() => {
      this.setState({ isLoaded: true });
    });
    analytics.pageStart('Course List - Student');
    setPageTitle('pageName.course.list');
  }

  componentWillUnmount() {
    analytics.pageEnd();
  }

  render() {
    const { bootcamps, cohorts } = this.props;
    const { isLoaded } = this.state;

    let accessibleBootcamps = bootcamps
      .map(bootcamp => {
        let matches = Object.values(cohorts).filter(cohort => cohort.bootcamp_id === bootcamp.id);
        if (matches) {
          bootcamp.accessDate = Math.max(...matches.map(e => new Date(e.access_date)));
        }
        return bootcamp;
      })
      .filter(bootcamp => {
        return new Date(bootcamp.accessDate) <= Date.now();
      });

    return (
      <AdminWrapper headerTitle={intl.formatMessage({ id: 'nav.bootcamps' })} hideAuthorToggle>
        {!isLoaded && <Loading />}
        <div className="grid-row">
          <div className="sm-12">
            <div className="courses-list">
              {_map(accessibleBootcamps, bootcamp => (
                <BootcampListItemContainer key={bootcamp.id} bootcamp={bootcamp} />
              ))}
            </div>
          </div>
        </div>
      </AdminWrapper>
    );
  }
}
BootcampList.propTypes = {
  bootcamps: PropTypes.array,
  fetchCurrentEnrollments: PropTypes.func.isRequired,
};
