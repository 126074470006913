import { Operator } from './OperatorsEnum';
import { ASTLinkedList, ASTLinkedListNode } from './ASTLinkedList';
import { ASTNode } from './ASTNode';
import { buildAlphabet, isAllowedSymbol, SPACE } from './helper';

const buildSubAST = (start, symbols, isParentheses) => {
  const findAndBuiltSubASTForOperator = (startNode, endNode, operatorSymbol) => {
    let currentNode = startNode;
    while (currentNode !== endNode && currentNode) {
      if (currentNode.value.value === operatorSymbol) {
        if (!currentNode.next || !currentNode.prev) {
          //operator inside multiple parentheses, f.e ((a | b))
          if (currentNode.value.left && currentNode.value.right) {
            currentNode = currentNode.next;
            break;
          }
          throw new Error('Wrong expression');
        }
        const operatorToken = currentNode.value;
        operatorToken.left = currentNode.prev.value;
        operatorToken.right = currentNode.next.value;
        symbols.removeNode(currentNode.prev);
        symbols.removeNode(currentNode.next);
      }
      currentNode = currentNode.next;
    }
  };
  findAndBuiltSubASTForOperator(start, symbols.tail, Operator.AND);
  findAndBuiltSubASTForOperator(isParentheses ? start : symbols.head, symbols.tail, Operator.OR);
};

// eslint-disable-next-line complexity
const parse = (formula, alphabet) => {
  const symbolsLinkedList = new ASTLinkedList();
  const openParenthesis = [];
  for (let i = 0; i < formula.length; i++) {
    const currentSymbol = formula.charAt(i);
    if (currentSymbol === SPACE) {
      continue;
    }
    if (!isAllowedSymbol(currentSymbol, alphabet)) {
      throw new Error(`Invalid operator: ${currentSymbol}`);
    }
    const newExpression = new ASTLinkedListNode(new ASTNode(currentSymbol));
    if (currentSymbol === Operator.OPEN_PARENTHESES) {
      openParenthesis.push(newExpression);
    }
    if (currentSymbol === Operator.CLOSE_PARENTHESES) {
      if (openParenthesis.length === 0) {
        throw new Error('Wrong Parenthesis');
      }
      const lastOpenParenthesis = openParenthesis.pop();
      if (!lastOpenParenthesis) {
        throw new Error('Open parentheses not found');
      }
      // parse sub expression at Parenthesis and remove Parenthesis
      buildSubAST(lastOpenParenthesis.next, symbolsLinkedList, true);
      symbolsLinkedList.removeNode(lastOpenParenthesis);
      continue;
    }
    symbolsLinkedList.addNode(newExpression);
  }
  if (openParenthesis.length !== 0) {
    throw new Error('Wrong Parenthesis');
  }
  if (symbolsLinkedList.size === 0) {
    throw new Error('Wrong formula');
  }
  // build AST for rest expression
  buildSubAST(symbolsLinkedList.head, symbolsLinkedList);
  if (symbolsLinkedList.size > 1) {
    throw new Error('Wrong formula');
  }
  return symbolsLinkedList.head.value;
};

export const parseFormula = (formula, checksAmount) => {
  const alphabet = buildAlphabet(checksAmount);
  try {
    return parse(formula, alphabet);
  } catch (e) {
    return { error: e.message };
  }
};
