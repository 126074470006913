import { difference as _difference } from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Errors from '../../Utils/Errors';
import Modal2 from '../../library/Modal2';
import ProjectDropZone from '../../Utils/ProjectDropZone';
import {
  isGsuiteApp,
  isMysqlApp,
  allowProjectUpload,
  isGoogleColabApp,
  isToughTalksApp,
} from '../../../helpers/labHelper';
import CustomInput from '../../Utils/CustomInput';
import intl from '../../../utils/intl';
import EditStepMysql from './EditStepMysql';
import { LabVersionChangeType } from '../../Utils/ProjectVersionChangeModal';

class EditStepModal extends Component {
  static propTypes = {
    tutorial: PropTypes.object.isRequired,
    step: PropTypes.object.isRequired,
    bootcamp: PropTypes.object.isRequired,
    onSave: PropTypes.func.isRequired,
    hideFile: PropTypes.bool,
  };

  constructor(props) {
    super(props);

    this.state = {
      step: { ...props.step, public_dbs: props.step.public_dbs || [] },
      errors: null,
    };
  }

  handleChange = e => {
    const { target } = e;
    const { name } = target;
    const { step } = this.state;
    let value = target.value;

    let public_dbs = step.public_dbs;
    if (name === 'public_dbs') {
      const isChecked = target.checked;
      if (isChecked) {
        public_dbs.push(value);
      } else {
        public_dbs = _difference(public_dbs, [value]);
      }
      value = public_dbs;
    } else {
      value = target.type === 'checkbox' ? target.checked : target.value;
    }

    this.setState({
      step: {
        ...step,
        [name]: value,
      },
    });
  };

  handleSubmit = e => {
    e.preventDefault();
    const { bootcamp, hideFile, onSave } = this.props;
    const stepExtraArgs = hideFile ? {} : { bootcamp_id: bootcamp.id, lab_change_type: LabVersionChangeType.NEW };

    onSave({ ...this.state.step, ...stepExtraArgs })
      .then(() => this.props.onHide())
      .catch(err => {
        this.setState({ errors: err.value.errors });
      });
  };

  handleFileUpload = name => url => this.handleChange({ target: { name, value: url } });

  renderTitle = step =>
    step.id ? (
      <FormattedMessage id="step.heading.edit" values={{ seqNum: step.seq_num }} />
    ) : (
      <FormattedMessage id="step.heading.create" />
    );

  render() {
    const { step, errors } = this.state;
    const { tutorial, onHide, hideFile } = this.props;

    return (
      <Modal2 onOk={this.handleSubmit} onHide={onHide} title={this.renderTitle(step)}>
        <Errors errors={errors} />

        <CustomInput
          name="title"
          onChange={this.handleChange}
          value={step.title}
          label={intl.formatMessage({ id: 'step.form.title' })}
        />

        {(isGsuiteApp(tutorial.app) || isGoogleColabApp(tutorial.app) || isToughTalksApp(tutorial.app)) && (
          <CustomInput
            name="project_url"
            onChange={this.handleChange}
            value={step.project_url}
            label={intl.formatMessage({ id: 'step.form.url' })}
          />
        )}

        {allowProjectUpload(tutorial.app) && !hideFile && (
          <ProjectDropZone
            onFileUploaded={this.handleFileUpload('project_url')}
            app={tutorial.app}
            projectUrl={step.project_url}
          />
        )}

        {isMysqlApp(tutorial.app) && <EditStepMysql app={tutorial.app} step={step} onChange={this.handleChange} />}
      </Modal2>
    );
  }
}

export default EditStepModal;
