import axios from './axios';

export default class Project {
  static async getProjectsForCohort(_, cohortId, graded, studentId) {
    const response = await axios.get(`/paths/bootcamp_instances/${cohortId}/grade`, {
      params: { graded, user_id: studentId },
    });
    return response.data;
  }

  static async getProjectsForUser(_, userId, bootcampInstanceId) {
    const response = await axios.get(`/paths/users/${userId}/projects`, {
      params: { bootcamp_instance_id: bootcampInstanceId },
    });
    return response.data;
  }
}
