import axios from './axios';

export default class Comment {
  static commentsPath(_, blockId) {
    return `/content/blocks/${blockId}/comments`;
  }

  static commentPath(_, id) {
    return `/content/comments/${id}`;
  }

  static all(_, blockId, bootcampInstanceId) {
    const params = { bootcamp_instance_id: bootcampInstanceId };
    return axios.get(Comment.commentsPath(_, blockId), { params }).then(response => response.data);
  }

  static save(_, comment, blockId, bootcampInstanceId) {
    const params = { bootcamp_instance_id: bootcampInstanceId, comment };
    return axios.post(Comment.commentsPath(_, blockId), params).then(response => response.data);
  }

  static update(_, comment) {
    return axios.put(Comment.commentPath(_, comment.id), { comment }).then(response => response.data);
  }

  static destroy(_, id) {
    return axios.delete(Comment.commentPath(_, id)).then(response => response.data);
  }
}
