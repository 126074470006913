import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { fetchBootcamp } from '../actions/bootcampActions';
import useBootcamp from './useBootcamp';

/**
 * Fetch the bootcamp if not already present in redux.
 * Make sure to call this only once in a page preferably in the top level component
 * @param {string} lessonId
 */
export default function useFetchBootcamp(bootcampId) {
  const { bootcamp, path, sections } = useBootcamp(bootcampId);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!bootcamp && bootcampId) {
      dispatch(fetchBootcamp(bootcampId));
    }
  }, [bootcampId]);

  return {
    bootcamp,
    path,
    sections,
  };
}
