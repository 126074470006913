import axios from './axios';

export default class BlockProgress {
  static async save(tenantId, blockProgress) {
    const response = await axios.put(`/content/block_progresses/${blockProgress.id}`, blockProgress);
    return response.data;
  }

  static async fetchSqlBlockProgresses(tenantId, enrollmentId) {
    const response = await axios.get('/content/block_progresses/sql_progresses', {
      params: { context: enrollmentId },
    });
    return response.data;
  }
}
