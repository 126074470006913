import axios from './axios';

export default class AssignmentProgress {
  static MAX_SCORE = 10;

  static async all(tenantId, params) {
    const response = await axios.get('/assessments/assignment_progresses', { params });
    return response.data;
  }

  static async get(tenantId, assignmentProgressId) {
    const response = await axios.get(`/assessments/assignment_progresses/${assignmentProgressId}`);
    return response.data;
  }

  static async start(tenantId, assignmentProgressId) {
    const response = await axios.put(`/assessments/assignment_progresses/${assignmentProgressId}/start`);
    return response.data;
  }

  static async submit(tenantId, assignmentProgress) {
    const response = await axios.put(
      `/assessments/assignment_progresses/${assignmentProgress.id}/submit`,
      assignmentProgress,
    );
    return response.data;
  }

  static async grade(tenantId, assignmentProgress) {
    const response = await axios.put(
      `/assessments/assignment_progresses/${assignmentProgress.id}/grade`,
      assignmentProgress,
    );
    return response.data;
  }

  static async allowResubmission(tenantId, assignmentProgressId) {
    const response = await axios.put(`/assessments/assignment_progresses/${assignmentProgressId}/allow_resubmission`);
    return response.data;
  }

  static async save(tenantId, assignmentProgress) {
    const response = await axios.put(`/assessments/assignment_progresses/${assignmentProgress.id}`, assignmentProgress);
    return response.data;
  }
}
