import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

const ProjectContext = React.createContext([{}, () => {}]);

const ProjectDataProvider = ({ children, assignment, isEditMode }) => {
  const [state, setState] = useState({});

  useEffect(() => {
    setState({
      ...state,
      assignment,
      isEditMode,
    });
  }, [assignment, isEditMode]);

  return <ProjectContext.Provider value={[state, setState]}>{children}</ProjectContext.Provider>;
};

ProjectDataProvider.propTypes = {
  children: PropTypes.node,
  assignment: PropTypes.object,
  isEditMode: PropTypes.bool,
};

export { ProjectContext, ProjectDataProvider };
