import { chunk as _chunk } from 'lodash';

import { getRosterForCohort } from '../../../actions/rosterActions';
import { getUsers } from '../../../actions/userActions';
import { getProjectsForCohort } from '../../../actions/projectActions';
import { fetchUnitProgresses } from '../../../actions/unitProgressActions';
import { fetchAssignmentProgresses } from '../../../actions/assignmentProgressActions';
import CohortReport from '../../../api/cohortReport';

export const fetchUsers = async (cohortId, dispatch) => {
  const rosterEntries = await getRosterForCohort(cohortId)(dispatch);

  const userIds = rosterEntries.map(entry => entry.user_id);

  getUsers(userIds)(dispatch);
};

export const fetchProgresses = async (cohortId, dispatch) => {
  // Can optimize api; making two calls because api only returns either graded or ungraded entries
  const getUngradedProjects = () => getProjectsForCohort(cohortId)(dispatch);
  const getGradedProjects = () => getProjectsForCohort(cohortId, true)(dispatch);
  const [ungradedProjects, gradedProjects] = await Promise.all([getUngradedProjects(), getGradedProjects()]);

  const gradeEntries = [...ungradedProjects, ...gradedProjects];
  // sorting is not necessary here, but predictable ordering helps for unit test
  const unitProgressIds = gradeEntries.map(entry => entry.unit_progress_id).sort();
  const assignmentProgressIds = gradeEntries.map(entry => entry.assignment_progress_id).sort();

  // Request fails when too long; chunking to make smaller requests
  // TODO: update api's in paths and assessments to support fetching by cohort id or
  //   new "search" POST api
  _chunk(unitProgressIds, 100).forEach(chunkedIds => {
    fetchUnitProgresses({ ids: chunkedIds })(dispatch);
  });

  _chunk(assignmentProgressIds, 100).forEach(chunkedIds => {
    fetchAssignmentProgresses({ ids: chunkedIds })(dispatch);
  });
};

export const fetchCohortReportStudents = async (cohortId, setCohortReportStudents) => {
  try {
    const { rows } = await CohortReport.students(cohortId);
    setCohortReportStudents(rows);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Error retrieving cohort report students', e);
  }
};
