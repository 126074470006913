import axios from './axios';

export default class EvaluatorQueueItem {
  static async all(_tenantId, params) {
    const response = await axios.get('/assessments/evaluator_queue_items', { params });
    return response.data;
  }

  static async startLease(_tenantId, evaluatorQueueItemId, leaseData) {
    const response = await axios.post(
      `/assessments/evaluator_queue_items/${evaluatorQueueItemId}/start_lease`,
      leaseData,
    );
    return response.data;
  }
  static async returnToQueue(_tenantId, evaluatorQueueItemId, leaseData) {
    const response = await axios.post(
      `/assessments/evaluator_queue_items/${evaluatorQueueItemId}/return_to_queue`,
      leaseData,
    );
    return response.data;
  }
  static async extendLease(_tenantId, evaluatorQueueItemId, leaseData) {
    const response = await axios.post(
      `/assessments/evaluator_queue_items/${evaluatorQueueItemId}/extend_lease`,
      leaseData,
    );
    return response.data;
  }
  static async regradeItem(_tenantId, evaluatorQueueItemId, leaseData) {
    const response = await axios.post(
      `/assessments/evaluator_queue_items/${evaluatorQueueItemId}/regrade_item`,
      leaseData,
    );
    return response.data;
  }
}
