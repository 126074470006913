import { connect } from 'react-redux';
import Navigator from './Navigator';
import { withRouter } from 'react-router-dom';
import { selectCurrentUrlObject } from '../../selectors/urlSelectors';
import { selectCurrentUser } from '../../selectors/authSelectors';

const mapStateToProps = (state, ownProps) => {
  if (!ownProps.match) {
    return {};
  }

  const { bootcampId, pathId, sectionId, unitId } = ownProps.match.params;
  const { url: currentUrl } = ownProps.match;
  const currentUser = selectCurrentUser(state);

  return {
    currentUnitUrlObject: selectCurrentUrlObject(state, {
      bootcampId,
      pathId,
      sectionId,
      unitId,
      currentUrl,
      currentUser,
    }),
  };
};

export default withRouter(connect(mapStateToProps, {})(Navigator));
