import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { trimEndTag } from '../../utils/utils';
import RTEContent from '../../helpers/contentMathjaxHelper';

export default class BlockCollapsibleHtml extends Component {
  render() {
    const { block } = this.props;

    return (
      <div className="section">
        <details>
          <summary>{trimEndTag(block.title, 'br')}</summary>
          <RTEContent content={block.content} Tag="div" classSelector="pre-content" />
        </details>
      </div>
    );
  }
}
BlockCollapsibleHtml.propTypes = {
  block: PropTypes.object.isRequired,
};
