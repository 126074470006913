import React from 'react';
import PropTypes from 'prop-types';
import BackLink from '../../Utils/BackLink';
import { FormattedMessage } from 'react-intl';

const StudentHeader = ({ backUrl, bootcamp, noTabs }) => {
  return (
    <div className={noTabs ? 'student-header no-tabs' : 'student-header'}>
      <div>
        <BackLink
          url={backUrl}
          className="go-back-wrapper---main-blue-600"
          text={
            <span className="go-back-button-label">
              <FormattedMessage id="nav.courses" />
            </span>
          }
        />
      </div>
      <div className="student-header-title">{bootcamp.title}</div>
    </div>
  );
};

StudentHeader.propTypes = {
  backUrl: PropTypes.string,
  student: PropTypes.object,
};

export default StudentHeader;
