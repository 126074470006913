import { find as _find, isEmpty as _isEmpty } from 'lodash';
import { connect } from 'react-redux';
import { selectPath } from '../../selectors/pathSelectors';
import { selectSection } from '../../selectors/sectionSelectors';
import { selectUnit } from '../../selectors/unitSelectors';
import TutorialShow from './TutorialShow';
import { fetchCurrentEnrollments, fetchEnrollmentForCohort } from '../../actions/enrollmentActions';
import { selectEnrollmentForBootcamp } from '../../selectors/enrollmentSelectors';
import { selectBootcamp } from '../../selectors/bootcampSelectors';
import { selectTutorial } from '../../selectors/tutorialSelectors';
import { selectTutorialSteps } from '../../selectors/stepSelectors';
import { fetchTutorial } from '../../actions/tutorialActions';
import { fetchLesson } from '../../actions/lessonActions';
import { selectProgressForTutorial } from '../../selectors/tutorialProgressSelectors';
import { completeTutorialProgress, saveTutorialProgress } from '../../actions/tutorialProgressActions';
import { selectComponent } from '../../selectors/componentSelectors';
import { selectProgressForComponent } from '../../selectors/componentProgressSelectors';
import { updateComponentProgress } from '../../actions/componentProgressActions';
import { selectLesson, selectLessonBlocks, selectLessonParts } from '../../selectors/lessonSelectors';
import { selectProgressForLesson } from '../../selectors/lessonProgressSelectors';
import { selectProgressForUnit } from '../../selectors/unitProgressSelectors';
import { selectCurrentUrlObject } from '../../selectors/urlSelectors';
import { selectCurrentUser } from '../../selectors/authSelectors';
import { canLearn } from '../../helpers/userAuth';
import { fetchLtiAssignmentProgresses } from '../../actions/ltiAssignmentProgressActions';
import { getFolderPath } from '../../helpers/labHelper';
import { selectCohortForEnrollment } from '../../selectors/cohortSelectors';
import { generateFeedbackData } from '../../utils/analytics';

// eslint-disable-next-line complexity
const mapStateToProps = (state, ownProps) => {
  const { match } = ownProps;
  const { bootcampId, pathId, sectionId, unitId, componentId, blockId } = ownProps.match.params;
  const path = selectPath(state, pathId);
  const section = selectSection(state, sectionId);
  const unit = selectUnit(state, unitId);
  const bootcamp = selectBootcamp(state, bootcampId);
  const component = selectComponent(state, componentId);
  const lessonId = component?.external_id;
  const lesson = selectLesson(state, lessonId);
  const blocks = selectLessonBlocks(state, lessonId);
  const parts = selectLessonParts(state, lessonId);
  const block = _find(blocks, { id: blockId });
  const tutorialId = block?.external_id;
  const tutorial = selectTutorial(state, tutorialId);
  const steps = selectTutorialSteps(state, tutorialId);
  const enrollment = selectEnrollmentForBootcamp(state, bootcampId);
  const unitProgress = selectProgressForUnit(state, sectionId, unitId);
  const enrollmentId = enrollment?.id;
  const tutorialProgress = selectProgressForTutorial(state, tutorialId);
  const componentProgress = selectProgressForComponent(state, unitProgress?.id, componentId);
  const lessonProgress = selectProgressForLesson(state, lessonId);
  const lessonUrl = match.url.substring(0, match.url.indexOf('/blocks/'));
  const currentUser = selectCurrentUser(state);
  const lessonUrlObject = selectCurrentUrlObject(state, {
    bootcampId,
    pathId,
    sectionId,
    unitId,
    currentUrl: lessonUrl,
    currentUser,
    blockId,
  });
  const isStudent = canLearn(currentUser);
  const isLtiStudent = enrollment ?
        enrollment.requires_lti_support :
        (isStudent && !_isEmpty(currentUser.lti_user_id));
  const cohort = enrollment && selectCohortForEnrollment(state, enrollment.id);
  const projectFolderPath = bootcamp && getFolderPath({ bootcamp, cohort, user: selectCurrentUser(state) });

  const feedbackData = generateFeedbackData(bootcamp, currentUser, cohort, 'lab', tutorial);

  return {
    bootcamp,
    path,
    section,
    unit,
    component,
    tutorial,
    steps,
    tutorialId,
    enrollmentId,
    tutorialProgress,
    componentProgress,
    lessonProgress,
    lesson,
    lessonId,
    lessonUrlObject,
    isStudent,
    isLtiStudent,
    projectFolderPath,
    cohortId: enrollment && enrollment.enrollable_id,
    cohort,
    block,
    feedbackData,
    parts,
  };
};

export default connect(mapStateToProps, {
  fetchEnrollmentForCohort,
  fetchCurrentEnrollments,
  fetchTutorial,
  fetchLesson,
  completeTutorialProgress,
  updateComponentProgress,
  fetchLtiAssignmentProgresses,
  saveTutorialProgress,
})(TutorialShow);
