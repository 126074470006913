import axios from './axios';

export default class Lesson {
  static all(_) {
    return axios.get('/content/lessons').then(response => response.data);
  }

  static get(_, lessonId) {
    return axios.get(`/content/lessons/${lessonId}`).then(response => response.data);
  }

  static save(_, lesson) {
    if (lesson.id) {
      return axios.put(`/content/lessons/${lesson.id}`, lesson).then(response => response.data);
    }

    return axios.post('/content/lessons', lesson).then(response => response.data);
  }

  static destroy(_, lesson) {
    return axios.delete(`/content/lessons/${lesson.id}`).then(response => response.data);
  }
}
