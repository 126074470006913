import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { ReactComponent as MoveUpIcon } from '../../styles/pathstream/svg-jsx/icon-move-up.svg';
import { ReactComponent as MoveDownIcon } from '../../styles/pathstream/svg-jsx/icon-move-down.svg';
import intl from '../../utils/intl';

export default class ReorderButtons extends Component {
  handleMoveUp = e => {
    e.preventDefault();
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    const { index, onReorder } = this.props;
    const newIndex = Math.max(0, index - 1);
    onReorder(index, newIndex);
  };

  handleMoveDown = e => {
    e.preventDefault();
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    const { index, total, onReorder } = this.props;
    const newIndex = Math.min(total - 1, index + 1);
    onReorder(index, newIndex);
  };

  render() {
    const { index, total, onReorder, showSmall } = this.props;
    const classes = classnames('authoring-reorder-button', 'move-up-down', showSmall && 'small');

    if (!onReorder) {
      return null;
    }

    return (
      <>
        {index > 0 && (
          <button
            className={classes}
            onClick={this.handleMoveUp}
            data-tip={intl.formatMessage({ id: 'aria.moveUp' })}
            title={intl.formatMessage({ id: 'aria.moveUp' })}
            aria-label={intl.formatMessage({ id: 'aria.moveUp' })}>
            <MoveUpIcon />
          </button>
        )}
        {index < total - 1 && (
          <button
            className={classes}
            onClick={this.handleMoveDown}
            data-tip={intl.formatMessage({ id: 'aria.moveDown' })}
            title={intl.formatMessage({ id: 'aria.moveDown' })}
            aria-label={intl.formatMessage({ id: 'aria.moveDown' })}>
            <MoveDownIcon />
          </button>
        )}
      </>
    );
  }
}
ReorderButtons.propTypes = {
  index: PropTypes.number,
  total: PropTypes.number,
  onReorder: PropTypes.func,
  showSmall: PropTypes.bool,
};

ReorderButtons.defaultProps = {
  showSmall: false,
};
