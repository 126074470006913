import { map as _map, compact as _compact } from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { toast } from 'react-toastify';
import useLesson from '../../hooks/useLesson';
import Modal2 from '../library/Modal2';
import intl from '../../utils/intl';
import { moveTo } from '../../actions/blockActions';
import { toastException, toastSuccess } from '../../utils/toastHelper';
import { confirmInput } from '../../utils/confirmInput';

export default function SelectPartModal({ block, onHide }) {
  const dispatch = useDispatch();
  const { parts } = useLesson(block.lesson_id);
  const otherParts = _compact(
    _map(parts, (part, index) => {
      if (part.id !== block.part_id) {
        return { part, index };
      }
      return null;
    }),
  );

  const handleSelect = part => {
    confirmInput({
      message: intl.formatMessage({ id: 'block.moveToPart.confirm' }),
      onOk: async () => {
        const toastId = toast(intl.formatMessage({ id: 'block.moveToPart.saving' }), {
          type: toast.TYPE.INFO,
          autoClose: false,
        });
        try {
          await moveTo(block.id, part.id)(dispatch);

          toast.dismiss(toastId);
          toastSuccess(intl.formatMessage({ id: 'block.moveToPart.success' }));
          onHide();
        } catch (err) {
          // eslint-disable-next-line no-console
          console.log('Error moving block', err);
          toast.dismiss(toastId);
          toastException(err, intl.formatMessage({ id: 'block.moveToPart.failure' }));
        }
      },
    });
  };

  return (
    <Modal2 onHide={onHide} title={intl.formatMessage({ id: 'block.moveToPart.heading' })}>
      <div className="select-parts">
        <ul className="select-parts__list">
          {otherParts.map(({ part, index }) => (
            <li key={part.id} className="select-parts__item">
              <span className="select-parts__label">
                <FormattedMessage id="component.partIndex" values={{ index: index + 1 }} />
                {part.title && ` - ${part.title}`}
              </span>
              <div className="select-parts__button">
                <button
                  type="button"
                  onClick={() => handleSelect(part)}
                  className="button-standard button-standard--blue2 button-standard--big ">
                  <FormattedMessage id="common.select" />
                </button>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </Modal2>
  );
}

SelectPartModal.propTypes = {
  block: PropTypes.object.isRequired,
  onHide: PropTypes.func.isRequired,
};
