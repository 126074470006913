import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from '@pathstream/ui';
import Icons from '../Utils/Icons';
import { styles } from '@pathstream/ui';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { startLease, returnToQueue, extendLease, regradeItem } from '../../actions/evaluatorQueueItemActions';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { STATUS_QUEUED, STATUS_LEASED } from './constants';
import { Modal } from 'antd';
import intl from '../../utils/intl';

const { colorLibrary } = styles;

const EvaluatorActionButton = ({ action, item, currentUserId, updateData }) => {
  const dispatch = useDispatch();
  const [buttonIsDisabled, setButtonIsDisabled] = useState(true);
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  useEffect(() => {
    setButtonIsDisabled(buttonSettings[action].initialButtonStateDisabled);
  }, [action]);

  const styles = {
    resumeButtonOverrides: {
      color: colorLibrary.oranges.warningOrange,
      borderColor: colorLibrary.oranges.warningOrange,
      minWidth: 165,
    },
    viewButtonOverrides: {
      color: colorLibrary.neutrals.darkGray,
      borderColor: colorLibrary.neutrals.darkGray,
      minWidth: 165,
    },
    actionButton: {
      minWidth: 165,
    },
    iconButton: {},
  };

  const buttonSettings = {
    grade: {
      styles: styles.actionButton,
      label: 'Grade',
      initialButtonStateDisabled: false,
    },
    view: {
      styles: styles.viewButtonOverrides,
      label: 'View',
      initialButtonStateDisabled: false,
    },
    resume: {
      styles: styles.resumeButtonOverrides,
      label: 'Resume',
      initialButtonStateDisabled: false,
    },
    'in-progress': {
      styles: styles.actionButton,
      label: 'In Progress',
      initialButtonStateDisabled: true,
    },
    'return-to-queue': {
      styles: styles.iconButton,
      label: <Icons.Refresh height={16} width={16} style={{ transform: 'scaleX(-1)' }} />,
      initialButtonStateDisabled: false,
    },
    regrade: {
      styles: styles.iconButton,
      label: <Icons.Refresh height={16} width={16} style={{ transform: 'scaleX(-1)' }} />,
      initialButtonStateDisabled: false,
    },
  };

  const handleButtonClick = action => {
    setLoading(true);
    switch (action) {
      case 'grade':
        handleGradeButtonClick(item);
        break;
      case 'resume':
        handleResumeButtonClick(item);
        break;
      case 'return-to-queue':
        handleReturnToQueueButtonClick(item);
        break;
      case 'regrade':
        handleRegradeButtonClick(item);
        break;
      case 'view':
        handleViewButtonClick(item);
        break;
      default:
        break;
    }
  };

  const displayErrorMessage = message => {
    toast(message, {
      autoClose: 5000,
      type: toast.TYPE.ERROR,
    });
  };
  const displaySuccessMessage = message => {
    toast(message, {
      autoClose: 5000,
      type: toast.TYPE.SUCCESS,
    });
  };

  const handleResumeButtonClick = async item => {
    const leaseActionData = {
      status: STATUS_LEASED,
    };
    try {
      await extendLease(item.id, leaseActionData)(dispatch);
    } catch (error) {
      console.error('304 - Lease not modified by extend lease');
    }
    setLoading(false);
    navigateToGradeableItem(item);
  };

  const handleReturnToQueueButtonClick = async item => {
    const leaseActionData = {
      status: STATUS_QUEUED,
    };
    try {
      const response = await returnToQueue(item.id, leaseActionData)(dispatch);
      if (response.status === STATUS_QUEUED) {
        displaySuccessMessage('Item returned to the queue');
      } else {
        displayErrorMessage('Unable to return item to the queue');
      }
    } catch (error) {
      displayErrorMessage(`Unable to return item to the queue (${error})`);
    }
    setLoading(false);
    updateData();
  };

  const handleGradeButtonClick = async item => {
    const leaseActionData = {
      status: STATUS_LEASED,
      lease_owner: currentUserId,
    };

    try {
      const response = await startLease(item.id, leaseActionData)(dispatch);
      if (response.status === STATUS_LEASED) {
        navigateToGradeableItem(item);
      } else {
        setLoading(false);
        displayErrorMessage('Unable to begin grading this item');
        updateData();
      }
    } catch (error) {
      setLoading(false);
      displayErrorMessage(`Unable to begin grading this item (${error})`);
    }
  };

  const handleRegradeActionAndItemUpdates = async item => {
    const leaseActionData = {
      status: STATUS_LEASED,
      lease_owner: currentUserId,
    };
    try {
      const response = await regradeItem(item.id, leaseActionData)(dispatch);
      if (response.status === STATUS_LEASED) {
        navigateToGradeableItem(item);
      } else {
        setLoading(false);
        displayErrorMessage('Unable to begin grading this item');
        updateData();
      }
    } catch (error) {
      setLoading(false);
      displayErrorMessage(`Unable to begin grading this item (${error})`);
    }
  };

  const handleRegradeButtonClick = () => {
    const modal = Modal.confirm();
    const handleOk = () => {
      handleRegradeActionAndItemUpdates(item);
      modal.destroy();
    };
    modal.update({
      title: intl.formatMessage({ id: 'grading.regradeAssignment' }),
      content: intl.formatMessage({ id: 'grading.regradeWarning' }),
      okText: intl.formatMessage({ id: 'grading.regrade' }),
      onOk: handleOk,
    });
    setLoading(false);
  };

  const handleViewButtonClick = item => {
    navigateToGradeableItem(item);
  };

  const navigateToGradeableItem = item => {
    const returnUrl = '/evaluator/grader_queue';
    const gradeUrl = `/coach/grade/${item.unit_progress_id}?isGrading=true&returnUrl=${returnUrl}`;

    history.push(gradeUrl);
  };

  const antIcon = <LoadingOutlined style={{ fontSize: 16 }} spin />;
  return (
    <Button
      aria-label={action}
      size="small"
      appearance="secondary"
      style={buttonSettings[action].styles}
      disabled={buttonIsDisabled}
      onClick={() => handleButtonClick(action)}>
      {loading ? <Spin size="small" indicator={antIcon} /> : buttonSettings[action].label}
    </Button>
  );
};

export default EvaluatorActionButton;
