import { connect } from 'react-redux';
import TermsOfService from './TermsOfService';
import { acceptTermsOfService } from '../../actions/authActions';
import { selectCurrentUser } from '../../selectors/authSelectors';

const mapStateToProps = state => {
  const currentUser = selectCurrentUser(state);

  return {
    currentUser,
  };
};

export default connect(mapStateToProps, { acceptTermsOfService })(TermsOfService);
