import { connect } from 'react-redux';
import SectionTileHeader from './SectionTileHeader';
import { selectCompletedCounts } from '../../selectors/sectionProgressSelectors';

const mapStateToProps = (state, ownProps) => {
  const { sectionProgress } = ownProps;
  const completedCounts = sectionProgress && selectCompletedCounts(state, sectionProgress.id);

  return {
    completedCounts,
  };
};

export default connect(mapStateToProps)(SectionTileHeader);
