import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import CKEditor from './CKEditor';
import Submittable from '../library/Submittable';
import RTEAccessibilityNote from './RTEAccessibilityNote';

export default class SingleTextInputForm extends Component {
  constructor(props) {
    super(props);

    this.state = { content: this.props.initialContent };
  }

  handleChange = content => {
    this.setState({ content });
  };

  handleSubmit = () =>
    this.props.onSubmit(this.state.content).then(() => this.setState({ content: this.props.initialContent }));

  render() {
    return (
      <Submittable
        onSubmit={this.handleSubmit}
        render={(isSubmitting, handleSubmit) => (
          <>
            <div className="input-element">
              {isSubmitting ? (
                <div dangerouslySetInnerHTML={{ __html: this.state.content }} />
              ) : (
                <>
                  <CKEditor
                    inline
                    minimal
                    onChange={this.handleChange}
                    content={this.props.initialContent}
                    activeClass="rte"
                  />
                </>
              )}
            </div>
            <div className="button">
              <button
                type="button"
                className="button-standard button-standard--grey button-standard--small"
                onClick={handleSubmit}
                disabled={this.props.disabled || isSubmitting}>
                <FormattedMessage id={isSubmitting ? 'comment.submitting' : 'comment.submit'} />
              </button>
            </div>
            <RTEAccessibilityNote />
          </>
        )}
      />
    );
  }
}

SingleTextInputForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialContent: PropTypes.string,
  disabled: PropTypes.bool,
};

SingleTextInputForm.defaultProps = {
  disabled: false,
};
