import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Unit from '../../../api/unit';
import Tutorial from '../../../api/tutorial';
import Errors from '../../Utils/Errors';
import Modal2 from '../../library/Modal2';
import { toastError, toastSuccess } from '../../../utils/toastHelper';
import { canAdministrate } from '../../../helpers/userAuth';
import AppSelect from '../../Utils/AppSelect';
import intl from '../../../utils/intl';
import CustomInput from '../../Utils/CustomInput';
import { getDefaultLaunchIn } from '../../../helpers/labHelper';
import FutureCohortsQuizCheckbox from '../../../common/components/FutureCohortsQuizCheckbox/FutureCohortsQuizCheckbox';
import useFutureCohortsQuiz from '../../../common/components/FutureCohortsQuizCheckbox/useFutureCohortsQuiz';

// eslint-disable-next-line complexity
const EditUnitModal = ({
  unit: unitProp,
  onSave,
  section,
  onHide,
  fetchBootcamp,
  currentFirstBootcampProject,
  isFirstProject,
  bootcamp,
  currentUser,
}) => {
  const { futureCohortsOnly, setFutureCohortsOnly } = useFutureCohortsQuiz();
  const isProject = unitProp.unit_type === Unit.PROJECT;
  const [unit, setUnit] = useState({
    ...unitProp,
    app: unitProp.app || (isProject ? Tutorial.TABLEAU : null),
    launch_in: unitProp.launch_in || (isProject ? getDefaultLaunchIn(unitProp.app) : null),
  });
  const [errors, setErrors] = useState([]);

  let isAdmin = canAdministrate(currentUser);

  const handleChange = e => {
    const { target } = e;
    const { name } = target;
    const value = target.type === 'checkbox' ? target.checked : target.value;

    setUnit({
      ...unit,
      [name]: value,
    });
  };

  const handleAppChange = changes => {
    const newUnit = { ...unit, ...changes };
    const launch_in = getDefaultLaunchIn(newUnit.app);
    setUnit({
      ...newUnit,
      launch_in,
    });
  };

  const handleSubmit = e => {
    e.preventDefault();
    onSave(unit, section.id, futureCohortsOnly)
      .then(() => {
        onHide();
        fetchBootcamp(bootcamp.id);
        toastSuccess(<FormattedMessage id="section.notification.saveModule" />, { autoClose: 5000 });
      })
      .catch(err => {
        setErrors(err.value.errors);
        toastError(err.value.errors, { autoClose: 5000 });
      });
  };

  const firstProjectCheckbox = () => {
    const disableFirstProjectCheckbox = currentFirstBootcampProject && !isFirstProject;

    return (
      <div className="checkbox first-project">
        <label htmlFor="is_first_project">Is this the first project?</label>
        <input
          type="checkbox"
          aria-label="First Project Checkbox"
          name="is_first_project"
          checked={unit.is_first_project || false}
          onChange={handleChange}
          disabled={disableFirstProjectCheckbox}
        />
        {disableFirstProjectCheckbox && (
          <div className="first-project-disclaimer">
            The first project is currently "{currentFirstBootcampProject.title}"
          </div>
        )}
      </div>
    );
  };

  let title;
  if (unit.id) {
    switch (unit.unit_type) {
      case Unit.MODULE:
        title = <FormattedMessage id="unit.heading.editModule" />;
        break;
      case Unit.PROJECT:
        title = <FormattedMessage id="unit.heading.editProject" />;
        break;
      case Unit.GRADED_QUIZ:
        title = <FormattedMessage id="unit.heading.editGradedQuiz" />;
        break;
    }
  } else {
    switch (unit.unit_type) {
      case Unit.MODULE:
        title = <FormattedMessage id="unit.heading.createModule" />;
        break;
      case Unit.PROJECT:
        title = <FormattedMessage id="unit.heading.createProject" />;
        break;
      case Unit.GRADED_QUIZ:
        title = <FormattedMessage id="unit.heading.createGradedQuiz" />;
        break;
    }
  }

  return (
    <Modal2 size="small" bodyClass=" " onHide={onHide} onOk={handleSubmit} title={title}>
      <Errors errors={errors} />

      <CustomInput
        name="title"
        onChange={handleChange}
        value={unit.title}
        label={intl.formatMessage({ id: 'unit.form.title' })}
      />
      {unit.unit_type === Unit.PROJECT && <AppSelect block={unit} onChange={handleAppChange} />}
      {unit.unit_type === Unit.PROJECT && firstProjectCheckbox()}
      {unit.unit_type === Unit.GRADED_QUIZ && (
        <FutureCohortsQuizCheckbox
          checked={futureCohortsOnly}
          onChange={setFutureCohortsOnly}
          disabled={isAdmin ? false : true}
        />
      )}
    </Modal2>
  );
};

EditUnitModal.propTypes = {
  section: PropTypes.object.isRequired,
  unit: PropTypes.object.isRequired,
  onSave: PropTypes.func.isRequired,
};

export default EditUnitModal;
