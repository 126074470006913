import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Spin } from 'antd';
import { FormattedMessage } from 'react-intl';

import AdminWrapper from '../../../components/v2/Nav/Admin/AdminWrapper';
import intl from '../../../utils/intl';
import { getProjectsForCohort } from '../../../actions/projectActions';
import { fetchAssignmentProgresses } from '../../../actions/assignmentProgressActions';
import { getUsers } from '../../../actions/userActions';
import { getRosterForCohort } from '../../../actions/rosterActions';
import { useAutograderRulesetByAssignmentId } from '../../hooks/useAutograderRuleset';
import { runRulesetChecks } from '../../utils/googleSheetsGraderHelper';
import { loadGoogleAPI } from '../../utils/googleAPILoader';
import RulesetCriterionReport from '../../components/RulesetCriterionReport/RulesetCriterionReport';
import { calculateGrade } from '../RulesetCriterionReport/rulesetCriterionReportHelper';

const Grader = () => {
  const dispatch = useDispatch();
  const { cohortId } = useParams();
  const [users, setUsers] = useState([]);
  const [gradedAssignments, setGradedAssignments] = useState([]);
  const [selectedAssignment, setSelectedAssignment] = useState({});
  const [loading, setLoading] = useState(false);

  const [autograderResults, setAutograderResults] = useState();
  const autograderRuleset = useAutograderRulesetByAssignmentId(selectedAssignment.assignment_id);

  useEffect(() => {
    const fetchUsers = async cohortId => {
      const rosterEntries = await getRosterForCohort(cohortId)(dispatch);
      const userIds = rosterEntries.map(entry => entry.user_id);

      const users = await getUsers(userIds)(dispatch);
      setUsers(users);
    };

    const fetchUserProgresses = async cohortId => {
      const userAssignmentPromises = users.map(async user => {
        const getGradedProjects = () => getProjectsForCohort(cohortId, true, user.id)(dispatch);
        const gradedProjects = await getGradedProjects();
        const assignmentProgressIds = gradedProjects.map(entry => entry.assignment_progress_id);
        return await fetchAssignmentProgresses({ ids: assignmentProgressIds })(dispatch);
      });
      const userAssignments = await Promise.all(userAssignmentPromises);
      const finalAssignments = userAssignments.reduce((acc, currVal) => [...acc, ...currVal], []);
      setGradedAssignments(finalAssignments);
    };

    if (cohortId) {
      if (!users.length) {
        fetchUsers(cohortId);
      } else {
        fetchUserProgresses(cohortId);
      }
    }
  }, [cohortId, dispatch, users]);

  useEffect(() => {
    const generateResult = async () => {
      await loadGoogleAPI();
      const submissionURL = selectedAssignment?.user_project_url;

      if (submissionURL) {
        const results = await runRulesetChecks(autograderRuleset, submissionURL);
        setLoading(false);
        setAutograderResults(results);
      }
    };

    if (autograderRuleset) {
      generateResult();
    }
  }, [autograderRuleset, selectedAssignment]);

  const Results = () => {
    return autograderResults.map(({ result, isModelAnswer }) => {
      if (isModelAnswer) {
        return <FormattedMessage id="autograder.grading.modelAnswer" />;
      }

      const { reportData, nonLinearGradingRanges } = result;
      const recommendedGrade = calculateGrade(reportData, nonLinearGradingRanges);
      return (
        <>
          <div>
            <span className="title">
              <FormattedMessage id="autograder.label.gradedGoogleSheets" />
            </span>
            <a href={selectedAssignment.user_project_url} target="_blank" rel="noopener noreferrer">
              <FormattedMessage id="autograder.label.viewSubmission" />
            </a>
          </div>
          <RulesetCriterionReport reportData={reportData} recommendedGrade={recommendedGrade} />;
        </>
      );
    });
  };

  return (
    <AdminWrapper headerTitle={intl.formatMessage({ id: 'autograder.header.gradeAssignment' })}>
      <div className="grade-by-cohort">
        <div className="submission-selector">
          <select
            data-testid="select-submission"
            name="assessment"
            disabled={!cohortId}
            onChange={evt => {
              setLoading(true);
              setUsers([]);
              const currentAssignment = gradedAssignments.find(({ id }) => id === evt.target.value);
              setSelectedAssignment(currentAssignment);
            }}>
            <option data-testid="select-option-submission" value="">
              --Select a submission--
            </option>
            {gradedAssignments?.map(({ id, title }) => (
              <option key={id} value={id} data-testid="select-option-submission">
                {`${title}-${id}`}
              </option>
            ))}
          </select>
        </div>
        {loading && (
          <div className="spinner">
            <Spin size="large" />
          </div>
        )}
        {autograderResults && !loading && <Results />}
      </div>
    </AdminWrapper>
  );
};

export default Grader;
