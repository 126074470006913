import { map as _map } from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import SectionTile from './SectionTile';
import useBootcamp from '../../../../hooks/useBootcamp';

export default function Bootcamp({ bootcamp, block, onSelect }) {
  const { sections } = useBootcamp(bootcamp.id);

  return (
    <div>
      {_map(sections, (section, index) => (
        <SectionTile key={section.id} section={section} index={index} block={block} onSelect={onSelect} />
      ))}
    </div>
  );
}

Bootcamp.propTypes = {
  bootcamp: PropTypes.object.isRequired,
  block: PropTypes.object.isRequired,
  onSelect: PropTypes.func.isRequired,
};
