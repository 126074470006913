import { evaluator } from './evalHelpers';

const evalWorkbook = ({ data, property, operator, value }) => {
  const workbook = { ...data };

  if (property === 'exists') {
    return !!workbook?.sheets?.length;
  }

  if (property === 'numOfSheets') {
    const sheetCount = workbook && workbook.sheets.length;
    return evaluator(sheetCount, operator, value);
  }
  return null;
};

export default evalWorkbook;
