import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Icons from './Icons';
import intl from '../../utils/intl';

class BackLink extends Component {
  render() {
    const { url, text, className, linkClass, smallArrow, goBack } = this.props;
    const classes = classnames('go-back-wrapper', className);

    if (!url && !goBack) {
      return '';
    }

    const linkProps = {};
    if (url) {
      linkProps.to = url;
    } else {
      linkProps.onClick = this.props.history.goBack;
    }

    const Tag = url ? Link : 'button';

    return (
      <div className={classes}>
        <Tag
          {...linkProps}
          className={classnames('go-back-button', linkClass)}
          aria-label={text ? null : intl.formatMessage({ id: 'common.back' })}>
          {smallArrow ? <Icons.ArrowBackSmall /> : <Icons.ArrowBack />}
          {text || ''}
        </Tag>
      </div>
    );
  }
}

BackLink.propTypes = {
  url: PropTypes.string,
  className: PropTypes.string,
  text: PropTypes.any,
  smallArrow: PropTypes.bool,
  goBack: PropTypes.bool,
};

export default withRouter(BackLink);
