import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Tooltip } from 'antd';
import useCohortSetupFields from '../hooks/useCohortSetupFields';

const CohortSetupFields = ({ cohort }) => {
  const { status, synced, warning } = useCohortSetupFields(cohort);

  if (!cohort.id) {
    return null;
  }

  return (
    <div className="setup-field-container">
      {status && (
        <span className="label-status">
          <FormattedMessage id={`cohort.status.${status}`} />
        </span>
      )}
      {synced && (
        <span className="label-synced">
          <FormattedMessage id="cohort.synced" />
        </span>
      )}
      {warning && (
        <Tooltip placement="top" title={<FormattedMessage id="cohort.warningMessage" />}>
          <span className="label-warning">
            <FormattedMessage id="cohort.warning" />
          </span>
        </Tooltip>
      )}
    </div>
  );
};

CohortSetupFields.propTypes = {
  cohort: PropTypes.object.isRequired,
};

export default CohortSetupFields;
