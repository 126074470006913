import { getLocationPath } from './dataSourceHelpers';
import { evaluator, sheetNotFound } from './evalHelpers';

const getColumnDataSourcePath = ({ type, order, name }) => {
  const locIndex = order && parseInt(order, 10);

  switch (type) {
    case 'byOrder':
      return path => path.columnHeaders[locIndex];
    case 'byName':
      return path => path.columnHeaders.find(header => header === name);
    case 'byAny':
      return path => Array.prototype.some.bind(path);
    case 'byAll':
      return path => Array.prototype.every.bind(path);
    default:
      return () => null;
  }
};

// assuming we're checking only in the first table/dataset
// eslint-disable-next-line complexity
const evalColumns = ({ data, location, property, operator, value }) => {
  const sheet = getLocationPath(data, location);
  if (!sheet) return sheetNotFound(property, operator, value);

  const { type, order, name } = location[location.length - 1].dataSource;
  const columnData = sheet.dataset[0];
  const { columnHeaders, rows } = columnData;

  const columnChecker = getColumnDataSourcePath({ type, order, name });
  const columnHeader = columnChecker(columnData);

  switch (property) {
    case 'exists':
      return !!columnHeader;
    case 'columnSize':
      if (type === 'byOrder' || type === 'byName') {
        let colSize = 0;
        const columnIndex = columnHeaders.findIndex(header => header === columnHeader);
        rows.forEach(row => {
          if (row[columnIndex].name) colSize += 1;
        });
        return evaluator(colSize, operator, value);
      }
      if (type === 'byAny' || type === 'byAll') {
        const colSizes = Array.from({ length: columnHeaders.length }, () => 0);
        for (let r = 0; r < rows.length; r += 1) {
          for (let c = 0; c < rows[r].length; c += 1) {
            // eslint-disable-next-line max-depth
            if (rows[r][c].name) colSizes[c] += 1;
          }
        }
        return columnChecker(colSizes)(colSize => {
          return evaluator(colSize, operator, value);
        });
      }
      return false;
    case 'name':
      if (type === 'byOrder' || type === 'byName') {
        return evaluator(columnHeader, operator, value);
      }
      if (type === 'byAny' || type === 'byAll') {
        return columnChecker(columnHeaders)(header => {
          return evaluator(header, operator, value);
        });
      }
      return false;
    case 'filterExists':
      if (type === 'byOrder' || type === 'byName') {
        const columnIndex = columnHeaders.findIndex(header => header === columnHeader);
        const { filters } = rows[0][columnIndex];
        return !!filters;
      }
      if (type === 'byAny' || type === 'byAll') {
        return columnChecker(columnHeaders)((header, index) => {
          const { filters } = rows[0][index];
          return !!filters;
        });
      }
      return false;
    case 'filterType':
      if (type === 'byOrder' || type === 'byName') {
        const columnIndex = columnHeaders.findIndex(header => header === columnHeader);
        const { filters } = rows[0][columnIndex];
        if (filters) {
          const filterTypes = Object.keys(filters);
          const conditionFilter = filters.condition ? filters.condition.type : '';
          if (conditionFilter) {
            filterTypes.push(conditionFilter);
          }
          if (operator === 'contains') {
            // eslint-disable-next-line max-depth
            if (filterTypes.includes(value)) return true;
          } else if (operator === 'does not contain') {
            // eslint-disable-next-line max-depth
            if (filterTypes.includes(value)) return true;
          } else {
            return evaluator(conditionFilter, operator, value);
          }
        }
        return false;
      }
      if (type === 'byAny' || type === 'byAll') {
        return columnChecker(columnHeaders)((header, index) => {
          const { filters } = rows[0][index];
          if (filters) {
            const filterTypes = Object.keys(filters);
            const conditionFilter = filters.condition ? filters.condition.type : '';
            if (conditionFilter) {
              filterTypes.push(conditionFilter);
            }
            if (operator === 'contains') {
              if (filterTypes.includes(value)) return true;
            } else if (operator === 'does not contain') {
              if (filterTypes.includes(value)) return true;
            } else {
              return evaluator(conditionFilter, operator, value);
            }
          }
          return false;
        });
      }
      return false;
    case 'sortType':
      if (type === 'byOrder' || type === 'byName') {
        const columnIndex = columnHeaders.findIndex(header => header === columnHeader);
        const { sorts } = rows[0][columnIndex];
        if (sorts) {
          const sortTypes = Object.keys(sorts);
          if (operator === 'contains') {
            if (sortTypes.includes(value)) return true;
          } else if (operator === 'does not contain') {
            if (sortTypes.includes(value)) return true;
          } else if (sorts.sortOrder) {
            const conditionSort = sorts.sortOrder;
            return evaluator(conditionSort, operator, value);
          }
        }
        return false;
      }
      if (type === 'byAny' || type === 'byAll') {
        return columnChecker(columnHeaders)((header, index) => {
          const { sorts } = rows[0][index];
          if (sorts) {
            const sortTypes = Object.keys(sorts);
            if (operator === 'contains') {
              if (sortTypes.includes(value)) return true;
            } else if (operator === 'does not contain') {
              if (sortTypes.includes(value)) return true;
            } else if (sorts.sortOrder) {
              const conditionSort = sorts.sortOrder;
              return evaluator(conditionSort, operator, value);
            }
          }
          return false;
        });
      }
      return false;
    default:
      return null;
  }
};

export default evalColumns;
