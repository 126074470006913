import { keyBy as _keyBy, sumBy as _sumBy, map as _map, sortBy as _sortBy } from 'lodash';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import BootcampSelector from '../../Bootcamp/BootcampSelector';
import CohortSelectorContainer from '../../Cohorts/CohortSelectorContainer';
import intl from '../../../utils/intl';
import Icons from '../../Utils/Icons';
import { getSearchRegex } from '../../../helpers/regexHelper';
import StudentCard from './StudentCard';

export default class StudentList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedBootcamp: this.props.selectedBootcamp,
      selectedCohort: this.props.selectedCohort,
      selectedStudent: null,
      searchText: '',
      students: [],
      onlyUngraded: false,
    };
  }

  componentsWillReceiveProps(nextProps) {
    if (!this.state.selectedBootcamp && nextProps.selectedBootcamp) {
      this.setState({ selectedBootcamp: nextProps.selectedBootcamp });
    }

    if (!this.state.selectedCohort && nextProps.selectedCohort) {
      this.setState({ selectedCohort: nextProps.selectedCohort });

      this.getData();
    }
  }

  handleBootcampSelect = bootcamp => {
    this.setState({ selectedBootcamp: bootcamp, selectedStudent: null, students: [] }, () => {
      this.triggerStudentSelect();
    });
  };

  handleCohortSelect = cohort => {
    if (cohort) {
      this.props.setCurrentCohortId(cohort.id);
    }
    this.setState({ selectedCohort: cohort, selectedStudent: null }, () => {
      this.triggerCohortSelect();
      this.getData();
    });
  };

  handleSearchChange = e => {
    this.setState({ searchText: e.target.value });
  };

  toggleOnlyUngraded = () => {
    this.setState({ onlyUngraded: !this.state.onlyUngraded });
  };

  getData = async () => {
    const { getRosterForCohort, getUsers, currentStudentId } = this.props;
    const { selectedCohort } = this.state;
    let { selectedStudent } = this.state;

    if (!selectedCohort) {
      return;
    }

    const rawEntries = await getRosterForCohort(selectedCohort.id);
    const userIds = rawEntries.map(entry => entry.user_id);

    const users = await getUsers(userIds);
    const usersById = _keyBy(users, 'id');
    const students = rawEntries.map(entry => ({
      ...entry,
      student: usersById[entry.user_id],
    }));

    const filtered = this.filteredStudents(students);

    if (_map(filtered, 'student.id').includes(currentStudentId)) {
      selectedStudent = filtered.filter(s => s.student.id === currentStudentId)[0];
    } else if (!_map(filtered, 'student.id').includes(selectedStudent && selectedStudent.student.id)) {
      selectedStudent = filtered[0];
      if (selectedStudent) {
        this.props.setCurrentStudentId(selectedStudent.student.id);
      }
    }

    this.setState(
      {
        hasLoaded: true,
        students,
        selectedStudent,
      },
      this.triggerStudentSelect,
    );
  };

  filteredStudents = students => {
    const { searchText, onlyUngraded } = this.state;

    const regex = getSearchRegex(searchText);

    const filtered = students
      .filter(s => !onlyUngraded || (onlyUngraded && s.ungraded_count > 0))
      .filter(s => s.student && regex.test(s.student.name));

    return _sortBy(filtered, s => s.student.name.toLowerCase());
  };

  handleStudentSelect = selectedStudent => {
    this.props.setCurrentStudentId(selectedStudent.student.id);
    this.setState({ selectedStudent }, () => {
      this.triggerStudentSelect();
      this.triggerCohortSelect();
    });
  };

  triggerStudentSelect = () => {
    const { onStudentSelect } = this.props;
    const { selectedStudent } = this.state;
    onStudentSelect && onStudentSelect(selectedStudent);
  };

  triggerCohortSelect = () => {
    const { onCohortSelect } = this.props;
    const { selectedCohort } = this.state;
    onCohortSelect && onCohortSelect(selectedCohort);
  };

  render() {
    const { selectedBootcamp, selectedCohort, searchText, selectedStudent } = this.state;
    const { bootcamps } = this.props;
    const filtered = this.filteredStudents(this.state.students);
    const totalUngraded = _sumBy(filtered, 'ungraded_count');

    return (
      <div>
        <div className="student-roaster-dropdown">
          <BootcampSelector bootcamps={bootcamps} selected={selectedBootcamp} onSelect={this.handleBootcampSelect} />
        </div>

        <div className="student-roaster-dropdown">
          <CohortSelectorContainer
            selected={selectedCohort}
            bootcamp={selectedBootcamp}
            onSelect={this.handleCohortSelect}
          />
        </div>

        <div className="student-roaster-search">
          <div className="custom-search-input">
            <input
              className="custom-search-input__input"
              type="text"
              value={searchText}
              onChange={this.handleSearchChange}
              placeholder={intl.formatMessage({ id: 'common.search' })}
              aria-label={intl.formatMessage({ id: 'common.search' })}
            />

            <button type="submit" className="custom-search-input__results" onClick={this.toggleOnlyUngraded}>
              <span className="visually-hidden">
                <FormattedMessage id="roster.showOnlyUngraded" />
              </span>
              <span className="custom-search-input__results--count" aria-hidden>
                {totalUngraded}
              </span>
              <Icons.Graded />
            </button>
          </div>
        </div>

        <div className="students-search-results">
          {filtered.map(s => (
            <StudentCard
              key={s.student.id}
              info={s}
              isSelected={selectedStudent && s.student.id === selectedStudent.student.id}
              onSelect={this.handleStudentSelect}
            />
          ))}
        </div>
      </div>
    );
  }
}
