import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const ModalFooter = ({ showCancel, onCancel, cancelTitle, onOk, okTitle, footerContent, className }) => {
  return (
    <>
      {(showCancel || onOk || footerContent) && (
        <div className={classnames('modal-dialog-footer', className)}>
          {showCancel && (
            <button
              type="button"
              className="button-standard button-standard--outlined button-standard--short"
              onClick={onCancel}>
              {cancelTitle}
            </button>
          )}

          {onOk && (
            <button
              type="submit"
              className="button-standard button-standard--blue2 button-standard--short"
              onClick={onOk}>
              {okTitle}
            </button>
          )}
          {footerContent}
        </div>
      )}
    </>
  );
};

ModalFooter.propTypes = {
  showCancel: PropTypes.bool,
  onCancel: PropTypes.func,
  cancelTitle: PropTypes.any,
  onOk: PropTypes.func,
  okTitle: PropTypes.any,
  className: PropTypes.string,
  footerContent: PropTypes.any,
};

export default ModalFooter;
