export const getSpreadsheet = async (sheetID, params) => {
  const { access_token: accessToken } = await getAccessToken();
  let queryParams = '';
  if (params && params.length) {
    queryParams = params.map(param => `${param.key}=${param.value}`).join('&');
  }
  try {
    const response = await fetch(
      `https://sheets.googleapis.com/v4/spreadsheets/${sheetID}?${queryParams}${
        queryParams ? '&' : ''
      }includeGridData=true`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      },
    );

    const results = await response.json();
    const { error } = results;
    if (error) {
      throw new Error(error.status);
    }
    return results;
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log(`ERROR: ${err}`);
    return null;
  }
};

// TODO move to backend
export const getAccessToken = async () => {
  const { gapi } = window;
  const CLIENT_ID = '508628288154-38gnme0plfelvcp975334g8ktr4cssgd.apps.googleusercontent.com';
  const API_KEY = 'AIzaSyBSTrc5keL44u0mWkD7B7b7n6IyLe3OtAs';
  const DISCOVERY_DOCS = ['https://sheets.googleapis.com/$discovery/rest?version=v4'];
  const SCOPES = 'https://www.googleapis.com/auth/spreadsheets.readonly';
  let GoogleAuth;
  return new Promise(resolve => {
    const initClient = async () => {
      await gapi.client.init({
        apiKey: API_KEY,
        clientId: CLIENT_ID,
        discoveryDocs: DISCOVERY_DOCS,
        scope: SCOPES,
      });
      GoogleAuth = gapi.auth2.getAuthInstance();
      GoogleAuth.isSignedIn.listen(setSigninStatus);
      setSigninStatus();
    };
    const setSigninStatus = () => {
      const isAuthorized = GoogleAuth.currentUser.get().hasGrantedScopes(SCOPES);
      if (isAuthorized) {
        resolve(gapi.auth2.getAuthInstance().currentUser.get().getAuthResponse());
      } else {
        GoogleAuth.signIn();
      }
    };

    gapi.load('client:auth2', initClient);
  });
};
