const INTERVAL_IDS_BY_TYPE = {};

const getIntervalIds = type => {
  if (!INTERVAL_IDS_BY_TYPE[type]) {
    INTERVAL_IDS_BY_TYPE[type] = [];
  }
  return INTERVAL_IDS_BY_TYPE[type];
};

const set = (type, callback, milliseconds, args = []) => {
  const intervalIds = getIntervalIds(type);
  const intervalId = setInterval(callback, milliseconds, ...args);
  intervalIds.push(intervalId);
};

const clear = type => {
  const intervalIds = getIntervalIds(type);
  intervalIds.forEach(intervalId => clearInterval(intervalId));
  delete INTERVAL_IDS_BY_TYPE[type];
};

const clearAll = () => {
  for (const type in INTERVAL_IDS_BY_TYPE) {
    clear(type);
  }
};

export default {
  set,
  clear,
  clearAll,
};
