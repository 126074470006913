/* eslint-disable max-lines */
import { getSpreadsheet } from '../api/googleSheetsAPI';
import { getAddyFromRowCol, getWorkbookId } from './utils';
import { GoogleSheetsChartsType, GoogleSheetsFormat } from './constants/GoogleSheetsFormatEnum';
import Group from '../models/Group';
import { calculateFormula } from './logicFormulaParser/logicFormulaCalculator';

let filteredSpreadsheet = {};
let currentSheetPivotTables = [];
let currentSheetFilters = {};

const getSheetById = (id, sheets) => sheets.find(sheet => sheet.properties.sheetId === id);

// TODO: Revisit how we build pivot tables and see if we can improve
// Doing so will also change its associated check functions
// TODO: Build tests but will wait because implementation may change
const buildPivotTable = val => {
  const { pivotTable } = val;

  const sheet = getSheetById(pivotTable.source.sheetId, filteredSpreadsheet.sheets);

  const getValuesWithTitle = () =>
    pivotTable.values?.map(value => {
      return {
        ...value,
        title: sheet?.data[0]?.rowData[0]?.values[value.sourceColumnOffset]?.formattedValue,
      };
    });

  const getRowsWithTitle = () =>
    pivotTable.rows?.map(value => {
      return {
        ...value,
        title: sheet?.data[0]?.rowData[0]?.values[value.sourceColumnOffset]?.formattedValue,
      };
    });

  return {
    title: val[GoogleSheetsFormat.VALUE_TYPE],
    range: '', // TODO: what goes here?
    ...pivotTable,
    values: getValuesWithTitle(),
    rows: getRowsWithTitle(),
    filters: val.pivotTable.criteria,
  };
};

const getValues = (values, rowIndex) => {
  // eslint-disable-next-line complexity
  return values.map((val, columnIndex) => {
    let updatedValues = {};
    if (val[GoogleSheetsFormat.EFFECTIVE_FORMAT]) {
      const vals = {
        name: val[GoogleSheetsFormat.VALUE_TYPE],
        formula: (val[GoogleSheetsFormat.USER_ENTERED_VALUE] || {}).formulaValue || null,
        bold: val[GoogleSheetsFormat.EFFECTIVE_FORMAT].textFormat.bold || null,
        textColor: val[GoogleSheetsFormat.EFFECTIVE_FORMAT].color || null,
        backgroundColor: val[GoogleSheetsFormat.EFFECTIVE_FORMAT].backgroundColor || null,
        formatType: val[GoogleSheetsFormat.EFFECTIVE_FORMAT].formatType || null,
        numberFormat: val[GoogleSheetsFormat.EFFECTIVE_FORMAT].numberFormat || null,
        textFormat: val[GoogleSheetsFormat.EFFECTIVE_FORMAT].textFormat || null,
        formattedValue: val[GoogleSheetsFormat.VALUE_TYPE],
        rawNumValue: val[GoogleSheetsFormat.EFFECTIVE_VALUE]?.numberValue,
        a1Address: getAddyFromRowCol(rowIndex, columnIndex),
        rowIndex,
        columnIndex,
      };
      updatedValues = { ...vals };
    }

    const filterGroup = {};
    // We assume that filters are defined in the first row where headers are located within a sheet
    if (rowIndex === 0) {
      if (currentSheetFilters) {
        const { criteria, sortSpecs } = currentSheetFilters;
        filterGroup.filters = criteria && criteria[columnIndex];
        filterGroup.sorts = sortSpecs && sortSpecs.find(({ dimensionIndex }) => dimensionIndex === columnIndex);
      }

      if (val.pivotTable) {
        currentSheetPivotTables.push(buildPivotTable(val));
      }
    }

    return { ...updatedValues, ...filterGroup };
  });
};

const getRows = rows => {
  return rows.map((row, rowIndex) => {
    if (!Object.keys(row).length) return {};
    return getValues(row.values, rowIndex);
  });
};

const getRowHeaders = rows => {
  return rows.map(row => {
    if (!Object.keys(row).length) return {};
    return row.values[0][GoogleSheetsFormat.VALUE_TYPE];
  });
};

const getColumnHeaders = cols => {
  return cols.map(col => col[GoogleSheetsFormat.VALUE_TYPE]);
};

const getDataset = (sheetName, data, ranges = []) => {
  return data.map((set, i) => {
    return {
      range: sheetName && ranges[sheetName] && ranges[sheetName][i],
      rows: getRows(set.rowData),
      columnHeaders: getColumnHeaders(set.rowData[0].values),
      rowHeaders: getRowHeaders(set.rowData),
    };
  });
};

const extractCharts = spreadsheet => {
  const availCharts = {};
  spreadsheet.sheets.forEach(sheet => {
    if (sheet.charts) {
      availCharts[sheet.properties.title] = sheet.charts;
    }
  });
  return availCharts;
};

const getCharts = charts => {
  return charts.map(chart => {
    const { spec } = chart;
    let chartType = '';
    let chartData = {};

    if (spec.basicChart) {
      chartType = spec.basicChart.chartType;
      chartData = spec.basicChart;
    } else if (spec.pieChart) {
      chartType = GoogleSheetsChartsType.PIE_CHART;
      chartData = spec.pieChart;
    }

    return {
      title: spec.title,
      chartType,
      chartData,
    };
  });
};

export const buildRangeParams = spreadsheet => {
  const ranges = {};
  spreadsheet.sheets.forEach(({ properties, data }) => {
    const sheetName = properties.title;
    const rows = data[0].rowData.length - 1;
    const columns = 25;
    ranges[sheetName] = [`A1:${getAddyFromRowCol(rows, columns)}`];
  });
  return ranges;
};

// TODO: change parsing logic to only parse the sheet associated with each
// ruleset criterion (effectively changing the entire parsing structure)
// TODO: load sheet data asynchronously so autograder can grade the loaded sheets
// without blocking the UI
const optimizeQueryWithRows = rulesetCriteria => {
  const maxRowsBySheet = 1000;

  const allSheetsExist = rulesetCriteria.group.every(({ sheetName }) => !!sheetName);
  // maintain backwards compatibility with no required field for sheet info
  if (!allSheetsExist) {
    return [];
  }

  const sheetsRowMaxMap = {};
  const params = rulesetCriteria.group.map(({ sheetName, sheetTotalRows }) => {
    const userDefinedSheetRows = sheetTotalRows && parseInt(sheetTotalRows, 10);
    const currentSheetRowMax = sheetsRowMaxMap[sheetName];

    const chunkSheetRows = () => {
      // selected arbitrary row count
      const rowGroupsCount = Math.ceil(userDefinedSheetRows / maxRowsBySheet);
      const lastIndex = rowGroupsCount - 1;
      const moreParams = Array.from({ length: rowGroupsCount }).map((val, index) => {
        const minRow = index * maxRowsBySheet + 1;
        let maxRow = (index + 1) * maxRowsBySheet;
        if (index === lastIndex) {
          maxRow = userDefinedSheetRows;
        }
        return {
          key: 'ranges',
          value: `${sheetName}!A${minRow}:${maxRow}`,
        };
      });
      return moreParams;
    };

    if (currentSheetRowMax) {
      if (userDefinedSheetRows && currentSheetRowMax < userDefinedSheetRows) {
        sheetsRowMaxMap[sheetName] = userDefinedSheetRows;
        return chunkSheetRows();
      }
      return [];
    } else {
      if (userDefinedSheetRows) {
        sheetsRowMaxMap[sheetName] = userDefinedSheetRows;
        return chunkSheetRows();
      }
      sheetsRowMaxMap[sheetName] = maxRowsBySheet;
      return [
        {
          key: 'ranges',
          value: sheetName,
        },
      ];
    }
  });
  return params.reduce((acc, currentParams) => [...acc, ...currentParams], []);
};

export const parseGoogleSheets = async (sheetId, params) => {
  if (params.length) {
    const paramsPromises = params.map(async param => {
      return await getSpreadsheet(sheetId, [param]);
    });
    const spreadsheets = await Promise.all(paramsPromises);
    // sheet aggregator since parser expects this format
    const sheetObjects = {};
    spreadsheets.forEach(spreadsheet => {
      if (spreadsheet) {
        if (!Object.keys(filteredSpreadsheet).length) {
          // this gives us the spreadsheet structure and some metadata
          filteredSpreadsheet = { ...spreadsheet };
        }
        const { sheets } = spreadsheet;
        const currentSheet = sheets[0];
        const sheetName = currentSheet.properties.title;
        const rowData = currentSheet.data[0]?.rowData;
        if (rowData) {
          if (sheetObjects[sheetName]) {
            let existingRowData = sheetObjects[sheetName].data[0].rowData;
            sheetObjects[sheetName].data[0].rowData = [...existingRowData, ...rowData];
          } else {
            sheetObjects[sheetName] = currentSheet;
          }
        }
      }
    });
    filteredSpreadsheet.sheets = Object.values(sheetObjects);
  } else {
    filteredSpreadsheet = await getSpreadsheet(sheetId);
  }

  const ranges = buildRangeParams(filteredSpreadsheet);
  const availableCharts = extractCharts(filteredSpreadsheet);

  const parsedSheets = filteredSpreadsheet.sheets.map(sheet => {
    currentSheetFilters = sheet.basicFilter;
    const sheetName = sheet.properties.title;
    const updatedSheet = {
      name: sheetName,
      dataset: getDataset(sheetName, sheet.data, ranges),
      charts: availableCharts[sheetName] ? getCharts(availableCharts[sheetName]) : [],
      pivotTables: [],
    };

    if (currentSheetPivotTables.length) {
      // Iterate over the pivot tables and retrieve source and metadata
      updatedSheet.pivotTables = currentSheetPivotTables.map(pt => ({
        ...pt,
        data: updatedSheet.dataset,
      }));
      currentSheetPivotTables = [];
    }
    currentSheetFilters = {};
    return updatedSheet;
  });

  return {
    workbookName: filteredSpreadsheet.properties.title,
    sheets: parsedSheets,
  };
};

export const runCriteriaChecks = (gradeableAssessment, parsedSpreadsheet) => {
  const rules = [];
  gradeableAssessment.criteria.forEach(groupCriteria => {
    const checks = [];
    groupCriteria.checks.forEach(check => {
      checks.push({
        id: check.id,
        name: check.name,
        status: check.run(parsedSpreadsheet),
        detail: check.value,
        ...check.run(parsedSpreadsheet),
      });
    });
    let isPassedChecks;
    if (groupCriteria.formula) {
      isPassedChecks = calculateFormula(
        groupCriteria.formula,
        checks.map(check => check.status),
      );
    } else {
      isPassedChecks = checks.every(check => check.status);
    }

    rules.push({
      id: groupCriteria.id,
      name: groupCriteria.name,
      isPassedChecks,
      points: groupCriteria.points,
      checks,
      detail: `Formula: ${groupCriteria.formula}`,
      formula: groupCriteria.formula,
    });
  });
  return rules;
};

export const prepForGrade = rulesetCriterion => {
  const gradableRulesetCriterion = new Group(rulesetCriterion.name);
  rulesetCriterion.criteria.forEach(crit => {
    const gradableRule = gradableRulesetCriterion.createCriterion(crit);
    crit.check.forEach(ch => {
      gradableRule.addCheck(ch);
    });
  });
  return gradableRulesetCriterion;
};

export const runRulesetChecks = async (rulesetCriteria, submissionURL) => {
  const workbookId = getWorkbookId(submissionURL);
  if (!workbookId) {
    return;
  }

  const params = optimizeQueryWithRows(rulesetCriteria);

  const parsedSpreadsheet = await parseGoogleSheets(workbookId, params);
  return rulesetCriteria.group.map(rulesetCriterion => {
    const { isModelAnswer, criterionId, nonLinearGradingRanges } = rulesetCriterion;
    if (isModelAnswer) {
      return rulesetCriterion;
    }
    const gradeReadyAssessment = prepForGrade(rulesetCriterion);
    const result = runCriteriaChecks(gradeReadyAssessment, parsedSpreadsheet);
    return {
      result: { reportData: result, nonLinearGradingRanges },
      criterionId,
    };
  });
};
