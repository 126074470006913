import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'antd';
import { InfoCircleFilled, CalendarOutlined, UserOutlined, FileTextOutlined } from '@ant-design/icons';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';

const Overlay = ({ cohort }) => {
  const {
    start_date: startDate,
    end_date: endDate,
    num_enrolled: numStudents,
    num_projects_submitted: numAssignmentsSubmitted,
  } = cohort;
  const cohortDates = `${moment(new Date(startDate)).format('MMM D YYYY')} - ${moment(new Date(endDate)).format(
    'MMM D YYYY',
  )}`;

  return (
    <div data-testid="cohort-tooltip">
      <div className="cohort-info">
        <CalendarOutlined />
        <span>{cohortDates}</span>
      </div>

      <div className="cohort-info">
        <UserOutlined />
        <FormattedMessage id="cohort.numTotalStudents" values={{ count: numStudents }} />
      </div>

      <div className="cohort-info">
        <FileTextOutlined />
        <FormattedMessage id="cohort.numAssignmentsSubmitted" values={{ count: numAssignmentsSubmitted }} />
      </div>
    </div>
  );
};

const CohortInfoTooltip = ({ cohort }) => {
  return (
    <Tooltip
      placement="bottom"
      title={<Overlay cohort={cohort} />}
      overlayClassName="cohort-info-tooltip"
      overlayStyle={{ maxWidth: '1000px' }}>
      <InfoCircleFilled />
    </Tooltip>
  );
};

CohortInfoTooltip.propTypes = {
  cohort: PropTypes.object.isRequired,
};

export default CohortInfoTooltip;
