import { selectEntities, select } from './applicationSelectors';
import { selectLessonByBlock } from './lessonSelectors';
import { selectStepByLesson } from './stepSelectors';

export const selectTutorialEntities = selectEntities('tutorials');
export const selectTutorial = select(selectTutorialEntities);

const selectTutorialByStep = (state, step) => selectTutorial(state, step?.tutorial_id);

const selectTutorialByLesson = (state, lesson) => selectTutorialByStep(state, selectStepByLesson(state, lesson));

export const selectTutorialByBlock = (state, block) => selectTutorialByLesson(state, selectLessonByBlock(state, block));
