import React from 'react';
import Icons from '../../../Utils/Icons';
import CurrentlyEnrolledCourseCards from './CurrentlyEnrolledCourseCards';
import Hills from '../../../../styles/v2/images/hills.svg';
import PathstreamUser from '../../../../styles/v2/images/pathstream-user.svg';
import PathstreamUser2 from '../../../../styles/v2/images/pathstream-user2.svg';
import CurrentlyEnrolledBootcamps from './CurrentlyEnrolledBootcamps';
import { FormattedMessage } from 'react-intl';
import { renderISODateAsReadable } from './helpers';

const CurrentlyEnrolled = props => {
  const { programCohorts, bootcamps, cohorts, filteredCohorts, currentUser } = props;

  return (
    <div>
      {programCohorts && (
        <div>
          {Object.values(programCohorts).map((cohort, index) => {
            let startDate = renderISODateAsReadable(cohort.start_date);
            let endDate = renderISODateAsReadable(cohort.end_date);
            let downloadLink = cohort.handbook_url;

            return (
              <div className="currently-enrolled" key={index}>
                <div className="flex-top-of-program-cohort-container">
                  <div className="flex-text-program-info">
                    <div className="program-text">
                      <div className="grey-text">
                        <FormattedMessage id="learningHome.currentlyEnrolled" />
                      </div>
                      <div className="blue-text">{cohort.program_name}</div>
                    </div>
                    <div className="small-screen-flex">
                      <div className="text-divide">|</div>
                      <div className="icon-schedule">
                        <Icons.Schedule />
                      </div>
                      <div className="program-dates">{`${startDate} - ${endDate}`}</div>
                    </div>
                  </div>
                  {downloadLink && (
                    <button className="download-btn" onClick={() => window.open(downloadLink, '_blank')}>
                      <span className="icon-element icon-element--background-transparent icon-element--size-standard">
                        <Icons.Download />
                      </span>
                      <h3 className="download-btn-txt">
                        <FormattedMessage id="learningHome.programHandbook" />
                      </h3>
                    </button>
                  )}
                </div>
                {cohort.enrollments && (
                  <CurrentlyEnrolledCourseCards enrollments={cohort.enrollments} cohorts={cohorts} />
                )}
                {index % 2 === 0 ? (
                  <img className="pathstream-user" alt="" src={PathstreamUser} />
                ) : (
                  <img className="pathstream-user" alt="" src={PathstreamUser2} />
                )}
                <img className="hills" alt="" src={Hills} />
              </div>
            );
          })}
        </div>
      )}
      {filteredCohorts[0] && (
        <CurrentlyEnrolledBootcamps
          bootcamps={bootcamps}
          cohorts={cohorts}
          currentUser={currentUser}
          filteredCohorts={filteredCohorts}
        />
      )}
    </div>
  );
};

export default CurrentlyEnrolled;
