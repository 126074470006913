import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Form, Input, Button } from 'antd';
import { v4 as uuidv4 } from 'uuid';
import { FormattedMessage } from 'react-intl';
import { MinusCircleOutlined } from '@ant-design/icons';

import { AutograderLevel, OTHER_PROPS } from '../../../utils/constants/AutograderEnums';

const RulesetBuilder = ({ parentForm, initialValues }) => {
  const [isSuperGroupOn, setIsSuperGroupOn] = useState(!!initialValues);

  const subFieldName = AutograderLevel.RULESET;
  const otherProps = OTHER_PROPS;

  const { name: fName, fieldKey } = initialValues || { name: '' };

  return (
    <div className="ruleset-container">
      {isSuperGroupOn && (
        <Form.Item required={false}>
          <div className="ruleset-main">
            <div className="ruleset-name">
              <FormattedMessage id="autograder.form.rulesetName" />
            </div>
            <Form.Item name={[subFieldName, 'name']} initialValue={fName}>
              <Input />
            </Form.Item>
            <Form.Item name={[subFieldName, 'fieldKey']}>
              <Form.Item className="save-ruleset-submit">
                <Button
                  type="primary"
                  className="save-ruleset-field-btn"
                  onClick={() => {
                    setIsSuperGroupOn(false);

                    parentForm.setFields([
                      {
                        name: [subFieldName, 'fieldKey'],
                        value: initialValues ? fieldKey : uuidv4(),
                      },
                    ]);
                    parentForm.submit();
                  }}>
                  <FormattedMessage id="autograder.form.updateRulesetBtn" />
                </Button>
                <Form.Item name={[otherProps, 'isCanceled']} noStyle>
                  <Button
                    danger
                    className="cancel-check-field-btn"
                    onClick={() => {
                      setIsSuperGroupOn(false);

                      parentForm.setFields([
                        {
                          name: [otherProps, 'isCanceled'],
                          value: true,
                        },
                      ]);
                      parentForm.submit();
                    }}>
                    <MinusCircleOutlined
                      style={{
                        fontSize: '16px',
                        verticalAlign: 'initial',
                      }}
                    />
                    <FormattedMessage id="common.cancel" />
                  </Button>
                </Form.Item>
              </Form.Item>
            </Form.Item>
          </div>
        </Form.Item>
      )}
    </div>
  );
};

RulesetBuilder.propTypes = {
  parentForm: PropTypes.any,
  initialValues: PropTypes.any,
};

export default RulesetBuilder;
