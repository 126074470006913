import Roster from '../api/roster';
import { fetchCurrentTenant } from './tenantActions';

export const getRosterForCohort = cohortId => dispatch =>
  fetchCurrentTenant()(dispatch).then(tenant =>
    Roster.getRosterForCohort(tenant.id, cohortId)
      .then(data => data.value)
      .catch(err => {
        throw err;
      }),
  );
