import { values as _values } from 'lodash';
import createCachedSelector from 're-reselect';
import { selectAll, selectAllFilterByIncludes, selectAllFilterBy } from './applicationSelectors';

export const selectAssignmentProgressEntities = state => state.entities.assignmentProgresses;

export const selectAssignmentProgress = createCachedSelector(
  [selectAssignmentProgressEntities, (_state, assignmentProgressId) => assignmentProgressId],
  (assignmentProgressEntities, assignmentProgressId) => assignmentProgressEntities[assignmentProgressId],
)((_state, assignmentProgressId) => assignmentProgressId);

export const selectProgressForAssignment = createCachedSelector(
  [selectAssignmentProgressEntities, (_state, assignmentId) => assignmentId],
  (assignmentProgressEntities, assignmentId) =>
    _values(assignmentProgressEntities).filter(
      assignmentProgress => assignmentProgress.assignment_id === assignmentId,
    )[0],
)((_state, assignmentId) => assignmentId);

export const selectAssignmentProgresses = selectAll(selectAssignmentProgressEntities);
export const selectAssignmentProgressesByEnrollmentIds = selectAllFilterByIncludes(
  selectAssignmentProgresses,
  'context',
);
export const selectAssignmentProgressesByEnrollmentId = selectAllFilterBy(selectAssignmentProgresses, 'context');
