import { dynamicJSLoad } from '../helpers/dynamicJSLoadHelper';

export const loadAppStreamApi = () =>
  new Promise((resolve, reject) => {
    const load = dynamicJSLoad('appstream-embed-js', '/appstream_embed.js');
    try {
      load(resolve);
    } catch (e) {
      reject(e);
    }
  });
