import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Button, Table, Input } from 'antd';
import { FormattedMessage } from 'react-intl';
import { selectCriterionByCriterionProgress } from '../../../../../selectors/criterionSelectors';
import { GradingScale } from '../GradingScale/GradingScale';
import { getNumeratorFromPercent } from '../../../../../utils/utils';
import intl from '../../../../../utils/intl';
const { TextArea } = Input;

const handleSelectedGrade = (currentGrade, saveGrade) => e => {
  const score = Number(e.target.value);

  if (score >= 0) {
    const updatedCriterion = { ...currentGrade, score };
    saveGrade({ criterion: updatedCriterion });
  }
};

const Criterion = ({ criterionProgress, index }) => {
  const criterion = useSelector(state => selectCriterionByCriterionProgress(state, criterionProgress)) || {};

  return (
    <div className="criterion-summary">
      <span className="criterion-index">{index + 1}.</span>
      <span className="criterion-text">{criterion.name}</span>
    </div>
  );
};

Criterion.propTypes = {
  criterionProgress: PropTypes.object,
  index: PropTypes.number,
};

const getColumns = (rubric, saveGrade) => [
  {
    title: intl.formatMessage({ id: 'grade.criterion_name' }),
    dataIndex: 'name',
    render: (_criterion, criterionProgress, index) => <Criterion {...{ criterionProgress, index }} />,
  },
  {
    title: intl.formatMessage({ id: 'grade.score' }),
    dataIndex: 'score',
    width: '40%',
    render: (score, record) => (
      <GradingScale selectedGrade={record} rubric={rubric} onGradeSelect={handleSelectedGrade(record, saveGrade)} />
    ),
  },
  {
    title: intl.formatMessage({ id: 'grade.comment' }),
    dataIndex: 'comment',
    render: (comment, record) => {
      const handleGradeSave = e => {
        const updatedRecord = { ...record, comment: e.target.value };
        saveGrade({ criterion: updatedRecord });
      };
      return <TextArea rows={4} value={comment} onChange={handleGradeSave} />;
    },
  },
];

const getTotalGrade = (criterionProgresses, totalGrade) => {
  const totalScorePossible = criterionProgresses.length * 10;
  let gradeScore = 0;

  if (totalGrade) {
    gradeScore = getNumeratorFromPercent(totalGrade, totalScorePossible);
  }

  return `${gradeScore}/${totalScorePossible} (${totalGrade}%)`;
};

const TableSummary = ({ criterionProgresses, totalGrade, comments, onSaveComments }) => (
  <tr>
    <th scope="row" className="criterion-summary">
      <FormattedMessage id="grade.totalGrade" />
    </th>
    <td>
      <div className="total-grade">{getTotalGrade(criterionProgresses, totalGrade)}</div>
    </td>
    <td>
      <TextArea rows={4} value={comments} onChange={onSaveComments} />
    </td>
  </tr>
);

TableSummary.propTypes = {
  criterionProgresses: PropTypes.array,
  totalGrade: PropTypes.number,
  comments: PropTypes.string,
  onSaveComments: PropTypes.func,
};

const GradingSummary = ({
  criterionProgresses,
  isSaving,
  totalGrade,
  comments,
  rubric,
  isGradedAll,
  saveGrade,
  onSaveComments,
  onSubmit,
}) => (
  <div className="grading-summary">
    <Table
      dataSource={criterionProgresses}
      rowKey="criterion_id"
      columns={getColumns(rubric, saveGrade)}
      pagination={false}
      scroll={{ y: 270 }}
      rowClassName="middle-align"
      summary={() => <TableSummary {...{ criterionProgresses, totalGrade, comments, onSaveComments }} />}
    />
    <Button
      type="primary"
      size="large"
      className="submit-grades"
      disabled={!isGradedAll}
      loading={isSaving}
      onClick={onSubmit}>
      <FormattedMessage id="grading.submitGrades" />
    </Button>
  </div>
);

GradingSummary.propTypes = {
  criterionProgresses: PropTypes.array.isRequired,
  isSaving: PropTypes.bool,
  totalGrade: PropTypes.number,
  comments: PropTypes.string,
  rubric: PropTypes.array,
  isGradedAll: PropTypes.bool,
  saveGrade: PropTypes.func,
  onSaveComments: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default GradingSummary;
