import React from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { selectSaving } from '../../selectors/requestSelectors';

export default function SavingIndicator() {
  const isSaving = useSelector(selectSaving);

  return <FormattedMessage id={isSaving ? 'common.saving' : 'common.saved'} />;
}
