import React, { useEffect, useState } from 'react';
import { Space } from 'antd';
import evaluatorFormattedDate from './evaluatorFormattedDate';

const EvaluatorCountUpTimer = ({ startTime, endTime }) => {
  const [timeDifferenceHours, setTimeDifferenceHours] = useState(0);
  const [timeDifferenceMinutes, setTimeDifferenceMinutes] = useState(0);
  const [timeDifferenceSeconds, setTimeDifferenceSeconds] = useState(0);

  useEffect(() => {
    const timerBeginDate = new Date(startTime);
    const currentTimeDate = new Date(endTime);
    const difference = currentTimeDate.getTime() - timerBeginDate.getTime();

    const hours = Math.floor(difference / (60000 * 60));
    setTimeDifferenceHours(hours);

    const minutes = Math.floor(difference / 60000) - hours * 60;
    setTimeDifferenceMinutes(minutes);

    const seconds = Math.floor(difference / 1000) - (hours * 3600 + minutes * 60);
    setTimeDifferenceSeconds(seconds);
  }, [endTime]);

  if (!startTime || !endTime) {
    return <span></span>;
  }
  return (
    <Space direction="vertical">
      <span title={evaluatorFormattedDate(startTime)}>
        <b>
          {timeDifferenceHours}h {timeDifferenceMinutes}m {timeDifferenceSeconds}s
        </b>
      </span>
    </Space>
  );
};

export default EvaluatorCountUpTimer;
