import { isEmpty as _isEmpty } from 'lodash';
import { BOOTCAMP } from './constants';
import Bootcamp from '../api/bootcamp';
import {
  applicationSave,
  applicationFetch,
  applicationFetchAll,
  applicationDestroy,
  applicationApiExecute,
} from './applicationActions';
import { fetchCurrentTenant } from './tenantActions';
import { getUsers } from './userActions';

export const saveBootcamp = applicationSave(Bootcamp);
export const fetchBootcamp = applicationFetch(Bootcamp);
export const fetchBootcamps = applicationFetchAll(Bootcamp);
export const destroyBootcamp = applicationDestroy(Bootcamp);
export const addAuthor = applicationApiExecute(Bootcamp.addAuthor);
export const removeAuthor = applicationApiExecute(Bootcamp.removeAuthor);
export const copyBootcamp = applicationApiExecute(Bootcamp.copy);

export const getAuthors = cohortId => async dispatch => {
  const tenant = await fetchCurrentTenant()(dispatch);
  const authorIds = await Bootcamp.authorIds(tenant.id, cohortId);
  return _isEmpty(authorIds) ? [] : getUsers(authorIds)(dispatch);
};

export const setSearchText = searchText => ({
  type: BOOTCAMP.SET_SEARCH,
  payload: searchText,
});
