import React from 'react';
import ProgressTracker from '../../../Paths/CourseOverview/ProgressTracker';

const CurrentCourse = props => {
  const { progresses, currentProgressTitle, currentEnrollment } = props;

  if (!currentEnrollment) {
    return null;
  }

  return (
    <div className="my-current-course">
      <div className="current-course-header">
        <div className="current-course-label">My Current Course</div>
      </div>
      {currentProgressTitle && <div className="current-course-title">{currentProgressTitle}</div>}
      <div data-testid="current-course-progress">
        <ProgressTracker progresses={progresses} />
      </div>
    </div>
  );
};

export default CurrentCourse;
