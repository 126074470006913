import React from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import { LabDataProvider } from '../../../context/Lab/LabContext';

const LabWrapper = ({ app, isFinished, children }) => (
  <LabDataProvider app={app} isFinished={isFinished}>
    {children}
    <ReactTooltip effect="solid" event="mouseenter focusin" eventOff="mouseleave focusout click" className="tooltip" />
  </LabDataProvider>
);

export default LabWrapper;

LabWrapper.propTypes = {
  app: PropTypes.string,
  isFinished: PropTypes.bool,
  children: PropTypes.node,
};
