import { connect } from 'react-redux';
import Questions from './Questions';
import { selectQuestionProgressesForQuiz } from '../../selectors/questionProgressSelectors';
import { selectProgressForQuiz } from '../../selectors/quizProgressSelectors';
import { submitQuizProgress } from '../../actions/quizProgressActions';

const mapStateToProps = (state, ownProps) => {
  const { quiz } = ownProps;
  const questionProgresses = selectQuestionProgressesForQuiz(state, quiz.id);
  const quizProgress = selectProgressForQuiz(state, quiz);

  return {
    questionProgresses,
    quizProgress,
  };
};

export default connect(mapStateToProps, {
  submitQuizProgress,
})(Questions);
