import { COMPONENT_GRADE } from '../../actions/constants';

const INITIAL_STATE = {
  currentCohortId: '',
  currentStudentId: '',
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case COMPONENT_GRADE.SET_CURRENT_COHORT_ID: {
      const { currentCohortId } = action.payload;
      return {
        ...state,
        currentCohortId,
      };
    }

    case COMPONENT_GRADE.SET_CURRENT_STUDENT_ID: {
      const { currentStudentId } = action.payload;
      return {
        ...state,
        currentStudentId,
      };
    }

    default:
      return state;
  }
}
