import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';

import { selectIsEditMode } from '../../selectors/contextSelectors';
import Errors from '../Utils/Errors';
import showModal from '../../utils/showModal';
import EditRubricModal from '../Author/Projects/EditRubricModal';
import RubricModal from './RubricModal';
import { saveAssignment } from '../../actions/assignmentActions';
import { selectProgressForAssignment } from '../../selectors/assignmentProgressSelectors';
import useCurrentUser from '../../hooks/useCurrentUser';
import ViewGradeModal from './ViewGradeModal';
import { LabContext } from '../../context/Lab/LabContext';
import { DownloadProjectStarterFile } from '../Labs/DownloadStarterFile';

export default function ProjectInstructionsFooter({ submitLabel, onSubmit, errors, assignment }) {
  const isEditMode = useSelector(state => selectIsEditMode(state));

  const showRubrics = isEditMode || assignment.display_rubrics;

  const dispatch = useDispatch();
  const assignmentProgress = useSelector(state => selectProgressForAssignment(state, assignment.id));
  const { isStudent } = useCurrentUser();
  const shouldViewGrade = isStudent && assignmentProgress && assignmentProgress.graded_at;
  const [{ isReady }] = useContext(LabContext);

  const handleClick = () => {
    let Modal;
    if (isEditMode) {
      Modal = EditRubricModal;
    } else if (shouldViewGrade) {
      Modal = ViewGradeModal;
    } else {
      Modal = RubricModal;
    }

    showModal(Modal, {
      assignment,
      assignmentProgress,
      onSave: assn => saveAssignment(assn)(dispatch),
    });
  };

  return (
    <footer className="lab-exercise__footer">
      <Errors errors={errors} />
      <DownloadProjectStarterFile assignment={assignment} />
      <div className="lab-exercise__controls">
        {showRubrics && (
          <button
            type="button"
            className="lab-button lab-button--medium lab-button--clear lab-button--radius"
            onClick={handleClick}>
            <div className="lab-button__label">
              {shouldViewGrade ? (
                <FormattedMessage id="unit.viewGrade" />
              ) : (
                <FormattedMessage id="project.rubricCriteria" />
              )}
            </div>
          </button>
        )}
        {onSubmit && (
          <button
            type="button"
            className="lab-button lab-button--medium lab-button--blue lab-button--radius"
            disabled={!isReady}
            onClick={onSubmit}>
            <div className="lab-button__label">{submitLabel || <FormattedMessage id="common.submit" />}</div>
          </button>
        )}
      </div>
    </footer>
  );
}

ProjectInstructionsFooter.propTypes = {
  submitLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onSubmit: PropTypes.func,
  errors: PropTypes.array,
  assignment: PropTypes.object,
};
