import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { some as _some } from 'lodash';

const validateImages = content => {
  const doc = new DOMParser().parseFromString(content, 'text/html');
  const images = doc.querySelectorAll('img');

  return !_some(images, image => image.alt.trim() === '');
};

const ContentValidator = ({ content, children }) => {
  const hasInvalidImages = !validateImages(content);

  return (
    <div className={classnames({ 'invalid-images': hasInvalidImages })}>
      {children}

      {hasInvalidImages && (
        <div className="warning">
          <FormattedMessage id="block.images.altTextWarning" />
        </div>
      )}
    </div>
  );
};

ContentValidator.propTypes = {
  content: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default ContentValidator;
