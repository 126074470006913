export class ASTLinkedListNode {
  constructor(value) {
    this.value = value;
  }
}

export class ASTLinkedList {
  constructor() {
    this.size = 0;
  }

  addNode(newNode) {
    if (!this.head || !this.tail) {
      this.head = newNode;
      this.tail = newNode;
    } else {
      this.tail.next = newNode;
      newNode.prev = this.tail;
      this.tail = newNode;
    }
    this.size++;
  }

  removeNode(node) {
    if (!node) {
      throw new Error('wrong expression, removeNode undefined');
    }
    const { prev, next } = node;
    if (next) {
      next.prev = prev;
    } else {
      this.tail = prev;
    }
    if (prev) {
      prev.next = next;
    } else {
      this.head = next;
    }
    this.size--;
  }
}
