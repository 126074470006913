import React from 'react';
import PropTypes from 'prop-types';
import { Select } from 'antd';
const { Option } = Select;

const CustomSelect = ({ options, onSelect, placeholder, ...rest }) => (
  <Select allowClear onSelect={onSelect} className="custom-select" placeholder={placeholder} {...rest}>
    {options.map(({ value, label }) => (
      <Option key={value} value={value}>
        {label}
      </Option>
    ))}
  </Select>
);

CustomSelect.propTypes = {
  options: PropTypes.any,
  onSelect: PropTypes.func,
  placeholder: PropTypes.string,
};

export default CustomSelect;
