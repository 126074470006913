import { connect } from 'react-redux';
import Header from './Header';
import { toggleSideNav } from '../../../../actions/sideNavActions';
import { enableEditMode } from '../../../../actions/contextActions';
import { selectSideNavIsCollapsed } from '../../../../selectors/sideNavSelectors';
import { selectCurrentUser } from '../../../../selectors/authSelectors';
import { canLearn, canAuthor } from '../../../../helpers/userAuth';
import { selectIsEditMode } from '../../../../selectors/contextSelectors';

const mapStateToProps = state => ({
  isCollapsed: selectSideNavIsCollapsed(state),
  currentUser: selectCurrentUser(state),
  isStudent: canLearn(selectCurrentUser(state)),
  isAuthor: canAuthor(selectCurrentUser(state)),
  isEditMode: selectIsEditMode(state),
});

export default connect(mapStateToProps, { toggleSideNav, enableEditMode })(Header);
