import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

export default class SectionTileHeader extends Component {
  render() {
    const { index, section } = this.props;
    const headingClasses = classnames('standard-label');

    return (
      <div className="units-header">
        <div>
          <div className="units-header__number">{index + 1}</div>
        </div>

        <div className="units-header__label">
          <div className={headingClasses}>
            <h2>{section.title}</h2>
          </div>
        </div>

        {this.props.children}
      </div>
    );
  }
}
SectionTileHeader.propTypes = {
  section: PropTypes.object.isRequired,
  sectionProgress: PropTypes.object,
  index: PropTypes.number.isRequired,
  completedCounts: PropTypes.object,
};
