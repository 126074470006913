import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Button } from 'antd';
import { FormattedMessage } from 'react-intl';
import BackLink from '../../../../../common/components/BackLink/BackLink';

const InstructorGradingHeader = ({ totalGrade, canViewSummary, onProgressChange, showProgress, children }) => {
  const progressClasses = classnames('progress-state', (!totalGrade && 'not-graded') || 'graded');
  let currentProgress = 'skipToSummary';

  if (canViewSummary) {
    currentProgress = 'reviewSummary';
  } else if (totalGrade !== null && totalGrade >= 0) {
    currentProgress = 'nextCriterion';
  }

  return (
    <div className="grading-header-container">
      <div className="grading-rubric-header">{children}</div>
      {showProgress ? (
        <Button className={progressClasses} onClick={onProgressChange}>
          <FormattedMessage id={`grading.${currentProgress}`} />
        </Button>
      ) : null}
    </div>
  );
};

InstructorGradingHeader.Criterion = ({ criterion, goBackToPrev, showBackLink }) => (
  <div className="header-child criterion">
    {showBackLink && <BackLink onHandleClick={goBackToPrev} />}
    <span className="criterion-num">{`${criterion.id}.`}</span>
    <span className="criterion-name">{criterion.name}</span>
  </div>
);

InstructorGradingHeader.GradingSummary = ({ goBackToPrev }) => (
  <div className="header-child grading-summary">
    <BackLink onHandleClick={goBackToPrev} />
    <FormattedMessage id="grading.summary" />
  </div>
);

InstructorGradingHeader.propTypes = {
  children: PropTypes.any,
  totalGrade: PropTypes.number,
  canViewSummary: PropTypes.bool,
  onProgressChange: PropTypes.func,
  showProgress: PropTypes.bool,
};

InstructorGradingHeader.Criterion.propTypes = {
  criterion: PropTypes.object.isRequired,
  goBackToPrev: PropTypes.func,
  showBackLink: PropTypes.bool,
};

InstructorGradingHeader.GradingSummary.propTypes = {
  goBackToPrev: PropTypes.func,
};

export default InstructorGradingHeader;
