import React from 'react';
import classnames from 'classnames';
import { useHistory, useParams } from 'react-router-dom';
import { LeftOutlined } from '@ant-design/icons';

import EditBar from '../Components/EditBar';
import useCurrentGradedQuiz from '../../hooks/useCurrentGradedQuiz';
import useCurrentUser from '../../hooks/useCurrentUser';
import intl from '../../utils/intl';
import logo from '../../styles/v2/images/logo.png';
import { getCoursePathByIds } from '../../helpers/pathHelper';

export default function ShowHeader() {
  const { bootcampId, pathId } = useParams();
  const history = useHistory();
  const { unit } = useCurrentGradedQuiz();
  const { currentUser, canAuthor } = useCurrentUser();

  const backHandler = () => {
    history.push(getCoursePathByIds(currentUser, bootcampId, pathId));
  };

  return (
    <header className={classnames('header-bar', canAuthor && 'header-bar--edit-mode')}>
      <div className="header-bar__wrapper">
        <nav id="header-bar-module" className="header-bar__module">
          <div className="header-bar__menu">
            <button
              type="button"
              className="header-bar__menu-button"
              aria-label={intl.formatMessage({ id: 'common.back' })}
              onClick={backHandler}>
              <LeftOutlined />
            </button>
          </div>
          <div className="header-bar__summary">
            <div>
              <div className="header-bar__summary-heading"></div>
              <div className="header-bar__summary-title">{unit && unit.title}</div>
            </div>
          </div>

          <div className="header-bar__tabs-wrapper"></div>

          <img src={logo} alt="logo" className="pathstream-logo" />

          <EditBar />
        </nav>
      </div>
    </header>
  );
}
