import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { toast } from 'react-toastify';
import Modal2 from '../library/Modal2';
import Errors from '../Utils/Errors';
import CustomInput from '../Utils/CustomInput';
import intl from '../../utils/intl';
import Loading from '../Utils/Loading';

export default class ChangePasswordModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user: { ...props.user },
      errors: [],
    };
  }

  handleChange = e => {
    const { target } = e;
    const { name } = target;
    const { value } = target;

    this.setState({
      user: {
        ...this.state.user,
        [name]: value,
      },
    });
  };

  handleSubmit = e => {
    e.preventDefault();
    const { user } = this.state;
    this.setState({ isSaving: true, errors: null });

    if (!user.current_password) {
      this.setState({
        isSaving: false,
        errors: [<FormattedMessage id="user.setPassword.errors.currentPasswordRequired" />],
      });
      return;
    }

    if (user.current_password === user.password) {
      this.setState({ errors: [<FormattedMessage id="user.setPassword.errors.passwordSame" />] });
      return;
    }

    if (!user.password || !user.password_confirmation) {
      this.setState({ isSaving: false, errors: [<FormattedMessage id="user.setPassword.errors.passwordRequired" />] });
      return;
    }

    if (user.password !== user.password_confirmation) {
      this.setState({ isSaving: false, errors: [<FormattedMessage id="user.setPassword.errors.passwordMismatch" />] });
      return;
    }

    this.props
      .saveProfile(user)
      .then(() => {
        toast(<FormattedMessage id="profile.passwordChanged" />, { type: toast.TYPE.SUCCESS });
        this.setState({ isSaving: false });
        this.handleCancel();
      })
      .catch(err => {
        this.setState({ errors: err.errors, isSaving: false });
      });
  };

  handleCancel = e => {
    this.props.onHide();
  };

  render() {
    const { user, errors, isSaving } = this.state;

    return (
      <Modal2
        size="small"
        title={<FormattedMessage id="profile.changePassword" />}
        onHide={this.props.onHide}
        onOk={this.handleSubmit}>
        <Errors errors={errors} />
        {isSaving && <Loading showMessage={false} />}

        <CustomInput
          name="current_password"
          type="password"
          value={user.current_password}
          label={intl.formatMessage({ id: 'user.form.currentPassword' })}
          autoFocus
          autoComplete="off"
          onChange={this.handleChange}
        />

        <CustomInput
          name="password"
          type="password"
          onChange={this.handleChange}
          value={user.password}
          autoComplete="off"
          label={intl.formatMessage({ id: 'user.form.password' })}
        />

        <CustomInput
          name="password_confirmation"
          type="password"
          onChange={this.handleChange}
          value={user.password_confirmation}
          label={intl.formatMessage({ id: 'user.form.confirmPassword' })}
        />
      </Modal2>
    );
  }
}
ChangePasswordModal.propTypes = {
  user: PropTypes.object.isRequired,
  saveProfile: PropTypes.func.isRequired,
};
