import { connect } from 'react-redux';
import GradeCohortAssignments from './GradeCohortAssignments';
import { allowResubmission as allowProjectResubmission } from '../../../actions/unitProgressActions';
import { allowResubmission as allowAssignmentResubmission } from '../../../actions/assignmentProgressActions';
import { fetchGradedItems } from '../../../actions/gradedItemActions';
import { allowResubmission as allowQuizResubmission } from '../../../actions/quizProgressActions';

const mapStateToProps = (state, ownProps) => {
  const { cohort } = ownProps;
  return {
    cohort,
  };
};

export default connect(mapStateToProps, {
  allowProjectResubmission,
  allowAssignmentResubmission,
  allowQuizResubmission,
  fetchGradedItems,
})(GradeCohortAssignments);
