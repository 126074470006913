import React from 'react';
import PropTypes from 'prop-types';

import MetricCard from './MetricCard';
import Icons from '../../../../../Utils/Icons';
import intl from '../../../../../../utils/intl';

const CompletedLessonsCard = ({ lessonsCompletedPercent }) => {
  const lessonsCompletedText = lessonsCompletedPercent
    ? `${lessonsCompletedPercent}%`
    : intl.formatMessage({ id: 'cohortDashboard.studentsPage.notAvailable' });

  const metric = {
    icon: <Icons.LessonsCompleted />,
    metricKey: intl.formatMessage({ id: 'cohortDashboard.studentsPage.lessonsCompleted' }),
    metricValue: lessonsCompletedText,
  };

  return <MetricCard {...metric} />;
};

CompletedLessonsCard.propTypes = {
  lessonsCompletedPercent: PropTypes.number.isRequired,
};

export default CompletedLessonsCard;
