import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Link, useParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import Icons from '../Utils/Icons';
import { formatDuration } from '../../utils/utils';
import { getCoursePathByIds } from '../../helpers/pathHelper';
import useCurrentUser from '../../hooks/useCurrentUser';
import { ClockCircleOutlined } from '@ant-design/icons';

/* eslint-disable complexity */
export default function ComponentTab({ isComplete, hasLab, isCurrent, component }) {
  const {
    bootcampId: currentBootcampId,
    pathId: currentPathId,
    sectionId: currentSectionId,
    unitId: currentUnitId,
  } = useParams();
  const { currentUser } = useCurrentUser();

  const isIncomplete = !isComplete && !isCurrent;

  const baseUrl = `${getCoursePathByIds(
    currentUser,
    currentBootcampId,
    currentPathId,
  )}/sections/${currentSectionId}/units/${currentUnitId}`;

  return (
    <li
      key={component.id}
      className={classnames(
        'header-bar__tab',
        isComplete && 'header-bar__tab--finished',
        isCurrent && 'header-bar__tab--active',
        isIncomplete && 'header-bar__tab--normal',
      )}>
      <Link
        to={`${baseUrl}/components/${component.id}`}
        data-tip={component.title}
        className="header-bar__tab-link"
        role="tab"
        aria-label={
          isComplete
            ? `tab link for completed assignment ${component.title}`
            : `tab link for assignment ${component.title}`
        }
        aria-selected={isCurrent}>
        <span className="header-bar__tab-heading">
          {isComplete && (
            <>
              <Icons.CircleCheckChecked />
              <FormattedMessage id="status.complete" />
            </>
          )}
          {!isComplete && isCurrent && <FormattedMessage id="component.currentLesson" />}
          {isIncomplete && (
            <>
              <ClockCircleOutlined />
              <span className="time">
                <span className="visually-hidden">
                  <FormattedMessage id="common.estimatedTime" />
                  {formatDuration(component.duration, true)}
                </span>
                <span aria-hidden>{formatDuration(component.duration)}</span>
              </span>
            </>
          )}
        </span>
        <span className="header-bar__tab-title">
          {hasLab && <Icons.Elixir />}
          {component.title}
        </span>
      </Link>
    </li>
  );
}
/* eslint-enable complexity */

ComponentTab.propTypes = {
  isComplete: PropTypes.bool,
  hasLab: PropTypes.bool,
  isCurrent: PropTypes.bool,
  component: PropTypes.object,
};
