import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import BootcampListItem from './BootcampListItem';
import { selectEnrollmentForBootcamp } from '../../selectors/enrollmentSelectors';
import { selectCohortForEnrollment } from '../../selectors/cohortSelectors';
import { canLearn, canCoach, canAuthor } from '../../helpers/userAuth';
import { selectCurrentUser } from '../../selectors/authSelectors';
import { selectIsEditMode } from '../../selectors/contextSelectors';
import { selectBootcampPaths } from '../../selectors/bootcampSelectors';
import { selectProgressForPath, selectProgressToResume } from '../../selectors/pathProgressSelectors';
import { getPathPrefix } from '../../helpers/pathHelper';
import { fetchEnrollmentForCohort } from '../../actions/enrollmentActions';
import { selectFirstComponent } from '../../selectors/cohortBootcampSelectors';

const mapStateToProps = (state, ownProps) => {
  const { bootcamp } = ownProps;
  const enrollment = selectEnrollmentForBootcamp(state, bootcamp.id);
  const cohort = ownProps.cohort || selectCohortForEnrollment(state, enrollment && enrollment.id);
  const currentUser = selectCurrentUser(state);
  const isStudent = canLearn(currentUser);
  const isAuthor = canAuthor(currentUser);
  const isCoach = canCoach(currentUser);
  const isEditMode = selectIsEditMode(state);
  const paths = selectBootcampPaths(state, bootcamp.id);
  const path = selectBootcampPaths(state, bootcamp.id)[0];
  const pathProgress = path && selectProgressForPath(state, path.id);
  const currentProgress =
    (pathProgress && selectProgressToResume(state, pathProgress.id)) ||
    selectFirstComponent(state, bootcamp.id, cohort && cohort.id);
  const pathPrefix = getPathPrefix(currentUser);

  let resumeUrl;
  if (currentProgress) {
    resumeUrl = `${pathPrefix}/bootcamps/${bootcamp.id}/paths/${currentProgress.pathId}/sections/${currentProgress.sectionId}`;
    if (currentProgress.componentId) {
      resumeUrl += `/units/${currentProgress.unitId}/components/${currentProgress.componentId}`;
    } else {
      resumeUrl += `/projects/${currentProgress.unitId}`;
    }
  }

  return {
    bootcamp,
    enrollment,
    paths,
    cohort,
    isStudent,
    isEditMode,
    isAuthor,
    isCoach,
    currentUser,
    resumeUrl,
    currentComponent: currentProgress && (currentProgress.component || currentProgress.unit),
  };
};

export default withRouter(connect(mapStateToProps, { fetchEnrollmentForCohort })(BootcampListItem));
