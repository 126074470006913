import React from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import RTEContent from '../../../helpers/contentMathjaxHelper';
import { selectCurrentUser } from '../../../selectors/authSelectors';
import useCurrentEnrollment from '../../../hooks/useCurrentEnrollment';
import { Embed } from './Embed';

const useTypeformHiddenParams = block => {
  const currentUser = useSelector(selectCurrentUser);
  const { enrollment, cohort } = useCurrentEnrollment();
  const params = useParams();

  const hiddenParams = {
    userId: currentUser?.id,
    courseId: params.bootcampId,
    lessonId: block.lesson_id,
    cohortId: cohort?.id,
    enrollmentId: enrollment?.id,
  };

  return hiddenParams;
};

const queryStringFor = params => {
  const pairs = Object.entries(params).filter(([_key, val]) => val !== undefined);
  const queryString = new URLSearchParams(pairs).toString();
  return queryString ? `?${queryString}` : '';
};

export const Show = ({ block }) => {
  const embedParams = useTypeformHiddenParams(block);

  return (
    <div className="section">
      <RTEContent content={block.title} Tag="h2" />
      <RTEContent content={block.content} />
      <div className={`typeform-survey-container__${block.height}`}>
        <Embed uniqueId={block.id} typeformUrl={`${block.url}${queryStringFor(embedParams)}`} />
      </div>
    </div>
  );
};
