import Question from '../api/question.js';
import intl from '../utils/intl';
import uuid from 'uuid/v4';
import { shuffle as _shuffle, cloneDeep as _cloneDeep } from 'lodash';

export const NEW_QUESTION = {
  [Question.FREE_TEXT_ANSWER]: {
    question_type: 'free_text_answer',
    prompt: intl.formatMessage({ id: 'question.defaultPrompt' }),
    randomize_choices: true,
  },
  [Question.SINGLE_SELECT]: {
    question_type: 'single_select',
    prompt: intl.formatMessage({ id: 'question.defaultPrompt' }),
    randomize_choices: true,
    choices: [
      { id: uuid(), prompt: intl.formatMessage({ id: 'question.defaultAnswer' }, { num: 1 }), is_correct: true },
      { id: uuid(), prompt: intl.formatMessage({ id: 'question.defaultAnswer' }, { num: 2 }), is_correct: false },
    ],
  },
  [Question.MULTI_SELECT]: {
    question_type: 'multi_select',
    prompt: intl.formatMessage({ id: 'question.defaultPrompt' }),
    randomize_choices: true,
    choices: [
      { id: uuid(), prompt: intl.formatMessage({ id: 'question.defaultAnswer' }, { num: 1 }), is_correct: true },
      { id: uuid(), prompt: intl.formatMessage({ id: 'question.defaultAnswer' }, { num: 2 }), is_correct: false },
    ],
  },
  [Question.SELF_REFLECTION]: {
    question_type: 'self_reflection',
    prompt: intl.formatMessage({ id: 'question.defaultPrompt' }),
    explanation: intl.formatMessage({ id: 'question.defaultExplanation' }),
    randomize_choices: true,
    choices: [
      { id: uuid(), prompt: intl.formatMessage({ id: 'question.defaultAnswer' }, { num: 1 }) },
      { id: uuid(), prompt: intl.formatMessage({ id: 'question.defaultAnswer' }, { num: 2 }) },
    ],
  },
};

export const isSubmitted = questionProgress => {
  return questionProgress && questionProgress.completed_at;
};

export const findChoice = (userChoice, choices) => choices.find(choice => choice.id === userChoice.id);

export const buildSelectableChoices = (choices, useSaved = false) =>
  choices.map(choice => ({
    id: choice.id,
    prompt: choice.prompt,
    is_selected: useSaved ? !!choice.is_selected : false,
  }));

export const getChoicesFromProgress = (questionProgress, question) => {
  const choices = _cloneDeep(question.choices);
  return questionProgress.choices.map(choice => {
    const userChoice = findChoice(choice, choices);
    userChoice.is_selected = choice.is_selected;
    return userChoice;
  });
};

export const organizeUserChoices = (question, choices = question.choices) => {
  return question.randomize_choices ? _shuffle(choices) : choices;
};
