import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import StandardCardWrapper from './StandardCardWrapper';
import moment from 'moment';

const CohortCompletedCard = ({ cohort }) => {
  const completedDate = moment(cohort.end_date).format('MMM D, YYYY');

  return (
    <div className="cohort-card" data-testid="cohort-completed-card">
      <StandardCardWrapper cohort={cohort}>
        <div className="cohort-completed-card-body">
          <div className="text">
            <FormattedMessage id="cohort.courseCompletedOn" values={{ date: completedDate }} />
          </div>
        </div>
      </StandardCardWrapper>
    </div>
  );
};

CohortCompletedCard.propTypes = {
  cohort: PropTypes.object.isRequired,
};

export default CohortCompletedCard;
