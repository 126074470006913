import { isEmpty as _isEmpty } from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Loading from '../Utils/Loading';
import { openLab } from '../../helpers/labHelper';
import Icons from '../Utils/Icons';
import { contentForStudent } from '../../helpers/blockHelper';
import { isMobileDevice } from '../../utils/utils';
import { SoftBlockModal, getProjectsWorkBestOnDesktopModalProps } from '../SoftBlockModal/SoftBlockModal';

export default class BlockLabShow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileSoftBlockOpen: false,
    };
  }

  componentDidMount() {
    const { tutorialId, tutorial, fetchTutorial, appStreamAppSites, fetchAppStreamAppSites } = this.props;

    if (!tutorial && tutorialId) {
      fetchTutorial(tutorialId);
    }

    if (!appStreamAppSites || !appStreamAppSites.length) {
      fetchAppStreamAppSites();
    }
  }

  handleLaunch = async (dismissMobileSoftBlock = false) => {
    if (isMobileDevice() && !dismissMobileSoftBlock) {
      return this.setState({ mobileSoftBlockOpen: true });
    }

    const { match, history, block, tutorial } = this.props;
    const url = `${match.url}/blocks/${block.id}`;

    await openLab({
      launchIn: tutorial.launch_in,
      tenantId: tutorial.tenant_id,
      labUrl: url,
      history,
    });
  };

  handleDismissMobileSoftBlock = () => {
    this.setState({ mobileSoftBlockOpen: false });
    this.handleLaunch(true);
  };

  render() {
    const { tutorial, block } = this.props;
    const { mobileSoftBlockOpen } = this.state;

    if (!tutorial) {
      return <Loading />;
    }

    return (
      <>
        {mobileSoftBlockOpen && (
          <SoftBlockModal
            {...getProjectsWorkBestOnDesktopModalProps()}
            onCancel={() => this.setState({ mobileSoftBlockOpen: false })}
            onPrimaryButton={this.handleDismissMobileSoftBlock}
            onSecondaryButton={() => this.setState({ mobileSoftBlockOpen: false })}
          />
        )}
        <div className="section">
          <div className="block-label">
            <div className="block-label__content">
              <div className="knowledge-check-wrapper">
                {!_isEmpty(tutorial.title) && (
                  <div className="knowledge-check__header">
                    <Icons.ElixirBig />
                    <h2 className="heading" dangerouslySetInnerHTML={{ __html: tutorial.title }} />
                  </div>
                )}
                <div className="knowledge-check__body">
                  <div dangerouslySetInnerHTML={{ __html: contentForStudent(block.content) }} />
                  <div className="launch__wrapper">
                    <Icons.LabBlockWrapper />
                    <button type="button" className="launch" onClick={() => this.handleLaunch()}>
                      <FormattedMessage id="common.launchLab" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

BlockLabShow.propTypes = {
  block: PropTypes.object.isRequired,
  tutorial: PropTypes.object,
  tutorialId: PropTypes.string,
  fetchTutorial: PropTypes.func.isRequired,
  appStreamAppSites: PropTypes.array.isRequired,
  fetchAppStreamAppSites: PropTypes.func.isRequired,
};
