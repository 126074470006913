import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { isLoggedIn, ping } from '../actions/authActions';
import { fetchCurrentTenant } from '../actions/tenantActions';
import App from './App';
import { selectCurrentUser } from '../selectors/authSelectors';

const mapStateToProps = state => {
  const currentUser = selectCurrentUser(state);

  return {
    currentUser,
  };
};

export default withRouter(connect(mapStateToProps, { isLoggedIn, fetchCurrentTenant, ping })(App));
