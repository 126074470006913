/* eslint-disable max-lines */
export default [
  {
    id: '75149172-e051-4e9c-823c-392f60f1ecaa',
    assessment: [
      {
        type: 'ruleset',
        group: [
          {
            type: 'ruleset criterion',
            criteria: [
              {
                type: 'rule',
                check: [
                  {
                    type: 'check',
                    name: 'Acquisition: Total users value is 965',
                    property: 'value',
                    operator: '==',
                    value: '965',
                    fieldKey: 'aec11012-fdd1-47a7-999e-85add18df0a3',
                    location: [
                      {
                        within: 'worksheet',
                        dataSource: {
                          type: 'byName',
                          name: 'Acquisition',
                        },
                      },
                      {
                        within: 'cell',
                        dataSource: {
                          type: 'cellAddress',
                          name: 'A:12',
                          cellAddress: 'A:12',
                        },
                      },
                    ],
                    dataSource: ['cellAddress'],
                    names: ['Acquisition'],
                    cellAddress: 'A:12',
                  },
                ],
                name: 'Aquisition: Total users',
                description: '',
                logicFormula: '',
                points: '1',
                resubmitOnError: false,
                fieldKey: '1e545754-1533-41af-8198-2c8eec8a00be',
              },
              {
                type: 'rule',
                check: [
                  {
                    type: 'check',
                    name: 'Acquisition: Total new users is 844',
                    location: [
                      {
                        within: 'worksheet',
                        dataSource: {
                          type: 'byName',
                          name: 'Acquisition',
                        },
                      },
                      {
                        within: 'cell',
                        dataSource: {
                          type: 'cellAddress',
                          name: 'B:12',
                          cellAddress: 'B:12',
                        },
                      },
                    ],
                    dataSource: ['cellAddress'],
                    property: 'value',
                    operator: '==',
                    value: '844',
                    fieldKey: 'ef292450-8408-4144-9b19-3adecca17a98',
                    names: ['Acquisition'],
                    cellAddress: 'B:12',
                  },
                ],
                name: 'Acquisition: Total new users',
                description: '',
                logicFormula: '',
                points: '1',
                resubmitOnError: false,
                fieldKey: '71705141-d358-44f4-8ddc-790dbbefc26c',
              },
              {
                type: 'rule',
                check: [
                  {
                    type: 'check',
                    name: 'Acquisition: Total sessions is 1089',
                    property: 'value',
                    operator: '==',
                    value: '1089',
                    fieldKey: 'f9e121a2-7600-4da1-8cc1-8faebd0af1d1',
                    location: [
                      {
                        within: 'worksheet',
                        dataSource: {
                          type: 'byName',
                          name: 'Acquisition',
                        },
                      },
                      {
                        within: 'cell',
                        dataSource: {
                          type: 'cellAddress',
                          name: 'C:12',
                          cellAddress: 'C:12',
                        },
                      },
                    ],
                    dataSource: ['cellAddress'],
                    names: ['Acquisition'],
                    cellAddress: 'C:12',
                  },
                ],
                name: 'Acquisition: Total sessions',
                description: '',
                logicFormula: '',
                points: '1',
                resubmitOnError: false,
                fieldKey: 'ace55842-ee93-48f7-a3f3-2082281dc21a',
              },
              {
                type: 'rule',
                check: [
                  {
                    type: 'check',
                    name: 'Acquisition: Average bounce rate is 50.68%',
                    location: [
                      {
                        within: 'worksheet',
                        dataSource: {
                          type: 'byName',
                          name: 'Acquisition',
                        },
                      },
                      {
                        within: 'cell',
                        dataSource: {
                          type: 'cellAddress',
                          name: 'D:12',
                          cellAddress: 'D:12',
                        },
                      },
                    ],
                    dataSource: ['byName', 'cellAddress'],
                    property: 'value',
                    operator: '==',
                    value: '50.68%',
                    fieldKey: 'b4ac34b5-f1e1-4eed-b3dd-9243847d86aa',
                  },
                ],
                name: 'Acquisition: Average bounce rate',
                description: '',
                logicFormula: '',
                points: '1',
                resubmitOnError: false,
                fieldKey: 'e3ac3e13-1ce7-4a6e-ad24-d0025fefa3c1',
              },
              {
                type: 'rule',
                check: [
                  {
                    type: 'check',
                    name: 'Acquisition: Highest bounce rate is 74.53%',
                    property: 'value',
                    operator: '==',
                    value: '74.53%',
                    fieldKey: '267130fe-728f-480d-8044-ae44a4683c74',
                    location: [
                      {
                        within: 'worksheet',
                        dataSource: {
                          type: 'byName',
                          name: 'Acquisition',
                        },
                      },
                      {
                        within: 'cell',
                        dataSource: {
                          type: 'cellAddress',
                          name: 'D:16',
                          cellAddress: 'D:16',
                        },
                      },
                    ],
                    dataSource: ['cellAddress'],
                    names: ['Acquisition'],
                    cellAddress: 'D:16',
                  },
                ],
                name: 'Acquisition: Highest bounce rate',
                description: '',
                logicFormula: '',
                points: '1',
                resubmitOnError: false,
                fieldKey: '74204347-0c67-4773-b489-cc7150b18eea',
              },
              {
                type: 'rule',
                check: [
                  {
                    type: 'check',
                    name: 'Acquisition: Lowest bounce rate is 35.96%',
                    property: 'value',
                    operator: '==',
                    value: '35.96%',
                    fieldKey: '8d70c26c-b979-42c6-8d49-4a2b737125eb',
                    location: [
                      {
                        within: 'worksheet',
                        dataSource: {
                          type: 'byName',
                          name: 'Acquisition',
                        },
                      },
                      {
                        within: 'cell',
                        dataSource: {
                          type: 'cellAddress',
                          name: 'D:14',
                          cellAddress: 'D:14',
                        },
                      },
                    ],
                    dataSource: ['cellAddress'],
                    names: ['Acquisition'],
                    cellAddress: 'D:14',
                  },
                ],
                name: 'Acquisition: Lowest bounce rate',
                description: '',
                logicFormula: '',
                points: '1',
                resubmitOnError: false,
                fieldKey: 'a0f83279-03ae-4537-b471-1401e0e9a850',
              },
              {
                type: 'rule',
                check: [
                  {
                    type: 'check',
                    name: 'Acquisition: Most pages/session is 5.85',
                    property: 'value',
                    operator: '==',
                    value: '5.85',
                    fieldKey: '20dcda8c-0a7f-45b4-9b99-7d7114e92730',
                    location: [
                      {
                        within: 'worksheet',
                        dataSource: {
                          type: 'byName',
                          name: 'Acquisition',
                        },
                      },
                      {
                        within: 'cell',
                        dataSource: {
                          type: 'cellAddress',
                          name: 'E:12',
                          cellAddress: 'E:12',
                        },
                      },
                    ],
                    dataSource: ['cellAddress'],
                    names: ['Acquisition'],
                    cellAddress: 'E:12',
                  },
                ],
                name: 'Acquisition: Most pages/session',
                description: '',
                logicFormula: '',
                points: '1',
                resubmitOnError: false,
                fieldKey: 'ac760e1b-6c03-4817-89d5-8ac8d9b5fdf3',
              },
              {
                type: 'rule',
                check: [
                  {
                    type: 'check',
                    name: 'Acquisition: Overall session duration average is 0:02:50',
                    property: 'value',
                    operator: '==',
                    value: '0:02:50',
                    fieldKey: 'e6281692-689d-4424-b27c-03c5e1674682',
                    location: [
                      {
                        within: 'worksheet',
                        dataSource: {
                          type: 'byName',
                          name: 'Acquisition',
                        },
                      },
                      {
                        within: 'cell',
                        dataSource: {
                          type: 'cellAddress',
                          name: 'F:12',
                          cellAddress: 'F:12',
                        },
                      },
                    ],
                    dataSource: ['cellAddress'],
                    names: ['Acquisition'],
                    cellAddress: 'F:12',
                  },
                ],
                name: 'Acquisition: Overall session duration average',
                description: '',
                logicFormula: '',
                points: '1',
                resubmitOnError: false,
                fieldKey: 'aeb682a2-1a51-4619-b110-35e47351f9c8',
              },
              {
                type: 'rule',
                check: [
                  {
                    type: 'check',
                    name: 'Acquisition: Total transaction is 21',
                    property: 'value',
                    operator: '==',
                    value: '21',
                    fieldKey: '2b058364-2f31-4b94-8a61-5d5cfdf70379',
                    location: [
                      {
                        within: 'worksheet',
                        dataSource: {
                          type: 'byName',
                          name: 'Acquisition',
                        },
                      },
                      {
                        within: 'cell',
                        dataSource: {
                          type: 'cellAddress',
                          name: 'G:12',
                          cellAddress: 'G:12',
                        },
                      },
                    ],
                    dataSource: ['cellAddress'],
                    names: ['Acquisition'],
                    cellAddress: 'G:12',
                  },
                ],
                name: 'Acquisition: Total transactions',
                description: '',
                logicFormula: '',
                points: '1',
                resubmitOnError: false,
                fieldKey: '6e6a4583-b007-4b89-9d79-2b5f1d73bc83',
              },
              {
                type: 'rule',
                check: [
                  {
                    type: 'check',
                    name: 'Acquisition: Total revenue is: $202.29\t',
                    property: 'value',
                    operator: '==',
                    value: '$202.29',
                    fieldKey: '45d4465e-5fde-4fce-8bdf-b154ebf9d6b2',
                    location: [
                      {
                        within: 'worksheet',
                        dataSource: {
                          type: 'byName',
                          name: 'Acquisition',
                        },
                      },
                      {
                        within: 'cell',
                        dataSource: {
                          type: 'cellAddress',
                          name: 'H:12',
                          cellAddress: 'H:12',
                        },
                      },
                    ],
                    dataSource: ['cellAddress'],
                    names: ['Acquisition'],
                    cellAddress: 'H:12',
                  },
                ],
                name: 'Acquisition: Total revenue',
                description: '',
                logicFormula: '',
                points: '1',
                resubmitOnError: false,
                fieldKey: 'd06fa974-714e-43b8-99c3-d88f13e87887',
              },
            ],
            name: 'Analyzing the Acquisition Report',
            fieldKey: '14589cc4-7eab-48e9-853a-f65840bdfb07',
          },
          {
            type: 'ruleset criterion',
            criteria: [
              {
                type: 'rule',
                check: [
                  {
                    type: 'check',
                    name: "SHOW - What's the difference between Users, New Users, and Sessions?\t\t\t\t",
                    property: '',
                    operator: '',
                    value: '',
                    fieldKey: '1597b64c-e615-42a5-81d1-d52dbe5adbd5',
                    location: [
                      {
                        within: 'worksheet',
                        dataSource: {
                          type: 'byName',
                          name: 'Acquisition',
                        },
                      },
                      {
                        within: 'cell',
                        dataSource: {
                          type: 'cellAddress',
                          name: 'A:19',
                          cellAddress: 'A:19',
                        },
                      },
                    ],
                    dataSource: ['cellAddress'],
                    names: ['Acquisition'],
                    cellAddress: 'A:19',
                  },
                ],
                name: "What's the difference between Users, New Users, and Sessions?\t\t\t\t\t\t\t\t",
                description: '',
                logicFormula: '',
                points: '1',
                resubmitOnError: false,
                fieldKey: '85b24cfa-d8e4-466d-a254-822b4c99271a',
              },
              {
                type: 'rule',
                check: [
                  {
                    type: 'check',
                    name:
                      'SHOW - I don’t understand the term bounce rate. What does it mean, and why should I pay attention to it?',
                    location: [
                      {
                        within: 'worksheet',
                        dataSource: {
                          type: 'byName',
                          name: 'Acquisition',
                        },
                      },
                      {
                        within: 'cell',
                        dataSource: {
                          type: 'cellAddress',
                          name: 'A:21',
                          cellAddress: 'A:21',
                        },
                      },
                    ],
                    dataSource: ['byName', 'cellAddress'],
                    property: '',
                    operator: '',
                    value: '',
                    fieldKey: '6f7c26e1-9ff2-4238-8f8c-78ac441a87d9',
                  },
                ],
                name:
                  'I don’t understand the term bounce rate. What does it mean, and why should I pay attention to it?',
                description: '',
                logicFormula: '',
                points: '1',
                resubmitOnError: false,
                fieldKey: '6f18ea63-5bde-4af4-a5cb-76ad47a0dde9',
              },
              {
                type: 'rule',
                check: [
                  {
                    type: 'check',
                    name:
                      'SHOW - Which channel has the best engagement? Which channel has the worst engagement? Explain your answers.',
                    location: [
                      {
                        within: 'worksheet',
                        dataSource: {
                          type: 'byName',
                          name: 'Acquisition',
                        },
                      },
                      {
                        within: 'cell',
                        dataSource: {
                          type: 'cellAddress',
                          name: 'A:23',
                          cellAddress: 'A:23',
                        },
                      },
                    ],
                    dataSource: ['byName', 'cellAddress'],
                    property: '',
                    operator: '',
                    value: '',
                    fieldKey: '73f4caa2-7d3d-43cf-ac5d-13bec6fa0a76',
                  },
                ],
                name:
                  'Which channel has the best engagement? Which channel has the worst engagement? Explain your answers.',
                description: '',
                logicFormula: '',
                points: '1',
                resubmitOnError: false,
                fieldKey: 'c5b10cc4-29cb-4b82-9fff-0bb4e29f22bf',
              },
            ],
            name: 'Acquisition Report Reflection Questions',
            fieldKey: 'd49638fd-cabb-4592-83ad-3a945b23b6c8',
          },
          {
            type: 'ruleset criterion',
            criteria: [
              {
                type: 'rule',
                check: [
                  {
                    type: 'check',
                    name: 'Email 1 - Received is 192',
                    property: 'value',
                    operator: '==',
                    value: '91',
                    fieldKey: '75ae9b37-0d3d-4704-b747-f6faf37d5fac',
                    location: [
                      {
                        within: 'worksheet',
                        dataSource: {
                          type: 'byName',
                          name: 'Emails',
                        },
                      },
                      {
                        within: 'cell',
                        dataSource: {
                          type: 'cellAddress',
                          name: 'O:4',
                          cellAddress: 'O:4',
                        },
                      },
                    ],
                    dataSource: ['cellAddress'],
                    names: ['Emails'],
                    cellAddress: 'O:4',
                  },
                  {
                    type: 'check',
                    name: 'Email 2 - Received is 200',
                    property: 'value',
                    operator: '==',
                    value: '200',
                    fieldKey: 'b8afdc1f-a694-4672-9f75-3ba3601e671b',
                    location: [
                      {
                        within: 'worksheet',
                        dataSource: {
                          type: 'byName',
                          name: 'Emails',
                        },
                      },
                      {
                        within: 'cell',
                        dataSource: {
                          type: 'cellAddress',
                          name: 'O:8',
                          cellAddress: 'O:8',
                        },
                      },
                    ],
                    dataSource: ['cellAddress'],
                    names: ['Emails'],
                    cellAddress: 'O:8',
                  },
                  {
                    type: 'check',
                    name: 'Email 3 - Received is 210',
                    property: 'value',
                    operator: '==',
                    value: '210',
                    fieldKey: '1d52f50d-1e86-4a71-b695-0bcd3b731312',
                    location: [
                      {
                        within: 'worksheet',
                        dataSource: {
                          type: 'byName',
                          name: 'Emails',
                        },
                      },
                      {
                        within: 'cell',
                        dataSource: {
                          type: 'cellAddress',
                          name: 'O:12',
                          cellAddress: 'O:12',
                        },
                      },
                    ],
                    dataSource: ['cellAddress'],
                    names: ['Emails'],
                    cellAddress: 'O:12',
                  },
                  {
                    type: 'check',
                    name: 'Email 4 - Received is 228',
                    property: 'value',
                    operator: '==',
                    value: '228',
                    fieldKey: '89690e62-3ea1-466c-844a-62322bf7c292',
                    location: [
                      {
                        within: 'worksheet',
                        dataSource: {
                          type: 'byName',
                          name: 'Emails',
                        },
                      },
                      {
                        within: 'cell',
                        dataSource: {
                          type: 'cellAddress',
                          name: 'O:16',
                          cellAddress: 'O:16',
                        },
                      },
                    ],
                    dataSource: ['cellAddress'],
                    names: ['Emails'],
                    cellAddress: 'O:16',
                  },
                  {
                    type: 'check',
                    name: 'Summary - Received is 830',
                    property: 'value',
                    operator: '==',
                    value: '830',
                    fieldKey: '11500843-7528-439f-9f40-f7fe67726f25',
                    location: [
                      {
                        within: 'worksheet',
                        dataSource: {
                          type: 'byName',
                          name: 'Emails',
                        },
                      },
                      {
                        within: 'cell',
                        dataSource: {
                          type: 'cellAddress',
                          name: 'O:20',
                          cellAddress: 'O:20',
                        },
                      },
                    ],
                    dataSource: ['cellAddress'],
                    names: ['Emails'],
                    cellAddress: 'O:20',
                  },
                ],
                name: 'Emails: Received',
                description: '',
                logicFormula: '',
                points: '1',
                resubmitOnError: false,
                fieldKey: '76555d78-cc68-461b-994b-7a4111f7f377',
              },
              {
                type: 'rule',
                check: [
                  {
                    type: 'check',
                    name: 'Email 1 - Opened is 41',
                    property: 'value',
                    operator: '==',
                    value: '41',
                    fieldKey: '9630bde5-5e8f-4de3-a788-a4da2ac6241e',
                    location: [
                      {
                        within: 'worksheet',
                        dataSource: {
                          type: 'byName',
                          name: 'Emails',
                        },
                      },
                      {
                        within: 'cell',
                        dataSource: {
                          type: 'cellAddress',
                          name: 'P:4',
                          cellAddress: 'P:4',
                        },
                      },
                    ],
                    dataSource: ['cellAddress'],
                    names: ['Emails'],
                    cellAddress: 'P:4',
                  },
                  {
                    type: 'check',
                    name: 'Email 2 - Opened is 48',
                    property: 'value',
                    operator: '==',
                    value: '48',
                    fieldKey: 'ca65db0c-9849-4c7b-8b35-c02b65ddb3c7',
                    location: [
                      {
                        within: 'worksheet',
                        dataSource: {
                          type: 'byName',
                          name: 'Emails',
                        },
                      },
                      {
                        within: 'cell',
                        dataSource: {
                          type: 'cellAddress',
                          name: 'P:8',
                          cellAddress: 'P:8',
                        },
                      },
                    ],
                    dataSource: ['cellAddress'],
                    names: ['Emails'],
                    cellAddress: 'P:8',
                  },
                  {
                    type: 'check',
                    name: 'Email 3 - Opened is 48',
                    location: [
                      {
                        within: 'worksheet',
                        dataSource: {
                          type: 'byName',
                          name: 'Emails',
                        },
                      },
                      {
                        within: 'cell',
                        dataSource: {
                          type: 'cellAddress',
                          name: 'P:12',
                          cellAddress: 'P:12',
                        },
                      },
                    ],
                    dataSource: ['cellAddress'],
                    property: 'value',
                    operator: '==',
                    value: '48',
                    fieldKey: 'e9388047-bbd3-4628-bb09-e841e1c767eb',
                    names: ['Emails'],
                    cellAddress: 'P:12',
                  },
                  {
                    type: 'check',
                    name: 'Email 4 - Opened is 41',
                    location: [
                      {
                        within: 'worksheet',
                        dataSource: {
                          type: 'byName',
                          name: 'Emails',
                        },
                      },
                      {
                        within: 'cell',
                        dataSource: {
                          type: 'cellAddress',
                          name: 'P:16',
                          cellAddress: 'P:16',
                        },
                      },
                    ],
                    dataSource: ['cellAddress'],
                    property: 'value',
                    operator: '==',
                    value: '41',
                    fieldKey: '35e64efc-b786-476d-b360-75f267b317a6',
                    names: ['Emails'],
                    cellAddress: 'P:16',
                  },
                  {
                    type: 'check',
                    name: 'Summary - Opened is 178',
                    property: 'value',
                    operator: '==',
                    value: '178',
                    fieldKey: '02d6542c-578c-4495-863c-8fb842f271d6',
                    location: [
                      {
                        within: 'worksheet',
                        dataSource: {
                          type: 'byName',
                          name: 'Emails',
                        },
                      },
                      {
                        within: 'cell',
                        dataSource: {
                          type: 'cellAddress',
                          name: 'P:20',
                          cellAddress: 'P:20',
                        },
                      },
                    ],
                    dataSource: ['cellAddress'],
                    names: ['Emails'],
                    cellAddress: 'P:20',
                  },
                ],
                name: 'Emails: Opened',
                description: '',
                logicFormula: '',
                points: '1',
                resubmitOnError: false,
                fieldKey: '01e8fe27-83df-4ef9-b19d-4fa18784cab7',
              },
              {
                type: 'rule',
                check: [
                  {
                    type: 'check',
                    name: 'Email 1 - Clicks is 9',
                    property: 'value',
                    operator: '==',
                    value: '9',
                    fieldKey: '15ed5c23-9dcc-413b-b9f0-8fa7dfdf427d',
                    location: [
                      {
                        within: 'worksheet',
                        dataSource: {
                          type: 'byName',
                          name: 'Emails',
                        },
                      },
                      {
                        within: 'cell',
                        dataSource: {
                          type: 'cellAddress',
                          name: 'Q:4',
                          cellAddress: 'Q:4',
                        },
                      },
                    ],
                    dataSource: ['cellAddress'],
                    names: ['Emails'],
                    cellAddress: 'Q:4',
                  },
                  {
                    type: 'check',
                    name: 'Email 2 - Clicks is 3',
                    property: 'value',
                    operator: '==',
                    value: '3',
                    fieldKey: 'd9619387-b41d-44f6-a38c-9bc5009ee98b',
                    location: [
                      {
                        within: 'worksheet',
                        dataSource: {
                          type: 'byName',
                          name: 'Emails',
                        },
                      },
                      {
                        within: 'cell',
                        dataSource: {
                          type: 'cellAddress',
                          name: 'Q:8',
                          cellAddress: 'Q:8',
                        },
                      },
                    ],
                    dataSource: ['cellAddress'],
                    names: ['Emails'],
                    cellAddress: 'Q:8',
                  },
                  {
                    type: 'check',
                    name: 'Email 3 - Clicks is 7',
                    property: 'value',
                    operator: '==',
                    value: '7',
                    fieldKey: '0c76de73-9fa9-49ab-901d-83d961aa3b92',
                    location: [
                      {
                        within: 'worksheet',
                        dataSource: {
                          type: 'byName',
                          name: 'Emails',
                        },
                      },
                      {
                        within: 'cell',
                        dataSource: {
                          type: 'cellAddress',
                          name: 'Q:12',
                          cellAddress: 'Q:12',
                        },
                      },
                    ],
                    dataSource: ['cellAddress'],
                    names: ['Emails'],
                    cellAddress: 'Q:12',
                  },
                  {
                    type: 'check',
                    name: 'Email 4 - Clicks is 10',
                    property: 'value',
                    operator: '==',
                    value: '16',
                    fieldKey: '2407d0ef-e229-4787-9252-dbc126b4ece9',
                    location: [
                      {
                        within: 'worksheet',
                        dataSource: {
                          type: 'byName',
                          name: 'Emails',
                        },
                      },
                      {
                        within: 'cell',
                        dataSource: {
                          type: 'cellAddress',
                          name: 'Q:16',
                          cellAddress: 'Q:16',
                        },
                      },
                    ],
                    dataSource: ['cellAddress'],
                    names: ['Emails'],
                    cellAddress: 'Q:16',
                  },
                  {
                    type: 'check',
                    name: 'Summary - Clicks is 29',
                    property: 'value',
                    operator: '==',
                    value: '29',
                    fieldKey: 'd45fefeb-243e-4c84-a7bf-49036843e73c',
                    location: [
                      {
                        within: 'worksheet',
                        dataSource: {
                          type: 'byName',
                          name: 'Emails',
                        },
                      },
                      {
                        within: 'cell',
                        dataSource: {
                          type: 'cellAddress',
                          name: 'Q:20',
                          cellAddress: 'Q:20',
                        },
                      },
                    ],
                    dataSource: ['cellAddress'],
                    names: ['Emails'],
                    cellAddress: 'Q:20',
                  },
                ],
                name: 'Emails: Clicks',
                description: '',
                logicFormula: '',
                points: '1',
                resubmitOnError: false,
                fieldKey: '02b5602f-14de-4680-aafc-7fd4fb286959',
              },
              {
                type: 'rule',
                check: [
                  {
                    type: 'check',
                    name: 'Email 1 - Open Rate is 21.35%',
                    property: 'value',
                    operator: '==',
                    value: '21.35%',
                    fieldKey: 'c9c3365f-6026-4f0a-b29e-89c24aa54844',
                    location: [
                      {
                        within: 'worksheet',
                        dataSource: {
                          type: 'byName',
                          name: 'Emails',
                        },
                      },
                      {
                        within: 'cell',
                        dataSource: {
                          type: 'cellAddress',
                          name: 'R:4',
                          cellAddress: 'R:4',
                        },
                      },
                    ],
                    dataSource: ['cellAddress'],
                    names: ['Emails'],
                    cellAddress: 'R:4',
                  },
                  {
                    type: 'check',
                    name: 'Email 2 - Open Rate is 24.00%',
                    property: 'value',
                    operator: '==',
                    value: '24.00%',
                    fieldKey: '5b5f5878-e816-4cb7-a4b5-3a827574d2cd',
                    location: [
                      {
                        within: 'worksheet',
                        dataSource: {
                          type: 'byName',
                          name: 'Emails',
                        },
                      },
                      {
                        within: 'cell',
                        dataSource: {
                          type: 'cellAddress',
                          name: 'R:8',
                          cellAddress: 'R:8',
                        },
                      },
                    ],
                    dataSource: ['cellAddress'],
                    names: ['Emails'],
                    cellAddress: 'R:8',
                  },
                  {
                    type: 'check',
                    name: 'Email 3 - Open Rate is 22.86%',
                    property: 'value',
                    operator: '==',
                    value: '22.86%',
                    fieldKey: 'f68d5d61-5746-4a80-a4a6-661532d4ca83',
                    location: [
                      {
                        within: 'worksheet',
                        dataSource: {
                          type: 'byName',
                          name: 'Emails',
                        },
                      },
                      {
                        within: 'cell',
                        dataSource: {
                          type: 'cellAddress',
                          name: 'R:12',
                          cellAddress: 'R:12',
                        },
                      },
                    ],
                    dataSource: ['cellAddress'],
                    names: ['Emails'],
                    cellAddress: 'R:12',
                  },
                  {
                    type: 'check',
                    name: 'Email 4 - Open Rate is 17.98%',
                    property: 'value',
                    operator: '==',
                    value: '17.98%',
                    fieldKey: '9d609b82-6803-4b10-8f68-02b9388892df',
                    location: [
                      {
                        within: 'worksheet',
                        dataSource: {
                          type: 'byName',
                          name: 'Emails',
                        },
                      },
                      {
                        within: 'cell',
                        dataSource: {
                          type: 'cellAddress',
                          name: 'R:16',
                          cellAddress: 'R:16',
                        },
                      },
                    ],
                    dataSource: ['cellAddress'],
                    names: ['Emails'],
                    cellAddress: 'R:16',
                  },
                  {
                    type: 'check',
                    name: 'Summary - Open Rate is 21.45%',
                    property: 'value',
                    operator: '==',
                    value: '21.45%',
                    fieldKey: 'e3ddba2b-628a-488b-aa8b-7f21850763d0',
                    location: [
                      {
                        within: 'worksheet',
                        dataSource: {
                          type: 'byName',
                          name: 'Emails',
                        },
                      },
                      {
                        within: 'cell',
                        dataSource: {
                          type: 'cellAddress',
                          name: 'R:20',
                          cellAddress: 'R:20',
                        },
                      },
                    ],
                    dataSource: ['cellAddress'],
                    names: ['Emails'],
                    cellAddress: 'R:20',
                  },
                ],
                name: 'Emails: Open Rate',
                description: '',
                logicFormula: '',
                points: '1',
                resubmitOnError: false,
                fieldKey: 'a87f7eaa-20c6-4b12-807f-9c9dcf355fab',
              },
              {
                type: 'rule',
                check: [
                  {
                    type: 'check',
                    name: 'Email 1 - Click-through Rate is 4.69%',
                    property: 'value',
                    operator: '==',
                    value: '4.69%',
                    fieldKey: 'd95c8e11-2403-4800-ac82-306beebc7fd7',
                    location: [
                      {
                        within: 'worksheet',
                        dataSource: {
                          type: 'byName',
                          name: 'Emails',
                        },
                      },
                      {
                        within: 'cell',
                        dataSource: {
                          type: 'cellAddress',
                          name: 'S:4',
                          cellAddress: 'S:4',
                        },
                      },
                    ],
                    dataSource: ['cellAddress'],
                    names: ['Emails'],
                    cellAddress: 'S:4',
                  },
                  {
                    type: 'check',
                    name: 'Email 2 - Click-through Rate is 1.50%',
                    location: [
                      {
                        within: 'worksheet',
                        dataSource: {
                          type: 'byName',
                          name: 'Emails',
                        },
                      },
                      {
                        within: 'cell',
                        dataSource: {
                          type: 'cellAddress',
                          name: 'S:8',
                          cellAddress: 'S:8',
                        },
                      },
                    ],
                    dataSource: ['cellAddress'],
                    property: 'value',
                    operator: '==',
                    value: '1.50%',
                    fieldKey: 'f333ed3e-08ed-48f1-a61d-3e64dd4ac08b',
                    names: ['Emails'],
                    cellAddress: 'S:8',
                  },
                  {
                    type: 'check',
                    name: 'Email 3 - Click-through Rate is 3.33%',
                    property: 'value',
                    operator: '==',
                    value: '3.33%',
                    fieldKey: '21d22f5d-283c-4c73-918a-9309a1f1e800',
                    location: [
                      {
                        within: 'worksheet',
                        dataSource: {
                          type: 'byName',
                          name: 'Emails',
                        },
                      },
                      {
                        within: 'cell',
                        dataSource: {
                          type: 'cellAddress',
                          name: 'S:12',
                          cellAddress: 'S:12',
                        },
                      },
                    ],
                    dataSource: ['cellAddress'],
                    names: ['Emails'],
                    cellAddress: 'S:12',
                  },
                  {
                    type: 'check',
                    name: 'Email 4 - Click-through Rate is 4.39%',
                    property: 'value',
                    operator: '==',
                    value: '4.39%',
                    fieldKey: '4ccf4a00-3217-439b-beef-bacc66ebcfd0',
                    location: [
                      {
                        within: 'worksheet',
                        dataSource: {
                          type: 'byName',
                          name: 'Emails',
                        },
                      },
                      {
                        within: 'cell',
                        dataSource: {
                          type: 'cellAddress',
                          name: 'S:16',
                          cellAddress: 'S:16',
                        },
                      },
                    ],
                    dataSource: ['cellAddress'],
                    names: ['Emails'],
                    cellAddress: 'S:16',
                  },
                  {
                    type: 'check',
                    name: 'Summary - Click-through Rate is 3.49%',
                    property: 'value',
                    operator: '==',
                    value: '3.49%',
                    fieldKey: 'da1b22c3-8e03-4e03-bd28-8ed2a2a937f7',
                    location: [
                      {
                        within: 'worksheet',
                        dataSource: {
                          type: 'byName',
                          name: 'Emails',
                        },
                      },
                      {
                        within: 'cell',
                        dataSource: {
                          type: 'cellAddress',
                          name: 'S:20',
                          cellAddress: 'S:20',
                        },
                      },
                    ],
                    dataSource: ['cellAddress'],
                    names: ['Emails'],
                    cellAddress: 'S:20',
                  },
                ],
                name: 'Emails: Click-through Rate',
                description: '',
                logicFormula: '',
                points: '1',
                resubmitOnError: false,
                fieldKey: 'e90d2b72-b4f8-4933-828f-4ff32eea7dc3',
              },
              {
                type: 'rule',
                check: [
                  {
                    type: 'check',
                    name: 'Summary - Average Clicks is 7.25',
                    property: 'value',
                    operator: '==',
                    value: '7.25',
                    fieldKey: '73949476-6fcd-4570-a9b3-8cbf3bff413d',
                    location: [
                      {
                        within: 'worksheet',
                        dataSource: {
                          type: 'byName',
                          name: 'Emails',
                        },
                      },
                      {
                        within: 'cell',
                        dataSource: {
                          type: 'cellAddress',
                          name: 'Q:22',
                          cellAddress: 'Q:22',
                        },
                      },
                    ],
                    dataSource: ['cellAddress'],
                    names: ['Emails'],
                    cellAddress: 'Q:22',
                  },
                ],
                name: 'Emails: Average Clicks',
                description: '',
                logicFormula: '',
                points: '1',
                resubmitOnError: false,
                fieldKey: '042c4fd6-cbe0-4931-b7f4-90edcd2bafff',
              },
            ],
            name: 'Email Metrics',
            fieldKey: 'f5c2fa1b-2983-4e0a-926e-b66ff751096a',
          },
          {
            type: 'ruleset criterion',
            criteria: [
              {
                type: 'rule',
                check: [
                  {
                    type: 'check',
                    name: 'SHOW - Which email performed the best? Explain your answer.',
                    value: '',
                    fieldKey: '2aec27f0-0125-4717-b8d1-2d9762415d34',
                    location: [
                      {
                        within: 'worksheet',
                        dataSource: {
                          type: 'byName',
                          name: 'Emails',
                        },
                      },
                      {
                        within: 'cell',
                        dataSource: {
                          type: 'cellAddress',
                          name: 'O:29',
                          cellAddress: 'O:29',
                        },
                      },
                    ],
                    dataSource: ['cellAddress'],
                    names: ['Emails'],
                    cellAddress: 'O:29',
                  },
                ],
                name: 'Which email performed the best? Explain your answer.',
                description: '',
                logicFormula: '',
                points: '1',
                resubmitOnError: false,
                fieldKey: 'b99edc34-6c89-46d1-9763-ee8714d008dd',
              },
              {
                type: 'rule',
                check: [
                  {
                    type: 'check',
                    name: 'SHOW - Which email performed the worst? Explain your answer.',
                    property: '',
                    operator: '',
                    value: '',
                    fieldKey: '521bc821-f889-47fa-ac36-3230cb235f94',
                    location: [
                      {
                        within: 'worksheet',
                        dataSource: {
                          type: 'byName',
                          name: 'Emails',
                        },
                      },
                      {
                        within: 'cell',
                        dataSource: {
                          type: 'cellAddress',
                          name: 'O:34',
                          cellAddress: 'O:34',
                        },
                      },
                    ],
                    dataSource: ['cellAddress'],
                    names: ['Emails'],
                    cellAddress: 'O:34',
                  },
                ],
                name: 'Which email performed the worst? Explain your answer.',
                description: '',
                logicFormula: '',
                points: '1',
                resubmitOnError: false,
                fieldKey: 'ab581e6a-a89a-4f88-8fc6-925b4b0fbb0f',
              },
            ],
            name: 'Email Reflection Questions',
            fieldKey: '103430bd-607f-46f0-b263-cfc875245964',
          },
          {
            type: 'ruleset criterion',
            criteria: [
              {
                type: 'rule',
                check: [
                  {
                    type: 'check',
                    name: 'Total impressions is 1,361',
                    property: 'value',
                    operator: '==',
                    value: '1,361',
                    fieldKey: 'c53f734f-3ab3-4623-bde3-08c38b23fa0b',
                    location: [
                      {
                        within: 'worksheet',
                        dataSource: {
                          type: 'byName',
                          name: 'Adwords',
                        },
                      },
                      {
                        within: 'cell',
                        dataSource: {
                          type: 'cellAddress',
                          name: 'B:17',
                          cellAddress: 'B:17',
                        },
                      },
                    ],
                    dataSource: ['cellAddress'],
                    names: ['Adwords'],
                    cellAddress: 'B:17',
                  },
                ],
                name: 'Total Impressions',
                description: '',
                logicFormula: '',
                points: '1',
                resubmitOnError: false,
                fieldKey: '1850d3f7-f80d-4868-a988-8820eb53fdb2',
              },
              {
                type: 'rule',
                check: [
                  {
                    type: 'check',
                    name: 'Total Clicks is 47',
                    property: 'value',
                    operator: '==',
                    value: '47',
                    fieldKey: '5b97a749-7456-404c-b59a-30208457da44',
                    location: [
                      {
                        within: 'worksheet',
                        dataSource: {
                          type: 'byName',
                          name: 'Adwords',
                        },
                      },
                      {
                        within: 'cell',
                        dataSource: {
                          type: 'cellAddress',
                          name: 'C:17',
                          cellAddress: 'C:17',
                        },
                      },
                    ],
                    dataSource: ['cellAddress'],
                    names: ['Adwords'],
                    cellAddress: 'C:17',
                  },
                ],
                name: 'Total Clicks',
                description: '',
                logicFormula: '',
                points: '1',
                resubmitOnError: false,
                fieldKey: 'b256c137-5543-4834-9bd1-57200957e98b',
              },
              {
                type: 'rule',
                check: [
                  {
                    type: 'check',
                    name: 'Total Cost is $56.02',
                    property: 'value',
                    operator: '==',
                    value: '$56.02',
                    fieldKey: 'b300fc91-d3ea-4319-b622-e806d3b60308',
                    location: [
                      {
                        within: 'worksheet',
                        dataSource: {
                          type: 'byName',
                          name: 'Adwords',
                        },
                      },
                      {
                        within: 'cell',
                        dataSource: {
                          type: 'cellAddress',
                          name: 'D:17',
                          cellAddress: 'D:17',
                        },
                      },
                    ],
                    dataSource: ['cellAddress'],
                    names: ['Adwords'],
                    cellAddress: 'D:17',
                  },
                ],
                name: 'Total Cost',
                description: '',
                logicFormula: '',
                points: '1',
                resubmitOnError: false,
                fieldKey: 'e3cfd39a-6201-4f78-a2e5-2c5e70f82b66',
              },
              {
                type: 'rule',
                check: [
                  {
                    type: 'check',
                    name: 'Total Conversions is 5',
                    property: 'value',
                    operator: '==',
                    value: '5',
                    fieldKey: 'aca35683-d664-468d-b58b-b058b4575210',
                    location: [
                      {
                        within: 'worksheet',
                        dataSource: {
                          type: 'byName',
                          name: 'Adwords',
                        },
                      },
                      {
                        within: 'cell',
                        dataSource: {
                          type: 'cellAddress',
                          name: 'E:17',
                          cellAddress: 'E:17',
                        },
                      },
                    ],
                    dataSource: ['cellAddress'],
                    names: ['Adwords'],
                    cellAddress: 'E:17',
                  },
                ],
                name: 'Total Conversions',
                description: '',
                logicFormula: '',
                points: '1',
                resubmitOnError: false,
                fieldKey: 'c589ec54-c4c0-4904-a6d2-2af62aaf6e2e',
              },
              {
                type: 'rule',
                check: [
                  {
                    type: 'check',
                    name: 'Total Click Through Rate is 3.45%',
                    property: 'value',
                    operator: '==',
                    value: '3.45%',
                    fieldKey: '08e2740c-0aaa-4771-9f8f-eaa70cdc4d7d',
                    location: [
                      {
                        within: 'worksheet',
                        dataSource: {
                          type: 'byName',
                          name: 'Adwords',
                        },
                      },
                      {
                        within: 'cell',
                        dataSource: {
                          type: 'cellAddress',
                          name: 'F:17',
                          cellAddress: 'F:17',
                        },
                      },
                    ],
                    dataSource: ['cellAddress'],
                    names: ['Adwords'],
                    cellAddress: 'F:17',
                  },
                ],
                name: 'Total Click Through Rate',
                description: '',
                logicFormula: '',
                points: '1',
                resubmitOnError: false,
                fieldKey: '25e1c8e1-2663-4c28-8115-c5db2f48f57e',
              },
              {
                type: 'rule',
                check: [
                  {
                    type: 'check',
                    name: 'Cost Per Click is $1.19',
                    property: 'value',
                    operator: '==',
                    value: '$1.19',
                    fieldKey: 'f0bc0cea-7f15-481f-a566-1e7d160b57d5',
                    location: [
                      {
                        within: 'worksheet',
                        dataSource: {
                          type: 'byName',
                          name: 'Adwords',
                        },
                      },
                      {
                        within: 'cell',
                        dataSource: {
                          type: 'cellAddress',
                          name: 'G:17',
                          cellAddress: 'G:17',
                        },
                      },
                    ],
                    dataSource: ['cellAddress'],
                    names: ['Adwords'],
                    cellAddress: 'G:17',
                  },
                ],
                name: 'Cost Per Click',
                description: '',
                logicFormula: '',
                points: '1',
                resubmitOnError: false,
                fieldKey: '86863c0f-c775-49ae-bc43-16653689737f',
              },
              {
                type: 'rule',
                check: [
                  {
                    type: 'check',
                    name: 'ROI is 100.52%',
                    property: 'value',
                    operator: '==',
                    value: '100.52%',
                    fieldKey: 'f67b7f41-d389-4d6d-8ac7-abf991ee05c4',
                    location: [
                      {
                        within: 'worksheet',
                        dataSource: {
                          type: 'byName',
                          name: 'Adwords',
                        },
                      },
                      {
                        within: 'cell',
                        dataSource: {
                          type: 'cellAddress',
                          name: 'H:17',
                          cellAddress: 'H:17',
                        },
                      },
                    ],
                    dataSource: ['cellAddress'],
                    names: ['Adwords'],
                    cellAddress: 'H:17',
                  },
                ],
                name: 'ROI',
                description: '',
                logicFormula: '',
                points: '1',
                resubmitOnError: false,
                fieldKey: '8be0056c-596c-4dcb-a99d-bbf78c1d8965',
              },
            ],
            name: 'Adword Campaign Metrics',
            fieldKey: 'c59fb3a8-bb00-405c-982b-98b3b3da4ae2',
          },
          {
            type: 'ruleset criterion',
            criteria: [
              {
                type: 'rule',
                check: [
                  {
                    type: 'check',
                    name:
                      'SHOW - Based on the ROI you calculated, would you recommend that this campaign continues? Explain your answer in a 2-4 sentence paragraph.',
                    property: '',
                    operator: '',
                    value: '',
                    fieldKey: 'db03e3b7-f4b5-43cf-ae6c-6e768ea38f9e',
                    location: [
                      {
                        within: 'worksheet',
                        dataSource: {
                          type: 'byName',
                          name: 'Adwords',
                        },
                      },
                      {
                        within: 'cell',
                        dataSource: {
                          type: 'cellAddress',
                          name: 'A:24',
                          cellAddress: 'A:24',
                        },
                      },
                    ],
                    dataSource: ['cellAddress'],
                    names: ['Adwords'],
                    cellAddress: 'A:24',
                  },
                ],
                name:
                  'Based on the ROI you calculated, would you recommend that this campaign continues? Explain your answer in a 2-4 sentence paragraph.',
                description: '',
                logicFormula: '',
                points: '1',
                resubmitOnError: false,
                fieldKey: '49a7e26b-66d9-4e03-9a42-12952d800514',
              },
            ],
            name: 'Adword Reflection Question',
            fieldKey: '4b7b505c-23d1-4d68-899d-253a9fec8226',
          },
          {
            type: 'ruleset criterion',
            criteria: [
              {
                type: 'rule',
                check: [
                  {
                    type: 'check',
                    name: 'Total impressions is 3700',
                    property: 'value',
                    operator: '==',
                    value: '3700',
                    fieldKey: 'f10ec9c2-ca34-4bcd-9746-e42940223b83',
                    location: [
                      {
                        within: 'worksheet',
                        dataSource: {
                          type: 'byName',
                          name: 'Facebook',
                        },
                      },
                      {
                        within: 'cell',
                        dataSource: {
                          type: 'cellAddress',
                          name: 'B:25',
                          cellAddress: 'B:25',
                        },
                      },
                    ],
                    dataSource: ['cellAddress'],
                    names: ['Facebook'],
                    cellAddress: 'B:25',
                  },
                ],
                name: 'Total Impressions',
                description: '',
                logicFormula: '',
                points: '1',
                resubmitOnError: false,
                fieldKey: '48d63a46-15dd-40d7-8e00-400f9d67972f',
              },
              {
                type: 'rule',
                check: [
                  {
                    type: 'check',
                    name: 'Average impressions is 217.65',
                    property: 'value',
                    operator: '==',
                    value: '217.65',
                    fieldKey: '855e913a-9fd9-479a-8c2c-8a880605d5c0',
                    location: [
                      {
                        within: 'worksheet',
                        dataSource: {
                          type: 'byName',
                          name: 'Facebook',
                        },
                      },
                      {
                        within: 'cell',
                        dataSource: {
                          type: 'cellAddress',
                          name: 'B:26',
                          cellAddress: 'B:26',
                        },
                      },
                    ],
                    dataSource: ['cellAddress'],
                    names: ['Facebook'],
                    cellAddress: 'B:26',
                  },
                ],
                name: 'Average Impressions',
                description: '',
                logicFormula: '',
                points: '1',
                resubmitOnError: false,
                fieldKey: '6b322d83-b9d2-434f-a125-67cc3aa1734a',
              },
              {
                type: 'rule',
                check: [
                  {
                    type: 'check',
                    name: 'Total likes is 362',
                    property: 'value',
                    operator: '==',
                    value: '362',
                    fieldKey: '46928792-fafe-4e4d-ba4e-ccdeb9745cf0',
                    location: [
                      {
                        within: 'worksheet',
                        dataSource: {
                          type: 'byName',
                          name: 'Facebook',
                        },
                      },
                      {
                        within: 'cell',
                        dataSource: {
                          type: 'cellAddress',
                          name: 'C:25',
                          cellAddress: 'C:25',
                        },
                      },
                    ],
                    dataSource: ['cellAddress'],
                    names: ['Facebook'],
                    cellAddress: 'C:25',
                  },
                ],
                name: 'Total Likes',
                description: '',
                logicFormula: '',
                points: '1',
                resubmitOnError: false,
                fieldKey: '0500f244-ac40-40b7-853a-1e1e3b7dd1a8',
              },
              {
                type: 'rule',
                check: [
                  {
                    type: 'check',
                    name: 'Average likes is 21.29',
                    property: 'value',
                    operator: '==',
                    value: '21.29',
                    fieldKey: '49e601b1-139b-497e-8d8d-cead8bc22758',
                    location: [
                      {
                        within: 'worksheet',
                        dataSource: {
                          type: 'byName',
                          name: 'Facebook',
                        },
                      },
                      {
                        within: 'cell',
                        dataSource: {
                          type: 'cellAddress',
                          name: 'C:26',
                          cellAddress: 'C:26',
                        },
                      },
                    ],
                    dataSource: ['cellAddress'],
                    names: ['Facebook'],
                    cellAddress: 'C:26',
                  },
                ],
                name: 'Average Likes',
                description: '',
                logicFormula: '',
                points: '1',
                resubmitOnError: false,
                fieldKey: '355e2ea4-c08d-42b2-96a2-fa9266c33402',
              },
              {
                type: 'rule',
                check: [
                  {
                    type: 'check',
                    name: 'Total comments is 52',
                    property: 'value',
                    operator: '==',
                    value: '52',
                    fieldKey: '96faa10b-d3e7-4ce6-b244-8dfe735a45d2',
                    location: [
                      {
                        within: 'worksheet',
                        dataSource: {
                          type: 'byName',
                          name: 'Facebook',
                        },
                      },
                      {
                        within: 'cell',
                        dataSource: {
                          type: 'cellAddress',
                          name: 'D:25',
                          cellAddress: 'D:25',
                        },
                      },
                    ],
                    dataSource: ['cellAddress'],
                    names: ['Facebook'],
                    cellAddress: 'D:25',
                  },
                ],
                name: 'Total Comments',
                description: '',
                logicFormula: '',
                points: '1',
                resubmitOnError: false,
                fieldKey: 'a5f90b4a-578f-4ffd-bb09-2247933bbe44',
              },
              {
                type: 'rule',
                check: [
                  {
                    type: 'check',
                    name: 'Average comments is 3.06',
                    property: 'value',
                    operator: '==',
                    value: '3.06',
                    fieldKey: '01f2caf8-e324-4e19-8903-3264d4d89965',
                    location: [
                      {
                        within: 'worksheet',
                        dataSource: {
                          type: 'byName',
                          name: 'Facebook',
                        },
                      },
                      {
                        within: 'cell',
                        dataSource: {
                          type: 'cellAddress',
                          name: 'D:26',
                          cellAddress: 'D:26',
                        },
                      },
                    ],
                    dataSource: ['cellAddress'],
                    names: ['Facebook'],
                    cellAddress: 'D:26',
                  },
                ],
                name: 'Average Comments',
                description: '',
                logicFormula: '',
                points: '1',
                resubmitOnError: false,
                fieldKey: 'b11b1cfd-4cdd-461e-a3ed-26ff946411da',
              },
              {
                type: 'rule',
                check: [
                  {
                    type: 'check',
                    name: 'Total shares is 32',
                    property: 'value',
                    operator: '==',
                    value: '32',
                    fieldKey: 'c37f3fa1-3631-4f1c-bdfc-77f166fbff71',
                    location: [
                      {
                        within: 'worksheet',
                        dataSource: {
                          type: 'byName',
                          name: 'Facebook',
                        },
                      },
                      {
                        within: 'cell',
                        dataSource: {
                          type: 'cellAddress',
                          name: 'E:25',
                          cellAddress: 'E:25',
                        },
                      },
                    ],
                    dataSource: ['cellAddress'],
                    names: ['Facebook'],
                    cellAddress: 'E:25',
                  },
                ],
                name: 'Total Shares',
                description: '',
                logicFormula: '',
                points: '1',
                resubmitOnError: false,
                fieldKey: '459ee5ae-c66e-483d-936f-bd06651289e6',
              },
              {
                type: 'rule',
                check: [
                  {
                    type: 'check',
                    name: 'Average shares is 1.88',
                    property: 'value',
                    operator: '==',
                    value: '1.88',
                    fieldKey: '744e3336-b1b4-40a3-a952-537682fbc171',
                    location: [
                      {
                        within: 'worksheet',
                        dataSource: {
                          type: 'byName',
                          name: 'Facebook',
                        },
                      },
                      {
                        within: 'cell',
                        dataSource: {
                          type: 'cellAddress',
                          name: 'E:26',
                          cellAddress: 'E:26',
                        },
                      },
                    ],
                    dataSource: ['cellAddress'],
                    names: ['Facebook'],
                    cellAddress: 'E:26',
                  },
                ],
                name: 'Average Shares',
                description: '',
                logicFormula: '',
                points: '1',
                resubmitOnError: false,
                fieldKey: 'c0e126d5-6743-40e2-9cd0-b4252c220ee3',
              },
              {
                type: 'rule',
                check: [
                  {
                    type: 'check',
                    name: 'Total Clicks is 50',
                    property: 'value',
                    operator: '==',
                    value: '50',
                    fieldKey: '40b2210e-1758-4a2b-accc-d222ca3ad00b',
                    location: [
                      {
                        within: 'worksheet',
                        dataSource: {
                          type: 'byName',
                          name: 'Facebook',
                        },
                      },
                      {
                        within: 'cell',
                        dataSource: {
                          type: 'cellAddress',
                          name: 'F:25',
                          cellAddress: 'F:25',
                        },
                      },
                    ],
                    dataSource: ['cellAddress'],
                    names: ['Facebook'],
                    cellAddress: 'F:25',
                  },
                ],
                name: 'Total Clicks',
                description: '',
                logicFormula: '',
                points: '1',
                resubmitOnError: false,
                fieldKey: 'd7b79bc8-784d-4f04-a77c-8cb692595474',
              },
              {
                type: 'rule',
                check: [
                  {
                    type: 'check',
                    name: 'Average Clicks is 2.94',
                    property: 'value',
                    operator: '==',
                    value: '2.94',
                    fieldKey: '9f38d817-8a60-4a6f-992e-b3598016a3de',
                    location: [
                      {
                        within: 'worksheet',
                        dataSource: {
                          type: 'byName',
                          name: 'Facebook',
                        },
                      },
                      {
                        within: 'cell',
                        dataSource: {
                          type: 'cellAddress',
                          name: 'F:26',
                          cellAddress: 'F:26',
                        },
                      },
                    ],
                    dataSource: ['cellAddress'],
                    names: ['Facebook'],
                    cellAddress: 'F:26',
                  },
                ],
                name: 'Average Clicks',
                description: '',
                logicFormula: '',
                points: '1',
                resubmitOnError: false,
                fieldKey: '4b01fe67-52d3-4e48-be00-ffeb2f5ea655',
              },
              {
                type: 'rule',
                check: [
                  {
                    type: 'check',
                    name: 'Click-Through Rate is 1.35%',
                    property: 'value',
                    operator: '==',
                    value: '1.35%',
                    fieldKey: 'f83caf2c-4da6-4b13-a3f1-4f9ce43430e9',
                    location: [
                      {
                        within: 'worksheet',
                        dataSource: {
                          type: 'byName',
                          name: 'Facebook',
                        },
                      },
                      {
                        within: 'cell',
                        dataSource: {
                          type: 'cellAddress',
                          name: 'F:28',
                          cellAddress: 'F:28',
                        },
                      },
                    ],
                    dataSource: ['cellAddress'],
                    names: ['Facebook'],
                    cellAddress: 'F:28',
                  },
                ],
                name: 'Click-Through Rate',
                description: '',
                logicFormula: '',
                points: '1',
                resubmitOnError: false,
                fieldKey: '95280ed0-5702-4cac-9035-3a3b4700a0ad',
              },
              {
                type: 'rule',
                check: [
                  {
                    type: 'check',
                    name: 'Like Rate is 9.78%',
                    property: 'value',
                    operator: '==',
                    value: '9.78%',
                    fieldKey: '5400726a-e6b3-46f2-b5a6-997d44fdc328',
                    location: [
                      {
                        within: 'worksheet',
                        dataSource: {
                          type: 'byName',
                          name: 'Facebook',
                        },
                      },
                      {
                        within: 'cell',
                        dataSource: {
                          type: 'cellAddress',
                          name: 'C:28',
                          cellAddress: 'C:28',
                        },
                      },
                    ],
                    dataSource: ['cellAddress'],
                    names: ['Facebook'],
                    cellAddress: 'C:28',
                  },
                ],
                name: 'Like Rate',
                description: '',
                logicFormula: '',
                points: '1',
                resubmitOnError: false,
                fieldKey: 'd72481a5-1ff9-404e-a564-c1008921ff15',
              },
              {
                type: 'rule',
                check: [
                  {
                    type: 'check',
                    name: 'Comment Rate is 1.41%',
                    property: 'value',
                    operator: '==',
                    value: '1.41%',
                    fieldKey: '6031827e-8d93-4bb1-a868-fc3f8ef73c87',
                    location: [
                      {
                        within: 'worksheet',
                        dataSource: {
                          type: 'byName',
                          name: 'Facebook',
                        },
                      },
                      {
                        within: 'cell',
                        dataSource: {
                          type: 'cellAddress',
                          name: 'D:28',
                          cellAddress: 'D:28',
                        },
                      },
                    ],
                    dataSource: ['cellAddress'],
                    names: ['Facebook'],
                    cellAddress: 'D:28',
                  },
                ],
                name: 'Comment Rate',
                description: '',
                logicFormula: '',
                points: '1',
                resubmitOnError: false,
                fieldKey: '6cbfdf58-e04e-47a4-856f-48477c9041ed',
              },
              {
                type: 'rule',
                check: [
                  {
                    type: 'check',
                    name: 'Share Rate is 0.86%',
                    property: 'value',
                    operator: '==',
                    value: '0.86%',
                    fieldKey: '15c718ea-47a0-4054-9aa4-b4ade07210d4',
                    location: [
                      {
                        within: 'worksheet',
                        dataSource: {
                          type: 'byName',
                          name: 'Facebook',
                        },
                      },
                      {
                        within: 'cell',
                        dataSource: {
                          type: 'cellAddress',
                          name: 'E:28',
                          cellAddress: 'E:28',
                        },
                      },
                    ],
                    dataSource: ['cellAddress'],
                    names: ['Facebook'],
                    cellAddress: 'E:28',
                  },
                ],
                name: 'Share Rate',
                description: '',
                logicFormula: '',
                points: '1',
                resubmitOnError: false,
                fieldKey: '31c9a5ed-a9c6-409c-91bf-3b8f3447dc1b',
              },
            ],
            name: 'Social Metrics',
            fieldKey: '4a2c020b-a875-422b-a6a8-70f8444fdd45',
          },
        ],
        name: 'Part A',
        fieldKey: 'fd5d6d15-8311-47da-8e5b-5978f5157302',
      },
    ],
    name: 'Facebook DM3.2',
  },
];
