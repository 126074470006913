import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

export default function BlockHeader({ title, messageId, icon }) {
  if (!title && !messageId) {
    return '';
  }
  const message = title || <FormattedMessage id={messageId} />;

  return (
    <div className="block-header block-label__wrapper block-label__wrapper--static">
      {!!icon && (
        <span className="block-icon icon-element icon-element--fill-transparent icon-element--background-transparent  icon-element--size-">
          {icon}
        </span>
      )}
      <span className="label">{message}</span>
    </div>
  );
}

BlockHeader.propTypes = {
  title: PropTypes.string,
  icon: PropTypes.element,
  messageId: PropTypes.string,
};
