import { v4 as uuidv4 } from 'uuid';
import Criteria from './Criteria';

export default class Group {
  constructor(name) {
    this.id = uuidv4();
    this.name = name;
    this.criteria = [];
  }

  createCriterion = ({ name, description, logicFormula, allowSubmitOnError, points }) => {
    this.criteria.push(new Criteria(name, description, logicFormula, allowSubmitOnError, points));
    return this.criteria.slice(-1)[0];
  };

  createCriteria = criteria => {
    criteria.forEach(crit => {
      this.createCriterion(crit);
    });
  };
}
