import { dataSources, properties } from './checkOptions';

const { any, all, position, name: dsName, title: dsTitle, contains, cellAddress, cellRange } = dataSources;

const {
  numberOfWorksheets,
  exists,
  name,
  numberOfColumns,
  numberOfRows,
  numberOfCharts,
  numberOfPivotTables,
  columnSize,
  rowSize,
  filterExists,
  filterType,
  sortType,
  value,
  formula,
  bold,
  textColor,
  backgroundColor,
  formatType,
  type,
  order,
  xAxisLabel,
  yAxisLabel,
  orderBy,
  sortBy,
  showTotals,
  repeatRowLabels,
  summarizeBy,
  showAs,
  queries,
  displayName,
  dataType,
  dimensionOrMeasure,
  discreteOrContinuous,
  numberOfCalculatedFields,
  numberOfStories,
  numberOfDashboards,
  numberOfActions,
  graphType,
  title,
  titleFont,
  graphColor,
  numberOfFilters,
  numberOfCaptions,
  numberOfAnalytics,
  numberOfFields,
  description,
  numberOfTasks,
  numberOfSections,
  numberOfMilestones,
  numberOfCustomFields,
  section,
  numberOfActiveCustomFields,
  dueDate,
  startDate,
  endDate,
  taskLength,
  assignee,
  overlapsWith,
  before,
  after,
  customField,
  checked,
} = properties;

// eslint-disable-next-line complexity
const getCheckOptions = path => {
  switch (path) {
    case 'googlesheet.workbook.worksheet':
      return {
        properties: [exists, name, numberOfColumns, numberOfRows, numberOfCharts, numberOfPivotTables],
        dataSources: [position, dsName, any, all],
      };
    case 'googlesheet.workbook.worksheet.columns':
      return {
        properties: [exists, columnSize, name, filterExists, filterType, sortType],
        dataSources: [position, dsName, any, all],
      };
    case 'googlesheet.workbook.worksheet.columns.cell':
      return {
        properties: [value, formula, bold, textColor, backgroundColor, formatType],
        dataSources: [any, all],
      };
    case 'googlesheet.workbook.worksheet.columns.rowValue.columns2':
      return {
        properties: [value, formula, bold, textColor, backgroundColor, formatType],
        dataSources: [dsName],
      };
    case 'googlesheet.workbook.worksheet.rows':
      return {
        properties: [exists, rowSize, name, filterExists, filterType, sortType],
        dataSources: [position, dsName, any, all],
      };
    case 'googlesheet.workbook.worksheet.cellRange':
      return {
        properties: [value, formula, bold, textColor, backgroundColor, formatType],
        dataSources: [cellRange],
      };
    case 'googlesheet.workbook.worksheet.cell':
      return {
        properties: [value, formula, bold, textColor, backgroundColor, formatType],
        dataSources: [cellAddress],
      };
    case 'googlesheet.workbook.worksheet.chart':
      return {
        properties: [exists, name, type, xAxisLabel, yAxisLabel],
        dataSources: [dsTitle, any, all],
      };
    case 'googlesheet.workbook.worksheet.chart.chartData':
      return {
        properties: [value, formula, bold, textColor, backgroundColor, formatType],
        dataSources: [cellRange, cellAddress],
      };
    case 'googlesheet.workbook.worksheet.pivotTable':
      return {
        properties: [exists, name],
        dataSources: [dsTitle, any, all],
      };
    case 'googlesheet.workbook.worksheet.pivotTable.rows':
      return {
        properties: [exists, orderBy, sortBy, showTotals, repeatRowLabels],
        dataSources: [dsTitle, any, all],
      };
    case 'googlesheet.workbook.worksheet.pivotTable.columns':
      return {
        properties: [exists, orderBy, sortBy, showTotals, repeatRowLabels],
        dataSources: [dsTitle, any, all],
      };
    case 'googlesheet.workbook.worksheet.pivotTable.columns.rowValue.column2':
      return {
        properties: [value, formula, bold, textColor, backgroundColor, formatType],
        dataSources: [dsTitle],
      };
    case 'googlesheet.workbook.worksheet.pivotTable.value':
      return {
        properties: [exists, summarizeBy, showAs],
        dataSources: [dsTitle, any, all],
      };
    case 'googlesheet.workbook.worksheet.pivotTable.filter':
      return {
        properties: [exists, summarizeBy, showAs],
        dataSources: [dsTitle, any, all],
      };
    case 'sql.file':
      return {
        properties: [queries],
        dataSources: [],
      };
    case 'sql.file.resultingTable.cellRange':
      return {
        properties: [value],
        dataSources: [cellRange],
      };
    case 'sql.file.resultingTable.cell':
      return {
        properties: [value],
        dataSources: [cellAddress],
      };
    case 'tableau.workbook.dataSource':
      return {
        properties: [exists, name, displayName, dataType, dimensionOrMeasure, discreteOrContinuous],
        dataSources: [dsName, any, all],
      };
    case 'tableau.workbook':
      return {
        properties: [
          numberOfCalculatedFields,
          numberOfWorksheets,
          numberOfStories,
          numberOfDashboards,
          numberOfActions,
        ],
        dataSources: [],
      };
    case 'tableau.workbook.calculatedFields.calculatedField':
      return {
        properties: [exists, name, formula],
        dataSources: [dsName, any, all, contains],
      };
    case 'tableau.workbook.worksheet':
      return {
        properties: [
          exists,
          graphType,
          title,
          titleFont,
          graphColor,
          numberOfFilters,
          numberOfCaptions,
          numberOfAnalytics,
        ],
        dataSources: [name, any, all],
      };
    case 'tableau.workbook.worksheet.columns':
      return {
        properties: [numberOfFields],
        dataSources: [dsName, any, all],
      };
    case 'tableau.workbook.worksheet.columns.fieldName':
      return {
        properties: [exists, name, type, order],
        dataSources: [dsName, any, all],
      };
    case 'tableau.workbook.worksheet.rows':
      return {
        properties: [numberOfFields],
        dataSources: [dsName, any, all],
      };
    case 'tableau.workbook.worksheet.rows.fieldName':
      return {
        properties: [exists, name, type, order],
        dataSources: [dsName, any, all],
      };
    case 'workbook.worksheet.columns':
      return {
        properties: [],
        dataSources: [],
      };
    case 'tableau.workbook.worksheet.filters':
      return {
        properties: [exists, name, type, filterType],
        dataSources: [dsName, any, all],
      };
    case 'tableau.workbook.worksheet.captions':
      return {
        properties: [exists, name, description],
        dataSources: [dsName, any, all],
      };
    case 'tableau.workbook.worksheet.analytics':
      return {
        properties: [exists, type],
        dataSources: [dsName, any, all],
      };
    case 'tableau.workbook.actions':
      return {
        properties: [exists, type],
        dataSources: [dsName, any, all],
      };
    case 'tableau.workbook.story':
      return {
        properties: [],
        dataSources: [],
      };
    case 'tableau.workbook.dashboard.filters':
      return {
        properties: [exists, name, type, filterType],
        dataSources: [dsName, any, all],
      };
    case 'tableau.workbook.dashboard.worksheets':
      return {
        properties: [exists, title],
        dataSources: [dsName, any, all],
      };
    case 'asana.project':
      return {
        properties: [numberOfTasks, numberOfSections, numberOfMilestones, numberOfCustomFields],
        dataSources: [],
      };
    case 'asana.project.customFields':
      return {
        properties: [exists, name],
        dataSources: [dsName, any, all],
      };
    case 'asana.project.section':
      return {
        properties: [exists, name, numberOfTasks],
        dataSources: [dsName, any, all],
      };
    case 'asana.project.task':
      return {
        properties: [
          exists,
          name,
          description,
          section,
          numberOfActiveCustomFields,
          dueDate,
          startDate,
          endDate,
          taskLength,
          assignee,
          overlapsWith,
          before,
          after,
          customField,
        ],
        dataSources: [dsName, any, all],
      };
    case 'asana.project.milestone':
      return {
        properties: [exists, name, checked],
        dataSources: [dsName, any, all],
      };
    default:
      return null;
  }
};

export default getCheckOptions;
