import { isEmpty as _isEmpty } from 'lodash';
import { connect } from 'react-redux';

import { selectPath } from '../../../selectors/pathSelectors';
import { selectSection } from '../../../selectors/sectionSelectors';
import { selectUnit } from '../../../selectors/unitSelectors';
import ProjectShow from './ProjectShow';
import { fetchCurrentEnrollments, fetchEnrollmentForCohort } from '../../../actions/enrollmentActions';
import { selectEnrollmentForBootcamp } from '../../../selectors/enrollmentSelectors';
import { selectBootcamp } from '../../../selectors/bootcampSelectors';
import { selectAssignment } from '../../../selectors/assignmentSelectors';
import { selectLesson } from '../../../selectors/lessonSelectors';
import { selectProgressForAssignment } from '../../../selectors/assignmentProgressSelectors';
import {
  saveAssignmentProgress,
  startAssignmentProgress,
  submitAssignmentProgress,
} from '../../../actions/assignmentProgressActions';
import { fetchAssignment } from '../../../actions/assignmentActions';
import { updateSectionProgress } from '../../../actions/sectionProgressActions';
import { selectProgressForUnit } from '../../../selectors/unitProgressSelectors';
import { selectProgressForSection } from '../../../selectors/sectionProgressSelectors';
import { startUnitProgress } from '../../../actions/unitProgressActions';
import { fetchUnit } from '../../../actions/unitActions';
import { fetchLesson } from '../../../actions/lessonActions';
import { fetchLtiAssignmentProgresses } from '../../../actions/ltiAssignmentProgressActions';
import { selectLtiProgressForAssignment } from '../../../selectors/ltiAssignmentProgressSelectors';
import { canLearn } from '../../../helpers/userAuth';
import { selectCurrentUser } from '../../../selectors/authSelectors';
import { selectCohortForEnrollment } from '../../../selectors/cohortSelectors';
import { getFolderPath } from '../../../helpers/labHelper';
import { getCoursePath } from '../../../helpers/pathHelper';
import { generateFeedbackData } from '../../../utils/analytics';

const mapStateToProps = (state, ownProps) => {
  const { bootcampId, pathId, sectionId, unitId } = ownProps.match.params;
  const path = selectPath(state, pathId);
  const section = selectSection(state, sectionId);
  const unit = selectUnit(state, unitId);
  const bootcamp = selectBootcamp(state, bootcampId);
  const assignment = selectAssignment(state, unit?.assignment_id);
  const lesson = selectLesson(state, unit?.lesson_id);
  const introLesson = unit?.intro_lesson_id && selectLesson(state, unit.intro_lesson_id);
  const enrollment = selectEnrollmentForBootcamp(state, bootcampId);
  const enrollmentId = enrollment?.id;
  const unitProgress = selectProgressForUnit(state, sectionId, unitId);
  const sectionProgress = selectProgressForSection(state, sectionId);
  const assignmentProgress = selectProgressForAssignment(state, unit?.assignment_id);
  const currentUser = selectCurrentUser(state);
  const isLtiStudent = enrollment?.requires_lti_support;

  // Look up the lti assignment progress using the context and the unit id (project id)
  const ltiProgress =
    isLtiStudent && selectLtiProgressForAssignment(state, enrollment && enrollment.id, unit && unit.assignment_id);

  const prerequisiteAssignment = assignment?.prerequisite_id && selectAssignment(state, assignment.prerequisite_id);
  const prerequisiteProgress =
    assignment?.prerequisite_id && selectProgressForAssignment(state, assignment.prerequisite_id);
  const cohort = enrollment && selectCohortForEnrollment(state, enrollment.id);
  const projectFolderPath = bootcamp && getFolderPath({ bootcamp, cohort, user: selectCurrentUser(state) });

  const bootcampPath = getCoursePath(currentUser, bootcamp, path);
  const feedbackData = generateFeedbackData(bootcamp, currentUser, cohort, 'project', unit);

  return {
    bootcamp,
    bootcampId,
    path,
    pathId,
    section,
    sectionId,
    unit,
    unitId,
    assignment,
    lesson,
    enrollmentId,
    unitProgress,
    sectionProgress,
    assignmentProgress,
    isLtiStudent,
    ltiProgress,
    projectFolderPath,
    prerequisiteAssignment,
    prerequisiteProgress,
    introLesson,
    cohortId: enrollment?.enrollable_id,
    cohort,
    bootcampPath,
    feedbackData,
    currentUser,
  };
};

export default connect(mapStateToProps, {
  fetchEnrollmentForCohort,
  fetchCurrentEnrollments,
  fetchUnit,
  startAssignmentProgress,
  submitAssignmentProgress,
  updateSectionProgress,
  startUnitProgress,
  fetchLesson,
  fetchLtiAssignmentProgresses,
  saveAssignmentProgress,
  fetchAssignment,
})(ProjectShow);
