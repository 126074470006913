import { createStore, applyMiddleware } from 'redux';
import reduxThunk from 'redux-thunk';
import { devToolsEnhancer } from 'redux-devtools-extension/logOnlyInProduction';

import reducers from './reducers';

const middlewares = [reduxThunk];
let reduxDevToolsConfig = '';
/* eslint-disable no-underscore-dangle */
if (process.env.NODE_ENV === 'development') {
  reduxDevToolsConfig = window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__();
} else {
  reduxDevToolsConfig = devToolsEnhancer();
}
/* eslint-enable */

const createStoreWithMiddleware = applyMiddleware(...middlewares)(createStore);

const store = createStoreWithMiddleware(reducers, reduxDevToolsConfig);

export const buildStore = initialState => createStore(reducers, initialState, applyMiddleware(...middlewares));

export default store;
