import axios from './axios';

export default class CohortLookup {
  static async all(tenantId, lookups) {
    const response = await axios.get('/paths/bootcamp_instances/bootcamp_instance_lookups', {
      params: {
        lookups,
      },
    });
    return response.data;
  }
}
