import React from 'react';
import PropTypes from 'prop-types';
import VideoPlayer from '../Utils/VideoPlayer';
import BlockHeader from '../Utils/BlockHeader';
import EmbedWithTitle from '../Utils/EmbedWithTitle';
import Icons from '../Utils/Icons';

export default function BlockVideoShow({ block, blockLabel, headingClass }) {
  return (
    <div className="section">
      <div className="media-content-wrapper">
        <div className="media-content">
          <div className="media-content__element">
            <div className="block-label">
              <BlockHeader messageId="common.media" icon={<Icons.LabelMedia />} />
              <div className="block-label__content">
                {blockLabel}
                {block.url && <VideoPlayer src={block.url} />}
                {block.embed_code && <EmbedWithTitle title={block.title} html={block.embed_code} />}
                {block.title && <h2 className={headingClass} dangerouslySetInnerHTML={{ __html: block.title }} />}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

BlockVideoShow.propTypes = {
  block: PropTypes.object.isRequired,
  blockLabel: PropTypes.any,
  headingClass: PropTypes.string,
};

BlockVideoShow.defaultProps = {
  headingClass: 'heading',
};
