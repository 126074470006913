import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import intl from '../../../utils/intl';
import UserPicture from '../../Utils/UserPicture';
import Icons from '../../Utils/Icons';
import showModal from '../../../utils/showModal';
import ChangePasswordModalContainer from '../../Users/ChangePasswordModalContainer';
import TermsOfServiceContainer from '../../Auth/TermsOfServiceContainer';
import useCurrentUser from '../../../hooks/useCurrentUser';
import useFeatureFlags from '../../../hooks/useFeatureFlags';
import useHandleOutsideClick from '../../../hooks/useHandleOutsideClick';

export default function ProfileNav({ hideName }) {
  const { currentUser, isStudent } = useCurrentUser();
  const { inExperiment } = useFeatureFlags();
  const [isOpen, setIsOpen] = useState(false);
  const containerRef = useHandleOutsideClick(isOpen, () => {
    if (isOpen) toggleMenu();
  });

  const handleKeyPress = e => {
    if (e.key === 'Enter') toggleMenu();
  };

  const toggleMenu = () => setIsOpen(!isOpen);

  const handleChangePassword = e => {
    e.preventDefault();
    toggleMenu();

    showModal(ChangePasswordModalContainer, {
      user: currentUser,
    });
  };

  if (!currentUser) {
    return '';
  }

  let backgroundColor = '#efefef';
  if (inExperiment('remote_evaluation_experiment')) {
    backgroundColor = 'lightslategray';
  }

  return (
    <div className="header-user-profile-wrapper" ref={containerRef}>
      <div className={classnames('user-profile')}>
        {!hideName && (
          <div
            role="button"
            tabIndex="0"
            className="user-profile__label"
            onClick={toggleMenu}
            onKeyPress={handleKeyPress}
            style={{ cursor: 'pointer' }}>
            {currentUser.name}
          </div>
        )}

        <button
          type="button"
          className="user-profile__avatar"
          aria-label={intl.formatMessage({ id: 'nav.showUserProfile' }, { name: currentUser.name })}
          aria-haspopup="true"
          aria-expanded={isOpen}
          aria-controls="user-menu"
          style={{ backgroundColor: backgroundColor }}
          onClick={toggleMenu}>
          <UserPicture user={currentUser} defaultWhite className="user-profile-picture" aria-pressed={isOpen} />
        </button>

        {isOpen && (
          <div className="user-profile-options-wrapper" style={{ zIndex: 10 }} id="user-menu" role="menu">
            <div className="user-profile-options-inner">
              <div className="user-profile-options-header">
                <button
                  type="button"
                  className="user-profile-options-header__close-button"
                  onClick={toggleMenu}
                  aria-label={intl.formatMessage({ id: 'common.close' })}>
                  <Icons.Close />
                </button>

                <div className="user-profile-options-header__heading">
                  <h1>
                    <FormattedMessage id="profile.profile" />
                  </h1>
                </div>
              </div>
              <div className="user-profile-options user-profile-options-links">
                <div className="user-profile-options__user">
                  <div className="user-profile-options__user-avatar">
                    <UserPicture user={currentUser} defaultWhite className="user-profile-picture" />
                  </div>
                  <span className="user-profile-options__user-label">{currentUser.name}</span>
                </div>
                <div className="user-profile-options__link">
                  <Link alt="Profile" to={`/profile/${currentUser.id}`}>
                    <FormattedMessage id="nav.profile" />
                  </Link>
                </div>
                {!currentUser.lti_user_id && (
                  <div className="user-profile-options__link">
                    <a alt="Change Password" href="# " onClick={handleChangePassword}>
                      <FormattedMessage id="profile.changePassword" />
                    </a>
                  </div>
                )}
                {isStudent && (
                  <div className="user-profile-options__link">
                    <Link to="/student/bootcamps">
                      <FormattedMessage id="nav.myBootcamps" />
                    </Link>
                  </div>
                )}

                <div className="user-profile-options__link">
                  <a alt="Terms of Service" href="# " onClick={() => showModal(TermsOfServiceContainer)}>
                    <FormattedMessage id="user.termsOfService.heading" />
                  </a>
                </div>
                <div className="user-profile-options__link">
                  <a
                    alt="help"
                    href="https://pathstream.force.com/support"
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={toggleMenu}>
                    <FormattedMessage id="nav.help" />
                  </a>
                </div>
              </div>
            </div>

            <div className="user-profile-options-footer">
              <Link className="user-profile-options-footer__sign-out-button" to="/logout">
                <FormattedMessage id="nav.signOut" />
              </Link>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
ProfileNav.propTypes = {
  hideName: PropTypes.bool,
};
