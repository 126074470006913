import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class ProgressBar extends Component {
  static propTypes = {
    progress: PropTypes.number,
    showPercent: PropTypes.bool,
    className: PropTypes.string,
  };

  static defaultProps = {
    className: 'progress-bar-wrapper',
  };

  render() {
    const { progress, showPercent, className } = this.props;
    if (!progress) {
      return '';
    }
    const roundedProgress = Math.round(100 * progress);

    return (
      <div className={className}>
        {showPercent && (
          <div className="progress-bar-wrapper__header">
            <span className="standard-label" />
            <span className="standard-label">{roundedProgress}%</span>
          </div>
        )}
        <div className="progress-bar">
          <div className="progress-bar__element" style={{ width: `${roundedProgress}%` }}></div>
        </div>
      </div>
    );
  }
}
