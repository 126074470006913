import React, { useState } from 'react';
import { UnorderedListOutlined } from '@ant-design/icons';
import { FormattedMessage } from 'react-intl';
import RubricModal from '../../../../Projects/RubricModal';

const ViewRubricButton = ({ assignment }) => {
  const [rubricVisible, setRubricVisible] = useState(false);

  const handleShowRubric = () => {
    setRubricVisible(true);
  };

  const handleHide = () => {
    setRubricVisible(false);
  };

  return (
    <div className="assignment-actions">
      <button className="action-button" type="button" onClick={handleShowRubric} data-testid="view-rubric-button">
        <UnorderedListOutlined />
        <FormattedMessage id="cohortDashboard.assignmentsPage.viewRubric" />
      </button>

      <RubricModal assignment={assignment} onHide={handleHide} visible={rubricVisible} />
    </div>
  );
};
export default ViewRubricButton;
