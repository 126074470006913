import React from 'react';
import Icons from '../../../Utils/Icons';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';

const CurrentLesson = ({
  resumeUrl,
  history,
  lessonTitle,
  resuming,
  pathProgress,
  startPathProgress,
  accessDenied,
  currentEnrollmentAccessDate,
}) => {
  const handleResumeClick = () => {
    startPathProgress(pathProgress.id);

    history.push(resumeUrl);
  };

  const formattedDate = moment(currentEnrollmentAccessDate).format('MM/DD');

  return (
    <div className="my-current-lesson">
      <div className="left">
        <div className="current-lesson-header">
          <div className="current-lesson-label">My Current Lesson</div>
        </div>
        <div className="current-lesson-content">
          <div className="title">{lessonTitle}</div>
        </div>
      </div>
      <div className="resume">
        <button
          className={
            accessDenied
              ? 'button-standard button-standard--short button-disabled'
              : 'button-standard button-standard--short'
          }
          onClick={handleResumeClick}
          disabled={accessDenied}
          aria-label={`resume learning in ${lessonTitle}`}
          data-testid="resume-button">
          {!accessDenied && (
            <span className="icon-element icon-element--fill-white icon-element--background-transparent icon-element--size-standard">
              <Icons.FillPlay />
            </span>
          )}
          {accessDenied ? (
            <FormattedMessage id="bootcamp.accessOn" values={{ date: formattedDate }} />
          ) : resuming ? (
            <FormattedMessage id="path.studentPathProgress.resumeLearning" />
          ) : (
            <FormattedMessage id="common.start" />
          )}
        </button>
      </div>
    </div>
  );
};

export default CurrentLesson;
