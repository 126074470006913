import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import classnames from 'classnames';
import Loading from '../Utils/Loading';
import Icons from '../Utils/Icons';
import CircularProgress from '../Utils/CircularProgress';
import { formatDate } from '../../utils/utils';

export default class StudentProfileProjects extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasLoaded: false,
      activeTab: 0,
    };
  }

  handleTabSelect = activeTab => {
    this.setState({
      activeTab,
    });
  };

  componentDidMount() {
    this.getData(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.cohort !== nextProps.cohort) {
      this.getData(nextProps);
    }
  }

  getData = async props => {
    const { getProjectsForUser, user, cohort } = props;
    if (!cohort) {
      return;
    }

    const projects = await getProjectsForUser(user.id, cohort.id);
    this.setState({
      hasLoaded: true,
      projects,
    });
  };

  renderProjectRow = project => {
    const { unit_title, completed_at, graded_at, score } = project;
    const classes = classnames('graded-assignment-item', score && 'graded-assignment-item--graded');
    const iconClasses = classnames('graded-assignment-item__icon', score && 'graded-assignment-item__icon--graded');
    const submittedAt = completed_at && formatDate(completed_at); // moment(completed_at).format('h:mm a, DD MMM');
    const gradedAt = graded_at && formatDate(graded_at); // moment(graded_at).format('h:mm a, DD MMM');

    return (
      <div key={project.assignment_progress_id} className={classes}>
        <div className={iconClasses}>
          <Icons.Graded />
        </div>

        <div className="graded-assignment-item__content-wrapper">
          <div className="graded-assignment-item__content">
            <span className="graded-assignment-item__title">{unit_title}</span>
            {completed_at && (
              <span className="graded-assignment-item__date">
                <FormattedMessage id={score ? 'grading.gradedAt' : 'grading.submitted'} />
                :&nbsp;
                {score ? gradedAt : submittedAt}
              </span>
            )}
          </div>

          <div className="graded-assignment-item__buttons">
            <span className="graded-assignment-item__status">
              {score && <CircularProgress color="green" showPercent progress={score / 100} />}
              {!score && completed_at && <FormattedMessage id="grading.inGrading" />}
              {!completed_at && <FormattedMessage id="grading.notSubmitted" />}
            </span>
          </div>
        </div>
      </div>
    );
  };

  render() {
    const { hasLoaded, projects, activeTab } = this.state;

    if (!hasLoaded) {
      return <Loading />;
    }

    return (
      <div className="student-roster-content__tabs">
        <Tabs
          selectedTabClassName="tabs__item--active"
          selectedTabPanelClassName="active"
          forceRenderTabPanel
          selectedIndex={activeTab}
          onSelect={this.handleTabSelect}>
          <TabList className="tabs">
            <Tab className="tabs__item">
              <FormattedMessage id="roster.assignments" />
            </Tab>
          </TabList>
          <TabPanel>{projects.map(this.renderProjectRow)}</TabPanel>
        </Tabs>
      </div>
    );
  }
}
StudentProfileProjects.propTypes = {
  getProjectsForUser: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
};
