import React from 'react';
import PropTypes from 'prop-types';
import Loading from '../Utils/Loading';
import LessonParts from './LessonParts';
import useFetchLesson from '../../hooks/useFetchLesson';

export default function LessonShow({ lessonId }) {
  const { lesson, blocks } = useFetchLesson(lessonId);

  const allAdaptiveAttrs = blocks.reduce((acc, obj) => {
    if (obj.adaptive_tracks) {
      obj.adaptive_tracks.forEach(track => {
        acc.add(track);
      });
    }
    return acc;
  }, new Set());

  const adaptiveAttrs = Array.from(allAdaptiveAttrs);

  if (!lesson) {
    return (
      <article className="lesson-part lesson-part--normal">
        <div className="lesson-part__wrapper">
          <Loading />
        </div>
      </article>
    );
  }

  return <LessonParts lesson={lesson} adaptiveAttributes={adaptiveAttrs} />;
}

LessonShow.propTypes = {
  lessonId: PropTypes.string,
};
