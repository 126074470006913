import { datadogRum } from '@datadog/browser-rum';
import { setSentryUser } from '../helpers/sentryHelper';
import * as analytics from '../utils/analytics';

export const analyticsSetUser = user => {
  const { id, name, email } = user;

  window.FS?.identify(id, { displayName: name, email });
  setSentryUser({ id, name, email });
  analytics.setUser(user);
  datadogRum.setUser({ id, name, email });
  intercomChatWidget(user);
};

export const analyticsClearUser = () => {
  window.FS?.identify(false);
  setSentryUser();
  analytics.endSession();
  datadogRum.clearUser();
};

export const intercomChatWidget = user => {
  const isStaging = window.location.hostname.includes('staging');
  let appId = isStaging ? 'asp7nv75' : 'asbwuofu';

  window.Intercom('boot', {
    api_base: 'https://api-iam.intercom.io',
    app_id: appId,
    user_id: user.segment_id,
    name: user.name,
    email: user.email,
    created_at: user.created_at,
  });
};
