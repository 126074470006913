import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import MetricCard from './MetricCard';
import Icons from '../../../../../Utils/Icons';
import intl from '../../../../../../utils/intl';

const LastLoginCard = ({ lastLogin }) => {
  const lastLoginText = lastLogin
    ? moment(lastLogin).format('hh:mma MMM D, YYYY')
    : intl.formatMessage({ id: 'cohortDashboard.studentsPage.notAvailable' });

  const metric = {
    icon: <Icons.LastLogin />,
    metricKey: intl.formatMessage({ id: 'cohortDashboard.studentsPage.lastLogin' }),
    metricValue: lastLoginText,
  };

  return <MetricCard {...metric} />;
};

LastLoginCard.propTypes = {
  lastLogin: PropTypes.string.isRequired,
};

export default LastLoginCard;
