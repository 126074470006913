import axios from './axios';

export default class AppSite {
  static all(_) {
    return axios.get('/labs/app_sites').then(response => response.data);
  }

  static appStream(_) {
    return axios.get('/labs/app_sites/app_stream').then(response => response.data);
  }

  static save(_, appSite) {
    if (appSite.id) {
      return axios.put(`/labs/app_sites/${appSite.id}`, appSite).then(response => response.data);
    }

    return axios.post('/labs/app_sites', appSite).then(response => response.data);
  }
}
