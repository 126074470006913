import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class Description extends Component {
  render() {
    const { item } = this.props;
    const { description } = item;

    if (!description) {
      return null;
    }

    return (
      <div className="description">
        <p dangerouslySetInnerHTML={{ __html: description }} />
      </div>
    );
  }
}
Description.propTypes = {
  item: PropTypes.object.isRequired,
};
