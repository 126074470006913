import { connect } from 'react-redux';
import { saveUnit } from '../../../actions/unitActions';
import AddUnit from './AddUnit';

const mapStateToProps = (state, ownProps) => {
  const section = ownProps.section;

  return {
    section,
  };
};

export default connect(mapStateToProps, {
  saveUnit,
})(AddUnit);
