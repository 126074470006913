import React from 'react';
import { isEmpty as _isEmpty } from 'lodash';
import { useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import useTutorialContext from '../../../context/Tutorial/useTutorialContext';
import showModal from '../../../utils/showModal';
import EditStepModal from './EditStepModal';
import { saveStep } from '../../../actions/stepActions';

export default function StepFooter(props) {
  const {
    bootcamp,
    tutorial,
    currentStepIndex,
    steps,
    hasPreviousStep,
    hasNextStep,
    isEditMode,
    movePrevious,
    moveNext,
    isReady,
  } = useTutorialContext();
  const { onStepChanged, onFinishClick } = props;

  const progress = _isEmpty(steps) ? 0 : ((currentStepIndex + 1) / steps.length) * 100;
  const dispatch = useDispatch();

  const editStep = step => {
    showModal(EditStepModal, {
      tutorial,
      step,
      bootcamp,
      onSave: stepToSave => dispatch(saveStep(stepToSave)),
    });
  };

  return (
    <footer className="lab-exercise__footer">
      <div className="lab-exercise__progress">
        <div className="lab-exercise__progress-bar" style={{ width: `${progress}%` }} />
      </div>
      <div className="lab-exercise__controls">
        {hasPreviousStep() && (
          <button
            type="button"
            className="lab-button lab-button--medium lab-button--outline  lab-button--radius previous-step-button"
            onClick={() => movePrevious(onStepChanged)}>
            <FormattedMessage id="common.previous" />
          </button>
        )}
        {hasNextStep() && (
          <button
            type="button"
            className="lab-button lab-button--medium lab-button--blue lab-button--radius next-step-button"
            onClick={() => moveNext(onStepChanged)}>
            <FormattedMessage id="common.next" />
          </button>
        )}
        {!isEditMode && !hasNextStep() && (
          <button
            type="button"
            disabled={!isReady && !isEditMode}
            className="lab-button lab-button--medium lab-button--blue  lab-button--radius"
            onClick={onFinishClick}>
            <FormattedMessage id="tutorial.finish" />
          </button>
        )}
      </div>
      {isEditMode && (
        <div className="lab-exercise__controls">
          <button
            type="button"
            className="lab-button lab-button--medium lab-button--outline lab-button--radius"
            onClick={() => editStep({ tutorial_id: tutorial.id })}>
            <FormattedMessage id="tutorial.addStep" />
          </button>
        </div>
      )}
    </footer>
  );
}

StepFooter.propTypes = {
  onStepChanged: PropTypes.func,
  onFinishClick: PropTypes.func,
};
