import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Loading from '../../Utils/Loading';
import ContentWrapperContainer from '../../v2/Nav/ContentWrapperContainer';
import LabInstructions from '../../Student/LabInstructions';
import * as analytics from '../../../utils/analytics';
import { isTrailheadApp } from '../../../helpers/labHelper';
import { setPageTitle } from '../../../helpers/pageHelper';
import QuizOnLab from '../../Quiz/QuizOnLab';
import StudentBlockQuizShow from '../../Student/BlockQuizShow';
import Tutorial from '../../../api/tutorial';
import { TutorialDataProvider } from '../../../context/Tutorial/TutorialContext';
import LabStepWrapper from '../Steps/LabStepWrapper';
import StepShow from '../Steps/StepShow';
import { DismissIntro } from '../../Labs/LabIntro';
import LabPageContent from '../../Labs/LabPageContent';
import LabWrapper from '../../v2/Nav/LabWrapper';
import LabToolbar from '../../Labs/LabToolbar';

export default class TutorialShow extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoadingLesson: false,
      isLoadingTutorial: false,
      hasEnded: false,
    };
  }

  componentDidUpdate() {
    const { bootcamp, tutorial } = this.props;
    if (bootcamp && tutorial) {
      const params = {
        course: bootcamp.title,
        project: tutorial.title,
      };
      setPageTitle('pageName.course.project', params);
    }
  }

  componentDidMount() {
    const { component, bootcampId } = this.props;

    if (!component) {
      this.props.fetchBootcamp(bootcampId);
    }
    analytics.pageStart('Edit Lab');
  }

  analyticsParams = () => {
    const { bootcamp, section, unit, component, tutorial } = this.props;
    return {
      bootcamp,
      section,
      module: unit,
      lesson: component,
      lab: tutorial,
    };
  };

  componentWillUnmount() {
    analytics.pageEnd({
      ...this.analyticsParams(),
      includeIdle: true,
    });
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    this.fetchData(nextProps);
  }

  fetchData = props => {
    const { lessonId, lesson, tutorialId, tutorial, fetchTutorial, fetchLesson } = props;
    const { isLoadingLesson, isLoadingTutorial } = this.state;

    if (!lesson && lessonId && !isLoadingLesson) {
      this.setState({ isLoadingLesson: true });
      fetchLesson(lessonId);
    }

    if (!tutorial && tutorialId && !isLoadingTutorial) {
      this.setState({ isLoadingTutorial: true });
      fetchTutorial(tutorialId);
    }
  };

  handleDoneClick = () => {
    const { tutorial, lessonUrlObject } = this.props;
    let nextUrl;
    if (tutorial.end_button_type === Tutorial.NEXT_LESSON && lessonUrlObject.next) {
      nextUrl = lessonUrlObject.next.url;
    } else {
      nextUrl = lessonUrlObject.current.url;
    }

    this.setState({ hasEnded: true }, () => {
      const { history } = this.props;
      setTimeout(() => {
        history.push(nextUrl);
      }, 0);
    });
  };

  handleStepSync = projectUrl => {
    this.setState({ streamingUrl: projectUrl });
  };

  render() {
    const { bootcamp, tutorial, steps, isEditMode, lessonUrlObject, component, lesson, projectFolderPath } = this.props;
    const { hasEnded, streamingUrl } = this.state;

    if (!tutorial) {
      return (
        <ContentWrapperContainer hideStaffNav className="lab-page">
          <Loading />
        </ContentWrapperContainer>
      );
    }

    return (
      <LabWrapper app={tutorial.app}>
        <QuizOnLab
          render={(quizOnLab, renderQuiz) => (
            <TutorialDataProvider
              component={component}
              bootcamp={bootcamp}
              lesson={lesson}
              tutorial={tutorial}
              steps={steps}
              isEditMode={isEditMode}
              lessonUrlObject={lessonUrlObject}
              projectFolderPath={projectFolderPath}>
              <LabToolbar />
              <div className="new-lab-page">
                <main className="new-lab-page__main">
                  {tutorial && !isTrailheadApp(tutorial.app) && (
                    <LabInstructions
                      title={tutorial.title || component.title}
                      backLink={lessonUrlObject.current ? lessonUrlObject.current.url : null}>
                      <LabStepWrapper
                        StepComponent={StepShow}
                        quizOnLab={quizOnLab}
                        onFinishClick={this.handleDoneClick}
                      />
                    </LabInstructions>
                  )}
                  <LabPageContent
                    {...{ tutorial, bootcamp, projectFolderPath, component, steps, hasEnded, renderQuiz }}
                    saveToS3={false}
                    preview
                    streamingUrl={streamingUrl}
                    analyticsParams={this.analyticsParams()}
                    QuizComponent={StudentBlockQuizShow}
                    questionProgressesEnabled={false}
                    saveBackups={false}
                    useFreshCopy={true}
                  />
                </main>
              </div>
              <DismissIntro />
            </TutorialDataProvider>
          )}
        />
      </LabWrapper>
    );
  }
}

TutorialShow.propTypes = {
  bootcamp: PropTypes.object,
  bootcampId: PropTypes.string,
  unit: PropTypes.object,
  section: PropTypes.object,
  lesson: PropTypes.object,
  component: PropTypes.object,
  tutorial: PropTypes.object,
  block: PropTypes.object,
  steps: PropTypes.array,
  // eslint-disable-next-line react/no-unused-prop-types
  fetchTutorial: PropTypes.func.isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  fetchLesson: PropTypes.func.isRequired,
  fetchBootcamp: PropTypes.func.isRequired,
  saveStep: PropTypes.func.isRequired,
  isEditMode: PropTypes.bool,
  history: PropTypes.object,
  lessonUrlObject: PropTypes.object,
  projectFolderPath: PropTypes.string,
};
