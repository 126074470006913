import React from 'react';
import PropTypes from 'prop-types';

import EditQuiz from '../Quizzes/EditQuiz';
import StudentBlockGradedQuizShow from '../../Student/BlockGradedQuizShow';
import BlockEditOptionsContainer from './BlockEditOptionsContainer';
import BlockHeader from '../../Utils/BlockHeader';
import QuizTabsContainer from '../../GradedQuiz/QuizTabsContainer';
import QuizLoaderContainer from '../../GradedQuiz/LoaderContainer';

const BlockGradedQuizShow = ({ isEditMode, index, block, totalBlocks, onReorder }) => {
  if (!isEditMode) {
    return <StudentBlockGradedQuizShow block={block} questionProgressesEnabled={false} />;
  }

  return (
    <>
      <BlockEditOptionsContainer
        block={block}
        index={index}
        total={totalBlocks}
        onReorder={onReorder}
        onDelete={null}
      />
      <div className="section">
        <div className="block-label">
          <BlockHeader messageId="common.gradedQuiz" />

          <div className="block-label__content">
            <div className="knowledge-check-wrapper">
              <EditQuiz forGradedQuiz block={block} QuizTabs={QuizTabsContainer} QuizLoader={QuizLoaderContainer} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

BlockGradedQuizShow.propTypes = {
  block: PropTypes.object.isRequired,
  isEditMode: PropTypes.bool,
  index: PropTypes.number.isRequired,
  totalBlocks: PropTypes.number.isRequired,
  onReorder: PropTypes.func.isRequired,
};

export default BlockGradedQuizShow;
