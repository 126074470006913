import React from 'react';
import PropTypes from 'prop-types';
import { size as _size } from 'lodash';
import { FormattedMessage } from 'react-intl';
import RTEContent from '../../helpers/contentMathjaxHelper';
import Icons from '../../components/Utils/Icons';

/* eslint-disable complexity*/
export const FinalAnswersContainer = ({ questionProgresses, questions }) => {
  let questionProgressArr = Object.values(questionProgresses);

  const answers = questions.map(question => {
    const qp = questionProgressArr.find(qp => qp.question_id === question.id);
    return {
      question: question.prompt,
      choices: qp.choices,
      questionAnsweredCorrectly: qp.user_score === 1,
      explanation: question.explanation,
      key: question.id,
    };
  });

  const styleClassForChoice = choice => {
    const isSelected = choice.is_selected;
    const isCorrect = choice.is_correct;
    const isSelectedAndCorrect = isSelected && isCorrect;
    const isSelectedAndWrong = isSelected && !isCorrect;

    if (isSelectedAndCorrect) {
      return 'final-result-choice-correct-selected';
    }

    if (isSelectedAndWrong) {
      return 'final-result-choice-incorrect';
    }

    if (isCorrect) {
      return 'final-result-choice-correct-unselected';
    }

    return 'final-result-choice';
  };

  const choiceIsSelectedAndWrong = choice => choice.is_selected && !choice.is_correct;

  const choiceIsSelectedAndRight = choice => choice.is_selected && choice.is_correct;

  const choiceIsNotSelectAndRight = choice => !choice.is_selected && choice.is_correct;

  return (
    <>
      {answers.map((answer, index) => {
        return (
          <div key={index}>
            <div className="final-result-graded-quiz-wrapper">
              <div className="final-answers-questions-wrapper">
                {answer.questionAnsweredCorrectly ? <Icons.CircleCheck3 /> : <Icons.CircleX />}
                <RTEContent content={answer.question} classSelector="final-result-questions" />
              </div>
              <div className="final-result-choices">
                {answer.choices.map((choice, index) => {
                  return (
                    <div className="result-choice-margins" key={index}>
                      <RTEContent content={choice.prompt} classSelector={styleClassForChoice(choice)} />
                      {choiceIsSelectedAndWrong(choice) && (
                        <div className="final-result-choice-incorrect-message">
                          <FormattedMessage id={'gradedQuiz.incorrectChoiceMessage'} />
                        </div>
                      )}
                      {choiceIsSelectedAndRight(choice) && (
                        <div className="final-result-choice-correct-or-missed-message">
                          <FormattedMessage id={'gradedQuiz.correctChoiceMessage'} />
                        </div>
                      )}
                      {choiceIsNotSelectAndRight(choice) && (
                        <div className="final-result-choice-correct-or-missed-message">
                          <FormattedMessage id={'gradedQuiz.missedCorrectChoiceMessage'} />
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
              <div className="feedback-ui-border">Feedback</div>
              <RTEContent content={answer.explanation} classSelector="final-result-choice-explanation" />
            </div>
          </div>
        );
      })}
    </>
  );
};

FinalAnswersContainer.propTypes = {
  questionProgress: PropTypes.object,
  questions: PropTypes.array,
};
