import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectCohortEnrollments } from '../../../../../selectors/enrollmentSelectors';
import { selectUsers } from '../../../../../selectors/userSelectors';
import { selectCohort } from '../../../../../selectors/cohortSelectors';
import { selectAssignmentProgressesByEnrollmentIds } from '../../../../../selectors/assignmentProgressSelectors';
import { selectProjectsForBootcamp } from '../../../../../selectors/unitSelectors';
import { selectAllBootcampInstanceAssignmentByCompositeKeyForAssignments } from '../../../../../selectors/bootcampInstanceAssignmentSelectors';
import Enrollment from '../../../../../api/enrollment';
import {
  checkAssignmentAverageGradeCriteria,
  checkAssignmentDueDateCriteria,
  checkCohortProgressCriteria,
  checkLoginCriteria,
} from '../helpers/StudentsToWatchCriteria';
import { map as _map } from 'lodash';

const useCohortStudents = cohortId => {
  const users = useSelector(selectUsers);
  const cohort = useSelector(state => selectCohort(state, cohortId));
  const cohortUnits = useSelector(state => selectProjectsForBootcamp(state, cohort?.bootcamp_id));

  const hiddenUnits = _map(cohort && cohort.hidden_items, item => item.split('::')[1]);
  const units = cohortUnits && cohortUnits.filter(unit => !hiddenUnits.includes(unit.id));

  const enrollments = useSelector(state => selectCohortEnrollments(state, cohortId));
  const enrollmentIds = enrollments.map(enrollment => enrollment.id);
  const assignmentProgresses = useSelector(state => selectAssignmentProgressesByEnrollmentIds(state, enrollmentIds));
  const assignmentIds = units ? units.map(unit => unit.assignment_id) : [];
  const bootcampInstanceAssignments = useSelector(
    selectAllBootcampInstanceAssignmentByCompositeKeyForAssignments(assignmentIds, cohortId),
  );

  // eslint-disable-next-line complexity
  const checkIfInWatchList = student => {
    let value = {
      inWatchList: false,
      reasons: [],
    };

    // enrollment status is COMPLETED or DROPPED
    if ([Enrollment.COMPLETED, Enrollment.DROPPED].includes(student.enrollment.status)) {
      return value;
    }

    // 1. No logins in the past 5 days
    const loginValue = checkLoginCriteria(student);
    if (loginValue.inWatchList) {
      value.reasons.push(loginValue.reason);
    }

    // 2. Average assignment grade below 70%
    const assignmentAverageGradeValue = checkAssignmentAverageGradeCriteria(student.assignmentProgresses);
    if (assignmentAverageGradeValue.inWatchList) {
      value.reasons.push(assignmentAverageGradeValue.reason);
    }

    // 3. Behind on assignments
    let behindOnAssignmentValue;
    if (bootcampInstanceAssignments.length === units.length) {
      // 1. If assignment has due dates — behind if haven't submitted an assignment where the due date has passed
      behindOnAssignmentValue = checkAssignmentDueDateCriteria(
        student.assignmentProgresses,
        bootcampInstanceAssignments,
      );
    } else {
      // 2. If assignment does not have a due date — if haven't submitted 50% of assignments by 50% through the course (based on start and end date) — and same for 75% and 100% of the course
      behindOnAssignmentValue = checkCohortProgressCriteria(cohort, student.assignmentProgresses, units.length);
    }
    if (behindOnAssignmentValue.inWatchList) {
      value.reasons.push(behindOnAssignmentValue.reason);
    }
    value.inWatchList =
      loginValue.inWatchList || assignmentAverageGradeValue.inWatchList || behindOnAssignmentValue.inWatchList;

    return value;
  };

  const students = useMemo(() => {
    const studentsIds = enrollments ? enrollments.map(enrollment => enrollment.user_id) : [];

    return users
      .filter(user => studentsIds.includes(user.id))
      .map(user => {
        const enrollment = enrollments.find(enrollment => enrollment.user_id === user.id);
        let student = {
          ...user,
          enrollment: enrollment,
          ungraded_assignments_count: assignmentProgresses.filter(
            assignmentProgress =>
              assignmentProgress.user_id === user.id &&
              !!assignmentProgress.completed_at &&
              !assignmentProgress.graded_at,
          ).length,
          assignmentProgresses: assignmentProgresses.filter(
            assignmentProgress => assignmentProgress.user_id === user.id,
          ),
        };
        student.watchList = checkIfInWatchList(student);

        return student;
      });
  }, [enrollments, users, assignmentProgresses]);

  return {
    students,
  };
};

export default useCohortStudents;
