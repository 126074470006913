import React, { Component } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import NavigatorContainer from './NavigatorContainer';
import { ReactComponent as SvgIconHamburgerWhite } from '../../styles/pathstream/svg-jsx/icon-hamburger-white.svg';
import { ReactComponent as SvgLogoOnDark } from '../../styles/pathstream/svg-jsx/logo-onDark.svg';
import ProfileNav from './ProfileNav';
import { getDefaultPath } from '../../helpers/pathHelper';

export default class Header extends Component {
  static propTypes = {
    currentPathId: PropTypes.string,
    currentSectionId: PropTypes.string,
    currentUnitId: PropTypes.string,
    currentComponentId: PropTypes.string,
    currentUser: PropTypes.object,
    enableEditMode: PropTypes.func.isRequired,
    hasSideNav: PropTypes.bool,
    isCollapsed: PropTypes.bool.isRequired,
    signOut: PropTypes.func.isRequired,
    toggleSideNav: PropTypes.func.isRequired,
    headerText: PropTypes.string,
    hideAuthorToggle: PropTypes.bool,
    home: PropTypes.shape({
      text: PropTypes.string,
      url: PropTypes.string,
    }),
  };

  static defaultProps = {
    hasSideNav: false,
  };

  getUserHeader = () => {
    const {
      currentPathId,
      currentSectionId,
      currentUnitId,
      currentComponentId,
      currentUser,
      hasSideNav,
      toggleSideNav,
      headerText,
      isStudent,
      hideAuthorToggle,
      home,
    } = this.props;

    const navigatorActive = currentUnitId || headerText;
    const classes = classnames('hdr-global', navigatorActive && 'navigator-active');

    return (
      <header className={classes}>
        <div className="hdr-global-inner">
          <Link title="Homepage link" className="logo header" to={getDefaultPath(currentUser)}>
            <SvgLogoOnDark />
          </Link>
          {hasSideNav && (
            <button onClick={toggleSideNav} className="menu-hamburger-btn" aria-label="Menu Open Button">
              <SvgIconHamburgerWhite />
            </button>
          )}
          {!hasSideNav && home && (
            <Link
              to={{ pathname: home.url }}
              style={{
                display: 'inline-block',
                padding: '30px 10px',
                color: 'white',
                textDecoration: 'none',
              }}>
              {home.text}
            </Link>
          )}
          {(currentUnitId || headerText) && (
            <NavigatorContainer
              currentPathId={currentPathId}
              currentSectionId={currentSectionId}
              currentUnitId={currentUnitId}
              currentComponentId={currentComponentId}
              headerText={headerText}
            />
          )}

          <ProfileNav
            isStudent={isStudent}
            currentUser={currentUser}
            signOut={this.props.signOut}
            hideAuthorToggle={hideAuthorToggle}
          />
        </div>
      </header>
    );
  };

  render() {
    return (
      <React.Fragment>
        <a href="#main" className="visuallyhidden focusable skiplink">
          Skip to main content
        </a>
        {this.getUserHeader()}
      </React.Fragment>
    );
  }
}
