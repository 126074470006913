import React from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import Template from './Template';
import Icons from '../Utils/Icons';

const ResetPasswordSuccess = () => {
  return (
    <Template welcomeMsgId="user.login.welcomeMsgId" taglineId="user.login.taglineId">
      <div className="modal-dialog-body modal-dialog-body--small ">
        <div className="check-icon">
          <Icons.CircleCheck4 />
        </div>
        <h5 className="title">
          <FormattedMessage id="user.setPassword.setConfirmation" />
        </h5>
        <p>
          <FormattedMessage id="user.setPassword.setSuccess" />
        </p>
      </div>
      <div className="modal-dialog-footer modal-dialog-footer--centered">
        <Link to="/login" className="button-standard button-standard--grey button-standard--medium new-auth-btn">
          <FormattedMessage id="user.login.submitBtn" />
        </Link>
      </div>
    </Template>
  );
};

export default ResetPasswordSuccess;
