import LearningHome from './LearningHome';
import { connect } from 'react-redux';
import { fetchBootcamps } from '../../../actions/bootcampActions';
import { fetchCurrentEnrollments, fetchEnrollment } from '../../../actions/enrollmentActions';
import { selectEnrolledBootcamps, selectEnrollment, selectEnrollments } from '../../../selectors/enrollmentSelectors';
import { selectCurrentUser } from '../../../selectors/authSelectors';
import { fetchCohorts } from '../../../actions/cohortActions';
import { selectCohorts } from '../../../selectors/cohortSelectors';

const mapStateToProps = state => {
  const bootcamps = selectEnrolledBootcamps(state);
  const currentUser = selectCurrentUser(state);
  const enrollments = selectEnrollments(state);
  const cohorts = selectCohorts(state);
  let progressableEnrollment = null;
  if (currentUser?.current_enrollment?.id) {
    // Find latest enrollment in redux store, if available, then fallback to currentUser's copy.
    const currentEnrollment = selectEnrollment(state, currentUser.current_enrollment.id);
    progressableEnrollment = currentEnrollment ? currentEnrollment : currentUser.current_enrollment;
  }

  return {
    bootcamps,
    currentUser,
    enrollments,
    currentEnrollment: progressableEnrollment,
    cohorts,
  };
};

export default connect(mapStateToProps, { fetchCurrentEnrollments, fetchEnrollment, fetchBootcamps, fetchCohorts })(
  LearningHome,
);
