import { useEffect, useRef } from 'react';

export default function useHandleOutsideClick(enabled, onOutsideClick) {
  const containerRef = useRef(null);

  useEffect(() => {
    const removeListener = () => {
      document.removeEventListener('mousedown', handleClick, false);
    };

    const handleClick = e => {
      if (containerRef.current && containerRef.current.contains(e.target)) {
        return;
      }

      if (onOutsideClick) onOutsideClick();
    };

    if (!enabled) return removeListener();

    document.addEventListener('mousedown', handleClick, false);

    return removeListener;
  }, [enabled, containerRef, onOutsideClick]);

  return containerRef;
}
