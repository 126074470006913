import { Operator } from './OperatorsEnum';
import { A_CHAR_CODE } from '../utils';

export const buildAlphabet = checksAmount => {
  let symbolChar = A_CHAR_CODE;
  return Array.apply(null, Array(checksAmount)).map(() => String.fromCharCode(symbolChar++));
};

export const isAllowedSymbol = (symbol, alphabet) => {
  const isOperator = Object.values(Operator).indexOf(symbol) > -1;
  return alphabet.includes(symbol) || isOperator;
};

export const SPACE = ' ';
