import { createSelector } from 'reselect';

export const selectComponent = state => {
  return state.components.grade;
};

export const selectCurrentCohortId = createSelector([selectComponent], component => {
  return component.currentCohortId;
});

export const selectCurrentStudentId = createSelector([selectComponent], component => {
  return component.currentStudentId;
});
