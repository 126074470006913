import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ProjectShowContainer from './ProjectShowContainer';
import { selectUnit } from '../../../selectors/unitSelectors';
import { fetchUnit } from '../../../actions/unitActions';

export default function ProjectShowLanding(props) {
  const {
    location: { search },
    match: {
      params: { unitId },
    },
  } = props;
  const queryParams = new URLSearchParams(search);
  const skipElectronLaunch = queryParams.get('skipLaunch');
  const unit = useSelector(s => selectUnit(s, unitId));
  const dispatch = useDispatch();
  const [showProject, setShowProject] = useState(false);

  useEffect(() => {
    if (skipElectronLaunch) {
      setShowProject(true);
    }
  }, []);

  useEffect(() => {
    if (skipElectronLaunch) return;

    if (!unit) {
      fetchUnit(unitId)(dispatch);
      return;
    }

    setShowProject(true);
  }, [unit]);

  if (showProject) {
    return <ProjectShowContainer {...props} />;
  }

  return <div></div>;
}
