import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import intl from '../../utils/intl';
import CKEditor from '../Utils/CKEditor';
import RTEAccessibilityNote from '../Utils/RTEAccessibilityNote';
import RTEContent from '../../helpers/contentMathjaxHelper';

export default class QuestionWithAnswer extends Component {
  constructor(props) {
    super(props);

    const { questionProgress } = this.props;

    if (questionProgress && questionProgress.completed_at) {
      this.state = {
        questionState: 'answered',
        questionAnswer: questionProgress.answer,
      };
    } else {
      this.state = {
        questionState: null,
        questionAnswer: '',
      };
    }
  }

  isCurrentQuestionAnswered = () => this.state.questionAnswer && this.state.questionAnswer.length !== 0;

  handleChangeAnswer = questionAnswer => this.setState({ questionAnswer });

  handleAnswerQuestion = () => {
    if (!this.isCurrentQuestionAnswered()) {
      return;
    }

    this.setState({ questionState: 'answered' });
    this.props.submitAnswer(this.state.questionAnswer);
  };

  renderAnswered = () => (
    <div className="question-with-answer content">
      <p>
        <span className="answered">
          <FormattedMessage id="question.answered" />
        </span>{' '}
        <span dangerouslySetInnerHTML={{ __html: this.state.questionAnswer }} />
      </p>
      <p className="question-explanation">
        <span className="answered">
          <FormattedMessage id="question.explanationForStudent" />
        </span>{' '}
        <RTEContent content={this.props.question.explanation} Tag="span" />
      </p>
      {this.props.renderNext && this.props.renderNext()}
    </div>
  );

  renderUnanswered = () => (
    <>
      <div className="content textarea">
        <CKEditor
          inline
          minimal
          onChange={this.handleChangeAnswer}
          content={this.state.questionAnswer}
          defaultContent={intl.formatMessage({ id: 'aria.yourAnswer' })}
          activeClass="rte"
        />
        <RTEAccessibilityNote />
      </div>
      <div className="answer-buttons">
        {this.props.renderQuestionsNavigation && this.props.renderQuestionsNavigation()}
        <button
          type="submit"
          onClick={this.handleAnswerQuestion}
          aria-label={intl.formatMessage({ id: 'question.submitAnswer' })}
          disabled={!this.isCurrentQuestionAnswered()}
          className="button-standard button-standard--blue-9 button-standard--short">
          <FormattedMessage id="question.submitAnswer" />
        </button>
      </div>
    </>
  );

  render() {
    return this.state.questionState === 'answered' ? this.renderAnswered() : this.renderUnanswered();
  }
}

QuestionWithAnswer.propTypes = {
  question: PropTypes.object.isRequired,
  questionProgress: PropTypes.object,
  submitAnswer: PropTypes.func.isRequired,
  renderNext: PropTypes.func,
};
