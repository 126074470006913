import { canLearn, canAdministrate, canCoach, canEvaluate, canAuthor } from './userAuth';

export const getDefaultPath = user => {
  if (!user) {
    return '/login';
  }

  if (canLearn(user)) {
    return '/student/';
  }

  if (canAdministrate(user)) {
    return '/admin/users';
  }

  if (canEvaluate(user)) {
    return '/evaluator/grader_queue';
  }

  if (canCoach(user)) {
    return '/coach/dashboard';
  }

  if (canAuthor(user)) {
    return '/author/bootcamps';
  }

  return '/';
};

export const getPathPrefix = user => {
  let prefix;
  if (canLearn(user)) {
    prefix = '/student';
  } else if (canAuthor(user)) {
    prefix = '/author';
  } else if (canCoach(user)) {
    prefix = '/coach';
  } else if (canEvaluate(user)) {
    prefix = '/evaluator';
  } else {
    // Should we throw errors here?
    prefix = '/author';
  }
  return prefix;
};

export const getCoursePath = (user, bootcamp, path) => {
  if (!path) {
    return '#';
  }

  return getCoursePathByIds(user, bootcamp.id, path.id);
};

export const getCoursePathByIds = (user, bootcampId, pathId) =>
  `${getPathPrefix(user)}/bootcamps/${bootcampId}/paths/${pathId}`;

export const getCohortDashboardPath = (cohortId, { tab, assignmentId, studentId }) => {
  let path = `/coach/dashboard/cohorts/${cohortId}`;
  if (tab) {
    path = `${path}?tab=${tab}`;
  }
  if (assignmentId) {
    path = `${path}&assignmentId=${assignmentId}`;
  }
  if (studentId) {
    path = `${path}&studentId=${studentId}`;
  }
  return path;
};
