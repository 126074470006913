import React from 'react';

const Tag = ({ text, color }) => {
  return (
    <div className={`tag-component ${color}`}>
      {text}
    </div>
  );
}

export default Tag;
