const modalDefaultProps = {
  width: 640,
  footer: null,
  closable: false,
  // Needs  in order to not overlay the sidebar for lab model.
  // Also related to .lab-message class at _lab-components.scss z-index 10
  zIndex: 10,
  centered: true,
};

export { modalDefaultProps };
