import { map as _map } from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Element } from 'react-scroll';
import UnitTileContainer from '../Units/UnitTileContainer';
import ActionButtons from '../Utils/ActionButtons';
import { confirmInput } from '../../utils/confirmInput';
import { toastError } from '../../utils/toastHelper';
import AddUnitContainer from '../Author/Units/AddUnitContainer';
import ReorderButtons from '../Utils/ReorderButtons';
import SectionTileHeaderContainer from './SectionTileHeaderContainer';

export default class SectionTile extends Component {
  handleEdit = () => {
    const { section } = this.props;
    this.props.editSection(section);
  };

  handleDelete = () => {
    const { pathId, section } = this.props;

    confirmInput({
      message: <FormattedMessage id="section.confirmDelete" values={{ name: section.title }} />,
      onOk: () => {
        this.props.destroyPathSection(section, pathId).catch(err => {
          console.log('Error occurred', err);
          toastError(err.value.errors, { autoClose: 5000 });
        });
      },
    });
  };

  unitProps = unit => {
    const {
      section,
      isEditMode,
      currentUnitProgress,
      sectionProgress,
      currentSectionProgress,
      units,
      bootcamp,
    } = this.props;

    return {
      bootcamp,
      section,
      unit,
      isEditMode,
      currentUnitProgress,
      isCurrentSectionSelected: sectionProgress && sectionProgress === currentSectionProgress,
      units,
      totalUnits: units.length,
    };
  };

  render() {
    const { index, section, sectionProgress, units, isEditMode, totalSections, onReorder, bootcamp } = this.props;

    return (
      <Element name={`section_${section.id}`} className="units-container" data-testid={`section_${section.id}`}>
        <SectionTileHeaderContainer section={section} sectionProgress={sectionProgress} index={index}>
          {isEditMode && (
            <div className="learning-path-item-reorder-button">
              <ReorderButtons index={index} total={totalSections} onReorder={onReorder} />
            </div>
          )}
          <ActionButtons
            isEditMode={isEditMode}
            className="learning-path-item-edit-delete-buttons"
            onEdit={this.handleEdit}
            onDelete={this.handleDelete}
          />
        </SectionTileHeaderContainer>

        <div className="units-container__content">
          {isEditMode && (
            <>
              <AddUnitContainer section={section} bootcamp={bootcamp} />
            </>
          )}

          {_map(units, (unit, index) => (
            <UnitTileContainer index={index} key={unit.id} {...this.unitProps(unit)} />
          ))}
        </div>
      </Element>
    );
  }
}
SectionTile.propTypes = {
  index: PropTypes.number.isRequired,
  section: PropTypes.object.isRequired,
  sectionProgress: PropTypes.object,
  currentUnitProgress: PropTypes.object,
  currentSectionProgress: PropTypes.object,
  units: PropTypes.array.isRequired,
  bootcamp: PropTypes.object.isRequired,
};
