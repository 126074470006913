import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import BootcampParent from './Bootcamps/BootcampParent';
import NotFound from '../NotFound';
import { selectCurrentUser } from '../../selectors/authSelectors';
import { selectBootcamps } from '../../selectors/bootcampSelectors';

class Author extends Component {
  render() {
    const { match } = this.props;

    return (
      <Switch>
        <Route path={`${match.path}/bootcamps`} component={BootcampParent} />
        <Route component={NotFound} />
      </Switch>
    );
  }
}

const mapStateToProps = state => {
  const currentUser = selectCurrentUser(state);
  const bootcamps = selectBootcamps(state);

  return {
    currentUser,
    bootcamps,
  };
};

export default connect(mapStateToProps)(Author);
