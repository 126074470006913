// TODO: rewrite to smaller components
// TODO: separate components to smaller and single purpose components
// TODO: to remove overloading functions
import React, { useState } from 'react';
import { Collapse, Form } from 'antd';

import RulesetBuilder from '../BuilderParts/RulesetBuilder/RulesetBuilder';
import RulesetCriterionBuilder from '../BuilderParts/RulesetCriterionBuilder/RulesetCriterionBuilder';
import RuleBuilder from '../BuilderParts/RuleBuilder/RuleBuilder';
import CheckBuilder from '../BuilderParts/CheckBuilder/CheckBuilder';
import RulesetViewer from '../ViewerParts/RulesetViewer/RulesetViewer';
import RulesetCriterionViewer from '../ViewerParts/RulesetCriterionViewer/RulesetCriterionViewer';
import RuleViewer from '../ViewerParts/RuleViewer/RuleViewer';
import CheckViewer from '../ViewerParts/CheckViewer/CheckViewer';
import EmptyRuleset from '../shared/EmptyRuleset/EmptyRuleset';
import { AutograderLevel } from '../../utils/constants/AutograderEnums';
import {
  removeCheck,
  removeRule,
  removeRulesetCriterion,
  updateCheck,
  updateRule,
  updateRuleset,
  updateRulesetCriterion,
} from './helpers';
import { useAutograderDispatch } from '../../api/store';
import { useCriteriaByAssignment } from '../../hooks/useCriteriaByAssignment';
import { updateAutograderRuleset } from '../../../actions/autograderRulesetActions';
import { useAutograderRuleset } from '../../hooks/useAutograderRuleset';

const { Panel } = Collapse;

const AutograderRulesetBuilder = ({ match: { params } }) => {
  const { id: rulesetId } = params;
  const ruleset = useAutograderRuleset(rulesetId);
  const assignmentId = ruleset?.assignmentId;
  const dispatch = useAutograderDispatch();

  const [form] = Form.useForm();
  const [editableFieldKeys, setEditableFieldKeys] = useState({});
  const criteria = useCriteriaByAssignment(assignmentId);

  // eslint-disable-next-line complexity
  const onFinish = values => {
    const type =
      (values[AutograderLevel.RULESET] && AutograderLevel.RULESET) ||
      (values[AutograderLevel.RULESET_CRITERION] && AutograderLevel.RULESET_CRITERION) ||
      (values[AutograderLevel.RULE] && AutograderLevel.RULE) ||
      (values[AutograderLevel.CHECK] && AutograderLevel.CHECK);

    if (values.otherProps && values.otherProps.isCanceled) {
      setEditableFieldKeys({});
      form.resetFields();
    } else {
      if (!type) return;

      const fieldValue = { ...values[type] };
      let updatedRuleset = {};

      const { rulesetCriterionKey, ruleKey, checkKey } = editableFieldKeys;

      if (type === AutograderLevel.RULESET) {
        updatedRuleset = updateRuleset({ fieldValue, ruleset });
      } else if (type === AutograderLevel.RULESET_CRITERION) {
        updatedRuleset = updateRulesetCriterion({ type, fieldValue, rulesetCriterionKey, ruleset });
      } else if (type === AutograderLevel.RULE) {
        updatedRuleset = updateRule({ type, fieldValue, rulesetCriterionKey, ruleKey, ruleset });
      } else if (type === AutograderLevel.CHECK) {
        updatedRuleset = updateCheck({ type, fieldValue, rulesetCriterionKey, ruleKey, checkKey, ruleset, values });
      } else {
        // Throw error???
        return;
      }

      setEditableFieldKeys({});
      updateAutograderRuleset(ruleset.id, { ...updatedRuleset, assignmentId: ruleset.assignmentId })(dispatch);
      form.resetFields();
    }
  };

  const updateFieldKeys = fieldKeys => {
    setEditableFieldKeys(fieldKeys);
  };

  const handleRemove = ({ rulesetCriterionKey, ruleKey, checkKey }) => {
    let updatedRuleset;
    if (checkKey) {
      updatedRuleset = removeCheck(
        {
          rulesetCriterionKey,
          ruleKey,
          checkKey,
        },
        ruleset,
      );
    } else if (ruleKey) {
      updatedRuleset = removeRule({ rulesetCriterionKey, ruleKey }, ruleset);
    } else if (rulesetCriterionKey) {
      updatedRuleset = removeRulesetCriterion({ rulesetCriterionKey }, ruleset);
    } else {
      return;
    }

    updateAutograderRuleset(ruleset.id, { ...updatedRuleset, assignmentId: ruleset.assignmentId })(dispatch);
  };

  const renderCheckViewer = (crit, fieldKeys) => {
    const updatedFieldKeys = { ...fieldKeys, ruleKey: crit.fieldKey };

    return (
      <div>
        {crit?.check?.length > 0 && (
          <div className="check-text-main-container">
            {crit.check.map((ch, index) => {
              if (ch.fieldKey && ch.fieldKey === editableFieldKeys.checkKey) {
                return (
                  <CheckBuilder
                    key={index}
                    parentForm={form}
                    // onCancel={handleCancelEdit}
                    initialValues={ch}
                  />
                );
              }

              return (
                <CheckViewer
                  check={ch}
                  fieldKeys={{ ...updatedFieldKeys, checkKey: ch.fieldKey }}
                  key={ch.fieldKey}
                  onEdit={updateFieldKeys}
                  onRemove={handleRemove}
                  orderIndex={index}
                />
              );
            })}
          </div>
        )}
        <CheckBuilder parentForm={form} fieldKeys={{ ...updatedFieldKeys }} onEdit={updateFieldKeys} />
      </div>
    );
  };

  const renderRuleViewer = (rulesetCriterion, fieldKeys) => {
    const updatedFieldKeys = { ...fieldKeys, rulesetCriterionKey: rulesetCriterion.fieldKey };

    return (
      <div>
        <Collapse bordered={false} defaultActiveKey={[]}>
          {rulesetCriterion?.criteria?.map((rule, index) => {
            const { ruleKey, checkKey } = editableFieldKeys;

            if (rule.fieldKey && !checkKey && rule.fieldKey === ruleKey) {
              return (
                <RuleBuilder key={index} parentForm={form} initialValues={rule} checksAmount={rule?.check?.length} />
              );
            }

            const RuleViewerComponent = (
              <RuleViewer
                rule={rule}
                onEdit={updateFieldKeys}
                onRemove={handleRemove}
                fieldKeys={{
                  ...updatedFieldKeys,
                  ruleKey: rule.fieldKey,
                }}
                key={index}
                orderIndex={index}
              />
            );

            return (
              <Panel className="rule-header" header={RuleViewerComponent} key={index}>
                {renderCheckViewer(rule, updatedFieldKeys)}
              </Panel>
            );
          })}
          <RuleBuilder parentForm={form} fieldKeys={{ ...updatedFieldKeys }} onEdit={updateFieldKeys} />
        </Collapse>
      </div>
    );
  };

  const renderRulesetCriterionViewer = sg => {
    const updatedFieldKeys = { rulesetKey: sg.fieldKey };

    return (
      <div>
        <Collapse bordered={false} defaultActiveKey={['0']}>
          {sg?.group?.map((rulesetCriterion, index) => {
            const { rulesetCriterionKey, ruleKey } = editableFieldKeys;

            if (rulesetCriterion.fieldKey && !ruleKey && rulesetCriterion.fieldKey === rulesetCriterionKey) {
              return (
                <RulesetCriterionBuilder
                  key={index}
                  parentForm={form}
                  initialValues={rulesetCriterion}
                  criteria={criteria}
                />
              );
            }
            const RulesetCriterionViewerComponent = (
              <RulesetCriterionViewer
                key={index}
                rulesetCriterion={rulesetCriterion}
                onEdit={updateFieldKeys}
                onRemove={handleRemove}
                fieldKeys={{ ...updatedFieldKeys, rulesetCriterionKey: rulesetCriterion.fieldKey }}
                orderIndex={index}
              />
            );
            return (
              <Panel className="ruleset-criterion-header" header={RulesetCriterionViewerComponent} key={index}>
                {renderRuleViewer(rulesetCriterion, updatedFieldKeys)}
              </Panel>
            );
          })}
        </Collapse>
        <RulesetCriterionBuilder parentForm={form} fieldKeys={{ ...updatedFieldKeys }} criteria={criteria} />
      </div>
    );
  };

  const renderRulesetViewer = () => {
    const { rulesetKey, rulesetCriterionKey } = editableFieldKeys;
    let rulesetComponent;
    if (ruleset.fieldKey && !rulesetCriterionKey && ruleset.fieldKey === rulesetKey) {
      rulesetComponent = <RulesetBuilder parentForm={form} initialValues={ruleset} />;
    } else {
      const RulesetHeader = (
        <RulesetViewer ruleset={ruleset} onEdit={updateFieldKeys} fieldKeys={{ rulesetKey: ruleset.fieldKey }} />
      );
      rulesetComponent = (
        <Panel className="ruleset-header" header={RulesetHeader}>
          {renderRulesetCriterionViewer(ruleset)}
        </Panel>
      );
    }

    return (
      <div className="rule-read-only">
        <Collapse bordered={false} defaultActiveKey={[0]}>
          {rulesetComponent}
        </Collapse>
      </div>
    );
  };

  // TODO prevent UI flicker
  if (!assignmentId || !ruleset) {
    return <EmptyRuleset />;
  }

  return (
    <Form name="ruleset-builder" form={form} onFinish={onFinish}>
      {renderRulesetViewer()}
    </Form>
  );
};

export default AutograderRulesetBuilder;
