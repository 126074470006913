import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Checkbox, Form, Input, InputNumber, Switch } from 'antd';
import { v4 as uuidv4 } from 'uuid';
import { cloneDeep } from 'lodash';
import { PlusCircleOutlined, MinusCircleOutlined } from '@ant-design/icons';

import { getLinearScoreRanges } from '../../../utils/utils';
import ImageUploader from './ModelAnswerImageUploadModal';
import { FormattedMessage } from 'react-intl';
import intl from '../../../../utils/intl';
import { AutograderLevel, OTHER_PROPS } from '../../../utils/constants/AutograderEnums';

const RulesetCriterionBuilder = ({ parentForm, initialValues, criteria }) => {
  const {
    fieldKey,
    nonLinearGradingRanges,
    criterionId,
    isModelAnswer: initIsModelAnswer,
    modelAnswerImage,
    modelAnswerDescription,
    sheetName,
    sheetTotalRows,
  } = initialValues || { name: '' };
  const [isRulesetCriterionOn, setIsRulesetCriterionOn] = useState(!!initialValues);
  const [isNonLinearGrading, setIsNonLinearGrading] = useState(!!nonLinearGradingRanges?.length);
  const [isModelAnswer, setIsModelAnswer] = useState(initIsModelAnswer);
  const [scoreRanges, setScoreRanges] = useState(
    nonLinearGradingRanges?.length ? nonLinearGradingRanges : getLinearScoreRanges(),
  );
  const [associatedSheetName, setAssociatedSheetName] = useState(sheetName);
  // TODO for V1 assume instructor map each criterion to unique ruleset. Change to undefined as possible state
  const [associatedCriterionId, setAssociatedCriterionId] = useState(criterionId || criteria[0]?.id);

  const subFieldName = AutograderLevel.RULESET_CRITERION;
  const otherProps = OTHER_PROPS;

  const handleSwitchChange = value => setIsNonLinearGrading(value);

  const handleModelChange = e => {
    const isChecked = e.target.checked;
    if (!isChecked) {
      // TODO delete images on false
    }
    setIsModelAnswer(isChecked);
  };

  const handleRangeChange = index => value => {
    const updatedScoreRanges = cloneDeep(scoreRanges);
    updatedScoreRanges[index].maxRange = value;
    setScoreRanges(updatedScoreRanges);
  };

  const renderScoreRanges = () => {
    return (
      <div className="score-range-group">
        {scoreRanges.map(({ score, maxRange }, index) => (
          <div className="score-range" key={score}>
            <span className="score">{score}:</span>
            <InputNumber
              className="score-slider"
              step={10}
              min={0}
              max={100}
              defaultValue={maxRange}
              formatter={value => `< ${value}%`}
              onChange={handleRangeChange(index)}
            />
          </div>
        ))}
      </div>
    );
  };

  const prepForSubmit = () => {
    if (associatedSheetName) {
      setIsRulesetCriterionOn(false);
    }
    parentForm.setFields([
      {
        name: [subFieldName, 'fieldKey'],
        value: initialValues ? fieldKey : uuidv4(),
      },
      {
        name: [subFieldName, 'nonLinearGradingRanges'],
        value: isNonLinearGrading ? scoreRanges : [],
      },
      {
        // TODO check if associatedCriterionId already assigned with another AutograderRulesetCriterion
        name: [subFieldName, 'criterionId'],
        value: associatedCriterionId,
      },
      {
        name: [subFieldName, 'name'],
        value: criteria.find(({ id }) => id === associatedCriterionId)?.name,
      },
    ]);
    parentForm.submit();
  };

  return (
    <div className="ruleset-criterion-part-container">
      {isRulesetCriterionOn && (
        <Form.Item required={false}>
          <div className="ruleset-criterion-main">
            <Form.Item name={[subFieldName, 'name']}>
              <Form.Item name={[subFieldName, 'criterionId']} initialValue={associatedCriterionId}>
                <>
                  <label htmlFor="criterion_id" className="ruleset-name">
                    <FormattedMessage id="autograder.form.rulesetCriterionAssociatedName" />
                  </label>
                  <br />
                  <select
                    name="criterion_id"
                    id="criterion_id"
                    className="select-box"
                    onChange={e => setAssociatedCriterionId(e.target.value)}
                    value={associatedCriterionId}>
                    {criteria.map((criterion, index) => (
                      <option key={index} value={criterion.id}>
                        {criterion.name}
                      </option>
                    ))}
                  </select>
                </>
              </Form.Item>
            </Form.Item>
            <Form.Item
              className="sheet-name"
              label={intl.formatMessage({ id: 'autograder.label.sheetName' })}
              name={[subFieldName, 'sheetName']}
              initialValue={sheetName}
              rules={[{ required: true, message: 'Please input the associated sheet name!' }]}>
              <Input placeholder="E.g. - Prompts" onChange={e => setAssociatedSheetName(e.target.value)} />
            </Form.Item>
            <Form.Item
              className="sheet-total-rows"
              label={intl.formatMessage({ id: 'autograder.label.sheetTotalRows' })}
              name={[subFieldName, 'sheetTotalRows']}
              initialValue={sheetTotalRows}>
              <Input placeholder="E.g. - 1000" />
            </Form.Item>
            <Form.Item>
              <FormattedMessage id="autograder.form.weightedScore">
                {text => <span className="non-linear-option">{text}</span>}
              </FormattedMessage>
              <Switch checked={isNonLinearGrading} onChange={handleSwitchChange} />
            </Form.Item>
            <Form.Item name={[subFieldName, 'nonLinearGradingRanges']}>
              <>{isNonLinearGrading && renderScoreRanges()}</>
            </Form.Item>
            <Form.Item name={[subFieldName, 'isModelAnswer']} initialValue={isModelAnswer} valuePropName="checked">
              <Checkbox checked={isModelAnswer} onChange={handleModelChange}>
                <FormattedMessage id="autograder.modelAnswer.title" />
              </Checkbox>
            </Form.Item>
            {isModelAnswer && (
              <>
                <Form.Item name={[subFieldName, 'modelAnswerImage']} initialValue={modelAnswerImage}>
                  <ImageUploader />
                </Form.Item>
                <Form.Item name={[subFieldName, 'modelAnswerDescription']} initialValue={modelAnswerDescription}>
                  <Input
                    placeholder={intl.formatMessage({ id: 'autograder.modelAnswer.descriptionInputPlaceholder' })}
                  />
                </Form.Item>
              </>
            )}
            <Form.Item name={[subFieldName, 'fieldKey']}>
              <Form.Item className="save-ruleset-criterion-submit">
                <Button type="primary" className="save-ruleset-criterion-field-btn" onClick={prepForSubmit}>
                  {initialValues ? (
                    <FormattedMessage id="autograder.form.updateRulesetCriterionBtn" />
                  ) : (
                    <FormattedMessage id="autograder.form.saveRulesetCriterionBtn" />
                  )}
                </Button>
                <Form.Item name={[otherProps, 'isCanceled']} noStyle>
                  <Button
                    danger
                    className="cancel-check-field-btn"
                    onClick={() => {
                      setIsRulesetCriterionOn(false);

                      parentForm.setFields([
                        {
                          name: [otherProps, 'isCanceled'],
                          value: true,
                        },
                      ]);
                      parentForm.submit();
                    }}>
                    <MinusCircleOutlined
                      style={{
                        fontSize: '16px',
                        verticalAlign: 'initial',
                      }}
                    />
                    <FormattedMessage id="common.cancel" />
                  </Button>
                </Form.Item>
              </Form.Item>
            </Form.Item>
          </div>
        </Form.Item>
      )}
      {!initialValues && (
        <Form.Item>
          <Button
            type="default"
            className="add-ruleset-criterion-field-btn"
            onClick={() => setIsRulesetCriterionOn(true)}>
            <PlusCircleOutlined style={{ fontSize: '20px' }} />
            Add Ruleset Criterion
          </Button>
        </Form.Item>
      )}
    </div>
  );
};

RulesetCriterionBuilder.propTypes = {
  parentForm: PropTypes.any,
  initialValues: PropTypes.any,
  criteria: PropTypes.array.isRequired,
};

export default RulesetCriterionBuilder;
