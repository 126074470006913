import {
  evalWorkbook,
  evalSheets,
  evalColumns,
  evalCellRange,
  evalCell,
  evalChart,
  evalChartDataCellRange,
  evalChartDataCell,
  evalPivotTable,
  evalPivotTableRow,
  evalPivotTableColumn,
  evalPivotTableValue,
  evalPivotTableFilter,
  evalPivotTableColRowValCol,
  evalColumnsCell,
  evalRows,
  evalColRowValCol,
} from '../../utils/checkFunctions';
import intl from '../../../utils/intl';

export const transformResult = (checkResult, params) => {
  if (checkResult === null) {
    return {
      status: false,
      expected: `${params.property} ${params.operator} ${params.value}`.trim(),
      actual: intl.formatMessage({ id: 'autograder.checkErrors.wrongCheck' }),
    };
  }
  let result;
  if (typeof checkResult === 'boolean') {
    result = {
      status: checkResult,
      expected: `${params.property} ${params.operator} ${params.value}`.trim(),
      actual: checkResult.toString(),
    };
  } else {
    result = checkResult;
  }

  return { ...result };
};

// eslint-disable-next-line complexity
export const executeCheck = (location, params) => {
  if (location === 'workbook') {
    return evalWorkbook({ ...params });
  }
  // type: order, name, any, all
  if (location === 'worksheet') {
    return evalSheets({ ...params });
  }
  // type: order, name, any, all
  if (location === 'worksheet.columns') {
    return evalColumns({ ...params });
  }
  // type: order, name, any, all
  if (location === 'worksheet.columns.cell') {
    return evalColumnsCell({ ...params });
  }
  // type: order, name, any, all
  if (location === 'worksheet.rows') {
    return evalRows({ ...params });
  }
  // type: order, name, any, all
  if (location === 'worksheet.columns.rowValue.columns2') {
    return evalColRowValCol({ ...params });
  }
  // type: coordinates - A13:Z99
  if (location === 'worksheet.cellRange') {
    return evalCellRange({ ...params });
  }
  // type: coordinates - A13
  if (location === 'worksheet.cell') {
    return evalCell({ ...params });
  }
  // type: title, any, all
  if (location === 'worksheet.chart') {
    return evalChart({ ...params });
  }
  // type: coordinates - A13:Z99
  if (location === 'worksheet.chart.cellRange') {
    return evalChartDataCellRange({ ...params });
  }
  // type: coordinates - A13
  if (location === 'worksheet.chart.cell') {
    return evalChartDataCell({ ...params });
  }
  // type: title, any, all
  if (location === 'worksheet.pivotTable') {
    return evalPivotTable({ ...params });
  }
  // type: title, any, all
  if (location === 'worksheet.pivotTable.rows') {
    return evalPivotTableRow({ ...params });
  }
  // type: title, any, all
  if (location === 'worksheet.pivotTable.columns') {
    return evalPivotTableColumn({ ...params });
  }
  // type: title, any, all
  if (location === 'worksheet.pivotTable.columns.rowValue.columns2') {
    return evalPivotTableColRowValCol({ ...params });
  }
  // type: title, any, all
  if (location === 'worksheet.pivotTable.value') {
    return evalPivotTableValue({ ...params });
  }
  // type: title, any, all
  if (location === 'worksheet.pivotTable.filter') {
    return evalPivotTableFilter({ ...params });
  }

  return null;
};
