import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';
import { Layout, Menu } from 'antd';
import { orderBy as _orderBy } from 'lodash';
import { FormattedMessage } from 'react-intl';
import StudentListItem from './StudentListItem';
import StudentsFilter from './StudentsFilter';
import { getSearchRegex } from '../../../../../helpers/regexHelper';
import Enrollment from '../../../../../api/enrollment';

const StudentsList = ({ students, selectedStudentId, onStudentSelect }) => {
  const [selectedFilters, setSelectedFilters] = useState({
    completedStudents: false,
    droppedStudents: false,
    studentsToWatch: false,
    studentsToGrade: false,
  });
  const [searchText, setSearchText] = useState('');

  const selectTab = e => {
    onStudentSelect(e.key);
  };

  const handleSelectFilter = key => {
    setSelectedFilters({
      ...selectedFilters,
      [key]: !selectedFilters[key],
    });
  };

  const getDisplayedEnrollmentStatus = () => {
    let statuses = [Enrollment.ENROLLED];

    if (selectedFilters.completedStudents) {
      statuses.push(Enrollment.COMPLETED);
    }
    if (selectedFilters.droppedStudents) {
      statuses.push(Enrollment.DROPPED);
    }

    return statuses;
  };

  const getFilteredStudents = () => {
    const regex = getSearchRegex(searchText);
    const displayedEnrollmentStatus = getDisplayedEnrollmentStatus();

    let filtered = students.filter(student => displayedEnrollmentStatus.includes(student.enrollment.status));

    filtered = filtered.filter(student => regex.test(student.name));

    if (selectedFilters.studentsToWatch) {
      filtered = filtered.filter(
        student => student.watchList.inWatchList && student.enrollment.status === Enrollment.ENROLLED,
      );
    }

    if (selectedFilters.studentsToGrade) {
      filtered = filtered.filter(
        student => student.ungraded_assignments_count > 0 && student.enrollment.status === Enrollment.ENROLLED,
      );
    }

    return _orderBy(filtered, ['name'], ['asc']);
  };

  const options = getFilteredStudents().map(student => {
    return (
      <Menu.Item key={student.id}>
        <StudentListItem student={student} />
      </Menu.Item>
    );
  });

  const showShowCompletedStudentButton = useMemo(() => {
    if (selectedFilters.studentsToGrade || selectedFilters.studentsToWatch || selectedFilters.completedStudents) {
      return false;
    }
    const completedStudents = students.filter(student => student.enrollment.status === Enrollment.COMPLETED);
    return completedStudents.length;
  }, [selectedFilters, students]);

  const showShowDroppedStudentButton = useMemo(() => {
    if (selectedFilters.studentsToGrade || selectedFilters.studentsToWatch || selectedFilters.droppedStudents) {
      return false;
    }
    const droppedStudents = students.filter(student => student.enrollment.status === Enrollment.DROPPED);
    return droppedStudents.length;
  }, [selectedFilters, students]);

  return (
    <Layout.Sider className="cohort-dashboard-list-sidebar" width={400}>
      <StudentsFilter
        filters={selectedFilters}
        onSelectFilter={handleSelectFilter}
        searchText={searchText}
        onSearchChange={setSearchText}
      />
      <Menu
        className="cohort-dashboard-sidebar-menu"
        onClick={selectTab}
        selectedKeys={[selectedStudentId]}
        mode="vertical">
        {options}
      </Menu>
      {!!showShowCompletedStudentButton && (
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <a
          href="#"
          onClick={() => {
            handleSelectFilter('completedStudents');
          }}
          data-testid="show-completed-students"
          className="show-student-filter-button">
          <FormattedMessage id="cohortDashboard.studentsPage.showCompletedStudents" />
        </a>
      )}
      {!!showShowDroppedStudentButton && (
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <a
          href="#"
          onClick={() => {
            handleSelectFilter('droppedStudents');
          }}
          data-testid="show-dropped-students"
          className="show-student-filter-button">
          <FormattedMessage id="cohortDashboard.studentsPage.showDroppedStudents" />
        </a>
      )}
    </Layout.Sider>
  );
};

export default StudentsList;

StudentsList.propTypes = {
  onStudentSelect: PropTypes.func.isRequired,
  selectedStudentId: PropTypes.string,
  students: PropTypes.array.isRequired,
};
