import axios from './axios';

const promises = {};

export default class Auth {
  static signIn(tenantId, username, password) {
    return axios.post('/users/auth/login', { username, password }).then(response => response.data);
  }

  static signOut(token) {
    return axios.post('/users/oauth/revoke', { token }).then(response => response.data);
  }

  static tokenInfo(token) {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    return axios.get('/users/oauth/token/info', { headers }).then(response => response.data);
  }

  static verifyConfirmationToken(confirmationToken) {
    return axios
      .post('/users/auth/verify_confirmation_token', { confirmation_token: confirmationToken })
      .then(response => response.data);
  }

  static confirmAndSetPassword(confirmationToken, password, passwordConfirmation) {
    return axios
      .post('/users/auth/confirm_and_set_password', {
        confirmation_token: confirmationToken,
        password,
        password_confirmation: passwordConfirmation,
      })
      .then(response => response.data);
  }

  static forgotPassword(email) {
    return axios.post('/users/auth/forgot_password', { email }).then(response => response.data);
  }

  static resetPassword(resetPasswordToken, password, passwordConfirmation, currentPassword) {
    return axios
      .post('/users/auth/reset_password', {
        reset_password_token: resetPasswordToken,
        password,
        password_confirmation: passwordConfirmation,
        current_password: currentPassword,
      })
      .then(response => response.data);
  }

  static ping() {
    if (promises.ping) {
      return promises.ping;
    }

    return (promises.ping = axios
      .post('/users/auth/ping')
      .then(response => {
        delete promises.ping;
        return response.data;
      })
      .catch(err => {
        delete promises.ping;
        throw err;
      }));
  }

  static acceptTermsOfService() {
    return axios.post('/users/auth/accept_terms_of_service').then(response => response.data);
  }
}
