import { connect } from 'react-redux';
import QuizLoader from './Loader';
import { selectQuestionsByQuizIdSorted } from '../../selectors/questionSelectors';
import { selectQuizIdByBlock, selectQuizByBlock } from '../../selectors/quizSelectors';
import { fetchQuiz } from '../../actions/quizActions';

const mapStateToProps = (state, ownProps) => {
  const { block } = ownProps;
  const quiz = selectQuizByBlock(state, block);
  const questions = selectQuestionsByQuizIdSorted(state, quiz && quiz.id);

  return {
    quiz,
    questions,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const { block } = ownProps;
  const quizId = selectQuizIdByBlock(block);

  return {
    fetchQuiz: () => dispatch(fetchQuiz(quizId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(QuizLoader);
