import { connect } from 'react-redux';
import { selectCurrentUser } from '../../../../selectors/authSelectors';
import { StudentNavBar } from './StudentNavBar';
import { signOut } from '../../../../actions/authActions';

const mapStateToProps = state => {
  const user = selectCurrentUser(state);

  return {
    user,
  };
};

export default connect(mapStateToProps, { signOut })(StudentNavBar);
