import { sortBy as _sortBy } from 'lodash';
import { createSelector } from 'reselect';
import createCachedSelector from 're-reselect';
import { selectEntities, selectAll, select, selectAllFilterBy } from './applicationSelectors';
import { selectEnrollment } from './enrollmentSelectors';
import { selectBootcampEntities } from './bootcampSelectors';

export const selectCohortEntities = selectEntities('cohorts');
export const selectCohorts = selectAll(selectCohortEntities);
export const selectCohort = select(selectCohortEntities);

export const selectCohortsByBootcampId = selectAllFilterBy(selectCohorts, 'bootcamp_id');

export const selectCohortForEnrollment = createCachedSelector(
  [selectCohortEntities, (state, enrollmentId) => selectEnrollment(state, enrollmentId)],
  (cohortEntities, enrollment) => {
    if (!enrollment) {
      return null;
    }

    return cohortEntities[enrollment.enrollable_id];
  },
)((_state, enrollmentId) => enrollmentId);

export const selectSortedCohorts = createSelector(
  [selectCohorts, selectBootcampEntities],
  (cohorts, bootcampEntities) => {
    return _sortBy(
      cohorts,
      cohort => {
        const bootcamp = bootcampEntities[cohort.bootcamp_id] || { title: '' };
        return bootcamp.title.toLowerCase();
      },
      'start_date',
      'end_date',
    );
  },
);
