/*
  eslint
  max-lines: 0
  max-len: 0
*/
import flatten from 'flat';

// prettier-ignore
const messages = {
  common: {
    app_name: 'Pathstream',
    homepage_link: 'Homepage link',
    updated_at: 'Updated {updated_at}',
    fileDropMessage: 'Drag your file or Click to browse',
    add: 'Add',
    edit: 'Edit',
    ok: 'Ok',
    copy: 'Copy',
    delete: 'Delete',
    download: 'Download',
    remove: 'Remove',
    submit: 'Submit',
    save: 'Save',
    saving: 'Saving...',
    saved: 'Saved',
    savingWarning: 'Please wait while saving is completed',
    cancel: 'Cancel',
    close: 'Close',
    create: 'Create',
    view: 'View',
    more: 'more',
    loading: 'Loading ...',
    copying: 'Copying ...',
    loadFailure: 'Failure to load',
    clear_all: 'Unselect All',
    errors: {
      required: '{field} is required',
      unhandled: 'Sorry, something went wrong.',
      unhandledOptions: 'Try the following:',
      unhandledReload: 'Reload',
    },
    makeFullScreen: 'Make Full Screen',
    closeFullScreen: 'Close Full Screen',
    undockPanel: 'Undock Panel',
    dockPanel: 'Dock Panel',
    closePanel: 'Close Panel',
    showProjectOverview: 'Project Overview',
    backToLab: 'Back to Lab',
    launch: 'Launch',
    launchLab: 'Launch Lab',
    editLab: 'Edit Lab',
    showItems: 'Show {count, plural, =0 {0 items} one { 1 item } other {{count} items} }',
    hideItems: 'Hide {count, plural, =0 {0 items} one { 1 item } other {{count} items} }',
    authorModes: {
      view: 'View Mode',
      edit: 'Edit Mode',
    },
    changePicture: 'Change Picture',
    search: 'Search',
    begin: 'Begin',
    resume: 'Resume',
    estimatedTime: 'Estimated Time',
    back: 'Back',
    next: 'Next',
    nextSection: 'Next Section',
    previous: 'Previous',
    knowledgeCheck: 'Knowledge check',
    gradedQuiz: 'Graded Quiz',
    lab: 'Lab',
    reset: 'Reset',
    start: 'Start',
    continue: 'Continue',
    time: {
      h: '{hours} hr',
      hours: '{hours, plural, =0 {0 hours} one { 1 hour } other {{hours} hours} }',
      m: '{minutes} min',
      minutes: '{minutes, plural, =0 {0 minutes} one { 1 minute } other {{minutes} minutes} }',
    },
    media: 'Media',
    commentThread: 'Comment Thread',
    options: 'Options',
    addNew: 'Add New',
    searchPlaceholder: 'Enter search text',
    action: 'Action',
    selectBootcamp: 'Select Course',
    selectCohort: 'Select Cohort',
    confirmTitle: 'Are you sure?',
    yes: 'Yes',
    no: 'No',
    select: 'Select',
    errorDownload: 'Unable to download. Please try again later',
    sessionTimeoutWarning: 'Are you still there? Your session will time out in [{seconds}] seconds.',
    rteAccessibilityNote: 'Press Alt+0 or Option+0 for accessibility shortcuts',
    complete: 'Complete',
    viewSource: 'View Source',
    addFilter: 'Add Filter',
    selectFilter: 'Select Field',
    selectOption: 'Select one or more options',
    filters: 'Filters',
    getHelp: 'Get Help',
    viewingCourseContent: 'Viewing course content',
    projectVersionChangeModal: {
      title: 'Breaking change?',
      message: 'Do you want to mark this as a breaking change?',
      note: "Note: If you select 'Yes', all students that have started this lab/project will have their work deleted and will be issued the new starter file - except students who already submitted projects. If you click 'No', only students who are starting the project/lab for the first time will be issued the new starter file.",
    },
    sidebarCollapse: 'Toggle sidebar collapse',
    quizModuleForCohort:
      'Available for future cohorts only. Please contact the delivery team to enable for existing cohorts.',
    youAreHere: 'YOU ARE HERE',
  },
  pageName: {
    assessments: 'Assessments',
    cohort: {
      create: 'Create New Cohort',
      edit: 'Edit Cohort',
      list: 'Cohorts',
    },
    course: {
      lab: '{course} {lab}',
      lesson: '{course} {lesson}',
      list: 'Courses List',
      overview: 'Course Overview {course}',
      project: '{course} {project}',
    },
    school: {
      list: 'Schools',
    },
    default: 'Pathstream',
    help: 'Help',
    roster: 'Roster',
    studentProfile: {
      my: 'My Profile Page',
      roster: 'Profile Page',
    },
    user: {
      create: 'Create New User',
      edit: 'Edit User',
      list: 'Users',
    },
    reports: {
      cohort: 'Cohort Reports',
    },
    instructorDashboard: 'Instructor Dashboard',
    courseDashboard: 'Course Dashboard',
  },
  aria: {
    showSectionItems: 'Show {count, plural, =0 {0 items} one { 1 item } other {{count} items} } from the section.',
    hideSectionItems: 'Hide {count} items from the section',
    reorderPath: 'Reorder Path',
    reorderSection: 'Reorder Section',
    reorderUnit: 'Reorder Module',
    reorderComponent: 'Reorder Lesson',
    moveUp: 'Move Up',
    moveDown: 'Move Down',
    deleteRubric: 'Delete Rubric',
    deleteChoice: 'Delete Choice',
    provideAnswer: 'Provide Answer',
    removeProjectFile: 'Delete Project file',
    nextUnit: 'Next Unit',
    prevUnit: 'Previous Unit',
    editBlock: 'Edit Block',
    addBlock: 'Add Block',
    webview: {
      reset: 'Reset to Lab Home Page',
      back: 'Go back a page',
      forward: 'Go forward a page',
      refresh: 'Refresh this page',
    },
    yourAnswer: 'Your Answer',
    viewStudents: 'List of Students',
  },
  nav: {
    menu: 'Menu',
    toggle: 'Toggle Menu',
    closeButton: 'Menu Close Button',
    backToCourses: 'Back to Courses',
    backToLearningPath: 'Return to Learning Path',
    backToBootcamp: 'Back to Course Overview',
    backToLesson: 'Back to Lesson',
    section: 'Section',
    path: 'Path',
    bootcamp: 'Course',
    bootcamps: 'Courses',
    allBootcamps: 'All Courses',
    learningPath: 'Learning Path',
    myBootcamp: 'My Course',
    myBootcamps: 'My Courses',
    profile: 'Profile',
    signOut: 'Sign Out',
    login: 'Login',
    logout: 'Logout',
    roster: 'Roster',
    gradingQueue: 'Grading',
    help: 'Help',
    users: 'Users',
    cohorts: 'Cohorts',
    topNavSubHeading: {
      module:
        'Section {sectionIndex} &nbsp;&nbsp;>&nbsp;&nbsp; Module {unitIndex} &nbsp;&nbsp;>&nbsp;&nbsp; Lesson {componentIndex}',
      project: 'Section {sectionIndex} &nbsp;&nbsp;>&nbsp;&nbsp; Assessment {assessmentIndex}',
    },
    topNav: {
      section: 'Section {index}',
      lessonIndex: {
        prefix: 'Lesson {index}',
        suffix: 'of {total}',
      },
      project: 'Project',
    },
    showUserProfile: "Show {name}'s User Profile",
    syllabus: 'Course Syllabus',
    bootcampOverview: 'Course Overview',
    confirmSectionNav: 'Be sure you have completed all items in this section before moving on.',
    bootcampsList: 'Courses List',
    courses: 'Courses',
    finishLesson: 'Finish Lesson',
    finishModule: 'Finish Module',
    finishSection: 'Finish Section',
    nextLesson: 'Next Lesson',
    cohortReports: 'Cohort Reports',
    ltiConsumers: 'LTI Consumers',
    schools: 'Schools',
    next: 'Next tab',
    prev: 'Previous tab',
    evaluatorQueue: 'Evaluator Queue',
    student: {
      profile: 'My Profile',
      changePassword: 'Change Password',
    },
    impersonating: 'YOU ARE IMPERSONATING {firstName} {lastName}',
    backToSabrina: 'Back to Sabrina',
  },
  autograder: {
    checkErrors: {
      wrongCheck: 'Wrong check construction',
    },
    nav: {
      autograder: 'Autograder',
    },
    header: {
      gradeAssignment: 'Grade Assignment',
      createRuleset: 'Add/Edit Rulesets ({projectType})',
      editRuleset: 'Edit Rulesets ({projectType})',
    },
    form: {
      rulesetName: 'Ruleset Name:',
      updateRulesetBtn: 'Update Ruleset',
      editRulesetBtn: 'Edit Ruleset',
      rulesetCriterionAssociatedName: 'Ruleset Criterion Name (Associated Criterion ID):',
      weightedScore: 'Apply weighted score?',
      updateRulesetCriterionBtn: 'Update Ruleset Criterion',
      saveRulesetCriterionBtn: 'Save Ruleset Criterion',
      ruleName: 'Rule Name:',
      ruleDesc: 'Description:',
      ruleFormula: 'Logic Formula for Checks:',
      rulePoints: '# of points:',
      ruleResubmitPrompt: 'Prompt user to resubmit if rule failed',
      updateRule: 'Update Rule',
      saveRule: 'Save Rule',
      addRule: 'Add Rule',
      checkName: 'Check Name:',
      checkLookIn: 'Look in:',
      checkSeeIf: 'See if',
      checkOperator: 'Operator',
      checkValue: 'Value',
      checkWorkbook: 'Workbook',
      checkGetMoreSpecific: 'Get more specific',
      updateCheck: 'Update Check',
      saveCheck: 'Save Check',
      addCheck: 'Add Check',
    },
    label: {
      nameRuleset: 'Name this Ruleset',
      selectRuleset: 'Select ruleset',
      rulesets: 'Rulesets',
      rulesetPlaceholder: 'e.g. Data Certificate - SQL Project 4 Part 1',
      defaultOption: 'What do you want to do?',
      gradedGoogleSheets: 'Google Sheets Graded Assignments',
      viewSubmission: 'View Submission',
      sheetName: 'Sheet Name',
      sheetTotalRows: 'Sheet Total Rows (if > 1000 rows)',
    },
    grading: {
      reportHeader: 'Pathstream Autograder™ Results',
      gradeTitle: 'Pathstream Autograder™',
      modelAnswer: 'Manual grading required',
      recommendedGrade: 'Recommended Grade',
      nonLinearGradingSummary: 'NonLinear Grading Summary',
      rulesetWasntMapped: "Ruleset wasn't mapped to this criterion",
      passed: 'Passed',
      mistakes: 'Mistakes',
      formula: 'Formula',
      rule: {
        title: 'Autograder Rule',
        result: 'Result',
        idealAnswer: 'Ideal Answer',
        submittedAnswer: 'Submitted Answer',
      },
    },
    project: {
      createAutogradeRuleset: 'Create Autograde Ruleset',
    },
    action: {
      updateRuleset: 'Edit/Remove Rulesets',
      createRuleset: 'Create Ruleset',
      gradePastSubs: 'Grade Past Submissions By Cohort',
    },
    sheetNotFound: 'Sheet not found',
    rulesetNotFound: 'NO RULESET FOUND!',
    createSuccess: 'Created Ruleset',
    createError: 'Ruleset not created, possibly duplication',
    createMockSuccess: 'Created Mock Rulesets',
    removeSuccess: 'Removed Ruleset',
    removeAllSuccess: 'Removed All Rulesets',
    modelAnswer: {
      title: 'Model Answer',
      descriptionInputPlaceholder: 'Modal answer description:',
      imageInputPlaceholder: 'Paste image URL here or upload through button below',
      uploadImage: 'Upload image',
      uploadModal: {
        title: 'Upload model answer image',
        body: 'Click below to upload model image. Be sure file is less than 300MB.',
        removeAllButton: 'Remove All',
        submitButton: 'Submit',
      },
    },
  },
  learningHome: {
    currentlyEnrolled: 'My certificate program:',
    programHandbook: 'Handbook',
    courses: 'My courses',
  },
  resourcesTab: {
    sections: {
      downloadsTitle: 'Downloads',
      contactTitle: 'Contact',
    },
    downloadHandbook: {
      title: 'Student Handbook',
      message: 'Download student handbook for {productName}',
      button: 'Download',
    },
    courseOverview: {
      title: 'Course syllabus',
      message: 'Download the course syllabus PDF for this course',
      button: 'Download',
    },
    emailInstructor: {
      title: 'Email your instructors',
      message: 'Contact your instructors for any academic questions',
      button: 'Email: {instructorEmail}',
    },
    officeHours: {
      title: 'Live meeting with an instructor',
      message: 'Book a 1-on-1 zoom meeting with an instructor',
      button: 'Book a Meeting',
    },
    techSupport: {
      title: 'Technical and enrollment help',
      message: 'Chat with our support team live by clicking the chat icon at the bottom right, or email {techSupportMailTo}',
    },
  },
  sidenav: {
    bootcamp: 'Course',
    bootcamps: 'Courses',
    cohorts: 'Cohorts',
    login: 'Login',
    logout: 'Logout',
    modules: 'Modules',
    my_profile: 'My Profile',
    path: 'Path',
    unit: 'Unit',
    users: 'Users',
  },
  bootcamp: {
    heading: {
      create: 'Create New Course',
      edit: 'Edit Course',
    },
    create: 'Create',
    notification: {
      saveBootcamp: 'Course Saved',
      updateBootcamp: 'Course Updated',
      savePath: 'Path Saved',
    },
    form: {
      title: 'Title',
      version: 'Version',
      certificate_title: 'Certificate Title',
      description: 'Description',
      titlePlaceholder: 'Enter title for Course',
      descriptionPlaceholder: 'Enter a short description for Course',
      showProgress: 'Show course progress bar',
      requiresTableau: 'Contains Tableau Labs',
      requiresColab: 'Contains Google Colab Labs',
      requiresAsana: 'Contains Asana Labs',
      showIntroModal: 'Show first modal',
      showReturningModal: 'Show returning modal',
      showProgressModals: 'Show progress modals',
      requiresAsanaLinkUpload: 'Allow Asana link uploads',
    },
    addPath: 'Add Path',
    accessOn: 'Access {date}',
    confirmDelete: 'Do you want to delete "{name}" Course?',
    courseComplete: 'Course complete!',
    currentlyLearning: 'Currently Learning',
    optional: 'Optional',
    optionalWP: '(Optional)',
    progress: 'Course Progress',
    reviewCourse: 'Review course',
    sections: 'Sections',
    searchPlaceholder: 'Search for Courses',
    goTo: 'Go To Course',
    options: 'Course Options',
    modals: {
      configureHeading: 'Configure Course Modals',
      button: 'Modals',
      saved: 'Modal configuration saved',
      introVideoRequired: 'Intro Video Required',
    },
    enrollAuthors: 'Enroll Authors',
    unenrollAuthors: 'Unenroll Authors',
    authors: {
      messages: {
        removed: 'Author removed',
        noneFound: 'No authors found',
        added: 'Author added',
      },
    },
    copy: {
      confirmPrompt: 'Are you sure you want to copy "{name}"?',
      success: 'Course is being copied. Please check again after some time.',
    },
  },
  cohort: {
    status: {
      not_started: 'Not Started',
      live: 'Live',
      ended: 'Ended',
      canceled: 'Canceled',
    },
    heading: {
      create: 'Create New Cohort',
      edit: 'Edit Cohort',
      settings: 'Settings',
      enrollments: 'Enrollments',
      coaches: 'Instructors',
      hideContent: 'Hide Content',
    },
    form: {
      bootcamp: 'Course',
      endDate: 'End date',
      location: 'Name',
      maxSeats: 'Seats',
      school: 'School',
      createNewSchool: 'Create New School',
      startDate: 'Start date',
      ltiConsumerKey: 'LTI Consumer Key',
      ltiContext: 'LTI Course Context IDs',
      dates: 'Dates',
      cohortTitle: 'College Partner Course Name',
      displayCohortTitle: 'Display College Partner Course Name to students and instructors',
      partnerType: 'Partner Type',
      modality: 'Modality',
      creditType: 'Credit Type',
      isSponsored: 'Sponsored Program',
      displaySchoolLogo: 'Display School Logo in Course Overview',
      isCanceled: 'Cohort Cancelled',
      psSemester: 'PS Semester',
      termLengthIs: 'Term Length is',
      isPathstreamDirect: 'Pathstream Direct',
      isAutograderEnabled: 'Enable Autograder',
      enrollmentSKU: 'Enrollment SKU',
      emailInstructorName: 'Instructor Name (primarily for PS Direct emails)',
      instructorEmail: 'Instructor Email (primarily for PS Direct emails)',
      courseName: 'Course Name',
      internalCourseName: 'Internal Course Name',
    },
    fields: {
      numEnrolled: 'Enrolled',
    },
    confirmDelete: 'Do you want to delete "{name}" cohort?',
    Admin: {
      sectionsDue: {
        due: 'Due: {date} {button}',
        editDueDate: 'Edit due date',
        info: 'This due date is used for creating the course schedule, and is shown in both the course completion percentage bar and the course overview page.  The week deadline is automatically associated based on section due date.',
        selectDate: 'Select Date',
        setDueDate: 'Set due date',
      },
    },
    Coach: {
      messages: {
        confirmRemove: 'Do you want to remove "{name}" instructor?',
        removed: 'Instructor removed',
        noneFound: 'No instructors found',
        added: 'Instructor added',
      },
      assignmentDue: {
        setDueDate: 'Set due date and grade weight',
        due: 'Due: {date} {button}',
        editDueDate: 'Edit due date',
        editDueDateAndWeight: 'Edit due date and grade weight',
        info: 'This due date is displayed to students in their grades overview page. Due dates allow you and students to track late submissions and for students with late assignments to be flagged as Students to Watch.',
        selectDate: 'Select Date',
      },
      sectionsDue: {
        editDueDate: 'Edit due date',
        info: 'This due date is used for creating the course schedule, and is shown in both the course completion percentage bar and the course overview page.  The week deadline is automatically associated based on section due date.',
        setDueDate: 'Set due date',
      },
    },
    details: 'Cohort Details',
    enrollStudents: 'Enroll Students',
    enrollCoaches: 'Enroll Instructors',
    unenrollStudents: 'Unenroll Students',
    unenrollCoaches: 'Unenroll Instructors',
    manageAssignmentsAndGrades: 'Manage Assignments and Grades',
    manageCohortAssignmentsAndGrades: 'Manage cohort assignments and grades',
    manageCourseSectionDueDates: 'Manage Course Section Due Dates',
    cohortAssignments: 'Cohort Assignments',
    moreDetails: 'More Details',
    hideContent: 'Hide Content',
    weeks: 'Weeks',
    synced: 'Synced',
    warning: 'Warning',
    warningMessage: 'There is another Cohort with same Context ID',
    statusLabel: 'Status',
    nCohorts: '{count, plural, =0 {0 Cohorts} one {1 Cohort} other {{count} Cohorts}}',
    numWeeksLeft:
      '{count, plural, =0 {0 weeks left for this cohort} one {1 week left for this cohort} other {{count} weeks left for this cohort}}',
    numDaysLeft:
      '{count, plural, =0 {0 days left for this cohort} one {1 day left for this cohort} other {{count} days left for this cohort}}',
    numDaysLeftToFinishGrading:
      '{count, plural, =0 {0 days left to finish grading} one {1 day left to finish grading} other {{count} days left to finish grading}}',
    numAssignmentsSubmitted:
      '{count, plural, =0 {0 assignments submitted} one {1 assignment submitted} other {{count} assignments submitted}}',
    showCompletedCohorts: 'Show completed cohorts',
    greatJobKeepingUp: 'Great job on keeping up with this cohort!',
    noStudentToWatch: 'There are currently no students to watch.',
    greatJobStayingTop: 'Great job on staying on top of it!',
    noAssignmentsToGrade: 'There are currently no assignments to grade.',
    assignmentsToGrade: 'Assignments to grade',
    gradedAssignments: 'Graded assignments',
    noAssignmentsSubmitted: 'No assignments have been submitted yet.',
    viewStudents: 'View students',
    viewAssignments: 'View assignments',
    courseCompletedOn: 'Course completed on {date}',
    courseCompletedForCohort: 'Course completed for this cohort',
    numTotalStudents: '{count, plural, =0 {0 total students} one {1 total student} other {{count} total students}}',
    viewCohort: 'View cohort',
  },
  component: {
    heading: {
      add: 'Create New Lesson',
      edit: 'Edit Lesson',
      create: 'Create New Lesson',
      content: 'Content (HTML, Video, Quiz, Lab)',
      addContent: 'Add Content (HTML, Video, Quiz, Lab)',
      lab: 'Lab',
      addLab: 'Add Lab',
      copyExistingLesson: 'Copy an existing Lesson',
      noLessons: 'No Lessons found',

    },
    messages: {
      lessonCopied: 'Lesson has been copied',
      unknownError: 'Unknown error occurred. Please try again.',
    },
    form: {
      title: 'Title',
      duration: 'Duration',
    },
    add: 'Add Lesson',
    copy: 'Copy Lesson',
    actions: 'Lesson Actions',
    confirmDelete: 'Do you want to delete "{name}" lesson?',
    editDuration: 'Edit Duration',
    durationDisplay: '{duration} min',
    tableOfContents: 'Table of Contents',
    currentLesson: 'Current Lesson',
    lessonProgress: 'Lesson Progress',
    lessonCompleted: 'Lesson Completed',
    partIndex: 'Part {index}',
    addPart: 'Add Part',
    partAdded: 'Part added',
    goToCurrent: 'Go to Current',
    markPartComplete: 'Mark part complete',
    markLessonComplete: 'Mark lesson complete',
    adaptive: 'Adaptive Learning On',
  },
  path: {
    heading: {
      create: 'Create New Path',
      edit: 'Edit Path',
    },
    notification: {
      saveSection: 'Section Saved',
    },
    form: {
      title: 'Title',
      description: 'Description',
    },
    addSection: 'Add Section',
    confirmDelete: 'Do you want to delete "{name}" Path?',
    studentPathProgress: {
      resumeLearning: 'Resume Learning',
    },
    readOnlyWarning: {
      title: 'READ-ONLY',
      body: `
        Your program end date has passed. You can continue learning but your progress<br/>
        will not contribute towards certificate completion.<br/>
        Contact <a href="mailto:help@pathstream.com">help@pathstream.com</a> for any questions.
      `,
    }
  },
  section: {
    heading: {
      create: 'Create New Section',
      edit: 'Edit Section',
    },
    notification: {
      saveModule: 'Module Saved',
    },
    form: {
      title: 'Title',
      description: 'Description',
    },
    addModule: 'Add Module',
    confirmDelete: 'Do you want to delete "{name}" section?',
    sectionNum: 'Section {index}',
  },
  unit: {
    heading: {
      create: 'Create New Unit',
      edit: 'Edit Unit',
      add: 'Add',
      createModule: 'Create a New Module',
      copyExistingModule: 'Copy an existing Module',
      createProject: 'Create a New Project',
      createGradedQuiz: 'Create a New Graded Quiz',
      editModule: 'Edit Module',
      editProject: 'Edit Project',
      editGradedQuiz: 'Edit Graded Quiz',
      instructions: 'Instructions',
      noModules: 'No Modules found',
      copyExistingProject: 'Copy an existing Project',
      copyExistingGradedQuiz: 'Copy an existing Graded Quiz',
    },
    form: {
      title: 'Title',
      unit_type: 'Unit Type',
      starter_project: 'Zip file for streaming Lab',
    },
    actions: 'Unit Actions',
    confirmModuleRemove: 'Do you want to remove "{name}" module?',
    confirmProjectDelete: 'Do you want to delete "{name}" project?',
    confirmGradedQuizDelete: 'Do you want to delete "{name}" graded quiz?',
    messages: {
      moduleCopied: 'Module has been copied',
      projectCopied: 'Project has been copied',
      gradedQuizCopied: 'Graded Quiz has been copied',
      unknownError: 'Unknown error occurred. Please try again.',
    },
    completed: '{completed} / {total}',
    viewGrade: 'View Grade',
    inProgress: 'In Progress',
    submitted: 'Submitted',
    projectCompleted: 'Completed',
    currentModule: 'Current Module',
    currentProject: 'Current Project',
    currentGradedQuiz: 'Current Graded Quiz',
    completedModule: 'Completed Module',
    completedProject: 'Completed Project',
    completedGradedQuiz: 'Completed Graded Quiz',
    module: 'Module',
    project: 'Project',
    gradedQuiz: 'Graded Quiz',
    componentCount: '{count, plural, =0 {No lessons} one {1 lesson} other {{count} lessons} }',
    suggestedDueDate: 'SUGGESTED DUE DATE',
  },
  tutorial: {
    heading: {
      create: 'Create New Lab',
      edit: 'Edit Lab',
      projects: 'Lab Projects',
      instructions: 'Instructions',
      starterProject: 'Starter Project',
      endButtonOptionsLabel: 'End of lab button',
      endButtonOptions: {
        back_to_lesson: 'Back to Lesson',
        next_lesson: 'Continue to Next Lesson',
      },
    },
    form: {
      title: 'Title',
      mysql: {
        starterDb: 'Starter database (.sql files only)',
        project: 'Query file (.sql files only)',
      },
      tableau: {
        project: 'Workbook file (.twb, .twbx, .zip files only)',
      },
      tough_talks: {
        project: 'Tough Talks Url'
      }
    },
    showInstructions: 'Show Instructions >>',
    confirmRemoveProject: 'Do you want to delete {name_exists, select, true {"{name}"} other {the}} project?',
    noStarterProject: 'No starter project found',
    syncProject: 'Reset lab',
    syncWaitMessage:
      'Please wait while the project is being synced to this step. This might take a few minutes to load the new project.',
    syncFailed: 'Unable to sync project with this step.',
    streamLoading: {
      title: 'Setting up your lab',
      titleGSuite: 'Loading your lab',
      subtitle: 'This should take less than two minutes',
      subtitleGSuite: "This shouldn't take long...",
      launchingLab: 'Launching and configuring your virtual desktop',
      loadingCustomFiles: 'Loading your custom project files',
      optimizing: 'Optimizing system performance',
      stepMessage: 'Step {currentStepNum} of {totalStepsNum}: {message}',
      loadingProjectFiles: 'Loading your custom project files',
    },
    streamEnded: 'Streaming session has ended. Refresh the page to restart the lab.',
    warningPromptVM: 'Are you sure you want to leave this lab? Any work you have not saved will be lost.',
    warningPrompt: 'Are you sure you want to leave the lab?',
    backToLesson: 'Back to Lesson',
    blockTypes: {
      prompt: 'Select a type',
      overview: 'Lab Overview',
      objective: 'Objective',
      takeaways: 'Key Takeaways',
      objectives: 'Objectives {count, plural, one {} other { - {index}/{count}} }',
    },
    goToSteps: 'Go To Steps',
    types: {
      tutorial: 'Walkthrough lab',
      project: 'Project Submission',
    },
    numSteps: '{count, plural, one {1 Step} other {{count} Steps}}',
    duration: '{duration} minutes',
    overview: 'Lab Overview',
    loadingNote: 'Please wait as the lab loads, this will take a few moments.',
    startLab: 'Start Lab',
    toolBar: {
      showTools: 'Show Tools',
      hideTools: 'Hide Tools',
      tools: 'Workspace Tools',
    },
    sideBar: {
      stepCount: 'Step {current} / {total}',
      downloadStarterFile: 'Download starter file',
      downloadFiles: 'Download files',
      mysql: {
        dbStarterFile: 'Download db starter file',
        queryStarterFile: 'Download query starter file',
      },
    },
    finish: 'Finish',
    addStep: 'Add Step',
    loadingModal: {
      readyTitle: 'Lab is ready.',
      loadingTitle: 'Preparing Lab',
      readyNote: 'Remember to <span style="text-decoration: underline;">save often</span> to avoid losing your work.',
      loadingNote1: 'Preparing for assembly...',
      loadingNote2: 'Gathering metallic components...',
      loadingNote3: 'Molding the plastic...',
      loadingNote4: 'Melting down the silicon...',
      loadingNote5: 'Preparing the circuit board...',
      loadingNote6: 'Laser etching the CPU...',
      loadingNote7: 'Adding the green paint...',
      loadingNote8: 'Galvanizing with gold...',
      loadingNote9: 'Supercooling the container...',
      loadingNote10: 'Putting 95 mask on...',
      loadingNote11: 'Recalling memory...',
      loadingNote12: 'Enjoying nostalgia from memories...',
      loadingNote13: 'Spin testing the hard drive...',
      loadingNote14: 'Checking needle for sharpness...',
      loadingNote15: 'Fetching water for cooling...',
      loadingNote16: 'Attaching coolant fan...',
      loadingNote17: 'Purifying cooling agent...',
      loadingNote18: 'Adding cool LED lights...',
      loadingNote19: 'Assembling the computer...',
      loadingNote20: 'Gluing the pieces together...',
      loadingNote21: 'Forcing plug A into plug D...',
      loadingNote22: 'Looking for a power cable...',
      loadingNote23: 'Finding an extension cord...',
      loadingNote24: 'Untangling some cables...',
      loadingNote25: 'Finding wall socket...',
      loadingNote26: 'Plugging computer in...',
      loadingNote27: 'Turning it on for the first time...',
      loadingNote28: 'Listening for fan sounds...',
      loadingNote29: 'Listening for beep...',
      loadingNote30: 'Booting BIOS...',
      loadingNote31: 'Preparing OS...',
      loadingNote32: 'Launching software...',
      minutesLeft:
        '{minutes, plural, =0 {Less than a minute left} one {Less than a minute left} other {~ {minutes} minutes left...}}',
    },
    errorModal: {
      bestOnDesktop: {
        title: 'Projects work best on desktop!',
        body: 'Please complete your project via a computer for now while our mobile experience is under construction.',
        primaryButton: 'Continue anyway',
        secondaryButton: 'Back to course',
      },
      machineHiccup: {
        title: 'Sorry, our machines had a small hiccup.',
        body: "Don't worry. Try the following:",
      },
      longerThanUsual: {
        title: 'This is taking a lot longer than usual.',
        body: 'Try the following:',
      },
      oneLabAtATime: {
        title: 'Only one lab can be loaded at a time.',
        body: 'Close all other lab sessions and restart the lab.',
      },
      labGotSleepy: {
        title: 'Your lab got sleepy and took a nap.',
        body: 'You can wake it up by restarting the lab.',
      },
      missingOrNotReadyDisk: {
        title: 'This lab is not ready yet, please try again in a few minutes.',
        body: 'If the problem persists:',
      },
      noMachinesAvailable: {
        title:
          'All of the virtual machines for labs are being used right now. You can leave this page open and we will connect you in a few minutes.',
      },
      cleaningUpOldSession: {
        title: 'Your last lab is being cleaned, please wait a few minutes.',
      },
      diskInMaintenance: {
        title: 'Lab under maintenance. Please come back later.',
        body: 'If the problem persists:',
      },
      restartLab: 'Restart Lab',
    },
    assignmentModal: {
      assignmentNotAvailable: {
        title: "This assignment isn't available to you yet.",
        body: 'You need to submit "{title}" first',
      },
      gsuiteAssignmentGraded: {
        title: "It's graded! You scored a {score}%",
        body: 'You can review your assignment.',
      },
      assignmentGraded: {
        title: "It's graded! You scored a {score}%",
        body: 'You can review your grades for all your assignments.',
      },
      gsuiteAssignmentSubmitted: {
        title: 'This assignment has been submitted.',
        body: 'You can view your submission below.',
      },
      assignmentSubmitted: {
        title: 'This assignment has been submitted.',
        body: "You won't be able to load this lab to make changes.",
      },
      assignmentPending: {
        title: 'This assignment has been submitted',
        body: "Your submission is being scanned for virus.  You won't be able to load this lab to make changes.",
      },
      seeLessons: 'See Lessons',
      viewSubmission: 'View Submission',
      reviewSubmission: 'Review Submission',
      goToGrades: 'Go To Grades',
    },
  },
  step: {
    stageSequence: 'Stage {seqNum}',
    heading: {
      create: 'Create New Stage',
      edit: 'Edit Stage {seqNum}',
      add: 'Add Stage',
    },
    form: {
      title: 'Title',
      project: 'Zip file for streaming Lab',
      url: 'URL',
    },
    confirmDelete: 'Do you want to delete {name_exists, select, true {"{name}"} other {the}} stage?',
    defaultTitle: 'Instructions',
  },
  part: {
    confirmDelete: 'Do you want to delete {name_exists, select, true {"{name}"} other {the}} part?',
  },
  block: {
    heading: {
      create: 'Add Content',
      edit: 'Edit Content',
      add: 'Add Content',
      conflict: 'Resolve Conflicts',
    },
    form: {
      title: 'Title',
      description: 'Description',
      content: 'HTML',
      video: 'Video',
      embedCode: 'Embed Code',
      instructions: 'Instructions',
      project: 'Project',
      quiz: 'Quiz',
      embed: 'Embed',
      typeform: 'Typeform',
      url: 'URL',
      width: 'Width',
      height: 'Height',
      app: 'Application',
      custom_app: 'Custom Application',
      sql: 'SQL Query',
      defaultCheckbox: 'Use Pathstream default height and width',
    },
    types: {
      html: 'HTML',
      video: 'VIDEO',
      quiz: 'QUIZ',
      lab: 'LAB',
      embed: 'EMBED',
      sql: 'SQL',
    },
    embed: {
      defaultVideoTitle: 'Embedded Video',
    },
    adaptive: {
      learningTracks: 'Adaptive Learning Tracks',
      selectTracks: 'Select Tracks',
    },
    codio: {
      defaultEmbedTitle: 'Coding sandbox',
      form: {
        codioResourceLtiUrl: 'Codio Resource LTI URL',
      },
      error: 'Our machines encountered an issue',
    },
    typeform: {
      defaultEmbedTitle: 'Embedded typeform survey',
      form: {
        url: 'Typeform survey URL',
        heightLabel: 'Height',
        height: {
          small: 'Small (300px)',
          medium: 'Medium (600px)',
          large: 'Large (768px)',
        },
      },
    },
    multipleEditors: {
      warning: 'This block is currently being edited by {editors}',
    },
    add: 'Add Block',
    addHtml: 'HTML',
    addCollapsibleHtml: 'Collapsible HTML',
    addVideo: 'Video',
    addQuiz: 'Quiz',
    addSingleQuestionQuiz: '1 Question Quiz',
    addLab: 'Lab',
    addCommentThread: 'Comment thread',
    addEmbed: 'Embed',
    addSql: 'SQL',
    addTypeform: 'Typeform',
    addAsanaAccount: 'Asana Account',
    defaultTitle: '{blockType} - Title goes here.',
    defaultSqlContent: 'Instructions goes here',
    defaultHtmlContent: 'Content goes here',
    defaultCollapsibleHtmlContent: 'Content goes here',
    confirmDelete: 'Do you want to delete {name_exists, select, true {"{name}"} other {the}} Lesson block?',
    tableOfContents: 'Table of Contents',
    moveTo: {
      button: 'Move to New Lesson',
      heading: 'Move to New Lesson',
      success: 'Block moved to new lesson',
      failure: 'Error moving block to new lesson.',
      confirm: 'Are you sure you want to move the block?',
      saving: 'Moving lesson...',
    },
    copyTo: {
      button: 'Copy to New Lesson',
      heading: 'Copy to New Lesson',
      success: 'Block copied to new lesson',
      failure: 'Error copying block to new lesson.',
      confirm: 'Are you sure you want to copy the block?',
      saving: 'Copying lesson...',
    },
    launchInLabel: 'Launch location',
    launchIn: {
      browser: 'Browser',
      desktop: 'Desktop App (Deprecated)',
      new_tab: 'New Tab',
    },
    moveToPart: {
      button: 'Move to Part',
      heading: 'Move to Part',
      success: 'Block moved to part',
      failure: 'Error moving block to part.',
      confirm: 'Are you sure you want to move the block?',
      saving: 'Moving lesson...',
    },
    moveToStep: {
      button: 'Move to New Step',
      heading: 'Move to New Step',
      success: 'Block moved to new step',
      failure: 'Error moving block to new step.',
      confirm: 'Are you sure you want to move the block?',
      saving: 'Moving block...',
    },
    conflict: {
      message: 'Another author changed the block since you started editing. Resolve the conflicting changes below.',
      yourChanges: 'Your Changes (Editable)',
      conflictingChanges: "Previous Author's Changes (Read Only)",
      localChanges: 'Keep Your Changes',
      remoteChanges: "Keep Previous Author's Changes",
    },
    images: {
      altTextWarning: 'One or more images are missing Alt Text',
    },
  },
  comment: {
    submit: 'Post',
    submitting: 'Posting...',
    edited: '(edited)',
    options: 'Comment Options',
  },
  user: {
    login: {
      heading: 'Sign In',
      loading: 'Signing in',
      submitBtn: 'Continue',
      googleSignIn: 'Continue with Google',
      errorLoggingIn: 'Invalid email/password.',
      forgotPassword: 'Forgot Password?',
      welcomeMsgId: 'Welcome!',
      taglineId: 'The next step in your career starts here.',
      continueToPathstream: 'Log in to continue to Pathstream',
      terms1: 'By continuing, you agree to the',
      terms2: 'Self Service PSS',
      terms3: 'and',
      terms4: 'Privacy Policy.',
    },
    forgotPassword: {
      label: 'Email address',
      heading: 'Reset Password',
      success:
        'If an account with this email address exists, you will receive an email to reset your password shortly.',
      submitBtn: 'Submit',
      formHeading: 'Reset your password',
      instructions: "Enter your email and we'll send you a link to reset your password",
    },
    setPassword: {
      heading: 'Set Password',
      resetHeading: 'Reset Password',
      successHeading: 'Password set!',
      password: 'New password',
      confirmPassword: 'Confirm new password',
      setConfirmation: 'Password Set!',
      setSuccess: 'Your password has been set successfully.',
      loading: 'Please wait ',
      submitBtn: 'Submit',
      tokenMissing: 'Reset Token is missing',
      success: 'Login with your new password.',
      welcomeMsgId: 'Set your Password',
      forceChangeInstructions:
        'Security it important to us. So we require you to reset your password every year. <br/><br/>Enter your current and new password below. ',
      errors: {
        passwordRequired: 'Password is required',
        passwordMismatch: 'Passwords do not match',
        required: 'is required',
        currentPasswordRequired: 'Current Password is required',
        passwordSame: "New password can't be the same as the old password.",
      },
    },
    form: {
      name: 'Name',
      email: 'Email',
      password: 'Password',
      confirmPassword: 'Confirm Password',
      roles: 'Roles',
      sendInvitation: 'Send Invitation Email',
      currentPassword: 'Current Password',
    },
    roles: {
      Admin: 'Admin',
      Author: 'Author',
      Student: 'Student',
      Coach: 'Instructor',
      Evaluator: 'Evaluator',
    },
    fields: {
      current_sign_in_at: 'Last Sign in',
    },
    confirmation: {
      heading: 'Set Password',
      instructions: 'Confirm your account and set your new password.',
      success: 'Your account has been created',
      tokenMissing: 'Confirmation Token is missing',
      welcome: 'Welcome to Pathstream!',
      passwordIsSet: 'Password is set, taking you to your learning homepage...',
    },
    termsOfService: {
      heading: 'Terms of Use & Privacy Policy',
      acceptBtn: 'I agree to the terms',
      title: 'Terms of Service',
    },
    messages: {
      noneFound: 'No users found',
      updated: 'User updated',
      noCohortsFound: 'Not enrolled in any cohorts',
    },
    edit: 'Edit User',
    create: 'Create New User',
    viewCohorts: 'View Cohorts',
    logout: {
      expired: 'Session has expired. Login to continue.',
    },
  },
  fileUpload: {
    valid: 'Valid file type',
    invalid: 'Invalid file type',
    noneSelected: 'None Selected',
    uploading: 'Uploading file',
    uploaded: 'File uploaded',
    error: 'Unable to upload file',
    largeFileError: 'File size should be less than 500 MB',
    selectFile: 'Select File',
    success: 'Upload Successful',
    pending: 'Uploading',
    pendingDescription:
      'We are uploading your submission, including scanning for viruses. The status will change to "Submitted" if successful or "Upload Failed" if there is an issue and you need to resubmit.',
    failed: 'Upload Failed',
    failedDescription:
      'We were unable to upload the files you submitted. Either a file was too big or we identified it as a security risk. Please resubmit with different files.',
    uploadModal: {
      title: 'Upload your assignment submission',
      body: 'Once you’ve completed your assignment, click below to upload your submission. Be sure each file is less than 300MB. The files you upload will be submitted to your instructor for grading.',
      removeAllButton: 'Remove All',
      submitButton: 'Submit',
    },
    removeConfirmModal: {
      title: 'Remove all uploaded files?',
      body: 'Are you sure you want to remove the files you have uploaded?',
      removeButton: 'Remove',
    },
  },
  linkUploadModal: {
    title: 'Upload your assignment via share link',
    bodyMessage:
      "Once you've completed your experiential assessment and are ready to submit, follow these instructions:",
    instruction1: 'With your project open, click the "Share" button in Asana.',
    instruction2: 'A popup will appear. Click "copy project link".',
    instruction3:
      'Click below and paste in your project link. The link will be submitted to your instructor for grading.',
    note: 'Note that some experiential assessments call for submitting two Asana projects. In this case, you will need to paste in two project links. Paste both links, separated by a comma.',
    inputPlaceholder: 'Copy and paste your link here',
    missingLink: 'Please upload your Asana project link',
    submitButton: 'Submit',
  },
  rubricCriteria: {
    heading: '{assignmentTitle} Rubrics',
    fields: {
      proficiency_level_1: 'Exceed (A)',
      proficiency_level_2: 'Achieve (B)',
      proficiency_level_3: 'Emerging (C)',
      proficiency_level_4: 'Incomplete (D/F)',
      displayRubrics: 'Display Rubric to Student',
      requiresGrading: 'Submit for grading',
    },
    buttons: {
      add: 'Add Another Criterion',
    },
  },
  enrollments: {
    heading: {
      add: 'Enroll Users',
    },
    fields: {
      name: 'Name',
      status: 'Status',
    },
    button: {
      enroll: 'Enroll',
      unenroll: 'Unenroll',
    },
    messages: {
      removed: 'User has been removed',
      added: 'User has been enrolled',
      noneFound: 'No enrollments',
      confirmRemove: 'Do you want to un-enroll the user?',
    },
  },
  status: {
    complete: 'Complete',
    completed: 'Completed',
    inProgress: 'In-Progress',
    todo: 'To Do',
    notStarted: 'Not Started',
  },
  quiz: {
    backButtonOnLab: 'View Lab',
    buttonForAuthorOnLab: 'Edit Quiz',
    heading: 'Quiz',
    instructions: 'Instructions',
    question: 'Question {questionIndex}/{numQuestionsString}',
    beginQuiz: 'Start',
    editQuestions: 'Edit Questions',
    questionNum: 'Question {index} of {total}',
    answer: 'Answer',
    addChoice: 'Add Choice',
    moveBack: 'Move Back',
    moveForward: 'Move Forward',
    removeQuestion: 'Remove Question',
    results: 'Results',
  },
  gradedQuiz: {
    neededToPass: 'You need a score of {percentToPass} to pass.',
    oneMoreChancePlural: ' You have one more chance to retry the questions you got wrong.',
    oneMoreChanceSingular: ' You have one more chance to retry the question you got wrong.',
    likeToRetry: 'Would you like to retry ',
    submitPrompt: 'Press submit to review your answers.',
    incorrectAnswer: '{incorrectAnswers} incorrect answer',
    incorrectAnswers: '{incorrectAnswers} incorrect answers',
    reviewFeedback: 'Below you can review all of your answers with feedback.',
    submittedSuccessfully: 'Your grades have been submitted successfully.',
    incorrectChoiceMessage: 'You got an incorrect answer',
    correctChoiceMessage: 'You got a correct answer',
    missedCorrectChoiceMessage: 'You missed a correct answer',
    quizCompleted: 'Quiz completed!',
    submitted: 'Your grades have been submitted \n successfully.',
    resultNextStep: {
      completed: 'You completed:',
      next: 'Next Up',
      length: 'Length',
    },
  },
  question: {
    next: 'Next',
    previous: 'Previous',
    confirmDelete: 'Do you want to delete "{name}" question?',
    confirmQuestionChange: 'There are unsaved changes. Do you want to continue?',
    randomizeChoices: 'Randomize Answer Order',
    add: 'Add Question',
    defaultPrompt: 'Prompt goes here.',
    defaultAnswer: 'Answer {num} goes here.',
    defaultExplanation: 'Explanation goes here.',
    addSingleSelect: 'Multiple choice - single answer',
    addMultiSelect: 'Multiple choice - multiple answer',
    addFreeTextAnswer: 'Free response',
    addSelfReflection: 'Multiple choice - self reflection',
    answerIncorrect: 'Your answer is incorrect',
    answerPartiallyCorrect: 'One or more of your answers is incorrect or incomplete',
    tryAgain: 'Try Again',
    showAnswer: 'Show Answer',
    answer: 'Answer',
    submitAnswer: 'Submit',
    noQuestions: 'This quiz has no questions',
    correctAnswer: 'You got it',
    correctChoice: 'Correct choice - {choice}',
    correctnessNumbers:
      'You selected {correctNum, plural, =0 {no correct choices} one {# correct choice} other {# correct choices}} and {incorrectNum, plural, =0 {no incorrect choices} one {# incorrect choice} other {# incorrect choices}}',
    answered: 'Your answer:',
    explanationForStudent: 'Explanation:',
    prompt: 'Question Prompt',
    explanation: 'Overall Explanation',
    choice: {
      isCorrect: 'Is Correct?',
      prompt: 'Choice',
      explanation: 'Answer Explanation',
    },
    review: 'Review',
  },
  project: {
    confirmSubmit: "You won't be able to modify the project once it has been submitted. Do you want to continue?",
    confirmComplete: "You won't be able to modify the project once you mark it as complete. Do you want to continue?",
    scoreOverMax: 'Score cannot be greater than Max Score',
    rubricCriteria: 'Rubric',
    submitted: 'Your assessment has been submitted for grading.',
    completed: 'Your assessment has been completed.',
    scored: 'You have received a score of {score}%',
    configure: 'Configure',
    selectDependentProject: 'Select Dependent Project',
    setTemplate: 'Set Template',
    dependentProjectNotes:
      'The starting file for this project will be the student’s submission from the project you select below.<br/>' +
      'Note that students will not be able to access this project until they submit the project you select below',
    prerequisiteIncomplete:
      "Uh oh. This is '{title}'. You need to submit '{prerequisiteTitle}' before you can start this part of the project.",
    form: {
      starterProjectUrl: 'URL',
      prerequisite: 'Project',
    },
    submitMessage: "Don't close this page yet! Give us about 10 seconds to submit your assessment.",
    selectProject: 'Select Project',
    waitForLab: 'Please wait while the lab is fully loaded before submitting',
    submissionModal: {
      title: 'Submit to your instructor?',
      body: "Do you want to submit the uploaded files? Once you submit, you won't be able to edit or add files to your submission.",
      submitButton: 'Submit',
    },
    failedSubmission: 'Assignment failed to submit. Please try again. For additional help, contact Pathstream support.',
    openAssignment: 'Open Assignment',
    start: 'Start Project',
  },
  profile: {
    changePassword: 'Change Password',
    passwordChanged: 'Password updated',
    profileUpdated: 'Profile updated',
    completedPaths: 'Completed Paths',
    lastLogin: 'Last Login',
    pastBootcamps: 'Past Courses',
    profile: 'Profile',
    myProfile: 'My Profile',
    pathAssessment: 'Path / Assessment',
    attributes: {
      gmail_address: {
        prompt: {
          title: 'Google Colab',
          message:
            '<ul style="list-style: disc"><li>This course includes labs that use <a href="{colabUrl}" target="_blank">Google Colaboratory</a>, a free Jupyter notebook environment that requires no setup and is run entirely in the cloud. </li><li>For us to provide you Google Colaboratory in our labs environment, we will need to share lab files with a Google email. </li><li>Enter an existing Google email below, or create one <a href="{googleSignupUrl}" target="_blank">here</a>, then enter it below. </li><li>We will not use the Google email you share for anything other than providing the Google Colaboratory labs experience. </li></ul>',
        },
        form: {
          label: 'Google email',
        },
      },
      asana_address: {
        prompt: {
          title: 'Generate Your Asana Username',
          message:
            'Once you enter a personal email and click "generate", you will receive an email from Asana.<br/><br/>Open the email and click “Verify email address”, but then REMEMBER to refer back to the Asana Account Setup video instructions so you correctly set up your account.',
          okTitle: 'Generate',
        },
        form: {
          label: 'Enter your email address',
        },
      },
    },
    asanaAccount: {
      title: 'Forgot your Asana username?',
      buttonTitle: 'My Asana Username',
    },
    asanaAccountCreatedModal: {
      title: 'Success!',
      subTitle: 'Asana username:',
      loginInstruction1: 'Exit this popup and continue with your course material.',
      loginInstruction2: 'You will be instructed on when to return for your Asana username.',
      noEmail: "Didn't receive the invite email?",
      changeEmail: 'Or want to use a different email? Click below.',
      viewInviteEmailButton: 'View Asana invite email',
      changeEmailButton: 'Use a different email address',
    },
    noAsanaEmailModal: {
      somethingWentWrong: 'Something went wrong.',
      letUsKnow: 'Let us know so we can fix it.',
      noEmail: 'The Asana invite email was not generated.',
      contactSupport:
        'Contact <b>{supportEmail}</b> or use the chat widget to contact a Pathstream Customer Support Specialist.',
      resolution: 'We will get it sorted out for you.',
    },
  },
  grading: {
    grade: 'Grade',
    regrade: 'Regrade',
    regradeAssignment: 'Regrade Assignment',
    regradeWarning: 'By regrading the assignment you will lose the previously given grade for this assignment. ',
    allowNewSubmission: 'Allow new <br/>submission',
    inGrading: 'In Grading',
    submitted: 'Submitted',
    notSubmitted: 'Not yet submitted',
    gradedAt: 'Graded',
    gradedMessage: 'Assessment has been graded',
    noAssessments: 'No assessments found',
    noSubmissions: 'No submissions yet',
    graded: 'Graded',
    toBeGraded: 'To be graded',
    resubmissionSuccess: 'Assessment has been marked for resubmission',
    resubmissionFailure: 'Unable to mark assessment for resubmission',
    confirmResubmission: 'Do you want to allow new submission for this assessment?',
    showRubric: 'See Full Rubric',
    hideRubric: 'Hide Rubric',
    summary: 'Grading Summary',
    skipToSummary: 'Skip to grading summary',
    nextCriterion: 'Next rubric criterion',
    reviewSummary: 'Review grading summary',
    viewInstructions: 'View instructions',
    backToGrading: 'Back to grading view',
    gradedAssignment: 'Graded Assignment',
    gradeTitle: 'Grade',
    selectGradeTitle: 'Select a grade',
    commentsTitle: 'Comments',
    commentsText: 'Write a comment about this rubric',
    submitGrades: 'Submit grades',
    submitGradesSuccess: 'Grades submitted!',
    submitGradesError: 'Grades not submitted. Please try again.',
    view: 'View',
    viewTooltip:
      "This assignment part is not for a grade. You can click here to view and comment on the student's work.",
    hideDrawer: 'Hide',
    showDrawer: 'Show',
    downloadSubmission: 'Download Submission',
    openSubmission: 'Open Submission',
    projectTitle: 'Project-',
  },
  grade: {
    heading: '{assignmentTitle} Grade',
    criterion_name: 'Criteria',
    score: 'Grades',
    comment: 'Comments',
    maxScore: 'Maximum Score',
    totalGrade: 'Total Grade',
  },
  roster: {
    noStudents: 'No students enrolled',
    cohort: 'Cohort',
    hideComplete: 'Hide Complete',
    lastLogin: 'Last Login',
    info: 'Student Grading Information',
    grade: 'Grade',
    paths: 'Paths',
    viewLab: 'View ongoing lab',
    awards: 'Awards',
    badges: '{count, plural, one {1 Badge} other {{count} Badges} }',
    showOnlyUngraded: 'Show only ungraded students',
    assignments: 'Assignments',
  },
  help: {
    heading: 'FAQs and How Tos',
    contact: {
      heading: 'Contact Us',
      modalHeading: 'Contact form',
      button: 'Contact Form',
      info: `
        <p>
          If you didn't find the question and answer here, use our contact form.
          <br><br>
          We will respond within 24 hours.
        </p>
      `,
    },
    contentBeforeForm: `
      <h2>Have a question?</h2>
      <p>
        We are here to help you however you need, including if you have questions related to:
      </p>
      <ul>
        <li>
          Curriculum or content
        </li>
        <li>
          Tech support or the platform
        </li>
        <li>
          Other aspects of the course or platform
        </li>
      </ul>
      <p>
        You can always email us at <a href="mailto:help@pathstream.com">help@pathstream.com</a> or you can email us using the form below.
      </p>
    `,
    contentAfterForm: `
      <p>
        Or you can use the <b>chat widget</b> to the right to message us (don’t worry - your conversations should remain even if you leave and come back).
      </p>
    `,
    form: {
      name: 'Name *',
      email: 'Your email address',
      subject: 'Subject',
      message: 'Type your opinion or question',
      submit: 'Send',
      submitting: 'Sending...',
      submitSuccess: 'Message sent',
      submitError: 'Error sending message, please try again',
      allFieldsAreRequired: 'All fields are required',
    },
  },
  coachLab: {
    header: 'Viewing lab of {name}',
    noSession: 'There is no ongoing lab for this student',
    popupBlocked: 'Popups need to be allowed to view lab sessions.',
    invalidSession: "Unable to view student's lab session. Make sure a session is in progress.",
  },
  lti: {
    config: {
      heading: 'LTI Configuration',
      viewParams: 'View LTI Params',
    },
    messages: {
      noAccess: 'Only Students and Instructors can access content through LTI',
      wait: 'Please wait while your course is being prepared...',
      assignmentWait: 'Please wait while the project is being setup',
      launchFromLMS: 'To access this project click the link in your school learning system',
      unknownError: 'Unable to launch app. Please contact your administrator.',
      noAssignmentsInSection: 'No assignments present',
      assignmentRequired: 'At least one assignment must be selected',
      duplicateAssignmentMapping: 'Multiple assignments cannot be mapped to the same LMS assignment',
      assignmentMappingSaved: 'Assignment Mapping Saved',
      selectLMSAssignment: 'Select LMS Assignment',
      howToLaunchAssignment: 'How to launch your assignment',
    },
  },
  apps: {
    gsuite: 'G-Suite',
    codio: 'Codio',
    mysql: 'MySQL',
    tableau: 'Tableau',
    trailhead: 'Salesforce Trailhead labs',
    custom_site: 'Create Custom URL',
    existing_custom_site: 'Existing Custom URL',
    google_colab: 'Google Colab Labs',
    embed: 'Embedded URL',
    tough_talks: 'Tough Talks',
    customSite: {
      name: 'Name',
      url: 'URL',
    },
    video: 'Video',
  },
  pagination: {
    page: 'Page',
    ofTotal: 'of {total}',
  },
  sqlBlock: {
    execute: 'Execute',
    clear: 'Clear',
  },
  introModal: {
    heading: 'Hello, {name}! Welcome to Pathstream',
    content: 'We are excited for you to start learning. Watch the video below for an intro on navigating the platform:',
    startButton: 'Start First Lesson',
  },
  returningModal: {
    heading: 'Hello, {name}!',
    content: 'Here is where you left off',
    sectionHeading: 'Section {index}',
    moduleHeading: 'Module {index}',
    projectHeading: 'Project',
    gradedQuizHeading: 'Graded Quiz',
    startButton: 'Jump Back In',
  },
  incompleteModal: {
    heading: 'Hang On!',
    content: "Looks like you didn't complete the following items:",
    markComplete: 'Mark Lesson as Complete',
    gotoNext: 'Go to the Next Lesson without Marking Complete',
  },
  completionModal: {
    nextUp: 'Next Up',
    moduleIndex: 'Module {index}',
    project: 'Project',
    heading: {
      default: 'Great Job!',
      bootcampCompleted: 'Congratulations!<span>🎉🎉🎉</span>',
      sectionCompleted: 'Section Completed!',
      moduleCompleted: 'Module Completed!',
      lessonCompleted: 'Lesson Completed!',
      courseCompleted: 'Course completed!',
    },
    completedMessage: {
      project: 'Your project has been submitted!',
      module: 'You completed a module!',
      section:
        'Way to go. You completed all the Pathstream items for this section. Would you like to start next section?',
      bootcamp:
        'Wow. You completed all the Pathstream items in this course. Your hard work has paid off - you should be very proud.',
    },
    additionalFooter: {
      section: 'Be sure to check for assignments on Canvas or Blackboard',
      launchFromLMS: 'To open this project go to Blackboard, Brightspace, or Canvas and click the project link',
    },
    description: {
      sectionCompleted: 'In this section you completed these modules:',
      moduleCompleted: 'In this module you completed these lessons:',
      lessonCompleted: 'By completing this lesson, you learned:',
      youLearned: 'You Learned:',
      youCompleted: 'You completed:',
    },
    footerMessage: {
      sectionCompleted: 'Great job on completing this section!',
    },
    nextModule: 'Next Module',
    nextLesson: 'Next Lesson',
    nextProject: 'Next Project',
    nextGradedQuiz: 'Next Graded Quiz',
    nextCourse: 'Next course',
    startDate: 'Start date',
  },
  streamContainer: {
    messages: {
      noMachinesAvailable:
        'All of the virtual machines powering streaming labs are being used right now. Please try again later.',
      confirmLabUse: 'Would you like to continue working on the lab?',
    },
  },
  trailhead: {
    message:
      'Your Salesforce instance will open here. <br/><br/>Click Launch in the window on the right to open it <br/>(you may have to click Log in to verify this step first).',
  },
  cohortReports: {
    students: {
      name: 'Student Name',
      assignments_completed_percent: '% of Assessments Completed',
      avg_assignment_score: 'Average Assessment Score',
      lessons_completed_percent: '% of Lessons Completed',
      duration: 'Active Time (in Hours)',
      num_logins: 'Number of Logins',
      last_login: 'Last Login',
    },
    assignments: {
      title: 'Cohort Assessment Performance',
      avgAssignmentScore: 'Average Assessment Score',
      percentStudentsSubmitted: '% of Students Submitted',
    },
    download: {
      pdf: 'Print PDF',
      csv: {
        title: 'Download CSV',
        loading: 'Generating...',
      },
    },
    generated: 'Generated {date}',
  },
  labIntro: {
    sidebar: {
      heading: 'Lab Instructions',
      content: 'Step-by-step instructions to complete the lab.',
    },
    labArea: {
      heading: 'Your Workspace',
      content: 'Work directly in this space.',
    },
    dismissPrompt: 'Click anywhere to start',
  },
  labCompletion: {
    heading: 'Congratulations',
    recapLeadIn: 'To recap, here is what you learned in this lab:',
    labFormTitle: 'What did you think of this lab?',
    projectFormTitle: 'What did you think of this project?',
    footnote: 'Feedback will be submitted after you leave this page.',
    continue: 'Continue',
    submit: 'Submit',
    backToLab: 'Go back to the lab',
    thumbUp: 'Thumb Up',
    thumbDown: 'Thumb Down',
    projectFinished: 'Congratulations on submitting your assignment!',
    labFinished: 'Congratulations on completing this lab!',
    finishedForLaunchInNewTab: 'Close and return to lesson',
    placeholder: 'Type your feedback here',
  },
  school: {
    name: 'School',
    lti_consumer_key: 'Lti Consumer Key',
    schoolType: 'School Type',
    edit: 'Edit School',
    create: 'Create School',
    messages: {
      saved: 'School saved.',
    },
    form: {
      name: 'School',
      ltiConsumerId: 'Consumer Key',
    },
    logo: {
      title: 'Upload school logo',
      body: 'make sure your logo is 380x52 pixels',
      failedSubmission: 'Logo failed to submit. Please try again. For additional help, contact Pathstream support.',
      uploadLogo: 'Upload logo',
      editLogo: 'Edit logo',
    },
  },
  ltiConsumer: {
    consumerKey: 'Consumer Key',
    edit: 'Edit LTI Consumer',
    create: 'Create LTI Consumer',
    messages: {
      saved: 'LTI Consumer saved.',
    },
    form: {
      consumerKey: 'Consumer Key',
      sharedSecret: 'Shared Secret',
      lti13Details: 'LTI 1.3 Details',
      v13ClientId: 'Client ID',
      v13DeploymentId: 'Deployment ID',
      v13JwkUrl: 'JWK Url',
      v13AuthorizeUrl: 'Authorize Url',
      v13TokenUrl: 'Token Url',
      v13OauthAudience: 'OAuth2 Audience',
    },
  },
  coachHeader: {
    shortcuts: {
      heading: 'Shortcuts',
      downloadReports: 'Download reports',
      resourceCenter: 'Resource center',
      help: 'Help',
      downloadReportsDescription: 'You can download cohort reports',
    },
    backToDashboard: 'Back to Dashboard',
    lastLoginWasOn: 'Your last login was {time} on {date}',
    goodMorning: 'Good morning',
    goodAfternoon: 'Good afternoon',
    goodEvening: 'Good evening',
  },
  cohortDashboard: {
    header: {
      viewCourseAsStudent: 'View course content',
    },
    menu: {
      studentsTab: 'Your students',
      assignmentsTab: 'Assignments',
    },
    assignmentsPage: {
      submittedAt: 'Submitted at {time} on {date}',
      resubmittedAt: 'Resubmitted at {time} on {date}',
      notStarted: 'Not started',
      viewRubric: 'View rubric',
      viewAssignmentContent: 'View assignment content',
      allowResubmissions: 'Allow resubmission',
    },
    studentsPage: {
      showCompletedStudents: 'Show completed students',
      showDroppedStudents: 'Show dropped students',
      filter: 'Filter',
      searchForStudent: 'Search for student',
      studentsToWatch: 'Students to watch',
      studentsToGrade: 'Students to grade',
      updateEnrollmentStatus: {
        markEnrollmentStatus: 'Mark enrollment status',
        enrolled: 'Enrolled',
        dropped: 'Dropped',
        completed: 'Completed Course',
        updateInfo:
          "This does not update the student's enrollment status in your school's learning management system and it does not remove student access for the course. This is to organize your list of students and to ensure accurate data collection on course completion.",
        completeInfo:
          'Students must complete and pass all assignments to receive the certificate co-branded by the Pathstream tech partner. Please confirm this is true for this student before marking that they completed the course.',
        verifyComplete: 'This student completed all assignments',
      },
      reasonToBeWatched: 'Reason to be watched',
      completedAssignments: 'Completed assignments',
      averageAssignmentGrade: 'Average assignment grade',
      lastLogin: 'Last Login',
      lessonsCompleted: 'Lessons completed',
      totalTimeSpent: 'Total time spent',
      hoursMinutes:
        '{hours, plural, =0 {0 hours} one { 1 hour } other {{hours} hours} } {minutes, plural, =0 {0 minutes} one { 1 minute } other {{minutes} minutes} }',
      notAvailable: 'N/A',
      status: 'Status',
      onTrack: 'On track',
      editEnrollmentStatus: 'Edit enrollment status',
      enrolledStatus: 'Currently enrolled',
      droppedStatus: 'Dropped course',
      completedStatus: 'Completed course',
    },
  },
  watchListReasons: {
    noLoginInPast5Days: 'No login in past 5 days',
    lowAverageGrade: 'Low average grade',
    behindOnAssignments: 'Behind on assignments',
  },
  evaluatorQueue: {
    searchPlaceholder: 'Filter by student, cohort, or course',
    noItemsToDisplay: {
      title: 'No items to display',
      subtitle: 'Try editing the search criteria and certificate filter to display more items.',
    },
  },
};

export default flatten(messages);
