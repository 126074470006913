import { map as _map, compact as _compact } from 'lodash';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Modal2 from '../library/Modal2';
import intl from '../../utils/intl';
import Block from '../../api/block';
import useSaveBlock from '../../hooks/useSaveBlock';
export default function SelectAdaptiveLearningTracks({ block, onHide }) {
  const { saveBlock, blockState } = useSaveBlock(block);

  const initialCheckboxState = Object.entries(Block.ADAPTIVE_TRACKS).reduce((acc, [trackKey]) => {
    let trackFound = block.adaptive_tracks?.includes(trackKey);

    if (block.adaptive_tracks?.length === 0) {
      acc[trackKey] = false;
    } else if (trackFound) {
      acc[trackKey] = true;
    } else {
      acc[trackKey] = false;
    }
    return acc;
  }, {});

  const [isChecked, setIsChecked] = useState(initialCheckboxState);

  const handleSave = async () => {
    const selectedTracks = Object.entries(isChecked)
      .filter(([_, value]) => value)
      .map(([key]) => key);

    let updatedBlockState = { ...blockState, adaptive_tracks: selectedTracks };
    saveBlock(updatedBlockState);
    onHide();
  };

  const handleCheckboxChange = event => {
    const { name, checked } = event.target;
    setIsChecked({
      ...isChecked,
      [name]: checked,
    });
  };

  return (
    <Modal2
      onHide={onHide}
      title={intl.formatMessage({ id: 'block.adaptive.learningTracks' })}
      okTitle="Save"
      onOk={handleSave}>
      <div className="select-tracks">
        {Object.entries(Block.ADAPTIVE_TRACKS).map(([trackKey, trackObject]) => {
          return (
            <label key={trackObject.index} className="track-name-label">
              <input
                className="track-checkbox"
                type="checkbox"
                name={trackKey}
                checked={isChecked[trackKey]}
                onChange={handleCheckboxChange}
              />
              <p className="track-name">{trackObject.label}</p>
            </label>
          );
        })}
      </div>
    </Modal2>
  );
}

SelectAdaptiveLearningTracks.propTypes = {
  block: PropTypes.object.isRequired,
  onHide: PropTypes.func.isRequired,
};
