import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import UserPicture from '../../../Utils/UserPicture';
import Icons from '../../../Utils/Icons';
import ChangePasswordModalContainer from '../../../Users/ChangePasswordModalContainer';
import showModal from '../../../../utils/showModal';
import TermsOfServiceContainer from '../../../Auth/TermsOfServiceContainer';
import useHandleOutsideClick from '../../../../hooks/useHandleOutsideClick';

export default function StudentMenu({ currentUser }) {
  const [isOpen, setIsOpen] = useState(false);
  const containerRef = useHandleOutsideClick(isOpen, () => {
    if (isOpen) {
      toggleMenu();
    }
  });

  const toggleMenu = () => setIsOpen(!isOpen);

  const handleChangePassword = e => {
    e.preventDefault();
    toggleMenu();
    showModal(ChangePasswordModalContainer, {
      user: currentUser,
    });
  };

  if (!currentUser) return null;

  return (
    <div className="student-menu" ref={containerRef}>
      <div className="user-profile">
        <button
          data-testid="student-user-profile"
          className="student-user-profile"
          onClick={toggleMenu}
          aria-label="student action dropdown menu">
          <UserPicture user={currentUser} aria-pressed={isOpen} />
        </button>

        {isOpen && (
          <div className="outer" data-testid="student-user-profile-menu">
            <div className="arrow-up" />
            <div className="bubble" id="user-menu" role="menu">
              <div className="heading">
                <div className="title">
                  <FormattedMessage id="nav.student.profile" />
                </div>
                <button onClick={toggleMenu} className="close-wrapper icon-element--fill-grey-600" tabIndex="0">
                  <Icons.Close onClick={toggleMenu} className="close" />
                </button>
              </div>
              <div className="body">
                <div className="change-password">
                  <a
                    alt="Change Password"
                    href="# "
                    onClick={handleChangePassword}
                    data-testid="student-change-password">
                    <FormattedMessage id="nav.student.changePassword" />
                  </a>
                </div>
                <div className="terms">
                  <a alt="Terms of Service" href="# " onClick={() => showModal(TermsOfServiceContainer)}>
                    <FormattedMessage id="user.termsOfService.heading" />
                  </a>
                </div>
                <div className="help">
                  <a
                    alt="help"
                    href="https://pathstream.force.com/support"
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={toggleMenu}>
                    <FormattedMessage id="nav.help" />
                  </a>
                </div>
              </div>
              <div className="footer">
                <Link className="sign-out" to="/logout">
                  <div className="sign-out-logo">
                    <Icons.Logout />
                  </div>
                  <div className="message">
                    <FormattedMessage id="nav.logout" />
                  </div>
                </Link>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

StudentMenu.propTypes = {
  currentStudent: PropTypes.object,
};
