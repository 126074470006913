import React, { Component } from 'react';
import PropTypes from 'prop-types';
import BackLink from '../Utils/BackLink';
import { FormattedMessage } from 'react-intl';
import Icons from '../Utils/Icons';
import CircularProgress from '../Utils/CircularProgress';

export default class PathHeader extends Component {
  static propTypes = {
    bootcamp: PropTypes.object,
    path: PropTypes.object,
    pathProgress: PropTypes.object,
    resumeUrl: PropTypes.string,
    backUrl: PropTypes.string,
    scrolledPastHeader: PropTypes.bool,
    canAuthor: PropTypes.bool.isRequired,
  };

  handleResumeClick = () => {
    const { resumeUrl, history } = this.props;
    history.push(resumeUrl);
  };

  render() {
    const { bootcamp, pathProgress, backUrl, resumeUrl, scrolledPastHeader, isEditMode, canAuthor } = this.props;

    return (
      <div className="header__container-course-overview">
        <div className="grid-row">
          <div className={`md-${isEditMode ? '10' : '7'} md-offset-1 vertical-center horizontal-space-between`}>
            <div className="after-scroll-content-left">
              <div className="after-scroll-go-back-wrapper">
                <BackLink
                  url={backUrl}
                  className="go-back-wrapper---gray"
                  text={
                    scrolledPastHeader ? null : (
                      <span className="go-back-button-label">
                        <FormattedMessage id="nav.bootcampsList" />
                      </span>
                    )
                  }
                />
              </div>
              {scrolledPastHeader && bootcamp && (
                <React.Fragment>
                  <div className="course-card__type-title">{bootcamp.title}</div>
                  {canAuthor && isEditMode && bootcamp.version && (
                    <div className="course-card__type-content">{bootcamp.version}</div>
                  )}
                </React.Fragment>
              )}
            </div>

            {scrolledPastHeader && bootcamp && bootcamp.show_progress && (
              <CircularProgress progress={pathProgress && pathProgress.progress} />
            )}
          </div>

          {!isEditMode && (
            <div className="md-3 md-offset-1">
              <div className="continue-button-wrapper">
                <button
                  disabled={!resumeUrl}
                  className="button-standard button-standard--transparent button-standard--short"
                  onClick={this.handleResumeClick}
                  style={{ opacity: resumeUrl ? 1 : 0.5 }}>
                  {pathProgress && pathProgress.current_section_progress_id ? (
                    <FormattedMessage id="common.continue" />
                  ) : (
                    <FormattedMessage id="common.start" />
                  )}
                  <span className="icon-right">
                    <Icons.ArrowNext />
                  </span>
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}
