import React from 'react';
import PropTypes from 'prop-types';
import { Avatar, Modal, Timeline } from 'antd';
import { v1 as uuidv1 } from 'uuid';
import { FormattedMessage } from 'react-intl';
import VideoPlayer from '../Utils/VideoPlayer';

const LaunchAssignmentStepsModal = ({ lmsSteps, assignment, section, onHide }) => {
  const steps = lmsSteps.steps.map((step, index) => {
    let stepValue = step.replace('$assignment_name$', assignment.title);
    stepValue = stepValue.replace('$section_name$', section.title);
    return (
      <Timeline.Item
        key={uuidv1()}
        dot={
          <Avatar className="launch-assignment__step-container">
            <span className="launch-assignment__step-number">{index + 1}</span>
          </Avatar>
        }>
        <div
          className="launch-assignment__step-value"
          dangerouslySetInnerHTML={{
            __html: stepValue,
          }}
        />
      </Timeline.Item>
    );
  });

  return (
    <Modal title={<FormattedMessage id="lti.messages.howToLaunchAssignment" />} visible onCancel={onHide} footer={null}>
      {lmsSteps.video_url && <VideoPlayer src={lmsSteps.video_url} />}
      <div className="launch-assignment__timeline-container">
        <Timeline>{steps}</Timeline>
      </div>
    </Modal>
  );
};
export default LaunchAssignmentStepsModal;

LaunchAssignmentStepsModal.propTypes = {
  lmsSteps: PropTypes.object.isRequired,
  assignment: PropTypes.object.isRequired,
  section: PropTypes.object.isRequired,
  onHide: PropTypes.func.isRequired,
};
