import { connect } from 'react-redux';
import CopyComponentModal from './CopyComponentModal';
import { copyComponent, fetchComponents } from '../../../actions/componentActions';
import { selectComponents } from '../../../selectors/componentSelectors';

const mapStateToProps = state => {
  const components = selectComponents(state);

  return {
    components,
  };
};

export default connect(mapStateToProps, {
  fetchComponents,
  copyComponent,
})(CopyComponentModal);
