import intl from '../../../utils/intl';
import showModal from '../../../utils/showModal';
import UserAttributeModal from '../UserAttributeModal/UserAttributeModal';
import AsanaAccountCreatedModal from '../AsanaAccountCreatedModal/AsanaAccountCreatedModal';

export const showGmailAddressModal = () => {
  showModal(UserAttributeModal, {
    attributeName: 'gmail_address',
    updateToken: true,
    message: intl.formatMessage(
      { id: 'profile.attributes.gmail_address.prompt.message' },
      {
        colabUrl: 'https://colab.research.google.com/notebooks/welcome.ipynb',
        googleSignupUrl: 'https://accounts.google.com/signup',
      },
    ),
  });
};

export const showAsanaAddressModal = () => {
  showModal(UserAttributeModal, {
    attributeName: 'asana_address',
    message: intl.formatMessage(
      { id: 'profile.attributes.asana_address.prompt.message' },
      { asanaUrl: 'https://asana.com/' },
    ),
    onSuccess: showAsanaAccountCreatedModal,
    okTitle: intl.formatMessage({ id: 'profile.attributes.asana_address.prompt.okTitle' }),
  });
};

export const showAsanaAccountCreatedModal = () => {
  showModal(AsanaAccountCreatedModal, {
    onStartOver: showAsanaAddressModal,
  });
};
