import axios from './axios';

export default class TutorialProgress {
  static start(tenantId, tutorialProgressId) {
    return axios.put(`/labs/tutorial_progresses/${tutorialProgressId}/start`).then(response => response.data);
  }

  static complete(tenantId, tutorialProgressId) {
    return axios.put(`/labs/tutorial_progresses/${tutorialProgressId}/complete`).then(response => response.data);
  }

  static save(tenantId, tutorialProgress) {
    return axios
      .put(`/labs/tutorial_progresses/${tutorialProgress.id}`, tutorialProgress)
      .then(response => response.data);
  }
}
