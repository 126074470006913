import React from 'react';
import ViewRubricButton from '../../AssignmentsPage/components/ViewRubricButton';
import ViewAsStudentButton from '../../AssignmentsPage/components/ViewAsStudentButton';
import Loading from '../../../../Utils/Loading';
import AllowResubmissionButton from '../../AssignmentsPage/components/AllowResubmissionButton';

const AssignmentCardActionsContent = ({ assignment, assignmentProgress, hideActions }) => {
  if (!assignment) {
    return <Loading />;
  }

  const showAllowResubmissions = assignment && assignmentProgress && assignmentProgress.completed_at;

  return (
    <div onClick={hideActions}>
      <div className="assignment-action-button" data-testid="view-rubric-action">
        <ViewRubricButton assignment={assignment} />
      </div>
      <div className="assignment-action-button" data-testid="view-as-student-button">
        <ViewAsStudentButton assignment={assignment} />
      </div>
      {showAllowResubmissions && (
        <div className="assignment-action-button" data-testid="allow-resubmission-button">
          <AllowResubmissionButton assignmentProgress={assignmentProgress} />
        </div>
      )}
    </div>
  );
};

export default AssignmentCardActionsContent;
