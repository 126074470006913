import React, { Component } from 'react';
import PropTypes from 'prop-types';
import showModal from '../../../utils/showModal';
import EditUnitModalContainer from '../Paths/EditUnitModalContainer';
import SelectUnitModal from './SelectUnitModal';
import Dropdown from '../Components/Dropdown';
import Unit from '../../../api/unit';

export default class AddUnit extends Component {
  static propTypes = {
    section: PropTypes.object.isRequired,
  };

  handleAddUnit = unitType => {
    showModal(EditUnitModalContainer, {
      section: this.props.section,
      unit: {
        unit_type: unitType,
      },
      onSave: this.props.saveUnit,
    });
  };

  handleSelectUnits = unitType => {
    showModal(SelectUnitModal, {
      section: this.props.section,
      unitType,
    });
  };

  render() {
    const dropdownButtons = [
      { messageId: 'unit.heading.copyExistingModule', onClick: () => this.handleSelectUnits(Unit.MODULE) },
      { messageId: 'unit.heading.copyExistingProject', onClick: () => this.handleSelectUnits(Unit.PROJECT) },
      { messageId: 'unit.heading.copyExistingGradedQuiz', onClick: () => this.handleSelectUnits(Unit.GRADED_QUIZ) },
      { messageId: 'unit.heading.createModule', onClick: () => this.handleAddUnit(Unit.MODULE) },
      { messageId: 'unit.heading.createProject', onClick: () => this.handleAddUnit(Unit.PROJECT) },
      { messageId: 'unit.heading.createGradedQuiz', onClick: () => this.handleAddUnit(Unit.GRADED_QUIZ) },
    ];

    return (
      <div className="learning-path-item-add-module-dropdown">
        <Dropdown toggleButtonMessageId="section.addModule" buttons={dropdownButtons} />
      </div>
    );
  }
}
