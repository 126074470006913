import { map as _map } from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Loading from '../Utils/Loading';
import ContentWrapperContainer from '../v2/Nav/ContentWrapperContainer';
import showModal from '../../utils/showModal';
import * as analytics from '../../utils/analytics';
import { setPageTitle } from '../../helpers/pageHelper';
import BootcampIntroModalContainer from '../Student/BootcampIntroModalContainer';
import { showGmailAddressModal } from '../Users/utils/showSetupModal';
import CourseOverview from './CourseOverview/CourseOverview';
import StudentCourseOverviewContainer from './CourseOverview/StudentCourseOverviewContainer';
import StudentHeaderContainer from './CourseOverview/StudentHeaderContainer';
import CourseTabs from './CourseTabs/CourseTabs';
import PathProgressContainer from './PathProgressContainer';
import ResourcesTab from './ResourcesTab/ResourcesTab';
import BootcampGrades from '../Student/BootcampGrades/BootcampGrades';
import PathHeaderContainer from './PathHeaderContainer';

export default class PathShow extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoaded: false,
    };
  }

  componentDidMount() {
    this.fetchData(this.props);
    analytics.pageStart(this.props.isStudent ? 'View Course' : 'Edit Course');

    if (this.props.bootcamp) this.showSetupModals();
  }

  componentWillUnmount() {
    const { cohort, bootcamp } = this.props;
    analytics.pageEnd({
      bootcamp,
      cohort,
    });
  }

  componentDidUpdate(prevProps) {
    const { bootcamp } = this.props;

    if (bootcamp) {
      setPageTitle('pageName.course.overview', { course: bootcamp.title });

      if ((prevProps.bootcamp || {}).id != bootcamp.id) this.showSetupModals();
    }
    if (this.props?.cohort?.display_school_logo && !this.props.school) {
      this.props.fetchSchool(this.props.cohort.school_id);
    }
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.fetchData(props);
  }

  fetchData = props => {
    const { bootcampId, cohortId, isStudent, currentUser, isLtiStudent, enrollmentId, isCoach } = props;
    const { isLoaded } = this.state;

    if (!currentUser || isLoaded) {
      return;
    }

    if (isStudent) {
      this.fetchStudentData(isLtiStudent, cohortId, enrollmentId);
    } else {
      if (!this.fetchBootcampPromise) {
        this.fetchBootcampPromise = this.props.fetchBootcamp(bootcampId);
        this.setState({ isLoaded: true });
      }

      if (!this.fetchCohortPromise && cohortId && isCoach) {
        this.fetchCohortPromise = this.props.fetchCohort(cohortId);
      }
    }

    this.showIntroReturningModal();
  };

  fetchStudentData = (isLtiStudent, cohortId, enrollmentId) => {
    if (isLtiStudent && !this.ltiAssignmentProgressFetched) {
      this.props.fetchLtiAssignmentProgresses();
      this.ltiAssignmentProgressFetched = true;
    }
    if (!cohortId) {
      this.props.fetchCurrentEnrollments();
    } else {
      this.props.fetchEnrollment(enrollmentId);
      this.setState({ isLoaded: true });
    }
  };

  showSetupModals = () => {
    const { bootcamp, currentUser } = this.props;

    if (bootcamp.requires_colab && !currentUser.gmail_address) showGmailAddressModal();
  };

  showIntroReturningModal = () => {
    const { bootcamp, pathProgress, showIntroModal, history } = this.props;

    if (this.modalShown || !showIntroModal) {
      return;
    }

    this.modalShown = true;

    showModal(BootcampIntroModalContainer, {
      bootcamp,
      pathProgress,
      history,
    });
  };

  renderCourseOverview(props, bootcamp) {
    const {
      path,
      sections,
      sectionProgresses,
      currentUser,
      isEditMode,
      saveSection,
      currentSectionProgress,
      reorderSections,
      cohortId,
    } = props;
    const showAsanaButton = bootcamp.requires_asana && !!currentUser?.asana_address;
    return (
      <CourseOverview
        isEditMode={isEditMode}
        currentUser={currentUser}
        path={path}
        saveSection={saveSection}
        bootcamp={bootcamp}
        sections={sections}
        currentSectionProgress={currentSectionProgress}
        reorderSections={reorderSections}
        sectionProgresses={sectionProgresses}
        showAsanaButton={showAsanaButton}
        cohortId={cohortId}
      />
    );
  }

  renderStudentCourseOverview(props, bootcamp) {
    const {
      path,
      sections,
      sectionProgresses,
      currentUser,
      currentSectionProgress,
      cohortId,
      cohort,
      programCohort,
    } = props;

    return (
      <StudentCourseOverviewContainer
        currentUser={currentUser}
        bootcamp={bootcamp}
        sections={sections}
        currentSectionProgress={currentSectionProgress}
        sectionProgresses={sectionProgresses}
        showAsanaButton={bootcamp.requires_asana && !!currentUser?.asana_address}
        cohortId={cohortId}
        cohort={cohort}
        path={path}
        programCohort={programCohort}
      />
    );
  }

  renderBootcampGrades(props) {
    const { bootcamp, fetchGradedItems, matchUrl, cohortId, isLiteCourse } = props;
    return (
      <BootcampGrades
        bootcamp={bootcamp}
        fetchGradedItems={fetchGradedItems}
        matchUrl={matchUrl}
        cohortId={cohortId}
        hideDueDates={isLiteCourse}
      />
    );
  }

  renderResourcesTab(props, bootcamp) {
    const { matchUrl, cohort, programCohort, isLiteCourse } = props;

    if (!isLiteCourse) {
      return <ResourcesTab bootcamp={bootcamp} programCohort={programCohort} matchUrl={matchUrl} cohort={cohort} />;
    }
  }

  renderTabs(tabs) {
    return <CourseTabs className="student-tabs" tabs={tabs} progress={<PathProgressContainer />} />;
  }
  /* eslint-disable complexity */
  render() {
    const { bootcamp, path, currentUser, school, isLtiStudent, isStudent, isLiteCourse } = this.props;

    const { isLoaded } = this.state;

    if (!isLoaded || !bootcamp || !path) {
      return (
        <ContentWrapperContainer className="course-overview" showLogo hideStaffNav>
          <Loading />
        </ContentWrapperContainer>
      );
    }

    const showCourseTabs = analytics.inFlag(currentUser, 'show_course_tabs');

    if (!isLtiStudent && !isLiteCourse && isStudent) {
      const tabs = [
        { name: 'OVERVIEW', panel: this.renderStudentCourseOverview(this.props, bootcamp) },
        { name: 'GRADES', panel: this.renderBootcampGrades(this.props) },
        { name: 'RESOURCES', panel: this.renderResourcesTab(this.props, bootcamp) },
      ];
      return (
        <div className="student-course-overview ps2">
          <StudentHeaderContainer student={currentUser} bootcamp={bootcamp} />
          {this.renderTabs(tabs)}
        </div>
      );
    } else if ((showCourseTabs && isLtiStudent && isStudent) || (isLiteCourse && isStudent)) {
      const tabs = [
        { name: 'OVERVIEW', panel: this.renderStudentCourseOverview(this.props, bootcamp) },
        { name: 'GRADES', panel: this.renderBootcampGrades(this.props) },
      ];
      return (
        <div className="student-course-overview ps2">
          <StudentHeaderContainer student={currentUser} bootcamp={bootcamp} />
          {this.renderTabs(tabs)}
        </div>
      );
    } else if (!showCourseTabs && isLtiStudent && isStudent) {
      return (
        <div className="student-course-overview ps2">
          <StudentHeaderContainer student={currentUser} bootcamp={bootcamp} noTabs />
          <PathProgressContainer />
          <div className="add-panel-non-tab">{this.renderStudentCourseOverview(this.props, bootcamp)}</div>
        </div>
      );
    } else {
      return (
        <ContentWrapperContainer
          headerContent={PathHeaderContainer}
          className="course-overview"
          scrollHeaderText={bootcamp.title}
          showLogo
          hideStaffNav
          school={school}>
          {this.renderCourseOverview(this.props, bootcamp)}
        </ContentWrapperContainer>
      );
    }
  }
}
/* eslint-enable complexity */

PathShow.propTypes = {
  bootcamp: PropTypes.object,
  path: PropTypes.object,
  pathProgress: PropTypes.object,
  currentSectionProgress: PropTypes.object,
  sections: PropTypes.array,
  durationsBySectionId: PropTypes.object,
  isEditMode: PropTypes.bool,
  matchUrl: PropTypes.string.isRequired,
};
