import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { ReactComponent as SpinnerIcon } from '../../styles/pathstream/svg-jsx/icon-spinner.svg';

export default class Loading extends Component {
  render() {
    const { messageId, showMessage } = this.props;

    return (
      <div className="loading">
        {showMessage && <FormattedMessage id={messageId || 'common.loading'} />}
        <span className="icon" style={{ width: 24, height: 24 }}>
          <SpinnerIcon />
        </span>
      </div>
    );
  }
}
Loading.propTypes = {
  messageId: PropTypes.string,
};

Loading.defaultProps = {
  showMessage: true,
};
