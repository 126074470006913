import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Modal } from 'antd';
import { useDispatch } from 'react-redux';
import Errors from '../../Utils/Errors';
import { toastSuccess } from '../../../utils/toastHelper';
import intl from '../../../utils/intl';
import CustomInput from '../../Utils/CustomInput';
import ModalFooter from '../../library/ModalFooter';
import { saveSchool, updateSchool } from '../../../actions/schoolActions';
import CustomSelect from '../../Utils/CustomSelect';
import UploadSchoolLogo from './component/UploadSchoolLogo';
import { SchoolType } from './SchoolTypeEnum';

const schoolTypeOptions = Object.values(SchoolType).map(type => ({
  label: type,
  value: type,
}));

const getLtiConsumerOptions = ltiConsumers =>
  ltiConsumers.map(ltiConsumer => ({
    label: ltiConsumer.consumer_key,
    value: ltiConsumer.id,
  }));

export default function EditSchoolModal({ school, onHide, ltiConsumers }) {
  const [errors, setErrors] = useState([]);
  const [isSaving, setIsSaving] = useState(false);
  const [schoolData, setSchoolData] = useState(school);
  const [files, setFiles] = useState();

  const dispatch = useDispatch();

  const ltiConsumerOptions = getLtiConsumerOptions(ltiConsumers);

  const handleChange = e => {
    const { name, value } = e.target;

    setSchoolData({
      ...schoolData,
      [name]: value,
    });
  };

  const handleSelectChange = name => option => {
    const value = option === null ? null : option.value;
    setSchoolData({
      ...schoolData,
      [name]: value,
    });
  };

  const handleFileChange = files => {
    setFiles(files);
    setSchoolData({
      ...schoolData,
      logo: files[0].url,
    });
  };

  const saveOrUpdateSchool = () => {
    if (school.id) {
      return updateSchool(schoolData);
    }
    return saveSchool(schoolData);
  };

  const handleSubmit = async e => {
    e.preventDefault();

    if (isSaving) return;

    setIsSaving(true);
    setErrors([]);

    saveOrUpdateSchool()(dispatch)
      .then(() => {
        toastSuccess(<FormattedMessage id="school.messages.saved" />);
        onHide();
      })
      .catch(err => {
        // eslint-disable-next-line no-console
        console.log('ERROR', err);
        setErrors(err?.value?.errors);
      })
      .finally(() => {
        setIsSaving(false);
      });
  };

  const onCancel = () => {
    files && RTE.deleteFiles(files.map(file => file.s3Key));
    onHide();
  };

  return (
    <Modal
      centered
      title={schoolData.id ? <FormattedMessage id="school.edit" /> : <FormattedMessage id="school.create" />}
      visible
      onOk={handleSubmit}
      onCancel={onCancel}
      footer={
        <ModalFooter
          showCancel
          cancelTitle={<FormattedMessage id="common.cancel" />}
          onCancel={onCancel}
          okTitle={<FormattedMessage id="common.save" />}
          onOk={handleSubmit}
        />
      }>
      <Errors errors={errors} />

      <fieldset disabled={isSaving}>
        <CustomInput
          name="name"
          onChange={handleChange}
          value={schoolData.name}
          label={intl.formatMessage({ id: 'school.form.name' })}
        />
        <CustomSelect
          className="Select--simple"
          name="lti_consumer_id"
          value={schoolData.lti_consumer_id}
          onChange={handleSelectChange('lti_consumer_id')}
          options={ltiConsumerOptions}
          clearable
          placeholder={intl.formatMessage({ id: 'school.form.ltiConsumerId' })}
          aria-label={intl.formatMessage({ id: 'school.form.ltiConsumerId' })}
        />
        <CustomSelect
          className="Select--simple"
          name="school_type"
          value={schoolData.school_type}
          onChange={handleSelectChange('school_type')}
          options={schoolTypeOptions}
          clearable
          placeholder={intl.formatMessage({ id: 'school.schoolType' })}
          aria-label={intl.formatMessage({ id: 'school.schoolType' })}
        />
        <UploadSchoolLogo school={schoolData} onFilesChange={handleFileChange} />
      </fieldset>
    </Modal>
  );
}

EditSchoolModal.propTypes = {
  school: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    logo: PropTypes.string,
    school_type: PropTypes.string,
  }).isRequired,
  onHide: PropTypes.func.isRequired,
  ltiConsumers: PropTypes.array.isRequired,
};
