import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import useTutorialContext from '../../../context/Tutorial/useTutorialContext';
import StepHeader from './StepHeader';
import StepFooter from './StepFooter';
import { PopupImageWrapper, PopupTableWrapper } from '../../Utils/Popup';
import { DownloadLabStarterFile } from '../../Labs/DownloadStarterFile';

const LabStepWrapper = ({ quizOnLab, StepComponent, stepProps, onStepChanged, onFinishClick }) => {
  const { steps, currentStepIndex, isEditMode, tutorial, getCurrentStep } = useTutorialContext();
  const step = getCurrentStep();

  const scrollableDiv = useRef(null);

  useEffect(() => {
    if (scrollableDiv) {
      scrollableDiv.current.scrollTop = 0;
    }
  }, [currentStepIndex]);

  return (
    <>
      <StepHeader
        tutorial={tutorial}
        isEditMode={isEditMode}
        stepIndex={currentStepIndex}
        totalSteps={steps.length}
        step={step}
      />
      <DownloadLabStarterFile step={steps && steps[0]} app={tutorial?.app} />
      <div className="lab-exercise__content" ref={scrollableDiv}>
        {step && (
          <PopupImageWrapper enabled={!isEditMode}>
            <PopupTableWrapper enabled={!isEditMode}>
              <StepComponent {...stepProps} isEditMode={isEditMode} step={step} quizOnLab={quizOnLab} />
            </PopupTableWrapper>
          </PopupImageWrapper>
        )}
      </div>
      <StepFooter onStepChanged={onStepChanged} onFinishClick={onFinishClick} />
    </>
  );
};

LabStepWrapper.propTypes = {
  quizOnLab: PropTypes.object.isRequired,
  StepComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
  stepProps: PropTypes.object,
  onStepChanged: PropTypes.func,
  onFinishClick: PropTypes.func,
};

export default LabStepWrapper;
