import { values as _values } from 'lodash';
import { normalize } from 'normalizr';
import Enrollment from '../api/enrollment';
import Schemas from '../api/schemas';
import { updateEntities } from './entityActions';
import { fetchCurrentTenant } from './tenantActions';
import { applicationSave, applicationDestroy, applicationApiExecute, applicationUpdate } from './applicationActions';
import { wait } from '../utils/utils';

export const saveEnrollment = applicationSave(Enrollment);
export const destroyEnrollment = applicationDestroy(Enrollment);
export const fetchEnrollments = applicationApiExecute(Enrollment.forCohort);
export const updateEnrollment = applicationUpdate(Enrollment);

const promises = {};

export const fetchCurrentEnrollments = () => dispatch => {
  if (promises.fetchCurrentEnrollments) {
    return promises.fetchCurrentEnrollments;
  }
  return (promises.fetchCurrentEnrollments = fetchCurrentTenant()(dispatch)
    .then(async tenant => {
      const response = await Enrollment.currentEnrollments(tenant.id);
      const { entities } = normalize(response.entities, Schemas.entities);
      dispatch(updateEntities({ entities }));
      delete promises.fetchCurrentEnrollments;

      return _values(entities.enrollments);
    })
    .catch(err => {
      delete promises.fetchCurrentEnrollments;
      throw err;
    }));
};

export const fetchEnrollment = enrollmentId => dispatch => {
  if (promises.fetchEnrollment) {
    return promises.fetchEnrollment;
  }
  return (promises.fetchEnrollment = fetchCurrentTenant()(dispatch)
    .then(async tenant => {
      const response = await Enrollment.get(tenant.id, enrollmentId);
      const { entities } = normalize(response.entities, Schemas.entities);
      dispatch(updateEntities({ entities }));

      rebuildEnrollment(enrollmentId)(dispatch);
      delete promises.fetchEnrollment;
      return response.value;
    })
    .catch(err => {
      delete promises.fetchEnrollment;
      throw err;
    }));
};

export const getEnrollmentsForUser = userId => dispatch =>
  fetchCurrentTenant()(dispatch).then(tenant =>
    Enrollment.forUser(tenant.id, userId).then(response => {
      const { entities } = normalize(response.entities, Schemas.entities);
      dispatch(updateEntities({ entities }));
      return response.entities.enrollments;
    }),
  );

export const fetchEnrollmentForCohort = cohortId => async dispatch => {
  if (promises.fetchEnrollmentForCohort) {
    return promises.fetchEnrollmentForCohort;
  }

  // wait till the enrollment is ready
  const waitTillReady = async () => {
    const enrollments = await fetchCurrentEnrollments()(dispatch);

    // check if the enrollment is ready
    const enrollment = enrollments.find(enr => enr.enrollable_id === cohortId && enr.ready);
    if (enrollment) {
      // fetch the current enrollment
      return fetchEnrollment(enrollment.id)(dispatch);
    }

    // try again after 5s
    return wait(5000).then(waitTillReady);
  };

  return (promises.fetchEnrollmentForCohort = waitTillReady()
    .then(response => {
      delete promises.fetchEnrollmentForCohort;
      return response;
    })
    .catch(err => {
      delete promises.fetchEnrollmentForCohort;
      throw err;
    }));
};

const lastRebuildTime = {};
const REBUILD_INTERVAL = 6 * 60 * 60 * 1000; // 6 hours
export const rebuildEnrollment = enrollmentId => dispatch => {
  const now = Date.now();
  if (lastRebuildTime[enrollmentId] && lastRebuildTime[enrollmentId] + REBUILD_INTERVAL > now) {
    return;
  }

  return fetchCurrentTenant()(dispatch).then(async tenant => {
    const response = await Enrollment.rebuild(tenant.id, enrollmentId);
    const { entities } = normalize(response.entities, Schemas.entities);
    dispatch(updateEntities({ entities }));
    lastRebuildTime[enrollmentId] = now;
    return response.value;
  });
};
