import { map as _map, compact as _compact } from 'lodash';
import createCachedSelector from 're-reselect';

import { selectTutorial } from './tutorialSelectors';
import { selectEntities, selectAll, selectFromBy } from './applicationSelectors';
import { selectLessonByBlock } from './lessonSelectors';

const selectStepEntities = selectEntities('steps');
export const selectSteps = selectAll(selectStepEntities);

const tutorialStepIds = (state, tutorialId) => (selectTutorial(state, tutorialId) || {}).step_ids;

export const selectTutorialSteps = createCachedSelector(
  [tutorialStepIds, selectStepEntities],
  (stepIds, stepEntities) => {
    return _compact(_map(stepIds, stepId => stepEntities[stepId]));
  },
)((state, tutorialId) => tutorialId);

export const selectStepByLesson = selectFromBy(selectSteps, 'lesson_id');
export const selectStepByBlock = (state, block) => selectStepByLesson(state, selectLessonByBlock(state, block));
