import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import Modal2 from '../../library/Modal2';
import Errors from '../../Utils/Errors';
import CustomInput from '../../Utils/CustomInput';
import useCurrentUser from '../../../hooks/useCurrentUser';
import { ping } from '../../../actions/authActions';
import { toastSuccess } from '../../../utils/toastHelper';
import intl from '../../../utils/intl';
import { saveProfile } from '../../../actions/userActions';

const UserAttributeModal = ({ attributeName, onHide, onSuccess, updateToken, message, okTitle }) => {
  const dispatch = useDispatch();
  const { currentUser } = useCurrentUser();
  const [attributeValue, setUserAttributeValue] = useState(currentUser[attributeName] || '');
  const [errors, setErrors] = useState([]);
  const [isSaving, setIsSaving] = useState(false);

  const handleChange = event => {
    setUserAttributeValue(event.target.value);
  };

  const getUpdatedToken = () => {
    ping(true)(dispatch);
  };

  const handleSubmit = () => {
    if (isSaving) {
      return;
    }

    setIsSaving(true);

    saveProfile({ ...currentUser, [attributeName]: attributeValue })(dispatch)
      .then(() => {
        if (updateToken) getUpdatedToken();
        toastSuccess(intl.formatMessage({ id: 'profile.profileUpdated' }));
        onHide();
        if (onSuccess) onSuccess();
      })
      .catch(err => {
        setErrors(err.errors);
        setIsSaving(false);
      });
  };

  const title = intl.formatMessage({ id: `profile.attributes.${attributeName}.prompt.title` });
  if (!message) {
    message = intl.formatMessage({ id: `profile.attributes.${attributeName}.prompt.message` });
  }
  const label = intl.formatMessage({ id: `profile.attributes.${attributeName}.form.label` });

  return (
    <Modal2 className="user-attribute-modal" onHide={onHide} title={title} onOk={handleSubmit} okTitle={okTitle}>
      <div dangerouslySetInnerHTML={{ __html: message }} />

      {isSaving && (
        <div className="loading-wrapper">
          <FormattedMessage id="common.saving" />
        </div>
      )}
      {!isSaving && <Errors errors={errors} />}

      <CustomInput name={attributeName} onChange={handleChange} value={attributeValue} label={label} />
    </Modal2>
  );
};

UserAttributeModal.propTypes = {
  attributeName: PropTypes.string.isRequired,
  onHide: PropTypes.func.isRequired,
  onSuccess: PropTypes.func,
  updateToken: PropTypes.bool,
  message: PropTypes.string,
  okTitle: PropTypes.string,
};

export default UserAttributeModal;
