import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import BlockEditOptions from './BlockEditOptions';
import { destroyBlock } from '../../../actions/blockActions';
import { selectBootcamp } from '../../../selectors/bootcampSelectors';

const mapStateToProps = (state, ownProps) => {
  const { bootcampId } = ownProps.match.params;
  const bootcamp = selectBootcamp(state, bootcampId);

  return {
    bootcamp,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onDelete: ownProps.onDelete === undefined ? block => dispatch(destroyBlock(block)) : ownProps.onDelete,
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BlockEditOptions));
