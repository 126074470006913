export const getEmbedUrl = url => {
  if (url.indexOf('docs.google.com/presentation/d/') > -1) {
    // url is of the form - https://docs.google.com/presentation/d/1StrGMZh0OvNVhkleaEU7bAYTI2XUl9BhpCJEWRw8dd0/edit?usp=sharing
    const urlParts = url.split('/');
    const docId = urlParts[urlParts.length - 2];

    return `https://docs.google.com/presentation/embed?id=${docId}&size=l`;
  }
  return url;
};
