/* eslint-disable max-classes-per-file */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ContentWrapperContainer from '../ContentWrapperContainer';
import AdminHeader from './AdminHeader';

const adminHeader = title =>
  class extends React.Component {
    render() {
      return <AdminHeader title={title} />;
    }
  };

export default class AdminWrapper extends Component {
  render() {
    const { headerTitle, children, hideStaffNav, ...extraProps } = this.props;
    return (
      <ContentWrapperContainer
        headerText={headerTitle}
        showLogo
        className="admin-page"
        headerClass="admin-pages-header"
        hideAuthorToggle
        hideStaffNav={hideStaffNav}
        headerContent={adminHeader(headerTitle)}
        {...extraProps}>
        <div className="admin-pages-content">
          <div className="grid-wrap">{children}</div>
        </div>
      </ContentWrapperContainer>
    );
  }
}

AdminWrapper.propTypes = {
  headerTitle: PropTypes.any,
  children: PropTypes.any,
  hideStaffNav: PropTypes.bool,
};
