import { isEmpty as _isEmpty } from 'lodash';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import GradedQuizTile from './GradedQuizTile';
import { destroySectionUnit } from '../../actions/sectionUnitActions';
import { saveUnit } from '../../actions/unitActions';

const mapStateToProps = (state, ownProps) => {
  const { bootcamp } = ownProps;
  let cohortsArray = Object.values(state.entities.cohorts);
  const bootcampInstance = cohortsArray.find(cohort => cohort.bootcamp_id === bootcamp.id);
  return {
    cohort: bootcampInstance,
  };
};

export default connect(mapStateToProps, { destroySectionUnit, saveUnit })(withRouter(GradedQuizTile));
