import { isEmpty as _isEmpty } from 'lodash';
import { connect } from 'react-redux';
import BootcampCohortSelector from './BootcampCohortSelector';
import { selectBootcamps, selectBootcampEntities } from '../../selectors/bootcampSelectors';
import { selectCohorts } from '../../selectors/cohortSelectors';
import { selectCurrentUser } from '../../selectors/authSelectors';
import { canAdministrate, canCoach } from '../../helpers/userAuth';
import { dropdownBootcampsWithCohortTitle, dropdownBootcamps } from '../../helpers/bootcampHelper';

const mapStateToProps = (state, ownProps) => {
  const { cohortId } = ownProps;

  const bootcamps = selectBootcamps(state);
  const bootcampEntities = selectBootcampEntities(state);
  const cohorts = selectCohorts(state);
  let selectedBootcamp;
  let selectedCohort;
  let accessibleBootcamps = [];
  const currentUser = selectCurrentUser(state);
  const isInstructor = canCoach(currentUser) && !canAdministrate(currentUser);

  if (cohortId) {
    selectedCohort = _isEmpty(cohorts) ? null : cohorts.find(c => c.id === cohortId);
    selectedBootcamp = _isEmpty(bootcamps) ? null : bootcamps.find(b => b.id === selectedCohort.bootcamp_id);
  }

  if (isInstructor) {
    accessibleBootcamps = dropdownBootcampsWithCohortTitle(bootcampEntities, cohorts);
  } else {
    accessibleBootcamps = dropdownBootcamps(bootcampEntities, cohorts);
  }

  return {
    selectedBootcamp,
    selectedCohort,
    bootcamps: accessibleBootcamps,
  };
};

export default connect(mapStateToProps)(BootcampCohortSelector);
