import React from 'react';
import AdminWrapper from '../../v2/Nav/Admin/AdminWrapper';
import intl from '../../../utils/intl';
import CoachDashboardContent from './CoachDashboardContent';

const CoachDashboard = () => {
  return (
    <AdminWrapper headerTitle={intl.formatMessage({ id: 'nav.bootcamps' })} hideAuthorToggle={false}>
      <CoachDashboardContent />
    </AdminWrapper>
  );
};

export default CoachDashboard;
