import { getLocationPath } from './dataSourceHelpers';
import { getDataFromCellAndEval, sheetNotFound } from './evalHelpers';

const evalCell = ({ data, location, property, operator, value }) => {
  const sheet = getLocationPath(data, location);
  if (!sheet) return sheetNotFound(property, operator, value);

  return getDataFromCellAndEval({
    path: sheet.dataset,
    location,
    property,
    operator,
    value,
  });
};

export default evalCell;
