import React, { useEffect, createRef } from 'react';
import PropTypes from 'prop-types';
import { validateFilename } from 'freezeframe/src/utils';
import classnames from 'classnames';
import 'react-image-lightbox/style.css';
import showModal from '../../../utils/showModal';
import ImagePopup from './ImagePopup';
import { isInsideCKEditor } from '../../../utils/imageHelper';

const activeImagePopupSrcs = new Set();
export default function PopupImageWrapper({ enabled, children, disableOnSelect }) {
  const containerRef = createRef(null);

  const handleEvents = e => {
    if (!enabled) return;

    const { target } = e;
    if (target.tagName !== 'IMG') return;
    if (isInsideCKEditor(target)) return;

    if (e.type === 'click' || e.key === 'Enter') {
      const src = target.getAttribute('src');
      const alt = target.getAttribute('alt');

      if (disableOnSelect && disableOnSelect(src)) return;

      if (!activeImagePopupSrcs.has(src)) {
        activeImagePopupSrcs.add(src);
        target.classList.add('off');

        const onUnmount = () => {
          activeImagePopupSrcs.delete(src);
          target.classList.remove('off');
        };

        showModal(ImagePopup, {
          src,
          alt,
          onUnmount,
        });
      }
    }
  };

  // Note: This should not be enabled when authors are editing.
  //   ckeditor's image2 plugin has a draggable feature that adds an img to the dom.
  //   Adding tabindex to that img is seen as a change in content in ckeditor and breaks the undo/redo feature
  const makeImagesKeyboardAccessible = () => {
    const images = containerRef.current.getElementsByTagName('img');
    for (let i = 0; i < images.length; i += 1) {
      if (!validateFilename(images[i].src)) {
        images[i].tabIndex = 0;
      }
    }
  };

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.addEventListener('click', handleEvents);
      containerRef.current.addEventListener('keypress', handleEvents);
      if (enabled) {
        makeImagesKeyboardAccessible();
      }
    }

    return () => {
      if (containerRef.current) {
        containerRef.current.removeEventListener('click', handleEvents);
        containerRef.current.removeEventListener('keypress', handleEvents);
      }
    };
  });

  return (
    <div className={classnames('popup-image-wrapper', enabled && 'enabled')} ref={containerRef}>
      {children}
    </div>
  );
}

PopupImageWrapper.propTypes = {
  enabled: PropTypes.bool,
  children: PropTypes.node,
  disableOnSelect: PropTypes.func,
};

PopupImageWrapper.defaultProps = {
  enabled: true,
};
