import { connect } from 'react-redux';
import QuizLoader from '../Quiz/Loader';
import { selectNextQuestions, selectQuestionsByQuizIdSorted } from '../../selectors/questionSelectors';
import { selectQuizIdByBlock, selectQuizByBlock } from '../../selectors/quizSelectors';
import { fetchQuiz } from '../../actions/quizActions';

const mapStateToProps = (state, ownProps) => {
  const { block } = ownProps;
  const quiz = selectQuizByBlock(state, block);
  const questions = quiz ? selectNextQuestions(state, quiz.id) : [];
  const allQuestions = quiz ? selectQuestionsByQuizIdSorted(state, quiz.id) : [];

  return {
    quiz,
    questions,
    allQuestions,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const { block } = ownProps;
  const quizId = selectQuizIdByBlock(block);

  return {
    fetchQuiz: () => dispatch(fetchQuiz(quizId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(QuizLoader);
