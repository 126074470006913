import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import welcome from '../../styles/v2/images/welcome.svg';
import poweredByPathstream from '../../styles/v2/svg-jsx/powered-by-pathstream.svg';
import { PathstreamLoader } from '../Utils/PathstreamLoader';

import logo from '../../styles/v2/svg-ps2/home-logo.svg';
export default function Template({
  children,
  afterDialog,
  onSubmit,
  titleMsgId,
  welcomeMsgId,
  taglineId,
  formHeader,
  formTagline,
  centerTagline,
  loaderInsteadOfLogo,
}) {
  return (
    <>
      <div className="auth-view-wrapper">
        <div className="auth-welcome-container">
          <div className="auth-welcome-content">
            <img src={welcome} className="auth-image" />
            {welcomeMsgId && (
              <h2>
                <FormattedMessage id={welcomeMsgId} />
              </h2>
            )}
            {taglineId && (
              <p>
                <FormattedMessage id={taglineId} />
              </p>
            )}
          </div>

          <img src={poweredByPathstream} className="powered-by-pathstream" />
        </div>
        <div className="auth-modal-container">
          <div className="auth-align-modal">
            <div className="modal-dialog-wrapper modal-dialog-wrapper--opened">
              <div className="modal-dialog">
                <form className="modal-dialog-content modal-dialog-content--small" onSubmit={onSubmit}>
                  {titleMsgId && (
                    <div className="modal-dialog-header modal-dialog-header--small">
                      <h5 className="modal-dialog-title modal-dialog-title--centered">
                        <FormattedMessage id={titleMsgId} />
                      </h5>
                    </div>
                  )}
                  <div className="form-header">
                    {loaderInsteadOfLogo ? (
                      <PathstreamLoader className="login-logo" />
                    ) : (
                      <img className="login-logo" src={logo} />
                    )}

                    {formHeader && (
                      <h5 className="title">
                        <FormattedMessage id={formHeader} />
                      </h5>
                    )}

                    {formTagline && (
                      <p className={centerTagline ? 'form-tagline-centered' : 'form-tagline'}>
                        <FormattedMessage id={formTagline} />
                      </p>
                    )}
                  </div>

                  {children}
                </form>

                {afterDialog}
              </div>
            </div>
            <div className="modal-dialog-overlay modal-dialog-overlay--light" />
          </div>
          <img src={poweredByPathstream} className="powered-by-pathstream" />
        </div>
      </div>
    </>
  );
}

Template.propTypes = {
  children: PropTypes.node.isRequired,
  afterDialog: PropTypes.node,
  onSubmit: PropTypes.func,
  titleMsgId: PropTypes.string,
  welcomeMsgId: PropTypes.string,
  taglineId: PropTypes.string,
  formHeader: PropTypes.string,
  formTagline: PropTypes.string,
  centerTagline: PropTypes.bool,
};
