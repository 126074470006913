import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, DatePicker, Input } from 'antd';
import { ExclamationCircleFilled } from '@ant-design/icons';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';
import intl from '../../../utils/intl';

const DATE_FORMAT = 'h:mma MMM DD';

/* eslint-disable complexity */
export default function EditDueDateModal({ bootcampInstanceAssignment, bootcampInstanceQuiz, handleOk }) {
  const [visible, setVisible] = React.useState(false);
  const [dueDate, setDueDate] = React.useState(
    bootcampInstanceAssignment
      ? bootcampInstanceAssignment.assignment_due_at
      : bootcampInstanceQuiz
      ? bootcampInstanceQuiz.quiz_due_at
      : null,
  );
  const [weight, setWeight] = React.useState(
    bootcampInstanceAssignment
      ? bootcampInstanceAssignment.weight
      : bootcampInstanceQuiz
      ? bootcampInstanceQuiz.weight
      : null,
  );

  const handleCancel = () => {
    setVisible(false);
  };

  const handleClick = () => {
    setVisible(true);
  };

  const handleDateChange = date => {
    setDueDate(date.toISOString());
  };

  const handleWeightChange = e => {
    setWeight(e.target.value);
  };

  const dateDefaultValue = bootcampInstanceAssignment
    ? moment(bootcampInstanceAssignment.assignment_due_at)
    : bootcampInstanceQuiz
    ? moment(bootcampInstanceQuiz.quiz_due_at)
    : undefined;

  return (
    <>
      {bootcampInstanceAssignment ? (
        <FormattedMessage
          id="cohort.Coach.assignmentDue.due"
          values={{
            date: moment(bootcampInstanceAssignment.assignment_due_at).format(DATE_FORMAT),
            button: (
              <Button type="link" onClick={handleClick}>
                <FormattedMessage id="cohort.Coach.assignmentDue.editDueDateAndWeight" />
              </Button>
            ),
          }}
        />
      ) : bootcampInstanceQuiz ? (
        <FormattedMessage
          id="cohort.Coach.assignmentDue.due"
          values={{
            date: moment(bootcampInstanceQuiz.quiz_due_at).format(DATE_FORMAT),
            button: (
              <Button type="link" onClick={handleClick}>
                <FormattedMessage id="cohort.Coach.assignmentDue.editDueDateAndWeight" />
              </Button>
            ),
          }}
        />
      ) : (
        <Button type="link" onClick={handleClick}>
          <ExclamationCircleFilled />
          <FormattedMessage id="cohort.Coach.assignmentDue.setDueDate" />
        </Button>
      )}
      <Modal
        onCancel={handleCancel}
        className="ant-edit-due-date-modal"
        title={
          <FormattedMessage
            id="cohort.Coach.assignmentDue.editDueDateAndWeight"
            className="edit-due-date-modal-title"
          />
        }
        visible={visible}
        footer={null}
        okText={<FormattedMessage id="common.save" />}>
        <div className="flex-edit-due-date-modal">
          <FormattedMessage id="cohort.Coach.assignmentDue.info" />
          <DatePicker
            showTime
            onChange={handleDateChange}
            defaultValue={dateDefaultValue}
            format={DATE_FORMAT}
            data-testid="date-input"
            placeholder={
              bootcampInstanceAssignment
                ? bootcampInstanceAssignment.assignment_due_at
                : bootcampInstanceQuiz
                ? bootcampInstanceQuiz.quiz_due_at
                : intl.formatMessage({ id: 'cohort.Coach.assignmentDue.selectDate' })
            }
            className="due-date-input"
          />
          <Input type="integer" name="weight" value={weight} onChange={handleWeightChange} placeholder="grade weight" />
        </div>
        <Button onClick={handleCancel} className="edit-due-button cancel" size="medium">
          <FormattedMessage id="common.cancel" />
        </Button>
        <Button
          onClick={() => {
            handleOk(dueDate, weight);
            setVisible(false);
          }}
          type="primary"
          className="edit-due-button"
          size="medium">
          <FormattedMessage id="common.save" />
        </Button>
      </Modal>
    </>
  );
}

/* eslint-enable complexity */
EditDueDateModal.propTypes = {
  bootcampInstanceAssignment: PropTypes.object,
  bootcampInstanceQuiz: PropTypes.object,
  handleOk: PropTypes.func.isRequired,
};
