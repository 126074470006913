import { map as _map } from 'lodash';
import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import Icons from './Icons';
import intl from '../../utils/intl';

export default function OptionsPopup({ options }) {
  const [optionsOpen, setOptionsOpen] = useState(false);
  const optionsRef = useRef(null);

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick, false);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick, false);
    };
  }, [optionsOpen]);

  const handleOutsideClick = e => {
    if (optionsRef.current && optionsRef.current.contains(e.target)) {
      return;
    }

    if (optionsOpen) {
      toggleMenu();
    }
  };

  const toggleMenu = () => {
    setOptionsOpen(!optionsOpen);
  };

  const handleOptionClick = onClick => {
    toggleMenu();
    onClick();
  };

  const renderOption = (option, index) => {
    let { buttonClass, buttonContainerClass, label, onClick, disabled, title } = option;

    if (!buttonClass) {
      buttonClass = 'button-standard--grey';
      if (disabled) buttonClass = `${buttonClass} button-standard--grey-disabled`;
    }
    buttonClass = `${buttonClass} button-standard button-standard--short`;

    if (!buttonContainerClass) {
      buttonContainerClass = 'user-options__button-standard';
    }

    return (
      <div key={`option_${index}`} className={buttonContainerClass}>
        <button className={buttonClass} disabled={disabled} onClick={() => handleOptionClick(onClick)}>
          {label}
          {title && <span className="tooltiptext">{title}</span>}
        </button>
      </div>
    );
  };

  const renderOptionsWrapper = () => {
    return (
      <>
        <div className="user-options-wrapper" ref={optionsRef}>
          <div className="user-options-inner">
            <div className="user-options-header">
              <button
                aria-label={intl.formatMessage({ id: 'common.close' })}
                onClick={toggleMenu}
                className="user-options-header__close-button">
                <Icons.Close />
              </button>
            </div>

            <div className="user-options">{_map(options, renderOption)}</div>
          </div>
        </div>
        <div className="user-options-overlay"></div>
      </>
    );
  };

  return (
    <>
      <button
        className="course-card__button"
        aria-label={intl.formatMessage({ id: 'common.options' })}
        onClick={toggleMenu}>
        <span className="icon-element icon-element--fill-grey-dark icon-element--background-transparent icon-element--size-auto">
          <Icons.More />
        </span>
      </button>

      {optionsOpen && renderOptionsWrapper()}
    </>
  );
}

OptionsPopup.propTypes = {
  options: PropTypes.array.isRequired,
};
