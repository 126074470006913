import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import AdminWrapper from '../../v2/Nav/Admin/AdminWrapper';
import intl from '../../../utils/intl';
import SchoolRow from './SchoolRow';
import showModal from '../../../utils/showModal';
import EditSchoolModal from './EditSchoolModal';
import { fetchSchools } from '../../../actions/schoolActions';
import { useDispatch, useSelector } from 'react-redux';
import { selectSchools } from '../../../selectors/schoolSelectors';
import useLtiConsumers from '../../../hooks/useLtiConsumers';
import { setPageTitle } from '../../../helpers/pageHelper';

export default function SchoolList() {
  const { ltiConsumers } = useLtiConsumers();
  const dispatch = useDispatch();

  useEffect(() => {
    fetchSchools()(dispatch);
    setPageTitle('pageName.school.list');
  }, []);

  const schools = useSelector(selectSchools);

  const handleCreate = () => {
    showModal(EditSchoolModal, {
      school: {},
      ltiConsumers,
    });
  };

  const renderHeader = () => (
    <div className="user-details user-details-title">
      <div className="grid-row user-details-items clear">
        <div className="md-4 sm-12 user-details-item">
          <div className="user-details-item__title">
            <span className="title-row-label">
              <FormattedMessage id="school.name" />
            </span>
          </div>
        </div>
        <div className="md-3 sm-12 user-details-item">
          <div className="user-details-item__title">
            <span className="title-row-label">
              <FormattedMessage id="school.lti_consumer_key" />
            </span>
          </div>
        </div>
        <div className="md-3 sm-12 user-details-item">
          <div className="user-details-item__title">
            <span className="title-row-label">
              <FormattedMessage id="school.schoolType" />
            </span>
          </div>
        </div>
        <div className="md-1 md-offset-1 sm-12 user-details-item">
          <div className="user-details-item__title user-details-item__text-center">
            <span className="title-row-label">
              <FormattedMessage id="common.action" />
            </span>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <AdminWrapper headerTitle={intl.formatMessage({ id: 'nav.schools' })}>
      <div className="grid-row">
        <div className="sm-3 sm-offset-1">&nbsp;</div>
        <div className="sm-2 sm-offset-5 horizontal-end">
          <button
            type="button"
            className="button-standard button-standard--blue2 button-standard--short"
            onClick={handleCreate}>
            <FormattedMessage id="common.addNew" />
          </button>
        </div>
      </div>
      <div className="grid-row">
        <div className="sm-10 sm-offset-1">
          {renderHeader()}
          {schools?.map(school => (
            <SchoolRow key={school.id} school={school} ltiConsumers={ltiConsumers} />
          ))}
        </div>
      </div>
    </AdminWrapper>
  );
}
