import { connect } from 'react-redux';
import CohortList from './CohortList';
import { fetchBootcamps } from '../../../actions/bootcampActions';
import { fetchCohorts } from '../../../actions/cohortActions';
import { selectBootcamps } from '../../../selectors/bootcampSelectors';
import { selectCohorts } from '../../../selectors/cohortSelectors';
import { fetchCohortLookups } from '../../../actions/cohortLookupActions';
import { fetchSchools } from '../../../actions/schoolActions';
import { selectSchools } from '../../../selectors/schoolSelectors';

const mapStateToProps = state => {
  const bootcamps = selectBootcamps(state);
  const cohorts = selectCohorts(state);
  const schools = selectSchools(state);

  return {
    bootcamps,
    cohorts,
    schools,
  };
};

export default connect(mapStateToProps, { fetchBootcamps, fetchCohorts, fetchCohortLookups, fetchSchools })(CohortList);
