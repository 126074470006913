import { clone as _clone, difference as _difference, trim as _trim, isEmpty as _isEmpty } from 'lodash';
import React, { Component } from 'react';
import Modal2 from '../library/Modal2';
import { FormattedMessage } from 'react-intl';
import { canAdministrate } from '../../helpers/userAuth';
import { toastSuccess } from '../../utils/toastHelper';
import Errors from '../Utils/Errors';
import intl from '../../utils/intl';
import FileUpload from '../Utils/FileUpload';
import defaultImage from '../../assets/default-profile.png';
import CustomCheckbox from '../Utils/CustomCheckbox';
import User from '../../api/user';
import CustomInput from '../Utils/CustomInput';

export default class EditUserModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user: _clone(props.user),
    };
  }

  isLtiUser = () => {
    const { user } = this.state;
    return user && !_isEmpty(user.lti_user_id);
  };

  showPassword = () => {
    const { currentUser } = this.props;
    const { user } = this.state;

    if (!user || this.isLtiUser()) {
      return;
    }

    // Show password when creating user or when editing own profile
    return canAdministrate(currentUser) || user.id === currentUser.id;
  };

  handleChange = e => {
    const { target } = e;
    const { name } = target;
    let value = target.value;

    let roles = this.state.user.roles || [];
    if (name === 'roles') {
      const isChecked = target.checked;
      if (isChecked) {
        roles.push(value);
      } else {
        roles = _difference(roles, [value]);
      }
      value = roles;
    } else if (name === 'send_invitation') {
      value = target.checked;
    }

    this.setState({
      user: {
        ...this.state.user,
        [name]: value,
      },
    });
  };

  validateUser = () => {
    const { user } = this.state;
    const errors = [];

    if (_isEmpty(_trim(user.name))) {
      errors.push(
        <FormattedMessage id="common.errors.required" values={{ field: <FormattedMessage id="user.form.name" /> }} />,
      );
    }

    if (_isEmpty(_trim(user.email))) {
      errors.push(
        <FormattedMessage id="common.errors.required" values={{ field: <FormattedMessage id="user.form.email" /> }} />,
      );
    }

    if (!user.id && !user.send_invitation) {
      if (_isEmpty(_trim(user.password))) {
        errors.push(
          <FormattedMessage
            id="common.errors.required"
            values={{ field: <FormattedMessage id="user.form.password" /> }}
          />,
        );
      }

      if (_isEmpty(_trim(user.password_confirmation))) {
        errors.push(
          <FormattedMessage
            id="common.errors.required"
            values={{ field: <FormattedMessage id="user.form.confirmPassword" /> }}
          />,
        );
      }

      if (user.password !== user.password_confirmation) {
        errors.push(<FormattedMessage id="user.setPassword.errors.passwordMismatch" />);
      }
    }

    this.setState({ errors });

    return errors.length === 0;
  };

  onAvatarClicked = () => {
    this.setState({ avatarClicked: true });
  };

  fileUploaded = url => {
    this.setState({
      user: {
        ...this.state.user,
        avatar_url: url,
      },
    });
  };

  fileUploadComplete = () => {
    this.setState({ avatarClicked: false });
  };

  handleSubmit = async e => {
    e.preventDefault();

    const { user } = this.state;
    const { afterSave } = this.props;

    if (!this.validateUser()) {
      return;
    }

    this.setState({ isSaving: true, errors: null });

    try {
      const saved = await this.props.saveUser(user);
      toastSuccess(<FormattedMessage id="user.messages.updated" />);
      this.setState({ isSaving: false });
      this.props.onHide();
      afterSave && afterSave(saved);
    } catch (err) {
      this.setState({ errors: err.errors, isSaving: false });
    }
  };

  render() {
    const { currentUser } = this.props;
    const { user, errors, isSaving } = this.state;

    return (
      <Modal2
        onHide={this.props.onHide}
        onOk={this.handleSubmit}
        title={user.id ? <FormattedMessage id="user.edit" /> : <FormattedMessage id="user.create" />}>
        <Errors errors={errors} />
        <fieldset disabled={this.isLtiUser() || isSaving}>
          <div className="user-fields">
            <div className="user-fields__avatar">
              <FileUpload
                isOpen={this.state.avatarClicked}
                accept="image/*"
                onSuccess={this.fileUploaded}
                onComplete={this.fileUploadComplete}
                public={true}
              />

              <img src={user.avatar_url || defaultImage} alt="" onClick={this.onAvatarClicked} />
            </div>

            <div className="user-fields__fields">
              <CustomInput
                name="name"
                onChange={this.handleChange}
                value={user.name}
                label={intl.formatMessage({ id: 'user.form.name' })}
              />

              <CustomInput
                name="email"
                onChange={this.handleChange}
                value={user.email}
                label={intl.formatMessage({ id: 'user.form.email' })}
              />

              {!user.id && (
                <CustomCheckbox
                  name="send_invitation"
                  onChange={this.handleChange}
                  checked={user.send_invitation || false}
                  label={intl.formatMessage({ id: 'user.form.sendInvitation' })}
                />
              )}
            </div>
          </div>

          {this.showPassword() && (
            <React.Fragment>
              <div className="label-modal">
                <FormattedMessage id="user.setPassword.heading" />
              </div>
              <div className="fields-wrapper fields-wrapper--withMarginBottom">
                <CustomInput
                  name="password"
                  type="password"
                  onChange={this.handleChange}
                  value={user.password || ''}
                  label={intl.formatMessage({ id: 'user.form.password' })}
                  disabled={user.send_invitation}
                />

                <CustomInput
                  name="password_confirmation"
                  type="password"
                  onChange={this.handleChange}
                  value={user.password_confirmation || ''}
                  label={intl.formatMessage({ id: 'user.form.confirmPassword' })}
                  disabled={user.send_invitation}
                />
              </div>
            </React.Fragment>
          )}

          {canAdministrate(currentUser) && (
            <fieldset>
              <legend className="label-modal">
                <FormattedMessage id="user.form.roles" />
              </legend>

              {User.ROLES.map(role => (
                <CustomCheckbox
                  key={role}
                  name="roles"
                  onChange={this.handleChange}
                  checked={(user.roles || []).includes(role)}
                  value={role}
                  label={intl.formatMessage({ id: `user.roles.${role}` })}
                  inline={true}
                />
              ))}
            </fieldset>
          )}
        </fieldset>
      </Modal2>
    );
  }
}
