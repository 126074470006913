import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { clone as _clone } from 'lodash';
import { PlusCircleOutlined } from '@ant-design/icons';
import { v1 as uuidv1 } from 'uuid';
import { Button } from 'antd';
import { FormattedMessage } from 'react-intl';
import FilterRow from './FilterRow';

const Filters = ({ settings, filtersState, setFiltersState }) => {
  const [filtersFields, setFiltersFields] = useState({});

  const handleFilterTypeChange = name => option => {
    const clonedFiltersState = _clone(filtersState);
    delete clonedFiltersState[filtersFields[name]];

    const value = option === null ? null : option.value;
    if (value) {
      setFiltersState({
        ...clonedFiltersState,
        [value]: undefined,
      });
      setFiltersFields({
        ...filtersFields,
        [name]: value,
      });
    } else {
      setFiltersState(clonedFiltersState);
      const clonedFiltersFields = _clone(filtersFields);
      delete clonedFiltersFields[name];
      setFiltersFields(clonedFiltersFields);
    }
  };

  const handleSelectFilterValueChange = name => options => {
    let value = options === null ? null : options.value;
    if (Array.isArray(options) && options.length > 0) {
      value = options.map(option => option.value);
    }
    setFiltersState({
      ...filtersState,
      [name]: value,
    });
  };
  const handleTextFilterValueChange = e => {
    const { target } = e;
    const { name } = target;

    setFiltersState({
      ...filtersState,
      [name]: target.value,
    });
  };

  const fieldsList = settings.map(settingItem => ({
    value: settingItem.name,
    label: settingItem.label,
  }));

  const addFilter = () => {
    const newFilterName = `filter_${uuidv1()}`;
    setFiltersFields({
      ...filtersFields,
      [newFilterName]: null,
    });
  };

  const removeFilter = name => {
    const clonedFiltersState = _clone(filtersState);
    delete clonedFiltersState[filtersFields[name]];
    setFiltersState(clonedFiltersState);

    const clonedFiltersFields = _clone(filtersFields);
    delete clonedFiltersFields[name];
    setFiltersFields(clonedFiltersFields);
  };

  const renderFilters = () =>
    Object.keys(filtersFields).map(name => {
      const filteredFieldsList = fieldsList.filter(field => {
        if (field.value === filtersFields[name]) {
          return true;
        }
        return !Object.keys(filtersFields).find(filterName => filtersFields[filterName] === field.value);
      });
      const field = settings.find(option => option.name === filtersFields[name]);
      const value = filtersState[filtersFields[name]];
      return (
        <FilterRow
          key={name}
          name={name}
          field={field}
          value={value}
          onFieldsFilterChange={handleFilterTypeChange(name)}
          onSelectFilterValueChange={handleSelectFilterValueChange(filtersFields[name])}
          onTextFilterValueChange={handleTextFilterValueChange}
          fieldsList={filteredFieldsList}
          onRemoveFilter={removeFilter}
        />
      );
    });

  return (
    <div>
      {renderFilters()}
      <div className="filter-row-container">
        <Button id="add_new_filter" type="dashed" className="filter-select" onClick={addFilter}>
          <PlusCircleOutlined />
          <FormattedMessage id="common.addFilter" />
        </Button>
      </div>
    </div>
  );
};

export default Filters;

Filters.propTypes = {
  filtersState: PropTypes.object.isRequired,
  setFiltersState: PropTypes.func.isRequired,
  settings: PropTypes.array.isRequired,
};
