import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import intl from '../../utils/intl';
import RTEContent from '../../helpers/contentMathjaxHelper';

export default class QuestionResponse extends Component {
  renderTryAgain = (messageId, selectedChoiceExplanation) => (
    <div className="question-response">
      <div aria-live="assertive" role="status" className="error-message-wrapper">
        <span className="error-message">
          <FormattedMessage id={messageId} description="Status Message" />
        </span>
      </div>

      {selectedChoiceExplanation && (
        <RTEContent content={selectedChoiceExplanation} classSelector="choice-explanation" />
      )}

      <div className="answer-buttons">
        <div>{this.props.renderQuestionsNavigation && this.props.renderQuestionsNavigation()}</div>
        <div className="answer-buttons-wrapper">
          <button
            type="button"
            onClick={this.props.handleShowAnswer}
            aria-label={intl.formatMessage({ id: 'question.showAnswer' })}
            className="button-standard button-standard--outlined">
            <FormattedMessage id="question.showAnswer" description="Show Answer" />
          </button>
          <button
            type="button"
            onClick={this.props.handleResetQuestion}
            aria-label={intl.formatMessage({ id: 'question.tryAgain' })}
            className="button-standard button-standard--grey button-standard--short">
            <FormattedMessage id="question.tryAgain" description="Try Again" />
          </button>
        </div>
      </div>
    </div>
  );

  // eslint-disable-next-line complexity
  render() {
    const {
      question,
      questionState,
      correctChoiceExplanation,
      selectedChoiceExplanation,
      renderNext,
      renderQuestionsNavigation,
    } = this.props;

    const explanationRendered = <RTEContent content={question.explanation} classSelector="question-explanation" />;

    const correctChoiceExplanationRendered = (
      <RTEContent content={correctChoiceExplanation} classSelector="choice-explanation" />
    );

    switch (questionState) {
      case 'no_answer':
      case 'single_answer':
      case 'multiple_answer':
      case 'correct':
        return (
          <div aria-live="assertive" role="status" className="question-response">
            {questionState === 'correct' && (
              <div className="error-message-wrapper">
                <span className="correct-message">
                  <FormattedMessage id="question.correctAnswer" description="Status Message" />
                </span>
              </div>
            )}

            {correctChoiceExplanation && correctChoiceExplanationRendered}

            {explanationRendered}
            {renderNext && renderNext()}
          </div>
        );

      case 'incorrect':
        return this.renderTryAgain('question.answerIncorrect', selectedChoiceExplanation);

      case 'partially_correct':
        return this.renderTryAgain('question.answerPartiallyCorrect', selectedChoiceExplanation);

      default:
        return (
          <div className="answer-buttons">
            <div>{renderQuestionsNavigation && renderQuestionsNavigation()}</div>
            <button
              type="button"
              onClick={this.props.handleAnswerQuestion}
              aria-label={intl.formatMessage({ id: 'question.submitAnswer' })}
              disabled={!this.props.isCurrentQuestionAnswered()}
              className="button-standard button-standard--blue-9 button-standard--short">
              <FormattedMessage id="question.submitAnswer" description="Submit Answer" />
            </button>
          </div>
        );
    }
  }
}

QuestionResponse.propTypes = {
  question: PropTypes.object.isRequired,
  questionState: PropTypes.string.isRequired,
  selectedChoiceExplanation: PropTypes.string,
  correctChoiceExplanation: PropTypes.string,
  handleAnswerQuestion: PropTypes.func.isRequired,
  isCurrentQuestionAnswered: PropTypes.func.isRequired,
  handleShowAnswer: PropTypes.func,
  handleResetQuestion: PropTypes.func,
  renderNext: PropTypes.func,
};
