import React from 'react';
import PropTypes from 'prop-types';
import EditVideoModal from './EditVideoModal';
import showModal from '../../../utils/showModal';
import VideoPlayer from '../../Utils/VideoPlayer';
import EditBlockTitle from '../../Utils/EditBlockTitle';
import EmbedWithTitle from '../../Utils/EmbedWithTitle';
import StudentBlockVideoShow from '../../Student/BlockVideoShow';
import BlockEditOptionsContainer from './BlockEditOptionsContainer';
import BlockHeader from '../../Utils/BlockHeader';
import Icons from '../../Utils/Icons';
import MultipleEditor from '../../Blocks/MultipleEditor';

export default function BlockVideoShow(props) {
  const handleEdit = () => {
    const { block } = props;
    showModal(EditVideoModal, { block });
  };

  const { block, isEditMode, index, totalBlocks, onReorder, blockLabel, headingClass } = props;

  if (!isEditMode) {
    return <StudentBlockVideoShow block={block} blockLabel={blockLabel} headingClass={headingClass} />;
  }

  return (
    <div className="section">
      <BlockEditOptionsContainer
        block={block}
        index={index}
        total={totalBlocks}
        onReorder={onReorder}
        onEdit={handleEdit}
      />

      <div className="media-content-wrapper">
        <div className="media-content">
          <div className="media-content__element">
            <div className="block-label">
              <BlockHeader messageId="common.media" icon={<Icons.LabelMedia />} />

              <div className="block-label__content">
                {block.url && <VideoPlayer src={block.url} />}
                {block.embed_code && <EmbedWithTitle title={block.title} html={block.embed_code} />}
                <MultipleEditor blockId={block.id}>
                  <EditBlockTitle key={block.updated_at} block={block} headingClass={headingClass} tagName="h2" />
                </MultipleEditor>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

BlockVideoShow.propTypes = {
  block: PropTypes.object.isRequired,
  isEditMode: PropTypes.bool,
  index: PropTypes.number,
  totalBlocks: PropTypes.number,
  onReorder: PropTypes.func,
  blockLabel: PropTypes.any,
  headingClass: PropTypes.string,
};
