import React from 'react';
import PropTypes from 'prop-types';
import ContentEditable from 'react-contenteditable';
import useSaveBlock from '../../hooks/useSaveBlock';

export default function EditBlockTitle({ block, tagName, headingClass }) {
  const { blockState, handleChange } = useSaveBlock(block);

  return (
    <ContentEditable
      className={headingClass}
      html={blockState.title}
      tagName={tagName}
      onChange={handleChange('title', true)}
    />
  );
}

EditBlockTitle.propTypes = {
  block: PropTypes.object.isRequired,
  tagName: PropTypes.string,
  headingClass: PropTypes.string,
};

EditBlockTitle.defaultProps = {
  tagName: 'h1',
  headingClass: 'heading',
};
