import React, { Component } from 'react';

export default class AdminHeader extends Component {
  render() {
    const { title } = this.props;

    return (
      <div className="header__container-admin-page">
        <div className="grid-row">
          <div className="sm-11  vertical-center horizontal-space-between header-admin-page-column">
            <h1>{title}</h1>
          </div>
        </div>
      </div>
    );
  }
}
