import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, Row, Col, Button } from 'antd';
import { FormattedMessage } from 'react-intl';
import ContentEditable from 'react-contenteditable';

import intl from '../../../../utils/intl';
import CKEditor from '../../../Utils/CKEditor';
import useSaveBlock from '../../../../hooks/useSaveBlock';
import Block from '../../../../api/block';
import CodeEditor from '../../../Utils/CodeEditor';
const BlockConflictModal = ({ currentBlock, remoteBlock, onOk, onCancel }) => {
  const { blockState, saveBlock, handleChange, handleBlockChange } = useSaveBlock(currentBlock);
  const [showSource, setShowSource] = useState(false);
  const ckEditorRef = React.createRef();

  const handleSubmit = () => {
    saveBlock(blockState, true, onOk);
  };

  const isSql = currentBlock.block_type === Block.SQL;

  const handleInputChange = name => handleChange(name, false, false);

  const resolveBlockConflict = type => () => {
    const block = type === 'local' ? currentBlock : remoteBlock;
    const updatedBlock = {
      title: block.title,
      content: block.content,
    };
    if (block.script) updatedBlock.script = block.script;
    handleBlockChange(updatedBlock);
    ckEditorRef.current.setContent(block.content);
  };

  const { title: currentTitle, content: currentContent } = blockState;
  const { title: remoteTitle, content: remoteContent } = remoteBlock;

  return (
    <Modal
      className="block-conflict"
      title={intl.formatMessage({ id: 'block.heading.conflict' })}
      visible
      onCancel={onCancel}
      width="980px"
      okText={intl.formatMessage({ id: 'common.submit' })}
      onOk={handleSubmit}>
      <h2 style={{ paddingBottom: 20 }}>
        <FormattedMessage id="block.conflict.message" />
      </h2>
      <Row>
        <Col span={12}>
          <div className="change-msg">
            <FormattedMessage id="block.conflict.yourChanges" />
          </div>
          <div className="conflict-section conflict-section__left">
            <ContentEditable
              className={currentTitle !== remoteTitle && 'conflict'}
              html={blockState.title || ''}
              tagName="h3"
              onChange={handleInputChange('title')}
            />
            <div
              style={{ height: 300, overflow: 'auto', borderTop: '1px solid black' }}
              className={currentContent !== remoteContent && 'conflict'}>
              <CKEditor
                defaultContent={intl.formatMessage({ id: 'block.defaultHtmlContent' })}
                inline
                content={blockState.content}
                onChange={handleInputChange('content')}
                ref={ckEditorRef}
              />
            </div>
            {isSql && <CodeEditor value={blockState.script || ''} onChange={handleInputChange('script')} mode="sql" />}
          </div>
        </Col>
        <Col span={12}>
          <div className="change-msg">
            <FormattedMessage id="block.conflict.conflictingChanges" />
            <Button className="view-source" type="link" size="small" onClick={() => setShowSource(true)}>
              <FormattedMessage id="common.viewSource" />
            </Button>
          </div>
          <div className="conflict-section conflict-section__right">
            <h3 className="title__read-only" dangerouslySetInnerHTML={{ __html: remoteBlock.title }} />
            <div
              style={{ height: 300, overflow: 'auto', borderTop: '1px solid black', padding: '5px' }}
              dangerouslySetInnerHTML={{ __html: remoteBlock.content }}
            />
            {isSql && <CodeEditor options={{ readOnly: true }} value={remoteBlock.script || ''} mode="sql" />}
          </div>
        </Col>
      </Row>
      <div className="resolve-options">
        <Button type="primary" onClick={resolveBlockConflict('local')}>
          <FormattedMessage id="block.conflict.localChanges" />
        </Button>
        <Button type="default" className="remote-btn" onClick={resolveBlockConflict('remote')}>
          <FormattedMessage id="block.conflict.remoteChanges" />
        </Button>
      </div>
      <Modal
        title={intl.formatMessage({ id: 'common.viewSource' })}
        visible={showSource}
        onCancel={() => setShowSource(false)}
        width="980px"
        footer={[
          <Button type="primary" onClick={() => setShowSource(false)}>
            <FormattedMessage id="common.ok" />
          </Button>,
        ]}>
        <textarea defaultValue={remoteBlock.content} rows={15} disabled />
      </Modal>
    </Modal>
  );
};

BlockConflictModal.propTypes = {
  currentBlock: PropTypes.object.isRequired,
  remoteBlock: PropTypes.object.isRequired,
  onOk: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default BlockConflictModal;
