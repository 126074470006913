import { map as _map, isEmpty as _isEmpty } from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Element } from 'react-scroll';
import classnames from 'classnames';
import { Button } from 'antd';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import showModal from '../../utils/showModal';
import BlockContainer from '../Author/BlockContainer';
import Dropdown from '../Author/Components/Dropdown';
import ConfigureProjectModalContainer from '../Author/Projects/ConfigureProjectModalContainer';
import AddBlock from '../Author/Blocks/AddBlock';
import { PopupImageWrapper, PopupTableWrapper } from '../Utils/Popup';
import { getBlockClasses, isGsuiteApp, isCustomSiteApp, allowProjectUpload } from '../../helpers/labHelper';
import useLesson from '../../hooks/useLesson';
import { reorderBlocks } from '../../actions/blockActions';
import ProjectInstructionsFooter from './ProjectInstructionsFooter';
import { ShowProjectOverviewButton } from './ProjectOverview';
import useFeatureToggle from '../../hooks/useFeatureToggle';

// eslint-disable-next-line complexity
export default function ProjectInstructions(props) {
  const {
    assignment,
    bootcampId,
    lesson,
    isEditMode,
    isIntroLesson,
    quizOnLab,
    showBlockTakeQuiz,
    isSidebar,
    onSubmit,
    errors,
    hideFooter,
    submitLabel,
    disableImagePopup,
    onHandleBackToGrading,
    unit,
  } = props;
  const { parts, blocksByPart } = useLesson(lesson && lesson.id);
  const dispatch = useDispatch();
  const history = useHistory();
  const { shouldShowAutograder } = useFeatureToggle();
  const isAutograderAllowed = shouldShowAutograder();

  // There should be only one part for a Project Lesson
  const blocks = _isEmpty(parts) ? [] : blocksByPart[parts[0].id] || [];
  const blockClasses = classnames(isEditMode && 'block--edit-mode');

  const configureButtons = [
    {
      messageId: 'project.selectDependentProject',
      onClick: () => handleConfigureModal('selectDependentProject'),
    },
    {
      messageId: 'project.setTemplate',
      onClick: () => handleConfigureModal('setTemplate'),
    },
  ];

  if (isAutograderAllowed) {
    configureButtons.push({
      messageId: 'autograder.project.createAutogradeRuleset',
      onClick: () => history.push(`/autograder-create-ruleset/${assignment.id}`),
    });
  }

  const handleConfigureModal = mode => {
    showModal(ConfigureProjectModalContainer, {
      assignment,
      bootcampId,
      mode,
      unit,
    });
  };

  const handleReorder = (oldPosition, newPosition) => {
    const blockIds = _map(blocks, 'id');
    const removed = blockIds.splice(oldPosition, 1);
    blockIds.splice(newPosition, 0, removed[0]);

    dispatch(reorderBlocks(blockIds, lesson, parts[0]));
  };

  return (
    <>
      {!isIntroLesson && (
        <header className="lab-exercise__header">
          <span className="with-project-overview">
            <h3 className="lab-exercise__title">
              <FormattedMessage id="unit.heading.instructions" />
            </h3>
            {!isEditMode && <ShowProjectOverviewButton app={assignment.app} unit={unit} bootcampId={bootcampId} />}
          </span>
        </header>
      )}
      <div className={classnames('project-instructions', isSidebar && 'lab-exercise__content')}>
        {isEditMode && !isIntroLesson && !isCustomSiteApp(assignment.app) && (
          <>
            {allowProjectUpload(assignment.app) && (
              <button
                type="button"
                className="button-standard button-standard--grey button-standard--hover-blue"
                onClick={() => handleConfigureModal('setTemplate')}>
                <FormattedMessage id="project.configure" />
              </button>
            )}
            {isGsuiteApp(assignment.app) && (
              <Dropdown toggleButtonMessageId="project.configure" buttons={configureButtons} />
            )}
          </>
        )}
        <PopupImageWrapper enabled={!disableImagePopup && !isEditMode}>
          <PopupTableWrapper enabled={!disableImagePopup && !isEditMode}>
            {_map(blocks, (block, index) => (
              <Element name={`block_${block.id}`} key={block.id}>
                <div className={getBlockClasses(block, blockClasses)}>
                  <BlockContainer
                    key={block.id}
                    lesson={lesson}
                    block={block}
                    index={index}
                    totalBlocks={blocks.length}
                    onReorder={handleReorder}
                    isEditMode={isEditMode}
                    showBlockTakeQuiz={showBlockTakeQuiz}
                    quizOnLab={quizOnLab}
                    headingClass={props.headingClass}
                  />
                </div>

                {isEditMode && <AddBlock lesson={lesson} prevSeqNum={block.seq_num} isLab />}
              </Element>
            ))}
          </PopupTableWrapper>
          {isEditMode && blocks.length === 0 && <AddBlock lesson={lesson} prevSeqNum={0} isLab />}
        </PopupImageWrapper>
        {!!onHandleBackToGrading && (
          <div className="grading-btn-outer">
            <Button className="grading-btn" size="large" onClick={() => onHandleBackToGrading(true)}>
              <FormattedMessage id="grading.backToGrading" />
            </Button>
          </div>
        )}
      </div>

      {!isIntroLesson && !hideFooter && (
        <ProjectInstructionsFooter {...{ errors, assignment, onSubmit, submitLabel }} />
      )}
    </>
  );
}

ProjectInstructions.propTypes = {
  bootcampId: PropTypes.string,
  lesson: PropTypes.object,
  assignment: PropTypes.object,
  unit: PropTypes.object,
  isEditMode: PropTypes.bool,
  isIntroLesson: PropTypes.bool,
  showBlockTakeQuiz: PropTypes.bool,
  quizOnLab: PropTypes.object,
  headingClass: PropTypes.string,
  isSidebar: PropTypes.bool,
  onSubmit: PropTypes.func,
  errors: PropTypes.array,
  hideFooter: PropTypes.bool,
  disableImagePopup: PropTypes.bool,
  submitLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onHandleBackToGrading: PropTypes.func,
};

ProjectInstructions.defaultProps = {
  isIntroLesson: false,
  showBlockTakeQuiz: false,
  isSidebar: false,
  isEditMode: false,
  disableImagePopup: false,
};
