import { values as _values, sortBy as _sortBy } from 'lodash';
import { createSelector } from 'reselect';
import createCachedSelector from 're-reselect';

export const selectEntities = name => state => state && state.entities[name];

export const selectAll = selector => createSelector([selector], entities => _values(entities));

export const select = selector => (state, id) => selector(state)[id];

export const selectAllFilterBy = (selector, foreignKeyName) =>
  createCachedSelector([selector, (_state, oneEntityId) => oneEntityId], (manyEntities, oneEntityId) =>
    manyEntities.filter(manyEntity => manyEntity[foreignKeyName] === oneEntityId),
  )((_state, oneEntityId) => oneEntityId);

export const sortBy = (selector, attributeName) =>
  createSelector(selector, entities => _sortBy(entities, attributeName));

export const reversed = selector => createSelector(selector, entities => entities.reverse());

export const selectFromBy = (selector, foreignIdName) =>
  createCachedSelector([selector, (_state, foreignEntity) => foreignEntity.id], (manyEntities, foreignId) =>
    manyEntities.find(entity => entity[foreignIdName] === foreignId),
  )((_state, foreignEntity) => foreignEntity.id);

export const selectHasMany = (selector, foreignIdName) =>
  createCachedSelector([selector, (_state, foreignEntity) => foreignEntity.id], (manyEntities, foreignId) =>
    manyEntities.find(entity => entity[foreignIdName].includes(foreignId)),
  )((_state, foreignEntity) => (foreignEntity ? foreignEntity.id : null));

// Instead of grabbing entities that match one id like in selectAllFilterBy,
//   grab entities that match any in a list of ids
// Sort ids for cache key in case order changes
export const selectAllFilterByIncludes = (selector, foreignKeyName) =>
  createCachedSelector([selector, (_state, entityIds) => entityIds], (manyEntities, entityIds) => {
    return manyEntities.filter(manyEntity => entityIds.includes(manyEntity[foreignKeyName]));
  })((_state, entityIds) => entityIds.sort().join());
