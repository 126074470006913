import React from 'react';
import CourseCard from './CourseCard';
import CompletedCourseCard from './CompletedCourseCard';
import { cohortTitle } from '../../../../helpers/cohortHelper';
import { parseISODate, renderISODateAsReadable, shortenAtIndex } from './helpers';

const CurrentlyEnrolledCourseCards = props => {
  const getCardType = (completedAt, access_date, currentEnrollment, optional) => {
    let parsedAccessDate = access_date ? parseISODate(access_date) : null;
    if (!!completedAt) {
      return optional ? 'completeOptional' : 'complete';
    } else if (parsedAccessDate && parsedAccessDate > Date.now()) {
      return 'future';
    } else if (currentEnrollment === true) {
      return optional ? 'currentOptional' : 'current';
    } else if (optional) {
      return 'optional';
    } else {
      return 'upcoming';
    }
  };

  return (
    <div>
      <div className="bottom">
        {props.enrollments &&
          props.enrollments.map(course => {
            let startDate = renderISODateAsReadable(course.cohort.startDate);
            let endDate = renderISODateAsReadable(course.cohort.endDate);
            let date = `${startDate} - ${endDate}`;

            let cardType = getCardType(
              course.completed_at,
              course.cohort.accessDate,
              course.cohort.isCurrentEnrollment,
              course.cohort?.optional,
            );
            let title = shortenAtIndex(cohortTitle(course.cohort, course.cohort.bootcampTitle), 30);
            let url = `/student/bootcamps/${course.cohort.bootcampId}/paths/${course.path_id}`;

            {
              if (cardType === 'complete' || cardType === 'completeOptional') {
                return <CompletedCourseCard title={title} url={url} key={course.id} type={cardType} />;
              }
            }

            return (
              <CourseCard
                key={course.id}
                date={date}
                title={title}
                type={cardType}
                url={url}
                accessDate={course.cohort.accessDate}
              />
            );
          })}
      </div>
    </div>
  );
};

export default CurrentlyEnrolledCourseCards;
