import { intersection as _intersection, isEmpty as _isEmpty } from 'lodash';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import intl from '../../../utils/intl';
import { formatDate } from '../../../utils/utils';
import UserPicture from '../../Utils/UserPicture';
import showModal from '../../../utils/showModal';
import OptionsPopup from '../../Utils/OptionsPopup';
import UserCohortsModal from './UserCohortsModal';
import User from '../../../api/user';

export default class UserRow extends Component {
  renderOptions = () => {
    const { user } = this.props;

    const options = [
      {
        label: <FormattedMessage id="user.edit" />,
        onClick: () => this.props.onEdit(user),
      },
    ];

    if (!_isEmpty(_intersection(user.roles, [User.STUDENT, User.COACH]))) {
      options.unshift({
        label: <FormattedMessage id="user.viewCohorts" />,
        onClick: () => showModal(UserCohortsModal, { user }),
      });
    }

    return <OptionsPopup options={options} />;
  };

  render() {
    const { user } = this.props;

    return (
      <div className="user-details">
        <div className="grid-row user-details-items clear">
          <div className="md-2 user-details-item">
            <div className="user-details-item__user">
              <span className="user-profile-picture">
                <UserPicture user={user} />
              </span>
              <span className="user-label">{user.name}</span>
            </div>
          </div>
          <div className="md-3 md-offset-1 sm-12 user-details-item">
            <div className="user-details-item__text">
              <span className="text-label">{user.email}</span>
            </div>
          </div>
          <div className="md-1 md-offset-1 sm-12 user-details-item">
            <div className="user-details-item__text">
              <span className="text-label">
                {user.roles.map(r => intl.formatMessage({ id: `user.roles.${r}` })).join(', ')}
              </span>
            </div>
          </div>
          <div className="md-2 sm-12 user-details-item">
            <div className="user-details-item__text user-details-item__text-center">
              <span className="text-label">{formatDate(user.current_sign_in_at)}</span>
            </div>
          </div>
          <div className="md-1 md-offset-1 sm-12 user-details-item user-content-button">{this.renderOptions()}</div>
        </div>
      </div>
    );
  }
}

UserRow.propTypes = {
  user: PropTypes.object.isRequired,
  onEdit: PropTypes.func.isRequired,
};
