import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'antd';
import classNames from 'classnames';
import ErrorBlock from '../ErrorBlock';
import { modalDefaultProps } from './SharedAntProps';

const SimpleModal2 = ({ className, visible, backgroundImage, zIndex, ...rest }) => {
  return (
    <Modal
      visible={visible}
      className={classNames('simple-modal-2', className)}
      bodyStyle={{ minWidth: '640px', backgroundImage }}
      maskClosable={false}
      {...modalDefaultProps}
      zIndex={zIndex}>
      <ErrorBlock {...rest} />
    </Modal>
  );
};

SimpleModal2.defaultProps = {
  visible: true,
};

SimpleModal2.propTypes = {
  className: PropTypes.string,
  visible: PropTypes.bool,
  backgroundImage: PropTypes.string,
  ...ErrorBlock.propTypes,
};

export default SimpleModal2;
