import React from 'react';
import { useSelector } from 'react-redux';
import { Space } from 'antd';

import { STATUS_QUEUED, STATUS_LEASED, STATUS_GRADED } from './constants';
import EvaluatorActionButton from './EvaluatorActionButton';
import { selectCurrentUser } from '../../selectors/authSelectors';

const EvaluatorActionButtonGroup = ({ item, updateData }) => {
  const currentUser = useSelector(selectCurrentUser);

  return (
    <Space>
      {item.status === STATUS_QUEUED && (
        <EvaluatorActionButton action="grade" item={item} currentUserId={currentUser.id} updateData={updateData} />
      )}
      {item.status === STATUS_LEASED && item.lease_owner !== currentUser.id && (
        <EvaluatorActionButton
          action="in-progress"
          item={item}
          currentUserId={currentUser.id}
          updateData={updateData}
        />
      )}
      {item.status === STATUS_LEASED && item.lease_owner === currentUser.id && (
        <EvaluatorActionButton action="resume" item={item} currentUserId={currentUser.id} updateData={updateData} />
      )}
      {item.status === STATUS_GRADED && (
        <EvaluatorActionButton action="view" item={item} currentUserId={currentUser.id} updateData={updateData} />
      )}

      {item.status === STATUS_LEASED && (
        <EvaluatorActionButton
          action="return-to-queue"
          item={item}
          currentUserId={currentUser.id}
          updateData={updateData}
        />
      )}
      {item.status === STATUS_GRADED && (
        <EvaluatorActionButton action="regrade" item={item} currentUserId={currentUser.id} updateData={updateData} />
      )}
    </Space>
  );
};
export default EvaluatorActionButtonGroup;
