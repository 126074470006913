const evaluatorFormattedDate = inputString => {
  const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

  const formatAMPM = date => {
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? `0${minutes}` : minutes;
    let strTime = `${hours}:${minutes}${ampm}`;
    return strTime;
  };

  const format = inputString => {
    const time = new Date(inputString);
    const month = monthNames[time.getMonth()];
    const date = time.getDate();
    return `${formatAMPM(time)} on ${month} ${date}`;
  };
  return format(inputString);
};

export default evaluatorFormattedDate;
