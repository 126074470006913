import React, { useEffect, createRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import 'react-image-lightbox/style.css';
import showModal from '../../../utils/showModal';
import TablePopup from './TablePopup';

const activeTablePopupSrcs = new Set();
export default function PopupTableWrapper({ enabled, children }) {
  const containerRef = createRef(null);

  const handleClick = e => {
    if (!enabled) return;

    const table = e.target.closest('table');
    if (table !== null) {
      if (!activeTablePopupSrcs.has(table)) {
        activeTablePopupSrcs.add(table);

        const onUnmount = () => {
          activeTablePopupSrcs.delete(table);
        };

        showModal(TablePopup, {
          table: table.outerHTML,
          onUnmount,
        });
      }
    }
  };

  useEffect(() => {
    if (containerRef.current) containerRef.current.addEventListener('click', handleClick);
  }, [containerRef]);

  return (
    <div className={classnames('popup-table-wrapper', enabled && 'enabled')} ref={containerRef}>
      {children}
    </div>
  );
}

PopupTableWrapper.propTypes = {
  enabled: PropTypes.bool,
  children: PropTypes.node,
};

PopupTableWrapper.defaultProps = {
  enabled: true,
};
