/* eslint-disable max-lines*/
export const allWrongMockData = {
  id: 'fe65ebf5-43fa-4992-92df-5f59721228c7',
  group: [
    {
      type: 'group',
      criteria: [
        {
          type: 'criteria',
          check: [
            {
              type: 'check',
              name: 'Cell A12 is 965',
              property: 'value',
              operator: '==',
              value: '965',
              fieldKey: 'c0022177-9dcc-47a6-a425-5ce480d09f33',
              location: [
                { within: 'worksheet', dataSource: { type: 'byName', name: 'Acquisition' } },
                { within: 'cell', dataSource: { type: 'cellAddress', name: 'A:12', cellAddress: 'A:12' } },
              ],
              dataSource: ['cellAddress'],
              names: ['Acquisition'],
              cellAddress: 'A:12',
            },
          ],
          name: 'Acquisition: Total Users',
          description: 'The number of total users is 965',
          logicFormula: '',
          points: '1',
          resubmitOnError: false,
          fieldKey: 'f05eee7b-b2c9-45cc-b10c-f3318cf4b6a6',
        },
        {
          type: 'criteria',
          check: [
            {
              type: 'check',
              name: 'Cell B12 is 844',
              property: 'value',
              operator: '==',
              value: '844',
              fieldKey: '1187a245-b2ef-447c-83d6-d31064f40b34',
              location: [
                { within: 'worksheet', dataSource: { type: 'byName', name: 'Acquisition' } },
                { within: 'cell', dataSource: { type: 'cellAddress', name: 'B:12', cellAddress: 'B:12' } },
              ],
              dataSource: ['cellAddress'],
              names: ['Acquisition'],
              cellAddress: 'B:12',
            },
          ],
          name: 'Acquisition: Total New Users',
          description: 'The number of total new users is 844',
          logicFormula: '',
          points: '1',
          resubmitOnError: false,
          fieldKey: 'd4c6e855-5af2-45e9-85a0-358aa81f93fd',
        },
        {
          type: 'criteria',
          check: [
            {
              type: 'check',
              name: 'Cell C12 is 1089',
              location: [
                { within: 'worksheet', dataSource: { type: 'byName', name: 'Acquisition' } },
                { within: 'cell', dataSource: { type: 'cellAddress', name: 'C:12', cellAddress: 'C:12' } },
              ],
              dataSource: ['byName', 'cellAddress'],
              property: 'value',
              operator: '==',
              value: '1089',
              fieldKey: 'd336a36f-e213-4ffc-a314-08e2fcc214cc',
            },
            {
              type: 'check',
              name: 'Cell C12 is 1,089',
              property: 'value',
              operator: '==',
              value: '1,089',
              fieldKey: 'baa0f726-b1be-4ed8-8876-2270540a4c9b',
              location: [
                { within: 'worksheet', dataSource: { type: 'byName', name: 'Acquisition' } },
                { within: 'cell', dataSource: { type: 'cellAddress', name: 'C:12', cellAddress: 'C:12' } },
              ],
              dataSource: ['cellAddress'],
              names: ['Acquisition'],
              cellAddress: 'C:12',
            },
          ],
          name: 'Acquisition: Total Sessions',
          description: 'The number of total sessions is 1089',
          logicFormula: 'a|b',
          points: '1',
          resubmitOnError: false,
          fieldKey: '1be0eae0-89e8-4bea-9b9a-4e7b4c5a48f1',
        },
        {
          type: 'criteria',
          check: [
            {
              type: 'check',
              name: 'Cell D12 contains 50.6',
              location: [
                { within: 'worksheet', dataSource: { type: 'byName', name: 'Acquisition' } },
                { within: 'cell', dataSource: { type: 'cellAddress', name: 'D:12', cellAddress: 'D:12' } },
              ],
              dataSource: ['cellAddress'],
              property: 'value',
              operator: 'contains',
              value: '50.6',
              fieldKey: '830eacf7-8d9e-4e88-bb9e-94cc0faaa4c6',
              names: ['Acquisition'],
              cellAddress: 'D:12',
            },
            {
              type: 'check',
              name: 'Cell D12 has at least 2 decimal spaces',
              property: 'decimalSpaces',
              operator: '\u003e=',
              value: '2',
              fieldKey: '91a28029-2859-46cd-8a96-dcb54550d17d',
              location: [
                { within: 'worksheet', dataSource: { type: 'byName', name: 'Acquisition' } },
                { within: 'cell', dataSource: { type: 'cellAddress', name: 'D:12', cellAddress: 'D:12' } },
              ],
              dataSource: ['cellAddress'],
              names: ['Acquisition'],
              cellAddress: 'D:12',
            },
            {
              type: 'check',
              name: 'Cell D12 has a percentage sign',
              property: 'value',
              operator: 'contains',
              value: '%',
              fieldKey: '8fec5703-8473-48e6-b04c-8035d8e9c296',
              location: [
                { within: 'worksheet', dataSource: { type: 'byName', name: 'Acquisition' } },
                { within: 'cell', dataSource: { type: 'cellAddress', name: 'D:12', cellAddress: 'D:12' } },
              ],
              dataSource: ['cellAddress'],
              names: ['Acquisition'],
              cellAddress: 'D:12',
            },
          ],
          name: 'Acquisition: Average Bounce Rate',
          description: 'The average bounce rate is 50.68%',
          logicFormula: 'a\u0026b\u0026c',
          points: '1',
          resubmitOnError: false,
          fieldKey: '9f2e9e66-7d0c-432a-ab6a-ac519eb92f10',
        },
        {
          type: 'criteria',
          check: [
            {
              type: 'check',
              name: 'D:16 contains 74.5',
              property: 'value',
              operator: 'contains',
              value: '74.5',
              fieldKey: '28654cfc-8e81-4bcf-b441-19955e6e3436',
              location: [
                { within: 'worksheet', dataSource: { type: 'byName', name: 'Acquisition' } },
                { within: 'cell', dataSource: { type: 'cellAddress', name: 'D:16', cellAddress: 'D:16' } },
              ],
              dataSource: ['cellAddress'],
              names: ['Acquisition'],
              cellAddress: 'D:16',
            },
            {
              type: 'check',
              name: 'D16 has at least 2 decimal spaces',
              property: 'decimalSpaces',
              operator: '\u003e=',
              value: '2',
              fieldKey: '9f58bdd4-ec30-4cef-bae9-09362c317193',
              location: [
                { within: 'worksheet', dataSource: { type: 'byName', name: 'Acquisition' } },
                { within: 'cell', dataSource: { type: 'cellAddress', name: 'D:16', cellAddress: 'D:16' } },
              ],
              dataSource: ['cellAddress'],
              names: ['Acquisition'],
              cellAddress: 'D:16',
            },
            {
              type: 'check',
              name: 'D16 has a percentage sign',
              property: 'value',
              operator: 'contains',
              value: '%',
              fieldKey: 'b0a8d31f-bc3c-467a-ab3a-e7593652868b',
              location: [
                { within: 'worksheet', dataSource: { type: 'byName', name: 'Acquisition' } },
                { within: 'cell', dataSource: { type: 'cellAddress', name: 'D:16', cellAddress: 'D:16' } },
              ],
              dataSource: ['cellAddress'],
              names: ['Acquisition'],
              cellAddress: 'D:16',
            },
          ],
          name: 'Acquisition: Highest Bounce Rate',
          description: 'The highest bounce rate is 74.53%',
          logicFormula: 'a\u0026b\u0026c',
          points: '1',
          resubmitOnError: false,
          fieldKey: '568b78cf-f884-4554-b97b-25b8a6546a45',
        },
        {
          type: 'criteria',
          check: [
            {
              type: 'check',
              name: 'D14 contains 35.9',
              property: 'value',
              operator: 'contains',
              value: '35.9',
              fieldKey: 'd5e1f044-97fc-4346-a042-b161cbbd801e',
              location: [
                { within: 'worksheet', dataSource: { type: 'byName', name: 'Acquisition' } },
                { within: 'cell', dataSource: { type: 'cellAddress', name: 'D:14', cellAddress: 'D:14' } },
              ],
              dataSource: ['cellAddress'],
              names: ['Acquisition'],
              cellAddress: 'D:14',
            },
            {
              type: 'check',
              name: 'D14 has at least 2 decimal spaces',
              property: 'decimalSpaces',
              operator: '\u003e=',
              value: '2',
              fieldKey: '766927da-d93d-45be-b000-d0f7db6dabbe',
              location: [
                { within: 'worksheet', dataSource: { type: 'byName', name: 'Acquisition' } },
                { within: 'cell', dataSource: { type: 'cellAddress', name: 'D:14', cellAddress: 'D:14' } },
              ],
              dataSource: ['cellAddress'],
              names: ['Acquisition'],
              cellAddress: 'D:14',
            },
            {
              type: 'check',
              name: 'D14 has a percentage sign',
              property: 'value',
              operator: 'contains',
              value: '%',
              fieldKey: 'a7368a68-2bc9-47da-b990-faabe681c663',
              location: [
                { within: 'worksheet', dataSource: { type: 'byName', name: 'Acquisition' } },
                { within: 'cell', dataSource: { type: 'cellAddress', name: 'D:14', cellAddress: 'D:14' } },
              ],
              dataSource: ['cellAddress'],
              names: ['Acquisition'],
              cellAddress: 'D:14',
            },
          ],
          name: 'Acquisition: Lowest Bounce Rate',
          description: 'The lowest bounce rate is 35.96%',
          logicFormula: 'a\u0026b\u0026c',
          points: '1',
          resubmitOnError: false,
          fieldKey: 'f2a64883-f1a4-4ad7-a1c2-a8c5b9bd0119',
        },
        {
          type: 'criteria',
          check: [
            {
              type: 'check',
              name: 'Cell E12 is 5.85',
              property: 'value',
              operator: '==',
              value: '5.85',
              fieldKey: '7c6ca236-96cf-4a07-b7b7-1343500c91e2',
              location: [
                { within: 'worksheet', dataSource: { type: 'byName', name: 'Acquisition' } },
                { within: 'cell', dataSource: { type: 'cellAddress', name: 'E:12', cellAddress: 'E:12' } },
              ],
              dataSource: ['cellAddress'],
              names: ['Acquisition'],
              cellAddress: 'E:12',
            },
          ],
          name: 'Acquisition: Most Pages Per Session',
          description: 'The most pages per session is 5.85',
          logicFormula: '',
          points: '1',
          resubmitOnError: false,
          fieldKey: '86c79b1f-44f4-45a1-b88c-389ae8f138e5',
        },
        {
          type: 'criteria',
          check: [
            {
              type: 'check',
              name: 'Cell F12 contains 2:50',
              property: 'value',
              operator: 'contains',
              value: '2:50',
              fieldKey: '1f5060d4-c644-4926-a10c-e5a95bf74812',
              location: [
                { within: 'worksheet', dataSource: { type: 'byName', name: 'Acquisition' } },
                { within: 'cell', dataSource: { type: 'cellAddress', name: 'F:12', cellAddress: 'F:12' } },
              ],
              dataSource: ['cellAddress'],
              names: ['Acquisition'],
              cellAddress: 'F:12',
            },
          ],
          name: 'Acquisition: Overall Session Duration Average',
          description: 'Overall session duration average is 2:50',
          logicFormula: '',
          points: '1',
          resubmitOnError: false,
          fieldKey: '402caa06-9f5b-465f-8c2d-f7d62b04e391',
        },
        {
          type: 'criteria',
          check: [
            {
              type: 'check',
              name: 'Cell G12 is 21',
              property: 'value',
              operator: '==',
              value: '21',
              fieldKey: 'fc453f83-f150-4902-a168-4a09ae1959dc',
              location: [
                { within: 'worksheet', dataSource: { type: 'byName', name: 'Acquisition' } },
                { within: 'cell', dataSource: { type: 'cellAddress', name: 'G:12', cellAddress: 'G:12' } },
              ],
              dataSource: ['cellAddress'],
              names: ['Acquisition'],
              cellAddress: 'G:12',
            },
          ],
          name: 'Acquisition: Total transactions',
          description: 'Total transactions is 21',
          logicFormula: '',
          points: '1',
          resubmitOnError: false,
          fieldKey: '13042a04-87d0-417b-8b23-5ee9fe97b4b8',
        },
        {
          type: 'criteria',
          check: [
            {
              type: 'check',
              name: 'H12 is $202.29',
              property: 'value',
              operator: '==',
              value: '$202.29',
              fieldKey: 'c42bc8f1-72ac-4d5a-978f-6f927ed6e733',
              location: [
                { within: 'worksheet', dataSource: { type: 'byName', name: 'Acquisition' } },
                { within: 'cell', dataSource: { type: 'cellAddress', name: 'H:12', cellAddress: 'H:12' } },
              ],
              dataSource: ['cellAddress'],
              names: ['Acquisition'],
              cellAddress: 'H:12',
            },
          ],
          name: 'Acquisition: Total revenue',
          description: 'Total revenue is $202.29',
          logicFormula: '',
          points: '1',
          resubmitOnError: false,
          fieldKey: 'c19a57c1-52a4-4116-b177-40d3a07b2ff4',
        },
      ],
      name: 'Part A: Analyzing the Acquisition Report',
      criterionId: 'bd21c891-8dfe-4327-a93a-d0b3c1310479',
      nonLinearGradingRanges: [
        { score: '1/10', maxRange: 10, rangeUnit: 'percent' },
        { score: '2/10', maxRange: 20, rangeUnit: 'percent' },
        { score: '3/10', maxRange: 30, rangeUnit: 'percent' },
        { score: '4/10', maxRange: 40, rangeUnit: 'percent' },
        { score: '5/10', maxRange: 45, rangeUnit: 'percent' },
        { score: '6/10', maxRange: 50, rangeUnit: 'percent' },
        { score: '7/10', maxRange: 70, rangeUnit: 'percent' },
        { score: '8/10', maxRange: 90, rangeUnit: 'percent' },
        { score: '9/10', maxRange: 95, rangeUnit: 'percent' },
        { score: '10/10', maxRange: 100, rangeUnit: 'percent' },
      ],
      fieldKey: '2b479b72-9094-4436-9837-674f054e04e2',
    },
    {
      type: 'group',
      criteria: [],
      name: 'Part A: Acquisition Report Reflection Questions',
      criterionId: 'cca3e216-cb06-40fb-9007-b0c7bac2f21e',
      nonLinearGradingRanges: [],
      isModelAnswer: true,
      modelAnswerImage: {
        name: 'Screen Shot 2020-08-11 at 3.14.59 PM.png',
        url:
          '/rte/content/project-files/app.pathstream.com/ruleset_id/autograde_rubric_id/a7c300c7-360f-419e-8312-27c4d9f3a30a/Screen Shot 2020-08-11 at 3.14.59 PM.png',
      },
      modelAnswerDescription: 'Part A: Acquisition Report Reflection Questions - Model Answer',
      fieldKey: '4f601068-ae91-4396-93e7-d959f9790296',
    },
    {
      type: 'group',
      criteria: [
        {
          type: 'criteria',
          check: [
            {
              type: 'check',
              name: 'Cell O4 is 192',
              location: [
                { within: 'worksheet', dataSource: { type: 'byName', name: 'Emails' } },
                { within: 'cell', dataSource: { type: 'cellAddress', name: 'O:4', cellAddress: 'O:4' } },
              ],
              dataSource: ['cellAddress'],
              property: 'value',
              operator: '==',
              value: '192',
              fieldKey: 'aa462745-7c75-4747-a2b9-3b7a298b490b',
              names: ['Emails'],
              cellAddress: 'O:4',
            },
            {
              type: 'check',
              name: 'Cell 08 is 200',
              property: 'value',
              operator: '==',
              value: '200',
              fieldKey: '67f99ebf-c08d-4a7e-bf7a-08ff80637ffe',
              location: [
                { within: 'worksheet', dataSource: { type: 'byName', name: 'Emails' } },
                { within: 'cell', dataSource: { type: 'cellAddress', name: 'O:8', cellAddress: 'O:8' } },
              ],
              dataSource: ['cellAddress'],
              names: ['Emails'],
              cellAddress: 'O:8',
            },
            {
              type: 'check',
              name: 'Cell O12 is 210',
              property: 'value',
              operator: '==',
              value: '210',
              fieldKey: '36837e4f-2a43-43a1-96f3-93dd1fc0a182',
              location: [
                { within: 'worksheet', dataSource: { type: 'byName', name: 'Emails' } },
                { within: 'cell', dataSource: { type: 'cellAddress', name: 'O:12', cellAddress: 'O:12' } },
              ],
              dataSource: ['cellAddress'],
              names: ['Emails'],
              cellAddress: 'O:12',
            },
            {
              type: 'check',
              name: 'Cell O16 is 228',
              property: 'value',
              operator: '==',
              value: '228',
              fieldKey: 'ceede64f-d458-49a7-9d74-b3b237ad870c',
              location: [
                { within: 'worksheet', dataSource: { type: 'byName', name: 'Emails' } },
                { within: 'cell', dataSource: { type: 'cellAddress', name: 'O:16', cellAddress: 'O:16' } },
              ],
              dataSource: ['cellAddress'],
              names: ['Emails'],
              cellAddress: 'O:16',
            },
            {
              type: 'check',
              name: 'Cell O20 is 830',
              property: 'value',
              operator: '==',
              value: '830',
              fieldKey: 'fd9c94b0-864b-4d1f-9255-e91b82d65216',
              location: [
                { within: 'worksheet', dataSource: { type: 'byName', name: 'Emails' } },
                { within: 'cell', dataSource: { type: 'cellAddress', name: 'O:20', cellAddress: 'O:20' } },
              ],
              dataSource: ['cellAddress'],
              names: ['Emails'],
              cellAddress: 'O:20',
            },
          ],
          name: 'Emails Received are all calculated correctly',
          description: 'Emails Received are all calculated correctly',
          logicFormula: 'a\u0026b\u0026c\u0026d\u0026e',
          points: '1',
          resubmitOnError: false,
          fieldKey: '4764af5e-081d-4443-826e-6b2e689c384d',
        },
        {
          type: 'criteria',
          check: [
            {
              type: 'check',
              name: 'Cell P4 is 41',
              property: 'value',
              operator: '==',
              value: '41',
              fieldKey: '88b1a12c-deee-41f1-808a-dfd991bf45b7',
              location: [
                { within: 'worksheet', dataSource: { type: 'byName', name: 'Emails' } },
                { within: 'cell', dataSource: { type: 'cellAddress', name: 'P:4', cellAddress: 'P:4' } },
              ],
              dataSource: ['cellAddress'],
              names: ['Emails'],
              cellAddress: 'P:4',
            },
            {
              type: 'check',
              name: 'Cell P8 is 48',
              property: 'value',
              operator: '==',
              value: '48',
              fieldKey: 'f2bc521e-3b8b-4f2e-8617-e4971108333b',
              location: [
                { within: 'worksheet', dataSource: { type: 'byName', name: 'Emails' } },
                { within: 'cell', dataSource: { type: 'cellAddress', name: 'P:8', cellAddress: 'P:8' } },
              ],
              dataSource: ['cellAddress'],
              names: ['Emails'],
              cellAddress: 'P:8',
            },
            {
              type: 'check',
              name: 'Cell P12 is 48',
              property: 'value',
              operator: '==',
              value: '48',
              fieldKey: '46a64238-b27f-4faa-8282-c52457fbfb13',
              location: [
                { within: 'worksheet', dataSource: { type: 'byName', name: 'Emails' } },
                { within: 'cell', dataSource: { type: 'cellAddress', name: 'P:12', cellAddress: 'P:12' } },
              ],
              dataSource: ['cellAddress'],
              names: ['Emails'],
              cellAddress: 'P:12',
            },
            {
              type: 'check',
              name: 'Cell P16 is 41',
              property: 'value',
              operator: '==',
              value: '41',
              fieldKey: 'e6e5051c-6e41-4f47-aa47-cdc98fa87311',
              location: [
                { within: 'worksheet', dataSource: { type: 'byName', name: 'Emails' } },
                { within: 'cell', dataSource: { type: 'cellAddress', name: 'P:16', cellAddress: 'P:16' } },
              ],
              dataSource: ['cellAddress'],
              names: ['Emails'],
              cellAddress: 'P:16',
            },
            {
              type: 'check',
              name: 'Cell P20 is 178',
              property: 'value',
              operator: '==',
              value: '178',
              fieldKey: '5a4fb8bc-7e13-4180-84f6-75e10ff60e1c',
              location: [
                { within: 'worksheet', dataSource: { type: 'byName', name: 'Emails' } },
                { within: 'cell', dataSource: { type: 'cellAddress', name: 'P:20', cellAddress: 'P:20' } },
              ],
              dataSource: ['cellAddress'],
              names: ['Emails'],
              cellAddress: 'P:20',
            },
          ],
          name: 'Emails Opened are all calculated correctly',
          description: 'Emails Opened are all calculated correctly',
          logicFormula: 'a\u0026b\u0026c\u0026d\u0026e',
          points: '1',
          resubmitOnError: false,
          fieldKey: '1c0bbd3f-17db-4a9b-ac7a-aa7ea0f93dce',
        },
        {
          type: 'criteria',
          check: [
            {
              type: 'check',
              name: 'Cell Q4 is 9',
              property: 'value',
              operator: '==',
              value: '9',
              fieldKey: '9121320f-3048-4339-b789-9c8e52c6daad',
              location: [
                { within: 'worksheet', dataSource: { type: 'byName', name: 'Emails' } },
                { within: 'cell', dataSource: { type: 'cellAddress', name: 'Q:4', cellAddress: 'Q:4' } },
              ],
              dataSource: ['cellAddress'],
              names: ['Emails'],
              cellAddress: 'Q:4',
            },
            {
              type: 'check',
              name: 'Cell Q8 is 3',
              property: 'value',
              operator: '==',
              value: '3',
              fieldKey: 'c3da65fc-cb0a-4a08-a41b-a23906f85f5d',
              location: [
                { within: 'worksheet', dataSource: { type: 'byName', name: 'Emails' } },
                { within: 'cell', dataSource: { type: 'cellAddress', name: 'Q:8', cellAddress: 'Q:8' } },
              ],
              dataSource: ['cellAddress'],
              names: ['Emails'],
              cellAddress: 'Q:8',
            },
            {
              type: 'check',
              name: 'Cell Q12 is 7',
              property: 'value',
              operator: '==',
              value: '7',
              fieldKey: 'dd2181dc-e0b8-4087-86a0-38e66a524c5d',
              location: [
                { within: 'worksheet', dataSource: { type: 'byName', name: 'Emails' } },
                { within: 'cell', dataSource: { type: 'cellAddress', name: 'Q:12', cellAddress: 'Q:12' } },
              ],
              dataSource: ['cellAddress'],
              names: ['Emails'],
              cellAddress: 'Q:12',
            },
            {
              type: 'check',
              name: 'Cell Q16 is 10',
              property: 'value',
              operator: '==',
              value: '10',
              fieldKey: '764b6d12-6225-4e7d-ae8f-528dd468354e',
              location: [
                { within: 'worksheet', dataSource: { type: 'byName', name: 'Emails' } },
                { within: 'cell', dataSource: { type: 'cellAddress', name: 'Q:16', cellAddress: 'Q:16' } },
              ],
              dataSource: ['cellAddress'],
              names: ['Emails'],
              cellAddress: 'Q:16',
            },
            {
              type: 'check',
              name: 'Cell Q20 is 29',
              property: 'value',
              operator: '==',
              value: '29',
              fieldKey: '08b0cace-820a-4489-b70a-077d44588f60',
              location: [
                { within: 'worksheet', dataSource: { type: 'byName', name: 'Emails' } },
                { within: 'cell', dataSource: { type: 'cellAddress', name: 'Q:20', cellAddress: 'Q:20' } },
              ],
              dataSource: ['cellAddress'],
              names: ['Emails'],
              cellAddress: 'Q:20',
            },
          ],
          name: 'Emails Clicked are all calculated correctly',
          description: 'Emails Clicked are all calculated correctly',
          logicFormula: 'a\u0026b\u0026c\u0026d\u0026e',
          points: '1',
          resubmitOnError: false,
          fieldKey: 'bf27ae72-f8da-4e40-b1ff-0055b0508482',
        },
        {
          type: 'criteria',
          check: [
            {
              type: 'check',
              name: 'Cell R4 is 21.35%',
              property: 'value',
              operator: '==',
              value: '21.35%',
              fieldKey: 'e33a776e-4c7d-4cb5-a902-7b853d81b02f',
              location: [
                { within: 'worksheet', dataSource: { type: 'byName', name: 'Emails' } },
                { within: 'cell', dataSource: { type: 'cellAddress', name: 'R:4', cellAddress: 'R:4' } },
              ],
              dataSource: ['cellAddress'],
              names: ['Emails'],
              cellAddress: 'R:4',
            },
            {
              type: 'check',
              name: 'Cell R8 is 24.00%',
              property: 'value',
              operator: '==',
              value: '24.00%',
              fieldKey: '8540be5e-3240-43d3-91b0-a689afde1549',
              location: [
                { within: 'worksheet', dataSource: { type: 'byName', name: 'Emails' } },
                { within: 'cell', dataSource: { type: 'cellAddress', name: 'R:8', cellAddress: 'R:8' } },
              ],
              dataSource: ['cellAddress'],
              names: ['Emails'],
              cellAddress: 'R:8',
            },
            {
              type: 'check',
              name: 'Cell R12 is 22.86%',
              property: 'value',
              operator: '==',
              value: '22.86%',
              fieldKey: 'e87ba91c-2384-4d9d-8e6f-7c0c0c462d33',
              location: [
                { within: 'worksheet', dataSource: { type: 'byName', name: 'Emails' } },
                { within: 'cell', dataSource: { type: 'cellAddress', name: 'R:12', cellAddress: 'R:12' } },
              ],
              dataSource: ['cellAddress'],
              names: ['Emails'],
              cellAddress: 'R:12',
            },
            {
              type: 'check',
              name: 'Cell R16 is 17.98%',
              location: [
                { within: 'worksheet', dataSource: { type: 'byName', name: 'Emails' } },
                { within: 'cell', dataSource: { type: 'cellAddress', name: 'R:16', cellAddress: 'R:16' } },
              ],
              dataSource: ['byName', 'cellAddress'],
              property: 'value',
              operator: '==',
              value: '17.98%',
              fieldKey: 'b63330d2-d43e-40d0-b286-cf54c72610c9',
            },
            {
              type: 'check',
              name: 'Cell R20 is 21.45%',
              property: 'value',
              operator: '==',
              value: '21.45%',
              fieldKey: 'bc05ee13-9c09-4edc-baad-43a066609087',
              location: [
                { within: 'worksheet', dataSource: { type: 'byName', name: 'Emails' } },
                { within: 'cell', dataSource: { type: 'cellAddress', name: 'R:20', cellAddress: 'R:20' } },
              ],
              dataSource: ['cellAddress'],
              names: ['Emails'],
              cellAddress: 'R:20',
            },
          ],
          name: 'Emails Open Rate are all calculated correctly',
          description: 'Emails Open Rate are all calculated correctly',
          logicFormula: 'a\u0026b\u0026c\u0026d\u0026e',
          points: '1',
          resubmitOnError: false,
          fieldKey: 'a041efcd-2922-4b29-a9b6-7dd0f5fe3b0d',
        },
        {
          type: 'criteria',
          check: [
            {
              type: 'check',
              name: 'Cell S4 is 4.69%',
              property: 'value',
              operator: '==',
              value: '4.69%',
              fieldKey: '7061afbf-986f-4b4c-b593-44dd709f11ab',
              location: [
                { within: 'worksheet', dataSource: { type: 'byName', name: 'Emails' } },
                { within: 'cell', dataSource: { type: 'cellAddress', name: 'S:4', cellAddress: 'S:4' } },
              ],
              dataSource: ['cellAddress'],
              names: ['Emails'],
              cellAddress: 'S:4',
            },
            {
              type: 'check',
              name: 'Cell S8 is 1.50%',
              property: 'value',
              operator: '==',
              value: '1.50%',
              fieldKey: 'd0fd2e8c-7add-4f37-8cf7-67d7f9d1fea3',
              location: [
                { within: 'worksheet', dataSource: { type: 'byName', name: 'Emails' } },
                { within: 'cell', dataSource: { type: 'cellAddress', name: 'S:8', cellAddress: 'S:8' } },
              ],
              dataSource: ['cellAddress'],
              names: ['Emails'],
              cellAddress: 'S:8',
            },
            {
              type: 'check',
              name: 'Cell S12 is 3.33%',
              property: 'value',
              operator: '==',
              value: '3.33%',
              fieldKey: '4ee2ac45-26bb-48fb-8b06-68b82dfa1607',
              location: [
                { within: 'worksheet', dataSource: { type: 'byName', name: 'Emails' } },
                { within: 'cell', dataSource: { type: 'cellAddress', name: 'S:12', cellAddress: 'S:12' } },
              ],
              dataSource: ['cellAddress'],
              names: ['Emails'],
              cellAddress: 'S:12',
            },
            {
              type: 'check',
              name: 'Cell S16 is 4.39%',
              property: 'value',
              operator: '==',
              value: '4.39%',
              fieldKey: '972f62c9-69cc-4f29-99db-ce1809926c49',
              location: [
                { within: 'worksheet', dataSource: { type: 'byName', name: 'Emails' } },
                { within: 'cell', dataSource: { type: 'cellAddress', name: 'S:16', cellAddress: 'S:16' } },
              ],
              dataSource: ['cellAddress'],
              names: ['Emails'],
              cellAddress: 'S:16',
            },
            {
              type: 'check',
              name: 'Cell S20 is 3.49%',
              property: 'value',
              operator: '==',
              value: '3.49%',
              fieldKey: 'ba87a790-e3be-4a91-86f4-27fdfcb49bf3',
              location: [
                { within: 'worksheet', dataSource: { type: 'byName', name: 'Emails' } },
                { within: 'cell', dataSource: { type: 'cellAddress', name: 'S:20', cellAddress: 'S:20' } },
              ],
              dataSource: ['cellAddress'],
              names: ['Emails'],
              cellAddress: 'S:20',
            },
          ],
          name: 'Emails Click-Through Rate are all calculated correctly',
          description: 'Emails Click-Through Rate are all calculated correctly',
          logicFormula: 'a\u0026b\u0026c\u0026d\u0026e',
          points: '1',
          resubmitOnError: false,
          fieldKey: '47930512-45c3-4478-9bcb-8f2932f3d577',
        },
        {
          type: 'criteria',
          check: [
            {
              type: 'check',
              name: 'Cell Q22 contains 7.25',
              property: 'value',
              operator: 'contains',
              value: '7.25',
              fieldKey: '6bb0e2a5-e5e2-47eb-84ca-b81f09cd8765',
              location: [
                { within: 'worksheet', dataSource: { type: 'byName', name: 'Emails' } },
                { within: 'cell', dataSource: { type: 'cellAddress', name: 'Q:22', cellAddress: 'Q:22' } },
              ],
              dataSource: ['cellAddress'],
              names: ['Emails'],
              cellAddress: 'Q:22',
            },
          ],
          name: 'Emails Average Clicks (Summary) is correct',
          description: 'Emails Average Clicks (Summary) is correct',
          logicFormula: '',
          points: '1',
          resubmitOnError: false,
          fieldKey: 'a0d0341b-6350-4f52-b140-16821c75eb2d',
        },
      ],
      name: 'Part A: Email Metrics',
      criterionId: 'fc56faed-8cfe-47ce-bd88-dddf20294d21',
      nonLinearGradingRanges: [
        { score: '1/10', maxRange: 10, rangeUnit: 'percent' },
        { score: '2/10', maxRange: 20, rangeUnit: 'percent' },
        { score: '3/10', maxRange: 35, rangeUnit: 'percent' },
        { score: '4/10', maxRange: 40, rangeUnit: 'percent' },
        { score: '5/10', maxRange: 45, rangeUnit: 'percent' },
        { score: '6/10', maxRange: 40, rangeUnit: 'percent' },
        { score: '7/10', maxRange: 70, rangeUnit: 'percent' },
        { score: '8/10', maxRange: 80, rangeUnit: 'percent' },
        { score: '9/10', maxRange: 90, rangeUnit: 'percent' },
        { score: '10/10', maxRange: 100, rangeUnit: 'percent' },
      ],
      fieldKey: '428ef827-7ca4-4423-91db-e5722bb7e03b',
    },
    {
      type: 'group',
      criteria: [],
      name: 'Part A: Email Reflection Questions',
      criterionId: '39fbc83c-f781-4683-a7c9-06d0a15001ca',
      nonLinearGradingRanges: [],
      isModelAnswer: true,
      fieldKey: '0e629f92-189a-4a24-a3c5-51b591bcbae1',
      modelAnswerImage: {
        name: 'Screen Shot 2020-08-11 at 4.02.14 PM.png',
        url:
          '/rte/content/project-files/app.pathstream.com/ruleset_id/autograde_rubric_id/0bf94c70-428a-4f30-a37b-b40d64218916/Screen Shot 2020-08-11 at 4.02.14 PM.png',
      },
      modelAnswerDescription: 'Part A: Email Reflection Questions - Model Answer',
    },
    {
      type: 'group',
      criteria: [
        {
          type: 'criteria',
          check: [
            {
              type: 'check',
              name: 'Cell B17 is 1361',
              property: 'value',
              operator: '==',
              value: '1361',
              fieldKey: '2e96e372-5037-42e2-8aa0-9d459fae186f',
              location: [
                { within: 'worksheet', dataSource: { type: 'byName', name: 'Adwords' } },
                { within: 'cell', dataSource: { type: 'cellAddress', name: 'B:17', cellAddress: 'B:17' } },
              ],
              dataSource: ['cellAddress'],
              names: ['Adwords'],
              cellAddress: 'B:17',
            },
            {
              type: 'check',
              name: 'Cell B17 is 1,361',
              property: 'value',
              operator: '==',
              value: '1,361',
              fieldKey: '1b49a21a-e3ad-4f78-a1af-10571022f0cc',
              location: [
                { within: 'worksheet', dataSource: { type: 'byName', name: 'Adwords' } },
                { within: 'cell', dataSource: { type: 'cellAddress', name: 'B:17', cellAddress: 'B:17' } },
              ],
              dataSource: ['cellAddress'],
              names: ['Adwords'],
              cellAddress: 'B:17',
            },
          ],
          name: 'Adwords - Total Impressions is correct',
          description: 'Adwords - Total Impressions is correct',
          logicFormula: 'a|b',
          points: '1',
          resubmitOnError: false,
          fieldKey: '498bc4e0-9395-48c7-9d82-8c9af82c988c',
        },
        {
          type: 'criteria',
          check: [
            {
              type: 'check',
              name: 'Cell C17 is 47',
              property: 'value',
              operator: '==',
              value: '47',
              fieldKey: '006478a4-3ca8-49b2-bc4e-7d68e4fd60f1',
              location: [
                { within: 'worksheet', dataSource: { type: 'byName', name: 'Adwords' } },
                { within: 'cell', dataSource: { type: 'cellAddress', name: 'C:17', cellAddress: 'C:17' } },
              ],
              dataSource: ['cellAddress'],
              names: ['Adwords'],
              cellAddress: 'C:17',
            },
          ],
          name: 'Adwords - Total Clicks is correct',
          description: 'Adwords - Total Clicks is correct',
          logicFormula: '',
          points: '1',
          resubmitOnError: false,
          fieldKey: 'ee7e1558-b2d5-48fc-9914-8afeea21c451',
        },
        {
          type: 'criteria',
          check: [
            {
              type: 'check',
              name: 'Cell D17 is $56.02',
              property: 'value',
              operator: '==',
              value: '$56.02',
              fieldKey: '1a96de6d-3031-45d5-bb8e-0e202923153c',
              location: [
                { within: 'worksheet', dataSource: { type: 'byName', name: 'Adwords' } },
                { within: 'cell', dataSource: { type: 'cellAddress', name: 'D:17', cellAddress: 'D:17' } },
              ],
              dataSource: ['cellAddress'],
              names: ['Adwords'],
              cellAddress: 'D:17',
            },
          ],
          name: 'Adwords - Total Cost is correct',
          description: 'Adwords - Total Cost is correct',
          logicFormula: '',
          points: '1',
          resubmitOnError: false,
          fieldKey: '83b2af74-51ba-407f-81fd-401a0f61adc8',
        },
        {
          type: 'criteria',
          check: [
            {
              type: 'check',
              name: 'Cell E17 is 5',
              property: 'value',
              operator: '==',
              value: '5',
              fieldKey: '8834fd50-7762-45ee-a19d-d803b3aeabda',
              location: [
                { within: 'worksheet', dataSource: { type: 'byName', name: 'Adwords' } },
                { within: 'cell', dataSource: { type: 'cellAddress', name: 'E:17', cellAddress: 'E:17' } },
              ],
              dataSource: ['cellAddress'],
              names: ['Adwords'],
              cellAddress: 'E:17',
            },
          ],
          name: 'Adwords - Total Conversions is correct',
          description: 'Adwords - Total Conversions is correct',
          logicFormula: '',
          points: '1',
          resubmitOnError: false,
          fieldKey: '554bf7c5-f718-4b41-bc0a-411462f77a8c',
        },
        {
          type: 'criteria',
          check: [
            {
              type: 'check',
              name: 'Cell F17 contains 3.45',
              property: 'value',
              operator: 'contains',
              value: '3.45',
              fieldKey: '0b7fb0d6-e103-483e-8d54-6fe6b5a7347d',
              location: [
                { within: 'worksheet', dataSource: { type: 'byName', name: 'Adwords' } },
                { within: 'cell', dataSource: { type: 'cellAddress', name: 'F:17', cellAddress: 'F:17' } },
              ],
              dataSource: ['cellAddress'],
              names: ['Adwords'],
              cellAddress: 'F:17',
            },
            {
              type: 'check',
              name: 'Cell F17 contains percentage sign',
              property: 'value',
              operator: 'contains',
              value: '%',
              fieldKey: 'ed4c18b9-339e-48c4-bf50-f9bd8b242473',
              location: [
                { within: 'worksheet', dataSource: { type: 'byName', name: 'Adwords' } },
                { within: 'cell', dataSource: { type: 'cellAddress', name: 'F:17', cellAddress: 'F:17' } },
              ],
              dataSource: ['cellAddress'],
              names: ['Adwords'],
              cellAddress: 'F:17',
            },
          ],
          name: 'Adwords - CTR is correct',
          description: 'Adwords - CTR is correct',
          logicFormula: 'a\u0026b',
          points: '1',
          resubmitOnError: false,
          fieldKey: 'fabba773-5898-4ee6-9f0a-53557e12b2d0',
        },
        {
          type: 'criteria',
          check: [
            {
              type: 'check',
              name: 'Cell G17 is $1.19',
              property: 'value',
              operator: '==',
              value: '$1.19',
              fieldKey: 'fd39334a-1996-427d-9ccb-612965235b45',
              location: [
                { within: 'worksheet', dataSource: { type: 'byName', name: 'Adwords' } },
                { within: 'cell', dataSource: { type: 'cellAddress', name: 'G:17', cellAddress: 'G:17' } },
              ],
              dataSource: ['cellAddress'],
              names: ['Adwords'],
              cellAddress: 'G:17',
            },
          ],
          name: 'Adwords - CPC is correct',
          description: 'Adwords - CPC is correct',
          logicFormula: '',
          points: '1',
          resubmitOnError: false,
          fieldKey: 'bea49e35-30c6-432d-ac9e-c83c82fc88f1',
        },
        {
          type: 'criteria',
          check: [
            {
              type: 'check',
              name: 'Cell H17 contains 100.5',
              property: 'value',
              operator: 'contains',
              value: '100.5',
              fieldKey: '0d708aba-e942-4c08-926e-62b0808d01c8',
              location: [
                { within: 'worksheet', dataSource: { type: 'byName', name: 'Adwords' } },
                { within: 'cell', dataSource: { type: 'cellAddress', name: 'H:17', cellAddress: 'H:17' } },
              ],
              dataSource: ['cellAddress'],
              names: ['Adwords'],
              cellAddress: 'H:17',
            },
            {
              type: 'check',
              name: 'Cell H17 has at least 2 decimal places',
              property: 'decimalSpaces',
              operator: '\u003e=',
              value: '2',
              fieldKey: '7a04279f-27c6-48f4-ac41-7cda82aca44f',
              location: [
                { within: 'worksheet', dataSource: { type: 'byName', name: 'Adwords' } },
                { within: 'cell', dataSource: { type: 'cellAddress', name: 'H:17', cellAddress: 'H:17' } },
              ],
              dataSource: ['cellAddress'],
              names: ['Adwords'],
              cellAddress: 'H:17',
            },
            {
              type: 'check',
              name: 'Cell H17 has a percentage sign',
              property: 'value',
              operator: 'contains',
              value: '%',
              fieldKey: 'cd7b2841-ce18-4d4d-aee9-c55be1007a15',
              location: [
                { within: 'worksheet', dataSource: { type: 'byName', name: 'Adwords' } },
                { within: 'cell', dataSource: { type: 'cellAddress', name: 'H:17', cellAddress: 'H:17' } },
              ],
              dataSource: ['cellAddress'],
              names: ['Adwords'],
              cellAddress: 'H:17',
            },
          ],
          name: 'Adwords - ROI is correct',
          description: 'Adwords - ROI is correct',
          logicFormula: 'a\u0026b\u0026c',
          points: '1',
          resubmitOnError: false,
          fieldKey: '5c308e26-5014-401e-a487-94610e0b0003',
        },
      ],
      name: 'Part A: Adword Campaign Metrics',
      criterionId: 'e858c040-830f-43f4-8b6f-4ee70e172a96',
      nonLinearGradingRanges: [
        { score: '1/10', maxRange: 5, rangeUnit: 'percent' },
        { score: '2/10', maxRange: 10, rangeUnit: 'percent' },
        { score: '3/10', maxRange: 15, rangeUnit: 'percent' },
        { score: '4/10', maxRange: 20, rangeUnit: 'percent' },
        { score: '5/10', maxRange: 25, rangeUnit: 'percent' },
        { score: '6/10', maxRange: 29, rangeUnit: 'percent' },
        { score: '7/10', maxRange: 58, rangeUnit: 'percent' },
        { score: '8/10', maxRange: 86, rangeUnit: 'percent' },
        { score: '9/10', maxRange: 90, rangeUnit: 'percent' },
        { score: '10/10', maxRange: 100, rangeUnit: 'percent' },
      ],
      fieldKey: '2a104b43-f241-4d5c-b759-0b44a4452e2c',
    },
    {
      type: 'group',
      criteria: [],
      name: 'Part A: Adword Reflection Question',
      criterionId: 'faa3c253-1784-4300-b780-7b52593abf84',
      nonLinearGradingRanges: [],
      isModelAnswer: true,
      fieldKey: '22b29202-51dc-4503-b583-04d5eee50f78',
      modelAnswerImage: {
        name: 'Screen Shot 2020-08-11 at 4.16.10 PM.png',
        url:
          '/rte/content/project-files/app.pathstream.com/ruleset_id/autograde_rubric_id/ddfff1ee-fab8-466d-8a40-39dc4c720b47/Screen Shot 2020-08-11 at 4.16.10 PM.png',
      },
      modelAnswerDescription: 'Part A: Adword Reflection Question - Model Answer',
    },
    {
      type: 'group',
      criteria: [
        {
          type: 'criteria',
          check: [
            {
              type: 'check',
              name: 'Cell B25 is 3700',
              property: 'value',
              operator: '==',
              value: '3700',
              fieldKey: 'd7b0bcc3-1ad1-40ff-bcbd-d63e34639066',
              location: [
                { within: 'worksheet', dataSource: { type: 'byName', name: 'Facebook' } },
                { within: 'cell', dataSource: { type: 'cellAddress', name: 'B:25', cellAddress: 'B:25' } },
              ],
              dataSource: ['cellAddress'],
              names: ['Facebook'],
              cellAddress: 'B:25',
            },
          ],
          name: 'Total Impressions is correct',
          description: 'Total Impressions is correct',
          logicFormula: '',
          points: '1',
          resubmitOnError: false,
          fieldKey: '83118d71-b10c-4239-bd39-ec50882822b7',
        },
        {
          type: 'criteria',
          check: [
            {
              type: 'check',
              name: 'Cell B26 contains 217.6',
              property: 'value',
              operator: 'contains',
              value: '217.6',
              fieldKey: 'eb52e544-96ca-4ed2-904f-ea58efdd8f81',
              location: [
                { within: 'worksheet', dataSource: { type: 'byName', name: 'Facebook' } },
                { within: 'cell', dataSource: { type: 'cellAddress', name: 'B:26', cellAddress: 'B:26' } },
              ],
              dataSource: ['cellAddress'],
              names: ['Facebook'],
              cellAddress: 'B:26',
            },
            {
              type: 'check',
              name: 'Cell B26 has at least 2 decimal places',
              property: 'decimalSpaces',
              operator: '\u003e=',
              value: '2',
              fieldKey: 'ee2c8459-2eaf-455e-ad0b-a26c42605ef2',
              location: [
                { within: 'worksheet', dataSource: { type: 'byName', name: 'Facebook' } },
                { within: 'cell', dataSource: { type: 'cellAddress', name: 'B:26', cellAddress: 'B:26' } },
              ],
              dataSource: ['cellAddress'],
              names: ['Facebook'],
              cellAddress: 'B:26',
            },
          ],
          name: 'Average Impressions is correct',
          description: 'Average Impressions is correct',
          logicFormula: 'a\u0026b',
          points: '1',
          resubmitOnError: false,
          fieldKey: '5c1db7a7-c699-4808-8c6e-124683d16761',
        },
        {
          type: 'criteria',
          check: [
            {
              type: 'check',
              name: 'Cell C25 is 362',
              property: 'value',
              operator: '==',
              value: '362',
              fieldKey: 'e4d5fc36-f89e-4289-853e-80a1dad76ea6',
              location: [
                { within: 'worksheet', dataSource: { type: 'byName', name: 'Facebook' } },
                { within: 'cell', dataSource: { type: 'cellAddress', name: 'C:25', cellAddress: 'C:25' } },
              ],
              dataSource: ['cellAddress'],
              names: ['Facebook'],
              cellAddress: 'C:25',
            },
          ],
          name: 'Total Likes is correct',
          description: 'Total Likes is correct',
          logicFormula: '',
          points: '1',
          resubmitOnError: false,
          fieldKey: '35604dc0-74ff-4346-8832-48eb93aeff16',
        },
        {
          type: 'criteria',
          check: [
            {
              type: 'check',
              name: 'Cell C26 contains 21.29',
              property: 'value',
              operator: 'contains',
              value: '21.29',
              fieldKey: '6fcd8153-0ea6-4931-9390-8a8a41f0e66a',
              location: [
                { within: 'worksheet', dataSource: { type: 'byName', name: 'Facebook' } },
                { within: 'cell', dataSource: { type: 'cellAddress', name: 'C:26', cellAddress: 'C:26' } },
              ],
              dataSource: ['cellAddress'],
              names: ['Facebook'],
              cellAddress: 'C:26',
            },
          ],
          name: 'Average Likes is correct',
          description: 'Average Likes is correct',
          logicFormula: '',
          points: '1',
          resubmitOnError: false,
          fieldKey: 'a2b93d96-0cf3-4ef0-ba7a-e2d0dc4d7bb5',
        },
        {
          type: 'criteria',
          check: [
            {
              type: 'check',
              name: 'Cell D25 is 52',
              property: 'value',
              operator: '==',
              value: '52',
              fieldKey: '786d7d4f-b13b-43f0-a833-ac30f7bdd380',
              location: [
                { within: 'worksheet', dataSource: { type: 'byName', name: 'Facebook' } },
                { within: 'cell', dataSource: { type: 'cellAddress', name: 'D:25', cellAddress: 'D:25' } },
              ],
              dataSource: ['cellAddress'],
              names: ['Facebook'],
              cellAddress: 'D:25',
            },
          ],
          name: 'Total Comments is correct',
          description: 'Total Comments is correct',
          logicFormula: '',
          points: '1',
          resubmitOnError: false,
          fieldKey: 'efee5257-3398-41a6-9d10-0f19951a6ad1',
        },
        {
          type: 'criteria',
          check: [
            {
              type: 'check',
              name: 'Cell D26 contains 3.0',
              property: 'value',
              operator: 'contains',
              value: '3.0',
              fieldKey: '527931f3-f131-4865-9edd-d1647f3520a0',
              location: [
                { within: 'worksheet', dataSource: { type: 'byName', name: 'Facebook' } },
                { within: 'cell', dataSource: { type: 'cellAddress', name: 'D:26', cellAddress: 'D:26' } },
              ],
              dataSource: ['cellAddress'],
              names: ['Facebook'],
              cellAddress: 'D:26',
            },
            {
              type: 'check',
              name: 'Cell D26 has at least 2 decimal places',
              property: 'decimalSpaces',
              operator: '\u003e=',
              value: '2',
              fieldKey: '314d4359-62c4-4eef-86a9-ca1b1b98696b',
              location: [
                { within: 'worksheet', dataSource: { type: 'byName', name: 'Facebook' } },
                { within: 'cell', dataSource: { type: 'cellAddress', name: 'D:26', cellAddress: 'D:26' } },
              ],
              dataSource: ['cellAddress'],
              names: ['Facebook'],
              cellAddress: 'D:26',
            },
          ],
          name: 'Average Comments is correct',
          description: 'Average Comments is correct',
          logicFormula: 'a\u0026b',
          points: '1',
          resubmitOnError: false,
          fieldKey: 'fc679f7d-0844-410d-9256-ddb638d371a0',
        },
        {
          type: 'criteria',
          check: [
            {
              type: 'check',
              name: 'Cell E25 is 32',
              property: 'value',
              operator: '==',
              value: '32',
              fieldKey: '354b5db8-185a-4c00-affa-891010a47b08',
              location: [
                { within: 'worksheet', dataSource: { type: 'byName', name: 'Facebook' } },
                { within: 'cell', dataSource: { type: 'cellAddress', name: 'E:25', cellAddress: 'E:25' } },
              ],
              dataSource: ['cellAddress'],
              names: ['Facebook'],
              cellAddress: 'E:25',
            },
          ],
          name: 'Total Shares is correct',
          description: 'Total Shares is correct',
          logicFormula: '',
          points: '1',
          resubmitOnError: false,
          fieldKey: '71f48342-6081-42c6-962a-ddf8083eb624',
        },
        {
          type: 'criteria',
          check: [
            {
              type: 'check',
              name: 'Cell E26 contains 1.88',
              property: 'value',
              operator: 'contains',
              value: '1.88',
              fieldKey: '6556e472-e5b5-4c14-9b2b-672711affe69',
              location: [
                { within: 'worksheet', dataSource: { type: 'byName', name: 'Facebook' } },
                { within: 'cell', dataSource: { type: 'cellAddress', name: 'E:26', cellAddress: 'E:26' } },
              ],
              dataSource: ['cellAddress'],
              names: ['Facebook'],
              cellAddress: 'E:26',
            },
          ],
          name: 'Average Shares is correct',
          description: 'Average Shares is correct',
          logicFormula: '',
          points: '1',
          resubmitOnError: false,
          fieldKey: '34fdf417-04a4-4af5-9870-088d5b325d03',
        },
        {
          type: 'criteria',
          check: [
            {
              type: 'check',
              name: 'Cell F25 is 50',
              property: 'value',
              operator: '==',
              value: '50',
              fieldKey: '5bf7d7c2-688c-475c-ad89-af7699d62274',
              location: [
                { within: 'worksheet', dataSource: { type: 'byName', name: 'Facebook' } },
                { within: 'cell', dataSource: { type: 'cellAddress', name: 'F:25', cellAddress: 'F:25' } },
              ],
              dataSource: ['cellAddress'],
              names: ['Facebook'],
              cellAddress: 'F:25',
            },
          ],
          name: 'Total Clicks is correct',
          description: 'Total Clicks is correct',
          logicFormula: '',
          points: '1',
          resubmitOnError: false,
          fieldKey: 'dcc89a9e-2699-44a8-99f6-2875429b6056',
        },
        {
          type: 'criteria',
          check: [
            {
              type: 'check',
              name: 'Cell F26 contains 2.94',
              property: 'value',
              operator: 'contains',
              value: '2.94',
              fieldKey: '8d73cb10-9e03-4223-84c1-5ae840433c24',
              location: [
                { within: 'worksheet', dataSource: { type: 'byName', name: 'Facebook' } },
                { within: 'cell', dataSource: { type: 'cellAddress', name: 'F:26', cellAddress: 'F:26' } },
              ],
              dataSource: ['cellAddress'],
              names: ['Facebook'],
              cellAddress: 'F:26',
            },
          ],
          name: 'Average Clicks is correct',
          description: 'Average Clicks is correct',
          logicFormula: '',
          points: '1',
          resubmitOnError: false,
          fieldKey: 'a6131be5-ab58-4821-98e4-46abada85610',
        },
        {
          type: 'criteria',
          check: [
            {
              type: 'check',
              name: 'Cell C28 contains 9.78',
              property: 'value',
              operator: 'contains',
              value: '9.78',
              fieldKey: '429fa633-1940-44f4-8336-d0e9ad536cc6',
              location: [
                { within: 'worksheet', dataSource: { type: 'byName', name: 'Facebook' } },
                { within: 'cell', dataSource: { type: 'cellAddress', name: 'C:28', cellAddress: 'C:28' } },
              ],
              dataSource: ['cellAddress'],
              names: ['Facebook'],
              cellAddress: 'C:28',
            },
            {
              type: 'check',
              name: 'Cell C28 contains a percentage sign',
              property: 'value',
              operator: 'contains',
              value: '%',
              fieldKey: '35f0f3db-c60a-46ef-b6ce-3d54fb7c060f',
              location: [
                { within: 'worksheet', dataSource: { type: 'byName', name: 'Facebook' } },
                { within: 'cell', dataSource: { type: 'cellAddress', name: 'C:28', cellAddress: 'C:28' } },
              ],
              dataSource: ['cellAddress'],
              names: ['Facebook'],
              cellAddress: 'C:28',
            },
          ],
          name: 'Like Rate is correct',
          description: 'Like Rate is correct',
          logicFormula: 'a\u0026b',
          points: '1',
          resubmitOnError: false,
          fieldKey: 'ede740aa-f7c0-4150-badf-52a211575d7f',
        },
        {
          type: 'criteria',
          check: [
            {
              type: 'check',
              name: 'Cell D28 contains 1.4',
              property: 'value',
              operator: 'contains',
              value: '1.4',
              fieldKey: '6366ffb4-f24c-48aa-869b-d7c9e337788b',
              location: [
                { within: 'worksheet', dataSource: { type: 'byName', name: 'Facebook' } },
                { within: 'cell', dataSource: { type: 'cellAddress', name: 'D:28', cellAddress: 'D:28' } },
              ],
              dataSource: ['cellAddress'],
              names: ['Facebook'],
              cellAddress: 'D:28',
            },
            {
              type: 'check',
              name: 'Cell D28 has at least 2 decimal places',
              property: 'decimalSpaces',
              operator: '\u003e=',
              value: '2',
              fieldKey: 'ac19cf34-c41b-461d-93eb-953a74cc2df5',
              location: [
                { within: 'worksheet', dataSource: { type: 'byName', name: 'Facebook' } },
                { within: 'cell', dataSource: { type: 'cellAddress', name: 'D:28', cellAddress: 'D:28' } },
              ],
              dataSource: ['cellAddress'],
              names: ['Facebook'],
              cellAddress: 'D:28',
            },
            {
              type: 'check',
              name: 'Cell D28 contains a percentage sign',
              property: 'value',
              operator: 'contains',
              value: '%',
              fieldKey: '3c7d8f7c-05e9-470b-b7bd-67265bf3c48b',
              location: [
                { within: 'worksheet', dataSource: { type: 'byName', name: 'Facebook' } },
                { within: 'cell', dataSource: { type: 'cellAddress', name: 'D:28', cellAddress: 'D:28' } },
              ],
              dataSource: ['cellAddress'],
              names: ['Facebook'],
              cellAddress: 'D:28',
            },
          ],
          name: 'Comment Rate is correct',
          description: 'Comment Rate is correct',
          logicFormula: 'a\u0026b\u0026c',
          points: '1',
          resubmitOnError: false,
          fieldKey: 'c09e146a-544e-4269-9987-51e8a8fa26f0',
        },
        {
          type: 'criteria',
          check: [
            {
              type: 'check',
              name: 'Cell E28 contains 0.86',
              property: 'value',
              operator: 'contains',
              value: '0.86',
              fieldKey: 'e0838bed-e724-48e2-80f7-6eb972f3f360',
              location: [
                { within: 'worksheet', dataSource: { type: 'byName', name: 'Facebook' } },
                { within: 'cell', dataSource: { type: 'cellAddress', name: 'E:28', cellAddress: 'E:28' } },
              ],
              dataSource: ['cellAddress'],
              names: ['Facebook'],
              cellAddress: 'E:28',
            },
            {
              type: 'check',
              name: 'Cell E28 contains a percentage sign',
              property: 'value',
              operator: 'contains',
              value: '%',
              fieldKey: '0d7d43d3-e64d-4888-9212-15e70e89f858',
              location: [
                { within: 'worksheet', dataSource: { type: 'byName', name: 'Facebook' } },
                { within: 'cell', dataSource: { type: 'cellAddress', name: 'E:28', cellAddress: 'E:28' } },
              ],
              dataSource: ['cellAddress'],
              names: ['Facebook'],
              cellAddress: 'E:28',
            },
          ],
          name: 'Share Rate is correct',
          description: 'Share Rate is correct',
          logicFormula: 'a\u0026b',
          points: '1',
          resubmitOnError: false,
          fieldKey: '4dd9584d-aa98-4099-ac69-b917670bf41c',
        },
        {
          type: 'criteria',
          check: [
            {
              type: 'check',
              name: 'Cell F28 contains 1.35',
              property: 'value',
              operator: 'contains',
              value: '1.35',
              fieldKey: 'db927f6a-119a-45cb-aea1-af36f46f6f18',
              location: [
                { within: 'worksheet', dataSource: { type: 'byName', name: 'Facebook' } },
                { within: 'cell', dataSource: { type: 'cellAddress', name: 'F:28', cellAddress: 'F:28' } },
              ],
              dataSource: ['cellAddress'],
              names: ['Facebook'],
              cellAddress: 'F:28',
            },
            {
              type: 'check',
              name: 'Cell F28 contains a percentage sign',
              property: 'value',
              operator: 'contains',
              value: '%',
              fieldKey: '965e9714-0006-49df-bd21-76023d57386d',
              location: [
                { within: 'worksheet', dataSource: { type: 'byName', name: 'Facebook' } },
                { within: 'cell', dataSource: { type: 'cellAddress', name: 'F:28', cellAddress: 'F:28' } },
              ],
              dataSource: ['cellAddress'],
              names: ['Facebook'],
              cellAddress: 'F:28',
            },
          ],
          name: 'CTR is correct',
          description: 'CTR is correct',
          logicFormula: 'a\u0026b',
          points: '1',
          resubmitOnError: false,
          fieldKey: '3659651a-5746-413d-82d7-314f6294e10a',
        },
      ],
      name: 'Part A: Social Metrics',
      criterionId: '2fa5fbc6-b1db-439f-86a6-39416c4c1be1',
      nonLinearGradingRanges: [
        { score: '1/10', maxRange: 10, rangeUnit: 'percent' },
        { score: '2/10', maxRange: 20, rangeUnit: 'percent' },
        { score: '3/10', maxRange: 30, rangeUnit: 'percent' },
        { score: '4/10', maxRange: 40, rangeUnit: 'percent' },
        { score: '5/10', maxRange: 50, rangeUnit: 'percent' },
        { score: '6/10', maxRange: 65, rangeUnit: 'percent' },
        { score: '7/10', maxRange: 79, rangeUnit: 'percent' },
        { score: '8/10', maxRange: 93, rangeUnit: 'percent' },
        { score: '9/10', maxRange: 95, rangeUnit: 'percent' },
        { score: '10/10', maxRange: 100, rangeUnit: 'percent' },
      ],
      fieldKey: '3f264ed1-a2d3-4333-adc7-1f91a7c3f1f6',
    },
  ],
  type: 'superGroup',
  fieldKey: '1305ec57-2091-4ac0-9df2-88eba97d8807',
  name: 'All wrong FB.DM-3.2 Project Part A',
  assignmentId: '348046cd-6b36-4617-a93e-32a0a7c4b55e',
};
