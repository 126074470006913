import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { generateCSV } from './reportDataHelper';
import { KeyboardCode } from '../../utils/keyboardConstant';

const CSVDownloadButton = ({ cohort, currentBootcampTitle }) => {
  const [isGenerating, setIsGenerating] = useState();
  const disabled = !cohort || !currentBootcampTitle;

  const handleDownload = async () => {
    if (disabled || isGenerating) {
      return;
    }
    setIsGenerating(true);
    await generateCSV(cohort, currentBootcampTitle);
    setIsGenerating(false);
  };

  const onKeyDown = e => e.keyCode === KeyboardCode.ENTER && handleDownload();

  return (
    <div
      className={classnames({
        'button-standard button-standard--grey': true,
        'button-standard--grey-disabled': disabled,
      })}
      role="button"
      tabIndex="0"
      onKeyDown={onKeyDown}
      onClick={handleDownload}>
      <FormattedMessage id={isGenerating ? 'cohortReports.download.csv.loading' : 'cohortReports.download.csv.title'} />
    </div>
  );
};

CSVDownloadButton.propTypes = {
  cohort: PropTypes.object,
  currentBootcampTitle: PropTypes.string,
};

export default CSVDownloadButton;
