import React from 'react';
import PropTypes from 'prop-types';
import intl from '../../utils/intl';

export default function EmbedWithTitle({ title, html }) {
  const embedElementRef = React.useRef();

  const iframeTitle = title || intl.formatMessage({ id: 'block.embed.defaultVideoTitle' });

  React.useEffect(function setIframeTitle() {
    const iframe = embedElementRef.current.querySelector('iframe');
    if (!iframe) {
      return;
    }

    iframe.setAttribute('title', iframeTitle);
  });

  return <div ref={embedElementRef} style={{ width: '100%' }} dangerouslySetInnerHTML={{ __html: html }} />;
}

EmbedWithTitle.propTypes = {
  title: PropTypes.string,
  html: PropTypes.string.isRequired,
};
