import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';

import RTEContent from '../../../helpers/contentMathjaxHelper';
import { showAsanaAddressModal, showAsanaAccountCreatedModal } from '../../Users/utils/showSetupModal';
import useCurrentUser from '../../../hooks/useCurrentUser';
import intl from '../../../utils/intl';

const AccountShow = ({ block }) => {
  const { currentUser } = useCurrentUser();

  const showAsanaModal = () => {
    if (currentUser.asana_address) {
      showAsanaAccountCreatedModal();
    } else {
      showAsanaAddressModal();
    }
  };

  return (
    <div className="section buttons-lessons">
      <RTEContent content={block.title} Tag="h2" />
      <Button type="primary" className="asana-account-button" size="large" shape="round" onClick={showAsanaModal}>
        {intl.formatMessage({ id: 'profile.asanaAccount.buttonTitle' })}
      </Button>
    </div>
  );
};

AccountShow.propTypes = {
  block: PropTypes.object.isRequired,
};

export default AccountShow;
