import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { getDefaultPath } from '../helpers/pathHelper';
import * as analytics from '../utils/analytics';
import { isSelectionRequest, isAssignmentMappingRequest } from '../helpers/ltiParams';
import AdminWrapper from './v2/Nav/Admin/AdminWrapper';

export default function Lti(props) {
  const { isStudent, fetchEnrollmentForCohort, fetchLtiAssignmentProgresses, cohortId, assignmentId } = props;

  useEffect(() => {
    analytics.pageStart('LTI Launch Page');
    return () => {
      analytics.pageEnd();
    };
  }, []);

  useEffect(() => {
    if (isStudent) {
      fetchEnrollmentForCohort(cohortId);
    }
  }, [isStudent]);

  useEffect(() => {
    if (isStudent && assignmentId) {
      fetchLtiAssignmentProgresses();
    }
  }, [isStudent, assignmentId]);

  const renderMessage = (message, className) => (
    <AdminWrapper hideStaffNav>
      <div className={className} style={{ textAlign: 'center' }}>
        {message}
      </div>
    </AdminWrapper>
  );

  const { bootcamp, path, section, unit, currentUser, isCoach, isAuthor, ltiProgress, error } = props;

  if (error) {
    return renderMessage(error, 'alert alert-danger');
  }
  if (!currentUser) {
    return renderMessage(<FormattedMessage id="lti.messages.wait" />, 'alert alert-info');
  }
  analytics.setUser(currentUser);

  if (isSelectionRequest() && (isCoach || isAuthor)) {
    return <Redirect to={{ pathname: '/lti/assignments' }} />;
  }

  if (isAssignmentMappingRequest() && (isCoach || isAuthor)) {
    return <Redirect to={{ pathname: '/lti/assignment_mapping' }} />;
  }

  if (!isStudent && !isCoach) {
    return renderMessage(<FormattedMessage id="lti.messages.noAccess" />, 'alert alert-warn');
  }

  if (isCoach) {
    // set current cohort
    props.setRosterCohort(props.cohortId);
    props.setGradebookCohort(props.cohortId);

    // redirect to coach landing page
    return <Redirect to={{ pathname: getDefaultPath(currentUser) }} />;
  }

  if (bootcamp) {
    if (assignmentId) {
      if (ltiProgress) {
        return (
          <Redirect
            to={{
              pathname: `/student/bootcamps/${bootcamp.id}/paths/${path.id}/sections/${section.id}/projects/${unit.id}`,
            }}
          />
        );
      }
    } else {
      // Redirect to bootcamp path page
      return (
        <Redirect
          to={{ pathname: `/student/bootcamps/${bootcamp.id}/paths/${bootcamp.path_ids[0]}`, search: '?overview=1' }}
        />
      );
    }
  }

  return renderMessage(<FormattedMessage id="lti.messages.wait" />, 'alert alert-info');
}

Lti.propTypes = {
  bootcamp: PropTypes.object,
  path: PropTypes.object,
  section: PropTypes.object,
  unit: PropTypes.object,
  cohortId: PropTypes.string,
  assignmentId: PropTypes.string,
  currentUser: PropTypes.object,
  isStudent: PropTypes.bool,
  isCoach: PropTypes.bool,
  isAuthor: PropTypes.bool,
  ltiProgress: PropTypes.object,
  error: PropTypes.string,
  setRosterCohort: PropTypes.func,
  setGradebookCohort: PropTypes.func,
};
