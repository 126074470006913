import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { getCoursePath } from '../../helpers/pathHelper';
import BootcampOptions from './BootcampOptions';
import Icons from '../Utils/Icons';
import { formatDate } from '../../utils/utils';
import CircularProgress from '../Utils/CircularProgress';
import ProgressBar from '../Utils/ProgressBar';
import { cohortTitle } from '../../helpers/cohortHelper';

// eslint-disable-next-line complexity
const Header = ({ bootcamp, enrollment, cohort, isStudent, isAuthor, isEditMode }) => {
  const showProgress = bootcamp.show_progress && ((isStudent && enrollment) || (isAuthor && !isEditMode));
  const certificate = bootcamp.certificate_name;

  return (
    <div className="course-card__top">
      <div className="course-card__header">
        <div className="course-card__header--inner">
          {isAuthor && isEditMode && bootcamp.version && (
            <div className="course-card__type-content">{bootcamp.version}</div>
          )}
          {certificate && (
            <div className="course-card__type-content">
              <div className="icon-element icon-element--fill- icon-element--background-transparent  icon-element--size-auto">
                <Icons.Cert2 />
              </div>
              <div className="course-card__type">
                <span className="course-card__type--text ">{certificate}</span>
              </div>
            </div>
          )}
          {cohort && (
            <div className="course-card__cohort">
              {`${formatDate(cohort.start_date)} — ${formatDate(cohort.end_date)}, ${cohort.location}`}
            </div>
          )}
        </div>
        {isEditMode && <BootcampOptions bootcamp={bootcamp} />}
      </div>

      <div className={classnames('course-card__content', showProgress && 'course-card__body--withProgressBar')}>
        <h1 className="course-card__heading">{cohortTitle(cohort, bootcamp.title)}</h1>
        <div
          className={classnames(
            'course-card__sub-heading',
            showProgress && 'course-card__sub-heading--withProgressBar',
          )}
          dangerouslySetInnerHTML={{ __html: bootcamp.description }}
        />
      </div>
    </div>
  );
};

Header.propTypes = {
  bootcamp: PropTypes.object.isRequired,
  enrollment: PropTypes.object,
  cohort: PropTypes.object,
  isStudent: PropTypes.bool,
  isAuthor: PropTypes.bool,
  isEditMode: PropTypes.bool,
};

// eslint-disable-next-line complexity
const Footer = ({
  bootcamp,
  enrollment,
  isStudent,
  isCoach,
  isAuthor,
  isEditMode,
  paths,
  currentUser,
  onClick,
  history,
  cohort,
  fetchEnrollmentForCohort,
}) => {
  const showProgress = bootcamp.show_progress && ((isStudent && enrollment) || (isAuthor && !isEditMode));

  const getBootcampUrl = () => `${getCoursePath(currentUser, bootcamp, paths[0])}?overview=1`;

  const handleClick = (e, url) => {
    e.preventDefault();
    e.stopPropagation();

    if (onClick) {
      onClick({ bootcamp, cohort });
    }
    if (url && enrollment) {
      fetchEnrollmentForCohort(enrollment.enrollable_id);
    }
    history.push(url || getBootcampUrl());
  };

  return (
    <div className="course-card__footer">
      <div className="course-card-progress-bar">
        {showProgress && (
          <>
            <div className="course-card-progress-bar-desktop">
              <CircularProgress
                className="circular-simple-wrapper"
                showPercent
                showStar={false}
                progress={enrollment ? enrollment.progress : 0}
              />
            </div>
            <div className="course-card-progress-bar-mobile">
              <ProgressBar showPercent progress={enrollment ? enrollment.progress : 0} />
            </div>
          </>
        )}
      </div>
      <div className="course-card-buttons">
        <button
          aria-label={`link to ${bootcamp.title}`}
          type="button"
          className="button-standard button-standard--transparent button-standard--short button-standard--hover-blue"
          onClick={e => handleClick(e)}>
          {(isStudent || isCoach || !isEditMode) && <FormattedMessage id="common.view" />}
          {!isStudent && !isCoach && isEditMode && <FormattedMessage id="bootcamp.goTo" />}
        </button>
      </div>
    </div>
  );
};

Footer.propTypes = {
  bootcamp: PropTypes.object.isRequired,
  enrollment: PropTypes.object,
  cohort: PropTypes.object,
  paths: PropTypes.array,
  isStudent: PropTypes.bool,
  isAuthor: PropTypes.bool,
  isCoach: PropTypes.bool,
  isEditMode: PropTypes.bool,
  onClick: PropTypes.func,
  currentUser: PropTypes.object,
  currentComponent: PropTypes.object,
  history: PropTypes.object,
  resumeUrl: PropTypes.string,
  fetchEnrollmentForCohort: PropTypes.func,
};

export default function BootcampListItem(props) {
  return (
    <div className="courses-list__item">
      <div className="course-card-wrapper">
        <div className="course-card" data-testid={`course-item-${props.bootcamp.id}`}>
          <Header {...props} />

          <Footer {...props} />
        </div>
      </div>
    </div>
  );
}

BootcampListItem.propTypes = {
  bootcamp: PropTypes.object.isRequired,
  enrollment: PropTypes.object,
  cohort: PropTypes.object,
  paths: PropTypes.array,
  isStudent: PropTypes.bool,
  isAuthor: PropTypes.bool,
  isCoach: PropTypes.bool,
  isEditMode: PropTypes.bool,
  onClick: PropTypes.func,
  currentUser: PropTypes.object,
  currentComponent: PropTypes.object,
  history: PropTypes.object,
  resumeUrl: PropTypes.string,
  fetchEnrollmentForCohort: PropTypes.func,
};
