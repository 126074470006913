import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Modal } from 'antd';

import Loading from '../Utils/Loading';
import LessonShow from '../Lesson/LessonShow';
import { setPageTitle } from '../../helpers/pageHelper';
import ComponentHeader from '../Components/ComponentHeader';
import useAnalytics from '../../hooks/useAnalytics';
import useCurrentComponent from '../../hooks/useCurrentComponent';
import useFetchCurrentEnrollment from '../../hooks/useFetchCurrentEnrollment';
import useCurrentComponentProgress from '../../hooks/useCurrentComponentProgress';
import { fetchEnrollmentForCohort } from '../../actions/enrollmentActions';
import useFetchLtiAssignmentProgresses from '../../hooks/useFetchLtiAssignmentProgresses';
import usePrevious from '../../hooks/usePrevious';
import LessonCompletionModal from './LessonCompletionModal/LessonCompletionModal';
import useFetchSqlBlockProgresses from '../../hooks/useFetchSqlBlockProgresses';
import useCurrentUser from '../../hooks/useCurrentUser';
import * as analytics from '../../utils/analytics';

export default function ComponentShow() {
  const dispatch = useDispatch();
  const { bootcamp, section, unit, component } = useCurrentComponent();
  const { enrollment, cohort } = useFetchCurrentEnrollment();
  const { _unitProgress, componentProgress, lessonProgress } = useCurrentComponentProgress();
  const prevComponentProgress = usePrevious(componentProgress);
  const [showCompletionModal, setShowCompletionModal] = useState(false);
  const { currentUser } = useCurrentUser();

  useFetchLtiAssignmentProgresses();
  useFetchSqlBlockProgresses(enrollment && enrollment.id);

  useAnalytics('View Lesson', {
    bootcamp,
    cohort,
    section,
    module: unit,
    lesson: component,
  });

  useEffect(() => {
    if (bootcamp && component) {
      const params = {
        course: bootcamp.title,
        lesson: component.title,
      };
      setPageTitle('pageName.course.lesson', params);
    }
  }, [bootcamp, component]);

  useEffect(() => {
    if (cohort && !lessonProgress) {
      // fetch all the enrollment data
      dispatch(fetchEnrollmentForCohort(cohort.id));
    }
  }, [cohort, lessonProgress]);

  useEffect(() => {
    if (!componentProgress || !prevComponentProgress) return;

    if (componentProgress.id !== prevComponentProgress.id) {
      setShowCompletionModal(false);
      return;
    }

    if (componentProgress.completed_at && !prevComponentProgress.completed_at) {
      setShowCompletionModal(true);
    }
  }, [componentProgress, prevComponentProgress]);

  // hide PS2.0 font change behind a feature flag
  const showPS2Styles = analytics.inFlag(currentUser, 'show_ps2_styles');

  return (
    <div className={showPS2Styles ? 'ps2' : ''}>
      <Modal visible={showCompletionModal} closable={false} footer={null} centered className="subject-completion-modal">
        <LessonCompletionModal onCancel={() => setShowCompletionModal(false)} />
      </Modal>
      <div className="new-lesson-page">
        <main className="new-lesson-page__main">
          <ComponentHeader />

          {component ? (
            <LessonShow lessonId={component.external_id} />
          ) : (
            <article className="lesson-part lesson-part--normal">
              <div className="lesson-part__wrapper">
                <Loading />
              </div>
            </article>
          )}
        </main>
      </div>
    </div>
  );
}
