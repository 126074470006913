import { connect } from 'react-redux';

import StudentPathProgress from './StudentPathProgress';
import { selectCurrentUser } from '../../selectors/authSelectors';
import { withRouter } from 'react-router-dom';
import { getPathPrefix } from '../../helpers/pathHelper';
import { selectBootcamp } from '../../selectors/bootcampSelectors';
import { selectPath } from '../../selectors/pathSelectors';
import { selectEnrollmentForBootcamp } from '../../selectors/enrollmentSelectors';

const mapStateToProps = (state, ownProps) => {
  const { bootcampId, pathId } = ownProps.match.params;
  const bootcamp = selectBootcamp(state, bootcampId);
  const path = selectPath(state, pathId);
  const currentUser = selectCurrentUser(state);
  const pathPrefix = getPathPrefix(currentUser);

  const enrollment = selectEnrollmentForBootcamp(state, bootcamp.id);

  const resumeUrlPath = enrollment?.next_course_step?.url_path;
  const baseUrlPath = `/bootcamps/${enrollment.bootcamp_id}/paths/${enrollment.path_id}`;
  const resumeUrl = `${pathPrefix}${resumeUrlPath || baseUrlPath}`;
  const lessonTitle = enrollment?.next_course_step?.title;
  const resuming = enrollment && !!enrollment.started_at;

  return {
    resumeUrl,
    bootcamp,
    path,
    lessonTitle,
    resuming,
  };
};

export default withRouter(connect(mapStateToProps)(StudentPathProgress));
