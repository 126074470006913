import moment from 'moment';

export const GRADING_DAYS = 10;

export const isCohortEnded = cohort => new Date(cohort.end_date) <= Date.now();

export const daysLeftToFinishGrading = cohort => {
  const today = moment(Date.now());
  return GRADING_DAYS - today.diff(moment(cohort.end_date), 'day');
};

export const isCohortCompleted = cohort => {
  const today = moment(Date.now());
  return isCohortEnded(cohort) && today.diff(moment(cohort.end_date), 'day') > GRADING_DAYS;
};

export const isCohortInGradingProcess = cohort => {
  const today = moment(Date.now());
  return isCohortEnded(cohort) && today.diff(moment(cohort.end_date), 'day') < GRADING_DAYS;
};
