import React, { Component } from 'react';
import { toast } from 'react-toastify';
import { FormattedMessage } from 'react-intl';
import FileUpload from '../Utils/FileUpload';
import UserPicture from '../Utils/UserPicture';
import Icons from '../Utils/Icons';

export default class ProfilePicture extends Component {
  constructor(props) {
    super(props);

    this.state = {
      avatarClicked: false,
      user: { ...this.props.user },
      errors: null,
    };
  }

  onAvatarClicked = () => {
    this.setState({ avatarClicked: true });
  };

  fileUploaded = url => {
    this.setState({
      user: {
        ...this.state.user,
        avatar_url: url,
      },
    });
    this.handleSubmit();
  };

  fileUploadComplete = () => {
    this.setState({ avatarClicked: false });
  };

  handleSubmit = () => {
    const { user } = this.state;
    this.props
      .saveProfile(user)
      .then(user => {
        toast(<FormattedMessage id="profile.profileUpdated" />, { type: toast.TYPE.SUCCESS });
        this.setState({ user });
      })
      .catch(err => {
        this.setState({ errors: err.errors });
      });
  };

  render() {
    const { user } = this.props;

    return (
      <div className="student-roster-content__avatar">
        <div className="student-roster-content-avatar-img-wrapper">
          <UserPicture className="student-roster-content__avatar-img" user={user} />
          <button className="student-roster-content__avatar-icon" onClick={this.onAvatarClicked}>
            <Icons.Camera />
          </button>
          <FileUpload
            isOpen={this.state.avatarClicked}
            accept="image/*"
            onSuccess={this.fileUploaded}
            onComplete={this.fileUploadComplete}
            public
            cropImage
            successMessage={false}
          />
        </div>
      </div>
    );
  }
}
ProfilePicture.propTypes = {
  user: UserPicture.propTypes.user,
};
