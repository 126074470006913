import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from 'antd';
import { FormattedMessage } from 'react-intl';

const FutureCohortsQuizCheckbox = ({ checked, onChange, disabled }) => {
  return (
    <div className="quiz-module-checkbox">
      <Checkbox checked={checked} onChange={onChange} disabled={disabled}>
        <FormattedMessage id="common.quizModuleForCohort" />
      </Checkbox>
    </div>
  );
};

FutureCohortsQuizCheckbox.propTypes = {
  checked: PropTypes.boolean,
  onChange: PropTypes.func,
};

export default FutureCohortsQuizCheckbox;
