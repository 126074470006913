import { size as _size } from 'lodash';
import { some as _some } from 'lodash';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import QuizTabsContainer from '../GradedQuiz/QuizTabsContainer';
import QuizLoaderContainer from '../GradedQuiz/LoaderContainer';
import QuestionsContainer from '../GradedQuiz/QuestionsContainer';
import { selectQuestionProgressesForQuiz } from '../../selectors/questionProgressSelectors';
import { selectProgressForQuiz } from '../../selectors/quizProgressSelectors';
import { selectQuizByBlock } from '../../selectors/quizSelectors';
import { selectQuestionsByQuizIdSorted } from '../../selectors/questionSelectors';
import BlockHeader from '../Utils/BlockHeader';
import { ResultPage } from '../GradedQuiz/ResultPage';
import { FinalAnswersContainer } from '../GradedQuiz/FinalAnswersContainer';
import { retryQuizProgress, submitQuizProgress } from '../../actions/quizProgressActions';

const BlockGradedQuizShow = ({
  quizProgress,
  block,
  questionProgresses,
  questionProgressesEnabled = true, // is set false when view mode as author
  renderBody,
  submitQuizProgress,
  retryQuizProgress,
  currentQuestionIndex,
}) => {
  const knowledgeCheckRef = React.createRef();
  const [questionIndex, setQuestionIndex] = useState(questionProgressesEnabled ? currentQuestionIndex : 0);

  useEffect(() => {
    if (questionProgressesEnabled) {
      setQuestionIndex(currentQuestionIndex);
    }
  }, [currentQuestionIndex]);

  const updateQuestionIndex = increment => {
    if (!questionProgressesEnabled) {
      setQuestionIndex(questionIndex + increment);
    }
  };

  const resetQuestionIndex = () => {
    setQuestionIndex(0);
  };

  const handleRetry = () => {
    retryQuizProgress(quizProgress);
    resetQuestionIndex();
  };

  const renderTitle = quiz => {
    return (
      quiz.title && (
        <div className="knowledge-check__header">
          <h1 className="heading" dangerouslySetInnerHTML={{ __html: quiz.title }} />
        </div>
      )
    );
  };

  const completeQuiz = (quizProgress, questionProgresses) => {
    if (!quizProgress || quizProgress.completed_at) {
      return;
    }

    // make sure all the questions have been answered
    if (_some(questionProgresses, { completed_at: null })) {
      return;
    }
    submitQuizProgress(quizProgress);
  };

  const quizSubmitted = quizProgress?.completed_at;
  const allQuestionsAnswered = quizProgress?.current_question_progress_id === null;

  return (
    <QuizLoaderContainer
      block={block}
      render={(quiz, questions, allQuestions) =>
        _size(questions) !== 0 && (
          <div className="section">
            <div className="block-label">
              <BlockHeader messageId="common.gradedQuiz" />
              {renderBody ? renderTitle(quiz) : ''}
              <div className="block-label__content">
                <div className="knowledge-check-wrapper" ref={knowledgeCheckRef}>
                  <div className="knowledge-check__body">
                    {renderBody ? (
                      renderBody(quiz, questions)
                    ) : (
                      <QuizTabsContainer
                        block={block}
                        isEditMode={false}
                        renderTitle={() => renderTitle(quiz)}
                        questionIndex={questionIndex}
                        resetQuestionIndex={resetQuestionIndex}>
                        {quizSubmitted ? (
                          <>
                            <ResultPage
                              completeQuiz={completeQuiz}
                              quizProgress={quizProgress}
                              questionProgresses={questionProgresses}
                            />
                            <FinalAnswersContainer questionProgresses={questionProgresses} questions={allQuestions} />
                          </>
                        ) : allQuestionsAnswered ? (
                          <ResultPage
                            completeQuiz={completeQuiz}
                            quizProgress={quizProgress}
                            questionProgresses={questionProgresses}
                            onRetry={handleRetry}
                          />
                        ) : (
                          <QuestionsContainer
                            quiz={quiz}
                            questions={questions}
                            questionProgressesEnabled={questionProgressesEnabled}
                            knowledgeCheckRef={knowledgeCheckRef}
                            questionIndex={questionIndex}
                            updateQuestionIndex={updateQuestionIndex}
                          />
                        )}
                      </QuizTabsContainer>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      }
    />
  );
};

BlockGradedQuizShow.propTypes = {
  block: PropTypes.object.isRequired,
  questionProgressesEnabled: PropTypes.bool,
  renderBody: PropTypes.func,
};

// TODO: move this into hooks in BlockGradedQuizShow
const mapStateToProps = (state, ownProps) => {
  const quiz = selectQuizByBlock(state, ownProps.block);
  if(!quiz) {
    // Don't try to load anything if quiz not loaded, just give back nil quiz and wait for QuizLoader to fetchQuiz
    return { quiz };
  };

  const quizProgress = selectProgressForQuiz(state, quiz);
  const questionProgresses = selectQuestionProgressesForQuiz(state, quiz.id);

  let currentQuestionIndex;
  if (quizProgress?.current_question_progress_id) {
    const currentQuestionProgress = Object.values(questionProgresses).find(
      questionProgress => questionProgress.id === quizProgress.current_question_progress_id,
    );
    const questions = selectQuestionsByQuizIdSorted(state, quiz.id);
    const questionsForAttempt = questions.filter(
      question => questionProgresses[question.id].quiz_attempt_for_choices === quizProgress.quiz_attempt,
    );
    currentQuestionIndex = questionsForAttempt.findIndex(
      question => question.id === currentQuestionProgress.question_id,
    );
  }

  return {
    questionProgresses,
    quizProgress,
    quiz,
    currentQuestionIndex,
  };
};

export default connect(mapStateToProps, { submitQuizProgress, retryQuizProgress })(BlockGradedQuizShow);
