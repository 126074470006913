import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, DatePicker, Input } from 'antd';
import { ExclamationCircleFilled } from '@ant-design/icons';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';
import intl from '../../../utils/intl';

const DATE_FORMAT = 'h:mma MMM DD';

const EditSectionDueDateModal = ({ bootcampInstanceSection, handleOk }) => {
  const [visible, setVisible] = useState(false);
  const [dueDate, setDueDate] = useState(bootcampInstanceSection ? bootcampInstanceSection.section_due_at : null);
  const [weekLength, setWeekLength] = useState(bootcampInstanceSection ? bootcampInstanceSection.week_length : 1);

  const handleCancel = () => {
    setVisible(false);
  };

  const handleClick = () => {
    setVisible(true);
  };

  const handleDateChange = date => {
    setDueDate(date.toISOString());
  };

  const dateDefaultValue =
    bootcampInstanceSection && bootcampInstanceSection.section_due_at
      ? moment(bootcampInstanceSection.section_due_at)
      : undefined;

  const handleWeekLengthChange = e => {
    setWeekLength(e.target.value);
  };

  return (
    <>
      {bootcampInstanceSection ? (
        <FormattedMessage
          id="cohort.Admin.sectionsDue.due"
          values={{
            date:
              bootcampInstanceSection && bootcampInstanceSection.section_due_at
                ? moment(bootcampInstanceSection.section_due_at).format(DATE_FORMAT)
                : '',
            button: (
              <Button type="link" onClick={handleClick}>
                <FormattedMessage id="cohort.Admin.sectionsDue.editDueDate" />
              </Button>
            ),
          }}
        />
      ) : (
        <Button type="link" onClick={handleClick}>
          <ExclamationCircleFilled />
          <FormattedMessage id="cohort.Admin.sectionsDue.setDueDate" />
        </Button>
      )}

      <Modal
        onCancel={handleCancel}
        className="edit-section-due-date-modal"
        title={<FormattedMessage id="cohort.Admin.sectionsDue.editDueDate" className="edit-due-date-modal-title" />}
        visible={visible}
        footer={null}
        okText={<FormattedMessage id="common.save" />}>
        <div className="flex-edit-due-date-modal">
          <FormattedMessage id="cohort.Admin.sectionsDue.info" />
          <DatePicker
            showTime
            onChange={handleDateChange}
            defaultValue={dateDefaultValue}
            format={DATE_FORMAT}
            data-testid="date-input"
            placeholder={
              bootcampInstanceSection
                ? bootcampInstanceSection.section_due_at
                : intl.formatMessage({ id: 'cohort.Admin.sectionsDue.selectDate' })
            }
            className="due-date-input"
          />
          <Input
            type="integer"
            name="weight"
            value={weekLength}
            onChange={handleWeekLengthChange}
            placeholder="week length"
          />
        </div>
        <Button onClick={handleCancel} className="edit-due-button cancel" size="medium">
          <FormattedMessage id="common.cancel" />
        </Button>
        <Button
          onClick={() => {
            handleOk(dueDate, weekLength);
            setVisible(false);
          }}
          type="primary"
          className="edit-due-button"
          size="medium">
          <FormattedMessage id="common.save" />
        </Button>
      </Modal>
    </>
  );
};

EditSectionDueDateModal.propTypes = {
  bootcampInstanceSection: PropTypes.object,
  handleOk: PropTypes.func.isRequired,
};

export default EditSectionDueDateModal;
