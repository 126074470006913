import { connect } from 'react-redux';
import { filter as _filter, map as _map, compact as _compact } from 'lodash';
import LtiSectionTile from './LtiSectionTile';
import { selectSectionUnits } from '../../../selectors/sectionSelectors';
import { fetchAssignments } from '../../../actions/assignmentActions';
import { selectAssignmentEntities } from '../../../selectors/assignmentSelectors';
import Unit from '../../../api/unit';

const mapStateToProps = (state, ownProps) => {
  const { section } = ownProps;
  const units = selectSectionUnits(state, section.id);
  const assignmentIds = _compact(_map(_filter(units, { unit_type: Unit.PROJECT }), 'assignment_id'));

  const assignmentEntities = selectAssignmentEntities(state);
  const assignments = _filter(_compact(_map(assignmentIds, id => assignmentEntities[id])), { requires_grading: true });

  return {
    assignmentIds,
    assignments,
  };
};

export default connect(mapStateToProps, { fetchAssignments })(LtiSectionTile);
