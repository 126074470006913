import React from 'react';
import PropTypes from 'prop-types';
import ReactDOMServer from 'react-dom/server';

const LTIPostScript = ({ launch }) => (
  <>
    <form
      style={{ display: 'none' }}
      action={launch.url}
      name="ltiLaunchForm"
      id="ltiLaunchForm"
      method="post"
      encType="application/x-www-form-urlencoded">
      {Object.entries(launch.data).map(([key, value]) => (
        <input key={key} type="hidden" name={key} value={value} />
      ))}
      <button type="submit">Launch</button>
    </form>
    <script>document.ltiLaunchForm.submit();</script>
  </>
);

export const LtiIFrame = ({ launch, handleReady }) => (
  <iframe
    srcDoc={ReactDOMServer.renderToString(<LTIPostScript launch={launch} />)}
    title="LTI sandbox"
    frameBorder="0"
    marginHeight="0"
    marginWidth="0"
    height="100%"
    width="100%"
    onLoad={handleReady}
    allowFullScreen
  />
);

LtiIFrame.propTypes = {
  launch: PropTypes.shape({
    url: PropTypes.string.isRequired,
    data: PropTypes.object.isRequired,
  }),
  handleReady: PropTypes.func,
};
