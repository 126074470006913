import QuizProgress from '../api/quizProgress';
import { applicationApiExecute } from './applicationActions';

export const submitQuizProgress = applicationApiExecute(QuizProgress.submit);

export const retryQuizProgress = applicationApiExecute(QuizProgress.retry);
export const allowResubmission = applicationApiExecute(QuizProgress.allowResubmission);

export const setCurrentQuestionToPreviousQuizProgress = applicationApiExecute(
  QuizProgress.setCurrentQuestionToPrevious,
);
