import { getLocationPath, getDataSourcePath } from './dataSourceHelpers';
import { evaluator } from './evalHelpers';

// eslint-disable-next-line complexity
const evalPivotTableColumn = ({ data, location, property, operator, value }) => {
  // Get the specific location (one before the last one)
  const pivotTable = getLocationPath(data, location);
  if (!pivotTable || !pivotTable.columns) return null;
  const { type, order, name } = location[location.length - 1].dataSource;

  // locate path based on dataSource
  const pivotTableColumnLocator = getDataSourcePath({ type, order, name });
  const pivotTableColumn = pivotTableColumnLocator(pivotTable.columns);

  switch (property) {
    case 'exists':
      // assuming we only use title for the dataSource
      return !!pivotTableColumn;
    case 'orderBy':
      if (type === 'byTitle') {
        const { sortOrder } = pivotTableColumn;
        if (operator === 'contains') {
          if (sortOrder.includes(value)) return true;
        } else if (operator === 'does not contain') {
          if (!sortOrder.includes(value)) return true;
        } else {
          return evaluator(sortOrder, operator, value);
        }
      }
      if (type === 'byAny' || type === 'byAll') {
        return pivotTableColumnLocator(pivotTable.columns)(column => {
          const { sortOrder } = column;
          if (operator === 'contains') {
            if (sortOrder.includes(value)) return true;
          } else if (operator === 'does not contain') {
            if (!sortOrder.includes(value)) return true;
          } else {
            return evaluator(sortOrder, operator, value);
          }
          return false;
        });
      }
      return false;
    // not able to find where this is in the api
    // case 'sortBy':
    case 'showTotals':
      if (type === 'byTitle') {
        const { showTotals } = pivotTableColumn;
        return evaluator(showTotals, operator, value);
      }
      if (type === 'byAny' || type === 'byAll') {
        return pivotTableColumnLocator(pivotTable.columns)(column => {
          const { showTotals } = column;
          return evaluator(showTotals, operator, value);
        });
      }
      return false;
    case 'repeatColumnLabels':
      if (type === 'byTitle') {
        const { repeatColumnLabels } = pivotTableColumn;
        return evaluator(repeatColumnLabels, operator, value);
      }
      if (type === 'byAny' || type === 'byAll') {
        return pivotTableColumnLocator(pivotTable.columns)(column => {
          const { repeatColumnLabels } = column;
          return evaluator(repeatColumnLabels, operator, value);
        });
      }
      return false;
    default:
      return null;
  }
};

export default evalPivotTableColumn;
