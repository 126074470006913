import React from 'react';
import { useDispatch } from 'react-redux';
import ContentEditable from 'react-contenteditable';

import CKEditor from '../../Utils/CKEditor';
import useSaveBlock from '../../../hooks/useSaveBlock';
import ContentValidator from '../../Author/Blocks/ContentValidator';
import BlockEditOptionsContainer from '../../Author/Blocks/BlockEditOptionsContainer';

import { FormattedMessage } from 'react-intl';
import Errors from '../../Utils/Errors';
import Modal2 from '../../library/Modal2';
import CustomInput from '../../Utils/CustomInput';
import CustomRadioGroup from '../../Utils/CustomRadioGroup';
import intl from '../../../utils/intl';
import { saveBlock } from '../../../actions/blockActions';
import MultipleEditor from '../MultipleEditor';
import { toastException } from '../../../utils/toastHelper';
import Block from '../../../api/block';
import { Embed } from './Embed';

const useTypeformBlockState = block => {
  const reduxDispatch = useDispatch();
  const { blockState, handleChange } = useSaveBlock(block);

  const handleChangeWithDebouncedSave = name => handleChange(name);
  const handleChangeLocally = name => handleChange(name, false, false);

  const updateBlock = () => saveBlock(blockState)(reduxDispatch);

  return {
    blockState,
    updateBlock,
    handleChangeWithDebouncedSave,
    handleChangeLocally,
  };
};

const EditForm = ({ block, onChange, onEditClick }) => {
  return (
    <ContentValidator content={block.content}>
      <BlockEditOptionsContainer block={block} onEdit={onEditClick} />

      <div className="section">
        <ContentEditable className="heading" html={block.title} onChange={onChange('title')} tagName="h2" />
        <CKEditor inline content={block.content} onChange={onChange('content')} />
        <div className={`typeform-survey-container__${block.height}`}>
          <Embed uniqueId={block.id} typeformUrl={block.url} />
        </div>
      </div>
    </ContentValidator>
  );
};

export const EditModal = ({ block, onHide }) => {
  const { blockState, handleChangeLocally, updateBlock } = useTypeformBlockState(block);
  const [errors, setErrors] = React.useState([]);

  const handleSubmit = e => {
    e.preventDefault();
    updateBlock()
      .then(onHide)
      .catch(error => {
        if (error.value && error.value.errors) {
          setErrors(error.value.errors);
        }
        toastException(error, 'Unable to save block');
      });
  };

  return (
    <Modal2 title={<FormattedMessage id="block.form.typeform" />} onHide={onHide} onOk={handleSubmit}>
      <MultipleEditor blockId={blockState.id} forceEditing>
        <Errors errors={errors} />
        <CustomInput
          name="url"
          onChange={handleChangeLocally('url')}
          value={blockState.url || ''}
          label={intl.formatMessage({ id: 'block.typeform.form.url' })}
        />
        <CustomRadioGroup
          labelMessageId="block.typeform.form.height"
          choices={Block.HEIGHT_OPTIONS}
          name="height"
          selected={blockState.height || 'medium'}
          onChange={handleChangeLocally('height')}
        />
      </MultipleEditor>
    </Modal2>
  );
};

export const Edit = ({ block }) => {
  const { blockState, handleChangeWithDebouncedSave } = useTypeformBlockState(block);
  const [modalOpen, setModalOpen] = React.useState(false);

  return (
    <>
      {modalOpen ? <EditModal block={block} onHide={() => setModalOpen(false)} /> : null}
      <EditForm block={blockState} onChange={handleChangeWithDebouncedSave} onEditClick={() => setModalOpen(true)} />
    </>
  );
};
