import { useSelector } from 'react-redux';
import { selectCurrentUser } from '../selectors/authSelectors';

const isDevelopment = () => process.env.NODE_ENV === 'development';

// TODO: find these IDs once they've been created
const cohortsAllowedForAutograding = [
  'b774a950-28a5-4ab9-9792-2f119ba91bac', // Travis's Sandbox Course on QA Env, only cohort
  '036849fb-269b-426e-a803-91ff4ae402eb', // Louie's Fresh Slate Course on QA Env
  '1487455f-f19f-4af3-b0b6-2029ace3c7c8', // D_Reg_FBDM3.2_0920_Section1
  'f10c1c3b-0ea8-4084-97a4-7ab35273a932', // CE_syr_FBDM3.2_0920_Section1
  '2b2f9c23-d774-45bc-a030-545e569ce428', // CE_cwr_TBDA1.1_1020_Section1
];

// NOTE: This might be temporary if all authors will be allowed to create rulesets later
const usersAllowedForAutograding = [
  'cf68bcf3-dccd-42e6-be38-88dc16723459', // qasource@instructor.com on QA Env
  'ef5d557c-0262-4417-a710-3bb3f57b0553', // Louie on QA Env
  '320d26e0-d71f-4ad9-a27a-3019d1bf56f3', // Louie on Prod Env
  'cd8496c4-587c-4d44-a8ab-c837f5b92313', // Travis on QA Env
  'b0062300-7cb6-494c-8385-f6101dcbf276', // Travis on Prod Env
  'b1deebd5-cf1a-47eb-a074-3707df90d0e6', // Ilgiz on QA Env
  '20f2be61-4558-4665-a6b8-6621a4783cab', // Ilgiz on UAT Env
  '2fbecd0c-9c33-4f43-b724-9ffdf8e81dfd', // Ilgiz on Prod Env
  'a7efdf0f-6aa2-4374-91a0-27d838a04edd', // Nathan on QA Env
  '59269d88-4c1e-4085-821a-c03719e65f74', // Nathan on Prod Env
  '0a986fcf-fbc8-42af-ba5e-39c635c4a9f7', // Johnson on QA Env
  '3bf7930b-7850-47ba-bcd6-29b2a6ba6af0', // Johnson Admin on Prod Env
  '0972b64c-8c23-4dbb-982d-528144258dac', // Johnson Author on Prod Env
  '733ca650-7cbf-472c-8d53-eee8a59ec595', // Johnson Instructor on Prod Env
  '35a36d29-e269-49fe-9615-92585d8a064d', // Shane Author on Prod Env
  'e52cc9e8-85c3-4465-a843-6a51226e4a18', // Amy Admin on Prod Env
  '447e8c6d-560e-4123-9be9-9cc87ef3f854', // Daniel Admin on Prod Env
  'e3df5626-a144-4a3d-aff4-0c574d085aac', // Robert Margherio Author on Prod Env
  '07509a47-e71a-4c16-b48d-53ab546f4009', // Robert Margherio Evaluator on Prod Env
];

// For use with RTE
const usersAllowedSpellCheckBadges = [
  'c3ecbf28-3f5f-425b-9465-a75f8447dd6e', // Max QA
  '75a0878a-1482-4922-8dc9-2890a41833ff', // Max UAT
  'beffb812-bffd-4fb3-a98d-75b82e61c5e4', // Nathan@copyeditor on QA Env
  '040d047d-42d4-4892-b6e4-2f2c3c4a87e4', // Nathan@admin on QA Env
  '6ec443ef-ffd6-4057-8901-a57ee3dca3c2', // Nathan@copyeditor on UAT Env
  '1ff064ec-c714-4d64-bab8-b4ddb0bfe9fb', // Nathan@admin on UAT Env
  'e1e33479-d41c-4afa-8374-66f98e09ad9d', // Nathan@copyeditor on Prod Env
  'e3708f09-65f4-42ab-832e-0968735ced97', // Kendra on Prod Env
  '5e1e28fe-5cb4-484c-bcfc-40b537684bfc', // Jessica on Prod Env
  '2090d713-b291-4fbf-8bb0-660604945a66', // Kristin on Prod Env
];

// For use with RTE
const usersDisablingSpellCheck = [
  '6625c5ae-da8f-498b-bd25-f94f60e15038', // Nathan@nospellcheck QA
  '07e21706-b51c-4b95-8001-871d2a9d2182', // Nathan@nospellcheck UAT
  '5a57120d-8aa3-4380-a427-12bb598ea8b2', // Nathan@nospellcheck Prod
];

export default function useFeatureToggle() {
  const currentUser = useSelector(selectCurrentUser);

  return {
    shouldShowAutograder: cohortId =>
      cohortsAllowedForAutograding.includes(cohortId) ||
      usersAllowedForAutograding.includes(currentUser?.id) ||
      isDevelopment(),
    shouldDisableSpellCheck: (user = currentUser) => usersDisablingSpellCheck.includes(user?.id),
    shouldEnableSpellCheckBadges: (user = currentUser) => usersAllowedSpellCheckBadges.includes(user?.id),
  };
}
