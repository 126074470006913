// https://trippingoncode.com/react-debounce-hook/

import { useCallback } from 'react';
import { debounce } from 'lodash';

export default function useDebounce(fnToDebounce, durationInMs = 500) {
  // eslint-disable-next-line no-restricted-globals
  if (isNaN(durationInMs)) {
    throw new TypeError('durationInMs for debounce should be a number');
  }

  if (fnToDebounce === null) {
    throw new TypeError('fnToDebounce cannot be null');
  }

  if (typeof fnToDebounce !== 'function') {
    throw new TypeError('fnToDebounce should be a function');
  }

  return useCallback(debounce(fnToDebounce, durationInMs), []);
}
