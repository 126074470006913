import { map as _map } from 'lodash';
import { selectEntities, select, sortBy } from './applicationSelectors';

const selectCriterionEntities = selectEntities('criteria');
const selectCriterion = select(selectCriterionEntities);

export const selectCriterionByCriterionProgress = (state, criterionProgress) =>
  selectCriterion(state, criterionProgress.criterion_id);

export const selectCriteriaByAssignment = (state, assignment) =>
  _map(assignment.rubric_criteria, id => selectCriterion(state, id));

const selectOldestCriteriaByAssignment = sortBy(selectCriteriaByAssignment, 'created_at');

export const selectOldestEnabledCriteriaByAssignment = (state, assignment) =>
  selectOldestCriteriaByAssignment(state, assignment).filter(criterion => !criterion.disabled_at);
