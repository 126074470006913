import { Operator } from './OperatorsEnum';
import { parseFormula } from './logicFormulaParser';
import { buildAlphabet } from './helper';

export const calculateFormula = (formula, checks) => {
  const root = parseFormula(formula, checks.length);
  const alphabetMap = {};
  buildAlphabet(checks.length).forEach((letter, index) => (alphabetMap[letter] = checks[index]));
  const calculateValue = node => {
    if (!node.left || !node.right) {
      return alphabetMap[node.value];
    }
    const left = calculateValue(node.left);
    const right = calculateValue(node.right);
    switch (node.value) {
      case Operator.AND:
        return left && right;
      case Operator.OR:
        return left || right;
      default:
        console.error('Something wrong with parser', node, root);
        throw new Error('Parser error');
    }
  };
  return calculateValue(root);
};
