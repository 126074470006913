import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class LtiAssignmentTile extends Component {
  static propTypes = {
    assignment: PropTypes.object,
    isMultiSelect: PropTypes.bool,
    onSelect: PropTypes.func,
  };

  static defaultProps = {
    isMultiSelect: false,
  };

  handleSelect = e => {
    const isSelected = e.target.checked;
    const { assignment, onSelect } = this.props;
    onSelect(assignment, isSelected);
  };

  render() {
    const { assignment, isMultiSelect, onSelect } = this.props;

    return (
      <React.Fragment>
        <div className="to-do assessment">
          <div className="progress-indicator" />
          <label htmlFor={`el_${assignment.id}`} style={{ flex: 'auto', fontSize: 16 }}>
            {assignment.title}
          </label>
          {isMultiSelect && <input type="checkbox" id={`el_${assignment.id}`} onChange={this.handleSelect} />}
          {!isMultiSelect && (
            <button onClick={() => onSelect(assignment, true)} className="authoring-button small">
              Select
            </button>
          )}
        </div>
      </React.Fragment>
    );
  }
}
