const MAX_GRADE = 10;

const calculateLinearGrade = passRatio => {
  let grade = Math.ceil(passRatio * MAX_GRADE);
  return grade.toString();
};

const calculateNonLinearGrade = (passRatio, nonLinearGradingRanges) => {
  const passPercent = passRatio * 100;
  const foundIndex = nonLinearGradingRanges.findIndex(grade => passPercent <= grade.maxRange);
  const grade = foundIndex + 1;

  return foundIndex >= -1 ? grade.toString() : calculateLinearGrade(passRatio);
};

export const calculateGrade = (ruleResult, nonLinearGradingRanges) => {
  if (!ruleResult) {
    return '0';
  }

  let totalPoints = 0;
  let maxPoints = 0;
  ruleResult.forEach(({ formula, isPassedChecks, points, checks }) => {
    let rulePoint = Number(points);
    if (isNaN(rulePoint) || rulePoint < 1) {
      rulePoint = 1;
    }
    maxPoints += rulePoint;
    if (formula) {
      totalPoints += isPassedChecks ? rulePoint : 0;
    } else {
      let rightAnswer = 0;
      checks.forEach(check => check.status && rightAnswer++);
      totalPoints += (rightAnswer / checks.length) * rulePoint;
    }
  });
  const passRatio = totalPoints / maxPoints;
  if (nonLinearGradingRanges?.length) {
    return calculateNonLinearGrade(passRatio, nonLinearGradingRanges);
  } else {
    return calculateLinearGrade(passRatio);
  }
};

export const truncate = (str, n) => ((str?.length || 0) > n ? `${str.substr(0, n - 1)}...` : str);
