import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Modal2 from '../../library/Modal2';
import Errors from '../../Utils/Errors';
import CustomInput from '../../Utils/CustomInput';
import intl from '../../../utils/intl';
import CustomCheckbox from '../../Utils/CustomCheckbox';

export default class EditComponentModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      component: props.component,
      errors: null,
    };
  }

  handleChange = e => {
    const { target } = e;
    const { name } = target;
    const value = target.type === 'checkbox' ? target.checked : target.value;

    this.setState({
      component: {
        ...this.state.component,
        [name]: value,
      },
    });
  };

  handleSubmit = e => {
    const { section, unit, match, history } = this.props;
    const { component } = this.state;
    const isNew = !component.id;

    e.preventDefault();

    this.props
      .onSave(component, unit.id)
      .then(comp => {
        if (isNew) {
          const url = `${match.url}/sections/${section.id}/units/${unit.id}/components/${comp.id}`;
          history.push(url);
        }
        this.props.onHide();
      })
      .catch(err => {
        if (err.value) {
          this.setState({
            errors: err.value.errors,
          });
        }
      });
  };

  render() {
    const { component, errors } = this.state;

    return (
      <Modal2
        size="small"
        title={
          component.id ? (
            <FormattedMessage id="component.heading.edit" />
          ) : (
            <FormattedMessage id="component.heading.create" />
          )
        }
        onHide={this.props.onHide}
        onOk={this.handleSubmit}>
        <Errors errors={errors} />

        <CustomInput
          name="title"
          onChange={this.handleChange}
          value={component.title}
          label={intl.formatMessage({ id: 'component.form.title' })}
        />

        <CustomInput
          name="duration"
          onChange={this.handleChange}
          value={component.duration}
          label={intl.formatMessage({ id: 'component.form.duration' })}
        />

        <CustomCheckbox
          label={intl.formatMessage({ id: 'component.adaptive' })}
          checked={component.adaptive_learning_on}
          onChange={this.handleChange}
          name="adaptive_learning_on"
        />
      </Modal2>
    );
  }
}
EditComponentModal.propTypes = {
  unit: PropTypes.object.isRequired,
  section: PropTypes.object.isRequired,
  component: PropTypes.object.isRequired,
  history: PropTypes.object,
  onSave: PropTypes.func.isRequired,
};
