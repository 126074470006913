/* global RTE */
import React, { forwardRef, useRef, useEffect, useState, useImperativeHandle, useMemo } from 'react';
import PropTypes from 'prop-types';
import Script from 'react-load-script';
import Editor from '../library/CKEditor';
import Loading from './Loading';
import useFeatureToggle from '../../hooks/useFeatureToggle';

const CKEditor = forwardRef((props, ref) => {
  const [isRTELoaded, setIsRTELoaded] = useState(false);
  const onChangeMethod = useRef(props.onChange);
  const triggerChange = useRef(true);
  const defaultContentTimeout = useRef(null);
  const editorRef = useRef(null);
  const featureToggle = useFeatureToggle();

  useEffect(() => {
    onChangeMethod.current = props.onChange;
  }, [props.onChange]);

  useImperativeHandle(ref, () => ({
    setContent(content) {
      const editor = editorRef.current;
      if (editor && editor.editorInstance) {
        triggerChange.current = false;
        editor.editorInstance.setData(content);
        triggerChange.current = true;
      }
    },
  }));

  const handleRTEOnLoad = () => {
    RTE.onReady(() => {
      setIsRTELoaded(true);
      RTE.setDefaults();
      RTE.setFeatureToggles(featureToggle);
    });
  };

  const handleChange = e => {
    clearTimeout(defaultContentTimeout.current);
    const content = e.editor.getData() || props.defaultContent || '';

    if (props.defaultContent && content === props.defaultContent && e.editor.getData().trim() === '') {
      // Use setTimeout to prevent maximum call stack exceeded issue
      defaultContentTimeout.current = setTimeout(() => {
        e.editor.setData(content);
      }, 1000);
    }

    if (onChangeMethod.current && triggerChange.current) {
      onChangeMethod.current(content.replace(/<p>&nbsp;<\/p>\s*$/, ''));
    }
  };

  const editorProps = () => {
    let { config, events } = props;
    const { content, inline, activeClass, minimal } = props;
    config = {
      ...(minimal ? RTE.minimalOptions() : RTE.defaultOptions()),
      ...config,
    };
    events = {
      change: handleChange,
      mode: e => {
        if (e.editor.mode === 'source') {
          const editable = e.editor.editable();
          editable.attachListener(editable, 'input', () => {
            handleChange(e);
          });
        }
      },
      ...events,
    };

    return {
      inline,
      content,
      events,
      config,
      activeClass,
    };
  };

  return useMemo(() => {
    return (
      <>
        <Script url="/rte/rte.js" onLoad={handleRTEOnLoad} />
        {isRTELoaded ? <Editor ref={editorRef} isScriptLoaded {...editorProps()} /> : <Loading />}
      </>
    );
  }, [isRTELoaded]);
});

export default CKEditor;

CKEditor.propTypes = {
  onChange: PropTypes.func,
  inline: PropTypes.bool,
  activeClass: PropTypes.string,
  defaultContent: PropTypes.string,
  minimal: PropTypes.bool,
  config: PropTypes.object,
  events: PropTypes.object,
  content: PropTypes.string,
};

CKEditor.defaultProps = {
  inline: false,
  defaultContent: null,
  minimal: false,
};
