import { connect } from 'react-redux';
import LtiConfigDialog from './LtiConfigDialog';
import { fetchCohorts } from '../../../actions/cohortActions';
import { selectSortedCohorts } from '../../../selectors/cohortSelectors';

const mapStateToProps = (state, ownProps) => {
  const { bootcamp } = ownProps;
  const cohorts = selectSortedCohorts(state).filter(cohort => cohort.bootcamp_id === bootcamp.id);

  return {
    bootcamp,
    cohorts,
    selectedCohort: cohorts[0],
  };
};

export default connect(mapStateToProps, { fetchCohorts })(LtiConfigDialog);
