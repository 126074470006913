import React, { useEffect, useRef } from 'react';
import hljs from 'highlight.js';
import 'highlight.js/styles/tomorrow-night.css';

const CodeSnippetWrapper = props => {
  const highlightDiv = useRef(null);

  useEffect(() => {
    hljs.initHighlightingOnLoad();
    highlightDiv.current.querySelectorAll('pre code').forEach(block => {
      hljs.highlightBlock(block);
    });
  });

  return <div className="rte-content" ref={highlightDiv} {...props} />;
};

export default CodeSnippetWrapper;
