import uuid from 'uuid/v4';
import moment from 'moment';
import Bowser from 'bowser';
import * as sessionTime from './sessionTime';
import { STUDENT, COACH, ADMIN, AUTHOR } from '../helpers/userAuth';
import axios from '../api/axios';
import { postBeacon } from '../helpers/beaconHelper';
import { formatDate } from './utils';

const STORAGE_KEY = 'pathstream_analytics';
// TIME_UNTIL_NEW_SESSION is the milliseconds passed until a new session will be created
//   after final tab is closed. This is to help distinguish between a tab close and a tab refresh.
//   If a new tab is opened within this time, it would still be part of the previous session.
//   If a new tab is opened after this time, it will be part of new session.
const TIME_UNTIL_NEW_SESSION = 300000;
let currentUser;
let currentSession;
let currentPage;
let currentPageArgs;
let ip;
let tabCount;
let tabCountLastUpdated;

let browserInfo;
const getBrowserInfo = () => {
  if (browserInfo) return browserInfo;
  const browser = Bowser.getParser(window.navigator.userAgent);
  browserInfo = browser.getResult();

  return browserInfo;
};

const getBrowserDetailsForAnalytics = () => {
  const browser = getBrowserInfo();

  const screen = window.screen || {};
  const { height, width } = screen;

  return {
    $browser: browser.browser.name,
    $browser_version: parseFloat(browser.browser.version), // ES stores browser version as long
    $os: browser.os.name,
    $screen_height: height,
    $screen_width: width,
  };
};

const fetchIP = () => {
  if (ip) {
    return;
  }

  axios.get('/users/remote_ip').then(response => {
    ip = response.data.ip;
  });
};

const deserialize = () => {
  try {
    let data = localStorage.getItem(STORAGE_KEY);
    if (data) {
      data = JSON.parse(data);
      currentPage = data.currentPage;
      currentUser = data.currentUser;
      currentSession = data.currentSession;
      tabCount = data.tabCount;
      tabCountLastUpdated = data.tabCountLastUpdated;
      ip = data.ip;
      sessionTime.setInfo(data.sessionTime);

      fetchIP();
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.warn('Error deserializing data', e);
  }
};
deserialize();

const serialize = cleanUp => {
  if (cleanUp) {
    localStorage.removeItem(STORAGE_KEY);
    return;
  }

  // Save most up to date tab count
  let data = localStorage.getItem(STORAGE_KEY);
  if (data) {
    data = JSON.parse(data);
    // if other tabs updated tab count, use the updated instead
    if (data.tabCountLastUpdated > tabCountLastUpdated) {
      tabCount = data.tabCount;
      tabCountLastUpdated = data.tabCountLastUpdated;
    }
  }

  localStorage.setItem(
    STORAGE_KEY,
    JSON.stringify({
      currentPage,
      currentUser,
      currentSession,
      ip,
      sessionTime: sessionTime.getInfo(),
      tabCount,
      tabCountLastUpdated,
    }),
  );
};

const cleanUp = () => {
  currentUser = null;
  currentPage = null;
  currentSession = null;
  serialize(true);
  sessionTime.reset();
};

const shouldLog = () => {
  if (!currentUser || process.env.NODE_ENV !== 'production') {
    return false;
  }
  return true;
};

const getUserDetailsForAnalytics = () => ({
  distinct_id: currentUser.id,
  user_id: currentUser.id, // NOTE: Remapped to segment id on backend
  name: currentUser.name,
  $email: currentUser.email,
  isStudent: currentUser.roles.includes(STUDENT),
  isCoach: currentUser.roles.includes(COACH),
  isAdmin: currentUser.roles.includes(ADMIN),
  isAuthor: currentUser.roles.includes(AUTHOR),
});

const postTrackingData = (name, args) => {
  if (!currentUser) {
    return;
  }

  const clientTime = moment().format();

  try {
    const data = {
      ...getBrowserDetailsForAnalytics(),
      ...getUserDetailsForAnalytics(),
      ...args,
      time: Date.now() / 1000,
      $current_url: window.location.href,
      client_time: clientTime,
      event: name,
      ip,
      session_id: currentSession,
      domain: window.location.hostname,
    };
    postBeacon(
      '/users/analytics',
      {
        data: JSON.stringify(data),
      },
      true,
    );
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('Error logging analytics', e);
  }
};

export const track = (name, args) => {
  serialize();

  if (!shouldLog()) {
    return;
  }

  postTrackingData(name, args);
};

export const trackExposure = (flagKey, variant) => {
  track('$exposure', { flagKey, variant });
};

export const setUser = user => {
  const isNewLogin = !currentUser || currentUser.id !== user.id;
  currentUser = user;

  tabCount = tabCount || 0;
  if (tabCountLastUpdated && tabCount === 0 && Date.now() - tabCountLastUpdated > TIME_UNTIL_NEW_SESSION) {
    currentSession = null;
  }
  tabCount += 1;
  tabCountLastUpdated = Date.now();

  if (!currentSession) {
    currentSession = uuid();
  }

  fetchIP();

  // serialize so tab count in local storage is up to date
  if (isNewLogin) {
    incrementLogin();
  } else {
    serialize();
  }
};

export const inExperiment = (user, flag, variant = 'treatment') => {
  const userVariant = user?.variants?.[flag];
  if (userVariant !== undefined) {
    trackExposure(flag, userVariant);
    return userVariant === variant;
  } else {
    return false;
  }
};

export const inFlag = (user, flag) => inExperiment(user, flag, 'on');

const incrementLogin = () => {
  track('Login');
};

export const pageStart = pageName => {
  currentPage = pageName;
  sessionTime.pageStart();
};

export const setPageArgs = pageArgs => {
  currentPageArgs = pageArgs;
};

export const getPageParams = pageArgs => {
  const args = { ...pageArgs };

  const keys = ['bootcamp', 'section', 'module', 'lesson', 'lab', 'project', 'student', 'cohort'];
  keys.forEach(key => {
    const value = args[key];
    if (!value) {
      return;
    }

    args[key] = value.title;
    args[`${key}_id`] = value.id;

    switch (key) {
      case 'module':
      case 'project':
        args.unit = value.title;
        args.unit_id = value.id;
        break;

      case 'lab':
        args.tutorial = value.title;
        args.tutorial_id = value.id;
        break;

      case 'student':
        args.student = value.name;
        break;

      case 'cohort':
        args.bootcamp_instance = `${value.start_date} - ${value.end_date} - ${value.location}`;
        args.bootcamp_instance_id = value.id;
        break;

      default:
        break;
    }
  });

  return args;
};

export const pageEnd = args => {
  let includeIdle = false;
  if (args) {
    includeIdle = args.includeIdle;
    delete args.includeIdle;
  }

  const pageParams = getPageParams(args || currentPageArgs);
  currentPageArgs = null;

  sessionTime.pageEnd(includeIdle);

  track(currentPage, {
    $duration: sessionTime.getCurrentPageDuration(),
    ...pageParams,
  });
};

export const endSession = () => {
  pageEnd();

  // console.log('Session Duration', sessionTime.getSessionDuration());

  // Note: This only logs the session id and session time for the tab the user logs out in
  //   If session id changed, the session id tracked will be different from the one in the "Login" event.
  //   Other tabs will have different session times that will be logged when those pages close,
  //   and they will be tracked after this event.
  track('Session', {
    time: sessionTime.getLastEventTime() + 1,
    $duration: sessionTime.getSessionDuration(),
  });

  cleanUp();
};

export const unloadEventListener = () => {
  sessionTime.pageEnd();
  const duration = sessionTime.getCurrentPageDuration();

  // get latest count from local storage in case other tab updated
  let data = localStorage.getItem(STORAGE_KEY);
  if (data) {
    data = JSON.parse(data);
    if (data.tabCountLastUpdated > tabCountLastUpdated) {
      tabCount = data.tabCount;
    }
  }
  tabCount -= 1;
  tabCountLastUpdated = Date.now();

  track(currentPage, {
    $duration: duration,
  });
};

export const generateFeedbackData = (bootcamp, currentUser, cohort, labClassification, labInteractable) => {
  const browser = getBrowserInfo();

  return {
    email: currentUser?.email,
    user_id: currentUser?.id,
    user_name: currentUser?.name,
    os: `${browser.os.name} ${browser.os.version}`,
    browser: `${browser.browser.name} ${browser.browser.version}`,
    cohort_id: cohort?.id,
    cohort_name: cohort && `${formatDate(cohort.start_date)} → ${formatDate(cohort.end_date)}, ${cohort.location}`,
    course_id: bootcamp?.id,
    course_name: bootcamp?.title,
    course_internal_name: bootcamp?.version,
    lab_classification: labClassification,
    lab_type: labInteractable?.app,
    lab_id: labInteractable?.id,
    lab_name: labInteractable?.title,
    time_zone_offset: moment().utcOffset(),
    certificate_title: bootcamp?.certificate_title,
  };
};
// Note: this is triggered on tab/window close and also page refresh
window.addEventListener('unload', unloadEventListener);
