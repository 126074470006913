import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Icons from '../../Utils/Icons';
import intl from '../../../utils/intl';

export default function AddButton({ onClick, spacingOnTop, stretched, ariaLabel, children }) {
  return (
    <section className={classnames('add-block-section', spacingOnTop && 'add-block-section--spaced-top')}>
      <div className={classnames('add-block-section__line', stretched && 'add-block-section__line--stretched')} />
      <button type="button" onClick={onClick} className="add-block-section__button" aria-label={ariaLabel}>
        <span className="icon-element icon-element--fill-white icon-element--background-blue icon-element--button icon-element--size-">
          <Icons.Plus />
        </span>
      </button>
      {children}
    </section>
  );
}

AddButton.propTypes = {
  onClick: PropTypes.func,
  spacingOnTop: PropTypes.bool,
  stretched: PropTypes.bool,
  ariaLabel: PropTypes.string,
  children: PropTypes.any,
};

AddButton.defaultProps = {
  ariaLabel: intl.formatMessage({ id: 'aria.addBlock' }),
};
