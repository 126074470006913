import { REQUEST } from './constants';

export const startRequest = requestType => ({
  type: REQUEST.START,
  payload: { requestType },
});

export const endRequest = requestType => ({
  type: REQUEST.END,
  payload: { requestType },
});
