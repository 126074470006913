import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Popover } from 'antd';
import UserPicture from '../../../Utils/UserPicture';
import intl from '../../../../utils/intl';
import CoachProfileNavContent from './CoachProfileNavContent';

const CoachProfileNav = ({ currentUser }) => {
  const [visible, setVisible] = useState(false);

  const handleVisibleChange = () => {
    setVisible(!visible);
  };

  return (
    <Popover
      placement="bottomRight"
      content={<CoachProfileNavContent currentUser={currentUser} />}
      trigger="click"
      visible={visible}
      onVisibleChange={handleVisibleChange}>
      <button
        type="button"
        className="user-profile__avatar"
        aria-label={intl.formatMessage({ id: 'nav.showUserProfile' }, { name: currentUser.name })}
        aria-haspopup="true"
        aria-controls="user-menu">
        <UserPicture user={currentUser} defaultWhite className="user-profile-picture" />
      </button>
    </Popover>
  );
};

export default CoachProfileNav;

CoachProfileNav.propTypes = {
  currentUser: PropTypes.object.isRequired,
};
