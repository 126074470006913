// eslint-disable-next-line no-unused-vars
import { getLocationPath, getDataSourcePath } from './dataSourceHelpers';
import { evaluator, sheetNotFound } from './evalHelpers';

// eslint-disable-next-line complexity
export const evalPivotTable = ({ data, location, property, operator, value }) => {
  // Get the specific location (one before the last one)
  const sheet = getLocationPath(data, location);
  if (!sheet) return sheetNotFound(property, operator, value);
  const { type, order, name } = location[location.length - 1].dataSource;

  // locate path based on dataSource
  const pivotTableLocator = getDataSourcePath({ type, order, name });
  const pivotTable = pivotTableLocator(sheet.pivotTables);

  switch (property) {
    case 'exists':
      if (type === 'byAny') {
        return !!sheet.pivotTables.length;
      }
      return !!pivotTable;
    case 'name':
      if (!pivotTable) return null;
      // assuming we only use title for the dataSource
      if (type === 'byTitle') {
        const { title } = pivotTable;
        if (operator === 'contains') {
          if (title.includes(value)) return true;
        } else if (operator === 'does not contain') {
          if (!title.includes(value)) return true;
        } else {
          return evaluator(title, operator, value);
        }
      }
      return false;
    default:
      return null;
  }
};

export default evalPivotTable;
