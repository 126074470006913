import React from 'react';
import PropTypes from 'prop-types';
import { Element } from 'react-scroll';
import classnames from 'classnames';
import useElementScrolling from '../../hooks/useElementScrolling';
import BlockHtmlShow from './BlockHtmlShow';
import BlockCollapsibleHtml from './BlockCollapsibleHtml';
import CommentThreadContainer from '../Blocks/CommentThread/CommentThreadContainer';
import BlockQuizShow from './BlockQuizShow';
import BlockGradedQuizShow from './BlockGradedQuizShow';
import BlockSingleQuestionQuizShow from './BlockSingleQuestionQuizShow';
import BlockVideoShow from './BlockVideoShow';
import BlockEmbedShow from './BlockEmbedShow';
import BlockLabShowContainer from './BlockLabShowContainer';
import BlockSqlShow from './BlockSqlShow';
import BlockAsanaAccount from '../Blocks/AsanaAccount/AsanaAccount';
import Block from '../../api/block';
import { Typeform } from '../Blocks/Typeform/Typeform';

export default function BlockShow(props) {
  const { block } = props;
  const blockName = `block_${block.id}`;
  useElementScrolling(blockName, { offset: -150 });

  // eslint-disable-next-line complexity
  const renderBlock = props => {
    const { block, enrollmentId, blockLabel, headingClass, bootcampId } = props;
    const propsForAll = { block, blockLabel, headingClass, bootcampId };

    switch (block.block_type) {
      case Block.HTML:
        return <BlockHtmlShow {...propsForAll} />;
      case Block.COLLAPSIBLE_HTML:
        return <BlockCollapsibleHtml {...propsForAll} />;
      case Block.COMMENT_THREAD:
        return <CommentThreadContainer {...propsForAll} enrollmentId={enrollmentId} />;
      case Block.LAB:
        return <BlockLabShowContainer {...propsForAll} />;
      case Block.QUIZ:
        return <BlockQuizShow {...propsForAll} />;
      case Block.GRADED_QUIZ:
        return <BlockGradedQuizShow {...propsForAll} />;
      case Block.SINGLE_QUESTION_QUIZ:
        return <BlockSingleQuestionQuizShow {...propsForAll} />;
      case Block.VIDEO:
        return <BlockVideoShow {...propsForAll} />;
      case Block.EMBED:
        return <BlockEmbedShow {...propsForAll} />;
      case Block.SQL:
        return <BlockSqlShow {...propsForAll} enrollmentId={enrollmentId} />;
      case Block.TYPEFORM:
        return <Typeform block={block} />;
      case Block.ASANA_ACCOUNT:
        return <BlockAsanaAccount {...propsForAll} />;
      default:
        return null;
    }
  };

  const classes = classnames('block buttons-lessons', block.block_type);

  return (
    <Element className={classes} name={blockName}>
      {renderBlock(props)}
    </Element>
  );
}

BlockShow.propTypes = {
  block: PropTypes.object.isRequired,
  enrollmentId: PropTypes.string,
  blockLabel: PropTypes.any,
  headingClass: PropTypes.string,
  bootcampId: PropTypes.string,
};
