import { map as _map } from 'lodash';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import SectionTileContainer from '../../Sections/SectionTileContainer';
import Loading from '../../Utils/Loading';
import moment from 'moment';
import { isEmpty as _isEmpty } from 'lodash';
import ProgressTracker from './ProgressTracker';
import { FormattedMessage } from 'react-intl';
import { canSetupAsanaAddress } from '../../../helpers/userAuth';
import { showAsanaAccountCreatedModal } from '../../Users/utils/showSetupModal';
import { Modal } from 'antd';
import CourseCompletionModal from './CourseCompletionModal';
import { getWeekFromDate } from '../../../utils/utils';
import ReadOnlyWarning from '../ReadOnlyWarning/ReadOnlyWarning';

const StudentCourseOverview = ({
  bootcamp,
  sections,
  currentProgress,
  currentSectionProgress,
  currentUser,
  cohortId,
  cohort,
  progresses,
  fetchAssignments,
  fetchQuizzes,
  fetchBootcampInstanceSections,
  showAsanaButton,
  enrollment,
  programCohort,
  enrollments,
  bootcamps,
  cohorts,
  pathPrefix,
}) => {
  const [bootcampInstanceSections, setBootcampInstanceSections] = useState([]);
  const [hasLoaded, setHasLoaded] = useState(false);
  const [showCourseCompleteModal, setShowCourseCompleteModal] = useState(false);

  const sectionProps = section => {
    return {
      bootcamp,
      section,
      currentSectionProgress,
      cohortId,
    };
  };

  const startDate = moment(cohort.start_date, 'YYYY-MM-DD').startOf('day');
  const isPastDeadline = cohort.past_deadline;

  useEffect(() => {
    let isMounted = true;
    const fetchData = async () => {
      const response = await fetchBootcampInstanceSections(cohortId);
      if (isMounted) {
        setBootcampInstanceSections(response);
        setHasLoaded(true);
      }
    };

    if (cohortId) {
      fetchAssignments(cohortId);
      fetchQuizzes(cohortId);
      if (!hasLoaded) {
        fetchData();
      }
    }
    if (hasLoaded && enrollment?.progress >= 1.0) {
      setShowCourseCompleteModal(true);
    }

    return () => {
      isMounted = false;
    };
  }, [cohortId, fetchAssignments, fetchQuizzes, fetchBootcampInstanceSections, hasLoaded, enrollment?.completed_at]);

  if (!hasLoaded) {
    return <Loading />;
  }

  const getFormattedDateRangeForWeek = weekNumber => {
    const startOfWeek = moment(startDate)
      .add(weekNumber - 1, 'weeks')
      .format('MMM D');
    const endOfWeek = moment(startDate).add(weekNumber, 'weeks').subtract(1, 'days').format('MMM D');

    return { startOfWeek, endOfWeek };
  };

  const assignedSectionIds = [];

  const currentUnitId = currentProgress && currentProgress.unit.id;
  const showProgressTracker = progresses?.length > 0;

  const canSetupAsana = canSetupAsanaAddress(currentUser);

  const groupedSections = bootcampInstanceSections?.reduce((acc, section) => {
    const week = getWeekFromDate(section.section_due_at, startDate);
    const weekLength = section.week_length || 1;
    const sectionStartWeek = week - weekLength + 1;
    const sectionEndWeek = week;
    const weekRange = weekLength > 1 ? `${sectionStartWeek} & ${sectionEndWeek}` : `${sectionStartWeek}`;
    const startWeekDateRange = getFormattedDateRangeForWeek(sectionStartWeek);
    const endWeekDateRange = getFormattedDateRangeForWeek(sectionEndWeek);

    const weekRangeTitle = `${startWeekDateRange.startOfWeek}-${endWeekDateRange.endOfWeek}`;

    const foundSection = sections.find(s => s.id === section.section_id);

    if (foundSection) {
      let group = acc.find(group => group.weekRange === weekRange);
      if (!group) {
        group = { weekRange, sections: [], weekRangeTitle, week };
        acc.push(group);
      }
      if (!group.sections.some(s => s.id === foundSection.id)) {
        group.sections.push(foundSection);
      }
      assignedSectionIds.push(foundSection.id);
    }
    return acc;
  }, []);

  const unassignedSectionsExist = !_isEmpty(sections.filter(section => !assignedSectionIds.includes(section.id)));
  sections.forEach((section, index) => {
    section.index = index;
  });
  groupedSections?.sort((a, b) => a.week - b.week);
  groupedSections?.forEach(group => group.sections.sort((a, b) => a.index - b.index));

  const closeModal = () => {
    setShowCourseCompleteModal(false);
  };

  const closeModalandGoToCourses = () => {
    closeModal();
    window.location.href = '/student/';
  };

  return (
    <div className="student-course-overview-sections" data-testid={'student-course-overview'}>
      <Modal
        visible={showCourseCompleteModal}
        closable={false}
        footer={null}
        centered
        className="subject-completion-modal">
        <CourseCompletionModal
          onReturn={closeModalandGoToCourses}
          bootcamp={bootcamp}
          onCancel={closeModal}
          enrollments={enrollments}
          bootcamps={bootcamps}
          cohorts={cohorts}
          programCohort={programCohort}
          currentUser={currentUser}
          pathPrefix={pathPrefix}
        />
      </Modal>

      {isPastDeadline && <ReadOnlyWarning />}

      {showProgressTracker && (
        <div className="overview-progress-tracker" data-testid={'progress-tracker'}>
          <div className="contents-label">Progress Tracker</div>
          <ProgressTracker progresses={progresses} />
        </div>
      )}

      {showAsanaButton && canSetupAsana && (
        <div className="asana-container" data-testid={'asana-container'}>
          <button
            className="button-standard button-standard--short"
            onClick={showAsanaAccountCreatedModal}
            data-testid="show-asana-modal">
            <FormattedMessage id="profile.asanaAccount.buttonTitle" />
          </button>
        </div>
      )}

      <div className="contents-label">Contents</div>
      {unassignedSectionsExist
        ? sections.map(section => (
            <SectionTileContainer
              key={section.id}
              index={section.index}
              totalSections={sections.length}
              pathstream2={true}
              currentUnitId={currentUnitId}
              {...sectionProps(section)}
            />
          ))
        : groupedSections.map(weekGroup => (
            <div className="week-group" key={`week-range-${weekGroup.weekRange}`}>
              {' '}
              <div className="week-label" data-testid={`week-range-${weekGroup.weekRange}-title`}>
                Week {weekGroup.weekRange} ({weekGroup.weekRangeTitle})
              </div>
              <div className="week-sections" data-testid={`week-range-${weekGroup.weekRange}-sections`}>
                {weekGroup.sections.map(section => (
                  <SectionTileContainer
                    key={section.id}
                    index={section.index}
                    totalSections={sections.length}
                    pathstream2={true}
                    currentUnitId={currentUnitId}
                    {...sectionProps(section)}
                  />
                ))}
              </div>
            </div>
          ))}
    </div>
  );
};
StudentCourseOverview.propTypes = {
  currentUser: PropTypes.object.isRequired,
  bootcamp: PropTypes.object.isRequired,
  sections: PropTypes.array.isRequired,
  currentSectionProgress: PropTypes.object,
  progresses: PropTypes.array,
  enrollment: PropTypes.object,
  sectionProgresses: PropTypes.array,
  cohort: PropTypes.object.isRequired,
  cohortId: PropTypes.string.isRequired,
  currentProgress: PropTypes.object,
  fetchAssignments: PropTypes.func.isRequired,
  fetchQuizzes: PropTypes.func.isRequired,
  fetchBootcampInstanceSections: PropTypes.func.isRequired,
  showAsanaButton: PropTypes.bool.isRequired,
  currentUser: PropTypes.object.isRequired,
  programCohort: PropTypes.object,
  enrollments: PropTypes.array,
  bootcamps: PropTypes.array,
  cohorts: PropTypes.array,
  pathPrefix: PropTypes.string,
};

export default StudentCourseOverview;
