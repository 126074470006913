import { connect } from 'react-redux';
import QuizTabs from './QuizTabs';
import { selectQuizByBlock } from '../../selectors/quizSelectors';
import { selectQuestionsByQuizId } from '../../selectors/questionSelectors';
import { saveBlock } from '../../actions/blockActions';

const mapStateToProps = (state, ownProps) => {
  const { block } = ownProps;
  const quiz = selectQuizByBlock(state, block);
  const questions = selectQuestionsByQuizId(state, quiz && quiz.id);
  const numQuestions = questions && questions.length;

  return {
    quiz,
    numQuestions,
  };
};

export default connect(mapStateToProps, {
  saveBlock,
})(QuizTabs);
