import { some as _some, isEmpty as _isEmpty, isEqual as _isEqual } from 'lodash';
import cookies from 'browser-cookies';

const getRoles = user => {
  if (!user || !user.roles) {
    return [];
  }

  return user.roles;
};

const allowRoles = (user, ...roles) => {
  const userRoles = getRoles(user);
  return _some(roles, role => userRoles.includes(role));
};

const hasOnlyRole = (user, role) => {
  return _isEqual(getRoles(user), [role]);
};

export const decodeJwt = token => {
  if (_isEmpty(token) || _isEmpty(token.split('.')[1])) {
    return { exp: 0 };
  }
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  return JSON.parse(window.atob(base64));
};

const ACCESS_TOKEN_NAME = 'pathstream_token';

export const ADMIN = 'Admin';
export const AUTHOR = 'Author';
export const COACH = 'Coach';
export const EVALUATOR = 'Evaluator';
export const STUDENT = 'Student';

export const canAuthor = user => allowRoles(user, ADMIN, AUTHOR);

export const canCoach = user => allowRoles(user, ADMIN, COACH);

export const canEvaluate = user => allowRoles(user, ADMIN, EVALUATOR);

export const canAdministrate = user => allowRoles(user, ADMIN);

export const canLearn = user => allowRoles(user, STUDENT);

export const canSetupAsanaAddress = user => allowRoles(user, ADMIN, STUDENT, COACH);

export const isStaff = user => canAuthor(user) || canCoach(user) || canAdministrate(user);

export const isCoach = user => hasOnlyRole(user, COACH);

export const isEvaluator = user => hasOnlyRole(user, EVALUATOR);

export const isAuthor = user => hasOnlyRole(user, AUTHOR);

export const getTokens = () => {
  const accessToken = localStorage.getItem(ACCESS_TOKEN_NAME);
  return { accessToken };
};

export const setTokens = ({ accessToken }) => {
  localStorage.setItem(ACCESS_TOKEN_NAME, accessToken);
  cookies.set(ACCESS_TOKEN_NAME, accessToken, { secure: true, samesite: 'None' });
};

export const clearTokens = () => {
  localStorage.removeItem(ACCESS_TOKEN_NAME);
  cookies.erase(ACCESS_TOKEN_NAME);
};

export const getTokenExpirationEpoch = () => {
  const { accessToken } = getTokens();

  if (!accessToken) {
    return false;
  }

  const decodedToken = decodeJwt(accessToken);

  return decodedToken.exp * 1000;
};

export const getImpersonator = () => {
  const { accessToken } = getTokens();
  if (!accessToken) {
    return null;
  }

  const decodedToken = decodeJwt(accessToken);
  return decodedToken.impersonated_by || null;
};

export const areTokensValid = () => getTokenExpirationEpoch() >= Date.now();
