import ifvisible from 'ifvisible.js';

let sessionDuration = 0,
  currentPageDuration = 0;
let startTime, endTime;

const now = () => Math.round(Date.now() / 1000);

const onEveryHandle = ifvisible.onEvery(1, () => {
  currentPageDuration += 1;
  endTime = now();
});

export const pageStart = () => {
  onEveryHandle.resume();
  startTime = now();
  endTime = now();
  currentPageDuration = 0;
};

export const pageEnd = (includeIdle = false) => {
  onEveryHandle.pause();

  if (includeIdle) {
    endTime = now();
    currentPageDuration = endTime - startTime;
  }

  sessionDuration += currentPageDuration;
};

export const getCurrentPageDuration = () => currentPageDuration;

export const getSessionDuration = () => sessionDuration;

export const getLastEventTime = () => endTime;

export const reset = () => {
  sessionDuration = 0;
  currentPageDuration = 0;
  startTime = 0;
  endTime = 0;
};

export const getInfo = () => {
  return {
    sessionDuration,
    currentPageDuration,
    startTime,
    endTime,
  };
};

export const setInfo = data => {
  console.log('setInfo', data);
  if (!data) {
    return reset();
  }
  sessionDuration = data.sessionDuration;
  currentPageDuration = 0; //data.currentPageDuration;
  startTime = data.startTime;
  endTime = data.endTime;
};
