import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Route, Switch } from 'react-router-dom';
import PathShowContainer from '../../Paths/PathShowContainer';
import ProjectShowContainer from '../Projects/ProjectShowContainer';
import ComponentShow from '../Components/ComponentShow';
import TutorialShowContainer from '../Labs/TutorialShowContainer';
import GradedQuizShow from '../GradedQuizzes/GradedQuizShow';
import NotFound from '../../NotFound';

export default class PathParent extends Component {
  render() {
    const { match } = this.props;

    return (
      <Switch>
        <Route path={`${match.path}/sections/:sectionId/projects/:projectId`} component={ProjectShowContainer} />

        <Route
          exact
          path={`${match.path}/sections/:sectionId/units/:unitId/components/:componentId/blocks/:blockId`}
          component={TutorialShowContainer}
        />

        <Route
          path={`${match.path}/sections/:sectionId/units/:unitId/components/:componentId`}
          component={ComponentShow}
        />

        <Route path={`${match.path}/sections/:sectionId/graded_quizzes/:unitId`} component={GradedQuizShow} />

        <Route path={`${match.path}`} component={PathShowContainer} />

        <Route component={NotFound} />
      </Switch>
    );
  }
}

PathParent.propTypes = {
  match: PropTypes.object.isRequired,
};
