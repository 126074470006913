import React, { Component } from 'react';
import classnames from 'classnames';
import Icons from './Icons';

export default class CustomCheckbox extends Component {
  render() {
    const { label, inline, ...rest } = this.props;
    const classes = classnames('custom-checkbox', inline && 'custom-checkbox--inline');

    return (
      <div className={classes}>
        <label className="custom-checkbox__label">
          <input className="custom-checkbox__input" type="checkbox" {...rest} />

          <span className="custom-checkbox__checkmark">
            <span className="custom-checkbox__checkmark-icon custom-checkbox__checkmark-icon--checked">
              <Icons.Tick />
            </span>
            <span className="custom-checkbox__checkmark-icon">
              <Icons.RadioCircle />
            </span>
          </span>

          <span className="custom-checkbox__label-text">{label}</span>
        </label>
      </div>
    );
  }
}
