import React from 'react';
import PropTypes from 'prop-types';

import EditQuiz from '../Quizzes/EditQuiz';
import StudentBlockQuizShow from '../../Student/BlockQuizShow';
import BlockEditOptionsContainer from './BlockEditOptionsContainer';
import BlockHeader from '../../Utils/BlockHeader';

const BlockQuizShow = ({
  isEditMode,
  index,
  block,
  totalBlocks,
  onReorder,
  StudentBlockShowComponent,
  EditQuizComponent,
}) => {
  if (!isEditMode) {
    return <StudentBlockShowComponent block={block} questionProgressesEnabled={false} />;
  }

  return (
    <>
      <BlockEditOptionsContainer block={block} index={index} total={totalBlocks} onReorder={onReorder} />
      <div className="section">
        <div className="block-label">
          <BlockHeader messageId="common.knowledgeCheck" />

          <div className="block-label__content">
            <div className="knowledge-check-wrapper">
              <EditQuizComponent block={block} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

BlockQuizShow.propTypes = {
  block: PropTypes.object.isRequired,
  isEditMode: PropTypes.bool,
  index: PropTypes.number.isRequired,
  totalBlocks: PropTypes.number.isRequired,
  onReorder: PropTypes.func.isRequired,
  StudentBlockShowComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  EditQuizComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
};

BlockQuizShow.defaultProps = {
  StudentBlockShowComponent: StudentBlockQuizShow,
  EditQuizComponent: EditQuiz,
};

export default BlockQuizShow;
