import { useState } from 'react';

export default () => {
  const [futureCohortsOnly, setFutureCohortsOnly] = useState(false);

  const handleFutureCohortsOnly = e => {
    setFutureCohortsOnly(e.target.checked);
  };

  return { futureCohortsOnly, setFutureCohortsOnly: handleFutureCohortsOnly };
};
