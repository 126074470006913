/* global RTE */
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, List, Card } from 'antd';
import { QuestionOutlined, FileDoneOutlined, CloseOutlined, UploadOutlined } from '@ant-design/icons';
import { FormattedMessage } from 'react-intl';

import FileUpload from '../../../components/Utils/FileUpload';
import intl from '../../../utils/intl';
import showModal from '../../../utils/showModal';
import SimpleModal from '../../../components/library/SimpleModal/SimpleModal';

// TODO change or delete
// modified copy of src/components/Utils/FileUploadModal/FileUploadModal.jsx
const FileUploadModal = ({ onHide, onSubmit, accept, s3Prefix }) => {
  const [uploadingFile, setUploadingFile] = useState();
  const uploadingFileRef = useRef();
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const uploadedFilesRef = useRef();
  const fileInputRef = useRef();

  uploadingFileRef.current = uploadingFile; // for updated uploadingFile when fileUploaded is called by FileUpload
  uploadedFilesRef.current = uploadedFiles; // for updated uploadedFiles when deleteAllUploadedFiles is called from effect cleanup

  useEffect(() => {
    return deleteAllUploadedFiles;
  }, []);

  const onSelectFile = () => {
    fileInputRef.current.click();
  };

  const onBeforeUpload = file => {
    setUploadingFile(file);
  };

  const fileUploaded = (url, s3Key) => {
    setUploadedFiles([...uploadedFiles, { url, s3Key, file: uploadingFileRef.current }]);
  };

  const fileUploadComplete = () => {
    setUploadingFile(null);
  };

  const deleteAllUploadedFiles = () => {
    if (uploadedFilesRef.current.length === 0) return;
    RTE.deleteFiles(uploadedFilesRef.current.map(file => file.s3Key));
  };

  const removeAll = () => {
    showModal(SimpleModal, {
      icon: <QuestionOutlined />,
      title: intl.formatMessage({ id: 'fileUpload.removeConfirmModal.title' }),
      body: intl.formatMessage({ id: 'fileUpload.removeConfirmModal.body' }),
      okText: intl.formatMessage({ id: 'fileUpload.removeConfirmModal.removeButton' }),
      onOk: () => {
        deleteAllUploadedFiles();
        setUploadedFiles([]);
      },
      showCancel: true,
    });
  };

  const remove = s3Key => {
    return () => {
      RTE.deleteFiles([s3Key]);
      setUploadedFiles(uploadedFiles.filter(file => file.s3Key !== s3Key));
    };
  };

  const closeModalCallback = ({ deleteFiles } = {}) => {
    if (!deleteFiles) {
      // clear uploadedFilesRef before closing or submitted files will be deleted in cleanup function
      uploadedFilesRef.current = [];
    }
    onHide();
  };

  const submit = () => {
    // onSubmit has to call closeModalCallback on sucess for modal to close
    // This is to make sure if onSubmit fails, this modal doesn't close and delete uploaded files
    onSubmit(
      uploadedFilesRef.current.map(file => ({ name: file.file.name, url: file.url })),
      closeModalCallback,
    );
  };

  const fileSize = bytes => {
    if (bytes > 1000000) {
      return `${Math.ceil(bytes / 1000000)} MB`;
    } else if (bytes > 1000) {
      return `${Math.ceil(bytes / 1000)} KB`;
    }

    return `${bytes} B`;
  };

  const title = intl.formatMessage({ id: 'autograder.modelAnswer.uploadModal.title' });
  const body = intl.formatMessage({ id: 'autograder.modelAnswer.uploadModal.body' });

  return (
    <Modal
      visible={true}
      onCancel={onHide}
      className="file-upload-modal"
      title={title}
      maskClosable={false}
      footer={null}>
      <div className="file-upload">
        <h1>Feature flag notice: Only 1st file will be saved</h1>
        <div className="instructions">{body}</div>

        {uploadedFiles.length > 0 && (
          <div className="uploaded-list">
            <List
              bordered
              dataSource={uploadedFiles}
              renderItem={item => (
                <List.Item>
                  <div className="uploaded-list-item">
                    <div className="uploaded-icon">
                      <FileDoneOutlined />
                    </div>
                    <div className="uploaded-body">
                      <p className="file-name">{item.file.name}</p>
                      <p className="file-size">{fileSize(item.file.size)}</p>
                    </div>
                    <div className="uploaded-remove">
                      <CloseOutlined onClick={remove(item.s3Key)} />
                    </div>
                  </div>
                </List.Item>
              )}
            />
          </div>
        )}

        {uploadingFile && (
          <div>
            <Card className="uploading-card">
              <div className="uploading-icon">
                <FileDoneOutlined />
              </div>
              <div className="uploading-body">
                <p className="file-name">{uploadingFile.name}</p>
                <p className="file-status">
                  <FormattedMessage id="fileUpload.uploading" />
                </p>
              </div>
            </Card>
          </div>
        )}

        {!uploadingFile && (
          <Button className="select-file-button" onClick={onSelectFile}>
            <span className="select-file-text">
              <FormattedMessage id="fileUpload.selectFile" />
            </span>
            <UploadOutlined />
          </Button>
        )}

        {!uploadingFile && uploadedFiles.length > 0 && (
          <div className="buttons">
            <Button className="remove-all-button" onClick={removeAll}>
              <FormattedMessage id="autograder.modelAnswer.uploadModal.removeAllButton" />
            </Button>

            <Button className="submit-button" onClick={submit}>
              <FormattedMessage id="autograder.modelAnswer.uploadModal.submitButton" />
            </Button>
          </div>
        )}

        <FileUpload
          accept={accept}
          onSuccess={fileUploaded}
          onComplete={fileUploadComplete}
          uploadType="projectFile"
          onBeforeUpload={onBeforeUpload}
          forwardedFileInputRef={fileInputRef}
          s3Prefix={s3Prefix}
        />
      </div>
    </Modal>
  );
};

FileUploadModal.propTypes = {
  onHide: PropTypes.func,
  onSubmit: PropTypes.func, // should call closeModalCallback to close FileUploadModal
  accept: PropTypes.string,
  s3Prefix: PropTypes.string,
};

export default FileUploadModal;
