import loadScript from 'load-script';

let loadedScripts = {};

export const dynamicJSLoad = (scriptId, src) => callback => {
  if (loadedScripts[scriptId]) {
    if (callback) callback();
  } else {
    loadScript(src, error => {
      if (error) throw error;

      loadedScripts[scriptId] = true;

      if (callback) callback();
    });
  }
};
