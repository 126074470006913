import axios from './axios';

export default class Step {
  static all(tenantId, tutorialId) {
    return axios.get(`/labs/tutorials/${tutorialId}/steps`).then(response => response.data);
  }

  static save(tenantId, step) {
    if (step.id) {
      return axios.put(`/labs/tutorials/${step.tutorial_id}/steps/${step.id}`, step).then(response => response.data);
    }
    return axios.post(`/labs/tutorials/${step.tutorial_id}/steps`, step).then(response => response.data);
  }

  static destroy(tenantId, step) {
    return axios.delete(`/labs/tutorials/${step.tutorial_id}/steps/${step.id}`).then(response => response.data);
  }

  static reorder(tenantId, stepIds, tutorialId) {
    return axios
      .put(`/labs/tutorials/${tutorialId}/reorder_steps`, { step_ids: stepIds })
      .then(response => response.data);
  }
}
