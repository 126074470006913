import React from 'react';
import PropTypes from 'prop-types';
import { each as _each } from 'lodash';
const loadScript = require('load-script');

const defaultScriptUrl = 'https://cdn.ckeditor.com/4.6.2/standard/ckeditor.js';

/**
 * @author codeslayer1
 * @description CKEditor component to render a CKEditor textarea with defined configs and all CKEditor events handler
 */
class CKEditor extends React.Component {
  constructor(props) {
    super(props);

    this.containerRef = React.createRef();
    // Bindings
    this.onLoad = this.onLoad.bind(this);

    // State initialization
    this.state = {
      isScriptLoaded: props.isScriptLoaded,
    };
  }

  // load ckeditor script as soon as component mounts if not already loaded
  componentDidMount() {
    if (!this.state.isScriptLoaded) {
      loadScript(this.props.scriptUrl, this.onLoad);
    } else {
      this.onLoad();
    }
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(props) {
    const editor = this.editorInstance;
    if (editor && editor.getData() !== props.content) {
      editor.setData(props.content);
    }
  }

  componentWillUnmount() {
    this.unmounting = true;
    if (this.editorInstance) {
      this.editorInstance.destroy();
    }
  }

  onLoad() {
    if (this.unmounting) return;

    this.setState({
      isScriptLoaded: true,
    });

    if (!window.CKEDITOR) {
      // eslint-disable-next-line no-console
      console.error('CKEditor not found');
      return;
    }

    if (this.props.inline) {
      this.editorInstance = window.CKEDITOR.inline(this.containerRef.current.firstChild, this.props.config);
    } else {
      this.editorInstance = window.CKEDITOR.appendTo(this.containerRef.current, this.props.config, this.props.content);
    }

    // Register listener for custom events if any
    _each(this.props.events, (eventHandler, event) => {
      this.editorInstance.on(event, eventHandler);
    });

    this.fixSpellcheckBug();
  }

  fixSpellcheckBug() {
    const changeEventHandle = this.props.events.change;

    if (changeEventHandle) {
      this.editorInstance.element.$.addEventListener('input', evt => {
        if (['insertReplacementText', 'insertText'].includes(evt.inputType)) {
          changeEventHandle({ editor: this.editorInstance });
        }
      });
    }
  }

  render() {
    return (
      <div ref={this.containerRef} className={this.props.activeClass}>
        {this.props.inline && <div contentEditable dangerouslySetInnerHTML={{ __html: this.props.content }} />}
      </div>
    );
  }
}

CKEditor.defaultProps = {
  content: '',
  config: {},
  isScriptLoaded: false,
  scriptUrl: defaultScriptUrl,
  activeClass: '',
  events: {},
  inline: false,
};

CKEditor.propTypes = {
  content: PropTypes.any,
  config: PropTypes.object,
  isScriptLoaded: PropTypes.bool,
  scriptUrl: PropTypes.string,
  activeClass: PropTypes.string,
  events: PropTypes.object,
  inline: PropTypes.bool,
};

export default CKEditor;
