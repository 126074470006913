import { assign as _assign, cloneDeep as _cloneDeep, each as _each } from 'lodash';
import { mergeEntities } from '../utils/utils';

const INITIAL_STATE = {
  assignmentProgresses: {},
  assignments: {},
  blockProgresses: {},
  blocks: {},
  bootcamps: {},
  bootcampInstanceAssignments: [],
  bootcampInstanceQuizzes: [],
  bootcampInstanceSections: [],
  schools: [],
  cohorts: {},
  partnerTypes: [],
  creditTypes: [],
  psSemesters: [],
  modalities: [],
  componentProgresses: {},
  components: {},
  criteria: {},
  enrollments: {},
  lessonProgresses: {},
  lessons: {},
  ltiAssignmentProgresses: {},
  pathProgresses: {},
  paths: {},
  programCohorts: {},
  questionProgresses: {},
  questions: {},
  quizProgresses: {},
  quizzes: {},
  sectionProgresses: {},
  sections: {},
  stepProgresses: {},
  steps: {},
  tutorialProgresses: {},
  tutorials: {},
  unitProgresses: {},
  units: {},
  users: {},
  appStreamAppSites: {},
};

const entitiesMerge = (state, entities) => {
  const newState = _assign({}, state);
  _each(entities, (typedEntities, entityType) => {
    newState[entityType] = newState[entityType] ? _assign({}, state[entityType]) : {};
    _each(typedEntities, (_entity, entityId) => {
      newState[entityType][entityId] = newState[entityType][entityId] ? _cloneDeep(newState[entityType][entityId]) : {};
    });
  });

  return mergeEntities(newState, entities);
};

const entitiesRemove = (state, entities) => {
  const newState = _assign({}, state);
  _each(entities, (typedEntities, entityType) => {
    newState[entityType] = newState[entityType] ? _assign({}, state[entityType]) : {};
    _each(typedEntities, (_entity, entityId) => {
      delete newState[entityType][entityId];
    });
  });
  return newState;
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    default: {
      if (action.payload && (action.payload.entities || action.payload.entitiesX)) {
        let nextState = state;

        if (action.payload.entities) {
          nextState = entitiesMerge(nextState, action.payload.entities);
        }

        if (action.payload.entitiesX) {
          nextState = entitiesRemove(nextState, action.payload.entitiesX);
        }

        return nextState;
      }
    }
  }

  return state;
};
