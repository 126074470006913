import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import classnames from 'classnames';

const TooltipButton = ({ className, onClick, messageId, icon, isDisabled }) => (
  <div className="tooltip-button">
    <button
      type="button"
      className={classnames({ disabled: isDisabled }, 'tooltip-button__button', className)}
      onClick={onClick}>
      <span
        className={classnames({
          'tooltip-button__label': true,
          disabled: isDisabled,
        })}>
        <FormattedMessage id={messageId} />
      </span>
      <div className="tooltip-button__icon">{icon}</div>
    </button>
  </div>
);

TooltipButton.propTypes = {
  messageId: PropTypes.string,
  className: PropTypes.string,
  icon: PropTypes.node,
  onClick: PropTypes.func,
  isDisabled: PropTypes.bool,
};

export default TooltipButton;
