import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import AddButton from './AddButton';
import { toastException } from '../../../utils/toastHelper';
import { savePart } from '../../../actions/partActions';
import { selectIsEditMode } from '../../../selectors/contextSelectors';

export default function AddPart({ lesson, seqNum }) {
  const dispatch = useDispatch();
  const isEditMode = useSelector(selectIsEditMode);

  if (!isEditMode) return '';

  const handleClick = async () => {
    try {
      await savePart({ tenant_id: lesson.tenant_id, lesson_id: lesson.id, seq_num: seqNum })(dispatch);
    } catch (err) {
      toastException(err, 'Unable to save part');
    }
  };
  return <AddButton onClick={handleClick} stretched />;
}

AddPart.propTypes = {
  lesson: PropTypes.object.isRequired,
  seqNum: PropTypes.number,
};

AddPart.defaultProps = {
  seqNum: 1,
};
