import { difference as _difference, isEmpty as _isEmpty } from 'lodash';
import React, { Component } from 'react';
import EnrollModalContainer from '../../Admin/EnrollModalContainer';
import intl from '../../../utils/intl';
import { toastSuccess } from '../../../utils/toastHelper';
import { FormattedMessage } from 'react-intl';
import User from '../../../api/user';

export default class EnrollUser extends Component {
  constructor(props) {
    super(props);

    this.state = {
      userIds: [],
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (_isEmpty(this.state.userIds) && this.state.userIds !== nextProps.userIds) {
      this.setState({ userIds: nextProps.userIds });
    }
  }

  async componentDidMount() {
    const { cohort, getCohortUsers, role } = this.props;

    const users = await getCohortUsers(cohort.id, role);
    this.setState({
      userIds: users.map(c => c.id),
    });
  }

  handleEnroll = async user => {
    const { cohort, addUser, removeUser, mode, role } = this.props;
    let userIds = [...this.state.userIds];

    if (mode === 'enroll') {
      await addUser(cohort.id, user.id, role);
      toastSuccess(intl.formatMessage({ id: `cohort.${role}.messages.added` }));
      userIds.push(user.id);
    } else {
      await removeUser(cohort.id, user.id, role);
      toastSuccess(intl.formatMessage({ id: `cohort.${role}.messages.removed` }));
      userIds = _difference(this.state.userIds, [user.id]);
    }

    this.setState({ userIds });
  };

  getTitle = () => {
    const { mode, role } = this.props;

    if (role === User.COACH) {
      return mode === 'enroll' ? (
        <FormattedMessage id="cohort.enrollCoaches" />
      ) : (
        <FormattedMessage id="cohort.unenrollCoaches" />
      );
    }
  };

  render() {
    const { mode, role } = this.props;
    const { userIds } = this.state;

    return (
      <EnrollModalContainer
        userRole={role}
        onEnroll={this.handleEnroll}
        userIds={userIds}
        mode={mode}
        headerTitle={this.getTitle()}
        {...this.props}
      />
    );
  }
}
