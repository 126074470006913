import { Component } from 'react';
import PropTypes from 'prop-types';

export default class Submittable extends Component {
  constructor(props) {
    super(props);

    this.state = { isSubmitting: false };
  }

  handleSubmit = event => {
    event.preventDefault();
    if (this.state.isSubmitting) return;

    this.setState({ isSubmitting: true });
    try {
      this.props.onSubmit();
    } finally {
      this.setState({ isSubmitting: false });
    }
  };

  render() {
    return this.props.render(this.state.isSubmitting, this.handleSubmit);
  }
}

Submittable.propTypes = {
  render: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};
