import { COMPONENT_COACH } from '../../actions/constants';

export const selectCurrentCohortId = bootcampId => {
  const urlCohortId = new URLSearchParams(window.location.search).get('cohortId');
  if (urlCohortId) {
    return urlCohortId;
  }

  const cohortIdsByBootcamp = JSON.parse(localStorage.getItem(COMPONENT_COACH.COACH_COHORT_IDS) || '{}');
  return cohortIdsByBootcamp[bootcampId];
};
