import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Errors from '../../Utils/Errors';
import Modal2 from '../../library/Modal2';
import CustomInput from '../../Utils/CustomInput';
import intl from '../../../utils/intl';
import useSaveBlock from '../../../hooks/useSaveBlock';
import Block from '../../../api/block';
import MultipleEditor from '../../Blocks/MultipleEditor';

export default function EditEmbedModal({ block, onHide }) {
  const defaults = { height: '500', width: '100%' };

  const updatedBlock = { url: '', ...defaults, ...block };

  const { blockState, handleChange, errors, saveBlock } = useSaveBlock(updatedBlock, Block.EMBED);

  const handleSubmit = e => {
    e.preventDefault();
    saveBlock(blockState, true, onHide);
  };

  const handleInputChange = name => handleChange(name, false, false);

  return (
    <Modal2 size="medium" title={<FormattedMessage id="block.form.embed" />} onHide={onHide} onOk={handleSubmit}>
      <MultipleEditor blockId={blockState.id} forceEditing>
        <Errors errors={errors} />

        <CustomInput
          name="title"
          onChange={handleInputChange('title')}
          value={blockState.title}
          label={intl.formatMessage({ id: 'block.form.title' })}
        />
        <CustomInput
          name="url"
          onChange={handleInputChange('url')}
          value={blockState.url}
          label={intl.formatMessage({ id: 'block.form.url' })}
        />
        <div className="fields-wrapper">
          <CustomInput
            name="width"
            onChange={handleInputChange('width')}
            value={blockState.width}
            label={intl.formatMessage({ id: 'block.form.width' })}
          />

          <CustomInput
            name="height"
            onChange={handleInputChange('height')}
            value={blockState.height}
            label={intl.formatMessage({ id: 'block.form.height' })}
          />
        </div>
      </MultipleEditor>
    </Modal2>
  );
}

EditEmbedModal.propTypes = {
  block: PropTypes.object.isRequired,
  onHide: PropTypes.func,
};
