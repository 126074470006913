import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Button } from 'antd';
import { FormattedMessage } from 'react-intl';
import sanitizeHtml from 'sanitize-html';

import intl from '../../../utils/intl';

const AsanaEmailModal = ({ onHide, html }) => {
  const sanitizedHTML = sanitizeHtml(html);

  const back = intl.formatMessage({ id: 'common.back' });

  return (
    <Modal onCancel={onHide} visible className="asana-email-modal" footer={null}>
      <div className="asana-email">
        <div dangerouslySetInnerHTML={{ __html: sanitizedHTML }} />
      </div>
      <div className="back">
        <Button onClick={onHide}>{back}</Button>
      </div>
    </Modal>
  );
};

AsanaEmailModal.propTypes = {
  onHide: PropTypes.func.isRequired,
  html: PropTypes.string.isRequired,
};

export default AsanaEmailModal;
