import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import Modal2 from '../library/Modal2';
import intl from '../../utils/intl';
import VideoPlayer from '../Utils/VideoPlayer';
import Unit from '../../api/unit';

export default class BootcampIntroModal extends Component {
  handleClick = () => {
    const { resumeUrl, history } = this.props;

    this.props.onHide();
    history.push(resumeUrl);
  };

  renderIntroModal = () => {
    const { bootcamp } = this.props;

    return (
      <>
        <div className="modal-dialog-body modal-dialog-body--medium">
          <p>
            <FormattedMessage id="introModal.content" />
          </p>
        </div>

        <div className="modal-dialog-body modal-dialog-body--full">
          <div className="media-content-wrapper media-content-wrapper--isClear">
            <div className="media-content">
              <div className="media-content__element">
                {bootcamp.intro_video_url && <VideoPlayer src={bootcamp.intro_video_url} />}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  renderReturningModal = () => {
    const { currentProgress } = this.props;
    const { section, sectionIndex, unit, unitIndex, component } = currentProgress;

    let returningUnitHeading = 'returningModal.moduleHeading';
    if (unit.unit_type === Unit.PROJECT) {
      returningUnitHeading = 'returningModal.projectHeading';
    } else if (unit.unit_type === Unit.GRADED_QUIZ) {
      returningUnitHeading = 'returningModal.gradedQuizHeading';
    }

    return (
      <div className="modal-dialog-body modal-dialog-body--medium">
        <p>
          <FormattedMessage id="returningModal.content" />
        </p>

        <div className="lesson-item">
          <div className="lesson-item__lesson-number">{(sectionIndex || 0) + 1}</div>
          <div className="lesson-item__content">
            <span className="lesson-item__heading">
              <FormattedMessage id="returningModal.sectionHeading" values={{ index: (sectionIndex || 0) + 1 }} />
            </span>
            <span className="lesson-item__subheading  lesson-item__subheading--uppercase">{section.title}</span>
          </div>
        </div>

        <div className="lesson-item">
          <div className="lesson-item__lesson-number">{(unitIndex || 0) + 1}</div>
          <div className="lesson-item__content">
            <span className="lesson-item__heading">
              <FormattedMessage id={returningUnitHeading} values={{ index: (unitIndex || 0) + 1 }} />
            </span>
            <span className="lesson-item__subheading  lesson-item__subheading--uppercase">
              {(component && component.title) || unit.title}
            </span>
          </div>
        </div>
      </div>
    );
  };

  render() {
    const { currentUser, showIntroModal, showReturningModal } = this.props;
    const headerMessageId = showIntroModal ? 'introModal.heading' : 'returningModal.heading';

    return (
      <Modal2
        onHide={this.props.onHide}
        bodyClass="modal-dialog-body--full"
        showCancel={false}
        title={intl.formatMessage({ id: headerMessageId }, { name: currentUser.name })}
        footerClass="modal-dialog-footer--centered"
        footerContent={
          <button
            className="button-standard button-standard--yellow button-standard--medium"
            onClick={this.handleClick}>
            <FormattedMessage id={showIntroModal ? 'introModal.startButton' : 'returningModal.startButton'} />
          </button>
        }>
        {showIntroModal && this.renderIntroModal()}
        {showReturningModal && this.renderReturningModal()}
      </Modal2>
    );
  }
}
