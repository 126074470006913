import React, { Component } from 'react';
import { connect } from 'react-redux';
import Icons from '../../Utils/Icons';
import { setSearchText } from '../../../actions/bootcampActions';
import intl from '../../../utils/intl';

class BootcampSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchText: this.props.bootcamp.searchText,
    };
  }

  searchChanged = e => {
    this.setState({ searchText: e.target.value });
  };

  handleSearch = e => {
    e.preventDefault();
    this.props.setSearchText(this.state.searchText);
  };

  render() {
    const { searchText } = this.state;

    return (
      <div className="grid-row">
        <div className="sm-12">
          <div className="courses-list-page-search-wrapper">
            <div className="courses-list-page-search-icon">
              <Icons.Search />
            </div>

            <form className="filters" onSubmit={this.handleSearch} style={{ width: '100%' }}>
              <label htmlFor="search" className="visually-hidden">
                {intl.formatMessage({ id: 'bootcamp.searchPlaceholder' })}
              </label>
              <input
                className="courses-list-page-search-input"
                id="search"
                type="text"
                name="search"
                placeholder={intl.formatMessage({ id: 'bootcamp.searchPlaceholder' })}
                onChange={this.searchChanged}
                value={searchText || ''}
              />
            </form>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  bootcamp: state.bootcamp,
});

export default connect(mapStateToProps, { setSearchText })(BootcampSearch);
