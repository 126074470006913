import { COMPONENT_LESSON } from '../../actions/constants';

const INITIAL_STATE = {
  isProgressExpanded: true,
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case COMPONENT_LESSON.SET_PROGRESS_EXPANDED: {
      const { isProgressExpanded } = action.payload;
      return {
        ...state,
        isProgressExpanded,
      };
    }
    default:
      return state;
  }
}
