import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { CalendarOutlined, UserOutlined, FileTextOutlined } from '@ant-design/icons';
import moment from 'moment';

import { Link, useLocation } from 'react-router-dom';
import DashboardModalContext from '../../../../v2/Nav/Coach/context/DashboardModalContext';
import { getCohortDashboardPath } from '../../../../../helpers/pathHelper';
import { setCurrentCohortId } from '../../../../../actions/components/coachActions';

// For V1, this card is used to bring instructor to assignments page
const InfoCard = ({ cohort }) => {
  const {
    start_date: startDate,
    end_date: endDate,
    num_enrolled: numStudents,
    num_projects_submitted: numAssignmentsSubmitted,
  } = cohort;
  const cohortDates = `${moment(new Date(startDate)).format('MMM D YYYY')} - ${moment(new Date(endDate)).format(
    'MMM D YYYY',
  )}`;

  const { onModalHide } = useContext(DashboardModalContext);
  const location = useLocation();

  const getViewCohortUrl = () => {
    const queryParams = new URLSearchParams(location.search);
    const tab = queryParams.get('tab');

    setCurrentCohortId(cohort.id, cohort.bootcamp_id);

    return getCohortDashboardPath(cohort.id, { tab });
  };

  return (
    <div className="cohort-card info-card" data-testid="info-card">
      <div className="info-card-body">
        <div className="cohort-info">
          <CalendarOutlined />
          <span>{cohortDates}</span>
        </div>

        <div className="cohort-info">
          <UserOutlined />
          <FormattedMessage id="cohort.numTotalStudents" values={{ count: numStudents }} />
        </div>

        <div className="cohort-info">
          <FileTextOutlined />
          <FormattedMessage id="cohort.numAssignmentsSubmitted" values={{ count: numAssignmentsSubmitted }} />
        </div>
      </div>

      <div className="card-buttons" onClick={onModalHide}>
        <Link to={getViewCohortUrl()}>
          <FormattedMessage id="cohort.viewCohort" />
        </Link>
      </div>
    </div>
  );
};

InfoCard.propTypes = {
  cohort: PropTypes.object.isRequired,
};

export default InfoCard;
