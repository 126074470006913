import React from 'react';
import PropTypes from 'prop-types';
import Loading from '../Utils/Loading';
import QuestionSingleSelect from './QuestionSingleSelect';
import QuestionMultiSelect from './QuestionMultiSelect';
import Question from '../../api/question';
import { contentForStudent } from '../../helpers/blockHelper';
import RTEContent from '../../helpers/contentMathjaxHelper';

const QuizQuestion = ({
  question,
  renderQuestionsNavigation,
  renderNext,
  isLastQuestion,
  questionProgressesEnabled,
  canCoach,
  submitQuestionProgress,
  questionProgress,
  afterSubmit,
}) => {
  const handleSubmitChoices = choices => {
    if (isLastQuestion && !questionProgressesEnabled) return;

    submitQProgress(choices, '');
  };

  const submitQProgress = (choices, answer) => {
    const submit = canCoach || !questionProgressesEnabled ? async () => {} : submitQuestionProgress;

    submit(questionProgress, choices, answer).then(afterSubmit);
  };

  const getQuestionComponent = () =>
    ({
      [Question.SINGLE_SELECT]: QuestionSingleSelect,
      [Question.MULTI_SELECT]: QuestionMultiSelect,
    }[question.question_type]);

  const waitingForQuestionProgress = () => !canCoach && questionProgressesEnabled && !questionProgress;

  const QuestionComponent = getQuestionComponent();

  return (
    <div className="graded-question">
      <div className="knowledge-check-title-wrapper">
        <div className="knowledge-check-title">
          <RTEContent content={contentForStudent(question.prompt)} />
        </div>
      </div>

      <form aria-label="answer">
        {waitingForQuestionProgress() ? (
          <Loading />
        ) : (
          <QuestionComponent
            question={question}
            key={question.id}
            questionProgress={questionProgress}
            submitChoices={handleSubmitChoices}
            renderNext={renderNext}
            renderQuestionsNavigation={() => renderQuestionsNavigation(questionProgress?.id)}
            isLastQuestion={isLastQuestion}
            afterSubmit={afterSubmit}
          />
        )}
      </form>
    </div>
  );
};

QuizQuestion.propTypes = {
  question: PropTypes.object.isRequired,
  questionProgress: PropTypes.object,
  questionProgressesEnabled: PropTypes.bool,
  canCoach: PropTypes.bool.isRequired,
  afterSubmit: PropTypes.func,
  submitQuestionProgress: PropTypes.func.isRequired,
  renderQuestionsNavigation: PropTypes.func,
  renderNext: PropTypes.func,
  isLastQuestion: PropTypes.bool,
};

QuizQuestion.defaultProps = {
  questionProgressesEnabled: true,
};

export default QuizQuestion;
