import { connect } from 'react-redux';
import UnitTile from './UnitTile';
import { selectUnitComponents } from '../../../../selectors/unitSelectors';

const mapStateToProps = (state, ownProps) => {
  const { unit } = ownProps;

  return {
    components: selectUnitComponents(state, unit.id),
  };
};

export default connect(mapStateToProps)(UnitTile);
