import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import BootcampCohortSelectorContainer from '../Cohorts/BootcampCohortSelectorContainer';
import { isPrintMode } from '../../helpers/printHelper';
import AjaxDownloadLink from '../Utils/AjaxDownloadLink';
import CSVDownloadButton from './CSVDownloadButton';
import CohortStudentsContainer from './CohortStudentsContainer';
import CohortAssignments from './CohortAssignments';

const CohortReportsContent = ({ cohort }) => {
  const [currentCohort, setCurrentCohort] = useState(cohort);
  const [currentBootcampTitle, setCurrentBootcampTitle] = useState();

  useEffect(() => {
    setCurrentCohort(cohort);
  }, [cohort]);

  /* eslint-disable indent */
  const downloadLink = currentCohort ? `/reports/cohort/${currentCohort.id}?print=1` : '';

  const handleCohortChange = (newCohort, bootcamp) => {
    if (newCohort) {
      setCurrentCohort(newCohort);
      setCurrentBootcampTitle(bootcamp?.title);
    }
  };

  return (
    <>
      <div className="grid-row">
        <BootcampCohortSelectorContainer cohortId={currentCohort?.id} onCohortChange={handleCohortChange} />
        {!isPrintMode() && (
          <>
            <div className="sm-2 download-button-wrapper">
              <AjaxDownloadLink href={downloadLink} className="button-standard button-standard--grey">
                <FormattedMessage id="cohortReports.download.pdf" />
              </AjaxDownloadLink>
            </div>
            <div className="sm-2 download-button-wrapper">
              <CSVDownloadButton cohort={currentCohort} currentBootcampTitle={currentBootcampTitle} />
            </div>
          </>
        )}
      </div>
      {currentCohort && (
        <div className="cohort-reports">
          <CohortStudentsContainer cohortId={currentCohort.id} />
          <CohortAssignments cohortId={currentCohort.id} />
        </div>
      )}
    </>
  );
};

CohortReportsContent.propTypes = {
  cohort: PropTypes.object,
};

export default CohortReportsContent;
