import { isEmpty as _isEmpty, map as _map } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import Modal2 from '../../library/Modal2';
import { formatDate } from '../../../utils/utils';
import intl from '../../../utils/intl';
import { forUser } from '../../../actions/cohortActions';
import { fetchSchools } from '../../../actions/schoolActions';

const UserCohortsModal = ({ user, onHide }) => {
  const [cohorts, setCohorts] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    // TODO refactor after app collapse! Do not join table at FE!
    const fetch = async () => {
      const data = await Promise.all([fetchSchools()(dispatch), forUser(user.id)(dispatch)]);
      const [schools, cohorts] = data;
      const cohortWithSchools = cohorts.map(cohort => {
        const school = schools.find(school => school.id === cohort.school_id);
        const schoolName = school?.name;
        return {
          ...cohort,
          schoolName,
        };
      });

      setCohorts(cohortWithSchools);
      setIsLoading(false);
    };
    fetch();
  }, []);

  const renderCohorts = () => {
    if (_isEmpty(cohorts)) {
      return (
        <div style={{ textAlign: 'center' }}>
          <FormattedMessage id="user.messages.noCohortsFound" />
        </div>
      );
    }

    return (
      <table className="cohorts-for-user">
        <thead>
          <tr>
            <th scope="col">
              <FormattedMessage id="cohort.form.bootcamp" />{' '}
            </th>
            <th scope="col">
              <FormattedMessage id="cohort.form.school" />{' '}
            </th>
            <th scope="col">
              <FormattedMessage id="cohort.form.location" />{' '}
            </th>
            <th scope="col">
              <FormattedMessage id="cohort.form.dates" />{' '}
            </th>
          </tr>
        </thead>
        <tbody>
          {_map(cohorts, (cohort, index) => (
            <tr key={index}>
              <th scope="row" className="course-name">
                <strong>{cohort.bootcamp_title}</strong>
                <br />
                {cohort.bootcamp_version}
              </th>
              <td>{cohort.schoolName}</td>
              <td>{cohort.location}</td>
              <td>
                <span className="date">{formatDate(cohort.start_date)}</span>
                <span className="date">{formatDate(cohort.end_date)}</span>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  return isLoading ? null : (
    <Modal2
      size="big"
      bodyClass=" "
      className="user-cohorts-modal"
      onHide={onHide}
      showCancel={false}
      title={intl.formatMessage({ id: 'user.viewCohorts' })}>
      {renderCohorts()}
    </Modal2>
  );
};

UserCohortsModal.propTypes = {
  user: PropTypes.object,
  cohortsForUser: PropTypes.func,
  onHide: PropTypes.func,
};

export default UserCohortsModal;
