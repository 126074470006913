import { connect } from 'react-redux';
import { getEnrollmentsForUser, fetchEnrollment } from '../../actions/enrollmentActions';
import StudentProfile from './StudentProfile';
import { selectCurrentUser } from '../../selectors/authSelectors';
import { selectBootcampEntities } from '../../selectors/bootcampSelectors';
import { selectCohortEntities } from '../../selectors/cohortSelectors';
import { selectEnrollmentsForUser } from '../../selectors/enrollmentSelectors';

const mapStateToProps = (state, ownProps) => {
  const { user } = ownProps;
  const enrollments = selectEnrollmentsForUser(state, user.id);
  const bootcampEntities = selectBootcampEntities(state);
  const cohortEntities = selectCohortEntities(state);

  return {
    bootcampEntities,
    cohortEntities,
    currentUser: selectCurrentUser(state),
    enrollments,
  };
};

export default connect(mapStateToProps, {
  fetchEnrollment,
  getEnrollmentsForUser,
})(StudentProfile);
