import React, { Component } from 'react';
import PropTypes from 'prop-types';
import BlockHeader from '../Utils/BlockHeader';
import RTEContent from '../../helpers/contentMathjaxHelper';

export default class BlockHtmlShow extends Component {
  render() {
    const { block, blockLabel, headingClass, blockHeaderMessageId, blockHeaderIcon } = this.props;

    return (
      <>
        <div className="section">
          {blockLabel}
          {block.title && <RTEContent content={block.title} Tag="h2" classSelector={headingClass} />}
          <RTEContent content={block.content} Tag="div" />
          {this.props.children}

          <BlockHeader messageId={blockHeaderMessageId} icon={blockHeaderIcon} />
        </div>
      </>
    );
  }
}

BlockHtmlShow.propTypes = {
  block: PropTypes.object.isRequired,
  blockLabel: PropTypes.any,
  headingClass: PropTypes.string,
};
