import { isEqual as _isEqual, filter as _filter } from 'lodash';
import { useState } from 'react';

export default function useChannelHelper() {
  const [hasMultipleUsersByChannel, setHasMultipleUsersByChannel] = useState(false);
  const [channelByName, setChannelByName] = useState({});

  const updateMultipleUsers = (channel, channelName) => {
    setHasMultipleUsersByChannel(prevState => ({
      ...prevState,
      [channelName]: channel && channel.members && channel.members.length > 1,
    }));
  };

  const handleChannelUpdate = (channel, channelName) => {
    setChannelByName(prevState => ({
      ...prevState,
      [channelName]: channel,
    }));

    updateMultipleUsers(channel, channelName);
  };

  const handleChannelMemberJoined = (newMember, name) => {
    setChannelByName(prevState => {
      const channel = prevState[name];
      const updatedChannel = {
        ...channel,
        members: [...channel.members, newMember],
      };

      updateMultipleUsers(updatedChannel, name);

      return {
        ...prevState,
        [name]: updatedChannel,
      };
    });
  };

  const handleChannelMemberLeft = (oldMember, name) => {
    setChannelByName(prevState => {
      const channel = prevState[name];
      const updatedChannel = {
        ...channel,
        members: _filter(channel.members, member => !_isEqual(member, oldMember)),
      };

      updateMultipleUsers(updatedChannel, name);

      return {
        ...prevState,
        [name]: updatedChannel,
      };
    });
  };

  return {
    hasMultipleUsersByChannel,
    channelByName,
    handleChannelUpdate,
    handleChannelMemberJoined,
    handleChannelMemberLeft,
  };
}
