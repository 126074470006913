import React, { useState } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import CKEditor from '../Utils/CKEditor';
import intl from '../../utils/intl';
import { contentForStudent } from '../../helpers/blockHelper';
import BlockConflictModal from '../Author/Blocks/BlockConflictModal/BlockConflictModal';
import RTEContent from '../../helpers/contentMathjaxHelper';
import MultipleEditor from '../Blocks/MultipleEditor';

/* eslint-disable complexity */

const QuizTabs = ({
  block,
  handleConflictSave,
  contentRef,
  conflict,
  handleChange,
  quiz,
  isEditMode,
  numQuestions,
  children,
  questionIndex,
  renderTitle,
}) => {
  const [activeTab, setActiveTab] = useState(questionIndex === undefined || questionIndex > 0 ? 1 : 0);

  const handleTabSelect = newTab => {
    setActiveTab(newTab);
  };

  const numQuestionsString = typeof numQuestions === 'number' ? String(numQuestions) : '-';
  const currentQuestion = questionIndex < numQuestions ? questionIndex + 1 : numQuestions;
  const questionIndexString = typeof numQuestions === 'number' ? String(currentQuestion) : '-';

  return (
    <>
      <Tabs
        selectedTabClassName="tabs__item--active"
        selectedTabPanelClassName="active"
        forceRenderTabPanel
        selectedIndex={activeTab}
        onSelect={handleTabSelect}>
        <div className="knowledge-check__body--tabs">
          <TabList className="tabs">
            <Tab className={classnames('tabs__item')}>
              <FormattedMessage id="quiz.instructions" />
            </Tab>
            <Tab className={classnames('tabs__item')} data-testid="question-progress">
              <FormattedMessage
                id="quiz.question"
                values={{ numQuestionsString, questionIndex: questionIndexString }}
              />
            </Tab>
          </TabList>
          {renderTitle && renderTitle()}
        </div>
        <TabPanel>
          <div className="knowledge-check-title-wrapper">
            <div className="knowledge-check-title">
              {isEditMode && (
                <MultipleEditor blockId={block.id}>
                  <CKEditor
                    inline
                    content={block.content || ''}
                    activeClass="input-text"
                    onChange={handleChange('content')}
                    config={{ title: intl.formatMessage({ id: 'quiz.instructions' }) }}
                    ref={contentRef}
                  />
                </MultipleEditor>
              )}
              {!isEditMode && <RTEContent content={contentForStudent(quiz.description)} />}
            </div>
          </div>
          <div className="answer-buttons">
            <button
              type="button"
              onClick={() => handleTabSelect(1)}
              className="button-standard  button-standard--blue-9 button-standard--short">
              {isEditMode ? <FormattedMessage id="quiz.editQuestions" /> : <FormattedMessage id="quiz.beginQuiz" />}
            </button>
          </div>
        </TabPanel>
        <TabPanel>{children}</TabPanel>
      </Tabs>

      {conflict && conflict.visible && (
        <BlockConflictModal
          remoteBlock={conflict.remote}
          currentBlock={conflict.current}
          onOk={handleConflictSave}
          onCancel={conflict.hide}
        />
      )}
    </>
  );
};
/* eslint-enable complexity */
QuizTabs.propTypes = {
  block: PropTypes.object,
  quiz: PropTypes.object,
  isEditMode: PropTypes.bool.isRequired,
  numQuestions: PropTypes.number,
  children: PropTypes.node,

  // the following props are all provided from the parent component by the
  // useSaveBlock hook, and are required when isEditMode is true
  handleConflictSave: PropTypes.func,
  contentRef: PropTypes.shape({ current: PropTypes.object }),
  conflict: PropTypes.object,
  handleChange: PropTypes.func,
  questionIndex: PropTypes.number,
  title: PropTypes.string,
};

export default QuizTabs;
