import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Draggable from 'react-draggable';
import { Resizable } from 're-resizable';

const Popup = ({
  containerStyle = {},
  className,
  children,
  initPosition = { x: 0, y: 0 },
  dragHandle = 'header',
  stylePosition = 'absolute',
  isResizable = true,
  bounds = 'body',
  minWidth = 100,
  minHeight = 100,
  maxWidth = '100%',
  maxHeight = '95vh',
}) => {
  const [position, setPosition] = useState(initPosition);
  useEffect(() => {
    setPosition(initPosition);
  }, [initPosition]);

  const handleDragStop = (e, data) => {
    setPosition({ ...position, x: data.x, y: data.y });
  };

  const onResizeStop = (e, direction, ref, d) => {
    let { width, height } = position;
    width += d.width;
    height += d.height;
    setPosition({ ...position, width, height });
  };
  const resizableProps = () => {
    if (!isResizable) {
      return {};
    }
    return {
      onResizeStop,
      bounds,
      size: position,
      enable: {
        top: true,
        right: true,
        bottom: true,
        left: true,
        topRight: true,
        bottomRight: true,
        bottomLeft: true,
        topLeft: true,
      },
      minWidth,
      minHeight,
      maxWidth,
      maxHeight,
    };
  };
  return (
    <Draggable handle={dragHandle} position={position} onStop={handleDragStop} bounds={bounds}>
      <Resizable className={className} style={{ ...containerStyle, position: stylePosition }} {...resizableProps()}>
        {children}
      </Resizable>
    </Draggable>
  );
};

Popup.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  initPosition: PropTypes.object,
  stylePosition: PropTypes.string,
  isFloating: PropTypes.bool,
  dragHandle: PropTypes.string,
  isResizable: PropTypes.bool,
  bounds: PropTypes.string,
  containerStyle: PropTypes.object,
  minWidth: PropTypes.number,
  minHeight: PropTypes.number,
  maxWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  maxHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default Popup;
