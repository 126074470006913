import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import 'react-image-lightbox/style.css';
import { validateFilename } from 'freezeframe/src/utils';
import Popup from './Popup';
import Icons from '../Icons';

const HEADER_HEIGHT = 32;

const ImagePopup = ({ src, alt, onHide }) => {
  const [position, setPosition] = useState();
  const imgRef = useRef(null);
  useEffect(() => {
    const img = imgRef.current;
    if (!img) return;
    setTimeout(() => {
      if (img.dataset.ff) {
        img.parentElement.focus();
      } else {
        img.focus();
      }
    }, 100); // give some delay for freezeframe to initialize
  }, []);

  const isGif = validateFilename(src);
  // Don't close when clicking gif as CLICK is a trigger for freezeframe
  const imgOnClick = isGif ? null : onHide;

  const handleImageOnLoad = ({ target }) => {
    const aspectRatio = target.clientWidth / target.clientHeight;
    const halfHeight = window.screen.height / 2;
    let width = window.screen.width / 2;
    let height = width / aspectRatio;
    if (height > halfHeight) {
      height = halfHeight;
      width = (height - HEADER_HEIGHT) * aspectRatio;
    }
    setPosition({ x: 0, y: 0, width, height });
    const container = target.parentElement;
    container.parentElement.style.visibility = 'visible';
  };

  const onContainerClick = e => {
    if (e.target === e.currentTarget) {
      onHide();
    }
  };

  return (
    <div
      className="main-image-popup-container"
      onClick={onContainerClick}
      onKeyDown={onContainerClick}
      role="button"
      tabIndex="0">
      <Popup className="image-popup" initPosition={position} stylePosition="fixed">
        <header className="popup-header">
          <button type="button" className="icon close small black" onClick={onHide} aria-label="Close Image">
            <Icons.Close />
          </button>
        </header>
        <section className="content popup-section">
          {/* eslint-disable-next-line max-len */}
          {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions, jsx-a11y/click-events-have-key-events */}
          <img
            ref={imgRef}
            alt={alt}
            src={src}
            onClick={imgOnClick}
            // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
            tabIndex="0"
            onLoad={handleImageOnLoad}
          />
        </section>
      </Popup>
    </div>
  );
};

ImagePopup.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string,
  onHide: PropTypes.func,
};

export default ImagePopup;
