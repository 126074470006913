import React from 'react';
import { useParams, Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import useCurrentUser from '../../../hooks/useCurrentUser';
import { getCoursePathByIds } from '../../../helpers/pathHelper';

export default function SectionFooter() {
  const { bootcampId, pathId } = useParams();
  const { currentUser } = useCurrentUser();
  const bootcampUrl = getCoursePathByIds(currentUser, bootcampId, pathId);

  return (
    <>
      <div className="subject-congrats__next-step">
        <div className="subject-congrats__actions">
          <div className="subject-congrats__actions-wrapper">
            <>
              <h5 className="subject-congrats__actions-message">
                <FormattedMessage id="completionModal.footerMessage.sectionCompleted" />
              </h5>
            </>
          </div>
        </div>
      </div>
      <Link to={bootcampUrl} className="subject-congrats__actions-link">
        <FormattedMessage id="nav.backToBootcamp" />
      </Link>
    </>
  );
}
