import { getLocationPath } from './dataSourceHelpers';
import { getDataFromRangeAndEval } from './evalHelpers';

const evalChartDataCellRange = ({ data, location, property, operator, value }) => {
  const chart = getLocationPath(data, location);
  if (!chart) return false;

  const sheet = getLocationPath(data, location.slice(0, -1));
  if (sheet.dataset.length) {
    chart.rows = sheet.dataset[0].rows;
  }

  return getDataFromRangeAndEval({
    path: [chart],
    location,
    property,
    operator,
    value,
  });
};

export default evalChartDataCellRange;
