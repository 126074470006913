import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import Loading from '../Utils/Loading';
import ShowLesson from '../GradedQuiz/ShowLesson';
import { setPageTitle } from '../../helpers/pageHelper';
import ShowHeader from '../GradedQuiz/ShowHeader';
import useAnalytics from '../../hooks/useAnalytics';
import useCurrentGradedQuiz from '../../hooks/useCurrentGradedQuiz';
import useCurrentGradedQuizProgress from '../../hooks/useCurrentGradedQuizProgress';
import useFetchCurrentEnrollment from '../../hooks/useFetchCurrentEnrollment';
import useFetchEnrollmentForCohort from '../../hooks/useFetchEnrollmentForCohort';
import { startUnitProgress } from '../../actions/unitProgressActions';

export default function GradedQuizShow() {
  const dispatch = useDispatch();

  const { cohort } = useFetchCurrentEnrollment();
  useFetchEnrollmentForCohort(cohort?.id); // need to fetch progresses that weren't returned by current enrollment
  const { bootcamp, section, unit, lesson, parts, blocks } = useCurrentGradedQuiz();
  const { unitProgress } = useCurrentGradedQuizProgress();

  useAnalytics('View Graded Quiz', {
    bootcamp,
    cohort,
    section,
    module: unit,
  });

  useEffect(() => {
    if (!unitProgress) return;
    if (!unitProgress.started_at) {
      dispatch(startUnitProgress(unitProgress.id));
    }
  }, [unitProgress]);

  useEffect(() => {
    if (bootcamp && unit) {
      const params = {
        course: bootcamp.title,
        lesson: unit.title,
      };
      setPageTitle('pageName.course.lesson', params);
    }
  }, [bootcamp, unit]);

  const part = parts[0];

  return (
    <div className="new-lesson-page">
      <main className="new-lesson-page__main">
        <ShowHeader />

        {lesson && part && blocks ? (
          <ShowLesson lesson={lesson} part={part} blocks={blocks} />
        ) : (
          <article className="lesson-part lesson-part--normal">
            <div className="lesson-part__wrapper">
              <Loading />
            </div>
          </article>
        )}
      </main>
    </div>
  );
}
