import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import FileUpload, { getFileName } from '../../Utils/FileUpload';
import VideoPlayer from '../../Utils/VideoPlayer';
import Errors from '../../Utils/Errors';
import Modal2 from '../../library/Modal2';
import CustomInput from '../../Utils/CustomInput';
import intl from '../../../utils/intl';
import useSaveBlock from '../../../hooks/useSaveBlock';
import CustomCheckbox from '../../Utils/CustomCheckbox';
import MultipleEditor from '../../Blocks/MultipleEditor';

export default function EditVideoModal({ block, onHide }) {
  const [isDefaultChecked, setIsDefaultChecked] = React.useState(true);
  const [previousEmbedCode, setPreviousEmbedCode] = React.useState('');

  const { blockState, handleChange, errors, saveBlock } = useSaveBlock(block);

  const handleSubmit = e => {
    e.preventDefault();
    saveBlock(blockState, true, onHide);
  };

  React.useEffect(() => {
    if (isDefaultChecked) {
      setPreviousEmbedCode(blockState.embed_code || '');
      handleInputChange('embed_code')(transformEmbedCode(blockState.embed_code || ''));
    } else {
      handleInputChange('embed_code')(previousEmbedCode);
    }
  }, [isDefaultChecked]);

  const transformEmbedCode = input => {
    const domparser = new DOMParser();
    const iframeDoc = domparser.parseFromString(input, 'text/html');
    const iframe = iframeDoc.querySelector('iframe');
    if (!iframe) {
      return input;
    }
    iframe.setAttribute('width', '100%');
    iframe.setAttribute('height', '500');
    return iframeDoc.body.innerHTML;
  };

  const handleEmbedCodeChange = e => {
    const updatedEmbedCode = e.target.value;
    if (!isDefaultChecked) {
      handleInputChange('embed_code')(updatedEmbedCode);
      return;
    }
    setPreviousEmbedCode(updatedEmbedCode);
    const transformedCode = transformEmbedCode(updatedEmbedCode);
    handleInputChange('embed_code')(transformedCode);
  };

  const handleCheckboxChange = () => {
    setIsDefaultChecked(!isDefaultChecked);
  };

  const handleInputChange = name => handleChange(name, false, false);

  return (
    <Modal2 title={<FormattedMessage id="block.form.video" />} onHide={onHide} onOk={handleSubmit}>
      <MultipleEditor blockId={blockState.id} forceEditing>
        <Errors errors={errors} />

        <CustomInput
          name="title"
          onChange={handleInputChange('title')}
          value={blockState.title}
          label={intl.formatMessage({ id: 'block.form.title' })}
        />

        <div className="form-item">
          <span className="ml-2 project-file">{getFileName(block.url)}</span>
          <FileUpload showDropZone accept="video/*" onSuccess={handleInputChange('url')}>
            <div className="custom-upload-input__label">
              <span className="custom-upload-input__label--text">
                <FormattedMessage id="common.fileDropMessage" />
              </span>
            </div>
          </FileUpload>
        </div>

        <details className="collapsable-field">
          <summary className="collapsable-field__summary">
            <FormattedMessage id="block.form.embedCode" />
          </summary>
          <div className="collapsable-field__content">
            <div className="custom-textarea">
              <textarea
                id="embedCode"
                className="custom-textarea__textarea"
                onChange={handleEmbedCodeChange}
                rows={5}
                style={{ width: '100%' }}
                value={blockState.embed_code || ''}
              />
            </div>
            <div className="custom-checkbox">
              <CustomCheckbox
                id="defaultCheckbox"
                type="checkbox"
                checked={isDefaultChecked}
                onChange={handleCheckboxChange}
                label={<FormattedMessage id="block.form.defaultCheckbox" />}
                inline
              />
            </div>
          </div>
        </details>

        {blockState.url && (
          <div className="video-container">
            <VideoPlayer src={blockState.url} />
          </div>
        )}
      </MultipleEditor>
    </Modal2>
  );
}

EditVideoModal.propTypes = {
  block: PropTypes.object.isRequired,
  onHide: PropTypes.func.isRequired,
};
