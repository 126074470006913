import React from 'react';
import PropTypes from 'prop-types';
import Icons from '../Utils/Icons';
import ComponentItemContainer from '../Components/ComponentItemContainer';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';

const StudentModuleTile = ({
  unit,
  index,
  completedCounts,
  components,
  section,
  currentComponentProgress,
  progressesByComponentId,
  hasLab,
  youAreHere,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const componentProps = component => {
    const componentProgress = progressesByComponentId[component.id];

    return {
      component,
      currentComponentProgress,
      unit,
      section,
      unitId: unit.id,
      componentProgress,
      totalComponents: components.length,
    };
  };

  const handleEvent = e => {
    e.preventDefault();
    setIsExpanded(!isExpanded);
  };

  let totalCount = 0;
  let completedCount = 0;

  if (completedCounts) {
    totalCount = completedCounts.total;
    completedCount = completedCounts.completed;
  } else {
    totalCount = components.length;
  }

  const completed = completedCount > 0 && completedCount === totalCount;
  const iconClasses = completed
    ? 'icon-element--fill-green-800 icon-element--background-green-100'
    : 'icon-element--fill-grey-600 icon-element--background-main-blue-100';
  return (
    <div className="student-module-tile" key={index}>
      <button
        className={`unit-row ${isExpanded ? 'expanded' : ''} ${completed ? 'complete' : ''}`}
        onClick={handleEvent}
        data-testid="module-unit-row">
        <div className={`icon-element ${iconClasses}  icon-element--size-standard`}>
          <Icons.Class />
        </div>
        <div className="unit-title">
          {unit.title}
          {youAreHere && (
            <div className="you-are-here">
              <FormattedMessage id="common.youAreHere" />
            </div>
          )}
        </div>
        {hasLab && (
          <div className={`unit-icon icon-element ${iconClasses}  icon-element--size-standard`}>
            <Icons.Elixir />
          </div>
        )}
        <div className="unit-status" data-testid="module-status">
          {completedCount} / {totalCount}
        </div>
      </button>
      <div className={`${isExpanded ? 'components-expanded' : 'components-hidden'}`}>
        {components.map((component, index) => (
          <ComponentItemContainer pathstream2={true} key={component.id} index={index} {...componentProps(component)} />
        ))}
      </div>
    </div>
  );
};

StudentModuleTile.propTypes = {
  unit: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  completedCounts: PropTypes.object,
  components: PropTypes.array.isRequired,
  section: PropTypes.object.isRequired,
  hasLab: PropTypes.bool,
};

export default StudentModuleTile;
