import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getPathPrefix } from '../../helpers/pathHelper';
import { selectCurrentUser } from '../../selectors/authSelectors';
import { selectProgressToResume, selectProgressForPath } from '../../selectors/pathProgressSelectors';
import { selectPath } from '../../selectors/pathSelectors';
import { selectBootcamp } from '../../selectors/bootcampSelectors';
import PathHeader from './PathHeader';
import { selectIsEditMode } from '../../selectors/contextSelectors';
import { selectFirstComponent } from '../../selectors/cohortBootcampSelectors';
import { canAuthor } from '../../helpers/userAuth';

const mapStateToProps = (state, ownProps) => {
  const { bootcampId, pathId } = ownProps.match.params;
  const bootcamp = selectBootcamp(state, bootcampId);
  const path = selectPath(state, pathId);
  const pathProgress = selectProgressForPath(state, pathId);
  const currentUser = selectCurrentUser(state);
  const currentProgress =
    (pathProgress && selectProgressToResume(state, pathProgress.id)) || selectFirstComponent(state, bootcamp.id);
  const pathPrefix = getPathPrefix(currentUser);
  const isEditMode = selectIsEditMode(state);

  let resumeUrl;
  if (currentProgress) {
    resumeUrl = `${pathPrefix}/bootcamps/${bootcamp.id}/paths/${currentProgress.pathId}/sections/${currentProgress.sectionId}`;
    if (currentProgress.componentId) {
      resumeUrl += `/units/${currentProgress.unitId}/components/${currentProgress.componentId}`;
    } else {
      resumeUrl += `/projects/${currentProgress.unitId}`;
    }
  }

  return {
    resumeUrl,
    bootcamp,
    path,
    pathProgress,
    isEditMode,
    canAuthor: canAuthor(currentUser),
    backUrl: `${pathPrefix}/bootcamps`,
  };
};

export default withRouter(connect(mapStateToProps)(PathHeader));
