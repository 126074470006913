import Assignment from '../../../api/assignment';
import intl from '../../../utils/intl';
import { proficiencyLevelToGrades } from '../../../utils/utils';

export const criterionToProficiencyLevelArray = criterion =>
  Assignment.CRITERION_PROFICIENCY_LEVELS.map(level => ({
    title: intl.formatMessage({ id: `rubricCriteria.fields.${level}` }),
    description: criterion[level] || '',
    grades: proficiencyLevelToGrades(level),
  }));
