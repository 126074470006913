import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import intl from '../../utils/intl';
import SvgCircularProgress from '../Utils/SvgCircularProgress';
import ComponentTabs from './ComponentTabs';
import { selectIsEditMode } from '../../selectors/contextSelectors';
import EditBar from './EditBar';
import logo from '../../styles/v2/images/logo.png';
import { LeftOutlined } from '@ant-design/icons';

export default function ComponentProgresses(props) {
  const {
    components,
    currentComponent,
    componentsWithLab,
    componentsCompleted,
    unit,
    unitProgress,
    onMenuClick,
  } = props;
  const isEditMode = useSelector(selectIsEditMode);

  return (
    <nav id="header-bar-module" className="header-bar__module">
      <div className="header-bar__menu">
        <button
          type="button"
          className="header-bar__menu-button"
          aria-label={intl.formatMessage({ id: 'nav.menu' })}
          onClick={onMenuClick}>
          <LeftOutlined />
        </button>
      </div>
      <div className="header-bar__summary">
        <div>
          <div className="header-bar__summary-heading">
            <FormattedMessage id="unit.currentModule" />
          </div>
          <div className="header-bar__summary-title">{unit && unit.title}</div>
        </div>
        {!isEditMode && <SvgCircularProgress progress={(unitProgress && unitProgress.progress) || 0} />}
      </div>

      <ComponentTabs
        componentsWithLab={componentsWithLab}
        componentsCompleted={componentsCompleted}
        currentComponent={currentComponent}
        components={components}
      />
      <img src={logo} alt="logo" className="pathstream-logo" />

      <EditBar />
    </nav>
  );
}

ComponentProgresses.propTypes = {
  components: PropTypes.array,
  currentComponent: PropTypes.object,
  componentsWithLab: PropTypes.object,
  componentsCompleted: PropTypes.object,
  unit: PropTypes.object,
  unitProgress: PropTypes.object,
  onMenuClick: PropTypes.func,
};
