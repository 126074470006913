import { isEmpty as _isEmpty } from 'lodash';
import { connect } from 'react-redux';
import { selectCurrentUser } from '../../selectors/authSelectors';
import BootcampIntroModal from './BootcampIntroModal';
import { selectProgressToResume } from '../../selectors/pathProgressSelectors';
import { getPathPrefix } from '../../helpers/pathHelper';
import { canAuthor, canLearn } from '../../helpers/userAuth';
import { selectIsEditMode } from '../../selectors/contextSelectors';
import { selectFirstComponent } from '../../selectors/cohortBootcampSelectors';
import Unit from '../../api/unit';

const mapStateToProps = (state, ownProps) => {
  const { bootcamp, pathProgress } = ownProps;
  const currentUser = selectCurrentUser(state);
  const isEditMode = selectIsEditMode(state);
  const isStudent = canLearn(currentUser);
  const pathPrefix = getPathPrefix(currentUser);

  const currentProgress =
    (pathProgress && selectProgressToResume(state, pathProgress.id)) || selectFirstComponent(state, bootcamp.id);

  let resumeUrl;
  if (currentProgress) {
    resumeUrl = `${pathPrefix}/bootcamps/${bootcamp.id}/paths/${currentProgress.pathId}/sections/${currentProgress.sectionId}`;
    if (currentProgress.componentId) {
      resumeUrl += `/units/${currentProgress.unitId}/components/${currentProgress.componentId}`;
    } else if (currentProgress.unit?.unit_type === Unit.GRADED_QUIZ) {
      resumeUrl += `/graded_quizzes/${currentProgress.unitId}`;
    } else {
      resumeUrl += `/projects/${currentProgress.unitId}`;
    }
  }

  const showIntroModal =
    bootcamp.show_intro_modal &&
    ((canAuthor(currentUser) && !isEditMode) ||
      (isStudent && pathProgress && _isEmpty(pathProgress.current_section_progress_id)));

  const showReturningModal =
    bootcamp.show_returning_modal && isStudent && pathProgress && !_isEmpty(pathProgress.current_section_progress_id);

  return {
    currentUser,
    resumeUrl,
    showIntroModal,
    showReturningModal,
    currentProgress,
  };
};

export default connect(mapStateToProps)(BootcampIntroModal);
