import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import LtiAssignmentTile from './LtiAssignmentTile';
import SectionTileHeaderContainer from '../../Sections/SectionTileHeaderContainer';

export default function LtiSectionTile({
  section,
  index,
  assignments,
  assignmentIds,
  assignmentMappings,
  lineItems,
  onSelect,
  fetchAssignments,
}) {
  useEffect(() => {
    fetchAssignments(assignmentIds);
  }, []);

  return (
    <>
      <SectionTileHeaderContainer section={section} index={index} />

      <div className="units-container__content">
        {assignments.map(assignment => (
          <LtiAssignmentTile
            key={assignment.id}
            assignment={assignment}
            assignmentMapping={assignmentMappings[assignment.id]}
            onSelect={onSelect}
            lineItems={lineItems}
          />
        ))}
        {assignments.length === 0 && (
          <div style={{ marginTop: 10, paddingLeft: 20 }}>
            <FormattedMessage id="lti.messages.noAssignmentsInSection" />
          </div>
        )}
      </div>
    </>
  );
}

LtiSectionTile.propTypes = {
  section: PropTypes.object.isRequired,
  assignments: PropTypes.array,
  assignmentMappings: PropTypes.object,
  lineItems: PropTypes.array,
  assignmentIds: PropTypes.array,
  onSelect: PropTypes.func,
  fetchAssignments: PropTypes.func.isRequired,
  index: PropTypes.number,
};
