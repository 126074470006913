import { isEmpty as _isEmpty } from 'lodash';
import { connect } from 'react-redux';
import StudentList from './StudentList';
import { setCurrentCohortId, setCurrentStudentId } from '../../../actions/components/gradeActions';
import { selectBootcampEntities, selectBootcamps } from '../../../selectors/bootcampSelectors';
import { selectCohorts } from '../../../selectors/cohortSelectors';
import { selectCurrentCohortId, selectCurrentStudentId } from '../../../selectors/components/gradeSelectors';
import { getRosterForCohort } from '../../../actions/rosterActions';
import { getUsers } from '../../../actions/userActions';
import { selectCurrentUser } from '../../../selectors/authSelectors';
import { canAdministrate, canCoach } from '../../../helpers/userAuth';
import { dropdownBootcampsWithCohortTitle, dropdownBootcamps } from '../../../helpers/bootcampHelper';

const mapStateToProps = state => {
  const bootcamps = selectBootcamps(state);
  const bootcampEntities = selectBootcampEntities(state);
  const cohorts = selectCohorts(state);
  const selectedCohortId = selectCurrentCohortId(state);
  let selectedBootcamp;
  let selectedCohort;
  const currentStudentId = selectCurrentStudentId(state);
  let accessibleBootcamps = [];
  const currentUser = selectCurrentUser(state);
  const isInstructor = canCoach(currentUser) && !canAdministrate(currentUser);

  if (selectedCohortId) {
    selectedCohort = _isEmpty(cohorts) ? null : cohorts.find(c => c.id === selectedCohortId);
    selectedBootcamp = _isEmpty(bootcamps) ? null : bootcamps.find(b => b.id === selectedCohort.bootcamp_id);
  }

  if (isInstructor) {
    accessibleBootcamps = dropdownBootcampsWithCohortTitle(bootcampEntities, cohorts);
  } else {
    accessibleBootcamps = dropdownBootcamps(bootcampEntities, cohorts);
  }

  return {
    selectedBootcamp,
    selectedCohort,
    bootcamps: accessibleBootcamps,
    currentStudentId,
  };
};

export default connect(mapStateToProps, { setCurrentCohortId, getRosterForCohort, getUsers, setCurrentStudentId })(
  StudentList,
);
