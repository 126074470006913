import { map as _map, compact as _compact, sortBy as _sortBy } from 'lodash';
import createCachedSelector from 're-reselect';
import { selectBlock } from './blockSelectors';
import { selectEntities, selectAll, select } from './applicationSelectors';
import { selectPart } from './partSelectors';

export const selectLessonEntities = selectEntities('lessons');
export const selectLessons = selectAll(selectLessonEntities);
export const selectLesson = select(selectLessonEntities);

export const selectLessonByBlock = (state, block) => selectLesson(state, block.lesson_id);

const lessonBlockIds = (state, lessonId) => (selectLesson(state, lessonId) || {}).block_ids;
const lessonPartIds = (state, lessonId) => (selectLesson(state, lessonId) || {}).part_ids;

export const selectLessonBlocks = createCachedSelector([state => state, lessonBlockIds], (state, blockIds) =>
  _compact(_map(blockIds, id => selectBlock(state, id))),
)((_state, lessonId) => lessonId);

export const selectLessonParts = createCachedSelector([state => state, lessonPartIds], (state, partIds) =>
  _compact(_map(partIds, id => selectPart(state, id))),
)((_state, lessonId) => lessonId);

export const selectOrderedLessonParts = createCachedSelector([state => state, lessonPartIds], (state, partIds) =>
  _sortBy(_compact(_map(partIds, id => selectPart(state, id))), ['seq_num']),
)((_state, lessonId) => lessonId);
