import axios from './axios';

export default class Enrollment {
  static async forCohort(_, cohortId) {
    const response = await axios.get(`/paths/bootcamp_instances/${cohortId}/enrollments`);
    return response.data;
  }

  static async forUser(_, userId) {
    const response = await axios.get(`/paths/enrollments?user_id=${userId}`);
    return response.data;
  }

  static async currentEnrollments(_) {
    const response = await axios.get('/paths/enrollments');
    return response.data;
  }

  static async get(_, enrollmentId) {
    const response = await axios.get(`/paths/enrollments/${enrollmentId}`);
    return response.data;
  }

  static async rebuild(_, enrollmentId) {
    const response = await axios.put(`/paths/enrollments/${enrollmentId}/rebuild`);
    return response.data;
  }

  static async update(_, enrollment) {
    const response = await axios.put(
      `/paths/bootcamp_instances/${enrollment.cohort_id}/enrollments/${enrollment.id}`,
      enrollment,
    );
    return response.data;
  }

  static async save(_, cohort, enrollment) {
    if (enrollment.id) {
      const response = await axios.put(
        `/paths/bootcamp_instances/${cohort.id}/enrollments/${enrollment.id}`,
        enrollment,
      );
      return response.data;
    }
    const response_1 = await axios.post(`/paths/bootcamp_instances/${cohort.id}/enrollments`, enrollment);
    return response_1.data;
  }

  static async destroy(_, cohort, enrollment) {
    const response = await axios.delete(`/paths/bootcamp_instances/${cohort.id}/enrollments/${enrollment.id}`);
    return response.data;
  }

  static ENROLLED = 'Enrolled';
  static DROPPED = 'Dropped';
  static COMPLETED = 'Completed';
}
