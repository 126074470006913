import { connect } from 'react-redux';
import Header from './Header';
import { toggleSideNav } from '../../actions/sideNavActions';
import { enableEditMode } from '../../actions/contextActions';
import { signOut } from '../../actions/authActions';
import { selectSideNavIsCollapsed } from '../../selectors/sideNavSelectors';
import { selectCurrentUser } from '../../selectors/authSelectors';
import { canLearn } from '../../helpers/userAuth';

const mapStateToProps = state => {
  return {
    isCollapsed: selectSideNavIsCollapsed(state),
    currentUser: selectCurrentUser(state),
    isStudent: canLearn(selectCurrentUser(state)),
  };
};

export default connect(mapStateToProps, { toggleSideNav, enableEditMode, signOut })(Header);
