import { isEmpty as _isEmpty, keyBy as _keyBy, sortBy as _sortBy, orderBy as _orderBy } from 'lodash';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import GradeRow from './GradeRow';
import Loading from '../../Utils/Loading';
import { toastError, toastSuccess } from '../../../utils/toastHelper';
import intl from '../../../utils/intl';
import { isGsuiteApp } from '../../../helpers/labHelper';
import AssignmentProgress from '../../../api/assignmentProgress';
import Stream from '../../../api/stream';

const GradeCohortAssignments = ({
  cohort,
  student,
  allowAssignmentResubmission,
  allowProjectResubmission,
  allowQuizResubmission,
  fetchGradedItems,
}) => {
  const [hasLoaded, setHasLoaded] = useState(false);
  const [gradedItems, setGradedItems] = useState();

  useEffect(() => {
    setHasLoaded(false);
    getData();
  }, [student]);

  const getData = async () => {
    const gradedItems = await fetchGradedItems(cohort.id, cohort.bootcamp_id, student.id);
    setGradedItems(gradedItems.data);
    setHasLoaded(true);
  };

  const handleAllowResubmission = async params => {
    // eslint-disable-next-line camelcase
    const { tenant_id, unit_progress_id, app, gradable_id, template_type } = params;
    try {
      if (isGsuiteApp(app) && template_type === 'assignment') {
        // make the project editable
        const response = await AssignmentProgress.get(tenant_id, gradable_id);
        const assignmentProgress = response.value;
        await Stream.updateAccess(assignmentProgress.user_project_url, true, app);
      }

      const resubmissionPromise =
        template_type === 'assignment' ? allowAssignmentResubmission(gradable_id) : allowQuizResubmission(gradable_id);

      const projectResubmissionPromise = allowProjectResubmission(unit_progress_id);
      await Promise.all([resubmissionPromise, projectResubmissionPromise]);
      toastSuccess(intl.formatMessage({ id: 'grading.resubmissionSuccess' }));
      setHasLoaded(false);
      await getData();
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('Error marking project for resubmission', e);
      toastError(intl.formatMessage({ id: 'grading.resubmissionFailure' }));
    }
  };

  if (!hasLoaded) {
    return <Loading />;
  }

  // Order of assignments:
  // ungraded assignments that are require_grading true oldest to newest
  // graded assignments and quizzes newest to oldest
  // ungraded assignments that are require_grading false newest to oldest

  const completedItems = gradedItems.filter(entry => entry.completed_at !== null);

  const ungradedAssignmentsGrading = completedItems.filter(
    entry => entry.requires_grading === true && entry.graded_at === null,
  );
  const sortedUngradedGrading = _orderBy(ungradedAssignmentsGrading, ['completed_at'], ['asc']);

  const gradedAssignmentsAndQuizzes = completedItems.filter(
    entry => (entry.requires_grading === true && entry.graded_at !== null) || entry.template_type === 'quiz',
  );
  const sortedGradedAssignmentsAndQuizzes = _orderBy(
    gradedAssignmentsAndQuizzes,
    ['graded_at', 'completed_at'],
    ['desc', 'desc'],
  );

  const ungradedAssignmentsNoGrading = completedItems.filter(
    entry => entry.requires_grading === false && entry.graded_at === null,
  );
  const sortedUngradedNoGrading = _orderBy(ungradedAssignmentsNoGrading, ['completed_at'], ['desc']);

  const sortedEntries = [...sortedUngradedGrading, ...sortedGradedAssignmentsAndQuizzes, ...sortedUngradedNoGrading];

  return sortedEntries.map(entry => (
    <GradeRow key={entry.unit_progress_id} {...entry} onAllowResubmission={handleAllowResubmission} />
  ));
};

GradeCohortAssignments.propTypes = {
  cohort: PropTypes.object.isRequired,
  student: PropTypes.object.isRequired,
  allowAssignmentResubmission: PropTypes.func.isRequired,
  allowProjectResubmission: PropTypes.func.isRequired,
  allowQuizResubmission: PropTypes.func.isRequired,
  fetchGradedItems: PropTypes.func.isRequired,
};

export default GradeCohortAssignments;
