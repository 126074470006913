import { map as _map, uniqBy as _uniqBy, values as _values } from 'lodash';
import { createSelector } from 'reselect';
import createCachedSelector from 're-reselect';
import { selectBootcampEntities } from './bootcampSelectors';
import { selectCohortEntities } from './cohortSelectors';

export const selectEnrollmentEntities = state => {
  return (state && state.entities && state.entities.enrollments) || {};
};

export const selectEnrollments = createSelector([selectEnrollmentEntities], enrollmentEntities => {
  return _values(enrollmentEntities);
});

export const selectEnrollment = createCachedSelector(
  [selectEnrollmentEntities, (_state, enrollmentId) => enrollmentId],
  (enrollmentEntities, enrollmentId) => {
    return enrollmentEntities[enrollmentId];
  },
)((_state, enrollmentId) => enrollmentId);

export const selectCohortEnrollments = createCachedSelector(
  [selectEnrollmentEntities, (_state, cohortId) => cohortId],
  (enrollmentEntities, cohortId) => {
    return _values(enrollmentEntities).filter(
      enrollment => enrollment.enrollable_type === 'BootcampInstance' && enrollment.enrollable_id === cohortId,
    );
  },
)((_state, cohortId) => cohortId);

export const selectEnrolledBootcamps = createSelector(
  [
    state => selectEnrollmentEntities(state),
    state => selectCohortEntities(state),
    state => selectBootcampEntities(state),
  ],
  (enrollmentEntities, cohortEntities, bootcampEntities) => {
    return _uniqBy(
      _map(_values(enrollmentEntities), enrollment => {
        const cohort = cohortEntities[enrollment.enrollable_id];
        if (!cohort) {
          return [];
        }

        return bootcampEntities[cohort.bootcamp_id];
      }),
      'id',
    );
  },
);

//   ,
export const selectEnrollmentForBootcamp = createCachedSelector(
  [selectEnrollmentEntities, (state, _bootcampId) => selectCohortEntities(state), (state, bootcampId) => bootcampId],
  (enrollmentEntities, cohortEntities, bootcampId) => {
    const cohort = _values(cohortEntities).filter(cohort => !cohort.deleted_at  && cohort.bootcamp_id === bootcampId)[0];
    if (!cohort) {
      return null;
    }

    return _values(enrollmentEntities).filter(enrollment => !enrollment.deleted_at && enrollment.enrollable_id === cohort.id)[0];
  },
)((state, bootcampId) => bootcampId);

export const selectEnrollmentsForUser = createCachedSelector(
  [selectEnrollments, (state, userId) => userId],
  (enrollments, userId) => {
    return enrollments.filter(enrollment => enrollment.user_id === userId);
  },
)((state, userId) => userId);
