import React, { useState, useEffect } from 'react';

const ProgressCircle = ({
  percentageComplete,
}) => {

  useEffect(() => {
    document.documentElement.style.setProperty('--progress', `${percentageComplete}%`);
  }, [percentageComplete]);

  return (
    <div className='progress-bar' role="progressbar" data-testid="progress-circle"
         aria-valuenow={percentageComplete} aria-valuemin="0" aria-valuemax="100"
    >
      {percentageComplete}%
    </div>
  );
};

export default ProgressCircle;
