import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import Icons from '../../Utils/Icons';
import { RightOutlined } from '@ant-design/icons';
import moment from 'moment';
import { selectBootcampPaths } from '../../../selectors/bootcampSelectors';

export default function CourseCompletionModal({
  onCancel,
  bootcamp,
  onReturn,
  bootcamps,
  cohorts,
  enrollments,
  programCohort,
  currentUser,
  pathPrefix,
}) {
  const usersIncompleteEnrollments = enrollments.filter(
    enrollment =>
      enrollment?.program_cohort_id === programCohort?.id &&
      enrollment.user_id === currentUser.id &&
      !enrollment.completed_at,
  );

  const incompleteEnrollmentsWithoutProgramCohort = enrollments.filter(
    enrollment => enrollment.program_cohort_id === null && !enrollment.completed_at,
  );
  let filteredCohorts = incompleteEnrollmentsWithoutProgramCohort.flatMap(enrollment => {
    let matches = cohorts.filter(cohort => cohort.id === enrollment.enrollable_id);
    return matches;
  });

  const enrollmentsToCohorts = usersIncompleteEnrollments.map(enrollment => {
    return cohorts.find(cohort => cohort.id === enrollment.enrollable_id);
  });
  const sortedCohorts = enrollmentsToCohorts.sort((a, b) => {
    return moment(a.start_date).diff(moment(b.start_date));
  });
  const nextCohort = sortedCohorts[0] ? sortedCohorts[0] : filteredCohorts[0];
  const formattedDate = nextCohort?.start_date && moment(nextCohort?.start_date).format('MMM D');
  const nextBootcamp = bootcamps.find(bootcamp => bootcamp.id === nextCohort?.bootcamp_id);
  const bootcampPaths = nextBootcamp ? useSelector(s => selectBootcampPaths(s, nextBootcamp.id)) : [];
  const nextPath = bootcampPaths.length > 0 ? bootcampPaths[0] : {};
  const nextBootcampUrl = `${pathPrefix}/bootcamps/${nextBootcamp?.id}/paths/${nextPath?.id}`;
  const nextAccessibleDate = nextCohort?.access_date || nextCohort?.start_date;
  const nextAccessible = moment(nextAccessibleDate).isBefore(moment());
  const nextCourseTitle = nextBootcamp?.title;

  let desc = <FormattedMessage id="completionModal.description.youCompleted" />;

  return (
    <div
      className="subject-congrats-wrapper subject-congrats-wrapper--opened"
      tabIndex="-1"
      role="dialog"
      data-testid="course-completion-modal">
      <div className="subject-congrats" role="document">
        <div className="subject-congrats__content">
          <section className="subject-congrats__panel subject-congrats__panel--dark">
            <div className="subject-congrats__panel-close">
              <button
                type="button"
                onClick={onCancel}
                className="subject-congrats__panel-close-button icon-element--fill-grey-600">
                <Icons.Close />
              </button>
            </div>
            <header className="subject-congrats__header">
              <h1 className="subject-congrats__title">
                <FormattedMessage id="completionModal.heading.courseCompleted" />
              </h1>
            </header>
            <div className="subject-congrats__description">
              <>
                <div className="subject-congrats__description-text">{desc}</div>
                <div className="subject-congrats__description-list">
                  <ul className="subject-congrats__list">
                    <li key={bootcamp.id} className="subject-congrats__list-item">
                      {bootcamp.title}
                    </li>
                  </ul>
                </div>
              </>
            </div>
            <footer className="subject-congrats__overview">
              <>
                {nextCohort && (
                  <div className="subject-congrats__next-step" data-testid="next-course-section">
                    <div className="subject-congrats__subject">
                      <div className="subject-congrats__subject-title">
                        <FormattedMessage id="completionModal.nextCourse" />
                      </div>
                      <div className="subject-congrats__subject-description">
                        {formattedDate && (
                          <>
                            <Icons.ClockBlack />
                            <div className="subject-congrats__subject-description-date">
                              <FormattedMessage id="completionModal.startDate" />: {formattedDate}
                            </div>
                          </>
                        )}
                      </div>
                      <div className="subject-congrats__subject-heading">{nextCourseTitle}</div>
                    </div>
                    {nextAccessible ? (
                      <div className="subject-congrats__actions">
                        <div className="subject-congrats__actions-wrapper">
                          <Link
                            to={nextBootcampUrl}
                            tabIndex="0"
                            aria-label={'link to next course'}
                            className="subject-congrats__actions-button"
                            onClick={() => {
                              return onCancel();
                            }}
                            data-testid="next-subject-button">
                            <RightOutlined />
                          </Link>
                        </div>
                      </div>
                    ) : null}
                  </div>
                )}
                <button type="button" onClick={onReturn} className="subject-congrats__actions-link">
                  <FormattedMessage id="nav.backToCourses" />
                </button>
              </>
            </footer>
          </section>
        </div>
      </div>
    </div>
  );
}

CourseCompletionModal.propTypes = {
  onCancel: PropTypes.func,
  bootcamp: PropTypes.object,
  onReturn: PropTypes.func,
  bootcamps: PropTypes.array,
  cohorts: PropTypes.array,
  enrollments: PropTypes.array,
  programCohort: PropTypes.object,
  currentUser: PropTypes.object,
  pathPrefix: PropTypes.string,
};
