import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Button, Modal } from 'antd';

export const LabVersionChangeType = {
  MINOR: 'minor',
  MAJOR: 'major',
  NEW: 'new',
};

export default function ProjectVersionChangeModal({ onSubmit, onHide }) {
  const handleSubmit = changeType => {
    onSubmit(changeType);
  };

  return (
    <Modal
      // We had to do this because a parent is a modal that is triggered by the old showModal
      // should be removed when ConfigureProjectModal.js is converted to a new modal
      zIndex={1076}
      visible={true}
      onCancel={onHide}
      title={<FormattedMessage id="common.projectVersionChangeModal.title" />}
      footer={[
        <Button key="no" onClick={() => handleSubmit(LabVersionChangeType.MINOR)}>
          NO
        </Button>,
        <Button key="yes" type="primary" danger="true" onClick={() => handleSubmit(LabVersionChangeType.MAJOR)}>
          YES
        </Button>,
      ]}>
      <div>
        <FormattedMessage id="common.projectVersionChangeModal.message" />
      </div>
      <div>
        <FormattedMessage id="common.projectVersionChangeModal.note" />
      </div>
    </Modal>
  );
}

ProjectVersionChangeModal.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onHide: PropTypes.func.isRequired,
};
