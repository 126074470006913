import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

export default function LessonCompletionHeader({ isSectionComplete, isUnitComplete }) {
  let title = <FormattedMessage id="completionModal.heading.lessonCompleted" />;
  if (isSectionComplete) {
    title = <FormattedMessage id="completionModal.heading.sectionCompleted" />;
  } else if (isUnitComplete) {
    title = <FormattedMessage id="completionModal.heading.moduleCompleted" />;
  }

  return (
    <header className="subject-congrats__header">
      <h3 className="subject-congrats__title">{title}</h3>
    </header>
  );
}

LessonCompletionHeader.propTypes = {
  isSectionComplete: PropTypes.bool,
  isUnitComplete: PropTypes.bool,
};
