import { compact as _compact, map as _map } from 'lodash';
import createCachedSelector from 're-reselect';
import { selectPathEntities, selectPathFromSection } from './pathSelectors';
import { selectSectionFromUnit } from './sectionSelectors';
import { selectEntities, selectAll, select, selectHasMany } from './applicationSelectors';

export const selectBootcampEntities = selectEntities('bootcamps');
export const selectBootcamps = selectAll(selectBootcampEntities);
export const selectBootcamp = select(selectBootcampEntities);
export const selectBootcampFromPath = selectHasMany(selectBootcamps, 'path_ids');
export const selectBootcampFromUnit = (state, unit) =>
  selectBootcampFromSection(state, selectSectionFromUnit(state, unit));
export const selectBootcampFromSection = (state, section) => {
  const path = selectPathFromSection(state, section);
  if (!path) return;
  return selectBootcampFromPath(state, path);
};

export const selectBootcampByCohort = (state, cohort) => selectBootcamp(state, cohort.bootcamp_id);

export const selectBootcampPathIds = (state, bootcampId) => (selectBootcamp(state, bootcampId) || {}).path_ids;

export const selectBootcampPaths = createCachedSelector(
  [selectBootcampPathIds, selectPathEntities],
  (pathIds, pathEntities) => {
    return _compact(_map(pathIds, pathId => pathEntities[pathId]));
  },
)((_state, bootcampId) => bootcampId);
