import React, { Component } from 'react';
import PropTypes from 'prop-types';
import BlockQuizShow from './BlockQuizShow';
import QuestionContainer from '../SingleQuestionQuiz/QuestionContainer';

export default class BlockSingleQuestionQuizShow extends Component {
  render() {
    const { block, questionProgressesEnabled } = this.props;

    return (
      <BlockQuizShow
        block={block}
        renderBody={(quiz, questions) => (
          <QuestionContainer question={questions[0]} questionProgressesEnabled={questionProgressesEnabled} />
        )}
      />
    );
  }
}
BlockSingleQuestionQuizShow.propTypes = {
  block: PropTypes.object.isRequired,
  questionProgressesEnabled: PropTypes.bool,
};
