import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import useCurrentUser from './useCurrentUser';
import { getCoursePathByIds } from '../helpers/pathHelper';
import { getProgress } from '../selectors/overallProgressSelector';
import Unit from '../api/unit';

export default function useNextUrl(skipLti = false) {
  const { bootcampId, pathId, sectionId, unitId, componentId } = useParams();
  const { currentUser, isStudent, isLtiStudent } = useCurrentUser();

  const { currentProgress, nextProgress } = useSelector(s =>
    getProgress(s, { bootcampId, pathId, sectionId, unitId, componentId }),
  );
  const bootcampUrl = getCoursePathByIds(currentUser, bootcampId, pathId);

  if (!isStudent || !nextProgress) return null;

  let nextUrl = null;

  nextUrl = `${bootcampUrl}/sections/${nextProgress.sectionId}`;
  if (nextProgress.unit.unit_type === Unit.PROJECT) {
    // Don't show next url if it's an LTI assignment
    if (skipLti && isLtiStudent && currentProgress.componentComplete) {
      return null;
    }
    nextUrl += `/projects/${nextProgress.unitId}`;
  } else if (nextProgress.unit.unit_type === Unit.GRADED_QUIZ) {
    nextUrl += `/graded_quizzes/${nextProgress.unitId}`;
  } else {
    nextUrl += `/units/${nextProgress.unitId}/components/${nextProgress.componentId}`;
  }

  return nextUrl;
}
