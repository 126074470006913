import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import intl from '../../utils/intl';
import { fetchLtiLaunchRequest } from '../../actions/ltiLaunchRequestActions';

const INITIAL = 'initial';
const ERROR = 'error';
const LOADING = 'loading';
const LOADED = 'loaded';

export const LTIDataState = { INITIAL, ERROR, LOADING, LOADED };

const NETWORK_ERROR = 'networkError';
const INTERNAL_ERROR = 'internalError';
const UNKNOWN_ERROR = 'unknownError';

const isNetworkError = error => !!error.isAxiosError && !error.response;

const getErrorCode = error => {
  if (isNetworkError(error)) return NETWORK_ERROR;

  const status = error.meta?.status;

  if (status >= 500) return INTERNAL_ERROR;
  return UNKNOWN_ERROR;
};

const shouldSendErrorToSentry = errorCode => errorCode == UNKNOWN_ERROR;

export const useLTIData = ({ unit, tutorial }) => {
  const dispatch = useDispatch();

  const initialState = { status: LTIDataState.INITIAL, launch: { url: undefined, data: {} } };
  const [{ launch, status }, setState] = React.useState(initialState);

  const fetchAndSetSrc = () => {
    setState({ ...initialState, status: LTIDataState.LOADING });
    dispatch(fetchLtiLaunchRequest({ unit, tutorial }))
      .then(ltiData => setState({ launch: ltiData.launch, status: LTIDataState.LOADED }))
      .catch(e => {
        const errorCode = getErrorCode(e);
        if (shouldSendErrorToSentry(errorCode)) window.Raven?.captureException(e.origError || e);
        setState({ ...initialState, status: LTIDataState.ERROR });
      });
  };
  useEffect(fetchAndSetSrc, [unit, tutorial, dispatch]);

  const title = intl.formatMessage({ id: 'block.codio.defaultEmbedTitle' });
  return { launch, title, status, reload: fetchAndSetSrc };
};
