import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Modal } from 'antd';
import Errors from '../../Utils/Errors';
import intl from '../../../utils/intl';
import EditRubric from './EditRubric';
import { selectOldestEnabledCriteriaByAssignment } from '../../../selectors/criterionSelectors';

export default function EditRubricModal(props) {
  const [assignment, setAssignment] = useState(props.assignment);
  const initialRubricCriteria = useSelector(state => selectOldestEnabledCriteriaByAssignment(state, props.assignment));
  const [rubricCriteria, setRubricCriteria] = useState(initialRubricCriteria);
  const [errors, setErrors] = useState([]);

  const handleSubmit = e => {
    e.preventDefault();
    props
      .onSave({ ...assignment, rubric_criteria: rubricCriteria })
      .then(() => props.onHide())
      .catch(err => setErrors(err.value.errors));
  };

  return (
    <Modal
      title={<FormattedMessage id="rubricCriteria.heading" values={{ assignmentTitle: assignment.title }} />}
      visible
      okText={intl.formatMessage({ id: 'common.save' })}
      onCancel={props.onHide}
      width="fit-content"
      onOk={handleSubmit}>
      <Errors errors={errors} />
      <EditRubric {...{ assignment, setAssignment, rubricCriteria, setRubricCriteria }} />
    </Modal>
  );
}

EditRubricModal.propTypes = {
  assignment: PropTypes.object.isRequired,
  onSave: PropTypes.func.isRequired,
  onHide: PropTypes.func.isRequired,
};
