import { normalize } from 'normalizr';
import Schemas from '../api/schemas';
import store from '../store';
import { updateEntities } from '../actions/entityActions';
import { subscribe } from './events';

import { toastSuccess, toastError } from '../utils/toastHelper';
import intl from '../utils/intl';

const update = msg => {
  const { payload } = msg;
  let entities, entitiesX;

  if (payload.entities) {
    entities = normalize(payload.entities, Schemas.entities).entities;
  }
  if (payload.entities_x) {
    entitiesX = normalize(payload.entities_x, Schemas.entities).entities;
  }

  if (entities || entitiesX) {
    store.dispatch(updateEntities({ entities, entitiesX }));
  }
};

export const toastSubmissionResults = msg => {
  const assignmentProgresses = msg?.payload?.entities?.assignmentProgresses;

  if (!assignmentProgresses) {
    return;
  }

  const processedProgresses = Object.values(assignmentProgresses).filter(progress => {
    return ['passed', 'failed'].includes(progress.submission_status);
  });

  if (!processedProgresses.length) {
    return;
  }

  const state = store.getState();
  const storeProgresses = state?.entities?.assignmentProgresses;
  for (let progress of processedProgresses) {
    const storeProgress = storeProgresses[progress.id] || {};

    if (progress.submission_status === storeProgress.submission_status) {
      continue;
    }

    if (progress.submission_status === 'passed') {
      toastSuccess(intl.formatMessage({ id: 'fileUpload.success' }));
    } else {
      toastError(intl.formatMessage({ id: 'fileUpload.failed' }));
    }
  }
};

subscribe('entities', toastSubmissionResults, true);
subscribe('entities', update);
