import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { enableEditMode } from '../../../actions/contextActions';
import { canAuthor } from '../../../helpers/userAuth';
import { selectCurrentUser } from '../../../selectors/authSelectors';
import { selectIsEditMode } from '../../../selectors/contextSelectors';

export default function AuthorEditToggle({ className }) {
  const isEditMode = useSelector(selectIsEditMode);
  const currentUser = useSelector(selectCurrentUser);
  const isAuthor = canAuthor(currentUser);
  const dispatch = useDispatch();

  useEffect(() => {
    enableEditModeIfNeeded();
  });

  const enableEditModeIfNeeded = () => {
    if (isEditMode === null && isAuthor) {
      dispatch(enableEditMode(true));
    }
  };

  const toggleAuthorEditMode = () => {
    dispatch(enableEditMode(!isEditMode));
  };

  if (!isAuthor) {
    return null;
  }

  return (
    <>
      <span id="edit-mode-label" className={className || 'edit-mode__label'}>
        {isEditMode ? (
          <FormattedMessage id="common.authorModes.edit" />
        ) : (
          <FormattedMessage id="common.authorModes.view" />
        )}
      </span>
      <div className="toggle-button">
        <div className="checkbox">
          <input
            type="checkbox"
            aria-label="toggle"
            aria-labelledby="edit-mode-label"
            checked={isEditMode || false}
            aria-checked={isEditMode || false}
            onChange={toggleAuthorEditMode}
            role="switch"
          />
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control, jsx-a11y/control-has-associated-label */}
          <label></label>
        </div>
      </div>
    </>
  );
}

AuthorEditToggle.propTypes = {
  className: PropTypes.string,
};
