import { each as _each, findIndex as _findIndex, size as _size, filter as _filter } from 'lodash';
import { createSelector } from 'reselect';
import { selectSectionEntities } from './sectionSelectors';
import { selectUnitEntities } from './unitSelectors';
import { selectComponentEntities } from './componentSelectors';
import Unit from '../api/unit';
import { selectPathEntities } from './pathSelectors';
import intl from '../utils/intl';
import { getPathPrefix } from '../helpers/pathHelper';

const selectUrlObjectsForBootcampPath = createSelector(
  [
    (_state, bootcampId) => bootcampId,
    (_state, _bootcampId, pathId) => pathId,
    (_state, _bootcampId, _pathId, _sectionId) => _sectionId,
    (_state, _bootcampId, _pathId, _sectionId, unitId) => unitId,
    (_state, _bootcampId, _pathId, _sectionId, _unitId, currentUser) => currentUser,
    selectPathEntities,
    selectSectionEntities,
    selectUnitEntities,
    selectComponentEntities,
  ],
  (
    bootcampId,
    pathId,
    _sectionId,
    _unitId,
    currentUser,
    pathEntities,
    sectionEntities,
    unitEntities,
    componentEntities,
  ) => {
    const urls = [];
    const path = pathEntities[pathId];

    if (!path) {
      return urls;
    }

    _each(path.section_ids, (sectionId, sectionIndex) => {
      const section = sectionEntities[sectionId];
      const unitIds = section && section.unit_ids;
      const pathPrefix = getPathPrefix(currentUser);
      let assessmentIndex = 0;

      _each(unitIds, (unitId, unitIndex) => {
        const unit = unitEntities[unitId];
        let url;

        if (!unit) {
          return;
        }

        if (unit.unit_type === Unit.PROJECT) {
          assessmentIndex += 1;

          url = `${pathPrefix}/bootcamps/${bootcampId}/paths/${pathId}/sections/${sectionId}/projects/${unitId}`;
          urls.push({
            subHeading: intl.formatMessage(
              { id: 'nav.topNavSubHeading.project' },
              {
                sectionIndex: sectionIndex + 1,
                assessmentIndex,
              },
            ),
            heading: `${unit.title}`,
            url,
            sectionIndex: sectionIndex + 1,
            sectionId,
            unitId,
          });

          return;
        }

        if (unit.unit_type === Unit.GRADED_QUIZ) {
          assessmentIndex += 1;

          url = `${pathPrefix}/bootcamps/${bootcampId}/paths/${pathId}/sections/${sectionId}/graded_quizzes/${unitId}`;
          urls.push({
            subHeading: intl.formatMessage(
              { id: 'nav.topNavSubHeading.project' },
              {
                sectionIndex: sectionIndex + 1,
                assessmentIndex,
              },
            ),
            heading: `${unit.title}`,
            url,
            sectionIndex: sectionIndex + 1,
            sectionId,
            unitId,
          });

          return;
        }

        const componentIds = unit.component_ids;
        // eslint-disable-next-line max-nested-callbacks
        _each(componentIds, (componentId, componentIndex) => {
          const component = componentEntities[componentId];
          if (!component) {
            return;
          }
          url = `${pathPrefix}/bootcamps/${bootcampId}/paths/${pathId}/sections/${sectionId}/units/${unitId}/components/${componentId}`;
          urls.push({
            subHeading: intl.formatMessage(
              { id: 'nav.topNavSubHeading.module' },
              {
                sectionIndex: sectionIndex + 1,
                unitIndex: unitIndex + 1,
                componentIndex: componentIndex + 1,
              },
            ),
            heading: `${component.title}`,
            url,
            sectionIndex: sectionIndex + 1,
            sectionId,
            unitId,
            componentId,
          });
        });
      });
    });

    return urls;
  },
);

export const selectCurrentUrlObject = createSelector(
  [
    (_state, { sectionId }) => sectionId,
    (_state, { currentUrl }) => currentUrl,
    (_state, { blockId }) => blockId,
    (state, { bootcampId, pathId, sectionId, unitId, currentUser }) =>
      selectUrlObjectsForBootcampPath(state, bootcampId, pathId, sectionId, unitId, currentUser),
  ],
  (sectionId, currentUrl, blockId, allUrls) => {
    const allUrlIndex = _findIndex(allUrls, ['url', currentUrl]);

    const sectionUrls = _filter(allUrls, { sectionId });
    const sectionUrlIndex = _findIndex(sectionUrls, ['url', currentUrl]);

    sectionUrls.forEach((url, index) => {
      url.index = index + 1;
      url.total = sectionUrls.length;
    });

    if (sectionUrlIndex === -1) {
      return {};
    }

    const currentUrlObj = sectionUrls[sectionUrlIndex];

    if (blockId) {
      currentUrlObj.url = `${currentUrlObj.url}#block_${blockId}`;
    }

    const previousUrlObj = sectionUrlIndex > 0 ? sectionUrls[sectionUrlIndex - 1] : null;
    const nextUrlObj = sectionUrlIndex + 1 < _size(sectionUrls) ? sectionUrls[sectionUrlIndex + 1] : null;
    // get the next section url only when on the last item of the current section
    const nextSectionUrlObj = nextUrlObj === null && allUrlIndex + 1 < _size(allUrls) ? allUrls[allUrlIndex + 1] : null;

    return {
      current: currentUrlObj,
      next: nextUrlObj,
      previous: previousUrlObj,
      nextSection: nextSectionUrlObj,
    };
  },
);
