import axios from './axios';

export default class BootcampInstanceSection {
  static all(_, bootcampInstanceId) {
    return axios
      .get('/assessments/bootcamp_instance_sections', {
        params: { bootcamp_instance_id: bootcampInstanceId },
      })
      .then(response => response.data);
  }

  static get(_, sectionId, bootcampInstanceId) {
    return axios
      .get(`/assessments/bootcamp_instance_sections/${bootcampInstanceId}/${sectionId}`)
      .then(response => response.data);
  }

  static save(_, bootcampInstanceSection) {
    return axios
      .post('/assessments/bootcamp_instance_sections', { ...bootcampInstanceSection })
      .then(response => response.data);
  }

  static update(_, bootcampInstanceSection) {
    return axios
      .put(`/assessments/bootcamp_instance_sections/${bootcampInstanceSection.id}`, bootcampInstanceSection)
      .then(response => response.data);
  }
}
