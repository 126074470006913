import { findIndex as _findIndex, map as _map, max as _max, groupBy as _groupBy } from 'lodash';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { selectProgressForLesson } from '../../selectors/lessonProgressSelectors';
import useLesson from '../../hooks/useLesson';
import LessonPart from './LessonPart';
import { PopupImageWrapper } from '../Utils/Popup';
import { selectIsEditMode } from '../../selectors/contextSelectors';
import { scrollToPart } from '../../helpers/lessonHelper';
import { getProgressStatus } from '../../utils/utils';
import AddPart from '../Author/Blocks/AddPart';
import useCurrentUser from '../../hooks/useCurrentUser';
import { reorderParts } from '../../actions/partActions';
import Block from '../../api/block';
import Icons from '../Utils/Icons';
import * as analytics from '../../utils/analytics';
import { selectCohortForEnrollment } from '../../selectors/cohortSelectors';
import { scroller } from 'react-scroll';

const handleDisableOnSelect = src => {
  const gifReg = /.gif$/i;
  return gifReg.test(src);
};
/* eslint-disable complexity */
const LessonParts = ({ lesson, adaptiveAttributes }) => {
  const dispatch = useDispatch();
  const { isStudent } = useCurrentUser();
  const { parts, blocks, blocksByPart } = useLesson(lesson && lesson.id);
  const lessonProgress = useSelector(s => (lesson ? selectProgressForLesson(s, lesson.id) : {}));
  const isEditMode = useSelector(selectIsEditMode);
  const currentPartId = (lessonProgress && lessonProgress.current_part_id) || (parts[0] && parts[0].id);
  const currentPartIndex = _findIndex(parts, { id: currentPartId });
  const { isComplete: isLessonComplete } = getProgressStatus(lessonProgress);
  const maxSeq = _max(_map(parts, 'seq_num')) || 0;
  const enrollmentId = lessonProgress?.context;
  const cohort = useSelector(s => (enrollmentId ? selectCohortForEnrollment(s, enrollmentId) : {}));

  const filterByAdaptiveAttribute = track => {
    return blocks.filter(block => block.adaptive_tracks?.some(val => val === track));
  };

  const defaultTrackObject =
    Array.isArray(adaptiveAttributes) && adaptiveAttributes.length > 0
      ? Block.ADAPTIVE_TRACKS[adaptiveAttributes[0]]
      : '';
  const [bannerState, setBannerState] = useState(defaultTrackObject);

  const defaultDisplayBlocks =
    Array.isArray(adaptiveAttributes) && adaptiveAttributes.length > 0
      ? filterByAdaptiveAttribute(adaptiveAttributes[0])
      : blocks;
  const [displayBlocks, setDisplayBlocks] = useState(defaultDisplayBlocks);

  useEffect(() => {
    if (isStudent && !isLessonComplete) {
      scrollToPart(currentPartId, parts[0].id, lesson.adaptive_learning_on);
    }
  }, [currentPartId, isStudent, lesson]);

  useEffect(() => {
    setBannerState(defaultTrackObject);
    setDisplayBlocks(defaultDisplayBlocks);
  }, [lesson]);

  const handleReorder = (oldPosition, newPosition) => {
    const partIds = _map(parts, 'id');
    const removed = partIds.splice(oldPosition, 1);
    partIds.splice(newPosition, 0, removed[0]);

    dispatch(reorderParts(partIds, lesson));
  };

  const handleClick = (e, track, trackObject) => {
    const filteredBlocks = filterByAdaptiveAttribute(track);
    setDisplayBlocks(filteredBlocks);
    setBannerState(trackObject);
    analytics.track('Change Adaptive Track', {
      ...analytics.getPageParams({ lesson, cohort }),
      adaptive_track_selected: trackObject?.name,
    });
  };

  const organizeByPart = _groupBy(displayBlocks, 'part_id');

  const pathIcon = trackObject => {
    return trackObject.class === 'beginner' ? (
      <Icons.Search1 className="icon" />
    ) : trackObject.class === 'intermediate' ? (
      <Icons.Badge className="icon" />
    ) : (
      <Icons.Gauge className="icon" />
    );
  };

  const noParts = parts.length <= 1;

  const scrollToTop = () => {
    scroller.scrollTo('adaptive-buttons', {
      smooth: true,
      offset: -280,
    });

    analytics.track('Scroll to Adaptive Buttons', {
      ...analytics.getPageParams({ lesson, cohort }),
    });
  };

  return (
    <PopupImageWrapper enabled={!isEditMode} disableOnSelect={handleDisableOnSelect}>
      {lesson.adaptive_learning_on && bannerState && isStudent && (
        <div className={`adaptive-banner-container ${noParts ? 'adaptive-learning-shorter-banner' : ''}`}>
          <div className={`adaptive-learning-banner ${bannerState.class}`}>
            <div className="adaptive-learning-banner-content">
              <div className="track-label">
                {pathIcon(bannerState)}
                {bannerState.label}
              </div>
              <span className="change-track-button" onClick={scrollToTop}>
                Change Track
              </span>
            </div>
          </div>
        </div>
      )}
      <div className="adaptive-learning-student" name="adaptive-buttons">
        {lesson.adaptive_learning_on &&
          isStudent &&
          bannerState &&
          Object.entries(Block.ADAPTIVE_TRACKS).map(([trackKey, trackObject]) => {
            let active = bannerState.name === trackObject.name;
            if (adaptiveAttributes.includes(trackKey)) {
              return (
                <button
                  key={trackObject.index}
                  onClick={e => handleClick(e, trackKey, trackObject)}
                  className={
                    active
                      ? `adaptive-learning-buttons ${trackObject.class} banner-spacing active`
                      : `adaptive-learning-buttons ${trackObject.class} banner-spacing`
                  }>
                  {pathIcon(trackObject)}
                  {trackObject.label}
                </button>
              );
            }
          })}
      </div>

      {parts.map((part, partIndex) => (
        <LessonPart
          key={part.id}
          part={part}
          partIndex={partIndex}
          totalParts={parts.length}
          lesson={lesson}
          lessonProgress={lessonProgress}
          blocks={isStudent ? organizeByPart[part.id] : blocksByPart[part.id] || []}
          currentPartId={currentPartId}
          isBeforeCurrentPart={partIndex < currentPartIndex}
          onPartReorder={handleReorder}
        />
      ))}
      <AddPart lesson={lesson} seqNum={maxSeq + 1} />
    </PopupImageWrapper>
  );
};
/* eslint-enable complexity */
LessonParts.propTypes = {
  lesson: PropTypes.object,
  adaptiveAttributes: PropTypes.array,
};

export default LessonParts;
