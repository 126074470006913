import { isEmpty as _isEmpty } from 'lodash';
import Cohort from '../api/cohort';
import {
  applicationSave,
  applicationFetchAll,
  applicationDestroy,
  applicationFetch,
  applicationApiExecute,
} from './applicationActions';
import { fetchCurrentTenant } from './tenantActions';
import { getUsers } from './userActions';

export const saveCohort = applicationSave(Cohort);
export const fetchCohorts = applicationFetchAll(Cohort);
export const destroyCohort = applicationDestroy(Cohort);
export const fetchCohort = applicationFetch(Cohort);
export const addUser = applicationApiExecute(Cohort.addUser);
export const removeUser = applicationApiExecute(Cohort.removeUser);
export const forUser = applicationApiExecute(Cohort.forUser);

export const getCohortUsers = (cohortId, role) => async dispatch => {
  const tenant = await fetchCurrentTenant()(dispatch);
  const userIds = await Cohort.userIds(tenant.id, cohortId, role);
  return _isEmpty(userIds) ? [] : getUsers(userIds)(dispatch);
};
