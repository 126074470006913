import { map as _map } from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import classnames from 'classnames';
import ActionButtons from '../Utils/ActionButtons';
import EditUnitModalContainer from '../Author/Paths/EditUnitModalContainer';
import showModal from '../../utils/showModal';
import { confirmInput } from '../../utils/confirmInput';
import EditComponentModal from '../Author/Components/EditComponentModal';
import CopyComponentModalContainer from '../Author/Components/CopyComponentModalContainer';
import { getProgressStatus } from '../../utils/utils';
import ComponentItemContainer from '../Components/ComponentItemContainer';
import Icons from '../Utils/Icons';
import ReorderButtons from '../Utils/ReorderButtons';

export default class ModuleTile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isExpanded: this.expandIfNeeded(this.props, true),
    };
  }

  expandIfNeeded = (props, fromConstructor) => {
    const { unitProgress, currentUnitProgress, isCurrentSectionSelected } = props;
    if (isCurrentSectionSelected && unitProgress && currentUnitProgress && unitProgress.id === currentUnitProgress.id) {
      if (!fromConstructor) {
        this.setState({
          isExpanded: true,
        });
      }
      return true;
    }
    return false;
  };

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(props) {
    this.expandIfNeeded(props);
  }

  handleEdit = () => {
    showModal(EditUnitModalContainer, {
      section: this.props.section,
      unit: this.props.unit,
      onSave: this.props.saveUnit,
    });
  };

  handleDelete = () => {
    const { section, unit } = this.props;
    const message = <FormattedMessage id="unit.confirmModuleRemove" values={{ name: unit.title }} />;

    confirmInput({
      message,
      onOk: () => {
        this.props.destroySectionUnit(unit, section.id);
      },
    });
  };

  handleToggleExpand = e => {
    e.preventDefault();

    const { isExpanded } = this.state;
    this.setState({
      isExpanded: !isExpanded,
    });
  };

  editComponent = component => {
    const { match, history, unit, section } = this.props;
    showModal(EditComponentModal, {
      component,
      onSave: this.props.saveComponent,
      unit,
      section,
      history,
      match,
    });
  };

  copyComponent = unitId => showModal(CopyComponentModalContainer, { unitId });

  componentProps = component => {
    const { currentComponentProgress, progressesByComponentId, unit, section, components } = this.props;
    const componentProgress = progressesByComponentId[component.id];

    return {
      component,
      currentComponentProgress,
      unit,
      section,
      unitId: unit.id,
      editComponent: this.editComponent,
      componentProgress,
      totalComponents: components.length,
      onReorder: this.handleReorder,
    };
  };

  handleReorder = (oldPosition, newPosition) => {
    const { unit, components, reorderComponents } = this.props;
    const componentIds = _map(components, 'id');
    const removed = componentIds.splice(oldPosition, 1);
    componentIds.splice(newPosition, 0, removed[0]);

    reorderComponents(componentIds, unit);
  };

  renderComponents = () => {
    const { components, unit, isEditMode } = this.props;

    if (isEditMode) {
      return (
        <>
          <button
            type="button"
            className="authoring-button small"
            onClick={() => this.editComponent({ unit_id: unit.id })}>
            <FormattedMessage id="component.add" />
          </button>
          <button type="button" className="authoring-button small" onClick={() => this.copyComponent(unit.id)}>
            <FormattedMessage id="component.copy" />
          </button>
          {components.map((component, index) => (
            <ComponentItemContainer key={component.id} index={index} {...this.componentProps(component)} />
          ))}
        </>
      );
    }

    return components.map((component, index) => (
      <ComponentItemContainer key={component.id} index={index} {...this.componentProps(component)} />
    ));
  };

  handleKeyPress = event => {
    if (event.key === 'Enter') {
      this.handleToggleExpand(event);
    }
  };

  // eslint-disable-next-line complexity
  render() {
    const { unit, unitProgress, completedCounts, isEditMode, hasLab, unitIndex, totalUnits } = this.props;
    const { isExpanded } = this.state;
    const { isComplete, inProgress } = getProgressStatus(unitProgress);
    const unitClassName = classnames(
      'units-group',
      (inProgress || isComplete) && 'units-group--touched',
      inProgress && 'units-group--in-progress',
    );
    const headingClass = classnames(
      'units-group__heading',
      isComplete && 'units-group__heading--completed',
      isExpanded && 'units-group__heading--expanded',
    );
    const iconClass = classnames(
      'units-group__icon',
      inProgress && 'units-group__icon--in-progress',
      isComplete && 'units-group__icon--completed',
    );

    return (
      <div className={unitClassName} aria-expanded={isExpanded}>
        <div className="units-group-heading">
          <div
            className={headingClass}
            onClick={this.handleToggleExpand}
            tabIndex={0}
            aria-expanded={isExpanded}
            onKeyPress={this.handleKeyPress}
            role="button">
            <div className="units-group-left">
              <span className={iconClass}>
                <Icons.Layers />
              </span>
              <div
                className={classnames('units-group-title', (inProgress || isComplete) && 'units-group-title--accent')}>
                {unit.title}
              </div>
            </div>
            <div className="units-group-right">
              {hasLab && (
                <div className="units-group__lesson-icon">
                  <div className="icon-element icon-element--fill-transparent icon-element--background-transparent  icon-element--size-small">
                    <Icons.ElixirSmall />
                  </div>
                </div>
              )}
              {completedCounts && (
                <span className="units-group__count units-group__count--expanded units-group__count--accent">
                  <FormattedMessage id="unit.completed" values={completedCounts} />
                </span>
              )}
              {isEditMode && (
                <>
                  <div className="learning-path-item-reorder-button">
                    <ReorderButtons index={unitIndex} total={totalUnits} onReorder={this.props.onUnitsReorder} />
                  </div>
                  <span className="learning-path-module-authoring" style={{ marginLeft: 20 }}>
                    <ActionButtons
                      isEditMode
                      className="authoring-edit-delete-button"
                      onEdit={this.handleEdit}
                      onDelete={this.handleDelete}
                      showRemove
                    />
                  </span>
                </>
              )}
            </div>
          </div>
        </div>
        <div className="units-group__items">{isExpanded && this.renderComponents()}</div>
      </div>
    );
  }
}

ModuleTile.propTypes = {
  section: PropTypes.object.isRequired,
  unit: PropTypes.object.isRequired,
  unitProgress: PropTypes.object,
  currentUnitProgress: PropTypes.object,
  isCurrentSectionSelected: PropTypes.bool,
  components: PropTypes.array,
  saveUnit: PropTypes.func.isRequired,
  destroySectionUnit: PropTypes.func.isRequired,
  isEditMode: PropTypes.bool,
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  saveComponent: PropTypes.func.isRequired,
  reorderComponents: PropTypes.func.isRequired,
  onUnitsReorder: PropTypes.func.isRequired,
  currentComponentProgress: PropTypes.object,
  progressesByComponentId: PropTypes.object,
  completedCounts: PropTypes.object,
  hasLab: PropTypes.bool,
  unitIndex: PropTypes.number,
  totalUnits: PropTypes.number,
};
