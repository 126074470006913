import { getDataSourcePath } from './dataSourceHelpers';
import { evaluator, sheetNotFound } from './evalHelpers';

// eslint-disable-next-line complexity
const evalSheets = ({ data: workbook, location, property, operator, value }) => {
  const { sheets } = workbook;
  // Get the specific location (one before the last one)
  const { type, order, name } = location[0].dataSource;
  let prop = null;

  // locate path based on dataSource
  const sheetLocator = getDataSourcePath({ type, order, name });
  const sheet = sheetLocator(sheets);
  if (!sheet) return sheetNotFound(property, operator, value);

  switch (property) {
    case 'exists':
      return !!sheet;
    case 'name':
      if (type === 'byAny' || type === 'byAll') {
        return sheetLocator(sheets)(sh => {
          if (operator === 'contains') {
            if (sh.name.includes(value)) return true;
          } else if (operator === 'does not contain') {
            if (!sh.name.includes(value)) return true;
          } else {
            prop = sh.name;
            return evaluator(prop, operator, value);
          }
          return false;
        });
      }
      if (type === 'byOrder' || type === 'byName') {
        prop = sheet.name;
        if (operator === 'contains') {
          if (prop.includes(value)) return true;
        } else if (operator === 'does not contain') {
          if (!prop.includes(value)) return true;
        }
        return evaluator(prop, operator, value);
      }
      return false;
    case 'numberOfCols':
      if (type === 'byAny' || type === 'byAll') {
        return sheetLocator(sheets)(sh => {
          prop = sh.dataset[0].columnHeaders.length;
          return evaluator(prop, operator, value);
        });
      }
      if (type === 'byOrder' || type === 'byName') {
        const sheetDataset = sheet.dataset[0];
        // Note: assuming only one table to calculate number of columns in a sheet
        prop = sheetDataset.columnHeaders.length;
        return evaluator(prop, operator, value);
      }
      return false;
    case 'numberOfRows':
      if (type === 'byOrder' || type === 'byName') {
        const sheetDataset = sheet.dataset[0];
        prop = sheetDataset.rows.length;
        return evaluator(prop, operator, value);
      }
      if (type === 'byAny' || type === 'byAll') {
        return sheetLocator(sheets)(sh => {
          prop = sh.dataset[0].rows.length;
          return evaluator(prop, operator, value);
        });
      }
      return false;
    case 'numberOfCharts':
      if (type === 'byOrder' || type === 'byName') {
        prop = sheet.charts && sheet.charts.length;
        return evaluator(prop, operator, value);
      }
      if (type === 'byAny' || type === 'byAll') {
        return sheetLocator(sheets)(sh => {
          prop = sh.charts && sh.charts.length;
          return evaluator(prop, operator, value);
        });
      }
      return false;
    case 'numberOfPivotTables':
      if (type === 'byOrder' || type === 'byName') {
        prop = sheet.pivotTables && sheet.pivotTables.length;
        return evaluator(prop, operator, value);
      }
      if (type === 'byAny' || type === 'byAll') {
        return sheetLocator(sheets)(sh => {
          prop = sh.pivotTables && sh.pivotTables.length;
          return evaluator(prop, operator, value);
        });
      }
      return false;
    default:
      return null;
  }
};

export default evalSheets;
