import { connect } from 'react-redux';
import BootcampList from './BootcampList';
import { fetchBootcamps, setSearchText, saveBootcamp, destroyBootcamp } from '../../../actions/bootcampActions';
import { selectBootcamps } from '../../../selectors/bootcampSelectors';
import { selectIsEditMode } from '../../../selectors/contextSelectors';
import { filterBootcamps } from '../../../helpers/bootcampHelper';

const mapStateToProps = state => {
  const bootcamps = filterBootcamps(selectBootcamps(state), state.bootcamp.searchText);
  const isEditMode = selectIsEditMode(state);

  return {
    bootcamps,
    isEditMode,
  };
};

export default connect(mapStateToProps, { fetchBootcamps, setSearchText, saveBootcamp, destroyBootcamp })(BootcampList);
