import React, { Component } from 'react';
import PropTypes from 'prop-types';
import EditBootcampModal from './EditBootcampModal/EditBootcampModal';
import showModal from '../../../utils/showModal';
import AdminWrapper from '../../v2/Nav/Admin/AdminWrapper';
import BootcampListItemContainer from '../../Bootcamp/BootcampListItemContainer';
import Loading from '../../Utils/Loading';
import * as analytics from '../../../utils/analytics';
import { setPageTitle } from '../../../helpers/pageHelper';
import BootcampSearch from './BootcampSearch';
import intl from '../../../utils/intl';
import Icons from '../../Utils/Icons';

export default class BootcampList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
    };
  }

  componentDidMount() {
    if (!this.state.isLoading) {
      this.setState({ isLoading: true });
      this.props.fetchBootcamps().then(() => this.setState({ isLoading: false }));
    }
    analytics.pageStart('Course List - Author');
    setPageTitle('pageName.course.list');
  }

  componentWillUnmount() {
    analytics.pageEnd();
  }

  handleCreate = () => {
    showModal(EditBootcampModal, {
      bootcamp: { show_progress: true },
    });
  };

  render() {
    const { isEditMode } = this.props;
    const { isLoading } = this.state;

    return (
      <AdminWrapper headerTitle={intl.formatMessage({ id: 'nav.bootcamps' })} hideAuthorToggle={false}>
        <BootcampSearch />

        {isLoading && <Loading />}

        <div className="grid-row">
          <div className="sm-12">
            <div className="courses-list">
              {this.props.bootcamps.map(bootcamp => (
                <BootcampListItemContainer key={bootcamp.id} bootcamp={bootcamp} />
              ))}
              {isEditMode && (
                <div className="courses-list__item">
                  <div className="course-card-new-wrapper">
                    <div className="course-card-new">
                      <button
                        type="button"
                        aria-label={intl.formatMessage({ id: 'bootcamp.create' })}
                        className="course-card-new__button"
                        onClick={this.handleCreate}>
                        <div className="icon-element icon-element--fill-white icon-element--background-blue icon-element--button icon-element--size-">
                          <Icons.Plus />
                        </div>
                      </button>
                      <span className="course-card-new__label">{intl.formatMessage({ id: 'bootcamp.create' })}</span>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </AdminWrapper>
    );
  }
}

BootcampList.propTypes = {
  isEditMode: PropTypes.bool,
  bootcamps: PropTypes.array,
  fetchBootcamps: PropTypes.func.isRequired,
};
