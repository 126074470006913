import axios from './axios';

export default class LtiConsumer {
  static async all(_) {
    const response = await axios.get('/users/lti_consumers');
    return response.data;
  }

  static async save(ltiConsumer) {
    let response;
    const params = {
      lti_consumer: ltiConsumer,
    };
    if (ltiConsumer.id) {
      response = await axios.put(`/users/lti_consumers/${ltiConsumer.id}`, params);
    } else {
      response = await axios.post('/users/lti_consumers', params);
    }
    return response.data;
  }
}
