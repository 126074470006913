import { map as _map } from 'lodash';
import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Assignment from '../../api/assignment';
import { selectOldestEnabledCriteriaByAssignment } from '../../selectors/criterionSelectors';

export default function Rubric({ assignment }) {
  const rubricCriteria = useSelector(state => selectOldestEnabledCriteriaByAssignment(state, assignment));

  return (
    <table className="rubric">
      <thead>
        <tr>
          <th scope="col"></th>
          {_map(Assignment.CRITERION_PROFICIENCY_LEVELS, attributeName => (
            <th key={attributeName} scope="col">
              <FormattedMessage id={`rubricCriteria.fields.${attributeName}`} />
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {_map(rubricCriteria, (criterion, row) => (
          <tr key={criterion.id}>
            <td>
              <p className="number-list">{row + 1}</p>
              <p className="criterion-name">{criterion.name}</p>
            </td>
            {_map(Assignment.CRITERION_PROFICIENCY_LEVELS, attributeName => (
              <td key={attributeName}>
                <p className="rubric-text">{criterion[attributeName]}</p>
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
}

Rubric.propTypes = {
  assignment: PropTypes.object.isRequired,
};
