import axios from './axios';

export default class Lti {
  static async signParams(payload) {
    const response = await axios.post('/users/lti/sign_params', payload);
    return response.data;
  }

  static async fetchLineItems(tenantId, consumerKey, lineItemsUrl) {
    const response = await axios.get('/users/lti/v13/line_items', {
      params: { consumer_key: consumerKey, line_items_url: lineItemsUrl },
    });
    return response.data;
  }
}
