import { normalize } from 'normalizr';
import Lesson from '../api/lesson';
import Schemas from '../api/schemas';
import { updateEntities } from './entityActions';
import { fetchCurrentTenant } from './tenantActions';
import { applicationFetch, applicationSave } from './applicationActions';

export const fetchLesson = applicationFetch(Lesson);
export const saveLesson = applicationSave(Lesson);

export const fetchLessons = () => (dispatch, getState) => {
  const { entities } = getState();

  console.log('Fetching Lessons');

  if (entities && Object.keys(entities.lessons).length > 0) {
    return Promise.resolve();
  }

  return fetchCurrentTenant()(dispatch).then(tenant =>
    Lesson.all(tenant.id).then(response => {
      const { entities } = normalize(response.entities, Schemas.entities);
      dispatch(updateEntities({ entities }));
    }),
  );
};
