import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import intl from '../../utils/intl';

const QuestionResponse = ({
  renderQuestionsNavigation,
  handleAnswerQuestion,
  isCurrentQuestionAnswered,
  isLastQuestion,
}) => {
  return (
    <div className="answer-buttons">
      <div>{renderQuestionsNavigation && renderQuestionsNavigation()}</div>
      <button
        type="button"
        onClick={handleAnswerQuestion}
        aria-label={intl.formatMessage({ id: 'question.submitAnswer' })}
        disabled={!isCurrentQuestionAnswered()}
        className="button-standard button-standard--blue-9 button-standard--short">
        {isLastQuestion ? (
          <FormattedMessage id="question.review" description="Review" />
        ) : (
          <FormattedMessage id="question.next" description="Next" />
        )}
      </button>
    </div>
  );
};

QuestionResponse.propTypes = {
  renderQuestionsNavigation: PropTypes.func,
  handleAnswerQuestion: PropTypes.func,
  isCurrentQuestionAnswered: PropTypes.func,
  isLastQuestion: PropTypes.bool,
};

export default QuestionResponse;
