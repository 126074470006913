import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { isEmpty as _isEmpty } from 'lodash';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { Tooltip } from 'antd';
import { FormattedMessage } from 'react-intl';
import classnames from 'classnames';

import { selectAssignmentProgress } from '../../../../../selectors/assignmentProgressSelectors';
import { selectUser } from '../../../../../selectors/userSelectors';
import { selectUnitProgressByAssignmentProgressId } from '../../../../../selectors/unitProgressSelectors';
import { selectAssignment } from '../../../../../selectors/assignmentSelectors';
import UserPicture from '../../../../Utils/UserPicture';
import intl from '../../../../../utils/intl';
import { confirmInput } from '../../../../../utils/confirmInput';
import { allowResubmission as allowProjectResubmission } from '../../../../../actions/unitProgressActions';
import { allowResubmission as allowAssignmentResubmission } from '../../../../../actions/assignmentProgressActions';
import { toastError, toastSuccess } from '../../../../../utils/toastHelper';
import { isGsuiteApp } from '../../../../../helpers/labHelper';
import Stream from '../../../../../api/stream';

const useStore = assignmentProgressId => {
  const assignmentProgress = useSelector(state => selectAssignmentProgress(state, assignmentProgressId));
  const user = useSelector(state => selectUser(state, assignmentProgress?.user_id));
  const unitProgress = useSelector(state => selectUnitProgressByAssignmentProgressId(state, assignmentProgressId));
  const assignment = useSelector(state => selectAssignment(state, assignmentProgress?.assignment_id));

  return {
    assignmentProgress,
    user,
    unitProgress,
    assignment,
  };
};

const getSubmissionText = (completedAt, scoreHistory) => {
  const completedAtMoment = moment(completedAt);
  const time = completedAtMoment.format('h:mm a');
  const date = completedAtMoment.format('MMMM D');

  const textId = _isEmpty(scoreHistory)
    ? 'cohortDashboard.assignmentsPage.submittedAt'
    : 'cohortDashboard.assignmentsPage.resubmittedAt';
  return intl.formatMessage({ id: textId }, { time, date });
};

const getGradeButtonText = (requiresGrading, isGraded) => {
  if (requiresGrading) {
    if (isGraded) {
      return intl.formatMessage({ id: 'grading.regrade' });
    }

    return intl.formatMessage({ id: 'grading.grade' });
  }

  return intl.formatMessage({ id: 'grading.view' });
};

const SubmissionCard = ({ assignmentProgressId, isWatched }) => {
  const dispatch = useDispatch();
  const { assignmentProgress, user, unitProgress, assignment } = useStore(assignmentProgressId);

  const userName = user?.name;
  const submissionText =
    assignmentProgress && getSubmissionText(assignmentProgress.completed_at, assignmentProgress.score_history);
  const tooltip = !assignmentProgress?.requires_grading && intl.formatMessage({ id: 'grading.viewTooltip' });
  const gradeUrl = `/coach/grade/${unitProgress?.id}?isGrading=true`;
  const gradeButtonText = getGradeButtonText(assignmentProgress.requires_grading, !!assignmentProgress.graded_at);
  const showAllowResubmissions = assignment && assignmentProgress && unitProgress;

  const handleResubmissionClick = () => {
    confirmInput({
      message: <FormattedMessage id="grading.confirmResubmission" />,
      onOk: async () => {
        try {
          if (isGsuiteApp(assignment.app)) {
            // make the project editable
            await Stream.updateAccess(assignmentProgress.user_project_url, true, assignment.app);
          }

          await Promise.all([
            allowAssignmentResubmission(assignmentProgress.id)(dispatch),
            allowProjectResubmission(unitProgress.id)(dispatch),
          ]);

          toastSuccess(intl.formatMessage({ id: 'grading.resubmissionSuccess' }));
        } catch (e) {
          // eslint-disable-next-line no-console
          console.error('Error marking project for resubmission', e);
          toastError(intl.formatMessage({ id: 'grading.resubmissionFailure' }));
        }
      },
    });
  };

  const avatarClassName = classnames('student-avatar', { 'student-avatar__watched': isWatched });
  const showScore = assignmentProgress?.requires_grading && assignmentProgress.score !== null;

  return (
    <div className="submission-card" data-testid="submission-card">
      <div className="submission-info">
        <div className={avatarClassName} data-testid="student-avatar">
          <UserPicture user={user} className="user-profile-picture" />
        </div>
        <div className="name-submission">
          <div className="student-name">{userName}</div>
          <div className="submitted-at">{submissionText}</div>
        </div>
        {showScore && (
          <div className="submission-score">
            <div className="grade-header">
              <FormattedMessage id="grading.grade" />
            </div>
            <div className="grade-score" data-testid="grade-score">
              {assignmentProgress.score}%
            </div>
          </div>
        )}
      </div>

      <div className="submission-actions">
        {showAllowResubmissions && (
          <button type="button" className="allow-resubmit-button" onClick={handleResubmissionClick}>
            <FormattedMessage id="cohortDashboard.assignmentsPage.allowResubmissions" />
          </button>
        )}

        {assignmentProgress && (
          <Tooltip title={tooltip}>
            <Link to={gradeUrl} className="grade-button">
              {gradeButtonText}
            </Link>
          </Tooltip>
        )}
      </div>
    </div>
  );
};

SubmissionCard.propTypes = {
  assignmentProgressId: PropTypes.string.isRequired,
  isWatched: PropTypes.bool,
};

export default SubmissionCard;
