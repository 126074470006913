// TODO: dynamic load typeform lib

import React from 'react';
import { useSelector } from 'react-redux';
import { selectIsEditMode } from '../../../selectors/contextSelectors';
import PropTypes from 'prop-types';
import { Show } from './Show';
import { Edit } from './Edit';

const TypeformPresenter = ({ block }) => {
  const isEditMode = useSelector(selectIsEditMode);

  return isEditMode ? <Edit block={block} /> : <Show block={block} />;
};

export const Typeform = ({ block }) => (block ? <TypeformPresenter block={block} /> : null);

Typeform.propTypes = {
  block: PropTypes.shape({
    height: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
  }),
};
