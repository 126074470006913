import { connect } from 'react-redux';
import EditQuizQuestion from './EditQuizQuestion';
import { saveQuestion } from '../../../actions/questionActions';
import { selectBlockByQuestion } from '../../../selectors/blockSelectors';

const mapStateToProps = (state, ownProps) => ({
  blockId: selectBlockByQuestion(state, ownProps.question)?.id,
});

export default connect(mapStateToProps, { saveQuestion })(EditQuizQuestion);
