import { useEffect } from 'react';
import { scroller } from 'react-scroll';
import { useLocation } from 'react-router-dom';

/**
 * Scroll to an element if it has been added to the window location (much like normal html)
 * @param {string} elementName - Should be the name on a react-scroll Element tag or
 * the id of a div (though note the div tag has not yet been tested)
 */
export default function useElementScrolling(elementName, options = {}) {
  const scrollOptions = { ...{ duration: 500, smooth: true }, ...options };
  const location = useLocation();

  useEffect(() => {
    if (location.hash === `#${elementName}`) {
      scroller.scrollTo(elementName, scrollOptions);
    }
  }, [elementName]);
}
