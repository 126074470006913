import { findIndex as _findIndex } from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import Tabs from '../Utils/Tabs';
import ComponentTab from './ComponentTab';

export default function ComponentTabs({ componentsCompleted, componentsWithLab, currentComponent, components }) {
  return (
    <Tabs
      className="header-bar__tabs-wrapper"
      items={components.map(component => (
        <ComponentTab
          key={component.id}
          component={component}
          hasLab={!!componentsWithLab[component.id]}
          isComplete={!!componentsCompleted[component.id]}
          isCurrent={component.id === currentComponent.id}
        />
      ))}
      wrapperTag="ul"
      wrapperClass="header-bar__tabs"
      prevBtnClass="header-bar__prev-button"
      nextBtnClass="header-bar__next-button"
      selectedIndex={_findIndex(components, { id: currentComponent && currentComponent.id })}
    />
  );
}

ComponentTabs.propTypes = {
  componentsCompleted: PropTypes.object,
  componentsWithLab: PropTypes.object,
  currentComponent: PropTypes.object,
  components: PropTypes.array,
};
