import { useState, useEffect } from 'react';
import Lti from '../api/lti';
import { getOauthConsumerKey, lineItemsUrl } from '../helpers/ltiParams';

export default function useLtiLineItems(tenantId) {
  const [lineItems, setLineItems] = useState([]);

  async function fetchLineItems() {
    const response = await Lti.fetchLineItems(tenantId, getOauthConsumerKey(), lineItemsUrl());
    setLineItems(response);
  }

  useEffect(() => {
    fetchLineItems();
  }, [tenantId]);

  return lineItems;
}
