import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { find as _find, orderBy as _orderBy } from 'lodash';
import { FormattedMessage } from 'react-intl';

import { selectBootcamps } from '../../../selectors/bootcampSelectors';
import { selectCohorts } from '../../../selectors/cohortSelectors';
import { fetchCohorts } from '../../../actions/cohortActions';
import Loading from '../../Utils/Loading';
import * as analytics from '../../../utils/analytics';
import { setPageTitle } from '../../../helpers/pageHelper';
import CohortGroup from './CohortGroup';
import { isCohortCompleted } from './CohortItem/helpers';

const groupCohorts = (bootcamps, cohorts) => {
  const activeCohortsByTitle = {};
  const completedCohortsByTitle = {};

  cohorts.forEach(cohort => {
    const { bootcamp_id: bootcampId, display_cohort_title: displayCohortTitle, cohort_title: cohortTitle } = cohort;

    const bootcamp = _find(bootcamps, { id: bootcampId });

    if (!bootcamp) {
      return;
    }

    const cohortsByTitle = isCohortCompleted(cohort) ? completedCohortsByTitle : activeCohortsByTitle;
    // Group by course title or "College Partner Course Name" (cohort title)
    const title = displayCohortTitle ? cohortTitle : bootcamp.title;

    if (!cohortsByTitle[title]) cohortsByTitle[title] = [];

    cohortsByTitle[title].push(cohort);
  });

  return { activeCohortsByTitle, completedCohortsByTitle };
};

const useDashboardStates = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [activeCohortsByTitle, setActiveCohortsByTitle] = useState({});
  const [completedCohortsByTitle, setCompletedCohortsByTitle] = useState({});
  const [showCompletedCohorts, setShowCompletedCohorts] = useState(false);

  return {
    isLoading,
    setIsLoading,
    activeCohortsByTitle,
    setActiveCohortsByTitle,
    completedCohortsByTitle,
    setCompletedCohortsByTitle,
    showCompletedCohorts,
    setShowCompletedCohorts,
  };
};

const CoachDashboardContent = () => {
  const dispatch = useDispatch();
  const bootcamps = useSelector(state => selectBootcamps(state));
  const cohorts = useSelector(state => selectCohorts(state));
  const {
    isLoading,
    setIsLoading,
    activeCohortsByTitle,
    setActiveCohortsByTitle,
    completedCohortsByTitle,
    setCompletedCohortsByTitle,
    showCompletedCohorts,
    setShowCompletedCohorts,
  } = useDashboardStates();

  useEffect(() => {
    if (!isLoading) {
      setIsLoading(true);

      (async () => {
        await fetchCohorts(true)(dispatch);
      })();

      setIsLoading(false);
    }

    analytics.pageStart('Dashboard - Coach');
    setPageTitle('pageName.instructorDashboard');

    return analytics.pageEnd;
  }, []);

  useEffect(() => {
    if (bootcamps.length > 0 && cohorts.length > 0) {
      const groupedCohorts = groupCohorts(bootcamps, cohorts);

      setActiveCohortsByTitle(groupedCohorts.activeCohortsByTitle);
      setCompletedCohortsByTitle(groupedCohorts.completedCohortsByTitle);
    }
  }, [bootcamps, cohorts]);

  const renderCohortGroups = (cohortsByTitle, reverseOrder = false) => {
    const dateOrder = reverseOrder ? 'desc' : 'asc';

    // Sort cohorts in each group by end date
    const tuplesWithSortedCohorts = Object.keys(cohortsByTitle).map(title => [
      title,
      _orderBy(cohortsByTitle[title], ['end_date', 'location'], [dateOrder, 'asc']),
    ]);

    // Sort groups by first cohort's end date
    const sortedTuples = _orderBy(
      tuplesWithSortedCohorts,
      [tuple => tuple[1][0].end_date, tuple => tuple[0]],
      [dateOrder, 'asc'],
    );

    return sortedTuples.map(tuple => {
      const [title, tupleCohorts] = tuple;

      return <CohortGroup key={title} title={title} cohorts={tupleCohorts} />;
    });
  };

  const handleShowCompleted = () => {
    setShowCompletedCohorts(true);
  };

  return (
    <div className="coach-dashboard">
      {isLoading && <Loading />}

      {renderCohortGroups(activeCohortsByTitle)}

      {showCompletedCohorts && renderCohortGroups(completedCohortsByTitle, true)}
      {!showCompletedCohorts && Object.keys(completedCohortsByTitle).length > 0 && (
        <button type="button" className="show-completed-button" onClick={handleShowCompleted}>
          <FormattedMessage id="cohort.showCompletedCohorts" />
        </button>
      )}
    </div>
  );
};

export default CoachDashboardContent;
