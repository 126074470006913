import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Redirect } from 'react-router-dom';
import { clearAllIntervals as clearAllSocketIntervals } from '../../sockets';

export default class SessionTimeoutWarning extends Component {
  componentDidMount() {
    this.timerID = setInterval(() => this.forceUpdate(), 1000);
  }

  componentWillUnmount() {
    clearInterval(this.timerID);
  }

  render() {
    const { timeoutEpoch, stop } = this.props;
    const timeoutSeconds = Math.round((timeoutEpoch - new Date()) / 1000);

    if (timeoutSeconds < 0) {
      clearAllSocketIntervals();
      return <Redirect to={{ pathname: '/login?expired=1' }} />;
    }

    return (
      <>
        <FormattedMessage id="common.sessionTimeoutWarning" values={{ seconds: timeoutSeconds }} />
        <button onClick={stop}>
          <FormattedMessage id="common.yes" />
        </button>
      </>
    );
  }
}
SessionTimeoutWarning.propTypes = {
  timeoutEpoch: PropTypes.number.isRequired,
  stop: PropTypes.func.isRequired,
};
