import { values as _values } from 'lodash';
import createCachedSelector from 're-reselect';

export const selectBlockProgressEntities = state => {
  return state.entities.blockProgresses;
};

export const selectProgressForBlock = createCachedSelector(
  [selectBlockProgressEntities, (_state, blockId) => blockId],
  (blockProgressEntities, blockId) => {
    return _values(blockProgressEntities).find(blockProgress => blockProgress.block_id === blockId);
  },
)((_state, blockId) => blockId);
