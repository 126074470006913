import { values as _values } from 'lodash';
import createCachedSelector from 're-reselect';
import { selectSectionProgressEntities } from './sectionProgressSelectors';
import { selectComponentProgressEntities } from './componentProgressSelectors';
import { selectUnitEntities } from './unitSelectors';
import { getProgressStatus } from '../utils/utils';
import { selectAll, selectAllFilterBy } from './applicationSelectors';

export const selectUnitProgressEntities = state => {
  return state.entities.unitProgresses;
};

export const selectUnitProgress = createCachedSelector(
  [selectUnitProgressEntities, (_state, unitProgressId) => unitProgressId],
  (unitProgressEntities, unitProgressId) => {
    return unitProgressEntities[unitProgressId];
  },
)((_state, unitProgressId) => unitProgressId);

export const selectProgressForUnit = createCachedSelector(
  [
    selectSectionProgressEntities,
    selectUnitProgressEntities,
    (_state, sectionId) => sectionId,
    (_state, _sectionId, unitId) => unitId,
  ],
  (sectionProgressEntities, unitProgressEntities, sectionId, unitId) => {
    const sectionProgress = _values(sectionProgressEntities).find(sp => sp.section_id === sectionId);

    if (!sectionProgress) {
      return;
    }

    return _values(unitProgressEntities).find(
      up => up.section_progress_id === sectionProgress.id && up.unit_id === unitId,
    );
  },
)((_state, sectionId, unitId) => `${sectionId}_${unitId}`);

export const selectCompletedCounts = createCachedSelector(
  [
    selectUnitProgressEntities,
    selectComponentProgressEntities,
    selectUnitEntities,
    (_state, unitProgressId) => unitProgressId,
  ],
  (unitProgressEntities, componentProgressEntities, unitEntities, unitProgressId) => {
    const unitProgress = unitProgressEntities[unitProgressId];

    if (!unitProgress) {
      return;
    }
    const unit = unitEntities[unitProgress.unit_id];
    const counts = {
      total: 0,
      completed: 0,
    };

    if (!unit) {
      return counts;
    }

    unit.component_ids.forEach(componentId => {
      const componentProgress = _values(componentProgressEntities).find(cp => cp.component_id === componentId);

      counts.total += 1;
      if (getProgressStatus(componentProgress).isComplete) {
        counts.completed += 1;
      }
    });

    return counts;
  },
)((_state, unitProgressId) => unitProgressId);

const selectUnitProgresses = selectAll(selectUnitProgressEntities);
const selectUnitProgressesByAssignmentProgressId = selectAllFilterBy(selectUnitProgresses, 'assignment_progress_id');
export const selectUnitProgressByAssignmentProgressId = (state, assignmentProgressId) => {
  return selectUnitProgressesByAssignmentProgressId(state, assignmentProgressId)[0];
};
