import React from 'react';
import './ReadOnlyWarning.scss';
import intl from '../../../utils/intl';

function ReadOnlyWarning() {
  return (
    <div className="read-only-warning" data-testid="read-only-warning">
      <div
        className="warning-title"
        dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'path.readOnlyWarning.title' }) }}
      />
      <div
        className="warning-text"
        dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'path.readOnlyWarning.body' }) }}
      />
    </div>
  );
}

export default ReadOnlyWarning;
