/* eslint-disable max-lines */
import { isEmpty as _isEmpty } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Prompt } from 'react-router-dom';
import ReactGA from 'react-ga';
import Stream from '../../api/stream';
import intl from '../../utils/intl';
import * as analytics from '../../utils/analytics';
import { usesRemoteVM } from '../../helpers/labHelper';
import useLabContext from '../../context/Lab/useLabContext';
import LabEmbed from './LabEmbed';
import { getFullStoryUrl } from '../../helpers/fullStoryHelper';

import {
  getCleaningUpOldSessionModalProps,
  getLabGotSleepyModalProps,
  getMachineHiccupModalProps,
  getNoMachinesAvailableModalProps,
  getOneLabAtATimeModalProps,
  LabErrorModal,
} from '../Labs/LabErrorModal/LabErrorModal';
import useCustomSiteLabs from '../../hooks/useCustomSiteLabs';

const HOSTED_LABS_RETRY_INTERVAL = 5 * 1000;

export default function StreamContainer(props) {
  const { app, launchIn, streamParams } = props;

  const { setIsReady: onReady, setErrorModalProps: onError, setIsVmUp } = useLabContext();
  const { shouldUseAppStreamFor } = useCustomSiteLabs();

  const [state, setState] = useState({
    streamingUrl: props.streamingUrl,
    error: null,
    errorProps: null,
    isReady: !_isEmpty(props.streamingUrl),
    hasEnded: false,
    labLoadTime: 0,
    startTime: Date.now(),
    wasEverSubmitted: false,
    labSessionId: null,
  });

  const retryHandle = useRef(null);
  const stateRef = useRef(state);
  const pingHandle = useRef(null);
  const readyHandle = useRef(null);

  const updateState = args => {
    stateRef.current = { ...stateRef.current, ...args };
    setState(stateRef.current);
  };

  const labAttr = { app: app, title: streamParams?.title, app_site_id: streamParams?.app_site_id };

  const shouldUseAppStream = usesRemoteVM(app) || shouldUseAppStreamFor(labAttr);

  useEffect(() => {
    window.addEventListener('beforeunload', handleUnload);
    window.addEventListener('pagehide', handleUnload);

    const { isReady } = stateRef.current;
    if (isReady && onReady) onReady(true);

    if (shouldUseAppStream) {
      getHostedLabsStreamingUrl();
    } else if (!props.streamingUrl) {
      getStreamingUrl();
    }

    return () => {
      expireSession();
      clearHandles();
    };
  }, []);

  useEffect(() => {
    const { hasEnded } = stateRef.current;
    if (!hasEnded && props.hasEnded) {
      updateState({ hasEnded: true });
      Stream.expire({ app: props.app });
    }
  }, [props.hasEnded]);

  useEffect(() => {
    if (props.isSubmitted) {
      updateState({ wasEverSubmitted: true });
    }
  }, [props.isSubmitted]);

  useEffect(() => {
    if (props.streamingUrl) {
      updateState({ streamingUrl: props.streamingUrl });
    }
  }, [props.streamingUrl]);

  const getStreamParams = () => ({
    ...streamParams,
    fs_url: getFullStoryUrl(),
    current_url: window.location.href,
  });

  const getStreamingUrl = () => {
    const { app } = props;
    Stream.stream({ app, ...getStreamParams() })
      // eslint-disable-next-line complexity
      .then(response => {
        if (response.streaming_url) {
          handleStreamingUrl(response.streaming_url, response.lab_version);
          return;
        }

        // support for both old and new labs
        if (response.error_code) {
          handleError({ errorCode: response.error_code });
        }
      })
      .catch(e => {
        handleError({ exceptionError: e });
      });
  };

  const handleStreamingUrl = (streamingUrl, labVersion) => {
    const { onUrlReceived } = props;

    updateState({ error: null, streamingUrl });
    if (onUrlReceived) onUrlReceived(streamingUrl, labVersion);

    if (onReady) onReady(true);
    updateState({ labLoadTime: getDuration() });
  };

  // eslint-disable-next-line complexity
  const getHostedLabsStreamingUrl = async () => {
    let streamApiParams = { use_app_stream: true };
    if (!usesRemoteVM(app) && streamParams.app_site_id) {
      streamApiParams = { ...streamApiParams, app_site_id: streamParams.app_site_id };
    } else {
      streamApiParams = {
        ...streamApiParams,
        lab_id: streamParams.lab_id,
        target_username: streamParams.target_username,
      };
    }

    try {
      const response = await Stream.stream(streamApiParams);
      const { error_code, retry, ready, lab_session_id } = response;

      if (!_isEmpty(lab_session_id)) {
        updateState({ labSessionId: lab_session_id });
      }

      const shouldRetry = retry || (_isEmpty(error_code) && !ready);
      if (!_isEmpty(error_code) || shouldRetry) {
        if (!_isEmpty(error_code)) {
          handleError({ errorCode: response.error_code, retry });
        }

        if (shouldRetry) {
          retryHandle.current = setTimeout(getHostedLabsStreamingUrl, HOSTED_LABS_RETRY_INTERVAL);
        }
        return;
      }

      // reaching here: session is ready and streaming_url is available
      handleHostedLabsStreamingUrl(response);
    } catch (e) {
      handleError({ exceptionError: e });
    }
  };

  const handleHostedLabsStreamingUrl = response => {
    const { lab_session_id, streaming_url, lab_version } = response;

    updateState({
      error: null,
      labSessionId: lab_session_id,
      streamingUrl: streaming_url,
      labLoadTime: getDuration(),
    });

    if (usesRemoteVM(app) && setIsVmUp) {
      setIsVmUp(true);
    }

    if (props.onUrlReceived) {
      props.onUrlReceived(streaming_url, lab_version);
    }
  };

  const handleError = ({ errorCode, exceptionError, retry }) => {
    if (usesRemoteVM(app) && setIsVmUp) {
      setIsVmUp(false);
    }

    let errorProps;
    if (errorCode === 'one_lab_at_a_time') {
      errorProps = getOneLabAtATimeModalProps();
    } else if (errorCode === 'cleaning_up_old_session') {
      errorProps = getCleaningUpOldSessionModalProps();
    } else if (errorCode === 'no_machines_available') {
      errorProps = getNoMachinesAvailableModalProps();
    } else {
      errorProps = getMachineHiccupModalProps();
    }

    // TODO: Remove conditional - temporary to support old labs
    if (errorCode !== 'launching') {
      updateState({ error: errorCode, errorProps });
    }

    if (!retry) {
      onError([errorProps]);
    }

    if (exceptionError) {
      const userError = intl.formatMessage({ id: 'streamContainer.messages.noMachinesAvailable' });
      const actualError = `${exceptionError.message}<br/>${exceptionError.stack}`;
      Stream.sendAlert({
        app,
        user_message: userError,
        actual_message: actualError,
        ...getStreamParams(),
      });
    }
  };

  const expireSession = () => {
    const { hasEnded, labSessionId } = stateRef.current;

    trackDuration();
    if (hasEnded || !shouldUseAppStream || _isEmpty(labSessionId)) {
      return;
    }

    Stream.expire({ lab_session_id: labSessionId });
  };

  const getDuration = () => Math.round((Date.now() - stateRef.current.startTime) / 1000);

  const trackDuration = () => {
    const { analyticsParams, app } = props;
    const { isReady, wasEverSubmitted, labLoadTime } = stateRef.current;
    const duration = getDuration();

    ReactGA.timing({
      category: 'Lab',
      variable: 'load',
      label: 'Lab Load',
      value: (duration || 1) * 1000, // duration in ms. use 1 as default
    });

    analytics.track('Lab Load', {
      ...analytics.getPageParams(analyticsParams),
      $session_duration: duration,
      $duration: labLoadTime,
      $submitted: wasEverSubmitted,
      $aborted: !isReady,
      app,
    });
  };

  const clearHandles = () => {
    if (pingHandle.current) {
      clearTimeout(pingHandle.current);
      pingHandle.current = null;
    }

    if (readyHandle.current) {
      clearTimeout(readyHandle.current);
      readyHandle.current = null;
    }

    if (retryHandle.current) {
      clearTimeout(retryHandle.current);
      retryHandle.current = null;
    }

    window.removeEventListener('beforeunload', handleUnload);
    window.removeEventListener('pagehide', handleUnload);
  };

  const handleUnload = () => {
    clearHandles();
    expireSession();
  };

  const renderMessage = () => {
    const { error, errorProps, hasEnded } = stateRef.current;

    // TODO: Revisit to manage zindex definitions in one file
    if (error) {
      return <LabErrorModal {...errorProps} zIndex={1000} />;
    } else if (hasEnded && !props.hasEnded) {
      return <LabErrorModal {...getLabGotSleepyModalProps()} />;
    }
    return null;
  };

  const { streamingUrl, hasEnded } = stateRef.current;
  const showPrompt = !_isEmpty(streamingUrl) && !_isEmpty(app) && !hasEnded;

  const warningMessage = intl.formatMessage({ id: 'tutorial.warningPrompt' });

  return (
    <div className="video-container">
      <Prompt when={showPrompt} message={warningMessage} />
      {renderMessage()}

      {!hasEnded && <LabEmbed {...{ streamingUrl, launchIn, app, isAppStream: shouldUseAppStream }} />}
    </div>
  );
}

StreamContainer.propTypes = {
  app: PropTypes.string,
  onEnd: PropTypes.func,
  hasEnded: PropTypes.bool,
  isSubmitted: PropTypes.bool,
  streamingUrl: PropTypes.string,
  onUrlReceived: PropTypes.func,
  streamParams: PropTypes.object,
  analyticsParams: PropTypes.object,
  launchIn: PropTypes.string,
};

StreamContainer.defaultProps = {
  hasEnded: false,
  analyticsParams: {},
};
