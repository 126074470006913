import { useDispatch } from 'react-redux';
import { useState, useEffect, useRef } from 'react';
import useDebounce from './useDebounce';
import { saveBlock } from '../actions/blockActions';
import { toastException } from '../utils/toastHelper';
import { trimEndTag, stripHtml } from '../utils/utils';

const useSaveBlock = (block, extraBlockProps) => {
  const dispatch = useDispatch();
  const contentRef = useRef();
  const saveRef = useRef(false);
  const [errors, setErrors] = useState([]);
  const [currentBlock, setCurrentBlock] = useState(null);
  const [remoteBlock, setRemoteBlock] = useState(null);
  const [showConflictModal, setShowConflictModal] = useState(false);
  const [blockState, setBlockState] = useState({
    ...block,
    content: block.content || '',
    ...(extraBlockProps || {}),
  });

  useEffect(() => {
    if (blockState && block) {
      const needsUpdate = blockState.seq_num !== block.seq_num || blockState.updated_at !== block.updated_at;
      if (!needsUpdate) return;
      let newState = blockState;
      if (new Date(blockState.updated_at) < new Date(block.updated_at)) {
        newState = block;
      }
      setBlockState({
        ...newState,
        seq_num: block.seq_num,
        updated_at: block.updated_at,
      });
    }
  }, [block, blockState, setBlockState]);

  const debouncedSave = useDebounce(async (updated, ignoreConflict, afterSave) => {
    try {
      const newBlock = { ...updated };
      if (ignoreConflict) {
        newBlock.updated_at = null;
      }

      saveRef.current = true;
      const savedBlock = await saveBlock(newBlock)(dispatch);
      if (afterSave) afterSave(savedBlock);
      setShowConflictModal(false);
    } catch (err) {
      saveRef.current = false;
      // eslint-disable-next-line no-console
      console.error('Error occurred while saving block', err);
      if (err.value && err.value.conflict) {
        setCurrentBlock(updated);
        setRemoteBlock(err.value.block);
        setShowConflictModal(true);
      } else {
        if (err.value && err.value.errors) {
          setErrors(err.value.errors);
        }
        toastException(err, 'Unable to save block');
      }
    } finally {
      saveRef.current = false;
    }
  }, 1000);

  const handleChange = (name, forceSave, saveOnChange) => e => {
    let value;
    if (typeof e === 'string') {
      value = e;
    } else {
      const { target } = e;
      value = target.type === 'checkbox' ? target.checked : target.value;
    }

    if (name === 'title') {
      value = stripHtml(trimEndTag(value, 'br')).content;
    }

    const updatedBlock = { ...blockState, [name]: value };
    setBlockState(updatedBlock);

    if (saveOnChange !== false && !saveRef.current) {
      debouncedSave(updatedBlock, forceSave);
    }
  };

  const handleBlockChange = blockChanges => {
    const updatedBlock = { ...blockState, ...blockChanges };
    setBlockState(updatedBlock);
  };

  const handleConflictSave = newState => {
    setBlockState(newState);
    setShowConflictModal(false);
    if (contentRef.current) {
      contentRef.current.setContent(newState.content);
    }
  };

  return {
    blockState,
    setBlockState,
    saveBlock: debouncedSave,
    handleChange,
    handleBlockChange,
    conflict: {
      current: currentBlock,
      remote: remoteBlock,
      visible: showConflictModal,
      hide: () => setShowConflictModal(false),
    },
    errors,
    setErrors,
    handleConflictSave,
    contentRef,
  };
};

export default useSaveBlock;
