import { useSelector } from 'react-redux';
import { selectProgressForLesson } from '../selectors/lessonProgressSelectors';
import useCurrentComponent from './useCurrentComponent';
import { selectProgressForUnit } from '../selectors/unitProgressSelectors';
import { selectProgressForComponent } from '../selectors/componentProgressSelectors';

export default function useCurrentComponentProgress() {
  const { unit, component, section, lessonId } = useCurrentComponent();
  const unitProgress = useSelector(s => (unit && section ? selectProgressForUnit(s, section.id, unit.id) : null));
  const componentProgress = useSelector(s =>
    component && unitProgress ? selectProgressForComponent(s, unitProgress.id, component.id) : null,
  );
  const lessonProgress = useSelector(s => (lessonId ? selectProgressForLesson(s, lessonId) : null));

  return { unitProgress, componentProgress, lessonProgress };
}
