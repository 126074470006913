import { connect } from 'react-redux';
import { selectUnit } from '../../../selectors/unitSelectors';
import ProjectShow from './ProjectShow';
import { saveUnit } from '../../../actions/unitActions';
import { fetchLesson, saveLesson } from '../../../actions/lessonActions';
import { selectLesson } from '../../../selectors/lessonSelectors';
import { fetchAssignment } from '../../../actions/assignmentActions';
import { selectSection } from '../../../selectors/sectionSelectors';
import { selectAssignment } from '../../../selectors/assignmentSelectors';
import { selectIsEditMode } from '../../../selectors/contextSelectors';
import { fetchBootcamp } from '../../../actions/bootcampActions';
import { selectBootcamp } from '../../../selectors/bootcampSelectors';
import { getFolderPath } from '../../../helpers/labHelper';
import { selectCurrentUser } from '../../../selectors/authSelectors';
import { selectPath } from '../../../selectors/pathSelectors';
import { getCoursePath } from '../../../helpers/pathHelper';
import { isCoach } from '../../../helpers/userAuth';

const mapStateToProps = (state, ownProps) => {
  const { bootcampId, pathId, projectId, sectionId } = ownProps.match.params;
  const unit = selectUnit(state, projectId);
  const isEditMode = selectIsEditMode(state);
  const section = selectSection(state, sectionId);
  const lessonId = unit && unit.lesson_id;
  const lesson = selectLesson(state, lessonId);
  const introLesson = unit && unit.intro_lesson_id && selectLesson(state, unit.intro_lesson_id);
  const assignmentId = unit && unit.assignment_id;
  const assignment = selectAssignment(state, assignmentId);
  const prerequisiteAssignment =
    assignment && assignment.prerequisite_id && selectAssignment(state, assignment.prerequisite_id);
  const bootcamp = selectBootcamp(state, bootcampId);
  const projectFolderPath = getFolderPath({ preview: true, bootcamp, user: selectCurrentUser(state) });

  const currentUser = selectCurrentUser(state);
  const path = selectPath(state, pathId);
  const bootcampPath = getCoursePath(currentUser, bootcamp, path);

  let handleHeaderBackClick = null;
  if (isCoach(currentUser)) {
    const queryParams = new URLSearchParams(ownProps.location.search);
    let returnUrl = queryParams.get('returnUrl');
    if (!returnUrl) {
      returnUrl = '/coach/dashboard';
    }

    handleHeaderBackClick = () => ownProps.history.push(returnUrl);
  }

  return {
    bootcamp,
    bootcampId,
    unit,
    lesson,
    lessonId,
    assignment,
    assignmentId,
    section,
    isEditMode,
    projectFolderPath,
    prerequisiteAssignment,
    introLesson,
    bootcampPath,
    onHeaderBackClick: handleHeaderBackClick,
  };
};

export default connect(mapStateToProps, {
  fetchLesson,
  fetchAssignment,
  fetchBootcamp,
  saveUnit,
  saveLesson,
})(ProjectShow);
