import { getLocationPath, getDataSourcePath } from './dataSourceHelpers';
import { evaluator } from './evalHelpers';

// eslint-disable-next-line complexity
const evalPivotTableValue = ({ data, location, property, operator, value }) => {
  // Get the specific location (one before the last one)
  const pivotTable = getLocationPath(data, location);
  if (!pivotTable) return null;
  const { type, order, name } = location[location.length - 1].dataSource;

  // locate path based on dataSource
  const pivotTableValueLocator = getDataSourcePath({ type, order, name });
  const pivotTableValue = pivotTableValueLocator(pivotTable.values);

  switch (property) {
    case 'exists':
      // assuming we only use title for the dataSource
      return !!pivotTableValue;
    case 'summarizeBy':
      if (type === 'byTitle') {
        if (!pivotTableValue) return null;
        const { summarizeFunction } = pivotTableValue;
        return evaluator(summarizeFunction, operator, value);
      }
      if (type === 'byAny' || type === 'byAll') {
        return pivotTableValueLocator(pivotTable.values)(ptValue => {
          const { summarizeFunction } = ptValue;
          return evaluator(summarizeFunction, operator, value);
        });
      }
      return false;
    case 'showAs':
      if (type === 'byTitle') {
        if (!pivotTableValue) return null;
        const { calculatedDisplayType } = pivotTableValue;
        return evaluator(calculatedDisplayType, operator, value);
      }
      if (type === 'byAny' || type === 'byAll') {
        return pivotTableValueLocator(pivotTable.values)(ptValue => {
          const { calculatedDisplayType } = ptValue;
          return evaluator(calculatedDisplayType, operator, value);
        });
      }
      return false;
    default:
      return null;
  }
};

export default evalPivotTableValue;
