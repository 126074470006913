import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Modal2 from '../library/Modal2';
import intl from '../../utils/intl';
import UserPicture from '../Utils/UserPicture';
import Loading from '../Utils/Loading';
import { debounce as _debounce, isEmpty as _isEmpty } from 'lodash';

const EnrollModal = ({ userIds, userRole, mode, cohort, fetchUsers, onEnroll, onHide, headerTitle }) => {
  const [searchText, setSearchText] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [users, setUsers] = useState([]);
  const [page, setPage] = useState(1);
  const [pageSize, _setPageSize] = useState(10);
  const [totalPages, setTotalPages] = useState(1);

  const debounceSearch = _debounce(newSearchText => {
    setSearchText(newSearchText);
    setPage(1);
  }, 300);

  useEffect(() => {
    getData();
  }, [page, userIds, searchText]);

  const handleChange = e => {
    const newSearchText = e.target.value;
    debounceSearch(newSearchText);
  };

  const handlePageChange = name => {
    setPage(prevPage => {
      return name === 'next' ? Math.min(prevPage + 1, totalPages) : Math.max(prevPage - 1, 1);
    });
  };

  const getData = () => {
    setIsLoading(true);
    let excludeIds = [];
    let includeIds = [];
    let excludeCohort;
    let includeCohort;

    if (mode === 'enroll') {
      // when there are lots of students, request length limit gets hit, so exclude cohort users in backend
      if (userRole == 'Student') {
        excludeCohort = cohort?.id;
      } else {
        excludeIds = userIds;
      }
    } else {
      if (userRole == 'Student') {
        includeCohort = cohort?.id;
      } else {
        includeIds = userIds;

        if (!includeIds.length) {
          includeIds.push(''); // Add an empty element to make the backend execute the query
        }
      }
    }

    fetchUsers(
      searchText,
      page - 1,
      pageSize,
      'name',
      false,
      [userRole],
      excludeIds,
      includeIds,
      excludeCohort,
      includeCohort,
    )
      .then(data => {
        setTotalPages(Math.ceil(data.total / pageSize));
        setUsers(data.rows || []); // Ensure users is always an array
        setIsLoading(false);
      })
      .catch(err => {
        // eslint-disable-next-line no-console
        console.log('Error getting users', err);
        setIsLoading(false);
        setUsers([]); // Reset users to empty array in case of error
      });
  };

  const renderUserRow = user => {
    // const { mode } = props;

    return (
      <div key={user.id} className="modal-users-list-item">
        <div className="modal-users-list-item__left">
          <div className="modal-users-list-item__avatar">
            <UserPicture user={user} />
          </div>
          <div className="modal-users-list-item__name">{user.name}</div>
        </div>

        <div className="modal-users-list-item__right">
          <div className="modal-users-list-item__email">{user.email}</div>
          <div className="modal-users-list-item__button">
            <button className="modal-users-list-item__button--element" type="button" onClick={() => onEnroll(user)}>
              {mode === 'enroll' ? (
                <FormattedMessage id="enrollments.button.enroll" />
              ) : (
                <FormattedMessage id="enrollments.button.unenroll" />
              )}
            </button>
          </div>
        </div>
      </div>
    );
  };

  const renderFooter = () => {
    return (
      <div className="modal-dialog-footer modal-dialog-footer--full">
        <div className="pagination">
          <div className="pagination__button">
            <button
              type="button"
              name="previous"
              className="pagination__button--element"
              disabled={page === 1}
              onClick={() => handlePageChange('previous')}>
              <FormattedMessage id="common.previous" />
            </button>
          </div>

          <div className="pagination__state">
            <span className="pagination__state--label">
              <FormattedMessage id="pagination.page" />
            </span>
            <span className="pagination__state--current">{page}</span>
            <span className="pagination__state--label">
              <FormattedMessage id="pagination.ofTotal" values={{ total: totalPages }} />
            </span>
          </div>

          <div className="pagination__button pagination__button--right">
            <button
              type="button"
              name="next"
              className="pagination__button--element"
              disabled={page === totalPages}
              onClick={() => handlePageChange('next')}>
              <FormattedMessage id="common.next" />
            </button>
          </div>
        </div>
      </div>
    );
  };

  return (
    <Modal2
      size="big"
      bodyClass="modal-dialog-body--full"
      contentClass="modal-dialog-content--footerFull"
      onHide={onHide}
      title={headerTitle}
      showCancel={false}
      className="enroll-modal">
      <div className="modal-dialog-body--big">
        <div className="custom-search-input">
          <input
            type="text"
            name="searchText"
            className="custom-search-input__input"
            onChange={handleChange}
            aria-label={intl.formatMessage({ id: 'common.search' })}
            placeholder={intl.formatMessage({ id: 'common.search' })}
          />
        </div>

        <div className="modal-users-list">
          {!_isEmpty(users) && (
            <div className="modal-users-list-header">
              <div className="modal-users-list-header__label modal-users-list-header__label--left">
                <FormattedMessage id="user.form.name" />
              </div>
              <div className="modal-users-list-header__label modal-users-list-header__label--right">
                <FormattedMessage id="user.form.email" />
              </div>
            </div>
          )}

          <div className="modal-users-list-body">
            {isLoading && <Loading />}
            {!isLoading && _isEmpty(users) && (
              <div style={{ textAlign: 'center' }}>
                <FormattedMessage id="user.messages.noneFound" />
              </div>
            )}
            {users.map(u => renderUserRow(u))}
          </div>
        </div>
      </div>

      {renderFooter()}
    </Modal2>
  );
};

EnrollModal.propTypes = {
  userIds: PropTypes.array.isRequired,
  userRole: PropTypes.string.isRequired,
  mode: PropTypes.string.isRequired,
  cohort: PropTypes.object,
  fetchUsers: PropTypes.func.isRequired,
  onEnroll: PropTypes.func.isRequired,
  onHide: PropTypes.func.isRequired,
  headerTitle: PropTypes.node.isRequired,
};

export default EnrollModal;
