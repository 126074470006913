import axios from './axios';

export default class ComponentProgress {
  static start(tenantId, componentProgressId) {
    return axios.put(`/paths/component_progresses/${componentProgressId}/start`).then(response => response.data);
  }

  static update(tenantId, componentProgressId, params) {
    return axios.put(`/paths/component_progresses/${componentProgressId}`, params).then(response => response.data);
  }
}
