import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { selectEnrollmentForBootcamp } from '../../../../selectors/enrollmentSelectors';
import { selectCohortForEnrollment } from '../../../../selectors/cohortSelectors';
import { selectBootcampPaths } from '../../../../selectors/bootcampSelectors';
import { selectProgressForPath, selectProgressToResume } from '../../../../selectors/pathProgressSelectors';
import { getPathPrefix } from '../../../../helpers/pathHelper';
import { fetchEnrollmentForCohort } from '../../../../actions/enrollmentActions';
import { selectFirstComponent } from '../../../../selectors/cohortBootcampSelectors';
import BootcampCourseCard from './BootcampCourseCard';
import { selectCurrentUser } from '../../../../selectors/authSelectors';

/* eslint-disable complexity */
const mapStateToProps = (state, ownProps) => {
  const { bootcamp } = ownProps;
  const currentUser = selectCurrentUser(state);
  const enrollment = selectEnrollmentForBootcamp(state, bootcamp.id);
  const cohort = ownProps.cohort || selectCohortForEnrollment(state, enrollment && enrollment.id);
  const paths = selectBootcampPaths(state, bootcamp.id);
  const path = selectBootcampPaths(state, bootcamp.id)[0];
  const pathProgress = path && selectProgressForPath(state, path.id);
  const currentProgress =
    (pathProgress && selectProgressToResume(state, pathProgress.id)) ||
    selectFirstComponent(state, bootcamp.id, cohort && cohort.id);
  let pathPrefix = getPathPrefix(currentUser);

  let url;
  if (currentProgress) {
    url = `${pathPrefix}/bootcamps/${bootcamp.id}/paths/${path.id}`;
  }

  return {
    bootcamp,
    enrollment,
    paths,
    cohort,
    url,
    currentComponent: currentProgress && (currentProgress.component || currentProgress.unit),
  };
};
/* eslint-enable complexity */
export default withRouter(connect(mapStateToProps, { fetchEnrollmentForCohort })(BootcampCourseCard));
