import React from 'react';
import PropTypes from 'prop-types';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

const CourseTabs = ({ tabs, progress, className }) => {
  return (
    <div className="course-tabs">
      <Tabs selectedTabClassName="active" selectedTabPanelClassName="active" forceRenderTabPanel>
        <TabList className={`react-tabs__tab-list ${className}`}>
          {tabs.map((tab, index) => (
            <Tab key={index} tabIndex="0">
              {tab.name}
            </Tab>
          ))}
        </TabList>
        {progress}
        {tabs.map((tab, index) => (
          <TabPanel key={index}>{tab.panel}</TabPanel>
        ))}
      </Tabs>
    </div>
  );
};

CourseTabs.propTypes = {
  tabs: PropTypes.array.isRequired,
  progress: PropTypes.node,
  className: PropTypes.string,
};

export default CourseTabs;
