import { operators } from './OperatorsEnum';

export class ASTNode {
  constructor(value, left, right) {
    this.value = value;
    this.isOperator = operators.includes(value);
    this.left = left;
    this.right = right;
  }
}
