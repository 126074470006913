import { isEmpty as _isEmpty } from 'lodash';
import { useSelector } from 'react-redux';
import { selectCurrentUser } from '../selectors/authSelectors';
import * as analytics from '../utils/analytics';

export const FEATURE_FLAGS = {
  INITIAL_TEST: 'initial_test',
  REMOTE_EVALUATION_TEST: 'remote_evaluation_test',
};

export default function useFeatureFlags() {
  const currentUser = useSelector(selectCurrentUser);
  const variants = currentUser?.variants;
  const isReady = !!currentUser;
  const variant = (key, fallback) => {
    if (!isReady) {
      return fallback;
    }

    const value = variants?.[key];
    if (value !== undefined) {
      analytics.trackExposure(key, value);
    }
    return value;
  };

  const inFlag = (key, variantValue = 'on') => {
    const value = variant(key);
    if (!value) {
      return false;
    }

    return !variantValue || value === variantValue;
  };

  const inExperiment = (key, variantValue = 'treatment') => {
    const value = variant(key);
    if (!value) {
      return false;
    }

    return !variantValue || value === variantValue;
  };

  // NOTE: variants could be returned here, but without tracking exposure Amplitude
  // will not work properly!
  return {
    isReady,
    variant,
    inExperiment,
    inFlag,
  };
}
