import axios from './axios';

export default class Component {
  static LAB = 'lab';

  static all(_) {
    return axios.get('/paths/components').then(response => response.data);
  }

  static save(_, component, unitId) {
    if (component.id) {
      return axios.put(`/paths/units/${unitId}/components/${component.id}`, component).then(response => response.data);
    }
    return axios.post(`/paths/units/${unitId}/components`, component).then(response => response.data);
  }

  static copy(_, component, unitId) {
    return axios
      .post(`/paths/components/${component.id}/copy`, {
        unit_id: unitId,
      })
      .then(response => response.data);
  }

  static destroy(_, unitId, component) {
    return axios.delete(`/paths/units/${unitId}/components/${component.id}`).then(response => response.data);
  }

  static reorder(_, componentIds, unitId) {
    return axios
      .put(`/paths/units/${unitId}/reorder_components`, { component_ids: componentIds })
      .then(response => response.data);
  }
}
