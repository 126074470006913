import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Loading from '../Utils/Loading';

export default class QuizLoader extends Component {
  static propTypes = {
    render: PropTypes.func.isRequired,
    fetchQuiz: PropTypes.func.isRequired,
    quiz: PropTypes.object,
    questions: PropTypes.array,
    allQuestions: PropTypes.array,
  };

  componentDidMount() {
    const { quiz, fetchQuiz } = this.props;

    if (!quiz) fetchQuiz();
  }

  render() {
    const { quiz, render, questions, allQuestions } = this.props;

    if (quiz) {
      return render(quiz, questions, allQuestions);
    } else {
      return <Loading />;
    }
  }
}
