import React from 'react';
import { useSelector } from 'react-redux';
import { selectCurrentUser } from '../../selectors/authSelectors';
import NotFound from '../NotFound';
import { Route } from 'react-router-dom';
import Evaluator from './Evaluator';
import { canAdministrate, isEvaluator } from '../../helpers/userAuth';

const GraderQueue = () => {
  const currentUser = useSelector(selectCurrentUser);

  return canAdministrate(currentUser) || isEvaluator(currentUser) ? (
    <>
      <Evaluator />
    </>
  ) : (
    <Route component={NotFound} />
  );
};

export default GraderQueue;
