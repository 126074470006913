import { connect } from 'react-redux';
import HideContentModal from './HideContentModal';
import { selectBootcampByCohort } from '../../../../selectors/bootcampSelectors';
import { selectPath, selectPathSections } from '../../../../selectors/pathSelectors';
import { saveCohort } from '../../../../actions/cohortActions';
import { fetchBootcamp } from '../../../../actions/bootcampActions';

const mapStateToProps = (state, ownProps) => {
  const { cohort } = ownProps;
  const bootcamp = selectBootcampByCohort(state, cohort);
  const pathId = bootcamp && bootcamp.path_ids[0];
  const path = pathId && selectPath(state, pathId);
  const sections = (pathId && selectPathSections(state, pathId)) || [];

  return {
    bootcamp,
    path,
    sections,
  };
};

export default connect(mapStateToProps, { saveCohort, fetchBootcamp })(HideContentModal);
