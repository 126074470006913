import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { formatDate } from '../../../utils/utils';

export default class StudentInfoPanel extends Component {
  render() {
    const { student } = this.props;

    if (!student) {
      return '';
    }

    return (
      <div className="student-info">
        <div className="student-info__item">
          <span className="student-info__title">
            <FormattedMessage id="roster.lastLogin" />
          </span>
          <span className="student-info__box">
            <span className="student-info__box--text">{formatDate(student.current_sign_in_at)}</span>
          </span>
        </div>
      </div>
    );
  }
}
