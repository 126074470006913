import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import Icons from '../Utils/Icons';
import useHandleOutsideClick from '../../hooks/useHandleOutsideClick';
import { confirmInput } from '../../utils/confirmInput';
import TooltipButtons from '../Utils/Tooltip/TooltipButtons';
import { destroyPart } from '../../actions/partActions';
import { toastException } from '../../utils/toastHelper';

export default function PartEditOptions({ part, index, total, onReorder }) {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const containerRef = useHandleOutsideClick(isOpen, () => {
    if (isOpen) toggleOpen();
  });

  const toggleOpen = () => setIsOpen(!isOpen);

  const handleClick = (e, callback) => {
    toggleOpen();
    e.stopPropagation();
    callback(e);
  };

  const handleMoveUp = () => {
    const newIndex = Math.max(0, index - 1);
    onReorder(index, newIndex);
  };

  const handleMoveDown = () => {
    const newIndex = Math.min(total - 1, index + 1);
    onReorder(index, newIndex);
  };

  const handleDelete = () => {
    const { title } = part;
    confirmInput({
      message: <FormattedMessage id="part.confirmDelete" values={{ name: title, name_exists: !!title }} />,
      onOk: async () => {
        try {
          destroyPart(part)(dispatch);
        } catch (err) {
          toastException(err, 'Unable to delete part');
        }
      },
    });
  };

  const getButtons = () => {
    const buttons = [];

    if (index > 0) {
      buttons.push({
        messageId: 'aria.moveUp',
        onClick: e => handleClick(e, handleMoveUp),
        className: 'tooltip-button__button--split-top',
      });
    }

    if (index < total - 1) {
      buttons.push({
        messageId: 'aria.moveDown',
        onClick: e => handleClick(e, handleMoveDown),
        className: 'tooltip-button__button--split-top',
      });
    }

    buttons.push({
      icon: <Icons.Trash />,
      messageId: 'common.delete',
      onClick: e => handleClick(e, handleDelete),
      className: 'tooltip-button__button--split-top',
    });

    return buttons;
  };

  return (
    <div ref={containerRef} className="lesson-part__addPart">
      <button type="button" aria-label="Edit Part" onClick={toggleOpen}>
        <span className="icon-element icon-element--fill-blue icon-element--background-white icon-element--button icon-element--size-">
          <Icons.More />
        </span>
      </button>

      {isOpen && <TooltipButtons buttons={getButtons()} onClose={toggleOpen} />}
    </div>
  );
}

PartEditOptions.propTypes = {
  part: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  onReorder: PropTypes.func.isRequired,
};
