import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SectionInfo from '../Sections/SectionInfo';
import LtiAssignmentTile from './LtiAssignmentTile';
import { FormattedMessage } from 'react-intl';

export default class LtiSectionTile extends Component {
  static propTypes = {
    section: PropTypes.object.isRequired,
    assignments: PropTypes.array,
    isMultiSelect: PropTypes.bool,
    onSelect: PropTypes.func,
  };

  render() {
    const { section, index, assignments, isMultiSelect, onSelect } = this.props;

    return (
      <li className="to-do">
        <SectionInfo section={section} index={index + 1} hideStatus={true} />

        <div className="items-container open" style={{ marginTop: 0 }}>
          {assignments.length > 0 && (
            <ol className="items-list">
              {assignments.map(assignment => (
                <li key={assignment.id}>
                  <LtiAssignmentTile assignment={assignment} isMultiSelect={isMultiSelect} onSelect={onSelect} />
                </li>
              ))}
            </ol>
          )}
          {assignments.length === 0 && (
            <div style={{ marginTop: 10, paddingLeft: 20 }}>
              <FormattedMessage id="lti.messages.noAssignmentsInSection" />
            </div>
          )}
        </div>
      </li>
    );
  }
}
