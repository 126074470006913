import React from 'react';
import PropTypes from 'prop-types';
import Tutorial from '../../../api/tutorial';
import Project from '../../Labs/Project';
import { usesUserProjectUrl } from '../../../helpers/labHelper';

const SubmissionProject = ({ assignmentProgress, assignment, analyticsParams, projectFolderPath, student, unit }) => {
  const canAccessStreamer = assignmentProgress?.user_project_url && student;
  let streamingUrl = usesUserProjectUrl(assignment.app) ? assignmentProgress.user_project_url : null;

  if (canAccessStreamer) {
    return (
      <Project
        labInteractable={assignment}
        projectUrl={assignmentProgress.user_project_url}
        projectFolderPath={projectFolderPath}
        analyticsParams={analyticsParams}
        streamingUrl={streamingUrl}
        unit={unit}
        shouldShowLabLoadingModal={false}
        launchIn={Tutorial.LAUNCH_IN_BROWSER}
        userId={student?.id}
        saveBackups={false}
        useFreshCopy={true}
        targetUsername={student?.email}
      />
    );
  }
  return assignmentProgress.user_project_url ? null : <div>No project uploaded</div>;
};

SubmissionProject.propTypes = {
  assignmentProgress: PropTypes.object,
  assignment: PropTypes.object,
  analyticsParams: PropTypes.object,
  projectFolderPath: PropTypes.string,
  student: PropTypes.object,
  unit: PropTypes.object,
};

export default SubmissionProject;
