import { connect } from 'react-redux';
import SelectLessonModal from './SelectLessonModal';
import { copyTo } from '../../../../actions/blockActions';
import { fetchBootcamp } from '../../../../actions/bootcampActions';

const mapStateToProps = state => ({
  messagePrefixId: 'block.copyTo',
});

export default connect(mapStateToProps, { onSelect: copyTo, fetchBootcamp })(SelectLessonModal);
