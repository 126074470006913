import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Layout, Menu } from 'antd';
import { useHistory, useLocation } from 'react-router-dom';

const MenuContent = ({ menu }) => {
  const history = useHistory();
  const location = useLocation();

  const getInitialTab = () => {
    const queryParams = new URLSearchParams(location.search);
    let initialTab = queryParams.get('tab');
    if (!menu[initialTab]) {
      initialTab = Object.keys(menu)[0];
    }
    return initialTab;
  };
  const [currentTab, setCurrentTab] = useState(getInitialTab);

  const selectTab = e => {
    const tab = e.key;

    setCurrentTab(tab);

    history.replace(`${location.pathname}?tab=${tab}`);
  };

  return (
    <Layout>
      <Menu
        className="cohort-dashboard-menu"
        onClick={selectTab}
        selectedKeys={[currentTab]}
        mode="horizontal"
        data-testid="cohort-dashboard-menu">
        {Object.keys(menu).map(key => (
          <Menu.Item key={key}>{menu[key].tabName}</Menu.Item>
        ))}
      </Menu>

      <Layout.Content>{menu[currentTab].page}</Layout.Content>
    </Layout>
  );
};

// menu structure
// {
//   [key]: {
//     tabName,
//     page
//   }
// }
MenuContent.propTypes = {
  menu: PropTypes.object.isRequired,
};

export default MenuContent;
