import PropTypes from 'prop-types';
import React from 'react';
import { DownloadOutlined, QuestionCircleFilled, PhoneFilled } from '@ant-design/icons';
import { FormattedMessage } from 'react-intl';
import showModal from '../../../../utils/showModal';
import DownloadReportsModal from './shortcuts/DownloadReportsModal';
import Icons from '../../../Utils/Icons';

const CoachShortcutsContent = ({ hideShortcut }) => {
  const handleLinkClick = modal => {
    showModal(modal, {});
    hideShortcut();
  };

  return (
    <div className="user-profile-options-wrapper" id="user-menu" role="menu">
      <div className="user-profile-options-inner">
        <div className="user-profile-options user-profile-options-links">
          <div className="user-profile-options__link">
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a
              className="coach-profile-option"
              alt="Download reports"
              href="#"
              onClick={handleLinkClick.bind(this, DownloadReportsModal)}>
              <DownloadOutlined /> <FormattedMessage id="coachHeader.shortcuts.downloadReports" />
            </a>
          </div>

          <div className="user-profile-options__link">
            <a
              className="coach-profile-option"
              target="_blank"
              alt="Help"
              href="https://www.notion.so/Instructor-Resource-Center-e9aafe3632794cbe8dabacc1dd813a0c">
              <Icons.Book /> <FormattedMessage id="coachHeader.shortcuts.resourceCenter" />
            </a>
          </div>

          <div className="user-profile-options__link">
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a className="coach-profile-option" target="_blank" alt="Help" href="https://pathstream.force.com/support">
              <QuestionCircleFilled /> <FormattedMessage id="coachHeader.shortcuts.help" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CoachShortcutsContent;

CoachShortcutsContent.propTypes = {
  hideShortcut: PropTypes.func.isRequired,
};
