import {
  each as _each,
  every as _every,
  find as _find,
  indexOf as _indexOf,
  uniq as _uniq,
  values as _values,
} from 'lodash';
import { createSelector } from 'reselect';
import { selectSectionEntities } from './sectionSelectors';
import { selectUnitEntities } from './unitSelectors';
import { selectComponentEntities } from './componentSelectors';
import Unit from '../api/unit';
import { selectPathEntities } from './pathSelectors';
import { selectPathProgressesForBootcamp } from './pathProgressSelectors';
import { selectSectionProgressEntities } from './sectionProgressSelectors';
import { selectUnitProgressEntities } from './unitProgressSelectors';
import { selectComponentProgressEntities } from './componentProgressSelectors';
import { selectBootcamp } from './bootcampSelectors';
import { getProgressStatus } from '../utils/utils';

export const selectProgressesForBootcamp = createSelector(
  [
    selectBootcamp,
    selectPathEntities,
    selectSectionEntities,
    selectUnitEntities,
    selectComponentEntities,
    selectPathProgressesForBootcamp,
    selectSectionProgressEntities,
    selectUnitProgressEntities,
    selectComponentProgressEntities,
  ],
  (
    bootcamp,
    pathEntities,
    sectionEntities,
    unitEntities,
    componentEntities,
    pathProgresses,
    sectionProgressEntities,
    unitProgressEntities,
    componentProgressEntities,
  ) => {
    const progresses = [];

    if (!bootcamp) {
      return progresses;
    }

    _each(bootcamp.path_ids, pathId => {
      const path = pathEntities[pathId];
      const pathProgress = _find(pathProgresses, { path_id: pathId });

      if (!pathProgress) {
        return;
      }

      const pathInfo = {
        path,
        pathId,
        pathProgress,
        pathComplete: getProgressStatus(pathProgress).isComplete,
      };

      _each(path.section_ids, (sectionId, sectionIndex) => {
        const section = sectionEntities[sectionId];
        const sectionProgress = _find(_values(sectionProgressEntities), {
          path_progress_id: pathProgress.id,
          section_id: sectionId,
        });
        if (!sectionProgress) {
          return;
        }

        const sectionInfo = {
          section,
          sectionId,
          sectionIndex,
          sectionProgress,
          sectionComplete: getProgressStatus(sectionProgress).isComplete,
        };

        _each(section.unit_ids, (unitId, unitIndex) => {
          const unit = unitEntities[unitId];
          const unitProgress = _find(_values(unitProgressEntities), {
            section_progress_id: sectionProgress.id,
            unit_id: unitId,
          });
          if (!unitProgress) {
            return;
          }

          const progress = {
            ...pathInfo,
            ...sectionInfo,
            unit,
            unitId,
            unitIndex,
            unitProgress,
            unitComplete: getProgressStatus(unitProgress).isComplete,
          };

          if (unit.unit_type === Unit.PROJECT || unit.unit_type === Unit.GRADED_QUIZ) {
            progresses.push(progress);
          } else {
            _each(unit.component_ids, (componentId, componentIndex) => {
              const component = componentEntities[componentId];
              const componentProgress = _find(_values(componentProgressEntities), {
                unit_progress_id: unitProgress.id,
                component_id: componentId,
              });

              if (!componentProgress) {
                return;
              }

              progresses.push({
                ...progress,
                component,
                componentId,
                componentIndex,
                componentProgress,
                componentComplete: getProgressStatus(componentProgress).isComplete,
              });
            });
          }
        });
      });
    });

    return progresses;
  },
);

export const getProgress = (state, { bootcampId, pathId, sectionId, unitId, componentId }) => {
  const progresses = selectProgressesForBootcamp(state, bootcampId);
  const filterArgs = { pathId, sectionId, unitId };
  if (componentId) {
    filterArgs.componentId = componentId;
  }
  const currentProgress = _find(progresses, filterArgs);
  if (!currentProgress) {
    return {};
  }

  // get the next item to continue
  const currentIndex = _indexOf(progresses, currentProgress);
  let nextProgress;
  // get next lesson
  if (currentProgress.sectionComplete) {
    // get next section
    nextProgress = _find(progresses, pr => pr.sectionId !== sectionId, currentIndex + 1);
  } else if (currentProgress.unitComplete) {
    // get next unit
    nextProgress = _find(progresses, pr => pr.unitId !== unitId, currentIndex + 1);
  }

  if (currentProgress.component && !nextProgress) {
    // get next lesson
    nextProgress = _find(progresses, pr => pr.componentId !== componentId, currentIndex + 1);
  }

  return {
    currentProgress,
    nextProgress,
  };
};

export const whatWillIComplete = (state, { bootcampId, sectionId, unitId, componentId }) => {
  const progresses = selectProgressesForBootcamp(state, bootcampId);
  const allExceptMe = progresses.filter(pr => pr.componentId !== componentId);

  const willCompleteSection = _every(
    allExceptMe.filter(pr => pr.sectionId === sectionId),
    pr => (pr.componentProgress && pr.componentComplete) || pr.unitComplete,
  );

  const willCompleteModule = _every(
    allExceptMe.filter(pr => pr.unitId === unitId),
    pr => pr.componentComplete,
  );

  return {
    willCompleteSection,
    willCompleteModule,
  };
};

export const getCompletedItems = (state, { bootcampId, sectionId, unitId }) => {
  if (!sectionId && !unitId) return [];

  const progresses = selectProgressesForBootcamp(state, bootcampId);

  const titles = progresses
    .filter(pr => (sectionId && pr.section.id === sectionId) || (unitId && pr.unit.id === unitId))
    .map(pr => (sectionId ? pr.unit.title : pr.component.title));

  return _uniq(titles);
};
