import { each as _each, remove as _remove } from 'lodash';

const SUBSCRIPTIONS = {};
const EARLY_SUBSCRIPTIONS = {};

const getTypedSubscriptions = (type, subscriptions) => {
  if (!subscriptions[type]) {
    subscriptions[type] = [];
  }
  return subscriptions[type];
};

const notifySubscribers = msg => {
  const { type, ...rest } = msg;
  _each([EARLY_SUBSCRIPTIONS, SUBSCRIPTIONS], subscriptionList => {
    const subscriptions = getTypedSubscriptions(type, subscriptionList);
    _each(subscriptions, cb => cb(rest));
  });
};

let listening = false;
let prevSocket;
export const listenForEvents = socket => {
  if (!listening || prevSocket !== socket) {
    socket.on('message', notifySubscribers);
    listening = true;
    prevSocket = socket;
  }
};

export const subscribe = (type, cb, beforeStoreUpdate = false) => {
  const subscriptionList = beforeStoreUpdate ? EARLY_SUBSCRIPTIONS : SUBSCRIPTIONS;
  const subscriptions = getTypedSubscriptions(type, subscriptionList);
  subscriptions.push(cb);
};

export const unsubscribe = (type, cb, beforeStoreUpdate = false) => {
  const subscriptionList = beforeStoreUpdate ? EARLY_SUBSCRIPTIONS : SUBSCRIPTIONS;
  const subscriptions = getTypedSubscriptions(type, subscriptionList);
  _remove(subscriptions, callback => callback === cb);
};
