import { some as _some } from 'lodash';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import ComponentProgresses from './ComponentProgresses';
import { selectLessonBlocks } from '../../selectors/lessonSelectors';
import { selectUnit } from '../../selectors/unitSelectors';
import { selectComponent } from '../../selectors/componentSelectors';
import Block from '../../api/block';
import { selectProgressForUnit } from '../../selectors/unitProgressSelectors';
import { selectProgressForComponent } from '../../selectors/componentProgressSelectors';
import { getProgressStatus } from '../../utils/utils';
import { selectEntitiesForBootcamp } from '../../selectors/cohortBootcampSelectors';

const mapStateToProps = (state, ownProps) => {
  const {
    bootcampId: currentBootcampId,
    sectionId: currentSectionId,
    unitId: currentUnitId,
    componentId: currentComponentId,
  } = ownProps.match.params;
  const unit = selectUnit(state, currentUnitId);
  const { componentsByUnit } = selectEntitiesForBootcamp(state, currentBootcampId);
  const components = (componentsByUnit && componentsByUnit[unit.id]) || [];
  const currentComponent = selectComponent(state, currentComponentId);

  let unitProgress;
  let componentProgress;
  const componentsCompleted = {};
  const componentsWithLab = {};
  (components || []).forEach(comp => {
    const blocks = selectLessonBlocks(state, comp.external_id);
    const hasLab = _some(blocks, b => b.block_type === Block.LAB);
    componentsWithLab[comp.id] = hasLab;

    unitProgress = selectProgressForUnit(state, currentSectionId, currentUnitId);
    componentProgress = selectProgressForComponent(state, unitProgress && unitProgress.id, comp.id);
    componentsCompleted[comp.id] = getProgressStatus(componentProgress).isComplete;
  });

  return {
    unit,
    components,
    currentComponent,
    componentsWithLab,
    componentsCompleted,
    unitProgress,
    componentProgress,
  };
};

export default withRouter(connect(mapStateToProps)(ComponentProgresses));
