import React from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames';
import { selectCurrentUser } from '../../selectors/authSelectors';
import { canAuthor } from '../../helpers/userAuth';
import AuthorEditToggle from '../v2/Nav/AuthorEditToggle';
import useLabContext from '../../context/Lab/useLabContext';
import SavingIndicator from '../Utils/SavingIndicator';

export default function LabToolbar() {
  const currentUser = useSelector(selectCurrentUser);
  const { isEditMode } = useLabContext();

  const showEditToogle = canAuthor(currentUser);

  if (canAuthor(currentUser) && !showEditToogle) return null;

  const renderToolbar = () => {
    return (
      <div className={classnames('lab-tools lab-tools--opened')}>
        <div className="lab-tools__wrapper">
          <div className="lab-tools__open">
            {showEditToogle && (
              <>
                {isEditMode && (
                  <div className="edit-mode__saving-indicator">
                    <SavingIndicator />
                  </div>
                )}
                <AuthorEditToggle />
              </>
            )}
          </div>
        </div>
      </div>
    );
  };

  return renderToolbar();
}
