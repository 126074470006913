import { map as _map } from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import QuestionResponse from './QuestionResponse';
import Icons from '../Utils/Icons';
import { isSubmitted, buildSelectableChoices, organizeUserChoices } from '../../helpers/questionHelper';
import RTEContent from '../../helpers/contentMathjaxHelper';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';

export default class QuestionSingleSelect extends Component {
  constructor(props) {
    super(props);

    const { question, questionProgress } = this.props;

    const choices = _map(question.choices, (choice, index) => ({
      ...choice,
      original_index: index,
    }));
    const userChoices = organizeUserChoices(this.props.question, choices);
    this.state = this.initialState(userChoices);

    if (isSubmitted(questionProgress)) {
      const selectedChoice = questionProgress.choices.find(choice => choice.is_selected);
      if (selectedChoice) {
        this.state.selectedChoice = selectedChoice;
        this.state.userChoiceIndex = this.findChoiceIndex(selectedChoice);
        this.state.questionState = this.isChoiceCorrect(this.state.userChoiceIndex);
        this.state.isSubmitted = true;
      }
    }
  }

  initialState = choices => ({
    userChoices: choices,
    userChoiceIndex: null,
    questionState: 'initial',
    isSubmitted: false,
  });

  findChoiceIndex = lookingChoice => this.props.question.choices.findIndex(choice => choice.id === lookingChoice.id);

  getChoice = (choiceIndex = this.state.userChoiceIndex) => this.props.question.choices[choiceIndex];

  isChoiceCorrect = (choiceIndex = this.state.userChoiceIndex) =>
    this.getChoice(choiceIndex).is_correct ? 'correct' : 'incorrect';

  getCorrectChoice = () => this.props.question.choices.find(choice => choice.is_correct);

  handleAnswerQuestion = () => {
    if (!this.isCurrentQuestionAnswered()) {
      return;
    }

    const selectedChoice = this.props.question.choices[this.state.userChoiceIndex];
    this.setState({ selectedChoice, questionState: this.isChoiceCorrect(), isSubmitted: true });

    const selectedChoices = buildSelectableChoices(this.props.question.choices);
    selectedChoices[this.state.userChoiceIndex].is_selected = true;

    this.props.submitChoices(selectedChoices);
  };

  isCurrentQuestionAnswered = () => this.state.userChoiceIndex !== null;

  handleShowAnswer = () => {
    this.setState({ questionState: 'single_answer' });
  };

  handleResetQuestion = () => {
    this.setState(this.initialState(this.state.userChoices));
  };

  handleSelectChoice = index => {
    this.setState({ userChoiceIndex: index });
  };

  isChecked = index => {
    const { userChoiceIndex, questionState } = this.state;

    if (['single_answer', 'correct'].includes(questionState)) {
      // show answer mode
      return this.getChoice(index).is_correct;
    }
    return userChoiceIndex === index;
  };

  renderIcon(index) {
    const { userChoiceIndex, questionState } = this.state;

    if (['single_answer', 'correct'].includes(questionState)) {
      // show answer mode
      return this.getChoice(index).is_correct ? (
        <span className="checked correct">
          <Icons.Tick />
        </span>
      ) : (
        <span className="not-checked">
          <Icons.RadioCircle />
        </span>
      );
    }
    if (questionState === 'incorrect') {
      if (userChoiceIndex === index) {
        return this.getChoice(index).is_correct ? (
          <span className="checked">
            <Icons.RadioCircleChecked />
          </span>
        ) : (
          <span className="checked">
            <Icons.Cross />
          </span>
        );
      }
    }

    return userChoiceIndex === index ? (
      <span className="checked">
        <Icons.RadioCircleChecked />
      </span>
    ) : (
      <span className="not-checked">
        <Icons.RadioCircle />
      </span>
    );
  }

  render() {
    const { question } = this.props;
    const { isSubmitted, questionState, selectedChoice, userChoices } = this.state;

    return (
      <>
        <div className="content" style={{ paddingBottom: '0 !important' }}>
          <div className="questions" role="radiogroup" aria-live="polite">
            {/* eslint-disable-next-line complexity */}
            {_map(userChoices, (choice, index) => {
              const isIncorrect =
                questionState === 'incorrect' &&
                this.isChecked(choice.original_index) &&
                !this.getChoice(choice.original_index).is_correct;
              const isActive = !isSubmitted && this.isChecked(choice.original_index);
              const isPartiallyIncorrect =
                questionState === 'partially_correct' &&
                this.isChecked(choice.original_index) &&
                !this.getChoice(choice.original_index).is_correct;
              const isShowingAnswer =
                questionState === 'single_answer' && this.getChoice(choice.original_index).is_correct;
              const isCorrect =
                (questionState === 'correct' || questionState === 'partially_correct') &&
                this.isChecked(choice.original_index) &&
                this.getChoice(choice.original_index).is_correct;

              const labelClass = classnames({
                'option-label__incorrect': isIncorrect,
                'option-label': true,
                'option-label__active': isActive,
                'option-label__partially-incorrect': isPartiallyIncorrect,
                'option-label__show-answer': isShowingAnswer,
                'option-label__correct': isCorrect,
              });

              return (
                <div
                  key={choice.original_index}
                  className={classnames('question', isSubmitted && 'question---disabled')}>
                  <input
                    id={`questions-${question.id}-${index}-multiple`}
                    name={`question-${question.id}`}
                    type="radio"
                    checked={this.isChecked(choice.original_index)}
                    onChange={() => this.handleSelectChoice(choice.original_index)}
                    disabled={isSubmitted}
                  />
                  <div className="option-label-wrapper">
                    <RTEContent
                      content={choice.prompt}
                      Tag="label"
                      // classSelector="question-explanation"
                      classSelector={labelClass}
                      htmlForVar={`questions-${question.id}-${index}-multiple`}
                    />
                    {isCorrect && <CheckOutlined className="option-label__icon option-label__icon--correct" />}
                    {isIncorrect && <CloseOutlined className="option-label__icon option-label__icon--incorrect" />}
                  </div>
                </div>
              );
            })}
          </div>

          <QuestionResponse
            question={this.props.question}
            questionState={questionState}
            selectedChoiceExplanation={selectedChoice && selectedChoice.explanation}
            correctChoiceExplanation={this.getCorrectChoice().explanation}
            handleAnswerQuestion={this.handleAnswerQuestion}
            isCurrentQuestionAnswered={this.isCurrentQuestionAnswered}
            handleShowAnswer={this.handleShowAnswer}
            handleResetQuestion={this.handleResetQuestion}
            renderNext={this.props.renderNext}
            renderQuestionsNavigation={this.props.renderQuestionsNavigation}
          />
        </div>
      </>
    );
  }
}

QuestionSingleSelect.propTypes = {
  question: PropTypes.object.isRequired,
  questionProgress: PropTypes.object,
  submitChoices: PropTypes.func.isRequired,
};
