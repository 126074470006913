import React from 'react';
import PropTypes from 'prop-types';

import intl from '../../../utils/intl';
import SimpleModal2 from '../../library/SimpleModal2/SimpleModal2';
import bgAlertImg from '../../../styles/v2/images/lab/bg-alert-purple.png';
import iconLabNotAvailableImg from '../../../styles/v2/images/lab/icon-lab-not-available.png';
import { getCoursePath } from '../../../helpers/pathHelper';

const AssignmentNotAvailableModal = ({ prereqTitle, history, currentUser, bootcamp, path }) => {
  return (
    <SimpleModal2
      backgroundImage={`url(${bgAlertImg})`}
      icon={iconLabNotAvailableImg}
      title={intl.formatMessage({ id: 'tutorial.assignmentModal.assignmentNotAvailable.title' })}
      body={intl.formatMessage({ id: 'tutorial.assignmentModal.assignmentNotAvailable.body' }, { title: prereqTitle })}
      leftButtonText={intl.formatMessage({ id: 'tutorial.assignmentModal.seeLessons' })}
      onLeftButton={() => history.push(getCoursePath(currentUser, bootcamp, path))}
    />
  );
};

AssignmentNotAvailableModal.propTypes = {
  prereqTitle: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  bootcamp: PropTypes.object.isRequired,
  path: PropTypes.object.isRequired,
};

export default AssignmentNotAvailableModal;
