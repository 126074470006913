import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ContentEditable from 'react-contenteditable';
import NewComment from './NewComment';
import Comments from './Comments';
import Loading from '../../Utils/Loading';
import Icons from '../../Utils/Icons';
import CKEditor from '../../Utils/CKEditor';
import BlockHeader from '../../Utils/BlockHeader';
import BlockEditOptionsContainer from '../../Author/Blocks/BlockEditOptionsContainer';
import intl from '../../../utils/intl';
import { contentForStudent } from '../../../helpers/blockHelper';
import useSaveBlock from '../../../hooks/useSaveBlock';
import BlockConflictModal from '../../Author/Blocks/BlockConflictModal/BlockConflictModal';
import ContentValidator from '../../Author/Blocks/ContentValidator';
import MultipleEditor from '../MultipleEditor';

export default function CommentThread(props) {
  const { block, cohortId, fetchComments, comments, user, index, totalBlocks, onReorder, isEditMode } = props;

  const [loading, setLoading] = useState(false);
  const [loadFail, setLoadFail] = useState(false);
  const { blockState, handleConflictSave, contentRef, conflict, handleChange } = useSaveBlock(block);

  const getDisabled = () => !cohortId;

  useEffect(() => {
    if (getDisabled()) {
      setLoading(false);
    } else {
      (async () => {
        try {
          await fetchComments(block.id, cohortId);
        } catch {
          setLoadFail(true);
        } finally {
          setLoading(false);
        }
      })();
    }
  }, []);

  const saveComment = content => props.saveComment({ content }, block.id, cohortId);

  return (
    <ContentValidator content={blockState.content}>
      <div className="block-label">
        <div className="block-label__content">
          <div className="comment-thread-wrapper">
            <div className="comment-thread">
              {isEditMode && (
                <BlockEditOptionsContainer block={block} index={index} total={totalBlocks} onReorder={onReorder} />
              )}

              <div className="section">
                <BlockHeader messageId="common.commentThread" icon={<Icons.LabelCommentThread />} />
                <div className="comment-thread__header">
                  {isEditMode && (
                    <MultipleEditor blockId={blockState.id}>
                      <ContentEditable
                        html={blockState.title || ''}
                        className="heading"
                        tagName="h2"
                        onChange={handleChange('title')}
                      />
                      <div className="text">
                        <CKEditor
                          defaultContent={intl.formatMessage({ id: 'block.defaultHtmlContent' })}
                          inline
                          content={blockState.content}
                          onChange={handleChange('content')}
                          ref={contentRef}
                        />
                      </div>
                    </MultipleEditor>
                  )}

                  {!isEditMode && (
                    <>
                      <h2 className="heading" dangerouslySetInnerHTML={{ __html: block.title }} />
                      <div className="text" dangerouslySetInnerHTML={{ __html: contentForStudent(block.content) }} />
                    </>
                  )}

                  <NewComment user={user} saveComment={saveComment} disabled={getDisabled()} />
                </div>

                <div className="comment-thread__body">
                  {loading && <Loading />}
                  {loadFail && <Loading messageId="common.loadFailure" />}
                  {comments && <Comments comments={comments} />}
                </div>
              </div>
            </div>

            {conflict.visible && (
              <BlockConflictModal
                remoteBlock={conflict.remote}
                currentBlock={conflict.current}
                onOk={handleConflictSave}
                onCancel={conflict.hide}
              />
            )}
          </div>
        </div>
      </div>
    </ContentValidator>
  );
}

CommentThread.propTypes = {
  block: PropTypes.object.isRequired,
  comments: PropTypes.array,
  cohortId: PropTypes.string,
  user: NewComment.propTypes.user,
  saveComment: NewComment.propTypes.saveComment,
  fetchComments: PropTypes.func.isRequired,
  isEditMode: PropTypes.bool,
  index: PropTypes.number,
  totalBlocks: PropTypes.number,
  onReorder: PropTypes.func,
};
