import { useSelector } from 'react-redux';
import useCurrentGradedQuiz from './useCurrentGradedQuiz';
import { selectProgressForUnit } from '../selectors/unitProgressSelectors';

export default function useCurrentGradedQuizProgress() {
  const { unit, section } = useCurrentGradedQuiz();
  const unitProgress = useSelector(s => selectProgressForUnit(s, section?.id, unit?.id));

  return { unitProgress };
}
