/* eslint-disable */
import React from 'react';
export const PathstreamLoader = ({ className }) => (
    <svg className={className} viewBox="0 0 173 173" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#allClip)">
        <g fill="#FF4F51" clipPath="url(#redClip)">
          <path d="M31.595 114.085h9.208a3.592 3.592 0 0 1 3.589 3.602v.184c0 1.984-1.61 3.602-3.589 3.602h-9.208a3.592 3.592 0 0 1-3.588-3.602v-.184c0-1.984 1.61-3.602 3.588-3.602z" />
          <path d="M100.058 113.936h-.183c-.972 0-1.917.394-2.603 1.08a3.718 3.718 0 0 0-1.08 2.61c0 .972.393 1.923 1.08 2.61a3.702 3.702 0 0 0 2.603 1.08h12.579c33.443 0 60.546-27.157 60.546-60.655C173.007 27.157 145.897 0 112.461 0 79.024 0 51.915 27.157 51.915 60.655V169.31a3.688 3.688 0 0 0 3.683 3.69 3.687 3.687 0 0 0 3.684-3.69V60.655c.054-29.42 23.813-53.22 53.179-53.275 29.366.055 53.124 23.855 53.179 53.275-.055 29.42-23.813 53.219-53.179 53.274h-12.58c-.972 0-1.916.394-2.603 1.08a3.718 3.718 0 0 0-1.08 2.61c0 .972.394 1.923 1.08 2.61a3.702 3.702 0 0 0 2.603 1.08h.184" />
        </g>
        <g fill="orange" clipPath="url(#orangeClip)">
          <path d="M40.79 99.69H17.602A3.602 3.602 0 0 0 14 103.294v.176a3.602 3.602 0 0 0 3.602 3.602H40.79a3.602 3.602 0 0 0 3.602-3.602v-.176a3.602 3.602 0 0 0-3.602-3.602z" />
          <path d="M73.9 155.507v-94.94c.04-21.49 17.391-38.867 38.839-38.908 21.449.04 38.799 17.418 38.84 38.907-.041 21.49-17.391 38.867-38.84 38.907H99.881a3.688 3.688 0 0 0-3.683 3.691 3.688 3.688 0 0 0 3.683 3.69h12.858c25.52 0 46.207-20.721 46.207-46.288 0-25.567-20.687-46.288-46.207-46.288-25.519 0-46.206 20.721-46.206 46.288v94.941a3.688 3.688 0 0 0 3.684 3.69 3.687 3.687 0 0 0 3.683-3.69z" />
        </g>
        <g fill="#039BE8" clipPath="url(#blueClip)">
          <path d="M3.595 85.297h37.208a3.592 3.592 0 0 1 3.589 3.602v.183c0 1.985-1.61 3.602-3.589 3.602H3.595A3.598 3.598 0 0 1 0 89.082V88.9a3.602 3.602 0 0 1 3.595-3.602z" />
          <path d="M88.702 141.262V60.654c.027-13.551 10.969-24.513 24.493-24.54 13.531.027 24.472 10.989 24.5 24.54-.028 13.552-10.969 24.52-24.5 24.54H99.841a3.688 3.688 0 0 0-3.684 3.691 3.688 3.688 0 0 0 3.684 3.69h13.354c17.602 0 31.86-14.292 31.867-31.92 0-17.63-14.265-31.922-31.867-31.922s-31.86 14.292-31.867 31.921v80.601a3.688 3.688 0 0 0 3.684 3.691 3.688 3.688 0 0 0 3.683-3.691l.007.007z" />
        </g>
      </g>
      <defs>
        <clipPath id="redClip">
          <rect transform="rotate(180,72,173)" x="50" y="173" width="44" height="0">
            <animate id="rc1" attributeName="height" from="0" to="113" begin="0s;ac1.end" dur="200ms" fill="freeze" />
            <set attributeName="height" to="0" begin="ac1.end - 1ms" />
          </rect>
          <polyline points="113,61 42,61 42,61">
            <animate
              id="rc21"
              attributeName="points"
              from="113,61 42,61 42,61"
              to="113,61 42,61 53,26"
              begin="rc1.end"
              dur="50ms"
              fill="freeze"
            />
            <animate
              id="rc22"
              attributeName="points"
              from="113,61 42,61 53,26 53,26"
              to="113,61 42,61 53,26 78,1"
              begin="rc21.end"
              dur="50ms"
              fill="freeze"
            />
            <animate
              id="rc23"
              attributeName="points"
              from="113,61 42,61 53,26 78,1 78,1"
              to="113,61 42,61 53,26 78,1 113,-10"
              begin="rc22.end"
              dur="50ms"
              fill="freeze"
            />
            <animate
              id="rc24"
              attributeName="points"
              from="113,61 42,61 53,26 78,1 113,-10 113,-10"
              to="113,61 42,61 53,26 78,1 113,-10 148,1"
              begin="rc23.end"
              dur="50ms"
              fill="freeze"
            />
            <animate
              id="rc25"
              attributeName="points"
              from="113,61 42,61 53,26 78,1 113,-10 148,1 148,1"
              to="113,61 42,61 53,26 78,1 113,-10 148,1 173,26"
              begin="rc24.end"
              dur="50ms"
              fill="freeze"
            />
            <animate
              id="rc26"
              attributeName="points"
              from="113,61 42,61 53,26 78,1 113,-10 148,1 173,26 173,26"
              to="113,61 42,61 53,26 78,1 113,-10 148,1 173,26 183,61"
              begin="rc25.end"
              dur="50ms"
              fill="freeze"
            />
            <animate
              id="rc27"
              attributeName="points"
              from="113,61 42,61 53,26 78,1 113,-10 148,1 173,26 183,61 183,61"
              to="113,61 42,61 53,26 78,1 113,-10 148,1 173,26 183,61 173,96"
              begin="rc26.end"
              dur="50ms"
              fill="freeze"
            />
            <animate
              id="rc28"
              attributeName="points"
              from="113,61 42,61 53,26 78,1 113,-10 148,1 173,26 183,61 173,96 173,96"
              to="113,61 42,61 53,26 78,1 113,-10 148,1 173,26 183,61 173,96 148,121"
              begin="rc27.end"
              dur="50ms"
              fill="freeze"
            />
            <animate
              id="rc29"
              attributeName="points"
              from="113,61 42,61 53,26 78,1 113,-10 148,1 173,26 183,61 173,96 148,121 148,121"
              to="113,61 42,61 53,26 78,1 113,-10 148,1 173,26 183,61 173,96 148,121 113,132"
              begin="rc28.end"
              dur="50ms"
              fill="freeze"
            />
            <set attributeName="points" to="113,61 42,61 42,61" begin="ac1.end - 1ms" />
          </polyline>
          <rect transform="rotate(180,114,105)" x="114" y="80" height="50">
            <animate id="rc3" attributeName="width" from="0" to="116" begin="rc29.end" dur="200ms" fill="freeze" />
            <set attributeName="width" to="0" begin="ac1.end - 1ms" />
          </rect>
        </clipPath>
        <clipPath id="orangeClip">
          <rect transform="rotate(180,72,173)" x="50" y="173" width="44" height="0">
            <animate
              id="oc1"
              attributeName="height"
              from="0"
              to="113"
              begin="rc1.begin + 100ms"
              dur="200ms"
              fill="freeze"
            />
            <set attributeName="height" to="0" begin="ac1.end - 1ms" />
          </rect>
          <polyline points="113,61 42,61 42,61">
            <animate
              id="oc21"
              attributeName="points"
              from="113,61 42,61 42,61"
              to="113,61 42,61 53,26"
              begin="oc1.end"
              dur="50ms"
              fill="freeze"
            />
            <animate
              id="oc22"
              attributeName="points"
              from="113,61 42,61 53,26 53,26"
              to="113,61 42,61 53,26 78,1"
              begin="oc21.end"
              dur="50ms"
              fill="freeze"
            />
            <animate
              id="oc23"
              attributeName="points"
              from="113,61 42,61 53,26 78,1 78,1"
              to="113,61 42,61 53,26 78,1 113,-10"
              begin="oc22.end"
              dur="50ms"
              fill="freeze"
            />
            <animate
              id="oc24"
              attributeName="points"
              from="113,61 42,61 53,26 78,1 113,-10 113,-10"
              to="113,61 42,61 53,26 78,1 113,-10 148,1"
              begin="oc23.end"
              dur="50ms"
              fill="freeze"
            />
            <animate
              id="oc25"
              attributeName="points"
              from="113,61 42,61 53,26 78,1 113,-10 148,1 148,1"
              to="113,61 42,61 53,26 78,1 113,-10 148,1 173,26"
              begin="oc24.end"
              dur="50ms"
              fill="freeze"
            />
            <animate
              id="oc26"
              attributeName="points"
              from="113,61 42,61 53,26 78,1 113,-10 148,1 173,26 173,26"
              to="113,61 42,61 53,26 78,1 113,-10 148,1 173,26 183,61"
              begin="oc25.end"
              dur="50ms"
              fill="freeze"
            />
            <animate
              id="oc27"
              attributeName="points"
              from="113,61 42,61 53,26 78,1 113,-10 148,1 173,26 183,61 183,61"
              to="113,61 42,61 53,26 78,1 113,-10 148,1 173,26 183,61 173,96"
              begin="oc26.end"
              dur="50ms"
              fill="freeze"
            />
            <animate
              id="oc28"
              attributeName="points"
              from="113,61 42,61 53,26 78,1 113,-10 148,1 173,26 183,61 173,96 173,96"
              to="113,61 42,61 53,26 78,1 113,-10 148,1 173,26 183,61 173,96 148,121"
              begin="oc27.end"
              dur="50ms"
              fill="freeze"
            />
            <animate
              id="oc29"
              attributeName="points"
              from="113,61 42,61 53,26 78,1 113,-10 148,1 173,26 183,61 173,96 148,121 148,121"
              to="113,61 42,61 53,26 78,1 113,-10 148,1 173,26 183,61 173,96 148,121 113,132"
              begin="oc28.end"
              dur="50ms"
              fill="freeze"
            />
            <set attributeName="points" to="113,61 42,61 42,61" begin="ac1.end - 1ms" />
          </polyline>
          <rect transform="rotate(180,114,105)" x="114" y="80" height="50">
            <animate id="oc3" attributeName="width" from="0" to="116" begin="oc29.end" dur="200ms" fill="freeze" />
            <set attributeName="width" to="0" begin="ac1.end - 1ms" />
          </rect>
        </clipPath>
        <clipPath id="blueClip">
          <rect transform="rotate(180,72,173)" x="50" y="173" width="44" height="0">
            <animate
              id="bc1"
              attributeName="height"
              from="0"
              to="113"
              begin="oc1.begin + 100ms"
              dur="200ms"
              fill="freeze"
            />
            <set attributeName="height" to="0" begin="ac1.end - 1ms" />
          </rect>
          <polyline points="113,61 42,61 42,61">
            <animate
              id="bc21"
              attributeName="points"
              from="113,61 42,61 42,61"
              to="113,61 42,61 53,26"
              begin="bc1.end"
              dur="50ms"
              fill="freeze"
            />
            <animate
              id="bc22"
              attributeName="points"
              from="113,61 42,61 53,26 53,26"
              to="113,61 42,61 53,26 78,1"
              begin="bc21.end"
              dur="50ms"
              fill="freeze"
            />
            <animate
              id="bc23"
              attributeName="points"
              from="113,61 42,61 53,26 78,1 78,1"
              to="113,61 42,61 53,26 78,1 113,-10"
              begin="bc22.end"
              dur="50ms"
              fill="freeze"
            />
            <animate
              id="bc24"
              attributeName="points"
              from="113,61 42,61 53,26 78,1 113,-10 113,-10"
              to="113,61 42,61 53,26 78,1 113,-10 148,1"
              begin="bc23.end"
              dur="50ms"
              fill="freeze"
            />
            <animate
              id="bc25"
              attributeName="points"
              from="113,61 42,61 53,26 78,1 113,-10 148,1 148,1"
              to="113,61 42,61 53,26 78,1 113,-10 148,1 173,26"
              begin="bc24.end"
              dur="50ms"
              fill="freeze"
            />
            <animate
              id="bc26"
              attributeName="points"
              from="113,61 42,61 53,26 78,1 113,-10 148,1 173,26 173,26"
              to="113,61 42,61 53,26 78,1 113,-10 148,1 173,26 183,61"
              begin="bc25.end"
              dur="50ms"
              fill="freeze"
            />
            <animate
              id="bc27"
              attributeName="points"
              from="113,61 42,61 53,26 78,1 113,-10 148,1 173,26 183,61 183,61"
              to="113,61 42,61 53,26 78,1 113,-10 148,1 173,26 183,61 173,96"
              begin="bc26.end"
              dur="50ms"
              fill="freeze"
            />
            <animate
              id="bc28"
              attributeName="points"
              from="113,61 42,61 53,26 78,1 113,-10 148,1 173,26 183,61 173,96 173,96"
              to="113,61 42,61 53,26 78,1 113,-10 148,1 173,26 183,61 173,96 148,121"
              begin="bc27.end"
              dur="50ms"
              fill="freeze"
            />
            <animate
              id="bc29"
              attributeName="points"
              from="113,61 42,61 53,26 78,1 113,-10 148,1 173,26 183,61 173,96 148,121 148,121"
              to="113,61 42,61 53,26 78,1 113,-10 148,1 173,26 183,61 173,96 148,121 113,132"
              begin="bc28.end"
              dur="50ms"
              fill="freeze"
            />
            <set attributeName="points" to="113,61 42,61 42,61" begin="ac1.end - 1ms" />
          </polyline>
          <rect transform="rotate(180,114,105)" x="114" y="80" height="50">
            <animate id="bc3" attributeName="width" from="0" to="116" begin="bc29.end" dur="200ms" fill="freeze" />
            <set attributeName="width" to="0" begin="ac1.end - 1ms" />
          </rect>
        </clipPath>
        <clipPath id="allClip" clipPathUnits="objectBoundingBox">
          <circle cx="0.5" cy="0.5" r="1">
            <animate id="ac1" attributeName="r" from="1" to="0" begin="bc3.end + 1s" dur="500ms" fill="freeze" />
            <set attributeName="r" to="1" begin="ac1.end" />
          </circle>
        </clipPath>
      </defs>
    </svg>
  );