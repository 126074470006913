import React from 'react';
import Icons from '../../Utils/Icons';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

const InstructorBio = ({ headshotUrl, name, title, bullets }) => {
  return (
    <div className="instructor-bio-block">
      <div className="instructor-bio-headshot-box">{headshotUrl && <img src={headshotUrl} />}</div>
      <div className="instructor-bio-block-details">
        <p className="instructor-bio-name-title">
          <strong>{name}</strong> <em>/</em> {title}
        </p>
        <ul>
          {bullets.map(str => (
            <li>{str}</li>
          ))}
        </ul>
      </div>
    </div>
  );
};

const ResourcesTab = ({ cohort, programCohort }) => {
  const hasInstructorBios = cohort.instructor_bios && cohort.instructor_bios.length > 0;

  return (
    <div className="resources-tab">
      <h3>
        <FormattedMessage id="resourcesTab.sections.downloadsTitle" />
      </h3>
      {programCohort && programCohort.handbook_url && (
        <div className="resources-row resources-syllabus-block" data-testid="handbook">
          <div className="resources-row-icon">
            <Icons.LineDescription />
          </div>
          <div className="resources-row-block">
            <h4>
              <FormattedMessage id="resourcesTab.downloadHandbook.title" />
            </h4>
            <p>
              <FormattedMessage
                id="resourcesTab.downloadHandbook.message"
                values={{ productName: programCohort.product_name }}
              />
            </p>
          </div>
          <div className="resources-row-action">
            <a className="outlined-button" href={programCohort.handbook_url} target="_blank" download>
              <Icons.FillDownload />
              <FormattedMessage id="resourcesTab.downloadHandbook.button" />
            </a>
          </div>
        </div>
      )}
      {cohort.course_overview_link && (
        <div className="resources-row resources-syllabus-block" data-testid="course-overview">
          <div className="resources-row-icon">
            <Icons.LineFolder />
          </div>
          <div className="resources-row-block">
            <h4>
              <FormattedMessage id="resourcesTab.courseOverview.title" />
            </h4>
            <p>
              <FormattedMessage id="resourcesTab.courseOverview.message" />
            </p>
          </div>
          <div className="resources-row-action">
            <a className="outlined-button" href={cohort.course_overview_link} target="_blank" download>
              <Icons.FillDownload />
              <FormattedMessage id="resourcesTab.courseOverview.button" />
            </a>
          </div>
        </div>
      )}
      <h3>
        <FormattedMessage id="resourcesTab.sections.contactTitle" />
      </h3>
      <div className="resources-row resources-row-secondary resources-email-block" data-testid="instructor-email">
        <div className="resources-row-icon">
          <Icons.LineEmail />
        </div>
        <div className="resources-row-block">
          <h4>
            <FormattedMessage id="resourcesTab.emailInstructor.title" />
          </h4>
          <p>
            <FormattedMessage id="resourcesTab.emailInstructor.message" />
          </p>
        </div>
        <div className="resources-row-action">
          <a className="outlined-button" href={`mailto:${cohort.instructor_email}`} target="_blank">
            <FormattedMessage
              id="resourcesTab.emailInstructor.button"
              values={{ instructorEmail: cohort.instructor_email }}
            />
          </a>
        </div>
      </div>
      {cohort.instructor_calendly_link && (
        <div className="resources-row resources-row-secondary resources-zoom-block" data-testid="instructor-calendly">
          <div className="resources-row-icon">
            <Icons.LineSchedule />
          </div>
          <div className="resources-row-block">
            <h4>
              <FormattedMessage id="resourcesTab.officeHours.title" />
            </h4>
            <p>
              <FormattedMessage id="resourcesTab.officeHours.message" />
            </p>
          </div>
          <div className="resources-row-action">
            <a className="outlined-button" href={cohort.instructor_calendly_link} target="_blank">
              <FormattedMessage id="resourcesTab.officeHours.button" />
            </a>
          </div>
        </div>
      )}
      <div className="resources-row resources-email-block" data-testid="tech-support">
        <div className="resources-row-icon">
          <Icons.LineBuild />
        </div>
        <div className="resources-row-block">
          <h4>
            <FormattedMessage id="resourcesTab.techSupport.title" />
          </h4>
          <p>
            <FormattedMessage
              id="resourcesTab.techSupport.message"
              values={{
                techSupportMailTo: (
                  <a target="_blank" href="mailto:help@pathstream.com">
                    help@pathstream.com
                  </a>
                ),
              }}
            />
          </p>
        </div>
      </div>
      {hasInstructorBios && (
        <div className="resources-bios-section" data-testid="instructor-bios">
          <h3>Your Instructors</h3>
          {cohort.instructor_bios.map(({ headshot_url, name, title, bullets }) => (
            <InstructorBio headshotUrl={headshot_url} name={name} title={title} bullets={bullets} />
          ))}
        </div>
      )}
    </div>
  );
};

ResourcesTab.propTypes = {
  bootcamp: PropTypes.object.isRequired,
  matchUrl: PropTypes.string.isRequired,
  programCohort: PropTypes.object.isRequired,
  cohort: PropTypes.object.isRequired,
};

export default ResourcesTab;
