import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Button } from 'antd';
import { ExclamationOutlined } from '@ant-design/icons';

import intl from '../../../utils/intl';

const NoAsanaEmailModal = ({ onHide }) => {
  const somethingWentWrong = intl.formatMessage({ id: 'profile.noAsanaEmailModal.somethingWentWrong' });
  const letUsKnow = intl.formatMessage({ id: 'profile.noAsanaEmailModal.letUsKnow' });
  const noEmail = intl.formatMessage({ id: 'profile.noAsanaEmailModal.noEmail' });
  const contactSupport = intl.formatMessage(
    { id: 'profile.noAsanaEmailModal.contactSupport' },
    { supportEmail: 'help@pathstream.com' },
  );
  const resolution = intl.formatMessage({ id: 'profile.noAsanaEmailModal.resolution' });
  const back = intl.formatMessage({ id: 'common.back' });

  return (
    <Modal onCancel={onHide} visible className="no-asana-email-modal" footer={null}>
      <div className="no-asana-email">
        <div className="modal-icon">
          <ExclamationOutlined />
        </div>

        <div className="title">
          {somethingWentWrong}
          <br />
          {letUsKnow}
        </div>

        <div className="body">
          <div className="no-email">{noEmail}</div>

          <div className="contact-support" dangerouslySetInnerHTML={{ __html: contactSupport }} />

          <div className="resolution">{resolution}</div>
        </div>
      </div>

      <div className="back">
        <Button onClick={onHide}>{back}</Button>
      </div>
    </Modal>
  );
};

NoAsanaEmailModal.propTypes = {
  onHide: PropTypes.func,
};

export default NoAsanaEmailModal;
