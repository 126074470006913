import React, { useState } from 'react';
import { Popover } from 'antd';
import { FormattedMessage } from 'react-intl';
import Icons from '../../../Utils/Icons';
import CoachShortcutsContent from './CoachShortcutsContent';

const CoachShortcuts = () => {
  const [visible, setVisible] = useState(false);

  const handleVisibleChange = () => {
    setVisible(!visible);
  };

  return (
    <Popover
      placement="bottomRight"
      content={<CoachShortcutsContent hideShortcut={handleVisibleChange} />}
      trigger="click"
      visible={visible}
      onVisibleChange={handleVisibleChange}>
      <button type="button" id="shortcuts-button" className="shortcuts-button">
        <Icons.Shortcuts />
        <FormattedMessage id="coachHeader.shortcuts.heading" />
      </button>
    </Popover>
  );
};

export default CoachShortcuts;
