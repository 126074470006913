import { values as _values } from 'lodash';
import createCachedSelector from 're-reselect';

export const selectComponentProgressEntities = state => {
  return state.entities.componentProgresses;
};

export const selectProgressForComponent = createCachedSelector(
  [
    selectComponentProgressEntities,
    (_state, unitProgressId) => unitProgressId,
    (_state, _unitProgressId, componentId) => componentId,
  ],
  (componentProgressEntities, unitProgressId, componentId) => {
    if (!unitProgressId) {
      return null;
    }

    return _values(componentProgressEntities).find(
      componentProgress =>
        componentProgress.unit_progress_id === unitProgressId && componentProgress.component_id === componentId,
    );
  },
)((_state, unitProgressId, componentId) => `${unitProgressId}_${componentId}`);
