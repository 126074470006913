import axios from './axios';

export default class Bootcamp {
  static async all(_) {
    const response = await axios.get('/paths/bootcamps');
    return response.data;
  }

  static async get(_, bootcampId) {
    const response = await axios.get(`/paths/bootcamps/${bootcampId}`);
    return response.data;
  }

  static async save(_, bootcamp) {
    const response = bootcamp.id
      ? await axios.put(`/paths/bootcamps/${bootcamp.id}`, bootcamp)
      : await axios.post('/paths/bootcamps', bootcamp);

    return response.data;
  }

  static async destroy(_, bootcamp) {
    const response = await axios.delete(`/paths/bootcamps/${bootcamp.id}`, bootcamp);
    return response.data;
  }

  static async authorIds(_, bootcampId) {
    const response = await axios.get(`/paths/bootcamps/${bootcampId}/authors`);
    return response.data.value;
  }

  static async addAuthor(_, bootcampId, authorId) {
    const response = await axios.post(`/paths/bootcamps/${bootcampId}/add_author`, { author_id: authorId });
    return response.data;
  }

  static async removeAuthor(_, bootcampId, authorId) {
    const response = await axios.post(`/paths/bootcamps/${bootcampId}/remove_author`, {
      author_id: authorId,
    });
    return response.data;
  }

  static async copy(_, bootcampId) {
    const response = await axios.post(`/paths/bootcamps/${bootcampId}/copy`);

    return response.data;
  }
}
