import React from 'react';
import { getCoursePath } from '../../../../../helpers/pathHelper';
import { Link, useLocation } from 'react-router-dom';
import { EyeFilled } from '@ant-design/icons';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { selectUnitByAssignmentId } from '../../../../../selectors/unitSelectors';
import { selectSectionFromUnit } from '../../../../../selectors/sectionSelectors';
import { selectPathFromSection } from '../../../../../selectors/pathSelectors';
import { selectBootcampFromPath } from '../../../../../selectors/bootcampSelectors';
import { selectCurrentUser } from '../../../../../selectors/authSelectors';

const ViewAsStudentButton = ({ assignment }) => {
  const unit = useSelector(state => selectUnitByAssignmentId(state, assignment.id));
  const section = useSelector(state => selectSectionFromUnit(state, unit));
  const path = useSelector(state => selectPathFromSection(state, section));
  const bootcamp = useSelector(state => selectBootcampFromPath(state, path));
  const currentUser = useSelector(selectCurrentUser);
  const location = useLocation();

  if (!unit || !section || !path || !bootcamp || !currentUser) {
    return null;
  }

  const coursePath = getCoursePath(currentUser, bootcamp, path);
  // Passing returnUrl so assignments page will know where to go back to when back button is clicked
  const returnUrl = encodeURIComponent(`${location.pathname}${location.search}`);
  const viewAsStudentUrl = `${coursePath}/sections/${section.id}/projects/${unit.id}?skipLaunch=true&returnUrl=${returnUrl}`;

  return (
    <div className="assignment-actions">
      <Link className="action-button" to={viewAsStudentUrl}>
        <EyeFilled />
        <FormattedMessage id="cohortDashboard.assignmentsPage.viewAssignmentContent" />
      </Link>
    </div>
  );
};

export default ViewAsStudentButton;
