import { compact as _compact, map as _map } from 'lodash';
import createCachedSelector from 're-reselect';
import { selectSectionEntities } from './sectionSelectors';
import { selectEntities, selectAll, select, selectHasMany } from './applicationSelectors';

export const selectPathEntities = selectEntities('paths');
export const selectPaths = selectAll(selectPathEntities);
export const selectPath = select(selectPathEntities);
export const selectPathFromSection = selectHasMany(selectPaths, 'section_ids');

const pathSectionIds = (state, pathId) => (selectPath(state, pathId) || {}).section_ids;

export const selectPathSections = createCachedSelector(
  [pathSectionIds, selectSectionEntities],
  (sectionIds, sectionEntities) => {
    return _compact(_map(sectionIds, sectionId => sectionEntities[sectionId]));
  },
)((_state, pathId) => pathId);
