import React, { Component } from 'react';
import PropTypes from 'prop-types';
import EditQuizQuestionContainer from './EditQuizQuestionContainer';
import EditQuiz from './EditQuiz';
import { NEW_QUESTION } from '../../../helpers/questionHelper';
import Question from '../../../api/question';
import Select from 'react-select';
import intl from '../../../utils/intl';

export default class EditSingleQuestionQuiz extends Component {
  static propTypes = {
    block: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = { newQuestionType: Question.FREE_TEXT_ANSWER };
  }

  getNewQuestion = (quiz, newQuestionType) => {
    const newQuestion = NEW_QUESTION[newQuestionType];
    newQuestion.quiz_id = quiz.id;
    return newQuestion;
  };

  render() {
    const { block } = this.props;
    const { newQuestionType } = this.state;

    const options = [
      { label: intl.formatMessage({ id: 'question.addSingleSelect' }), value: Question.SINGLE_SELECT },
      { label: intl.formatMessage({ id: 'question.addMultiSelect' }), value: Question.MULTI_SELECT },
      { label: intl.formatMessage({ id: 'question.addFreeTextAnswer' }), value: Question.FREE_TEXT_ANSWER },
    ];

    return (
      <EditQuiz
        block={block}
        renderBody={(quiz, questions) =>
          questions.length === 0 ? (
            <React.Fragment>
              <Select
                className="Select--simple"
                name="newQuestionType"
                value={newQuestionType}
                onChange={option => this.setState({ newQuestionType: option.value })}
                options={options}
                clearable={false}
                searchable={false}
                id="newQuestionType"
              />
              <EditQuizQuestionContainer key={Math.random()} question={this.getNewQuestion(quiz, newQuestionType)} />
            </React.Fragment>
          ) : (
            <EditQuizQuestionContainer key={questions[0].id} question={questions[0]} />
          )
        }
      />
    );
  }
}
