import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { size as _size } from 'lodash';
import { Result } from './Result';
import { MAX_GRADED_QUIZ_ATTEMPT } from '../../common/constants';
import intl from '../../utils/intl';
import { ResultNextStep } from './ResultNextStep';
import { getProgress } from '../../selectors/overallProgressSelector';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { formatDuration } from '../../utils/utils';
import useNextUrl from '../../hooks/useNextUrl';
import Unit from '../../api/unit';
import { fetchEnrollment } from '../../actions/enrollmentActions';
import { useDispatch } from 'react-redux';
import { selectCohortForEnrollment } from '../../selectors/cohortSelectors';
import { selectEnrollmentForBootcamp } from '../../selectors/enrollmentSelectors';

const PERCENT_TO_PASS = '70%';

/* eslint-disable complexity*/
export const ResultPage = ({ quizProgress, questionProgresses, onRetry, completeQuiz, totalAttempts }) => {
  const nextUrl = useNextUrl();
  const dispatch = useDispatch();
  const { bootcampId, pathId, sectionId, unitId, componentId } = useParams();
  const { currentProgress, nextProgress } = useSelector(s =>
    getProgress(s, { bootcampId, pathId, sectionId, unitId, componentId }),
  );

  const currentTitle = useMemo(() => {
    if (currentProgress?.sectionComplete) {
      return currentProgress.section.title;
    } else if (currentProgress?.unitComplete) {
      return currentProgress.unit.title;
    } else if (currentProgress?.component) {
      return currentProgress.component.title;
    }
  }, [currentProgress]);

  const questionProgressesList = Object.values(questionProgresses);
  const quizAttempt = quizProgress.quiz_attempt;
  const maxAttemptReached = quizAttempt === MAX_GRADED_QUIZ_ATTEMPT;

  let incorrectAnswers = 0;
  let points = 0;
  let totalPoints = 0;

  questionProgressesList.forEach(progress => {
    if (progress.user_score < 1) {
      incorrectAnswers += 1;
    }
    if (progress?.user_score) {
      points += progress.user_score;
    }
    if (progress?.question_score) {
      totalPoints += progress.question_score;
    }
  });

  let percentageCorrect = (points / totalPoints).toFixed(2) * 100;
  let perfectScore = percentageCorrect === 100;

  const canRetake = !maxAttemptReached && !perfectScore;
  const quizCompleted = quizProgress.completed_at;
  const nextComponent = nextProgress && nextProgress.component;
  const isProjectNext = nextProgress && nextProgress.unit.unit_type === Unit.PROJECT;
  const isGradedQuizNext = nextProgress && nextProgress.unit.unit_type === Unit.GRADED_QUIZ;

  useEffect(() => {
    if (!currentProgress?.pathProgress || !quizProgress?.completed_at) return;

    dispatch(fetchEnrollment(currentProgress.pathProgress.enrollment_id));
  }, [quizProgress?.completed_at]);

  const renderNextLesson = () => (
    <ResultNextStep
      currentTitle={currentTitle}
      nextTitle={nextComponent.title}
      nextUrl={nextUrl}
      nextLessonLength={formatDuration(nextComponent.duration)}
    />
  );

  const renderNextProject = () => (
    <ResultNextStep currentTitle={currentTitle} nextTitle={nextProgress.unit.title} nextUrl={nextUrl} />
  );

  const renderNextGradedQuiz = () => (
    <ResultNextStep currentTitle={currentTitle} nextTitle={nextProgress.unit.title} nextUrl={nextUrl} />
  );

  const enrollment = useSelector(state => selectEnrollmentForBootcamp(state, bootcampId));
  const bootcampInstance = enrollment && useSelector(state => selectCohortForEnrollment(state, enrollment.id));
  const pastDeadline = bootcampInstance && bootcampInstance.past_deadline;

  return (
    <div className="result-page-container">
      <div className={quizCompleted ? 'result-summary-section' : 'result-summary-section--unsubmitted'}>
        {quizCompleted ? (
          <div className="result-header">
            <div className="title">
              <FormattedMessage id={'gradedQuiz.quizCompleted'} />
            </div>
            <div className="subtitle">
              <FormattedMessage id={'gradedQuiz.submitted'} />
            </div>
          </div>
        ) : null}
        <div className="result-summary">
          <Result
            incorrectAnswers={incorrectAnswers}
            points={points}
            totalPoints={totalPoints}
            attempts={quizProgress.quiz_attempt || 1}
            totalAttempts={totalAttempts || 2}
            variant={quizCompleted ? 'small' : 'big'}
          />
        </div>
      </div>
      {quizCompleted ? (
        <div className="grades-have-been-submitted-text">
          {nextComponent && renderNextLesson()}
          {isProjectNext && renderNextProject()}
          {isGradedQuizNext && renderNextGradedQuiz()}
          <FormattedMessage id={'gradedQuiz.reviewFeedback'} />
        </div>
      ) : (
        <div>
          {canRetake ? (
            <div>
              <p>
                <FormattedMessage id={'gradedQuiz.neededToPass'} values={{ percentToPass: PERCENT_TO_PASS }} />
                <b>
                  {incorrectAnswers === 1 ? (
                    <FormattedMessage id={'gradedQuiz.oneMoreChanceSingular'} />
                  ) : (
                    <FormattedMessage id={'gradedQuiz.oneMoreChancePlural'} />
                  )}
                </b>
              </p>
              <p className="retry-text">
                <FormattedMessage id={'gradedQuiz.likeToRetry'} />
                <span className="emphasized-red-text">
                  <FormattedMessage
                    id={incorrectAnswers === 1 ? 'gradedQuiz.incorrectAnswer' : 'gradedQuiz.incorrectAnswers'}
                    values={{ incorrectAnswers: incorrectAnswers }}
                  />
                </span>
                <span className="question-mark">?</span>
              </p>
            </div>
          ) : (
            <p className="submit-text">{!pastDeadline && <FormattedMessage id={'gradedQuiz.submitPrompt'} />}</p>
          )}
          <div className={canRetake ? 'side-by-side-btn' : 'btn-single-submit'}>
            {canRetake && (
              <button
                type="button"
                onClick={onRetry}
                aria-label={intl.formatMessage({ id: 'question.tryAgain' })}
                className="button-standard button-standard--blue-9 button-standard--short">
                <FormattedMessage id="question.tryAgain" description="Try Again" />
              </button>
            )}
            {!pastDeadline && (
              <button
                type="button"
                onClick={() => {
                  completeQuiz(quizProgress, questionProgressesList);
                }}
                aria-label={intl.formatMessage({ id: 'common.submit' })}
                className="button-standard button-standard--blue-9 button-standard--short">
                <FormattedMessage id={'common.submit'} />
              </button>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

ResultPage.propTypes = {
  completeQuiz: PropTypes.func,
  quizProgress: PropTypes.object,
  questionProgresses: PropTypes.object,
};
