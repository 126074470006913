import React from 'react';
import moment from 'moment';
import Icons from '../../Utils/Icons';
import PropTypes from 'prop-types';

const GradedItemColumn = ({ itemType, itemNum, title, linkUrl, graded, score_percentage, dueDate }) => {
  let copy, icon;
  let dateString = new Date(dueDate).toDateString();
  if (itemType === 'assignment') {
    copy = `Project ${itemNum}`;
    icon = <Icons.Assignment2 />;
  } else {
    copy = `Quiz ${itemNum}`;
    icon = <Icons.Puzzle />;
  }

  return (
    <td className="gradable-title-container">
      <div className="gradable-type">{copy}</div>
      <div className="gradable-icon">
        <span className="icon-element icon-element--background-main-blue-500 icon-element--size-brian">{icon}</span>
      </div>
      <div className="gradable-title">
        <a
          href={linkUrl}
          aria-label={
            graded
              ? `${title} your grade is ${score_percentage}% assignment due at ${dateString}`
              : `${title} assignment due at ${dateString}`
          }>
          {title}
        </a>
      </div>
    </td>
  );
};

const SubmittedColumn = ({ completed }) => {
  return (
    <td>
      {completed && (
        <span className="submitted-grade icon-element--fill-white icon-element--background-green">
          <Icons.Check />
        </span>
      )}
    </td>
  );
};

const FeedbackColumn = ({ completed, graded }) => {
  let feedbackClass, feedback;
  feedbackClass = feedback = '';

  if (graded) {
    feedback = 'available';
  } else if (completed) {
    feedbackClass = feedback = 'pending';
  }

  return (
    <td className="grade-feedback">
      <div className={feedbackClass}>{feedback.toUpperCase()}</div>
    </td>
  );
};

const WeightColumn = ({ weight }) => {
  const copy = `${weight} %`;

  return <td>{copy}</td>;
};

const GradeColumn = ({ score_percentage }) => {
  let copy = '';
  if (Number.isFinite(score_percentage)) {
    copy = `${Math.round(score_percentage)} %`;
  }

  return <td>{copy}</td>;
};

const StatusColumn = ({ score_percentage }) => {
  let statusClass, status;
  statusClass = status = '';

  if (Number.isFinite(score_percentage)) {
    if (score_percentage < 70) {
      statusClass = 'no-pass';
      status = 'NO PASS';
    } else {
      statusClass = 'pass';
      status = 'PASS';
    }
  }

  return <td className={statusClass}>{status}</td>;
};

const DueDateColumn = ({ dueDate }) => {
  const copy = dueDate ? moment(dueDate).format('MM/DD/YYYY') : '';

  return <td>{copy}</td>;
};

const GradedRow = ({ item, relativeIndex: itemNum, linkUrl, hideDueDates }) => {
  const { template_type: itemType, title, completed_at, weight, score, score_percentage, due_date: dueDate } = item;

  const completed = !!completed_at;
  const graded = Number.isFinite(score);

  return (
    <tr data-testid="graded-row">
      <GradedItemColumn {...{ itemType, itemNum, title, linkUrl, score_percentage, dueDate, graded }} />
      <SubmittedColumn {...{ completed }} />
      <FeedbackColumn {...{ completed, graded }} />
      <WeightColumn {...{ weight }} />
      <GradeColumn {...{ score_percentage }} />
      <StatusColumn {...{ score_percentage }} />
      {!hideDueDates && <DueDateColumn {...{ dueDate }} />}
    </tr>
  );
};

GradedRow.propTypes = {
  item: PropTypes.object.isRequired,
  relativeIndex: PropTypes.number.isRequired,
  linkUrl: PropTypes.string.isRequired,
};

export default GradedRow;
