import { connect } from 'react-redux';
import { getLtiCohortId, acceptMultipleItems } from '../../helpers/ltiParams';
import { selectCohort } from '../../selectors/cohortSelectors';
import { selectBootcamp, selectBootcampPaths } from '../../selectors/bootcampSelectors';
import { selectPathSections } from '../../selectors/pathSelectors';
import LtiAssignmentSelector from './LtiAssignmentSelector';
import { fetchCohort } from '../../actions/cohortActions';

const mapStateToProps = state => {
  const cohortId = getLtiCohortId();
  const cohort = selectCohort(state, cohortId);
  const bootcamp = cohort && selectBootcamp(state, cohort.bootcamp_id);
  const path = bootcamp && selectBootcampPaths(state, cohort.bootcamp_id)[0];
  const sections = (path && selectPathSections(state, path.id)) || [];
  const isMultiSelect = acceptMultipleItems();

  return {
    cohortId,
    cohort,
    bootcamp,
    path,
    sections,
    isMultiSelect,
  };
};

export default connect(mapStateToProps, { fetchCohort })(LtiAssignmentSelector);
