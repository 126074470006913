import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Cascader, Form, Input, Button } from 'antd';
import { v4 as uuidv4 } from 'uuid';
import { FormattedMessage } from 'react-intl';
import { PlusCircleOutlined, MinusCircleOutlined } from '@ant-design/icons';
import getCheckOptions from '../../../utils/checkPermutations';
import { operators } from '../../../utils/checkOptions';
import locationPaths from './locationPaths';
import CustomSelect from '../../shared/CustomSelect/CustomSelect';
import intl from '../../../../utils/intl';
import { AutograderLevel, OTHER_PROPS } from '../../../utils/constants/AutograderEnums';

const CheckBuilder = ({ parentForm, initialValues, fieldKeys, onEdit }) => {
  const [isCheckOn, setIsCheckOn] = useState(!!initialValues);
  const [locationPath, setLocationPath] = useState([]);
  const [dataSource, setDataSource] = useState([]);
  const [inputs, setInputs] = useState([]);
  const [getMore, setGetMore] = useState(false);
  const [optionsPath, setOptionsPath] = useState('');
  const [options, setOptions] = useState({
    properties: [],
    dataSources: [],
  });

  useEffect(() => {
    const optionsData = getCheckOptions(optionsPath);
    if (optionsData) setOptions(optionsData);
  }, [optionsPath]);

  const subFieldName = AutograderLevel.CHECK;
  const otherProps = OTHER_PROPS;

  const { name: fName, location: fLoc, property: fProp, operator: fOp, value: fVal, fieldKey } = initialValues || {
    name: '',
    location: [],
    property: '',
    operator: '',
    value: '',
    fieldKey: '',
  };

  const fLocPath = [];
  const fDS = [];
  fLoc.forEach(loc => {
    fLocPath.push(loc.within);
    fDS.push(loc.dataSource.type);
  });

  const handleLocationChange = (value, selectedOptions) => {
    setLocationPath(selectedOptions);
    setOptionsPath(value.length ? `googlesheet.workbook.${value.join('.')}` : '');
  };
  const handleDataSourceChange = value => {
    const dataElems = [];
    const finalDataSource = value[value.length - 1];
    const paths = [...locationPath];
    const updatedDS = [...dataSource];
    // don't include the last path
    let namesCounter = paths.length - 1;

    paths.reverse().forEach((path, index) => {
      // index 0 is the final path in the dataSource
      if (index === 0) {
        let label = '';
        let fieldName = '';

        updatedDS.unshift(finalDataSource);

        if (finalDataSource === 'cellAddress') {
          label = 'Cell Address';
          fieldName = 'cellAddress';
        } else if (finalDataSource === 'cellRange') {
          label = 'Cell Range';
          fieldName = 'cellRange';
        } else if (finalDataSource === 'byName') {
          label = `${path.label} name`;
          fieldName = 'byName';
        } else if (finalDataSource === 'byTitle') {
          label = `${path.label} title`;
          fieldName = 'byTitle';
        } else if (finalDataSource === 'byOrder') {
          label = `${path.label} order`;
          fieldName = 'byOrder';
        } else {
          return;
        }

        dataElems.unshift(
          <Form.Item key={index} label={label} name={[subFieldName, fieldName]}>
            <Input />
          </Form.Item>,
        );
      } else {
        dataElems.unshift(
          <Form.Item key={index} label={`${path.label} name`} name={[subFieldName, 'names', (namesCounter -= 1)]}>
            <Input />
          </Form.Item>,
        );

        // update data sources - assuming named ds for intermediary paths
        updatedDS.unshift('name');
      }
    });

    setInputs(dataElems);
    setDataSource(updatedDS);
  };

  const clearInputs = () => setInputs([]);
  const goDeeper = () => setGetMore(true);

  const { properties: propertyOptions, dataSources: dsOptions } = options;

  return (
    <div className="check-part-container">
      {isCheckOn && (
        <Form.Item required={false}>
          <div className="check-main">
            <div className="check-name">
              <FormattedMessage id="autograder.form.checkName" />
            </div>
            <Form.Item name={[subFieldName, 'name']} initialValue={fName}>
              <Input />
            </Form.Item>
            <div className="check-top-section">
              <div className="check-location-section">
                <div className="check-location-part">
                  <div className="check-location-name">
                    <FormattedMessage id="autograder.form.checkLookIn" />
                  </div>
                  <div className="check-location-path">
                    <div className="check-top-location">
                      <FormattedMessage id="autograder.form.checkWorkbook" />
                    </div>
                    <div className="check-match-location">
                      <div
                        role="button"
                        tabIndex={0}
                        className="check-match-location-specific"
                        onClick={goDeeper}
                        onKeyPress={goDeeper}>
                        <FormattedMessage id="autograder.form.checkGetMoreSpecific" />
                      </div>
                      {(getMore || initialValues) && (
                        <>
                          <Form.Item initialValue={fLocPath} name={[subFieldName, 'location']}>
                            <Cascader
                              allowClear
                              changeOnSelect
                              expandTrigger="hover"
                              placeholder="Location"
                              options={locationPaths}
                              value={dataSource}
                              onChange={handleLocationChange}
                            />
                          </Form.Item>
                          <Form.Item initialValue={fDS} name={[subFieldName, 'dataSource']}>
                            <Cascader
                              allowClear
                              placeholder="Data Source"
                              options={dsOptions}
                              onChange={handleDataSourceChange}
                            />
                          </Form.Item>
                        </>
                      )}
                      {inputs}
                      {getMore &&
                        locationPath.map((loc, index) => (
                          <React.Fragment key={index}>
                            <div className="check-match-location-where">{`- ${loc.label}`}</div>
                            <div className="check-match-location-data-source">{dataSource[index]}</div>
                          </React.Fragment>
                        ))}
                    </div>
                  </div>
                </div>
              </div>
              <Form.Item className="check-expression-section">
                <Form.Item
                  name={[subFieldName, 'property']}
                  initialValue={fProp}
                  label={intl.formatMessage({ id: 'autograder.form.checkSeeIf' })}>
                  <CustomSelect options={propertyOptions} />
                </Form.Item>
                <Form.Item
                  name={[subFieldName, 'operator']}
                  initialValue={fOp}
                  label={intl.formatMessage({ id: 'autograder.form.checkOperator' })}>
                  <CustomSelect options={operators} />
                </Form.Item>
                <Form.Item
                  name={[subFieldName, 'value']}
                  initialValue={fVal}
                  label={intl.formatMessage({ id: 'autograder.form.checkValue' })}>
                  <Input />
                </Form.Item>
                <Form.Item name={[otherProps, 'prevValues']}>
                  <Form.Item name={[subFieldName, 'fieldKey']}>
                    <Form.Item className="check-footer">
                      <Button
                        type="primary"
                        className="check-submit"
                        onClick={() => {
                          setIsCheckOn(false);
                          setGetMore(false);
                          if (onEdit) onEdit(fieldKeys);
                          clearInputs();

                          parentForm.setFields([
                            {
                              name: [otherProps, 'prevValues'],
                              value: initialValues,
                            },
                            {
                              name: [subFieldName, 'fieldKey'],
                              value: initialValues ? fieldKey : uuidv4(),
                            },
                          ]);
                          parentForm.submit();
                        }}>
                        {initialValues ? (
                          <FormattedMessage id="autograder.form.updateCheck" />
                        ) : (
                          <FormattedMessage id="autograder.form.saveCheck" />
                        )}
                      </Button>
                      <Form.Item name={[otherProps, 'isCanceled']} noStyle>
                        <Button
                          danger
                          className="cancel-check-field-btn"
                          onClick={() => {
                            setIsCheckOn(false);
                            setGetMore(false);
                            clearInputs();

                            parentForm.setFields([
                              {
                                name: [otherProps, 'isCanceled'],
                                value: true,
                              },
                            ]);
                            parentForm.submit();
                          }}>
                          <MinusCircleOutlined
                            style={{
                              fontSize: '16px',
                              verticalAlign: 'initial',
                            }}
                          />
                          <FormattedMessage id="common.cancel" />
                        </Button>
                      </Form.Item>
                    </Form.Item>
                  </Form.Item>
                </Form.Item>
              </Form.Item>
            </div>
          </div>
        </Form.Item>
      )}
      {!initialValues && (
        <Form.Item>
          <Button type="default" className="add-check-field-btn" onClick={() => setIsCheckOn(true)}>
            <PlusCircleOutlined style={{ fontSize: '20px' }} />
            <FormattedMessage id="autograder.form.addCheck" />
          </Button>
        </Form.Item>
      )}
    </div>
  );
};

CheckBuilder.propTypes = {
  parentForm: PropTypes.any,
  initialValues: PropTypes.any,
  fieldKeys: PropTypes.any,
  onEdit: PropTypes.func,
};

export default CheckBuilder;
