import { isEmpty as _isEmpty, map as _map } from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  FlexibleXYPlot,
  XAxis,
  YAxis,
  HorizontalGridLines,
  LineSeries,
  VerticalBarSeries as BarSeries,
  DiscreteColorLegend,
  Crosshair,
} from 'react-vis';
import { FormattedMessage } from 'react-intl';
import CohortReport from '../../api/cohortReport';
import Loading from '../Utils/Loading';
import intl from '../../utils/intl';

export default class CohortAssignments extends Component {
  constructor(props) {
    super(props);

    this.state = {
      crosshairValues: [],
    };
  }

  componentDidMount() {
    this.fetchData(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.cohortId !== nextProps.cohortId) {
      this.fetchData(nextProps);
    }
  }

  fetchData = props => {
    const { cohortId } = props;

    this.setState({ isLoading: true });
    CohortReport.assignments(cohortId).then(response => {
      this.setState({ rows: response.rows, isLoading: false });
    });
  };

  renderScores = () => {
    const { rows } = this.state;
    if (_isEmpty(rows)) return '';

    const data = _map(rows, row => ({ x: row.assignment_title, y: row.avg_assignment_score || 0 }));

    return <BarSeries onNearestX={this._onNearestX} barWidth={0.6} data={data} color="#90AADB" />;
  };

  renderCompleted = () => {
    const { rows } = this.state;
    if (_isEmpty(rows)) return '';

    const data = _map(rows, row => ({ x: row.assignment_title, y: row.assignments_completed_percent || 0 }));

    return <LineSeries data={data} color="#EF8644" />;
  };

  /**
   * Event handler for onMouseLeave.
   * @private
   */
  _onMouseLeave = () => {
    this.setState({ crosshairValues: [] });
  };

  /**
   * Event handler for onNearestX.
   * @param {Object} value Selected value.
   * @param {index} index Index of the value in the data array.
   * @private
   */
  _onNearestX = (value, { index }) => {
    const { rows } = this.state;
    const row = rows[index];

    const crosshairValues = [
      { x: row.assignment_title, y: row.avg_assignment_score || 0 },
      { x: row.assignment_title, y: row.assignments_completed_percent || 0 },
    ];
    this.setState({ crosshairValues });
  };

  _formatCrosshair = values =>
    values.map((v, i) => {
      const title =
        i === 0
          ? intl.formatMessage({ id: 'cohortReports.assignments.avgAssignmentScore' })
          : intl.formatMessage({ id: 'cohortReports.assignments.percentStudentsSubmitted' });

      return {
        title,
        value: v.y,
      };
    });

  /**
   * Format the title line of the crosshair.
   * @param {Array} values Array of values.
   * @returns {Object} The caption and the value of the title.
   * @private
   */
  _formatCrosshairTitle = values => ({
    title: values[0].x,
    value: null,
  });

  render() {
    const { isLoading, rows } = this.state;

    if (isLoading) {
      return (
        <div className="grid-row">
          <div className="sm-12">
            <Loading />
          </div>
        </div>
      );
    }

    if (_isEmpty(rows)) {
      return <h2 className="info-msg">No assessments found</h2>;
    }

    return (
      <div className="grid-row" style={{ pageBreakBefore: 'always' }}>
        <div className="md-12">
          <h2 style={{ marginTop: 30, textAlign: 'center' }}>
            <FormattedMessage id="cohortReports.assignments.title" />
          </h2>

          <FlexibleXYPlot
            height={500}
            xType="ordinal"
            margin={{ bottom: 200, top: 50, left: 150, right: 50 }}
            yDomain={[0, 100]}
            onMouseLeave={this._onMouseLeave}>
            <HorizontalGridLines />
            {this.renderScores()}
            {this.renderCompleted()}
            <XAxis tickLabelAngle={-45} />
            <YAxis />
            <DiscreteColorLegend
              style={{ position: 'absolute', left: '200px', top: '10px' }}
              orientation="horizontal"
              items={[
                {
                  title: intl.formatMessage({ id: 'cohortReports.assignments.avgAssignmentScore' }),
                  color: '#90AADB',
                },
                {
                  title: intl.formatMessage({ id: 'cohortReports.assignments.percentStudentsSubmitted' }),
                  color: '#EF8644',
                },
              ]}
            />
            <Crosshair
              values={this.state.crosshairValues}
              itemsFormat={this._formatCrosshair}
              titleFormat={this._formatCrosshairTitle}
            />
          </FlexibleXYPlot>
        </div>
      </div>
    );
  }
}
CohortAssignments.propTypes = {
  cohortId: PropTypes.string.isRequired,
};
