import React from 'react';
import { getInitialState, autoGraderReducer } from './reducers';

const AutograderStateContext = React.createContext();
const AutograderDispatchContext = React.createContext();

export const AutograderProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(autoGraderReducer, getInitialState());

  return (
    <AutograderStateContext.Provider value={state}>
      <AutograderDispatchContext.Provider value={dispatch}>{children}</AutograderDispatchContext.Provider>
    </AutograderStateContext.Provider>
  );
};

export const useAutograderState = () => {
  const stateContext = React.useContext(AutograderStateContext);
  if (stateContext === undefined) {
    throw new Error('useAutograderState must be called from inside an AutograderProvider');
  }
  return stateContext;
};

export const useAutograderSelector = select => {
  const state = useAutograderState();
  return select(state);
};

export const useAutograderDispatch = () => {
  const dispatchContext = React.useContext(AutograderDispatchContext);
  if (dispatchContext === undefined) {
    throw new Error('useAutograderDispatch must be called from inside an AutograderProvider');
  }
  return dispatchContext;
};
