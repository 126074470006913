import classnames from 'classnames';
import Tutorial from '../api/tutorial';
import intl from '../utils/intl';

export const getFolderPath = ({ preview, bootcamp, cohort, user }) => {
  if (!user) return '';
  const domain = window.location.hostname;
  const paths = [];
  if (preview) {
    paths.push('Author Previews');
    paths.push(domain);
  } else {
    paths.push(domain);
    paths.push(bootcamp.title);
    if (cohort) {
      paths.push(`${cohort.start_date} - ${cohort.end_date} - ${cohort.location}`);
    }
    paths.push(user.email);
  }

  return paths.join('/');
};

export const showStream = (app, projectUrl) => projectUrl || app === Tutorial.CUSTOM_SITE || app === Tutorial.VIDEO;

export const getDefaultLaunchIn = () => {
  return Tutorial.LAUNCH_IN_BROWSER;
};

export const openLab = async ({ launchIn, labUrl, history }) => {
  switch (launchIn) {
    // eslint-disable-next-line no-fallthrough
    case Tutorial.LAUNCH_IN_BROWSER:
    case Tutorial.LAUNCH_IN_DESKTOP:
      return openLabInBrowser(labUrl, history);
    case Tutorial.LAUNCH_IN_NEW_TAB:
      window.open(`${labUrl}?disable_navigation=1`);
      return { externalApp: false };
  }
};

const openLabInBrowser = (labUrl, history) => {
  history.push(labUrl);
  return {
    externalApp: false,
  };
};

export const getLabBlockTypeOptions = () =>
  ['overview', 'objective', 'takeaways'].map(type => ({
    value: type,
    label: intl.formatMessage({ id: `tutorial.blockTypes.${type}` }),
  }));

export const getBlockClasses = (block, defaultClass) =>
  classnames(
    block.lab_block_type === 'overview' && 'lab-sidebar-body lab-sidebar-body-unit',
    block.lab_block_type === 'objective' && 'lab-sidebar-body---accent',
    block.lab_block_type === 'takeaways' && 'lab-sidebar-body',
    !block.lab_block_type && (defaultClass || 'lab-sidebar-body'),
  );

export const isGsuiteApp = app => Tutorial.GSUITE === app;

export const isCodioApp = app => Tutorial.CODIO === app;

export const isMysqlApp = app => Tutorial.MYSQL === app;

export const isTableauApp = app => Tutorial.TABLEAU === app;

export const isTrailheadApp = app => Tutorial.TRAILHEAD === app;

export const isCustomSiteApp = app => Tutorial.CUSTOM_SITE === app;

export const isGoogleColabApp = app => Tutorial.GOOGLE_COLAB === app;

export const isVideoApp = app => Tutorial.VIDEO === app;

export const isEmbedApp = app => Tutorial.EMBED === app;

export const isToughTalksApp = app => Tutorial.TOUGH_TALKS === app;

export const usesProjectUrl = app => isTrailheadApp(app) || isEmbedApp(app);

export const usesUserProjectUrl = app => isGsuiteApp(app) || isToughTalksApp(app);

export const allowProjectUpload = app => isMysqlApp(app) || isTableauApp(app) || isToughTalksApp(app);

export const usesRemoteVM = app => isMysqlApp(app) || isTableauApp(app);

export const isReviewable = app => isGsuiteApp(app) || isVideoApp(app) || isToughTalksApp(app);
