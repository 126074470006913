import {
  isEmpty as _isEmpty,
  take as _take,
  mergeWith as _mergeWith,
  isArray as _isArray,
  isObject as _isObject,
} from 'lodash';
import moment from 'moment';

import Assignment from '../api/assignment';
import intl from './intl';

export const getProgressStatus = entity => {
  const status = {
    isGraded: false,
    isComplete: false,
    inProgress: false,
    notStarted: true,
  };
  if (entity) {
    status.isComplete = entity.progress >= 1 || isSubmitted(entity);
    status.isGraded = status.isComplete && isGraded(entity);
    status.inProgress = !status.isComplete && (entity.progress > 0 || !_isEmpty(entity.started_at));
    status.notStarted = !status.isComplete && !status.inProgress;
  }
  return status;
};

export const isSubmitted = progress => {
  return !_isEmpty(progress?.completed_at);
};

export const isGraded = progress => {
  return !_isEmpty(progress?.graded_at);
};

export const isPending = assignmentProgress => {
  return assignmentProgress?.submission_status === 'pending';
};

export const getFileSubmissionStatus = assignmentProgress => {
  if (!assignmentProgress) {
    return { submissionPending: false, submissionFailed: false };
  }

  return {
    submissionPending: isPending(assignmentProgress),
    submissionFailed:
      assignmentProgress.submission_status === 'failed' || assignmentProgress.submission_status === 'timedout',
  };
};

export const getStatusClass = (item, itemProgress, currentItemId, isStudent) => {
  if (!isStudent) {
    if (currentItemId === item.id) {
      return 'in-progress';
    }

    return 'to-do';
  }

  const roundedProgress = itemProgress ? Math.round(itemProgress.progress * 100) : 0;
  const hasCompleted = itemProgress && itemProgress.completed_at;

  if (currentItemId === item.id) {
    return hasCompleted || roundedProgress >= 100 ? 'completed in-progress' : 'in-progress';
  }
  if (hasCompleted || roundedProgress >= 100) {
    return 'completed';
  }

  // if (roundedProgress > 0) {
  //   return 'in-progress';
  // }

  return 'to-do';
};

export const formatDuration = (duration, screenReader = false) => {
  const hours = Math.floor(duration / 60);
  const minutes = Math.floor(duration % 60);

  let displayStr = '';
  if (hours > 0) {
    displayStr += screenReader
      ? intl.formatMessage({ id: 'common.time.hours' }, { hours })
      : intl.formatMessage({ id: 'common.time.h' }, { hours });
  }

  if (minutes > 0) {
    displayStr += screenReader
      ? intl.formatMessage({ id: 'common.time.minutes' }, { minutes })
      : intl.formatMessage({ id: 'common.time.m' }, { minutes });
  }

  return displayStr;
};

export const stripHtml = (html, numWords) => {
  const doc = new DOMParser().parseFromString(html, 'text/html');
  const content = doc.body.textContent || '';
  const words = content.split(' ');
  const result = {
    content,
    totalWords: words.length,
  };

  if (numWords > 0) {
    result.content = _take(words, numWords).join(' ');
  }
  return result;
};

export const wait = ms => new Promise(resolve => setTimeout(resolve, ms));

export const formatDate = date => {
  if (!date) {
    return '';
  }
  return moment(date).format('MMM D YYYY');
};

export const trimEndTag = (value, tag) => {
  const regEx = new RegExp(`</?${tag}>`, 'g');
  return value.replace(regEx, '');
};

export const sortAscendNums = nums =>
  nums.sort((a, b) => {
    if (a < b) {
      return -1;
    }
    if (a > b) {
      return 1;
    }
    return 0;
  });

export const getRatio = (score, total) => (total ? Math.round((score / total) * 100) : null);

export const getRatioPercent = (score, total) => (total ? `${getRatio(score, total)}%` : null);

export const getNumeratorFromPercent = (percent, total) => Math.round((percent * total) / 100);

export const getGradeLevel = score => {
  if (score === 10) return Assignment.PROFICIENCY_LEVEL_1;
  if (score === 8 || score === 9) return Assignment.PROFICIENCY_LEVEL_2;
  if (score === 7) return Assignment.PROFICIENCY_LEVEL_3;
  if (score < 7 && score >= 0) return Assignment.PROFICIENCY_LEVEL_4;
  return null;
};

export const getProficiencyLevel = (rubric, criterionId, score) => {
  const gradeFound = rubric.find(r => r.id === criterionId);
  const proficiencyLevel = gradeFound && getGradeLevel(score);
  return (
    proficiencyLevel && {
      proficiencyLevel,
      proficiencyLevelDescription: gradeFound[proficiencyLevel],
    }
  );
};

export const supportedFile = url => {
  // const supportedFiles = ['jpg', 'jpeg', 'png', 'svg', 'tif', 'gif', 'mp4', 'avi', 'mov', 'qt', 'pdf'];
  // TODO: we may want to add image previews to the formats that are not currently preview-able
  const supportedFiles = ['jpg', 'jpeg', 'png', 'gif', 'pdf'];
  const re = new RegExp(`\\.(${supportedFiles.join('|')})$`, 'i');
  const result = re.exec(url);
  return {
    canPreviewFile: !!result,
    fileType: result && result[1],
  };
};

export const proficiencyLevelToGrades = level => {
  switch (level) {
    case Assignment.PROFICIENCY_LEVEL_1:
      return [10];
    case Assignment.PROFICIENCY_LEVEL_2:
      return [8, 9];
    case Assignment.PROFICIENCY_LEVEL_3:
      return [7];
    case Assignment.PROFICIENCY_LEVEL_4:
      return [0, 1, 2, 3, 4, 5, 6];
    default:
      return [];
  }
};

const mergeEntitiesCustomizer = (originalValue, newValue) => {
  if (_isArray(originalValue)) {
    return newValue;
  }

  if (_isObject(originalValue) && _isObject(newValue)) {
    // don't replace if updated_at is older
    if (originalValue.updated_at && newValue.updated_at) {
      if (originalValue.updated_at > newValue.updated_at) {
        return originalValue;
      }
    }
  }
};

export const mergeEntities = (originalEntities, newEntities) => {
  return _mergeWith(originalEntities, newEntities, mergeEntitiesCustomizer);
};

export const getWeekFromDate = (dueAt, startDate) => {
  const dueDate = moment(dueAt);

  const weekDiff = dueDate.diff(startDate, 'weeks') + 1;

  return weekDiff;
};

export const isMobileDevice = () => {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
};
