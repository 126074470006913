import '@babel/polyfill';
import './polyfills';
import 'hacktimer'; // use hacktimer so that setTimeout, setInterval works reliably when the browser tab is not active
import 'react-toastify/dist/ReactToastify.css';
import 'react-select/dist/react-select.css';
import 'react-table/react-table.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-vis/dist/style.css';
import './styles';
import './styles/v2';
import './styles/lessonsRedesign';

import 'whatwg-fetch';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Router, Route } from 'react-router-dom';
import { IntlProvider } from 'react-intl';
import moment from 'moment';
import AppContainer from './components/AppContainer';

import history from './history';
import locales from './locales';
import { initialize as initializeUserMonitoring } from './utils/userMonitoring';
import store from './store';
import './helpers/sentryHelper';
import './sockets';

initializeUserMonitoring();

// set the locale for moment
moment.locale(locales.locale);

if (process.env.NODE_ENV === 'production') {
  if (window.location.protocol !== 'https:') {
    window.location.href = window.location.href.replace('http:', 'https:');
  }
}

const MOUNT_NODE = document.getElementById('root');

const render = loc => {
  ReactDOM.render(
    <IntlProvider locale={loc.locale} messages={loc.messages}>
      <Provider store={store}>
        <Router history={history}>
          <Route path="/" component={AppContainer} />
        </Router>
      </Provider>
    </IntlProvider>,
    MOUNT_NODE,
  );
};

if (module.hot) {
  // Hot reloadable React components and translation json files
  // modules.hot.accept does not accept dynamic dependencies,
  // have to be constants at compile-time
  module.hot.accept(['./'], () => {
    ReactDOM.unmountComponentAtNode(MOUNT_NODE);
    render(locales);
  });
}

// Chunked polyfill for browsers without Intl support
if (!window.Intl) {
  new Promise(resolve => {
    resolve(import('intl'));
  })
    .then(() => Promise.all([import('intl/locale-data/jsonp/en.js')]))
    .then(() => render(locales))
    .catch(err => {
      throw err;
    });
} else {
  render(locales);
}
