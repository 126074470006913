// eslint-disable-next-line no-unused-vars
import { getLocationPath } from './dataSourceHelpers';
import { evalCellFormat, sheetNotFound } from './evalHelpers';

const getCellDataSourcePath = ({ type }) => {
  switch (type) {
    case 'byAny':
      return path => Array.prototype.some.bind(path);
    case 'byAll':
      return path => Array.prototype.every.bind(path);
    default:
      return () => null;
  }
};

// assuming we're checking only in the first table/dataset
const evalColumnsCell = ({ data, location, property, operator, value }) => {
  const sheet = getLocationPath(data, location);
  if (!sheet) return sheetNotFound(property, operator, value);
  const { type } = location[location.length - 1].dataSource;
  const { name: columnName } = location[location.length - 2].dataSource;
  const columnData = sheet.dataset[0];
  const { columnHeaders, rows } = columnData;

  const colHeadIndex = columnHeaders.findIndex(header => header === columnName);

  if (colHeadIndex === -1) return null;

  const cellChecker = getCellDataSourcePath({ type });

  return cellChecker(rows)(row => {
    const cell = row[colHeadIndex];
    const result = evalCellFormat({ cell, property, operator, value });
    return result.status;
  });
};

export default evalColumnsCell;
