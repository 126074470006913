import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Button } from 'antd';
import { Link } from 'react-scroll';

import intl from '../../../utils/intl';
import Icons from '../../Utils/Icons';
import { getProgressStatus } from '../../../utils/utils';
import { showAsanaAccountCreatedModal } from '../../Users/utils/showSetupModal';
import { canSetupAsanaAddress } from '../../../helpers/userAuth';
import useCurrentUser from '../../../hooks/useCurrentUser';

export default function PathTableOfContents({ sections, sectionProgresses, showAsanaButton }) {
  const [isOpen, setIsOpen] = useState(false);
  const { currentUser } = useCurrentUser();

  const toggleOpen = () => setIsOpen(previousIsOpen => !previousIsOpen);

  const isSectionComplete = section => {
    if (!sectionProgresses) return false;

    const progress = sectionProgresses.find(sp => sp.section_id === section.id);
    if (!progress) return false;

    return getProgressStatus(progress).isComplete;
  };

  const classes = classnames(
    'sub-nav md-3 md-offset-1 sub-nav---course-overview',
    isOpen && 'sub-nav--mobile-opened',
    'sub-nav---course-overview-after-scroll',
  );
  const offset = -58;

  return (
    <>
      <button
        className="table-of-contents-button"
        aria-label={intl.formatMessage({ id: isOpen ? 'common.close' : 'bootcamp.sections' })}
        onClick={toggleOpen}>
        {isOpen ? <Icons.Close /> : <Icons.Contents />}
      </button>

      <div className={classes}>
        {showAsanaButton && canSetupAsanaAddress(currentUser) && (
          <Button
            type="primary"
            className="asana-account-button"
            size="large"
            shape="round"
            onClick={showAsanaAccountCreatedModal}>
            {intl.formatMessage({ id: 'profile.asanaAccount.buttonTitle' })}
          </Button>
        )}
        <div className="sub-nav__panel-wrapper">
          <div className="panel">
            <div className="panel_items" data-testid="path-sections">
              {(sections || []).map(section => (
                <Link
                  key={section.id}
                  className={classnames(
                    'panel-element panel-element-course-overview-page panel-element---future',
                    isSectionComplete(section) && 'panel-element---finished',
                  )}
                  to={`section_${section.id}`}
                  isDynamic
                  smooth
                  offset={offset}
                  spy
                  activeClass="panel-element---active"
                  onClick={() => setIsOpen(false)}>
                  <span className="panel-element__label">{section.title}</span>
                </Link>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

PathTableOfContents.propTypes = {
  sections: PropTypes.array,
  sectionProgresses: PropTypes.array,
  showAsanaButton: PropTypes.bool,
};
