import { difference as _difference, isEmpty as _isEmpty } from 'lodash';
import React, { Component } from 'react';
import EnrollModalContainer from '../../Admin/EnrollModalContainer';
import intl from '../../../utils/intl';
import { toastSuccess } from '../../../utils/toastHelper';
import { FormattedMessage } from 'react-intl';

export default class EnrollStudent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      userIds: props.userIds,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (_isEmpty(this.state.userIds) && this.state.userIds !== nextProps.userIds) {
      this.setState({ userIds: nextProps.userIds });
    }
  }

  componentDidMount() {
    this.props.fetchEnrollments(this.props.cohort?.id);
  }

  handleEnroll = async user => {
    const { cohort, enrollments, saveEnrollment, destroyEnrollment, mode } = this.props;
    let userIds = [...this.state.userIds];

    if (mode === 'enroll') {
      await saveEnrollment(cohort, { user_id: user.id, user_email: user.email });
      toastSuccess(intl.formatMessage({ id: 'enrollments.messages.added' }));
      userIds.push(user.id);
    } else {
      const enrollment = enrollments.find(e => e.user_id === user.id);
      await destroyEnrollment(cohort, enrollment);
      toastSuccess(intl.formatMessage({ id: 'enrollments.messages.removed' }));
      userIds = _difference(this.state.userIds, [user.id]);
    }

    this.setState({ userIds });
  };

  render() {
    const { mode } = this.props;
    const { userIds } = this.state;
    const headerTitle =
      mode === 'enroll' ? (
        <FormattedMessage id="cohort.enrollStudents" />
      ) : (
        <FormattedMessage id="cohort.unenrollStudents" />
      );

    return (
      <EnrollModalContainer
        userRole="Student"
        onEnroll={this.handleEnroll}
        userIds={userIds}
        mode={mode}
        headerTitle={headerTitle}
        {...this.props}
      />
    );
  }
}
