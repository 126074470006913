import { map as _map } from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { selectIsEditMode } from '../../selectors/contextSelectors';
import { reorderBlocks } from '../../actions/blockActions';
import AuthorBlockContainer from '../Author/BlockContainer';
import StudentBlockContainer from '../Student/BlockShowContainer';
import AddBlock from '../Author/Blocks/AddBlock';
import useCurrentUser from '../../hooks/useCurrentUser';
import useCurrentEnrollment from '../../hooks/useCurrentEnrollment';
import Block from '../../api/block';

const getBlockClass = block => {
  switch (block.block_type) {
    case Block.GRADED_QUIZ:
      return 'lesson-part__graded';

    default:
      return 'lesson-part__contentBlock';
  }
};

export default function ShowLesson({ lesson, part, blocks }) {
  const { bootcampId } = useParams();
  const isEditMode = useSelector(selectIsEditMode);
  const dispatch = useDispatch();
  const { canAuthor } = useCurrentUser();
  const { enrollment } = useCurrentEnrollment();

  const handleReorder = (oldPosition, newPosition) => {
    const blockIds = _map(blocks, 'id');
    const removed = blockIds.splice(oldPosition, 1);
    blockIds.splice(newPosition, 0, removed[0]);

    dispatch(reorderBlocks(blockIds, lesson, part));
  };

  const BlockContainer = canAuthor ? AuthorBlockContainer : StudentBlockContainer;

  return (
    <article className="lesson-part lesson-part--active">
      <div className="lesson-part__wrapper">
        <div className="lesson-part__container buttons-lessons">
          <div className="lesson-part__content">
            {isEditMode && <AddBlock lesson={lesson} part={part} prevSeqNum={0} isGradedQuiz />}

            {blocks.map((block, index) => (
              <div key={block.id} className={getBlockClass(block)}>
                <BlockContainer
                  index={index}
                  block={block}
                  bootcampId={bootcampId}
                  lesson={lesson}
                  isEditMode={isEditMode}
                  totalBlocks={blocks.length}
                  onReorder={handleReorder}
                  enrollmentId={enrollment && enrollment.id}
                />
                {isEditMode && <AddBlock lesson={lesson} part={part} prevSeqNum={block.seq_num} isGradedQuiz />}
              </div>
            ))}
          </div>

          <footer className="lesson-part__footer"></footer>
        </div>
      </div>
    </article>
  );
}

ShowLesson.propTypes = {
  lesson: PropTypes.object.isRequired,
  part: PropTypes.object.isRequired,
  blocks: PropTypes.array.isRequired,
};
