import { connect } from 'react-redux';
import { selectUserFromComment } from '../../../selectors/userSelectors';
import { selectCurrentUser } from '../../../selectors/authSelectors';
import Comment from './Comment';
import { updateComment, destroyComment } from '../../../actions/commentActions';
import { canCoach } from '../../../helpers/userAuth';

const mapStateToProps = (state, ownProps) => {
  const currentUser = selectCurrentUser(state);
  const isCurrentUserTheAuthor = ownProps.comment.author_id === currentUser.id;

  return {
    author: selectUserFromComment(state, ownProps.comment),
    canEdit: isCurrentUserTheAuthor,
    canDestroy: isCurrentUserTheAuthor || canCoach(currentUser),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onEdit: content => dispatch(updateComment({ id: ownProps.comment.id, content })),
    onDestroy: () => dispatch(destroyComment(ownProps.comment.id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Comment);
