import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import CohortItem from './CohortItem';

const Header = ({ title, cohortCount }) => (
  <div className="cohort-group-header">
    <span className="cohort-group-name">{title}</span>
    <span className="cohort-group-cohort-count">
      <FormattedMessage id="cohort.nCohorts" values={{ count: cohortCount }} />
    </span>
  </div>
);

Header.propTypes = {
  title: PropTypes.string.isRequired,
  cohortCount: PropTypes.number.isRequired,
};

const CohortGroup = ({ title, cohorts }) => (
  <div className="cohort-group" data-testid="cohort-group">
    <Header title={title} cohortCount={cohorts.length} />

    {cohorts.map(cohort => (
      <CohortItem key={cohort.id} cohort={cohort} />
    ))}
  </div>
);

CohortGroup.propTypes = {
  title: PropTypes.string.isRequired,
  cohorts: PropTypes.array.isRequired,
};

export default CohortGroup;
