import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import intl from '../../../utils/intl';

const notes = [];
for (let num = 1; num <= 32; num++) {
  notes.push(intl.formatMessage({ id: `tutorial.loadingModal.loadingNote${num}` }));
}

export default function LabLoadingNotes() {
  const [noteFadingOut, setNoteFadingOut] = useState(false);
  const notesIndex = useRef(0);
  const notesTimer = useRef();
  const fadingTimer = useRef();

  useEffect(() => {
    scheduleNextNote();

    return () => {
      clearTimeout(notesTimer.current);
      clearTimeout(fadingTimer.current);
    };
  }, []);

  useEffect(() => {
    if (noteFadingOut) scheduleNoteFadeIn();
  }, [noteFadingOut]);

  const scheduleNextNote = () => {
    notesTimer.current = setTimeout(noteFadeOut, 3000);
  };

  const scheduleNoteFadeIn = () => {
    fadingTimer.current = setTimeout(() => {
      setNextNote();
      noteFadeIn();
      scheduleNextNote();
    }, 500);
  };

  const setNextNote = () => {
    if (notesIndex.current + 1 < notes.length) notesIndex.current = notesIndex.current + 1;
  };

  const noteFadeOut = () => {
    setNoteFadingOut(true);
  };

  const noteFadeIn = () => {
    setNoteFadingOut(false);
  };

  const note = notes[notesIndex.current];
  const noteClass = `loading-notes animated ${noteFadingOut ? 'fadeOutUp' : 'fadeInUp'}`;

  return (
    <div className={noteClass} data-testid="loading-notes">
      {note}
    </div>
  );
}
