import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CommentContainer from './CommentContainer';

export default class Comments extends Component {
  static propTypes = {
    comments: PropTypes.array.isRequired,
  };

  render() {
    return (
      <React.Fragment>
        {this.props.comments.map(comment => (
          <CommentContainer key={comment.id} comment={comment} />
        ))}
      </React.Fragment>
    );
  }
}
