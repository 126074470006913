import { CheckCircleFilled } from '@ant-design/icons';
import { FormattedMessage } from 'react-intl';
import React from 'react';

const NoAssignmentToGrade = () => {
  return (
    <div className="submission-card" data-testid="empty-state">
      <div className="assignment-empty-state-container">
        <div className="check-icon">
          <CheckCircleFilled />
        </div>
        <div className="empty-state-text-container">
          <div className="empty-state-text-container__first-line">
            <FormattedMessage id="cohort.greatJobStayingTop" />
          </div>
          <div className="empty-state-text-container__second-line">
            <FormattedMessage id="cohort.noAssignmentsToGrade" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default NoAssignmentToGrade;
