import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const RecommendedGrade = ({ recommendedGrade, size = 'large', isModelAnswer }) => (
  <div
    className={classnames('ruleset-criterion-report-recommended-grade-container', size, {
      'model-answer': isModelAnswer,
    })}
    data-testid="recommended-grade">
    <div className="ruleset-criterion-report-recommended-grade-header-container">
      <div className="ruleset-criterion-report-recommended-grade-header">
        <FormattedMessage id="autograder.grading.gradeTitle" />
      </div>
      <div className="ruleset-criterion-report-recommended-grade-title">
        <FormattedMessage id={`autograder.grading.${isModelAnswer ? 'modelAnswer' : 'recommendedGrade'}`} />
      </div>
    </div>
    <div className="ruleset-criterion-report-recommended-grade-grade">{recommendedGrade}</div>
  </div>
);

RecommendedGrade.propTypes = {
  recommendedGrade: PropTypes.string.isRequired,
  size: PropTypes.oneOf(['large', 'small']),
  isModelAnswer: PropTypes.bool,
};

export default RecommendedGrade;
