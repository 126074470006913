import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { fetchCurrentEnrollments } from '../actions/enrollmentActions';
import useCurrentEnrollment from './useCurrentEnrollment';
import useCurrentUser from './useCurrentUser';

/**
 * Fetch the enrollments if not already present in redux.
 * Make sure to call this only once in a page preferably in the top level component
 */
export default function useFetchCurrentEnrollment() {
  const { isStudent } = useCurrentUser();
  const { enrollment, cohort } = useCurrentEnrollment();
  const dispatch = useDispatch();
  const [isFetching, setIsFetching] = useState(false);

  useEffect(() => {
    if (isStudent && !enrollment && !isFetching) {
      setIsFetching(true);
      dispatch(fetchCurrentEnrollments());
    }
  }, [isStudent]);

  return { enrollment, cohort };
}
