import React, { useState, useEffect } from 'react';
import { findIndex as _findIndex } from 'lodash';
import PropTypes from 'prop-types';

const TutorialContext = React.createContext([{}, () => {}]);

const getStepIndex = (prevSteps, newSteps, currentStepIndex, tutorialProgress) => {
  // if there were no steps before, don't change
  if (!prevSteps.length) {
    if (tutorialProgress) {
      // start off at where the user left
      return _findIndex(newSteps, { id: tutorialProgress.current_step_id });
    }
    return currentStepIndex;
  }

  // step added
  if (prevSteps.length < newSteps.length) {
    return newSteps.length - 1;
  }
  // step deleted and was the last
  if (prevSteps.length > newSteps.length && currentStepIndex >= newSteps.length) {
    return currentStepIndex - 1;
  }

  if (tutorialProgress) {
    // start off at where the user left
    return _findIndex(newSteps, { id: tutorialProgress.current_step_id });
  }
  return currentStepIndex;
};

const TutorialDataProvider = ({
  children,
  component,
  bootcamp,
  lesson,
  tutorial,
  tutorialProgress,
  steps,
  isEditMode,
  lessonUrlObject,
  projectFolderPath,
}) => {
  const [state, setState] = useState({ steps: [], currentStepIndex: 0 });

  useEffect(() => {
    setState(prevState => {
      const currentStepIndex = Math.max(
        0,
        getStepIndex(state.steps, steps, prevState.currentStepIndex, tutorialProgress),
      );
      return {
        ...state,
        currentStepIndex,
        component,
        bootcamp,
        lesson,
        tutorial,
        tutorialProgress,
        steps,
        isEditMode,
        lessonUrlObject,
        projectFolderPath,
      };
    });
  }, [component, bootcamp, lesson, tutorial, tutorialProgress, steps, isEditMode, lessonUrlObject, projectFolderPath]);

  return <TutorialContext.Provider value={[state, setState]}>{children}</TutorialContext.Provider>;
};

TutorialDataProvider.propTypes = {
  children: PropTypes.node,
  component: PropTypes.object,
  tutorial: PropTypes.object,
  lesson: PropTypes.object,
  tutorialProgress: PropTypes.object,
  steps: PropTypes.array,
  isEditMode: PropTypes.bool,
  lessonUrlObject: PropTypes.object,
  projectFolderPath: PropTypes.string,
};

export { TutorialContext, TutorialDataProvider };
