import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { find as _find } from 'lodash';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { confirmInput } from '../../../utils/confirmInput';
import intl from '../../../utils/intl';
import Icons from '../../Utils/Icons';
import TooltipButtons from '../../Utils/Tooltip/TooltipButtons';
import showModal from '../../../utils/showModal';
import MoveBlockModalContainer from './SelectLesson/MoveBlockModalContainer';
import CopyBlockModalContainer from './SelectLesson/CopyBlockModalContainer';
import useHandleOutsideClick from '../../../hooks/useHandleOutsideClick';
import SelectPartModal from '../../Lesson/SelectPartModal';
import SelectStepModal from '../../Labs/SelectStepModal';
import useSaving from '../../../hooks/useSaving';
import { selectSteps } from '../../../selectors/stepSelectors';
import { selectLessonParts } from '../../../selectors/lessonSelectors';
import SelectAdaptiveLearningTracks from '../../Lesson/AdaptiveTrackModal';

const useStore = block => {
  const steps = useSelector(selectSteps);
  const step = _find(steps, { lesson_id: block.lesson_id });
  const parts = useSelector(s => selectLessonParts(s, block.lesson_id));

  return {
    step,
    parts,
  };
};

const BlockEditOptions = ({ bootcamp, block, index, total, onEdit, onReorder, onDelete }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isSaving, isSavingInProcess] = useSaving();

  const { step, parts } = useStore(block);

  const containerRef = useHandleOutsideClick(isOpen, () => {
    if (isOpen) toggleOpen();
  });

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  const handleClick = (e, callback) => {
    toggleOpen();
    e.stopPropagation();
    callback(e);
  };

  const handleMoveUp = () => {
    const newIndex = Math.max(0, index - 1);
    onReorder(index, newIndex);
  };

  const handleMoveDown = () => {
    const newIndex = Math.min(total - 1, index + 1);
    onReorder(index, newIndex);
  };

  const handleDelete = () => {
    const { title } = block;
    confirmInput({
      message: <FormattedMessage id="block.confirmDelete" values={{ name: title, name_exists: !!title }} />,
      onOk: () => {
        onDelete(block);
      },
    });
  };

  const handleMoveBlock = () => {
    showModal(MoveBlockModalContainer, { block, bootcamp });
  };

  const handleCopyBlock = () => {
    showModal(CopyBlockModalContainer, { block, bootcamp });
  };

  const handleMoveToPart = () => {
    showModal(SelectPartModal, { block });
  };

  const handleMoveToStep = () => {
    showModal(SelectStepModal, { block });
  };

  const handleOpenTrackSelectionModal = () => {
    showModal(SelectAdaptiveLearningTracks, { block });
  };

  const showMoveToStepButton = !!step;
  const showMoveToPartButton = parts?.length > 1;

  const getButtons = () => {
    const buttons = [];

    if (onEdit) {
      buttons.push({
        icon: <Icons.Pencil />,
        messageId: 'common.edit',
        onClick: e => !isSavingInProcess() && handleClick(e, onEdit),
        className: 'tooltip-button__button--split-top',
        isDisabled: isSaving,
      });
    }

    if (onDelete) {
      buttons.push({
        icon: <Icons.Trash />,
        messageId: 'common.delete',
        onClick: e => handleClick(e, handleDelete),
        className: 'tooltip-button__button--split-top',
      });
    }

    if (onReorder) {
      if (index > 0) {
        buttons.push({
          messageId: 'aria.moveUp',
          onClick: e => handleClick(e, handleMoveUp),
          className: 'tooltip-button__button--split-top',
        });
      }

      if (index < total - 1) {
        buttons.push({
          messageId: 'aria.moveDown',
          onClick: e => handleClick(e, handleMoveDown),
          className: 'tooltip-button__button--split-top',
        });
      }
    }

    if (showMoveToPartButton) {
      buttons.push({
        messageId: 'block.moveToPart.button',
        onClick: e => handleClick(e, handleMoveToPart),
        className: 'tooltip-button__button--split-top',
      });
    }

    if (showMoveToStepButton) {
      buttons.push({
        messageId: 'block.moveToStep.button',
        onClick: e => handleClick(e, handleMoveToStep),
        className: 'tooltip-button__button--split-top',
      });
    }

    buttons.push({
      messageId: 'block.moveTo.button',
      onClick: e => handleClick(e, handleMoveBlock),
      className: 'tooltip-button__button--split-top',
    });

    buttons.push({
      messageId: 'block.copyTo.button',
      onClick: e => handleClick(e, handleCopyBlock),
      className: 'tooltip-button__button--split-top',
    });

    buttons.push({
      messageId: 'block.adaptive.selectTracks',
      onClick: e => handleClick(e, handleOpenTrackSelectionModal),
      className: 'tooltip-button__button--split-top',
    });

    return buttons;
  };

  return (
    <>
      <span className="square-element" />
      <span className="square-element" />
      <span className="square-element" />
      <span className="square-element" />

      <div ref={containerRef}>
        <button
          aria-label={intl.formatMessage({ id: 'aria.editBlock' })}
          className="block__button block__button--more"
          onClick={toggleOpen}
          type="button">
          <div className="icon-element icon-element--fill-blue icon-element--background-white icon-element--button">
            <Icons.More />
          </div>
        </button>

        {isOpen && <TooltipButtons buttons={getButtons()} onClose={toggleOpen} />}
      </div>
    </>
  );
};

BlockEditOptions.propTypes = {
  block: PropTypes.object.isRequired,
  bootcamp: PropTypes.object,
  index: PropTypes.number,
  total: PropTypes.number,
  onEdit: PropTypes.func,
  onReorder: PropTypes.func,
  onDelete: PropTypes.func,
};

export default BlockEditOptions;
