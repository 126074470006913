import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, Radio, Checkbox } from 'antd';
import { FormattedMessage } from 'react-intl';
import classnames from 'classnames';

import Icons from '../../../../Utils/Icons';
import Enrollment from '../../../../../api/enrollment';

const EditEnrollmentStatusModal = ({ enrollment, onOk, onCancel }) => {
  const [enrollmentStatus, setEnrollmentStatus] = useState(enrollment.status);
  const [hasCompletedAllAssignments, setHasCompletedAllAssignments] = useState(
    () => enrollment.status === Enrollment.COMPLETED,
  );

  const handleStatusChange = e => {
    const { value } = e.target;
    setEnrollmentStatus(value);
    setHasCompletedAllAssignments(false);
  };

  const handleCheckboxChange = e => {
    setHasCompletedAllAssignments(e.target.checked);
  };

  const isSubmitDisabled = () => {
    if (enrollmentStatus === Enrollment.ENROLLED || enrollmentStatus === Enrollment.DROPPED) {
      return false;
    }

    if (hasCompletedAllAssignments) {
      return false;
    }

    return true;
  };

  const handleSave = () => {
    onOk(enrollmentStatus);
  };

  return (
    <Modal
      onCancel={onCancel}
      title={<FormattedMessage id="cohortDashboard.studentsPage.updateEnrollmentStatus.markEnrollmentStatus" />}
      className="ant-edit-enrollment-status-modal"
      visible
      footer={null}>
      <div data-testid="edit-enrollment-status-modal">
        <Radio.Group className="status-group" name="radiogroup" value={enrollmentStatus}>
          <Radio onChange={handleStatusChange} value={Enrollment.ENROLLED}>
            <FormattedMessage id="cohortDashboard.studentsPage.updateEnrollmentStatus.enrolled" />
          </Radio>

          <Radio onChange={handleStatusChange} value={Enrollment.DROPPED}>
            <FormattedMessage id="cohortDashboard.studentsPage.updateEnrollmentStatus.dropped" />
          </Radio>
          {enrollmentStatus === Enrollment.DROPPED && (
            <div className="status-info">
              <FormattedMessage id="cohortDashboard.studentsPage.updateEnrollmentStatus.updateInfo" />
            </div>
          )}

          <Radio onChange={handleStatusChange} value={Enrollment.COMPLETED}>
            <FormattedMessage id="cohortDashboard.studentsPage.updateEnrollmentStatus.completed" />
          </Radio>
          {enrollmentStatus === Enrollment.COMPLETED && (
            <div className="status-info">
              <div>
                <FormattedMessage id="cohortDashboard.studentsPage.updateEnrollmentStatus.updateInfo" />
              </div>
              <div>
                <FormattedMessage id="cohortDashboard.studentsPage.updateEnrollmentStatus.completeInfo" />
              </div>

              <Checkbox onChange={handleCheckboxChange} checked={hasCompletedAllAssignments}>
                <FormattedMessage id="cohortDashboard.studentsPage.updateEnrollmentStatus.verifyComplete" />
              </Checkbox>
            </div>
          )}
        </Radio.Group>

        <div className="button-group">
          <button type="button" className="cancel-button" onClick={onCancel}>
            <FormattedMessage id="common.cancel" />
          </button>

          <button
            type="button"
            className={classnames('submit-button', { 'submit-button__disabled': isSubmitDisabled() })}
            onClick={handleSave}
            disabled={isSubmitDisabled()}>
            <FormattedMessage id="cohortDashboard.studentsPage.updateEnrollmentStatus.markEnrollmentStatus" />
          </button>
        </div>
      </div>
    </Modal>
  );
};

EditEnrollmentStatusModal.propTypes = {
  enrollment: PropTypes.object.isRequired,
  onOk: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

const EditEnrollmentStatusButton = ({ enrollment, onOk }) => {
  const [modalVisible, setModalVisible] = useState(false);

  const handleClick = () => {
    setModalVisible(true);
  };

  const handleOk = enrollmentStatus => {
    onOk(enrollmentStatus);
    setModalVisible(false);
  };

  const handleCancel = () => {
    setModalVisible(false);
  };

  return (
    <>
      <button className="edit-enrollment-button" type="button" onClick={handleClick}>
        <Icons.Edit />
        <FormattedMessage id="cohortDashboard.studentsPage.updateEnrollmentStatus.markEnrollmentStatus" />
      </button>

      {modalVisible && <EditEnrollmentStatusModal enrollment={enrollment} onOk={handleOk} onCancel={handleCancel} />}
    </>
  );
};

EditEnrollmentStatusButton.propTypes = {
  enrollment: PropTypes.object.isRequired,
  onOk: PropTypes.func.isRequired,
};

export default EditEnrollmentStatusButton;
