import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Modal2 from '../../library/Modal2';
import { toastSuccess } from '../../../utils/toastHelper';
import { getPlainText } from '../../../utils/plainText';
import CohortSelectorContainer from '../../Cohorts/CohortSelectorContainer';

export default class LtiConfigDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedCohort: this.props.selectedCohort,
    };
  }

  componentDidMount() {
    this.props.fetchCohorts();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.state.selectedCohort !== nextProps.selectedCohort) {
      this.setState({ selectedCohort: nextProps.selectedCohort });
    }
  }

  handleCohortChange = selectedCohort => {
    this.setState({ selectedCohort });
  };

  ltiConfig = () => {
    const { bootcamp, assignment } = this.props;
    const { selectedCohort } = this.state;

    if (!selectedCohort) {
      return;
    }

    const title = assignment ? `PathStream - ${assignment.title}` : `PathStream - ${bootcamp.title}`;
    const description = getPlainText(assignment ? assignment.description : bootcamp.description);
    const { location } = window;
    const iconUrl = `${location.protocol}//${location.host}/favicon.ico`;
    let launchUrl = `${location.protocol}//${location.host}/users/lti`;
    const toolId = assignment ? `${selectedCohort.id}-${assignment.id}` : selectedCohort.id;
    let assignmentSelection = '';
    if (assignment) {
      launchUrl += `?assignment_id=${assignment.id}`;
    } else {
      assignmentSelection = `\n      <lticm:options name="assignment_selection">
        <lticm:property name="message_type">ContentItemSelectionRequest</lticm:property>
        <lticm:property name="url">${launchUrl}</lticm:property>
      </lticm:options>`;
    }

    const ltiConfig = `<?xml version="1.0" encoding="UTF-8"?>
<cartridge_basiclti_link xmlns="http://www.imsglobal.org/xsd/imslticc_v1p0"
    xmlns:blti = "http://www.imsglobal.org/xsd/imsbasiclti_v1p0"
    xmlns:lticm ="http://www.imsglobal.org/xsd/imslticm_v1p0"
    xmlns:lticp ="http://www.imsglobal.org/xsd/imslticp_v1p0"
    xmlns:xsi = "http://www.w3.org/2001/XMLSchema-instance"
    xsi:schemaLocation = "http://www.imsglobal.org/xsd/imslticc_v1p0 http://www.imsglobal.org/xsd/lti/ltiv1p0/imslticc_v1p0.xsd
    http://www.imsglobal.org/xsd/imsbasiclti_v1p0 http://www.imsglobal.org/xsd/lti/ltiv1p0/imsbasiclti_v1p0.xsd
    http://www.imsglobal.org/xsd/imslticm_v1p0 http://www.imsglobal.org/xsd/lti/ltiv1p0/imslticm_v1p0.xsd
    http://www.imsglobal.org/xsd/imslticp_v1p0 http://www.imsglobal.org/xsd/lti/ltiv1p0/imslticp_v1p0.xsd">
    <blti:title>${title}</blti:title>
    <blti:description>${description || ''}</blti:description>
    <blti:icon>${iconUrl}</blti:icon>
    <blti:launch_url>${launchUrl}</blti:launch_url>
    <blti:custom>
      <lticm:property name="cohort_id">${selectedCohort.id}</lticm:property>
    </blti:custom>
    <blti:extensions platform="canvas.instructure.com">
      <lticm:property name="tool_id">${toolId}</lticm:property>
      <lticm:property name="privacy_level">public</lticm:property>${assignmentSelection}
    </blti:extensions>
    <cartridge_bundle identifierref="BLTI001_Bundle"/>
    <cartridge_icon identifierref="BLTI001_Icon"/>
</cartridge_basiclti_link>`;

    return ltiConfig;
  };

  handleCopy = text => {
    toastSuccess('Copied to clipboard');
  };

  render() {
    const { bootcamp } = this.props;
    const { selectedCohort } = this.state;

    return (
      <Modal2
        size="big"
        className="modal-auto-grow"
        bodyClass=" "
        onHide={this.props.onHide}
        showCancel={false}
        title={<FormattedMessage id="lti.config.heading" />}>
        <div style={{ marginBottom: 10 }}>
          <CohortSelectorContainer bootcamp={bootcamp} onSelect={this.handleCohortChange} selected={selectedCohort} />
        </div>

        <div className="custom-textarea">
          <textarea
            className="custom-textarea__textarea"
            readOnly
            rows={15}
            value={this.ltiConfig()}
            style={{ width: '100%', whiteSpace: 'pre-wrap' }}
          />
        </div>

        <div className="modal-dialog-footer">
          <button
            type="button"
            className="button-standard button-standard--transparent button-standard--medium"
            onClick={this.props.onHide}>
            <FormattedMessage id="common.close" />
          </button>

          <CopyToClipboard text={this.ltiConfig()} onCopy={this.handleCopy}>
            <button type="button" className="button-standard button-standard--blue2 button-standard--medium">
              <FormattedMessage id="common.copy" />
            </button>
          </CopyToClipboard>
        </div>
      </Modal2>
    );
  }
}
LtiConfigDialog.propTypes = {
  bootcamp: PropTypes.object.isRequired,
  assignment: PropTypes.object,
  fetchCohorts: PropTypes.func.isRequired,
};
