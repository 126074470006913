import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Modal } from 'antd';
import ViewGrade from './ViewGrade';

export default function ViewGradeModal({ onHide, assignment, ...rest }) {
  return (
    <Modal
      title={<FormattedMessage id="grade.heading" values={{ assignmentTitle: assignment.title }} />}
      visible
      onCancel={onHide}
      footer={null}
      width="fit-content">
      <ViewGrade {...rest} />
    </Modal>
  );
}

ViewGradeModal.propTypes = {
  ...ViewGrade.propTypes,
  assignment: PropTypes.object.isRequired,
  onHide: PropTypes.func.isRequired,
};
