import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import { isEmpty as _isEmpty } from 'lodash';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import intl from '../../../../../utils/intl';
import AssignmentCardActions from './AssignmentCardActions';
import { selectAssignment } from '../../../../../selectors/assignmentSelectors';

const AssignmentCard = ({ unit, assignmentProgress, unitProgress }) => {
  const assignment = useSelector(state => selectAssignment(state, unit?.assignment_id));
  const location = useLocation();

  const getSubmissionText = () => {
    if (!assignmentProgress || !assignmentProgress?.completed_at) {
      return intl.formatMessage({ id: 'cohortDashboard.assignmentsPage.notStarted' });
    }
    const completedAtMoment = moment(assignmentProgress.completed_at);
    const time = completedAtMoment.format('h:mm a');
    const date = completedAtMoment.format('MMMM D');

    const textId = _isEmpty(assignmentProgress.score_history)
      ? 'cohortDashboard.assignmentsPage.submittedAt'
      : 'cohortDashboard.assignmentsPage.resubmittedAt';
    return intl.formatMessage({ id: textId }, { time, date });
  };

  const gradeButtonText = useMemo(() => {
    if (!assignmentProgress) {
      return;
    }
    if (assignmentProgress.requires_grading) {
      if (!!assignmentProgress.graded_at) {
        return intl.formatMessage({ id: 'grading.regrade' });
      }

      return intl.formatMessage({ id: 'grading.grade' });
    }

    return intl.formatMessage({ id: 'grading.view' });
  }, [assignmentProgress]);

  const returnUrl = encodeURIComponent(`${location.pathname}${location.search}`);
  const gradeUrl = `/coach/grade/${unitProgress?.id}?isGrading=true&returnUrl=${returnUrl}`;

  const showGradeButton = assignmentProgress && assignmentProgress.completed_at;

  const showScore = assignmentProgress?.requires_grading && assignmentProgress.score !== null;

  return (
    <div className="student-assignment-card" data-testid="assignment-card">
      <div className="student-assignment-card--start">
        <div className="assignment-card-title" data-testid="assignment-title">
          {unit.title}
        </div>
        <div className="assignment-card-status" data-testid="submission-text">
          {getSubmissionText()}
        </div>
      </div>
      {showScore && (
        <div className="student-assignment-card--center" data-testid="submission-score">
          <div className="assignment-card-grade-title">
            <FormattedMessage id="roster.grade" />
          </div>
          <div>{assignmentProgress.score}%</div>
        </div>
      )}
      <div className="student-assignment-card--actions">
        {showGradeButton && (
          <Link to={gradeUrl} className="student-assignment-card--actions--grade-button" data-testid="grade-button">
            {gradeButtonText}
          </Link>
        )}

        <span className="student-assignment-card--actions--end">
          <AssignmentCardActions assignment={assignment} assignmentProgress={assignmentProgress} />
        </span>
      </div>
    </div>
  );
};
export default AssignmentCard;

AssignmentCard.propTypes = {
  assignmentProgress: PropTypes.object,
  unit: PropTypes.object.isRequired,
  unitProgress: PropTypes.object,
};
