import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ContentWrapper from './ContentWrapper';
import { selectSideNavIsCollapsed } from '../../selectors/sideNavSelectors';
import { selectCurrentUser } from '../../selectors/authSelectors';
import { selectIsEditMode } from '../../selectors/contextSelectors';

const mapStateToProps = state => {
  return {
    currentUser: selectCurrentUser(state),
    isCollapsed: selectSideNavIsCollapsed(state),
    isEditMode: selectIsEditMode(state),
  };
};

export default withRouter(connect(mapStateToProps, {})(ContentWrapper));
