import React from 'react';
import PropTypes from 'prop-types';

export default function SqlResults({ results }) {
  return (
    <div className="sql-results">
      {results.map((result, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <table key={index}>
          <thead>
            <tr>
              {result.columns.map((col, i) => (
                // eslint-disable-next-line react/no-array-index-key
                <th key={`col_${col}_${i}`}>{col}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {result.values.map((row, ri) => (
              // eslint-disable-next-line react/no-array-index-key
              <tr key={`row_${ri}`}>
                {row.map((cell, i) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <td key={`cell_${cell}_${i}`}>{cell}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      ))}
    </div>
  );
}

SqlResults.propTypes = {
  results: PropTypes.array,
};
