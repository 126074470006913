import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import intl from '../../../utils/intl';

const VM_NOT_REQUIRED_INIT_MINUTES = 1;
const VM_NOT_READY_INIT_MINUTES = 6;
const VM_READY_INIT_MINUTES = 2;

export default function LabLoadingBar({ usesRemoteVM, isVmUp }) {
  const [fakeMinutesLeft, setFakeMinutesLeft] = useState(usesRemoteVM ? null : VM_NOT_REQUIRED_INIT_MINUTES);
  const loadingBarTimer = useRef();

  useEffect(() => {
    if (isVmUp === undefined) return;

    return tryToSetFakeMinutesLeft(isVmUp ? VM_READY_INIT_MINUTES : VM_NOT_READY_INIT_MINUTES);
  }, [isVmUp]);

  const tryToSetFakeMinutesLeft = minutes => {
    if (minutes > 1) {
      setFakeMinutesLeft(minutes);

      clearTimeout(loadingBarTimer.current);
      loadingBarTimer.current = setTimeout(() => tryToSetFakeMinutesLeft(minutes - 1), 60000);
      return () => clearTimeout(loadingBarTimer.current);
    }

    if (isVmUp) {
      setFakeMinutesLeft(1);
    } else {
      tryToSetFakeMinutesLeft(2);
    }
  };

  const minutesLeftText = () =>
    intl.formatMessage({ id: 'tutorial.loadingModal.minutesLeft' }, { minutes: fakeMinutesLeft });

  return (
    <div className="loading-bar" data-testid="loading-bar">
      {fakeMinutesLeft ? minutesLeftText() : intl.formatMessage({ id: 'common.loading' })}
    </div>
  );
}

LabLoadingBar.propTypes = {
  usesRemoteVM: PropTypes.bool,
  isVmUp: PropTypes.bool,
};
