import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import classnames from 'classnames';

export default class LabNavButton extends Component {
  render() {
    const { onClick, messageId, reset, back } = this.props;
    const classes = classnames('lab-nav-button', reset && 'lab-nav-button---reset', back && 'lab-nav-button---back');

    return (
      <div className={classes}>
        <button type="button" className="button-standard" onClick={onClick}>
          <FormattedMessage id={messageId} />
        </button>
      </div>
    );
  }
}
LabNavButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  messageId: PropTypes.string.isRequired,
  reset: PropTypes.bool,
  back: PropTypes.bool,
};
LabNavButton.defaultProps = {
  reset: false,
};
