import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { canAuthor, canAdministrate, canCoach } from '../../helpers/userAuth';
import { ReactComponent as SvgIconBootcamp } from '../../styles/pathstream/svg-jsx/icon-bootcamp.svg';
import { ReactComponent as SvgIconBadge } from '../../styles/pathstream/svg-jsx/icon-badge.svg';
//import { ReactComponent as SvgIconRoster } from '../../styles/pathstream/svg-jsx/icon-roster.svg';
import { ReactComponent as SvgIconUsers } from '../../styles/pathstream/svg-jsx/icon-users.svg';
import { ReactComponent as SvgIconCohorts } from '../../styles/pathstream/svg-jsx/icon-cohorts.svg';
// import { ReactComponent as SvgIconModule } from '../../styles/pathstream/svg-jsx/icon-module.svg';
// import { ReactComponent as SvgIconGrade } from '../../styles/pathstream/svg-jsx/icon-grade.svg';
// import { ReactComponent as SvgIconCalendar } from '../../styles/pathstream/svg-jsx/icon-calendar.svg';
// import { ReactComponent as SvgIconChat } from '../../styles/pathstream/svg-jsx/icon-chat.svg';
import intl from '../../utils/intl';

export default class StaffSidebar extends Component {
  render() {
    const { currentUser } = this.props;

    return (
      <nav className="utility-bar">
        <div className="utility-bar-inner">
          {canAdministrate(currentUser) && (
            <React.Fragment>
              <NavLink
                to="/admin/users"
                activeClassName="active"
                data-tip={intl.formatMessage({ id: 'nav.users' })}
                title={intl.formatMessage({ id: 'nav.users' })}
                aria-label={intl.formatMessage({ id: 'nav.users' })}>
                <SvgIconUsers />
              </NavLink>
              <NavLink
                to="/admin/cohorts"
                activeClassName="active"
                data-tip={intl.formatMessage({ id: 'nav.cohorts' })}
                title={intl.formatMessage({ id: 'nav.cohorts' })}
                aria-label={intl.formatMessage({ id: 'nav.cohorts' })}>
                <SvgIconCohorts />
              </NavLink>
            </React.Fragment>
          )}
          {canCoach(currentUser) && (
            <React.Fragment>
              {/* <NavLink to="/coach/roster" activeClassName="active"
                         data-tip={intl.formatMessage({id: 'nav.roster'})}
                         title={intl.formatMessage({id: 'nav.roster'})}
                         aria-label={intl.formatMessage({id: 'nav.roster'})}>
                  <SvgIconRoster/>
                </NavLink> */}
              <NavLink
                to="/coach/grade"
                activeClassName="active"
                data-tip={intl.formatMessage({ id: 'nav.gradingQueue' })}
                title={intl.formatMessage({ id: 'nav.gradingQueue' })}
                aria-label={intl.formatMessage({ id: 'nav.gradingQueue' })}>
                <SvgIconBadge />
              </NavLink>
              {!canAuthor(currentUser) && (
                <NavLink
                  to="/coach/bootcamps"
                  activeClassName="active"
                  data-tip={intl.formatMessage({ id: 'nav.bootcamps' })}
                  title={intl.formatMessage({ id: 'nav.bootcamps' })}
                  aria-label={intl.formatMessage({ id: 'nav.bootcamps' })}>
                  <SvgIconBootcamp />
                </NavLink>
              )}
            </React.Fragment>
          )}
          {canAuthor(currentUser) && (
            <React.Fragment>
              <NavLink
                to="/author/bootcamps"
                activeClassName="active"
                data-tip={intl.formatMessage({ id: 'nav.bootcamps' })}
                title={intl.formatMessage({ id: 'nav.bootcamps' })}
                aria-label={intl.formatMessage({ id: 'nav.bootcamps' })}>
                <SvgIconBootcamp />
              </NavLink>
            </React.Fragment>
          )}
        </div>
      </nav>
    );
  }
}
