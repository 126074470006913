import React from 'react';
import PropTypes from 'prop-types';

import intl from '../../../utils/intl';
import SimpleModal2 from '../../library/SimpleModal2/SimpleModal2';
import bgErrorImg from '../../../styles/v2/images/lab/bg-error.png';
import bgAlertImg from '../../../styles/v2/images/lab/bg-alert-purple.png';
import iconMachineHiccupImg from '../../../styles/v2/images/lab/icon-machine-hiccup.png';
import iconLongerThanUsualImg from '../../../styles/v2/images/lab/icon-longer-than-usual.png';
import iconOneLabAtATimeImg from '../../../styles/v2/images/lab/icon-one-lab-at-a-time.png';
import iconLabGotSleepyImg from '../../../styles/v2/images/lab/icon-lab-got-sleepy.png';

const getMachineHiccupModalProps = () => ({
  backgroundImage: `url(${bgErrorImg})`,
  icon: iconMachineHiccupImg,
  title: intl.formatMessage({ id: 'tutorial.errorModal.machineHiccup.title' }),
  body: intl.formatMessage({ id: 'tutorial.errorModal.machineHiccup.body' }),
  leftButtonText: intl.formatMessage({ id: 'tutorial.errorModal.restartLab' }),
});

const getLongerThanUsualModalProps = () => ({
  backgroundImage: `url(${bgErrorImg})`,
  icon: iconLongerThanUsualImg,
  title: intl.formatMessage({ id: 'tutorial.errorModal.longerThanUsual.title' }),
  body: intl.formatMessage({ id: 'tutorial.errorModal.longerThanUsual.body' }),
  leftButtonText: intl.formatMessage({ id: 'tutorial.errorModal.restartLab' }),
});

const getOneLabAtATimeModalProps = () => ({
  backgroundImage: `url(${bgErrorImg})`,
  icon: iconOneLabAtATimeImg,
  title: intl.formatMessage({ id: 'tutorial.errorModal.oneLabAtATime.title' }),
  body: intl.formatMessage({ id: 'tutorial.errorModal.oneLabAtATime.body' }),
  leftButtonText: intl.formatMessage({ id: 'tutorial.errorModal.restartLab' }),
});

const getLabGotSleepyModalProps = () => ({
  backgroundImage: `url(${bgAlertImg})`,
  icon: iconLabGotSleepyImg,
  title: intl.formatMessage({ id: 'tutorial.errorModal.labGotSleepy.title' }),
  body: intl.formatMessage({ id: 'tutorial.errorModal.labGotSleepy.body' }),
  leftButtonText: intl.formatMessage({ id: 'tutorial.errorModal.restartLab' }),
});

const getMissingOrNotReadyDiskModalProps = () => ({
  backgroundImage: `url(${bgErrorImg})`,
  icon: iconMachineHiccupImg,
  title: intl.formatMessage({ id: 'tutorial.errorModal.missingOrNotReadyDisk.title' }),
  body: intl.formatMessage({ id: 'tutorial.errorModal.missingOrNotReadyDisk.body' }),
  leftButtonText: intl.formatMessage({ id: 'tutorial.errorModal.restartLab' }),
});

const getNoMachinesAvailableModalProps = () => ({
  backgroundImage: `url(${bgErrorImg})`,
  icon: iconMachineHiccupImg,
  title: intl.formatMessage({ id: 'tutorial.errorModal.noMachinesAvailable.title' }),
  body: null,
  leftButtonText: null,
  rightButtonHelpText: null,
});

const getCleaningUpOldSessionModalProps = () => ({
  backgroundImage: `url(${bgErrorImg})`,
  icon: iconMachineHiccupImg,
  title: intl.formatMessage({ id: 'tutorial.errorModal.cleaningUpOldSession.title' }),
  body: null,
  leftButtonText: null,
  rightButtonHelpText: null,
});

const getDiskInMaintenanceModalProps = () => ({
  backgroundImage: `url(${bgErrorImg})`,
  icon: iconMachineHiccupImg,
  title: intl.formatMessage({ id: 'tutorial.errorModal.diskInMaintenance.title' }),
  body: intl.formatMessage({ id: 'tutorial.errorModal.diskInMaintenance.body' }),
  leftButtonText: null,
});

const LabErrorModal = props => {
  const restartLab = () => {
    window.location.reload(false);
  };

  return (
    <div className="lab-error-modal-test">
      <SimpleModal2 onLeftButton={restartLab} {...props} testId="lab-error-modal" />
    </div>
  );
};

LabErrorModal.propTypes = {
  backgroundImage: PropTypes.string,
  icon: PropTypes.string,
  title: PropTypes.string,
  body: PropTypes.string,
  leftButtonText: PropTypes.string,
  onLeftButton: PropTypes.func,
};

export {
  LabErrorModal,
  getMachineHiccupModalProps,
  getLongerThanUsualModalProps,
  getOneLabAtATimeModalProps,
  getLabGotSleepyModalProps,
  getMissingOrNotReadyDiskModalProps,
  getNoMachinesAvailableModalProps,
  getCleaningUpOldSessionModalProps,
  getDiskInMaintenanceModalProps,
};
