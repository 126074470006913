import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Modal, Table, Tag } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import ModalFooter from '../../library/ModalFooter';
import { selectProjectsAndQuizzesForBootcamp } from '../../../selectors/unitSelectors';
import {
  fetchBootcampInstanceAssignments,
  saveBootcampInstanceAssignment,
  updateBootcampInstanceAssignment,
} from '../../../actions/bootcampInstanceAssignmentActions';
import {
  fetchBootcampInstanceQuizzes,
  saveBootcampInstanceQuiz,
  updateBootcampInstanceQuiz,
} from '../../../actions/bootcampInstanceQuizActions';
import { fetchCohort } from '../../../actions/cohortActions';
import { map as _map } from 'lodash';
import EditDueDateModal from '../../Coach/Grading/EditDueDateModal';
import {
  selectBootcampInstanceAssignments,
  selectBootcampInstanceQuizzes,
} from '../../../selectors/bootcampInstanceAssignmentSelectors';
import './styles/CohortAssignmentsDueDatesModal.scss';

const useStore = cohort => {
  const cohortUnits = useSelector(state => selectProjectsAndQuizzesForBootcamp(state, cohort.bootcamp_id));
  const hiddenUnits = _map(cohort && cohort.hidden_items, item => item.split('::')[1]);
  const units = cohortUnits && cohortUnits.filter(unit => !hiddenUnits.includes(unit.id));

  const allBootcampInstanceAssignments = useSelector(selectBootcampInstanceAssignments);
  const allBootcampInstanceQuizzes = useSelector(selectBootcampInstanceQuizzes);

  const bootcampInstanceQuizzes = allBootcampInstanceQuizzes
    ? Object.values(allBootcampInstanceQuizzes).filter(
        bootcampInstanceQuiz => bootcampInstanceQuiz.bootcamp_instance_id === cohort.id,
      )
    : [];
  const bootcampInstanceAssignments = allBootcampInstanceAssignments
    ? Object.values(allBootcampInstanceAssignments).filter(
        bootcampInstanceAssignment => bootcampInstanceAssignment.bootcamp_instance_id === cohort.id,
      )
    : [];

  return {
    units,
    bootcampInstanceAssignments,
    bootcampInstanceQuizzes,
  };
};

const CohortAssignmentsDueDatesModal = ({ cohort, onHide }) => {
  const [triggerUseEffect, setTriggerUseEffect] = React.useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    fetchCohort(cohort.id)(dispatch);
    fetchBootcampInstanceAssignments(cohort.id)(dispatch);
    fetchBootcampInstanceQuizzes(cohort.id)(dispatch);
  }, [cohort, dispatch, triggerUseEffect]);

  const { units, bootcampInstanceAssignments, bootcampInstanceQuizzes } = useStore(cohort);

  const currentUnitIds = units.flatMap(unit => [unit.quiz_id, unit.assignment_id].filter(id => id != null));

  let gradableUnitArr = [...bootcampInstanceAssignments, ...bootcampInstanceQuizzes];

  gradableUnitArr.push(
    ...units.filter(
      unit =>
        (unit.assignment_id && !gradableUnitArr.some(gu => gu.assignment_id === unit.assignment_id)) ||
        (unit.quiz_id && !gradableUnitArr.some(gu => gu.quiz_id === unit.quiz_id)),
    ),
  );

  gradableUnitArr.forEach(unit => {
    unit.statusOnModal =
      currentUnitIds.includes(unit.assignment_id) || currentUnitIds.includes(unit.quiz_id) ? 'Current' : 'Deleted';
  });

  gradableUnitArr.sort(
    (a, b) =>
      (a.statusOnModal === 'Current' ? -1 : 1) - (b.statusOnModal === 'Current' ? -1 : 1) ||
      new Date(a.quiz_due_at || a.assignment_due_at) - new Date(b.quiz_due_at || b.assignment_due_at),
  );

  gradableUnitArr.map((unit, index) => {
    unit.key = index + 1;
  });

  let totalGradeWeight = gradableUnitArr.reduce((a, b) => +a + +b.weight, 0);

  const saveOrUpdateDueDate = (bootcampInstanceAssignment, bootcampInstanceQuiz, unit) => (dueDate, weight) => {
    const handleError = error => alert(error.value.errors);
    const handleSuccess = () => setTriggerUseEffect(!triggerUseEffect);

    const updateData = (data, updateFunction) => {
      updateFunction(data)(dispatch).then(handleSuccess).catch(handleError);
    };

    if (bootcampInstanceAssignment) {
      updateData(
        {
          id: bootcampInstanceAssignment.id,
          assignment_due_at: dueDate,
          weight: weight,
        },
        updateBootcampInstanceAssignment,
      );
    } else if (bootcampInstanceQuiz) {
      updateData(
        {
          id: bootcampInstanceQuiz.id,
          quiz_due_at: dueDate,
          weight: weight,
        },
        updateBootcampInstanceQuiz,
      );
    } else if (unit.assignment_id) {
      updateData(
        {
          bootcamp_instance_id: cohort.id,
          assignment_id: unit.assignment_id,
          assignment_due_at: dueDate,
          weight: weight,
        },
        saveBootcampInstanceAssignment,
      );
    } else {
      updateData(
        {
          bootcamp_instance_id: cohort.id,
          quiz_id: unit.quiz_id,
          quiz_due_at: dueDate,
          weight: weight,
        },
        saveBootcampInstanceQuiz,
      );
    }
  };

  const columns = [
    {
      title: 'Assignment',
      dataIndex: 'title',
      key: 'title',
      align: 'left',
      render: (text, record) => (
        <div
          key={
            record.assignment_id ? `title-${cohort.id}-${record.assignment_id}` : `title-${cohort.id}-${record.quiz_id}`
          }>
          {text}
        </div>
      ),
    },
    {
      title: 'Due Date',
      key: 'date',
      dataIndex: 'date',
      align: 'center',
      render: (text, record) => {
        const bootcampInstanceAssignment = bootcampInstanceAssignments.find(
          bootcampInstanceAssignment => bootcampInstanceAssignment.assignment_id === record.assignment_id,
        );
        const bootcampInstanceQuiz = bootcampInstanceQuizzes.find(
          bootcampInstanceQuiz => bootcampInstanceQuiz.quiz_id === record.quiz_id,
        );
        return (
          <div
            key={
              record.assignment_id
                ? `action-${cohort.id}-${record.assignment_id}`
                : `action-${cohort.id}-${record.quiz_id}`
            }
            className="dueDateCohortContainer">
            <EditDueDateModal
              key={`due-date-${cohort.id}-${record.assignment_id}`}
              bootcampInstanceAssignment={bootcampInstanceAssignment}
              bootcampInstanceQuiz={bootcampInstanceQuiz}
              handleOk={saveOrUpdateDueDate(bootcampInstanceAssignment, bootcampInstanceQuiz, record)}
            />
          </div>
        );
      },
    },
    {
      title: 'Grade Weight',
      key: 'weight',
      dataIndex: 'weight',
      align: 'center',
      render: (text, record) => {
        const bootcampInstanceAssignment = bootcampInstanceAssignments.find(
          bootcampInstanceAssignment => bootcampInstanceAssignment.assignment_id === record.assignment_id,
        );
        const bootcampInstanceQuiz = bootcampInstanceQuizzes.find(
          bootcampInstanceQuiz => bootcampInstanceQuiz.quiz_id === record.quiz_id,
        );
        return (
          <div
            key={
              record.assignment_id
                ? `title-${cohort.id}-${record.assignment_id}`
                : `title-${cohort.id}-${record.quiz_id}`
            }>
            {bootcampInstanceAssignment?.weight
              ? parseFloat(bootcampInstanceAssignment.weight)
              : bootcampInstanceQuiz?.weight
              ? parseFloat(bootcampInstanceQuiz.weight)
              : 0}{' '}
            %
          </div>
        );
      },
    },
    {
      title: 'Status',
      key: 'statusOnModal',
      align: 'right',
      render: (text, record) => (
        <div key={record.key}>
          <Tag color={record.statusOnModal === 'Current' ? 'green' : 'red'}>{record.statusOnModal}</Tag>
        </div>
      ),
    },
  ];

  return (
    <Modal
      centered
      title={<FormattedMessage id="cohort.manageCohortAssignmentsAndGrades" />}
      visible
      width={800}
      onCancel={onHide}
      footer={
        <ModalFooter
          showCancel
          cancelTitle={<FormattedMessage id="common.close" />}
          onCancel={onHide}
          okTitle={<FormattedMessage id="common.save" />}
        />
      }>
      <Table columns={columns} dataSource={gradableUnitArr} pagination={false} rowClassName="editable-row" />

      <div className="grading-weight-reminder-container">
        <div className="total-grade-weight-text">{'Grade Weight Total:'}</div>
        <div className={totalGradeWeight === 100 ? 'total-grade-weight-green' : 'total-grade-weight-red'}>
          {totalGradeWeight} %
        </div>
      </div>
    </Modal>
  );
};

CohortAssignmentsDueDatesModal.propTypes = {
  cohort: PropTypes.object.isRequired,
  onHide: PropTypes.func.isRequired,
};

export default CohortAssignmentsDueDatesModal;
