import React, { Component } from 'react';
import EditSingleQuestionQuiz from '../Quizzes/EditSingleQuestionQuiz';
import StudentBlockSingleQuestionQuizShow from '../../Student/BlockSingleQuestionQuizShow';
import BlockQuizShow from './BlockQuizShow';

export default class BlockSingleQuestionQuizShow extends Component {
  render() {
    return (
      <BlockQuizShow
        {...this.props}
        StudentBlockShowComponent={StudentBlockSingleQuestionQuizShow}
        EditQuizComponent={EditSingleQuestionQuiz}
      />
    );
  }
}
