import PropTypes from 'prop-types';
import React from 'react';
import { Modal } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { FormattedMessage } from 'react-intl';
import CoachDashboardContent from '../../../../Coach/Dashboard/CoachDashboardContent';
import DashboardModalContext from '../context/DashboardModalContext';

const DashboardModal = ({ visible, handleModalVisible, backToDashboard }) => {
  const goBackToDashboard = () => {
    backToDashboard(true);
    handleModalVisible(false);
  };

  const hideModal = () => {
    handleModalVisible(false);
  };

  return (
    <Modal
      title={
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <a href="#" onClick={goBackToDashboard}>
          <ArrowLeftOutlined /> <FormattedMessage id="coachHeader.backToDashboard" />
        </a>
      }
      width={1140}
      visible={visible}
      centered
      onCancel={() => handleModalVisible(false)}
      footer={null}>
      <DashboardModalContext.Provider
        value={{
          onModalHide: hideModal,
        }}>
        <CoachDashboardContent />
      </DashboardModalContext.Provider>
    </Modal>
  );
};

export default DashboardModal;

DashboardModal.propTypes = {
  backToDashboard: PropTypes.func.isRequired,
  handleModalVisible: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
};
