import { cloneDeep as _cloneDeep, map as _map } from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import QuestionResponse from './QuestionResponse';
import {
  findChoice,
  isSubmitted,
  buildSelectableChoices,
  getChoicesFromProgress,
  organizeUserChoices,
} from '../../helpers/questionHelper';
import RTEContent from '../../helpers/contentMathjaxHelper';

export default class QuestionMultiSelect extends Component {
  constructor(props) {
    super(props);

    this.state = this.buildState();
  }

  buildState = () => {
    const { questionProgress, question } = this.props;
    if (isSubmitted(questionProgress)) {
      const userChoices = getChoicesFromProgress(questionProgress, question);
      return {
        userChoices: buildSelectableChoices(userChoices, true),
      };
    } else {
      const userChoices = organizeUserChoices(question);
      return this.initialState(userChoices);
    }
  };

  initialState = userChoices => ({
    userChoices: buildSelectableChoices(userChoices),
  });

  toggleUserChoice = userChoice => {
    this.setState(state => {
      const newUserChoices = _cloneDeep(state.userChoices);
      const newUserChoice = this.findMatchingChoice(userChoice, newUserChoices);
      newUserChoice.is_selected = !newUserChoice.is_selected;
      return { userChoices: newUserChoices };
    });
  };

  findMatchingChoice = (userChoice, choices = this.props.question.choices) => findChoice(userChoice, choices);

  handleAnswerQuestion = () => {
    if (!this.isCurrentQuestionAnswered()) {
      return;
    }

    this.props.submitChoices(this.state.userChoices);
  };

  isCurrentQuestionAnswered = () => this.state.userChoices.some(choice => choice.is_selected === true);

  render() {
    const { question, renderQuestionsNavigation, isLastQuestion } = this.props;
    const { userChoices } = this.state;

    return (
      <>
        <div className="content" style={{ paddingBottom: '0 !important' }}>
          <div className="graded-questions" role="group" aria-live="polite">
            {/* eslint-disable-next-line complexity */}
            {_map(userChoices, (userChoice, index) => {
              const isActive = userChoice.is_selected;

              const labelClass = classnames({
                'option-label': true,
                'option-label__active': isActive,
              });

              return (
                <div key={index} className="question">
                  <input
                    id={`questions-${question.id}-${index}-multiple`}
                    onChange={() => this.toggleUserChoice(userChoice)}
                    type="checkbox"
                    checked={isActive}
                  />
                  <div className="option-label-wrapper">
                    <RTEContent
                      content={userChoice.prompt}
                      Tag="label"
                      classSelector={labelClass}
                      htmlForVar={`questions-${question.id}-${index}-multiple`}
                    />
                  </div>
                </div>
              );
            })}
          </div>

          <QuestionResponse
            handleAnswerQuestion={this.handleAnswerQuestion}
            isCurrentQuestionAnswered={this.isCurrentQuestionAnswered}
            renderQuestionsNavigation={renderQuestionsNavigation}
            isLastQuestion={isLastQuestion}
          />
        </div>
      </>
    );
  }
}

QuestionMultiSelect.propTypes = {
  question: PropTypes.object.isRequired,
  questionProgress: PropTypes.object,
  submitChoices: PropTypes.func.isRequired,
  isLastQuestion: PropTypes.bool,
};
