import axios from './axios';

export default class LtiV13AssignmentMapping {
  static async all(tenantId, cohortId) {
    const response = await axios.get(`/assessments/lti_v13_assignment_mappings?bootcamp_instance_id=${cohortId}`);
    return response.data;
  }

  static async bulkCreate(tenantId, cohortId, mappings) {
    const response = await axios.post('/assessments/lti_v13_assignment_mappings/bulk_create', {
      bootcamp_instance_id: cohortId,
      mappings,
    });
    return response.data;
  }
}
