import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';

import EditBlockTitle from '../../Utils/EditBlockTitle';
import useSaveBlock from '../../../hooks/useSaveBlock';
import ContentValidator from '../../Author/Blocks/ContentValidator';
import BlockEditOptionsContainer from '../../Author/Blocks/BlockEditOptionsContainer';
import { showAsanaAddressModal, showAsanaAccountCreatedModal } from '../../Users/utils/showSetupModal';
import intl from '../../../utils/intl';

const AccountEdit = props => {
  const { block } = props;
  const { blockState } = useSaveBlock(block);
  const showAsanaModal = () => {
    if (currentUser.asana_address) {
      showAsanaAccountCreatedModal();
    } else {
      showAsanaAddressModal();
    }
  };

  return (
    <>
      <ContentValidator content={blockState.content}>
        <BlockEditOptionsContainer {...props} />
        <div className="section">
          <EditBlockTitle block={block} tagName="h2" />
          <Button type="primary" className="asana-account-button" size="large" shape="round" onClick={showAsanaModal}>
            {intl.formatMessage({ id: 'profile.asanaAccount.buttonTitle' })}
          </Button>
        </div>
      </ContentValidator>
    </>
  );
};

AccountEdit.propTypes = {
  block: PropTypes.object.isRequired,
};

export default AccountEdit;
