import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Errors from '../Utils/Errors';
import TermsOfServiceContent from './TermsOfServiceContent';
import Modal2 from '../library/Modal2';

export default class TermsOfService extends Component {
  constructor(props) {
    super(props);

    this.state = {
      errors: [],
    };
  }

  handleSubmit = e => {
    e.preventDefault();

    this.setState({ errors: [] });

    this.props
      .acceptTermsOfService()
      .then(() => this.props.onHide())
      .catch(err => this.setState({ errors: err.errors }));
  };

  render() {
    const { errors } = this.state;
    const { currentUser, onHide } = this.props;

    const letItClose = {
      showClose: !!currentUser.accepted_tos,
      closeOnEscape: !!currentUser.accepted_tos,
      showCancel: !!currentUser.accepted_tos,
      onHide,
    };

    return (
      <Modal2
        size="big"
        className="terms-of-service-modal"
        {...letItClose}
        onOk={!currentUser.accepted_tos ? this.handleSubmit : null}
        title={<FormattedMessage id="user.termsOfService.heading" />}
        okTitle={<FormattedMessage id="user.termsOfService.acceptBtn" />}
        cancelTitle={<FormattedMessage id="common.back" />}>
        <Errors errors={errors} />

        <TermsOfServiceContent />
      </Modal2>
    );
  }
}

TermsOfService.propTypes = {
  currentUser: PropTypes.object.isRequired,
  acceptTermsOfService: PropTypes.func.isRequired,
  onHide: PropTypes.func.isRequired,
};
