import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { selectTutorial } from '../../../selectors/tutorialSelectors';
import BlockLabShow from './BlockLabShow';
import { fetchTutorial } from '../../../actions/tutorialActions';
import { selectCurrentUser } from '../../../selectors/authSelectors';

const mapStateToProps = (state, ownProps) => {
  const { block, history, match } = ownProps;
  const tutorialId = block.external_id;
  const tutorial = selectTutorial(state, tutorialId);
  const currentUser = selectCurrentUser(state);
  return {
    currentUser,
    tutorial,
    history,
    match,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    fetchTutorial,
  })(BlockLabShow),
);
