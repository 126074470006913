/* eslint-disable max-lines */

export const dataSources = {
  any: {
    value: 'byAny',
    label: 'Any',
  },
  all: {
    value: 'byAll',
    label: 'All',
  },
  position: {
    value: 'byOrder',
    label: 'Positioned',
  },
  name: {
    value: 'byName',
    label: 'Named',
  },
  title: {
    value: 'byTitle',
    label: 'Title',
  },
  contains: {
    value: 'ByContains',
    label: 'Contains',
  },
  cellAddress: {
    value: 'cellAddress',
    label: 'Cell Address',
  },
  cellRange: {
    value: 'cellRange',
    label: 'Cell Range',
  },
};

export const properties = {
  numberOfWorksheets: {
    value: 'numberOfWorksheets',
    label: 'Number of Sheets',
  },
  exists: {
    value: 'exists',
    label: 'Exists',
  },
  name: {
    value: 'name',
    label: 'Name',
  },
  numberOfColumns: {
    value: 'numberOfColumns',
    label: 'Number of Columns',
  },
  numberOfRows: {
    value: 'numberOfRows',
    label: 'Number of Rows',
  },
  numberOfCharts: {
    value: 'numberOfCharts',
    label: 'Number of Charts',
  },
  numberOfPivotTables: {
    value: 'numberOfPivotTables',
    label: 'Number of Pivot Tables',
  },
  columnSize: {
    value: 'columnSize',
    label: 'Column Size',
  },
  rowSize: {
    value: 'rowSize',
    label: 'Row Size',
  },
  filterExists: {
    value: 'filterExists',
    label: 'Filter Exists',
  },
  filterType: {
    value: 'filterType',
    label: 'Filter Type',
  },
  sortType: {
    value: 'sortType',
    label: 'Sort Type',
  },
  value: {
    value: 'value',
    label: 'Value',
  },
  formula: {
    value: 'formula',
    label: 'Formula',
  },
  bold: {
    value: 'bold',
    label: 'Bold',
  },
  textColor: {
    value: 'textColor',
    label: 'Text Color',
  },
  backgroundColor: {
    value: 'backgroundColor',
    label: 'Background Color',
  },
  formatType: {
    value: 'formatType',
    label: 'FormatType',
  },
  type: {
    value: 'type',
    label: 'Type',
  },
  order: {
    value: 'order',
    label: 'Order',
  },
  xAxisLabel: {
    value: 'xAxisLabel',
    label: 'X-Axis Label',
  },
  yAxisLabel: {
    value: 'yAxisLabel',
    label: 'Y-Axis Label',
  },
  orderBy: {
    value: 'orderBy',
    label: 'Order By',
  },
  sortBy: {
    value: 'sortBy',
    label: 'Sort by',
  },
  showTotals: {
    value: 'showTotals',
    label: 'Show totals',
  },
  repeatRowLabels: {
    value: 'repeatRowLabels',
    label: 'Repeat row labels',
  },
  summarizeBy: {
    value: 'summarizeBy',
    label: 'Summarize by',
  },
  showAs: {
    value: 'showAs',
    label: 'Show As',
  },
  queries: {
    value: 'queries',
    label: 'Queries',
  },
  displayName: {
    value: 'displayName',
    label: 'Display Name',
  },
  dataType: {
    value: 'dataType',
    label: 'Data Type',
  },
  dimensionOrMeasure: {
    value: 'dimensionOrMeasure',
    label: 'Dimension or measure',
  },
  discreteOrContinuous: {
    value: 'discreteOrContinuous',
    label: 'Discrete or continuous',
  },
  numberOfCalculatedFields: {
    value: 'numberOfCalculatedFields',
    label: 'Number of Calculated Fields',
  },
  numberOfStories: {
    value: 'numberOfStories',
    label: 'Number of Stories',
  },
  numberOfDashboards: {
    value: 'numberOfDashboards',
    label: 'Number of Dashboards',
  },
  numberOfActions: {
    value: 'numberOfActions',
    label: 'Number of Actions',
  },
  graphType: {
    value: 'graphType',
    label: 'Graph type',
  },
  title: {
    value: 'title',
    label: 'Title',
  },
  titleFont: {
    value: 'titleFont',
    label: 'Title Font',
  },
  graphColor: {
    value: 'graphColor',
    label: 'Graph Color',
  },
  numberOfFilters: {
    value: 'numberOfFilters',
    label: 'Number of Filters',
  },
  numberOfCaptions: {
    value: 'numberOfCaptions',
    label: 'Number of Captions',
  },
  numberOfAnalytics: {
    value: 'numberOfAnalytics',
    label: 'Number of Analytics',
  },
  numberOfFields: {
    value: 'numberOfFields',
    label: 'Number of fields',
  },
  description: {
    value: 'description',
    label: 'Description',
  },
  numberOfTasks: {
    value: 'numberOfTasks',
    label: 'Number of tasks',
  },
  numberOfSections: {
    value: 'numberOfSections',
    label: 'Number of sections',
  },
  numberOfMilestones: {
    value: 'numberOfMilestones',
    label: 'Number of milestones',
  },
  numberOfCustomFields: {
    value: 'numberOfCustomFields',
    label: 'Number of custom fields',
  },
  section: {
    value: 'section',
    label: 'Section',
  },
  numberOfActiveCustomFields: {
    value: 'numberOfActiveCustomFields',
    label: 'Number of active custom fields',
  },
  dueDate: {
    value: 'dueDate',
    label: 'Due Date',
  },
  startDate: {
    value: 'startDate',
    label: 'Start Date',
  },
  endDate: {
    value: 'endDate',
    label: 'End Date',
  },
  taskLength: {
    value: 'taskLength',
    label: 'Task Length',
  },
  assignee: {
    value: 'assignee',
    label: 'Assignee',
  },
  overlapsWith: {
    value: 'overlapsWith',
    label: 'Overlaps with...',
  },
  before: {
    value: 'before',
    label: 'Before...',
  },
  after: {
    value: 'after',
    label: 'After...',
  },
  customField: {
    value: 'customField',
    label: 'Custom field',
  },
  checked: {
    value: 'checked',
    label: 'Checked',
  },
};

export const operators = [
  {
    value: '>',
    label: '>',
  },
  {
    value: '>=',
    label: '>=',
  },
  {
    value: '<',
    label: '<',
  },
  {
    value: '<=',
    label: '<=',
  },
  {
    value: '==',
    label: '==',
  },
  {
    value: '!=',
    label: '!=',
  },
  {
    value: 'contains',
    label: 'contains',
  },
  {
    value: 'doesNotContain',
    label: 'does not contain',
  },
  {
    value: 'all',
    label: 'all',
  },
  {
    value: 'some',
    label: 'some',
  },
  {
    value: 'none',
    label: 'none',
  },
];
