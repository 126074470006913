import { combineReducers } from 'redux';
import grade from './components/gradeReducer';
import roster from './components/rosterReducer';
import labPanel from './components/labPanelReducer';
import coach from './components/coachReducer';
import lesson from './components/lessonReducer';

const reducer = combineReducers({
  grade,
  roster,
  labPanel,
  coach,
  lesson,
});

export default reducer;
