import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Icons from '../Utils/Icons';

function StudentPathProgress(props) {
  const handleResumeClick = () => {
    const { resumeUrl, history } = props;
    history.push(resumeUrl);
  };

  const { resumeUrl, lessonTitle, resuming } = props;

  return (
    <div className="path-student-path-progress">
      <span className="icon-element icon-element--fill-white icon-element--background-main-blue-600  icon-element--size-standard">
        <Icons.Class />
      </span>
      <div className={`title-column ${resuming ? 'resuming' : ''}`}>
        <div className="current-lesson">Current Lesson: </div>
        <div className="title-display">{lessonTitle}</div>
      </div>
      <div className="continue-button-wrapper">
        <button disabled={!resumeUrl} className="button-standard button-standard--short" onClick={handleResumeClick}>
          <span className="icon-element icon-element--fill-white icon-element--background-transparent  icon-element--size-standard">
            <Icons.FillPlay />
          </span>
          {resuming ? (
            <FormattedMessage id="path.studentPathProgress.resumeLearning" />
          ) : (
            <FormattedMessage id="common.start" />
          )}
        </button>
      </div>
    </div>
  );
}

StudentPathProgress.propTypes = {
  bootcamp: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  resumeUrl: PropTypes.string,
};

export default StudentPathProgress;
