import { useSelector } from 'react-redux';

import { selectQuizByBlock } from '../selectors/quizSelectors';
import { selectQuestionsByQuizId } from '../selectors/questionSelectors';

const contentForPromptAndExplanation = object => (object.prompt || '') + (object.explanation || '');

const contentToValidate = (instructions, questions) => {
  let content = instructions || '';
  if (questions) {
    questions.forEach(question => {
      content += contentForPromptAndExplanation(question);
      if (question.choices) {
        question.choices.forEach(choice => {
          content += contentForPromptAndExplanation(choice);
        });
      }
    });
  }
  return content;
};

const useBlockContent = block => {
  const quiz = useSelector(state => selectQuizByBlock(state, block));
  const questions = useSelector(state => selectQuestionsByQuizId(state, quiz && quiz.id));
  const content = contentToValidate(block.content, questions);

  return {
    content,
  };
};

export default useBlockContent;
