import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { completePart } from '../../actions/lessonProgressActions';
import { getLessonPartStatus } from '../../helpers/lessonHelper';
import useCurrentComponentProgress from '../../hooks/useCurrentComponentProgress';
import { updateComponentProgress } from '../../actions/componentProgressActions';
import * as analytics from '../../utils/analytics';
import useCurrentComponent from '../../hooks/useCurrentComponent';
import useCurrentEnrollment from '../../hooks/useCurrentEnrollment';
import { whatWillIComplete } from '../../selectors/overallProgressSelector';

export default function FinishLessonPart({ lessonProgress, lesson, part }) {
  const dispatch = useDispatch();
  const { bootcampId, sectionId, unitId, componentId } = useParams();
  const { willCompleteSection, willCompleteModule } = useSelector(s =>
    whatWillIComplete(s, { bootcampId, sectionId, unitId, componentId }),
  );
  const { bootcamp, section, unit, component } = useCurrentComponent();
  const { cohort } = useCurrentEnrollment();
  const { isComplete, isCurrent, isLast } = getLessonPartStatus(lessonProgress, lesson, part);
  const { componentProgress } = useCurrentComponentProgress();

  const showMarkComplete = lessonProgress && !isComplete && isCurrent;

  const handlePartComplete = async skipComponentUpdate => {
    if (!lessonProgress) return;

    try {
      const lp = await completePart(lessonProgress.id, part.id)(dispatch);
      if (!skipComponentUpdate) {
        dispatch(updateComponentProgress(componentProgress.id, { progress: lp.progress }));
      }
      return true;
    } catch (e) {
      if (e?.meta?.status === 400 && e?.value?.errors?.[0] === 'part completed') {
        window.location.reload();
      }
      // eslint-disable-next-line no-console
      console.error('Error updating lesson/component progress', e);
    }
  };

  const handleLessonComplete = async () => {
    const partCompleteRequestStatus = await handlePartComplete(true);
    if (!partCompleteRequestStatus) return;

    if (!componentProgress) return;

    if (componentProgress.progress < 1) {
      const params = {
        bootcamp,
        cohort,
        section,
        module: unit,
        lesson: component,
      };

      analytics.track('Lesson Complete', analytics.getPageParams(params));
    }

    dispatch(updateComponentProgress(componentProgress.id, { progress: 1 }));
  };

  const getButtonText = () => {
    if (!isLast) return <FormattedMessage id="component.markPartComplete" />;

    if (willCompleteSection) return <FormattedMessage id="nav.finishSection" />;

    if (willCompleteModule) return <FormattedMessage id="nav.finishModule" />;

    return <FormattedMessage id="nav.finishLesson" />;
  };

  if (!showMarkComplete) return '';

  return (
    <button
      type="button"
      onClick={() => (isLast ? handleLessonComplete() : handlePartComplete(false))}
      className="button-standard button-standard--outlined button-standard--long">
      {getButtonText()}
    </button>
  );
}

FinishLessonPart.propTypes = {
  lessonProgress: PropTypes.object,
  lesson: PropTypes.object,
  part: PropTypes.object,
};
