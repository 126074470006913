import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import StaffSidebar from './StaffSidebar';
import { selectCurrentUser } from '../../selectors/authSelectors';

const mapStateToProps = state => {
  return {
    currentUser: selectCurrentUser(state),
  };
};

// 20180720JP: Although routing is not used directly, `withRouter`
//  is necessary to have NavLinks update on route transitions.
//  Could probably be replaced by https://github.com/supasate/connected-react-router
export default withRouter(connect(mapStateToProps, {})(StaffSidebar));
