import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import AuthorBootcampsContainer from './BootcampListContainer';
import CoachBootcampsContainer from '../../Coach/BootcampListContainer';
import PathParent from '../Paths/PathParent';

import NotFound from '../../NotFound';

export default class BootcampParent extends Component {
  render() {
    const { match, isCoach } = this.props;

    return (
      <Switch>
        <Route exact path={`${match.path}`} component={isCoach ? CoachBootcampsContainer : AuthorBootcampsContainer} />

        <Route path={`${match.path}/:bootcampId/paths/:pathId`} component={PathParent} />

        <Route component={NotFound} />
      </Switch>
    );
  }
}
