import React from 'react';
import { FormattedMessage } from 'react-intl';
import AdminWrapper from '../../v2/Nav/Admin/AdminWrapper';
import intl from '../../../utils/intl';
import useLtiConsumers from '../../../hooks/useLtiConsumers';
import LtiConsumerRow from './LtiConsumerRow';
import showModal from '../../../utils/showModal';
import EditLtiConsumerModal from './EditLtiConsumerModal';
import Loading from '../../Utils/Loading';

export default function LtiConsumerList() {
  const { isLoading, ltiConsumers, updateConsumer } = useLtiConsumers();

  const handleCreate = () => {
    showModal(EditLtiConsumerModal, {
      ltiConsumer: {},
      onSave: updateConsumer,
    });
  };

  const renderHeader = () => (
    <div className="user-details user-details-title">
      <div className="grid-row user-details-items clear">
        <div className="md-10 sm-12 user-details-item">
          <div className="user-details-item__title">
            <span className="title-row-label">
              <FormattedMessage id="ltiConsumer.consumerKey" />
            </span>
          </div>
        </div>
        <div className="md-1 md-offset-1 sm-12 user-details-item">
          <div className="user-details-item__title user-details-item__text-center">
            <span className="title-row-label">
              <FormattedMessage id="common.action" />
            </span>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <AdminWrapper headerTitle={intl.formatMessage({ id: 'nav.ltiConsumers' })}>
      <div className="grid-row">
        <div className="sm-3 sm-offset-1">&nbsp;</div>
        <div className="sm-2 sm-offset-5 horizontal-end">
          <button
            type="button"
            className="button-standard button-standard--blue2 button-standard--short"
            onClick={handleCreate}>
            <FormattedMessage id="common.addNew" />
          </button>
        </div>
      </div>
      <div className="grid-row">
        <div className="sm-10 sm-offset-1">
          {renderHeader()}
          {isLoading && <Loading />}
          {ltiConsumers.map(ltiConsumer => (
            <LtiConsumerRow key={ltiConsumer.id} ltiConsumer={ltiConsumer} onSave={updateConsumer} />
          ))}
        </div>
      </div>
    </AdminWrapper>
  );
}
