import qs from 'qs';
import axios from './axios';

export default class User {
  static ADMIN = 'Admin';

  static AUTHOR = 'Author';

  static COACH = 'Coach';

  static EVALUATOR = 'Evaluator';

  static STUDENT = 'Student';

  static ROLES = [User.ADMIN, User.AUTHOR, User.COACH, User.EVALUATOR, User.STUDENT];

  static current() {
    return axios.get('/users/profile/current').then(response => response.data);
  }

  static updateProfile(user) {
    return axios.put('/users/profile', user).then(response => response.data);
  }

  static list(tenantId, params) {
    const query = qs.stringify(params, { arrayFormat: 'brackets' });
    return axios.get(`/users/users?${query}`).then(response => response.data);
  }

  static get(userId) {
    return axios.get(`/users/users/${userId}`).then(response => response.data);
  }

  static save(tenantId, user) {
    if (user.id) {
      return axios.put(`/users/users/${user.id}`, user).then(response => response.data);
    }
    return axios.post('/users/users', user).then(response => response.data);
  }

  static getUsers(ids) {
    return axios
      .get(`/users/users/by_id?${qs.stringify({ ids }, { arrayFormat: 'brackets' })}`)
      .then(response => response.data);
  }

  static getAsanaInviteEmailContent(userId) {
    return axios.get(`/users/users/${userId}/asana_invite_email_content`).then(response => response.data);
  }
}
