import React from 'react';
import PropTypes from 'prop-types';
import intl from '../../utils/intl';
import { ReactComponent as MobileSoftBlockIllustration } from '../../styles/v2/images/lab/illustration-mobile-soft-block.svg';
import { Modal } from 'antd';
import classNames from 'classnames';
import SoftBlock from './SoftBlock';

const modalDefaultProps = {
  footer: null,
  closable: false,
  centered: true,
  zIndex: 9999,
};

const FullScreenModal = ({ className, visible, zIndex, onCancel, ...rest }) => {
  return (
    <Modal
      visible={visible}
      className={classNames('modal-full-screen', className)}
      maskClosable={false}
      width={'100%'}
      footer={null}
      onCancel={onCancel}
      modalDefaultProps={modalDefaultProps}
      zIndex={zIndex}>
      <SoftBlock {...rest} />
    </Modal>
  );
};

FullScreenModal.propTypes = {
  className: PropTypes.string,
  visible: PropTypes.bool,
  ...SoftBlock.propTypes,
};

const getProjectsWorkBestOnDesktopModalProps = () => ({
  illustration: MobileSoftBlockIllustration,
  title: intl.formatMessage({ id: 'tutorial.errorModal.bestOnDesktop.title' }),
  body: intl.formatMessage({ id: 'tutorial.errorModal.bestOnDesktop.body' }),
  primaryButtonText: intl.formatMessage({ id: 'tutorial.errorModal.bestOnDesktop.primaryButton' }),
  secondaryButtonText: intl.formatMessage({ id: 'tutorial.errorModal.bestOnDesktop.secondaryButton' }),
  onPrimaryButton: () => {},
  onSecondaryButton: () => {},
  onCancel: () => {},
});

const SoftBlockModal = props => {
  const [visible, setVisible] = React.useState(true);

  const handleCancel = () => {
    setVisible(false);

    if (props.onCancel) {
      props.onCancel();
    }
  };

  return (
    <div className="soft-block-modal-test">
      <FullScreenModal {...props} testId="soft-block-modal" visible={visible} onCancel={handleCancel} />
    </div>
  );
};

SoftBlockModal.propTypes = {
  illustration: PropTypes.elementType,
  title: PropTypes.string,
  body: PropTypes.string,
  primaryButtonText: PropTypes.string,
  primaryButtonIcon: PropTypes.elementType,
  secondaryButtonIcon: PropTypes.elementType,
  onPrimaryButton: PropTypes.func,
  secondaryButtonText: PropTypes.string,
  onSecondaryButton: PropTypes.func,
  onCancel: PropTypes.func,
};

export { SoftBlockModal, getProjectsWorkBestOnDesktopModalProps };
