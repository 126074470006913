import { SIDE_NAV } from '../actions/constants';

const INITIAL_STATE = {
  collapsed: true,
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case SIDE_NAV.TOGGLE:
      return {
        ...state,
        collapsed: !state.collapsed,
      };

    case SIDE_NAV.CLOSE:
      return {
        ...state,
        collapsed: true,
      };

    default:
      return state;
  }
}
