import React from 'react';
import PropTypes from 'prop-types';
import { LeftOutlined } from '@ant-design/icons';

const BackLink = ({ onHandleClick }) => (
  <div role="link" tabIndex="0" className="back-link" onClick={onHandleClick} onKeyPress={onHandleClick}>
    <LeftOutlined />
  </div>
);

BackLink.propTypes = {
  onHandleClick: PropTypes.func,
};

export default BackLink;
