import PropTypes from 'prop-types';
import React from 'react';
import { Collapse } from 'antd';
import { FilterOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import Filters from './Filters';
import intl from '../../../../utils/intl';
import COHORT_STATUS from '../partial/cohortStatuses';
import { selectPartnerTypes, selectPsSemesters } from '../../../../selectors/cohortLookupSelectors';
import { selectSchools } from '../../../../selectors/schoolSelectors';
import { selectBootcamps } from '../../../../selectors/bootcampSelectors';
import { sortBootcamps } from '../../../../helpers/bootcampHelper';

const CohortFilters = ({ filtersState, setFiltersState }) => {
  const statusOptions = Object.keys(COHORT_STATUS).map(status => ({
    value: COHORT_STATUS[status],
    label: intl.formatMessage({ id: `cohort.status.${COHORT_STATUS[status]}` }),
  }));

  const partnerTypes = useSelector(state => selectPartnerTypes(state));
  const psSemesters = useSelector(state => selectPsSemesters(state));
  const schools = useSelector(selectSchools);
  const bootcamps = useSelector(selectBootcamps);

  const schoolOptions = schools.map(school => ({
    value: school.id,
    label: school.name,
  }));

  const partnerTypeOptions = partnerTypes.map(partnerType => ({
    ...partnerType,
    value: partnerType.id,
    label: partnerType.name,
  }));

  const psSemesterOptions = psSemesters.map(psSemester => ({
    ...psSemester,
    value: psSemester.id,
    label: psSemester.name,
  }));

  const bootcampOptionsObject = {};
  const internalCourseNameOptions = [];

  sortBootcamps(bootcamps).forEach(bootcamp => {
    bootcampOptionsObject[bootcamp.title] = {
      value: bootcamp.title,
      label: bootcamp.title,
    };

    internalCourseNameOptions.push({
      label: bootcamp.title + (bootcamp.version ? ` > ${bootcamp.version}` : ''),
      value: bootcamp.id,
    });
  });

  const bootcampOptions = Object.values(bootcampOptionsObject);

  const COHORT_FILTERS = [
    {
      name: 'bootcamp_name',
      label: intl.formatMessage({ id: 'cohort.form.courseName' }),
      type: 'select',
      multi: true,
      options: bootcampOptions,
    },
    {
      name: 'bootcamp_version',
      label: intl.formatMessage({ id: 'cohort.form.internalCourseName' }),
      type: 'select',
      multi: true,
      options: internalCourseNameOptions,
    },
    {
      name: 'status',
      label: intl.formatMessage({ id: 'cohort.statusLabel' }),
      type: 'select',
      multi: true,
      options: statusOptions,
    },
    {
      name: 'warning',
      label: intl.formatMessage({ id: 'cohort.warning' }),
      type: 'select',
      options: [
        {
          value: true,
          label: intl.formatMessage({ id: 'common.yes' }),
        },
        {
          value: false,
          label: intl.formatMessage({ id: 'common.no' }),
        },
      ],
    },
    {
      name: 'synced',
      label: intl.formatMessage({ id: 'cohort.synced' }),
      type: 'select',
      options: [
        {
          value: true,
          label: intl.formatMessage({ id: 'common.yes' }),
        },
        {
          value: false,
          label: intl.formatMessage({ id: 'common.no' }),
        },
      ],
    },
    {
      name: 'school_id',
      label: intl.formatMessage({ id: 'cohort.form.school' }),
      type: 'select',
      multi: true,
      options: schoolOptions,
    },
    {
      name: 'partner_type_id',
      label: intl.formatMessage({ id: 'cohort.form.partnerType' }),
      type: 'select',
      multi: true,
      options: partnerTypeOptions,
    },
    {
      name: 'ps_semester_id',
      label: intl.formatMessage({ id: 'cohort.form.psSemester' }),
      type: 'select',
      multi: true,
      options: psSemesterOptions,
    },
  ];
  return (
    <Collapse>
      <Collapse.Panel header={intl.formatMessage({ id: 'common.filters' })} key="1" extra={<FilterOutlined />}>
        <Filters settings={COHORT_FILTERS} filtersState={filtersState} setFiltersState={setFiltersState} />
      </Collapse.Panel>
    </Collapse>
  );
};

export default CohortFilters;

CohortFilters.propTypes = {
  filtersState: PropTypes.object.isRequired,
  setFiltersState: PropTypes.func.isRequired,
};
