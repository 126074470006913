import React from 'react';

const ProgressTracker = props => {
  const { progresses: progressBars } = props;

  if (!progressBars || progressBars.length === 0) {
    return null;
  }

  return (
    <div className="progress-tracker">
      {progressBars.map((progress, index) => {
        const { title, percent } = progress;
        const adjustedPercent = Math.min(Math.max(percent, 0), 100);

        let greenBarClass = 'progress-tracker-item-done';
        let trackerBallClass = 'progress-tracker-ball partial-done';
        let percentClass = 'progress-tracker-item-percent';

        if (adjustedPercent === 0) {
          greenBarClass = '';
          trackerBallClass = 'progress-tracker-ball zero-done';
          percentClass = 'progress-tracker-item-percent zero';
        } else if (adjustedPercent === 100) {
          greenBarClass = 'progress-tracker-item-done';
          trackerBallClass = 'progress-tracker-ball all-done';
        }

        return (
          <div key={index} className="progress-tracker-item">
            <div className="progress-tracker-item-title">{title}</div>
            <div
              className={greenBarClass}
              style={{ width: `${adjustedPercent}%` }}
              data-testid={`green-bar-${title}`}></div>
            <div className={trackerBallClass} data-testid={`tracker-ball-${title}`}></div>
            <div
              className="progress-tracker-item-todo"
              style={{ width: `${100 - adjustedPercent}%` }}
              data-testid={`grey-bar-${title}`}></div>
            <div className={percentClass} data-testid={`percent-${title}`}>
              {Math.round(adjustedPercent)} <div className="percent">%</div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default ProgressTracker;
