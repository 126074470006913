import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import * as analytics from '../utils/analytics';

export default function useAnalytics(title, data) {
  const location = useLocation();

  useEffect(() => {
    analytics.setPageArgs(data);
  }, [data]);

  useEffect(() => {
    analytics.pageStart(title);
    return () => {
      analytics.pageEnd();
    };
  }, [title, location]);
}
