import Unit from '../api/unit';
import { updateEntities } from './entityActions';
import { fetchCurrentTenant } from './tenantActions';
import { applicationFetch, applicationFetchAll, applicationApiExecute } from './applicationActions';
import { saveAppSite } from './appSiteActions';
import Tutorial from '../api/tutorial';

export const fetchUnit = applicationFetch(Unit);
export const fetchUnits = applicationFetchAll(Unit);
export const copyUnit = applicationApiExecute(Unit.copy);

export const saveUnit = (unit, sectionId, futureCohortsOnly = true) => async dispatch => {
  // create the custom site if needed
  if (unit.app === Tutorial.CUSTOM_SITE && !unit.app_site_id) {
    const appSite = await saveAppSite(unit.custom_site)(dispatch);
    unit.app_site_id = appSite.id;
  }

  return applicationApiExecute(Unit.save, 'save')(unit, sectionId, futureCohortsOnly)(dispatch);
};

export const reorderUnits = (unitIds, section) => dispatch => {
  const entities = {
    sections: {
      [section.id]: {
        ...section,
        unit_ids: unitIds,
      },
    },
  };
  dispatch(updateEntities({ entities }));

  return fetchCurrentTenant()(dispatch).then(tenant => Unit.reorder(tenant.id, unitIds, section.id));
};
