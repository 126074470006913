import { connect } from 'react-redux';
import { verifyConfirmationToken, confirmAndSetPassword, signIn } from '../../actions/authActions';
import ConfirmUser from './ConfirmUser';
import { selectAuth } from '../../selectors/authSelectors';

const mapStateToProps = (state, ownProps) => {
  const { confirmationToken } = ownProps.match.params;

  return {
    confirmationToken,
    auth: selectAuth(state),
  };
};

export default connect(mapStateToProps, { verifyConfirmationToken, confirmAndSetPassword, signIn })(ConfirmUser);
