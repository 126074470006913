import { map as _map } from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Unit from '../../api/unit';
import ModuleTileContainer from './ModuleTileContainer';
import ProjectTileContainer from './ProjectTileContainer';
import GradedQuizTileContainer from './GradedQuizTileContainer';

export default class UnitTile extends Component {
  handleReorder = (oldPosition, newPosition) => {
    const { section, units, reorderUnits } = this.props;
    const unitIds = _map(units, 'id');
    const removed = unitIds.splice(oldPosition, 1);
    unitIds.splice(newPosition, 0, removed[0]);

    reorderUnits(unitIds, section);
  };

  render() {
    const {
      bootcamp,
      section,
      unit,
      unitProgress,
      currentUnitProgress,
      isEditMode,
      index,
      totalUnits,
      isCurrentSectionSelected,
    } = this.props;

    switch (unit.unit_type) {
      case Unit.PROJECT:
        return (
          <ProjectTileContainer
            bootcamp={bootcamp}
            unit={unit}
            unitProgress={unitProgress}
            section={section}
            isEditMode={isEditMode}
            unitIndex={index}
            totalUnits={totalUnits}
            onUnitsReorder={this.handleReorder}
          />
        );
      case Unit.MODULE:
        return (
          <ModuleTileContainer
            bootcamp={bootcamp}
            unit={unit}
            unitProgress={unitProgress}
            currentUnitProgress={currentUnitProgress}
            section={section}
            isEditMode={isEditMode}
            unitIndex={index}
            totalUnits={totalUnits}
            onUnitsReorder={this.handleReorder}
            isCurrentSectionSelected={isCurrentSectionSelected}
          />
        );
      case Unit.GRADED_QUIZ:
        return (
          <GradedQuizTileContainer
            bootcamp={bootcamp}
            unit={unit}
            unitProgress={unitProgress}
            section={section}
            isEditMode={isEditMode}
            unitIndex={index}
            totalUnits={totalUnits}
            onUnitsReorder={this.handleReorder}
          />
        );
    }
  }
}

UnitTile.propTypes = {
  unit: PropTypes.object.isRequired,
  section: PropTypes.object.isRequired,
  unitProgress: PropTypes.object,
  currentUnitProgress: PropTypes.object,
  index: PropTypes.number.isRequired,
  totalUnits: PropTypes.number.isRequired,
  bootcamp: PropTypes.object.isRequired,
  isEditMode: PropTypes.bool,
  isCurrentSectionSelected: PropTypes.bool,
  units: PropTypes.array,
  reorderUnits: PropTypes.func.isRequired,
};
