import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import Modal2 from '../../../library/Modal2';
import intl from '../../../../utils/intl';
import Errors from '../../../Utils/Errors';
import Loading from '../../../Utils/Loading';
import BootcampSelector from '../../../Bootcamp/BootcampSelector';
import { toastSuccess, toastError } from '../../../../utils/toastHelper';
import Bootcamp from './Bootcamp';
import { confirmInput } from '../../../../utils/confirmInput';

export default class SelectLessonModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      errors: [],
      isLoading: false,
      bootcamp: this.props.bootcamp,
    };
  }

  handleMove = part => {
    confirmInput({
      message: intl.formatMessage({ id: `${this.props.messagePrefixId}.confirm` }),
      onOk: () => {
        const toastId = toast(intl.formatMessage({ id: `${this.props.messagePrefixId}.saving` }), {
          type: toast.TYPE.INFO,
          autoClose: false,
        });
        const { block, onHide } = this.props;
        this.props
          .onSelect(block.id, part.id)
          .then(() => {
            toast.dismiss(toastId);
            toastSuccess(intl.formatMessage({ id: `${this.props.messagePrefixId}.success` }));
            onHide();
          })
          .catch(err => {
            // eslint-disable-next-line no-console
            console.log('Error moving lesson', err);
            toast.dismiss(toastId);
            toastError(
              (err && err.value && err.value.errors) ||
                intl.formatMessage({ id: `${this.props.messagePrefixId}.failure` }),
            );
            throw err;
          });
      },
    });
  };

  handleBootcampSelect = bootcamp => {
    this.setState({ bootcamp, isLoading: true });
    if (bootcamp) {
      this.props.fetchBootcamp(bootcamp.id).then(() => this.setState({ isLoading: false }));
    }
  };

  render() {
    const { onHide, block } = this.props;
    const { errors, isLoading, bootcamp } = this.state;

    return (
      <Modal2
        size="big"
        className="move-block-modal"
        onHide={onHide}
        title={intl.formatMessage({ id: `${this.props.messagePrefixId}.heading` })}>
        <Errors errors={errors} />

        <div className="content">
          <BootcampSelector selected={bootcamp} onSelect={this.handleBootcampSelect} />
          {isLoading ? <Loading /> : <Bootcamp block={block} onSelect={this.handleMove} bootcamp={bootcamp} />}
        </div>
      </Modal2>
    );
  }
}

SelectLessonModal.propTypes = {
  bootcamp: PropTypes.object.isRequired,
  block: PropTypes.object.isRequired,
  onHide: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  fetchBootcamp: PropTypes.func.isRequired,
  messagePrefixId: PropTypes.string.isRequired,
};
