import React, { Component } from 'react';
import { connect } from 'react-redux';
import { enableEditMode } from '../../actions/contextActions';
import { canAuthor } from '../../helpers/userAuth';
import { selectCurrentUser } from '../../selectors/authSelectors';
import { FormattedMessage } from 'react-intl';
import classnames from 'classnames';
import { selectIsEditMode } from '../../selectors/contextSelectors';

class AuthorEditToggle extends Component {
  enableEditModeIfNeeded = props => {
    const { isEditMode, isAuthor } = props;

    if (isEditMode === null && isAuthor) {
      this.props.enableEditMode(true);
    }
  };

  toggleAuthorEditMode = () => {
    this.props.enableEditMode(!this.props.isEditMode);
  };

  componentDidMount() {
    this.enableEditModeIfNeeded(this.props);
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.enableEditModeIfNeeded(props);
  }

  render() {
    const { isEditMode, isAuthor } = this.props;
    const btnClasses = classnames('toggle', isEditMode ? 'edit' : 'view');

    if (!isAuthor) {
      return null;
    }

    return (
      <div className="edit-mode-toggle">
        <button className={btnClasses} id="editModeToggle" onClick={this.toggleAuthorEditMode}>
          {!isEditMode && <div className="toggle-circle" />}
          <div className="toggle-label">
            {isEditMode ? (
              <FormattedMessage id="common.authorModes.edit" />
            ) : (
              <FormattedMessage id="common.authorModes.view" />
            )}
          </div>
          {isEditMode && <div className="toggle-circle" />}
        </button>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const isEditMode = selectIsEditMode(state);
  const isAuthor = canAuthor(selectCurrentUser(state));

  return {
    isEditMode,
    isAuthor,
  };
};

export default connect(mapStateToProps, { enableEditMode })(AuthorEditToggle);
