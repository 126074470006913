import Icons from '../../../../Utils/Icons';
import { FormattedMessage } from 'react-intl';
import React from 'react';

const NoAssignmentsSubmitted = () => {
  return (
    <div className="submission-card" data-testid="empty-state">
      <div className="assignment-empty-state-container">
        <div className="no-assignments-icon">
          <Icons.Assignments />
        </div>
        <div className="empty-state-text-container">
          <div className="empty-state-text-container__first-line">
            <FormattedMessage id="cohort.noAssignmentsSubmitted" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default NoAssignmentsSubmitted;
