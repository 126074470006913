import Project from '../api/project';
import { fetchCurrentTenant } from './tenantActions';

export const getProjectsForCohort = (cohortId, graded, studentId) => dispatch =>
  fetchCurrentTenant()(dispatch).then(tenant =>
    Project.getProjectsForCohort(tenant.id, cohortId, graded, studentId)
      .then(data => data.value)
      .catch(err => {
        throw err;
      }),
  );

export const getProjectsForUser = (userId, cohortId) => dispatch =>
  fetchCurrentTenant()(dispatch).then(tenant =>
    Project.getProjectsForUser(tenant.id, userId, cohortId)
      .then(data => data.value)
      .catch(err => {
        throw err;
      }),
  );
