import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { loadAppStreamApi } from '../../utils/appStreamAPILoader';
import useLabContext from '../../context/Lab/useLabContext';
import Stream from '../../api/stream';

export default function LabEmbed({ streamingUrl, isAppStream }) {
  const iframeRef = useRef(null);
  const appstreamEmbed = useRef(null);
  const { setIsReady: onReady } = useLabContext();

  // AppStream reports as `Started` but it still takes a few seconds to launch the app
  const LAB_READY_LAUNCH_DELAY = 10000;

  useEffect(() => {
    const loadAppStream = async () => {
      await loadAppStreamApi();

      const appstreamOptions = {
        sessionURL: streamingUrl,
        userInterfaceConfig: {
          [AppStream.Embed.Options.HIDDEN_ELEMENTS]: [
            AppStream.Embed.Elements.FULLSCREEN_BUTTON,
            AppStream.Embed.Elements.END_SESSION_BUTTON,
            AppStream.Embed.Elements.CATALOG_BUTTON,
            AppStream.Embed.Elements.WINDOW_SWITCHER_BUTTON,
            AppStream.Embed.Elements.SETTINGS_BUTTON,
            // AppStream.Embed.Elements.FILES_BUTTON,
            AppStream.Embed.Elements.STREAMING_MODE_BUTTON,
            AppStream.Embed.Elements.SCREEN_RESOLUTION_BUTTON,
            AppStream.Embed.Elements.REGIONAL_SETTINGS_BUTTON,
            'softKeyboardButton',
            'keyboardShortcutsButton',
          ],
        },
      };
      appstreamEmbed.current = new AppStream.Embed('appstream-container', appstreamOptions);
      appstreamEmbed.current.addEventListener(AppStream.Embed.Events.SESSION_STATE_CHANGE, updateSessionStateCallback);
    };

    if (isAppStream && streamingUrl && streamingUrl.length > 0) {
      loadAppStream();
    }
  }, [streamingUrl]);

  const updateSessionStateCallback = data => {
    // AppStream.Embed.SessionStatus is: {0: 'Unknown', 1: 'Reserved', 2: 'Started', 3: 'Disconnected', 4: 'Ended', Unknown: 0,
    // Reserved: 1, Started: 2, Disconnected: 3, Ended: 4}
    // data.sessionStatus would be the text (i.e.: "Started", "Unknown"...etc.).
    if (data.sessionStatus === AppStream.Embed.SessionStatus[AppStream.Embed.SessionStatus.Started]) {
      Stream.sessionStarted();
      if (onReady) {
        window.setTimeout(onReady, LAB_READY_LAUNCH_DELAY);
      }
    }
  };

  const setFrameFocus = () => {
    if (!iframeRef.current) return;

    iframeRef.current.contentWindow.focus();
  };

  const iframeStyle = {
    height: '100%',
    marginTop: 0,
    width: '100%',
    lineHeight: 0,
  };

  if (isAppStream) {
    return <div id="appstream-container" style={iframeStyle} />;
  }

  return (
    <iframe
      ref={iframeRef}
      style={iframeStyle}
      src={streamingUrl}
      title="Embedded Lab Content"
      onMouseOver={setFrameFocus}
      onFocus={setFrameFocus}
      allow="microphone"
    />
  );
}

LabEmbed.propTypes = {
  streamingUrl: PropTypes.string,
  launchIn: PropTypes.string,
  app: PropTypes.string.isRequired,
};
