import { selectEntities, selectAll, selectAllFilterBy, sortBy, reversed } from './applicationSelectors';
import createCachedSelector from 're-reselect';

const selectCommentEntities = selectEntities('comments');
const selectComments = selectAll(selectCommentEntities);

export const selectCommentsForBlock = selectAllFilterBy(selectComments, 'block_id');

export const selectCommentsForBlockAndCohortOrderByNewest = createCachedSelector(
  [reversed(sortBy(selectCommentsForBlock, 'created_at')), (_state, blockId, cohortId) => cohortId],
  (comments, cohortId) => {
    if (!comments) {
      return null;
    }

    return comments.filter(comment => comment.bootcamp_instance_id === cohortId || !comment.bootcamp_instance_id);
  },
)((_state, blockId, cohortId) => `${blockId}:${cohortId}`);
