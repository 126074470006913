import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import CohortListContainer from './Cohorts/CohortListContainer';

export default class Cohorts extends Component {
  render() {
    const { match } = this.props;

    return (
      <Switch>
        <Route exact path={`${match.path}`} component={CohortListContainer} />
      </Switch>
    );
  }
}
