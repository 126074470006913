import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import { DeleteOutlined, PlusCircleOutlined } from '@ant-design/icons';

const RuleViewer = ({ rule, onEdit, onRemove, fieldKeys, orderIndex }) => {
  const { name, description, logicFormula, points } = rule;

  return (
    <div className="rule-text-only-main">
      <div className="rule-label">
        <span className="order-index">{`${orderIndex}.`}</span>
        {name}
        <div className="rule-description">{description}</div>
      </div>
      <div className="rule-label support-text">{logicFormula}</div>
      <div className="rule-label support-text">{points}</div>
      <div className="edit-check-field">
        {onEdit && (
          <Button
            type="primary"
            size="small"
            ghost
            className="edit-check-field-btn"
            onClick={event => {
              event.stopPropagation();
              onEdit(fieldKeys);
            }}>
            <PlusCircleOutlined style={{ fontSize: '16px', verticalAlign: 'initial' }} />
            Edit Rule
          </Button>
        )}
        {onRemove && <DeleteOutlined style={{ color: 'red', marginLeft: '5px' }} onClick={() => onRemove(fieldKeys)} />}
      </div>
    </div>
  );
};

RuleViewer.propTypes = {
  rule: PropTypes.any,
  onEdit: PropTypes.func,
  onRemove: PropTypes.func,
  fieldKeys: PropTypes.any,
  orderIndex: PropTypes.number,
};

export default RuleViewer;
