import createCachedSelector from 're-reselect';

export const selectAutograderRulesets = state => state?.entities?.autograderRulesets;
export const selectAutograderRuleset = (state, rulesetId) => selectAutograderRulesets(state)[rulesetId];
export const selectAutograderRulesetData = createCachedSelector(
  selectAutograderRuleset,
  (_state, rulesetId) => rulesetId,
  (autograderRuleset, rulesetId) => {
    if (!autograderRuleset?.data) {
      return;
    }
    const data = JSON.parse(autograderRuleset.data);
    return { id: rulesetId, ...data };
  },
)((_state, rulesetId) => rulesetId);
