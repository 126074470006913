import React, { Component } from 'react';
import PropTypes from 'prop-types';
import EditQuizQuestionContainer from './EditQuizQuestionContainer';
import { FormattedMessage } from 'react-intl';
import { confirmInput } from '../../../utils/confirmInput';
import classnames from 'classnames';
import Dropdown from '../Components/Dropdown';
import intl from '../../../utils/intl';
import Question from '../../../api/question';
import { NEW_QUESTION } from '../../../helpers/questionHelper';
import { toastError } from '../../../utils/toastHelper';
import Button from './Button';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';

export default class EditQuizQuestions extends Component {
  static propTypes = {
    quiz: PropTypes.object.isRequired,
    questions: PropTypes.array.isRequired,
    destroyQuestion: PropTypes.func.isRequired,
    saveQuestion: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
  }

  currentQuestion = () => this.props.questions[this.props.questionIndex];
  hasNextQuestion = () => this.props.questionIndex < this.props.questions.length - 1;
  hasPreviousQuestion = () => this.props.questionIndex > 0;

  handleAddQuestion = questionType => {
    const newQuestion = NEW_QUESTION[questionType];

    newQuestion.seq_num = this.currentQuestion() ? this.currentQuestion().seq_num + 1 : 1;
    const nextQuestionIndex = this.currentQuestion() ? this.props.questionIndex + 1 : 0;

    this.saveQuestion(newQuestion, nextQuestionIndex);
  };

  saveQuestion = (newQuestion, nextQuestionIndex) => {
    const { quiz, saveQuestion } = this.props;

    const saveQuestionPromise = saveQuestion(newQuestion, quiz.id).then(() =>
      this.props.updateQuestionIndex(nextQuestionIndex),
    );
    this.catchError(saveQuestionPromise);
  };

  catchError = (promise, message = 'Error creating question.') => {
    promise.catch(err => {
      if (err.value) {
        toastError(err.value.errors);
      } else {
        toastError(message);
      }
    });
  };

  handleRemoveQuestion = () => {
    const { questions, questionIndex, updateQuestionIndex } = this.props;

    confirmInput({
      message: <FormattedMessage id="question.confirmDelete" values={{ name: this.currentQuestion().prompt }} />,
      onOk: () => {
        const destroyQuestionPromise = this.props.destroyQuestion(this.currentQuestion()).then(() => {
          updateQuestionIndex(Math.max(0, Math.min(questionIndex, questions.length - 2)));
        });
        this.catchError(destroyQuestionPromise, 'Error removing question.');
      },
    });
  };

  handleStepQuestion = increment => {
    this.props.updateQuestionIndex(this.props.questionIndex + increment);
  };

  handleMoveBack = () => this.moveQuestion(-1);
  handleMoveForward = () => this.moveQuestion(1);

  moveQuestion = by => {
    const { forGradedQuiz } = this.props;

    const currentQuestion = this.currentQuestion();
    let newQuestion;

    // Note: bug or inadverdent change when implementing graded quizzes.
    //   For knowledge checks, the move button was used to move the position of the current question in the quiz.
    //   But for graded quizzes, it's being used to navigate to the next/previous question
    if (forGradedQuiz) {
      const questions = this.props.questions;
      const currentQuestionIndex = questions.findIndex(question => question === currentQuestion);
      newQuestion = questions[currentQuestionIndex + by];
    } else {
      newQuestion = { ...currentQuestion, seq_num: currentQuestion.seq_num + by };
    }
    const nextQuestionIndex = this.props.questionIndex + by;

    this.saveQuestion(newQuestion, nextQuestionIndex);
  };

  renderQuizSteps() {
    return (
      <React.Fragment>
        <div className="steps" aria-labelledby="question-progress">
          <button
            disabled={!this.hasPreviousQuestion()}
            onClick={() => this.handleStepQuestion(-1)}
            className={classnames('steps__arrow', !this.hasPreviousQuestion() && 'disabled')}
            aria-label={intl.formatMessage({ id: 'question.previous' })}>
            <LeftOutlined className="step-icon" />
          </button>

          <button
            disabled={!this.hasNextQuestion()}
            onClick={() => this.handleStepQuestion(1)}
            className={classnames('steps__arrow', !this.hasNextQuestion() && 'disabled')}
            aria-label={intl.formatMessage({ id: 'question.next' })}>
            <RightOutlined className="step-icon" />
          </button>
        </div>
      </React.Fragment>
    );
  }

  render() {
    const { questions, forGradedQuiz } = this.props;
    const question = this.currentQuestion();

    const dropdownButtons = [
      {
        messageId: 'question.addSingleSelect',
        onClick: () => this.handleAddQuestion(Question.SINGLE_SELECT),
      },
      {
        messageId: 'question.addMultiSelect',
        onClick: () => this.handleAddQuestion(Question.MULTI_SELECT),
      },
    ];

    if (!forGradedQuiz) {
      dropdownButtons.push(
        {
          messageId: 'question.addSelfReflection',
          onClick: () => this.handleAddQuestion(Question.SELF_REFLECTION),
        },
        {
          messageId: 'question.addFreeTextAnswer',
          onClick: () => this.handleAddQuestion(Question.FREE_TEXT_ANSWER),
        },
      );
    }

    return (
      <React.Fragment>
        {question && (
          <EditQuizQuestionContainer
            forGradedQuiz={forGradedQuiz}
            question={question}
            key={question.id}
            questionCount={this.renderQuizSteps()}
          />
        )}
        {questions.length === 0 && (
          <div>
            <FormattedMessage id="question.noQuestions" />
          </div>
        )}

        {question && (
          <div className="answer-buttons" style={{ marginTop: 20 }}>
            {this.hasPreviousQuestion() && (
              <Button messageId="quiz.moveBack" onClick={this.handleMoveBack} style={{ marginRight: 20 }} />
            )}
            {this.hasNextQuestion() && <Button messageId="quiz.moveForward" onClick={this.handleMoveForward} />}
          </div>
        )}
        <div className="answer-buttons" style={{ marginTop: 20 }}>
          <Dropdown toggleButtonMessageId="question.add" buttons={dropdownButtons} />

          {questions.length > 0 && (
            <Button messageId="quiz.removeQuestion" onClick={this.handleRemoveQuestion} color="transparent" />
          )}
        </div>
        <div className="answer-buttons" style={{ marginTop: 20 }}>
          {this.renderQuizSteps()}
        </div>
      </React.Fragment>
    );
  }
}
