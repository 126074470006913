import React from 'react';
import PropTypes from 'prop-types';
import { Layout, Menu } from 'antd';
import { truncatedCopy } from '../../../../helpers/copyHelper';

const AssignmentsMenu = ({ menuItems, selectedAssignmentId, onAssignmentSelect }) => {
  const selectTab = e => {
    onAssignmentSelect(e.key);
  };

  const options = menuItems.map(menuItem => {
    const ungradedCount = menuItem.ungradedCount;
    return (
      <Menu.Item key={menuItem.assignmentId}>
        <div className="cohort-dashboard-menu-item__title" data-testid="assignment-title">
          {truncatedCopy(menuItem.title, 76)}
        </div>
        {ungradedCount > 0 && (
          <div className="cohort-dashboard-menu-item__ungraded-count" data-testid="assignment-ungraded-count">
            <div className="blue-box"></div>
            <div className="ungraded-count">{ungradedCount}</div>
          </div>
        )}
      </Menu.Item>
    );
  });

  return (
    <Layout.Sider className="cohort-dashboard-list-sidebar" width={400}>
      <Menu
        className="cohort-dashboard-sidebar-menu"
        onClick={selectTab}
        selectedKeys={[selectedAssignmentId]}
        mode="vertical">
        {options}
      </Menu>
    </Layout.Sider>
  );
};

AssignmentsMenu.propTypes = {
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      assignmentId: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      ungradedCount: PropTypes.number.isRequired,
    }),
  ).isRequired,
  selectedAssignmentId: PropTypes.string.isRequired,
  onAssignmentSelect: PropTypes.func.isRequired,
};

export default AssignmentsMenu;
