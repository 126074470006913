import { useSelector } from 'react-redux';
import { selectBootcamp, selectBootcampPaths } from '../selectors/bootcampSelectors';
import { selectPathSections } from '../selectors/pathSelectors';

export default function useBootcamp(bootcampId) {
  const bootcamp = useSelector(s => (bootcampId ? selectBootcamp(s, bootcampId) : null));
  const path = useSelector(s => (bootcampId ? selectBootcampPaths(s, bootcampId) : []))[0];
  const sections = useSelector(s => (path ? selectPathSections(s, path.id) : []));

  return {
    bootcamp,
    path,
    sections,
  };
}
