import React, { useState, useEffect } from 'react';
import CourseProgress from './CourseProgress';
import GradesTable from './GradesTable';
import Loading from '../../Utils/Loading';
import PropTypes from 'prop-types';

const BootcampGrades = ({ bootcamp, fetchGradedItems, matchUrl, cohortId, hideDueDates }) => {
  const [hasLoaded, setHasLoaded] = useState(false);

  const [gradedItems, setGradedItems] = useState([]);
  const [completedGradedItemsCount, setCompletedGradedItemsCount] = useState([]);
  const [percentageComplete, setPercentageComplete] = useState(0);

  const getData = async () => {
    const gradedItems = await fetchGradedItems(cohortId, bootcamp.id);
    const completedGradedItems = gradedItems.data.filter(item => {
      return item.completed_at !== undefined && item.completed_at !== null;
    });

    setCompletedGradedItemsCount(completedGradedItems.length);
    setGradedItems(gradedItems);
    if (gradedItems.data.length === 0) {
      setPercentageComplete(0);
    } else {
      setPercentageComplete(Math.round((completedGradedItemsCount / gradedItems.data.length) * 100));
    }

    setHasLoaded(true);
  };

  useEffect(() => {
    getData();
  }, [bootcamp, cohortId]);

  return (
    <div>
      {!hasLoaded ? (
        <Loading />
      ) : (
        <div className="bootcamp-grades" data-testid="bootcamp-grades">
          <CourseProgress
            percentageComplete={percentageComplete}
            message={'ASSIGNMENT PROGRESS:'}
            numerator={completedGradedItemsCount}
            denominator={gradedItems.data.length}
          />
          <GradesTable
            gradedItems={gradedItems.data}
            bootcamp={bootcamp}
            matchUrl={matchUrl}
            hideDueDates={hideDueDates}
          />
        </div>
      )}
    </div>
  );
};

BootcampGrades.propTypes = {
  bootcamp: PropTypes.object.isRequired,
  fetchGradedItems: PropTypes.func.isRequired,
  matchUrl: PropTypes.string.isRequired,
  cohortId: PropTypes.string.isRequired,
};

export default BootcampGrades;
