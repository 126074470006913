import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import ActionButtons from '../../Utils/ActionButtons';
import showModal from '../../../utils/showModal';
import EditStepModal from './EditStepModal';
import { confirmInput } from '../../../utils/confirmInput';
import { saveStep, destroyStep } from '../../../actions/stepActions';
import { shouldUseDefaultTitle } from './stepHeaderHelper';
import intl from '../../../utils/intl';

const StepHeader = ({ step, stepIndex, totalSteps, isEditMode, tutorial }) => {
  const dispatch = useDispatch();

  let stepCount = '';
  if (isEditMode || totalSteps > 1) {
    stepCount = (
      <span className="lab-exercise__subtitle">
        <FormattedMessage id="tutorial.sideBar.stepCount" values={{ current: stepIndex + 1, total: totalSteps }} />
      </span>
    );
  }

  const handleEdit = () => {
    showModal(EditStepModal, {
      tutorial,
      step,
      onSave: stepToSave => dispatch(saveStep(stepToSave)),
      hideFile: true,
    });
  };

  const handleDelete = () => {
    const { title } = step;
    confirmInput({
      message: <FormattedMessage id="step.confirmDelete" values={{ name: title, name_exists: !!title }} />,
      onOk: () => {
        dispatch(destroyStep(step));
      },
    });
  };

  return (
    <header className="lab-exercise__header">
      {stepCount}

      <h3 className="lab-exercise__title">
        {step && (
          <>
            {shouldUseDefaultTitle(step.title, totalSteps, isEditMode)
              ? intl.formatMessage({ id: 'step.defaultTitle' })
              : step.title}
            <ActionButtons
              className="float-right"
              isEditMode={isEditMode}
              onEdit={handleEdit}
              onDelete={handleDelete}
            />
            <div className="clearfix" />
          </>
        )}
      </h3>
    </header>
  );
};

StepHeader.propTypes = {
  tutorial: PropTypes.object,
  step: PropTypes.object,
  stepIndex: PropTypes.number,
  totalSteps: PropTypes.number,
  isEditMode: PropTypes.bool,
};

export default StepHeader;
