import React from 'react';
import PropTypes from 'prop-types';
import UserPicture from '../Utils/UserPicture';
import CircularProgress from '../Utils/CircularProgress';
import GradeCohortAssignmentsContainer from './Grading/GradeCohortAssignmentsContainer';
import BackLink from '../Utils/BackLink';

const RosterContent = ({ student, cohort }) => {
  if (!student) {
    return '';
  }

  return (
    <div className="student-roster-content">
      <div className="student-roster-content__header">
        <div className="student-roster-content__go-back-wrapper">
          <BackLink className="go-back-wrapper---gray   go-back-wrapper---background-" goBack />
        </div>
        <div className="student-roster-content__content">
          <div className="student-roster-content__avatar">
            <div className="student-roster-content-avatar-img-wrapper">
              <UserPicture className="student-roster-content__avatar-img" user={student.student} />
            </div>
            <div className="student-roster-content__progress">
              <CircularProgress progress={student.progress || 0.0001} />
            </div>
          </div>

          <h1 className="student-roster-content__name">{student.student.name}</h1>
        </div>
      </div>

      <div className="student-roster-content__tabs">
        <GradeCohortAssignmentsContainer cohort={cohort} student={student.student} />
      </div>
    </div>
  );
};

RosterContent.propTypes = {
  student: PropTypes.object,
  cohort: PropTypes.object,
};

export default RosterContent;
