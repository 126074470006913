import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Button } from 'antd';
import Tutorial from '../../api/tutorial';

const supportsDownload = app => [Tutorial.MYSQL, Tutorial.TABLEAU, Tutorial.GSUITE].includes(app);

export function DownloadSubmission(props) {
  const { unit, assignmentProgress } = props;
  const app = unit && unit.app;
  const projectUrl = assignmentProgress && assignmentProgress.user_project_url;

  if (!supportsDownload(app) || !projectUrl) {
    return null;
  }

  const buttonConfig =
    app === Tutorial.GSUITE
      ? { extraProps: { href: projectUrl, target: '_blank' }, textKey: 'grading.openSubmission' }
      : {
          extraProps: { href: `/rte/content/${projectUrl}?type=user-data`, download: true },
          textKey: 'grading.downloadSubmission',
        };

  return (
    <a className="download-submission-container" {...buttonConfig.extraProps}>
      <Button>
        <FormattedMessage id={buttonConfig.textKey} />
      </Button>
    </a>
  );
}

DownloadSubmission.propTypes = {
  unit: PropTypes.shape({ app: PropTypes.string }),
  assignmentProgress: PropTypes.shape({ user_project_url: PropTypes.string }),
};
