import React from 'react';
import PropTypes from 'prop-types';
import { getEmbedUrl } from '../../helpers/embedHelper';

const embeddedContent = props => {
  const width = props.block.width || '100%';
  const height = props.block.height || '420';
  return (
    <div className="media-content-wrapper">
      <div className="media-content">
        <div className="media-content__element">
          {props.block.url && (
            <iframe
              frameBorder="0"
              sandbox="allow-scripts allow-popups allow-forms allow-same-origin"
              allow="microphone"
              allowFullScreen
              src={getEmbedUrl(props.block.url)}
              width={width}
              height={height}
              title={props.block.title || 'Embedded External Content'}
            />
          )}
        </div>
      </div>
    </div>
  );
};

embeddedContent.propTypes = {
  block: PropTypes.object.isRequired,
};

export default embeddedContent;
