import { TENANT } from './constants';
import Tenant from '../api/tenant';

const promises = {};

/**
 * Fetch the current tenant info
 */
export const fetchCurrentTenant = () => async dispatch => {
  if (promises.fetchCurrentTenant) {
    return promises.fetchCurrentTenant;
  }

  const tenant = await (promises.fetchCurrentTenant = Tenant.current());
  dispatch(setCurrent(tenant));

  return tenant;
};

export const clearPromise = () => delete promises.fetchCurrentTenant;

/**
 * Set the current tenant in Redux store
 * @param tenant - Tenant
 * @returns {{type: string, payload: *}}
 */
export const setCurrent = tenant => ({
  type: TENANT.SET_CURRENT,
  payload: tenant,
});
