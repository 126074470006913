import React from 'react';
import PropTypes from 'prop-types';
import { meanBy as _meanBy } from 'lodash';

import MetricCard from './MetricCard';
import Icons from '../../../../../Utils/Icons';
import intl from '../../../../../../utils/intl';

const AvgAssignmentGradeCard = ({ assignmentProgresses }) => {
  const gradedAssignmentProgresses = assignmentProgresses.filter(assignmentProgress => assignmentProgress.graded_at);

  const averageAssignmentGradeText =
    gradedAssignmentProgresses.length > 0
      ? `${Math.floor(_meanBy(gradedAssignmentProgresses, 'score'))}%`
      : intl.formatMessage({ id: 'cohortDashboard.studentsPage.notAvailable' });

  const metric = {
    icon: <Icons.GraduationCap />,
    metricKey: intl.formatMessage({ id: 'cohortDashboard.studentsPage.averageAssignmentGrade' }),
    metricValue: averageAssignmentGradeText,
  };

  return <MetricCard {...metric} />;
};

AvgAssignmentGradeCard.propTypes = {
  assignmentProgresses: PropTypes.array.isRequired,
};

export default AvgAssignmentGradeCard;
