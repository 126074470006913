import React, { useState, useEffect } from 'react';
import Loading from '../../Utils/Loading';
import PropTypes from 'prop-types';

const FinalGrade = ({ gradedItems, hideDueDates }) => {
  const [calculatingGrade, setCalculatingGrade] = useState(true);
  const [finalGrade, setFinalGrade] = useState('--');
  const [totalWeight, setTotalWeight] = useState(0);
  let incompleteScore = false;

  useEffect(() => {
    let totalWeight = 0;
    let totalPoints = 0;
    gradedItems.forEach(item => {
      totalWeight += Number(item.weight);
    });
    setTotalWeight(totalWeight);

    gradedItems.map(item => {
      if (item.completed_at !== undefined && item.completed_at !== null) {
        const itemScore = item.score / item.total_gradable_points;
        totalPoints += itemScore * Number(item.weight);
      } else {
        incompleteScore = true;
      }
    });
    if (!incompleteScore) {
      const totalPointsRounded = totalPoints % 1 === 0 ? totalPoints : totalPoints.toFixed(2);
      setFinalGrade(`${totalPointsRounded} %`);
    }
    setCalculatingGrade(false);
  }, [gradedItems]);

  return (
    <tr data-testid="final-grade">
      <td className="final">Final Grade</td>
      <td></td>
      <td></td>
      <td>{totalWeight}%</td>
      <td>{calculatingGrade ? <Loading /> : finalGrade}</td>
      <td></td>
      {!hideDueDates && <td></td>}
    </tr>
  );
};

FinalGrade.propTypes = {
  gradedItems: PropTypes.array.isRequired,
};

export default FinalGrade;
