import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ContentWrapperContainer from '../Nav/ContentWrapperContainer';
import * as analytics from '../../utils/analytics';
import Loading from '../Utils/Loading';
import { FormattedMessage } from 'react-intl';
import Description from '../Utils/Description';
import LtiSectionTileContainer from './LtiSectionTileContainer';
import Errors from '../Utils/Errors';
import { getContentItemReturnUrl, getDataParam, getOauthConsumerKey } from '../../helpers/ltiParams';
import { getPlainText } from '../../utils/plainText';
import Lti from '../../api/lti';

export default class LtiAssignmentSelector extends Component {
  static propTypes = {
    cohortId: PropTypes.string.isRequired,
    cohort: PropTypes.object,
    bootcamp: PropTypes.object,
    path: PropTypes.object,
    sections: PropTypes.array,
    isMultiSelect: PropTypes.bool,
    fetchCohort: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      selectedAssignments: [],
      errors: [],
      ltiPostParams: {},
    };
  }

  componentDidMount() {
    const { bootcamp, cohortId, fetchCohort } = this.props;
    if (!bootcamp) {
      fetchCohort(cohortId);
    }

    analytics.pageStart('Select Lti Assignments');
  }

  componentWillUnmount() {
    analytics.pageEnd();
  }

  handleSelect = (assignment, isSelected) => {
    let { selectedAssignments } = this.state;
    const { isMultiSelect } = this.props;
    if (isSelected) {
      selectedAssignments = selectedAssignments.concat([assignment]);
    } else {
      selectedAssignments = selectedAssignments.filter(a => a.id !== assignment.id);
    }
    this.setState({ selectedAssignments }, () => {
      if (!isMultiSelect) {
        this.handleSubmit();
      }
    });
  };

  submitWithSignedParams = () => {
    const { bootcamp } = this.props;
    const payload = {
      tenant_id: bootcamp.tenant_id,
      consumer_key: getOauthConsumerKey(),
      return_url: getContentItemReturnUrl(),
      lti_params: {
        lti_message_type: 'ContentItemSelection',
        lti_version: 'LTI-1p0',
        content_items: this.getContentItems(),
        data: getDataParam(),
      },
    };

    Lti.signParams(payload)
      .then(response => {
        this.setState({ ltiPostParams: response.value }, () => {
          document.getElementById('ltiForm').submit();
        });
      })
      .catch(err => {
        console.log('Error signing params', err);
        this.setState({
          errors: ['Unknown error occurred.'],
        });
      });
  };

  handleSubmit = () => {
    if (this.state.selectedAssignments.length === 0) {
      this.setState({
        errors: [<FormattedMessage id="lti.messages.assignmentRequired" />],
      });
      return;
    }
    this.setState({ errors: [] });
    this.submitWithSignedParams();
  };

  getContentItems = () => {
    const { cohort } = this.props;
    const { selectedAssignments } = this.state;
    const location = window.location;
    const launchUrl = `${location.protocol}//${location.host}/users/lti?cohort_id=${cohort.id}`;
    const items = selectedAssignments.map(assignment => ({
      '@type': 'LtiLinkItem',
      '@id': assignment.id,
      url: `${launchUrl}&assignment_id=${assignment.assignment_id}`,
      title: assignment.title,
      text: getPlainText(assignment.description),
      mediaType: 'application/vnd.ims.lti.v1.ltilink',
      custom: {
        cohort_id: cohort.id,
      },
      placementAdvice: {
        presentationDocumentTarget: 'window',
        windowTarget: '_blank',
      },
      lineItem: {
        '@type': 'LineItem',
        label: assignment.title,
        reportingMethod: 'res:totalScore',
        assignedActivity: {
          '@id': `${launchUrl}&assignment_id=${assignment.assignment_id}`,
          activityId: assignment.id,
        },
        scoreConstraints: {
          '@type': 'NumericLimits',
          normalMaximum: 100,
          extraCreditMaximum: 0,
          totalMaximum: 100,
        },
      },
    }));

    const contentItems = {
      '@context': 'http://purl.imsglobal.org/ctx/lti/v1/ContentItem',
      '@graph': items,
    };

    return JSON.stringify(contentItems);
  };

  render() {
    const { bootcamp, sections, isMultiSelect } = this.props;
    const { errors, ltiPostParams } = this.state;

    if (!bootcamp) {
      return (
        <ContentWrapperContainer hideStaffNav className="learning-path">
          <Loading />
        </ContentWrapperContainer>
      );
    }

    return (
      <ContentWrapperContainer hideStaffNav className="learning-path">
        <div className="learning-path-hero">
          <div className="text">
            <span className="category">
              <FormattedMessage id="nav.syllabus" />
            </span>
            <h1>{bootcamp.title}</h1>
            <Description item={bootcamp} />
          </div>
        </div>

        <ol className="learning-path-list assignment-selector">
          {sections.map((section, index) => (
            <LtiSectionTileContainer
              section={section}
              index={index}
              key={section.id}
              isMultiSelect={isMultiSelect}
              onSelect={this.handleSelect}
            />
          ))}
        </ol>

        <div className="learning-path-list assignment-selector">
          <Errors errors={errors} />
          {isMultiSelect && (
            <button type="button" className="authoring-button" onClick={this.handleSubmit}>
              <FormattedMessage id="common.submit" />
            </button>
          )}
        </div>

        <form id="ltiForm" action={getContentItemReturnUrl()} method="POST" encType="application/x-www-form-urlencoded">
          <input type="hidden" name="lti_message_type" value="ContentItemSelection" />
          <input type="hidden" name="lti_version" value="LTI-1p0" />
          <input type="hidden" name="content_items" value={this.getContentItems()} />
          <input type="hidden" name="data" value={getDataParam()} />
          <input type="hidden" name="oauth_nonce" value={ltiPostParams.oauth_nonce} />
          <input type="hidden" name="oauth_signature_method" value={ltiPostParams.oauth_signature_method} />
          <input type="hidden" name="oauth_timestamp" value={ltiPostParams.oauth_timestamp} />
          <input type="hidden" name="oauth_version" value={ltiPostParams.oauth_version} />
          <input type="hidden" name="oauth_consumer_key" value={ltiPostParams.oauth_consumer_key} />
          <input type="hidden" name="oauth_signature" value={ltiPostParams.oauth_signature} />
        </form>
      </ContentWrapperContainer>
    );
  }
}
