import axios from './axios';

export default class CohortReport {
  static async students(cohortId) {
    const response = await axios.get('/analytics/reports/cohort_students', {
      params: { bootcamp_instance_id: cohortId },
    });
    return response.data;
  }

  static async assignments(cohortId) {
    const response = await axios.get('/analytics/reports/cohort_assignments', {
      params: { bootcamp_instance_id: cohortId },
    });
    return response.data;
  }
}
