export default [
  {
    value: 'worksheet',
    label: 'Worksheets',
    children: [
      {
        value: 'columns',
        label: 'Column',
        children: [
          {
            value: 'cell',
            label: 'Cell',
          },
          {
            value: 'rowValue',
            label: 'Row Value',
            children: [
              {
                value: 'columns2',
                label: 'Column',
              },
            ],
          },
        ],
      },
      {
        value: 'rows',
        label: 'Rows',
      },
      {
        value: 'cellRange',
        label: 'Cell Range',
      },
      {
        value: 'cell',
        label: 'Cell',
      },
      {
        value: 'chart',
        label: 'Chart',
        children: [
          {
            value: 'chartData',
            label: 'Chart Data',
          },
        ],
      },
      {
        value: 'pivotTable',
        label: 'Pivot Table',
        children: [
          {
            value: 'rows',
            label: 'Row',
          },
          {
            value: 'columns',
            label: 'Column',
            children: [
              {
                value: 'rowValue',
                label: 'Row Value',
                children: [
                  {
                    value: 'columns2',
                    label: 'Column',
                  },
                ],
              },
            ],
          },
          {
            value: 'value',
            label: 'Value',
          },
          {
            value: 'filter',
            label: 'Filter',
          },
        ],
      },
    ],
  },
];
