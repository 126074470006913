import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { selectAssignment } from '../../../../../selectors/assignmentSelectors';
import { selectBootcampInstanceAssignmentByCompositeKey } from '../../../../../selectors/bootcampInstanceAssignmentSelectors';
import EditDueDateModal from '../../../../Coach/Grading/EditDueDateModal';
import {
  saveBootcampInstanceAssignment,
  updateBootcampInstanceAssignment,
} from '../../../../../actions/bootcampInstanceAssignmentActions';
import ViewRubricButton from '../components/ViewRubricButton';
import ViewAsStudentButton from '../components/ViewAsStudentButton';

const useStore = (assignmentId, cohortId) => {
  const assignment = useSelector(state => selectAssignment(state, assignmentId));
  const bootcampInstanceAssignment = useSelector(
    selectBootcampInstanceAssignmentByCompositeKey(assignmentId, cohortId),
  );

  return {
    assignment,
    bootcampInstanceAssignment,
  };
};

const SubmissionsHeader = ({ assignmentId, cohortId }) => {
  const { assignment, bootcampInstanceAssignment } = useStore(assignmentId, cohortId);
  const dispatch = useDispatch();

  const assignmentTitle = assignment?.title;

  const handleOk = dueDate => {
    if (bootcampInstanceAssignment) {
      updateBootcampInstanceAssignment({
        id: bootcampInstanceAssignment.id,
        assignment_due_at: dueDate,
      })(dispatch);
    } else {
      saveBootcampInstanceAssignment({
        bootcamp_instance_id: cohortId,
        assignment_id: assignmentId,
        assignment_due_at: dueDate,
      })(dispatch);
    }
  };

  return (
    <div className="submissions-header" data-testid="submissions-header">
      <div className="assignment-info">
        <span className="assignment-title">{assignmentTitle}</span>
        <div className="assignment-due">
          <EditDueDateModal bootcampInstanceAssignment={bootcampInstanceAssignment} handleOk={handleOk} />
        </div>
      </div>

      <div className="assignment-actions">
        {assignment && (
          <div className="assignment-action-button">
            <ViewRubricButton assignment={assignment} />
            <ViewAsStudentButton assignment={assignment} />
          </div>
        )}
      </div>
    </div>
  );
};

SubmissionsHeader.propTypes = {
  assignmentId: PropTypes.string.isRequired,
  cohortId: PropTypes.string.isRequired,
};

export default SubmissionsHeader;
