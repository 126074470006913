import React from 'react';
import PropTypes from 'prop-types';
import intl from '../../utils/intl';
import { ReactComponent as SvgLogoOnLight } from '../../styles/pathstream/svg-jsx/logo-onlight-v2.svg';
import { ReactComponent as IllustrationMobileSoftBlock } from '../../styles/v2/images/lab/illustration-mobile-soft-block.svg';
import { ReactComponent as SvgArrowBackBig } from '../../styles/v2/svg-jsx/arrow-back-big.svg';

const SoftBlock = ({
  testId,
  illustration: Illustration,
  title,
  body,
  primaryButtonText,
  onPrimaryButton,
  secondaryButtonText,
  onSecondaryButton,
  primaryButtonIcon: PrimaryButtonIcon,
  secondaryButtonIcon: SecondaryButtonIcon,
}) => {
  return (
    <div className="soft-block" data-testid={testId} style={{ textAlign: 'center' }}>
      <div className="illustration">
        <Illustration />
      </div>

      <div className="content">
        <div className="title" data-testid="title">
          {title}
        </div>

        {body && (
          <div className="body" data-testid="body">
            {body}
          </div>
        )}

        <div className="buttons">
          {primaryButtonText && (
            <button
              className="button-standard button-standard--blue-9 button--primary"
              type="button"
              onClick={onPrimaryButton}>
              {PrimaryButtonIcon && <PrimaryButtonIcon />}
              {primaryButtonText}
            </button>
          )}

          {secondaryButtonText && (
            <button
              className="button-standard button-standard--blue-9 button--secondary"
              type="button"
              onClick={onSecondaryButton}>
              {SecondaryButtonIcon && <SecondaryButtonIcon />}
              {secondaryButtonText}
            </button>
          )}
        </div>
      </div>

      <div className="company-logo">
        <SvgLogoOnLight />
      </div>
    </div>
  );
};

SoftBlock.propTypes = {
  illustration: PropTypes.elementType,
  title: PropTypes.string,
  body: PropTypes.string,
  primaryButtonText: PropTypes.string,
  primaryButtonIcon: PropTypes.elementType,
  secondaryButtonIcon: PropTypes.elementType,
  onPrimaryButton: PropTypes.func,
  secondaryButtonText: PropTypes.string,
  onSecondaryButton: PropTypes.func,
  testId: PropTypes.string,
};

SoftBlock.defaultProps = {
  illustration: IllustrationMobileSoftBlock,
  title: intl.formatMessage({ id: 'tutorial.errorModal.bestOnDesktop.title' }),
  body: intl.formatMessage({ id: 'tutorial.errorModal.bestOnDesktop.body' }),
  primaryButtonText: intl.formatMessage({ id: 'tutorial.errorModal.bestOnDesktop.primaryButton' }),
  secondaryButtonText: intl.formatMessage({ id: 'tutorial.errorModal.bestOnDesktop.secondaryButton' }),
  onPrimaryButton: () => window.location.reload(false),
  onSecondaryButton: () => window.location.reload(false),
  primaryButtonIcon: null,
  secondaryButtonIcon: SvgArrowBackBig,
};

export default SoftBlock;
