import { map as _map, isEmpty as _isEmpty } from 'lodash';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Element } from 'react-scroll';
import BlockContainer from '../BlockContainer';
import AddBlock from '../Blocks/AddBlock';
import { reorderBlocks } from '../../../actions/blockActions';
import { fetchLesson } from '../../../actions/lessonActions';
import useLesson from '../../../hooks/useLesson';

export default function StepShow({ step, isEditMode, quizOnLab }) {
  const lessonId = step.lesson_id;
  const { lesson, parts, blocksByPart } = useLesson(lessonId);
  // There should be only one part for a Step
  const blocks = _isEmpty(parts) ? [] : blocksByPart[parts[0].id] || [];
  const dispatch = useDispatch();

  useEffect(() => {
    if (!lesson) {
      dispatch(fetchLesson(lessonId));
    }
  }, [lessonId]);

  if (!lessonId) return null;

  const handleReorder = (oldPosition, newPosition) => {
    const blockIds = _map(blocks, 'id');
    const removed = blockIds.splice(oldPosition, 1);
    blockIds.splice(newPosition, 0, removed[0]);

    dispatch(reorderBlocks(blockIds, lesson, parts[0]));
  };

  if (!lesson || (!isEditMode && blocks.length === 0)) {
    return null;
  }

  return (
    <>
      {_map(blocks, (block, index) => (
        <Element name={`block_${block.id}`} key={block.id}>
          <div>
            <BlockContainer
              lesson={lesson}
              block={block}
              index={index}
              totalBlocks={blocks.length}
              onReorder={handleReorder}
              isEditMode={isEditMode}
              showBlockTakeQuiz
              quizOnLab={quizOnLab}
            />
          </div>

          {isEditMode && <AddBlock lesson={lesson} prevSeqNum={block.seq_num} isLab />}
        </Element>
      ))}

      {isEditMode && _isEmpty(blocks) && <AddBlock lesson={lesson} prevSeqNum={0} isLab />}
    </>
  );
}

StepShow.propTypes = {
  step: PropTypes.object,
  isEditMode: PropTypes.bool,
  quizOnLab: PropTypes.object.isRequired,
};
