import axios from './axios';

export default class BootcampInstanceAssignment {
  static all(_, bootcampInstanceId) {
    return axios
      .get('/assessments/bootcamp_instance_assignments', {
        params: { bootcamp_instance_id: bootcampInstanceId },
      })
      .then(response => response.data);
  }

  static get(_, assignmentId, bootcampInstanceId) {
    return axios
      .get(`/assessments/bootcamp_instance_assignments/${bootcampInstanceId}/${assignmentId}`)
      .then(response => response.data);
  }

  static save(_, bootcampInstanceAssignment) {
    return axios
      .post('/assessments/bootcamp_instance_assignments', { ...bootcampInstanceAssignment })
      .then(response => response.data)
      .catch(error => {
        throw error;
      });
  }

  static update(_, bootcampInstanceAssignment) {
    return axios
      .put(`/assessments/bootcamp_instance_assignments/${bootcampInstanceAssignment.id}`, bootcampInstanceAssignment)
      .then(response => response.data)
      .catch(error => {
        throw error;
      });
  }
}
