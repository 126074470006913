import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import ContentEditable from 'react-contenteditable';

import EditQuizQuestionsContainer from './EditQuizQuestionsContainer';
import QuizTabsContainer from '../../Quiz/QuizTabsContainer';
import QuizLoaderContainer from '../../Quiz/LoaderContainer';
import useSaveBlock from '../../../hooks/useSaveBlock';
import { selectBlock } from '../../../selectors/blockSelectors';
import ContentValidator from '../Blocks/ContentValidator';
import useBlockContent from '../../../hooks/useBlockContent';

const EditQuiz = ({ block: propBlock, blockId, renderBody, forGradedQuiz, QuizTabs, QuizLoader }) => {
  const storeBlock = useSelector(state => selectBlock(state, blockId));
  const block = propBlock || storeBlock;
  const saveBlockProps = useSaveBlock(block);
  const { blockState, handleChange } = saveBlockProps;
  const { content } = useBlockContent(blockState);
  const [questionIndex, setQuestionIndex] = useState(0);

  const updateQuestionIndex = newQuestionIndex => {
    setQuestionIndex(newQuestionIndex);
  };

  const renderTitle = block => {
    return (
      <div className="knowledge-check__header">
        {/* Inline the body of EditBlockTitle here, because we don't want
         * multiple calls to the useSaveBlock hook in this component tree
         * (EditBlockTitle _also_ calls the useSaveBlock hook) */}
        <ContentEditable html={block.title} onChange={handleChange('title', true)} />
      </div>
    );
  };
  return (
    <ContentValidator content={content}>
      <div className="knowledge-check__body">
        <QuizLoader
          block={blockState}
          render={(quiz, questions) =>
            renderBody ? (
              renderBody(quiz, questions)
            ) : (
              /* _Directly_ pass useful helpers from useSaveBlock hook, instead
               * of calling it again inside the child component */
              <QuizTabs
                {...saveBlockProps}
                block={blockState}
                isEditMode
                renderTitle={() => renderTitle(blockState)}
                questionIndex={questionIndex}>
                <EditQuizQuestionsContainer
                  forGradedQuiz={forGradedQuiz}
                  questions={questions}
                  quiz={quiz}
                  key={quiz.id}
                  questionIndex={questionIndex}
                  updateQuestionIndex={updateQuestionIndex}
                />
              </QuizTabs>
            )
          }
        />
      </div>
    </ContentValidator>
  );
};

EditQuiz.propTypes = {
  block: PropTypes.object.isRequired,
  renderBody: PropTypes.func,
  forGradedQuiz: PropTypes.bool,
  QuizTabs: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  QuizLoader: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
};

EditQuiz.defaultProps = {
  QuizTabs: QuizTabsContainer,
  QuizLoader: QuizLoaderContainer,
};

export default EditQuiz;
