import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { fetchLesson } from '../actions/lessonActions';
import useLesson from './useLesson';

/**
 * Fetch the lesson if not already present in redux.
 * Make sure to call this only once in a page preferably in the top level component
 * @param {string} lessonId
 */
export default function useFetchLesson(lessonId) {
  const dispatch = useDispatch();
  const { lesson, parts, blocks, blocksByPart } = useLesson(lessonId);

  useEffect(() => {
    if (!lesson && lessonId) {
      dispatch(fetchLesson(lessonId));
    }
  }, [lessonId]);

  return { lesson, parts, blocks, blocksByPart };
}
