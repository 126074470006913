import UnitProgress from '../api/unitProgress';
import { applicationFetch, applicationUpdate, applicationApiExecute, applicationFetchAll } from './applicationActions';

export const updateUnitProgress = applicationUpdate(UnitProgress);
export const startUnitProgress = applicationApiExecute(UnitProgress.start);
export const gradeUnitProgress = applicationApiExecute(UnitProgress.grade);
export const completeUnitProgress = applicationApiExecute(UnitProgress.complete);
export const fetchUnitProgress = applicationFetch(UnitProgress);
export const allowResubmission = applicationApiExecute(UnitProgress.allowResubmission);
export const fetchUnitProgresses = applicationFetchAll(UnitProgress);
