import React from 'react';
import { FormattedMessage } from 'react-intl';
import useLabContext from '../../context/Lab/useLabContext';
import { KeyboardCode } from '../../utils/keyboardConstant';

export const SidebarIntro = () => {
  const { showIntro } = useLabContext();
  if (!showIntro()) return null;

  return (
    <div className="lab-placeholder">
      <div className="lab-placeholder__wrapper lab-placeholder__wrapper--panel">
        <div className="lab-placeholder__content">
          <h3 className="lab-heading lab-heading--big lab-heading-- lab-heading--none-space }}">
            <FormattedMessage id="labIntro.sidebar.heading" />
          </h3>

          <p className="lab-paragraph lab-paragraph--dense lab-paragraph--none-space lab-paragraph--big">
            <FormattedMessage id="labIntro.sidebar.content" />
          </p>
        </div>
      </div>
    </div>
  );
};

export const LabAreaIntro = () => {
  const { showIntro } = useLabContext();
  if (!showIntro()) return null;

  return (
    <div className="lab-placeholder">
      <div className="lab-placeholder__wrapper lab-placeholder__wrapper--panel">
        <div className="lab-placeholder__content">
          <h3 className="lab-heading lab-heading--big lab-heading-- lab-heading--none-space }}">
            <FormattedMessage id="labIntro.labArea.heading" />
          </h3>

          <p className="lab-paragraph lab-paragraph--dense     lab-paragraph--none-space lab-paragraph--big">
            <FormattedMessage id="labIntro.labArea.content" />
          </p>
        </div>
      </div>
    </div>
  );
};

export const DismissIntro = () => {
  const { markLabIntroSeen, showIntro } = useLabContext();
  if (!showIntro()) return null;

  const handleClick = e => {
    e.preventDefault();
    markLabIntroSeen();
  };

  const handleKeyPress = e => {
    if (e.keyCode === KeyboardCode.ENTER) {
      handleClick(e);
    }
  };

  return (
    <div className="lab-placeholder-panel">
      <div
        role="button"
        className="lab-placeholder-panel__button"
        href="#"
        onClick={handleClick}
        onKeyPress={handleKeyPress}
        tabIndex="0">
        <div className="lab-placeholder-panel__wrapper">
          <span className="lab-placeholder-panel__label">
            <FormattedMessage id="labIntro.dismissPrompt" />
          </span>
        </div>
      </div>
    </div>
  );
};
