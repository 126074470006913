// eslint-disable-next-line no-unused-vars
import { getLocationPath } from './dataSourceHelpers';
import { getDataFromRangeAndEval, sheetNotFound } from './evalHelpers';

const evalCellRange = ({ data, location, property, operator, value }) => {
  const sheet = getLocationPath(data, location);
  if (!sheet) return sheetNotFound(property, operator, value);
  return getDataFromRangeAndEval({
    path: sheet.dataset,
    location,
    property,
    operator,
    value,
  });
};

export default evalCellRange;
