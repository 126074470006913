import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { fetchSqlBlockProgresses } from '../actions/blockProgressActions';

/**
 * Fetch the Sql Block Progresses if not already present in redux.
 * Make sure to call this only once in a page preferably in the top level component
 */
export default function useFetchSqlBlockProgresses(enrollmentId) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchSqlBlockProgresses(enrollmentId));
  }, [enrollmentId]);
}
