import { connect } from 'react-redux';
import Question from './Question';
import { selectProgressForQuestion } from '../../selectors/questionProgressSelectors';
import { submitQuestionProgress } from '../../actions/questionProgressActions';
import { selectCurrentUser } from '../../selectors/authSelectors';
import { canCoach } from '../../helpers/userAuth';

const mapStateToProps = (state, ownProps) => {
  return {
    questionProgress: selectProgressForQuestion(state, ownProps.question),
    canCoach: canCoach(selectCurrentUser(state)),
  };
};

export default connect(mapStateToProps, {
  submitQuestionProgress,
})(Question);
