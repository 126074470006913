import { values as _values } from 'lodash';
import { createSelector } from 'reselect';
import createCachedSelector from 're-reselect';

export const selectTutorialProgressEntities = state => {
  return state.entities.tutorialProgresses;
};

export const selectTutorialProgresses = createSelector([selectTutorialProgressEntities], tutorialProgressEntities => {
  return _values(tutorialProgressEntities);
});

export const selectProgressForTutorial = createCachedSelector(
  [selectTutorialProgressEntities, (_state, tutorialId) => tutorialId],
  (tutorialProgressEntities, tutorialId) => {
    return _values(tutorialProgressEntities).filter(tutorialProgress => tutorialProgress.tutorial_id === tutorialId)[0];
  },
)((_state, tutorialId) => tutorialId);
