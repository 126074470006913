import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Button } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import Select from 'react-select';
import Modal2 from '../../library/Modal2';
import Errors from '../../Utils/Errors';
import intl from '../../../utils/intl';
import { isMysqlApp, isTableauApp } from '../../../helpers/labHelper';
import ProjectDropZone from '../../Utils/ProjectDropZone';
import CustomInput from '../../Utils/CustomInput';
import ProjectVersionChangeModal from '../../Utils/ProjectVersionChangeModal';

export default class ConfigureProjectModal extends Component {
  constructor(props) {
    super(props);

    const { assignment } = props;
    this.state = {
      assignment: {
        ...assignment,
        prerequisite_id: this.isSelectDependentProject() ? assignment.prerequisite_id : null,
        starter_project_url: !this.isSelectDependentProject() ? assignment.starter_project_url : null,
      },
      errors: null,
      showProjectVersionChangeModal: false,
      urlChanged: false,
    };
  }

  handleChange = e => {
    const { assignment } = this.state;
    let target;
    let value;
    let name;
    if (e.target) {
      target = e.target;
      name = target.name;
      value = target.value;
    } else {
      // checkbox
      value = e.value;
      name = 'prerequisite_id';
    }

    this.setState({
      assignment: {
        ...assignment,
        [name]: value,
      },
    });
  };

  handleFileUpload = name => url => {
    this.setState(state => ({
      assignment: {
        ...state.assignment,
        [name]: url,
      },
      urlChanged: url != state.assignment[name],
    }));
  };

  handleLabChangeType = labChangeType => {
    this.setShowProjectVersionChangeModal(false);
    return this.saveAssignment(labChangeType);
  };

  setShowProjectVersionChangeModal = shouldShow => {
    this.setState({ showProjectVersionChangeModal: shouldShow });
  };

  handleSubmit = e => {
    e.preventDefault();

    if (this.isCohortApp(this.props.unit.app) && this.state.urlChanged) {
      this.setShowProjectVersionChangeModal(true);
    } else {
      this.saveAssignment();
    }
  };

  saveAssignment = (labChangeType = null) => {
    const assignmentExtraArgs = !!labChangeType
      ? { lab_change_type: labChangeType, bootcamp_id: this.props.bootcampId }
      : {};
    return this.props
      .saveAssignment({ ...this.state.assignment, ...assignmentExtraArgs })
      .then(() => this.props.onHide())
      .catch(err => {
        this.setState({ errors: err.value.errors });
      });
  };

  isSelectDependentProject = () => {
    const { mode } = this.props;
    return mode === 'selectDependentProject';
  };

  renderNonCohortForm = () => {
    const { projects, unit } = this.props;
    const { assignment } = this.state;

    const options = [
      {
        label: intl.formatMessage({ id: 'project.selectProject' }),
        value: '',
      },
    ];
    projects.forEach(proj => {
      options.push({
        label: proj.title,
        value: proj.assignment_id,
      });
    });

    return (
      <>
        {this.isSelectDependentProject() && (
          <div className="form-item select-field">
            <label htmlFor="prerequisite_id" className="visually-hidden">
              <FormattedMessage id="project.form.prerequisite" />
            </label>

            <Select
              className="Select--simple"
              name="prerequisite_id"
              value={assignment.prerequisite_id || ''}
              onChange={this.handleChange}
              options={options}
              clearable={false}
              searchable={false}
            />
          </div>
        )}
        {!this.isSelectDependentProject() && !isMysqlApp(unit.app) && (
          <CustomInput
            name="starter_project_url"
            onChange={this.handleChange}
            value={assignment.starter_project_url}
            label={intl.formatMessage({ id: 'project.form.starterProjectUrl' })}
          />
        )}

        {!this.isSelectDependentProject() && isMysqlApp(unit.app) && (
          <div>
            <h3>
              <FormattedMessage id="tutorial.heading.starterProject" />
            </h3>
            <ProjectDropZone
              projectUrl={assignment.starter_project_url}
              app={unit.app}
              messageId="tutorial.form.mysql.project"
              onFileUploaded={this.handleFileUpload('starter_project_url')}
            />

            <ProjectDropZone
              projectUrl={assignment.starter_db_url}
              app={unit.app}
              messageId="tutorial.form.mysql.starterDb"
              onFileUploaded={this.handleFileUpload('starter_db_url')}
            />
          </div>
        )}
      </>
    );
  };

  isCohortApp(app) {
    return isTableauApp(app);
  }

  render() {
    const { unit } = this.props;
    const { errors, assignment, showProjectVersionChangeModal } = this.state;
    const isCohortApp = this.isCohortApp(unit.app);

    return (
      <>
        <Modal2
          title={
            this.isSelectDependentProject() ? (
              <FormattedMessage id="project.selectDependentProject" />
            ) : (
              <FormattedMessage id="project.setTemplate" />
            )
          }
          onHide={this.props.onHide}
          onOk={this.handleSubmit}>
          <header>
            <div style={{ textAlign: 'center', fontStyle: 'italic', marginTop: -30, marginBottom: 30 }}>
              {this.isSelectDependentProject() && (
                <div
                  dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'project.dependentProjectNotes' }) }}
                />
              )}
            </div>
          </header>

          <Errors errors={errors} />
          <Button className="file-download-btn" type="primary" role="button" data-testid="download-button">
            <a
              href={`/assessments/assignments/${assignment.id}/starter_project`}
              download={assignment.starter_project_url}>
              <DownloadOutlined />
              <FormattedMessage id="common.view" />
            </a>
          </Button>
          {isCohortApp && (
            <ProjectDropZone
              projectUrl={assignment.starter_project_url}
              app={assignment.app}
              messageId="tutorial.heading.starterProject"
              onFileUploaded={this.handleFileUpload('starter_project_url')}
            />
          )}
          {!isCohortApp && this.renderNonCohortForm()}
          {showProjectVersionChangeModal && (
            <ProjectVersionChangeModal
              onSubmit={this.handleLabChangeType}
              onHide={() => this.setShowProjectVersionChangeModal(false)}
            />
          )}
        </Modal2>
      </>
    );
  }
}

ConfigureProjectModal.propTypes = {
  projects: PropTypes.array,
  unit: PropTypes.object.isRequired,
  assignment: PropTypes.object.isRequired,
  mode: PropTypes.string.isRequired,
  saveAssignment: PropTypes.func.isRequired,
  onHide: PropTypes.func.isRequired,
  saveUnit: PropTypes.func.isRequired,
};
