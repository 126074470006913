import React from 'react';
import PropTypes from 'prop-types';

import MetricCard from './MetricCard';
import Icons from '../../../../../Utils/Icons';
import intl from '../../../../../../utils/intl';

const totalTimeSpentText = seconds => {
  if (!Number.isInteger(seconds) || seconds < 0) {
    return intl.formatMessage({ id: 'cohortDashboard.studentsPage.notAvailable' });
  }

  const hoursSpent = Math.floor(seconds / 3600);
  const secondsLeft = seconds % 3600;
  const minutesSpent = secondsLeft > 0 ? Math.floor(secondsLeft / 60) : 0;

  return intl.formatMessage(
    { id: 'cohortDashboard.studentsPage.hoursMinutes' },
    { hours: hoursSpent, minutes: minutesSpent },
  );
};

const TotalTimeSpentCard = ({ seconds }) => {
  const metric = {
    icon: <Icons.Time2 />,
    metricKey: intl.formatMessage({ id: 'cohortDashboard.studentsPage.totalTimeSpent' }),
    metricValue: totalTimeSpentText(seconds),
  };

  return <MetricCard {...metric} />;
};

TotalTimeSpentCard.propTypes = {
  seconds: PropTypes.number,
};

export default TotalTimeSpentCard;
