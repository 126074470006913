import moment from 'moment';
import intl from '../../../../../utils/intl';
import { meanBy as _meanBy } from 'lodash';

// No logins in the past 5 days
export const checkLoginCriteria = student => {
  const today = moment(new Date());
  const value = {
    inWatchList: false,
    reason: intl.formatMessage({ id: 'watchListReasons.noLoginInPast5Days' }),
  };

  const noLoginDays = moment(today).diff(moment(student.current_sign_in_at), 'day');
  if (noLoginDays > 5) {
    value.inWatchList = true;
  }
  return value;
};

// Average assignment grade below 70%
export const checkAssignmentAverageGradeCriteria = studentAssignmentProgresses => {
  const value = {
    inWatchList: false,
    reason: intl.formatMessage({ id: 'watchListReasons.lowAverageGrade' }),
  };

  const gradedAssignmentProgresses = studentAssignmentProgresses.filter(
    cohortUnitProgress => cohortUnitProgress.requires_grading && !!cohortUnitProgress.graded_at,
  );
  const assignmentAverage = _meanBy(
    gradedAssignmentProgresses,
    gradedAssignmentProgress => gradedAssignmentProgress.score,
  );
  if (assignmentAverage < 70) {
    value.inWatchList = true;
  }

  return value;
};

// if haven't submitted an assignment where the due date has passed
export const checkAssignmentDueDateCriteria = (studentAssignmentProgresses, bootcampInstanceAssignments) => {
  const today = moment(new Date());
  const value = {
    inWatchList: false,
    reason: intl.formatMessage({ id: 'watchListReasons.behindOnAssignments' }),
  };

  bootcampInstanceAssignments.forEach(bootcampInstanceAssignment => {
    const assignmentProgress = studentAssignmentProgresses.find(
      assignmentProgress => assignmentProgress.assignment_id === bootcampInstanceAssignment.assignment_id,
    );
    if (
      moment(bootcampInstanceAssignment.assignment_due_at) < today &&
      (!assignmentProgress || !assignmentProgress.completed_at)
    ) {
      value.inWatchList = true;
    }
  });

  return value;
};

// if haven't submitted 50% of assignments by 50% through the course (based on start and end date) — and same for 75% and 100% of the course
export const checkCohortProgressCriteria = (cohort, studentAssignmentProgresses, assignmentsCount) => {
  const today = moment(new Date());
  const value = {
    inWatchList: false,
    reason: intl.formatMessage({ id: 'watchListReasons.behindOnAssignments' }),
  };

  const completedAssignmentsCount = studentAssignmentProgresses.filter(
    assignmentProgress => !!assignmentProgress.completed_at,
  ).length;
  const studentProgress = completedAssignmentsCount / assignmentsCount;
  const totalCohortLength = moment(cohort.end_date).diff(moment(cohort.start_date), 'day');
  const passedCohortLength = today.diff(moment(cohort.start_date), 'day');
  const averageCohortPassedTime = (passedCohortLength / totalCohortLength) * 100;

  if (
    (averageCohortPassedTime >= 50 && averageCohortPassedTime < 70 && studentProgress < 0.5) ||
    (averageCohortPassedTime >= 70 && averageCohortPassedTime < 100 && studentProgress < 0.7) ||
    (averageCohortPassedTime >= 100 && studentProgress < 1)
  ) {
    value.inWatchList = true;
  }

  return value;
};
