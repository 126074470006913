import React from 'react';
import PropTypes from 'prop-types';
import { isMysqlApp, isTableauApp } from '../../../helpers/labHelper';
import Dropdown from '../../Author/Components/Dropdown';

const generateLink = url => `/rte/content/${url}?type=user-data`;

const shouldShowDownloadButton = app => isMysqlApp(app) || isTableauApp(app);

const mysqlDownloadButtons = (starter, query) => {
  const buttons = [];
  if (starter) {
    buttons.push({
      messageId: 'tutorial.sideBar.mysql.dbStarterFile',
      onClick: () => window.open(generateLink(starter)),
    });
  }
  if (query) {
    buttons.push({
      messageId: 'tutorial.sideBar.mysql.queryStarterFile',
      onClick: () => window.open(generateLink(query)),
    });
  }
  return buttons;
};

const starterFileDownloadButton = starter => {
  const buttons = [];
  if (starter) {
    buttons.push({
      messageId: 'tutorial.sideBar.downloadStarterFile',
      onClick: () => window.open(generateLink(starter)),
    });
  }
  return buttons;
};

const DownloadButtons = ({ url, mysqlStarterDbUrl, app }) => {
  if (!url && !mysqlStarterDbUrl) return null;
  let buttons;
  if (isMysqlApp(app)) {
    buttons = mysqlDownloadButtons(mysqlStarterDbUrl, url);
  } else {
    buttons = starterFileDownloadButton(url);
  }
  return (
    <div className="lab-exercise__download">
      <Dropdown
        className="full-width small-list"
        toggleButtonMessageId="tutorial.sideBar.downloadFiles"
        buttons={buttons}
      />
    </div>
  );
};

const DownloadProjectStarterFile = ({ assignment }) => {
  if (!assignment || !shouldShowDownloadButton(assignment?.app)) return null;
  const { starter_project_url: url, starter_db_url: starterDbUrl } = assignment;
  return <DownloadButtons url={url} mysqlStarterDbUrl={starterDbUrl} app={assignment.app} />;
};

const DownloadLabStarterFile = ({ step, app }) => {
  if (!step || !shouldShowDownloadButton(app)) return null;
  const { project_url: url, starter_db_url: starterDbUrl } = step;
  return <DownloadButtons url={url} mysqlStarterDbUrl={starterDbUrl} app={app} />;
};

DownloadProjectStarterFile.propTypes = {
  assignment: PropTypes.shape({
    starter_project_url: PropTypes.string,
    starter_db_url: PropTypes.string,
    app: PropTypes.string.isRequired,
  }).isRequired,
};

DownloadLabStarterFile.propTypes = {
  step: PropTypes.shape({
    project_url: PropTypes.string,
    starter_db_url: PropTypes.string,
  }).isRequired,
  app: PropTypes.string.isRequired,
};

export { DownloadProjectStarterFile, DownloadLabStarterFile };
