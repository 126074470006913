import { sortBy as _sortBy, find as _find } from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import AdminWrapper from '../v2/Nav/Admin/AdminWrapper';
import BootcampListItemContainer from '../Bootcamp/BootcampListItemContainer';
import Loading from '../Utils/Loading';
import * as analytics from '../../utils/analytics';
import { setPageTitle } from '../../helpers/pageHelper';
import BootcampSearch from '../Author/Bootcamps/BootcampSearch';
import intl from '../../utils/intl';
import { setCurrentCohortId } from '../../actions/components/coachActions';

export default class BootcampList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
    };
  }

  async componentDidMount() {
    if (!this.state.isLoading) {
      this.setState({ isLoading: true });
      await this.props.fetchCohorts(true);
      this.setState({ isLoading: false });
    }
    analytics.pageStart('Course List - Coach');
    setPageTitle('pageName.course.list');
  }

  componentWillUnmount() {
    analytics.pageEnd();
  }

  cohortBootcamps = () => {
    const { cohorts, bootcamps } = this.props;
    const cohortBootcamps = [];

    cohorts.forEach(cohort => {
      const bootcamp = _find(bootcamps, { id: cohort.bootcamp_id });
      if (!bootcamp) {
        return;
      }
      cohortBootcamps.push({
        bootcamp,
        cohort,
      });
    });

    return _sortBy(cohortBootcamps, ({ bootcamp, cohort }) => bootcamp.title.toLowerCase() + cohort.start_date);
  };

  handleCourseSelect = ({ cohort }) => {
    setCurrentCohortId(cohort.id, cohort.bootcamp_id);
  };

  render() {
    const { isLoading } = this.state;

    return (
      <AdminWrapper headerTitle={intl.formatMessage({ id: 'nav.bootcamps' })} hideAuthorToggle={false}>
        <BootcampSearch />

        {isLoading && <Loading />}

        <div className="grid-row">
          <div className="sm-12">
            <div className="courses-list">
              {this.cohortBootcamps().map(({ bootcamp, cohort }) => (
                <BootcampListItemContainer
                  key={`${cohort.id}-${bootcamp.id}`}
                  bootcamp={bootcamp}
                  cohort={cohort}
                  onClick={this.handleCourseSelect}
                />
              ))}
            </div>
          </div>
        </div>
      </AdminWrapper>
    );
  }
}
BootcampList.propTypes = {
  bootcamps: PropTypes.array,
  cohorts: PropTypes.array,
  fetchCohorts: PropTypes.func.isRequired,
};
