import { connect } from 'react-redux';
import BootcampList from './BootcampList';
import { fetchCurrentEnrollments } from '../../actions/enrollmentActions';
import { selectEnrolledBootcamps } from '../../selectors/enrollmentSelectors';
import { selectCohortEntities } from '../../selectors/cohortSelectors';

const mapStateToProps = state => {
  const bootcamps = selectEnrolledBootcamps(state);
  const cohorts = selectCohortEntities(state);

  return {
    bootcamps,
    cohorts,
  };
};

export default connect(mapStateToProps, { fetchCurrentEnrollments })(BootcampList);
