import axios from './axios';

export default class BootcampInstanceQuiz {
  static all(_, bootcampInstanceId) {
    return axios
      .get('/assessments/bootcamp_instance_quizzes', {
        params: { bootcamp_instance_id: bootcampInstanceId },
      })
      .then(response => response.data);
  }

  static get(_, quizId, bootcampInstanceId) {
    return axios
      .get(`/assessments/bootcamp_instance_quizzes/${bootcampInstanceId}/${quizId}`)
      .then(response => response.data);
  }

  static save(_, bootcampInstanceQuiz) {
    return axios
      .post('/assessments/bootcamp_instance_quizzes', { ...bootcampInstanceQuiz })
      .then(response => response.data)
      .catch(error => {
        throw error;
      });
  }

  static update(_, bootcampInstanceQuiz) {
    return axios
      .put(`/assessments/bootcamp_instance_quizzes/${bootcampInstanceQuiz.id}`, bootcampInstanceQuiz)
      .then(response => response.data)
      .catch(error => {
        throw error;
      });
  }
}
