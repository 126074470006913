import { getLocationPath, getDataSourcePath } from './dataSourceHelpers';
import { evaluator, sheetNotFound } from './evalHelpers';

// eslint-disable-next-line complexity
const evalChart = ({ data, location, property, operator, value }) => {
  // Get the specific location (one before the last one)
  const sheet = getLocationPath(data, location);
  if (!sheet) return sheetNotFound(property, operator, value);
  const { type, order, name } = location[location.length - 1].dataSource;

  // locate path based on dataSource
  const chartLocator = getDataSourcePath({ type, order, name });
  const chart = chartLocator(sheet.charts);
  if (!chart) return false;

  // get the x-y axis labels
  const getXYChartLabels = currentChart => {
    if (!currentChart.axis) return null;

    const chartAxisLabels = {
      xAxisLabel: '',
      yAxisLabel: '',
    };
    currentChart.axis.forEach(ax => {
      if (ax.position === 'BOTTOM_AXIS') {
        chartAxisLabels.xAxisLabel = ax.title;
      } else if (ax.position === 'LEFT_AXIS') {
        chartAxisLabels.yAxisLabel = ax.title;
      }
    });
    return chartAxisLabels;
  };

  switch (property) {
    case 'exists':
      // assuming we only use title for the dataSource
      return !!chart;
    case 'name':
      // assuming we only use title for the dataSource
      if (type === 'byTitle') {
        const { title } = chart;
        if (operator === 'contains') {
          if (title.includes(value)) return true;
        } else if (operator === 'does not contain') {
          if (!title.includes(value)) return true;
        } else {
          return evaluator(title, operator, value);
        }
      }
      return false;
    case 'type':
      if (type === 'byTitle') {
        const { chartType } = chart;
        if (operator === 'contains') {
          if (chartType.includes(value)) return true;
        } else if (operator === 'does not contain') {
          if (!chartType.includes(value)) return true;
        } else {
          return evaluator(chartType, operator, value);
        }
      }
      if (type === 'byAny' || type === 'byAll') {
        return chartLocator(sheet.charts)(currentChart => {
          const { chartType } = currentChart;
          if (operator === 'contains') {
            return chartType.includes(value);
          }
          if (operator === 'does not contain') {
            const result = chartType.includes(value);
            return !result;
          }
          return evaluator(chartType, operator, value);
        });
      }
      return false;
    case 'xAxisLabel':
      if (type === 'byTitle') {
        const xyLabels = getXYChartLabels(chart);
        if (!xyLabels) return false;

        const { xAxisLabel } = xyLabels;
        if (operator === 'contains') {
          if (xAxisLabel.includes(value)) return true;
        } else if (operator === 'does not contain') {
          if (!xAxisLabel.includes(value)) return true;
        } else {
          return evaluator(xAxisLabel, operator, value);
        }
      }
      if (type === 'byAny' || type === 'byAll') {
        return chartLocator(sheet.charts)(currentChart => {
          const xyLabels = getXYChartLabels(currentChart);
          if (!xyLabels) return false;

          const { xAxisLabel } = xyLabels;
          if (operator === 'contains') {
            return xAxisLabel.includes(value);
          }
          if (operator === 'does not contain') {
            const result = xAxisLabel.includes(value);
            return !result;
          }
          return evaluator(xAxisLabel, operator, value);
        });
      }
      return false;
    case 'yAxisLabel':
      if (type === 'byTitle') {
        const xyLabels = getXYChartLabels(chart);
        if (!xyLabels) return false;

        const { yAxisLabel } = xyLabels;
        if (operator === 'contains') {
          if (yAxisLabel.includes(value)) return true;
        } else if (operator === 'does not contain') {
          if (!yAxisLabel.includes(value)) return true;
        } else {
          return evaluator(yAxisLabel, operator, value);
        }
      }
      if (type === 'byAny' || type === 'byAll') {
        return chartLocator(sheet.charts)(currentChart => {
          const xyLabels = getXYChartLabels(currentChart);
          if (!xyLabels) return false;

          const { yAxisLabel } = xyLabels;
          if (operator === 'contains') {
            return yAxisLabel.includes(value);
          }
          if (operator === 'does not contain') {
            const result = yAxisLabel.includes(value);
            return !result;
          }
          return evaluator(yAxisLabel, operator, value);
        });
      }
      return false;
    default:
      return null;
  }
};

export default evalChart;
