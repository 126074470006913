import { values as _values } from 'lodash';
import createCachedSelector from 're-reselect';

export const selectLtiAssignmentProgressEntities = state => {
  return state.entities.ltiAssignmentProgresses;
};

export const selectLtiProgressForAssignment = createCachedSelector(
  [selectLtiAssignmentProgressEntities, (_state, context) => context, (_state, _context, assignmentId) => assignmentId],
  (ltiAssignmentProgressEntities, context, assignmentId) => {
    return _values(ltiAssignmentProgressEntities).filter(
      progress => progress.assignment_id === assignmentId && progress.context === context,
    )[0];
  },
)((_state, context, assignmentId) => `${context}-${assignmentId}`);
