import { selectEntities, selectAll } from './applicationSelectors';

export const selectModalityEntities = selectEntities('modalities');
export const selectPsSemesterEntities = selectEntities('psSemesters');
export const selectPartnerTypeEntities = selectEntities('partnerTypes');
export const selectCreditTypeEntities = selectEntities('creditTypes');

export const selectModalities = selectAll(selectModalityEntities);
export const selectPsSemesters = selectAll(selectPsSemesterEntities);
export const selectPartnerTypes = selectAll(selectPartnerTypeEntities);
export const selectCreditTypes = selectAll(selectCreditTypeEntities);
