import { each as _each } from 'lodash';
import createCachedSelector from 're-reselect';
import { selectBootcampEntities } from './bootcampSelectors';
import { selectPathEntities } from './pathSelectors';
import { selectSectionEntities } from './sectionSelectors';
import { selectUnitEntities } from './unitSelectors';
import { selectEntities, select } from './applicationSelectors';

export const selectAssignmentEntities = selectEntities('assignments');
export const selectAssignment = select(selectAssignmentEntities);
export const selectAssignmentFromAssignmentProgress = (state, assignmentProgress) => {
  if (!assignmentProgress) return;
  return selectAssignment(state, assignmentProgress.assignment_id);
};

export const selectPathSectionUnitForAssignment = createCachedSelector(
  [
    selectBootcampEntities,
    selectPathEntities,
    selectSectionEntities,
    selectUnitEntities,
    (_state, bootcampId) => bootcampId,
    (_state, _bootcampId, assignmentId) => assignmentId,
  ],
  (bootcampEntities, pathEntities, sectionEntities, unitEntities, bootcampId, assignmentId) => {
    const bootcamp = bootcampEntities[bootcampId];
    if (!bootcamp) {
      return {};
    }
    let selectedPath;
    let selectedSection;
    let selectedUnit;

    const pathIds = (bootcamp || {}).path_ids;

    _each(pathIds, pathId => {
      const path = pathEntities[pathId];
      if (!path) return;

      _each(path.section_ids, sectionId => {
        const section = sectionEntities[sectionId];
        if (!section) return;

        _each(section.unit_ids, unitId => {
          const unit = unitEntities[unitId];

          if (unit && unit.assignment_id === assignmentId) {
            selectedPath = path;
            selectedSection = section;
            selectedUnit = unit;
          }
        });
      });
    });

    return {
      path: selectedPath,
      section: selectedSection,
      unit: selectedUnit,
    };
  },
)((_state, bootcampId, assignmentId) => `${bootcampId}-${assignmentId}`);
