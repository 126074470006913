import axios from './axios';

export default class PathProgress {
  static start(tenantId, pathProgressId, params) {
    return axios.put(`/paths/path_progresses/${pathProgressId}/start`, params).then(response => response.data);
  }

  static update(tenantId, pathProgressId, params) {
    return axios.put(`/paths/path_progresses/${pathProgressId}`, params).then(response => response.data);
  }
}
