import { isEmpty as _isEmpty } from 'lodash';
import { useContext } from 'react';
import { TutorialContext } from './TutorialContext';
import useLabContext from '../Lab/useLabContext';

const useTutorialContext = () => {
  const [tutorialState, setTutorialState] = useContext(TutorialContext);
  const { isReady } = useLabContext();
  const {
    steps,
    currentStepIndex,
    component,
    bootcamp,
    lesson,
    tutorial,
    isEditMode,
    lessonUrlObject,
    projectFolderPath,
  } = tutorialState;

  const setCurrentStep = (index, cb) => {
    if (_isEmpty(steps)) return;
    if (index < 0 || index >= steps.length) return;

    if (currentStepIndex !== index) {
      setTutorialState({ ...tutorialState, currentStepIndex: index });
      if (cb) {
        cb(getCurrentStep(index));
      }
    }
  };

  const getCurrentStep = (index = currentStepIndex) => {
    if (_isEmpty(steps)) return null;
    return steps[index];
  };

  const hasNextStep = () => {
    if (_isEmpty(steps)) return false;
    return currentStepIndex < steps.length - 1;
  };

  const hasPreviousStep = () => {
    if (_isEmpty(steps)) return false;
    return currentStepIndex > 0;
  };

  const moveNext = cb => {
    setCurrentStep(currentStepIndex + 1, cb);
  };

  const movePrevious = cb => {
    setCurrentStep(currentStepIndex - 1, cb);
  };

  const moveLast = cb => {
    setCurrentStep(steps.length - 1, cb);
  };

  return {
    setCurrentStep,
    getCurrentStep,
    hasNextStep,
    hasPreviousStep,
    moveNext,
    movePrevious,
    moveLast,
    currentStepIndex,
    steps,
    component,
    bootcamp,
    lesson,
    tutorial,
    isEditMode,
    lessonUrlObject,
    projectFolderPath,
    isReady,
  };
};

export default useTutorialContext;
