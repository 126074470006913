import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Modal } from 'antd';
import Icons from '../Utils/Icons';
import BlockShow from '../Student/BlockShow';
import LabLoadingIcons from '../Labs/LabLoadingModal/LabLoadingIcons';
import useLesson from '../../hooks/useLesson';
import intl from '../../utils/intl';
import { FormattedMessage } from 'react-intl';
import { fetchLesson } from '../../actions/lessonActions';

export function ShowProjectOverviewButton(props) {
  const { children, ...overviewProps } = props;
  const [showOverview, setShowOverview] = React.useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (props.unit?.intro_lesson_id) {
      dispatch(fetchLesson(props.unit.intro_lesson_id));
    }
  }, [props.unit?.intro_lesson_id]);

  const onClick = () => setShowOverview(true);

  return (
    <>
      <div role="button" className="project-overview-btn" onClick={onClick} onKeyPress={onClick} tabIndex="0">
        <span className="icon-element--size-medium icon-element--fill-blue2">
          <Icons.Binoculars />
        </span>
        {children || intl.formatMessage({ id: 'common.showProjectOverview' })}
      </div>
      {showOverview && <ProjectOverview {...overviewProps} onHide={() => setShowOverview(false)} />}
    </>
  );
}

export function ProjectOverview(props) {
  const [visible, setVisible] = React.useState(true);
  const { app, bootcampId, unit } = props;
  const { blocks } = useLesson(unit && unit.intro_lesson_id);

  // presence of onHide prop indicates that calling component wants to dictate
  // when overview is shown/hidden
  const isControlled = !!props.onHide;

  if (!visible) {
    return null;
  }

  const renderTitle = () => (
    <div className="title">
      <LabLoadingIcons app={app} />
      <div className="title__heading">
        <Icons.Binoculars />
        <h1>
          <FormattedMessage id="common.showProjectOverview" />
        </h1>
      </div>
    </div>
  );

  const handleHide = () => {
    if (props.onHide) {
      props.onHide();
    } else {
      setVisible(false);
    }
  };

  return (
    <Modal
      className="modal-dialog"
      wrapClassName="project-overview-container"
      visible
      width="100%"
      onCancel={handleHide}
      title={renderTitle()}
      footer={null}>
      <div className="modal-dialog-body">
        {blocks.map(block => (
          <BlockShow key={block.id} bootcampId={bootcampId} block={block} />
        ))}
        {isControlled ? null : (
          <div className="footer buttons-lessons">
            <button
              type="button"
              className="button-standard button-standard--long button-standard--blue-9"
              onClick={() => setVisible(false)}>
              {intl.formatMessage({ id: 'project.start' })}
            </button>
          </div>
        )}
      </div>
    </Modal>
  );
}

const propTypes = {
  app: PropTypes.string.isRequired,
  bootcampId: PropTypes.string,
  unit: PropTypes.object,
};

ShowProjectOverviewButton.propTypes = {
  ...propTypes,
  children: PropTypes.node,
};

ProjectOverview.propTypes = {
  ...propTypes,
  onHide: PropTypes.func,
};
