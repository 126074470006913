import React from 'react';
import PropTypes from 'prop-types';
import ContentEditable from 'react-contenteditable';
import CKEditor from '../../Utils/CKEditor';
import StudentBlockSqlShow from '../../Student/BlockSqlShow';
import BlockEditOptionsContainer from './BlockEditOptionsContainer';
import intl from '../../../utils/intl';
import CodeEditor from '../../Utils/CodeEditor';
import useSaveBlock from '../../../hooks/useSaveBlock';
import BlockConflictModal from './BlockConflictModal/BlockConflictModal';
import MultipleEditor from '../../Blocks/MultipleEditor';

export default function BlockSqlShow({ block, isEditMode, index, totalBlocks, onReorder }) {
  const { blockState, handleConflictSave, contentRef, conflict, handleChange } = useSaveBlock(block);

  const renderEditMode = () => (
    <>
      <div className="section">
        <div className="block-label">
          <div className="block-label__content">
            <MultipleEditor blockId={blockState.id} className="knowledge-check-wrapper">
              <BlockEditOptionsContainer block={block} index={index} total={totalBlocks} onReorder={onReorder} />

              <div className="knowledge-check__header">
                <ContentEditable
                  html={blockState.title || ''}
                  className="heading"
                  tagName="h2"
                  onChange={handleChange('title')}
                />
              </div>

              <div className="knowledge-check__body">
                <CKEditor
                  defaultContent={intl.formatMessage({ id: 'block.defaultHtmlContent' })}
                  inline
                  content={blockState.content}
                  onChange={handleChange('content')}
                  ref={contentRef}
                />

                <CodeEditor value={blockState.script || ''} onChange={handleChange('script')} mode="sql" />
              </div>
            </MultipleEditor>
          </div>
        </div>
      </div>

      {conflict.visible && (
        <BlockConflictModal
          remoteBlock={conflict.remote}
          currentBlock={conflict.current}
          onOk={handleConflictSave}
          onCancel={conflict.hide}
        />
      )}
    </>
  );

  const renderViewMode = () => <StudentBlockSqlShow block={blockState} />;

  return isEditMode ? renderEditMode() : renderViewMode();
}

BlockSqlShow.propTypes = {
  block: PropTypes.object.isRequired,
  isEditMode: PropTypes.bool,
  index: PropTypes.number,
  totalBlocks: PropTypes.number,
  onReorder: PropTypes.func,
};
