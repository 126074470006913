import { schema } from 'normalizr';

const appSite = new schema.Entity('appSites');
const appSites = new schema.Array(appSite);
const appStreamAppSite = new schema.Entity('appStreamAppSites');
const appStreamAppSites = new schema.Array(appStreamAppSite);
const assignment = new schema.Entity('assignments');
const assignmentProgress = new schema.Entity('assignmentProgresses');
const assignmentProgresses = new schema.Array(assignmentProgress);
const assignments = new schema.Array(assignment);
const block = new schema.Entity('blocks');
const blockProgress = new schema.Entity('blockProgresses');
const blockProgresses = new schema.Array(blockProgress);
const blocks = new schema.Array(block);
const bootcamp = new schema.Entity('bootcamps');
const bootcamps = new schema.Array(bootcamp);
const bootcampInstanceAssignment = new schema.Entity('bootcampInstanceAssignments');
const bootcampInstanceAssignments = new schema.Array(bootcampInstanceAssignment);
const bootcampInstanceQuiz = new schema.Entity('bootcampInstanceQuizzes');
const bootcampInstanceQuizzes = new schema.Array(bootcampInstanceQuiz);
const bootcampInstanceSection = new schema.Entity('bootcampInstanceSections');
const bootcampInstanceSections = new schema.Array(bootcampInstanceSection);
const school = new schema.Entity('schools');
const schools = new schema.Array(school);
const cohort = new schema.Entity('cohorts');
const cohorts = new schema.Array(cohort);
const partnerType = new schema.Entity('partnerTypes');
const creditType = new schema.Entity('creditTypes');
const psSemester = new schema.Entity('psSemesters');
const modality = new schema.Entity('modalities');
const cohortLookups = new schema.Object({
  partnerTypes: new schema.Array(partnerType),
  creditTypes: new schema.Array(creditType),
  modalities: new schema.Array(modality),
  psSemesters: new schema.Array(psSemester),
});
const comment = new schema.Entity('comments');
const comments = new schema.Array(comment);
const component = new schema.Entity('components');
const componentProgress = new schema.Entity('componentProgresses');
const componentProgresses = new schema.Array(componentProgress);
const components = new schema.Array(component);
const criterion = new schema.Entity('criteria');
const criteria = new schema.Array(criterion);
const enrollment = new schema.Entity('enrollments');
const enrollments = new schema.Array(enrollment);
const evaluatorQueueItem = new schema.Entity('evaluatorQueueItems');
const evaluatorQueueItems = new schema.Array(evaluatorQueueItem);
const lesson = new schema.Entity('lessons');
const lessonProgress = new schema.Entity('lessonProgresses');
const lessonProgresses = new schema.Array(lessonProgress);
const lessons = new schema.Array(lesson);
const ltiAssignmentProgress = new schema.Entity('ltiAssignmentProgresses');
const ltiAssignmentProgresses = new schema.Array(ltiAssignmentProgress);
const part = new schema.Entity('parts');
const parts = new schema.Array(part);
const path = new schema.Entity('paths');
const pathProgress = new schema.Entity('pathProgresses');
const pathProgresses = new schema.Array(pathProgress);
const paths = new schema.Array(path);
const programCohort = new schema.Entity('programCohorts');
const programCohorts = new schema.Array(programCohort);
const question = new schema.Entity('questions');
const questionProgress = new schema.Entity('questionProgresses');
const questionProgresses = new schema.Array(questionProgress);
const questions = new schema.Array(question);
const quiz = new schema.Entity('quizzes');
const quizProgress = new schema.Entity('quizProgresses');
const quizProgresses = new schema.Array(quizProgress);
const quizzes = new schema.Array(quiz);
const section = new schema.Entity('sections');
const sectionProgress = new schema.Entity('sectionProgresses');
const sectionProgresses = new schema.Array(sectionProgress);
const sections = new schema.Array(section);
const step = new schema.Entity('steps');
const stepProgress = new schema.Entity('stepProgresses');
const stepProgresses = new schema.Array(stepProgress);
const steps = new schema.Array(step);
const tutorial = new schema.Entity('tutorials');
const tutorialProgress = new schema.Entity('tutorialProgresses');
const tutorialProgresses = new schema.Array(tutorialProgress);
const tutorials = new schema.Array(tutorial);
const unit = new schema.Entity('units');
const unitProgress = new schema.Entity('unitProgresses');
const unitProgresses = new schema.Array(unitProgress);
const units = new schema.Array(unit);
const user = new schema.Entity('users');
const users = new schema.Array(user);
const autograderRuleset = new schema.Entity('autograderRulesets');
const autograderRulesets = new schema.Array(autograderRuleset);

assignment.define({
  rubric_criteria: criteria,
});

const entities = {
  appSites,
  appStreamAppSites,
  assignmentProgresses,
  assignments,
  autograderRulesets,
  blockProgresses,
  blocks,
  bootcampInstances: cohorts,
  bootcampInstanceLookups: cohortLookups,
  bootcamps,
  bootcampInstanceAssignments,
  bootcampInstanceQuizzes,
  bootcampInstanceSections,
  schools,
  comments,
  componentProgresses,
  components,
  enrollments,
  evaluatorQueueItems,
  lessonProgresses,
  lessons,
  ltiAssignmentProgresses,
  parts,
  pathProgresses,
  paths,
  programCohorts,
  questionProgresses,
  questions,
  quizProgresses,
  quizzes,
  sectionProgresses,
  sections,
  stepProgresses,
  steps,
  tutorialProgresses,
  tutorials,
  unitProgresses,
  units,
  users,
};

export default {
  appSite,
  appSites,
  appStreamAppSite,
  appStreamAppSites,
  assignment,
  assignmentProgress,
  assignmentProgresses,
  assignments,
  block,
  blockProgress,
  blockProgresses,
  blocks,
  bootcamp,
  bootcamps,
  cohort,
  cohorts,
  comment,
  comments,
  component,
  componentProgress,
  componentProgresses,
  components,
  enrollment,
  enrollments,
  entities,
  lesson,
  lessonProgress,
  lessonProgresses,
  lessons,
  ltiAssignmentProgress,
  ltiAssignmentProgresses,
  part,
  parts,
  path,
  pathProgress,
  pathProgresses,
  paths,
  programCohort,
  programCohorts,
  question,
  questionProgress,
  questionProgresses,
  questions,
  quiz,
  quizProgress,
  quizProgresses,
  quizzes,
  section,
  sectionProgress,
  sectionProgresses,
  sections,
  step,
  stepProgress,
  stepProgresses,
  steps,
  tutorial,
  tutorialProgress,
  tutorialProgresses,
  tutorials,
  unit,
  unitProgress,
  unitProgresses,
  units,
  user,
  users,
};
