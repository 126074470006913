import { getLocationPath, getDataSourcePath } from './dataSourceHelpers';

const evalPivotTableFilter = ({ data, location, property }) => {
  // Get the specific location (one before the last one)
  const pivotTable = getLocationPath(data, location);
  if (!pivotTable) return false;
  const { type, order, name } = location[location.length - 1].dataSource;

  // locate path based on dataSource
  const pivotTableFilterLocator = getDataSourcePath({ type, order, name });
  const pivotTableFilter = pivotTableFilterLocator(pivotTable.filters);

  switch (property) {
    case 'exists':
      // assuming we only use title for the dataSource
      return !!pivotTableFilter;
    case 'filterBy':
      if (type === 'byTitle') {
        const { visibleValues } = pivotTableFilter;
        if (visibleValues) return true;
      }
      if (type === 'byAny' || type === 'byAll') {
        return pivotTableFilterLocator(pivotTable.filters)(filter => {
          const { visibleValues } = filter;
          if (visibleValues) return true;
          return false;
        });
      }
      return false;
    default:
      return null;
  }
};

export default evalPivotTableFilter;
