import React from 'react';
import Select, { Creatable } from 'react-select';
import PropTypes from 'prop-types';

const CustomSelect = ({ creatable, name, options, ...rest }) => {
  const Component = creatable ? Creatable : Select;

  return <Component id={name} className="Select--simple" name={name} options={options} {...rest} />;
};

CustomSelect.propTypes = {
  creatable: PropTypes.bool,
  name: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
};

export default CustomSelect;
