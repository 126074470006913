import showModal from '../../../../utils/showModal';
import { toast } from 'react-toastify';
import { FormattedMessage } from 'react-intl';
import React from 'react';
import { Button } from 'antd';
import intl from '../../../../utils/intl';
import PropTypes from 'prop-types';
import FileUploadModal from '../../../Utils/FileUploadModal/FileUploadModal';

const ACCEPTED_FILE_TYPES = ['image/jpeg', 'image/png', 'image/svg+xml', 'image/tiff', 'image/gif'];

const showUploadLogoModal = onSubmit => {
  showModal(FileUploadModal, {
    title: intl.formatMessage({ id: 'school.logo.title' }),
    body: intl.formatMessage({ id: 'school.logo.body' }),
    accept: ACCEPTED_FILE_TYPES,
    onSubmit: async (fileUploads, closeModal) => {
      try {
        await onSubmit(fileUploads);
        closeModal();
      } catch (err) {
        toast(<FormattedMessage id="school.logo.failedSubmission" />, {
          autoClose: 5000,
          type: toast.TYPE.ERROR,
        });
      }
    },
  });
};

const UploadSchoolLogo = ({ school, onFilesChange }) => {
  return (
    <>
      {school?.logo && (
        <div>
          <img src={school.logo} className="school-logo" alt={school.name} />
        </div>
      )}
      <div>
        <Button
          onClick={() =>
            showUploadLogoModal(fileUploads => {
              onFilesChange(fileUploads);
            })
          }>
          {school?.logo ? (
            <FormattedMessage id="school.logo.editLogo" />
          ) : (
            <FormattedMessage id="school.logo.uploadLogo" />
          )}
        </Button>
      </div>
    </>
  );
};

UploadSchoolLogo.propTypes = {
  school: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    logo: PropTypes.string,
  }),
  onFilesChange: PropTypes.func,
};

export default UploadSchoolLogo;
