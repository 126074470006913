import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Template from './Template';
import history from '../../history';

import { toastSuccess } from '../../utils/toastHelper';
import CustomInput from '../Utils/CustomInput';
import intl from '../../utils/intl';

const ForgotPassword = ({ forgotPassword }) => {
  const [submitting, setSubmitting] = useState(false);
  const [errors, setErrors] = useState(null);
  const [email, setEmail] = useState('');
  const loginUrl = '/login';

  const handleChange = e => {
    const { name, value } = e.target;
    if (name === 'email') setEmail(value);
  };

  const handleSubmit = e => {
    e.preventDefault();

    if (!email) {
      setErrors([
        <FormattedMessage id="common.errors.required" values={{ field: <FormattedMessage id="user.form.email" /> }} />,
      ]);
      return;
    }

    if (submitting) return;
    setSubmitting(true);
    setErrors(null);
    forgotPassword(email)
      .then(() => {
        toastSuccess(intl.formatMessage({ id: 'user.forgotPassword.success' }));
      })
      .catch(err => {
        setErrors(err.errors);
      })
      .finally(() => setSubmitting(false), history.push(loginUrl));
  };

  return (
    <Template
      onSubmit={handleSubmit}
      welcomeMsgId="user.login.welcomeMsgId"
      taglineId="user.login.taglineId"
      formHeader="user.forgotPassword.formHeading"
      formTagline="user.forgotPassword.instructions">
      <div className="modal-dialog-body modal-dialog-body--small ">
        <CustomInput
          name="email"
          type="email"
          autoFocus
          autoComplete="off"
          value={email}
          onChange={handleChange}
          label={intl.formatMessage({ id: 'user.forgotPassword.label' })}
        />
      </div>
      <div className="modal-dialog-footer modal-dialog-footer--centered">
        <button type="submit" className="button-standard button-standard--blue2 button-standard--medium new-auth-btn">
          <p>
            <FormattedMessage id="user.login.submitBtn" />
          </p>
        </button>
      </div>
    </Template>
  );
};

ForgotPassword.propTypes = {
  forgotPassword: PropTypes.func.isRequired,
};

export default ForgotPassword;
