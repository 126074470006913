import { difference as _difference } from 'lodash';
import React, { Component } from 'react';
import Modal2 from '../../../library/Modal2';
import intl from '../../../../utils/intl';
import { toastSuccess } from '../../../../utils/toastHelper';
import Errors from '../../../Utils/Errors';
import Loading from '../../../Utils/Loading';
import SectionTileContainer from './SectionTileContainer';

export default class HideContentModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      errors: [],
      hiddenItems: [...props.cohort.hidden_items],
      isLoading: true,
    };
  }

  componentDidMount() {
    const { fetchBootcamp, bootcamp } = this.props;
    fetchBootcamp(bootcamp.id).then(() => this.setState({ isLoading: false }));
  }

  handleOnChange = (itemId, isSelected) => {
    const { hiddenItems } = this.state;
    const newContent = isSelected ? _difference(hiddenItems, [itemId]) : [...hiddenItems, itemId];

    this.setState({
      hiddenItems: newContent,
    });
  };

  handleSubmit = () => {
    const { hiddenItems } = this.state;
    const { cohort, saveCohort } = this.props;

    this.setState({ errors: [] });
    saveCohort({ ...cohort, hidden_items: hiddenItems })
      .then(() => {
        toastSuccess('Cohort saved');
        this.props.onHide();
      })
      .catch(err => {
        console.log('ERROR', err);
        this.setState({ errors: err.value.errors });
      });
  };

  render() {
    const { onHide, sections } = this.props;
    const { errors, isLoading, hiddenItems } = this.state;

    return (
      <Modal2
        size="big"
        className="cohort-hide-content-modal"
        onHide={onHide}
        onOk={this.handleSubmit}
        title={intl.formatMessage({ id: 'cohort.heading.hideContent' })}>
        <Errors errors={errors} />

        <div className="content">
          {isLoading ? (
            <Loading />
          ) : (
            sections.map((section, index) => (
              <SectionTileContainer
                key={section.id}
                section={section}
                index={index}
                hiddenItems={hiddenItems}
                onChange={this.handleOnChange}
              />
            ))
          )}
        </div>
      </Modal2>
    );
  }
}
