import axios from 'axios';
import axiosRetry from 'axios-retry';
import store from '../store';
import { signOut, ping } from '../actions/authActions';
import { getTokens, areTokensValid } from '../helpers/userAuth';

const axiosInstance = axios.create();

// NOTE: axiosRetry does not work with axios@0.19.0. See https://github.com/axios/axios/issues/2203
// retry 3 times on all 5XX errors
axiosRetry(axiosInstance, {
  retries: 2, // Total of 3 attemps
  retryCondition: error =>
    axiosRetry.isNetworkError(error) ||
    (error.code !== 'ECONNABORTED' &&
      (!error.response || (error.response.status > 500 && error.response.status <= 599))),
  retryDelay: axiosRetry.exponentialDelay,
});

const handleSuccess = response => {
  ping()(store.dispatch);

  return response;
};

const handleError = error => {
  const getError = () => {
    let err = error.response;
    if (err && err.data && typeof err.data === 'object') {
      err = err.data;
      err.origError = error;
    } else {
      err = error;
    }
    return err;
  };

  const authFailed = () => {
    if (window.location.pathname !== '/login') {
      signOut(true)(store.dispatch);
    }
    return Promise.reject(getError());
  };

  if (error.response && error.response.status === 401) {
    return authFailed();
  }

  return Promise.reject(getError());
};

// Add a request interceptor for adding token to Authorization header
axiosInstance.interceptors.request.use(
  config => {
    if (areTokensValid() && !config.headers.Authorization) {
      const token = getTokens().accessToken;
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
    }

    return config;
  },
  error => Promise.reject(error),
);

axiosInstance.interceptors.response.use(handleSuccess, handleError);

export default axiosInstance;
