import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { FormattedMessage } from 'react-intl';
import Icons from '../Utils/Icons';
import { getLessonPartStatus, scrollToPart } from '../../helpers/lessonHelper';
import FinishLessonPart from './FinishLessonPart';

export default function LessonPartFooter({ lessonProgress, lesson, part, currentPartId }) {
  const { isComplete, isCurrent } = getLessonPartStatus(lessonProgress, lesson, part);

  const showGoToCurrent = !isComplete && !isCurrent;
  const showMarkComplete = lessonProgress && !isComplete && isCurrent;

  const handleGoToCurrent = () => scrollToPart(currentPartId);

  return (
    <footer
      className={classnames(
        'lesson-part__footer',
        isComplete && 'lesson-part__footer--message',
        (showGoToCurrent || showMarkComplete) && 'lesson-part__footer--action',
      )}>
      {isComplete && (
        <div className="lesson-part__message">
          <Icons.CircleCheckChecked />
          <FormattedMessage id="status.completed" />
        </div>
      )}

      {showGoToCurrent && (
        <button
          type="button"
          onClick={handleGoToCurrent}
          className="button-standard button-standard--outlined button-standard--long">
          <FormattedMessage id="component.goToCurrent" />
        </button>
      )}

      <FinishLessonPart lessonProgress={lessonProgress} lesson={lesson} part={part} />
    </footer>
  );
}

LessonPartFooter.propTypes = {
  lessonProgress: PropTypes.object,
  lesson: PropTypes.object,
  part: PropTypes.object,
  currentPartId: PropTypes.string,
};
