import { normalize } from 'normalizr';
import Schemas from '../api/schemas';
import { updateEntities } from './entityActions';
import { fetchCurrentTenant } from './tenantActions';
import { startRequest, endRequest } from './requestActions';

export const applicationSave = api => applicationApiExecute(api.save, 'save');
export const applicationFetch = api => applicationApiExecute(api.get);
export const applicationFetchAll = api => applicationApiExecute(api.all);
export const applicationUpdate = api => applicationApiExecute(api.update);
export const applicationDestroy = api => applicationApiExecute(api.destroy);

export const applicationApiExecute = (apiFunction, requestType) => (...args) => dispatch => {
  if (requestType === 'save') dispatch(startRequest(requestType));
  return fetchCurrentTenant()(dispatch).then(tenant =>
    apiFunction(tenant.id, ...args)
      .then(response => {
        if (requestType === 'save') dispatch(endRequest(requestType));
        if (response.entities && response.entities_x) {
          const { entities } = normalize(response.entities, Schemas.entities);
          const { entities: entitiesX } = normalize(response.entities_x, Schemas.entities);

          dispatch(updateEntities({ entities, entitiesX }));
        }

        return response.value || response;
      })
      .catch(err => {
        if (requestType === 'save') dispatch(endRequest(requestType));
        if (err.response && err.response.data) {
          throw err.response.data;
        }
        throw err;
      }),
  );
};
