import React from 'react';
import PropTypes from 'prop-types';
import { Input } from 'antd';
import { FormattedMessage } from 'react-intl';

import intl from '../../../../../utils/intl';
import RubricCard from '../Rubric/RubricCard';
import { GradingScale, FancyGroup } from '../GradingScale/GradingScale';
import { getProficiencyLevel } from '../../../../../utils/utils';
import { AutograderSummaryContainer } from '../../../../../autograder/components/AutograderSummary';
const { TextArea } = Input;

const handleSelectedGrade = (criterion, saveGrade) => e => {
  const score = Number(e.target.value);

  if (score >= 0) {
    const updatedCriterion = {
      ...criterion,
      score: criterion.score === score ? null : score,
    };

    saveGrade({ criterion: updatedCriterion });
  }
};

const handleCommentUpdate = (criterion, saveGrade) => e => {
  const comment = e.target.value;

  const updatedCriterion = {
    ...criterion,
    comment,
  };
  saveGrade({ criterion: updatedCriterion });
};

const GradeSelection = ({ saveGrade, criterionProgress, rubric, criterion, autograderRulesetCriterionResult }) => {
  const renderGrading = () => {
    const { criterion_id: id, score, comment } = criterionProgress;

    let currentProficiencyLevel = null;

    // Because score >= 0 is true in JS
    if (score === 0 || score > 0) {
      currentProficiencyLevel = getProficiencyLevel(rubric, id, score);
    }

    return (
      <div className="apply-grade">
        <div className="grade-bar">
          <GradingScale
            title={
              <div className="grade-title">
                <FormattedMessage id="grading.gradeTitle" />
              </div>
            }
            rubric={rubric}
            onGradeSelect={handleSelectedGrade(criterionProgress, saveGrade)}
            selectedGrade={criterionProgress}>
            <FancyGroup />
          </GradingScale>
        </div>
        <div className="grade-desc">
          {currentProficiencyLevel ? (
            <RubricCard {...currentProficiencyLevel} />
          ) : (
            <FormattedMessage id="grading.selectGradeTitle" />
          )}
        </div>
        <div className="grade-comments">
          <div className="grade-comments-label">
            <FormattedMessage id="grading.commentsTitle" />:
          </div>
          <TextArea
            rows={4}
            placeholder={intl.formatMessage({ id: 'grading.commentsText' })}
            value={comment}
            onChange={handleCommentUpdate(criterionProgress, saveGrade)}
          />
        </div>
        {autograderRulesetCriterionResult && (
          <AutograderSummaryContainer
            autograderRulesetCriterionResult={autograderRulesetCriterionResult}
            criterion={criterion}
            onGradeSelect={handleSelectedGrade(criterionProgress, saveGrade)}
          />
        )}
      </div>
    );
  };

  return (
    <div className="select-grade">
      <div className="grading-details">{renderGrading({ saveGrade, criterionProgress, rubric })}</div>
    </div>
  );
};

GradeSelection.propTypes = {
  rubric: PropTypes.array,
  saveGrade: PropTypes.func,
  criterionProgress: PropTypes.object,
  criterion: PropTypes.object,
  autograderRulesetCriterionResult: PropTypes.object,
};

export default GradeSelection;
