// eslint-disable-next-line no-unused-vars
import { getLocationPath } from './dataSourceHelpers';
import { getDataFromCellAndEval } from './evalHelpers';

const evalChartDataCell = ({ data, location, property, operator, value }) => {
  const chart = getLocationPath(data, location);
  if (!chart) return null;

  const sheet = getLocationPath(data, location.slice(0, -1));
  if (sheet.dataset.length) {
    chart.rows = sheet.dataset[0].rows;
  }

  return getDataFromCellAndEval({
    path: [chart],
    location,
    property,
    operator,
    value,
  });
};

export default evalChartDataCell;
