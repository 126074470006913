import React from 'react';
import { Tabs } from 'antd';

import AdminWrapper from '../v2/Nav/Admin/AdminWrapper';
import EvaluatorQueueTab from './EvaluatorQueueTab';

import { STATUS_QUEUED_AND_LEASED, STATUS_GRADED } from './constants';

const { TabPane } = Tabs;

const Evaluator = () => {
  return (
    <AdminWrapper headerTitle={'Evaluator Queue'}>
      <Tabs defaultActiveKey="queue">
        <TabPane tab="Queue" key="queue">
          <EvaluatorQueueTab tabTitle="Grading Queue" queueItemsType={STATUS_QUEUED_AND_LEASED} />
        </TabPane>

        <TabPane tab="Graded Projects" key="graded-projects">
          <EvaluatorQueueTab tabTitle="Graded Projects" queueItemsType={STATUS_GRADED} />
        </TabPane>
      </Tabs>
    </AdminWrapper>
  );
};

export default Evaluator;
