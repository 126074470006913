import React from 'react';
import PropTypes from 'prop-types';

export default function CustomInput(props) {
  const { name, label, type, value, ...rest } = props;

  let internalValue;
  if (props.hasOwnProperty('value')) {
    internalValue = value || '';
  }

  return (
    <div className="custom-input">
      <input
        className="custom-input__input"
        id={name}
        name={name}
        type={type}
        value={internalValue}
        placeholder={label}
        aria-label={label}
        {...rest}
      />
      <label className="custom-input__label" htmlFor={name}>
        {label}
      </label>
    </div>
  );
}

CustomInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  type: PropTypes.string,
};

CustomInput.defaultProps = {
  type: 'text',
};
