export default class CertificateTitle {
  static all = () => {
    const titles = [
      'Digital Marketing',
      'Data Analytics',
      'Unity Immersive Reality',
      'Project Management',
      'Salesforce Administration',
      'Management & Leadership',
    ].sort();

    titles.push('Other');
    titles.push('Testing');
    return titles;
  };
}
