import { connect } from 'react-redux';
import GradeAssignment from './GradeAssignment';
import { fetchUnitProgress, gradeUnitProgress } from '../../actions/unitProgressActions';
import { selectUnitProgress } from '../../selectors/unitProgressSelectors';
import { selectAssignmentProgress } from '../../selectors/assignmentProgressSelectors';
import { selectLessonProgress } from '../../selectors/lessonProgressSelectors';
import { selectLesson } from '../../selectors/lessonSelectors';
import { selectAssignment } from '../../selectors/assignmentSelectors';
import { selectUnit } from '../../selectors/unitSelectors';
import { selectCohortForEnrollment } from '../../selectors/cohortSelectors';
import { gradeAssignmentProgress } from '../../actions/assignmentProgressActions';
import { getUsers } from '../../actions/userActions';
import { selectCurrentUser } from '../../selectors/authSelectors';
import { isCoach, isEvaluator } from '../../helpers/userAuth';
import { getCohortDashboardPath } from '../../helpers/pathHelper';
import { fetchAssignment } from '../../actions/assignmentActions';

const configureBackLinkByRole = (state, currentUser, cohort, assignment, ownProps) => {
  let backLink = '/coach/grade';
  if (isCoach(currentUser) || isEvaluator(currentUser)) {
    if (cohort && assignment) {
      const queryParams = new URLSearchParams(ownProps.location.search);
      const returnUrl = queryParams.get('returnUrl');
      backLink = returnUrl || getCohortDashboardPath(cohort.id, { tab: 'assignments', assignmentId: assignment.id });
    }
  }
  return backLink;
};

const mapStateToProps = (state, ownProps) => {
  const { unitProgressId } = ownProps.match.params;
  const unitProgress = selectUnitProgress(state, unitProgressId);
  const unit = selectUnit(state, unitProgress && unitProgress.unit_id);
  const assignmentProgress = selectAssignmentProgress(state, unitProgress && unitProgress.assignment_progress_id);
  const assignment = selectAssignment(state, assignmentProgress && assignmentProgress.assignment_id);
  const lessonProgress = selectLessonProgress(state, unitProgress && unitProgress.lesson_progress_id);
  const lesson = selectLesson(state, lessonProgress && lessonProgress.lesson_id);
  const cohort = selectCohortForEnrollment(state, unitProgress?.context);

  const currentUser = selectCurrentUser(state);

  const backLink = configureBackLinkByRole(state, currentUser, cohort, assignment, ownProps);

  return {
    unitProgress,
    unitProgressId,
    assignmentProgress,
    assignment,
    unit,
    lesson,
    backLink,
  };
};

export default connect(mapStateToProps, {
  fetchUnitProgress,
  gradeUnitProgress,
  gradeAssignmentProgress,
  fetchAssignment,
  getUsers,
})(GradeAssignment);
