import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Modal } from 'antd';
import Rubric from './Rubric';

export default function RubricModal({ assignment, onHide, visible }) {
  return (
    <Modal
      title={<FormattedMessage id="rubricCriteria.heading" values={{ assignmentTitle: assignment.title }} />}
      visible={visible}
      onCancel={onHide}
      footer={null}
      width="80vw">
      <Rubric {...{ assignment }} />
    </Modal>
  );
}

RubricModal.propTypes = {
  assignment: PropTypes.object.isRequired,
  onHide: PropTypes.func.isRequired,
  visible: PropTypes.bool,
};

RubricModal.defaultProps = {
  visible: true,
};
