import React from 'react';
import PropTypes from 'prop-types';

import Header from './Header';
// import GreatJobCard from './Cards/GreatJobCard';
import CohortCompletedCard from './Cards/CohortCompletedCard';
import InfoCard from './Cards/InfoCard';
import { isCohortCompleted } from './helpers';

// For V1, don't show tooltip for in progress cohorts since the info will be shown on the InfoCard
const hideTooltip = cohort => !isCohortCompleted(cohort);

const CohortItem = ({ cohort }) => {
  const renderCard = () => {
    if (isCohortCompleted(cohort)) {
      return <CohortCompletedCard cohort={cohort} />;
    }

    // return <GreatJobCard cohortId={cohort.id} />;
    return <InfoCard cohort={cohort} />;
  };

  return (
    <div className="cohort-item" data-testid="cohort-item">
      <Header cohort={cohort} hideTooltip={hideTooltip(cohort)} />

      {renderCard()}
    </div>
  );
};

CohortItem.propTypes = {
  cohort: PropTypes.object.isRequired,
};

export default CohortItem;
