import axios from './axios';

export default class Tutorial {
  static GSUITE = 'gsuite';

  static CUSTOM_SITE = 'custom_site';

  static CODIO = 'codio';

  static MYSQL = 'mysql';

  static TABLEAU = 'tableau';

  static TRAILHEAD = 'trailhead';

  static GOOGLE_COLAB = 'google_colab';

  static EMBED = 'embed';

  static VIDEO = 'video';

  static TOUGH_TALKS = 'tough_talks';

  static APPS = [
    Tutorial.GSUITE,
    Tutorial.CODIO,
    Tutorial.MYSQL,
    Tutorial.TABLEAU,
    Tutorial.TRAILHEAD,
    Tutorial.CUSTOM_SITE,
    Tutorial.GOOGLE_COLAB,
    Tutorial.EMBED,
    Tutorial.VIDEO,
    Tutorial.TOUGH_TALKS,
  ];

  static BACK_TO_LESSON = 'back_to_lesson';

  static NEXT_LESSON = 'next_lesson';

  static BUTTON_OPTIONS = [Tutorial.BACK_TO_LESSON, Tutorial.NEXT_LESSON];

  static LAUNCH_IN_BROWSER = 'browser';
  static LAUNCH_IN_DESKTOP = 'desktop';
  static LAUNCH_IN_NEW_TAB = 'new_tab';

  static LAUNCH_OPTIONS = [Tutorial.LAUNCH_IN_BROWSER, Tutorial.LAUNCH_IN_DESKTOP, Tutorial.LAUNCH_IN_NEW_TAB];

  static ACCEPTED_FILE_TYPES = [
    'image/jpeg',
    'image/png',
    'image/svg+xml',
    'image/tiff',
    'image/gif',
    'video/mp4',
    'video/x-msvideo', // avi
    'video/quicktime', // mov, qt
    'application/pdf',
    'application/zip',
    'text/csv',
    'text/plain',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // docx
    'application/msword', // doc
    'application/vnd.openxmlformats-officedocument.presentationml.presentation', // pptx
    'application/vnd.ms-powerpoint', // ppt
    'application/vnd.ms-excel', // xls
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // xlsx
  ];

  static all(_) {
    return axios.get('/labs/tutorials').then(response => response.data);
  }

  static get(_, tutorialId) {
    return axios.get(`/labs/tutorials/${tutorialId}`).then(response => response.data);
  }

  static save(_, tutorial) {
    if (tutorial.id) {
      return axios.put(`/labs/tutorials/${tutorial.id}`, tutorial).then(response => response.data);
    }
    return axios.post('/labs/tutorials', tutorial).then(response => response.data);
  }

  static destroy(_, tutorial) {
    return axios.delete(`/labs/tutorials/${tutorial.id}`).then(response => response.data);
  }
}
