import React from 'react';
import PropTypes from 'prop-types';
import 'react-image-lightbox/style.css';
import Popup from './Popup';
import Icons from '../Icons';

const TablePopup = ({ table, onHide }) => (
  <Popup containerStyle={{ visibility: 'visible' }} className="table-popup" isResizable={false}>
    <header>
      <button type="button" className="icon close small black" onClick={onHide} aria-label="Close Table">
        <Icons.Close />
      </button>
    </header>
    <section className="content section" dangerouslySetInnerHTML={{ __html: table }} />
  </Popup>
);

TablePopup.propTypes = {
  table: PropTypes.string.isRequired,
  onHide: PropTypes.func.isRequired,
};
export default TablePopup;
