import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import { Link } from 'react-router-dom';

const CourseCard = ({ date, title, url, type, accessDate }) => {
  const buttonMessage = () => {
    if (type === 'future') {
      const formattedDate = moment(accessDate).format('MM/DD');
      return <FormattedMessage id="bootcamp.accessOn" values={{ date: formattedDate }} />;
    } else {
      return <FormattedMessage id="bootcamp.goTo" />;
    }
  };

  const buttonClass = () => {
    let buttonClass = 'button-standard--short';
    if (type === 'future') {
      buttonClass += ' button-disabled';
    }
    return buttonClass;
  };

  const dateClass = () => {
    let dateClass = 'date-range';
    if (type === 'future') {
      dateClass += ' disable';
    }
    return dateClass;
  };

  const titleClass = () => {
    let titleClass = 'course-title';
    if (type === 'future') {
      titleClass += ' disable';
    }
    return titleClass;
  };

  return (
    <div className="labels-and-card">
      <div className="labels">
        {type === 'currentOptional' && (
          <div className="both-labels">
            <div className="current-label label-margin">
              <FormattedMessage id="bootcamp.currentlyLearning" />
            </div>
            <div className="optional-label">
              <FormattedMessage id="bootcamp.optional" />
            </div>
          </div>
        )}
        {type === 'current' && (
          <div className="current-label">
            <FormattedMessage id="bootcamp.currentlyLearning" />
          </div>
        )}
        {type === 'optional' && (
          <div className="optional-label">
            <FormattedMessage id="bootcamp.optional" />
          </div>
        )}
      </div>
      <div className="currently-enrolled-course">
        <div className="words">
          <div className={dateClass()}>{date}</div>
          <div className={titleClass()}>{title}</div>
        </div>
        <div className="button">
          <Link className={`button-standard ${buttonClass()}`} aria-label={title} to={url} disabled={type === 'future'}>
            {buttonMessage()}
          </Link>
        </div>
      </div>
    </div>
  );
};

CourseCard.propTypes = {
  date: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  accessDate: PropTypes.string,
};

export default CourseCard;
