import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import UnitTile from './UnitTile';
import { selectProgressForUnit } from '../../selectors/unitProgressSelectors';
import { canLearn } from '../../helpers/userAuth';
import { selectIsEditMode } from '../../selectors/contextSelectors';
import { destroySectionUnit } from '../../actions/sectionUnitActions';
import { saveUnit, reorderUnits } from '../../actions/unitActions';
import { selectCurrentUser } from '../../selectors/authSelectors';

const mapStateToProps = (state, ownProps) => {
  const { section, unit } = ownProps;
  const unitProgress = selectProgressForUnit(state, section.id, unit.id);
  const isStudent = canLearn(selectCurrentUser(state));
  const isEditMode = selectIsEditMode(state);

  return {
    unitProgress,
    isStudent,
    isEditMode,
  };
};

export default connect(mapStateToProps, { destroySectionUnit, saveUnit, reorderUnits })(withRouter(UnitTile));
