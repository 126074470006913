import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { CheckCircleFilled } from '@ant-design/icons';
import { FormattedMessage } from 'react-intl';

import { Link, useLocation } from 'react-router-dom';
import DashboardModalContext from '../../../../v2/Nav/Coach/context/DashboardModalContext';
import { getCohortDashboardPath } from '../../../../../helpers/pathHelper';
import { setCurrentCohortId } from '../../../../../actions/components/coachActions';

// const ViewStudentsButton = () => {
//   const clickHandler = () => {
//     // TODO: navigate to students page
//   };

//   return (
//     <button type="button" onClick={clickHandler}>
//       <FormattedMessage id="cohort.viewStudents" />
//     </button>
//   );
// };

// const ViewAssignmentsButton = () => {
//   const clickHandler = () => {
//     // TODO: navigate to assignments page
//   };

//   return (
//     <button type="button" onClick={clickHandler}>
//       <FormattedMessage id="cohort.viewAssignments" />
//     </button>
//   );
// };

const StandardCardWrapper = ({ cohort, children }) => {
  const { onModalHide } = useContext(DashboardModalContext);
  const location = useLocation();

  const getViewCohortUrl = () => {
    const queryParams = new URLSearchParams(location.search);
    const tab = queryParams.get('tab');

    setCurrentCohortId(cohort.id, cohort.bootcamp_id);

    return getCohortDashboardPath(cohort.id, { tab });
  };

  const renderButtons = () => {
    // return (
    //   <div className="card-buttons">
    //     <ViewStudentsButton />

    //     <ViewAssignmentsButton />
    //   </div>
    // );

    // For V1, only have one button to navigate to assignments page
    return (
      <div className="card-buttons" onClick={onModalHide}>
        <Link to={getViewCohortUrl()}>
          <FormattedMessage id="cohort.viewCohort" />
        </Link>
      </div>
    );
  };

  return (
    <div className="standard-card-wrapper" data-testid="standard-card-wrapper">
      <div className="check-icon">
        <CheckCircleFilled />
      </div>

      {children}

      {renderButtons()}
    </div>
  );
};

StandardCardWrapper.propTypes = {
  cohort: PropTypes.object.isRequired,
  children: PropTypes.node,
};

export default StandardCardWrapper;
