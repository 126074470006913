import React from 'react';
import { Button } from 'antd';
import { DeleteOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { getColIndexToAlpha } from '../../../utils/utils';

const CheckViewer = ({ check, onEdit, onRemove, fieldKeys, orderIndex }) => {
  const { name, location, property, operator, value } = check;
  return (
    <div className="check-text-main">
      <div className="check-name">
        <span className="order-index">{`${getColIndexToAlpha(orderIndex)}.`}</span>
        {name}
      </div>
      <div className="check-compare-section">
        <div className="check-location">
          <div className="check-location-label">Look in:</div>
          <div className="check-location-path">
            {location.map(({ within, dataSource }, index) => (
              <React.Fragment key={index}>
                <div className="check-location-where">{`- ${within}`}</div>
                <div className="check-location-type">{dataSource.name || dataSource.type}</div>
              </React.Fragment>
            ))}
          </div>
        </div>
        <div className="check-property">
          <div className="check-property-label">See if:</div>
          <div className="check-property-value">{property}</div>
        </div>
        <div className="check-operator">{operator}</div>
        <div className="check-value">{value}</div>
      </div>
      <div className="edit-field">
        {onEdit && (
          <Button type="primary" size="small" ghost className="edit-field-btn" onClick={() => onEdit(fieldKeys)}>
            <PlusCircleOutlined style={{ fontSize: '16px', verticalAlign: 'initial' }} />
            Edit Check
          </Button>
        )}
        {onRemove && <DeleteOutlined style={{ color: 'red', marginLeft: '5px' }} onClick={() => onRemove(fieldKeys)} />}
      </div>
    </div>
  );
};

export default CheckViewer;
