import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import intl from '../../utils/intl';
import Icons from '../Utils/Icons';

const ViewingAsStudentHeader = ({ onBackClick }) => {
  return (
    <header className="viewing-as-student-header">
      <div className="back-button">
        <button type="button" aria-label={intl.formatMessage({ id: 'common.back' })} onClick={onBackClick}>
          <Icons.BackSmall />
        </button>
      </div>

      <div className="header-text">
        <FormattedMessage id="common.viewingCourseContent" />
      </div>
    </header>
  );
};

ViewingAsStudentHeader.propTypes = {
  onBackClick: PropTypes.func.isRequired,
};

export default ViewingAsStudentHeader;
