import Component from '../api/component';
import { updateEntities } from './entityActions';
import { fetchCurrentTenant } from './tenantActions';
import { applicationFetchAll, applicationSave, applicationDestroy, applicationApiExecute } from './applicationActions';

export const saveComponent = applicationSave(Component);
export const destroyComponent = applicationDestroy(Component);
export const fetchComponents = applicationFetchAll(Component);
export const copyComponent = applicationApiExecute(Component.copy);

export const reorderComponents = (componentIds, unit) => dispatch => {
  const entities = {
    units: {
      [unit.id]: {
        ...unit,
        component_ids: componentIds,
      },
    },
  };
  dispatch(updateEntities({ entities }));

  return fetchCurrentTenant()(dispatch).then(tenant => Component.reorder(tenant.id, componentIds, unit.id));
};
