import React from 'react';
import { some as _some } from 'lodash';
import { connect } from 'react-redux';
import { canLearn } from '../../helpers/userAuth';
import { withRouter } from 'react-router-dom';
import { selectCurrentUser } from '../../selectors/authSelectors';
import ComponentItem from './ComponentItem';
import { destroyComponent } from '../../actions/componentActions';
import { selectIsEditMode } from '../../selectors/contextSelectors';
import { selectLesson, selectLessonBlocks } from '../../selectors/lessonSelectors';
import Block from '../../api/block';
import { fetchLesson } from '../../actions/lessonActions';
import StudentComponentItem from './StudentComponentItem';

const mapStateToProps = (state, ownProps) => {
  const { component } = ownProps;
  const isStudent = canLearn(selectCurrentUser(state));
  const isEditMode = selectIsEditMode(state);
  const lesson = selectLesson(state, component.external_id);
  const blocks = selectLessonBlocks(state, component.external_id);
  const hasLab = _some(blocks, b => b.block_type === Block.LAB);

  return {
    isStudent,
    isEditMode,
    hasLab,
    lesson,
  };
};

const ComponentItemContainer = ({ pathstream2, ...props }) => {
  if (pathstream2) {
    return <StudentComponentItem {...props} />;
  } else {
    return <ComponentItem {...props} />;
  }
};

export default withRouter(connect(mapStateToProps, { destroyComponent, fetchLesson })(ComponentItemContainer));
