import { getLocationPath } from './dataSourceHelpers';
import { evaluator, sheetNotFound } from './evalHelpers';

// assuming we're checking only in the first table/dataset
// eslint-disable-next-line complexity
const evalColRowValCol = ({ data, location, property, operator, value }) => {
  const sheet = getLocationPath(data, location);
  if (!sheet) return sheetNotFound(property, operator, value);
  let columnStart = '';
  let rowValue = '';
  let columnEnd = '';

  location.forEach(loc => {
    switch (loc.within) {
      case 'columns':
        columnStart = loc.dataSource.name;
        break;
      case 'rowValue':
        rowValue = loc.dataSource.name;
        break;
      case 'columns2':
        columnEnd = loc.dataSource.name;
        break;
      default:
    }
  });

  if (!columnStart || !rowValue || !columnEnd) return null;

  const rowData = sheet.dataset[0];
  const { columnHeaders, rows } = rowData;

  const matchedColStartIndex = columnHeaders.findIndex(colHeader => colHeader === columnStart);

  if (matchedColStartIndex === -1) return null;

  const matchedColEndIndex = columnHeaders.findIndex(colHeader => colHeader === columnEnd);

  if (matchedColEndIndex === -1) return null;

  const matchedRowIndex = rows.findIndex(row => row[matchedColStartIndex].name === rowValue);

  if (matchedRowIndex === -1) return null;

  const matchedData = rows[matchedRowIndex][matchedColEndIndex];

  if (!matchedData) return false;

  const { name, rawNumValue, formula, bold, textColor, backgroundColor, formatType } = matchedData;

  switch (property) {
    case 'value':
      if (operator === 'contains') {
        return name.includes(value);
      }
      if (operator === 'does not contain') {
        const result = name.includes(value);
        return !result;
      }
      const nameComparisonResult = evaluator(name, operator, value);
      if (nameComparisonResult.status) return nameComparisonResult;
      return evaluator(rawNumValue, operator, value);
    case 'formula':
      if (operator === 'contains') {
        return formula ? formula.includes(value) : false;
      }
      if (operator === 'does not contain') {
        const result = formula ? formula.includes(value) : false;
        return !result;
      }
      return evaluator(formula, operator, value);
    case 'bold':
      if (operator === 'contains') {
        return bold.includes(value);
      }
      if (operator === 'does not contain') {
        const result = bold.includes(value);
        return !result;
      }
      return evaluator(bold, operator, value);
    case 'textColor':
      if (operator === 'contains') {
        return textColor.includes(value);
      }
      if (operator === 'does not contain') {
        const result = textColor.includes(value);
        return !result;
      }
      return evaluator(textColor, operator, value);
    case 'backgroundColor':
      if (operator === 'contains') {
        return backgroundColor.includes(value);
      }
      if (operator === 'does not contain') {
        const result = backgroundColor.includes(value);
        return !result;
      }
      return evaluator(backgroundColor, operator, value);
    case 'formatType':
      if (operator === 'contains') {
        return formatType ? formatType.includes(value) : false;
      }
      if (operator === 'does not contain') {
        const result = formatType ? formatType.includes(value) : false;
        return !result;
      }
      return evaluator(formatType, operator, value);
    default:
      return null;
  }
};

export default evalColRowValCol;
