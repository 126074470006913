import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import AdminWrapper from '../v2/Nav/Admin/AdminWrapper';
import intl from '../../utils/intl';
import * as analytics from '../../utils/analytics';
import { setPageTitle } from '../../helpers/pageHelper';
import PrintHeader from '../library/PrintHeader';
import { formatDate } from '../../utils/utils';
import CohortReportsContent from './CohortReportsContent';

const CohortReports = ({ cohort }) => {
  useEffect(() => {
    analytics.pageStart('Cohort Reports');
    setPageTitle('pageName.reports.cohort');
    return analytics.pageEnd;
  }, []);

  return (
    <AdminWrapper
      headerTitle={intl.formatMessage({ id: 'nav.cohortReports' })}
      printHeader={
        <PrintHeader
          title={<FormattedMessage id="pageName.reports.cohort" />}
          subTitle={<FormattedMessage id="cohortReports.generated" values={{ date: formatDate(new Date()) }} />}
        />
      }>
      <CohortReportsContent cohort={cohort} />
    </AdminWrapper>
  );
};

CohortReports.propTypes = {
  cohort: PropTypes.object,
};

export default CohortReports;
