import PropTypes from 'prop-types';
import React from 'react';
import { Modal } from 'antd';
import { FormattedMessage } from 'react-intl';
import CohortReportsContent from '../../../../Reports/CohortReportsContent';

const DownloadReportsModal = ({ onHide }) => (
  <Modal
    className="coach-reports-shortcut"
    title={
      <span className="report-modal-title">
        <FormattedMessage id="coachHeader.shortcuts.downloadReports" />
      </span>
    }
    width={1100}
    visible
    centered
    onCancel={onHide}
    footer={null}>
    <div className="">
      <div className="report-section">
        <FormattedMessage id="coachHeader.shortcuts.downloadReportsDescription" />
      </div>
      <div className="report-section">
        <div className="report-title">
          <FormattedMessage id="nav.cohortReports" />
        </div>
        <CohortReportsContent />
      </div>
    </div>
  </Modal>
);

export default DownloadReportsModal;

DownloadReportsModal.propTypes = {
  onHide: PropTypes.func.isRequired,
};
