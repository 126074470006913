import { map as _map } from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class Errors extends Component {
  render() {
    const { errors } = this.props;

    if (!errors || errors.length === 0) {
      return null;
    }

    return (
      <div className="alert alert-danger" role="status">
        {_map(errors, (error, i) => (
          <div key={i}>{error}</div>
        ))}
      </div>
    );
  }
}
Errors.propTypes = {
  errors: PropTypes.array,
};
