import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { selectIsEditMode } from '../../selectors/contextSelectors';

const DEFAULT_STATES = {
  startClicked: false,
  isReady: false,
  isVmUp: undefined, // undefined: state for when not known whether vm is up or not
  errors: [],
  errorModalProps: [],
};
const LabContext = React.createContext([{}, () => {}]);

const LabDataProvider = ({ children, app }) => {
  const isEditMode = useSelector(selectIsEditMode);
  const [state, setState] = useState({
    ...DEFAULT_STATES,
    callbacks: {},
    app,
  });

  const updateState = args => {
    setState({ ...state, ...args });
  };

  useEffect(() => {
    updateState({ app });
  }, [app]);

  useEffect(() => {
    const updateStates = isEditMode ? DEFAULT_STATES : {};
    updateState({ ...updateStates, isEditMode });
  }, [isEditMode]);

  return <LabContext.Provider value={[state, setState]}>{children}</LabContext.Provider>;
};

LabDataProvider.propTypes = {
  children: PropTypes.node,
  app: PropTypes.string,
};

export { LabContext, LabDataProvider };
