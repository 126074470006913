import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

export default class CopyComponentModalComponent extends Component {
  render() {
    const { component, unit, bootcamp, onClick } = this.props;
    if (!unit || !bootcamp) return null;

    return (
      <li>
        <div className="copy">
          <div className="label">
            <p className="hierarchy-parents">
              {bootcamp.title} {bootcamp.version && `> ${bootcamp.version}`} &gt; {unit.title}
            </p>

            {component.title}
          </div>

          <button
            type="button"
            className="button-standard button-standard--blue2 button-standard--short"
            onClick={() => onClick(component)}>
            <FormattedMessage id="common.copy" />
          </button>
        </div>
      </li>
    );
  }
}
CopyComponentModalComponent.propTypes = {
  component: PropTypes.object.isRequired,
  unit: PropTypes.object,
  bootcamp: PropTypes.object,
  onClick: PropTypes.func.isRequired,
};
