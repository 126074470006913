import { map as _map, debounce as _debounce } from 'lodash';
import uuid from 'uuid/v4';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import update from 'immutability-helper';
import { FormattedMessage } from 'react-intl';
import classnames from 'classnames';
import { ReactComponent as MinusIcon } from '../../../styles/pathstream/svg-jsx/icon-minus.svg';
import intl from '../../../utils/intl';
import Errors from '../../Utils/Errors';
import CKEditor from '../../Utils/CKEditor';
import Button from './Button';
import MultipleEditor from '../../Blocks/MultipleEditor';

export default class EditQuizQuestion extends Component {
  constructor(props) {
    super(props);
    this.state = { question: props.question, errors: [] };
  }

  getNewChoice = () => {
    const { question_type } = this.state.question;
    const choice = {
      id: uuid(),
      prompt: intl.formatMessage({ id: 'question.defaultAnswer' }, { num: '' }),
    };

    if (question_type === 'multi_select' || question_type === 'single_select') {
      choice.is_correct = false;
    }

    return choice;
  };

  shouldHaveChoices = () => {
    const { question_type } = this.state.question;
    return ['multi_select', 'single_select', 'self_reflection'].includes(question_type);
  };

  hasChoicesExplanation = () => {
    const { question_type } = this.state.question;
    return ['single_select'].includes(question_type);
  };

  debouncedSave = _debounce(() => {
    const { question } = this.state;

    this.setState({ errors: [] });

    this.props.saveQuestion(question, question.quiz_id).catch(err => {
      this.setState({ errors: err.value.errors });
    });
  }, 500);

  handleAddChoice = () => {
    const nextQuestion = update(this.state.question, {
      choices: {
        $push: [this.getNewChoice()],
      },
    });
    this.setState(
      {
        question: nextQuestion,
      },
      this.debouncedSave,
    );
  };

  handleRemoveChoice = (e, id) => {
    e.preventDefault();

    const nextQuestion = update(this.state.question, {
      choices: choices => {
        return choices.filter(choice => {
          return choice.id !== id;
        });
      },
    });
    this.setState(
      {
        question: nextQuestion,
      },
      this.debouncedSave,
    );
  };

  handleQuestionChange = (attributeName, content) => {
    this.setState(
      {
        question: {
          ...this.state.question,
          [attributeName]: content,
        },
      },
      this.debouncedSave,
    );
  };

  handleQuestionCheckboxChange = e => {
    const value = e.target.checked;

    this.handleQuestionChange('randomize_choices', value);
  };

  handleChoiceChange = (attributeName, content, id) => {
    const { question } = this.state;
    const newChoices = _map(question.choices, choice =>
      id === choice.id ? { ...choice, [attributeName]: content } : choice,
    );

    this.handleQuestionChange('choices', newChoices);
  };

  handleChoiceCheckboxChange = (e, id) => {
    const value = e.target.checked;

    this.handleChoiceChange('is_correct', value, id);
  };

  render() {
    const { forGradedQuiz } = this.props;
    const { question, errors } = this.state;
    const { choices } = question;

    const formItemClass = forGradedQuiz ? 'form-item-graded' : 'form-item';
    const questionsHeaderClass = forGradedQuiz ? 'questions-graded__header' : 'questions__header';

    return (
      <form>
        <Errors errors={errors} />

        <div className="knowledge-check-title-wrapper">
          <div className="knowledge-check-title">
            <section className="questions" aria-labelledby="question-progress">
              <MultipleEditor
                blockId={question.quiz_id}
                childrenId={question.id}
                className={classnames(formItemClass, 'text-field')}>
                <CKEditor
                  inline
                  onChange={content => this.handleQuestionChange('prompt', content)}
                  content={question.prompt || ''}
                  activeClass="input-text"
                  config={{ title: intl.formatMessage({ id: 'question.prompt' }) }}
                />
              </MultipleEditor>
            </section>
          </div>
        </div>

        <MultipleEditor blockId={question.quiz_id} childrenId={question.id} className="content">
          {this.shouldHaveChoices() && (
            <section className="questions" aria-labelledby="answers">
              <div className={questionsHeaderClass}>
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <label id="answers" className="section-label" style={{ flex: '100%' }}>
                  <FormattedMessage id="quiz.answer" />
                </label>
                {this.hasChoicesExplanation() && (
                  // eslint-disable-next-line jsx-a11y/label-has-associated-control
                  <label id="answers-explanations" className="section-label" style={{ flex: '100%' }}>
                    <FormattedMessage id="question.choice.explanation" />
                  </label>
                )}
              </div>
              {_map(choices, choice => (
                <div
                  className={classnames(formItemClass, 'text-field')}
                  key={choice.id}
                  style={{ display: 'flex', alignItems: 'center', marginBottom: 10 }}>
                  {choice.hasOwnProperty('is_correct') && (
                    <input
                      name="is_correct"
                      type="checkbox"
                      checked={choice.is_correct}
                      placeholder=""
                      onChange={e => this.handleChoiceCheckboxChange(e, choice.id)}
                      aria-label={intl.formatMessage({ id: 'question.choice.isCorrect' })}
                      style={{ marginRight: 10 }}
                    />
                  )}
                  <div style={{ overflowX: 'hidden', flex: '100%' }}>
                    <CKEditor
                      inline
                      onChange={content => this.handleChoiceChange('prompt', content, choice.id)}
                      content={choice.prompt || ''}
                      activeClass="input-text"
                      config={{ title: intl.formatMessage({ id: 'question.choice.prompt' }) }}
                    />
                  </div>

                  {this.hasChoicesExplanation() && (
                    <div style={{ marginLeft: '3px', overflowX: 'hidden', flex: '100%' }}>
                      <CKEditor
                        inline
                        onChange={content => this.handleChoiceChange('explanation', content, choice.id)}
                        content={choice.explanation || ''}
                        activeClass="input-text"
                        config={{
                          ignoreEmptyParagraph: false,
                          fillEmptyBlocks: false,
                          title: intl.formatMessage({ id: 'question.choice.explanation' }),
                        }}
                      />
                    </div>
                  )}

                  <div>
                    <button
                      className="authoring-icon-button small rounded"
                      aria-label={intl.formatMessage({ id: 'aria.deleteChoice' })}
                      title={intl.formatMessage({ id: 'aria.deleteChoice' })}
                      onClick={e => this.handleRemoveChoice(e, choice.id)}>
                      <MinusIcon />
                    </button>
                  </div>
                </div>
              ))}
            </section>
          )}
          <section aria-labelledby="explanation" style={{ paddingBottom: 20 }}>
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label id="explanation" className="section-label">
              <FormattedMessage id="question.explanation" />
            </label>

            <div className={classnames(formItemClass, 'text-field')}>
              <CKEditor
                inline
                onChange={content => this.handleQuestionChange('explanation', content)}
                content={question.explanation || ''}
                activeClass="input-text"
                config={{ title: intl.formatMessage({ id: 'question.explanation' }) }}
              />
            </div>
          </section>
        </MultipleEditor>

        {this.shouldHaveChoices() && (
          <>
            <label className="content">
              <input
                name="randomize_choices"
                type="checkbox"
                checked={question.randomize_choices}
                onChange={this.handleQuestionCheckboxChange}
                style={{ marginRight: 10 }}
                aria-label={intl.formatMessage({ id: 'question.randomizeChoices' })}
              />
              <FormattedMessage id="question.randomizeChoices" />
            </label>
            <div className="answer-buttons">
              <Button messageId="quiz.addChoice" onClick={this.handleAddChoice} style={{ marginRight: 20 }} />
            </div>
          </>
        )}
      </form>
    );
  }
}

EditQuizQuestion.propTypes = {
  question: PropTypes.object.isRequired,
  saveQuestion: PropTypes.func.isRequired,
  questionCount: PropTypes.object.isRequired,
};
