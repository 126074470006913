import { connect } from 'react-redux';
import CohortListItem from './CohortListItem';
import { selectBootcamp } from '../../../selectors/bootcampSelectors';
import { destroyCohort } from '../../../actions/cohortActions';
import { selectSchool } from '../../../selectors/schoolSelectors';

const mapStateToProps = (state, ownProps) => {
  const { cohort } = ownProps;
  const bootcamp = selectBootcamp(state, cohort.bootcamp_id);
  const school = selectSchool(state, cohort.school_id);

  return {
    bootcamp,
    school,
  };
};

export default connect(mapStateToProps, { destroyCohort })(CohortListItem);
