import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TooltipButton from './TooltipButton';
import intl from '../../../utils/intl';
import Icons from '../Icons';

export default class TooltipButtons extends Component {
  render() {
    const { buttons } = this.props;
    return (
      <>
        <div className="tooltip-buttons">
          {buttons.map((button, index) => (
            <TooltipButton key={index} {...button} />
          ))}
        </div>

        <div className="tooltip-buttons-close">
          <button
            aria-label={intl.formatMessage({ id: 'common.close' })}
            className="tooltip-buttons-close__button"
            onClick={this.props.onClose}>
            <div className="icon-element icon-element--fill- icon-element--background-white icon-element--button">
              <Icons.Close />
            </div>
          </button>
        </div>

        <div className="tooltip-buttons-overlay"></div>
      </>
    );
  }
}
TooltipButtons.propTypes = {
  buttons: PropTypes.arrayOf(PropTypes.shape(TooltipButton.propTypes)),
  onClose: PropTypes.func,
};
