import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Layout } from 'antd';
import { partition as _partition, orderBy as _orderBy, flatten as _flatten } from 'lodash';

import { selectCohortEnrollments } from '../../../../../selectors/enrollmentSelectors';
import { selectAssignmentProgressesByEnrollmentIds } from '../../../../../selectors/assignmentProgressSelectors';
import SubmissionCard from './SubmissionCard';
import SubmissionsHeader from './SubmissionsHeader';
import NoAssignmentsSubmitted from './NoAssignmentsSubmitted';
import NoAssignmentToGrade from './NoAssignmentToGrade';
import { selectAssignment } from '../../../../../selectors/assignmentSelectors';
import intl from '../../../../../utils/intl';

const useStore = (cohortId, assignmentId) => {
  const enrollments = useSelector(state => selectCohortEnrollments(state, cohortId));
  const enrollmentIds = enrollments.map(enrollment => enrollment.id);
  let assignmentProgresses = useSelector(state => selectAssignmentProgressesByEnrollmentIds(state, enrollmentIds));
  assignmentProgresses = assignmentProgresses.filter(
    assignmentProgress => assignmentProgress.assignment_id === assignmentId && !!assignmentProgress.completed_at,
  );
  const assignment = useSelector(state => selectAssignment(state, assignmentId));

  return {
    assignmentProgresses,
    assignment,
  };
};

const sortProgresses = progressesByAssignmentId => {
  const [requiresGradingProgresses, noReqGradingProgresses] = _partition(
    progressesByAssignmentId,
    progress => progress.requires_grading,
  );

  const [gradedProgresses, ungradedProgresses] = _partition(
    requiresGradingProgresses,
    progress => !!progress.graded_at,
  );

  return {
    ungradedProgresses: _orderBy(_flatten(ungradedProgresses), ['completed_at'], ['desc']),
    gradedProgresses: _orderBy(_flatten(gradedProgresses), ['graded_at'], ['desc']),
    noReqGradingProgresses: _orderBy(_flatten(noReqGradingProgresses), ['completed_at'], ['desc']),
  };
};

const AssignmentsContent = ({ cohortId, assignmentId, watchedByStudentId }) => {
  const { assignmentProgresses, assignment } = useStore(cohortId, assignmentId);
  const { ungradedProgresses, gradedProgresses, noReqGradingProgresses } = sortProgresses(assignmentProgresses);

  const renderSubmissions = assignmentProgresses => {
    return assignmentProgresses.map(assignmentProgress => {
      const isWatched = watchedByStudentId[assignmentProgress.user_id];
      return (
        <SubmissionCard
          key={assignmentProgress.id}
          assignmentProgressId={assignmentProgress.id}
          isWatched={isWatched}
        />
      );
    });
  };

  const renderSection = (sectionProgresses, { title, emptyState }) => {
    const showSectionTitle = !!title && (sectionProgresses.length || emptyState);
    const showEmptyState = !sectionProgresses.length && emptyState;
    const EmptyState = showEmptyState && assignmentProgresses.length ? NoAssignmentToGrade : NoAssignmentsSubmitted;

    return (
      <div className="submissions-container">
        {showSectionTitle && <div className="empty-state-header">{title}</div>}
        {sectionProgresses && renderSubmissions(sectionProgresses)}
        {showEmptyState && <EmptyState />}
      </div>
    );
  };

  const showMultipleSections = assignment?.requires_grading;

  return (
    <Layout.Content className="assignments-page-content">
      {assignmentId && (
        <div className="submissions-container">
          <SubmissionsHeader assignmentId={assignmentId} cohortId={cohortId} />
          {showMultipleSections ? (
            <>
              {renderSection(ungradedProgresses, {
                title: intl.formatMessage({ id: 'cohort.assignmentsToGrade' }),
                emptyState: true,
              })}
              {renderSection(gradedProgresses, { title: intl.formatMessage({ id: 'cohort.gradedAssignments' }) })}
            </>
          ) : (
            renderSection(noReqGradingProgresses, { emptyState: true })
          )}
        </div>
      )}
    </Layout.Content>
  );
};

AssignmentsContent.propTypes = {
  cohortId: PropTypes.string.isRequired,
  assignmentId: PropTypes.string.isRequired,
  watchedByStudentId: PropTypes.object,
};

AssignmentsContent.defaultProps = {
  watchedByStudentId: {},
};

export default AssignmentsContent;
