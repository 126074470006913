import { compact as _compact, map as _map } from 'lodash';
import createCachedSelector from 're-reselect';
import { selectEntities, select, selectAll, selectHasMany } from './applicationSelectors';

export const selectSectionEntities = selectEntities('sections');
const selectSections = selectAll(selectSectionEntities);
export const selectSection = select(selectSectionEntities);
export const selectSectionFromUnit = selectHasMany(selectSections, 'unit_ids');

const sectionUnitIds = (state, sectionId) => (selectSection(state, sectionId) || {}).unit_ids;

export const selectSectionUnits = createCachedSelector(
  [sectionUnitIds, state => state.entities.units],
  (unitIds, unitEntities) => _compact(_map(unitIds, unitId => unitEntities[unitId])),
)((_state, sectionId) => sectionId);
