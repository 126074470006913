import PropTypes from 'prop-types';
import React, { useState } from 'react';
import Select from 'react-select';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';

import CKEditor from '../../../Utils/CKEditor';
import Errors from '../../../Utils/Errors';
import Modal2 from '../../../library/Modal2';
import { toastError, toastSuccess } from '../../../../utils/toastHelper';
import intl from '../../../../utils/intl';
import CustomCheckbox from '../../../Utils/CustomCheckbox';
import CustomInput from '../../../Utils/CustomInput';
import { saveBootcamp } from '../../../../actions/bootcampActions';
import CertificateTitle from '../../../../api/certificateTitle';

export const certificateTitleSelectOptions = CertificateTitle.all().map(name => ({ label: name, value: name }));

export default function EditBootcampModal({ bootcamp: bootcampProp, onHide }) {
  const [bootcamp, setBootcamp] = useState(bootcampProp);
  const [errors, setErrors] = useState([]);
  const dispatch = useDispatch();

  const handleChange = name => e => {
    const { target } = e;
    let value;
    if (name === 'description') {
      value = e;
    } else if (target.type === 'checkbox') {
      value = target.checked;
    } else {
      value = target.value;
    }

    setBootcamp({
      ...bootcamp,
      [name]: value,
    });
  };

  const handleSubmit = e => {
    e.preventDefault();

    dispatch(saveBootcamp(bootcamp))
      .then(() => {
        onHide();
        toastSuccess(<FormattedMessage id="bootcamp.notification.saveBootcamp" />, { autoClose: 5000 });
      })
      .catch(err => {
        const errs = (err.value && err.value.errors) || ['Unable to save Course'];
        setErrors(errs);
        toastError(errs, { autoClose: 5000 });
      });
  };

  return (
    <Modal2
      size="big"
      bodyClass=" "
      className="modal-auto-grow edit-bootcamp-modal"
      onHide={onHide}
      onOk={handleSubmit}
      title={
        bootcamp.id ? (
          <FormattedMessage id="bootcamp.heading.edit" />
        ) : (
          <FormattedMessage id="bootcamp.heading.create" />
        )
      }>
      <Errors errors={errors} />
      <CustomInput
        name="title"
        onChange={handleChange('title')}
        value={bootcamp.title}
        label={intl.formatMessage({ id: 'bootcamp.form.title' })}
      />
      <CustomInput
        name="version"
        onChange={handleChange('version')}
        value={bootcamp.version}
        label={intl.formatMessage({ id: 'bootcamp.form.version' })}
      />
      <Select
        name="certificate_title"
        className="certificate-title-select"
        aria-label="Certificate Title"
        clearable={false}
        value={bootcamp.certificate_title ? bootcamp.certificate_title : certificateTitleSelectOptions[0]}
        onChange={option =>
          setBootcamp(currentBootcamp => ({
            ...currentBootcamp,
            certificate_title: option.value,
          }))
        }
        options={certificateTitleSelectOptions}
      />

      <div>
        <label htmlFor="description">
          <FormattedMessage id="bootcamp.form.description" />
        </label>
        <CKEditor content={bootcamp.description} onChange={handleChange('description')} />
      </div>

      <div>
        <CustomCheckbox
          name="show_progress"
          onChange={handleChange('show_progress')}
          checked={bootcamp.show_progress}
          label={<FormattedMessage id="bootcamp.form.showProgress" />}
        />
      </div>

      <div>
        <CustomCheckbox
          name="requires_tableau"
          onChange={handleChange('requires_tableau')}
          checked={bootcamp.requires_tableau}
          label={<FormattedMessage id="bootcamp.form.requiresTableau" />}
        />
      </div>

      <div>
        <CustomCheckbox
          name="requires_colab"
          onChange={handleChange('requires_colab')}
          checked={bootcamp.requires_colab}
          label={<FormattedMessage id="bootcamp.form.requiresColab" />}
        />
      </div>

      <div>
        <CustomCheckbox
          name="requires_asana"
          onChange={handleChange('requires_asana')}
          checked={bootcamp.requires_asana}
          label={<FormattedMessage id="bootcamp.form.requiresAsana" />}
        />
      </div>

      <div>
        <CustomCheckbox
          name="requires_asana_link_upload"
          onChange={handleChange('requires_asana_link_upload')}
          checked={bootcamp.requires_asana_link_upload}
          label={<FormattedMessage id="bootcamp.form.requiresAsanaLinkUpload" />}
        />
      </div>
    </Modal2>
  );
}

EditBootcampModal.propTypes = {
  bootcamp: PropTypes.object.isRequired,
  onHide: PropTypes.func.isRequired,
};
