import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as SvgIconArrowLeftWhite } from '../../styles/pathstream/svg-jsx/icon-arrow-left-white.svg';

export default class Navigator extends Component {
  static propTypes = {
    currentUnitUrlObject: PropTypes.object,
    history: PropTypes.object.isRequired,
    headerText: PropTypes.string,
  };

  handleNavigate = url => {
    this.props.history.push(url);
  };

  // eslint-disable-next-line complexity
  render() {
    const { currentUnitUrlObject, headerText } = this.props;

    if (!headerText && (!currentUnitUrlObject || !currentUnitUrlObject.current)) {
      return null;
    }

    const previousUrl = currentUnitUrlObject.previous ? currentUnitUrlObject.previous.url : null;
    const nextUrl = currentUnitUrlObject.next ? currentUnitUrlObject.next.url : null;
    const heading = currentUnitUrlObject.current ? currentUnitUrlObject.current.heading : headerText;
    const subHeading = currentUnitUrlObject.current ? currentUnitUrlObject.current.subHeading : null;

    return (
      <div className="section-navigator">
        <div className="prev">
          {previousUrl && (
            <button onClick={() => this.handleNavigate(previousUrl)} aria-label="Previous Unit">
              <SvgIconArrowLeftWhite />
            </button>
          )}
        </div>
        <div className="section-title">
          {subHeading && <div className="sub-heading" dangerouslySetInnerHTML={{ __html: subHeading }} />}
          <div dangerouslySetInnerHTML={{ __html: heading }} />
        </div>
        <div className="next">
          {nextUrl && (
            <button onClick={() => this.handleNavigate(nextUrl)} aria-label="Next Unit">
              <SvgIconArrowLeftWhite />
            </button>
          )}
        </div>
      </div>
    );
  }
}
