import { isEmpty as _isEmpty } from 'lodash';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import Modal2 from '../../library/Modal2';
import intl from '../../../utils/intl';
import { toastSuccess, toastError } from '../../../utils/toastHelper';
import Errors from '../../Utils/Errors';
import CustomCheckbox from '../../Utils/CustomCheckbox';
import FileUpload, { getFileName } from '../../Utils/FileUpload';

export default class ConfigureModalsModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      bootcamp: { ...this.props.bootcamp },
      errors: [],
    };
  }

  handleChange = e => {
    const { target } = e;
    const { name } = target;
    const value = target.type === 'checkbox' ? target.checked : target.value;

    this.setState({
      bootcamp: {
        ...this.state.bootcamp,
        [name]: value,
      },
    });
  };

  handleFileUpload = url => {
    this.setState({
      bootcamp: {
        ...this.state.bootcamp,
        intro_video_url: url,
      },
    });
  };

  validate = () => {
    const { bootcamp } = this.state;

    if (bootcamp.show_intro_modal && _isEmpty(bootcamp.intro_video_url)) {
      this.setState({
        errors: [intl.formatMessage({ id: 'bootcamp.modals.introVideoRequired' })],
      });
      return false;
    }

    return true;
  };

  handleSave = e => {
    e.preventDefault();

    if (!this.validate()) {
      return;
    }

    this.setState({ errors: [] });
    this.props
      .saveBootcamp(this.state.bootcamp)
      .then(() => {
        this.props.onHide();
        toastSuccess(<FormattedMessage id="bootcamp.modals.saved" />, { autoClose: 5000 });
      })
      .catch(err => {
        this.setState({ errors: err.value.errors });
        toastError(err.value.errors, { autoClose: 5000 });
      });
  };

  render() {
    const { onHide } = this.props;
    const { bootcamp, errors } = this.state;

    return (
      <Modal2
        onHide={onHide}
        title={intl.formatMessage({ id: 'bootcamp.modals.configureHeading' })}
        onOk={this.handleSave}>
        <Errors errors={errors} />

        <CustomCheckbox
          name="show_intro_modal"
          checked={bootcamp.show_intro_modal || false}
          onChange={this.handleChange}
          label={<FormattedMessage id="bootcamp.form.showIntroModal" />}
        />

        {bootcamp.show_intro_modal && (
          <div className="form-item">
            <span className="ml-2 project-file">{getFileName(bootcamp.intro_video_url)}</span>
            <FileUpload showDropZone accept="video/*" onSuccess={this.handleFileUpload}>
              <div className="custom-upload-input__label">
                <span className="custom-upload-input__label--text">
                  <FormattedMessage id="common.fileDropMessage" />
                </span>
              </div>
            </FileUpload>
          </div>
        )}

        <CustomCheckbox
          name="show_returning_modal"
          checked={bootcamp.show_returning_modal || false}
          onChange={this.handleChange}
          label={<FormattedMessage id="bootcamp.form.showReturningModal" />}
        />

        <CustomCheckbox
          name="show_progress_modals"
          checked={bootcamp.show_progress_modals || false}
          onChange={this.handleChange}
          label={<FormattedMessage id="bootcamp.form.showProgressModals" />}
        />
      </Modal2>
    );
  }
}
