import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { Button } from 'antd';
import { DownloadSubmission } from '../DownloadSubmission';

import Icons from '../../../components/Utils/Icons';

const GradeAssignmentHeader = ({
  assignment,
  assignmentProgress,
  backLink,
  isGrading,
  onToggleGrading,
  student,
  unit,
}) => {
  const requiresGrading = assignment?.requires_grading;

  const history = useHistory();
  const handleBackClick = () => {
    history.push(backLink);
  };

  const renderHeaderActions = () => {
    const grading = {
      className: 'grading',
      label: 'viewInstructions',
    };

    const viewing = {
      className: 'viewing',
      label: 'backToGrading',
    };

    const current = isGrading ? grading : viewing;

    return (
      <>
        <DownloadSubmission {...{ unit, assignmentProgress }} />
        <div className="toggle-button-container">
          <Button className={current.className} onClick={onToggleGrading}>
            <FormattedMessage id={`grading.${current.label}`} />
          </Button>
        </div>
      </>
    );
  };

  return (
    <div className="grade-assignment-header-nav-container">
      <Button className="head-nav-child back-link" onClick={handleBackClick}>
        <Icons.BackSmall />
      </Button>
      <div className="head-nav-child main-title">
        <FormattedMessage id="grading.gradedAssignment" />
        &nbsp;
        {assignment?.title && student?.name && `${assignment.title} for ${student.name}`}
      </div>
      {requiresGrading && renderHeaderActions()}
    </div>
  );
};

GradeAssignmentHeader.propTypes = {
  assignment: PropTypes.object,
  assignmentProgress: PropTypes.object,
  backLink: PropTypes.string,
  isGrading: PropTypes.bool,
  onToggleGrading: PropTypes.func,
  student: PropTypes.object,
  unit: PropTypes.object,
};

export default GradeAssignmentHeader;
