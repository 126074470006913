import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ProjectOverview } from '../Projects/ProjectOverview';
import { isCodioApp } from '../../helpers/labHelper';
import { CodioLab } from './CodioLab';
import Lab from './Lab';
import { SoftBlockModal, getProjectsWorkBestOnDesktopModalProps } from '../SoftBlockModal/SoftBlockModal';
import { isMobileDevice } from '../../utils/utils';

const Project = ({ labInteractable, bootcamp, history, unit, ...labProps }) => {
  const queryParams = new URLSearchParams(history?.location?.search);
  const openRubric = !!queryParams.get('openRubric');
  const [mobileSoftBlockOpen, setMobileSoftBlockOpen] = useState(false);

  useEffect(() => {
    if (isMobileDevice()) {
      setMobileSoftBlockOpen(true);
    }
  }, []);

  const handleCloseMobileSoftBlock = () => {
    setMobileSoftBlockOpen(false);
  };

  if (isCodioApp(labInteractable.app)) {
    return <CodioLab unit={unit} />;
  }

  const handleGoBack = () => {
    window.history.back();
  };

  return (
    <>
      {mobileSoftBlockOpen ? (
        <SoftBlockModal
          {...getProjectsWorkBestOnDesktopModalProps()}
          onCancel={handleCloseMobileSoftBlock}
          onPrimaryButton={handleCloseMobileSoftBlock}
          onSecondaryButton={handleGoBack}
        />
      ) : (
        <>
          {bootcamp && !openRubric && (
            <ProjectOverview app={labInteractable.app} bootcampId={bootcamp.id} unit={unit} />
          )}
          <Lab {...{ labInteractable, bootcamp }} {...labProps} />
        </>
      )}
    </>
  );
};

Project.propTypes = {
  labInteractable: PropTypes.object.isRequired,
  bootcamp: PropTypes.object,
  unit: PropTypes.object.isRequired,
  userId: PropTypes.string,
  ...Lab.propTypes,
};

export default Project;
