import moment from 'moment';
import COHORT_STATUS from '../partial/cohortStatuses';
import { getSearchRegex } from '../../../../helpers/regexHelper';

export const statusFilter = (cohorts, filter) => {
  let filtered = [];
  const today = moment();
  const statusCohorts = {
    [COHORT_STATUS.NOT_STARTED]: [],
    [COHORT_STATUS.LIVE]: [],
    [COHORT_STATUS.ENDED]: [],
    [COHORT_STATUS.CANCELED]: [],
  };

  cohorts.forEach(item => {
    if (item.is_canceled) {
      statusCohorts[COHORT_STATUS.CANCELED] = statusCohorts[COHORT_STATUS.CANCELED].concat([item]);
    } else if (moment(item.start_date).diff(today) > 0) {
      statusCohorts[COHORT_STATUS.NOT_STARTED] = statusCohorts[COHORT_STATUS.NOT_STARTED].concat([item]);
    } else if (moment(item.end_date).diff(today) >= 0) {
      statusCohorts[COHORT_STATUS.LIVE] = statusCohorts[COHORT_STATUS.LIVE].concat([item]);
    } else {
      statusCohorts[COHORT_STATUS.ENDED] = statusCohorts[COHORT_STATUS.ENDED].concat([item]);
    }
  });
  Object.keys(statusCohorts).forEach(status => {
    if (filter.includes(status)) {
      filtered = filtered.concat(statusCohorts[status]);
    }
  });
  return filtered;
};

export const syncedFilter = (cohorts, synced) => {
  const filtered = cohorts.filter(cohort => {
    const isSynced = cohort.lti_context.length > 0;
    return synced ? isSynced : !isSynced;
  });
  return filtered === undefined ? [] : filtered;
};

export const warningFilter = (cohorts, filter) => {
  const ltiContextsCohortIds = {};
  let sameContextIdsCohortsIds = [];

  cohorts.forEach(item => {
    if (item.lti_context.length) {
      ltiContextsCohortIds[item.lti_context[0]] =
        ltiContextsCohortIds[item.lti_context[0]] === undefined
          ? [item.id]
          : [item.id].concat(ltiContextsCohortIds[item.lti_context[0]]);
    }
  });
  Object.keys(ltiContextsCohortIds).forEach(contextId => {
    if (ltiContextsCohortIds[contextId].length > 1) {
      sameContextIdsCohortsIds = sameContextIdsCohortsIds.concat(ltiContextsCohortIds[contextId]);
    }
  });
  return cohorts.filter(item => {
    if (filter) {
      return sameContextIdsCohortsIds.includes(item.id);
    }
    return !sameContextIdsCohortsIds.includes(item.id);
  });
};

export const selectFilter = (cohorts, filter, name) => cohorts.filter(item => filter.includes(item[name]));

export const bootcampFilter = (cohorts, filters, name, bootcamps) => {
  return cohorts.filter(cohort => {
    const bootcamp = bootcamps.find(bootcamp => bootcamp.id === cohort.bootcamp_id);
    let match = false;

    filters.forEach(filter => {
      const regex = getSearchRegex(filter);
      switch (name) {
        case 'bootcamp_name':
          match = match || regex.test(bootcamp.title);
          break;
        case 'bootcamp_version':
          match = match || cohort.bootcamp_id === filter;
          break;
      }
    });
    return match;
  });
};
