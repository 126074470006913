import { map as _map, keys as _keys, debounce as _debounce, difference as _diff } from 'lodash';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Errors from '../../Utils/Errors';
import Modal2 from '../../library/Modal2';
import { toastSuccess } from '../../../utils/toastHelper';
import intl from '../../../utils/intl';
import { ReactComponent as SpinnerIcon } from '../../../styles/pathstream/svg-jsx/icon-spinner.svg';
import { getSearchRegex } from '../../../helpers/regexHelper';
import CustomInput from '../../Utils/CustomInput';
import Loading from '../../Utils/Loading';
import Unit from '../../../api/unit';
import { selectUnits } from '../../../selectors/unitSelectors';
import { selectSectionFromUnit } from '../../../selectors/sectionSelectors';
import { selectBootcampFromSection } from '../../../selectors/bootcampSelectors';
import { fetchUnits, copyUnit } from '../../../actions/unitActions';
import FutureCohortsQuizCheckbox from '../../../common/components/FutureCohortsQuizCheckbox/FutureCohortsQuizCheckbox';
import useFutureCohortsQuiz from '../../../common/components/FutureCohortsQuizCheckbox/useFutureCohortsQuiz';

const getFilteredUnits = (units, unitType, searchText) => {
  const regex = getSearchRegex(searchText);

  return units.filter(item => item.unit_type === unitType).filter(item => regex.test(item.title));
};

const selectEnrichedUnits = filteredUnits => s =>
  (filteredUnits || []).map(unit => {
    const section = selectSectionFromUnit(s, unit);
    const bootcamp = selectBootcampFromSection(s, section);
    return { ...unit, section, bootcamp };
  });

const useStore = (unitType, searchText) => {
  const dispatch = useDispatch();
  const units = useSelector(selectUnits);

  const filteredUnits = getFilteredUnits(units, unitType, searchText);
  const unitsWithAssociations = useSelector(selectEnrichedUnits(filteredUnits));

  return {
    dispatch,
    units: unitsWithAssociations,
  };
};

const ImportedBrightspaceQuizCopy = ({ unit, handleCopyClick }) => {
  return (
    <li key={unit.id}>
      <div className="copy">
        <div className="label">
          <p className="hierarchy-parents">Imported Brightspace Quiz</p>

          {unit.title}
        </div>

        <button
          type="button"
          className="button-standard button-standard--blue2 button-standard--short"
          onClick={() => handleCopyClick(unit)}>
          <FormattedMessage id="common.copy" />
        </button>
      </div>
    </li>
  );
};

export default function SelectUnitModal({ section, onHide, unitType }) {
  const [state, setState] = React.useState({
    errors: [],
    searchText: '',
    isSaving: false,
    isLoading: true,
  });
  const { futureCohortsOnly, setFutureCohortsOnly } = useFutureCohortsQuiz();

  const { dispatch, units } = useStore(unitType, state.searchText);

  React.useEffect(() => {
    fetchUnits()(dispatch).then(() => setState({ ...state, isLoading: false }));
  }, []);

  const debouncedChangeSearch = React.useRef(
    _debounce(value => setState(curr => ({ ...curr, searchText: value })), 200),
  );

  const handleCopyClick = unit => {
    setState({ ...state, isSaving: true });
    copyUnit(
      unit,
      section.id,
      futureCohortsOnly,
    )(dispatch)
      .then(() => {
        onHide();
        toastSuccess(
          unitType === Unit.PROJECT
            ? intl.formatMessage({ id: 'unit.messages.projectCopied' })
            : unitType === Unit.GRADED_QUIZ
            ? intl.formatMessage({ id: 'unit.messages.gradedQuizCopied' })
            : intl.formatMessage({ id: 'unit.messages.moduleCopied' }),
        );
      })
      .catch(err => {
        console.error('Error copying module', err);
        const errors = (err.value && err.value.errors) ||
          (err.meta && [err.meta.message]) || [intl.formatMessage({ id: 'unit.messages.unknownError' })];

        setState({ ...state, errors, isSaving: false });
      });
  };

  const { isSaving, isLoading, errors } = state;

  const isImportedBrightspaceQuiz = unit => unit.lti_context_id;

  return (
    <Modal2
      size="big"
      bodyClass=" "
      onOk={null}
      className="select-units modal-auto-grow"
      title={
        unitType === Unit.PROJECT ? (
          <FormattedMessage id="unit.heading.copyExistingProject" />
        ) : unitType === Unit.GRADED_QUIZ ? (
          <FormattedMessage id="unit.heading.copyExistingGradedQuiz" />
        ) : (
          <FormattedMessage id="unit.heading.copyExistingModule" />
        )
      }
      onHide={onHide}>
      {isSaving && (
        <div className="loading-wrapper">
          <FormattedMessage id="common.copying" />
          <span className="icon">
            <SpinnerIcon />
          </span>
        </div>
      )}
      <Errors errors={errors} />

      <CustomInput
        name="search"
        onChange={e => debouncedChangeSearch.current(e.target.value)}
        label={intl.formatMessage({ id: 'common.search' })}
      />

      <br />

      {isLoading && <Loading />}

      <ul>
        {units.length > 0 ? (
          units.map(unit =>
            unit.section && unit.bootcamp ? (
              <li key={unit.id}>
                <div className="copy">
                  <div className="label">
                    <p className="hierarchy-parents">
                      {unit.bootcamp.title} {unit.bootcamp.version && `> ${unit.bootcamp.version}`} &gt;{' '}
                      {unit.section.title}
                    </p>

                    {unit.title}
                  </div>

                  <button
                    type="button"
                    className="button-standard button-standard--blue2 button-standard--short"
                    onClick={() => handleCopyClick(unit)}>
                    <FormattedMessage id="common.copy" />
                  </button>
                </div>
              </li>
            ) : isImportedBrightspaceQuiz(unit) ? (
              <ImportedBrightspaceQuizCopy unit={unit} handleCopyClick={handleCopyClick} />
            ) : null,
          )
        ) : (
          <li>
            <FormattedMessage id="unit.heading.noModules" />
          </li>
        )}
      </ul>
      {unitType === Unit.GRADED_QUIZ && (
        <FutureCohortsQuizCheckbox checked={futureCohortsOnly} onChange={setFutureCohortsOnly} />
      )}
    </Modal2>
  );
}

SelectUnitModal.propTypes = {
  section: PropTypes.object.isRequired,
  onHide: PropTypes.func.isRequired,
  unitType: PropTypes.string.isRequired,
};
