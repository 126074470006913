import React, { useState } from 'react';
import classnames from 'classnames';
import { Modal } from 'antd';
import { useSelector } from 'react-redux';
import { Link, useParams, useHistory } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { RightOutlined } from '@ant-design/icons';
import ComponentProgressesContainer from './ComponentProgressesContainer';
import PartTabs from './PartTabs';
import { selectIsProgressExpanded } from '../../selectors/components/lessonSelectors';
import useCurrentComponent from '../../hooks/useCurrentComponent';
import { getCoursePathByIds } from '../../helpers/pathHelper';
import useCurrentComponentProgress from '../../hooks/useCurrentComponentProgress';
import { getProgressStatus } from '../../utils/utils';
import useNextUrl from '../../hooks/useNextUrl';
import { selectIsEditMode } from '../../selectors/contextSelectors';
import useLesson from '../../hooks/useLesson';
import useCurrentUser from '../../hooks/useCurrentUser';
import SectionHeader from '../Sections/SectionHeader';

/* eslint-disable complexity */
export default function ComponentHeader() {
  const { component: currentComponent, lessonId } = useCurrentComponent();
  const { parts } = useLesson(lessonId);
  const { componentProgress } = useCurrentComponentProgress();
  const { isComplete: isComponentComplete } = getProgressStatus(componentProgress);
  const isModuleProgressExpanded = useSelector(selectIsProgressExpanded);
  const isEditMode = useSelector(selectIsEditMode);
  const nextUrl = useNextUrl(true);
  const history = useHistory();
  const { bootcampId, pathId } = useParams();
  const { currentUser, canAuthor } = useCurrentUser();
  const [isMenuExpanded, setMenuExpanded] = useState(false);

  const onMenuClick = () => {
    if (canAuthor) {
      setMenuExpanded(!isMenuExpanded);
    } else {
      history.push(getCoursePathByIds(currentUser, bootcampId, pathId));
    }
  };

  return (
    <header
      className={classnames(
        'header-bar',
        !isModuleProgressExpanded && 'header-bar--simple',
        canAuthor && 'header-bar--edit-mode',
      )}>
      <Modal visible={isMenuExpanded} width="100%" style={{ top: 0 }} closable={false} footer={null}>
        <SectionHeader onClose={() => setMenuExpanded(!isMenuExpanded)} />
      </Modal>
      <div className="header-bar__wrapper">
        <ComponentProgressesContainer onMenuClick={onMenuClick} />

        {(isEditMode || isComponentComplete || parts.length > 1) && (
          <div className="header-bar__lesson">
            {currentComponent && parts.length > 1 && <PartTabs lessonId={currentComponent.external_id} />}

            {isComponentComplete && nextUrl && (
              <Link to={nextUrl} className="header-bar__continue">
                <span className="header-bar__continue-title">
                  <FormattedMessage id="common.continue" />
                </span>
                <span className="header-bar__continue-icon">
                  <RightOutlined />
                </span>
              </Link>
            )}
          </div>
        )}
      </div>
    </header>
  );
}
/* eslint-enable complexity */
