import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Button } from 'antd';
import { FormattedMessage } from 'react-intl';
import Loading from '../../Utils/Loading';
import ContentWrapperContainer from '../../v2/Nav/ContentWrapperContainer';
import LabInstructions from '../../Student/LabInstructions';
import * as analytics from '../../../utils/analytics';
import LabWrapper from '../../v2/Nav/LabWrapper';
import ProjectInstructions from '../../Projects/ProjectInstructions';
import InstructorGrading from '../InstructorGrading/InstructorGrading';
import FileUploadView from '../FileUploadView/FileUploadView';
import { toastSuccess } from '../../../utils/toastHelper';
import intl from '../../../utils/intl';
import SubmissionProject from './SubmissionProject';
import GradeAssignmentHeader from './GradeAssignmentHeader';

/* eslint-disable-next-line complexity */
const GradeAssignment = ({
  unit,
  assignment,
  lesson,
  unitProgress,
  unitProgressId,
  assignmentProgress,
  fetchUnitProgress,
  gradeAssignmentProgress,
  gradeUnitProgress,
  getUsers,
  history,
  projectFolderPath,
  location,
  backLink,
  fetchAssignment,
}) => {
  const [student, setStudent] = useState(null);
  const [currentProgress, setCurrentProgress] = useState(null);
  const requiresGrading = assignment?.requires_grading;
  const queryParams = new URLSearchParams(location.search);
  const isBrowserApp = queryParams.get('isGrading');
  const [isGrading, setIsGrading] = useState(!!isBrowserApp);

  const isAssigmentAutograderEligible = React.useRef(false);

  useEffect(() => {
    const getUser = async () => {
      const users = await getUsers([unitProgress.user_id]);
      setStudent(users[0]);
    };
    if (unitProgress) {
      getUser();
    }
  }, [unitProgress]);

  useEffect(() => {
    fetchUnitProgress(unitProgressId);
    analytics.pageStart('Grade Assessment');

    return () => {
      analytics.pageEnd(analyticsParams());
      if (window.fcWidget) {
        window.fcWidget.show();
      }
    };
  }, []);

  useEffect(() => {
    if (!assignment && unit?.assignment_id) {
      fetchAssignment(unit?.assignment_id);
    }
  }, [unit?.assignment_id]);

  // Note: Hiding widget on every render because it might not be initialized yet on first render.
  // The widget will appear for a second and then hidden.
  // There is a chance that the widget appears after the hide is called and this will leave the widget on screen.
  // We should find a better way to hide or prevent from showing.
  useEffect(() => {
    if (window.fcWidget) {
      window.fcWidget.hide();
    }
  });

  const analyticsParams = () => ({
    project: unit,
    assignment_progress_id: assignmentProgress?.id,
    assignment_autograder_eligible: isAssigmentAutograderEligible.current,
  });

  const handleGraded = ({ autograderAssigmentEligible }) => {
    toastSuccess(intl.formatMessage({ id: 'grading.submitGradesSuccess' }));
    isAssigmentAutograderEligible.current = autograderAssigmentEligible;
    history.push(backLink);
  };

  const handleBackToGrading = args => {
    if (requiresGrading) {
      setIsGrading(args);
    }
  };

  const renderSubmissionDownload = () => {
    const { file_uploads: fileUploads, link_upload: linkUpload } = assignmentProgress || {};
    if (fileUploads?.length) {
      return <FileUploadView files={fileUploads} />;
    }

    if (linkUpload) {
      const allLinks = linkUpload.split(',');

      return allLinks.map((link, i) => (
        <Button
          className="link-download-btn"
          type="primary"
          role="button"
          data-testid="download-button"
          key={`link-${i}`}
          style={{ marginBottom: '10px' }}>
          <a href={link} download target="_blank">
            <FormattedMessage id="grading.projectTitle">{title => <span>{`${title}${i + 1}`}</span>}</FormattedMessage>
          </a>
        </Button>
      ));
    }
    return null;
  };

  if (!unitProgress || !assignment) {
    return (
      <ContentWrapperContainer className="grading lab-page">
        <Loading />
      </ContentWrapperContainer>
    );
  }

  const shouldShowInstructorGrading = isGrading && requiresGrading && assignmentProgress?.completed_at;

  const getSubmissionProjectParams = () => ({
    assignmentProgress,
    assignment,
    analyticsParams: analyticsParams(),
    projectFolderPath,
    student,
    unit,
    isGrading,
  });

  return (
    <>
      <GradeAssignmentHeader
        assignment={assignment}
        assignmentProgress={assignmentProgress}
        backLink={backLink}
        isGrading={isGrading}
        onToggleGrading={() => setIsGrading(isGrading => !isGrading)}
        student={student}
        unit={unit}
      />

      <LabWrapper app={assignment.app}>
        <div
          className={classnames('new-lab-page new-lab-page__with-header', {
            'new-lab-page__with-grading': shouldShowInstructorGrading,
          })}>
          <main className="new-lab-page__main">
            {shouldShowInstructorGrading ? (
              <InstructorGrading
                assignmentProgress={assignmentProgress}
                unitProgress={unitProgress}
                gradeAssignmentProgress={gradeAssignmentProgress}
                gradeUnitProgress={gradeUnitProgress}
                onGraded={handleGraded}
                currentProgress={currentProgress}
                setCurrentProgress={setCurrentProgress}
              />
            ) : (
              <LabInstructions hasHeader>
                <ProjectInstructions
                  lesson={lesson}
                  assignment={assignment}
                  unit={unit}
                  showBlockTakeQuiz
                  isSidebar
                  hideFooter
                  onHandleBackToGrading={handleBackToGrading}
                />
              </LabInstructions>
            )}
            <section className="new-lab-page__content">
              <div className="new-lab-page__wrapper">
                <div className="lab-image">
                  {isBrowserApp && renderSubmissionDownload()}
                  <SubmissionProject {...getSubmissionProjectParams()} />
                </div>
              </div>
            </section>
          </main>
        </div>
      </LabWrapper>
    </>
  );
};

GradeAssignment.propTypes = {
  unit: PropTypes.object,
  assignment: PropTypes.object,
  lesson: PropTypes.object,
  unitProgress: PropTypes.object,
  unitProgressId: PropTypes.string.isRequired,
  assignmentProgress: PropTypes.object,
  fetchUnitProgress: PropTypes.func.isRequired,
  gradeAssignmentProgress: PropTypes.func.isRequired,
  gradeUnitProgress: PropTypes.func.isRequired,
  getUsers: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  projectFolderPath: PropTypes.string,
  location: PropTypes.object,
  match: PropTypes.object,
  backLink: PropTypes.string,
};

export default GradeAssignment;
