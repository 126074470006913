import { map as _map } from 'lodash';
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Element } from 'react-scroll';
import BlockShowContainer from './BlockShowContainer';
import { getBlockClasses } from '../../helpers/labHelper';
import { selectLesson, selectLessonBlocks } from '../../selectors/lessonSelectors';
import { fetchLesson } from '../../actions/lessonActions';

export default function StepShow({ step }) {
  const dispatch = useDispatch();
  const lessonId = step.lesson_id;
  const lesson = useSelector(state => selectLesson(state, lessonId));
  const blocks = useSelector(state => selectLessonBlocks(state, lessonId));

  useEffect(() => {
    if (!lesson) {
      dispatch(fetchLesson(step.lesson_id));
    }
  }, [step]);

  if (blocks.length === 0) {
    return null;
  }

  return (
    <div>
      <div className="lab-sidebar-body-unit" style={{ paddingBottom: 0 }}></div>

      {_map(blocks, block => (
        <Element
          name={`block_${block.id}`}
          data-testid={`block_${block.id}`}
          key={block.id}
          className={getBlockClasses(block)}>
          <BlockShowContainer {...{ block }} key={block.id} />
        </Element>
      ))}
    </div>
  );
}

StepShow.propTypes = {
  step: PropTypes.object.isRequired,
};
