import React from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getProgress, whatWillIComplete } from '../../../selectors/overallProgressSelector';
import LessonCompletionHeader from './LessonCompletionHeader';
import LessonCompletionDescription from './LessonCompletionDescription';
import LessonCompletionFooter from './LessonCompletionFooter';

export default function LessonCompletionModal({ onCancel }) {
  const { bootcampId, pathId, sectionId, unitId, componentId } = useParams();
  const { currentProgress, nextProgress } = useSelector(s =>
    getProgress(s, { bootcampId, pathId, sectionId, unitId, componentId }),
  );
  const { willCompleteSection, willCompleteModule } = useSelector(s =>
    whatWillIComplete(s, { bootcampId, sectionId, unitId, componentId }),
  );

  const isSectionComplete = currentProgress?.sectionComplete || willCompleteSection;
  const isUnitComplete = currentProgress?.unitComplete || willCompleteModule;

  if (!currentProgress) return '';

  return (
    <>
      <div className="subject-congrats-wrapper subject-congrats-wrapper--opened" tabIndex="-1" role="dialog">
        <div className="subject-congrats" role="document">
          <div className="subject-congrats__content">
            <section className="subject-congrats__panel subject-congrats__panel--dark">
              <LessonCompletionHeader isSectionComplete={isSectionComplete} isUnitComplete={isUnitComplete} />
              <LessonCompletionDescription
                currentProgress={currentProgress}
                isSectionComplete={isSectionComplete}
                isUnitComplete={isUnitComplete}
              />
              <LessonCompletionFooter
                currentProgress={currentProgress}
                nextProgress={nextProgress}
                isSectionComplete={isSectionComplete}
                isUnitComplete={isUnitComplete}
                onCancel={onCancel}
              />
            </section>
          </div>
        </div>
      </div>
    </>
  );
}

LessonCompletionModal.propTypes = {
  onCancel: PropTypes.func,
};
