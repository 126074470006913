import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { CheckCircleFilled } from '@ant-design/icons';
import UserPicture from '../../../../Utils/UserPicture';
import Enrollment from '../../../../../api/enrollment';

const StudentListItem = ({ student }) => {
  const getCardInfo = () => {
    let cardText;
    let cardIcon;
    let avatarClassName = 'student-avatar';

    switch (student.enrollment.status) {
      case Enrollment.COMPLETED:
        cardText = <FormattedMessage id="cohortDashboard.studentsPage.completedStatus" />;
        cardIcon = <CheckCircleFilled className="completed-course-icon" data-testid="completed-course-icon" />;
        break;
      case Enrollment.DROPPED:
        cardText = (
          <FormattedMessage className="student-card-description-text" id="cohortDashboard.studentsPage.droppedStatus" />
        );
        cardIcon = null;
        break;
      case Enrollment.ENROLLED:
        const ungradedCount = student.ungraded_assignments_count;

        cardText = student.watchList.inWatchList && (
          <span className="in-watch-list-reason-text" data-testid="watch-list-reason">
            {student.watchList.reasons[0]}
          </span>
        );
        cardIcon = ungradedCount > 0 && (
          <span className="ungraded-count-icon" data-testid="ungraded-count">
            {ungradedCount}
          </span>
        );
        if (student.watchList.inWatchList) {
          avatarClassName = 'student-avatar__watched';
        }
        break;
      default:
        cardText = null;
        cardIcon = null;
        break;
    }
    return {
      cardText,
      cardIcon,
      avatarClassName,
    };
  };

  const { avatarClassName, cardText, cardIcon } = getCardInfo();
  return (
    <div className="cohort-dashboard-student-list-item">
      <div className="student-list-item-left-section">
        <div className={avatarClassName} data-testid="student-avatar">
          <UserPicture user={student} className="user-profile-picture" />
        </div>
        <div className="student-name-section">
          <span className="student-name" data-testid="student-name">
            {student.name}
          </span>
          <span className="student-card-description-text" data-testid="student-description-text">
            {cardText}
          </span>
        </div>
      </div>
      <div className="student-list-item-right-section">
        <span className="student-card-icon" data-testid="student-card-icon">
          {cardIcon}
        </span>
      </div>
    </div>
  );
};

export default StudentListItem;

StudentListItem.propTypes = {
  student: PropTypes.object.isRequired,
};
