import React from 'react';
import PropTypes from 'prop-types';
import ModalContent from './ModalContent';

export default function LabCompletionModal({ steps, formData, onSubmit, onCancel }) {
  const { lab_classification: labClassification } = formData;

  const handleCancel = () => {
    if (onCancel) onCancel();
  };

  return (
    <>
      <div className="lab-congrats-wrapper lab-congrats-wrapper--opened" tabIndex="-1" role="dialog">
        <div className="lab-congrats" role="document">
          <div className="lab-congrats__content">
            <ModalContent
              labClassification={labClassification}
              formData={formData}
              onSubmit={onSubmit}
              onCancel={handleCancel}
              showCancel={steps.length > 0}
            />
          </div>
        </div>
      </div>
    </>
  );
}

LabCompletionModal.propTypes = {
  steps: PropTypes.array,
  formData: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
};

LabCompletionModal.defaultProps = {
  steps: [],
};
