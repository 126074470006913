import React, { Component } from 'react';
import Modal2 from '../components/library/Modal2';
import showModal from './showModal';
import { FormattedMessage } from 'react-intl';

class ConfirmModal extends Component {
  static defaultProps = {
    title: <FormattedMessage id="common.confirmTitle" />,
    okTitle: <FormattedMessage id="common.ok" />,
    showCancel: true,
  };

  componentDidMount() {
    const { timeout } = this.props;
    if (timeout) {
      this.timeoutHandle = setTimeout(() => this.handleCancel(true), timeout);
    }
  }

  componentWillUnmount() {
    this.clearHandle();
  }

  clearHandle = () => {
    if (this.timeoutHandle) {
      clearTimeout(this.timeoutHandle);
    }
  };

  handleSuccess = () => {
    const { onOk, onHide } = this.props;
    this.clearHandle();
    onOk();
    onHide();
  };

  handleCancel = hideModal => {
    const { onCancel, onHide } = this.props;
    onCancel && onCancel();
    if (hideModal) {
      onHide();
    }
  };

  render() {
    const { message, title, okTitle, cancelTitle, showCancel } = this.props;

    return (
      <Modal2
        size="medium"
        onHide={this.props.onHide}
        showClose={false}
        onOk={this.handleSuccess}
        onCancel={this.handleCancel}
        okTitle={okTitle}
        cancelTitle={cancelTitle}
        showCancel={showCancel}
        title={title}
        footerClass="modal-dialog-footer--space-between buttons-lessons">
        {message}
      </Modal2>
    );
  }
}

export const confirmInput = ({ message, onOk, timeout, onCancel, title, okTitle, cancelTitle, showCancel }) => {
  return showModal(ConfirmModal, {
    onOk,
    message,
    timeout,
    onCancel,
    title,
    okTitle,
    cancelTitle,
    showCancel,
  });
};
