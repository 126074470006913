import React from 'react';
import PropTypes from 'prop-types';
import { useParams, Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import useCurrentUser from '../../../hooks/useCurrentUser';
import { getCoursePathByIds } from '../../../helpers/pathHelper';
import Icons from '../../Utils/Icons';
import { formatDuration } from '../../../utils/utils';
import Unit from '../../../api/unit';
import useAssignmentProgress from '../../../hooks/useAssignmentProgress';
import { RightOutlined } from '@ant-design/icons';

/* eslint complexity: off */
export default function LessonFooter({ currentProgress, nextProgress, nextUrl, isUnitComplete, onCancel }) {
  const { bootcampId, pathId } = useParams();
  const nextComponent = nextProgress && nextProgress.component;
  const { currentUser } = useCurrentUser();
  const bootcampUrl = getCoursePathByIds(currentUser, bootcampId, pathId);
  const { launchFromLMS, prerequisiteComplete } = useAssignmentProgress(
    nextProgress && nextProgress.unit.assignment_id,
  );
  const isProjectNext = nextProgress && nextProgress.unit.unit_type === Unit.PROJECT;
  const isGradedQuizNext = nextProgress && nextProgress.unit.unit_type === Unit.GRADED_QUIZ;

  const renderNextLesson = () => (
    <div className="subject-congrats__subject">
      <h6 className="subject-congrats__subject-title">
        <FormattedMessage id="completionModal.nextLesson" />
      </h6>
      <h6 className="subject-congrats__subject-description">
        <Icons.ClockBlack />
        {formatDuration(nextComponent.duration)}
      </h6>
      <h6 className="subject-congrats__subject-heading">{nextComponent.title}</h6>
    </div>
  );

  const renderNextProject = () => (
    <div className="subject-congrats__subject">
      <h6 className="subject-congrats__subject-title">
        <FormattedMessage id="completionModal.nextProject" />
      </h6>
      <h6 className="subject-congrats__subject-heading">
        <Icons.Project /> {nextProgress.unit.title}
      </h6>
    </div>
  );

  const renderNextGradedQuiz = () => (
    <div className="subject-congrats__subject">
      <h6 className="subject-congrats__subject-title">
        <FormattedMessage id="completionModal.nextGradedQuiz" />
      </h6>
      <h6 className="subject-congrats__subject-heading">
        <Icons.GradedQuiz /> {nextProgress.unit.title}
      </h6>
    </div>
  );

  return (
    <>
      {nextComponent && nextProgress.unit !== currentProgress.unit && (
        <div className="subject-congrats__module">
          <h6 className="subject-congrats__module-heading">{nextProgress.unit.title}</h6>
        </div>
      )}
      <div className="subject-congrats__next-step">
        {nextComponent && renderNextLesson()}
        {isProjectNext && renderNextProject()}
        {isGradedQuizNext && renderNextGradedQuiz()}

        <div className="subject-congrats__actions">
          <div className="subject-congrats__actions-wrapper">
            {(nextComponent || (isProjectNext && !launchFromLMS && prerequisiteComplete)) && (
              <Link
                to={nextUrl}
                tabIndex="0"
                aria-label={
                  nextComponent?.unit ? `link to ${nextComponent?.unit.title}` : `link to ${nextComponent?.title}`
                }
                className="subject-congrats__actions-button">
                <RightOutlined />
              </Link>
            )}
            {isProjectNext && launchFromLMS && <FormattedMessage id="completionModal.additionalFooter.launchFromLMS" />}
          </div>
        </div>
      </div>

      {isUnitComplete ? (
        <Link to={bootcampUrl} className="subject-congrats__actions-link" tabIndex="0">
          <FormattedMessage id="nav.backToBootcamp" />
        </Link>
      ) : (
        <button type="button" onClick={onCancel} className="subject-congrats__actions-link">
          <FormattedMessage id="nav.backToLesson" />
        </button>
      )}
    </>
  );
}

LessonFooter.propTypes = {
  nextUrl: PropTypes.string.isRequired,
  nextProgress: PropTypes.object,
  currentProgress: PropTypes.object.isRequired,
  isUnitComplete: PropTypes.bool.isRequired,
  onCancel: PropTypes.func,
};
