import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { scroller } from 'react-scroll';
import { getPathPrefix } from '../../helpers/pathHelper';
import history from '../../history';
import { selectCurrentUser } from '../../selectors/authSelectors';

export default function BootcampHrefTranslator({ children }) {
  const currentUser = useSelector(selectCurrentUser);
  const containerRef = useRef(null);
  const handleClick = e => {
    const clickWasOnALink = e.target.tagName === 'A';
    if (!clickWasOnALink) return;

    const { href, target, hostname, hash, search, pathname } = e.target;

    const linkToDifferentDomain = hostname && hostname !== window.location.hostname;
    if (!href || linkToDifferentDomain) return;

    const bootcampUrlPrefixRegEx = /^\/(author|student|coach)\/bootcamp/;
    const isBootcampLink = pathname.search(bootcampUrlPrefixRegEx) >= 0;

    if (!isBootcampLink) return;

    e.preventDefault();

    const pathPrefix = getPathPrefix(currentUser);
    const fixedPathname = pathname.replace(bootcampUrlPrefixRegEx, `${pathPrefix}/bootcamp`);

    const fixedPath = `${fixedPathname}${search}${hash}`;

    const isSetToOpenInANewTab = target === '_blank';
    if (isSetToOpenInANewTab) {
      window.open(fixedPath, '_blank');
      return;
    }

    history.push(fixedPath);
    if (hash) {
      // hash contains # sign
      const urlPath = hash.substring(1);
      // set a timer for awaiting the child component mount
      setTimeout(() => scroller.scrollTo(urlPath), 200);
    }
  };

  useEffect(() => {
    containerRef.current.addEventListener('click', handleClick);
    return () => {
      containerRef.current.removeEventListener('click', handleClick);
    };
  }, [containerRef, currentUser]);

  return <div ref={containerRef}>{children}</div>;
}

BootcampHrefTranslator.propTypes = {
  children: PropTypes.node.isRequired,
};
