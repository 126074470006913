import { map as _map } from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import SectionTileHeaderContainer from '../../../Sections/SectionTileHeaderContainer';
import UnitTile from './UnitTile';
import { selectSectionUnits } from '../../../../selectors/sectionSelectors';
import Unit from '../../../../api/unit';

export default function SectionTile({ section, block, index, onSelect }) {
  const units = useSelector(s => selectSectionUnits(s, section.id)).filter(unit => unit.unit_type === Unit.MODULE);

  return (
    <>
      <SectionTileHeaderContainer section={section} index={index} />
      {_map(units, (unit, unitIndex) => (
        <UnitTile key={unit.id} unit={unit} index={unitIndex} onSelect={onSelect} block={block} />
      ))}
    </>
  );
}

SectionTile.propTypes = {
  section: PropTypes.object.isRequired,
  block: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  onSelect: PropTypes.func.isRequired,
};
