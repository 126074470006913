import { connect } from 'react-redux';
import { selectCohortEnrollments } from '../../../selectors/enrollmentSelectors';
import { saveEnrollment, destroyEnrollment, fetchEnrollments } from '../../../actions/enrollmentActions';
import EnrollStudent from './EnrollStudent';

const mapStateToProps = (state, ownProps) => {
  const { cohort } = ownProps;

  const enrollments = selectCohortEnrollments(state, cohort.id).filter(e => !e.deleted_at);
  const userIds = enrollments.map(e => e.user_id);

  return {
    userIds,
    enrollments,
  };
};

export default connect(mapStateToProps, {
  saveEnrollment,
  destroyEnrollment,
  fetchEnrollments,
})(EnrollStudent);
