import React from 'react';
import PropTypes from 'prop-types';
import { ClockCircleFilled, CheckCircleFilled } from '@ant-design/icons';
import { FormattedMessage } from 'react-intl';
import CohortInfoTooltip from '../../CohortInfoTooltip';
import { daysLeftToFinishGrading, isCohortEnded, isCohortInGradingProcess } from './helpers';

const millisecondsInADay = 86400000;

const CohortCompletedStatus = ({ cohort }) => {
  if (isCohortInGradingProcess(cohort)) {
    const daysLeft = daysLeftToFinishGrading(cohort);

    return (
      <div className="cohort-status">
        <ClockCircleFilled /> <FormattedMessage id="cohort.numDaysLeftToFinishGrading" values={{ count: daysLeft }} />
      </div>
    );
  }

  return (
    <div className="cohort-status">
      <CheckCircleFilled /> <FormattedMessage id="cohort.courseCompletedForCohort" />
    </div>
  );
};

const CohortInProgressStatus = ({ cohort }) => {
  const { end_date: endDate } = cohort;
  const msLeft = new Date(endDate) - Date.now();
  const daysLeft = Math.floor(msLeft / millisecondsInADay);
  const weeksLeft = Math.floor(daysLeft / 7);

  if (weeksLeft > 0) {
    return (
      <div className="cohort-status">
        <ClockCircleFilled /> <FormattedMessage id="cohort.numWeeksLeft" values={{ count: weeksLeft }} />
      </div>
    );
  }

  return (
    <div className="cohort-status">
      <ClockCircleFilled /> <FormattedMessage id="cohort.numDaysLeft" values={{ count: daysLeft }} />
    </div>
  );
};

CohortInProgressStatus.propTypes = {
  cohort: PropTypes.object.isRequired,
};

const Header = ({ cohort, hideTooltip }) => {
  const renderCohortStatus = () => {
    if (isCohortEnded(cohort)) {
      return <CohortCompletedStatus cohort={cohort} />;
    }

    return <CohortInProgressStatus cohort={cohort} />;
  };

  return (
    <div className="cohort-item-header" data-testid="cohort-item-header">
      <div className="cohort-name">
        <span>{cohort.location}</span>
        {!hideTooltip && <CohortInfoTooltip cohort={cohort} />}
      </div>

      {renderCohortStatus()}
    </div>
  );
};

Header.propTypes = {
  cohort: PropTypes.object.isRequired,
  hideTooltip: PropTypes.bool,
};

export default Header;
