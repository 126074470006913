import { Component } from 'react';
import PropTypes from 'prop-types';

export default class Logout extends Component {
  componentDidMount() {
    this.props.signOut();
  }

  render() {
    return null;
  }
}

Logout.propTypes = {
  signOut: PropTypes.func.isRequired,
};
