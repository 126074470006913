/* eslint-disable max-lines */
// TODO rewrite to smaller components
import { keyBy as _keyBy } from 'lodash';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { Button, Drawer } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { getRatio } from '../../../utils/utils';
import intl from '../../../utils/intl';
import { toastError } from '../../../utils/toastHelper';
import GradeSelection from './components/GradeSelection/GradeSelection';
import InstructorGradingHeader from './components/InstructorGradingHeader/InstructorGradingHeader';
import GradingSummary from './components/GradingSummary/GradingSummary';
import Rubric from '../../Projects/Rubric';
import { selectOldestEnabledCriteriaByAssignment } from '../../../selectors/criterionSelectors';
import { selectAssignmentFromAssignmentProgress } from '../../../selectors/assignmentSelectors';
import { selectCohortForEnrollment } from '../../../selectors/cohortSelectors';
import { GRADING_RANGE_MAX, TOAST_AUTO_CLOSE_MS } from '../../../common/constants';
import { fetchEnrollment } from '../../../actions/enrollmentActions';
import { selectEnrollment } from '../../../selectors/enrollmentSelectors';
import useFeatureToggle from '../../../hooks/useFeatureToggle';
import { useAutograderRulesetByAssignmentId } from '../../../autograder/hooks/useAutograderRuleset';
import { runRulesetChecks } from '../../../autograder/utils/googleSheetsGraderHelper';
import { loadGoogleAPI } from '../../../autograder/utils/googleAPILoader';

const addScores = criterionProgresses => {
  let totalScorePossible = 0;

  const getTotalScoreReducer = (acc, criterion) => {
    const currGrade = parseInt(criterion.score, 10);
    if (currGrade >= 0) {
      totalScorePossible += 1;
      if (acc && acc > 0) {
        return acc + currGrade;
      }
      return currGrade;
    }
    return acc;
  };

  const totalScore = criterionProgresses.reduce(getTotalScoreReducer, null);
  return getRatio(totalScore, totalScorePossible * 10);
};

const checkGradedAll = criterionProgresses =>
  criterionProgresses.reduce((acc, criterion) => (parseInt(criterion.score, 10) >= 0 ? acc + 1 : acc), 0) ===
  criterionProgresses.length;

const buildCriterionProgress = criterion => ({
  criterion_id: criterion.id,
  score: null,
  comment: null,
});

const buildCriterionProgresses = (criteria, assignmentProgress) => {
  const criterionProgresses = _keyBy(assignmentProgress.criterion_progresses, 'criterion_id');
  return criteria.map(
    criterion => criterionProgresses[criterion.id] || buildCriterionProgress(criterion, assignmentProgress),
  );
};

const useCurrentCohortId = enrollmentId => {
  const dispatch = useDispatch();
  const enrollment = useSelector(state => selectEnrollment(state, enrollmentId));

  useEffect(() => {
    if (enrollment || !enrollmentId) {
      return;
    }
    fetchEnrollment(enrollmentId)(dispatch);
  });

  // eslint-disable-next-line camelcase
  const cohortId = enrollment?.enrollable_id;
  return cohortId;
};

const getAutograderResultByCriterion = (autograderResults, criterionId) => {
  const foundIndex = autograderResults.findIndex(result => result.criterionId === criterionId);
  if (foundIndex < 0) {
    return { isUnmappedRuleset: true };
  }
  return autograderResults[foundIndex];
};

const InstructorGrading = ({
  assignmentProgress,
  gradeAssignmentProgress,
  gradeUnitProgress,
  unitProgress,
  onGraded,
  currentProgress,
  setCurrentProgress,
}) => {
  const enrollmentId = unitProgress.context;
  const cohort = useSelector(state => selectCohortForEnrollment(state, enrollmentId));

  // TODO: Remove cohort feature toggle for autograder once all the autogradeable cohorts have been enabled on the cohort form
  const cohortId = useCurrentCohortId(enrollmentId);
  const { shouldShowAutograder } = useFeatureToggle();
  const isAutograderAllowed = cohort?.is_autograder_enabled || shouldShowAutograder(cohortId);

  const { comments, score } = currentProgress || assignmentProgress;
  const assignment = useSelector(state => selectAssignmentFromAssignmentProgress(state, assignmentProgress));
  const rubric = useSelector(state => selectOldestEnabledCriteriaByAssignment(state, assignment));
  const criterionProgresses =
    (currentProgress && currentProgress.criterionProgresses) || buildCriterionProgresses(rubric, assignmentProgress);

  const assignmentGraded = checkGradedAll(criterionProgresses);
  const totalGrade = score || addScores(criterionProgresses);

  const criteriaById = rubric.reduce((acc, curr) => {
    acc[curr.id] = curr;
    return acc;
  }, {});

  const [isHeaderOnly, setIsHeaderOnly] = useState(false);
  const [showRubric, setShowRubric] = useState(false);
  const [goToGradeSummary, setGoToGradeSummary] = useState(assignmentGraded);
  const [isSaving, setIsSaving] = useState(false);
  const [currentCriterionProgress, setCurrentCriterionProgress] = useState(criterionProgresses[0]);
  const [allComments, setAllComments] = useState(comments);
  const [gradedAll, setGradedAll] = useState(assignmentGraded);

  const autograderRuleset = useAutograderRulesetByAssignmentId(assignmentProgress.assignment_id);
  const [autograderResults, setAutograderResults] = useState();
  const [autograderRulesetCriterionResult, setAutograderRulesetCriterionResult] = useState({});

  // Autograder checks process
  useEffect(() => {
    const submissionURL = assignmentProgress?.user_project_url;
    const generateResult = async () => {
      await loadGoogleAPI();
      const results = await runRulesetChecks(autograderRuleset, submissionURL);
      setAutograderResults(results);
    };

    if (autograderRuleset && !autograderResults && isAutograderAllowed && submissionURL) {
      generateResult();
    }
  }, [autograderRuleset, autograderResults, isAutograderAllowed, assignmentProgress.user_project_url]);

  // Setting autograder rule depending on current criterion progress
  useEffect(() => {
    if (autograderResults && currentCriterionProgress?.criterion_id) {
      const rulesetCriterionResult = getAutograderResultByCriterion(
        autograderResults,
        currentCriterionProgress?.criterion_id,
      );
      setAutograderRulesetCriterionResult(rulesetCriterionResult);
    }
  }, [autograderResults, currentCriterionProgress]);

  const handleAllComments = e => {
    setAllComments(e.target.value);
  };

  const handleSaveGrade = ({ criterion }) => {
    const gradingCrit = criterionProgresses.slice(0);

    // update current grade
    const foundIndex = gradingCrit.findIndex(grade => grade.criterion_id === criterion.criterion_id);
    if (foundIndex >= 0) {
      gradingCrit[foundIndex] = {
        ...gradingCrit[foundIndex],
        ...criterion,
      };
    }

    setCurrentCriterionProgress(criterion);
    setGradedAll(checkGradedAll(gradingCrit));
    setCurrentProgress({
      criterionProgresses: gradingCrit,
      score: addScores(gradingCrit),
      comments: allComments,
    });
  };

  const handleSubmit = async () => {
    // data munging to its original form for sending data via api
    const data = {
      ...assignmentProgress,
      score: totalGrade,
      comments: allComments,
      criterion_progresses: criterionProgresses,
    };
    setIsSaving(true);

    try {
      await Promise.all([gradeAssignmentProgress(data), gradeUnitProgress(unitProgress.id, { score: totalGrade })]);
      onGraded({ autograderAssigmentEligible: !!autograderRuleset });
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log('error', err);
      toastError(intl.formatMessage({ id: 'grading.submitGradesError' }, { autoClose: TOAST_AUTO_CLOSE_MS }));
    } finally {
      setIsSaving(false);
    }
  };

  const onClose = () => setIsHeaderOnly(true);

  const toggleDrawer = () => {
    setIsHeaderOnly(!isHeaderOnly);
  };

  const toggleRubric = () => {
    setShowRubric(!showRubric);
  };

  const handleProgressChange = () => {
    const foundIndex = criterionProgresses.findIndex(
      criterion => criterion.criterion_id === currentCriterionProgress.criterion_id,
    );
    let nextGrade = null;
    let nextIndex = null;

    if (foundIndex >= 0) {
      nextIndex = foundIndex + 1;
      nextGrade = criterionProgresses[nextIndex];
    }

    if (nextIndex < criterionProgresses.length && !gradedAll && totalGrade !== null && totalGrade >= 0) {
      setCurrentCriterionProgress(nextGrade);
    } else {
      setGoToGradeSummary(true);
    }
  };

  const handleGoBackToPrev = () => {
    if (goToGradeSummary) setGoToGradeSummary(false);
    else {
      const foundIndex = criterionProgresses.findIndex(
        grade => grade.criterion_id === currentCriterionProgress.criterion_id,
      );
      setGoToGradeSummary(false);
      setCurrentCriterionProgress(criterionProgresses[foundIndex - 1]);
    }
  };

  const RubricInfo = () => (
    <div
      className="header-child rubric-msg"
      onClick={toggleRubric}
      onKeyPress={toggleRubric}
      role="button"
      tabIndex="0">
      <FormattedMessage id={`grading.${showRubric ? 'hideRubric' : 'showRubric'}`} />
    </div>
  );

  const renderHeader = () => {
    const foundIndex = criterionProgresses.findIndex(
      grade => grade.criterion_id === currentCriterionProgress.criterion_id,
    );
    const foundCriterion = criteriaById[currentCriterionProgress.criterion_id];
    const criterion = {
      id: foundIndex + 1,
      name: foundCriterion ? foundCriterion.name : '',
    };

    return (
      <InstructorGradingHeader
        showRubric={showRubric}
        toggleRubric={toggleRubric}
        totalGrade={totalGrade}
        canViewSummary={gradedAll || criterionProgresses.length - 1 === foundIndex}
        onProgressChange={handleProgressChange}
        showProgress={!goToGradeSummary}>
        {goToGradeSummary ? (
          <InstructorGradingHeader.GradingSummary goBackToPrev={handleGoBackToPrev} />
        ) : (
          <InstructorGradingHeader.Criterion
            criterion={criterion}
            goBackToPrev={handleGoBackToPrev}
            showBackLink={foundIndex > 0}
          />
        )}
        {(!isAutograderAllowed || !autograderRuleset) && <RubricInfo />}
      </InstructorGradingHeader>
    );
  };

  const renderContent = () => {
    if (showRubric) return <Rubric assignment={assignment} />;
    if (goToGradeSummary) {
      return (
        <GradingSummary
          isSaving={isSaving}
          criterionProgresses={criterionProgresses}
          comments={allComments}
          totalGrade={totalGrade}
          rubric={rubric}
          isGradedAll={gradedAll}
          saveGrade={handleSaveGrade}
          onSaveComments={handleAllComments}
          onSubmit={handleSubmit}
        />
      );
    }

    const autoRulesetCritRes = isAutograderAllowed && autograderRuleset ? autograderRulesetCriterionResult : null;
    return (
      <GradeSelection
        scale={GRADING_RANGE_MAX}
        rubric={rubric}
        saveGrade={handleSaveGrade}
        criterionProgress={currentCriterionProgress}
        criterion={criteriaById[currentCriterionProgress.criterion_id]}
        autograderRulesetCriterionResult={autoRulesetCritRes}
      />
    );
  };

  const renderGradingDrawer = () => {
    let height = '240';
    if (showRubric || goToGradeSummary) height = '390';
    if (isHeaderOnly) height = '54';

    return (
      <Drawer
        title={renderHeader()}
        className="grading-drawer"
        headerStyle={{ backgroundColor: '#efefef' }}
        placement="bottom"
        height={height}
        mask={false}
        closable={false}
        onClose={onClose}
        visible>
        {isHeaderOnly ? null : renderContent()}
      </Drawer>
    );
  };

  const buttonToggleClass = classnames('drawer-toggle', {
    'header-only': isHeaderOnly,
    'header-expand': !isHeaderOnly && (showRubric || goToGradeSummary),
  });

  return (
    <div className="instructor-grading">
      <Button className={buttonToggleClass} onClick={toggleDrawer}>
        {isHeaderOnly ? (
          <>
            <UpOutlined />
            <FormattedMessage id="grading.showDrawer" />
          </>
        ) : (
          <>
            <DownOutlined />
            <FormattedMessage id="grading.hideDrawer" />
          </>
        )}
      </Button>
      {renderGradingDrawer()}
    </div>
  );
};

InstructorGrading.propTypes = {
  assignmentProgress: PropTypes.object,
  unitProgress: PropTypes.object,
  gradeAssignmentProgress: PropTypes.func,
  gradeUnitProgress: PropTypes.func,
  onGraded: PropTypes.func,
  currentProgress: PropTypes.object,
  setCurrentProgress: PropTypes.func,
};

export default InstructorGrading;
