import AutograderRuleset from '../api/autograderRuleset';
import {
  applicationSave,
  applicationFetch,
  applicationDestroy,
  applicationUpdate,
  applicationFetchAll,
} from './applicationActions';

export const saveAutograderRuleset = applicationSave(AutograderRuleset);
export const updateAutograderRuleset = applicationUpdate(AutograderRuleset);
export const fetchAutograderRuleset = applicationFetch(AutograderRuleset);
export const fetchAllAutograderRuleset = applicationFetchAll(AutograderRuleset);
export const destroyAutograderRuleset = applicationDestroy(AutograderRuleset);
