import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import classnames from 'classnames';
import intl from '../../utils/intl';
import { SidebarIntro } from '../Labs/LabIntro';
import { shouldHaveNavigation } from '../../utils/navigation';
import LabSidebarToggle from '../Labs/LabSidebarToggle/LabSidebarToggle';
import BackLink from '../Utils/BackLink';
import { FormattedMessage } from 'react-intl';

const LabInstructions = props => {
  const { backLink, title, hasHeader, isVisible } = props;
  useEffect(() => ReactTooltip.rebuild());

  if (!isVisible) {
    return null;
  }

  const labSidebarClass = classnames('lab-sidebar', hasHeader && 'with-header');
  return (
    <aside className="new-lab-page__sidebar">
      <SidebarIntro />
      <LabSidebarToggle />
      <div className={labSidebarClass}>
        <div className="lab-sidebar__wrapper" id="labSidebarWrapper">
          {!hasHeader && (
            <header className="lab-sidebar__header">
              <div className="lab-header lab-header--small">
                {shouldHaveNavigation() && backLink && (
                  <div className="lab-header__back">
                    <BackLink
                      url={backLink}
                      className="go-back-wrapper---main-blue-600"
                      text={
                        <span className="go-back-button-label">
                          <FormattedMessage id="nav.courses" />
                        </span>
                      }
                    />
                  </div>
                )}
                <div
                  className="lab-header__title  lab-header__title--left"
                  dangerouslySetInnerHTML={{
                    __html: title || intl.formatMessage({ id: 'tutorial.heading.instructions' }),
                  }}
                />
              </div>
            </header>
          )}

          <article className="lab-exercise">{props.children}</article>
        </div>
      </div>
    </aside>
  );
};

LabInstructions.propTypes = {
  isVisible: PropTypes.bool,
  children: PropTypes.node,
  title: PropTypes.string,
  backLink: PropTypes.string,
  hasHeader: PropTypes.bool,
};

LabInstructions.defaultProps = {
  isVisible: true,
};

export default LabInstructions;
