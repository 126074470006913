import React, { useState } from 'react';
import { Redirect, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Errors from '../Utils/Errors';
import { getDefaultPath } from '../../helpers/pathHelper';
import CustomInput from '../Utils/CustomInput';
import intl from '../../utils/intl';
import Loading from '../Utils/Loading';
import Template from './Template';

export default function LoginDisplay({ signIn, auth, location, history }) {
  const [user, setUser] = useState({ email: '', password: '' });
  const [error, setError] = useState(null);
  const [lockedOut, setLockedOut] = useState(false);

  const getError = () => {
    if (error) return [error];

    const expired = !!new URLSearchParams(location.search).get('expired');
    if (expired) {
      return [<FormattedMessage id="user.logout.expired" />];
    }
  };

  const handleChange = name => e => {
    setUser({
      ...user,
      [name]: e.target.value,
    });
  };

  const handleSubmit = e => {
    e.preventDefault();

    if (auth.loggingIn) {
      return;
    }

    signIn(user.email, user.password).catch(err => {
      if (err.reset_token) {
        history.push(`/password/${err.reset_token}?change=1`);
      } else {
        const error = err.error || <FormattedMessage id="user.login.errorLoggingIn" />;
        setLockedOut(err.locked_out);
        setError(error);
      }
    });
  };

  const renderLoginForm = () => (
    <Template
      onSubmit={handleSubmit}
      welcomeMsgId="user.login.welcomeMsgId"
      taglineId="user.login.taglineId"
      formHeader="user.login.heading"
      formTagline="user.login.continueToPathstream"
      centerTagline>
      <div className="modal-dialog-body modal-dialog-body--small ">
        <Errors errors={getError()} />
        {!lockedOut && (
          <>
            {auth.loggingIn && <Loading messageId="user.login.loading" />}

            <CustomInput
              name="email"
              autoFocus
              value={user.email}
              data-testid="login-input"
              label={intl.formatMessage({ id: 'user.form.email' })}
              onChange={handleChange('email')}
            />

            <CustomInput
              name="password"
              type="password"
              value={user.password}
              data-testid="login-input"
              label={intl.formatMessage({ id: 'user.form.password' })}
              onChange={handleChange('password')}
            />

            <Link to="/forgotPassword" className="forgot-password-link">
              <FormattedMessage id="user.login.forgotPassword" />
            </Link>
          </>
        )}
      </div>

      {!lockedOut && (
        <div className="modal-dialog-footer modal-dialog-footer--centered">
          <button type="submit" className="button-standard button-standard--blue2 button-standard--medium new-auth-btn">
            <p>
              <FormattedMessage id="user.login.submitBtn" />
            </p>
          </button>
        </div>
      )}
      <p className="terms-of-use">
        <FormattedMessage id="user.login.terms1" />{' '}
        <a href="https://pathstream.com/terms-of-use/" target="blank">
          <FormattedMessage id="user.login.terms2" />
        </a>{' '}
        <FormattedMessage id="user.login.terms3" />{' '}
        <a href="https://pathstream.com/privacy-policy/" target="blank">
          <FormattedMessage id="user.login.terms4" />
        </a>
      </p>
    </Template>
  );

  if (auth.signedIn) {
    const { from } = location.state || { from: { pathname: getDefaultPath(auth.user) } };
    return <Redirect to={from} />;
  }
  return renderLoginForm();
}

LoginDisplay.propTypes = {
  signIn: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};
