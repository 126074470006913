import { useEffect, useState } from 'react';
import { useAutograderDispatch, useAutograderSelector } from '../api/store';
import { fetchAllAutograderRuleset, fetchAutograderRuleset } from '../../actions/autograderRulesetActions';
import {
  selectAutograderRulesetData,
  selectAutograderRulesets,
} from '../../selectors/components/autograderRulesetSelector';
import { toastException } from '../../utils/toastHelper';

export const useAutograderRuleset = rulesetId => {
  const dispatch = useAutograderDispatch();
  const ruleset = useAutograderSelector(state => selectAutograderRulesetData(state, rulesetId));

  useEffect(() => {
    try {
      if (!ruleset) {
        fetchAutograderRuleset(rulesetId)(dispatch);
      }
    } catch (errorMessage) {
      toastException(errorMessage, 'Unable to fetch ruleset');
    }
  }, [dispatch, rulesetId, ruleset]);

  return ruleset;
};

export const useAutograderRulesetByAssignmentId = id => {
  const dispatch = useAutograderDispatch();
  const [rulesetId, setRulesetId] = useState();
  const ruleset = useAutograderSelector(state => selectAutograderRulesetData(state, rulesetId));

  useEffect(() => {
    const fetch = async () => {
      const response = await fetchAutograderRuleset(id, false)(dispatch);
      setRulesetId(response.id);
    };
    try {
      if (ruleset?.assignmentId !== id) {
        fetch();
      }
    } catch (errorMessage) {
      toastException(errorMessage, 'Unable to fetch ruleset');
    }
  }, [dispatch, id, ruleset]);

  return ruleset;
};

export const useAutograderRulesets = () => {
  const autograderRulesets = useAutograderSelector(state => selectAutograderRulesets(state));
  const dispatch = useAutograderDispatch();
  useEffect(() => {
    fetchAllAutograderRuleset()(dispatch).catch(errorMessage =>
      toastException(errorMessage, 'Unable to fetch rulesets'),
    );
  }, [dispatch]);
  return autograderRulesets;
};
