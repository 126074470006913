import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { criterionToProficiencyLevelArray } from './proficiencyLevelGradeSelectHelper';

const ProficiencyLevelGradeSelect = ({ criterion, onGradeSelect, recommendedGrade }) => {
  const proficiencyLevels = criterionToProficiencyLevelArray(criterion);
  return (
    <div className="proficiency-level-container" data-testid="proficiency-level">
      {proficiencyLevels.map((proficiencyLevel, i) => {
        const isRecommended = proficiencyLevel.grades.includes(recommendedGrade);
        return (
          <div className={classnames('proficiency-level', { recommended: isRecommended })} key={i}>
            <div className="proficiency-level-title">{proficiencyLevel.title}</div>
            <div className="proficiency-level-content">{proficiencyLevel.description}</div>
            <div className="proficiency-level-grade-container">
              {proficiencyLevel.grades.map(grade => (
                <button
                  className="proficiency-level-grade"
                  key={grade}
                  type="button"
                  onClick={onGradeSelect}
                  value={grade}>
                  {grade}
                </button>
              ))}
            </div>
          </div>
        );
      })}
    </div>
  );
};

ProficiencyLevelGradeSelect.propTypes = {
  criterion: PropTypes.object.isRequired,
  onGradeSelect: PropTypes.func.isRequired,
  recommendedGrade: PropTypes.string.isRequired,
};

export default ProficiencyLevelGradeSelect;
