import { findIndex as _findIndex } from 'lodash';
import { useState, useEffect } from 'react';
import LtiConsumer from '../api/ltiConsumer';
import useTenant from './useTenant';

export default function useLtiConsumers() {
  const tenant = useTenant();
  const [isLoading, setIsLoading] = useState(false);
  const [ltiConsumers, setLtiConsumers] = useState([]);

  async function fetchLtiConsumers() {
    if (!tenant) return;

    setIsLoading(true);
    try {
      const response = await LtiConsumer.all(tenant.id);
      setLtiConsumers(response);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    fetchLtiConsumers();
  }, [tenant]);

  const updateConsumer = consumer => {
    const ind = _findIndex(ltiConsumers, { id: consumer.id });
    if (ind === -1) {
      setLtiConsumers([...ltiConsumers, consumer]);
    } else {
      setLtiConsumers([...ltiConsumers.slice(0, ind), consumer, ...ltiConsumers.slice(ind + 1)]);
    }
  };

  return {
    isLoading,
    ltiConsumers,
    updateConsumer,
  };
}
