import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { selectTutorial } from '../../selectors/tutorialSelectors';
import BlockLabShow from './BlockLabShow';
import { fetchTutorial } from '../../actions/tutorialActions';
import { fetchAppStreamAppSites } from '../../actions/appSiteActions';
import { selectAppStreamAppSites } from '../../selectors/appSiteSelectors';

const mapStateToProps = (state, ownProps) => {
  const { block, history, match } = ownProps;
  const tutorialId = block.external_id;
  const tutorial = selectTutorial(state, tutorialId);
  const appStreamAppSites = selectAppStreamAppSites(state) || [];

  return {
    tutorial,
    tutorialId,
    history,
    match,
    appStreamAppSites,
  };
};

export default withRouter(connect(mapStateToProps, { fetchTutorial, fetchAppStreamAppSites })(BlockLabShow));
