export const GoogleSheetsFormat = {
  VALUE_TYPE: 'formattedValue',
  USER_ENTERED_FORMAT: 'userEnteredFormat',
  USER_ENTERED_VALUE: 'userEnteredValue',
  EFFECTIVE_FORMAT: 'effectiveFormat',
  EFFECTIVE_VALUE: 'effectiveValue',
  TABLE_RANGES: 'TableRanges',
};

export const GoogleSheetsChartsType = {
  BASIC_CHART: 'BASIC_CHART',
  PIE_CHART: 'PIE_CHART',
};
