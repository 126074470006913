import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import FileUpload, { getFileName } from './FileUpload';
import intl from '../../utils/intl';

import { ReactComponent as MinusIcon } from '../../styles/pathstream/svg-jsx/icon-minus.svg';
import Tutorial from '../../api/tutorial';

export default class ProjectDropZone extends Component {
  getAcceptFileTypes = () => {
    const { app } = this.props;
    switch (app) {
      default:
        return '';
    }
  };

  render() {
    const { projectUrl, onRemove, onFileUploaded, messageId, app } = this.props;
    const s3Prefix = `${this.props.app}/lab-projects`;

    return (
      <>
        <label>
          <FormattedMessage id={messageId || `tutorial.form.${app}.project`} />
        </label>
        <span className="project-file">
          {' - '} {getFileName(projectUrl) || <FormattedMessage id="fileUpload.noneSelected" />}{' '}
          {projectUrl && onRemove && (
            <button
              type="button"
              className="authoring-icon-button rounded small"
              onClick={onRemove}
              title={intl.formatMessage({ id: 'aria.removeProjectFile' })}
              aria-label={intl.formatMessage({ id: 'aria.removeProjectFile' })}>
              <MinusIcon />
            </button>
          )}
        </span>
        <FileUpload
          showDropZone
          accept={this.getAcceptFileTypes()}
          onSuccess={onFileUploaded}
          s3Prefix={s3Prefix}
          getS3Key>
          <div className="custom-upload-input__label">
            <span className="custom-upload-input__label--text">
              <FormattedMessage id="common.fileDropMessage" />
            </span>
          </div>
        </FileUpload>
      </>
    );
  }
}

ProjectDropZone.propTypes = {
  projectUrl: PropTypes.string,
  onRemove: PropTypes.func,
  onFileUploaded: PropTypes.func.isRequired,
  messageId: PropTypes.string,
  app: PropTypes.string.isRequired,
};
