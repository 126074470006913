import { CONTEXT } from '../actions/constants';

const INITIAL_STATE = {
  isEditMode: null,
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case CONTEXT.ENABLE_EDIT_MODE:
      return {
        ...state,
        isEditMode: action.payload,
      };

    default:
      return state;
  }
}
