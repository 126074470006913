import { AUTH } from '../actions/constants';

const INITIAL_STATE = {
  loggingIn: false,
  signedIn: false,
  user: null,
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case AUTH.LOGOUT:
      return {
        ...INITIAL_STATE,
      };

    case AUTH.LOGGED_IN:
      return {
        ...state,
        signedIn: true,
        user: action.payload.user,
        loggingIn: false,
      };

    case AUTH.SET_USER:
      return {
        ...state,
        user: action.payload,
      };

    case AUTH.LOGGING_IN:
      return {
        ...state,
        loggingIn: action.payload,
      };

    default:
      return state;
  }
}
