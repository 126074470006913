import axios from './axios';

export default class Block {
  static HTML = 'html';

  static COLLAPSIBLE_HTML = 'collapsible_html';

  static VIDEO = 'video';

  static QUIZ = 'quiz';

  static SINGLE_QUESTION_QUIZ = 'single_question_quiz';

  static GRADED_QUIZ = 'graded_quiz';

  static LAB = 'lab';

  static COMMENT_THREAD = 'comment_thread';

  static EMBED = 'embed';

  static SQL = 'sql';

  static TYPEFORM = 'typeform';

  static ASANA_ACCOUNT = 'asana_account';

  static SMALL = 'small';
  static MEDIUM = 'medium';
  static LARGE = 'large';

  static HEIGHT_OPTIONS = [Block.SMALL, Block.MEDIUM, Block.LARGE];

  static ADAPTIVE_TRACKS = {
    discovery: { label: 'Discovery Path', name: 'Discovery', index: 0, class: 'beginner' },
    essentials: { label: 'Essentials Path', name: 'Essentials', index: 1, class: 'intermediate' },
    accelerated: { label: 'Accelerated Path', name: 'Accelerated', index: 2, class: 'advanced' },
    beginner: { label: 'Beginner Path', name: 'Beginner', index: 3, class: 'beginner' },
    intermediate: { label: 'Intermediate Path', name: 'Intermediate', index: 4, class: 'intermediate' },
    advanced: { label: 'Advanced Path', name: 'Advanced', index: 5, class: 'advanced' },
    a: { label: 'Path A', name: 'Path A', index: 6, class: 'beginner' },
    b: { label: 'Path B', name: 'Path B', index: 7, class: 'intermediate' },
    c: { label: 'Path C', name: 'Path C', index: 8, class: 'advanced' },
  };

  static async all(_, lessonId) {
    const response = await axios.get(`/content/lessons/${lessonId}/blocks`);
    return response.data;
  }

  static async save(_, block) {
    if (!block.lesson_id) throw new Error('Block requires a lesson_id to be saved');

    const response = block.id
      ? await axios.put(`/content/lessons/${block.lesson_id}/blocks/${block.id}`, block)
      : await axios.post(`/content/lessons/${block.lesson_id}/blocks`, block);

    return response.data;
  }

  static async destroy(_, block) {
    const response = await axios.delete(`/content/lessons/${block.lesson_id}/blocks/${block.id}`);
    return response.data;
  }

  static async reorder(_, blockIds, lessonId, partId) {
    const response = await axios.post(`/content/lessons/${lessonId}/parts/${partId}/reorder_blocks`, {
      block_ids: blockIds,
    });
    return response.data;
  }

  static async moveToStep(_, blockId, newStepId) {
    const response = await axios.post(`/content/blocks/${blockId}/move_to_step`, {
      new_step_id: newStepId,
    });
    return response.data;
  }

  static async moveTo(_, blockId, newPartId) {
    const response = await axios.post(`/content/blocks/${blockId}/move_to`, { new_part_id: newPartId });
    return response.data;
  }

  static async copyTo(_, blockId, newPartId) {
    const response = await axios.post(`/content/blocks/${blockId}/copy_to`, { new_part_id: newPartId });
    return response.data;
  }
}
