import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { sortBy as _sortBy } from 'lodash';
import { selectLesson, selectLessonParts, selectLessonBlocks } from '../selectors/lessonSelectors';
import { selectUnit } from '../selectors/unitSelectors';
import { selectBootcamp } from '../selectors/bootcampSelectors';
import { selectSection } from '../selectors/sectionSelectors';

export default function useCurrentGradedQuiz() {
  const { bootcampId, sectionId, unitId } = useParams();
  const bootcamp = useSelector(s => selectBootcamp(s, bootcampId));
  const section = useSelector(s => selectSection(s, sectionId));
  const unit = useSelector(s => selectUnit(s, unitId));
  const lesson = useSelector(s => selectLesson(s, unit?.lesson_id));
  const parts = useSelector(s => selectLessonParts(s, lesson?.id));
  let blocks = useSelector(s => selectLessonBlocks(s, lesson?.id));
  blocks = _sortBy(blocks || [], ['seq_num']);

  return {
    bootcamp,
    bootcampId,
    unit,
    section,
    lesson,
    parts: parts || [],
    blocks,
  };
}
