import React, { useState } from 'react';
import PropTypes from 'prop-types';

import intl from '../../../utils/intl';
import SimpleModal2 from '../../library/SimpleModal2/SimpleModal2';
import bgSuccessImg from '../../../styles/v2/images/lab/bg-success.png';
import iconAssignmentSubmittedImg from '../../../styles/v2/images/lab/icon-assignment-submitted.png';
import iconGradedImg from '../../../styles/v2/images/lab/icon-graded.png';
import { isReviewable } from '../../../helpers/labHelper';
import showModal from '../../../utils/showModal';
import ViewGradeModal from '../../Projects/ViewGradeModal';
import { getCoursePath } from '../../../helpers/pathHelper';
import { isSubmitted, isGraded, isPending } from '../../../utils/utils';

const CloseableGradedModal = ({ assignmentProgress }) => {
  const [visible, setVisible] = useState(true);

  return (
    <SimpleModal2
      visible={visible}
      backgroundImage={`url(${bgSuccessImg})`}
      icon={iconGradedImg}
      title={intl.formatMessage(
        { id: 'tutorial.assignmentModal.gsuiteAssignmentGraded.title' },
        { score: assignmentProgress.score },
      )}
      body={intl.formatMessage({ id: 'tutorial.assignmentModal.gsuiteAssignmentGraded.body' })}
      leftButtonText={intl.formatMessage({ id: 'tutorial.assignmentModal.reviewSubmission' })}
      onLeftButton={() => setVisible(false)}
    />
  );
};

const GradedModal = ({ assignment, assignmentProgress, history, currentUser, bootcamp, path }) => {
  return (
    <SimpleModal2
      backgroundImage={`url(${bgSuccessImg})`}
      icon={iconGradedImg}
      title={intl.formatMessage(
        { id: 'tutorial.assignmentModal.assignmentGraded.title' },
        { score: assignmentProgress.score },
      )}
      body={intl.formatMessage({ id: 'tutorial.assignmentModal.assignmentGraded.body' })}
      leftButtonText={intl.formatMessage({ id: 'tutorial.assignmentModal.goToGrades' })}
      onLeftButton={() => {
        history.push(getCoursePath(currentUser, bootcamp, path));
        showModal(ViewGradeModal, {
          assignment,
          assignmentProgress,
        });
      }}
    />
  );
};

const CloseableSubmittedModal = () => {
  const [visible, setVisible] = useState(true);

  return (
    <SimpleModal2
      visible={visible}
      backgroundImage={`url(${bgSuccessImg})`}
      icon={iconAssignmentSubmittedImg}
      title={intl.formatMessage({ id: 'tutorial.assignmentModal.gsuiteAssignmentSubmitted.title' })}
      body={intl.formatMessage({ id: 'tutorial.assignmentModal.gsuiteAssignmentSubmitted.body' })}
      leftButtonText={intl.formatMessage({ id: 'tutorial.assignmentModal.viewSubmission' })}
      onLeftButton={() => setVisible(false)}
    />
  );
};

const SubmittedModal = ({ history, currentUser, bootcamp, path }) => {
  return (
    <SimpleModal2
      backgroundImage={`url(${bgSuccessImg})`}
      icon={iconAssignmentSubmittedImg}
      title={intl.formatMessage({ id: 'tutorial.assignmentModal.assignmentSubmitted.title' })}
      body={intl.formatMessage({ id: 'tutorial.assignmentModal.assignmentSubmitted.body' })}
      leftButtonText={intl.formatMessage({ id: 'tutorial.assignmentModal.seeLessons' })}
      onLeftButton={() => history.push(getCoursePath(currentUser, bootcamp, path))}
    />
  );
};

const PendingModal = ({ history, currentUser, bootcamp, path }) => {
  return (
    <SimpleModal2
      backgroundImage={`url(${bgSuccessImg})`}
      icon={iconAssignmentSubmittedImg}
      title={intl.formatMessage({ id: 'tutorial.assignmentModal.assignmentPending.title' })}
      body={intl.formatMessage({ id: 'tutorial.assignmentModal.assignmentPending.body' })}
      leftButtonText={intl.formatMessage({ id: 'tutorial.assignmentModal.seeLessons' })}
      onLeftButton={() => history.push(getCoursePath(currentUser, bootcamp, path))}
    />
  );
};

const SubmissionModal = props => {
  const { assignment, assignmentProgress, history, currentUser, bootcamp, path } = props;

  const queryParams = new URLSearchParams(history?.location?.search);
  const openRubric = queryParams.get('openRubric');

  if (isGraded(assignmentProgress)) {
    if (!!openRubric) {
      return (
        <ViewGradeModal
          onHide={() => history.push(getCoursePath(currentUser, bootcamp, path))}
          assignment={assignment}
          assignmentProgress={assignmentProgress}
        />
      );
    }

    if (isReviewable(assignment.app)) {
      return <CloseableGradedModal {...props} />;
    }

    return <GradedModal {...props} />;
  }

  if (isSubmitted(assignmentProgress)) {
    if (isReviewable(assignment.app)) {
      return <CloseableSubmittedModal />;
    }

    return <SubmittedModal {...props} />;
  }

  if (isPending(assignmentProgress)) {
    return <PendingModal {...props} />;
  }

  return '';
};

SubmissionModal.propTypes = {
  assignment: PropTypes.object.isRequired,
  assignmentProgress: PropTypes.object.isRequired,
  history: PropTypes.object,
  currentUser: PropTypes.object,
  bootcamp: PropTypes.object,
  path: PropTypes.object,
};

export default SubmissionModal;
