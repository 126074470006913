import React from 'react';
import { Row, Col, Space, Spin } from 'antd';

const EvaluatorLoadingRow = () => {
  return (
    <Row>
      <Col span={24} style={{ textAlign: 'center' }}>
        <Space size="middle">
          <Spin size="large" aria-label="loading" />
        </Space>
      </Col>
    </Row>
  );
};

export default EvaluatorLoadingRow;
