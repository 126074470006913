import React from 'react';
import { FormattedMessage } from 'react-intl';

export default function RTEAccessibilityNote() {
  return (
    <div className="rte-access-note">
      <FormattedMessage id="common.rteAccessibilityNote" />
    </div>
  );
}
