import axios from './axios';

const fetchRulesetByAssignmentId = assignmentId =>
  axios.get(`/assessments/assignments/${assignmentId}/autograder_ruleset`);

const fetchRulesetById = rulesetId => axios.get(`/assessments/autograder_rulesets/${rulesetId}`);

export default class AutograderRuleset {
  static save = async (_, assignmentId, ruleset) => {
    const response = await axios.post('/assessments/autograder_rulesets', {
      assignment_id: assignmentId,
      data: JSON.stringify(ruleset),
    });
    return response.data;
  };

  static update = async (_, rulesetId, ruleset) => {
    const response = await axios.put(`/assessments/autograder_rulesets/${rulesetId}`, {
      data: JSON.stringify(ruleset),
    });
    return response.data;
  };

  /**
   * @param tenantId
   * @param id - either ruleset id or assignment id
   * @param isFetchByRulesetId - flag that determine if @param id - ruleset id
   */
  static async get(_, id, isFetchByRulesetId = true) {
    let response;
    if (isFetchByRulesetId) {
      response = await fetchRulesetById(id);
    } else {
      response = await fetchRulesetByAssignmentId(id);
    }
    return response.data;
  }

  static async all(_) {
    const response = await axios.get('/assessments/autograder_rulesets');
    return response.data;
  }

  static async destroy(_, rulesetId) {
    const response = await axios.delete(`/assessments/autograder_rulesets/${rulesetId}`);
    return response.data;
  }
}
