import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import GradeContainer from './GradeContainer';
import NotFound from '../NotFound';
import GradeAssignmentContainer from './GradeAssignmentContainer';
import BootcampParent from '../Author/Bootcamps/BootcampParent';
import { enableEditMode } from '../../actions/contextActions';

import Dashboard from './Dashboard';
import CohortDashboard from './CohortDashboard';

class Coach extends Component {
  render() {
    const { match } = this.props;

    return (
      <Switch>
        <Route exact path={`${match.path}/grade/:unitProgressId`} component={GradeAssignmentContainer} />
        <Route exact path={`${match.path}/grade`} component={GradeContainer} />
        <Route path={`${match.path}/bootcamps`} render={props => <BootcampParent isCoach {...props} />} />
        <Route exact path={`${match.path}/dashboard`} component={Dashboard} />
        <Route path={`${match.path}/dashboard/cohorts/:cohortId`} component={CohortDashboard} />
        <Route component={NotFound} />
      </Switch>
    );
  }

  componentDidMount() {
    this.props.enableEditMode(false);
  }
}

Coach.propTypes = {
  match: PropTypes.object,
  enableEditMode: PropTypes.func,
};

export default connect(null, { enableEditMode })(Coach);
