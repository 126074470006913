import { isEmpty as _isEmpty, trim as _trim } from 'lodash';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Modal2 from '../../library/Modal2';
import Errors from '../../Utils/Errors';
import LtiConsumer from '../../../api/ltiConsumer';
import { toastSuccess } from '../../../utils/toastHelper';
import intl from '../../../utils/intl';
import CustomInput from '../../Utils/CustomInput';
import Tenant from '../../../api/tenant';

export default function EditLtiConsumerModal({ ltiConsumer, onSave, onHide }) {
  const [errors, setErrors] = useState([]);
  const [isSaving, setIsSaving] = useState(false);
  const [consumer, setConsumer] = useState(ltiConsumer);

  const handleChange = e => {
    const { target } = e;
    const { name } = target;
    const { value } = target;

    setConsumer({
      ...consumer,
      [name]: value,
    });
  };

  const validate = () => {
    const errs = [];

    if (_isEmpty(_trim(consumer.consumer_key))) {
      errs.push(
        <FormattedMessage
          id="common.errors.required"
          values={{ field: <FormattedMessage id="ltiConsumer.form.consumerKey" /> }}
        />,
      );
    }

    setErrors(errs);

    return errs.length === 0;
  };

  const handleSubmit = async e => {
    e.preventDefault();

    if (!validate()) {
      return;
    }

    setIsSaving(true);
    setErrors([]);

    try {
      const saved = await LtiConsumer.save({ ...consumer, tenant_id: Tenant.ID });
      toastSuccess(<FormattedMessage id="ltiConsumer.messages.saved" />);

      if (onSave) onSave(saved);
      onHide();
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error('Error saving LTI Consumer', err);
      setErrors(err.errors || ['Unable to save LTI Consumer']);
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <Modal2
      onHide={onHide}
      onOk={handleSubmit}
      size="big"
      title={consumer.id ? <FormattedMessage id="ltiConsumer.edit" /> : <FormattedMessage id="ltiConsumer.create" />}>
      <Errors errors={errors} />

      <fieldset disabled={isSaving} style={{ marginBottom: 20 }}>
        <CustomInput
          name="consumer_key"
          onChange={handleChange}
          value={consumer.consumer_key}
          label={intl.formatMessage({ id: 'ltiConsumer.form.consumerKey' })}
        />

        <CustomInput
          name="shared_secret"
          value={consumer.shared_secret}
          disabled
          label={intl.formatMessage({ id: 'ltiConsumer.form.sharedSecret' })}
        />

        <span className="label-modal">
          <FormattedMessage id="ltiConsumer.form.lti13Details" />
        </span>

        {<V13FormFields handleChange={handleChange} consumer={consumer} />}
      </fieldset>
    </Modal2>
  );
}

const V13FormFields = ({ handleChange, consumer }) => {
  return (
    <>
      <CustomInput
        name="v13_client_id"
        onChange={handleChange}
        value={consumer.v13_client_id}
        label={intl.formatMessage({ id: 'ltiConsumer.form.v13ClientId' })}
      />

      <CustomInput
        name="v13_deployment_id"
        onChange={handleChange}
        value={consumer.v13_deployment_id}
        label={intl.formatMessage({ id: 'ltiConsumer.form.v13DeploymentId' })}
      />

      <CustomInput
        name="v13_jwk_url"
        onChange={handleChange}
        value={consumer.v13_jwk_url}
        label={intl.formatMessage({ id: 'ltiConsumer.form.v13JwkUrl' })}
      />

      <CustomInput
        name="v13_authorize_url"
        onChange={handleChange}
        value={consumer.v13_authorize_url}
        label={intl.formatMessage({ id: 'ltiConsumer.form.v13AuthorizeUrl' })}
      />

      <CustomInput
        name="v13_token_url"
        onChange={handleChange}
        value={consumer.v13_token_url}
        label={intl.formatMessage({ id: 'ltiConsumer.form.v13TokenUrl' })}
      />
      <CustomInput
        name="v13_oauth_audience"
        onChange={handleChange}
        value={consumer.v13_oauth_audience}
        label={intl.formatMessage({ id: 'ltiConsumer.form.v13OauthAudience' })}
      />
    </>
  );
};

EditLtiConsumerModal.propTypes = {
  ltiConsumer: PropTypes.object.isRequired,
  onHide: PropTypes.func.isRequired,
  onSave: PropTypes.func,
};
