import { v4 as uuidv4 } from 'uuid';
import { transformResult, executeCheck } from './checkHelper';

export default class Check {
  constructor(name, location, property, operator, value) {
    this.id = uuidv4();
    this.name = name;
    this.location = location;
    this.property = property;
    this.operator = operator;
    this.value = value;
  }

  run = data => {
    if (!data) return;

    // TODO: think about location navigator implementation
    const { location, property, operator, value } = this;
    const params = {
      data,
      location,
      property,
      operator,
      value,
    };

    const combineLocations = (acc, loc) => {
      if (loc.within) {
        if (acc) return `${acc}.${loc.within}`;
        return `${loc.within}`;
      }
      return acc;
    };

    const finalLocation = location.reduce(combineLocations, '');

    const result = executeCheck(finalLocation, params);
    return transformResult(result, params);
  };
}
