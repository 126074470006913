import React, { useEffect } from 'react';
import Loading from '../../Utils/Loading';
import LessonShow from '../../Lesson/LessonShow';
import { setPageTitle } from '../../../helpers/pageHelper';
import ComponentHeader from '../../Components/ComponentHeader';
import useCurrentComponent from '../../../hooks/useCurrentComponent';
import useAnalytics from '../../../hooks/useAnalytics';
import useFetchBootcamp from '../../../hooks/useFetchBootcamp';

export default function ComponentShow() {
  const { bootcamp, bootcampId, section, unit, component, lessonId } = useCurrentComponent();
  useFetchBootcamp(bootcampId);

  useAnalytics('Edit Lesson', {
    bootcamp,
    section,
    module: unit,
    lesson: component,
  });

  useEffect(() => {
    if (bootcamp && component) {
      const params = {
        course: bootcamp.title,
        lesson: component.title,
      };
      setPageTitle('pageName.course.lesson', params);
    }
  }, [bootcamp, component]);

  return (
    <div className="new-lesson-page">
      <main className="new-lesson-page__main">
        <ComponentHeader />

        {component && unit ? (
          <LessonShow lessonId={lessonId} />
        ) : (
          <article className="lesson-part lesson-part--normal">
            <div className="lesson-part__wrapper">
              <Loading />
            </div>
          </article>
        )}
      </main>
    </div>
  );
}
