import React from 'react';
import PropTypes from 'prop-types';
import intl from '../../../../utils/intl';
import { canAdministrate, canCoach, canAuthor, isEvaluator } from '../../../../helpers/userAuth';
import Icons from '../../../Utils/Icons';
import SidebarLink from './SidebarLink';
import useFeatureToggle from '../../../../hooks/useFeatureToggle';

const EvaluatorSidebarLinks = ({ currentUser }) => {
  if (canAdministrate(currentUser) || isEvaluator(currentUser)) {
    return (
      <SidebarLink
        to="/evaluator/grader_queue"
        tooltip={intl.formatMessage({ id: 'nav.evaluatorQueue' })}
        Icon={Icons.Assignments}
      />
    );
  } else {
    return '';
  }
};

const AdminSidebar = ({ currentUser }) => {
  const { shouldShowAutograder } = useFeatureToggle();
  const isAutograderAllowed = shouldShowAutograder();

  return (
    <div className="admin-pages-sidebar">
      <div className="icons-group-menu">
        {canAdministrate(currentUser) && (
          <>
            <SidebarLink to="/admin/users" tooltip={intl.formatMessage({ id: 'nav.users' })} Icon={Icons.User2} />

            <SidebarLink to="/admin/cohorts" tooltip={intl.formatMessage({ id: 'nav.cohorts' })} Icon={Icons.Cards} />

            <SidebarLink to="/admin/lti" tooltip={intl.formatMessage({ id: 'nav.ltiConsumers' })} Icon={Icons.Tools} />

            <SidebarLink to="/admin/schools" tooltip={intl.formatMessage({ id: 'nav.schools' })} Icon={Icons.School} />
          </>
        )}
        {(canAdministrate(currentUser) || canCoach(currentUser)) && (
          <>
            <SidebarLink
              to="/reports/cohort"
              tooltip={intl.formatMessage({ id: 'nav.cohortReports' })}
              Icon={Icons.Board}
            />
          </>
        )}
        {canCoach(currentUser) && (
          <>
            <SidebarLink
              to="/coach/grade"
              tooltip={intl.formatMessage({ id: 'nav.gradingQueue' })}
              Icon={Icons.Users}
            />

            {!canAuthor(currentUser) && (
              <SidebarLink
                to="/coach/bootcamps"
                tooltip={intl.formatMessage({ id: 'nav.bootcamps' })}
                Icon={Icons.Sheet}
              />
            )}
          </>
        )}

        {canAuthor(currentUser) && (
          <>
            <SidebarLink
              to="/author/bootcamps"
              tooltip={intl.formatMessage({ id: 'nav.bootcamps' })}
              Icon={Icons.Sheet}
            />
          </>
        )}
        {canAuthor(currentUser) && isAutograderAllowed && (
          <>
            <SidebarLink
              to="/autograder-create-ruleset"
              tooltip={intl.formatMessage({ id: 'autograder.nav.autograder' })}
              Icon={Icons.Menu}
            />
          </>
        )}
        <EvaluatorSidebarLinks currentUser={currentUser} />
      </div>
    </div>
  );
};

AdminSidebar.propTypes = {
  currentUser: PropTypes.object,
};

export default AdminSidebar;
