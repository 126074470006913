import React from 'react';

const Welcome = ({ currentUser }) => {
  let endingMessage = '!';
  if (currentUser && currentUser.first_name) {
    endingMessage = `, ${currentUser.first_name}!`;
  }
  const welcomeString = `Welcome to your learning home${endingMessage}`;
  return <div className="learning-home-welcome">{welcomeString}</div>;
};

export default Welcome;
