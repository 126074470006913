/* eslint-disable complexity */
/* eslint-disable max-nested-callbacks */
import { each as _each, map as _map, includes as _includes, isEmpty as _isEmpty } from 'lodash';
import createCachedSelector from 're-reselect';
import { selectBootcampEntities } from './bootcampSelectors';
import { selectPathEntities } from './pathSelectors';
import { selectSectionEntities } from './sectionSelectors';
import { selectUnitEntities } from './unitSelectors';
import { selectComponentEntities } from './componentSelectors';
import { selectCohortEntities } from './cohortSelectors';
import { selectEnrollmentForBootcamp } from './enrollmentSelectors';
import Unit from '../api/unit';
import { canCoach, canAuthor } from '../helpers/userAuth';
import { selectCurrentCohortId } from './components/coachSelectors';
import { selectCurrentUser } from './authSelectors';

const addToEntitiesList = (entities, key, parentId, child) => {
  if (!entities[key]) {
    entities[key] = {};
  }
  if (!entities[key][parentId]) {
    entities[key][parentId] = [];
  }
  if (!_includes(entities[key][parentId], child)) {
    entities[key][parentId].push(child);
  }
};

const addEntities = (entities, bootcamp, path, section, unit, component) => {
  addToEntitiesList(entities, 'pathsByBootcamp', bootcamp.id, path);
  if (section) {
    addToEntitiesList(entities, 'sectionsByPath', path.id, section);
  }
  if (unit) {
    addToEntitiesList(entities, 'unitsBySection', section.id, unit);
  }
  if (component) {
    addToEntitiesList(entities, 'componentsByUnit', unit.id, component);
  }
};

export const selectEntitiesForBootcamp = createCachedSelector(
  [
    selectCohortEntities,
    selectBootcampEntities,
    selectPathEntities,
    selectSectionEntities,
    selectUnitEntities,
    selectComponentEntities,
    selectEnrollmentForBootcamp,
    (_state, bootcampId) => selectCurrentCohortId(bootcampId),
    (_state, bootcampId) => bootcampId,
    selectCurrentUser,
    (_state, _bootcampId, cohortId) => cohortId,
  ],
  (
    cohortEntities,
    bootcampEntities,
    pathEntities,
    sectionEntities,
    unitEntities,
    componentEntities,
    enrollment,
    coachCohortId,
    bootcampId,
    currentUser,
    cohortId,
  ) => {
    const isCoach = canCoach(currentUser);
    const isAuthor = canAuthor(currentUser);
    if (!cohortId) {
      cohortId = isCoach ? coachCohortId : enrollment && enrollment.enrollable_id;
    }
    const cohort = cohortId && cohortEntities[cohortId];
    const hiddenItems = _map(cohort && cohort.hidden_items, item => item.split('::')[1]);

    const entities = {
      enrollment,
      enrollmentId: enrollment && enrollment.id,
      cohort,
      cohortId,
    };

    const bootcamp = bootcampEntities[bootcampId];
    if (!bootcamp) return entities;

    _each(bootcamp.path_ids, pathId => {
      const path = pathEntities[pathId];
      if (!path) return;

      _each(path.section_ids, sectionId => {
        const section = sectionEntities[sectionId];
        if (!section) return;

        if (isAuthor && _isEmpty(section.unit_ids)) {
          addEntities(entities, bootcamp, path, section);
          return;
        }

        _each(section.unit_ids, unitId => {
          const unit = unitEntities[unitId];
          if (!unit) return;

          if (unit.unit_type === Unit.PROJECT || unit.unit_type === Unit.GRADED_QUIZ) {
            if (isAuthor || !_includes(hiddenItems, unitId)) {
              addEntities(entities, bootcamp, path, section, unit);
            }
            return;
          }

          if (isAuthor && _isEmpty(unit.component_ids)) {
            addEntities(entities, bootcamp, path, section, unit);
            return;
          }

          _each(unit.component_ids, componentId => {
            const component = componentEntities[componentId];
            if (!component) return;

            if (!_includes(hiddenItems, componentId)) {
              addEntities(entities, bootcamp, path, section, unit, component);
            }
          });
        });
      });
    });

    return entities;
  },
)((_state, bootcampId, cohortId) => `${bootcampId}-${cohortId}`);

export const selectFirstComponent = createCachedSelector(
  [selectEntitiesForBootcamp, (_state, bootcampId) => bootcampId],
  (entitiesForBootcamp, bootcampId) => {
    if (!entitiesForBootcamp) return;

    const { pathsByBootcamp, sectionsByPath, unitsBySection, componentsByUnit } = entitiesForBootcamp;
    if (!pathsByBootcamp || _isEmpty(pathsByBootcamp[bootcampId])) return;

    const path = pathsByBootcamp[bootcampId][0];
    if (!path || !sectionsByPath || _isEmpty(sectionsByPath[path.id])) return;

    const section = sectionsByPath[path.id][0];
    if (!section || !unitsBySection || _isEmpty(unitsBySection[section.id])) return;

    const unit = unitsBySection[section.id][0];
    if (!unit) return;

    if (unit.unit_type === Unit.PROJECT || unit.unit_type === Unit.GRADED_QUIZ) {
      return {
        pathId: path.id,
        sectionId: section.id,
        section,
        unitId: unit.id,
        unit,
      };
    }
    if (!componentsByUnit || _isEmpty(componentsByUnit[unit.id])) {
      return;
    }

    const component = componentsByUnit[unit.id][0];
    if (!component) {
      return;
    }

    return {
      pathId: path.id,
      sectionId: section.id,
      section,
      unitId: unit.id,
      unit,
      componentId: component.id,
      component,
    };
  },
)((_state, bootcampId, cohortId) => `${bootcampId}-${cohortId}`);
