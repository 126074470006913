import { difference as _difference } from 'lodash';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Tutorial from '../../api/tutorial';
import intl from '../../utils/intl';
import { selectOrderedAppSites } from '../../selectors/appSiteSelectors';
import { fetchAppSites } from '../../actions/appSiteActions';
import CustomInput from './CustomInput';
import CustomSelect from './CustomSelect';
import { usesProjectUrl } from '../../helpers/labHelper';
import * as analytics from '../../utils/analytics';
import { selectCurrentUser } from '../../selectors/authSelectors';

const SITE_STATUS = {
  NEW: 'new',
  EXISTING: 'existing',
};

export default function AppSelect({ block, onChange, showTrailhead, showGoogleColab }) {
  const { app, app_site_id, project_url, codio_resource_lti_url } = block;
  const orderedAppSites = useSelector(selectOrderedAppSites);
  const currentUser = useSelector(selectCurrentUser);
  const [customSite, setCustomSite] = useState({});
  const [selectedSiteStatus, setSelectedSiteStatus] = useState();

  const dispatch = useDispatch();

  useEffect(() => {
    fetchAppSites()(dispatch);
  }, [dispatch]);

  const handleMainSelectChange = option => {
    const { value } = option;

    const [app, app_site_id, site_status] = value.split('::');
    setSelectedSiteStatus(site_status);
    onChange({ app, app_site_id });
  };

  const handleCustomSelectChange = option => {
    const { value } = option;

    const [app, app_site_id] = value.split('::');
    onChange({ app, app_site_id });
  };

  const handleCustomSiteInputChange = e => {
    const { name, value } = e.target;

    const newCustomSite = { ...customSite, [name]: value };
    setCustomSite(newCustomSite);
    onChange({ custom_site: newCustomSite });
  };

  const handleInputChange = e => {
    const { name, value } = e.target;
    onChange({ [name]: value });
  };

  const showVideoAssessments = analytics.inFlag(currentUser, 'video_assessment');

  const getApps = () => {
    let apps = _difference(Tutorial.APPS, [Tutorial.CUSTOM_SITE]);

    if (!showVideoAssessments) {
      apps = _difference(apps, [Tutorial.VIDEO]);
    }

    if (!showTrailhead) {
      apps = _difference(apps, [Tutorial.TRAILHEAD]);
    }

    if (!showGoogleColab) {
      apps = _difference(apps, [Tutorial.GOOGLE_COLAB]);
    }

    return apps;
  };

  const getMainOptions = () => {
    const options = [];
    getApps().forEach(appOption => {
      options.push({
        value: appOption,
        label: intl.formatMessage({ id: `apps.${appOption}` }),
      });
    });

    options.push({
      value: `${Tutorial.CUSTOM_SITE}::::${SITE_STATUS.EXISTING}`,
      label: intl.formatMessage({ id: 'apps.existing_custom_site' }),
    });

    options.push({
      value: `${Tutorial.CUSTOM_SITE}::::${SITE_STATUS.NEW}`,
      label: intl.formatMessage({ id: 'apps.custom_site' }),
    });

    return options;
  };

  const getCustomOptions = () => {
    const options = [];

    orderedAppSites.forEach(appSite => {
      options.push({
        value: `${Tutorial.CUSTOM_SITE}::${appSite.id}`,
        label: appSite.name,
      });
    });

    return options;
  };

  const getSelectedMainValue = () => {
    if (app === Tutorial.CUSTOM_SITE) {
      if (app_site_id) {
        return `${Tutorial.CUSTOM_SITE}::::${SITE_STATUS.EXISTING}`;
      }
      return `${Tutorial.CUSTOM_SITE}::::${selectedSiteStatus}`;
    }
    return app;
  };

  const getSelectedCustomValue = () => {
    if (app_site_id) {
      return `${Tutorial.CUSTOM_SITE}::${app_site_id}`;
    }
    return undefined;
  };

  const showExistingCustomSites =
    selectedSiteStatus === SITE_STATUS.EXISTING || (app === Tutorial.CUSTOM_SITE && app_site_id);
  const showCreateCustomSites = selectedSiteStatus === SITE_STATUS.NEW && !app_site_id;

  return (
    <>
      <div className="form-item select-field clearfix app-select">
        <label htmlFor="selectApp" className="visually-hidden">
          <FormattedMessage id="block.form.app" />
        </label>

        <CustomSelect
          className="Select--simple"
          value={getSelectedMainValue()}
          searchable
          clearable={false}
          name="selectApp"
          options={getMainOptions()}
          onChange={handleMainSelectChange}
          placeholder={intl.formatMessage({ id: 'block.form.app' })}
          aria-label={intl.formatMessage({ id: 'block.form.app' })}
        />

        {showExistingCustomSites && (
          <CustomSelect
            className="Select--simple"
            name="selectApp"
            value={getSelectedCustomValue()}
            searchable
            clearable={false}
            options={getCustomOptions()}
            onChange={handleCustomSelectChange}
            placeholder={intl.formatMessage({ id: 'block.form.custom_app' })}
            aria-label={intl.formatMessage({ id: 'block.form.custom_app' })}
          />
        )}
      </div>

      {showCreateCustomSites && (
        <>
          <CustomInput
            name="name"
            onChange={handleCustomSiteInputChange}
            value={customSite.name}
            label={intl.formatMessage({ id: 'apps.customSite.name' })}
          />

          <CustomInput
            name="url"
            onChange={handleCustomSiteInputChange}
            value={customSite.url}
            label={intl.formatMessage({ id: 'apps.customSite.url' })}
          />
        </>
      )}

      {usesProjectUrl(app) && (
        <CustomInput
          name="project_url"
          onChange={handleInputChange}
          value={project_url}
          label={intl.formatMessage({ id: 'apps.customSite.url' })}
        />
      )}

      {app === Tutorial.CODIO && (
        <CustomInput
          name="codio_resource_lti_url"
          onChange={handleInputChange}
          value={codio_resource_lti_url}
          label={intl.formatMessage({ id: 'block.codio.form.codioResourceLtiUrl' })}
        />
      )}
    </>
  );
}

AppSelect.propTypes = {
  block: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  showTrailhead: PropTypes.bool,
  showGoogleColab: PropTypes.bool,
};

AppSelect.defaultProps = {
  showTrailhead: false,
  showGoogleColab: false,
};
