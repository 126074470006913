import Freezeframe from 'freezeframe';
import { validateFilename } from 'freezeframe/src/utils';
import { isWrappedInFreezeFrame, isInsideCKEditor, isInCollapsibleSnippet, isInLabSidebar } from './imageHelper';

export default function addGIFControlsObserver(target) {
  if (!window.MutationObserver) {
    // eslint-disable-next-line no-console
    console.log('MutationObserver API not available.');
    return null;
  }

  const observer = new MutationObserver(mutations =>
    mutations.forEach(mutation => {
      const { type, target: mutationTarget } = mutation;
      if (
        type === 'attributes' &&
        mutation.attributeName === 'open' &&
        mutationTarget.tagName === 'DETAILS' &&
        mutationTarget.open
      ) {
        // initialize freeze frame inside collapsible snippet only when the snippet is expanded
        addGIFControl(mutationTarget);
      } else if (type === 'childList') {
        mutation.addedNodes.forEach(addedNode => addGIFControl(addedNode));
      }
    }),
  );

  observer.observe(target, { childList: true, subtree: true, attributes: true });
  return observer;
}

const OPTIONS = { trigger: false, overlay: true, responsive: false };

function addHandlers(target) {
  const img = target.getElementsByTagName('img')[0];
  if (target.ffEvents) return;

  const handleEvents = e => {
    if (isInLabSidebar(img)) {
      if (e.key === 'Enter') {
        img.click();
      }
      return;
    }

    if (e.type === 'click' || e.key === ' ' || e.key === 'Space Bar') {
      e.preventDefault();
      img.ff.toggle(img.ff.items[0]);
    }
  };

  if (img) {
    target.ffEvents = true;
    target.tabIndex = 0;
    target.addEventListener('click', handleEvents);
    target.addEventListener('keypress', handleEvents);
  }
}

function addGIFControl(target) {
  if (typeof target.getElementsByTagName !== 'function') return;
  if (isWrappedInFreezeFrame(target)) {
    addHandlers(target);
    return;
  }

  const imgs = Array.from(target.getElementsByTagName('img'));
  imgs.forEach(img => {
    if (isInsideCKEditor(img)) return;
    if (!validateFilename(img.src)) return;

    if (isInCollapsibleSnippet(img)) {
      // initialize only when the details tag is expanded
      const parent = img.closest('details');
      if (img.dataset.ff || !parent.open) {
        return;
      }
    }

    if (!img.dataset.ff) {
      // eslint-disable-next-line no-new
      const ff = new Freezeframe(img, OPTIONS);
      img.ff = ff;
      img.dataset.ff = true;
    }
  });
}
