import entitiesReducer from '../../reducers/entitiesReducer';

export const getInitialState = () => ({
  entities: {
    autograderRulesets: {},
  },
});

export const autoGraderReducer = (state, action) => {
  return {
    entities: entitiesReducer(state.entities, action),
  };
};
