import { values as _values } from 'lodash';
import { selectEntities, selectAll, selectFromBy } from './applicationSelectors';
import createCachedSelector from 're-reselect';

export const selectQuizProgressEntities = selectEntities('quizProgresses');
export const selectQuizProgresses = selectAll(selectQuizProgressEntities);
export const selectProgressForQuiz = selectFromBy(selectQuizProgresses, 'quiz_id');

export const selectProgressForQuizId = createCachedSelector(
  [selectQuizProgressEntities, (_state, quizId) => quizId],
  (quizProgressEntities, quizId) =>
    _values(quizProgressEntities).filter(
      quizProgress => quizProgress.quiz_id === quizId,
    )[0],
)((_state, quizId) => quizId);
