import { isEmpty as _isEmpty } from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Errors from '../Utils/Errors';
import handleInputChange from '../../utils/handleInputChange';
import CustomInput from '../Utils/CustomInput';
import intl from '../../utils/intl';
import Template from './Template';
import ResetPasswordSuccess from './ResetPasswordSuccess';
import Loading from '../Utils/Loading';

export default class ResetPassword extends Component {
  constructor(props) {
    super(props);

    this.handleChange = handleInputChange.bind(this);

    this.state = {
      errors: null,
      currentPassword: '',
      password: '',
      confirmPassword: '',
      success: false,
      isSaving: false,
      forceChange: !!new URLSearchParams(props.location.search).get('change'),
    };
  }

  handleSubmit = e => {
    e.preventDefault();

    const { resetPasswordToken, resetPassword } = this.props;
    const { forceChange, currentPassword, password, confirmPassword } = this.state;

    if (forceChange) {
      if (_isEmpty(currentPassword)) {
        this.setState({ errors: [<FormattedMessage id="user.setPassword.errors.currentPasswordRequired" />] });
        return;
      }
      if (currentPassword === password) {
        this.setState({ errors: [<FormattedMessage id="user.setPassword.errors.passwordSame" />] });
        return;
      }
    }

    if (!password || !confirmPassword) {
      this.setState({ errors: [<FormattedMessage id="user.setPassword.errors.passwordRequired" />] });
      return;
    }

    if (password !== confirmPassword) {
      this.setState({ errors: [<FormattedMessage id="user.setPassword.errors.passwordMismatch" />] });
      return;
    }

    this.setState({ isSaving: true, errors: null });

    resetPassword(resetPasswordToken, password, confirmPassword, currentPassword)
      .then(() => {
        this.setState({ errors: null, success: true });
      })
      .catch(err => {
        this.setState({ errors: err.errors });
      })
      .then(() => this.setState({ isSaving: false }));
  };

  render() {
    const { errors, success, password, confirmPassword, isSaving, currentPassword, forceChange } = this.state;

    if (success) return <ResetPasswordSuccess />;

    return (
      <Template
        onSubmit={this.handleSubmit}
        welcomeMsgId="user.setPassword.welcomeMsgId"
        taglineId="user.login.taglineId"
        formHeader="user.confirmation.heading">
        <div className="modal-dialog-body modal-dialog-body--small">
          {forceChange && (
            <div
              style={{ paddingBottom: 10 }}
              dangerouslySetInnerHTML={{
                __html: intl.formatMessage({ id: 'user.setPassword.forceChangeInstructions' }),
              }}
            />
          )}

          <Errors errors={errors} />

          {isSaving && <Loading showMessage={false} />}

          <div>
            {forceChange && (
              <CustomInput
                name="currentPassword"
                type="password"
                value={currentPassword}
                label={intl.formatMessage({ id: 'user.form.currentPassword' })}
                autoFocus
                autoComplete="off"
                onChange={this.handleChange}
              />
            )}

            <CustomInput
              name="password"
              type="password"
              value={password}
              label={intl.formatMessage({ id: 'user.setPassword.password' })}
              autoFocus={!forceChange}
              autoComplete="off"
              onChange={this.handleChange}
            />

            <CustomInput
              name="confirmPassword"
              type="password"
              value={confirmPassword}
              label={intl.formatMessage({ id: 'user.setPassword.confirmPassword' })}
              autoComplete="off"
              onChange={this.handleChange}
            />
          </div>
        </div>

        <div className="modal-dialog-footer modal-dialog-footer--centered">
          <button
            className="button-standard button-standard--blue2 button-standard--medium new-auth-btn"
            type="submit"
            disabled={isSaving}>
            <FormattedMessage id="common.save" />
          </button>
        </div>
      </Template>
    );
  }
}

ResetPassword.propTypes = {
  resetPasswordToken: PropTypes.string,
  resetPassword: PropTypes.func.isRequired,
};
