import React, { useEffect } from 'react';
import { loadMathjax } from '../../helpers/mathjaxHelper';

export default function MathjaxWrapper({ children }) {
  useEffect(() => {
    loadMathjax(() => {
      window.MathJax.Hub.Queue(['Typeset', window.MathJax.Hub, children.ref ? children.ref.current : null]);
    });
  });

  return children;
}
