import { isEmpty as _isEmpty } from 'lodash';
import classnames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { getProgressStatus, getFileSubmissionStatus } from '../../utils/utils';
import ActionButtons from '../Utils/ActionButtons';
import showModal from '../../utils/showModal';
import EditUnitModalContainer from '../Author/Paths/EditUnitModalContainer';
import { confirmInput } from '../../utils/confirmInput';
import ReorderButtons from '../Utils/ReorderButtons';
import Icons from '../Utils/Icons';
import ViewGradeModal from '../Projects/ViewGradeModal';
import Tutorial from '../../api/tutorial';
import LaunchAssignmentStepsModal from './LaunchAssignmentStepsModal';
import { selectCurrentUser } from '../../selectors/authSelectors';
import LmsExplanation from '../../api/lmsExplanation';
import { Tooltip } from 'antd';
import { openLab } from '../../helpers/labHelper';

// eslint-disable-next-line complexity
const ProjectTile = ({
  unit,
  section,
  unitProgress,
  unitIndex,
  totalUnits,
  onUnitsReorder,
  assignment,
  assignmentProgress,
  isEditMode,
  isLtiStudent,
  ltiProgress,
  match,
  saveUnit,
  destroySectionUnit,
  history,
}) => {
  const labUrl = `${match.url}/sections/${section.id}/projects/${unit.id}?skipLaunch=true`;
  const currentUser = useSelector(selectCurrentUser);

  const handleEdit = () => {
    showModal(EditUnitModalContainer, {
      section,
      unit,
      onSave: saveUnit,
    });
  };

  const handleDelete = () => {
    const message = <FormattedMessage id="unit.confirmProjectDelete" values={{ name: unit.title }} />;

    confirmInput({
      message,
      onOk: () => {
        destroySectionUnit(unit, section.id);
      },
    });
  };

  const handleEvent = async e => {
    e.preventDefault();

    if (e.type === 'keypress' && e.key !== 'Enter') return;

    const linkEnabled = !isLtiStudent || !_isEmpty(ltiProgress);
    if (linkEnabled) {
      if (unit.app === Tutorial.CUSTOM_SITE && !isEditMode) {
        await openLab({
          launchIn: Tutorial.LAUNCH_IN_BROWSER,
          tenantId: unit.tenant_id,
          labUrl: labUrl,
          history,
        });
      } else {
        history.push(labUrl);
      }
    } else {
      showModal(LaunchAssignmentStepsModal, await getLaunchAssignmentSteps());
    }
  };

  const getLaunchAssignmentSteps = async () => {
    const lmsType = currentUser.lti_user ? currentUser.lti_user.lms_type : null;
    const lmsSteps = await LmsExplanation.get(unit.tenant_id, lmsType);
    return {
      lmsSteps,
      assignment,
      section,
    };
  };

  const showRubricCriteria = () => {
    showModal(ViewGradeModal, {
      assignment,
      assignmentProgress,
    });
  };

  const requiresGrading = () => assignment && assignment.requires_grading;

  const { isGraded, isComplete, inProgress } = getProgressStatus(unitProgress);

  const { submissionPending, submissionFailed } = getFileSubmissionStatus(assignmentProgress);

  const unitClassName = classnames(
    'units-group',
    (inProgress || isComplete) && 'units-group--touched',
    inProgress && 'units-group--in-progress',
  );
  const linkEnabled = !isLtiStudent || !_isEmpty(ltiProgress);
  const headingClass = classnames(
    'units-group__heading',
    isComplete && 'units-group__heading--completed',
    !linkEnabled && 'units-group__heading--graded',
  );
  const iconClass = classnames(
    'units-group__icon',
    inProgress && 'units-group__icon--in-progress',
    isComplete && 'units-group__icon--completed',
  );

  const attrs = {};

  let unitsGroupRight;

  if (isEditMode) {
    unitsGroupRight = (
      <>
        <div className="learning-path-item-reorder-button">
          <ReorderButtons index={unitIndex} total={totalUnits} onReorder={onUnitsReorder} />
        </div>
        <span className="learning-path-module-authoring" style={{ marginLeft: 20 }}>
          <ActionButtons
            isEditMode
            className="authoring-edit-delete-button"
            onEdit={handleEdit}
            onDelete={handleDelete}
            showRemove={false}
          />
        </span>
      </>
    );
  } else if (isGraded && requiresGrading()) {
    unitsGroupRight = (
      <button type="button" className="units-button-title--accent" onClick={showRubricCriteria}>
        <span>
          <FormattedMessage id="unit.viewGrade" />
        </span>
      </button>
    );
  } else if (isComplete) {
    unitsGroupRight = (
      <span className="units-group-title--accent">
        {requiresGrading() ? <FormattedMessage id="unit.submitted" /> : <FormattedMessage id="unit.projectCompleted" />}
      </span>
    );
  } else if (submissionPending) {
    unitsGroupRight = (
      <Tooltip title={<FormattedMessage id="fileUpload.pendingDescription" />}>
        <span className="units-group-title--accent">
          <FormattedMessage id="fileUpload.pending" />
        </span>
      </Tooltip>
    );
  } else if (submissionFailed) {
    unitsGroupRight = (
      <Tooltip title={<FormattedMessage id="fileUpload.failedDescription" />}>
        <span className="units-group-title--alert">
          <FormattedMessage id="fileUpload.failed" />
        </span>
      </Tooltip>
    );
  } else if (inProgress) {
    unitsGroupRight = (
      <span className="units-group-title--accent">
        <FormattedMessage id="unit.inProgress" />
      </span>
    );
  }

  return (
    <div className={unitClassName}>
      <div className="units-group-heading">
        <div className={headingClass} {...attrs}>
          <div role="button" className="units-group-left" tabIndex="0" onClick={handleEvent} onKeyPress={handleEvent}>
            <span className={iconClass}>
              <Icons.Graded />
            </span>

            <div className={classnames('units-group-title', (inProgress || isComplete) && 'units-group-title--accent')}>
              {unit.title}
            </div>
          </div>
          <div className="units-group-right">{unitsGroupRight}</div>
        </div>
      </div>
    </div>
  );
};

ProjectTile.propTypes = {
  unit: PropTypes.object.isRequired,
  section: PropTypes.object.isRequired,
  unitProgress: PropTypes.object,
  unitIndex: PropTypes.number.isRequired,
  totalUnits: PropTypes.number.isRequired,
  onUnitsReorder: PropTypes.func.isRequired,
  assignment: PropTypes.object,
  assignmentProgress: PropTypes.object,
  isEditMode: PropTypes.bool,
  isLtiStudent: PropTypes.bool,
  ltiProgress: PropTypes.object,
  match: PropTypes.object.isRequired,
  saveUnit: PropTypes.func.isRequired,
  destroySectionUnit: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
};

export default ProjectTile;
