import { connect } from 'react-redux';
import ResetPassword from './ResetPassword';
import { resetPassword } from '../../actions/authActions';

const mapStateToProps = (state, ownProps) => {
  const { resetPasswordToken } = ownProps.match.params;

  return {
    resetPasswordToken,
  };
};

export default connect(mapStateToProps, { resetPassword })(ResetPassword);
