import { connect } from 'react-redux';
import { getUser } from '../../actions/userActions';
import Profile from './Profile';
import { selectUser } from '../../selectors/userSelectors';

const mapStateToProps = (state, ownProps) => {
  const { userId } = ownProps.match.params;

  const user = selectUser(state, userId);

  return {
    user,
    userId,
  };
};

export default connect(mapStateToProps, {
  getUser,
})(Profile);
