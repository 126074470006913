import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import Icons from '../Utils/Icons';
import intl from '../../utils/intl';
import TooltipButton from '../Utils/Tooltip/TooltipButton';
import EditBootcampModal from '../Author/Bootcamps/EditBootcampModal/EditBootcampModal';
import showModal from '../../utils/showModal';
import { destroyBootcamp, copyBootcamp } from '../../actions/bootcampActions';
import { confirmInput } from '../../utils/confirmInput';
import { toastError, toastSuccess } from '../../utils/toastHelper';
import { selectCurrentUser } from '../../selectors/authSelectors';
import { canAdministrate } from '../../helpers/userAuth';

const Edit = ({ bootcamp, onClick }) => {
  const handleEdit = e => {
    e.preventDefault();

    showModal(EditBootcampModal, {
      bootcamp,
    });
  };

  return (
    <TooltipButton
      icon={<Icons.Pencil />}
      messageId="common.edit"
      onClick={e => onClick(e, handleEdit)}
      className="tooltip-button__button--split-top tooltip-button__button--grey"
    />
  );
};

Edit.propTypes = {
  bootcamp: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
};

const Delete = ({ bootcamp, onClick }) => {
  const dispatch = useDispatch();

  const handleDelete = e => {
    e.preventDefault();

    confirmInput({
      message: <FormattedMessage id="bootcamp.confirmDelete" values={{ name: bootcamp.title }} />,
      onOk: () => {
        dispatch(destroyBootcamp(bootcamp)).catch(err => {
          // eslint-disable-next-line no-console
          console.log('Error occurred', err);
          toastError((err.value && err.value.errors) || ['Unable to delete Course'], { autoClose: 5000 });
        });
      },
    });
  };

  return (
    <TooltipButton
      icon={<Icons.Trash />}
      messageId="common.delete"
      onClick={e => onClick(e, handleDelete)}
      className="tooltip-button__button--split-top tooltip-button__button--grey"
    />
  );
};
Delete.propTypes = {
  bootcamp: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
};

const Copy = ({ bootcamp, onClick }) => {
  const dispatch = useDispatch();

  const handleCopy = e => {
    e.preventDefault();

    confirmInput({
      message: <FormattedMessage id="bootcamp.copy.confirmPrompt" values={{ name: bootcamp.title }} />,
      onOk: () => {
        dispatch(copyBootcamp(bootcamp.id))
          .then(() => {
            toastSuccess(intl.formatMessage({ id: 'bootcamp.copy.success' }));
          })
          .catch(err => {
            // eslint-disable-next-line no-console
            console.log('Error occurred', err);
            toastError((err.value && err.value.errors) || ['Unable to delete Course'], { autoClose: 5000 });
          });
      },
    });
  };

  return (
    <TooltipButton
      icon={<Icons.Clipboard />}
      messageId="common.copy"
      onClick={e => onClick(e, handleCopy)}
      className="tooltip-button__button--split-bottom tooltip-button__button--grey"
    />
  );
};
Copy.propTypes = {
  bootcamp: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default function BootcampOptions({ bootcamp }) {
  const [isOpen, setIsOpen] = useState(false);
  const nodeRef = useRef(null);
  const currentUser = useSelector(selectCurrentUser);
  const isAdmin = canAdministrate(currentUser);

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick, false);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick, false);
    };
  }, [isOpen]);

  const handleOutsideClick = e => {
    if (nodeRef.current && nodeRef.current.contains(e.target)) {
      return;
    }

    if (isOpen) toggleOpen();
  };

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (e, callback) => {
    toggleOpen();
    callback(e);
  };

  return (
    <div ref={nodeRef}>
      <button
        type="button"
        className="course-card__button"
        aria-label={intl.formatMessage({ id: 'common.edit' })}
        onClick={toggleOpen}>
        <Icons.MoreSimple />
      </button>

      {isOpen && (
        <>
          <div className="course-options-wrapper">
            <div className="course-options-inner">
              <span className="course-options-title">{intl.formatMessage({ id: 'bootcamp.options' })}</span>
              <div className="course-options-heading">
                <h1 className="course-options-heading--text">{bootcamp.title}</h1>
              </div>
              <div className="course-options">
                <Edit bootcamp={bootcamp} onClick={handleOptionClick} />
                <Delete bootcamp={bootcamp} onClick={handleOptionClick} />
                {isAdmin && <Copy bootcamp={bootcamp} onClick={handleOptionClick} />}
              </div>
            </div>
          </div>
          <div className="course-options-overlay" />
        </>
      )}
    </div>
  );
}

BootcampOptions.propTypes = {
  bootcamp: PropTypes.object.isRequired,
};
