import { TENANT } from '../actions/constants';

const INITIAL_STATE = {
  current: null,
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case TENANT.SET_CURRENT:
      return {
        ...state,
        current: action.payload,
      };

    default:
      return state;
  }
}
