import PropTypes from 'prop-types';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileAlt } from '@fortawesome/free-solid-svg-icons';
import { FormattedMessage } from 'react-intl';
import AssignmentCard from './AssignmentCard';
import { selectUnitProgressEntities } from '../../../../../selectors/unitProgressSelectors';
import { useSelector } from 'react-redux';

const StudentAssignments = ({ student, units }) => {
  const allUnitProgresses = useSelector(selectUnitProgressEntities);

  const renderAssignmentsCards = () => {
    return units.map(unit => {
      const assignmentProgress = student.assignmentProgresses.find(
        assignmentProgress => assignmentProgress.assignment_id === unit.assignment_id,
      );
      const unitProgressId = Object.keys(allUnitProgresses).find(
        unitProgressId => allUnitProgresses[unitProgressId].assignment_progress_id === assignmentProgress?.id,
      );
      const unitProgress = unitProgressId ? allUnitProgresses[unitProgressId] : undefined;
      return (
        <AssignmentCard
          key={`${student.id}-${unit.id}`}
          unit={unit}
          assignmentProgress={assignmentProgress}
          unitProgress={unitProgress}
        />
      );
    });
  };

  return (
    <div className="student-assignments">
      <div className="student-assignments--title">
        <FontAwesomeIcon icon={faFileAlt} /> <FormattedMessage id="roster.assignments" />
      </div>
      <div className="student-assignments--cards-container">{renderAssignmentsCards()}</div>
    </div>
  );
};
export default StudentAssignments;

StudentAssignments.propTypes = {
  student: PropTypes.object.isRequired,
  units: PropTypes.array.isRequired,
};
