import { isEmpty as _isEmpty } from 'lodash';
import { connect } from 'react-redux';
import { selectPath } from '../../selectors/pathSelectors';
import PathShow from './PathShow';
import { selectBootcamp } from '../../selectors/bootcampSelectors';
import { fetchCurrentEnrollments, fetchEnrollmentForCohort, fetchEnrollment } from '../../actions/enrollmentActions';
import { selectProgressForPath } from '../../selectors/pathProgressSelectors';
import {
  selectSectionProgress,
  selectSectionProgressesForPathProgress,
} from '../../selectors/sectionProgressSelectors';
import { selectIsEditMode } from '../../selectors/contextSelectors';
import { reorderSections, saveSection } from '../../actions/sectionActions';
import { canLearn, canAuthor, canAdministrate, canCoach } from '../../helpers/userAuth';
import { selectCurrentUser } from '../../selectors/authSelectors';
import { fetchBootcamp, fetchBootcamps } from '../../actions/bootcampActions';
import { fetchLtiAssignmentProgresses } from '../../actions/ltiAssignmentProgressActions';
import { getProgressStatus } from '../../utils/utils';
import { selectEntitiesForBootcamp } from '../../selectors/cohortBootcampSelectors';
import { fetchCohort } from '../../actions/cohortActions';
import { selectSchool } from '../../selectors/schoolSelectors';
import { selectProgramCohort } from '../../selectors/programCohortSelectors';
import { fetchSchool } from '../../actions/schoolActions';
import { fetchGradedItems } from '../../actions/gradedItemActions';

const getShowIntroModal = (ownProps, isStudent, pathProgress, bootcamp, isEditMode, currentUser) => {
  let showIntroModal = false;
  if (!_isEmpty(new URLSearchParams(ownProps.location.search).get('overview'))) {
    if (isStudent) {
      if (bootcamp && pathProgress && !getProgressStatus(pathProgress).isComplete) {
        if (pathProgress.current_section_progress_id) {
          showIntroModal = bootcamp.show_returning_modal;
        } else {
          showIntroModal = bootcamp.show_intro_modal;
        }
      }
    } else {
      showIntroModal =
        !isEditMode && bootcamp?.show_intro_modal && (canAuthor(currentUser) || canAdministrate(currentUser));
    }
  }
  return showIntroModal;
};

const mapStateToProps = (state, ownProps) => {
  const { pathId, bootcampId } = ownProps.match.params;
  const { url: matchUrl } = ownProps.match;
  const currentUser = selectCurrentUser(state);
  const path = selectPath(state, pathId);
  const { enrollment, enrollmentId, cohort, cohortId, sectionsByPath } = selectEntitiesForBootcamp(state, bootcampId);
  const sections = (sectionsByPath && sectionsByPath[path.id]) || [];
  const bootcamp = selectBootcamp(state, bootcampId);
  const pathProgress = selectProgressForPath(state, pathId);
  let currentSectionProgress;
  if (!_isEmpty(pathProgress)) {
    currentSectionProgress = selectSectionProgress(state, pathProgress.current_section_progress_id);
  }
  const isEditMode = selectIsEditMode(state);
  const isStudent = canLearn(currentUser);
  const isCoach = canCoach(currentUser);
  const isLtiStudent = enrollment?.requires_lti_support;
  const sectionProgresses = pathProgress && selectSectionProgressesForPathProgress(state, pathProgress.id);
  const school = selectSchool(state, cohort?.school_id);
  const programCohort = enrollment ? selectProgramCohort(state, enrollment?.program_cohort_id) : null;

  const showIntroModal = getShowIntroModal(ownProps, isStudent, pathProgress, bootcamp, isEditMode, currentUser);
  const isLiteCourse = cohort?.bootcamp_instance_type === 'lite_course';

  return {
    currentUser,
    bootcampId,
    bootcamp,
    path,
    pathId,
    sections,
    enrollmentId,
    pathProgress,
    currentSectionProgress,
    isEditMode,
    isCoach,
    isStudent,
    isLtiStudent,
    sectionProgresses,
    cohortId,
    cohort,
    showIntroModal,
    school,
    matchUrl,
    isLiteCourse,
    programCohort,
  };
};

export default connect(mapStateToProps, {
  fetchEnrollmentForCohort,
  fetchCurrentEnrollments,
  reorderSections,
  saveSection,
  fetchBootcamp,
  fetchBootcamps,
  fetchLtiAssignmentProgresses,
  fetchEnrollment,
  fetchCohort,
  fetchSchool,
  fetchGradedItems,
})(PathShow);
