import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { fetchEnrollmentForCohort } from '../actions/enrollmentActions';

export default function useFetchEnrollmentForCohort(cohortId) {
  const dispatch = useDispatch();

  useEffect(() => {
    if (cohortId) {
      dispatch(fetchEnrollmentForCohort(cohortId));
    }
  }, [cohortId]);
}
