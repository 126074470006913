import { connect } from 'react-redux';
import ConfigureProjectModal from './ConfigureProjectModal';
import { saveAssignment } from '../../../actions/assignmentActions';
import { selectProjectsForBootcamp } from '../../../selectors/unitSelectors';
import { saveUnit } from '../../../actions/unitActions';

const mapStateToProps = (state, ownProps) => {
  const { bootcampId, unit } = ownProps;
  const projects = selectProjectsForBootcamp(state, bootcampId).filter(proj => proj.id !== unit.id);

  return {
    projects,
  };
};

export default connect(mapStateToProps, { saveAssignment, saveUnit })(ConfigureProjectModal);
