import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { setPageTitle } from '../../helpers/pageHelper';
import ProfilePictureContainer from './ProfilePictureContainer';
import BackLink from '../Utils/BackLink';

export default class StaffProfile extends Component {
  componentDidMount() {
    setPageTitle('pageName.studentProfile.my');
  }

  render() {
    const { user } = this.props;

    return (
      <div className="grid-row">
        <div className="sm-12">
          <div className="student-roster-content">
            <div className="student-roster-content__header">
              <div className="student-roster-content__go-back-wrapper">
                <BackLink className="go-back-wrapper---gray   go-back-wrapper---background-" goBack />
              </div>
              <div className="student-roster-content__content">
                <ProfilePictureContainer />

                <h1 className="student-roster-content__name">{user.name}</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
StaffProfile.propTypes = {
  user: PropTypes.object.isRequired,
};
