import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import intl from '../../utils/intl';
import Icons from './Icons';

export default function Tabs({
  className,
  wrapperTag,
  wrapperClass,
  items,
  prevBtnClass,
  nextBtnClass,
  selectedIndex,
}) {
  const [itemStartIndex, setItemStartIndex] = useState(0);
  const [showNext, setShowNext] = useState(false);
  const [tabWidth, setTabWidth] = useState(0);
  const [numTabsInViewport, setNumTabsInViewport] = useState(0);
  const containerRef = useRef(null);
  const wrapperRef = useRef(null);

  const WrapperElement = wrapperTag || 'div';

  const showPrev = itemStartIndex > 0;

  useEffect(() => {
    if (!containerRef.current || !wrapperRef.current) return;

    const childNode = wrapperRef.current.children[0];
    if (!childNode) return;

    const singleTabWidth = childNode.offsetWidth;
    const viewportWidth = containerRef.current.offsetWidth;
    const visibleTabCount = items.length - itemStartIndex;

    setNumTabsInViewport(Math.floor(viewportWidth / singleTabWidth));
    setShowNext(viewportWidth < visibleTabCount * singleTabWidth);
    setTabWidth(singleTabWidth);
  });

  useEffect(() => {
    const view = selectedIndex < 0 || numTabsInViewport === 0 ? 0 : Math.floor(selectedIndex / numTabsInViewport);
    setItemStartIndex(view * numTabsInViewport);
  }, [numTabsInViewport, selectedIndex]);

  const handleBtnClick = delta => {
    let newIndex = Math.max(0, itemStartIndex + delta);
    newIndex = Math.min(items.length, itemStartIndex + delta);
    setItemStartIndex(newIndex);
  };

  const handleNextClick = () => {
    handleBtnClick(numTabsInViewport);
  };

  const handlePrevClick = () => {
    handleBtnClick(-numTabsInViewport);
  };

  return (
    <div className={className} style={{ overflow: 'hidden' }} ref={containerRef}>
      <button
        type="button"
        className={classnames(prevBtnClass, showPrev && `${prevBtnClass}--visible`)}
        aria-label={intl.formatMessage({ id: 'common.previous' })}
        onClick={handlePrevClick}>
        <Icons.ArrowPrev />
      </button>

      <WrapperElement
        className={wrapperClass}
        ref={wrapperRef}
        style={{ width: 'inherit', marginLeft: `${-itemStartIndex * tabWidth}px`, transition: 'all 0.3s' }}>
        {items}
      </WrapperElement>

      <button
        type="button"
        className={classnames(nextBtnClass, showNext && `${nextBtnClass}--visible`)}
        aria-label={intl.formatMessage({ id: 'common.next' })}
        onClick={handleNextClick}>
        <Icons.ArrowNext />
      </button>
    </div>
  );
}

Tabs.propTypes = {
  className: PropTypes.string,
  wrapperTag: PropTypes.string,
  wrapperClass: PropTypes.string,
  items: PropTypes.array,
  prevBtnClass: PropTypes.string,
  nextBtnClass: PropTypes.string,
  selectedIndex: PropTypes.number,
};

Tabs.defaultProps = {
  selectedIndex: 0,
};
