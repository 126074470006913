import { connect } from 'react-redux';
import Question from './Question';
import { selectProgressForQuiz } from '../../selectors/quizProgressSelectors';
import { selectQuizByQuestion } from '../../selectors/quizSelectors';
import { submitQuizProgress } from '../../actions/quizProgressActions';

const mapStateToProps = (state, ownProps) => {
  const { question } = ownProps;
  const quiz = selectQuizByQuestion(state, question);
  const quizProgress = selectProgressForQuiz(state, quiz);

  return {
    quizProgress,
  };
};

export default connect(mapStateToProps, {
  submitQuizProgress,
})(Question);
