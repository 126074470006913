import { connect } from 'react-redux';
import BlockShow from './BlockShow';
import { selectBlock } from '../../selectors/blockSelectors';

const mapStateToProps = (state, ownProps) => {
  const block = ownProps.block || selectBlock(state, ownProps.blockId);

  return {
    block,
  };
};

export default connect(mapStateToProps)(BlockShow);
