import React from 'react';
import PropTypes from 'prop-types';
import { Radio, Popover } from 'antd';
import classnames from 'classnames';

import RubricCard from '../Rubric/RubricCard';
import { getProficiencyLevel } from '../../../../../utils/utils';
import { GRADING_RANGE_MAX, GRADING_RANGE_MIN } from '../../../../../common/constants';

const FancyGroup = () => (
  <div className="fancy-grouping">
    <div className="grade-group-4">D/F</div>
    <div className="grade-group-3">C</div>
    <div className="grade-group-2">B</div>
    <div className="grade-group-1">A</div>
  </div>
);

const GradingScale = ({
  onGradeSelect,
  rangeMax = GRADING_RANGE_MAX,
  rangeMin = GRADING_RANGE_MIN,
  selectedGrade,
  rubric,
  title,
  children,
}) => {
  const adjustedMin = rangeMin >= 0 && rangeMin < rangeMax ? rangeMin : GRADING_RANGE_MIN;
  const adjustedMax = rangeMax >= 0 && rangeMax > rangeMin ? rangeMax : GRADING_RANGE_MAX;
  const scoreRange = Array.from({ length: adjustedMax - adjustedMin + 1 }, (v, i) => i + adjustedMin);

  // TODO: might look outside of the antd radio group due to difficulty with styling selected/unselected states
  return (
    <div className="grade-scale">
      {title}
      <Radio.Group size="large">
        {scoreRange.map(score => {
          const scoreBtnsClasses = classnames('grade-radio-btn', { selected: selectedGrade.score === score });
          const currentProficiencyLevel = getProficiencyLevel(rubric, selectedGrade.criterion_id, score);

          return (
            <Popover key={score} content={<RubricCard {...currentProficiencyLevel} />} trigger="hover">
              <Radio.Button key={score} className={scoreBtnsClasses} value={score} onClick={onGradeSelect}>
                {score}
              </Radio.Button>
            </Popover>
          );
        })}
      </Radio.Group>
      {children}
    </div>
  );
};

GradingScale.propTypes = {
  onGradeSelect: PropTypes.func,
  rangeMax: PropTypes.number,
  rangeMin: PropTypes.number,
  selectedGrade: PropTypes.object.isRequired,
  showHoverInfo: PropTypes.bool,
  rubric: PropTypes.array,
  title: PropTypes.object,
};

export { GradingScale, FancyGroup };
