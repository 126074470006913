import { compareCaseInsensitive } from '../utils';

// TODO: separate to explicit functions that does one thing instead of overloading
// to support multiple data source type
export const getDataSourcePath = ({ type, order, name }) => {
  const locIndex = order && parseInt(order, 10);
  switch (type) {
    case 'byOrder':
      return path => path[locIndex];
    case 'byName':
      return path =>
        path.find(where => {
          if (where.name) return compareCaseInsensitive(where.name, name);
          if (where.title) return compareCaseInsensitive(where.title, name);
          return false;
        });
    case 'byTitle':
      return path =>
        path.find(where => {
          return !!where.title && compareCaseInsensitive(where.title, name);
        });
    case 'byAny':
      return path => Array.prototype.some.bind(path);
    case 'byAll':
      return path => Array.prototype.every.bind(path);
    default:
      return () => null;
  }
};

// get specific location right before the last location path
export const getLocationPath = (workbook, location) => {
  let path = { ...workbook };
  for (let i = 0; i < location.length - 1; i += 1) {
    switch (location[i].within) {
      case 'worksheet':
        path = getDataSourcePath(location[i].dataSource)(path.sheets);
        break;
      case 'chart':
        path = getDataSourcePath(location[i].dataSource)(path.charts);
        break;
      case 'pivotTable':
        path = getDataSourcePath(location[i].dataSource)(path.pivotTables);
        break;
      case 'columns':
      case 'columns2':
      case 'rows':
      case 'rowValue':
        break;
      default:
        path = null;
    }
    if (!path) return null;
  }
  return path;
};
