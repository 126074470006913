import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import EditVideoModal from './EditVideoModal';
import { EditModal as EditTypeformModal } from '../../Blocks/Typeform/Edit';
import EditEmbedModal from './EditEmbedModal';
import EditLabModal from './EditLabModal';
import showModal from '../../../utils/showModal';
import Block from '../../../api/block';
import { toastException } from '../../../utils/toastHelper';
import TooltipButtons from '../../Utils/Tooltip/TooltipButtons';
import { buildBlock, createBlock } from '../../../actions/blockActions';
import { selectLessonParts } from '../../../selectors/lessonSelectors';
import AddButton from './AddButton';
import useHandleOutsideClick from '../../../hooks/useHandleOutsideClick';
import useCurrentComponent from '../../../hooks/useCurrentComponent';

export default function AddBlock({ isLab, lesson, part, prevSeqNum, isGradedQuiz }) {
  const [isOpen, setIsOpen] = useState(false);
  const nodeRef = useHandleOutsideClick(isOpen, () => {
    if (isOpen) toggleOpen();
  });
  const dispatch = useDispatch();
  // use first part if none given
  const firstPart = useSelector(s => selectLessonParts(s, lesson.id))[0];
  const blockPart = part || firstPart;

  const { bootcamp } = useCurrentComponent();

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  const handleClick = callback => e => {
    toggleOpen();
    callback(e);
  };

  const handleEditBlockModal = (klass, blockType) => {
    const block = buildBlock(blockType, blockPart, lesson, prevSeqNum + 1);
    showModal(klass, { block });
  };

  const handleCreateBlock = async blockType => {
    try {
      await createBlock(blockType, blockPart, lesson, prevSeqNum + 1)(dispatch);
    } catch (err) {
      toastException(err, 'Unable to save block');
    }
  };

  let addButtons = [];

  if (isGradedQuiz) {
    addButtons = addButtons.concat([
      {
        messageId: 'block.addHtml',
        onClick: handleClick(() => handleCreateBlock(Block.HTML)),
      },
    ]);
  } else {
    addButtons = addButtons.concat([
      {
        messageId: 'block.addHtml',
        onClick: handleClick(() => handleCreateBlock(Block.HTML)),
      },
      {
        messageId: 'block.addCollapsibleHtml',
        onClick: handleClick(() => handleCreateBlock(Block.COLLAPSIBLE_HTML)),
      },
      {
        messageId: 'block.addVideo',
        onClick: handleClick(() => handleEditBlockModal(EditVideoModal, Block.VIDEO)),
      },
      {
        messageId: 'block.addQuiz',
        onClick: handleClick(() => handleCreateBlock(Block.QUIZ)),
      },
      {
        messageId: 'block.addSingleQuestionQuiz',
        onClick: handleClick(() => handleCreateBlock(Block.SINGLE_QUESTION_QUIZ)),
      },
      {
        messageId: 'block.addEmbed',
        onClick: handleClick(() => handleEditBlockModal(EditEmbedModal, Block.EMBED)),
      },
      {
        messageId: 'block.addTypeform',
        onClick: handleClick(() => handleEditBlockModal(EditTypeformModal, Block.TYPEFORM)),
      },
    ]);

    if (!isLab) {
      addButtons = addButtons.concat([
        {
          messageId: 'block.addLab',
          onClick: handleClick(() => handleEditBlockModal(EditLabModal, Block.LAB)),
        },
        {
          messageId: 'block.addCommentThread',
          onClick: handleClick(() => handleCreateBlock(Block.COMMENT_THREAD)),
        },
        {
          messageId: 'block.addSql',
          onClick: handleClick(() => handleCreateBlock(Block.SQL)),
        },
      ]);
    }

    if (bootcamp?.requires_asana) {
      addButtons = addButtons.concat([
        {
          messageId: 'block.addAsanaAccount',
          onClick: handleClick(() => handleCreateBlock(Block.ASANA_ACCOUNT)),
        },
      ]);
    }
  }

  return (
    <>
      <div ref={nodeRef}>
        <AddButton onClick={toggleOpen} spacingOnTop={prevSeqNum === 0}>
          {isOpen && <TooltipButtons buttons={addButtons} onClose={toggleOpen} />}
        </AddButton>
      </div>
    </>
  );
}

AddBlock.propTypes = {
  lesson: PropTypes.object.isRequired,
  part: PropTypes.object,
  prevSeqNum: PropTypes.number,
  isLab: PropTypes.bool,
};
