import { useEffect, useState } from 'react';
import { keyBy as _keyBy } from 'lodash';
import LtiV13AssignmentMapping from '../api/ltiV13AssignmentMapping';
import useTenant from './useTenant';

export default function useLtiAssignmentMappings(cohortId) {
  const [assignmentMappings, setAssignmentMappings] = useState({});
  const tenant = useTenant();

  async function fetchAssignmentMappings() {
    if (!cohortId || !tenant) return;

    const response = await LtiV13AssignmentMapping.all(tenant.id, cohortId);
    setAssignmentMappings(_keyBy(response.value, 'assignment_id'));
  }

  useEffect(() => {
    fetchAssignmentMappings();
  }, [cohortId, tenant]);

  return [assignmentMappings, setAssignmentMappings];
}
