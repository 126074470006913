import PropTypes from 'prop-types';
import React from 'react';
import { MinusCircleOutlined } from '@ant-design/icons';
import CustomSelect from '../../../Utils/CustomSelect';
import intl from '../../../../utils/intl';
import CustomInput from '../../../Utils/CustomInput';

const FilterRow = ({
  name,
  field,
  value,
  onFieldsFilterChange,
  onSelectFilterValueChange,
  onTextFilterValueChange,
  fieldsList,
  onRemoveFilter,
}) => {
  const renderFilterValue = valueName => {
    switch (field.type) {
      case 'select':
        return (
          <CustomSelect
            id={`${valueName}_value`}
            name={valueName}
            options={field.options}
            className="Select--simple filter-select"
            value={value}
            onChange={onSelectFilterValueChange}
            clearable={false}
            multi={field.multi}
            placeholder={field.label}
          />
        );
      case 'text':
      default:
        return (
          <div className="filter-text">
            <CustomInput
              id={`${valueName}_value`}
              name={valueName}
              value={value}
              onChange={onTextFilterValueChange}
              label={field.label}
            />
          </div>
        );
    }
  };

  return (
    <div className="filter-row-container">
      <CustomSelect
        name={name}
        options={fieldsList}
        className="Select--simple filter-select"
        value={field ? field.name : undefined}
        onChange={onFieldsFilterChange}
        clearable={false}
        placeholder={intl.formatMessage({ id: 'common.selectFilter' })}
      />
      {field && renderFilterValue(field.name)}
      <MinusCircleOutlined className="minus-filter-icon" onClick={() => onRemoveFilter(name)} />
    </div>
  );
};

export default FilterRow;

FilterRow.propTypes = {
  fieldsList: PropTypes.array.isRequired,
  value: PropTypes.any,
  name: PropTypes.string.isRequired,
  onFieldsFilterChange: PropTypes.func.isRequired,
  onSelectFilterValueChange: PropTypes.func.isRequired,
  onTextFilterValueChange: PropTypes.func.isRequired,
  onRemoveFilter: PropTypes.func.isRequired,
  field: PropTypes.object,
};
