import { connect } from 'react-redux';
import { saveProfile } from '../../actions/userActions';
import ProfilePicture from './ProfilePicture';
import { selectCurrentUser } from '../../selectors/authSelectors';

const mapStateToProps = state => ({
  user: selectCurrentUser(state),
});

export default connect(mapStateToProps, {
  saveProfile,
})(ProfilePicture);
