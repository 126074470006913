import React, { Component } from 'react';
import classnames from 'classnames';
import UserPicture from '../../Utils/UserPicture';

export default class StudentCard extends Component {
  handleKeyPress = e => {
    if (e.key === 'Enter') {
      this.handleClick();
    }
  };

  handleClick = () => {
    const { info, onSelect } = this.props;
    onSelect && onSelect(info);
  };

  render() {
    const { info, isSelected } = this.props;
    const { student } = info;

    return (
      <div
        className={classnames('student-item', isSelected && 'student-item--selected')}
        tabIndex="0"
        role="button"
        onKeyPress={this.handleKeyPress}
        onClick={this.handleClick}>
        <div className="student-item__avatar">
          <UserPicture user={student} className="user-profile-picture" />
        </div>

        <div className="student-item__text">
          <span className="student-item__name">{student.name}</span>
          {info.ungraded_count > 0 && (
            <span
              className={classnames('student-item__grade-count', isSelected && 'student-item__grade-count--selected')}>
              {info.ungraded_count}
            </span>
          )}
        </div>
      </div>
    );
  }
}
