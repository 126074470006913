import { datadogRum } from '@datadog/browser-rum';
import gitInfo from '../gitInfo';

export function initialize() {
  datadogRum.init({
    applicationId: 'dbcd3833-4802-465d-8cfe-0a5eda9d6963',
    clientToken: 'pub9fa56640e720bd65086f85763cdb470b',
    site: 'datadoghq.com',
    service: 'frontend-client',
    env: window.location.hostname,
    version: gitInfo.hash,
    sampleRate: 100,
    useSecureSessionCookie: true,
    trackInteractions: true,
    allowedTracingOrigins: [/https:\/\/.*\.pathstream\.com/],
  });
}
