import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga';

export default function GoogleAnalytics() {
  const location = useLocation();

  useEffect(() => {
    ReactGA.initialize('UA-150278515-1', {
      siteSpeedSampleRate: 100,
    });
  }, []);

  useEffect(() => {
    setTimeout(() => {
      ReactGA.pageview(location.pathname);
    }, 0);
  }, [location]);
  return null;
}
