import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from '../../api/axios';
import { toastError } from '../../utils/toastHelper';
import intl from '../../utils/intl';

class AjaxDownloadLink extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isDownloading: false,
    };
  }

  handleDownload = async () => {
    const { href } = this.props;
    const { isDownloading } = this.state;

    if (!href || isDownloading) return;

    this.setState({ isDownloading: true });

    try {
      const response = await axios.get(href, {
        responseType: 'arraybuffer',
      });
      const disposition = response.headers['content-disposition'];
      const matches = /"([^"]*)"/.exec(disposition);
      const fileName = matches != null && matches[1] ? matches[1] : 'download';

      const blob = new Blob([response.data], { type: response.headers['content-type'] });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = fileName;

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('Error downloading content', e);
      toastError(intl.formatMessage({ id: 'common.errorDownload' }));
      throw e;
    } finally {
      this.setState({ isDownloading: false });
    }
  };

  render() {
    const { className } = this.props;

    return (
      <div
        onClick={this.handleDownload}
        onKeyPress={this.handleDownload}
        role="button"
        tabIndex="0"
        className={className}>
        {this.props.children}
      </div>
    );
  }
}

AjaxDownloadLink.propTypes = {
  href: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.any,
};

export default AjaxDownloadLink;
