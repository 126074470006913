import PropTypes from 'prop-types';
import React, { useState } from 'react';
import intl from '../../../../../utils/intl';
import { FormattedMessage } from 'react-intl';
import { Popover } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faList, faTimes, faUserCircle } from '@fortawesome/free-solid-svg-icons';
import Icons from '../../../../Utils/Icons';

const StudentsFilter = ({ filters, onSelectFilter, searchText, onSearchChange }) => {
  const [filterVisible, setFilterVisible] = useState(false);

  const handleFilterSelect = key => {
    onSelectFilter(key);
    setFilterVisible(false);
  };

  const getFilterContent = () => {
    return (
      <div>
        <a
          href="#"
          onClick={() => handleFilterSelect('studentsToWatch')}
          className="students-list-filter-option"
          data-testid="students-to-watch-filter">
          <Icons.StudentToWatch /> <FormattedMessage id="cohortDashboard.studentsPage.studentsToWatch" />
        </a>
        <a
          href="#"
          onClick={() => handleFilterSelect('studentsToGrade')}
          className="students-list-filter-option"
          data-testid="students-to-grade-filter">
          <FontAwesomeIcon icon={faList} /> <FormattedMessage id="cohortDashboard.studentsPage.studentsToGrade" />
        </a>
      </div>
    );
  };
  return (
    <div className="students-list-filter-container">
      <div className="students-list-filter-section">
        <div className="students-list-filter-text-input">
          <div className="custom-search-input">
            <input
              data-testid="search-input"
              className="custom-search-input__input"
              type="text"
              value={searchText}
              onChange={e => onSearchChange(e.target.value)}
              placeholder={intl.formatMessage({ id: 'cohortDashboard.studentsPage.searchForStudent' })}
              aria-label={intl.formatMessage({ id: 'cohortDashboard.studentsPage.searchForStudent' })}
            />
          </div>
        </div>
        <Popover
          content={getFilterContent()}
          placement="bottomRight"
          trigger="click"
          visible={filterVisible}
          onVisibleChange={setFilterVisible}>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a
            href="#"
            onClick={() => setFilterVisible(true)}
            className="students-list-filter-button"
            data-testid="filter-button">
            <Icons.Filter /> <FormattedMessage id="cohortDashboard.studentsPage.filter" />
          </a>
        </Popover>
      </div>
      <div className="selected-filter-option-container">
        {filters.studentsToWatch && (
          <div className="selected-filter-option" data-testid="selected-students-to-watch">
            <FontAwesomeIcon icon={faUserCircle} />
            <FormattedMessage id="cohortDashboard.studentsPage.studentsToWatch" />
            <span className="remove-selected-filter-icon" onClick={() => onSelectFilter('studentsToWatch')}>
              <FontAwesomeIcon icon={faTimes} />
            </span>
          </div>
        )}
        {filters.studentsToGrade && (
          <div className="selected-filter-option" data-testid="selected-students-to-grade">
            <FontAwesomeIcon icon={faList} />
            <FormattedMessage id="cohortDashboard.studentsPage.studentsToGrade" />
            <span className="remove-selected-filter-icon" onClick={() => onSelectFilter('studentsToGrade')}>
              <FontAwesomeIcon icon={faTimes} />
            </span>
          </div>
        )}
      </div>
    </div>
  );
};
export default StudentsFilter;

StudentsFilter.propTypes = {
  filters: PropTypes.object.isRequired,
  onSearchChange: PropTypes.func.isRequired,
  onSelectFilter: PropTypes.func.isRequired,
  searchText: PropTypes.string.isRequired,
};
