import React, { useEffect } from 'react';
import AdminWrapper from './v2/Nav/Admin/AdminWrapper';
import * as analytics from '../utils/analytics';

const NotFound = () => {
  // Equivalent to componentDidMount and componentWillUnmount
  useEffect(() => {
    analytics.pageStart('Page Not Found');

    // Cleanup function equivalent to componentWillUnmount
    return () => {
      analytics.pageEnd();
    };
  }, []);

  return <AdminWrapper hideStaffNav>404 Not Found.</AdminWrapper>;
};

export default NotFound;
