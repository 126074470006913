import React from 'react';
import FinalGrade from './FinalGrade';
import GradedRowContainer from './GradedRowContainer';
import PropTypes from 'prop-types';

const GradesTable = ({ bootcamp, gradedItems, matchUrl, hideDueDates }) => {
  let quizIndex = 1;
  let projectIndex = 1;

  return (
    <div>
      <table className="grades-table" data-testid="grades-table">
        <thead>
          <tr>
            <th>Grade Item</th>
            <th>Submitted</th>
            <th>Feedback</th>
            <th>Weight</th>
            <th>Grade</th>
            <th>Status</th>
            {!hideDueDates && <th>Due Date</th>}
          </tr>
        </thead>
        <tbody>
          {gradedItems.map((item, index) => {
            return (
              <GradedRowContainer
                key={index}
                bootcamp={bootcamp}
                item={item}
                matchUrl={matchUrl}
                relativeIndex={item.template_type === 'quiz' ? quizIndex++ : projectIndex++}
                hideDueDates={hideDueDates}
              />
            );
          })}
          <FinalGrade gradedItems={gradedItems} hideDueDates={hideDueDates} />
        </tbody>
      </table>
    </div>
  );
};

GradesTable.propTypes = {
  bootcamp: PropTypes.object.isRequired,
  gradedItems: PropTypes.array.isRequired,
  matchUrl: PropTypes.string.isRequired,
};

export default GradesTable;
