import React, { Component } from 'react';
import logo from '../../assets/pathstream.png';

export default class PrintHeader extends Component {
  render() {
    const { title, subTitle } = this.props;

    return (
      <div className="print-header">
        <img alt="Pathstream" src={logo} className="logo" />
        <h1 className="title">{title}</h1>
        {subTitle && <div className="sub-title">{subTitle}</div>}
      </div>
    );
  }
}
