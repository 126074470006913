import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { selectProgressForLesson } from '../../selectors/lessonProgressSelectors';
import PartTab from './PartTab';
import useLesson from '../../hooks/useLesson';

export default function PartTabs({ lessonId }) {
  const { lesson, parts } = useLesson(lessonId);
  const lessonProgress = useSelector(s => (lessonId ? selectProgressForLesson(s, lessonId) : {}));

  return (
    <ul className="header-bar__parts">
      {parts.map((part, partIndex) => (
        <PartTab key={part.id} part={part} partIndex={partIndex} lesson={lesson} lessonProgress={lessonProgress} />
      ))}
    </ul>
  );
}

PartTabs.propTypes = {
  lessonId: PropTypes.string,
};
