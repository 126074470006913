/* eslint-disable complexity */
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useSelector } from 'react-redux';
import { useParams, Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { selectProgressForUnit } from '../../selectors/unitProgressSelectors';
import { getProgressStatus } from '../../utils/utils';
import Icons from '../Utils/Icons';
import Unit from '../../api/unit';
import SvgCircularProgress from '../Utils/SvgCircularProgress';
import { selectComponent } from '../../selectors/componentSelectors';
import { selectUnitComponents } from '../../selectors/unitSelectors';
import { getCoursePathByIds } from '../../helpers/pathHelper';
import useCurrentUser from '../../hooks/useCurrentUser';
import useAssignmentProgress from '../../hooks/useAssignmentProgress';
import intl from '../../utils/intl';

const getUnitIcon = (isComplete, unit) => {
  if (isComplete) {
    return <Icons.CircleCheckChecked />;
  }
  if (unit.unit_type === Unit.PROJECT) {
    return <Icons.Project />;
  }
  if (unit.unit_type === Unit.GRADED_QUIZ) {
    return <Icons.GradedQuiz />;
  }
  return <Icons.Module />;
};

const getHeading = (isCurrent, isComplete, unit) => {
  const unitTypeCamelCase = unit.unit_type.toLowerCase().replace(/[_][a-z]/g, group => group.slice(-1).toUpperCase());

  let copySuffix = unitTypeCamelCase.charAt(0).toUpperCase() + unitTypeCamelCase.slice(1);

  if (isComplete) {
    return <FormattedMessage id={`unit.completed${copySuffix}`} />;
  }
  if (isCurrent) {
    return <FormattedMessage id={`unit.current${copySuffix}`} />;
  }

  return <FormattedMessage id={`unit.${unitTypeCamelCase}`} />;
};

export default function UnitHeaderTile({ section, unit, isCurrent, currentComponentId, onClick }) {
  const unitProgress = useSelector(s => selectProgressForUnit(s, section.id, unit.id));
  const { isComplete } = getProgressStatus(unitProgress);
  const componentCount = (useSelector(s => selectUnitComponents(s, unit.id)) || []).length;
  const unitComponents = useSelector(s => (unit ? selectUnitComponents(s, unit.id) : []));
  const currentComponent = useSelector(s => selectComponent(s, currentComponentId));
  const { bootcampId, pathId, sectionId } = useParams();
  const { currentUser } = useCurrentUser();
  const isProject = unit && unit.unit_type === Unit.PROJECT;
  const isGradedQuiz = unit && unit.unit_type === Unit.GRADED_QUIZ;
  const { launchFromLMS, prerequisiteComplete } = useAssignmentProgress(isProject ? unit.external_id : null);

  const linkEnabled =
    (!isProject && unitComponents.length > 0) || (isProject && !launchFromLMS && prerequisiteComplete) || isGradedQuiz;

  let link = getCoursePathByIds(currentUser, bootcampId, pathId);
  if (isProject) {
    link += `/sections/${sectionId}/projects/${unit.id}`;
  } else if (isGradedQuiz) {
    link += `/sections/${sectionId}/graded_quizzes/${unit.id}`;
  } else if (unitComponents.length > 0) {
    link += `/sections/${sectionId}/units/${unit.id}/components/${unitComponents[0].id}`;
  }

  const linkAttrs = {};
  if (linkEnabled) {
    linkAttrs.to = link;
    linkAttrs.onClick = onClick;
  } else if (isProject) {
    linkAttrs['data-tip'] = intl.formatMessage({
      id: 'lti.messages.launchFromLMS',
    });
    linkAttrs.title = linkAttrs['data-tip'];
  }

  const Comp = linkEnabled ? Link : 'div';

  return (
    <div
      className={classnames(
        'header-panel__module',
        isCurrent && 'header-panel__module--active',
        isComplete && !isCurrent && 'header-panel__module--completed',
      )}>
      <Comp {...linkAttrs} className="header-panel__module-link">
        {unitProgress && isCurrent && (
          <SvgCircularProgress className="header-panel__module-progress" size="big" progress={unitProgress.progress} />
        )}
        <h6 className="header-panel__module-heading">
          {getUnitIcon(isComplete, unit)}
          {getHeading(isCurrent, isComplete, unit)}
        </h6>
        <h6 className="header-panel__module-title">{unit.title}</h6>
        {isCurrent && (
          <div className="header-panel__module-lesson">
            <div className="header-panel__module-lesson-title">
              <FormattedMessage id="component.currentLesson" />
            </div>
            <div className="header-panel__module-lesson-description">{currentComponent && currentComponent.title}</div>
          </div>
        )}
        {unit.unit_type === Unit.MODULE && (
          <ul className="header-panel__module-list">
            <li className="header-panel__module-item">
              <FormattedMessage id="unit.componentCount" values={{ count: componentCount }} />
            </li>
          </ul>
        )}
      </Comp>
    </div>
  );
}

UnitHeaderTile.propTypes = {
  section: PropTypes.object,
  unit: PropTypes.object,
  isCurrent: PropTypes.bool,
  currentComponentId: PropTypes.string,
  onClick: PropTypes.func,
};
