import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { confirmInput } from '../../../utils/confirmInput';
import { toastError } from '../../../utils/toastHelper';
import Loading from '../../Utils/Loading';
import { formatDate } from '../../../utils/utils';
import showModal from '../../../utils/showModal';
import EnrollStudentContainer from './EnrollStudentContainer';
import EnrollUserContainer from './EnrollUserContainer';
import HideContentModalContainer from './HideContent/HideContentModalContainer';
import User from '../../../api/user';
import OptionsPopup from '../../Utils/OptionsPopup';
import EditCohortModal from './EditCohortModal';
import CohortAssignmentsDueDatesModal from './CohortAssignmentsDueDatesModal';
import CourseSectionDueDatesModal from './CourseSectionDueDatesModal';

export default class CohortListItem extends Component {
  static propTypes = {
    bootcamp: PropTypes.object,
    school: PropTypes.object,
    cohort: PropTypes.object.isRequired,
    destroyCohort: PropTypes.func.isRequired,
    ltiConsumers: PropTypes.array.isRequired,
  };

  handleDelete = () => {
    const { cohort } = this.props;
    confirmInput({
      message: <FormattedMessage id="cohort.confirmDelete" values={{ name: cohort.location }} />,
      onOk: () => {
        this.props.destroyCohort(cohort).catch(err => {
          console.error('Error occurred', err);
          toastError(err.value.errors, { autoClose: 5000 });
        });
      },
    });
  };

  handleOptionClick = (Modal, mode, role) => {
    const { cohort, ltiConsumers } = this.props;

    return () => {
      showModal(Modal, {
        cohort,
        mode,
        role,
        ltiConsumers,
      });
    };
  };

  renderOptions = () => {
    const options = [
      {
        label: <FormattedMessage id="common.edit" />,
        onClick: this.handleOptionClick(EditCohortModal),
        buttonClass: 'button-standard--transparent',
      },
      {
        label: <FormattedMessage id="cohort.enrollStudents" />,
        onClick: this.handleOptionClick(EnrollStudentContainer, 'enroll'),
      },
      {
        label: <FormattedMessage id="cohort.enrollCoaches" />,
        onClick: this.handleOptionClick(EnrollUserContainer, 'enroll', User.COACH),
      },
      {
        label: <FormattedMessage id="cohort.unenrollStudents" />,
        onClick: this.handleOptionClick(EnrollStudentContainer, 'unenroll'),
      },
      {
        label: <FormattedMessage id="cohort.unenrollCoaches" />,
        onClick: this.handleOptionClick(EnrollUserContainer, 'unenroll', User.COACH),
      },
      {
        label: <FormattedMessage id="cohort.manageCourseSectionDueDates" />,
        onClick: this.handleOptionClick(CourseSectionDueDatesModal),
      },
      {
        label: <FormattedMessage id="cohort.manageAssignmentsAndGrades" />,
        onClick: this.handleOptionClick(CohortAssignmentsDueDatesModal),
      },
      {
        label: <FormattedMessage id="cohort.hideContent" />,
        onClick: this.handleOptionClick(HideContentModalContainer),
      },
      {
        label: <FormattedMessage id="common.delete" />,
        onClick: this.handleDelete,
        buttonContainerClass: 'user-options__button-action',
      },
    ];

    return <OptionsPopup options={options} />;
  };

  render() {
    const { bootcamp, cohort, school } = this.props;

    if (!bootcamp) {
      return <Loading />;
    }

    return (
      <div className="user-content">
        <div className="grid-row user-content-items clear">
          <div className="md-4 user-content-item">
            <h3>
              {formatDate(cohort.start_date)} → {formatDate(cohort.end_date)}
            </h3>
            <h5>{bootcamp.title}</h5>
            {bootcamp.version && <h5>{bootcamp.version}</h5>}
          </div>

          <div className="sm-3 xs-12 user-content-item">
            <div className="lesson-location">{cohort.location}</div>
          </div>

          <div className="sm-1 sm-offset-1 xs-12 user-content-item">
            <div className="value">{school?.name}</div>
          </div>

          <div className="sm-1 xs-12 user-content-item">
            <div className="value">{cohort.num_enrolled}</div>
          </div>

          <div className="sm-1 xs-12 user-content-button clear">{this.renderOptions()}</div>
        </div>
      </div>
    );
  }
}
