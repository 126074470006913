import { selectEntities, select, selectAll, selectFromBy } from './applicationSelectors';

const selectBlockEntities = selectEntities('blocks');
const selectBlocks = selectAll(selectBlockEntities);
export const selectBlock = select(selectBlockEntities);

const selectBlockByQuizId = selectFromBy(selectBlocks, 'external_id');
export const selectBlockByQuestion = (state, question) => selectBlockByQuizId(state, question.quiz_id);

export const selectBlockByTutorial = selectFromBy(selectBlocks, 'external_id');
