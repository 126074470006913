import { normalize } from 'normalizr';
import { setUser } from './authActions';
import User from '../api/user';
import { fetchCurrentTenant } from './tenantActions';
import { updateEntities } from './entityActions';
import Schemas from '../api/schemas';

/**
 * Save the user
 * @param user - User
 * @returns {function(*)}
 */
export const saveProfile = user => dispatch =>
  User.updateProfile(user).then(user => {
    dispatch(setUser(user));
    return user;
  });

export const fetchUsers = (
  searchText,
  page,
  pageSize,
  sortColumn,
  sortDesc,
  roles,
  excludeIds,
  includeIds,
  excludeCohort,
  includeCohort,
) => dispatch =>
  fetchCurrentTenant()(dispatch).then(tenant =>
    User.list(tenant.id, {
      search_text: searchText,
      page,
      page_size: pageSize,
      sort_column: sortColumn,
      sort_order: sortDesc ? 'desc' : 'asc',
      exclude_ids: excludeIds,
      include_ids: includeIds,
      roles,
      exclude_cohort: excludeCohort,
      include_cohort: includeCohort,
    }),
  );

export const getUser = userId => dispatch =>
  User.get(userId).then(user => {
    const { entities } = normalize(user, Schemas.user);
    dispatch(updateEntities({ entities }));
    return user;
  });

export const saveUser = user => dispatch => fetchCurrentTenant()(dispatch).then(tenant => User.save(tenant.id, user));

export const getUsers = userIds => dispatch =>
  User.getUsers(userIds).then(users => {
    const { entities } = normalize(users, Schemas.users);
    dispatch(updateEntities({ entities }));

    return users;
  });
