import React from 'react';
import PropTypes from 'prop-types';
import Icons from '../Utils/Icons';
import { useSelector } from 'react-redux';
import Tutorial from '../../api/tutorial';
import { selectCurrentUser } from '../../selectors/authSelectors';
import { isEmpty as _isEmpty } from 'lodash';
import { selectProgressForAssignment } from '../../selectors/assignmentProgressSelectors';
import { selectLtiProgressForAssignment } from '../../selectors/ltiAssignmentProgressSelectors';
import LaunchAssignmentStepsModal from './LaunchAssignmentStepsModal';
import LmsExplanation from '../../api/lmsExplanation';
import { openLab } from '../../helpers/labHelper';
import showModal from '../../utils/showModal';
import { selectAssignment } from '../../selectors/assignmentSelectors';
import { selectBootcampInstanceAssignments } from '../../selectors/bootcampInstanceAssignmentSelectors';
import moment from 'moment';
import Tag from '../library/Tag';
import { FormattedMessage } from 'react-intl';

/* eslint-disable complexity */
const StudentProjectTile = ({ unit, section, beginningUrl, history, youAreHere, isLtiStudent }) => {
  const labUrl = `${beginningUrl}/sections/${section.id}/projects/${unit.id}?skipLaunch=true`;
  const currentUser = useSelector(selectCurrentUser);

  const assignmentProgress = useSelector(state => selectProgressForAssignment(state, unit.assignment_id));
  const assignment = useSelector(state => selectAssignment(state, unit.assignment_id));
  const allBootcampInstanceAssignments = useSelector(state => selectBootcampInstanceAssignments(state));

  const bootcampInstanceAssignment = allBootcampInstanceAssignments
    ? Object.values(allBootcampInstanceAssignments).find(
        bootcampInstanceAssignment => bootcampInstanceAssignment.assignment_id === unit.assignment_id,
      )
    : undefined;

  const dueDate = bootcampInstanceAssignment && bootcampInstanceAssignment.assignment_due_at;
  const suggestedMessage = 'SUGGESTED DUE DATE ';
  const formattedDueDate = moment(dueDate).format('MMM D').toUpperCase();
  const suggestedMessageColor = moment() < moment(dueDate) ? 'yellow' : 'red';
  const completed = !!assignmentProgress?.completed_at;
  const showTag = !completed && dueDate;

  let ltiProgress;
  if (isLtiStudent) {
    ltiProgress = useSelector(state =>
      selectLtiProgressForAssignment(
        state,
        assignmentProgress && assignmentProgress.context,
        assignmentProgress && assignmentProgress.assignment_id,
      ),
    );
  }

  const getLaunchAssignmentSteps = async () => {
    const lmsType = currentUser.lti_user ? currentUser.lti_user.lms_type : null;
    const lmsSteps = await LmsExplanation.get(unit.tenant_id, lmsType);
    return {
      lmsSteps,
      assignment,
      section,
    };
  };

  const handleEvent = unit => async e => {
    e.preventDefault();

    const linkEnabled = !isLtiStudent || !_isEmpty(ltiProgress);
    if (linkEnabled) {
      if (unit.app === Tutorial.CUSTOM_SITE) {
        await openLab({
          launchIn: Tutorial.LAUNCH_IN_BROWSER,
          tenantId: unit.tenant_id,
          labUrl: labUrl,
          history,
        });
      } else {
        history.push(labUrl);
      }
    } else {
      showModal(LaunchAssignmentStepsModal, await getLaunchAssignmentSteps());
    }
  };

  const iconClasses = completed
    ? 'icon-element--fill-green-800 icon-element--background-green-200'
    : 'icon-element--fill-grey-600 icon-element--background-yellow-200';

  return (
    <button
      className={`student-graded-tile ${completed ? 'complete' : ''}`}
      onClick={handleEvent(unit)}
      data-testid="student-project-tile">
      <div className={`icon-element ${iconClasses} icon-element--size-standard`}>
        <Icons.LineEdit />
      </div>
      <div className="unit-title">
        {unit.title}
        <div className="unit-tags">
          {youAreHere && (
            <div className="you-are-here">
              <FormattedMessage id="common.youAreHere" />
            </div>
          )}
          {showTag && (
            <div className="suggested" data-testid={'tag-component'}>
              <Tag text={suggestedMessage + formattedDueDate} color={suggestedMessageColor} />
            </div>
          )}
        </div>
      </div>
      <div className="unit-status" data-testid="project-score">
        {assignmentProgress?.score ? assignmentProgress.score : '- -'}%
      </div>
    </button>
  );
};
/* eslint-enable complexity */

StudentProjectTile.propTypes = {
  unit: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  section: PropTypes.object.isRequired,
  beginningUrl: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired,
};

export default StudentProjectTile;
