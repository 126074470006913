import React, { useEffect } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import Login from './Auth/LoginContainer';
import Logout from './Auth/LogoutContainer';
import ForgotPasswordContainer from './Auth/ForgotPasswordContainer';
import PrivateRoute from './Utils/PrivateRoute';
import Author from './Author/Author';
import Coach from './Coach/Coach';
import GraderQueue from './Evaluator/GraderQueue';
import Admin from './Admin/Admin';
import ConfirmUserContainer from './Auth/ConfirmUserContainer';
import ResetPasswordContainer from './Auth/ResetPasswordContainer';
import ProfileContainer from './Users/ProfileContainer';
import NotFound from './NotFound';
import { getDefaultPath } from '../helpers/pathHelper';
import ScrollToTop from './Utils/ScrollToTop';
import LtiContainer from './LtiContainer';
import LtiAssignmentSelectorContainer from './Lti/LtiAssignmentSelectorContainer';
import addGIFControlsObserver from '../utils/addGIFControlsObserver';
import Reports from './Reports/Reports';
import PrintWrapper from './Utils/PrintWrapper';
import BootcampHrefTranslator from './Utils/BootcampHrefTranslator';
import GoogleAnalytics from './Utils/GoogleAnalytics';
import LtiAssignmentMapping from './Lti/AssignmentMapping/LtiAssignmentMapping';
import ContainerElements from './ContainerElements';
import { AutograderProvider } from '../autograder/api/store';
import CreateRuleset from '../autograder/components/CreateRuleset/CreateRuleset';
import AutograderRulesetBuilder from '../autograder/components/AutograderRulesetBuilder/AutograderRulesetBuilder';
import GradeByCohort from '../autograder/components/GradeByCohort/GradeByCohort';
import { canAuthor, canEvaluate } from '../helpers/userAuth';
import { StudentV2 } from './Student/Pathstream2/navigation/StudentV2';

const App = props => {
  const { currentUser, location, isLoggedIn, fetchCurrentTenant, ping } = props;

  // Equivalent to constructor in class component
  useEffect(() => {
    if (!location.pathname.startsWith('/app_launch')) {
      isLoggedIn();
    }
    fetchCurrentTenant();
  }, [isLoggedIn, fetchCurrentTenant, location.pathname]);

  // UseEffect for componentDidMount and componentWillUnmount
  useEffect(() => {
    const observer = addGIFControlsObserver(document.body);

    // Cleanup (componentWillUnmount)
    return () => {
      if (observer) {
        observer.disconnect();
      }
    };
  }, []);

  // UseEffect for componentDidUpdate
  useEffect(() => {
    if (location !== props.location) {
      if (currentUser) {
        ping();
      }
    }
  }, [currentUser, location, props.location, props.currentUser, ping]);

  return (
    <PrintWrapper>
      <ScrollToTop>
        <GoogleAnalytics />
        <>
          <AutograderProvider>
            <BootcampHrefTranslator>
              <Switch>
                <Redirect exact from="/" to={getDefaultPath(currentUser)} />
                <Redirect from="/student_v2" to={getDefaultPath(currentUser)} />
                <Route path="/login" component={Login} />
                <Route path="/logout" component={Logout} />
                <Route path="/forgotPassword" component={ForgotPasswordContainer} />
                <Route path="/confirm/:confirmationToken" component={ConfirmUserContainer} />
                <Route path="/password/:resetPasswordToken" component={ResetPasswordContainer} />
                {canAuthor(currentUser) && (
                  <PrivateRoute exact path="/autograder-create-ruleset/:assignmentId?" component={CreateRuleset} />
                )}
                {canAuthor(currentUser) && (
                  <PrivateRoute exact path="/autograder-ruleset/edit/:id" component={AutograderRulesetBuilder} />
                )}
                {canAuthor(currentUser) && (
                  <PrivateRoute exact path="/autograde-by-cohort/:cohortId" component={GradeByCohort} />
                )}
                <PrivateRoute path="/student" component={StudentV2} />
                <PrivateRoute path="/author" component={Author} />
                <PrivateRoute path="/admin" component={Admin} />
                <PrivateRoute path="/coach" component={Coach} />
                {canEvaluate(currentUser) && <PrivateRoute path="/evaluator/grader_queue" component={GraderQueue} />}
                <PrivateRoute path="/reports" component={Reports} />
                <PrivateRoute path="/profile/:userId" component={ProfileContainer} />
                <Route path="/lti/assignments" component={LtiAssignmentSelectorContainer} />
                <Route path="/lti/assignment_mapping" component={LtiAssignmentMapping} />
                <Route path="/lti" component={LtiContainer} />
                <Route component={NotFound} />
              </Switch>
            </BootcampHrefTranslator>
          </AutograderProvider>
        </>
        <ContainerElements />
      </ScrollToTop>
    </PrintWrapper>
  );
};

App.propTypes = {
  isLoggedIn: PropTypes.func.isRequired,
  ping: PropTypes.func.isRequired,
  fetchCurrentTenant: PropTypes.func.isRequired,
  currentUser: PropTypes.object,
  location: PropTypes.object.isRequired,
};

export default App;
