import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { FormattedMessage } from 'react-intl';
import { Button, Input } from 'antd';
import PropTypes from 'prop-types';

import FileUploadModal from '../../../utils/FileUploadModal/FileUploadModal';
import showModal from '../../../../utils/showModal';
import intl from '../../../../utils/intl';

const ACCEPTED_FILE_TYPES = ['image/jpeg', 'image/png', 'image/svg+xml', 'image/tiff', 'image/gif'];

const modelAnswerImageUploadModal = onSubmit => {
  showModal(FileUploadModal, {
    accept: ACCEPTED_FILE_TYPES,
    onSubmit: async (fileUploads, closeModal) => {
      try {
        await onSubmit({ fileUploads });
        closeModal();
      } catch (err) {
        toast(<FormattedMessage id="project.failedSubmission" />, {
          autoClose: 5000,
          type: toast.TYPE.ERROR,
        });
      }
    },
    s3Prefix: 'ruleset_id/autograde_rubric_id',
  });
};

const Uploader = ({ value, onChange }) => {
  const [files, setFiles] = useState();
  const [filename, setFilename] = useState();

  useEffect(() => {
    // Not sure why initial value undefined
    setFilename(value?.name || value?.url || '');
  }, [value]);

  useEffect(() => {
    if (files) {
      // TODO support multiple files
      setFilename(files[0].name);
      onChange(files[0]);
    }
  }, [files, onChange]);

  const onFilenameChange = e => {
    const newFilename = e.target.value;
    setFilename(newFilename);
    onChange({ url: newFilename });
  };

  return (
    <>
      <Input
        type="text"
        value={filename}
        onChange={onFilenameChange}
        placeholder={intl.formatMessage({ id: 'autograder.modelAnswer.imageInputPlaceholder' })}
      />
      <Button
        onClick={() =>
          modelAnswerImageUploadModal(({ fileUploads } = {}) => {
            setFiles(fileUploads);
          })
        }>
        <FormattedMessage id="autograder.modelAnswer.uploadImage" />
      </Button>
    </>
  );
};

Uploader.propTypes = {
  value: PropTypes.object,
  onChange: PropTypes.func,
};

export default Uploader;
