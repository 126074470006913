/* eslint-disable max-lines */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Modal } from 'antd';
import Loading from '../../Utils/Loading';
import ContentWrapperContainer from '../../v2/Nav/ContentWrapperContainer';
import LabInstructions from '../LabInstructions';
import { isSubmitted, isGraded, isPending } from '../../../utils/utils';
import { confirmInput } from '../../../utils/confirmInput';
import * as analytics from '../../../utils/analytics';
import ProjectInstructions from '../../Projects/ProjectInstructions';
import { isGsuiteApp, isCustomSiteApp, isVideoApp, isToughTalksApp, isReviewable } from '../../../helpers/labHelper';
import Stream from '../../../api/stream';
import { setPageTitle } from '../../../helpers/pageHelper';
import QuizOnLab from '../../Quiz/QuizOnLab';
import BlockShowContainer from '../BlockShowContainer';
import intl from '../../../utils/intl';
import { LabAreaIntro, DismissIntro } from '../../Labs/LabIntro';
import LabWrapper from '../../v2/Nav/LabWrapper';
import LabCompletionModal from '../../Labs/LabCompletionModal';
import { toastSuccess, toastError } from '../../../utils/toastHelper';
import { ProjectDataProvider } from '../../../context/Project/ProjectContext';
import { showFileUploadModal } from './showFileUploadModal';
import { showLinkUploadModal } from './showLinkUploadModal';
import SubmissionModal from './SubmissionModal';
import AssignmentNotAvailableModal from './AssignmentNotAvailableModal';
import Project from '../../Labs/Project';

export default class ProjectShow extends Component {
  constructor(props) {
    super(props);

    this.promises = {};
    this.state = {
      errors: [],
      isSaving: false,
      userProjectUrl: null,
      isFinished: false,
      hasEnded: false,
    };

    this.props.fetchLtiAssignmentProgresses();
    this.fetchData(props);
  }

  componentDidMount() {
    const { bootcamp, assignment, unitId, unit } = this.props;

    if (!bootcamp) {
      this.props.fetchCurrentEnrollments();
    }

    if (!assignment && unit) {
      this.props.fetchAssignment(unit.assignment_id);
    }

    this.props.fetchUnit(unitId);

    this.fetchData(this.props);

    analytics.pageStart('View Project');
  }

  componentDidUpdate() {
    const { bootcamp, assignment } = this.props;
    if (bootcamp && assignment) {
      const params = {
        course: bootcamp.title,
        project: assignment.title,
      };
      setPageTitle('pageName.course.project', params);
    }
  }

  analyticsParams = () => {
    const { bootcamp, section, unit, cohort } = this.props;
    return {
      bootcamp,
      cohort,
      section,
      project: unit,
    };
  };

  trackPageEnd = () => {
    analytics.pageEnd(this.analyticsParams());
  };

  componentWillUnmount() {
    this.trackPageEnd();
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(props) {
    if (props.unitId !== this.props.unitId) {
      this.promises = {};
      this.setState({ isSaving: false, errors: [] });
      this.trackPageEnd();
      analytics.pageStart('View Project');
    }
    this.fetchData(props);
  }

  requiresGrading = () => {
    const { assignment } = this.props;
    return assignment.requires_grading;
  };

  fetchLessonData = props => {
    const { lesson, unit } = props;

    if ((!lesson && unit) || props.location !== this.props.location) {
      if (unit.lesson_id && !this.promises.fetchLesson) {
        this.promises.fetchLesson = props.fetchLesson(unit.lesson_id);
      }
    }
  };

  fetchAssignmentData = props => {
    const { assignment, prerequisiteAssignment } = props;

    if (assignment && assignment.prerequisite_id && !prerequisiteAssignment && !this.promises.fetchAssignment) {
      this.promises.fetchAssignment = props.fetchAssignment(assignment.prerequisite_id);
    }
  };

  fetchIntroLessonData = props => {
    const { unit, introLesson } = props;

    if (unit && unit.intro_lesson_id && !introLesson && !this.promises.fetchIntroLesson) {
      this.promises.fetchIntroLesson = props.fetchLesson(unit.intro_lesson_id);
    }
  };

  fetchProgressData = props => {
    const { assignmentProgress, unitProgress } = props;

    if (unitProgress && assignmentProgress) {
      if (!assignmentProgress.started_at) {
        if (!this.promises.startAssignmentProgress) {
          this.promises.startAssignmentProgress = this.props.startAssignmentProgress(assignmentProgress.id);
        }
        if (!this.promises.startUnitProgress) {
          this.promises.startUnitProgress = this.props.startUnitProgress(unitProgress.id);
        }
      }
      this.updateProjectUrl(assignmentProgress, this.state.userProjectUrl);
      return true;
    }

    return false;
  };

  fetchData = props => {
    const { bootcamp, cohortId } = props;

    this.fetchLessonData(props);
    this.fetchAssignmentData(props);
    this.fetchIntroLessonData(props);
    if (this.fetchProgressData(props)) {
      return;
    }

    if (!bootcamp) {
      return;
    }

    this.props.fetchEnrollmentForCohort(cohortId);
  };

  handleSubmit = () => {
    const { assignmentProgress, submitAssignmentProgress, saveAssignmentProgress, assignment, bootcamp } = this.props;

    if (!assignmentProgress) return;

    const submitFile = async ({ fileUploads } = {}) => {
      this.setState({ isSaving: true, errors: [] });

      try {
        const progress = { ...assignmentProgress, file_uploads: fileUploads, submission_status: 'pending' };
        await saveAssignmentProgress(progress);

        this.setState({ isSaving: false, isFinished: true, hasEnded: true });
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log('error', err);
        this.setState({ isSaving: false, errors: [err.errors] || (err.value && err.value.errors) });
        toastError(<FormattedMessage id="project.failedSubmission" />);
      }
    };

    const submitLink = async linkUpload => {
      this.setState({ isSaving: true, errors: [] });

      try {
        const progress = { ...assignmentProgress, link_upload: linkUpload, submission_status: 'passed' };
        await submitAssignmentProgress(progress);

        this.setState({ isSaving: false, isFinished: true, hasEnded: true });
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log('error', err);
        this.setState({ isSaving: false, errors: [err.errors] || (err.value && err.value.errors) });
        toastError(<FormattedMessage id="project.failedSubmission" />);
      }
    };

    if (isCustomSiteApp(assignment.app)) {
      if (bootcamp.requires_asana_link_upload) {
        return showLinkUploadModal(submitLink);
      }
      return showFileUploadModal(submitFile);
    }

    // eslint-disable-next-line complexity
    const submitAssignment = async ({ fileUploads } = {}) => {
      this.setState({ isSaving: true, errors: [] });

      try {
        if (isGsuiteApp(assignment.app) || isToughTalksApp(assignment.app)) {
          if (!assignmentProgress.user_project_url) {
            this.setState({
              errors: [intl.formatMessage({ id: 'project.waitForLab' })],
              isSaving: false,
            });
            return;
          }

          try {
            Stream.updateAccess(assignmentProgress.user_project_url, false, assignment.app);
          } catch (e) {
            // eslint-disable-next-line no-console
            console.log('Error making file readonly', e);
          }
        }

        if (fileUploads) assignmentProgress.file_uploads = fileUploads;
        await submitAssignmentProgress(assignmentProgress);

        this.setState({ isSaving: false, isFinished: true, hasEnded: true });

        toastSuccess(intl.formatMessage({ id: this.requiresGrading() ? 'project.submitted' : 'project.completed' }));
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log('error', err);
        this.setState({ isSaving: false, errors: [err.errors] || (err.value && err.value.errors) });
        if (fileUploads) throw err;
      }
    };

    confirmInput({
      message: this.requiresGrading() ? (
        <FormattedMessage id="project.confirmSubmit" />
      ) : (
        <FormattedMessage id="project.confirmComplete" />
      ),
      onOk: submitAssignment,
    });
  };

  handleCompletionSubmit = () => {
    const { bootcamp, path, history } = this.props;
    history.push(`/student/bootcamps/${bootcamp.id}/paths/${path.id}`);
  };

  updateProjectUrl = (assignmentProgress, projectUrl) => {
    const { saveAssignmentProgress } = this.props;

    if (!projectUrl || assignmentProgress.user_project_url || this.promises.saveAssignmentProgress) {
      return;
    }

    this.promises.saveAssignmentProgress = saveAssignmentProgress({
      ...assignmentProgress,
      user_project_url: projectUrl,
    }).catch(err => {
      // eslint-disable-next-line no-console
      console.log('Error occurred while updating project_url', err);
    });
  };

  updateLabVersion = (assignmentProgress, labVersion) => {
    const { saveAssignmentProgress } = this.props;

    if (!labVersion || assignmentProgress.lab_version === labVersion || this.promises.saveAssignmentProgress) {
      return;
    }

    this.promises.saveAssignmentProgress = saveAssignmentProgress({
      ...assignmentProgress,
      lab_version: labVersion,
    });
  };

  handleProjectDataReceived = (projectUrl, labVersion) => {
    const { assignmentProgress, assignment } = this.props;

    if (!isGsuiteApp(assignment.app) && !isVideoApp(assignment.app) && !isToughTalksApp(assignment.app)) {
      this.updateLabVersion(assignmentProgress, labVersion);
      return;
    }

    if (!assignmentProgress) {
      // set the url in the state and update the assignmentProgress when available
      this.setState({
        userProjectUrl: projectUrl,
      });
      return;
    }

    this.updateProjectUrl(assignmentProgress, projectUrl);
  };

  showSubmissionModal = () => {
    const { assignmentProgress } = this.props;

    return isSubmitted(assignmentProgress) || isGraded(assignmentProgress) || isPending(assignmentProgress);
  };

  renderSubmissionModal = () => {
    const { assignment, assignmentProgress, history, currentUser, bootcamp, path } = this.props;

    if (!assignment || !assignmentProgress) return;

    return (
      <SubmissionModal
        assignment={assignment}
        assignmentProgress={assignmentProgress}
        history={history}
        currentUser={currentUser}
        bootcamp={bootcamp}
        path={path}
      />
    );
  };

  renderAlertInfo = () => {
    const { assignmentProgress } = this.props;

    if (!assignmentProgress) return;

    if (isGraded(assignmentProgress)) {
      return (
        <div className="alert alert-info" role="status">
          {this.requiresGrading() ? (
            <FormattedMessage id="project.scored" values={{ score: assignmentProgress.score }} />
          ) : (
            <FormattedMessage id="project.completed" />
          )}
        </div>
      );
    } else if (isSubmitted(assignmentProgress)) {
      return (
        <div className="alert alert-info" role="status">
          {this.requiresGrading() ? (
            <FormattedMessage id="project.submitted" />
          ) : (
            <FormattedMessage id="project.completed" />
          )}
        </div>
      );
    }
  };

  checkPrerequisites = props => {
    const { bootcamp, prerequisiteAssignment, prerequisiteProgress, history, currentUser, path } = props;

    if (prerequisiteAssignment) {
      if (!prerequisiteProgress) {
        return <Loading />;
      }
      if (!prerequisiteProgress.completed_at) {
        return (
          <AssignmentNotAvailableModal
            prereqTitle={prerequisiteAssignment.title}
            history={history}
            currentUser={currentUser}
            bootcamp={bootcamp}
            path={path}
          />
        );
      }
    }
  };

  getProjectUrl = props => {
    const { assignment, prerequisiteProgress } = props;
    return assignment.starter_project_url || (prerequisiteProgress && prerequisiteProgress.user_project_url);
  };

  renderLabContainer = () => {
    const { bootcamp, assignment, history, assignmentProgress, cohortId, projectFolderPath, unit } = this.props;
    const { hasEnded, isSaving } = this.state;

    const projectUrl = this.getProjectUrl(this.props);

    const prerequisiteError = this.checkPrerequisites(this.props);
    if (prerequisiteError) {
      return prerequisiteError;
    }

    return (
      <Project
        bootcamp={bootcamp}
        projectUrl={projectUrl}
        labInteractable={assignment}
        projectFolderPath={projectFolderPath}
        history={history}
        saveToS3
        cohortId={cohortId}
        analyticsParams={this.analyticsParams()}
        streamingUrl={assignmentProgress && assignmentProgress.user_project_url}
        handleProjectUrlReceived={this.handleProjectDataReceived}
        hasEnded={hasEnded}
        unit={unit}
        shouldShowLabLoadingModal={!isSubmitted(assignmentProgress)}
        isSubmitted={isSaving}
        preview={false}
      />
    );
  };

  getSubmitHandler = (isSaving, assignmentProgress) => {
    return isSaving || isSubmitted(assignmentProgress) ? null : this.handleSubmit;
  };

  render() {
    const {
      bootcamp,
      path,
      section,
      unit,
      assignment,
      assignmentProgress,
      isLtiStudent,
      ltiProgress,
      lesson,
      bootcampPath,
      feedbackData,
      cohort,
    } = this.props;
    const { isSaving, errors, isFinished } = this.state;

    if (!path || !assignment || !bootcamp || !assignmentProgress) {
      return (
        <ContentWrapperContainer className="lab-page">
          <Loading />
        </ContentWrapperContainer>
      );
    }

    const submitHandler = this.getSubmitHandler(isSaving, assignmentProgress);

    if (isLtiStudent && !ltiProgress) {
      return (
        <ContentWrapperContainer
          className="lab-page"
          currentPathId={path.id}
          currentSectionId={section.id}
          currentUnitId={unit.id}
          headerText={assignment.title}>
          <div className="alert alert-info" style={{ textAlign: 'center' }} role="status">
            <FormattedMessage id="lti.messages.launchFromLMS" />
          </div>
        </ContentWrapperContainer>
      );
    }

    const isPastDeadline = cohort && cohort.past_deadline;

    return (
      <LabWrapper app={assignment.app} isFinished={isFinished}>
        <Modal visible={isFinished} centered closable={false} className="lab-completion-modal" footer={null}>
          <LabCompletionModal formData={feedbackData} onSubmit={this.handleCompletionSubmit} />
        </Modal>
        {/* eslint-disable complexity */}
        <QuizOnLab
          render={(quizOnLab, renderQuiz) => (
            <ProjectDataProvider assignment={assignment} isEditMode={false}>
              <div className="new-lab-page">
                <main className="new-lab-page__main">
                  <LabInstructions title={assignment.title} backLink={bootcampPath}>
                    <ProjectInstructions
                      unit={unit}
                      lesson={lesson}
                      assignment={assignment}
                      assignmentProgress={assignmentProgress}
                      quizOnLab={quizOnLab}
                      bootcampId={this.props.bootcampId}
                      showBlockTakeQuiz={false}
                      isSidebar
                      onSubmit={isPastDeadline ? null : submitHandler}
                      errors={errors}
                      submitLabel={
                        this.requiresGrading() ? (
                          <FormattedMessage id="common.submit" />
                        ) : (
                          <FormattedMessage id="common.complete" />
                        )
                      }
                    />
                  </LabInstructions>

                  <section className="new-lab-page__content">
                    {this.showSubmissionModal() && this.renderSubmissionModal()}
                    {(!this.showSubmissionModal() || isReviewable(assignment.app)) && (
                      <>
                        <LabAreaIntro />

                        <div className="new-lab-page__wrapper">
                          <div className="lab-image">
                            {renderQuiz(BlockShowContainer)}
                            {isSaving && (
                              <div className="alert" style={{ textAlign: 'center' }} role="status">
                                <Loading messageId="project.submitMessage" />
                              </div>
                            )}
                            {isGsuiteApp(assignment.app) && this.renderAlertInfo()}
                            {(!isSubmitted(assignmentProgress) || isReviewable(assignment.app)) &&
                              !isPastDeadline &&
                              this.renderLabContainer()}
                          </div>
                        </div>
                      </>
                    )}
                  </section>
                </main>
              </div>
              <DismissIntro />
            </ProjectDataProvider>
          )}
        />
      </LabWrapper>
    );
  }
}

ProjectShow.propTypes = {
  bootcamp: PropTypes.object,
  path: PropTypes.object,
  section: PropTypes.object,
  unit: PropTypes.object,
  assignment: PropTypes.object,
  // eslint-disable-next-line react/no-unused-prop-types
  lesson: PropTypes.object,
  // eslint-disable-next-line react/no-unused-prop-types
  steps: PropTypes.array,
  // eslint-disable-next-line react/no-unused-prop-types
  cohortId: PropTypes.string,
  unitId: PropTypes.string,
  bootcampId: PropTypes.string,
  projectFolderPath: PropTypes.string,
  prerequisiteProgress: PropTypes.object,
  prerequisiteAssignment: PropTypes.object,
  introLesson: PropTypes.object,
  cohort: PropTypes.object,
  fetchCurrentEnrollments: PropTypes.func.isRequired,
  fetchEnrollmentForCohort: PropTypes.func.isRequired,
  fetchLtiAssignmentProgresses: PropTypes.func.isRequired,
  fetchAssignment: PropTypes.func.isRequired,
  saveAssignmentProgress: PropTypes.func.isRequired,
  submitAssignmentProgress: PropTypes.func.isRequired,
  startAssignmentProgress: PropTypes.func.isRequired,
  startUnitProgress: PropTypes.func.isRequired,
  fetchUnit: PropTypes.func.isRequired,
  assignmentProgress: PropTypes.object,
  isLtiStudent: PropTypes.bool,
  ltiProgress: PropTypes.object,
  history: PropTypes.object,
  location: PropTypes.object,
  bootcampPath: PropTypes.string,
  feedbackData: PropTypes.object,
  currentUser: PropTypes.object,
  unitProgress: PropTypes.object,
};
