import {
  getRowColFromRange,
  getRowColFromAddy,
  forceStringUpperCase,
  removeWhitespace,
  stripEmptyStringQuotes,
} from '../utils';
import intl from '../../../utils/intl';

/* TODO: find a library to evaluate expression strings or build one */
const getCellRangeCompare = (prop, { data, coordinates, operator, value }) => {
  const { minRow, maxRow, minCol, maxCol } = coordinates;
  for (let r = minRow; r <= maxRow; r += 1) {
    for (let c = minCol; c <= maxCol; c += 1) {
      if (operator === 'all') {
        if (data[r][c][prop] !== value) return false;
      }
      if (operator === 'none') {
        if (data[r][c][prop] === value) return false;
      }
      if (operator === 'some') {
        if (data[r][c][prop] === value) return true;
      }
    }
  }
  return operator !== 'some';
};

const formatExpectedValue = (expectedValue, operator) => {
  return `${operator} ${expectedValue ? expectedValue : 'empty string'}`.trim();
};

const buildResultObject = (status, params, actual) => {
  return {
    status,
    expected: `${params.property} ${params.operator} ${params.value}`.trim(),
    actual,
  };
};

export const sheetNotFound = (property, operator, value) => ({
  status: false,
  expected: `${property} ${operator} ${value}`.trim(),
  actual: intl.formatMessage({ id: 'autograder.sheetNotFound' }),
});

// eslint-disable-next-line complexity
export const getDataFromRangeAndEval = ({ path, location, property, operator, value }) => {
  const { cellRange } = location[location.length - 1].dataSource;
  if (!cellRange) return null;
  const coordsDS = getRowColFromRange(cellRange);
  if (!coordsDS) return null;
  const { minRow: minRowDS, maxRow: maxRowDS, minCol: minColDS, maxCol: maxColDS } = coordsDS;

  const dataset = path.find(p => {
    const coords = getRowColFromRange(p.range);
    if (!coords) return false;
    const { minRow, maxRow, minCol, maxCol } = coords;

    return minRowDS >= minRow && maxRowDS <= maxRow && minColDS >= minCol && maxColDS <= maxCol;
  });

  if (!dataset) return false;
  const { rows } = dataset;

  switch (property) {
    case 'value':
      return (
        getCellRangeCompare('name', {
          data: rows,
          coordinates: coordsDS,
          operator,
          value,
        }) ||
        getCellRangeCompare('rawNumValue', {
          data: rows,
          coordinates: coordsDS,
          operator,
          value,
        })
      );
    case 'formula':
      return getCellRangeCompare('formula', {
        data: rows,
        coordinates: coordsDS,
        operator,
        value,
      });
    case 'bold':
      return getCellRangeCompare('bold', {
        data: rows,
        coordinates: coordsDS,
        operator,
        value,
      });
    case 'textColor':
      return getCellRangeCompare('textColor', {
        data: rows,
        coordinates: coordsDS,
        operator,
        value,
      });
    case 'backgroundColor':
      return getCellRangeCompare('backgroundColor', {
        data: rows,
        coordinates: coordsDS,
        operator,
        value,
      });
    case 'formatType':
      return getCellRangeCompare('formatType', {
        data: rows,
        coordinates: coordsDS,
        operator,
        value,
      });
    default:
      return null;
  }
};

export const getDataFromCellAndEval = ({ path, location, property, operator, value }) => {
  const { cellAddress } = location[location.length - 1].dataSource;
  if (!cellAddress) return null;
  const normalizedCellAddress = cellAddress.split(':').join('').toUpperCase();
  const colRow = getRowColFromAddy(normalizedCellAddress);
  if (!colRow) return null;
  const { col, row } = colRow;

  // get dataset within the cell range
  const dataset = path.find(p => {
    const coords = getRowColFromRange(p.range);
    if (!coords) return null;
    const { minRow, maxRow, minCol, maxCol } = coords;
    return col <= maxCol && col >= minCol && row <= maxRow && row >= minRow;
  });

  if (!dataset) return null;
  const { rows } = dataset;

  // find the exact cell
  let cell = null;
  for (let r = 0; r < rows.length; r += 1) {
    for (let c = 0; c < rows[r].length; c += 1) {
      if (normalizedCellAddress === rows[r][c].a1Address) {
        cell = rows[r][c];
      }
      if (cell) break;
    }
    if (cell) break;
  }

  if (!cell) return null;

  return evalCellFormat({ cell, property, operator, value });
};

// TODO: write some tests
// eslint-disable-next-line complexity
export const evaluator = (actualValue, operator, expectedValue) => {
  let status;
  let actual = actualValue;
  let expected = expectedValue;
  if (!isNaN(actualValue)) {
    actual = parseFloat(actualValue);
    try {
      expected = parseFloat(expected);
      if (isNaN(expected)) {
        throw new Error('not a number');
      }
    } catch (e) {
      if (operator === '!=') {
        status = true;
      } else {
        return { status: false, expected: expectedValue, actual: actualValue };
      }
    }
  }

  if (typeof expected === 'string') {
    expected = stripEmptyStringQuotes(expected);
  }
  let isEqualOperator;
  if (!status) {
    switch (operator) {
      case '==':
        if (actual) {
          status = actual === expected;
        } else {
          status = !!actual === !!expected;
        }
        isEqualOperator = true;
        break;
      case '!=':
        if (actual) {
          status = actual !== expected;
        } else {
          status = !!actual !== !!expected;
        }
        break;
      case '>':
        status = actual > expected;
        break;
      case '>=':
        status = actual >= expected;
        break;
      case '<':
        status = actual < expected;
        break;
      case '<=':
        status = actual <= expected;
        break;
      default:
        status = null;
    }
  }

  if (status === null) return null;

  return {
    status,
    expected: isEqualOperator ? expected : formatExpectedValue(expected, operator),
    actual,
  };
};

// eslint-disable-next-line complexity
export const evalCellFormat = ({ cell, property, operator, value }) => {
  const { name, formula, bold, textColor, backgroundColor, formatType } = cell;

  let expectedNormalizedValue = forceStringUpperCase(value);

  switch (property) {
    case 'value':
      let actualNormalizedValue = forceStringUpperCase(name);
      if (operator === 'contains') {
        return buildResultObject(
          !!name && actualNormalizedValue.includes(expectedNormalizedValue),
          { property, operator, value },
          name,
        );
      }
      if (operator === 'doesNotContain') {
        const result = !!name && actualNormalizedValue.includes(expectedNormalizedValue);
        return buildResultObject(!result, { property, operator, value }, name);
      }
      return evaluator(name, operator, value);
    case 'formula':
      expectedNormalizedValue = removeWhitespace(expectedNormalizedValue);
      if (operator === 'contains') {
        return buildResultObject(
          !!formula && formula.includes(expectedNormalizedValue),
          { property, operator, value },
          formula,
        );
      }
      if (operator === 'doesNotContain') {
        const result = !!formula && formula.includes(expectedNormalizedValue);
        return buildResultObject(!result, { property, operator, value }, formula);
      }
      return evaluator(formula, operator, value);
    case 'bold':
      if (operator === 'contains') {
        return buildResultObject(!!bold && bold.includes(value), { property, operator, value }, bold);
      }
      if (operator === 'doesNotContain') {
        const result = !!bold && bold.includes(value);
        return buildResultObject(!result, { property, operator, value }, bold);
      }
      return evaluator(bold, operator, value);
    case 'textColor':
      if (operator === 'contains') {
        return buildResultObject(!!textColor && textColor.includes(value), { property, operator, value }, textColor);
      }
      if (operator === 'doesNotContain') {
        const result = !!textColor && textColor.includes(value);
        return buildResultObject(!result, { property, operator, value }, textColor);
      }
      return evaluator(textColor, operator, value);
    case 'backgroundColor':
      if (operator === 'contains') {
        return buildResultObject(
          !!backgroundColor && backgroundColor.includes(value),
          { property, operator, value },
          backgroundColor,
        );
      }
      if (operator === 'doesNotContain') {
        const result = !!backgroundColor && backgroundColor.includes(value);
        return buildResultObject(!result, { property, operator, value }, backgroundColor);
      }
      return evaluator(backgroundColor, operator, value);
    case 'formatType':
      if (operator === 'contains') {
        return buildResultObject(!!formatType && formatType.includes(value), { property, operator, value }, formatType);
      }
      if (operator === 'doesNotContain') {
        const result = !!formatType && formatType.includes(value);
        return buildResultObject(!result, { property, operator, value }, formatType);
      }
      return evaluator(formatType, operator, value);
    default:
      return null;
  }
};
