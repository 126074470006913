import React from 'react';
import { find as _find } from 'lodash';
import PropTypes from 'prop-types';
import Select from 'react-select';
import intl from '../../../utils/intl';
import Icons from '../../Utils/Icons';

export default function LtiAssignmentTile({ assignment, assignmentMapping, lineItems, onSelect }) {
  const handleChange = e => {
    const lineItemId = e.value;
    const lineItem = _find(lineItems, { id: lineItemId });
    onSelect(assignment, lineItem);
  };

  const getOptions = () => {
    const options = [
      {
        label: intl.formatMessage({ id: 'lti.messages.selectLMSAssignment' }),
        value: '',
      },
    ];

    (lineItems || []).forEach(item => {
      options.push({
        label: item.label,
        value: item.id,
      });
    });
    return options;
  };

  return (
    <div className="units-group">
      <div className="units-group-heading">
        <div className="units-group__heading" style={{ zIndex: 'inherit' }}>
          <div className="units-group-left">
            <span className="units-group__icon">
              <Icons.Graded />
            </span>
            <div className="units-group-title">
              <label htmlFor={`el_${assignment.id}`} style={{ flex: 'auto', fontSize: 16 }}>
                {assignment.title}
              </label>
            </div>
          </div>
          <div className="units-group-right">
            <Select
              style={{ width: 400 }}
              className="Select--simple"
              name="line_item_id"
              value={assignmentMapping ? assignmentMapping.line_item_id : ''}
              onChange={handleChange}
              options={getOptions()}
              clearable={false}
              searchable={false}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

LtiAssignmentTile.propTypes = {
  assignment: PropTypes.object,
  assignmentMapping: PropTypes.object,
  lineItems: PropTypes.array,
  onSelect: PropTypes.func,
};
