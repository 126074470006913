import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { ReactComponent as EditIcon } from '../../styles/pathstream/svg-jsx/icon-edit.svg';
import { ReactComponent as TrashIcon } from '../../styles/pathstream/svg-jsx/icon-trash.svg';
import { ReactComponent as MinusIcon } from '../../styles/pathstream/svg-jsx/icon-minus.svg';
import intl from '../../utils/intl';

export default class ActionButtons extends Component {
  handleClick = (e, callback) => {
    e.stopPropagation();
    callback(e);
  };

  render() {
    const { onEdit, onDelete, isEditMode, className, showRemove } = this.props;
    const classes = classnames(className, 'edit-delete-buttons');
    const buttonStyle = onEdit && onDelete ? {} : { borderRadius: 100 };
    const deleteTooltip = showRemove
      ? intl.formatMessage({ id: 'common.remove' })
      : intl.formatMessage({ id: 'common.delete' });

    if (!isEditMode) {
      return null;
    }

    return (
      <div className={classes}>
        <div className="authoring-edit-delete-button">
          {onEdit && (
            <button
              type="button"
              className="authoring-edit-delete-button-edit"
              aria-label={intl.formatMessage({ id: 'common.edit' })}
              title={intl.formatMessage({ id: 'common.edit' })}
              onClick={e => this.handleClick(e, onEdit)}
              style={buttonStyle}>
              <EditIcon />
            </button>
          )}
          {onDelete && (
            <>
              {onEdit && <div className="authoring-edit-delete-button-line" />}
              <button
                type="button"
                className="authoring-edit-delete-button-delete"
                aria-label={deleteTooltip}
                title={deleteTooltip}
                onClick={e => this.handleClick(e, onDelete)}
                style={buttonStyle}>
                {showRemove ? <MinusIcon /> : <TrashIcon />}
              </button>
            </>
          )}
        </div>
      </div>
    );
  }
}

ActionButtons.propTypes = {
  className: PropTypes.string,
  isEditMode: PropTypes.bool,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  showRemove: PropTypes.bool,
};

ActionButtons.defaultProps = {
  showRemove: false,
};
