import { find as _find, findIndex as _findIndex } from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { useParams, Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { getCoursePathByIds } from '../../helpers/pathHelper';
import useCurrentUser from '../../hooks/useCurrentUser';
import useBootcamp from '../../hooks/useBootcamp';
import intl from '../../utils/intl';
import Icons from '../Utils/Icons';
import { selectSectionUnits } from '../../selectors/sectionSelectors';
import Tabs from '../Utils/Tabs';
import UnitHeaderTile from '../Units/UnitHeaderTile';
import ProfileNav from '../v2/Nav/ProfileNav';
import { selectEntitiesForBootcamp } from '../../selectors/cohortBootcampSelectors';
import useFetchCohort from '../../hooks/useFetchCohort';
import { canAdministrate, canCoach } from '../../helpers/userAuth';
import { cohortTitle } from '../../helpers/cohortHelper';

export default function SectionHeader({ onClose }) {
  const { bootcampId, pathId, sectionId, unitId: currentUnitId, componentId: currentComponentId } = useParams();
  const { bootcamp, sections } = useBootcamp(bootcampId);
  const { currentUser } = useCurrentUser();
  const section = _find(sections, { id: sectionId });
  const sectionIndex = _findIndex(sections, { id: sectionId });
  const units = useSelector(s => selectSectionUnits(s, sectionId));
  const { cohortId } = useSelector(s => selectEntitiesForBootcamp(s, bootcampId));
  const cohort = useFetchCohort(cohortId);
  const isInstructor = canCoach(currentUser) && !canAdministrate(currentUser);
  // If no cohort, don't show title for instructors
  // possible scenario: instructor goes directly to lesson page, skipping courses list
  const defaultTitle = isInstructor && !cohort ? null : bootcamp.title;

  return (
    <aside className="header-panel">
      <div className="header-panel__wrapper">
        <div className="header-panel__container">
          <button
            type="button"
            className="header-panel__close"
            aria-label={intl.formatMessage({ id: 'common.close' })}
            onClick={onClose}>
            <Icons.CloseBig />
          </button>
          <div className="header-panel__profile">
            <ProfileNav hideName />
          </div>

          <header className="header-panel__header">
            <Link className="header-panel__link" to={getCoursePathByIds(currentUser, bootcampId, pathId)}>
              <Icons.ArrowPrevSmall />
              <FormattedMessage id="nav.backToBootcamp" />
            </Link>

            <h2 className="header-panel__title">{cohortTitle(cohort, defaultTitle)}</h2>
          </header>

          <div className="header-panel__section">
            <h4 className="header-panel__section-name">
              <FormattedMessage id="section.sectionNum" values={{ index: sectionIndex + 1 }} />: {section.title}
            </h4>

            <Tabs
              className="header-panel__modules"
              wrapperTag="div"
              wrapperClass="header-panel__modules-wrapper"
              prevBtnClass="header-panel__prev-button"
              nextBtnClass="header-panel__next-button"
              selectedIndex={_findIndex(units, { id: currentUnitId })}
              items={units.map(unit => (
                <UnitHeaderTile
                  key={unit.id}
                  section={section}
                  unit={unit}
                  isCurrent={unit.id === currentUnitId}
                  currentComponentId={currentComponentId}
                  onClick={onClose}
                />
              ))}
            />
          </div>
        </div>
      </div>
    </aside>
  );
}

SectionHeader.propTypes = {
  onClose: PropTypes.func,
};
