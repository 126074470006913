import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Errors from '../Utils/Errors';
import { getDefaultPath } from '../../helpers/pathHelper';
import CustomInput from '../Utils/CustomInput';
import intl from '../../utils/intl';
import Template from './Template';

const ConfirmUser = props => {
  const [userConfirmationAttrs, setUserConfirmationAttrs] = useState({
    userEmail: '',
    errors: null,
    password: '',
    confirmPassword: '',
    isSaving: false,
    redirectToHome: null,
    currentUser: null,
  });

  const { currentUser, redirectToHome, errors, password, confirmPassword, isSaving, userEmail } = userConfirmationAttrs;

  const [redirecting, setRedirecting] = useState(false);

  let { confirmationToken, verifyConfirmationToken, auth, confirmAndSetPassword } = props;
  let signedIn = auth.signedIn;

  useEffect(() => {
    if (confirmationToken) {
      verifyConfirmationToken(confirmationToken)
        .then(response => {
          if (response.message) {
            setUserConfirmationAttrs({ errors: null, message: response.message });
            setRedirecting(true);
            const delay = 2500;

            setTimeout(() => {
              setUserConfirmationAttrs({ redirectToHome: true });
            }, delay);
          } else if (response.email) {
            setUserConfirmationAttrs({ userEmail: response.email });
          }
        })
        .catch(err => {
          setUserConfirmationAttrs({ errors: err.errors });
        });
    }

    if (!confirmationToken) {
      setUserConfirmationAttrs({ errors: [<FormattedMessage id="user.confirmation.tokenMissing" />] });
      return;
    }

    if (signedIn) {
      setUserConfirmationAttrs({ currentUser: auth.user });

      const delay = 2500;

      setTimeout(() => {
        setUserConfirmationAttrs({ redirectToHome: true });
      }, delay);
    }
  }, [confirmationToken, signedIn]);

  const handleChange = event => {
    const { target } = event;
    const { name } = target;
    setUserConfirmationAttrs({ ...userConfirmationAttrs, [name]: target.value });
  };

  const handleSubmit = e => {
    e.preventDefault();
    if (!password || !confirmPassword) {
      setUserConfirmationAttrs({
        ...userConfirmationAttrs,
        errors: [<FormattedMessage id="user.setPassword.errors.passwordRequired" />],
      });
      return;
    }

    if (password !== confirmPassword) {
      setUserConfirmationAttrs({
        ...userConfirmationAttrs,
        errors: [<FormattedMessage id="user.setPassword.errors.passwordMismatch" />],
      });
      return;
    }

    setUserConfirmationAttrs({ ...userConfirmationAttrs, isSaving: true, errors: null });

    confirmAndSetPassword(confirmationToken, password)
      .then(success => {
        setUserConfirmationAttrs({
          ...userConfirmationAttrs,
          errors: null,
          message: <FormattedMessage id="user.confirmation.success" />,
          isSaving: false,
        });
        handleLogin();
      })
      .catch(err => {
        setUserConfirmationAttrs({
          ...userConfirmationAttrs,
          errors: err.errors,
          isSaving: false,
        });
      });
  };

  const handleLogin = () => {
    /* Redirect to home if user has been signed in */
    if (props.auth.signedIn) {
      setUserConfirmationAttrs({ redirectToHome: true });
      return;
    }

    const { userEmail, password } = userConfirmationAttrs;
    props.signIn(userEmail, password).catch(err => {
      console.log('Error logging in', err);
      const errMsg = <FormattedMessage id="user.login.errorLoggingIn" />;
      setUserConfirmationAttrs({ ...userConfirmationAttrs, errors: [errMsg] });
    });
  };

  if (redirectToHome) {
    const { from } = { from: { pathname: getDefaultPath(currentUser) } };
    return <Redirect to={from} />;
  }

  return (
    <Template
      onSubmit={handleSubmit}
      welcomeMsgId="user.setPassword.welcomeMsgId"
      taglineId="user.login.taglineId"
      loaderInsteadOfLogo={redirecting ? true : false}
      formHeader={redirecting ? null : 'user.confirmation.heading'}
      formTagline={redirecting ? 'user.confirmation.passwordIsSet' : null}
      centerTagline={redirecting ? true : false}>
      <div className="modal-dialog-body modal-dialog-body--small">
        <Errors errors={errors} />

        {!redirecting && (
          <div>
            <div className="disabled-input">
              <CustomInput
                name="email"
                autoFocus
                value={userEmail}
                label={intl.formatMessage({ id: 'user.form.email' })}
                disabled
                style={{ color: 'rgba(206, 206, 206, 1)' }}
              />
            </div>
            <div className="no-label-input">
              <CustomInput
                name="password"
                type="password"
                value={password}
                label={intl.formatMessage({ id: 'user.form.password' })}
                autoComplete="off"
                onChange={handleChange}
              />
            </div>
            <div className="no-label-input">
              <CustomInput
                name="confirmPassword"
                type="password"
                value={confirmPassword}
                label={intl.formatMessage({ id: 'user.form.confirmPassword' })}
                autoComplete="off"
                onChange={handleChange}
              />
            </div>
          </div>
        )}
      </div>

      {!redirecting && (
        <div className="modal-dialog-footer modal-dialog-footer--centered">
          <button
            className="button-standard button-standard--blue2 button-standard--medium  new-auth-btn"
            disabled={isSaving}
            type="submit">
            <p>
              <FormattedMessage id="user.login.submitBtn" />
            </p>
          </button>
        </div>
      )}
    </Template>
  );
};

export default ConfirmUser;

ConfirmUser.propTypes = {
  confirmationToken: PropTypes.string,
  auth: PropTypes.object.isRequired,
  verifyConfirmationToken: PropTypes.func.isRequired,
  confirmAndSetPassword: PropTypes.func.isRequired,
  signIn: PropTypes.func.isRequired,
};
