import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getPathPrefix } from '../../../helpers/pathHelper';
import StudentHeader from './StudentHeader';

const mapStateToProps = (state, ownProps) => {
  const { student } = ownProps;
  const pathPrefix = getPathPrefix(student);
  return {
    backUrl: `${pathPrefix}/`,
  };
};

export default withRouter(connect(mapStateToProps)(StudentHeader));
