import React from 'react';
import PropTypes from 'prop-types';
import Icons from '../../components/Utils/Icons';
import { FormattedMessage } from 'react-intl';

const Variants = {
  small: {
    'result-container': 'result-container',
    'percentage-circle-container': 'percentage-circle-container',
    'percentage-circle-green': 'percentage-circle-green',
    'percentage-circle-yellow': 'percentage-circle-yellow',
    'percentage-result-quiz': 'percentage-result-quiz',
    'result-uli': 'result-uli',
  },
  big: {
    'result-container': 'result-container--big',
    'percentage-circle-container': 'percentage-circle-container--big',
    'percentage-circle-green': 'percentage-circle-green--big',
    'percentage-circle-yellow': 'percentage-circle-yellow--big',
    'percentage-result-quiz': 'percentage-result-quiz--big',
    'result-uli': 'result-uli--big',
  },
};

export const Result = ({ points, totalPoints, incorrectAnswers, attempts, totalAttempts, variant = 'small' }) => {
  let percentageCorrect = Math.round((points / totalPoints) * 100);
  let passed = percentageCorrect >= 70;
  let roundedPoints = Math.round(points * 10) / 10;
  let variantClasses = Variants[variant] || {};

  return (
    <div className={variantClasses['result-container']}>
      <div className={variantClasses['percentage-circle-container']}>
        <div
          className={passed ? variantClasses['percentage-circle-green'] : variantClasses['percentage-circle-yellow']}>
          <div className={variantClasses['percentage-result-quiz']}>{`${percentageCorrect}%`}</div>
        </div>
      </div>
      <div>
        <ul>
          <div className={variantClasses['result-uli']}>
            <div className="icon-wrapper">{passed ? <Icons.CircleCheck3 /> : <Icons.WarningTriangle />}</div>

            <div className="result-unofficial-li">{passed ? 'Congrats, you passed!' : 'Did not pass'}</div>
          </div>
          <div className={variantClasses['result-uli']}>
            <div className="icon-wrapper">
              <Icons.CircleX />
            </div>
            <div className="result-unofficial-li">
              <FormattedMessage
                id={incorrectAnswers === 1 ? 'gradedQuiz.incorrectAnswer' : 'gradedQuiz.incorrectAnswers'}
                values={{ incorrectAnswers: incorrectAnswers }}
              />
            </div>
          </div>
          <div className={variantClasses['result-uli']}>
            <div className="icon-wrapper">
              <Icons.SmallGraph />
            </div>
            <div className="result-unofficial-li">{`${roundedPoints} out of ${totalPoints} points`}</div>
          </div>
          <div className={variantClasses['result-uli']}>
            <div className="icon-wrapper">
              <Icons.Clock1 />
            </div>
            <div className="result-unofficial-li">{`${attempts} out of ${totalAttempts} attempts`}</div>
          </div>
        </ul>
      </div>
    </div>
  );
};

Result.propTypes = {
  message: PropTypes.string,
  incorrectAnswers: PropTypes.number,
  points: PropTypes.number,
  totalPoints: PropTypes.number,
  attempts: PropTypes.number,
  totalAttempts: PropTypes.number,
  variant: PropTypes.string,
};
