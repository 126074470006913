import { difference as _difference, isEmpty as _isEmpty } from 'lodash';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import EnrollModalContainer from '../Admin/EnrollModalContainer';
import intl from '../../utils/intl';
import { toastSuccess } from '../../utils/toastHelper';

export default class EnrollAuthor extends Component {
  constructor(props) {
    super(props);

    this.state = {
      userIds: [],
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (_isEmpty(this.state.userIds) && this.state.userIds !== nextProps.userIds) {
      this.setState({ userIds: nextProps.userIds });
    }
  }

  async componentDidMount() {
    const { bootcamp, getAuthors } = this.props;

    const authors = await getAuthors(bootcamp.id);
    this.setState({
      userIds: authors.map(c => c.id),
    });
  }

  handleEnroll = async user => {
    const { bootcamp, addAuthor, removeAuthor, mode } = this.props;
    let userIds = [...this.state.userIds];

    if (mode === 'enroll') {
      await addAuthor(bootcamp.id, user.id);
      toastSuccess(intl.formatMessage({ id: 'bootcamp.authors.messages.added' }));
      userIds.push(user.id);
    } else {
      await removeAuthor(bootcamp.id, user.id);
      toastSuccess(intl.formatMessage({ id: 'bootcamp.authors.messages.removed' }));
      userIds = _difference(this.state.userIds, [user.id]);
    }

    this.setState({ userIds });
  };

  render() {
    const { mode } = this.props;
    const { userIds } = this.state;
    const headerTitle =
      mode === 'enroll' ? (
        <FormattedMessage id="bootcamp.enrollAuthors" />
      ) : (
        <FormattedMessage id="bootcamp.unenrollAuthors" />
      );

    return (
      <EnrollModalContainer
        userRole="Author"
        onEnroll={this.handleEnroll}
        userIds={userIds}
        mode={mode}
        headerTitle={headerTitle}
        {...this.props}
      />
    );
  }
}
