import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const statusStyleModifier = status => {
  switch (status) {
    case 'onTrack':
      return 'on-track';
    default:
      return status;
  }
};

const cardClassName = status =>
  classnames('student-analytics-metric-card', `student-analytics-metric-card__${statusStyleModifier(status)}`);

const MetricCard = ({ icon, metricKey, metricValue, status }) => {
  return (
    <div className={cardClassName(status)} data-testid="metric-card">
      <div className="metric-icon" data-testid="metric-icon">
        {icon}
      </div>
      <div className="metric-body">
        <div className="metric-key" data-testid="metric-key">
          {metricKey}
        </div>
        <div className="metric-value" data-testid="metric-value">
          {metricValue}
        </div>
      </div>
    </div>
  );
};

MetricCard.propTypes = {
  icon: PropTypes.node.isRequired,
  metricKey: PropTypes.string.isRequired,
  metricValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  status: PropTypes.oneOf(['onTrack', 'warning', 'behind', 'info']),
};

MetricCard.defaultProps = {
  status: 'info',
};

export default MetricCard;
