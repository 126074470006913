import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { EyeFilled } from '@ant-design/icons';
import { Link, useLocation } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import Loading from '../../Utils/Loading';
import { selectCohort } from '../../../selectors/cohortSelectors';
import { selectBootcamp } from '../../../selectors/bootcampSelectors';
import { selectCurrentUser } from '../../../selectors/authSelectors';
import { getCoursePathByIds } from '../../../helpers/pathHelper';
import CohortInfoTooltip from '../CohortInfoTooltip';
import { daysLeftToFinishGrading, isCohortInGradingProcess } from '../Dashboard/CohortItem/helpers';

const useStore = cohortId => {
  const cohort = useSelector(state => selectCohort(state, cohortId));
  const bootcamp = useSelector(state => selectBootcamp(state, cohort?.bootcamp_id));
  const currentUser = useSelector(selectCurrentUser);

  return {
    cohort,
    bootcamp,
    currentUser,
  };
};

const getDisplayTitle = (cohort, bootcamp) => {
  let prefix = '';
  if (isCohortInGradingProcess(cohort)) {
    const daysLeft = daysLeftToFinishGrading(cohort);
    prefix = <FormattedMessage id="cohort.numDaysLeftToFinishGrading" values={{ count: daysLeft }} />;
  }

  if (cohort?.display_cohort_title) {
    return (
      <span data-testid="display-title">
        {prefix} {cohort.cohort_title}
      </span>
    );
  }

  return (
    <span data-testid="display-title">
      {prefix} {bootcamp?.title}
    </span>
  );
};

const Header = ({ cohortId }) => {
  const { cohort, bootcamp, currentUser } = useStore(cohortId);
  const location = useLocation();

  if (!cohort || !bootcamp || !currentUser) {
    return <Loading />;
  }

  const displayTitle = getDisplayTitle(cohort, bootcamp);
  const cohortName = cohort.location;

  const getViewCourseAsStudentUrl = () => {
    const courseUrl = getCoursePathByIds(currentUser, bootcamp.id, bootcamp.path_ids[0]);
    const returnUrl = encodeURIComponent(`${location.pathname}${location.search}`);

    return `${courseUrl}?cohortId=${cohortId}&returnUrl=${returnUrl}`;
  };

  return (
    <div className="cohort-dashboard-header">
      <div className="cohort-info">
        <div className="cohort-title">{displayTitle}</div>
        <div className="cohort-location">
          {cohortName}

          <CohortInfoTooltip cohort={cohort} />
        </div>
      </div>
      <div className="cohort-actions">
        <div className="cohort-student-view">
          <Link className="action-button" to={getViewCourseAsStudentUrl()}>
            <EyeFilled />
            <FormattedMessage id="cohortDashboard.header.viewCourseAsStudent" />
          </Link>
        </div>
      </div>
    </div>
  );
};

Header.propTypes = {
  cohortId: PropTypes.string.isRequired,
};

export default Header;
