import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Icons from '../../components/Utils/Icons';

export const ResultNextStep = ({ currentTitle, nextTitle, nextUrl, nextLessonLength }) => {
  return (
    <div className="next-step-container">
      <div className="next-step-content">
        <div className="next-step-completed">
          <strong className="completed-text">
            <FormattedMessage id="gradedQuiz.resultNextStep.completed" />
          </strong>
          <span className="next-step-name">{currentTitle}</span>
        </div>
        <div className="next-step-detail">
          <div className="next-step-label">
            <FormattedMessage id="gradedQuiz.resultNextStep.next" />
          </div>
          <div className="next-step-title">{nextTitle}</div>
          {nextLessonLength && (
            <div className="next-step-length">
              <Icons.ClockBlack />
              <FormattedMessage id="gradedQuiz.resultNextStep.length" />
              <span className="minutes">{nextLessonLength}</span>
            </div>
          )}
        </div>
      </div>
      <a href={nextUrl} className="next-step-button" aria-label="Next Step">
        <Icons.ChevronRight />
      </a>
    </div>
  );
};

ResultNextStep.propTypes = {
  currentTitle: PropTypes.string.isRequired,
  nextTitle: PropTypes.string.isRequired,
  nextUrl: PropTypes.string.isRequired,
  nextLessonLength: PropTypes.string.isRequired,
};
