import { combineReducers } from 'redux';
import auth from './authReducer';
import bootcamp from './bootcampReducer';
import components from './componentsReducer';
import entities from './entitiesReducer';
import sideNav from './sideNavReducer';
import tenant from './tenantReducer';
import context from './contextReducer';
import requests from './requestsReducer';
import { AUTH } from '../actions/constants';
import { clearPromise } from '../actions/tenantActions';

const appReducer = combineReducers({
  auth,
  bootcamp,
  components,
  entities,
  sideNav,
  tenant,
  context,
  requests,
});

const rootReducer = (state, action) => {
  if (action.type === AUTH.LOGOUT) {
    // eslint-disable-next-line no-console
    console.log('Clearing redux on logout.');
    clearPromise();
    return undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;
