import React, { useEffect } from 'react';
import Welcome from './Welcome/Welcome';
import CurrentCourseContainer from './CurrentCourse/CurrentCourseContainer';
import CurrentLessonContainer from './CurrentCourse/CurrentLessonContainer';
import CurrentlyEnrolledContainer from './CurrentCourse/CurrentlyEnrolledContainer';

const LearningHome = props => {
  const { currentUser, enrollments, fetchCurrentEnrollments, currentEnrollment, bootcamps, fetchBootcamps } = props;

  useEffect(() => {
    if (enrollments.length == 0) fetchCurrentEnrollments();
    if (bootcamps.length == 0) fetchBootcamps();
  }, [currentUser]);

  if (!currentUser) {
    return null;
  }

  if (!currentEnrollment) {
    return (
      <div className="learning-home-wrapper" data-testid="learning-home">
        <h2>We are having trouble finding your courses!</h2>
        <p>Please try refreshing or reach out via the support center!</p>
      </div>
    );
  }
  const completedEnrollment = !!currentEnrollment?.completed_at;

  return (
    <div className="learning-home-wrapper" data-testid="learning-home">
      <Welcome currentUser={currentUser} />
      {completedEnrollment ? null : (
        <div className="top-row">
          <div className="square courses" data-testid="my-current-courses">
            <CurrentCourseContainer currentUser={currentUser} currentEnrollment={currentEnrollment} />
          </div>
          <div className="square lessons" data-testid="my-current-lesson">
            <CurrentLessonContainer currentUser={currentUser} currentEnrollment={currentEnrollment} />
          </div>
        </div>
      )}
      <CurrentlyEnrolledContainer currentUser={currentUser} enrollments={enrollments} bootcamps={bootcamps} />
    </div>
  );
};

export default LearningHome;
