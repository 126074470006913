import { isArray as _isArray } from 'lodash';
import { toast } from 'react-toastify';

const getMessage = errors => (_isArray(errors) ? errors.join(', ') : errors);

export const toastError = (errors, options) => {
  const opts = { type: toast.TYPE.ERROR, autoClose: 5000, ...options };
  return toast(getMessage(errors), opts);
};

export const toastSuccess = (messages, options) => {
  const opts = { type: toast.TYPE.SUCCESS, ...options };
  return toast(getMessage(messages), opts);
};

export const toastException = (err, defaultMessage, options) => {
  // eslint-disable-next-line no-console
  console.error('Error occurred while saving', err);
  const errors = (err.value && err.value.errors) || [defaultMessage];
  toastError(errors, options);
};
