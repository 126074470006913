import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import ContentEditable from 'react-contenteditable';
import { useLocation, useHistory } from 'react-router-dom';
import showModal from '../../../utils/showModal';
import EditLabModal from './EditLabModal';
import BlockEditOptionsContainer from './BlockEditOptionsContainer';
import CKEditor from '../../Utils/CKEditor';
import Icons from '../../Utils/Icons';
import StudentBlockLabShow from '../../Student/BlockLabShowContainer';
import { isTrailheadApp } from '../../../helpers/labHelper';
import useSaveBlock from '../../../hooks/useSaveBlock';
import BlockConflictModal from './BlockConflictModal/BlockConflictModal';
import MultipleEditor from '../../Blocks/MultipleEditor';

export default function BlockLabShow({ block, tutorial, fetchTutorial, isEditMode, index, totalBlocks, onReorder }) {
  const { blockState, handleConflictSave, conflict, handleChange, contentRef } = useSaveBlock(block);
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    if (!tutorial) {
      fetchTutorial(block.external_id);
    }
  }, []);

  const handleEdit = () => {
    showModal(EditLabModal, {
      block: {
        ...blockState,
        app: tutorial.app,
        app_site_id: tutorial.app_site_id,
        end_button_type: tutorial.end_button_type,
        project_url: tutorial.project_url,
        launch_in: tutorial.launch_in,
      },
      hideFile: true,
      afterSave: handleConflictSave,
    });
  };

  const handleEditLab = async () => {
    const url = `${location.pathname}/blocks/${block.id}`;

    history.push(url);
  };

  if (!isEditMode) {
    return <StudentBlockLabShow block={blockState} />;
  }

  return (
    <>
      <div className="section">
        <div className="block-label">
          <div className="block-label__content">
            <MultipleEditor blockId={blockState.id} className="knowledge-check-wrapper">
              <BlockEditOptionsContainer
                block={block}
                index={index}
                total={totalBlocks}
                onReorder={onReorder}
                onEdit={handleEdit}
              />

              <div className="knowledge-check__header">
                <Icons.ElixirBig />
                <ContentEditable
                  html={blockState.title}
                  className="heading"
                  tagName="h1"
                  onChange={handleChange('title')}
                />
              </div>

              <div className="knowledge-check__body">
                <CKEditor ref={contentRef} inline content={blockState.content} onChange={handleChange('content')} />
                {tutorial && !isTrailheadApp(tutorial.app) && (
                  <div className="launch__wrapper">
                    <Icons.LabBlockWrapper />
                    <button className="launch" onClick={handleEditLab} type="button">
                      <FormattedMessage id="common.editLab" />
                    </button>
                  </div>
                )}
              </div>
            </MultipleEditor>
          </div>
        </div>
      </div>

      {conflict.visible && (
        <BlockConflictModal
          remoteBlock={conflict.remote}
          currentBlock={conflict.current}
          onOk={handleConflictSave}
          onCancel={conflict.hide}
        />
      )}
    </>
  );
}
BlockLabShow.propTypes = {
  block: PropTypes.object.isRequired,
  tutorial: PropTypes.object,
  fetchTutorial: PropTypes.func.isRequired,
  isEditMode: PropTypes.bool,
  index: PropTypes.number.isRequired,
  totalBlocks: PropTypes.number.isRequired,
  onReorder: PropTypes.func.isRequired,
};
