import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { selectEnrollmentForBootcamp } from '../selectors/enrollmentSelectors';
import { selectCohort } from '../selectors/cohortSelectors';

export default function useCurrentEnrollment() {
  const { bootcampId } = useParams();
  const enrollment = useSelector(s => selectEnrollmentForBootcamp(s, bootcampId));
  const enrollmentId = enrollment && enrollment.enrollable_id;
  const cohort = useSelector(s => (enrollmentId ? selectCohort(s, enrollmentId) : null));

  return { enrollment, cohort };
}
