import React from 'react';
import PropTypes from 'prop-types';

import MetricCard from './MetricCard';
import Icons from '../../../../../Utils/Icons';
import intl from '../../../../../../utils/intl';

const metricCardProps = student => {
  if (student.watchList.inWatchList) {
    return {
      icon: <Icons.Exclamation />,
      status: 'behind',
      metricKey: intl.formatMessage({ id: 'cohortDashboard.studentsPage.reasonToBeWatched' }),
      metricValue: student.watchList.reasons[0],
    };
  }

  return {
    icon: <Icons.Check />,
    status: 'onTrack',
    metricKey: intl.formatMessage({ id: 'cohortDashboard.studentsPage.status' }),
    metricValue: intl.formatMessage({ id: 'cohortDashboard.studentsPage.onTrack' }),
  };
};

const WatchCard = ({ student }) => <MetricCard {...metricCardProps(student)} />;

WatchCard.propTypes = {
  student: PropTypes.object.isRequired,
};

export default WatchCard;
