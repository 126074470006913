import axios from './axios';

export default class Cohort {
  static async all(tenantId, withBootcamps) {
    const response = await axios.get('/paths/bootcamp_instances', {
      params: { with_bootcamps: withBootcamps },
    });
    return response.data;
  }

  static async get(tenantId, cohortId) {
    const response = await axios.get(`/paths/bootcamp_instances/${cohortId}`);
    return response.data;
  }

  static async save(tenantId, cohort) {
    const response = cohort.id
      ? await axios.put(`/paths/bootcamp_instances/${cohort.id}`, cohort)
      : await axios.post('/paths/bootcamp_instances', cohort);
    return response.data;
  }

  static async destroy(tenantId, cohort) {
    const response = await axios.delete(`/paths/bootcamp_instances/${cohort.id}`, cohort);
    return response.data;
  }

  static async userIds(tenantId, cohortId, role) {
    const response = await axios.get(`/paths/bootcamp_instances/${cohortId}/users?role=${role}`);
    return response.data.value;
  }

  static async addUser(tenantId, cohortId, userId, role) {
    const response = await axios.post(`/paths/bootcamp_instances/${cohortId}/add_user`, {
      user_id: userId,
      role,
    });
    return response.data;
  }

  static async removeUser(tenantId, cohortId, userId, role) {
    const response = await axios.post(`/paths/bootcamp_instances/${cohortId}/remove_user`, {
      user_id: userId,
      role,
    });
    return response.data;
  }

  static async forUser(tenantId, userId) {
    const response = await axios.get(`/paths/bootcamp_instances/for_user?user_id=${userId}`);
    return response.data;
  }
}
