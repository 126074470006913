import { connect } from 'react-redux';
import CohortSelector from './CohortSelector';
import { selectSortedCohorts } from '../../selectors/cohortSelectors';
import { fetchCohorts } from '../../actions/cohortActions';
import { selectCurrentUser } from '../../selectors/authSelectors';
import { canAdministrate } from '../../helpers/userAuth';
import { selectCohortsByBootcampAndCohortTitle } from '../../helpers/cohortHelper';

const mapStateToProps = (state, ownProps) => {
  const { bootcamp } = ownProps;
  const currentUser = selectCurrentUser(state);
  const isAdmin = canAdministrate(currentUser);
  const cohorts = bootcamp ? selectCohortsByBootcampAndCohortTitle(selectSortedCohorts(state), bootcamp, isAdmin) : [];

  return {
    bootcamp,
    cohorts,
  };
};

export default connect(mapStateToProps, { fetchCohorts })(CohortSelector);
