import { connect } from 'react-redux';
import SelectLessonModal from './SelectLessonModal';
import { moveTo } from '../../../../actions/blockActions';
import { fetchBootcamp } from '../../../../actions/bootcampActions';

const mapStateToProps = state => ({
  messagePrefixId: 'block.moveTo',
});

export default connect(mapStateToProps, { onSelect: moveTo, fetchBootcamp })(SelectLessonModal);
