import React, { useEffect } from 'react';
import ReactTooltip from 'react-tooltip';
import { ToastContainer } from 'react-toastify';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { selectIsEditMode } from '../selectors/contextSelectors';

export default function ContainerElements() {
  const isEditMode = useSelector(selectIsEditMode);
  const location = useLocation();

  useEffect(() => {
    setTimeout(() => ReactTooltip.rebuild(), 100);
  }, [isEditMode, location]);

  return (
    <>
      <ReactTooltip
        effect="solid"
        event="mouseenter focusin"
        eventOff="mouseleave focusout click"
        className="tooltip"
      />
      <ToastContainer hideProgressBar autoClose={3000} />
    </>
  );
}
