import React from 'react';
import PropTypes from 'prop-types';
import MathjaxWrapper from '../components/Utils/MathjaxWrapper';
import { contentForStudent } from './blockHelper';
import CodeSnippetWrapper from './contentCodeSnippetHelper';

export default function RTEContent({ content, Tag, classSelector, htmlForVar }) {
  return (
    <CodeSnippetWrapper>
      <MathjaxWrapper>
        <Tag
          className={classSelector}
          htmlFor={htmlForVar}
          dangerouslySetInnerHTML={{ __html: contentForStudent(content) }}
          tabIndex="0"
        />
      </MathjaxWrapper>
    </CodeSnippetWrapper>
  );
}

RTEContent.propTypes = {
  content: PropTypes.string,
  Tag: PropTypes.string,
  classSelector: PropTypes.string,
  htmlForVar: PropTypes.string,
};

RTEContent.defaultProps = {
  Tag: 'p',
};
