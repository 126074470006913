export const cohortTitle = (cohort, defaultTitle) => {
  return cohort && cohort.display_cohort_title ? cohort.cohort_title : defaultTitle;
};

export const selectCohortsByBootcampAndCohortTitle = (cohorts, bootcamp, isAdmin) => {
  return cohorts.filter(cohort => {
    let match = cohort.bootcamp_id === bootcamp.id;

    if (!match || isAdmin) return match;

    if (bootcamp.cohort_title) {
      match = cohort.display_cohort_title && cohort.cohort_title === bootcamp.cohort_title;
    } else {
      match = !cohort.display_cohort_title;
    }

    return match;
  });
};
