import React from 'react';
import PropTypes from 'prop-types';
import { Route, Switch } from 'react-router-dom';
import UserListContainer from './Users/UserListContainer';
import Cohorts from '../Cohorts/Cohorts';
import LtiConsumer from './LtiConsumers/LtiConsumerList';
import SchoolList from './Schools/SchoolList';

const Admin = ({ match }) => {
  return (
    <Switch>
      <Route path={`${match.path}/users`} component={UserListContainer} />
      <Route path={`${match.path}/cohorts`} component={Cohorts} />
      <Route path={`${match.path}/lti`} component={LtiConsumer} />
      <Route path={`${match.path}/schools`} component={SchoolList} />
    </Switch>
  );
};

Admin.propTypes = {
  match: PropTypes.object.isRequired,
};

export default Admin;
