import { connect } from 'react-redux';
import CurrentCourse from './CurrentCourse';
import { selectBootcamp } from '../../../../selectors/bootcampSelectors';

const mapStateToProps = (state, ownProps) => {
  const { currentUser, currentEnrollment } = ownProps;
  let currentProgressTitle;
  let progresses = [];
  if (currentUser && currentUser.current_enrollment) {
    const bootcampId = currentUser?.current_enrollment?.bootcamp_instance.bootcamp_id;
    const bootcamp = selectBootcamp(state, bootcampId);

    currentProgressTitle = bootcamp?.title;
  }
  if (currentEnrollment) {
    const {
      assignment_quiz_progress_calculation: as_percent,
      lesson_progress_calculation: ls_percent,
    } = currentEnrollment;
    progresses = [
      { title: 'Lesson completion', percent: ls_percent * 100 },
      { title: 'Assignment completion', percent: as_percent * 100 },
    ];
  }
  return {
    currentUser,
    currentProgressTitle,
    currentEnrollment,
    progresses,
  };
};

export default connect(mapStateToProps, {})(CurrentCourse);
