import { find as _find } from 'lodash';
import { connect } from 'react-redux';
import qs from 'query-string';
import { fetchCurrentEnrollments, fetchEnrollmentForCohort } from '../actions/enrollmentActions';
import { selectEnrollments } from '../selectors/enrollmentSelectors';
import { selectCurrentUser } from '../selectors/authSelectors';
import { canLearn, canCoach, canAuthor } from '../helpers/userAuth';
import { getLtiCohortId } from '../helpers/ltiParams';
import { selectCohortForEnrollment } from '../selectors/cohortSelectors';
import { selectBootcamp } from '../selectors/bootcampSelectors';
import { selectPathSectionUnitForAssignment } from '../selectors/assignmentSelectors';
import Lti from './Lti';
import { fetchLtiAssignmentProgresses } from '../actions/ltiAssignmentProgressActions';
import { selectLtiProgressForAssignment } from '../selectors/ltiAssignmentProgressSelectors';
import { setCurrentCohortId as setRosterCohort } from '../actions/components/rosterActions';
import { setCurrentCohortId as setGradebookCohort } from '../actions/components/gradeActions';

const mapStateToProps = (state, ownProps) => {
  const queryParams = qs.parse(ownProps.location.search);
  const { error } = queryParams;
  let currentUser;
  let enrollments;
  let enrollment;
  let bootcamp;
  let assignmentId;
  let isStudent;
  let isCoach;
  let isAuthor;
  let ltiProgress;
  let pathSectionUnit = {};
  const cohortId = getLtiCohortId();

  if (!error) {
    currentUser = selectCurrentUser(state);
    enrollments = selectEnrollments(state);
    isStudent = canLearn(currentUser);
    isCoach = canCoach(currentUser);
    isAuthor = canAuthor(currentUser);
    assignmentId = queryParams.assignment_id;
    enrollment = _find(enrollments, e => e.enrollable_id === cohortId);
    const cohort = enrollment && selectCohortForEnrollment(state, enrollment.id);
    bootcamp = cohort && selectBootcamp(state, cohort.bootcamp_id);
    if (assignmentId && bootcamp) {
      pathSectionUnit = selectPathSectionUnitForAssignment(state, bootcamp.id, assignmentId);
      ltiProgress = selectLtiProgressForAssignment(state, enrollment && enrollment.id, assignmentId);
    }
  }

  return {
    error,
    currentUser,
    bootcamp,
    assignmentId,
    isStudent,
    isCoach,
    isAuthor,
    ltiProgress,
    cohortId,
    ...pathSectionUnit,
  };
};

export default connect(mapStateToProps, {
  fetchCurrentEnrollments,
  fetchLtiAssignmentProgresses,
  setRosterCohort,
  setGradebookCohort,
  fetchEnrollmentForCohort,
})(Lti);
