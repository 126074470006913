import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Question from '../Quiz/QuestionContainer';

export default class SingleQuestionQuizQuestion extends Component {
  completeQuiz = () => {
    const { quizProgress, submitQuizProgress } = this.props;

    if (!quizProgress || quizProgress.completed_at) return;

    submitQuizProgress(quizProgress);
  };

  render() {
    const { question, questionProgressesEnabled } = this.props;

    return <Question {...{ question, questionProgressesEnabled }} afterSubmit={this.completeQuiz} />;
  }
}

SingleQuestionQuizQuestion.propTypes = {
  question: PropTypes.object.isRequired,
  questionProgressesEnabled: PropTypes.bool,
  quizProgress: PropTypes.object,
  submitQuizProgress: PropTypes.func.isRequired,
};
