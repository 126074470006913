import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Loading from '../Utils/Loading';
import QuestionSingleSelect from './QuestionSingleSelect';
import QuestionMultiSelect from './QuestionMultiSelect';
import QuestionSelfReflection from './QuestionSelfReflection';
import QuestionWithAnswer from './QuestionWithAnswer';
import Question from '../../api/question';
import { contentForStudent } from '../../helpers/blockHelper';
import RTEContent from '../../helpers/contentMathjaxHelper';

export default class QuizQuestion extends Component {
  handleSubmitChoices = choices => {
    this.submitQuestionProgress(choices, '');
  };

  handleSubmitAnswer = answer => {
    this.submitQuestionProgress([], answer);
  };

  submitQuestionProgress = (choices, answer) => {
    const { questionProgressesEnabled, canCoach, submitQuestionProgress, questionProgress, afterSubmit } = this.props;

    if (canCoach || !questionProgressesEnabled) return;

    submitQuestionProgress(questionProgress, choices, answer).then(afterSubmit);
  };

  getQuestionComponent = question =>
    ({
      [Question.SINGLE_SELECT]: QuestionSingleSelect,
      [Question.MULTI_SELECT]: QuestionMultiSelect,
      [Question.FREE_TEXT_ANSWER]: QuestionWithAnswer,
      [Question.SELF_REFLECTION]: QuestionSelfReflection,
    }[question.question_type]);

  waitingForQuestionProgress = () => {
    return !this.props.canCoach && this.props.questionProgressesEnabled && !this.props.questionProgress;
  };

  render() {
    const { question, questionProgress, renderQuestionsNavigation, renderNext } = this.props;

    const QuestionComponent = this.getQuestionComponent(question);

    return (
      <>
        <div className="knowledge-check-title-wrapper">
          <div className="knowledge-check-title">
            <RTEContent content={contentForStudent(question.prompt)} />
          </div>
        </div>

        <form aria-label="answer">
          {this.waitingForQuestionProgress() ? (
            <Loading />
          ) : (
            <QuestionComponent
              question={question}
              key={question.id}
              questionProgress={questionProgress}
              submitChoices={this.handleSubmitChoices}
              submitAnswer={this.handleSubmitAnswer}
              renderNext={renderNext}
              renderQuestionsNavigation={renderQuestionsNavigation}
            />
          )}
        </form>
      </>
    );
  }
}

QuizQuestion.propTypes = {
  question: PropTypes.object.isRequired,
  questionProgress: PropTypes.object,
  questionProgressesEnabled: PropTypes.bool,
  canCoach: PropTypes.bool.isRequired,
  afterSubmit: PropTypes.func,
  submitQuestionProgress: PropTypes.func.isRequired,
  renderQuestionsNavigation: PropTypes.func,
  renderNext: PropTypes.func,
};

QuizQuestion.defaultProps = {
  questionProgressesEnabled: true,
};
