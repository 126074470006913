import { map as _map, max as _max } from 'lodash';
import React, { Component } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import Icons from '../Utils/Icons';
import intl from '../../utils/intl';
import ModalFooter from './ModalFooter';

export default class Modal2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      zIndex: null,
    };
  }

  componentDidMount() {
    this.handleShow();
    this.setZIndex();
  }

  setZIndex = () => {
    if (!window.getComputedStyle) return;

    const modals = document.querySelectorAll('.modal-dialog-wrapper');
    const maxZIndex = _max(_map(modals, m => Number(getComputedStyle(m).zIndex)));
    if (maxZIndex) {
      this.setState({ zIndex: maxZIndex + 1 });
    }
  };

  handleCancel = () => {
    const { onCancel, onHide } = this.props;
    onCancel && onCancel();
    onHide();
  };

  handleShow = () => {
    const selectors = ['input', 'select', 'button:not(.modal-dialog-close__button)', 'textarea']
      .map(s => `.modal-dialog-wrapper ${s}`)
      .join(', ');

    let element = document.querySelector(selectors);
    if (element) {
      element.focus();
    } else {
      // find the close button
      element = document.querySelector('.modal-dialog-wrapper button.modal-dialog-close__button');
      if (element) {
        element.focus();
      }
    }
  };

  handleKeyDown = e => {
    if (this.props.closeOnEscape && e.key === 'Escape') {
      this.props.onHide();
    }
  };

  render() {
    const {
      children,
      showClose,
      size,
      title,
      okTitle,
      onOk,
      showCancel,
      className,
      bodyClass,
      footerClass,
      cancelTitle,
      contentClass,
      footerContent,
      backgroundImageUrl,
    } = this.props;
    const { zIndex } = this.state;

    const bgStyle = {};
    if (backgroundImageUrl) {
      bgStyle.backgroundImage = `url('${backgroundImageUrl}')`;
    }
    if (zIndex) {
      bgStyle.zIndex = zIndex;
    }

    return (
      <div className="v2">
        <div
          className={classnames('modal-dialog-wrapper modal-dialog-wrapper--opened', className)}
          tabIndex="-1"
          role="dialog"
          style={zIndex ? { zIndex: zIndex + 1 } : null}>
          <div className="modal-dialog" role="document" onKeyDown={this.handleKeyDown}>
            <div className={classnames('modal-dialog-content', `modal-dialog-content--${size}`, contentClass)}>
              {(showClose || title) && (
                <div className={classnames('modal-dialog-header', `modal-dialog-header--${size}`)}>
                  {showClose && (
                    <div className="modal-dialog-close">
                      <button
                        type="button"
                        aria-label={intl.formatMessage({ id: 'common.close' })}
                        className="modal-dialog-close__button"
                        data-testid="modal2-close-button"
                        onClick={this.handleCancel}>
                        <Icons.Close />
                      </button>
                    </div>
                  )}
                  {title && <h1 className="modal-dialog-title">{title}</h1>}
                </div>
              )}

              <div className={classnames('modal-dialog-body', bodyClass || `modal-dialog-body--${size}`)}>
                {children}
              </div>
              <ModalFooter
                showCancel={showCancel}
                onOk={onOk}
                footerContent={footerContent}
                className={footerClass}
                cancelTitle={cancelTitle}
                okTitle={okTitle}
                onCancel={this.handleCancel}
              />
            </div>
          </div>
        </div>

        <div
          className={classnames('modal-dialog-overlay', backgroundImageUrl ? 'modal-dialog-overlay--image' : '')}
          style={bgStyle}
        />
      </div>
    );
  }
}

Modal2.propTypes = {
  showClose: PropTypes.bool,
  title: PropTypes.any,
  size: PropTypes.oneOf(['small', 'medium', 'big']),
  footer: PropTypes.any,
  closeOnEscape: PropTypes.bool,
  showCancel: PropTypes.bool,
  onOk: PropTypes.func,
  okTitle: PropTypes.any,
  cancelTitle: PropTypes.any,
  className: PropTypes.string,
  contentClass: PropTypes.string,
  bodyClass: PropTypes.string,
  footerClass: PropTypes.string,
  footerContent: PropTypes.any,
  backgroundImageUrl: PropTypes.string,
  onCancel: PropTypes.func,
};

Modal2.defaultProps = {
  showClose: true,
  size: 'medium',
  closeOnEscape: true,
  showCancel: true,
  okTitle: intl.formatMessage({ id: 'common.save' }),
  cancelTitle: intl.formatMessage({ id: 'common.cancel' }),
};
