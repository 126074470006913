import cookies from 'browser-cookies';
const COOKIE_NAME = 'lti_params';

const getLtiParams = () => JSON.parse(cookies.get(COOKIE_NAME) || '{}');

export const getLtiCohortId = () => getLtiParams().custom_cohort_id;

export const getLtiMessageType = () => getLtiParams().lti_message_type;

export const isSelectionRequest = () => getLtiMessageType() === 'ContentItemSelectionRequest';

export const acceptMultipleItems = () => !!(getLtiParams().accept_multiple === 'true');

export const getContentItemReturnUrl = () => getLtiParams().content_item_return_url;

export const getDataParam = () => getLtiParams().data;

export const getOauthConsumerKey = () => getLtiParams().oauth_consumer_key;

export const isAssignmentMappingRequest = () => getLtiParams().action === 'assignment-mapping';

export const lineItemsUrl = () => getLtiParams().line_items_url;
