import { connect } from 'react-redux';
import { selectCurrentUser } from '../../../../selectors/authSelectors';
import AdminSidebar from './AdminSidebar';

const mapStateToProps = state => {
  const currentUser = selectCurrentUser(state);

  return {
    currentUser,
  };
};

export default connect(mapStateToProps)(AdminSidebar);
