import { connect } from 'react-redux';
import CommentThread from './CommentThread';
import { saveComment, fetchComments } from '../../../actions/commentActions';
import { selectCurrentUser } from '../../../selectors/authSelectors';
import { selectCohortForEnrollment } from '../../../selectors/cohortSelectors';
import { selectCommentsForBlockAndCohortOrderByNewest } from '../../../selectors/commentSelectors';
import { selectCurrentCohortId } from '../../../selectors/components/coachSelectors';
import { selectIsEditMode } from '../../../selectors/contextSelectors';
import { saveBlock } from '../../../actions/blockActions';

const mapStateToProps = (state, ownProps) => {
  const { block, enrollmentId, bootcampId } = ownProps;
  let cohortId = null;

  if (enrollmentId) {
    const cohort = selectCohortForEnrollment(state, enrollmentId);
    cohortId = cohort && cohort.id;
  } else {
    cohortId = selectCurrentCohortId(bootcampId);
  }

  return {
    user: selectCurrentUser(state),
    comments: selectCommentsForBlockAndCohortOrderByNewest(state, block.id, cohortId),
    cohortId,
    isEditMode: selectIsEditMode(state),
  };
};

export default connect(mapStateToProps, {
  fetchComments,
  saveComment,
  saveBlock,
})(CommentThread);
