import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Card } from 'antd';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import classnames from 'classnames';

import { selectEnrollmentsForUser } from '../../../../../selectors/enrollmentSelectors';
import { selectAssignmentProgressesByEnrollmentId } from '../../../../../selectors/assignmentProgressSelectors';
import UserPicture from '../../../../Utils/UserPicture';
import Icons from '../../../../Utils/Icons';
import WatchCard from './MetricCards/WatchCard';
import CompletedAssignmentsCard from './MetricCards/CompletedAssignmentsCard';
import AvgAssignmentGradeCard from './MetricCards/AvgAssignmentGradeCard';
import LastLoginCard from './MetricCards/LastLoginCard';
import CompletedLessonsCard from './MetricCards/CompletedLessonsCard';
import TotalTimeSpentCard from './MetricCards/TotalTimeSpentCard';
import EditEnrollmentStatusButton from './EditEnrollmentStatusButton';
import Enrollment from '../../../../../api/enrollment';
import { updateEnrollment } from '../../../../../actions/enrollmentActions';

const useStore = (userId, cohortId) => {
  const enrollments = useSelector(state => selectEnrollmentsForUser(state, userId));
  const enrollment = enrollments.find(enrollment => enrollment.enrollable_id === cohortId);
  const assignmentProgresses = useSelector(state => selectAssignmentProgressesByEnrollmentId(state, enrollment?.id));

  return {
    assignmentProgresses: assignmentProgresses || [],
    enrollment,
  };
};

const Title = ({ student, enrollmentStatus }) => {
  const avatarClassName = classnames('student-avatar', { 'student-avatar__watched': student.watchList.inWatchList });

  const displayedEnrollmentStatus = () => {
    switch (enrollmentStatus) {
      case Enrollment.ENROLLED:
        return <FormattedMessage id="cohortDashboard.studentsPage.enrolledStatus" />;
      case Enrollment.DROPPED:
        return <FormattedMessage id="cohortDashboard.studentsPage.droppedStatus" />;
      case Enrollment.COMPLETED:
        return <FormattedMessage id="cohortDashboard.studentsPage.completedStatus" />;
    }

    return;
  };

  return (
    <div className="students-page-student-analytics-title">
      <div className={avatarClassName} data-testid="student-avatar">
        <UserPicture user={student} className="user-profile-picture" />
      </div>

      <div className="student-enrollment">
        <div className="student-name">{student.name}</div>
        <div className="enrollment-status" data-testid="enrollment-status">
          {displayedEnrollmentStatus()}
        </div>
      </div>
    </div>
  );
};

Title.propTypes = {
  student: PropTypes.object.isRequired,
};

const StudentAnalytics = ({ student, cohortId, cohortReportStudent }) => {
  const { assignmentProgresses, enrollment } = useStore(student.id, cohortId);
  const dispatch = useDispatch();

  const handleOk = enrollmentStatus => {
    updateEnrollment({
      user_id: student.id,
      status: enrollmentStatus,
      cohort_id: enrollment.enrollable_id,
      id: enrollment.id,
    })(dispatch);
  };

  return (
    <div className="students-page-student-analytics">
      <Card
        title={<Title student={student} enrollmentStatus={enrollment?.status} />}
        extra={enrollment && <EditEnrollmentStatusButton enrollment={enrollment} onOk={handleOk} />}>
        <div className="row-1">
          <WatchCard student={student} />
          <CompletedAssignmentsCard numCompletedAssignments={assignmentProgresses.length} />
          <AvgAssignmentGradeCard assignmentProgresses={assignmentProgresses} />
        </div>
        <div>
          <LastLoginCard lastLogin={cohortReportStudent?.last_login} />
          <CompletedLessonsCard lessonsCompletedPercent={cohortReportStudent?.lessons_completed_percent} />
          <TotalTimeSpentCard seconds={cohortReportStudent?.duration} />
        </div>
      </Card>
    </div>
  );
};

StudentAnalytics.propTypes = {
  student: PropTypes.object.isRequired,
  cohortId: PropTypes.string.isRequired,
  cohortReportStudent: PropTypes.object.isRequired,
};

export default StudentAnalytics;
