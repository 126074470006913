import { isEmpty as _isEmpty } from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { selectCurrentUser } from '../../selectors/authSelectors';

const MultipleEditorWarning = ({ members }) => {
  const currentUser = useSelector(selectCurrentUser);

  if (_isEmpty(members)) {
    return '';
  }

  const editors = [];

  members.forEach(member => {
    if (member.email !== currentUser.email) {
      editors.push(`${member.name} - ${member.email}`);
    }
  });

  return (
    <div className="warning">
      <FormattedMessage id="block.multipleEditors.warning" values={{ editors: editors.join(', ') }} />
    </div>
  );
};

MultipleEditorWarning.propTypes = {
  members: PropTypes.array.isRequired,
};

export default MultipleEditorWarning;
