import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import { DeleteOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { FormattedMessage } from 'react-intl';

const RulesetCriterionViewer = ({ rulesetCriterion, onEdit, onRemove, fieldKeys, orderIndex }) => (
  <div className="ruleset-criterion-text-label-main">
    <span className="order-index">{`${orderIndex}.`}</span>
    {rulesetCriterion.name}
    {rulesetCriterion.nonLinearGradingRanges?.length ? (
      <>
        <div className="non-linear-grading-title">
          <FormattedMessage id="autograder.grading.nonLinearGradingSummary" />:
        </div>
        <div className="score-range-group">
          {rulesetCriterion.nonLinearGradingRanges.map(({ score, maxRange }) => (
            <div className="score-range" key={score}>
              <span className="score">{score}:</span>
              <span className="max-range">{`< ${maxRange}%`}</span>
            </div>
          ))}
        </div>
      </>
    ) : null}
    <div className="edit-field">
      {onEdit && (
        <Button
          type="primary"
          size="small"
          ghost
          className="edit-field-btn"
          onClick={event => {
            event.stopPropagation();
            onEdit(fieldKeys);
          }}>
          <PlusCircleOutlined style={{ fontSize: '16px', verticalAlign: 'initial' }} />
          Edit Ruleset Criterion
        </Button>
      )}
      {onRemove && <DeleteOutlined style={{ color: 'red', marginLeft: '5px' }} onClick={() => onRemove(fieldKeys)} />}
    </div>
  </div>
);

RulesetCriterionViewer.propTypes = {
  rulesetCriterion: PropTypes.object.isRequired,
  onEdit: PropTypes.func,
  onRemove: PropTypes.func,
  fieldKeys: PropTypes.any,
  orderIndex: PropTypes.number,
};

export default RulesetCriterionViewer;
