import { map as _map, debounce as _debounce } from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Errors from '../../Utils/Errors';
import Modal2 from '../../library/Modal2';
import { toastSuccess } from '../../../utils/toastHelper';
import intl from '../../../utils/intl';
import { ReactComponent as SpinnerIcon } from '../../../styles/pathstream/svg-jsx/icon-spinner.svg';
import { getSearchRegex } from '../../../helpers/regexHelper';
import CustomInput from '../../Utils/CustomInput';
import Loading from '../../Utils/Loading';
import CopyComponentModalComponentContainer from './CopyComponentModalComponentContainer';

export default class CopyComponentModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      errors: [],
      searchText: '',
      isSaving: false,
      isLoading: true,
    };
  }

  componentDidMount() {
    this.props.fetchComponents().then(() => this.setState({ isLoading: false }));
  }

  getFilteredComponents = () => {
    const { components } = this.props;
    const { searchText } = this.state;

    const regex = getSearchRegex(searchText);
    return components.filter(item => regex.test(item.title) || regex.test(item.description));
  };

  debouncedChangeSearch = _debounce(value => this.setState({ searchText: value }), 200);

  searchChanged = e => this.debouncedChangeSearch(e.target.value);

  handleClick = component => {
    this.setState({ isSaving: true });
    this.props
      .copyComponent(component, this.props.unitId)
      .then(() => {
        this.props.onHide();
        toastSuccess(<FormattedMessage id="component.messages.lessonCopied" />);
      })
      .catch(err => {
        const errors = (err.value && err.value.errors) ||
          (err.meta && [err.meta.message]) || [intl.formatMessage({ id: 'component.messages.unknownError' })];

        this.setState({ errors, isSaving: false });
      });
  };

  render() {
    const { isSaving, isLoading, errors } = this.state;
    const filteredComponents = this.getFilteredComponents();

    return (
      <Modal2
        size="big"
        bodyClass=" "
        className="select-units modal-auto-grow"
        title={<FormattedMessage id="component.heading.copyExistingLesson" />}
        onHide={this.props.onHide}>
        {isSaving && (
          <div className="loading-wrapper">
            <FormattedMessage id="common.copying" />
            <span className="icon">
              <SpinnerIcon />
            </span>
          </div>
        )}
        <Errors errors={errors} />

        <CustomInput name="search" onChange={this.searchChanged} label={intl.formatMessage({ id: 'common.search' })} />

        <br />

        {isLoading && <Loading />}

        <ul>
          {filteredComponents.length > 0 &&
            _map(filteredComponents, component => (
              <CopyComponentModalComponentContainer
                key={component.id}
                component={component}
                onClick={this.handleClick}
              />
            ))}
          {filteredComponents.length === 0 && (
            <li>
              <FormattedMessage id="component.heading.noLessons" />
            </li>
          )}
        </ul>
      </Modal2>
    );
  }
}
CopyComponentModal.propTypes = {
  unitId: PropTypes.string.isRequired,
  components: PropTypes.array.isRequired,
  copyComponent: PropTypes.func.isRequired,
  fetchComponents: PropTypes.func.isRequired,
};
