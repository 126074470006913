import React from 'react';
import { QuestionOutlined } from '@ant-design/icons';
import { toast } from 'react-toastify';
import { FormattedMessage } from 'react-intl';

import FileUploadModal from '../../Utils/FileUploadModal/FileUploadModal';
import Tutorial from '../../../api/tutorial';
import showModal from '../../../utils/showModal';
import SimpleModal from '../../library/SimpleModal/SimpleModal';
import intl from '../../../utils/intl';

export const showFileUploadModal = submitMethod => {
  showModal(FileUploadModal, {
    accept: Tutorial.ACCEPTED_FILE_TYPES.join(','),
    title: intl.formatMessage({ id: 'fileUpload.uploadModal.title' }),
    body: intl.formatMessage({ id: 'fileUpload.uploadModal.body' }),
    onSubmit: async (fileUploads, closeModal) => {
      showModal(SimpleModal, {
        icon: <QuestionOutlined />,
        title: intl.formatMessage({ id: 'project.submissionModal.title' }),
        body: intl.formatMessage({ id: 'project.submissionModal.body' }),
        okText: intl.formatMessage({ id: 'project.submissionModal.submitButton' }),
        onOk: async () => {
          try {
            await submitMethod({ fileUploads });

            closeModal();
          } catch (err) {
            toast(<FormattedMessage id="project.failedSubmission" />, {
              autoClose: 5000,
              type: toast.TYPE.ERROR,
            });
          }
        },
        showCancel: true,
      });
    },
  });
};
