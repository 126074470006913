import { each as _each, find as _find, map as _map, sortBy as _sortBy } from 'lodash';
import { formatDate } from '../../utils/utils';
import intl from '../../utils/intl';
import CohortReport from '../../api/cohortReport';
import User from '../../api/user';

export const STUDENT_DATA_FIELDS = [
  'name',
  'assignments_completed_percent',
  'avg_assignment_score',
  'lessons_completed_percent',
  'duration',
  'num_logins',
  'last_login',
];

export const CSV_COLUMN_NAMES = [
  'Course Name',
  'Cohort Start Date',
  'Cohort End Date',
  'Cohort Location',
  ...STUDENT_DATA_FIELDS.map(field =>
    intl
      .formatMessage({ id: `cohortReports.students.${field}` })
      // delete html tags
      .replace(/<\/?[^>]+(>|$)/g, '')
      // delete multiple spaces
      .replace(/ +(?= )/g, ''),
  ),
  'Date Generated',
];

export const getRowValueByFieldName = (row, field) => {
  switch (field) {
    case 'name':
      return row?.user?.name;

    case 'last_login':
      return formatDate(row.last_login);

    case 'duration':
      return Math.round((row.duration / 3600) * 100) / 100;

    default:
      return row[field];
  }
};

export const generateCSV = async (cohort, bootcampTitle) => {
  let rows = await generateStudentRows(cohort.id);
  rows = _sortBy(rows, 'user.name');
  const data = studentRowsToCSVArray(rows, bootcampTitle, cohort);
  const fileName = `${bootcampTitle}_${cohort.start_date}_${cohort.end_date}.csv`;
  const csvContent = [CSV_COLUMN_NAMES, ...data].map(e => e.join(',')).join('\n');
  const encodedUri = encodeURI(csvContent);
  const link = document.createElement('a');
  link.setAttribute('href', `data:text/csv;charset=utf-8,${encodedUri}`);
  link.setAttribute('download', fileName);
  link.click();
};

export const generateStudentRows = async cohortId => {
  const { rows } = await CohortReport.students(cohortId);
  const userIds = rows.map(user => user.user_id);
  const users = await User.getUsers(userIds);
  _each(users, user => {
    const row = _find(rows, s => s.user_id === user.id);
    if (row) {
      row.user = user;
    }
  });
  return rows;
};

export const studentRowsToCSVArray = (rows, currentBootcampTitle, cohort) =>
  _map(rows, row => [
    currentBootcampTitle,
    cohort.start_date,
    cohort.end_date,
    cohort.location,
    ..._map(STUDENT_DATA_FIELDS, field => getRowValueByFieldName(row, field)),
    formatDate(new Date()),
  ]);
