import React from 'react';
import { confirmInput } from '../../../../../utils/confirmInput';
import { FormattedMessage } from 'react-intl';
import { isGsuiteApp } from '../../../../../helpers/labHelper';
import Stream from '../../../../../api/stream';
import { allowResubmission as allowAssignmentResubmission } from '../../../../../actions/assignmentProgressActions';
import { allowResubmission as allowProjectResubmission } from '../../../../../actions/unitProgressActions';
import { toastError, toastSuccess } from '../../../../../utils/toastHelper';
import intl from '../../../../../utils/intl';
import { useDispatch, useSelector } from 'react-redux';
import { selectUnitProgressByAssignmentProgressId } from '../../../../../selectors/unitProgressSelectors';
import { selectAssignment } from '../../../../../selectors/assignmentSelectors';
import { DownSquareOutlined } from '@ant-design/icons';

const AllowResubmissionButton = ({ assignmentProgress }) => {
  const dispatch = useDispatch();

  const unitProgress = useSelector(state => selectUnitProgressByAssignmentProgressId(state, assignmentProgress?.id));
  const assignment = useSelector(state => selectAssignment(state, assignmentProgress?.assignment_id));

  const handleResubmissionClick = () => {
    confirmInput({
      message: <FormattedMessage id="grading.confirmResubmission" />,
      onOk: async () => {
        try {
          if (isGsuiteApp(assignment.app)) {
            // make the project editable
            await Stream.updateAccess(assignmentProgress.user_project_url, true, assignment.app);
          }

          await Promise.all([
            allowAssignmentResubmission(assignmentProgress.id)(dispatch),
            allowProjectResubmission(unitProgress.id)(dispatch),
          ]);

          toastSuccess(intl.formatMessage({ id: 'grading.resubmissionSuccess' }));
        } catch (e) {
          // eslint-disable-next-line no-console
          console.error('Error marking project for resubmission', e);
          toastError(intl.formatMessage({ id: 'grading.resubmissionFailure' }));
        }
      },
    });
  };
  return (
    <div className="assignment-actions">
      <button className="action-button" type="button" onClick={handleResubmissionClick}>
        <DownSquareOutlined />
        <FormattedMessage id="cohortDashboard.assignmentsPage.allowResubmissions" />
      </button>
    </div>
  );
};

export default AllowResubmissionButton;
