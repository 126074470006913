import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Loading from '../Utils/Loading';
import * as analytics from '../../utils/analytics';
import { setPageTitle } from '../../helpers/pageHelper';
import AdminWrapper from '../v2/Nav/Admin/AdminWrapper';
import StudentListContainer from './Grading/StudentListContainer';
import RosterContent from './RosterContent';
import intl from '../../utils/intl';
import StudentInfoPanel from './Grading/StudentInfoPanel';
import Icons from '../Utils/Icons';

export default class Grade extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasLoaded: false,
      selectedCohort: null,
      selectedStudent: null,
      mobileNavOpen: false,
    };
  }

  componentDidMount() {
    Promise.all([this.props.fetchCohorts(), this.props.fetchBootcamps()]).then(() => {
      this.setState({
        hasLoaded: true,
      });
    });

    analytics.pageStart('Assessments List');
    setPageTitle('pageName.assessments');
  }

  componentWillUnmount() {
    const { bootcamp, selectedCohort } = this.props;
    analytics.pageEnd({
      bootcamp,
      cohort: selectedCohort,
    });
  }

  handleStudentSelect = selectedStudent => {
    this.setState({ selectedStudent });
    if (selectedStudent) {
      this.setState({ mobileNavOpen: false });
    }
  };

  handleCohortSelect = selectedCohort => {
    this.setState({ selectedCohort });
  };

  toggleMobileNav = () => {
    this.setState(state => ({ mobileNavOpen: !state.mobileNavOpen }));
  };

  render() {
    const { hasLoaded, selectedCohort, selectedStudent, mobileNavOpen } = this.state;

    const dropdownWrapperClass = classnames(
      'md-3 sm-4 student-roaster-dropdown-wrapper',
      mobileNavOpen && 'student-roaster-dropdown-wrapper---search-open',
    );

    const contentWrapperClass = classnames(
      'md-7 sm-8 student-roaster-content-wrapper',
      mobileNavOpen && 'student-roaster-content-wrapper---search-open',
    );

    if (!hasLoaded) {
      return (
        <AdminWrapper headerTitle={intl.formatMessage({ id: 'nav.gradingQueue' })}>
          <Loading />
        </AdminWrapper>
      );
    }

    return (
      <AdminWrapper headerTitle={intl.formatMessage({ id: 'nav.gradingQueue' })}>
        <button
          type="button"
          className="student-roster-button"
          aria-label={intl.formatMessage({ id: 'aria.viewStudents' })}
          onClick={this.toggleMobileNav}>
          <Icons.Users />
        </button>
        <div className="grid-row">
          <div className={dropdownWrapperClass}>
            <StudentListContainer onStudentSelect={this.handleStudentSelect} onCohortSelect={this.handleCohortSelect} />
          </div>
          <div className={contentWrapperClass}>
            <RosterContent student={selectedStudent} cohort={selectedCohort} />
          </div>
          <div className="md-2 student-roaster-student-info-wrapper">
            {selectedStudent && <StudentInfoPanel student={selectedStudent.student} />}
          </div>
        </div>
      </AdminWrapper>
    );
  }
}

Grade.propTypes = {
  bootcamp: PropTypes.object,
  selectedCohort: PropTypes.object,
  fetchCohorts: PropTypes.func.isRequired,
  fetchBootcamps: PropTypes.func.isRequired,
};
