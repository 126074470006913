import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import OptionsPopup from '../../Utils/OptionsPopup';
import showModal from '../../../utils/showModal';
import EditSchoolModal from './EditSchoolModal';

export default function SchoolRow({ school, ltiConsumers }) {
  const handleEdit = () => {
    showModal(EditSchoolModal, { school, ltiConsumers });
  };

  const ltiConsumer = ltiConsumers.find(ltiConsumer => ltiConsumer.id === school.lti_consumer_id);

  return (
    <div className="user-details">
      <div className="grid-row user-details-items clear">
        <div className="md-4 user-details-item">
          <div className="user-details-item__text">{school.name}</div>
        </div>
        <div className="md-3 user-details-item">
          <div className="user-details-item__text">{ltiConsumer?.consumer_key}</div>
        </div>
        <div className="md-3 user-details-item">
          <div className="user-details-item__text">{school?.school_type}</div>
        </div>
        <div className="md-1 md-offset-1 sm-12 user-details-item user-content-button">
          <OptionsPopup
            options={[
              {
                label: <FormattedMessage id="school.edit" />,
                onClick: () => handleEdit(school),
              },
            ]}
          />
        </div>
      </div>
    </div>
  );
}

SchoolRow.propTypes = {
  school: PropTypes.object.isRequired,
  ltiConsumers: PropTypes.array.isRequired,
};
