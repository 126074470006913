import axios from './axios';

export default class LessonProgress {
  static async completePart(_, lessonProgressId, partId) {
    const response = await axios.put(`/content/lesson_progresses/${lessonProgressId}/complete_part`, {
      part_id: partId,
    });
    return response.data;
  }

  static async start(_, lessonProgressId) {
    const response = await axios.put(`/content/lesson_progresses/${lessonProgressId}/start`);
    return response.data;
  }

  static async complete(_, lessonProgressId) {
    const response = await axios.put(`/content/lesson_progresses/${lessonProgressId}/complete`);
    return response.data;
  }
}
