import axios from './axios';

export default class School {
  static all() {
    return axios.get('/users/schools').then(response => response.data);
  }

  static get(tenantId, schoolId) {
    return axios.get(`/users/schools/${schoolId}`).then(response => response.data);
  }

  static save(tenantId, school) {
    return axios.post('/users/schools', { ...school }).then(response => response.data);
  }

  static update(tenantId, school) {
    return axios.put(`/users/schools/${school.id}`, school).then(response => response.data);
  }
}
