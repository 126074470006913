import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { fetchCurrentTenant } from '../actions/tenantActions';

export default function useTenant() {
  const [tenant, setTenant] = useState(null);
  const dispatch = useDispatch();

  async function fetchTenant() {
    const t = await fetchCurrentTenant()(dispatch);
    setTenant(t);
  }

  useEffect(() => {
    fetchTenant();
  }, []);

  return tenant;
}
