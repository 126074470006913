import React from 'react';
import PropTypes from 'prop-types';

import BlockQuizShow from './BlockQuizShow';
import BlockEditOptionsContainer from './BlockEditOptionsContainer';
import LabBlockHeader from '../../Utils/LabBlockHeader';
import LabNavButton from '../../Blocks/Lab/LabNavButton';
import QuizLoaderContainer from '../../Quiz/LoaderContainer';
import ContentValidator from './ContentValidator';
import useBlockContent from '../../../hooks/useBlockContent';

const BlockTakeQuiz = props => {
  const { index, block, totalBlocks, onReorder, quizOnLab, isEditMode } = props;
  const { content } = useBlockContent(block);

  if (!isEditMode) return <BlockQuizShow {...props} />;

  const isOpen = quizOnLab.isOpen(block.id);
  const handleClick = isOpen ? quizOnLab.hide : quizOnLab.show;
  const buttonMessageId = isOpen ? 'quiz.backButtonOnLab' : 'quiz.buttonForAuthorOnLab';

  return (
    <ContentValidator content={content}>
      <BlockEditOptionsContainer {...{ block, index, totalBlocks, onReorder }} />

      <LabBlockHeader />

      <QuizLoaderContainer
        block={block}
        render={quiz => (
          <div className="lab-nav-objective lab-nav-objective-title">
            <h1 className="heading" dangerouslySetInnerHTML={{ __html: quiz.title }} />
          </div>
        )}
      />

      <LabNavButton onClick={handleClick} messageId={buttonMessageId} back={isOpen} />
    </ContentValidator>
  );
};

BlockTakeQuiz.propTypes = {
  block: PropTypes.object.isRequired,
  isEditMode: PropTypes.bool,
  index: PropTypes.number.isRequired,
  totalBlocks: PropTypes.number.isRequired,
  onReorder: PropTypes.func.isRequired,
  quizOnLab: PropTypes.object.isRequired,
};

export default BlockTakeQuiz;
