import { TaskQueue } from 'cwait';
import axios from './axios';
import { postBeacon } from '../helpers/beaconHelper';

// allow only one Stream call at a time
const streamQueue = new TaskQueue(Promise, 1);

export default class Stream {
  static stream(params) {
    return streamQueue.wrap(async () => {
      const response = await axios.get('/labs/stream', { params });
      return response.data;
    })();
  }

  static async sendAlert(params) {
    const response = await axios.post('/labs/stream/send_alert', params);
    return response.data;
  }

  static async expire(params) {
    postBeacon('/labs/stream/expire', params);
  }

  static async sessionStarted() {
    postBeacon('/labs/stream/session_started', {});
  }

  static async updateAccess(url, editable, app) {
    const response = await axios.post('/labs/stream/update_access', { url, editable, app });
    return response.data;
  }
}
