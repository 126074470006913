import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Icons from './Icons';

export default class CircularProgress extends Component {
  render() {
    const { progress, showPercent, color, className, showStar } = this.props;
    if (!progress) {
      return '';
    }
    const roundedProgress = Math.round(100 * progress);

    return (
      <div className={className}>
        {showPercent && <span className="progress-label">{roundedProgress}%</span>}
        <div className={`progress-circle ${roundedProgress > 50 ? 'over50' : ''} p${roundedProgress}`}>
          {showStar && (
            <span className="star-icon">
              <Icons.Star />
            </span>
          )}
          <div className="left-clipper">
            <div className={classnames('first50-bar', `first50-bar-${color}`)}></div>
            <div className={classnames('value-bar', `value-bar-${color}`)}></div>
          </div>
        </div>
      </div>
    );
  }
}

CircularProgress.propTypes = {
  progress: PropTypes.number,
  showPercent: PropTypes.bool,
  color: PropTypes.string,
  className: PropTypes.string,
  showStar: PropTypes.bool,
};

CircularProgress.defaultProps = {
  color: 'yellow',
  className: 'circular-star-wrapper',
  showStar: true,
};
