import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { signIn } from '../../actions/authActions';

import Login from './Login';
import { selectAuth } from '../../selectors/authSelectors';

const mapStateToProps = state => ({
  auth: selectAuth(state),
});

export default connect(mapStateToProps, { signIn })(withRouter(Login));
