import React from 'react';
import PropTypes from 'prop-types';
import defaultImageBlack from '../../assets/fill-profile.svg';
import defaultImageWhite from '../../assets/default-profile-white.png';

export default function UserPicture(props) {
  const { user, onClick, defaultWhite, className } = props;
  const defaultImage = defaultWhite ? defaultImageWhite : defaultImageBlack;

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions
    <img
      src={(user && user.avatar_url) || defaultImage}
      className={className || 'user-pic'}
      alt={(user && user.name) || ''}
      onClick={onClick}
    />
  );
}
UserPicture.propTypes = {
  user: PropTypes.shape({
    avatar_url: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
  onClick: PropTypes.func,
  defaultWhite: PropTypes.bool,
  className: PropTypes.string,
};

UserPicture.defaultProps = {
  defaultWhite: false,
};
