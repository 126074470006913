import { map as _map, compact as _compact, each as _each } from 'lodash';
import createCachedSelector from 're-reselect';
import { selectComponentEntities } from './componentSelectors';
import { selectBootcampEntities } from './bootcampSelectors';
import { selectPathEntities } from './pathSelectors';
import { selectSectionEntities } from './sectionSelectors';
import Unit from '../api/unit';
import { selectEntities, select, selectAll, selectAllFilterBy } from './applicationSelectors';

export const selectUnitEntities = selectEntities('units');
export const selectUnits = selectAll(selectUnitEntities);
export const selectUnit = select(selectUnitEntities);
export const selectUnitFromComponent = (state, component) => selectUnit(state, component.unit_id);

const unitComponentIds = (state, unitId) => (selectUnit(state, unitId) || {}).component_ids;

export const selectUnitComponents = createCachedSelector(
  [unitComponentIds, selectComponentEntities],
  (componentIds, componentEntities) => {
    return _compact(_map(componentIds, componentId => componentEntities[componentId]));
  },
)((_state, unitId) => unitId);

export const selectProjectsForBootcamp = createCachedSelector(
  [
    selectBootcampEntities,
    selectPathEntities,
    selectSectionEntities,
    selectUnitEntities,
    (_state, bootcampId) => bootcampId,
  ],
  (bootcampEntities, pathEntities, sectionEntities, unitEntities, bootcampId) => {
    const bootcamp = bootcampEntities[bootcampId];
    if (!bootcamp) {
      return [];
    }
    const graded_units = [];

    const pathIds = (bootcamp || {}).path_ids;

    _each(pathIds, pathId => {
      const path = pathEntities[pathId];

      _each(path.section_ids, sectionId => {
        const section = sectionEntities[sectionId];

        // sometimes sections not populated in store yet
        if (!section) {
          return;
        }

        // eslint-disable-next-line max-nested-callbacks
        _each(section.unit_ids, unitId => {
          const unit = unitEntities[unitId];

          if (unit?.unit_type === Unit.PROJECT) {
            graded_units.push(unit);
          }
        });
      });
    });

    return graded_units;
  },
)((_state, bootcampId) => bootcampId);

export const selectProjectsAndQuizzesForBootcamp = createCachedSelector(
  [
    selectBootcampEntities,
    selectPathEntities,
    selectSectionEntities,
    selectUnitEntities,
    (_state, bootcampId) => bootcampId,
  ],
  (bootcampEntities, pathEntities, sectionEntities, unitEntities, bootcampId) => {
    const bootcamp = bootcampEntities[bootcampId];
    if (!bootcamp) {
      return [];
    }
    const graded_units = [];

    const pathIds = (bootcamp || {}).path_ids;

    _each(pathIds, pathId => {
      const path = pathEntities[pathId];

      _each(path.section_ids, sectionId => {
        const section = sectionEntities[sectionId];

        // sometimes sections not populated in store yet
        if (!section) {
          return;
        }

        // eslint-disable-next-line max-nested-callbacks
        _each(section.unit_ids, unitId => {
          const unit = unitEntities[unitId];

          if ((unit && unit.unit_type === Unit.PROJECT) || (unit && unit.unit_type === Unit.GRADED_QUIZ)) {
            graded_units.push(unit);
          }
        });
      });
    });

    return graded_units;
  },
)((_state, bootcampId) => bootcampId);

const selectUnitsByAssignmentId = selectAllFilterBy(selectUnits, 'assignment_id');
export const selectUnitByAssignmentId = (state, assignmentId) => {
  return selectUnitsByAssignmentId(state, assignmentId)[0];
};
