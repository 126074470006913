import React from 'react';
import ProjectDropZone from '../../Utils/ProjectDropZone';

const EditStepMysql = ({ app, step, onChange }) => {
  return (
    <>
      <ProjectDropZone
        onFileUploaded={url => onChange({ target: { name: 'starter_db_url', value: url } })}
        app={app}
        projectUrl={step.starter_db_url}
        messageId="tutorial.form.mysql.starterDb"
      />
    </>
  );
};

export default EditStepMysql;
