import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import useFetchLesson from '../../../../hooks/useFetchLesson';

export default function ComponentTile({ component, block, onSelect }) {
  const [isExpanded, setIsExpanded] = useState(false);
  const { parts } = useFetchLesson(component.external_id);

  const handleSelect = part => () => {
    onSelect(part);
  };

  console.log('sup');

  const renderParts = () => {
    const filteredParts = parts.filter(part => part.id !== block.part_id);

    return filteredParts.map((part, partIndex) => (
      <div key={part.id} className="unit-item">
        <div className="unit-item-left">
          <div className="unit-item__check-icon">&nbsp;</div>
          <div className="unit-title">
            <span>
              <FormattedMessage id="component.partIndex" values={{ index: partIndex + 1 }} /> - {part.title}
            </span>
            <button
              onClick={handleSelect(part)}
              type="button"
              className="button-standard button-standard--blue2 button-standard--short">
              <FormattedMessage id="common.select" />
            </button>
          </div>
        </div>
      </div>
    ));
  };

  const containerClass = 'units-group';

  return (
    <div className={containerClass} style={{ marginLeft: 20 }}>
      <div className={`${containerClass}-heading`}>
        <div className={`${containerClass}__heading`} tabIndex="0" onClick={() => setIsExpanded(!isExpanded)}>
          <div className={`${containerClass}-left`}>
            <h3 className={`${containerClass}-title ${containerClass}-title--accent`}>{component.title}</h3>
          </div>
        </div>
      </div>
      {isExpanded && renderParts()}
    </div>
  );
}

ComponentTile.propTypes = {
  component: PropTypes.object.isRequired,
  block: PropTypes.object.isRequired,
  onSelect: PropTypes.func.isRequired,
};
