import { map as _map, trim as _trim, compact as _compact, isEmpty as _isEmpty } from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getCompletedItems } from '../../../selectors/overallProgressSelector';
import useLesson from '../../../hooks/useLesson';

export default function LessonCompletionDescription({ currentProgress, isSectionComplete, isUnitComplete }) {
  const { bootcampId, sectionId, unitId } = useParams();
  const { parts } = useLesson(currentProgress.component.external_id);

  let desc = <FormattedMessage id="completionModal.description.youLearned" />;

  const args = { bootcampId };
  if (isSectionComplete) {
    args.sectionId = sectionId;
  } else if (isUnitComplete) {
    args.unitId = unitId;
  }

  let completedItems = useSelector(s => getCompletedItems(s, args));
  if (!isSectionComplete && !isUnitComplete) {
    completedItems = _compact(_map(parts, pt => _trim(pt.title)));
  }

  return (
    <div className="subject-congrats__description">
      {!_isEmpty(completedItems) && (
        <>
          <div className="subject-congrats__description-text">{desc}</div>
          <div className="subject-congrats__description-list">
            <ul className="subject-congrats__list">
              {completedItems.map(item => (
                <li key={item} className="subject-congrats__list-item">
                  {item}
                </li>
              ))}
            </ul>
          </div>
        </>
      )}
    </div>
  );
}

LessonCompletionDescription.propTypes = {
  currentProgress: PropTypes.object.isRequired,
  isSectionComplete: PropTypes.bool,
  isUnitComplete: PropTypes.bool,
};
