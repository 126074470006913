import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import Icons from '../../../Utils/Icons';
import ComponentTile from './ComponentTile';
import { selectUnitComponents } from '../../../../selectors/unitSelectors';

export default function UnitTile({ unit, block, onSelect }) {
  const [isExpanded, setIsExpanded] = useState(false);
  const components = useSelector(s => selectUnitComponents(s, unit.id));

  const toggleCollapse = () => setIsExpanded(!isExpanded);
  const getFilteredComponents = () => components.filter(comp => comp.external_id !== block.lesson_id);

  return (
    <div className="units-group" key={unit.id}>
      <div className="units-group-heading">
        <div className="units-group__heading" tabIndex="0" onClick={toggleCollapse}>
          <div className="units-group-left">
            <span className="units-group__icon">
              <Icons.Layers />
            </span>

            <div className="units-group-title units-group-title--accent">{unit.title}</div>
          </div>
        </div>
      </div>
      {isExpanded &&
        getFilteredComponents().map(comp => (
          <ComponentTile key={comp.id} component={comp} block={block} onSelect={onSelect} />
        ))}
    </div>
  );
}

UnitTile.propTypes = {
  unit: PropTypes.object.isRequired,
  block: PropTypes.object.isRequired,
  onSelect: PropTypes.func.isRequired,
};
