import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Errors from '../../Utils/Errors';
import Modal2 from '../../library/Modal2';
import CKEditor from '../../Utils/CKEditor';
import { toastError, toastSuccess } from '../../../utils/toastHelper';
import intl from '../../../utils/intl';
import CustomInput from '../../Utils/CustomInput';

export default class EditSectionModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      section: props.section,
      errors: [],
    };
  }

  handleChange = name => e => {
    const value = name === 'description' ? e : e.target.value;

    this.setState({
      section: {
        ...this.state.section,
        [name]: value,
      },
    });
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props
      .onSave(this.state.section, this.props.path.id)
      .then(() => {
        this.props.onHide();
        toastSuccess(<FormattedMessage id="path.notification.saveSection" />, { autoClose: 5000 });
      })
      .catch(err => {
        this.setState({ errors: err.value.errors });
        toastError(err.value.errors, { autoClose: 5000 });
      });
  };

  render() {
    const { section, errors } = this.state;

    return (
      <Modal2
        size="big"
        bodyClass=" "
        onHide={this.props.onHide}
        onOk={this.handleSubmit}
        className="modal-auto-grow"
        title={
          section.id ? <FormattedMessage id="section.heading.edit" /> : <FormattedMessage id="section.heading.create" />
        }>
        <Errors errors={errors} />

        <CustomInput
          name="title"
          onChange={this.handleChange('title')}
          value={section.title}
          label={intl.formatMessage({ id: 'section.form.title' })}
        />

        <div>
          <label className="label-modal" htmlFor="description">
            <FormattedMessage id="section.form.description" />
          </label>
          <CKEditor content={section.description} onChange={this.handleChange('description')} />
        </div>
      </Modal2>
    );
  }
}

EditSectionModal.propTypes = {
  path: PropTypes.object.isRequired,
  section: PropTypes.object.isRequired,
  onSave: PropTypes.func.isRequired,
};
