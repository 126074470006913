import React from 'react';
import { QuestionOutlined } from '@ant-design/icons';
import { toast } from 'react-toastify';
import { FormattedMessage } from 'react-intl';

import LinkUploadModal from '../../Utils/LinkUploadModal/LinkUploadModal';
// import Tutorial from '../../../api/tutorial';
import showModal from '../../../utils/showModal';
import SimpleModal from '../../library/SimpleModal/SimpleModal';
import intl from '../../../utils/intl';

export const showLinkUploadModal = submitMethod => {
  showModal(LinkUploadModal, {
    title: intl.formatMessage({ id: 'linkUploadModal.title' }),
    bodyMessage: intl.formatMessage({ id: 'linkUploadModal.bodyMessage' }),
    instructions: [
      intl.formatMessage({ id: 'linkUploadModal.instruction1' }),
      intl.formatMessage({ id: 'linkUploadModal.instruction2' }),
      intl.formatMessage({ id: 'linkUploadModal.instruction3' }),
    ],
    note: intl.formatMessage({ id: 'linkUploadModal.note' }),
    onSubmit: async (link, closeModal) => {
      showModal(SimpleModal, {
        icon: <QuestionOutlined />,
        title: intl.formatMessage({ id: 'project.submissionModal.title' }),
        body: intl.formatMessage({ id: 'project.submissionModal.body' }),
        okText: intl.formatMessage({ id: 'project.submissionModal.submitButton' }),
        linkPlaceHolder: intl.formatMessage({ id: 'linkUploadModal.inputPlaceholder' }),
        onOk: async () => {
          try {
            await submitMethod(link);

            closeModal();
          } catch (err) {
            toast(<FormattedMessage id="project.failedSubmission" />, {
              autoClose: 5000,
              type: toast.TYPE.ERROR,
            });
          }
        },
        showCancel: true,
      });
    },
  });
};
