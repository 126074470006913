import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

export default class Button extends Component {
  render() {
    const { messageId, color, ...rest } = this.props;

    return (
      <button type="button" {...rest} className={`button-standard button-standard--${color} button-standard--short`}>
        <FormattedMessage id={messageId} />
      </button>
    );
  }
}
Button.propTypes = {
  messageId: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  color: PropTypes.string,
};

Button.defaultProps = {
  color: 'grey',
};
