import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Space, Popover, Badge } from 'antd';
import { SlidersOutlined } from '@ant-design/icons';
import CustomCheckbox from '../Utils/CustomCheckbox';
import CertificateTitle from '../../api/certificateTitle';
import useLocalStorageState from '../../hooks/useLocalStorageState';

const STORAGE_KEY = 'evaluator_queue_filter_preferences';
const certificateTitles = CertificateTitle.all();

const badgeAttributesFor = (filterValues, disabled) => {
  const values = Object.values(filterValues);
  const hasTrueValues = values.includes(true);
  const hasFalseValues = values.includes(false);

  if (disabled) {
    return { dot: true, color: 'lightGray' };
  }

  if (hasFalseValues && hasTrueValues) {
    return { dot: true, color: 'blue' };
  } else {
    return { dot: false, color: null };
  }
};

const getInitialFilterValues = () => {
  let filterValues = {};

  certificateTitles.forEach(title => {
    if (title === 'Other' || title === 'Testing') {
      filterValues[title] = false;
    } else {
      filterValues[title] = true;
    }
  });
  return filterValues;
};

const CertificateTitleFilterControl = ({ onCertificateTitleFilterChange, disabled }) => {
  const [filterValues, setFilterValues] = useLocalStorageState(STORAGE_KEY, getInitialFilterValues());

  const setFilterValuesWithNotification = filterValues => {
    setFilterValues(filterValues);
    if (onCertificateTitleFilterChange) {
      onCertificateTitleFilterChange(filterValues);
    }
  };
  useEffect(() => {
    if (onCertificateTitleFilterChange) {
      onCertificateTitleFilterChange(filterValues);
    }
  }, []);

  const handleChange = name => e => {
    const { target } = e;
    let value;
    if (target.type === 'checkbox') {
      value = target.checked;
    } else {
      value = target.value;
    }
    setFilterValuesWithNotification({ ...filterValues, [name]: value });
  };

  const selectAllCheckboxes = () => {
    const filterValuesUpdate = { ...filterValues };
    Object.keys(filterValuesUpdate).forEach(key => (filterValuesUpdate[key] = true));
    setFilterValuesWithNotification(filterValuesUpdate);
  };
  const deselectAllCheckboxes = () => {
    const filterValuesUpdate = { ...filterValues };
    Object.keys(filterValuesUpdate).forEach(key => (filterValuesUpdate[key] = false));
    setFilterValuesWithNotification(filterValuesUpdate);
  };

  const content = (
    <div>
      {certificateTitles.map((title, index) => (
        <div key={index}>
          <Space direction="vertical">
            <CustomCheckbox name={title} onChange={handleChange(title)} checked={filterValues[title]} label={title} />
          </Space>
        </div>
      ))}
      <Space>&nbsp;</Space>
      <Row>
        <Col span={11}>
          <a style={{ float: 'left' }} role="link" onClick={selectAllCheckboxes}>
            Check All
          </a>
        </Col>
        <Col span={13}>
          <a style={{ float: 'right' }} role="link" onClick={deselectAllCheckboxes}>
            Uncheck All
          </a>
        </Col>
      </Row>
    </div>
  );
  return (
    <div>
      <Popover
        placement="bottom"
        title="Filter by Certificate Title"
        content={content}
        trigger={disabled ? 'none' : 'click'}>
        <span role="button" aria-label="certificate title filters">
          <Badge {...badgeAttributesFor(filterValues, disabled)}>
            <SlidersOutlined style={disabled ? { color: 'lightGray' } : {}} />
          </Badge>
        </span>
      </Popover>
    </div>
  );
};

CertificateTitleFilterControl.propTypes = {
  onCertificateTitleFilterChange: PropTypes.func,
  disabled: PropTypes.bool,
};

export default CertificateTitleFilterControl;
