import React, { Component } from 'react';
import Icons from '../../../Utils/Icons';
import Unit from '../../../../api/unit';
import CustomCheckbox from '../../../Utils/CustomCheckbox';

const { MODULE, PROJECT, GRADED_QUIZ } = Unit;

export default class UnitTile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isExpanded: false,
      expandCollapse: props.unit.unit_type === MODULE,
    };
  }

  toggleCollapse = () => {
    const { expandCollapse, isExpanded } = this.state;
    if (!expandCollapse) {
      return;
    }

    this.setState({
      isExpanded: !isExpanded,
    });
  };

  isSelected = itemId => {
    const { hiddenItems } = this.props;
    return hiddenItems.indexOf(itemId) === -1;
  };

  handleOnChange = e => {
    const { target } = e;
    const { name } = target;

    this.props.onChange(name, target.checked);
  };

  renderComponents = () => {
    const { components } = this.props;

    return components.map(component => {
      const itemId = `Component::${component.id}`;

      return (
        <div key={component.id} className="unit-item">
          <div className="unit-item-left">
            <div className="unit-item__check-icon">&nbsp;</div>
            <div className="unit-title">
              <CustomCheckbox
                checked={this.isSelected(itemId)}
                label={component.title}
                name={itemId}
                onChange={this.handleOnChange}
              />
            </div>
          </div>
        </div>
      );
    });
  };

  getIcon = unitType => {
    switch (unitType) {
      case PROJECT:
        return <Icons.Graded />;
      case GRADED_QUIZ:
        return <Icons.GradedQuiz />;
      default:
        return <Icons.Layers />;
    }
  };

  render() {
    const { unit } = this.props;
    const { expandCollapse, isExpanded } = this.state;

    const unitType = unit.unit_type;
    const itemId = `Unit::${unit.id}`;

    return (
      <div className="units-group" key={unit.id}>
        <div className="units-group-heading">
          <div className="units-group__heading" tabIndex="0" onClick={unitType === MODULE ? this.toggleCollapse : null}>
            <div className="units-group-left">
              <span className="units-group__icon">{this.getIcon(unitType)}</span>

              <div className="units-group-title units-group-title--accent">
                {unit.unit_type === PROJECT || unit.unit_type === GRADED_QUIZ ? (
                  <CustomCheckbox
                    checked={this.isSelected(itemId)}
                    label={unit.title}
                    name={itemId}
                    onChange={this.handleOnChange}
                  />
                ) : (
                  unit.title
                )}
              </div>
            </div>
          </div>
        </div>
        {expandCollapse && isExpanded && this.renderComponents()}
      </div>
    );
  }
}
