import axios from './axios';

export default class UnitProgress {
  static update(tenantId, unitProgressId, params) {
    return axios.put(`/paths/unit_progresses/${unitProgressId}`, params).then(response => response.data);
  }

  static allowResubmission(tenantId, unitProgressId) {
    return axios.put(`/paths/unit_progresses/${unitProgressId}/allow_resubmission`).then(response => response.data);
  }

  static start(tenantId, unitProgressId) {
    return axios.put(`/paths/unit_progresses/${unitProgressId}/start`).then(response => response.data);
  }

  static grade(tenantId, unitProgressId, params) {
    return axios.put(`/paths/unit_progresses/${unitProgressId}/grade`, params).then(response => response.data);
  }

  static complete(tenantId, unitProgressId) {
    return axios.put(`/paths/unit_progresses/${unitProgressId}/complete`).then(response => response.data);
  }

  static get(tenantId, unitProgressId) {
    return axios.get(`/paths/unit_progresses/${unitProgressId}`).then(response => response.data);
  }

  static all(tenantId, params) {
    return axios.get('/paths/unit_progresses', { params }).then(response => response.data);
  }
}
