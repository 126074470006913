import React from 'react';
import PropTypes from 'prop-types';
import Icons from '../Utils/Icons';
import { useSelector } from 'react-redux';
import { selectProgressForQuizId } from '../../selectors/quizProgressSelectors';
import { selectBootcampInstanceQuizzes } from '../../selectors/bootcampInstanceAssignmentSelectors';
import moment from 'moment';
import { Link } from 'react-router-dom';
import Tag from '../library/Tag';
import { FormattedMessage } from 'react-intl';

const StudentGradedQuizTile = ({ unit, index, section, beginningUrl, youAreHere }) => {
  const gradedQuizUrl = `${beginningUrl}/sections/${section.id}/graded_quizzes/${unit.id}`;

  const quizProgress = useSelector(state => selectProgressForQuizId(state, unit.quiz_id));

  const completed = !!quizProgress?.completed_at;
  const allBootcampInstanceQuizzes = useSelector(state => selectBootcampInstanceQuizzes(state));

  const bootcampInstanceQuiz = allBootcampInstanceQuizzes
    ? Object.values(allBootcampInstanceQuizzes).find(biq => biq.quiz_id === unit.quiz_id)
    : undefined;

  const dueDate = bootcampInstanceQuiz && bootcampInstanceQuiz.quiz_due_at;
  const suggestedMessage = 'SUGGESTED DUE DATE ';
  const formattedDueDate = moment(dueDate).format('MMM D').toUpperCase();
  const suggestedMessageColor = moment() < moment(dueDate) ? 'yellow' : 'red';
  const showTag = !completed && dueDate;

  const iconClasses = completed
    ? 'icon-element--fill-green-800 icon-element--background-green-200'
    : 'icon-element--fill-grey-600 icon-element--background-yellow-200';

  return (
    <Link
      className={`student-graded-tile ${completed ? 'complete' : ''}`}
      key={index}
      to={gradedQuizUrl}
      data-testid="graded-quiz-tile">
      <div className={`icon-element ${iconClasses} icon-element--size-standard`}>
        <Icons.LineEdit />
      </div>
      <div className="unit-title">
        {unit.title}
        <div className="unit-tags">
          {youAreHere && (
            <div className="you-are-here">
              <FormattedMessage id="common.youAreHere" />
            </div>
          )}
          {showTag && (
            <div className="suggested" data-testid={'tag-component'}>
              <Tag text={suggestedMessage + formattedDueDate} color={suggestedMessageColor} />
            </div>
          )}
        </div>
      </div>
      <div className="unit-status" data-testid="graded-quiz-score">
        {Number.isFinite(quizProgress?.score_percentage) ? Math.round(quizProgress.score_percentage) : '- -'}%
      </div>
    </Link>
  );
};

StudentGradedQuizTile.propTypes = {
  unit: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  section: PropTypes.object.isRequired,
  beginningUrl: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired,
};

export default StudentGradedQuizTile;
