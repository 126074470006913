import React, { useState } from 'react';
import { Button, Modal } from 'antd';
import PropTypes from 'prop-types';
import ProficiencyLevelGradeSelect from './ProficiencyLevelGradeSelect';
import RecommendedGrade from '../RulesetCriterionReport/RecommendedGrade';

const AutograderSummary = ({
  isModelAnswer = false,
  reportInformation,
  recommendedGrade,
  onGradeSelect,
  criterion,
}) => {
  const [visible, setVisible] = useState(false);
  const closeModal = () => {
    setVisible(false);
  };

  const openModal = () => setVisible(true);

  const handleGrade = e => {
    onGradeSelect(e);
    closeModal();
  };

  return (
    <div className="autograder-summary">
      <RecommendedGrade recommendedGrade={recommendedGrade} size="small" isModelAnswer={isModelAnswer} />
      <Button className="see-rubric-button" onClick={openModal} type="primary" size="large">
        See Rubric
      </Button>
      <Modal
        visible={visible}
        onOk={closeModal}
        onCancel={closeModal}
        footer={null}
        width="90%"
        className="autograder-summary-modal">
        <div className="modal-container">
          <ProficiencyLevelGradeSelect
            criterion={criterion}
            onGradeSelect={handleGrade}
            recommendedGrade={recommendedGrade}
          />
          <div className="ruleset-criterion-report-container">{reportInformation}</div>
        </div>
      </Modal>
    </div>
  );
};

AutograderSummary.propTypes = {
  isModelAnswer: PropTypes.bool,
  reportInformation: PropTypes.object.isRequired,
  recommendedGrade: PropTypes.string.isRequired,
  onGradeSelect: PropTypes.func.isRequired,
  criterion: PropTypes.object.isRequired,
};

export default AutograderSummary;
