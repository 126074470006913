import React from 'react';
import PropTypes from 'prop-types';
import ContentEditable from 'react-contenteditable';
import CKEditor from '../../Utils/CKEditor';
import StudentBlockHtmlShow from '../../Student/BlockHtmlShow';
import BlockEditOptionsContainer from './BlockEditOptionsContainer';
import BlockHeader from '../../Utils/BlockHeader';
import intl from '../../../utils/intl';
import useSaveBlock from '../../../hooks/useSaveBlock';
import BlockConflictModal from './BlockConflictModal/BlockConflictModal';
import ContentValidator from './ContentValidator';
import MultipleEditor from '../../Blocks/MultipleEditor';

export default function BlockHtmlShow(props) {
  const {
    block,
    isEditMode,
    children,
    blockHeaderMessageId,
    blockLabel,
    headingClass,
    index,
    totalBlocks,
    onReorder,
    blockHeaderIcon,
  } = props;

  const { blockState, handleConflictSave, conflict, contentRef, handleChange } = useSaveBlock(block);

  const renderEditMode = () => (
    <ContentValidator content={blockState.content}>
      <BlockHeader messageId={blockHeaderMessageId} icon={blockHeaderIcon} />
      <BlockEditOptionsContainer block={block} index={index} total={totalBlocks} onReorder={onReorder} />

      <div className="section html__title">
        <MultipleEditor blockId={blockState.id}>
          <ContentEditable
            html={blockState.title || ''}
            className={headingClass}
            tagName="h2"
            onChange={handleChange('title')}
          />
          <CKEditor
            defaultContent={intl.formatMessage({ id: 'block.defaultHtmlContent' })}
            inline
            content={blockState.content}
            onChange={handleChange('content')}
            ref={contentRef}
          />
        </MultipleEditor>

        {children}
      </div>

      {conflict.visible && (
        <BlockConflictModal
          remoteBlock={conflict.remote}
          currentBlock={conflict.current}
          onOk={handleConflictSave}
          onCancel={conflict.hide}
        />
      )}
    </ContentValidator>
  );

  const renderViewMode = () => (
    <StudentBlockHtmlShow
      blockLabel={blockLabel}
      blockHeaderMessageId={blockHeaderMessageId}
      headingClass={headingClass}
      block={blockState}>
      {children}
    </StudentBlockHtmlShow>
  );

  return isEditMode ? renderEditMode() : renderViewMode();
}

BlockHtmlShow.propTypes = {
  block: PropTypes.object.isRequired,
  isEditMode: PropTypes.bool,
  index: PropTypes.number,
  totalBlocks: PropTypes.number,
  onReorder: PropTypes.func,
  blockHeaderMessageId: PropTypes.string,
  blockHeaderIcon: PropTypes.element,
  blockLabel: PropTypes.any,
  children: PropTypes.any,
  headingClass: PropTypes.string,
};
