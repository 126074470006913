import { createSelector } from 'reselect';
import { _keyBy } from 'lodash';
import { selectEntities, selectAll, selectAllFilterBy, sortBy } from './applicationSelectors';
import { selectQuestionProgressesForQuiz } from './questionProgressSelectors';

export const selectQuestionEntities = selectEntities('questions');
const selectQuestions = selectAll(selectQuestionEntities);

export const selectQuestionsByQuizId = selectAllFilterBy(selectQuestions, 'quiz_id');
export const selectQuestionsByQuizIdSorted = sortBy(selectQuestionsByQuizId, 'seq_num');

export const selectNextQuestions = createSelector(
  [
    (state, quizId) => selectQuestionsByQuizIdSorted(state, quizId),
    (state, quizId) => selectQuestionProgressesForQuiz(state, quizId),
  ],
  (questions, questionProgresses) => {
    const quizAttemptSet = [];
    for (const key in questionProgresses) {
      const quizAttempt = questionProgresses[key]?.quiz_attempt_for_choices;
      if (quizAttempt) quizAttemptSet.push(quizAttempt);
    }

    const maxQuizAttempt = quizAttemptSet.length ? Math.max(...quizAttemptSet) : 1;

    const nextQuestions = questions.filter(question => {
      const questionProgress = questionProgresses[question.id];
      // check user_score to support unanswered questions
      return questionProgress?.quiz_attempt_for_choices === maxQuizAttempt || !questionProgress?.user_score;
    });
    return nextQuestions;
  },
);
