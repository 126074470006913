import { connect } from 'react-redux';
import LtiSectionTile from './LtiSectionTile';
import { selectSectionUnits } from '../../selectors/sectionSelectors';
import Unit from '../../api/unit';

const mapStateToProps = (state, ownProps) => {
  const { section } = ownProps;
  const units = selectSectionUnits(state, section.id);
  const assignments = units && units.filter(unit => unit.unit_type === Unit.PROJECT);

  return {
    assignments,
  };
};

export default connect(mapStateToProps)(LtiSectionTile);
