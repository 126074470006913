import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Layout } from 'antd';
import { useHistory, useLocation } from 'react-router-dom';
import StudentsList from './StudentsList/StudentsList';
import useCohortStudents from './hooks/useCohortStudents';
import StudentContent from './StudentContent';
import { getCohortDashboardPath } from '../../../../helpers/pathHelper';

const StudentsPage = ({ cohortId, cohortReportStudents }) => {
  const history = useHistory();
  const location = useLocation();

  const getInitialStudentId = () => {
    const queryParams = new URLSearchParams(location.search);
    return queryParams.get('studentId');
  };
  const [selectedStudentId, setSelectedStudentId] = useState(getInitialStudentId);

  const { students } = useCohortStudents(cohortId);
  const selectedStudent = students.find(student => student.id === selectedStudentId);
  const cohortReportStudent = cohortReportStudents.find(student => student.user_id === selectedStudentId);

  const handleStudentSelect = studentId => {
    setSelectedStudentId(studentId);
    history.replace(getCohortDashboardPath(cohortId, { tab: 'students', studentId }));
  };

  return (
    <Layout>
      <StudentsList students={students} selectedStudentId={selectedStudentId} onStudentSelect={handleStudentSelect} />
      {selectedStudent && (
        <StudentContent student={selectedStudent} cohortId={cohortId} cohortReportStudent={cohortReportStudent} />
      )}
    </Layout>
  );
};

export default StudentsPage;

StudentsPage.propTypes = {
  cohortId: PropTypes.string.isRequired,
  cohortReportStudents: PropTypes.array.isRequired,
};
