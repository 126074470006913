import React from 'react';
import PropTypes from 'prop-types';
import { Element } from 'react-scroll';
import classnames from 'classnames';
import useElementScrolling from '../../hooks/useElementScrolling';
import BlockGradedQuizShow from './Blocks/BlockGradedQuizShow';
import BlockQuizShow from './Blocks/BlockQuizShow';
import BlockSingleQuestionQuizShow from './Blocks/BlockSingleQuestionQuizShow';
import BlockTakeQuiz from './Blocks/BlockTakeQuiz';
import BlockHtmlShow from './Blocks/BlockHtmlShow';
import BlockCollapsibleHtml from './Blocks/BlockCollapsibleHtml';
import CommentThreadContainer from '../Blocks/CommentThread/CommentThreadContainer';
import BlockVideoShow from './Blocks/BlockVideoShow';
import BlockEmbedShow from './Blocks/BlockEmbedShow';
import BlockLabShowContainer from './Blocks/BlockLabShowContainer';
import BlockSqlShow from './Blocks/BlockSqlShow';
import BlockAsanaAccount from '../Blocks/AsanaAccount/AsanaAccount';
import Block from '../../api/block';
import { Typeform as BlockTypeform } from '../Blocks/Typeform/Typeform';

const NoOp = () => '';

const BlockContainer = ({
  block,
  isEditMode,
  index,
  totalBlocks,
  onReorder,
  blockLabel,
  bootcampId,
  quizOnLab,
  showBlockTakeQuiz,
}) => {
  const blockName = `block_${block.id}`;
  useElementScrolling(blockName, { offset: -150 });

  const getPreparedQuizOnLab = () => ({ ...quizOnLab, show: () => quizOnLab.show(block.id) });

  // eslint-disable-next-line complexity
  const getBlockCompFromBlock = () => {
    switch (block.block_type) {
      case Block.HTML:
        return BlockHtmlShow;
      case Block.COLLAPSIBLE_HTML:
        return BlockCollapsibleHtml;
      case Block.COMMENT_THREAD:
        return CommentThreadContainer;
      case Block.QUIZ:
        return showBlockTakeQuiz ? BlockTakeQuiz : BlockQuizShow;
      case Block.SINGLE_QUESTION_QUIZ:
        return BlockSingleQuestionQuizShow;
      case Block.GRADED_QUIZ:
        return BlockGradedQuizShow;
      case Block.VIDEO:
        return BlockVideoShow;
      case Block.LAB:
        return BlockLabShowContainer;
      case Block.EMBED:
        return BlockEmbedShow;
      case Block.SQL:
        return BlockSqlShow;
      case Block.TYPEFORM:
        return BlockTypeform;
      case Block.ASANA_ACCOUNT:
        return BlockAsanaAccount;
      case 'next_tech':
        return NoOp;
      default:
        throw new Error(`unknown block_type ${block.block_type}`);
    }
  };

  const BlockComp = getBlockCompFromBlock();

  const classes = classnames('block buttons-lessons', isEditMode && 'block--edit-mode', block.block_type);

  return (
    <Element className={classes} name={blockName}>
      <BlockComp
        key={block.id}
        block={block}
        isEditMode={isEditMode}
        index={index}
        totalBlocks={totalBlocks}
        onReorder={onReorder}
        quizOnLab={getPreparedQuizOnLab()}
        blockLabel={blockLabel}
        bootcampId={bootcampId}
      />
      <div className="author-adaptive-tracks">
        {block.adaptive_tracks &&
          block.adaptive_tracks.map(track => {
            return <p className="author-individual-track">{track}</p>;
          })}
      </div>
    </Element>
  );
};

BlockContainer.propTypes = {
  block: PropTypes.object.isRequired,
  isEditMode: PropTypes.bool.isRequired,
  showBlockTakeQuiz: PropTypes.bool,
  index: PropTypes.number.isRequired,
  totalBlocks: PropTypes.number.isRequired,
  onReorder: PropTypes.func.isRequired,
  quizOnLab: PropTypes.object,
  blockLabel: PropTypes.any,
  bootcampId: PropTypes.string,
};

BlockContainer.defaultProps = {
  showBlockTakeQuiz: false,
};

export default BlockContainer;
