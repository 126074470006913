import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { faEllipsisH } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Popover } from 'antd';
import AssignmentCardActionsContent from './AssignmentCardActionsContent';

const AssignmentCardActions = ({ assignment, assignmentProgress }) => {
  const [visible, setVisible] = useState(false);

  const handleVisibleChange = () => {
    setVisible(!visible);
  };

  return (
    <Popover
      placement="bottomRight"
      content={
        <AssignmentCardActionsContent
          assignment={assignment}
          assignmentProgress={assignmentProgress}
          hideActions={() => setVisible(false)}
        />
      }
      trigger="click"
      visible={visible}
      onVisibleChange={handleVisibleChange}>
      <span onClick={handleVisibleChange} data-testid="actions-button">
        <FontAwesomeIcon icon={faEllipsisH} />
      </span>
    </Popover>
  );
};

export default AssignmentCardActions;

AssignmentCardActions.propTypes = {
  assignment: PropTypes.object.isRequired,
  assignmentProgress: PropTypes.object,
};
