export const SIDE_NAV = {
  TOGGLE: 'SIDENAV:TOGGLE',
  CLOSE: 'SIDENAV:CLOSE',
};

export const AUTH = {
  LOGGING_IN: 'AUTH:LOGGING_IN',
  LOGGED_IN: 'AUTH:LOGGED_IN',
  LOGOUT: 'AUTH:LOGOUT',
  SET_USER: 'AUTH:SET_USER',
};

export const TENANT = {
  SET_CURRENT: 'TENANT:SET_CURRENT',
};

export const BOOTCAMP = {
  SET_SEARCH: 'BOOTCAMP:SET_SEARCH',
};

export const ENTITIES = {
  UPDATE: 'ENTITIES:UPDATE',
};

export const CONTEXT = {
  ENABLE_EDIT_MODE: 'CONTEXT:ENABLE_EDIT_MODE',
};

export const COMPONENT_GRADE = {
  SET_CURRENT_COHORT_ID: 'COMPONENT_GRADE:SET_CURRENT_COHORT_ID',
  SET_CURRENT_STUDENT_ID: 'COMPONENT_GRADE:SET_CURRENT_STUDENT_ID',
};

export const COMPONENT_ROSTER = {
  SET_CURRENT_COHORT_ID: 'COMPONENT_ROSTER:SET_CURRENT_COHORT_ID',
};

export const COMPONENT_LESSON = {
  SET_PROGRESS_EXPANDED: 'COMPONENT_LESSON:SET_PROGRESS_EXPANDED',
};

export const COMPONENT_COACH = {
  COACH_COHORT_IDS: 'COACH_COHORT_IDS',
};

export const LAB_PANEL = {
  SET_SIZE: 'LAB_PANEL:SET_SIZE',
};

export const REQUEST = {
  START: 'REQUEST:START',
  END: 'REQUEST:END',
};
