import React, { Component } from 'react';
import PropTypes from 'prop-types';
import BootcampSelector from '../Bootcamp/BootcampSelector';
import CohortSelectorContainer from './CohortSelectorContainer';
import { isPrintMode } from '../../helpers/printHelper';

class BootcampCohortSelector extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedBootcamp: this.props.selectedBootcamp,
      selectedCohort: this.props.selectedCohort,
    };
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.selectedBootcamp && this.state.selectedBootcamp !== nextProps.selectedBootcamp) {
      this.setState({ selectedBootcamp: nextProps.selectedBootcamp });
    }

    if (this.state.selectedCohort !== nextProps.selectedCohort) {
      this.setState({ selectedCohort: nextProps.selectedCohort });
    }
  }

  handleBootcampSelect = bootcamp => {
    this.setState({ selectedBootcamp: bootcamp, selectedCohort: null }, this.triggerCohortSelect);
  };

  handleCohortSelect = cohort => {
    this.setState({ selectedCohort: cohort }, this.triggerCohortSelect);
  };

  triggerCohortSelect = () => {
    const { onCohortChange } = this.props;
    const { selectedCohort, selectedBootcamp } = this.state;
    // eslint-disable-next-line no-unused-expressions
    onCohortChange && onCohortChange(selectedCohort, selectedBootcamp);
  };

  render() {
    const { selectedBootcamp, selectedCohort } = this.state;
    const { bootcamps } = this.props;

    return (
      <>
        <div className={isPrintMode() ? 'sm-6' : 'sm-4'}>
          <BootcampSelector bootcamps={bootcamps} selected={selectedBootcamp} onSelect={this.handleBootcampSelect} />
        </div>
        <div className={isPrintMode() ? 'sm-6' : 'sm-4'}>
          <CohortSelectorContainer
            selected={selectedCohort}
            bootcamp={selectedBootcamp}
            onSelect={this.handleCohortSelect}
          />
        </div>
      </>
    );
  }
}

BootcampCohortSelector.propTypes = {
  selectedBootcamp: PropTypes.object,
  selectedCohort: PropTypes.object,
  onCohortChange: PropTypes.func,
  bootcamps: PropTypes.array,
};

export default BootcampCohortSelector;
