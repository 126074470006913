import PropTypes from 'prop-types';
import { clone as _clone } from 'lodash';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import CohortSetupFields from './partial/CohortSetupFields';
import CustomSelect from '../../Utils/CustomSelect';
import intl from '../../../utils/intl';
import CustomInput from '../../Utils/CustomInput';
import CustomDatePicker from '../../Utils/CustomDatePicker';
import CustomCheckbox from '../../Utils/CustomCheckbox';
import CustomTextarea from '../../Utils/CustomTextarea';

const CohortForm = ({
  cohort,
  stateCohort,
  handleSelectChange,
  getBootcampOptions,
  handleChange,
  handleDateChange,
  schoolOptions,
  showIsSponsored,
  ltiConsumerKey,
  cohortLookups,
}) => {
  let termLength;
  if (stateCohort.start_date && stateCohort.end_date) {
    termLength = moment(stateCohort.end_date).diff(moment(stateCohort.start_date), 'week');
  }

  const [lookupOptions] = useState(_clone(cohortLookups));

  const isDisplaySchoolLogoDisabled =
    !stateCohort?.school_id || !schoolOptions.find(school => school.id === stateCohort.school_id)?.logo;

  return (
    <>
      <CohortSetupFields cohort={cohort} />

      <CustomSelect
        className="Select--simple"
        name="bootcamp_id"
        value={stateCohort.bootcamp_id}
        onChange={handleSelectChange('bootcamp_id')}
        options={getBootcampOptions()}
        disabled={!!stateCohort.id}
        clearable={false}
        placeholder={intl.formatMessage({ id: 'common.selectBootcamp' })}
      />

      <CustomInput
        name="location"
        onChange={handleChange}
        value={stateCohort.location}
        label={intl.formatMessage({ id: 'cohort.form.location' })}
      />

      <div className="fields-wrapper">
        <CustomDatePicker
          label={intl.formatMessage({ id: 'cohort.form.startDate' })}
          name="start_date"
          selected={stateCohort.start_date}
          onChange={handleDateChange('start_date')}
          isClearable
          selectsStart
          startDate={stateCohort.start_date}
          endDate={stateCohort.end_date}
          maxDate={stateCohort.end_date}
        />
        <CustomDatePicker
          label={intl.formatMessage({ id: 'cohort.form.endDate' })}
          name="end_date"
          selected={stateCohort.end_date}
          onChange={handleDateChange('end_date')}
          isClearable
          selectsEnd
          startDate={stateCohort.start_date}
          endDate={stateCohort.end_date}
          minDate={stateCohort.start_date}
        />
      </div>

      {termLength !== undefined && (
        <div className="label-term-length">
          <FormattedMessage id="cohort.form.termLengthIs" /> &nbsp; {termLength} &nbsp;{' '}
          <FormattedMessage id="cohort.weeks" />
        </div>
      )}

      <CustomInput
        name="cohort_title"
        onChange={handleChange}
        value={stateCohort.cohort_title}
        label={intl.formatMessage({ id: 'cohort.form.cohortTitle' })}
      />

      <CustomCheckbox
        name="display_cohort_title"
        onChange={handleChange}
        checked={!!stateCohort.display_cohort_title}
        label={<FormattedMessage id="cohort.form.displayCohortTitle" />}
      />

      <CustomInput
        name="enrollment_sku"
        onChange={handleChange}
        value={stateCohort.enrollment_sku}
        label={intl.formatMessage({ id: 'cohort.form.enrollmentSKU' })}
      />

      <CustomInput
        name="email_instructor_name"
        onChange={handleChange}
        value={stateCohort.email_instructor_name}
        label={intl.formatMessage({ id: 'cohort.form.emailInstructorName' })}
      />

      <CustomInput
        name="instructor_email"
        onChange={handleChange}
        value={stateCohort.instructor_email}
        label={intl.formatMessage({ id: 'cohort.form.instructorEmail' })}
      />

      <span className="label-modal">
        <FormattedMessage id="cohort.moreDetails" />
      </span>

      <CustomSelect
        id="school-select"
        className="Select--simple"
        name="school_id"
        value={stateCohort.school_id}
        onChange={handleSelectChange('school_id')}
        options={schoolOptions}
        clearable={false}
        placeholder={intl.formatMessage({ id: 'cohort.form.school' })}
      />

      <CustomInput
        name="lti_consumer_key"
        disabled
        value={ltiConsumerKey}
        label={intl.formatMessage({ id: 'cohort.form.ltiConsumerKey' })}
      />
      <CustomTextarea
        name="lti_context"
        onChange={handleChange}
        value={stateCohort.lti_context || ''}
        label={intl.formatMessage({ id: 'cohort.form.ltiContext' })}
      />
      <CustomSelect
        className="Select--simple"
        name="partner_type_id"
        value={stateCohort.partner_type_id}
        onChange={handleSelectChange('partner_type_id')}
        options={lookupOptions.partner_types}
        clearable
        placeholder={intl.formatMessage({ id: 'cohort.form.partnerType' })}
        creatable
      />
      {showIsSponsored && (
        <CustomCheckbox
          id="is_sponsored"
          name="is_sponsored"
          onChange={handleChange}
          checked={!!stateCohort.is_sponsored}
          label={<FormattedMessage id="cohort.form.isSponsored" />}
        />
      )}
      <CustomSelect
        className="Select--simple"
        name="modality_id"
        value={stateCohort.modality_id}
        onChange={handleSelectChange('modality_id')}
        options={lookupOptions.modalities}
        clearable
        placeholder={intl.formatMessage({ id: 'cohort.form.modality' })}
        creatable
      />

      <CustomSelect
        className="Select--simple"
        name="credit_type_id"
        value={stateCohort.credit_type_id}
        onChange={handleSelectChange('credit_type_id')}
        options={lookupOptions.credit_types}
        clearable
        placeholder={intl.formatMessage({ id: 'cohort.form.creditType' })}
        creatable
      />

      <CustomSelect
        className="Select--simple"
        name="ps_semester_id"
        value={stateCohort.ps_semester_id}
        onChange={handleSelectChange('ps_semester_id')}
        options={lookupOptions.ps_semesters}
        clearable
        placeholder={intl.formatMessage({ id: 'cohort.form.psSemester' })}
        creatable
      />

      <CustomCheckbox
        id="display_school_logo"
        name="display_school_logo"
        onChange={handleChange}
        disabled={isDisplaySchoolLogoDisabled}
        checked={!!stateCohort.display_school_logo}
        label={<FormattedMessage id="cohort.form.displaySchoolLogo" />}
      />

      <CustomCheckbox
        id="is_canceled"
        name="is_canceled"
        onChange={handleChange}
        checked={!!stateCohort.is_canceled}
        label={<FormattedMessage id="cohort.form.isCanceled" />}
      />

      <CustomCheckbox
        id="is_pathstream_direct"
        name="is_pathstream_direct"
        onChange={handleChange}
        role="checkbox"
        checked={!!stateCohort.is_pathstream_direct}
        label={<FormattedMessage id="cohort.form.isPathstreamDirect" />}
      />

      <CustomCheckbox
        id="is_autograder_enabled"
        name="is_autograder_enabled"
        onChange={handleChange}
        role="checkbox"
        checked={!!stateCohort.is_autograder_enabled}
        label={<FormattedMessage id="cohort.form.isAutograderEnabled" />}
      />
    </>
  );
};

export default CohortForm;

CohortForm.propTypes = {
  cohort: PropTypes.object.isRequired,
  getBootcampOptions: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleDateChange: PropTypes.func.isRequired,
  handleSelectChange: PropTypes.func.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  ltiConsumerKey: PropTypes.string.isRequired,
  schoolOptions: PropTypes.array.isRequired,
  showIsSponsored: PropTypes.bool.isRequired,
  stateCohort: PropTypes.object.isRequired,
  cohortLookups: PropTypes.object.isRequired,
};
