import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import { FormattedMessage } from 'react-intl';
import { LeftOutlined, RightOutlined, DownloadOutlined } from '@ant-design/icons';

import { supportedFile } from '../../../utils/utils';

const FileUploadView = ({ files }) => {
  const [currentFile, setFile] = useState();

  useEffect(() => {
    if (files) setFile(files[0]);
  }, [files]);

  if (!currentFile) return '';

  const { name, url } = currentFile;
  const currentIndex = files.findIndex(file => file.url === url);
  const backIndex = currentIndex - 1;
  const nextIndex = currentIndex + 1;
  const disableBack = backIndex < 0;
  const disableNext = nextIndex >= files.length;

  const handleFileChange = index => {
    const changedFile = files[index];
    if (changedFile) setFile(changedFile);
  };

  const renderDownloadButton = () => (
    <Button className="file-download-btn" type="primary" role="button" data-testid="download-button">
      <a href={url} download={name}>
        <DownloadOutlined />
        <FormattedMessage id="common.download" />
      </a>
    </Button>
  );

  const renderFilePreview = fileType => (
    <div className="file-upload-image-inner" data-testid="file-image">
      <div className="file-name-btn">{name}</div>
      {fileType === 'pdf' ? (
        <>
          {renderDownloadButton()}
          <embed className="file-pdf" type="application/pdf" src={url} />
        </>
      ) : (
        <img className="file-image" src={url} alt={name} />
      )}
    </div>
  );

  const renderDownloadableFile = () => (
    <div className="file-upload-no-image-inner">
      <span className="file-name-btn">{name}</span>
      {renderDownloadButton()}
    </div>
  );

  const renderBtnLeft = () => (
    <Button
      className="btn-left"
      shape="circle"
      size="large"
      disabled={disableBack}
      onClick={() => handleFileChange(backIndex)}
      role="button"
      data-testid="left-button">
      <LeftOutlined />
    </Button>
  );

  const renderBtnRight = () => (
    <Button
      className="btn-right"
      shape="circle"
      size="large"
      disabled={disableNext}
      onClick={() => handleFileChange(nextIndex)}
      role="button"
      data-testid="right-button">
      <RightOutlined />
    </Button>
  );

  const { canPreviewFile, fileType } = supportedFile(url);
  return (
    <div className="file-upload-container">
      {canPreviewFile ? renderFilePreview(fileType) : renderDownloadableFile()}
      {!disableBack && renderBtnLeft()}
      {!disableNext && renderBtnRight()}
    </div>
  );
};

FileUploadView.propTypes = {
  files: PropTypes.array.isRequired,
};

export default FileUploadView;
