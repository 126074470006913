import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Controlled as CodeMirror } from 'react-codemirror2';
import 'codemirror/mode/sql/sql';
import 'codemirror/lib/codemirror.css';

export default function CodeEditor({ value, onChange, mode, options, ...rest }) {
  const [stateValue, setStateValue] = useState(value || '');

  useEffect(() => {
    if (stateValue !== value) {
      setStateValue(value);
    }
  }, [value]);

  const handleBeforeChange = (_editor, _data, newValue) => {
    setStateValue(newValue);
  };

  const handleChange = (_editor, _data, newValue) => {
    if (onChange) onChange(newValue);
  };

  // eslint-disable-next-line no-param-reassign
  options = {
    lineNumbers: true,
    mode,
    extraKeys: { Tab: false },
    ...(options || {}),
  };
  return (
    <CodeMirror
      {...rest}
      options={options}
      value={stateValue}
      onBeforeChange={handleBeforeChange}
      onChange={handleChange}
    />
  );
}

CodeEditor.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  mode: PropTypes.string,
  options: PropTypes.object,
};
