import axios from './axios';

export default class LtiLaunchRequest {
  static async get(_, { unit, tutorial }) {
    const url = unit
      ? `/paths/units/${unit.id}/lti_launch_request`
      : `/content/tutorials/${tutorial.id}/lti_launch_request`;
    const response = await axios.get(url);
    return response.data;
  }
}
