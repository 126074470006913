import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

const RubricCard = ({ proficiencyLevel, proficiencyLevelDescription }) => (
  <div className="grade-card">
    <div className="grade-content">
      <div className="grade-letter">
        <FormattedMessage id={`rubricCriteria.fields.${proficiencyLevel}`} />
      </div>
      <div className="hor-line-break" />
      <div className="grade-letter-detail">{proficiencyLevelDescription}</div>
    </div>
  </div>
);

RubricCard.propTypes = {
  proficiencyLevel: PropTypes.string,
  proficiencyLevelDescription: PropTypes.string,
};

export default RubricCard;
