import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import RulesetCriterionReport from '../RulesetCriterionReport/RulesetCriterionReport';
import { calculateGrade } from '../RulesetCriterionReport/rulesetCriterionReportHelper';
import Loading from '../../../components/Utils/Loading';
import AutograderSummary from './AutograderSummary';

const ModelAnswer = ({ image, description }) => (
  <div className="model-answer">
    <div className="model-answer-header">
      <FormattedMessage id="autograder.modelAnswer.title" />
    </div>
    <img className="file-image" src={image?.url} alt={image?.name} />
    <div className="model-answer-description">{description}</div>
  </div>
);

const AutograderSummaryContainer = ({ autograderRulesetCriterionResult, onGradeSelect, criterion }) => {
  const [recommendedGrade, reportInformation] = useMemo(() => {
    let recommendedGrade;
    let reportInformation;
    if (!Object.keys(autograderRulesetCriterionResult).length || autograderRulesetCriterionResult.isUnmappedRuleset) {
      return [];
    }
    if (autograderRulesetCriterionResult.isModelAnswer) {
      const { modelAnswerImage, modelAnswerDescription } = autograderRulesetCriterionResult;
      recommendedGrade = '?';
      reportInformation = <ModelAnswer description={modelAnswerDescription} image={modelAnswerImage} />;
    } else {
      const {
        result: { reportData, nonLinearGradingRanges },
      } = autograderRulesetCriterionResult;
      recommendedGrade = calculateGrade(reportData, nonLinearGradingRanges);
      reportInformation = <RulesetCriterionReport reportData={reportData} recommendedGrade={recommendedGrade} />;
    }
    return [recommendedGrade, reportInformation];
  }, [autograderRulesetCriterionResult]);

  if (autograderRulesetCriterionResult.isUnmappedRuleset) {
    return <FormattedMessage id="autograder.grading.rulesetWasntMapped" />;
  }

  if (!recommendedGrade || !reportInformation) {
    return <Loading />;
  }

  return (
    <AutograderSummary
      reportInformation={reportInformation}
      recommendedGrade={recommendedGrade}
      onGradeSelect={onGradeSelect}
      criterion={criterion}
      isModelAnswer={autograderRulesetCriterionResult.isModelAnswer}
    />
  );
};

AutograderSummaryContainer.propTypes = {
  autograderRulesetCriterionResult: PropTypes.object,
  criterion: PropTypes.object,
  onGradeSelect: PropTypes.func,
};

export default AutograderSummaryContainer;
