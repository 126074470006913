import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import SectionTile from './SectionTile';
import StudentSectionTile from './StudentSectionTile';
import { selectProgressForSection } from '../../selectors/sectionProgressSelectors';
import { canLearn } from '../../helpers/userAuth';
import { selectCurrentUser } from '../../selectors/authSelectors';
import { reorderUnits } from '../../actions/unitActions';
import { destroyPathSection } from '../../actions/pathSectionActions';
import { selectEntitiesForBootcamp } from '../../selectors/cohortBootcampSelectors';

const mapStateToProps = (state, ownProps) => {
  const { section, bootcamp } = ownProps;
  const { id: sectionId } = section;
  const currentUser = selectCurrentUser(state);
  const { enrollment, unitsBySection } = selectEntitiesForBootcamp(state, bootcamp.id);
  const units = (unitsBySection && unitsBySection[sectionId]) || [];

  const sectionProgress = selectProgressForSection(state, sectionId);
  const isStudent = canLearn(currentUser);
  const isLtiStudent = enrollment?.requires_lti_support;
  const currentUnitId = enrollment?.next_course_step?.unit_id;

  return {
    sectionProgress,
    units,
    isStudent,
    isLtiStudent,
    currentUnitId,
  };
};

const SectionTileContainer = ({ pathstream2, ...props }) => {
  if (pathstream2) {
    return <StudentSectionTile {...props} />;
  } else {
    return <SectionTile {...props} />;
  }
};

export default connect(mapStateToProps, { reorderUnits, destroyPathSection })(withRouter(SectionTileContainer));
