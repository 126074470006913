import React from 'react';
import PropTypes from 'prop-types';
import LabLoadingModal from './LabLoadingModal/LabLoadingModal';
import useLabContext from '../../context/Lab/useLabContext';
import { LabErrorModal, getMachineHiccupModalProps } from './LabErrorModal/LabErrorModal';
import Tutorial from '../../api/tutorial';
import intl from '../../utils/intl';
import { LtiIFrame } from '../Lti/LtiIframe';
import { LTIDataState, useLTIData } from '../Lti/useLTIData';

export const CodioLab = ({ unit, tutorial }) => {
  const { setIsReady: handleReady } = useLabContext();
  const { launch, status, reload } = useLTIData({ unit, tutorial });

  if (status === LTIDataState.ERROR) {
    const { backgroundImage } = getMachineHiccupModalProps();
    const errorMessage = intl.formatMessage({ id: 'block.codio.error' });

    return <LabErrorModal onLeftButton={reload} title={errorMessage} backgroundImage={backgroundImage} />;
  }

  return (
    <>
      <LabLoadingModal app={Tutorial.CODIO} />
      {status === LTIDataState.LOADED && <LtiIFrame {...{ launch, handleReady }} />}
    </>
  );
};

CodioLab.propTypes = {
  unit: PropTypes.object,
  tutorial: PropTypes.object,
};
