import React from 'react';
import PropTypes from 'prop-types';
import StudentGradedQuizTile from '../Units/StudentGradedQuizTile';
import StudentProjectTile from '../Units/StudentProjectTile';
import ModuleTileContainer from '../Units/ModuleTileContainer';

const StudentSectionTile = ({
  index,
  section,
  units,
  match,
  history,
  bootcamp,
  cohortId,
  isLtiStudent,
  currentUnitId,
}) => {
  const displayIndex = index + 1;

  return (
    <div className="student-section-tile">
      <div className="student-section-header" data-testid={`section-row-${displayIndex}`}>
        <div className="header-index">
          <div className="header-index-number">{displayIndex}</div>
        </div>
        <div className="header-title">{section.title}</div>
      </div>
      <div className="units-container" data-testid={`section-units-${displayIndex}`}>
        {units.map((unit, index) => {
          switch (unit.unit_type) {
            case 'graded_quiz':
              return (
                <StudentGradedQuizTile
                  unit={unit}
                  index={index}
                  section={section}
                  key={index}
                  beginningUrl={match.url}
                  history={history}
                  youAreHere={unit.id === currentUnitId}
                  cohortId={cohortId}
                />
              );
            case 'project':
              return (
                <StudentProjectTile
                  unit={unit}
                  index={index}
                  section={section}
                  key={index}
                  beginningUrl={match.url}
                  history={history}
                  isLtiStudent={isLtiStudent}
                  youAreHere={unit.id === currentUnitId}
                  cohortId={cohortId}
                />
              );
            case 'module':
              return (
                <ModuleTileContainer
                  bootcamp={bootcamp}
                  unit={unit}
                  index={index}
                  section={section}
                  key={index}
                  beginningUrl={match.url}
                  pathstream2={true}
                  youAreHere={unit.id === currentUnitId}
                  cohortId={cohortId}
                />
              );
            default:
              return <div>Unknown unit type</div>;
          }
        })}
      </div>
    </div>
  );
};

StudentSectionTile.propTypes = {
  index: PropTypes.number.isRequired,
  section: PropTypes.object.isRequired,
  units: PropTypes.array.isRequired,
  bootcamp: PropTypes.object.isRequired,
  currentUnitId: PropTypes.string,
};

export default StudentSectionTile;
