/* global Raven */

import gitInfo from '../gitInfo';

const getEnv = () => {
  let env;
  const { hostname } = window.location;
  if (hostname.indexOf('qa') === 0) {
    env = 'qa';
  } else if (hostname.indexOf('uat') === 0) {
    env = 'uat';
  } else if (hostname.endsWith('pathstream.com')) {
    env = 'production';
  } else {
    env = 'dev';
  }

  // eslint-disable-next-line no-console
  console.log('Using env', env);
  return env;
};

if (process.env.NODE_ENV === 'production') {
  // Sentry error tracking
  Raven.config('https://82cac5907bb3479ea8972ecad43bcf2f@sentry.io/1260375', {
    environment: getEnv(),
    release: gitInfo.hash,
    ignoreErrors: [
      // ignore errors from ckeditor
      "Cannot read property 'attributes' of null",
      "Unable to get property 'attributes' of undefined or null reference",
      "Cannot set property 'dir' of undefined",
      "Cannot set property 'previous' of null",
      "Cannot read property 'allow' of undefined",
      "Cannot read property 'editables' of undefined",
      "Cannot read property 'contains' of null",
      "Cannot read property 'getAscendant' of null",
      "Cannot read property 'type' of null",
      'null is not an object',
      "Cannot read property 'getData' of undefined",
      "Cannot read property 'checkFeature' of undefined",
      "Cannot read property 'setState' of null",
      "Cannot read property 'find' of null",
      "Cannot read property 'data' of undefined",
      'Request failed with status code 401',
    ],
    ignoreUrls: [/rte\/ckeditor\/ckeditor\.js/],
  }).install();
}

export const setSentryUser = user => {
  Raven.setUserContext(user || {});
};
