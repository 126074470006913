import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { selectLesson } from '../selectors/lessonSelectors';
import { selectUnit } from '../selectors/unitSelectors';
import { selectComponent } from '../selectors/componentSelectors';
import { selectBootcamp } from '../selectors/bootcampSelectors';
import { selectSection } from '../selectors/sectionSelectors';

export default function useCurrentComponent() {
  const { bootcampId, sectionId, componentId, unitId } = useParams();
  const bootcamp = useSelector(s => selectBootcamp(s, bootcampId));
  const section = useSelector(s => selectSection(s, sectionId));
  const unit = useSelector(s => selectUnit(s, unitId));
  const component = useSelector(s => selectComponent(s, componentId));
  const lessonId = component && component.external_id;
  const lesson = useSelector(s => (lessonId ? selectLesson(s, lessonId) : null));

  return {
    bootcamp,
    bootcampId,
    unit,
    section,
    component,
    lesson,
    lessonId,
  };
}
