import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Button } from 'antd';
import { FormattedMessage } from 'react-intl';

const SimpleModal = ({ onHide, icon, title, body, okText, onOk, showCancel }) => {
  const ok = () => {
    if (onOk) onOk();
    onHide();
  };

  return (
    <Modal
      visible={true}
      onCancel={onHide}
      className="simple-modal"
      width={350}
      bodyStyle={{ textAlign: 'center' }}
      footer={null}
      maskClosable={false}>
      <div className="simple">
        {icon && <div className="modal-icon">{icon}</div>}

        {title && <div className="title">{title}</div>}

        {body && <div className="body">{body}</div>}

        {okText && (
          <div>
            <Button className="ok-button" onClick={ok}>
              {okText}
            </Button>
          </div>
        )}

        {showCancel && (
          <Button className="cancel-button" onClick={onHide}>
            <FormattedMessage id="common.cancel" />
          </Button>
        )}
      </div>
    </Modal>
  );
};

SimpleModal.propTypes = {
  onHide: PropTypes.func.isRequired,
  icon: PropTypes.node, // ant icon
  title: PropTypes.string,
  body: PropTypes.string,
  okText: PropTypes.string,
  onOk: PropTypes.func,
  showCancel: PropTypes.bool,
};

export default SimpleModal;
