import React, { useEffect } from 'react';
import Loading from '../../Utils/Loading';
import ShowLesson from '../../GradedQuiz/ShowLesson';
import { setPageTitle } from '../../../helpers/pageHelper';
import ShowHeader from '../../GradedQuiz/ShowHeader';
import useCurrentGradedQuiz from '../../../hooks/useCurrentGradedQuiz';
import useAnalytics from '../../../hooks/useAnalytics';
import useFetchBootcamp from '../../../hooks/useFetchBootcamp';
import useFetchLesson from '../../../hooks/useFetchLesson';

export default function GradedQuizShow() {
  const { bootcamp, bootcampId, section, unit, lesson, parts, blocks } = useCurrentGradedQuiz();
  useFetchBootcamp(bootcampId);
  useFetchLesson(unit?.lesson_id);

  useAnalytics('Edit Graded Quiz', {
    bootcamp,
    section,
    module: unit,
  });

  useEffect(() => {
    if (bootcamp && unit) {
      const params = {
        course: bootcamp.title,
        lesson: unit.title,
      };
      setPageTitle('pageName.course.lesson', params);
    }
  }, [bootcamp, unit]);

  const part = parts[0];

  return (
    <div className="new-lesson-page">
      <main className="new-lesson-page__main">
        <ShowHeader />

        {lesson && part && blocks ? (
          <ShowLesson lesson={lesson} part={part} blocks={blocks} />
        ) : (
          <article className="lesson-part lesson-part--active">
            <div className="lesson-part__wrapper">
              <Loading />
            </div>
          </article>
        )}
      </main>
    </div>
  );
}
