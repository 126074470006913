import { connect } from 'react-redux';
import BootcampList from './BootcampList';
import { selectBootcamps } from '../../selectors/bootcampSelectors';
import { fetchCohorts } from '../../actions/cohortActions';
import { selectCohorts } from '../../selectors/cohortSelectors';
import { filterBootcamps } from '../../helpers/bootcampHelper';

const mapStateToProps = state => ({
  bootcamps: filterBootcamps(selectBootcamps(state), state.bootcamp.searchText),
  cohorts: selectCohorts(state),
});

export default connect(mapStateToProps, { fetchCohorts })(BootcampList);
