import { groupBy as _groupBy, sortBy as _sortBy } from 'lodash';
import { useSelector } from 'react-redux';
import { selectLessonBlocks, selectOrderedLessonParts, selectLesson } from '../selectors/lessonSelectors';

export default function useLesson(lessonId) {
  const lesson = useSelector(s => (lessonId ? selectLesson(s, lessonId) : null));
  const parts = useSelector(s => (lessonId ? selectOrderedLessonParts(s, lessonId) : []));
  let blocks = useSelector(s => (lessonId ? selectLessonBlocks(s, lessonId) : []));
  blocks = _sortBy(blocks, ['part_id', 'seq_num']);
  const blocksByPart = _groupBy(blocks, 'part_id');

  return {
    lesson,
    parts,
    blocks,
    blocksByPart,
  };
}
