import { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { each as _each, includes as _includes, remove as _remove } from 'lodash';
import { LabContext } from './LabContext';
import { setUser } from '../../actions/authActions';
import { saveProfile } from '../../actions/userActions';
import { selectCurrentUser } from '../../selectors/authSelectors';

const useLabContext = () => {
  const [state, setState] = useContext(LabContext);
  const dispatch = useDispatch();
  const currentUser = useSelector(selectCurrentUser);
  const { startClicked, isEditMode, callbacks, isReady, isVmUp, errors, errorModalProps } = state;

  const setStartClicked = value => {
    setState({ ...state, startClicked: value === undefined ? true : value });
  };

  const setIsReady = value => {
    setTimeout(() => {
      setState({ ...state, isReady: value === undefined ? true : value, errors: [], errorModalProps: [] });
    }, 0);
  };

  const setIsVmUp = (value = true) => {
    setTimeout(() => {
      setState({ ...state, isVmUp: value, errors: [], errorModalProps: [] });
    }, 0);
  };

  const setErrors = e => {
    setState({ ...state, errors: e });
  };

  const setErrorModalProps = e => {
    setState({ ...state, errorModalProps: e });
  };

  const showIntro = () => {
    if (!currentUser || currentUser.lab_intro_seen || !startClicked) return false;

    return true;
  };

  const markLabIntroSeen = () => {
    if (!currentUser) return;
    const userUpdates = { id: currentUser.id, lab_intro_seen: true };

    dispatch(setUser(userUpdates)); // optimistic update so the intro disappears immediately
    dispatch(saveProfile(userUpdates));
  };

  const getCallbacksFor = type => callbacks[type] || [];

  const subscribe = (type, cb) => {
    const cbs = getCallbacksFor(type);
    if (!_includes(cbs, cb)) {
      setState({
        ...state,
        callbacks: {
          ...callbacks,
          [type]: [cbs, cb],
        },
      });
    }
  };

  const unsubscribe = (type, cb) => {
    const cbs = getCallbacksFor(type);

    if (_includes(callbacks, cb)) {
      setState({
        ...state,
        callbacks: {
          ...callbacks,
          [type]: _remove(cbs, cb),
        },
      });
    }
  };

  const fireEvent = (type, e) => {
    const cbs = getCallbacksFor(type);
    _each(cbs, cb => cb(e));
  };

  return {
    startClicked,
    setStartClicked,
    showIntro,
    markLabIntroSeen,
    subscribe,
    unsubscribe,
    fireEvent,
    isEditMode,
    isReady,
    setIsReady,
    isVmUp,
    setIsVmUp,
    errors,
    setErrors,
    errorModalProps,
    setErrorModalProps,
  };
};

export default useLabContext;
