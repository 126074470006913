import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Layout } from 'antd';
import {
  isEmpty as _isEmpty,
  last as _last,
  filter as _filter,
  mapValues as _mapValues,
  keyBy as _keyBy,
  map as _map,
} from 'lodash';
import { useHistory, useLocation } from 'react-router-dom';

import { selectCohort } from '../../../../selectors/cohortSelectors';
import { selectProjectsForBootcamp } from '../../../../selectors/unitSelectors';
import { selectCohortEnrollments } from '../../../../selectors/enrollmentSelectors';
import { selectAssignmentProgressesByEnrollmentIds } from '../../../../selectors/assignmentProgressSelectors';
import AssignmentsMenu from './AssignmentsMenu';
import AssignmentsContent from './AssignmentsContent';
import useCohortStudents from '../StudentsPage/hooks/useCohortStudents';
import { getCohortDashboardPath } from '../../../../helpers/pathHelper';
import Enrollment from '../../../../api/enrollment';

const useStore = cohortId => {
  const cohort = useSelector(state => selectCohort(state, cohortId));
  const cohortUnits = useSelector(state => selectProjectsForBootcamp(state, cohort?.bootcamp_id));

  const enrollments = useSelector(state => selectCohortEnrollments(state, cohortId));
  const enrollmentIds = enrollments.map(enrollment => enrollment.id);
  const assignmentProgresses = useSelector(state => selectAssignmentProgressesByEnrollmentIds(state, enrollmentIds));

  const hiddenUnits = _map(cohort && cohort.hidden_items, item => item.split('::')[1]);
  const units = cohortUnits && cohortUnits.filter(unit => !hiddenUnits.includes(unit.id));

  return {
    units,
    assignmentProgresses,
    enrollments,
  };
};

const sortUnits = (units = []) => [...units].reverse();

const watchedByStudentId = students => _mapValues(_keyBy(students, 'id'), student => student.watchList.inWatchList);

const AssignmentsPage = ({ cohortId }) => {
  const { units, assignmentProgresses, enrollments } = useStore(cohortId);
  const history = useHistory();
  const location = useLocation();
  const getInitialAssignmentId = () => {
    const queryParams = new URLSearchParams(location.search);
    return queryParams.get('assignmentId');
  };
  const [selectedAssignmentId, setSelectedAssignmentId] = useState(getInitialAssignmentId);
  const { students } = useCohortStudents(cohortId);

  useEffect(() => {
    if (units && !selectedAssignmentId) {
      const initialAssignmentId = _isEmpty(units) ? null : _last(units).assignment_id;
      setSelectedAssignmentId(initialAssignmentId);
    }
  }, [selectedAssignmentId, units]);

  const enrolledEnrollmentsIds = enrollments
    .filter(enrollment => enrollment.status === Enrollment.ENROLLED)
    .map(enrollment => enrollment.id);

  const menuItems = sortUnits(units).map(unit => {
    const progresses = _filter(assignmentProgresses, progress => progress.assignment_id === unit.assignment_id);

    const ungradedCount = progresses.filter(
      progress => !!progress.completed_at && !progress.graded_at && enrolledEnrollmentsIds.includes(progress.context),
    ).length;

    return {
      assignmentId: unit.assignment_id,
      title: unit.title,
      ungradedCount,
    };
  });

  const handleAssignmentSelect = assignmentId => {
    setSelectedAssignmentId(assignmentId);
    history.replace(getCohortDashboardPath(cohortId, { tab: 'assignments', assignmentId }));
  };

  return (
    <Layout>
      <AssignmentsMenu
        menuItems={menuItems}
        selectedAssignmentId={selectedAssignmentId}
        onAssignmentSelect={handleAssignmentSelect}
      />

      <AssignmentsContent
        cohortId={cohortId}
        assignmentId={selectedAssignmentId}
        watchedByStudentId={watchedByStudentId(students)}
      />
    </Layout>
  );
};

AssignmentsPage.propTypes = {
  cohortId: PropTypes.string.isRequired,
};

export default AssignmentsPage;
