export const parseISODate = date => {
  var b = date.split(/\D+/);
  return new Date(b[0], --b[1], b[2]); // -- because month is 0 through 11 in JS world
};

export const renderISODateAsReadable = date => {
  return parseISODate(date).toLocaleDateString(undefined, { year:"numeric", month:"short", day: "numeric"});
};

export const shortenAtIndex = (string, index) => {
  if (string.length > index + 4) {
    let substring = string.substring(0, index);
    return `${substring}...`;
  }
  return string;
};
