import { isEmpty as _isEmpty } from 'lodash';
import React, { Component } from 'react';
import Select from 'react-select';
import { formatDate } from '../../utils/utils';
import intl from '../../utils/intl';
import { sortCohorts } from '../../helpers/bootcampHelper';

export default class CohortSelector extends Component {
  constructor(props) {
    super(props);

    const { cohorts } = this.props;
    this.state = {
      selectedCohort: this.props.selected || (_isEmpty(cohorts) ? null : cohorts[0]),
    };

    if (this.state.selectedCohort) {
      this.triggerSelect();
    }
  }

  componentDidMount() {
    const { cohorts, fetchCohorts } = this.props;
    if (_isEmpty(cohorts)) {
      fetchCohorts();
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { selectedCohort } = this.state;

    if (!selectedCohort && this.props.cohorts !== nextProps.cohorts) {
      const cohort =
        nextProps.selected && selectedCohort !== nextProps.selected ? nextProps.selected : nextProps.cohorts[0];
      this.setState(
        {
          selectedCohort: cohort,
        },
        this.triggerSelect,
      );
    }
  }

  cohortOptions = () => {
    const { cohorts } = this.props;
    return sortCohorts(cohorts).map(cohort => ({
      value: cohort.id,
      label: `${formatDate(cohort.start_date)} → ${formatDate(cohort.end_date)}, ${cohort.location}`,
      cohort,
    }));
  };

  handleSelect = option => {
    this.setState(
      {
        selectedCohort: option.cohort,
      },
      this.triggerSelect,
    );
  };

  triggerSelect = () => {
    const { onSelect } = this.props;
    const { selectedCohort } = this.state;

    onSelect && onSelect(selectedCohort);
  };

  render() {
    const { cohorts } = this.props;
    const { selectedCohort } = this.state;
    const isLoading = _isEmpty(cohorts);

    return (
      <Select
        className="Select--simple"
        value={selectedCohort && selectedCohort.id}
        isLoading={isLoading}
        clearable={false}
        name="cohorts"
        options={this.cohortOptions()}
        onChange={this.handleSelect}
        placeholder={intl.formatMessage({ id: 'common.selectCohort' })}
      />
    );
  }
}
