/* eslint-disable max-lines */
import { ReactComponent as Add } from '../../styles/v2/svg-jsx/add.svg';
import { ReactComponent as ArrowBack } from '../../styles/v2/svg-jsx/arrow-back.svg';
import { ReactComponent as ArrowBackSmall } from '../../styles/v2/svg-jsx/arrow-back-small.svg';
import { ReactComponent as ArrowLeftSmall } from '../../styles/v2/svg-jsx/arrow-left-small.svg';
import { ReactComponent as ArrowNext } from '../../styles/v2/svg-jsx/arrow-next.svg';
import { ReactComponent as ArrowNext2 } from '../../styles/v2/svg-jsx/arrow-next2.svg';
import { ReactComponent as ArrowPrev } from '../../styles/v2/svg-jsx/arrow-prev.svg';
import { ReactComponent as ArrowPrevSmall } from '../../styles/v2/svg-jsx/arrow-prev-small.svg';
import { ReactComponent as ArrowRight } from '../../styles/v2/svg-jsx/arrow-right.svg';
import { ReactComponent as ArrowRightBig } from '../../styles/v2/svg-jsx/arrow-right-big.svg';
import { ReactComponent as ArrowRightSmall } from '../../styles/v2/svg-jsx/arrow-right-small.svg';
import { ReactComponent as ArrowTop } from '../../styles/v2/svg-jsx/arrow-top.svg';
import { ReactComponent as ArrowUp } from '../../styles/v2/svg-jsx/arrow-up.svg';
import { ReactComponent as ArrowUpSmall } from '../../styles/v2/svg-jsx/arrow-up-small.svg';
import { ReactComponent as Assignment } from '../../styles/v2/svg-jsx/assignment.svg';
import { ReactComponent as Assignment2 } from '../../styles/v2/svg-jsx/assignment2.svg';
import { ReactComponent as Avatar } from '../../styles/v2/svg-jsx/avatar.svg';
import { ReactComponent as AvatarBig } from '../../styles/v2/svg-jsx/avatar-big.svg';
import { ReactComponent as AvatarMain } from '../../styles/v2/svg-jsx/avatar-main.svg';
import { ReactComponent as AvatarSmall } from '../../styles/v2/svg-jsx/avatar-small.svg';
import { ReactComponent as Back } from '../../styles/v2/svg-jsx/back.svg';
import { ReactComponent as SmallGraph } from '../../styles/v2/svg-jsx/small-graph.svg';
import { ReactComponent as BackSmall } from '../../styles/v2/svg-jsx/back-small.svg';
import { ReactComponent as BackSmallSidebar } from '../../styles/v2/svg-jsx/back-small-sidebar.svg';
import { ReactComponent as Badge } from '../../styles/v2/svg-jsx/badge.svg';
import { ReactComponent as Binoculars } from '../../styles/v2/svg-jsx/binoculars.svg';
import { ReactComponent as Board } from '../../styles/v2/svg-jsx/board.svg';
import { ReactComponent as Camera } from '../../styles/v2/svg-jsx/camera.svg';
import { ReactComponent as Cards } from '../../styles/v2/svg-jsx/cards.svg';
import { ReactComponent as Cert } from '../../styles/v2/svg-jsx/cert.svg';
import { ReactComponent as Cert2 } from '../../styles/v2/svg-jsx/cert2.svg';
import { ReactComponent as Check } from '../../styles/v2/svg-jsx/check.svg';
import { ReactComponent as Circle } from '../../styles/v2/svg-jsx/circle.svg';
import { ReactComponent as CircleBorder } from '../../styles/v2/svg-jsx/circle-border.svg';
import { ReactComponent as CircleCheck } from '../../styles/v2/svg-jsx/circle-check.svg';
import { ReactComponent as CircleCheck2 } from '../../styles/v2/svg-jsx/circle-check2.svg';
import { ReactComponent as CircleCheckActive } from '../../styles/v2/svg-jsx/circle-check-active.svg';
import { ReactComponent as CircleCheckActiveBig } from '../../styles/v2/svg-jsx/circle-check-active-big.svg';
import { ReactComponent as CircleCheckBig } from '../../styles/v2/svg-jsx/circle-check-big.svg';
import { ReactComponent as CircleCheckChecked } from '../../styles/v2/svg-jsx/circle-check-checked.svg';
import { ReactComponent as CircleCheckCheckedBig } from '../../styles/v2/svg-jsx/circle-check-checked-big.svg';
import { ReactComponent as CircleCheck3 } from '../../styles/v2/svg-jsx/circle-check3.svg';
import { ReactComponent as CircleX } from '../../styles/v2/svg-jsx/circle-x.svg';
import { ReactComponent as CircleIcon } from '../../styles/v2/svg-jsx/circle-icon.svg';
import { ReactComponent as Class } from '../../styles/v2/svg-jsx/class.svg';
import { ReactComponent as Clipboard } from '../../styles/v2/svg-jsx/clipboard.svg';
import { ReactComponent as Clock1 } from '../../styles/v2/svg-jsx/clock1.svg';
import { ReactComponent as ClockSmall } from '../../styles/v2/svg-jsx/clock-small.svg';
import { ReactComponent as ClockBlack } from '../../styles/v2/svg-jsx/clock-black.svg';
import { ReactComponent as Close } from '../../styles/v2/svg-jsx/close.svg';
import { ReactComponent as CloseBig } from '../../styles/v2/svg-jsx/close-big.svg';
import { ReactComponent as Contents } from '../../styles/v2/svg-jsx/contents.svg';
import { ReactComponent as Cross } from '../../styles/v2/svg-jsx/cross.svg';
import { ReactComponent as CrossSquare } from '../../styles/v2/svg-jsx/cross-square.svg';
import { ReactComponent as Discussion } from '../../styles/v2/svg-jsx/discussion.svg';
import { ReactComponent as DottedSeparator } from '../../styles/v2/svg-jsx/dotted-separator.svg';
import { ReactComponent as Edit } from '../../styles/v2/svg-jsx/edit.svg';
import { ReactComponent as Education } from '../../styles/v2/svg-jsx/education.svg';
import { ReactComponent as EducationClear } from '../../styles/v2/svg-jsx/education-clear.svg';
import { ReactComponent as Elixir } from '../../styles/v2/svg-jsx/elixir.svg';
import { ReactComponent as ElixirSmall } from '../../styles/v2/svg-jsx/elixir-small.svg';
import { ReactComponent as ElixirBig } from '../../styles/v2/svg-jsx/elixir_big.svg';
import { ReactComponent as Exclamation } from '../../styles/v2/svg-jsx/exclamation.svg';
import { ReactComponent as Face } from '../../styles/v2/svg-jsx/face.svg';
import { ReactComponent as FaceBlue } from '../../styles/v2/svg-jsx/face-blue.svg';
import { ReactComponent as FillPlay } from '../../styles/v2/svg-jsx/fill-play.svg';
import { ReactComponent as FillDownload } from '../../styles/v2/svg-jsx/fill-download.svg';
import { ReactComponent as Gauge } from '../../styles/v2/svg-jsx/gauge.svg';
import { ReactComponent as Grade } from '../../styles/v2/svg-jsx/grade.svg';
import { ReactComponent as Graded } from '../../styles/v2/svg-jsx/graded.svg';
import { ReactComponent as GradedQuiz } from '../../styles/v2/svg-jsx/graded-quiz.svg';
import { ReactComponent as GraduationCap } from '../../styles/v2/svg-jsx/graduation-cap.svg';
import { ReactComponent as Grid } from '../../styles/v2/svg-jsx/grid.svg';
import { ReactComponent as GridWhite } from '../../styles/v2/svg-jsx/grid-white.svg';
import { ReactComponent as Hamburger } from '../../styles/v2/svg-jsx/hamburger.svg';
import { ReactComponent as Hat } from '../../styles/v2/svg-jsx/hat.svg';
import { ReactComponent as Lab } from '../../styles/v2/svg-jsx/lab.svg';
import { ReactComponent as LabelCommentThread } from '../../styles/v2/svg-jsx/label_comment_thread.svg';
import { ReactComponent as LabelKeyPoint } from '../../styles/v2/svg-jsx/label_key_point.svg';
import { ReactComponent as LabelKnowledgeCheck } from '../../styles/v2/svg-jsx/label_knowledge_check.svg';
import { ReactComponent as LabelMedia } from '../../styles/v2/svg-jsx/label_media.svg';
import { ReactComponent as LabelQuestionMark } from '../../styles/v2/svg-jsx/label_question_mark.svg';
import { ReactComponent as LastLogin } from '../../styles/v2/svg-jsx/last-login.svg';
import { ReactComponent as Layers } from '../../styles/v2/svg-jsx/layers.svg';
import { ReactComponent as Lesson } from '../../styles/v2/svg-jsx/lesson.svg';
import { ReactComponent as LessonsCompleted } from '../../styles/v2/svg-jsx/lessons-completed.svg';
import { ReactComponent as LightningBolt } from '../../styles/v2/svg-jsx/lightning-bolt.svg';
import { ReactComponent as LineAccountBox } from '../../styles/v2/svg-jsx/line-account-box.svg';
import { ReactComponent as LineBook } from '../../styles/v2/svg-jsx/line-book.svg';
import { ReactComponent as LineBuild } from '../../styles/v2/svg-jsx/line-build.svg';
import { ReactComponent as LineCoaching } from '../../styles/v2/svg-jsx/line-coaching.svg';
import { ReactComponent as LineDescription } from '../../styles/v2/svg-jsx/line-description.svg';
import { ReactComponent as LineEdit } from '../../styles/v2/svg-jsx/line-edit.svg';
import { ReactComponent as LineEmail } from '../../styles/v2/svg-jsx/line-email.svg';
import { ReactComponent as LineHome } from '../../styles/v2/svg-jsx/line-home.svg';
import { ReactComponent as LineFolder } from '../../styles/v2/svg-jsx/line-folder.svg';
import { ReactComponent as LineInfo } from '../../styles/v2/svg-jsx/line-info.svg';
import { ReactComponent as LineSchedule } from '../../styles/v2/svg-jsx/line-schedule.svg';
import { ReactComponent as List } from '../../styles/v2/svg-jsx/list.svg';
import { ReactComponent as Logo } from '../../styles/v2/svg-jsx/logo.svg';
import { ReactComponent as LogoGray } from '../../styles/v2/svg-jsx/logo-gray.svg';
import { ReactComponent as Logout } from '../../styles/v2/svg-jsx/logout.svg';
import { ReactComponent as Magnifier } from '../../styles/v2/svg-jsx/magnifier.svg';
import { ReactComponent as Menu } from '../../styles/v2/svg-jsx/menu.svg';
import { ReactComponent as Module } from '../../styles/v2/svg-jsx/module.svg';
import { ReactComponent as More } from '../../styles/v2/svg-jsx/more.svg';
import { ReactComponent as MoreSimple } from '../../styles/v2/svg-jsx/more-simple.svg';
import { ReactComponent as Oval } from '../../styles/v2/svg-jsx/oval.svg';
import { ReactComponent as PageBack } from '../../styles/v2/svg-jsx/page-back.svg';
import { ReactComponent as PageForward } from '../../styles/v2/svg-jsx/page-forward.svg';
import { ReactComponent as PageRefresh } from '../../styles/v2/svg-jsx/page-refresh.svg';
import { ReactComponent as Pattern } from '../../styles/v2/svg-jsx/pattern.svg';
import { ReactComponent as Pencil } from '../../styles/v2/svg-jsx/pencil.svg';
import { ReactComponent as PencilPaper } from '../../styles/v2/svg-jsx/pencil-paper.svg';
import { ReactComponent as Phone } from '../../styles/v2/svg-jsx/phone.svg';
import { ReactComponent as Pin } from '../../styles/v2/svg-jsx/pin.svg';
import { ReactComponent as PinSmall } from '../../styles/v2/svg-jsx/pin-small.svg';
import { ReactComponent as Play } from '../../styles/v2/svg-jsx/play.svg';
import { ReactComponent as Plus } from '../../styles/v2/svg-jsx/plus.svg';
import { ReactComponent as Project } from '../../styles/v2/svg-jsx/project.svg';
import { ReactComponent as Puzzle } from '../../styles/v2/svg-jsx/puzzle.svg';
import { ReactComponent as RadioCircle } from '../../styles/v2/svg-jsx/radio-circle.svg';
import { ReactComponent as RadioCircleChecked } from '../../styles/v2/svg-jsx/radio-circle-checked.svg';
import { ReactComponent as RadioCircleQuestion } from '../../styles/v2/svg-jsx/radio-circle-question.svg';
import { ReactComponent as Refresh } from '../../styles/v2/svg-jsx/refresh.svg';
import { ReactComponent as Remove } from '../../styles/v2/svg-jsx/remove.svg';
import { ReactComponent as Reorder } from '../../styles/v2/svg-jsx/reorder.svg';
import { ReactComponent as Salesforce } from '../../styles/v2/svg-jsx/salesforce.svg';
import { ReactComponent as Search } from '../../styles/v2/svg-jsx/search.svg';
import { ReactComponent as Search1 } from '../../styles/v2/svg-jsx/search1.svg';
import { ReactComponent as Sheet } from '../../styles/v2/svg-jsx/sheet.svg';
import { ReactComponent as Shield } from '../../styles/v2/svg-jsx/shield.svg';
import { ReactComponent as Square } from '../../styles/v2/svg-jsx/square.svg';
import { ReactComponent as SquareOnlyCorners } from '../../styles/v2/svg-jsx/square-only-corners.svg';
import { ReactComponent as Spy } from '../../styles/v2/svg-jsx/spy.svg';
import { ReactComponent as Stack } from '../../styles/v2/svg-jsx/stack.svg';
import { ReactComponent as Star } from '../../styles/v2/svg-jsx/star.svg';
import { ReactComponent as Steps } from '../../styles/v2/svg-jsx/steps.svg';
import { ReactComponent as SuccessBig } from '../../styles/v2/svg-jsx/success-big.svg';
import { ReactComponent as Support } from '../../styles/v2/svg-jsx/support.svg';
import { ReactComponent as Tasks } from '../../styles/v2/svg-jsx/tasks.svg';
import { ReactComponent as ThumbDown } from '../../styles/v2/svg-jsx/thumb-down.svg';
import { ReactComponent as WhiteThumbDown } from '../../styles/v2/svg-jsx/thumb-down-white.svg';
import { ReactComponent as ThumbUp } from '../../styles/v2/svg-jsx/thumb-up.svg';
import { ReactComponent as WhiteThumbUp } from '../../styles/v2/svg-jsx/thumb-up-white.svg';
import { ReactComponent as Tick } from '../../styles/v2/svg-jsx/tick.svg';
import { ReactComponent as TickBig } from '../../styles/v2/svg-jsx/tick-big.svg';
import { ReactComponent as TickDisabledBig } from '../../styles/v2/svg-jsx/tick-disabled-big.svg';
import { ReactComponent as TickNoBackground } from '../../styles/v2/svg-jsx/tick-no-background.svg';
import { ReactComponent as TickSquare } from '../../styles/v2/svg-jsx/tick-square.svg';
import { ReactComponent as Time } from '../../styles/v2/svg-jsx/time.svg';
import { ReactComponent as Time2 } from '../../styles/v2/svg-jsx/time2.svg';
import { ReactComponent as Tools } from '../../styles/v2/svg-jsx/tools.svg';
import { ReactComponent as Trash } from '../../styles/v2/svg-jsx/trash.svg';
import { ReactComponent as Tube } from '../../styles/v2/svg-jsx/tube.svg';
import { ReactComponent as TwoArrowsDown } from '../../styles/v2/svg-jsx/two-arrows-down.svg';
import { ReactComponent as Upload } from '../../styles/v2/svg-jsx/upload.svg';
import { ReactComponent as User } from '../../styles/v2/svg-jsx/user.svg';
import { ReactComponent as User2 } from '../../styles/v2/svg-jsx/user-2.svg';
import { ReactComponent as Users } from '../../styles/v2/svg-jsx/users.svg';
import { ReactComponent as Video } from '../../styles/v2/svg-jsx/video.svg';
import { ReactComponent as Filter } from '../../styles/v2/svg-jsx/filter.svg';
import { ReactComponent as StudentToWatch } from '../../styles/v2/svg-jsx/students_to_watch.svg';
import { ReactComponent as Shortcuts } from '../../styles/v2/svg-jsx/shortcuts.svg';
import { ReactComponent as Assignments } from '../../styles/v2/svg-jsx/assignments.svg';
import { ReactComponent as School } from '../../styles/v2/svg-jsx/school.svg';
import { ReactComponent as Book } from '../../styles/v2/svg-jsx/book.svg';
import { ReactComponent as GoBack } from '../../styles/v2/svg-jsx/go-back.svg';
import { ReactComponent as LabBlockWrapper } from '../../styles/v2/svg-jsx/lab-block-wrapper.svg';
import { ReactComponent as TrueChoice } from '../../styles/v2/svg-jsx/true.svg';
import { ReactComponent as WrongChoice } from '../../styles/v2/svg-jsx/false.svg';
import { ReactComponent as LabCollapseSidebar } from '../../styles/v2/svg-jsx/collapse_sidebar_button.svg';
import { ReactComponent as LabExpandSidebar } from '../../styles/v2/svg-jsx/expand_sidebar_button.svg';
import { ReactComponent as WarningTriangle } from '../../styles/v2/svg-jsx/warning-triangle.svg';
import { ReactComponent as Download } from '../../styles/v2/svg-jsx/download.svg';
import { ReactComponent as Trophy } from '../../styles/v2/svg-jsx/trophy.svg';
import { ReactComponent as Schedule } from '../../styles/v2/svg-jsx/schedule.svg';
import { ReactComponent as CircleCheck4 } from '../../styles/v2/svg-jsx/circle-check4.svg';
import { ReactComponent as ChevronRight } from '../../styles/v2/svg-jsx/chevron-right.svg';

export default {
  Add,
  ArrowBack,
  ArrowBackSmall,
  ArrowLeftSmall,
  ArrowNext,
  ArrowNext2,
  ArrowPrev,
  ArrowPrevSmall,
  ArrowRight,
  ArrowRightBig,
  ArrowRightSmall,
  ArrowTop,
  ArrowUp,
  ArrowUpSmall,
  Assignment,
  Avatar,
  AvatarBig,
  AvatarMain,
  AvatarSmall,
  Back,
  BackSmall,
  BackSmallSidebar,
  Badge,
  Binoculars,
  Board,
  Camera,
  Cards,
  Cert,
  Cert2,
  Check,
  Circle,
  CircleBorder,
  CircleCheck,
  CircleCheckActive,
  CircleCheckActiveBig,
  CircleCheckBig,
  CircleCheckChecked,
  CircleCheckCheckedBig,
  CircleCheck2,
  CircleCheck3,
  CircleCheck4,
  CircleX,
  CircleIcon,
  Class,
  Clipboard,
  Clock1,
  ClockSmall,
  ClockBlack,
  Close,
  CloseBig,
  Contents,
  Cross,
  CrossSquare,
  Discussion,
  DottedSeparator,
  Edit,
  Education,
  EducationClear,
  Elixir,
  ElixirSmall,
  ElixirBig,
  Exclamation,
  Face,
  FaceBlue,
  FillPlay,
  FillDownload,
  Gauge,
  Grade,
  Graded,
  GradedQuiz,
  GraduationCap,
  Grid,
  GridWhite,
  Hamburger,
  Hat,
  Lab,
  LabelCommentThread,
  LabelKeyPoint,
  LabelKnowledgeCheck,
  LabelMedia,
  LabelQuestionMark,
  LastLogin,
  Layers,
  Lesson,
  LessonsCompleted,
  LightningBolt,
  LineAccountBox,
  LineBook,
  LineBuild,
  LineCoaching,
  LineDescription,
  LineEdit,
  LineEmail,
  LineHome,
  LineFolder,
  LineInfo,
  LineSchedule,
  List,
  Logo,
  LogoGray,
  Logout,
  Magnifier,
  Menu,
  Module,
  More,
  MoreSimple,
  Oval,
  PageBack,
  PageForward,
  PageRefresh,
  Pattern,
  Pencil,
  PencilPaper,
  Phone,
  Pin,
  PinSmall,
  Play,
  Plus,
  Project,
  Puzzle,
  RadioCircle,
  RadioCircleChecked,
  RadioCircleQuestion,
  Refresh,
  Remove,
  Reorder,
  Salesforce,
  Search,
  Search1,
  Sheet,
  Shield,
  SmallGraph,
  Square,
  SquareOnlyCorners,
  Spy,
  Stack,
  Star,
  Steps,
  SuccessBig,
  Support,
  Tasks,
  ThumbDown,
  WhiteThumbDown,
  ThumbUp,
  WhiteThumbUp,
  Tick,
  TickBig,
  TickDisabledBig,
  TickNoBackground,
  TickSquare,
  Time,
  Time2,
  Tools,
  Trash,
  Tube,
  TwoArrowsDown,
  Upload,
  User,
  User2,
  Users,
  Video,
  Filter,
  StudentToWatch,
  Shortcuts,
  Assignments,
  Book,
  School,
  GoBack,
  LabBlockWrapper,
  TrueChoice,
  WrongChoice,
  LabCollapseSidebar,
  LabExpandSidebar,
  WarningTriangle,
  Assignment2,
  Download,
  Trophy,
  Schedule,
  ChevronRight,
};
/* eslint-enable max-lines */
