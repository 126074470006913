import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Icons from '../../../Utils/Icons';
import CoachProfileNav from './CoachProfileNav';
import CoachShortcuts from './CoachShortcuts';
import DashboardModal from './shortcuts/DashboardModal';
import { Redirect, useLocation, useHistory } from 'react-router-dom';
import PathHeaderContainer from '../../../Paths/PathHeaderContainer';
import ViewingAsStudentHeader from '../../../Coach/ViewingAsStudentHeader';

const CoachHeader = ({ currentUser, SubHeader, scrolledPastHeader, Content }) => {
  const [backToDashboard, setBackToDashboard] = useState(false);
  const [dashboardModalVisible, setDashboardModalVisible] = useState(false);
  const location = useLocation();
  const history = useHistory();

  const getWelcomeText = () => {
    const currentTime = Date.now();
    const midnightTime = new Date().setHours(0);
    const noonTime = new Date().setHours(12);
    const sixPmTime = new Date().setHours(18);

    if (currentTime >= midnightTime && currentTime < noonTime) {
      return <FormattedMessage id="coachHeader.goodMorning" />;
    }
    if (currentTime >= noonTime && currentTime < sixPmTime) {
      return <FormattedMessage id="coachHeader.goodAfternoon" />;
    }
    return <FormattedMessage id="coachHeader.goodEvening" />;
  };

  const lastLoginTime = new Date(currentUser.current_sign_in_at).toLocaleTimeString();
  const lastLoginDate = new Date(currentUser.current_sign_in_at).toLocaleDateString();

  const getCoachHeader = () => {
    return (
      <header className="coach-header">
        {backToDashboard && <Redirect to="/coach/dashboard" />}
        <DashboardModal
          visible={dashboardModalVisible}
          handleModalVisible={setDashboardModalVisible}
          backToDashboard={setBackToDashboard}
        />
        <div className="header-container">
          <div className="header-left">
            <div className="logo-wrapper">
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a
                disabled={location.pathname === '/coach/dashboard'}
                className="coach-profile-option"
                alt="dashboard"
                href="#"
                onClick={() => setDashboardModalVisible(true)}>
                <Icons.Logo />
              </a>
            </div>

            <div className="text-container">
              <div className="welcome-text">
                {getWelcomeText()}, {currentUser.name}!
              </div>
              <div className="last-login">
                <span data-testid={'last-login'}>
                  <FormattedMessage
                    id="coachHeader.lastLoginWasOn"
                    values={{ time: lastLoginTime, date: lastLoginDate }}
                  />
                </span>
              </div>
            </div>
          </div>
          <div className="header-right">
            <CoachShortcuts />
            <div className="profile-wrapper">
              <CoachProfileNav currentUser={currentUser} />
            </div>
          </div>
        </div>
      </header>
    );
  };

  const onBackClick = () => {
    const queryParams = new URLSearchParams(location.search);
    let returnUrl = queryParams.get('returnUrl');
    if (!returnUrl) {
      returnUrl = '/coach/dashboard';
    }

    history.push(returnUrl);
  };
  const getHeader = () => {
    if (Content && Content === PathHeaderContainer) {
      return <ViewingAsStudentHeader onBackClick={onBackClick} />;
    } else {
      return getCoachHeader();
    }
  };

  return (
    <>
      {getHeader()}
      {SubHeader && <SubHeader scrolledPastHeader={scrolledPastHeader} />}
    </>
  );
};

export default CoachHeader;

CoachHeader.propTypes = {
  currentUser: PropTypes.object.isRequired,
  SubHeader: PropTypes.func,
  Content: PropTypes.func,
  scrolledPastHeader: PropTypes.bool,
};
