import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Description from '../Utils/Description';
import { formatDuration, getStatusClass } from '../../utils/utils';

export default class SectionInfo extends Component {
  renderStatusContainer = () => {
    const { section, sectionProgress, duration, isStudent, isEditMode } = this.props;
    const roundedProgress = sectionProgress ? Math.round(sectionProgress.progress * 100) : 0;
    const status = getStatusClass(section, sectionProgress, null, isStudent);

    let content;
    switch (status) {
      case 'completed':
      case 'completed in-progress':
        content = (
          <div className="status">
            <FormattedMessage id="status.complete" />
          </div>
        );
        break;
      case 'in-progress':
        content = (
          <>
            <div className="status">{roundedProgress}%</div>
            <div className="time-left">
              <span className="visually-hidden">
                <FormattedMessage id="common.estimatedTime" />
                {formatDuration(duration, true)}
              </span>
              <span aria-hidden>{formatDuration(duration)}</span>
            </div>
          </>
        );
        break;
      case 'to-do':
        content = (
          <>
            <div className="status">
              <FormattedMessage id="status.notStarted" />
            </div>
            <div className="time-left">
              <span className="visually-hidden">
                <FormattedMessage id="common.estimatedTime" />
                {formatDuration(duration, true)}
              </span>
              <span aria-hidden>{formatDuration(duration)}</span>
            </div>
          </>
        );
        break;
      default:
        if (!isStudent && !isEditMode) {
          content = (
            <>
              <div className="status">
                <FormattedMessage id="status.notStarted" />
              </div>
              <div className="time-left">
                <span className="visually-hidden">
                  <FormattedMessage id="common.estimatedTime" />
                  {formatDuration(duration, true)}
                </span>
                <span aria-hidden>{formatDuration(duration)}</span>
              </div>
            </>
          );
        } else {
          content = ' ';
        }
    }

    return <div className="status-container">{content}</div>;
  };

  render() {
    const { section, index, hideStatus } = this.props;
    return (
      <div className="header">
        <div className="text-container">
          <div className="category-status-container">
            <span className="category">Section</span>
            {!hideStatus && this.renderStatusContainer()}
          </div>
          <h2>
            {index}. {section.title}
          </h2>
          <Description item={section} />
        </div>
      </div>
    );
  }
}

SectionInfo.propTypes = {
  section: PropTypes.object.isRequired,
  sectionProgress: PropTypes.object,
  isEditMode: PropTypes.bool,
  isStudent: PropTypes.bool.isRequired,
  index: PropTypes.number.isRequired,
  duration: PropTypes.number,
  hideStatus: PropTypes.bool,
};

SectionInfo.defaultProps = {
  isStudent: false,
  isEditMode: false,
  hideStatus: false,
};
