import axios from './axios';

export default class Question {
  static SINGLE_SELECT = 'single_select';

  static MULTI_SELECT = 'multi_select';

  static FREE_TEXT_ANSWER = 'free_text_answer';

  static SELF_REFLECTION = 'self_reflection';

  static save(_, question, quizId) {
    if (question.id) {
      return axios
        .put(`/assessments/quizzes/${quizId}/questions/${question.id}`, question)
        .then(response => response.data);
    }
    return axios.post(`/assessments/quizzes/${quizId}/questions`, question).then(response => response.data);
  }

  static destroy(_, question) {
    return axios
      .delete(`/assessments/quizzes/${question.quiz_id}/questions/${question.id}`)
      .then(response => response.data);
  }
}
