import axios from './axios';

export default class Part {
  static async save(_, part) {
    const response = part.id
      ? await axios.put(`/content/lessons/${part.lesson_id}/parts/${part.id}`, part)
      : await axios.post(`/content/lessons/${part.lesson_id}/parts`, part);

    return response.data;
  }

  static async destroy(_, part) {
    const response = await axios.delete(`/content/lessons/${part.lesson_id}/parts/${part.id}`);
    return response.data;
  }

  static async reorder(_, partIds, lessonId) {
    const response = await axios.post(`/content/lessons/${lessonId}/reorder_parts`, {
      part_ids: partIds,
    });
    return response.data;
  }
}
