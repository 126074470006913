import React from 'react';
import PropTypes from 'prop-types';
import SectionFooter from './SectionFooter';
import LessonFooter from './LessonFooter';
import useNextUrl from '../../../hooks/useNextUrl';

export default function LessonCompletionFooter({
  currentProgress,
  nextProgress,
  isSectionComplete,
  isUnitComplete,
  onCancel,
}) {
  const nextUrl = useNextUrl();

  return (
    <footer className="subject-congrats__overview">
      {isSectionComplete && <SectionFooter />}
      {!isSectionComplete && (
        <LessonFooter
          currentProgress={currentProgress}
          nextProgress={nextProgress}
          nextUrl={nextUrl}
          isUnitComplete={isUnitComplete}
          onCancel={onCancel}
        />
      )}
    </footer>
  );
}

LessonCompletionFooter.propTypes = {
  currentProgress: PropTypes.object.isRequired,
  nextProgress: PropTypes.object.isRequired,
  isSectionComplete: PropTypes.bool,
  isUnitComplete: PropTypes.bool,
  onCancel: PropTypes.func,
};
