import React, { useState } from 'react';
import { Button, Checkbox, Form, Input } from 'antd';
import { v4 as uuidv4 } from 'uuid';
import { FormattedMessage } from 'react-intl';
import { MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';

import { AutograderLevel, OTHER_PROPS } from '../../../utils/constants/AutograderEnums';
import { parseFormula } from '../../../utils/logicFormulaParser/logicFormulaParser';

const RuleBuilder = ({ parentForm, initialValues, fieldKeys, onEdit, checksAmount }) => {
  const [isRuleOn, setIsRuleOn] = useState(!!initialValues);
  const [logicFormula, setLogicFormula] = useState(initialValues?.logicFormula || '');
  const subFieldName = AutograderLevel.RULE;
  const otherProps = OTHER_PROPS;

  const {
    name: fName,
    description: fDesc,
    points: fPoints,
    resubmitOnError: fResubmitOnError,
    fieldKey,
  } = initialValues || {
    name: '',
    description: '',
    points: null,
    resubmitOnError: false,
  };

  return (
    <div className="rule-part-container">
      {isRuleOn && (
        <Form.Item required={false}>
          <div className="rule-main">
            <div className="rule-name">
              <FormattedMessage id="autograder.form.ruleName" />
            </div>
            <Form.Item name={[subFieldName, 'name']} initialValue={fName}>
              <Input />
            </Form.Item>
            <div className="rule-desc">
              <FormattedMessage id="autograder.form.ruleDesc" />
            </div>
            <Form.Item name={[subFieldName, 'description']} initialValue={fDesc}>
              <Input />
            </Form.Item>
            <div className="rule-formula">
              <FormattedMessage id="autograder.form.ruleFormula" />
            </div>
            <Form.Item
              name={[subFieldName, 'logicFormula']}
              initialValue={logicFormula}
              validateTrigger="onBlur"
              rules={[
                {
                  validator: () => {
                    const formula = logicFormula.toLowerCase().trim();
                    if (!formula.length) {
                      return Promise.resolve();
                    }
                    const result = parseFormula(formula, checksAmount);
                    if (result.error) {
                      return Promise.reject(result.error);
                    }
                    return Promise.resolve();
                  },
                },
              ]}>
              <Input value={logicFormula} onChange={e => setLogicFormula(e.target.value)} />
            </Form.Item>
            <div className="rule-pt-resubmit-group">
              <div className="rule-points">
                <div className="points-assign">
                  <FormattedMessage id="autograder.form.rulePoints" />
                </div>
                <Form.Item name={[subFieldName, 'points']} initialValue={fPoints}>
                  <Input />
                </Form.Item>
              </div>
              <Form.Item
                name={[subFieldName, 'resubmitOnError']}
                initialValue={fResubmitOnError}
                className="rule-resubmit"
                valuePropName="checked">
                <Checkbox>
                  <FormattedMessage id="autograder.form.ruleResubmitPrompt" />
                </Checkbox>
              </Form.Item>
            </div>
            <Form.Item name={[subFieldName, 'fieldKey']}>
              <Form.Item className="save-rule-submit">
                <Button
                  type="primary"
                  className="save-rule-field-btn"
                  onClick={() => {
                    const fieldsError = parentForm.getFieldsError();
                    let hasErrors;
                    fieldsError.forEach(({ errors }) => {
                      if (errors.length) {
                        hasErrors = true;
                      }
                    });
                    if (hasErrors) {
                      return;
                    }
                    if (onEdit) onEdit(fieldKeys);
                    parentForm.setFields([
                      {
                        name: [subFieldName, 'fieldKey'],
                        value: initialValues ? fieldKey : uuidv4(),
                      },
                    ]);
                    parentForm.submit();
                    setIsRuleOn(false);
                  }}>
                  {initialValues ? (
                    <FormattedMessage id="autograder.form.updateRule" />
                  ) : (
                    <FormattedMessage id="autograder.form.saveRule" />
                  )}
                </Button>
                <Form.Item name={[otherProps, 'isCanceled']} noStyle>
                  <Button
                    danger
                    className="cancel-check-field-btn"
                    onClick={() => {
                      setIsRuleOn(false);

                      parentForm.setFields([
                        {
                          name: [otherProps, 'isCanceled'],
                          value: true,
                        },
                      ]);
                      parentForm.submit();
                    }}>
                    <MinusCircleOutlined
                      style={{
                        fontSize: '16px',
                        verticalAlign: 'initial',
                      }}
                    />
                    <FormattedMessage id="common.cancel" />
                  </Button>
                </Form.Item>
              </Form.Item>
            </Form.Item>
          </div>
        </Form.Item>
      )}
      {!initialValues && (
        <Form.Item>
          <Button type="default" className="add-rule-field-btn" onClick={() => setIsRuleOn(true)}>
            <PlusCircleOutlined style={{ fontSize: '20px' }} />
            <FormattedMessage id="autograder.form.addRule" />
          </Button>
        </Form.Item>
      )}
    </div>
  );
};

export default RuleBuilder;
