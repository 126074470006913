/* eslint-disable max-statements */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Modal } from 'antd';
import { CheckOutlined } from '@ant-design/icons';

import Errors from '../../Utils/Errors';
import useCurrentUser from '../../../hooks/useCurrentUser';
import intl from '../../../utils/intl';
import { saveProfile } from '../../../actions/userActions';
import showModal from '../../../utils/showModal';
import AsanaEmailModal from './AsanaEmailModal';
import User from '../../../api/user';
import NoAsanaEmailModal from './NoAsanaEmailModal';

const AsanaAccountCreatedModal = ({ onHide, onStartOver }) => {
  const dispatch = useDispatch();
  const { currentUser } = useCurrentUser();
  const [errors, setErrors] = useState([]);
  const [isSaving, setIsSaving] = useState(false);

  const handleSubmit = () => {
    if (isSaving) {
      return;
    }

    setIsSaving(true);

    saveProfile({ ...currentUser, asana_address: null })(dispatch)
      .then(() => {
        onStartOver();
        onHide();
      })
      .catch(err => {
        setErrors(err.errors);
        setIsSaving(false);
      });
  };

  // Per request in https://pathstream.atlassian.net/browse/DEV-919
  // Leaving this function in-place for a potential restore after some
  // user testing to validate these changes.
  // eslint-disable-next-line no-unused-vars
  const showAsanaEmail = async () => {
    try {
      const response = await User.getAsanaInviteEmailContent(currentUser.id);
      if (response.html) {
        showModal(AsanaEmailModal, { html: response.html });
      } else {
        showModal(NoAsanaEmailModal, {});
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log('error', err);
      showModal(NoAsanaEmailModal, {});
    }
  };

  const title = intl.formatMessage({ id: 'profile.asanaAccountCreatedModal.title' });
  const subTitle = intl.formatMessage({ id: 'profile.asanaAccountCreatedModal.subTitle' });
  const loginInstruction1 = intl.formatMessage({ id: 'profile.asanaAccountCreatedModal.loginInstruction1' });
  const loginInstruction2 = intl.formatMessage({ id: 'profile.asanaAccountCreatedModal.loginInstruction2' });
  const noEmail = intl.formatMessage({ id: 'profile.asanaAccountCreatedModal.noEmail' });
  const changeEmail = intl.formatMessage({ id: 'profile.asanaAccountCreatedModal.changeEmail' });

  // See note above with #showAsanaEmail
  // eslint-disable-next-line no-unused-vars
  const viewInviteEmailButton = intl.formatMessage({ id: 'profile.asanaAccountCreatedModal.viewInviteEmailButton' });
  const changeEmailButton = intl.formatMessage({ id: 'profile.asanaAccountCreatedModal.changeEmailButton' });

  return (
    <Modal onCancel={onHide} visible className="asana-account-created-modal" footer={null}>
      <div className="asana-account-created" data-testid="asana-created-modal">
        <CheckOutlined className="check-icon" />

        <div className="title">{title}</div>

        <div className="sub-title">{subTitle}</div>

        <div className="lab-email">{currentUser.lab_email}</div>

        <div className="login-instruct">{loginInstruction1}</div>
        <div className="login-instruct">{loginInstruction2}</div>

        <hr />

        <Errors errors={errors} />

        <div className="view-email-instruct">
          {noEmail}
          <br />
          {changeEmail}
        </div>

        {/*
        // See note above with #showAsanaEmail
        <Button className="view-asana-invite-email" key="view-asana-invite-email" onClick={showAsanaEmail}>
          {viewInviteEmailButton}
        </Button>
        */}

        <div className="new-email">
          <button onClick={handleSubmit} type="button">
            {changeEmailButton}
          </button>
        </div>
      </div>
    </Modal>
  );
};

AsanaAccountCreatedModal.propTypes = {
  onHide: PropTypes.func.isRequired,
  onStartOver: PropTypes.func.isRequired,
};

export default AsanaAccountCreatedModal;
