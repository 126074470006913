import { each as _each } from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Link } from 'react-scroll';
import { FormattedMessage } from 'react-intl';
import Icons from '../Utils/Icons';
import { getLessonPartStatus } from '../../helpers/lessonHelper';

export default function PartTab({ part, partIndex, lesson, lessonProgress }) {
  const { isLessonComplete, isComplete: isPartComplete, isCurrent } = getLessonPartStatus(lessonProgress, lesson, part);

  const handleSetActive = to => {
    const partContainers = document.querySelectorAll('.lesson-part__wrapper');
    _each(partContainers, container => {
      const isFocused = container.getAttribute('name') === to;
      container.classList.toggle('part-focused', isFocused);
    });
  };

  return (
    <li key={part.id} className="header-bar__part">
      <Link
        to={`part_${part.id}`}
        isDynamic
        smooth
        spy
        data-tip={part.title}
        offset={-80}
        activeClass="header-bar__link--focused"
        onSetActive={handleSetActive}
        className={classnames(
          'header-bar__link',
          isLessonComplete && 'header-bar__link--finished',
          !isLessonComplete && isPartComplete && 'header-bar__link--completed',
          !isLessonComplete && !isPartComplete && isCurrent && 'header-bar__link--active',
        )}>
        {isPartComplete && (<Icons.CircleCheckChecked />)}
        <FormattedMessage id="component.partIndex" values={{ index: partIndex + 1 }} />
      </Link>
    </li>
  );
}

PartTab.propTypes = {
  part: PropTypes.object,
  partIndex: PropTypes.number,
  lesson: PropTypes.object,
  lessonProgress: PropTypes.object,
};
