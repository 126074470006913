import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import { FormattedMessage } from 'react-intl';
import { PlusCircleOutlined } from '@ant-design/icons';

const RulesetViewer = ({ ruleset, onEdit, fieldKeys }) => (
  <div className="ruleset-text-label-main">
    {ruleset.name}
    <div className="edit-field">
      {onEdit && (
        <Button
          type="primary"
          size="small"
          ghost
          className="edit-field-btn"
          onClick={event => {
            event.stopPropagation();
            onEdit(fieldKeys);
          }}>
          <PlusCircleOutlined style={{ fontSize: '16px', verticalAlign: 'initial' }} />
          <FormattedMessage id="autograder.form.editRulesetBtn" />
        </Button>
      )}
    </div>
  </div>
);

RulesetViewer.propTypes = {
  ruleset: PropTypes.object.isRequired,
  onEdit: PropTypes.func,
  onRemove: PropTypes.func,
  fieldKeys: PropTypes.any,
  orderIndex: PropTypes.number,
};

export default RulesetViewer;
