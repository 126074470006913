import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Channel from '../Utils/Channel';
import MultipleEditorWarning from '../Utils/MultipleEditorWarning';
import useChannelHelper from '../../hooks/useChannelHelper';

export default function MultipleEditor({ children, blockId, className, childrenId, forceEditing }) {
  const {
    hasMultipleUsersByChannel,
    channelByName,
    handleChannelUpdate,
    handleChannelMemberJoined,
    handleChannelMemberLeft,
  } = useChannelHelper();

  const [isEditing, setIsEditing] = useState(false);

  if (!blockId) return children;

  const blockChannelName = getBlockChannelName(blockId, childrenId);

  const channelEnabled = isEditing || forceEditing;
  const hasMultipleUsers = hasMultipleUsersByChannel && hasMultipleUsersByChannel[blockChannelName];
  const showWarning = hasMultipleUsers && channelEnabled && channelByName[blockChannelName];

  return (
    <div
      className={classnames(className, { 'multiple-editors': hasMultipleUsers })}
      onFocus={() => setIsEditing(true)}
      onBlur={() => setIsEditing(false)}>
      <Channel
        channelName={blockChannelName}
        enabled={channelEnabled}
        onChannelUpdate={handleChannelUpdate}
        onMemberJoined={handleChannelMemberJoined}
        onMemberLeft={handleChannelMemberLeft}
      />
      {showWarning && <MultipleEditorWarning members={channelByName[blockChannelName].members} />}
      {children}
    </div>
  );
}

const getBlockChannelName = (blockId, childrenId) => {
  const childrenName = childrenId ? `_${childrenId}` : '';
  return `presence-block_${blockId}${childrenName}`;
};

MultipleEditor.propTypes = {
  blockId: PropTypes.string,
  childrenId: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.any,
  forceEditing: PropTypes.bool,
};

MultipleEditor.defaultProps = {
  forceEditing: false,
};
