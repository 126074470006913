import React from 'react';
import { FormattedMessage } from 'react-intl';
import Icons from './Icons';

export default function LabBlockHeader() {
  return (
    <div className="lab-nav-label">
      <span className="icon">
        <Icons.Shield />
      </span>
      <FormattedMessage id="common.knowledgeCheck" />
    </div>
  );
}
