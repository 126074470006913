import { find as _find } from 'lodash';
import { selectEntities } from './applicationSelectors';

export const selectBootcampInstanceAssignments = selectEntities('bootcampInstanceAssignments');
export const selectBootcampInstanceQuizzes = selectEntities('bootcampInstanceQuizzes');
export const selectBootcampInstanceSections = selectEntities('bootcampInstanceSections');

export const selectBootcampInstanceAssignmentByCompositeKey = (assignmentId, bootcampInstanceId) => state => {
  const allByPrimaryKey = selectBootcampInstanceAssignments(state);
  return _find(allByPrimaryKey, { assignment_id: assignmentId, bootcamp_instance_id: bootcampInstanceId });
};

export const selectAllBootcampInstanceAssignmentByCompositeKeyForAssignments = (
  assignmentIds,
  bootcampInstanceId,
) => state => {
  const allByPrimaryKey = selectBootcampInstanceAssignments(state);

  return allByPrimaryKey
    ? Object.values(allByPrimaryKey).filter(
        bootcampInstanceAssignment =>
          assignmentIds.includes(bootcampInstanceAssignment.assignment_id) &&
          bootcampInstanceAssignment.bootcamp_instance_id === bootcampInstanceId,
      )
    : [];
};
