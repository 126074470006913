import { connect } from 'react-redux';
import EditUnitModal from './EditUnitModal';
import { saveUnit } from '../../../actions/unitActions';
import { selectBootcampFromSection } from '../../../selectors/bootcampSelectors';
import { selectProjectsForBootcamp } from '../../../selectors/unitSelectors';
import { find as _find } from 'lodash';
import { fetchBootcamp } from '../../../actions/bootcampActions';
import { selectCurrentUser } from '../../../selectors/authSelectors';

const mapStateToProps = (state, ownProps) => {
  const bootcamp = selectBootcampFromSection(state, ownProps.section);
  const projects = selectProjectsForBootcamp(state, bootcamp.id);
  const currentFirstBootcampProject = _find(projects, 'is_first_project');
  const isFirstProject = Boolean(currentFirstBootcampProject && currentFirstBootcampProject.id === ownProps.unit.id);
  const currentUser = selectCurrentUser(state);

  return {
    currentFirstBootcampProject,
    isFirstProject,
    bootcamp,
    currentUser,
  };
};

export default connect(mapStateToProps, {
  saveUnit,
  fetchBootcamp,
})(EditUnitModal);
