import { normalize } from 'normalizr';
import Step from '../api/step';
import Lesson from '../api/lesson';
import Schemas from '../api/schemas';
import { updateEntities } from './entityActions';
import { fetchCurrentTenant } from './tenantActions';
import { applicationSave } from './applicationActions';

export const saveStep = applicationSave(Step);

export const destroyStep = step => dispatch =>
  fetchCurrentTenant()(dispatch).then(tenant =>
    // destroy the lesson before destroying the step
    Lesson.destroy(tenant.id, { id: step.lesson_id }).then(() =>
      Step.destroy(tenant.id, step).then(response => {
        const { entities } = normalize(response.entities, Schemas.entities);
        const { entities: entitiesX } = normalize(response.entities_x, Schemas.entities);

        dispatch(updateEntities({ entities, entitiesX }));
      }),
    ),
  );

export const reorderSteps = (stepIds, tutorial) => dispatch => {
  dispatch(
    updateEntities({
      tutorials: {
        [tutorial.id]: {
          ...tutorial,
          step_ids: stepIds,
        },
      },
    }),
  );

  return fetchCurrentTenant()(dispatch).then(tenant => Step.reorder(tenant.id, stepIds, tutorial.id));
};
