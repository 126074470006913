import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { selectIsEditMode } from '../../../selectors/contextSelectors';
import AccountEdit from './AccountEdit';
import AccountShow from './AccountShow';

export default function AsanaAccount({ block }) {
  const isEditMode = useSelector(selectIsEditMode);

  if (isEditMode) return <AccountEdit block={block} />;
  return <AccountShow block={block} />;
}

AsanaAccount.propTypes = {
  block: PropTypes.object.isRequired,
};
