import { isEmpty as _isEmpty } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Modal } from 'antd';

import Loading from '../Utils/Loading';
import ContentWrapperContainer from '../v2/Nav/ContentWrapperContainer';
import LabInstructions from './LabInstructions';
import StepShow from './StepShow';
import * as analytics from '../../utils/analytics';
import Tutorial from '../../api/tutorial';
import { isToughTalksApp, isTrailheadApp } from '../../helpers/labHelper';
import { setPageTitle } from '../../helpers/pageHelper';
import QuizOnLab from '../Quiz/QuizOnLab';
import BlockShowContainer from './BlockShowContainer';
import { TutorialDataProvider } from '../../context/Tutorial/TutorialContext';
import LabPageContent from '../Labs/LabPageContent';
import LabStepWrapper from '../Author/Steps/LabStepWrapper';
import { DismissIntro } from '../Labs/LabIntro';
import LabWrapper from '../v2/Nav/LabWrapper';
import LabCompletionModal from '../Labs/LabCompletionModal';
import { completePart } from '../../actions/lessonProgressActions';

const TutorialShow = ({
  bootcamp,
  path,
  section,
  unit,
  component,
  lesson,
  tutorial,
  steps,
  fetchCurrentEnrollments,
  fetchEnrollmentForCohort,
  fetchTutorial,
  fetchLesson,
  fetchLtiAssignmentProgresses,
  completeTutorialProgress,
  updateComponentProgress,
  tutorialProgress,
  lessonProgress,
  componentProgress,
  projectFolderPath,
  lessonUrlObject,
  history,
  cohort,
  saveTutorialProgress,
  feedbackData,
  tutorialId,
  lessonId,
  isLtiStudent,
  isStudent,
  cohortId,
  parts,
}) => {
  const dispatch = useDispatch();

  const [hasEnded, setHasEnded] = useState(false);
  const [userProjectUrl, setUserProjectUrl] = useState(null);
  const [isFinished, setIsFinished] = useState(false);

  const nextUrlRef = useRef();

  useEffect(() => {
    if (!bootcamp) fetchCurrentEnrollments();

    analytics.pageStart('View Lab');
    return () => {
      analytics.pageEnd({
        ...analyticsParams(),
        includeIdle: true,
      });
    };
  }, []);

  useEffect(() => {
    if (isLtiStudent) fetchLtiAssignmentProgresses();
  }, [isLtiStudent]);

  useEffect(() => {
    if (isStudent && cohortId && !tutorialProgress) fetchEnrollmentForCohort(cohortId);
  }, [isStudent, cohortId]);

  useEffect(() => {
    if (bootcamp && tutorial) {
      const params = {
        course: bootcamp.title,
        project: tutorial.title,
      };
      setPageTitle('pageName.course.project', params);
    }
  }, [bootcamp, tutorial]);

  useEffect(() => {
    if (!tutorial && tutorialId) fetchTutorial(tutorialId);
  }, [tutorialId]);

  useEffect(() => {
    if (!lesson && lessonId) fetchLesson(lessonId);
  }, [lessonId]);

  useEffect(() => {
    if (tutorialProgress) updateProjectUrl(tutorialProgress, userProjectUrl);
  }, [tutorialProgress]);

  useEffect(() => {
    if (hasEnded) {
      setTimeout(() => {
        history.push(nextUrlRef.current);
      }, 0);
    }
  }, [hasEnded]);

  useEffect(() => {
    if (isFinished) completeTutorial();
  }, [isFinished]);

  const analyticsParams = () => ({
    bootcamp,
    cohort,
    section,
    module: unit,
    lesson: component,
    lab: tutorial,
  });

  const completeTutorial = async () => {
    if (!tutorialProgress || tutorialProgress.completed_at) {
      return;
    }

    await completeTutorialProgress(tutorialProgress.id);
  };

  const saveProjectUrlPromiseRef = useRef();

  const updateProjectUrl = async (tutProg, projectUrl) => {
    if (!projectUrl || tutProg.project_url === projectUrl || saveProjectUrlPromiseRef.current) {
      return;
    }

    try {
      await (saveProjectUrlPromiseRef.current = saveTutorialProgress({ ...tutProg, project_url: projectUrl }));
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log('Error occurred while updating project_url', err);
    } finally {
      saveProjectUrlPromiseRef.current = null;
    }
  };

  const handleProjectUrlReceived = projectUrl => {
    if (![Tutorial.GSUITE, Tutorial.GOOGLE_COLAB, Tutorial.VIDEO, Tutorial.TOUGH_TALKS].includes(tutorial.app)) {
      return;
    }

    if (!tutorialProgress) {
      // set the url in the state and update the tutorialProgress when available
      setUserProjectUrl(projectUrl);
      return;
    }

    updateProjectUrl(tutorialProgress, projectUrl);
  };

  const completeLesson = async () => {
    const promises = [];

    for (let i = 0; i < parts.length; i += 1) {
      const part = parts[i];
      const partProgress = lessonProgress.parts_progress[part.id];
      if (!partProgress || !partProgress.completed_at) {
        promises.push(completePart(lessonProgress.id, part.id)(dispatch));
      }
    }

    if (promises.length > 0) {
      try {
        await Promise.all(promises);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error('Error updating lesson/component progress', e);
      }
    }

    if (componentProgress.completed_at) {
      return;
    }

    analytics.track('Lesson Complete', analytics.getPageParams(analyticsParams()));

    await updateComponentProgress(componentProgress.id, { progress: 1 });
  };

  const handleCompletionSubmit = async () => {
    if (tutorial.end_button_type === Tutorial.NEXT_LESSON && lessonUrlObject.next) {
      nextUrlRef.current = lessonUrlObject.next.url;
      await completeLesson();
    } else {
      nextUrlRef.current = lessonUrlObject.current.url;
    }

    setHasEnded(true);
  };

  const handleStepChanged = step => {
    if (!tutorialProgress) return;
    saveTutorialProgress({ id: tutorialProgress.id, current_step_id: step.id });
  };

  const handleFinishClick = () => {
    setIsFinished(true);
  };

  const handleCompletionCancel = () => {
    setIsFinished(false);
  };

  if (
    !path ||
    !tutorial ||
    (!isTrailheadApp(tutorial.app) && _isEmpty(steps)) ||
    (isToughTalksApp(tutorial.app) && !tutorialProgress)
  ) {
    return (
      <ContentWrapperContainer className="lab-page">
        <Loading />
      </ContentWrapperContainer>
    );
  }

  return (
    <LabWrapper app={tutorial.app}>
      <Modal visible={isFinished} centered closable={false} className="lab-completion-modal" footer={null}>
        <LabCompletionModal
          formData={feedbackData}
          steps={steps}
          onSubmit={handleCompletionSubmit}
          onCancel={handleCompletionCancel}
        />
      </Modal>
      <QuizOnLab
        render={(quizOnLab, renderQuiz) => (
          <TutorialDataProvider
            component={component}
            lesson={lesson}
            tutorial={tutorial}
            steps={steps}
            isEditMode={false}
            tutorialProgress={tutorialProgress}
            lessonUrlObject={lessonUrlObject}
            projectFolderPath={projectFolderPath}>
            <div className="new-lab-page">
              <main className="new-lab-page__main">
                {tutorial && !isTrailheadApp(tutorial.app) && (
                  <LabInstructions title={tutorial.title || component.title} backLink={lessonUrlObject?.current?.url}>
                    <LabStepWrapper
                      StepComponent={StepShow}
                      quizOnLab={quizOnLab}
                      onStepChanged={handleStepChanged}
                      onFinishClick={handleFinishClick}
                    />
                  </LabInstructions>
                )}

                <LabPageContent
                  {...{
                    tutorial,
                    bootcamp,
                    cohortId,
                    projectFolderPath,
                    component,
                    steps,
                    handleProjectUrlReceived,
                    hasEnded,
                    renderQuiz,
                  }}
                  preview={false}
                  saveToS3
                  streamingUrl={tutorialProgress && tutorialProgress.project_url}
                  analyticsParams={analyticsParams()}
                  QuizComponent={BlockShowContainer}
                />
              </main>
            </div>
            <DismissIntro />
          </TutorialDataProvider>
        )}
      />
    </LabWrapper>
  );
};

TutorialShow.propTypes = {
  bootcamp: PropTypes.object,
  path: PropTypes.object,
  section: PropTypes.object,
  unit: PropTypes.object,
  component: PropTypes.object,
  lesson: PropTypes.object,
  tutorial: PropTypes.object,
  steps: PropTypes.array,
  fetchCurrentEnrollments: PropTypes.func.isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  fetchEnrollmentForCohort: PropTypes.func.isRequired,
  fetchTutorial: PropTypes.func.isRequired,
  fetchLesson: PropTypes.func.isRequired,
  fetchLtiAssignmentProgresses: PropTypes.func.isRequired,
  completeTutorialProgress: PropTypes.func.isRequired,
  updateComponentProgress: PropTypes.func.isRequired,
  tutorialProgress: PropTypes.object,
  lessonProgress: PropTypes.object,
  componentProgress: PropTypes.object,
  projectFolderPath: PropTypes.string,
  lessonUrlObject: PropTypes.object,
  history: PropTypes.object,
  cohort: PropTypes.object,
  saveTutorialProgress: PropTypes.func,
  feedbackData: PropTypes.object,
  tutorialId: PropTypes.string,
  lessonId: PropTypes.string,
  isLtiStudent: PropTypes.bool,
  isStudent: PropTypes.bool,
  cohortId: PropTypes.string,
  parts: PropTypes.array,
};

export default TutorialShow;
