import React from 'react';
import PropTypes from 'prop-types';

import MetricCard from './MetricCard';
import Icons from '../../../../../Utils/Icons';
import intl from '../../../../../../utils/intl';

const CompletedAssignmentsCard = ({ numCompletedAssignments }) => {
  const metric = {
    icon: <Icons.LightningBolt />,
    metricKey: intl.formatMessage({ id: 'cohortDashboard.studentsPage.completedAssignments' }),
    metricValue: numCompletedAssignments,
  };

  return <MetricCard {...metric} />;
};

CompletedAssignmentsCard.propTypes = {
  numCompletedAssignments: PropTypes.number.isRequired,
};

export default CompletedAssignmentsCard;
