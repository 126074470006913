import { size as _size, some as _some } from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import QuestionContainer from './QuestionContainer';
import intl from '../../utils/intl';

const Questions = ({
  knowledgeCheckRef,
  questionProgressesEnabled,
  questions,
  questionIndex,
  updateQuestionIndex,
  quizProgress,
  setCurrentQuestionToPreviousQuizProgress,
}) => {
  const scrollToTop = () => {
    const HEADER_HEIGHT = 200;

    knowledgeCheckRef.current.scrollIntoView();
    window.scrollBy(0, -HEADER_HEIGHT);
  };

  const handleStepQuestion = increment => {
    updateQuestionIndex(increment);
    scrollToTop();
  };

  const handleBackStepQuestion = questionProgressId => {
    if (questionProgressId) {
      setCurrentQuestionToPreviousQuizProgress(quizProgress, questionProgressId);
    }
    handleStepQuestion(-1);
  };

  const renderQuizSteps = (hasPreviousQuestion, questionProgressId) => {
    return (
      <div aria-labelledby="question-progress">
        {hasPreviousQuestion && (
          <button
            type="button"
            disabled={!hasPreviousQuestion}
            onClick={() => handleBackStepQuestion(questionProgressId)}
            className="button-standard button-standard--outlined"
            aria-label={intl.formatMessage({ id: 'question.previous' })}>
            <FormattedMessage id="common.back" />
          </button>
        )}
      </div>
    );
  };

  const renderNext = () => {
    const hasNextQuestion = questionIndex < questions.length - 1;
    const hasPreviousQuestion = questionIndex > 0;

    if (!hasNextQuestion) {
      return '';
    }

    return (
      <div className="answer-buttons">
        {renderQuizSteps(hasPreviousQuestion)}
        <button
          type="button"
          className="button-standard button-standard--green button-standard--short"
          onClick={() => handleStepQuestion(1)}>
          <FormattedMessage id="common.next" />
        </button>
      </div>
    );
  };

  const hasPreviousQuestion = questionIndex > 0;
  const question = questions[questionIndex];
  // TODO: handle case in retries where last incorrect question isn't the last of all questions
  const isLastQuestion = questionIndex === questions.length - 1;

  return (
    <QuestionContainer
      {...{ questionProgressesEnabled, question }}
      afterSubmit={() => handleStepQuestion(1)}
      renderNext={renderNext}
      renderQuestionsNavigation={questionProgressId => renderQuizSteps(hasPreviousQuestion, questionProgressId)}
      isLastQuestion={isLastQuestion}
    />
  );
};

Questions.propTypes = {
  questions: PropTypes.array,
  questionProgressesEnabled: PropTypes.bool,
  knowledgeCheckRef: PropTypes.object.isRequired,
};

Questions.defaultProps = {
  questionProgressesEnabled: true,
};

export default Questions;
