import React, { Component } from 'react';
import classnames from 'classnames';

export default class CustomTextarea extends Component {
  render() {
    const { name, label, ...rest } = this.props;
    const classes = classnames('custom-textarea');

    return (
      <div className={classes}>
        <textarea
          className="custom-textarea__textarea"
          id={name}
          name={name}
          placeholder={label}
          aria-label={label}
          {...rest}
        />
      </div>
    );
  }
}
