import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Errors from '../../Utils/Errors';
import Modal2 from '../../library/Modal2';
import Tutorial from '../../../api/tutorial';
import AppSelect from '../../Utils/AppSelect';
import CustomInput from '../../Utils/CustomInput';
import CustomRadioGroup from '../../Utils/CustomRadioGroup';
import intl from '../../../utils/intl';
import { getDefaultLaunchIn } from '../../../helpers/labHelper';
import useSaveBlock from '../../../hooks/useSaveBlock';

export default function EditLabModal(props) {
  const { block, afterSave, onHide } = props;
  const extraBlockProps = {
    app: block.app || Tutorial.TABLEAU,
    end_button_type: block.end_button_type || '',
    launch_in: block.launch_in || getDefaultLaunchIn(block.app || Tutorial.TABLEAU),
  };

  const { blockState, setBlockState, handleChange, errors, saveBlock } = useSaveBlock(block, extraBlockProps);

  const showLaunchInOption = () => getDefaultLaunchIn(blockState.app) === Tutorial.LAUNCH_IN_BROWSER;

  const handleAppChange = changes => {
    const newBlockState = { ...blockState, ...changes };
    setBlockState({ ...newBlockState, launch_in: getDefaultLaunchIn(newBlockState.app) });
  };

  const handleSubmit = e => {
    e.preventDefault();
    saveBlock(blockState, true, savedBlock => {
      if (afterSave) afterSave(savedBlock);
      onHide();
    });
  };

  const handleInputChange = name => handleChange(name, false, false);

  return (
    <Modal2
      size="medium"
      onHide={onHide}
      title={
        block.id ? <FormattedMessage id="tutorial.heading.edit" /> : <FormattedMessage id="tutorial.heading.create" />
      }
      onOk={handleSubmit}>
      <Errors errors={errors} />

      <CustomInput
        name="title"
        onChange={handleInputChange('title')}
        value={blockState.title}
        label={intl.formatMessage({ id: 'block.form.title' })}
      />

      <AppSelect block={blockState} onChange={handleAppChange} showTrailhead showGoogleColab />

      <CustomRadioGroup
        labelMessageId="tutorial.heading.endButtonOptions"
        choices={Tutorial.BUTTON_OPTIONS}
        name="end_button_type"
        selected={blockState.end_button_type}
        onChange={handleInputChange('end_button_type')}
      />

      {showLaunchInOption() && (
        <CustomRadioGroup
          labelMessageId="block.launchIn"
          choices={Tutorial.LAUNCH_OPTIONS}
          name="launch_in"
          selected={blockState.launch_in}
          onChange={handleInputChange('launch_in')}
        />
      )}
    </Modal2>
  );
}

EditLabModal.propTypes = {
  block: PropTypes.object.isRequired,
  onHide: PropTypes.func.isRequired,
  afterSave: PropTypes.func,
};
