import { connect } from 'react-redux';
import CopyComponentModalComponent from './CopyComponentModalComponent';
import { selectUnitFromComponent } from '../../../selectors/unitSelectors';
import { selectBootcampFromUnit } from '../../../selectors/bootcampSelectors';

const mapStateToProps = (state, ownProps) => {
  const unit = selectUnitFromComponent(state, ownProps.component);
  const bootcamp = unit && selectBootcampFromUnit(state, unit);

  return {
    unit,
    bootcamp,
  };
};

export default connect(mapStateToProps)(CopyComponentModalComponent);
