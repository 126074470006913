import { map as _map, has as _has } from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import update from 'immutability-helper';
import classnames from 'classnames';
import intl from '../../../utils/intl';
import CustomCheckbox from '../../Utils/CustomCheckbox';
import Icons from '../../Utils/Icons';
import Assignment from '../../../api/assignment';

export default function EditRubric({ assignment, setAssignment, rubricCriteria, setRubricCriteria }) {
  const changeAssignment = e => {
    const { target } = e;
    const { name } = target;
    const value = target.type === 'checkbox' ? target.checked : target.value;

    setAssignment({
      ...assignment,
      [name]: value,
    });
  };

  const changeCriterion = (e, row) => {
    const { target } = e;
    const { name } = target;
    const value = target.type === 'checkbox' ? target.checked : target.value;

    setRubricCriteria(update(rubricCriteria, { [row]: { [name]: { $set: value } } }));
  };

  const removeCriterion = (e, row) => {
    e.preventDefault();

    if (_has(rubricCriteria[row], 'id')) {
      setRubricCriteria(update(rubricCriteria, { [row]: { _destroy_or_disable: { $set: true } } }));
    } else {
      setRubricCriteria(update(rubricCriteria, { $splice: [[row, 1]] }));
    }
  };

  const addCriterion = e => {
    e.preventDefault();

    setRubricCriteria(update(rubricCriteria, { $push: [{ tmp_id: Math.random(), name: '' }] }));
  };

  const hideRemoveCriterion = rubricCriteria.length <= 1;

  return (
    <div className="edit-rubric">
      <table>
        <thead>
          <tr>
            <th scope="col"></th>
            <th scope="col"></th>
            {_map(Assignment.CRITERION_PROFICIENCY_LEVELS, attributeName => (
              <th key={attributeName} scope="col">
                <FormattedMessage id={`rubricCriteria.fields.${attributeName}`} />
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {_map(
            rubricCriteria,
            (criterion, row) =>
              !_has(criterion, '_destroy_or_disable') && (
                <tr key={criterion.id || criterion.tmp_id}>
                  <td className="buttons">
                    <button
                      type="button"
                      className={classnames('remove-button', hideRemoveCriterion && 'disabled')}
                      aria-label={intl.formatMessage({ id: 'aria.deleteRubric' })}
                      title={intl.formatMessage({ id: 'aria.deleteRubric' })}
                      onClick={e => removeCriterion(e, row)}>
                      <Icons.Remove />
                    </button>
                  </td>
                  <td>
                    <textarea name="name" value={criterion.name} onChange={e => changeCriterion(e, row)} />
                  </td>
                  {_map(Assignment.CRITERION_PROFICIENCY_LEVELS, attributeName => (
                    <td key={attributeName}>
                      <textarea
                        name={attributeName}
                        value={criterion[attributeName] || ''}
                        onChange={e => changeCriterion(e, row)}
                      />
                    </td>
                  ))}
                </tr>
              ),
          )}
        </tbody>
      </table>

      <button type="button" className="add-button" onClick={addCriterion}>
        <Icons.Add />
        <FormattedMessage id="rubricCriteria.buttons.add" />
      </button>

      <CustomCheckbox
        name="display_rubrics"
        onChange={changeAssignment}
        checked={assignment.display_rubrics}
        label={<FormattedMessage id="rubricCriteria.fields.displayRubrics" />}
      />

      <CustomCheckbox
        name="requires_grading"
        onChange={changeAssignment}
        checked={assignment.requires_grading}
        label={<FormattedMessage id="rubricCriteria.fields.requiresGrading" />}
      />
    </div>
  );
}

EditRubric.propTypes = {
  assignment: PropTypes.object.isRequired,
  setAssignment: PropTypes.func.isRequired,
};
