import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { fetchCohort } from '../actions/cohortActions';
import { selectCohort } from '../selectors/cohortSelectors';

/**
 * Fetch the cohort if not already present in redux.
 * Make sure to call this only once in a page preferably in the top level component
 * @param {string} lessonId
 */
export default function useFetchCohort(cohortId) {
  const cohort = useSelector(s => selectCohort(s, cohortId));
  const dispatch = useDispatch();

  useEffect(() => {
    if (!cohort && cohortId) {
      dispatch(fetchCohort(cohortId));
    }
  }, [cohortId]);

  return cohort;
}
