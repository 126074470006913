import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import ContentEditable from 'react-contenteditable';
import { selectIsEditMode } from '../../selectors/contextSelectors';
import { savePart } from '../../actions/partActions';
import { toastException } from '../../utils/toastHelper';
import useDebounce from '../../hooks/useDebounce';

export default function LessonPartHeader({ lesson, part, partIndex, totalParts }) {
  const [title, setTitle] = useState(part.title);
  const isEditMode = useSelector(selectIsEditMode);
  const dispatch = useDispatch();
  const showHeading = isEditMode || totalParts > 1;

  const debouncedSave = useDebounce(async newTitle => {
    try {
      const newPart = { id: part.id, lesson_id: lesson.id, title: newTitle };
      await savePart(newPart, lesson.id)(dispatch);
    } catch (err) {
      toastException(err, 'Unable to save part');
    }
  }, 500);

  const handleChange = e => {
    const { value } = e.target;

    setTitle(value);
    debouncedSave(value);
  };

  return (
    <header className="lesson-part__header">
      {showHeading && (
        <h3 className="lesson-part__heading lesson-part__heading--edit-mode">
          <FormattedMessage id="component.partIndex" values={{ index: partIndex + 1 }} />
        </h3>
      )}
      {isEditMode && (
        <ContentEditable
          className="lesson-part__title lesson-part__title--edit-mode"
          html={title || ''}
          tagName="h1"
          onChange={handleChange}
        />
      )}
      {!isEditMode && part.title && (
        <h1 dangerouslySetInnerHTML={{ __html: part.title }} className="lesson-part__title" />
      )}
    </header>
  );
}

LessonPartHeader.propTypes = {
  lesson: PropTypes.object,
  lessonProgress: PropTypes.object,
  part: PropTypes.object,
  partIndex: PropTypes.number,
  totalParts: PropTypes.number,
};
