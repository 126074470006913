import { last as _last } from 'lodash';
import { scroller } from 'react-scroll';
import { getProgressStatus } from '../utils/utils';

export const getLessonPartStatus = (lessonProgress, lesson, part) => {
  const partProgress = ((lessonProgress && lessonProgress.parts_progress) || {})[part.id] || {};
  const { isComplete: isLessonComplete } = getProgressStatus(lessonProgress);
  const isPartComplete = isLessonComplete || !!partProgress.completed_at;
  const partIds = (lesson && lesson.part_ids) || [];
  const currentPartId = (lessonProgress && lessonProgress.current_part_id) || partIds[0];

  return {
    isLessonComplete,
    isComplete: isPartComplete,
    isCurrent: currentPartId === part.id,
    isLast: _last(partIds) === part.id,
  };
};

export const scrollToPart = (partId, firstPartId, adaptiveLearningOn) => {
  if (window.location.hash) return;
  if (partId === firstPartId && adaptiveLearningOn) {
    scroller.scrollTo('adaptive-buttons', {
      smooth: true,
      offset: -280,
    });
  } else {
    scroller.scrollTo(`part_${partId}`, {
      smooth: true,
      offset: -80,
    });
  }
};
