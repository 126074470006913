import { map as _map } from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import CustomCheckbox from './CustomCheckbox';

const CustomRadioGroup = ({ choices, selected, labelMessageId, ...rest }) => {
  return (
    <div className="custom-radio-group">
      <fieldset>
        <legend>
          <FormattedMessage id={`${labelMessageId}Label`} />
        </legend>
        {_map(choices, choice => (
          <CustomCheckbox
            key={choice}
            inline
            type="radio"
            {...rest}
            checked={choice === selected}
            value={choice}
            label={<FormattedMessage id={`${labelMessageId}.${choice}`} />}
          />
        ))}
      </fieldset>
    </div>
  );
};

CustomRadioGroup.propTypes = {
  labelMessageId: PropTypes.string.isRequired,
  choices: PropTypes.arrayOf(PropTypes.string).isRequired,
  selected: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default CustomRadioGroup;
