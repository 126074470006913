import React, { Component } from 'react';
import { Route, Redirect, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { selectAuth } from '../../selectors/authSelectors';
import { areTokensValid } from '../../helpers/userAuth';

class PrivateRoute extends Component {
  render() {
    if (this.props.auth.signedIn || areTokensValid()) {
      return <Route {...this.props} Component={this.props.component} />;
    }

    return (
      <Redirect
        to={{
          pathname: '/login',
          state: { from: this.props.location },
        }}
      />
    );
  }
}

const mapStateToProps = state => ({
  auth: selectAuth(state),
});

export default connect(mapStateToProps, null, null, { pure: false })(withRouter(PrivateRoute));
