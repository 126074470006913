import React, { Component } from 'react';
import PropTypes from 'prop-types';
import EmbeddedContent from '../Utils/EmbeddedContent';

export default class BlockEmbedShow extends Component {
  render() {
    const { block } = this.props;

    return (
      <>
        <div className="section">
          {block.title && <h2 className="heading" dangerouslySetInnerHTML={{ __html: block.title }} />}
        </div>
        <EmbeddedContent block={block} />
      </>
    );
  }
}
BlockEmbedShow.propTypes = {
  block: PropTypes.object.isRequired,
};
