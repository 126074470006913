import { connect } from 'react-redux';
import QuizTabs from '../Quiz/QuizTabs';
import { selectQuizByBlock } from '../../selectors/quizSelectors';
import { saveBlock } from '../../actions/blockActions';
import { selectNextQuestions } from '../../selectors/questionSelectors';

const mapStateToProps = (state, ownProps) => {
  const { block } = ownProps;
  const quiz = selectQuizByBlock(state, block);
  const questions = selectNextQuestions(state, quiz?.id);

  return {
    quiz,
    numQuestions: questions.length,
  };
};

export default connect(mapStateToProps, {
  saveBlock,
})(QuizTabs);
