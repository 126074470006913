import { cloneDeep } from 'lodash';

// TODO rename data structure itself :
// ruleset.group[groupIndex].criteria[criteriaIndex].check
// to
// ruleset.rulesetCriteria[rulesetCriteriaIndex].rules[ruleIndex].checks

const findRulesetCriterion = ({ rulesetCriterionKey }, ruleset) => {
  return ruleset.group.findIndex(group => group.fieldKey === rulesetCriterionKey);
};

const findRule = ({ groupIndex, ruleKey }, ruleset) => {
  return ruleset.group[groupIndex].criteria.findIndex(crit => crit.fieldKey === ruleKey);
};

const findCheck = ({ groupIndex, criteriaIndex, checkKey }, ruleset) => {
  return ruleset.group[groupIndex].criteria[criteriaIndex].check.findIndex(ch => ch.fieldKey === checkKey);
};

export const updateRuleset = ({ fieldValue, ruleset }) => {
  return {
    ...ruleset,
    ...fieldValue,
  };
};

export const updateRulesetCriterion = ({ type, fieldValue, rulesetCriterionKey, ruleset }) => {
  const groupData = {
    type,
    criteria: [],
    ...fieldValue,
  };

  const updatedRuleset = cloneDeep(ruleset);

  // edit existing group
  if (rulesetCriterionKey) {
    const foundGroupIndex = updatedRuleset.group.findIndex(g => g.fieldKey === rulesetCriterionKey);
    // Is there a case when not found?
    if (foundGroupIndex >= 0) {
      updatedRuleset.group[foundGroupIndex] = {
        ...groupData,
        criteria: updatedRuleset.group[foundGroupIndex].criteria,
      };
    }
  } else {
    // add group
    updatedRuleset.group.push(groupData);
  }

  return updatedRuleset;
};

export const updateRule = ({ type, fieldValue, rulesetCriterionKey, ruleKey, ruleset }) => {
  const criteriaData = {
    type,
    check: [],
    ...fieldValue,
  };

  const updatedRuleset = cloneDeep(ruleset);

  const groupIndex = findRulesetCriterion({ rulesetCriterionKey }, ruleset);
  const criteriaIndex = findRule({ groupIndex, ruleKey }, ruleset);

  // edit existing criteria
  if (ruleKey) {
    if (criteriaIndex >= 0) {
      updatedRuleset.group[groupIndex].criteria[criteriaIndex] = {
        ...criteriaData,
        check: updatedRuleset.group[groupIndex].criteria[criteriaIndex].check,
      };
    }
  } else {
    // add criteria
    updatedRuleset.group[groupIndex].criteria.push(criteriaData);
  }

  return updatedRuleset;
};

export const updateCheck = ({ type, fieldValue, rulesetCriterionKey, ruleKey, checkKey, ruleset, values }) => {
  // merge location path and data source
  const { byName, byTitle, order, names, cellRange, cellAddress, dataSource } = fieldValue;

  let updatedLocation;
  const updatedRuleset = cloneDeep(ruleset);

  if (names) {
    updatedLocation = fieldValue.location.map((path, index) => {
      const isLastPath = fieldValue.location.length - 1 === index;

      if (!isLastPath) {
        const updatedDS = {
          type: 'byName',
          name: names[index],
        };
        return {
          within: path,
          dataSource: updatedDS,
        };
      }
      const updatedFinalDS = {
        type: dataSource[0],
        order,
        name: byName || byTitle || cellAddress || cellRange,
        cellRange,
        cellAddress,
      };
      return {
        within: path,
        dataSource: updatedFinalDS,
      };
    });
  } else {
    updatedLocation = values.otherProps.prevValues.location;
  }

  const checkData = {
    type,
    ...fieldValue,
    location: updatedLocation,
  };

  const groupIndex = findRulesetCriterion({ rulesetCriterionKey }, ruleset);
  const criteriaIndex = findRule({ groupIndex, ruleKey }, ruleset);
  const checkIndex = findCheck({ groupIndex, criteriaIndex, checkKey }, ruleset);

  // edit existing check
  if (checkKey) {
    if (checkIndex >= 0) {
      updatedRuleset.group[groupIndex].criteria[criteriaIndex].check[checkIndex] = checkData;
    }
  } else {
    // add check
    updatedRuleset.group[groupIndex].criteria[criteriaIndex].check.push(checkData);
  }

  return updatedRuleset;
};

export const removeRulesetCriterion = (keys, ruleset) => {
  const updatedRuleset = cloneDeep(ruleset);

  const groupIndex = findRulesetCriterion({ ...keys }, ruleset);

  if (groupIndex > -1) {
    updatedRuleset.group.splice(groupIndex, 1);
  }

  return updatedRuleset;
};

export const removeRule = (keys, ruleset) => {
  const updatedRuleset = cloneDeep(ruleset);
  const groupIndex = findRulesetCriterion({ ...keys }, ruleset);
  const ruleIndex = findRule({ groupIndex, ...keys }, ruleset);

  if (ruleIndex > -1) {
    updatedRuleset.group[groupIndex].criteria.splice(ruleIndex, 1);
  }

  return updatedRuleset;
};

export const removeCheck = (keys, ruleset) => {
  const updatedRuleset = cloneDeep(ruleset);
  const groupIndex = findRulesetCriterion({ ...keys }, ruleset);
  const criteriaIndex = findRule({ groupIndex, ...keys }, ruleset);
  const checkIndex = findCheck({ groupIndex, criteriaIndex, ...keys }, ruleset);

  if (criteriaIndex > -1) {
    updatedRuleset.group[groupIndex].criteria[criteriaIndex].check.splice(checkIndex, 1);
  }

  return updatedRuleset;
};
