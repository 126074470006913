import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

export default class SidebarLink extends Component {
  render() {
    const { Icon, tooltip, to } = this.props;

    return (
      <NavLink
        to={to}
        className="icons-group-menu__item"
        activeClassName="icons-group-menu__item---active"
        data-tip={tooltip}
        title={tooltip}
        aria-label={tooltip}>
        <Icon />
      </NavLink>
    );
  }
}
