import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';

import intl from '../../utils/intl';
import iconMachineHiccupImg from '../../styles/v2/images/lab/icon-machine-hiccup.png';

const ErrorBlock = ({ testId, icon, title, body, leftButtonText, onLeftButton, rightButtonHelpText }) => {
  return (
    <div className="error-block" data-testid={testId}>
      <div className="icon">
        <img alt="" src={icon} />
      </div>

      <div className="content">
        <div className="title" data-testid="title">
          {title}
        </div>

        {body && (
          <div className="body" data-testid="body">
            {body}
          </div>
        )}

        <div className="buttons">
          {leftButtonText && (
            <Button className="button button--left" role="button" onClick={onLeftButton}>
              {leftButtonText}
            </Button>
          )}

          {rightButtonHelpText && (
            <Button className="button button--right" role="link" target="_blank" href="https://help.pathstream.com/">
              {rightButtonHelpText}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

ErrorBlock.propTypes = {
  icon: PropTypes.string,
  title: PropTypes.string,
  body: PropTypes.string,
  leftButtonText: PropTypes.string,
  onLeftButton: PropTypes.func,
  testId: PropTypes.string,
  rightButtonHelpText: PropTypes.string,
};

ErrorBlock.defaultProps = {
  icon: iconMachineHiccupImg,
  title: intl.formatMessage({ id: 'common.errors.unhandled' }),
  body: intl.formatMessage({ id: 'common.errors.unhandledOptions' }),
  leftButtonText: intl.formatMessage({ id: 'common.errors.unhandledReload' }),
  onLeftButton: () => window.location.reload(false),
  rightButtonHelpText: intl.formatMessage({ id: 'common.getHelp' }),
};

export default ErrorBlock;
