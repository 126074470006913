import React, { Component } from 'react';
import PropTypes from 'prop-types';

// https://docs.videojs.com/tutorial-react.html
export default class VideoPlayer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      triggeredHalfwayEvent: false,
    };
  }

  getSource = src => {
    if (src && src.indexOf('/rte/content') === 0) {
      return `${src}?expiresIn=${24 * 60 * 60}`;
    }
    return src;
  };

  handleTimeUpdate = () => {
    const { onReachedHalfway } = this.props;
    const { triggeredHalfwayEvent } = this.state;
    if (triggeredHalfwayEvent) {
      return;
    }

    if (this.player.currentTime() > this.player.remainingTime()) {
      this.setState({ triggeredHalfwayEvent: true }, onReachedHalfway);
    }
  };

  componentDidMount() {
    const { src } = this.props;
    const options = {
      controls: true,
      sources: [
        {
          src: this.getSource(src),
        },
      ],
    };

    // instantiate Video.js
    this.player = window.videojs(this.videoNode, options, function onPlayerReady() {
      // console.log('onPlayerReady', this);
    });
    this.player.on('timeupdate', this.handleTimeUpdate);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.src !== this.props.src) {
      this.setState({ triggeredHalfwayEvent: false });
      this.player.src([{ src: this.getSource(nextProps.src) }]);
    }
  }

  // destroy player on unmount
  componentWillUnmount() {
    if (this.player) {
      this.player.dispose();
    }
  }

  // wrap the player in a div with a `data-vjs-player` attribute
  // so videojs won't create additional wrapper in the DOM
  // see https://github.com/videojs/video.js/pull/3856
  render() {
    return (
      <div style={{ width: '100%' }}>
        <div style={{ width: '100%', maxWidth: '400', margin: '0 auto' }}>
          <video
            ref={node => (this.videoNode = node)}
            className="video-js vjs-big-play-centered vjs-default-skin vjs-16-9"
          />
        </div>
      </div>
    );
  }
}
VideoPlayer.propTypes = {
  src: PropTypes.string.isRequired,
  onReachedHalfway: PropTypes.func,
};
