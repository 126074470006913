import React from 'react';
import { NavLink } from 'react-router-dom';
import StudentMenu from './StudentMenu';
import Icons from '../../../Utils/Icons';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import { Logo } from '../../../Utils/ImageIcons';

export const StudentNavBar = props => {
  const { user, shouldShowNavigation, showAdminImpersonation } = props;

  const handleReturnToSabrina = () => {
    const isStaging = window.location.hostname.includes('staging');

    let relocationUrl = isStaging
      ? 'https://team.staging-pathstream.com/admin/v2/'
      : 'https://team.path.stream/admin/v2/';

    if (process.env.NODE_ENV === 'development') {
      relocationUrl = 'http://localhost:4001/admin/v2';
    }
    props.signOut();
    window.location.href = relocationUrl;
  };

  const today = new Date().toISOString().split('T')[0];
  const access_date = user?.current_enrollment?.bootcamp_instance?.access_date;
  const start_date = user?.current_enrollment?.bootcamp_instance?.start_date;
  let canAccessCurrentEnrollment = access_date <= today || (access_date === null && start_date <= today);

  let firstName = user?.firstName || user?.name?.split(' ')[0];
  let lastName = user?.lastName || user?.name?.split(' ')[1];

  let bootcampId = user?.current_enrollment?.bootcamp_instance.bootcamp_id;
  let pathId = user?.current_enrollment?.path_id;
  if (shouldShowNavigation) {
    return (
      <div>
        {showAdminImpersonation && (
          <div className="admin-impersonation">
            <div className="icon-and-text">
              <div className="spy-icon">
                <Icons.Spy />
              </div>
              <div className="user-text">
                <FormattedMessage id="nav.impersonating" values={{ firstName: firstName, lastName: lastName }} />
              </div>
            </div>
            <div className="return-button">
              <button
                className="button-standard button-standard--short"
                onClick={handleReturnToSabrina}
                data-testid="back-to-sabrina">
                <FormattedMessage id="nav.backToSabrina" />
              </button>
            </div>
          </div>
        )}
        <div className="ps2-student-nav-container">
          <div className="ps2-logo-container">
            <Link to="/student/" data-testid="pathstream-student-nav-logo">
              <img src={Logo} className="ps2-large-icon" alt="Pathstream Logo"></img>
            </Link>
          </div>
          <div className="student-nav-flex-between">
            <div className="student-nav-links-container">
              <div className="student-nav-link-item-container">
                <NavLink className="ps2-student-nav-link" to="/student/" exact activeClassName="active-link">
                  <Icons.LineHome className="ps2-navigation-icon"></Icons.LineHome>
                  LEARNING HOME
                </NavLink>
              </div>
              {canAccessCurrentEnrollment && (
                <div className="student-nav-link-item-container">
                  <NavLink
                    className="ps2-student-nav-link"
                    to={`/student/bootcamps/${bootcampId}/paths/${pathId}?overview=1`}
                    exact
                    activeClassName="active-link">
                    <Icons.LineBook className="ps2-navigation-icon"></Icons.LineBook>
                    CURRENTLY LEARNING
                  </NavLink>
                </div>
              )}
              <div className="student-nav-link-item-container">
                <NavLink
                  className="ps2-student-nav-link"
                  to={{ pathname: 'https://pathstream.com/student_support/' }}
                  target="_blank">
                  <Icons.LineInfo className="ps2-navigation-icon"></Icons.LineInfo>
                  SUPPORT CENTER
                </NavLink>
              </div>
            </div>
            <div className="student-nav-ps2-avatar-container">
              <StudentMenu currentUser={user} />
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return null;
  }
};
