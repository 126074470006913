import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Modal, Table } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import ModalFooter from '../../library/ModalFooter';
import {
  fetchBootcampInstanceSections,
  saveBootcampInstanceSection,
  updateBootcampInstanceSection,
} from '../../../actions/bootcampInstanceSectionActions';
import { selectBootcampInstanceSections } from '../../../selectors/bootcampInstanceAssignmentSelectors';
import { fetchCohort } from '../../../actions/cohortActions';
import { map as _map } from 'lodash';
import { selectEntitiesForBootcamp } from '../../../selectors/cohortBootcampSelectors';
import EditSectionDueDateModal from './EditSectionDueDateModal';
import { getWeekFromDate } from '../../../utils/utils';
import moment from 'moment';

const useStore = cohort => {
  const { sectionsByPath } = useSelector(state => selectEntitiesForBootcamp(state, cohort.bootcamp_id));
  const allBootcampInstanceSections = useSelector(selectBootcampInstanceSections);

  const bootcampInstanceSections = allBootcampInstanceSections
    ? Object.values(allBootcampInstanceSections).filter(
        bootcampInstanceSection => bootcampInstanceSection.bootcamp_instance_id === cohort.id,
      )
    : [];
  return {
    sectionsByPath,
    bootcampInstanceSections,
  };
};

const CourseSectionDueDatesModal = ({ cohort, onHide }) => {
  const dispatch = useDispatch();
  const startDate = moment(cohort.start_date, 'YYYY-MM-DD').startOf('day');

  useEffect(() => {
    fetchCohort(cohort.id)(dispatch);
    fetchBootcampInstanceSections(cohort.id)(dispatch);
  }, [cohort, dispatch]);

  const { bootcampInstanceSections, sectionsByPath } = useStore(cohort);

  const endDate = moment(cohort.end_date, 'YYYY-MM-DD');

  let sections = [];

  for (const pathId in sectionsByPath) {
    if (sectionsByPath.hasOwnProperty(pathId)) {
      if (sections.length === 0) {
        sections = sectionsByPath[pathId];
      }
    }
  }

  sections = sections.map(section => {
    section.key = section.id;
    return section;
  });

  const saveOrUpdateSectionDueDate = (bootcampInstanceSection, section) => (dueDate, weekLength) => {
    if (bootcampInstanceSection) {
      updateBootcampInstanceSection({
        id: bootcampInstanceSection.id,
        section_due_at: dueDate,
        week_length: weekLength,
      })(dispatch);
    } else if (section.id) {
      saveBootcampInstanceSection({
        bootcamp_instance_id: cohort.id,
        section_id: section.id,
        section_due_at: dueDate,
        week_length: weekLength,
      })(dispatch);
    }
  };

  const columns = [
    {
      title: 'Section',
      dataIndex: 'title',
      key: 'title',
      align: 'left',
      render: (text, record) => <div key={`title-${record.id}`}>{`${text}`}</div>,
    },
    {
      title: 'Due Date',
      key: 'date',
      dataIndex: 'date',
      align: 'center',
      render: (_text, record) => {
        const bootcampInstanceSection = bootcampInstanceSections.find(
          bootcampInstanceSection => bootcampInstanceSection.section_id === record.id,
        );

        return (
          <div key={`action-${cohort.id}-${record.id}`} className="dueDateCohortContainer">
            <EditSectionDueDateModal
              key={`due-date-${cohort.id}-${record.id}`}
              bootcampInstanceSection={bootcampInstanceSection}
              handleOk={saveOrUpdateSectionDueDate(bootcampInstanceSection, record)}
            />
          </div>
        );
      },
    },
    {
      title: 'Week Due',
      dataIndex: 'week',
      key: 'week',
      render: (_text, record) => {
        const bootcampInstanceSection = bootcampInstanceSections.find(
          bootcampInstanceSection => bootcampInstanceSection.section_id === record.id,
        );
        const dueAt = bootcampInstanceSection ? bootcampInstanceSection.section_due_at : endDate;
        const week = getWeekFromDate(dueAt, startDate);

        return (
          <div>
            <div>Week {week}</div>
          </div>
        );
      },
    },
    {
      title: 'Week Length',
      dataIndex: 'weekLength',
      key: 'weekLength',
      render: (_text, record) => {
        const bootcampInstanceSection = bootcampInstanceSections.find(
          bootcampInstanceSection => bootcampInstanceSection.section_id === record.id,
        );
        const weekLength = bootcampInstanceSection ? bootcampInstanceSection.week_length : 1;

        return (
          <div>
            <div>{weekLength}</div>
          </div>
        );
      },
    },
  ];

  return (
    <Modal
      centered
      title={<FormattedMessage id="cohort.manageCourseSectionDueDates" />}
      visible
      onCancel={onHide}
      footer={
        <ModalFooter
          showCancel
          cancelTitle={<FormattedMessage id="common.close" />}
          onCancel={onHide}
          okTitle={<FormattedMessage id="common.save" />}
        />
      }>
      <Table columns={columns} dataSource={sections} pagination={false} rowClassName="editable-row" />
    </Modal>
  );
};

CourseSectionDueDatesModal.propTypes = {
  cohort: PropTypes.object.isRequired,
  onHide: PropTypes.func.isRequired,
};

export default CourseSectionDueDatesModal;
