import React from 'react';
import CompletedCourseCard from './CompletedCourseCard';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

const BootcampCourseCard = props => {
  let { enrollment, bootcamp, url } = props;

  if (!!enrollment?.completed_at) {
    return <CompletedCourseCard title={bootcamp.title} url={url} type="complete" />;
  }
  return (
    <div className="labels-and-card">
      <div className="labels">
        {bootcamp.isCurrentEnrollment && (
          <div className="current-label">
            <FormattedMessage id="bootcamp.currentlyLearning" />
          </div>
        )}
      </div>

      <div className="currently-enrolled-course">
        <div className="words">
          <div className="lite-course-title">{bootcamp.title}</div>
        </div>
        <div className="button">
          <Link to={url} className={'button-standard button-standard--short'} aria-label={bootcamp.title}>
            <FormattedMessage id="bootcamp.goTo" />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default BootcampCourseCard;
