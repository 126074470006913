import { LAB_PANEL } from '../../actions/constants';

const INITIAL_STATE = {
  position: {
    x: 0,
    y: 0,
    width: 600,
    height: 300,
  },
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case LAB_PANEL.SET_SIZE:
      return {
        ...state,
        position: action.payload,
      };

    default:
      return state;
  }
}
