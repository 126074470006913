import { size as _size, some as _some } from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import classnames from 'classnames';
import QuestionContainer from './QuestionContainer';
import intl from '../../utils/intl';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';

export default class Questions extends Component {
  constructor(props) {
    super(props);
  }

  scrollToTop = () => {
    const HEADER_HEIGHT = 200;

    this.props.knowledgeCheckRef.current.scrollIntoView();
    window.scrollBy(0, -HEADER_HEIGHT);
  };

  hasNextQuestion = () => this.props.questionIndex + 1 < _size(this.props.questions);

  completeQuiz = () => {
    const { quizProgress, submitQuizProgress, questionProgresses } = this.props;

    if (!quizProgress || quizProgress.completed_at) {
      return;
    }

    // make sure all the questions have been answered
    if (_some(questionProgresses, { completed_at: null })) {
      return;
    }

    submitQuizProgress(quizProgress);
  };

  handleStepQuestion = increment => {
    this.props.updateQuestionIndex(increment);
    this.scrollToTop();
  };

  renderQuizSteps(hasPreviousQuestion) {
    return (
      <div className="steps" aria-labelledby="question-progress">
        <button
          type="button"
          disabled={!hasPreviousQuestion}
          onClick={() => this.handleStepQuestion(-1)}
          className={classnames('steps__arrow', !hasPreviousQuestion && 'disabled')}
          aria-label={intl.formatMessage({ id: 'question.previous' })}>
          <LeftOutlined className="step-icon" />
        </button>

        <button
          type="button"
          disabled={!this.hasNextQuestion()}
          onClick={() => this.handleStepQuestion(1)}
          className={classnames('steps__arrow', !this.hasNextQuestion() && 'disabled')}
          aria-label={intl.formatMessage({ id: 'question.next' })}>
          <RightOutlined className="step-icon" />
        </button>
      </div>
    );
  }

  renderNext = () => {
    const { questions, questionIndex } = this.props;

    const hasNextQuestion = questionIndex < questions.length - 1;
    const hasPreviousQuestion = questionIndex > 0;

    if (!hasNextQuestion) {
      return '';
    }

    return (
      <div className="answer-buttons">
        {this.renderQuizSteps(hasPreviousQuestion)}
        <button
          type="button"
          className="button-standard button-standard--green button-standard--short"
          onClick={() => this.handleStepQuestion(1)}>
          <FormattedMessage id="common.next" />
        </button>
      </div>
    );
  };

  render() {
    const { questions, questionProgressesEnabled, questionIndex } = this.props;

    const hasPreviousQuestion = questionIndex > 0;
    const question = questions[questionIndex];

    return (
      <QuestionContainer
        {...{ questionProgressesEnabled, question }}
        afterSubmit={this.completeQuiz}
        renderNext={this.renderNext}
        renderQuestionsNavigation={() => this.renderQuizSteps(hasPreviousQuestion)}
      />
    );
  }
}

Questions.propTypes = {
  questions: PropTypes.array,
  questionProgresses: PropTypes.object,
  questionProgressesEnabled: PropTypes.bool,
  quizProgress: PropTypes.object,
  submitQuizProgress: PropTypes.func.isRequired,
  knowledgeCheckRef: PropTypes.object.isRequired,
};

Questions.defaultProps = {
  questionProgressesEnabled: true,
};
