import { isEmpty as _isEmpty } from 'lodash';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ProjectTile from './ProjectTile';
import { selectCurrentUser } from '../../selectors/authSelectors';
import { destroySectionUnit } from '../../actions/sectionUnitActions';
import { saveUnit } from '../../actions/unitActions';
import { selectAssignment } from '../../selectors/assignmentSelectors';
import { selectEnrollmentForBootcamp } from '../../selectors/enrollmentSelectors';
import { selectCohortForEnrollment } from '../../selectors/cohortSelectors';
import { selectProgressForAssignment } from '../../selectors/assignmentProgressSelectors';
import { selectLtiProgressForAssignment } from '../../selectors/ltiAssignmentProgressSelectors';
import { canLearn } from '../../helpers/userAuth';

const mapStateToProps = (state, ownProps) => {
  const { unit, bootcamp } = ownProps;

  const assignment = selectAssignment(state, unit && unit.assignment_id);
  const enrollment = selectEnrollmentForBootcamp(state, bootcamp.id);
  const cohort = enrollment && selectCohortForEnrollment(state, enrollment.id);
  const currentUser = selectCurrentUser(state);
  const isLtiStudent = canLearn(currentUser) && !_isEmpty(currentUser.lti_user_id);
  const assignmentProgress = selectProgressForAssignment(state, unit && unit.assignment_id);

  let ltiProgress;

  if (isLtiStudent) {
    ltiProgress = selectLtiProgressForAssignment(
      state,
      assignmentProgress && assignmentProgress.context,
      assignmentProgress && assignmentProgress.assignment_id,
    );
  }
  return {
    assignment,
    enrollment,
    cohort,
    assignmentProgress,
    ltiProgress,
    isLtiStudent,
  };
};

export default connect(mapStateToProps, { destroySectionUnit, saveUnit })(withRouter(ProjectTile));
