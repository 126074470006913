import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import ProgressCircle from './ProgressCircle';

const CourseProgress = ({
  percentageComplete,
  message,
  numerator,
  denominator,
}) => {
  message = message || "COURSE PROGRESS:";

  return (
    <div className='total-progress' data-testid="course-progress">
      <div className='progress-pill'>
        <ProgressCircle percentageComplete={percentageComplete}/>
        <div className='progress-text'>{message}</div>
        <div className='fraction'>
          {numerator}/{denominator}
        </div>
      </div>
    </div>
  );
};

CourseProgress.propTypes = {
  percentageComplete: PropTypes.number.isRequired,
  numerator: PropTypes.number.isRequired,
  denominator: PropTypes.number.isRequired,
  message: PropTypes.string,
};

export default CourseProgress;
