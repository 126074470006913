import React from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';

// Dates supplied to date picker should be in UTC
// These conversions are for showing the date picker as if user is in UTC time zone
//   and also for storing the date in UTC
// https://github.com/Hacker0x01/react-datepicker/issues/1787
function convertUTCToLocalDate(utcDate) {
  if (!utcDate) {
    return utcDate;
  }
  return new Date(utcDate.getUTCFullYear(), utcDate.getUTCMonth(), utcDate.getUTCDate());
}

function convertLocalToUTCDate(localDate) {
  if (!localDate) {
    return localDate;
  }
  return new Date(Date.UTC(localDate.getFullYear(), localDate.getMonth(), localDate.getDate()));
}

export default function CustomDatePicker({ name, label, startDate, endDate, selected, onChange, ...rest }) {
  const CustomDatePickerInput = React.forwardRef((props, ref) => {
    return (
      <div>
        <input
          ref={ref}
          className="custom-input__input"
          id={name}
          name={name}
          type="text"
          placeholder={label}
          aria-label={label}
          {...props}
          autoComplete="off"
        />
        <label className="custom-input__label" htmlFor={name}>
          {label}
        </label>
      </div>
    );
  });

  return (
    <div className="custom-input">
      <DatePicker
        customInput={<CustomDatePickerInput />}
        className="custom-input__input"
        id={name}
        name={name}
        placeholder={label}
        aria-label={label}
        startDate={convertUTCToLocalDate(startDate)}
        endDate={convertUTCToLocalDate(endDate)}
        selected={convertUTCToLocalDate(selected)}
        onChange={date => onChange(convertLocalToUTCDate(date))}
        {...rest}
      />
    </div>
  );
}

CustomDatePicker.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};
