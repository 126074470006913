import React, { useState } from 'react';
import PropTypes from 'prop-types';
import UserPicture from '../../Utils/UserPicture';
import SingleTextInputForm from '../../Utils/SingleTextInputForm';

export default function NewComment({ user, saveComment, disabled }) {
  const [nonce, setNonce] = useState(Date.now()); // use nonce to regenerate the input field on submission

  const handleSubmit = value => saveComment(value).then(() => setNonce(Date.now()));

  return (
    <div className="input-wrapper">
      <div className="input">
        <div className="icon">
          <UserPicture user={user} className="user-profile-picture" />
        </div>
        <SingleTextInputForm key={nonce} onSubmit={handleSubmit} disabled={disabled} />
      </div>
    </div>
  );
}

NewComment.propTypes = {
  user: UserPicture.propTypes.user,
  saveComment: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

NewComment.defaultProps = {
  disabled: false,
};
