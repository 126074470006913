import { connect } from 'react-redux';
import { selectProgramCohortEntities } from '../../../../selectors/programCohortSelectors';
import { selectCohortForEnrollment } from '../../../../selectors/cohortSelectors';
import { selectBootcamp, selectBootcamps } from '../../../../selectors/bootcampSelectors';
import { selectCohorts } from '../../../../selectors/cohortSelectors';
import { fetchCohorts } from '../../../../actions/cohortActions';

import { withRouter } from 'react-router-dom';
import CurrentlyEnrolled from './CurrentlyEnrolled';

const mapStateToProps = (state, ownProps) => {
  const { enrollments, currentUser } = ownProps;
  const programCohorts = selectProgramCohortEntities(state);
  const bootcamps = selectBootcamps(state);
  const cohorts = selectCohorts(state);

  const enrollmentsWithoutProgramCohort = enrollments
    .filter(enrollment => enrollment.program_cohort_id === null)
    .sort((a, b) => {
      if (a.completed_at && b.completed_at) {
        return new Date(a.created_at) - new Date(b.created_at);
      } else if (a.completed_at) {
        return 1;
      } else if (b.completed_at) {
        return -1;
      } else {
        return new Date(b.created_at) - new Date(a.created_at);
      }
    });

  let filteredCohorts = enrollmentsWithoutProgramCohort.flatMap(enrollment => {
    let matches = cohorts.filter(cohort => cohort.id === enrollment.enrollable_id);
    return matches;
  });

  programCohorts &&
    Object.values(programCohorts).forEach(cohort => {
      let programCohortId = cohort.program_cohort_id;
      let matches = enrollments.filter(enrollment => enrollment.program_cohort_id === programCohortId);

      matches.forEach(match => {
        const cohort = selectCohortForEnrollment(state, match.id);
        const bootcamp = selectBootcamp(state, cohort.bootcamp_id);

        match.cohort = {
          id: cohort.id,
          accessDate: cohort.access_date,
          startDate: cohort.start_date,
          endDate: cohort.end_date,
          cohort_title: cohort.cohort_title, //snake case so I can use the cohortTitle helper
          display_cohort_title: cohort.display_cohort_title, //snake case so I can use the cohortTitle helper
          isCurrentEnrollment: cohort.bootcamp_id === currentUser.current_enrollment.bootcamp_instance.bootcamp_id,
          bootcampId: bootcamp.id,
          bootcampTitle: bootcamp.title,
          optional: cohort.optional,
          bootcampInstanceType: cohort.bootcampInstanceType,
        };
      });
      let orderedMatches = matches.sort((a, b) => {
        return new Date(a.cohort.startDate) - new Date(b.cohort.startDate);
      });
      cohort.enrollments = orderedMatches;
    });

  return {
    currentUser,
    programCohorts,
    bootcamps,
    cohorts,
    filteredCohorts,
  };
};

export default withRouter(connect(mapStateToProps, { fetchCohorts })(CurrentlyEnrolled));
