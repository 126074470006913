import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import ProjectUpload from '../Author/Steps/ProjectUpload';
import Lab from './Lab';
import { allowProjectUpload, isTrailheadApp, usesProjectUrl } from '../../helpers/labHelper';
import EditQuiz from '../Author/Quizzes/EditQuiz';
import { LabAreaIntro } from './LabIntro';
import { selectIsEditMode } from '../../selectors/contextSelectors';

const LabPageContent = ({
  tutorial,
  bootcamp,
  steps,
  renderQuiz,
  QuizComponent,
  questionProgressesEnabled,
  ...rest
}) => {
  const isEditMode = useSelector(selectIsEditMode);

  const wrapperStyle = {};
  if (tutorial && isTrailheadApp(tutorial.app)) {
    wrapperStyle.width = '100%';
  }

  const getProjectUrl = () => {
    let projectUrl = null;
    if (tutorial && usesProjectUrl(tutorial.app)) {
      projectUrl = tutorial.project_url;
    } else if (steps.length > 0) {
      projectUrl = steps[0].project_url;
    }
    return projectUrl;
  };

  return (
    <section className="new-lab-page__content" style={wrapperStyle}>
      <LabAreaIntro />
      <div className="new-lab-page__wrapper">
        {isEditMode && (
          <div className="lab-image" style={{ padding: 20 }}>
            {allowProjectUpload(tutorial.app) &&
              steps.map(step => (
                <div key={step.id}>
                  <ProjectUpload step={step} attr="project_url" app={tutorial.app} bootcamp={bootcamp} />
                </div>
              ))}
            {renderQuiz(EditQuiz)}
          </div>
        )}
        {!isEditMode && (
          <div className="lab-image">
            {renderQuiz(QuizComponent, { questionProgressesEnabled })}
            <Lab labInteractable={tutorial} projectUrl={getProjectUrl()} {...{ ...rest, bootcamp, steps }} />
          </div>
        )}
      </div>
    </section>
  );
};

LabPageContent.propTypes = {
  ...Lab.propTypes,
  tutorial: PropTypes.object,
  bootcamp: PropTypes.object,
  steps: PropTypes.array,
  renderQuiz: PropTypes.func.isRequired,
  QuizComponent: PropTypes.object.isRequired,
  questionProgressesEnabled: PropTypes.bool,
};

LabPageContent.defaultProps = {
  questionProgressesEnabled: true,
};

export default LabPageContent;
