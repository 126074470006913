import { connect } from 'react-redux';
import CohortReports from './CohortReports';
import { selectCohort } from '../../selectors/cohortSelectors';

const mapStateToProps = (state, ownProps) => {
  const { params } = ownProps.match;
  const { cohortId } = params;
  const cohort = cohortId && selectCohort(state, cohortId);

  return {
    cohort,
  };
};

export default connect(mapStateToProps)(CohortReports);
