import { dynamicJSLoad } from '../../helpers/dynamicJSLoadHelper';

export const loadGoogleAPI = () =>
  new Promise((resolve, reject) => {
    const load = dynamicJSLoad('google-API-autograder-js', 'https://apis.google.com/js/api.js');
    try {
      load(resolve);
    } catch (e) {
      reject(e);
    }
  });
