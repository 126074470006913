import React from 'react';
import BootcampCourseCardContainer from './BootcampCourseCardContainer';
import PathstreamUser3 from '../../../../styles/v2/images/pathstream-user3.svg';
import { FormattedMessage } from 'react-intl';

const CurrentlyEnrolledBootcamps = props => {
  let { bootcamps, cohorts, filteredCohorts, currentUser } = props;

  let filteredBootcamps = filteredCohorts.map(cohort => {
    let match = Object.values(bootcamps).filter(bootcamp => cohort.bootcamp_id === bootcamp.id)[0];
    let bootcamp = {
      ...match,
      isCurrentEnrollment: cohort.bootcamp_id === currentUser.current_enrollment.bootcamp_instance.bootcamp_id,
    };

    return bootcamp;
  });

  return (
    <div className="currently-enrolled bootcamp">
      <div className="flex-top-of-program-cohort-container bootcamp">
        <div className="flex-text-program-info">
          <div className="program-text">
            <div className="grey-text ">
              <FormattedMessage id="learningHome.courses" />
              <img className="pathstream-user" alt="" src={PathstreamUser3} />
            </div>
            <div className="optional-label">
              <FormattedMessage id="bootcamp.optionalWP" />
            </div>
          </div>
        </div>
      </div>
      <div className="flex-bootcamp-course-cards">
        {filteredBootcamps.map(bootcamp => {
          return (
            <BootcampCourseCardContainer
              bootcamp={bootcamp}
              cohorts={cohorts}
              filteredCohorts={filteredCohorts}
              key={bootcamp.id}
            />
          );
        })}
      </div>
    </div>
  );
};

export default CurrentlyEnrolledBootcamps;
