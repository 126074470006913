import { clone as _clone } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Modal } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { toastSuccess } from '../../../utils/toastHelper';
import Errors from '../../Utils/Errors';
import { sortBootcamps } from '../../../helpers/bootcampHelper';
import { selectBootcamps } from '../../../selectors/bootcampSelectors';
import { saveCohort, fetchCohorts } from '../../../actions/cohortActions';
import ModalFooter from '../../library/ModalFooter';
import { selectCurrentUser } from '../../../selectors/authSelectors';
import { canAdministrate } from '../../../helpers/userAuth';
import CohortForm from './CohortForm';
import {
  selectCreditTypes,
  selectModalities,
  selectPartnerTypes,
  selectPsSemesters,
} from '../../../selectors/cohortLookupSelectors';
import { fetchCohortLookups } from '../../../actions/cohortLookupActions';
import { selectSchools } from '../../../selectors/schoolSelectors';

const EditCohortModal = ({ cohort, onHide, ltiConsumers }) => {
  const currentUser = useSelector(selectCurrentUser);
  const isAdmin = canAdministrate(currentUser);
  const bootcamps = useSelector(state => selectBootcamps(state));
  const partnerTypes = useSelector(state => selectPartnerTypes(state));
  const psSemesters = useSelector(state => selectPsSemesters(state));
  const modalities = useSelector(state => selectModalities(state));
  const creditTypes = useSelector(state => selectCreditTypes(state));
  const schools = useSelector(selectSchools);

  const schoolOptions = useMemo(
    () =>
      schools.map(school => ({
        ...school,
        value: school.id,
        label: school.name,
      })),
    [schools],
  );

  const mapLookupOptions = lookup =>
    lookup.map(item => ({
      ...item,
      value: item.id,
      label: item.name,
    }));

  const cohortLookups = {
    modalities: mapLookupOptions(modalities),
    ps_semesters: mapLookupOptions(psSemesters),
    partner_types: mapLookupOptions(partnerTypes),
    credit_types: mapLookupOptions(creditTypes),
  };

  const dispatch = useDispatch();

  const convertStringToDate = dateString => {
    const [year, month, date] = dateString.split('-').map(num => parseInt(num));
    // months are based 0 in Date object
    return new Date(Date.UTC(year, month - 1, date));
  };

  // eslint-disable-next-line no-shadow
  const sanitizeCohort = cohort => {
    if (cohort.start_date && !(cohort.start_date instanceof Date)) {
      cohort.start_date = convertStringToDate(cohort.start_date);
    }

    if (cohort.end_date && !(cohort.end_date instanceof Date)) {
      cohort.end_date = convertStringToDate(cohort.end_date);
    }
    if (cohort.lti_context) {
      cohort.lti_context = cohort.lti_context.join('\n');
    } else {
      cohort.lti_context = '';
    }
    return cohort;
  };

  const [stateCohort, setStateCohort] = useState(sanitizeCohort(_clone(cohort)));
  const [errors, setErrors] = useState([]);

  const handleChange = e => {
    const { target } = e;
    const { name } = target;
    const value = target.type === 'checkbox' ? target.checked : target.value;

    setStateCohort({
      ...stateCohort,
      [name]: value,
    });
  };

  const handleSelectChange = name => option => {
    const value = option === null ? null : option.value;
    let updatedState = {
      [name]: value,
    };

    if (name === 'school_id' && !option.logo) {
      updatedState['display_school_logo'] = false;
    }

    setStateCohort({
      ...stateCohort,
      ...updatedState,
    });
  };

  const handleDateChange = name => date => {
    setStateCohort({
      ...stateCohort,
      [name]: date,
    });
  };

  const handleSubmit = async e => {
    e.preventDefault();

    try {
      // eslint-disable-next-line camelcase
      const lti_context = stateCohort.lti_context.split('\n');

      dispatch(saveCohort({ ...stateCohort, lti_context }))
        .then(() => {
          toastSuccess('Cohort saved');
          onHide();
          dispatch(fetchCohorts());
          dispatch(fetchCohortLookups(['partnerTypes', 'modalities', 'creditTypes', 'psSemesters']));
        })
        .catch(err => {
          // eslint-disable-next-line no-console
          console.log('ERROR', err);
          setErrors(err.value.errors);
        });
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error('Error saving LTI Consumer', err);
      setErrors(err.errors || ['Unable to save LTI Consumer']);
    }
  };

  const getBootcampOptions = () =>
    sortBootcamps(bootcamps).map(bootcamp => ({
      label: bootcamp.title + (bootcamp.version ? ` > ${bootcamp.version}` : ''),
      value: bootcamp.id,
    }));

  const [communityCollegeOptionId, setCommunityCollegeOptionId] = useState(null);
  useEffect(() => {
    const communityCollegeOption = partnerTypes.find(partnerType => partnerType.name === 'Community College');
    if (communityCollegeOption) {
      setCommunityCollegeOptionId(communityCollegeOption.id);
    }
  }, [partnerTypes]);

  const [showIsSponsored, setShowIsSponsored] = useState(false);
  useEffect(() => {
    if (communityCollegeOptionId) {
      if (stateCohort.partner_type_id === communityCollegeOptionId) {
        setShowIsSponsored(true);
      } else {
        setShowIsSponsored(false);
        setStateCohort({
          ...stateCohort,
          is_sponsored: false,
        });
      }
    }
  }, [stateCohort.partner_type_id, cohort, communityCollegeOptionId]);

  const getLtiConsumerKey = cohort => {
    if (cohort.school_id) {
      const school = schools.find(school => school.id === cohort.school_id);
      if (school) {
        const ltiConsumer = ltiConsumers.find(ltiConsumer => ltiConsumer.id === school.lti_consumer_id);
        return ltiConsumer.consumer_key;
      }
    }
  };

  const ltiConsumerKey = getLtiConsumerKey(stateCohort);

  return (
    <Modal
      centered
      title={
        stateCohort.id ? <FormattedMessage id="cohort.heading.edit" /> : <FormattedMessage id="cohort.heading.create" />
      }
      visible
      onOk={handleSubmit}
      onCancel={onHide}
      footer={
        <ModalFooter
          showCancel
          cancelTitle={<FormattedMessage id="common.cancel" />}
          onCancel={onHide}
          okTitle={<FormattedMessage id="common.save" />}
          onOk={handleSubmit}
        />
      }>
      <Errors errors={errors} />
      <CohortForm
        ltiConsumerKey={ltiConsumerKey}
        schoolOptions={schoolOptions}
        getBootcampOptions={getBootcampOptions}
        cohort={cohort}
        stateCohort={stateCohort}
        isAdmin={isAdmin}
        showIsSponsored={showIsSponsored}
        cohortLookups={cohortLookups}
        handleSelectChange={handleSelectChange}
        handleChange={handleChange}
        handleDateChange={handleDateChange}
      />
    </Modal>
  );
};

EditCohortModal.propTypes = {
  cohort: PropTypes.object.isRequired,
  ltiConsumers: PropTypes.array.isRequired,
  onHide: PropTypes.func.isRequired,
};

export default EditCohortModal;
