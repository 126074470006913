import { values as _values } from 'lodash';
import createCachedSelector from 're-reselect';
import { selectComponentProgressEntities } from './componentProgressSelectors';
import { selectSectionEntities } from './sectionSelectors';
import { selectUnitEntities } from './unitSelectors';
import Unit from '../api/unit';
import { getProgressStatus } from '../utils/utils';

export const selectSectionProgressEntities = state => {
  return state.entities.sectionProgresses;
};

export const selectSectionProgress = createCachedSelector(
  [selectSectionProgressEntities, (_state, sectionProgressId) => sectionProgressId],
  (sectionProgressEntities, sectionProgressId) => {
    return sectionProgressEntities[sectionProgressId];
  },
)((_state, sectionProgressId) => sectionProgressId);

export const selectProgressForSection = createCachedSelector(
  [selectSectionProgressEntities, (_state, sectionId) => sectionId],
  (sectionProgressEntities, sectionId) => {
    return _values(sectionProgressEntities).find(sectionProgress => sectionProgress.section_id === sectionId);
  },
)((_state, sectionId) => sectionId);

export const selectSectionProgressesForPathProgress = createCachedSelector(
  [selectSectionProgressEntities, (_state, pathProgressId) => pathProgressId],
  (sectionProgressEntities, pathProgressId) => {
    return _values(sectionProgressEntities).filter(sp => sp.path_progress_id === pathProgressId);
  },
)((_state, pathProgressId) => pathProgressId);

export const selectCompletedCounts = createCachedSelector(
  [
    selectSectionProgressEntities,
    selectComponentProgressEntities,
    selectSectionEntities,
    selectUnitEntities,
    (_state, sectionProgressId) => sectionProgressId,
  ],
  (sectionProgressEntities, componentProgressEntities, sectionEntities, unitEntities, sectionProgressId) => {
    const sectionProgress = sectionProgressEntities[sectionProgressId];

    if (!sectionProgress) {
      return;
    }
    const section = sectionEntities[sectionProgress.section_id];
    const counts = {
      total: 0,
      completed: 0,
    };

    section.unit_ids.forEach(unitId => {
      const unit = unitEntities[unitId];
      if (unit && unit.unit_type === Unit.MODULE) {
        unit.component_ids.forEach(componentId => {
          const componentProgress = _values(componentProgressEntities).find(cp => cp.component_id === componentId);

          counts.total += 1;
          if (getProgressStatus(componentProgress).isComplete) {
            counts.completed += 1;
          }
        });
      }
    });

    return counts;
  },
)((_state, sectionProgressId) => sectionProgressId);
