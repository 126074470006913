import React from 'react';
import * as typeformEmbed from '@typeform/embed';

export const Embed = ({ uniqueId, typeformUrl }) => {
  const typeformRef = React.useRef(null);
  React.useEffect(() => {
    typeformEmbed.makeWidget(typeformRef.current, typeformUrl);
  }, [typeformUrl]);

  return <div className="typeform-survey-embed" id={`typeform-survey-${uniqueId}`} ref={typeformRef} />;
};
