import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import OptionsPopup from '../../Utils/OptionsPopup';
import showModal from '../../../utils/showModal';
import EditLtiConsumerModal from './EditLtiConsumerModal';

export default function LtiConsumerRow({ ltiConsumer, onSave }) {
  const handleEdit = () => {
    showModal(EditLtiConsumerModal, { ltiConsumer, onSave });
  };

  return (
    <div className="user-details">
      <div className="grid-row user-details-items clear">
        <div className="md-10 user-details-item">
          <div className="user-details-item__text">{ltiConsumer.consumer_key}</div>
        </div>

        <div className="md-1 md-offset-1 sm-12 user-details-item user-content-button">
          <OptionsPopup
            options={[
              {
                label: <FormattedMessage id="ltiConsumer.edit" />,
                onClick: () => handleEdit(ltiConsumer),
              },
            ]}
          />
        </div>
      </div>
    </div>
  );
}

LtiConsumerRow.propTypes = {
  ltiConsumer: PropTypes.object.isRequired,
  onSave: PropTypes.func,
};
