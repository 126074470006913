const selectRequests = state => {
  return (state && state.requests) || {};
};

export const selectSaving = state => {
  const saveRequestsNum = selectRequests(state).save;

  if (typeof saveRequestsNum === 'undefined') return false;

  return saveRequestsNum > 0;
};
