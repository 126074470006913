import { isEmpty as _isEmpty } from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Table } from 'antd';
import { selectCriterionByCriterionProgress } from '../../selectors/criterionSelectors';
import intl from '../../utils/intl';
import { getNumeratorFromPercent } from '../../utils/utils';
import AssignmentProgress from '../../api/assignmentProgress';

export default function ViewGrade({ assignmentProgress }) {
  return (
    <>
      {!_isEmpty(assignmentProgress.rubrics) ? (
        <Table
          dataSource={assignmentProgress.rubrics}
          rowKey="criterion"
          columns={getColumnsForRubricsV1()}
          pagination={false}
          className="view-grade"
          summary={() => <TableSummary {...{ assignmentProgress }} />}
        />
      ) : (
        <Table
          dataSource={assignmentProgress.criterion_progresses}
          rowKey="id"
          columns={getColumnsForCriterionProgresses()}
          pagination={false}
          className="view-grade"
          summary={() => <TableSummary {...{ assignmentProgress }} />}
        />
      )}
    </>
  );
}

ViewGrade.propTypes = {
  assignmentProgress: PropTypes.object.isRequired,
};

const getColumnsForRubricsV1 = () => [
  {
    title: intl.formatMessage({ id: 'grade.criterion_name' }),
    dataIndex: 'criterion',
    render: (criterionName, _criterionProgress, index) => <Criterion {...{ criterionName, index }} />,
  },
  {
    title: intl.formatMessage({ id: 'grade.score' }),
    dataIndex: 'score',
    render: (score, rubrics) => (
      <p>
        {score}/{rubrics.max_score}
      </p>
    ),
  },
];

const getColumnsForCriterionProgresses = () => [
  {
    title: intl.formatMessage({ id: 'grade.criterion_name' }),
    dataIndex: 'criterion_id',
    render: (_criterionId, criterionProgress, index) => <Criterion {...{ criterionProgress, index }} />,
  },
  {
    title: intl.formatMessage({ id: 'grade.score' }),
    dataIndex: 'score',
    render: score => (
      <p>
        {score}/{AssignmentProgress.MAX_SCORE}
      </p>
    ),
  },
  {
    title: intl.formatMessage({ id: 'grade.comment' }),
    dataIndex: 'comment',
    render: comment => comment && <p className="criterion-comments">{comment}</p>,
  },
];

const Criterion = ({ criterionProgress, criterionName, index }) => {
  let critName = criterionName;

  if (!criterionName) {
    const criterion = useSelector(state => selectCriterionByCriterionProgress(state, criterionProgress));
    critName = criterion.name;
  }

  return (
    <div className="criterion-summary">
      <span className="criterion-index">{index}.</span>
      <span className="criterion-text">{critName}</span>
    </div>
  );
};

Criterion.propTypes = {
  criterionProgress: PropTypes.object.isRequired,
  criterionName: PropTypes.string,
  index: PropTypes.number,
};

const TableSummary = ({ assignmentProgress }) => {
  let maxScore;

  if (_isEmpty(assignmentProgress.rubrics)) {
    maxScore = assignmentProgress.criterion_progresses.length * AssignmentProgress.MAX_SCORE;
  } else {
    maxScore = assignmentProgress.rubrics.reduce((sum, rubric) => sum + rubric.max_score, 0);
  }

  const totalGrade = assignmentProgress.score;
  const score = getNumeratorFromPercent(totalGrade, maxScore);

  return (
    <tr>
      <th>
        <FormattedMessage id="grade.totalGrade" />
      </th>
      <td>
        <p>
          {score}/{maxScore} ({totalGrade}%)
        </p>
      </td>
      <td>
        <p className="criterion-comments">{assignmentProgress.comments}</p>
      </td>
    </tr>
  );
};

TableSummary.propTypes = {
  assignmentProgress: PropTypes.object.isRequired,
};
