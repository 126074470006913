import React from 'react';
import { values as _values, compact as _compact, some as _some, each as _each } from 'lodash';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { saveUnit } from '../../actions/unitActions';
import { destroySectionUnit } from '../../actions/sectionUnitActions';
import ModuleTile from './ModuleTile';
import StudentModuleTile from './StudentModuleTile';
import { reorderComponents, saveComponent } from '../../actions/componentActions';
import { selectProgressForComponent } from '../../selectors/componentProgressSelectors';
import { selectCurrentUser } from '../../selectors/authSelectors';
import { canLearn } from '../../helpers/userAuth';
import Block from '../../api/block';
import { selectLessonBlocks } from '../../selectors/lessonSelectors';
import { selectCompletedCounts, selectProgressForUnit } from '../../selectors/unitProgressSelectors';
import { selectEntitiesForBootcamp } from '../../selectors/cohortBootcampSelectors';

const mapStateToProps = (state, ownProps) => {
  const { bootcamp, unit, section } = ownProps;
  const { componentsByUnit } = selectEntitiesForBootcamp(state, bootcamp.id);
  const components = (componentsByUnit && componentsByUnit[unit.id]) || [];
  const unitProgress = selectProgressForUnit(state, section.id, unit.id);

  const progressesByComponentId = {};
  const isStudent = canLearn(selectCurrentUser(state));
  const hasLab = _some(components, comp => {
    const blocks = selectLessonBlocks(state, comp.external_id);
    return _some(blocks, b => b.block_type === Block.LAB);
  });
  const completedCounts = unitProgress && selectCompletedCounts(state, unitProgress.id);

  _each(components, component => {
    progressesByComponentId[component.id] = selectProgressForComponent(
      state,
      unitProgress && unitProgress.id,
      component.id,
    );
  });
  const currentComponentProgress =
    unitProgress &&
    _compact(_values(progressesByComponentId)).find(prog => prog.id === unitProgress.current_component_progress_id);

  return {
    isStudent,
    components,
    unitProgress,
    progressesByComponentId,
    currentComponentProgress,
    hasLab,
    completedCounts,
  };
};

const ModuleTileContainer = ({ pathstream2, ...props }) => {
  if (pathstream2) {
    return <StudentModuleTile {...props} />;
  } else {
    return <ModuleTile {...props} />;
  }
};

export default connect(mapStateToProps, {
  saveUnit,
  destroySectionUnit,
  saveComponent,
  reorderComponents,
})(withRouter(ModuleTileContainer));
