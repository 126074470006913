import { values as _values } from 'lodash';
import createCachedSelector from 're-reselect';

export const selectLessonProgressEntities = state => {
  return state.entities.lessonProgresses;
};

export const selectLessonProgress = createCachedSelector(
  [selectLessonProgressEntities, (_state, lessonProgressId) => lessonProgressId],
  (lessonProgressEntities, lessonProgressId) => {
    return lessonProgressEntities[lessonProgressId];
  },
)((_state, lessonProgressId) => lessonProgressId);

export const selectProgressForLesson = createCachedSelector(
  [selectLessonProgressEntities, (_state, lessonId) => lessonId],
  (lessonProgressEntities, lessonId) => {
    return _values(lessonProgressEntities).filter(lessonProgress => lessonProgress.lesson_id === lessonId)[0];
  },
)((_state, lessonId) => lessonId);
