import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { IntlProvider } from 'react-intl';

import locales from '../locales';
import store from '../store';

const showModal = (componentKlass, passedProps) => {
  const parent = document.body;
  const container = document.createElement('div');

  const mount = () => parent.appendChild(container);
  const unmount = () => {
    ReactDOM.unmountComponentAtNode(container);
    parent.removeChild(container);
    if (passedProps && 'onUnmount' in passedProps) {
      passedProps.onUnmount();
    }
  };

  const props = {
    ...passedProps,
    onHide: () => {
      unmount();
    },
  };
  const component = React.createElement(componentKlass, props);

  mount();
  ReactDOM.render(
    <IntlProvider locale={locales.locale} messages={locales.messages}>
      <Provider store={store}>{component}</Provider>
    </IntlProvider>,
    container,
  );
};

export default showModal;
