import { size as _size } from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import QuizTabsContainer from '../Quiz/QuizTabsContainer';
import QuizLoaderContainer from '../Quiz/LoaderContainer';
import QuestionsContainer from '../Quiz/QuestionsContainer';
import BlockHeader from '../Utils/BlockHeader';

import { selectBlock } from '../../selectors/blockSelectors';

class BlockQuizShow extends Component {
  constructor(props) {
    super(props);
    this.knowledgeCheckRef = React.createRef();

    this.state = {
      questionIndex: 0,
    };
  }

  updateQuestionIndex = increment => {
    this.setState(state => ({
      questionIndex: state.questionIndex + increment,
    }));
  };

  renderTitle = quiz => {
    return (
      quiz.title && (
        <div className="knowledge-check__header">
          <h1 className="heading" dangerouslySetInnerHTML={{ __html: quiz.title }} />
        </div>
      )
    );
  };

  render() {
    const { block, questionProgressesEnabled, renderBody } = this.props;

    return (
      <QuizLoaderContainer
        block={block}
        render={(quiz, questions) =>
          _size(questions) !== 0 && (
            <div className="section">
              <div className="block-label">
                <BlockHeader messageId="common.knowledgeCheck" />
                {renderBody ? this.renderTitle(quiz) : ''}
                <div className="block-label__content">
                  <div className="knowledge-check-wrapper" ref={this.knowledgeCheckRef}>
                    <div className="knowledge-check__body">
                      {renderBody ? (
                        renderBody(quiz, questions)
                      ) : (
                        <QuizTabsContainer
                          block={block}
                          isEditMode={false}
                          renderTitle={() => this.renderTitle(quiz)}
                          questionIndex={this.state.questionIndex}>
                          <QuestionsContainer
                            quiz={quiz}
                            questions={questions}
                            questionProgressesEnabled={questionProgressesEnabled}
                            knowledgeCheckRef={this.knowledgeCheckRef}
                            questionIndex={this.state.questionIndex}
                            updateQuestionIndex={this.updateQuestionIndex}
                          />
                        </QuizTabsContainer>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )
        }
      />
    );
  }
}

BlockQuizShow.propTypes = {
  block: PropTypes.object.isRequired,
  questionProgressesEnabled: PropTypes.bool,
  renderBody: PropTypes.func,
};

const mapStateToProps = (state, ownProps) => {
  const block = ownProps.block || selectBlock(state, ownProps.blockId);

  return {
    block,
  };
};

export default connect(mapStateToProps)(BlockQuizShow);
