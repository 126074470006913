import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import ProfileNav from '../ProfileNav';
import AuthorEditToggle from '../AuthorEditToggle';
import Icons from '../../../Utils/Icons';
import intl from '../../../../utils/intl';
import SavingIndicator from '../../../Utils/SavingIndicator';
import CoachHeader from '../Coach/CoachHeader';
import { isCoach } from '../../../../helpers/userAuth';
import logo from '../../../../styles/v2/images/logo.png';

export default class Header extends Component {
  // eslint-disable-next-line complexity
  getUserHeader = () => {
    const {
      currentUser,
      hasSideNav,
      toggleSideNav,
      headerText,
      isAuthor,
      hideAuthorToggle,
      scrolledPastHeader,
      className,
      SubHeader,
      Content,
      school,
      hideProgress,
    } = this.props;

    if (isCoach(currentUser)) {
      return (
        <CoachHeader
          currentUser={currentUser}
          scrolledPastHeader={scrolledPastHeader}
          SubHeader={SubHeader}
          Content={Content}
        />
      );
    }

    const hideNav = false;
    const showLogo = hideNav || this.props.showLogo;
    const headerClasses = classnames('header', scrolledPastHeader && 'header---after-scroll');

    return (
      <header className={className}>
        <div className={headerClasses}>
          <div className="header__left">
            {showLogo && (
              <>
                <div className="header-logo-wrapper">
                  <img src={logo} alt="logo" />
                </div>
                {school && (
                  <div className="school-logo">
                    <img src={school.logo} alt={school.name} />
                  </div>
                )}
              </>
            )}
            {!showLogo && (
              <div className="header-menu-wrapper">
                {hasSideNav && (
                  <div className={classnames('menu')}>
                    <button
                      type="button"
                      onClick={toggleSideNav}
                      className="menu__icon"
                      aria-label={intl.formatMessage({ id: 'nav.toggle' })}>
                      <Icons.Hamburger />
                    </button>
                  </div>
                )}
              </div>
            )}
          </div>
          {headerText && (
            <div className="header__title">
              <h1>{headerText}</h1>
            </div>
          )}
          {Content && <Content scrolledPastHeader={scrolledPastHeader} />}

          {!hideNav && (
            <div className="header__right">
              {currentUser && isAuthor && (
                <div className={classnames('edit-mode', scrolledPastHeader && 'edit-mode---floating')}>
                  <p className="edit-mode__saving-indicator">
                    <SavingIndicator />
                  </p>
                  {!hideAuthorToggle && <AuthorEditToggle />}
                </div>
              )}

              <ProfileNav hideName />
            </div>
          )}
        </div>

        {SubHeader && <SubHeader scrolledPastHeader={scrolledPastHeader} hideProgress={hideProgress} />}
      </header>
    );
  };

  render() {
    return (
      <>
        <a href="#main" className="visuallyhidden focusable skiplink">
          Skip to main content
        </a>
        {this.getUserHeader()}
      </>
    );
  }
}
Header.propTypes = {
  currentUser: PropTypes.object,
  hasSideNav: PropTypes.bool,
  toggleSideNav: PropTypes.func.isRequired,
  headerText: PropTypes.any,
  hideAuthorToggle: PropTypes.bool,
  scrolledPastHeader: PropTypes.bool,
  className: PropTypes.string,
  SubHeader: PropTypes.func,
  showLogo: PropTypes.bool,
  Content: PropTypes.func,
  isAuthor: PropTypes.bool,
};

Header.defaultProps = {
  hasSideNav: false,
  scrolledPastHeader: false,
};
