import moment from 'moment';
import { useSelector } from 'react-redux';
import { selectCohorts } from '../../../../selectors/cohortSelectors';
import COHORT_STATUS from '../partial/cohortStatuses';

const useCohortSetupFields = cohort => {
  const cohorts = useSelector(state => selectCohorts(state));
  const today = moment();

  const getStatus = () => {
    if (cohort.is_canceled) {
      return COHORT_STATUS.CANCELED;
    }
    if (moment(cohort.start_date).diff(today) > 0) {
      return COHORT_STATUS.NOT_STARTED;
    }
    if (moment(cohort.end_date).diff(today) >= 0) {
      return COHORT_STATUS.LIVE;
    }
    return COHORT_STATUS.ENDED;
  };

  const status = cohort.id ? getStatus() : '';

  const synced = cohort.id && cohort.lti_context.length > 0;

  let sameLtiContextCohort;
  if (cohort.id && cohort.lti_context.length) {
    sameLtiContextCohort = cohorts.find(item => item.lti_context[0] === cohort.lti_context[0] && item.id !== cohort.id);
  }
  const warning = !!sameLtiContextCohort;

  return {
    status,
    synced,
    warning,
  };
};

export default useCohortSetupFields;
