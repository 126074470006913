import React from 'react';
import PropTypes from 'prop-types';
import ContentEditable from 'react-contenteditable';
import CKEditor from '../../Utils/CKEditor';
import StudentBlockCollapsibleHtml from '../../Student/BlockCollapsibleHtml';
import BlockEditOptionsContainer from './BlockEditOptionsContainer';
import BlockHeader from '../../Utils/BlockHeader';
import intl from '../../../utils/intl';
import useSaveBlock from '../../../hooks/useSaveBlock';
import BlockConflictModal from './BlockConflictModal/BlockConflictModal';
import ContentValidator from './ContentValidator';
import MultipleEditor from '../../Blocks/MultipleEditor';

export default function BlockCollapsibleHtml(props) {
  const { block, index, totalBlocks, onReorder, blockHeaderMessageId, blockHeaderIcon, isEditMode } = props;

  const { blockState, conflict, handleChange, contentRef, handleConflictSave } = useSaveBlock(block);

  const renderEditMode = () => (
    <ContentValidator content={blockState.content}>
      <div>
        <BlockHeader messageId={blockHeaderMessageId} icon={blockHeaderIcon} />
        <BlockEditOptionsContainer block={block} index={index} total={totalBlocks} onReorder={onReorder} />

        <div className="section">
          <MultipleEditor blockId={blockState.id}>
            <details>
              <summary>
                <ContentEditable
                  html={blockState.title || ''}
                  tagName="h1"
                  className="summary"
                  onChange={handleChange('title')}
                />
              </summary>
              <CKEditor
                defaultContent={intl.formatMessage({ id: 'block.defaultHtmlContent' })}
                inline
                content={blockState.content}
                onChange={handleChange('content')}
                ref={contentRef}
              />
            </details>
          </MultipleEditor>
        </div>

        {conflict.visible && (
          <BlockConflictModal
            remoteBlock={conflict.remote}
            currentBlock={conflict.current}
            onOk={handleConflictSave}
            onCancel={conflict.hide}
          />
        )}
      </div>
    </ContentValidator>
  );

  return isEditMode ? renderEditMode() : <StudentBlockCollapsibleHtml block={blockState} />;
}

BlockCollapsibleHtml.propTypes = {
  block: PropTypes.object.isRequired,
  isEditMode: PropTypes.bool,
  index: PropTypes.number,
  totalBlocks: PropTypes.number,
  onReorder: PropTypes.func,
  blockHeaderMessageId: PropTypes.string,
  blockHeaderIcon: PropTypes.element,
};
