import { sortBy as _sortBy, uniq as _uniq, uniqBy as _uniqBy, compact as _compact, clone as _clone } from 'lodash';
import { getSearchRegex } from './regexHelper';

export const filterBootcamps = (bootcamps, searchText) => {
  if (!bootcamps) {
    return [];
  }

  let items = bootcamps;

  if (searchText) {
    const regex = getSearchRegex(searchText);
    items = bootcamps.filter(item => {
      return regex.test(item.title) || regex.test(item.description);
    });
  }

  return _sortBy(items, bc => bc.title.toLowerCase());
};

export const sortBootcamps = bootcamps => {
  return _sortBy(bootcamps, bc => {
    return bc.title.toLowerCase();
  });
};

export const sortCohorts = cohorts => {
  return _sortBy(cohorts, 'start_date', 'end_date');
};

export const dropdownBootcamps = (bootcampEntities, cohorts) => {
  return _uniq(_compact(cohorts.map(cohort => bootcampEntities[cohort.bootcamp_id])));
};

export const dropdownBootcampsWithCohortTitle = (bootcampEntities, cohorts) => {
  return _uniqBy(
    _compact(
      cohorts.map(cohort => {
        const bootcampEntity = bootcampEntities[cohort.bootcamp_id];

        if (!bootcampEntity) {
          return undefined;
        }

        const bootcamp = _clone(bootcampEntity);

        if (cohort.display_cohort_title) {
          bootcamp.cohort_title = cohort.cohort_title;
        }

        return bootcamp;
      }),
    ),
    bootcamp => [bootcamp.id, bootcamp.cohort_title].join(),
  );
};

export const bootcampOptionValue = bootcamp => {
  if (!bootcamp) return false;

  return `${bootcamp.id}+${bootcamp.cohort_title}`;
};

export const bootcampOptionLabel = bootcamp => {
  return bootcamp.cohort_title || bootcamp.title;
};
