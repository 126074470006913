import React, { useState, useEffect, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { Modal, Button } from 'antd';
import { isEmpty as _isEmpty } from 'lodash';

import intl from '../../../utils/intl';
import useLabContext from '../../../context/Lab/useLabContext';
import { usesRemoteVM } from '../../../helpers/labHelper';
import LabLoadingIcons from './LabLoadingIcons';
import LabLoadingBar from './LabLoadingBar';
import LabLoadingNotes from './LabLoadingNotes';
import backgroundImg from '../../../styles/v2/images/lab-loading-background.png';
import backgroundVideo from '../../../styles/v2/videos/lab-loading-background.mp4';
import {
  LabErrorModal,
  getLongerThanUsualModalProps,
  getNoMachinesAvailableModalProps,
} from '../LabErrorModal/LabErrorModal';
import { modalDefaultProps } from '../../library/SimpleModal2/SharedAntProps';

const LONGER_THAN_USUAL_MINUTES = 12;

const labErrorModal = modalData => {
  return <LabErrorModal {...modalData} />;
};

export default function LabLoadingModal({ app }) {
  const { startClicked, setStartClicked, isReady, isVmUp, errorModalProps } = useLabContext();
  const [minutesElapsed, setMinutesElapsed] = useState(0);
  const timer = useRef();

  useEffect(() => {
    return () => clearTimeout(timer.current);
  }, []);

  useEffect(() => {
    if (minutesElapsed >= LONGER_THAN_USUAL_MINUTES) return;
    timer.current = setTimeout(() => setMinutesElapsed(minutesElapsed => minutesElapsed + 1), 60000);
  }, [minutesElapsed]);

  useEffect(() => {
    if (isReady) clearTimeout(timer.current);
  }, [isReady]);

  useEffect(() => {
    if (!_isEmpty(errorModalProps)) clearTimeout(timer.current);
  }, [errorModalProps]);

  if (startClicked) {
    return '';
  }

  if (!_isEmpty(errorModalProps)) {
    return labErrorModal(errorModalProps[0]);
  }

  if (minutesElapsed >= LONGER_THAN_USUAL_MINUTES) {
    return labErrorModal(getLongerThanUsualModalProps());
  }

  const labUsesRemoteVm = usesRemoteVM(app);

  const onLaunchLab = () => setStartClicked(true);

  const renderTitle = () => {
    return isReady ? (
      <FormattedMessage id="tutorial.loadingModal.readyTitle" />
    ) : (
      <FormattedMessage id="tutorial.loadingModal.loadingTitle" />
    );
  };

  const renderNotes = () => {
    return isReady ? (
      <div
        dangerouslySetInnerHTML={{
          __html: intl.formatMessage({ id: 'tutorial.loadingModal.readyNote' }),
        }}
      />
    ) : (
      <LabLoadingNotes />
    );
  };

  const renderFooter = () => {
    return isReady ? (
      <div className="launch-lab">
        <Button role="button" onClick={onLaunchLab}>
          <FormattedMessage id="common.launchLab" />
        </Button>
      </div>
    ) : (
      <LabLoadingBar usesRemoteVM={labUsesRemoteVm} isVmUp={isVmUp} />
    );
  };

  return (
    <Modal className="lab-loading-modal" visible maskStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.85)' }} {...modalDefaultProps}>
      <video id="video" autoPlay loop poster={backgroundImg}>
        <source src={backgroundVideo} type="video/mp4" />
      </video>

      <div className="loading-overlay" data-testid="loading-overlay">
        <LabLoadingIcons app={app} />

        <div className="title" data-testid="title">
          {renderTitle()}
        </div>

        <div className="notes" data-testid="notes">
          {renderNotes()}
        </div>

        <div className="footer">{renderFooter()}</div>
      </div>
    </Modal>
  );
}

LabLoadingModal.propTypes = {
  app: PropTypes.string,
};
