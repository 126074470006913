import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { Button } from 'antd';
import { LockFilled, InfoCircleFilled } from '@ant-design/icons';
import UserPicture from '../../../Utils/UserPicture';
import showModal from '../../../../utils/showModal';
import TermsOfServiceContainer from '../../../Auth/TermsOfServiceContainer';
import ChangePasswordModalContainer from '../../../Users/ChangePasswordModalContainer';

const CoachProfileNavContent = ({ currentUser }) => {
  const handleChangePassword = e => {
    e.preventDefault();

    showModal(ChangePasswordModalContainer, {
      user: currentUser,
    });
  };

  const canChangePassword = () => {
    return !currentUser.lti_user_id;
  };

  return (
    <div className="user-profile-options-wrapper" id="user-menu" role="menu">
      <div className="user-profile-options-inner">
        <div className="user-profile-options user-profile-options-links">
          <div className="user-profile-options__user">
            <div className="user-profile-options__user-avatar">
              <UserPicture user={currentUser} defaultWhite className="user-profile-picture" />
            </div>
            <span className="user-profile-options__user-label">{currentUser.name}</span>
          </div>

          {canChangePassword() && (
            <div className="user-profile-options__link">
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a className="coach-profile-option" alt="Change Password" href="#" onClick={handleChangePassword}>
                <LockFilled /> <FormattedMessage id="profile.changePassword" />
              </a>
            </div>
          )}

          <div className="user-profile-options__link">
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a
              className="coach-profile-option"
              alt="Terms of Service"
              href="#"
              onClick={() => showModal(TermsOfServiceContainer)}>
              <InfoCircleFilled /> <FormattedMessage id="user.termsOfService.title" />
            </a>
          </div>

          <div className="user-profile-options__link">
            <Link to="/logout">
              <Button className="coach-profile-option" type="primary" ghost>
                <FormattedMessage id="nav.signOut" />
              </Button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CoachProfileNavContent;

CoachProfileNavContent.propTypes = {
  currentUser: PropTypes.object.isRequired,
};
