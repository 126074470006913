import {
  compact as _compact,
  each as _each,
  find as _find,
  isEmpty as _isEmpty,
  map as _map,
  max as _max,
  sortBy as _sortBy,
  sum as _sum,
} from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CohortReport from '../../api/cohortReport';
import intl from '../../utils/intl';
import Loading from '../Utils/Loading';
import { getRowValueByFieldName, STUDENT_DATA_FIELDS } from './reportDataHelper';
import User from '../../api/user';

export default class CohortStudents extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    this.fetchData(this.props);
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.cohortId !== nextProps.cohortId) {
      this.fetchData(nextProps);
    }
  }

  fetchData = props => {
    const { cohortId } = props;

    this.setState({ isLoading: true });
    CohortReport.students(cohortId).then(response => {
      let { rows } = response;
      const userIds = _map(rows, 'user_id');
      User.getUsers(userIds).then(users => {
        _each(users, user => {
          // eslint-disable-next-line max-nested-callbacks
          const row = _find(rows, s => s.user_id === user.id);
          if (row) {
            row.user = user;
          }
        });

        rows = _sortBy(rows, 'user.name');
        this.setState({ rows, isLoading: false });
      });
    });
  };

  getAverage = values => {
    const compactValues = _compact(values);
    if (compactValues.length === 0) return 0;
    return _sum(compactValues) / compactValues.length;
  };

  getAverageRow = () => {
    const { rows } = this.state;

    return {
      user: { name: <em>Average</em> },
      assignments_completed_percent: Math.round(this.getAverage(_map(rows, 'assignments_completed_percent'))),
      avg_assignment_score: Math.round(this.getAverage(_map(rows, 'avg_assignment_score'))),
      lessons_completed_percent: Math.round(this.getAverage(_map(rows, 'lessons_completed_percent'))),
      duration: this.getAverage(_map(rows, 'duration')),
      num_logins: Math.round(this.getAverage(_map(rows, 'num_logins')) * 100) / 100,
      last_login: _max(_map(rows, 'last_login')),
    };
  };

  render() {
    const { rows, isLoading } = this.state;

    if (isLoading) {
      return (
        <div className="grid-row">
          <div className="sm-12">
            <Loading />
          </div>
        </div>
      );
    }

    if (_isEmpty(rows)) {
      return <h2 className="info-msg">No students found</h2>;
    }

    const averageRow = this.getAverageRow();

    return (
      <div className="grid-row cohort-students-container">
        <div className="sm-12 centered-and-flexed">
          <table className="cohort-students">
            <thead>
              <tr>
                {_map(STUDENT_DATA_FIELDS, field => (
                  // eslint-disable-next-line jsx-a11y/control-has-associated-label
                  <th
                    key={field}
                    dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: `cohortReports.students.${field}` }) }}
                  />
                ))}
              </tr>
            </thead>
            <tbody>
              {_map(rows, row => (
                <tr key={row.user_id}>
                  {_map(STUDENT_DATA_FIELDS, field => (
                    <td key={field}>{getRowValueByFieldName(row, field)}</td>
                  ))}
                </tr>
              ))}
              <tr>
                {_map(STUDENT_DATA_FIELDS, field => (
                  <td key={field}>{getRowValueByFieldName(averageRow, field)}</td>
                ))}
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}
CohortStudents.propTypes = {
  cohortId: PropTypes.string.isRequired,
};
