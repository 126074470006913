import { addLocaleData } from 'react-intl';
import enLocaleData from 'react-intl/locale-data/en';
import enMessages from './en';
import { union as _union } from 'lodash';

addLocaleData(enLocaleData);
if (!Intl.PluralRules) {
  require('@formatjs/intl-pluralrules/polyfill');
  require('@formatjs/intl-pluralrules/dist/locale-data/en'); // Add locale data for en
}

if (!Intl.RelativeTimeFormat) {
  require('@formatjs/intl-relativetimeformat/polyfill');
  require('@formatjs/intl-relativetimeformat/dist/locale-data/en'); // Add locale data for en
}

const messages = {
  en: enMessages,
};

const supportedLanguages = Object.keys(messages);
const defaultLanguage = ['en'];

// navigator.languages polyfill
if (!navigator.languages) {
  navigator.languages = [navigator.language];
}

const navigatorLanguagesWithoutRegionCode = navigator.languages.map(language => {
  return language.split(/[-_]/)[0];
});

const languagesByPriority = _union(navigator.languages, navigatorLanguagesWithoutRegionCode, defaultLanguage);
const locale = languagesByPriority.find(language => {
  return supportedLanguages.includes(language);
});

export default {
  locale: locale,
  messages: messages[locale],
};
