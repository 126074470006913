import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import { selectCohort } from '../../../selectors/cohortSelectors';
import { selectProjectsForBootcamp } from '../../../selectors/unitSelectors';
import * as analytics from '../../../utils/analytics';
import { setPageTitle } from '../../../helpers/pageHelper';
import { fetchCohort } from '../../../actions/cohortActions';
import { fetchEnrollments } from '../../../actions/enrollmentActions';
import { fetchAssignments } from '../../../actions/assignmentActions';
import { fetchBootcampInstanceAssignments } from '../../../actions/bootcampInstanceAssignmentActions';
import Header from './Header';
import MenuContent from './MenuContent';
import StudentsPage from './StudentsPage';
import AssignmentsPage from './AssignmentsPage';
import CoachHeader from '../../v2/Nav/Coach/CoachHeader';
import { selectCurrentUser } from '../../../selectors/authSelectors';
import { fetchUsers, fetchProgresses, fetchCohortReportStudents } from './fetchDataHelper';

const useStore = cohortId => {
  const cohort = useSelector(state => selectCohort(state, cohortId));
  const units = useSelector(state => selectProjectsForBootcamp(state, cohort?.bootcamp_id));
  const currentUser = useSelector(selectCurrentUser);

  return {
    units,
    currentUser,
  };
};

const getMenu = (cohortId, cohortReportStudents) => ({
  students: {
    tabName: <FormattedMessage id="cohortDashboard.menu.studentsTab" />,
    page: <StudentsPage cohortId={cohortId} cohortReportStudents={cohortReportStudents} />,
  },
  assignments: {
    tabName: <FormattedMessage id="cohortDashboard.menu.assignmentsTab" />,
    page: <AssignmentsPage cohortId={cohortId} />,
  },
});

const CohortDashboard = () => {
  const { cohortId } = useParams();
  const { units, currentUser } = useStore(cohortId);
  const dispatch = useDispatch();
  const [cohortReportStudents, setCohortReportStudents] = useState([]);

  useEffect(() => {
    fetchCohort(cohortId)(dispatch);
    fetchUsers(cohortId, dispatch);
    fetchProgresses(cohortId, dispatch);
    fetchEnrollments(cohortId)(dispatch);
    fetchBootcampInstanceAssignments(cohortId)(dispatch);
    fetchCohortReportStudents(cohortId, setCohortReportStudents);

    analytics.pageStart('Cohort Dashboard - Coach');
    setPageTitle('pageName.courseDashboard');

    return analytics.pageEnd;
  }, [cohortId, dispatch]);

  useEffect(() => {
    if (!units) {
      return;
    }

    if (units.length === 0) {
      return;
    }

    const assignmentIds = units.map(unit => unit.assignment_id);
    fetchAssignments(assignmentIds)(dispatch);
  }, [dispatch, units]);

  const menu = getMenu(cohortId, cohortReportStudents);

  return (
    <div key={cohortId}>
      {currentUser && <CoachHeader currentUser={currentUser} />}
      <div className="cohort-dashboard">
        <Header cohortId={cohortId} />

        <MenuContent menu={menu} />
      </div>
    </div>
  );
};

export default CohortDashboard;
