import React from 'react';
import PropTypes from 'prop-types';

const getSvgParams = (isSmall, progress) => {
  const radius = isSmall ? 16.5 : 24.5;
  const cx = isSmall ? 18 : 26.5;
  const cy = isSmall ? 18 : 26.5;
  const strokeDasharray = isSmall ? '103.62 103.62' : '153.86 153.86';
  const strokeWidth = isSmall ? 3 : 4;
  const dim = isSmall ? 36 : 53;
  const strokeDashoffset = (2 * Math.PI * radius * (100 - progress)) / 100;

  return {
    radius,
    cx,
    cy,
    dim,
    strokeDasharray,
    strokeWidth,
    strokeDashoffset,
  };
};

export default function SvgCircularProgress({ progress, className, showPercent, size }) {
  const isSmall = size === 'small';
  const roundedProgress = Math.round(100 * (progress || 0));
  const { radius, cx, cy, dim, strokeDasharray, strokeDashoffset, strokeWidth } = getSvgParams(
    isSmall,
    roundedProgress,
  );

  return (
    <div className={className}>
      <svg className={`${className}-ring`} height={dim} width={dim} viewBox={`0 0 ${dim} ${dim}`}>
        <circle
          fill="transparent"
          cx={cx}
          cy={cy}
          strokeWidth={strokeWidth}
          r={radius}
          strokeDasharray={strokeDasharray}
          strokeDashoffset={strokeDashoffset}></circle>
      </svg>
      {showPercent && `${roundedProgress}%`}
    </div>
  );
}

SvgCircularProgress.propTypes = {
  progress: PropTypes.number,
  showPercent: PropTypes.bool,
  className: PropTypes.string,
  size: PropTypes.oneOf(['small', 'big']),
};

SvgCircularProgress.defaultProps = {
  className: 'header-bar__progress',
  showPercent: true,
  size: 'small',
};
