import axios from './axios';

export default class Quiz {
  static all(_) {
    return axios.get('/assessments/quizzes').then(response => response.data);
  }

  static get(_, quizId) {
    return axios.get(`/assessments/quizzes/${quizId}`).then(response => response.data);
  }

  static save(_, quiz) {
    if (quiz.id) {
      return axios.put(`/assessments/quizzes/${quiz.id}`, quiz).then(response => response.data);
    }
    return axios.post('/assessments/quizzes', quiz).then(response => response.data);
  }
}
