import { connect } from 'react-redux';
import UserList from './UserList';
import { fetchUsers } from '../../../actions/userActions';

const mapStateToProps = (state, ownProps) => {
  const { user } = state;

  return {
    user,
    history: ownProps.history,
  };
};

export default connect(mapStateToProps, { fetchUsers })(UserList);
