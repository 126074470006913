import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Loading from '../../Utils/Loading';
import AdminWrapper from '../../v2/Nav/Admin/AdminWrapper';
import * as analytics from '../../../utils/analytics';
import intl from '../../../utils/intl';
import { setPageTitle } from '../../../helpers/pageHelper';
import UserRow from './UserRow';
import showModal from '../../../utils/showModal';
import EditUserModalContainer from '../../Users/EditUserModalContainer';
import { Button } from '@pathstream/ui';
import { debounce as _debounce, sortBy as _sortBy, findIndex as _findIndex } from 'lodash';

const UserList = ({ fetchUsers }) => {
  const [state, setState] = useState({
    searchText: '',
    isLoading: false,
    users: [],
    page: 1,
    pageSize: 10,
    totalPages: 1,
  });

  const { searchText, isLoading, users } = state;

  const getData = useCallback(() => {
    const { searchText, pageSize, page } = state;
    setState(prevState => ({ ...prevState, isLoading: true }));

    fetchUsers(searchText, page - 1, pageSize, 'name', false)
      .then(data => {
        setState(prevState => ({
          ...prevState,
          totalPages: Math.ceil(data.total / pageSize),
          users: data.rows,
          isLoading: false,
        }));
      })
      .catch(err => {
        console.warn('Error getting users', err);
        setState(prevState => ({ ...prevState, isLoading: false }));
      });
  }, [fetchUsers, state.searchText, state.pageSize, state.page]);

  useEffect(() => {
    getData();
    analytics.pageStart('User List');
    setPageTitle('pageName.user.list');

    return () => {
      analytics.pageEnd();
    };
  }, [getData]);

  const debounceHandleSubmit = useCallback(
    _debounce(() => {
      getData();
    }, 300),
    [getData],
  );

  const handleCreate = () => {
    showModal(EditUserModalContainer, {
      user: {},
      afterSave: user => {
        setState(prevState => ({
          ...prevState,
          users: _sortBy(prevState.users.concat([user]), u => u.name.toLowerCase()),
        }));
      },
    });
  };

  const handleEdit = user => {
    showModal(EditUserModalContainer, {
      user,
      afterSave: user => {
        setState(prevState => {
          const { users } = prevState;
          const index = _findIndex(users, u => u.id === user.id);

          return {
            ...prevState,
            users: [...users.slice(0, index), user, ...users.slice(index + 1)],
          };
        });
      },
    });
  };

  const handleChange = e => {
    const { totalPages } = state;
    const { target } = e;
    const { name } = target;
    let { value } = target;
    const changes = {};

    if (name === 'page' && !isNaN(value)) {
      value = Number(value);
      if (value > totalPages) {
        value = totalPages;
      }
    } else {
      changes.page = 1;
    }
    changes[name] = value;
    setState(prevState => ({ ...prevState, ...changes }), debounceHandleSubmit);
  };

  const handlePageChange = e => {
    e.persist();
    const { name } = e.currentTarget;
    let { page, totalPages } = state;

    if (name === 'next') {
      page = Math.min(page + 1, totalPages);
    } else {
      page = Math.max(page - 1, 1);
    }

    setState(prevState => ({ ...prevState, page }), debounceHandleSubmit);
  };

  const renderHeader = () => (
    <div className="user-details user-details-title">
      <div className="grid-row user-details-items clear">
        <div className="md-2 sm-12 user-details-item">
          <div className="user-details-item__title">
            <span className="title-row-label">
              <FormattedMessage id="user.form.name" />
            </span>
          </div>
        </div>
        <div className="md-3 md-offset-1 sm-12 user-details-item">
          <div className="user-details-item__title">
            <span className="title-row-label">
              <FormattedMessage id="user.form.email" />
            </span>
          </div>
        </div>
        <div className="md-1 md-offset-1 sm-12 user-details-item">
          <div className="user-details-item__title">
            <span className="title-row-label">
              <FormattedMessage id="user.form.roles" />
            </span>
          </div>
        </div>
        <div className="md-2 sm-12 user-details-item">
          <div className="user-details-item__title user-details-item__text-center">
            <span className="title-row-label">
              <FormattedMessage id="user.fields.current_sign_in_at" />
            </span>
          </div>
        </div>
        <div className="md-1 md-offset-1 sm-12 user-details-item">
          <div className="user-details-item__title user-details-item__text-center">
            <span className="title-row-label">
              <FormattedMessage id="common.action" />
            </span>
          </div>
        </div>
      </div>
    </div>
  );

  const renderPagination = () => {
    const { page, totalPages } = state;

    return (
      <div className="pagination">
        <div className="pagination__button">
          <button
            type="button"
            name="previous"
            className="pagination__button--element"
            disabled={page === 1}
            onClick={handlePageChange}>
            <FormattedMessage id="common.previous" />
          </button>
        </div>

        <div className="pagination__state">
          <span className="pagination__state--label">
            <FormattedMessage id="pagination.page" />
          </span>
          <span className="pagination__state--current">
            <input name="page" value={page} onChange={handleChange} disabled={totalPages === 1} />
          </span>
          <span className="pagination__state--label">
            <FormattedMessage id="pagination.ofTotal" values={{ total: totalPages }} />
          </span>
        </div>

        <div className="pagination__button pagination__button--right">
          <button
            type="button"
            name="next"
            className="pagination__button--element"
            disabled={page === totalPages}
            onClick={handlePageChange}>
            <FormattedMessage id="common.next" />
          </button>
        </div>
      </div>
    );
  };

  const filteredUsers = users || [];

  return (
    <AdminWrapper headerTitle={intl.formatMessage({ id: 'nav.users' })}>
      <div className="grid-row">
        <div className="sm-3 sm-offset-1">
          <div className="custom-search-input">
            <label htmlFor="search" className="visually-hidden">
              <FormattedMessage id="common.search" />
            </label>
            <input
              id="search"
              type="text"
              name="searchText"
              value={searchText}
              onChange={handleChange}
              placeholder={intl.formatMessage({ id: 'common.searchPlaceholder' })}
              className="custom-search-input__input"
            />
          </div>
        </div>

        <div className="sm-2 sm-offset-5 horizontal-end">
          <Button onClick={handleCreate}>
            <FormattedMessage id="common.addNew" />
          </Button>
        </div>
      </div>

      {isLoading && <Loading />}
      <div className="grid-row">
        <div className="sm-10 sm-offset-1">
          {renderHeader()}
          {filteredUsers.map(row => (
            <UserRow key={row.id} user={row} onEdit={handleEdit} />
          ))}
          {renderPagination()}
        </div>
      </div>
    </AdminWrapper>
  );
};

UserList.propTypes = {
  history: PropTypes.object.isRequired,
  fetchUsers: PropTypes.func.isRequired,
};

export default UserList;
