import Block from '../api/block';
import { updateEntities } from './entityActions';
import { applicationDestroy, applicationApiExecute } from './applicationActions';
import intl from '../utils/intl';
import Tutorial from '../api/tutorial';
import { saveAppSite } from './appSiteActions';

export const destroyBlock = applicationDestroy(Block);
export const moveTo = applicationApiExecute(Block.moveTo);
export const moveToStep = applicationApiExecute(Block.moveToStep);
export const copyTo = applicationApiExecute(Block.copyTo);

export const saveBlock = block => async dispatch => {
  // create the custom site if needed
  if (block.app === Tutorial.CUSTOM_SITE && !block.app_site_id) {
    const appSite = await saveAppSite(block.custom_site)(dispatch);
    block.app_site_id = appSite.id;
  }

  return applicationApiExecute(Block.save, 'save')(block)(dispatch);
};

export const reorderBlocks = (blockIds, lesson, part) => dispatch => {
  const entities = {
    parts: {
      [part.id]: {
        ...part,
        block_ids: blockIds,
      },
    },
  };

  dispatch(updateEntities({ entities }));

  return applicationApiExecute(Block.reorder)(blockIds, lesson.id, part.id)(dispatch);
};
/* eslint-disable complexity */
export const buildBlock = (blockType, part, lesson, seqNum, adaptiveTracks) => {
  const block = {
    part_id: part.id,
    lesson_id: lesson.id,
    block_type: blockType,
    title: intl.formatMessage({ id: 'block.defaultTitle' }, { blockType: blockType.toUpperCase() }),
    seq_num: seqNum,
    adaptive_tracks: adaptiveTracks,
  };

  switch (blockType) {
    case Block.HTML:
    case Block.LAB:
      block.content = intl.formatMessage({ id: 'block.defaultHtmlContent' });
      break;

    case Block.COLLAPSIBLE_HTML:
      block.content = intl.formatMessage({ id: 'block.defaultCollapsibleHtmlContent' });
      break;

    case Block.SQL:
      block.content = intl.formatMessage({ id: 'block.defaultSqlContent' });
      break;

    case Block.COMMENT_THREAD:
    case Block.QUIZ:
      block.content = intl.formatMessage({ id: 'block.defaultQuizContent' });
      break;

    case Block.SINGLE_QUESTION_QUIZ:
      block.title = null;
      break;

    case Block.TYPEFORM:
      block.height = Block.MEDIUM;
      break;

    default:
      break;
  }

  return block;
};
/* eslint-enable complexity */

export const createBlock = (blockType, part, lesson, seqNum, adaptiveTracks) => dispatch => {
  const block = buildBlock(blockType, part, lesson, seqNum, adaptiveTracks);
  return saveBlock(block)(dispatch);
};
