import axios from './axios';

export default class Assignment {
  static PROFICIENCY_LEVEL_1 = 'proficiency_level_1';
  static PROFICIENCY_LEVEL_2 = 'proficiency_level_2';
  static PROFICIENCY_LEVEL_3 = 'proficiency_level_3';
  static PROFICIENCY_LEVEL_4 = 'proficiency_level_4';

  static CRITERION_PROFICIENCY_LEVELS = [
    this.PROFICIENCY_LEVEL_1,
    this.PROFICIENCY_LEVEL_2,
    this.PROFICIENCY_LEVEL_3,
    this.PROFICIENCY_LEVEL_4,
  ];

  static all(tenantId, ids) {
    return axios.get('/assessments/assignments', { params: { ids } }).then(response => response.data);
  }

  static get(tenantId, assignmentId) {
    return axios.get(`/assessments/assignments/${assignmentId}`).then(response => response.data);
  }

  static save(tenantId, assignment) {
    if (assignment.id) {
      return axios.put(`/assessments/assignments/${assignment.id}`, assignment).then(response => response.data);
    }

    return axios.post('/assessments/assignments', assignment).then(response => response.data);
  }

  static destroy(tenantId, assignment) {
    return axios.delete(`/assessments/assignments/${assignment.id}`).then(response => response.data);
  }
}
