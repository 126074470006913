import { map as _map } from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { FormattedMessage } from 'react-intl';
// import { ReactComponent as CaretIcon } from '../../../styles/pathstream/svg-jsx/icon-caret.svg';
import { RightOutlined } from '@ant-design/icons';

export default class Dropdown extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showAddMenu: false,
    };
  }

  handleOnClick = onClick => {
    onClick();
    this.toggleAddMenu();
  };

  toggleAddMenu = () => {
    this.setState({
      showAddMenu: !this.state.showAddMenu,
    });
  };

  render() {
    const { toggleButtonMessageId, buttons, className } = this.props;
    const { showAddMenu } = this.state;

    return (
      <div className={classnames('authoring-dropdown', className, showAddMenu && 'open')}>
        <button
          onClick={this.toggleAddMenu}
          className="button-standard button-standard--grey button-standard--short"
          type="button">
          <FormattedMessage id={toggleButtonMessageId} />
          <RightOutlined />
        </button>

        <div className="collapsible">
          {_map(buttons, button => (
            <button onClick={() => this.handleOnClick(button.onClick)} key={button.messageId} type="button">
              <FormattedMessage id={button.messageId} />
            </button>
          ))}
        </div>
      </div>
    );
  }
}
Dropdown.propTypes = {
  toggleButtonMessageId: PropTypes.string.isRequired,
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      onClick: PropTypes.func.isRequired,
      messageId: PropTypes.string.isRequired,
    }),
  ).isRequired,
};
