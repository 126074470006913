import { BOOTCAMP } from '../actions/constants';

const INITIAL_STATE = {
  searchText: null,
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case BOOTCAMP.SET_SEARCH:
      return {
        ...state,
        searchText: action.payload,
      };

    default:
      return state;
  }
}
