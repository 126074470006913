import React, { Component } from 'react';
import PropTypes from 'prop-types';
import AdminWrapper from '../v2/Nav/Admin/AdminWrapper';
import Loading from '../Utils/Loading';
import StaffProfile from './StaffProfile';
import StudentProfileContainer from './StudentProfileContainer';
import { canLearn } from '../../helpers/userAuth';
import * as analytics from '../../utils/analytics';
import intl from '../../utils/intl';

export default class Profile extends Component {
  constructor(props) {
    super(props);
    const { getUser, user, userId } = this.props;

    if (!user) {
      getUser(userId);
    }
  }

  componentDidMount() {
    analytics.pageStart('Profile');
  }

  componentWillUnmount() {
    const { user } = this.props;
    analytics.pageEnd(
      user && {
        user: `${user.name} (${user.email})`,
      },
    );
  }

  render() {
    const { user } = this.props;

    const content = (() => {
      if (!user) {
        return <Loading />;
      }
      if (canLearn(user)) {
        return <StudentProfileContainer user={user} />;
      }
      return <StaffProfile user={user} />;
    })();

    return (
      <AdminWrapper hideStaffNav={canLearn(user)} headerTitle={intl.formatMessage({ id: 'profile.profile' })}>
        <div className="learner-profile-page">{content}</div>
      </AdminWrapper>
    );
  }
}
Profile.propTypes = {
  getUser: PropTypes.func.isRequired,
  user: PropTypes.object,
  userId: PropTypes.string.isRequired,
};
