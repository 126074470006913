import { useSelector } from 'react-redux';
import useCurrentUser from './useCurrentUser';
import useCurrentEnrollment from './useCurrentEnrollment';
import { selectLtiProgressForAssignment } from '../selectors/ltiAssignmentProgressSelectors';
import { selectAssignment } from '../selectors/assignmentSelectors';
import { selectProgressForAssignment } from '../selectors/assignmentProgressSelectors';

export default function useAssignmentProgress(assignmentId) {
  let { isLtiStudent } = useCurrentUser();
  const { enrollment } = useCurrentEnrollment();
  if (enrollment) { isLtiStudent = enrollment.requires_lti_support; }

  const assignment = useSelector(s => (assignmentId ? selectAssignment(s, assignmentId) : null));
  // Look up the lti assignment progress using the context and the unit id (project id)
  const ltiProgress = useSelector(s =>
    enrollment && isLtiStudent && assignmentId ? selectLtiProgressForAssignment(s, enrollment.id, assignmentId) : null,
  );
  const prerequisiteProgress = useSelector(s =>
    assignment && assignment.prerequisite_id ? selectProgressForAssignment(s, assignment.prerequisite_id) : null,
  );
  const assignmentProgress = useSelector(s => (assignmentId ? selectProgressForAssignment(s, assignmentId) : null));

  const launchFromLMS = isLtiStudent && !ltiProgress;
  const prerequisiteComplete = !prerequisiteProgress || !!prerequisiteProgress.completed_at;

  return {
    assignment,
    assignmentProgress,
    launchFromLMS,
    prerequisiteComplete,
    ltiProgress,
  };
}
