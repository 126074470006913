import Part from '../api/part';
import { applicationDestroy, applicationSave, applicationApiExecute } from './applicationActions';
import { updateEntities } from './entityActions';

export const savePart = applicationSave(Part);
export const destroyPart = applicationDestroy(Part);

export const reorderParts = (partIds, lesson) => dispatch => {
  const entities = {
    lessons: {
      [lesson.id]: {
        ...lesson,
        part_ids: partIds,
      },
    },
  };

  dispatch(updateEntities({ entities }));

  return applicationApiExecute(Part.reorder)(partIds, lesson.id)(dispatch);
};
