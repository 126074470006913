import { connect } from 'react-redux';
import { saveUser } from '../../actions/userActions';
import EditUserModal from './EditUserModal';
import { selectCurrentUser } from '../../selectors/authSelectors';

const mapStateToProps = state => {
  const currentUser = selectCurrentUser(state);

  return {
    currentUser,
  };
};

export default connect(mapStateToProps, { saveUser })(EditUserModal);
