/* eslint complexity: off */
import React, { useState, useRef } from 'react';
import { isEmpty as _isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import classnames from 'classnames';
import Icons from '../../Utils/Icons';
import intl from '../../../utils/intl';
import LabFeedback from '../../../api/labFeedback';
import { isNavigationDisabled } from '../../../utils/queryStringHelper';
import { LabClassifications } from '../LabClassifications';

export default function ModalContent({ formData, onSubmit, onCancel, showCancel, labClassification }) {
  const [state, setState] = useState({ ...formData, liked: null });
  const submittedRef = useRef(false);

  const handleChange = e => {
    const { target } = e;
    const { value, name } = target;

    setState({ ...state, [name]: value });
  };

  const handleRatingChange = e => {
    if (e.type === 'click' || e.key === 'Enter' || e.key === ' ' || e.key === 'Space Bar') {
      e.preventDefault();
    }

    const inputEl = e.target.children.liked;
    const isChecked = inputEl.checked;
    let liked = inputEl.value === 'thumb-up';

    if (isChecked) {
      liked = null; // reset if clicking again
    }
    setState({ ...state, liked });
  };

  const handleSubmit = async e => {
    e.preventDefault();
    try {
      const { liked, feedback } = state;
      if (!submittedRef.current && (!_isEmpty(feedback) || liked !== null)) {
        submittedRef.current = true;
        await LabFeedback.create(state);
      }
    } catch (ex) {
      // eslint-disable-next-line no-console
      console.error('Error submitting lab feedback', ex);
    }

    if (isNavigationDisabled()) {
      window.close();
      return;
    }

    onSubmit();
  };

  return (
    <section className="lab-congrats__panel lab-congrats__panel--light">
      <header className="lab-congrats__title">
        <FormattedMessage id="labCompletion.heading" />
      </header>
      <form aria-label="congratulations" onSubmit={handleSubmit} className="lab-congrats__form buttons-lessons">
        <div className="congrats-form">
          <div className="congrats-form__title">
            {labClassification === LabClassifications.PROJECT ? (
              <FormattedMessage id="labCompletion.projectFormTitle" />
            ) : (
              <FormattedMessage id="labCompletion.labFormTitle" />
            )}
          </div>

          <div className="congrats-form__rating">
            <label
              className="congrats-form__icon"
              tabIndex="0"
              onClick={handleRatingChange}
              onKeyPress={handleRatingChange}>
              <span>
                <FormattedMessage id="labCompletion.thumbUp" />
              </span>
              <input
                className="congrats-form__radio congrats-form__radio--positive"
                type="radio"
                name="liked"
                value="thumb-up"
                checked={state.liked === true}
                onChange={() => {}}></input>
              {state.liked === true ? <Icons.WhiteThumbUp /> : <Icons.ThumbUp />}
            </label>
            <label
              className="congrats-form__icon"
              tabIndex="0"
              onClick={handleRatingChange}
              onKeyPress={handleRatingChange}>
              <span>
                <FormattedMessage id="labCompletion.thumbDown" />
              </span>
              <input
                className="congrats-form__radio congrats-form__radio--negative"
                type="radio"
                name="liked"
                value="thumb-down"
                checked={state.liked === false}
                onChange={() => {}}></input>
              {state.liked === false ? <Icons.WhiteThumbDown /> : <Icons.ThumbDown />}
            </label>
          </div>

          <div className="congrats-form__container">
            <textarea
              aria-label={intl.formatMessage({ id: 'labCompletion.placeholder' })}
              placeholder={intl.formatMessage({ id: 'labCompletion.placeholder' })}
              name="feedback"
              value={state.feedback || ''}
              onChange={handleChange}
            />
            <div className="congrats-form__note">
              <FormattedMessage id="labCompletion.footnote" />
            </div>

            <div className="congrats-form__controls">
              <button
                type="submit"
                className={classnames(
                  'lab-button',
                  'button-standard',
                  'lab-button--blue',
                  'lab-button--radius',
                  showCancel && 'lab-button-part',
                )}>
                <div className="lab-button__label">
                  <span>
                    {(() => {
                      if (isNavigationDisabled()) {
                        return <FormattedMessage id="labCompletion.finishedForLaunchInNewTab" />;
                      }
                      return <FormattedMessage id="labCompletion.continue" />;
                    })()}
                  </span>
                </div>
              </button>
              {showCancel && (
                <button type="button" className="congrats-form__link" onClick={onCancel}>
                  <Icons.GoBack />
                  <FormattedMessage id="labCompletion.backToLab" />
                </button>
              )}
            </div>
          </div>
        </div>
      </form>
    </section>
  );
}

ModalContent.propTypes = {
  formData: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  showCancel: PropTypes.bool,
  labClassification: PropTypes.string.isRequired,
};
