import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import UserPicture from '../Utils/UserPicture';
import AuthorEditToggle from './AuthorEditToggle';
import { ReactComponent as SvgIconCloseWhite } from '../../styles/pathstream/svg-jsx/icon-close-white.svg';
import { Link } from 'react-router-dom';

export default class ProfileNav extends Component {
  static propTypes = {
    currentUser: PropTypes.object,
    isStudent: PropTypes.bool,
    hideAuthorToggle: PropTypes.bool,
    signOut: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
    };
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClick, false);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClick, false);
  }

  handleClick = e => {
    if (this.node && this.node.contains(e.target)) {
      return;
    }

    if (this.state.isOpen) {
      this.toggleMenu();
    }
  };

  toggleMenu = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  };

  handleSignOut = e => {
    e.preventDefault();
    this.props.signOut();
  };

  render() {
    const { currentUser, isStudent, hideAuthorToggle } = this.props;
    const { isOpen } = this.state;

    if (!currentUser) {
      return (
        <div id="login">
          <Link className="login" to="/login">
            <FormattedMessage id="nav.login" />
          </Link>
        </div>
      );
    }

    return (
      <div ref={node => (this.node = node)}>
        <div className="user-block">
          <div className="user-meta">
            {!hideAuthorToggle && <AuthorEditToggle />}
            <div className="text-elements" onClick={this.toggleMenu}>
              <p className="user-name">{currentUser.name}</p>
            </div>
            {isOpen ? (
              <button className="user-menu-close-btn" aria-label="close-button" onClick={this.toggleMenu}>
                <SvgIconCloseWhite />
              </button>
            ) : (
              <UserPicture user={currentUser} defaultWhite={true} onClick={this.toggleMenu} />
            )}
          </div>
        </div>

        {isOpen && (
          <div className="user-menu-wrapper">
            <div className="user-meta" onClick={this.toggleMenu}>
              <div className="text-elements">
                <p className="user-name">{currentUser.name}</p>
              </div>
              <UserPicture user={currentUser} defaultWhite={true} />
            </div>
            <nav>
              <ul>
                <li>
                  <Link to={`/profile/${currentUser.id}`}>
                    <FormattedMessage id="nav.profile" />
                  </Link>
                </li>
                {isStudent && (
                  <li>
                    <Link to="/student/bootcamps">
                      <FormattedMessage id="nav.myBootcamps" />
                    </Link>
                  </li>
                )}
                <li>
                  <a href=" #" onClick={this.handleSignOut}>
                    <FormattedMessage id="nav.signOut" />
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        )}
      </div>
    );
  }
}
