import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { isCodioApp, isEmbedApp, isMysqlApp, showStream } from '../../helpers/labHelper';
import LabLoadingModal from './LabLoadingModal/LabLoadingModal';
import StreamContainer from '../Utils/StreamContainer';
import { CodioLab } from './CodioLab';

const Lab = ({
  labInteractable,
  bootcamp,
  projectUrl,
  cohortId,
  projectFolderPath,
  component = {},
  steps = [],
  analyticsParams,
  streamingUrl,
  handleProjectUrlReceived,
  hasEnded,
  saveToS3 = false,
  preview = true,
  shouldShowLabLoadingModal = true,
  launchIn,
  saveBackups = true,
  useFreshCopy = false,
  targetUsername,
  ...streamContainerProps
}) => {
  if (isCodioApp(labInteractable.app)) {
    return <CodioLab tutorial={labInteractable} />;
  }

  if (isEmbedApp(labInteractable.app)) {
    return (
      <iframe
        src={projectUrl}
        frameBorder="0"
        marginHeight="0"
        marginWidth="0"
        height="100%"
        width="100%"
        allowFullScreen
      />
    );
  }

  if (!showStream(labInteractable.app, projectUrl)) {
    return (
      <div className="alert alert-warn" role="status">
        <FormattedMessage id="tutorial.noStarterProject" />
      </div>
    );
  }

  const getLabInteractableStreamParams = () => {
    let dbStreamParams = { starter_db_url: null, public_dbs: null };

    if (isMysqlApp(labInteractable.app)) {
      if (steps.length > 0) {
        dbStreamParams = { starter_db_url: steps[0].starter_db_url, public_dbs: steps[0].public_dbs };
      } else {
        dbStreamParams = { starter_db_url: labInteractable.starter_db_url, public_dbs: labInteractable.public_dbs };
      }
    }

    return {
      project_id: labInteractable.id,
      lab_id: labInteractable.id,
      app_site_id: labInteractable.app_site_id,
      title: labInteractable.title || component.title,
      ...dbStreamParams,
    };
  };

  return (
    <>
      {shouldShowLabLoadingModal && <LabLoadingModal app={labInteractable.app} />}

      <StreamContainer
        streamParams={{
          ...getLabInteractableStreamParams(),
          bootcamp_name: bootcamp?.title,
          project_url: projectUrl,
          cohort_id: cohortId,
          save_to_s3: saveToS3,
          folder_path: projectFolderPath,
          preview,
          save_backups: saveBackups,
          use_fresh_copy: useFreshCopy,
          target_username: targetUsername,
        }}
        analyticsParams={analyticsParams}
        streamingUrl={streamingUrl}
        onUrlReceived={handleProjectUrlReceived}
        app={labInteractable.app}
        launchIn={launchIn || labInteractable.launch_in}
        hasEnded={hasEnded}
        {...streamContainerProps}
      />
    </>
  );
};

Lab.propTypes = {
  labInteractable: PropTypes.object,
  bootcamp: PropTypes.object,
  projectUrl: PropTypes.string,
  cohortId: PropTypes.string,
  projectFolderPath: PropTypes.string,
  component: PropTypes.object,
  steps: PropTypes.array,
  analyticsParams: PropTypes.object,
  streamingUrl: PropTypes.string,
  handleProjectUrlReceived: PropTypes.func,
  hasEnded: PropTypes.bool,
  saveToS3: PropTypes.bool,
  preview: PropTypes.bool,
  launchIn: PropTypes.string,
  saveBackups: PropTypes.bool,
  useFreshCopy: PropTypes.bool,
  targetUsername: PropTypes.string,
};

export default Lab;
