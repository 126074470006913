import { map as _map } from 'lodash';
import React, { Component } from 'react';
import SectionTileHeaderContainer from '../../../Sections/SectionTileHeaderContainer';
import UnitTileContainer from './UnitTileContainer';

export default class SectionTile extends Component {
  render() {
    const { section, index, units, hiddenItems, onChange } = this.props;

    return (
      <React.Fragment>
        <SectionTileHeaderContainer section={section} index={index} />
        {_map(units, (unit, unitIndex) => (
          <UnitTileContainer
            key={unit.id}
            unit={unit}
            index={unitIndex}
            hiddenItems={hiddenItems}
            onChange={onChange}
          />
        ))}
      </React.Fragment>
    );
  }
}
