/* eslint-disable complexity */
import { map as _map } from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Element } from 'react-scroll';
import { FormattedMessage } from 'react-intl';
import { selectIsEditMode } from '../../selectors/contextSelectors';
import { reorderBlocks } from '../../actions/blockActions';
import AuthorBlockContainer from '../Author/BlockContainer';
import StudentBlockContainer from '../Student/BlockShowContainer';
import AddBlock from '../Author/Blocks/AddBlock';
import useCurrentUser from '../../hooks/useCurrentUser';
import LessonPartHeader from './LessonPartHeader';
import Icons from '../Utils/Icons';
import LessonPartFooter from './LessonPartFooter';
import { scrollToPart, getLessonPartStatus } from '../../helpers/lessonHelper';
import Block from '../../api/block';
import AddPart from '../Author/Blocks/AddPart';
import PartEditOptions from './PartEditOptions';
import useCurrentEnrollment from '../../hooks/useCurrentEnrollment';

const getBlockClass = block => {
  switch (block.block_type) {
    case Block.GRADED_QUIZ:
      return 'lesson-part__graded';
    case Block.QUIZ:
    case Block.SINGLE_QUESTION_QUIZ:
      return 'lesson-part__check';

    default:
      return 'lesson-part__contentBlock';
  }
};

export default function LessonPart({
  lesson,
  lessonProgress,
  part,
  partIndex,
  totalParts,
  blocks,
  currentPartId,
  isBeforeCurrentPart,
  onPartReorder,
}) {
  const { bootcampId } = useParams();
  const isEditMode = useSelector(selectIsEditMode);
  const dispatch = useDispatch();
  const { canAuthor } = useCurrentUser();
  const { enrollment } = useCurrentEnrollment();
  const { isComplete, isLessonComplete } = getLessonPartStatus(lessonProgress, lesson, part);
  const isCurrent = currentPartId === part.id;

  const handleReorder = (oldPosition, newPosition) => {
    const blockIds = _map(blocks, 'id');
    const removed = blockIds.splice(oldPosition, 1);
    blockIds.splice(newPosition, 0, removed[0]);

    dispatch(reorderBlocks(blockIds, lesson, part));
  };

  const handleGoToCurrent = () => scrollToPart(currentPartId);

  const BlockContainer = canAuthor ? AuthorBlockContainer : StudentBlockContainer;

  return (
    <article
      className={classnames(
        'lesson-part',
        isComplete && 'lesson-part--completed',
        !isComplete && isCurrent && !isEditMode && 'lesson-part--active',
        !isComplete && !isCurrent && 'lesson-part--normal',
      )}>
      <AddPart lesson={lesson} seqNum={part.seq_num} />

      <Element name={`part_${part.id}`} className="lesson-part__wrapper">
        <div className="lesson-part__container buttons-lessons">
          {isEditMode && <PartEditOptions part={part} index={partIndex} total={totalParts} onReorder={onPartReorder} />}

          {!isEditMode && !isCurrent && !isLessonComplete && (
            <div className="lesson-part__topButton">
              <button type="button" className="top-button" onClick={handleGoToCurrent}>
                <span className="top-button__icon">
                  <Icons.ArrowUpSmall />
                </span>
                <span className={classnames('top-button__label', isBeforeCurrentPart && 'arrow-down')}>
                  <FormattedMessage id="component.goToCurrent" />
                </span>
              </button>
            </div>
          )}

          <LessonPartHeader
            lesson={lesson}
            lessonProgress={lessonProgress}
            part={part}
            partIndex={partIndex}
            totalParts={totalParts}
          />

          <div className="lesson-part__content">
            {isEditMode && <AddBlock lesson={lesson} part={part} prevSeqNum={0} />}

            {blocks?.map((block, index) => (
              <div key={block.id} className={getBlockClass(block)}>
                <BlockContainer
                  index={index}
                  block={block}
                  bootcampId={bootcampId}
                  lesson={lesson}
                  isEditMode={isEditMode}
                  totalBlocks={blocks.length}
                  onReorder={handleReorder}
                  enrollmentId={enrollment && enrollment.id}
                />
                {isEditMode && <AddBlock lesson={lesson} part={part} prevSeqNum={block.seq_num} />}
              </div>
            ))}
          </div>

          <LessonPartFooter lesson={lesson} lessonProgress={lessonProgress} part={part} currentPartId={currentPartId} />
        </div>
      </Element>
    </article>
  );
}

LessonPart.propTypes = {
  lesson: PropTypes.object,
  lessonProgress: PropTypes.object,
  part: PropTypes.object,
  partIndex: PropTypes.number,
  totalParts: PropTypes.number,
  blocks: PropTypes.array,
  currentPartId: PropTypes.string,
  isBeforeCurrentPart: PropTypes.bool,
  onPartReorder: PropTypes.func,
};
