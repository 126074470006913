import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as SvgLogoOnLight } from '../../styles/pathstream/svg-jsx/logo-onlight.svg';
import PropTypes from 'prop-types';
import { getDefaultPath } from '../../helpers/pathHelper';

export default class Footer extends Component {
  static propTypes = {
    currentUser: PropTypes.object,
  };

  render() {
    return (
      <div className="ftr-global-container">
        <div className="component-container">
          <h2 id="label-ftr-global">Footer</h2>

          <footer className="ftr-global" aria-labelledby="label-ftr-global">
            <div className="footer-branding">
              <p>Powered by</p>
              <Link className="logo footer" to={getDefaultPath(this.props.currentUser)}>
                <SvgLogoOnLight />
                <span className="visually-hidden">PathStream</span>
              </Link>
            </div>
          </footer>
        </div>
      </div>
    );
  }
}
