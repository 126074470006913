import { connect } from 'react-redux';
import Grade from './Grade';
import { selectCohort, selectSortedCohorts } from '../../selectors/cohortSelectors';
import { selectCurrentCohortId } from '../../selectors/components/gradeSelectors';
import { setCurrentCohortId } from '../../actions/components/gradeActions';
import { fetchCohorts } from '../../actions/cohortActions';
import { fetchBootcamps } from '../../actions/bootcampActions';
import { selectBootcamp } from '../../selectors/bootcampSelectors';

const mapStateToProps = state => {
  const cohorts = selectSortedCohorts(state);
  const currentCohortId = selectCurrentCohortId(state) || (cohorts.length > 0 && cohorts[0].id) || null;
  const selectedCohort = currentCohortId && selectCohort(state, currentCohortId);
  const bootcamp = selectedCohort && selectBootcamp(state, selectedCohort.bootcamp_id);

  return {
    bootcamp,
    currentCohortId,
    selectedCohort,
  };
};

export default connect(mapStateToProps, {
  setCurrentCohortId,
  fetchCohorts,
  fetchBootcamps,
})(Grade);
