import axios from './axios';

export default class Unit {
  static MODULE = 'module';
  static PROJECT = 'project';
  static GRADED_QUIZ = 'graded_quiz';

  static all(_) {
    return axios.get('/paths/units').then(response => response.data);
  }

  static get(_, unitId) {
    return axios.get(`/paths/units/${unitId}`).then(response => response.data);
  }

  static save(_, unit, sectionId, futureCohortsOnly) {
    const data = {
      ...unit,
      section_id: sectionId,
      future_cohorts_only: futureCohortsOnly,
    };
    if (unit.id) {
      return axios.put(`/paths/units/${unit.id}`, data).then(response => response.data);
    }
    return axios.post('/paths/units', data).then(response => response.data);
  }

  static copy(_, unit, sectionId, futureCohortsOnly = true) {
    return axios
      .post(`/paths/units/${unit.id}/copy`, {
        section_id: sectionId,
        future_cohorts_only: futureCohortsOnly,
      })
      .then(response => response.data);
  }

  static reorder(_, unitIds, sectionId) {
    return axios
      .put(`/paths/sections/${sectionId}/reorder_units`, { unit_ids: unitIds })
      .then(response => response.data);
  }
}
