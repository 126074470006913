import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import useCurrentUser from './useCurrentUser';
import { fetchLtiAssignmentProgresses } from '../actions/ltiAssignmentProgressActions';

/**
 * Fetch the enrollments if not already present in redux.
 * Make sure to call this only once in a page preferably in the top level component
 */
export default function useFetchLtiAssignmentProgresses() {
  const { isLtiStudent } = useCurrentUser();
  const dispatch = useDispatch();

  useEffect(() => {
    if (isLtiStudent) {
      dispatch(fetchLtiAssignmentProgresses());
    }
  }, [isLtiStudent]);
}
