import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Loading from '../Utils/Loading';
import StudentProfileProjectsContainer from './StudentProfileProjectsContainer';
import { setPageTitle } from '../../helpers/pageHelper';
import StudentInfoPanel from '../Coach/Grading/StudentInfoPanel';
import ProfilePictureContainer from './ProfilePictureContainer';
import BackLink from '../Utils/BackLink';

export default class StudentProfile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedEnrollment: null,
    };
  }

  async componentDidMount() {
    const { getEnrollmentsForUser, user } = this.props;

    setPageTitle('pageName.studentProfile.my');

    const enrollments = await getEnrollmentsForUser(user.id);
    const enrollment = enrollments[0];
    this.setState({
      selectedEnrollment: enrollment,
    });
  }

  handleEnrollmentClick = (e, selectedEnrollment) => {
    e.preventDefault();

    this.setState({ selectedEnrollment });
  };

  renderBootcampsList = () => {
    const { enrollments, cohortEntities, bootcampEntities } = this.props;
    const { selectedEnrollment } = this.state;

    return enrollments.map(enr => {
      const cohort = cohortEntities[enr.enrollable_id];
      const bootcamp = bootcampEntities[cohort.bootcamp_id];

      return (
        <div
          key={enr.id}
          className={classnames(
            'student-roaster-title',
            selectedEnrollment && selectedEnrollment.id === enr.id && 'active',
          )}>
          <a href="# " className="student-roaster-title__link" onClick={e => this.handleEnrollmentClick(e, enr)}>
            {bootcamp.title}
          </a>
        </div>
      );
    });
  };

  render() {
    const { user, cohortEntities } = this.props;
    const { selectedEnrollment } = this.state;
    const cohort = cohortEntities[selectedEnrollment && selectedEnrollment.enrollable_id];

    if (!user) {
      return <Loading />;
    }

    return (
      <div className="grid-row">
        <div className="md-3 sm-4">{this.renderBootcampsList()}</div>

        <div className="md-7 sm-8">
          <div className="student-roster-content">
            <div className="student-roster-content__header">
              <div className="student-roster-content__go-back-wrapper">
                <BackLink className="go-back-wrapper---gray   go-back-wrapper---background-" goBack />
              </div>
              <div className="student-roster-content__content">
                <ProfilePictureContainer />

                <h1 className="student-roster-content__name">{user.name}</h1>

                {cohort && <StudentProfileProjectsContainer cohort={cohort} user={user} />}
              </div>
            </div>
          </div>
        </div>

        <div className="md-2 student-roaster-student-info-wrapper">
          <StudentInfoPanel student={user} />
        </div>
      </div>
    );
  }
}
StudentProfile.propTypes = {
  fetchEnrollment: PropTypes.func.isRequired,
  getEnrollmentsForUser: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
};
