import Section from '../api/section';
import { updateEntities } from './entityActions';
import { fetchCurrentTenant } from './tenantActions';
import { applicationSave } from './applicationActions';

export const saveSection = applicationSave(Section);

export const reorderSections = (sectionIds, path) => (dispatch, _getState) => {
  const entities = {
    paths: {
      [path.id]: {
        ...path,
        section_ids: sectionIds,
      },
    },
  };

  dispatch(updateEntities({ entities }));
  return fetchCurrentTenant()(dispatch).then(tenant =>
    Section.reorder(tenant.id, sectionIds, path.id)
      .then(_response => {})
      .catch(err => {
        throw err;
      }),
  );
};
