import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, Input } from 'antd';
import { FormattedMessage } from 'react-intl';

import intl from '../../../utils/intl';

const LinkUploadModal = ({ onHide, title, bodyMessage, instructions, note, onSubmit, linkPlaceHolder }) => {
  const [link, setLink] = useState();
  const [trySubmit, setTrySubmit] = useState(false);

  const closeModal = () => {
    onHide();
  };

  const submit = () => {
    setTrySubmit(true);
    if (link) {
      onSubmit(link, closeModal);
    }
  };

  const handleLinkChange = e => {
    setTrySubmit(false);
    setLink(e.target.value);
  };

  return (
    <Modal
      visible={true}
      onCancel={onHide}
      className="link-upload-modal"
      title={title}
      maskClosable={false}
      footer={null}>
      <div className="link-upload">
        <div>{bodyMessage}</div>
        {instructions?.length && (
          <ol className="instructions">
            {instructions.map((instruction, i) => (
              <li key={`inst-${i}`}>{instruction}</li>
            ))}
          </ol>
        )}
        {note && <div className="note">{note}</div>}

        <Input
          className="link-input"
          aria-label="link-input"
          placeholder={linkPlaceHolder}
          value={link}
          onChange={handleLinkChange}
        />
        {trySubmit && !link && (
          <div className="missing-link">
            <FormattedMessage id="linkUploadModal.missingLink" />
          </div>
        )}

        <div className="buttons">
          <Button className="submit-button" onClick={submit}>
            <FormattedMessage id="linkUploadModal.submitButton" />
          </Button>
        </div>
      </div>
    </Modal>
  );
};

LinkUploadModal.propTypes = {
  onHide: PropTypes.func,
  onSubmit: PropTypes.func,
  title: PropTypes.string,
  bodyMessage: PropTypes.string,
  instructions: PropTypes.array,
  note: PropTypes.string,
  linkPlaceHolder: PropTypes.string,
};

LinkUploadModal.defaultProps = {
  title: intl.formatMessage({ id: 'linkUploadModal.title' }),
  bodyMessage: intl.formatMessage({ id: 'linkUploadModal.bodyMessage' }),
};

export default LinkUploadModal;
