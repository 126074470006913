import { getLocationPath, getDataSourcePath } from './dataSourceHelpers';
import { evaluator } from './evalHelpers';

// eslint-disable-next-line complexity
export const evalPivotTableRow = ({ data, location, property, operator, value }) => {
  // Get the specific location (one before the last one)
  const pivotTable = getLocationPath(data, location);
  if (!pivotTable) return null;
  const { type, order, name } = location[location.length - 1].dataSource;

  // locate path based on dataSource
  const pivotTableRowLocator = getDataSourcePath({ type, order, name });
  const pivotTableRow = pivotTableRowLocator(pivotTable.rows);

  switch (property) {
    case 'exists':
      // assuming we only use title for the dataSource
      return !!pivotTableRow;
    case 'orderBy':
      if (type === 'byTitle') {
        const { sortOrder } = pivotTableRow;
        if (operator === 'contains') {
          if (sortOrder.includes(value)) return true;
        } else if (operator === 'does not contain') {
          if (!sortOrder.includes(value)) return true;
        } else {
          return evaluator(sortOrder, operator, value);
        }
      }
      if (type === 'byAny' || type === 'byAll') {
        return pivotTableRowLocator(pivotTable.rows)(row => {
          const { sortOrder } = row;
          if (operator === 'contains') {
            if (sortOrder.includes(value)) return true;
          } else if (operator === 'does not contain') {
            if (!sortOrder.includes(value)) return true;
          } else {
            return evaluator(sortOrder, operator, value);
          }
          return false;
        });
      }
      return false;
    // not able to find where this is in the api
    // case 'sortBy':
    case 'showTotals':
      if (type === 'byTitle') {
        const { showTotals } = pivotTableRow;
        return evaluator(showTotals, operator, value);
      }
      if (type === 'byAny' || type === 'byAll') {
        return pivotTableRowLocator(pivotTable.rows)(row => {
          const { showTotals } = row;
          return evaluator(showTotals, operator, value);
        });
      }
      return false;
    case 'repeatRowLabels':
      if (type === 'byTitle') {
        const { repeatRowLabels } = pivotTableRow;
        return evaluator(repeatRowLabels, operator, value);
      }
      if (type === 'byAny' || type === 'byAll') {
        return pivotTableRowLocator(pivotTable.rows)(row => {
          const { repeatRowLabels } = row;
          return evaluator(repeatRowLabels, operator, value);
        });
      }
      return false;
    default:
      return null;
  }
};

export default evalPivotTableRow;
