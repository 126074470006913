import { REQUEST } from '../actions/constants';

const INITIAL_STATE = {};

function updatedRequestType(state, action, by) {
  const { requestType } = action.payload;

  return {
    [requestType]: (state[requestType] || 0) + by,
  };
}

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case REQUEST.START:
      return {
        ...state,
        ...updatedRequestType(state, action, 1),
      };

    case REQUEST.END:
      return {
        ...state,
        ...updatedRequestType(state, action, -1),
      };

    default:
      return state;
  }
}
