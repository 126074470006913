import { isEmpty as _isEmpty } from 'lodash';
import { toast } from 'react-toastify';
import React from 'react';
import { AUTH, COMPONENT_COACH } from './constants';
import AuthAPI from '../api/auth';
import User from '../api/user';
import { clearTokens, getTokens, setTokens, areTokensValid, getTokenExpirationEpoch } from '../helpers/userAuth';
import { fetchCurrentTenant } from './tenantActions';
import history from '../history';
import { updateEntities } from './entityActions';
import { connect as socketConnect, disconnect as socketDisconnect } from '../sockets';
import SessionTimeoutWarning from '../components/Utils/SessionTimeoutWarning';
import { analyticsSetUser, analyticsClearUser } from './analyticsActions';

const RETURN_URL_KEY = 'RETURN_URL';
const promises = {};

const getUser = (accessToken, dispatch) => {
  if (promises.userCurrent) {
    return promises.userCurrent;
  }

  setTokens({ accessToken });
  updateSessionTimeoutWarning();

  return (promises.userCurrent = User.current(accessToken)
    .then(user => {
      analyticsSetUser(user);
      socketConnect();
      dispatch(loggedIn(user));

      const entities = { users: { [user.id]: user } };
      dispatch(updateEntities({ entities }));

      delete promises.userCurrent;
      setTimeout(() => {
        const returnUrl = JSON.parse(localStorage.getItem(RETURN_URL_KEY) || '{}');
        if (!_isEmpty(returnUrl)) {
          localStorage.removeItem(RETURN_URL_KEY);
          history.push(returnUrl);
        }
      }, 0);
      return user;
    })
    .catch(err => {
      dispatch(loggingIn(false));
      delete promises.userCurrent;
      // HACK: this is to prevent getting signed out on hot-reload in development
      if (process.env.NODE_ENV !== 'development') {
        throw err;
      }
    }));
};

const isTokenValid = () => {
  const { accessToken } = getTokens();

  if (areTokensValid()) {
    return Promise.resolve({ accessToken });
  }

  return Promise.reject('Token expired');
};

// thunks
/**
 * Check if the User is logged in and update redux
 */
export const isLoggedIn = () => dispatch => {
  dispatch(loggingIn(true));

  return isTokenValid()
    .then(({ accessToken }) => getUser(accessToken, dispatch))
    .catch(err => {
      console.error(err);
      clearTokens();
      analyticsClearUser();
      dispatch(logout());
      dispatch(loggingIn(false));
    });
};

/**
 * Sign out the user
 */
export const signOut = expired => dispatch => {
  const { accessToken } = getTokens();

  let loginUrl = '/login';
  if (expired) {
    localStorage.setItem(RETURN_URL_KEY, JSON.stringify(history.location));
    loginUrl += '?expired=1';
  }

  clearTokens();
  localStorage.removeItem(COMPONENT_COACH.COACH_COHORT_IDS);
  analyticsClearUser();
  socketDisconnect();
  if (!accessToken) {
    history.push(loginUrl);
    return Promise.resolve();
  }

  return AuthAPI.signOut(accessToken)
    .then(() => {
      dispatch(logout());
      history.push(loginUrl);
    })
    .catch(err => {
      console.error(err);
      dispatch(logout());
      history.push(loginUrl);
    });
};

/**
 * Sign in using username and password
 * @param username - Username
 * @param password - Password
 */
export const signIn = (username, password) => dispatch => {
  dispatch(loggingIn(true));

  return fetchCurrentTenant()(dispatch).then(tenant =>
    AuthAPI.signIn(tenant.id, username, password)
      .then(response => {
        dispatch(loggingIn(false));
        return getUser(response.access_token, dispatch);
      })
      .catch(err => {
        dispatch(loggingIn(false));
        throw err;
      }),
  );
};

export const verifyConfirmationToken = confirmationToken => dispatch => {
  dispatch(loggingIn(true));
  return AuthAPI.verifyConfirmationToken(confirmationToken)
    .then(user => {
      dispatch(loggingIn(false));
      return user;
    })
    .catch(err => {
      dispatch(loggingIn(false));
      throw err;
    });
};

export const confirmAndSetPassword = (confirmationToken, password, passwordConfirmation) => dispatch => {
  dispatch(loggingIn(true));
  return AuthAPI.confirmAndSetPassword(confirmationToken, password, passwordConfirmation)
    .then(user => {
      dispatch(loggingIn(false));
      return user;
    })
    .catch(err => {
      dispatch(loggingIn(false));
      throw err;
    });
};

export const forgotPassword = email => dispatch => {
  dispatch(loggingIn(true));
  return AuthAPI.forgotPassword(email)
    .then(response => {
      dispatch(loggingIn(false));
      return response;
    })
    .catch(err => {
      dispatch(loggingIn(false));
      throw err;
    });
};

export const resetPassword = (token, password, passwordConfirmation, currentPassword) => dispatch => {
  dispatch(loggingIn(true));
  return AuthAPI.resetPassword(token, password, passwordConfirmation, currentPassword)
    .then(user => {
      dispatch(loggingIn(false));
      return user;
    })
    .catch(err => {
      dispatch(loggingIn(false));
      throw err;
    });
};

export const acceptTermsOfService = () => dispatch =>
  AuthAPI.acceptTermsOfService().then(user => dispatch(setUser(user)));

let lastPing;
const PING_INTERVAL = 60 * 1000; // 60 seconds
// Sent Keep Alive requests every X minutes
export const ping = force => async () => {
  try {
    const now = Date.now();
    if (areTokensValid() && (force || !lastPing || lastPing + PING_INTERVAL < now)) {
      lastPing = now;

      const response = await AuthAPI.ping();
      setTokens({ accessToken: response.access_token });
      updateSessionTimeoutWarning();
    }
  } catch (e) {
    console.error('Error pinging server', e);
  }
};

// Action Creators
/**
 * Logout action
 * @returns {{type: string}}
 */
export const logout = () => ({
  type: AUTH.LOGOUT,
});

const SECONDS_BEFORE_TIMEOUT_TO_WARN = 20;
let sessionTimeoutWarningId;
const updateSessionTimeoutWarning = () => {
  const tokenExpirationEpoch = getTokenExpirationEpoch();
  if (!tokenExpirationEpoch) return;

  const warningTimeEpoch = tokenExpirationEpoch - SECONDS_BEFORE_TIMEOUT_TO_WARN * 1000;
  const warningTimeDelay = warningTimeEpoch - new Date();

  clearTimeout(sessionTimeoutWarningId);
  sessionTimeoutWarningId = setTimeout(displaySessionTimeoutWarning, warningTimeDelay, tokenExpirationEpoch);
};

let toastId;
const displaySessionTimeoutWarning = tokenExpirationEpoch =>
  (toastId = toast(<SessionTimeoutWarning timeoutEpoch={tokenExpirationEpoch} stop={stopSessionTimeout} />, {
    type: toast.TYPE.INFO,
    closeButton: false,
    closeOnClick: false,
    pauseOnHover: false,
    pauseOnFocusLoss: false,
    autoClose: SECONDS_BEFORE_TIMEOUT_TO_WARN * 1000,
  }));

const stopSessionTimeout = () => {
  toast.dismiss(toastId);
  ping(true)(null);
};

/**
 * Logged in action - Set the user in redux
 * @param user - User
 * @returns {{type: string, payload: {user: *}}}
 */
export const loggedIn = user => ({
  type: AUTH.LOGGED_IN,
  payload: { user },
});

/**
 * Set the user in redux
 * @param user - User
 * @returns {{type: string, payload: *}}
 */
export const setUser = user => ({
  type: AUTH.SET_USER,
  payload: user,
});

/**
 * Set the login status
 * @param value - Status
 * @returns {{type: string, payload: *}}
 */
export const loggingIn = value => ({
  type: AUTH.LOGGING_IN,
  payload: value,
});
