import { map as _map, compact as _compact } from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { toast } from 'react-toastify';
import Modal2 from '../library/Modal2';
import intl from '../../utils/intl';
import { moveToStep } from '../../actions/blockActions';
import { toastException, toastSuccess } from '../../utils/toastHelper';
import { confirmInput } from '../../utils/confirmInput';
import { selectTutorialByBlock } from '../../selectors/tutorialSelectors';
import { selectTutorialSteps, selectStepByBlock } from '../../selectors/stepSelectors';

export default function SelectStepModal({ block, onHide }) {
  const dispatch = useDispatch();
  const tutorial = useSelector(state => selectTutorialByBlock(state, block));
  const steps = useSelector(state => selectTutorialSteps(state, tutorial.id));

  const currentBlockStep = useSelector(state => selectStepByBlock(state, block));

  const otherSteps = _compact(
    _map(steps, (step, index) => {
      if (step.id !== currentBlockStep.id) {
        return { step, index };
      }
      return null;
    }),
  );

  const handleSelect = step => {
    confirmInput({
      message: intl.formatMessage({ id: 'block.moveToStep.confirm' }),
      onOk: async () => {
        const toastId = toast(intl.formatMessage({ id: 'block.moveToStep.saving' }), {
          type: toast.TYPE.INFO,
          autoClose: false,
        });
        try {
          await moveToStep(block.id, step.id)(dispatch);

          toast.dismiss(toastId);
          toastSuccess(intl.formatMessage({ id: 'block.moveToStep.success' }));
          onHide();
        } catch (err) {
          // eslint-disable-next-line no-console
          console.log('Error moving block', err);
          toast.dismiss(toastId);
          toastException(err, intl.formatMessage({ id: 'block.moveToStep.failure' }));
        }
      },
    });
  };

  return (
    <Modal2 onHide={onHide} title={intl.formatMessage({ id: 'block.moveToStep.heading' })}>
      <div className="select-parts">
        <ul className="select-parts__list">
          {otherSteps.map(({ step, index }) => (
            <li key={step.id} className="select-parts__item">
              <span className="select-parts__label">
                <FormattedMessage id="component.partIndex" values={{ index: index + 1 }} />
                {step.title && ` - ${step.title}`}
              </span>
              <div className="select-parts__button">
                <button
                  type="button"
                  onClick={() => handleSelect(step)}
                  className="button-standard button-standard--blue2 button-standard--big ">
                  <FormattedMessage id="common.select" />
                </button>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </Modal2>
  );
}

SelectStepModal.propTypes = {
  block: PropTypes.object.isRequired,
  onHide: PropTypes.func.isRequired,
};
