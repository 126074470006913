/* eslint-disable lines-between-class-members */
import { v4 as uuidv4 } from 'uuid';
import Check from './Check/Check';

export default class Criteria {
  constructor(name, description, logicFormula, allowSubmitOnError, points) {
    this.id = uuidv4();
    this.name = name;
    this.description = description;
    this.checks = [];
    this.successMsg = 'You got it!';
    this.errorInstructorMsg = 'Your student needs work.';
    this.errorStudentMsg = 'You need some aid';
    this.allowSubmitOnError = allowSubmitOnError;
    this.formula = logicFormula;
    this.points = points;
  }

  addCheck = ({ name, location, property, operator, value }) => {
    this.checks.push(new Check(name, location, property, operator, value));
  };

  gradeOnFormula = () => {
    const status = true;

    // TODO: need logic to check if the results passes based on formula
    // tokens.forEach((token) => {});

    return status;
  };

  getPoints = () => {
    // TODO: need logic here
    return this.points;
  };

  runChecks = data => {
    const results = this.checks.map(check => {
      const status = check.run(data);
      return {
        checkName: check.name,
        status,
        detail: check.value,
      };
    });

    const isPassed = this.gradeOnFormula();

    // NOTE: future implementation may call for partial credit on points
    return {
      criteria: this.name,
      status: isPassed,
      points: isPassed ? this.points : 0,
      checksResult: results,
    };
  };
}
