import React, { Component } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import HeaderContainer from './HeaderContainer';
import { ReactComponent as SvgIconArrowPoint } from '../../styles/pathstream/svg-jsx/icon-arrow-point.svg';
import StaffSidebarContainer from './StaffSidebarContainer';
import { canAuthor, isStaff } from '../../helpers/userAuth';
import ReactTooltip from 'react-tooltip';
import Footer from './Footer';

export default class ContentWrapper extends Component {
  static propTypes = {
    breadcrumb: PropTypes.object,
    className: PropTypes.string,
    currentPathId: PropTypes.string,
    currentSectionId: PropTypes.string,
    currentUnitId: PropTypes.string,
    currentUser: PropTypes.object,
    currentComponentId: PropTypes.string,
    isCollapsed: PropTypes.bool.isRequired,
    sideNavComponent: PropTypes.node,
    hideStaffNav: PropTypes.bool,
    headerText: PropTypes.string,
    hideAuthorToggle: PropTypes.bool,
    showFooter: PropTypes.bool,
    home: PropTypes.shape({
      text: PropTypes.string,
      url: PropTypes.string,
    }),
  };

  static defaultProps = {
    breadcrumb: null,
    className: null,
    currentPathId: null,
    currentSectionId: null,
    currentUnitId: null,
    currentUser: null,
    sideNavComponent: null,
    hideStaffNav: false,
    hideAuthorToggle: false,
    showFooter: true,
  };

  renderBreadcrumb = () => {
    const { breadcrumb } = this.props;

    if (!breadcrumb) {
      return null;
    }

    const content = (
      <React.Fragment>
        <SvgIconArrowPoint />
        <p>{breadcrumb.text}</p>
      </React.Fragment>
    );

    // 20180731JP: <Link/> complains if you don't pass it a `to` property.
    //  We could pass `to:'#'` in addition to `onClick` but figured
    //  this is less error prone.
    const link = breadcrumb.to ? (
      <Link to={breadcrumb.to} className="back-link">
        {content}
      </Link>
    ) : (
      <a onClick={breadcrumb.onClick} className="back-link">
        {content}
      </a>
    );

    return <div className="breadcrumb">{link}</div>;
  };

  componentDidUpdate() {
    setTimeout(() => ReactTooltip.rebuild(), 0);
  }

  render() {
    const {
      children,
      className,
      currentPathId,
      currentSectionId,
      currentUnitId,
      currentUser,
      currentComponentId,
      isCollapsed,
      sideNavComponent,
      hideStaffNav,
      headerText,
      isEditMode,
      hideAuthorToggle,
      showFooter,
      home,
    } = this.props;

    const hasSideNav = !!sideNavComponent;
    const isUserStaff = isStaff(currentUser);
    const isAuthor = canAuthor(currentUser);
    const showStaffSidebar =
      !hideStaffNav && isUserStaff && !hasSideNav && (hideAuthorToggle || !isAuthor || isEditMode);
    const classes = classnames([
      className,
      'main-container',
      hasSideNav && !isCollapsed && 'menu-open',
      showStaffSidebar && 'is-staff',
    ]);

    const innerStyles = {};
    if (!showFooter) {
      innerStyles.paddingBottom = 0;
    }

    return (
      <React.Fragment>
        <HeaderContainer
          currentPathId={currentPathId}
          currentSectionId={currentSectionId}
          currentUnitId={currentUnitId}
          currentComponentId={currentComponentId}
          hasSideNav={hasSideNav}
          headerText={headerText}
          hideAuthorToggle={hideAuthorToggle}
          home={home}
        />
        <div className={classes}>
          <main id="main" className="full-content" tabIndex={0}>
            <div className="full-content-inner" style={innerStyles}>
              {!isCollapsed && sideNavComponent}
              {showStaffSidebar && <StaffSidebarContainer />}
              {this.renderBreadcrumb()}
              {children}
            </div>
          </main>
          <ReactTooltip
            effect="solid"
            event="mouseenter focusin"
            eventOff="mouseleave focusout click"
            className="tooltip"
          />
        </div>

        {showFooter && <Footer currentUser={currentUser} />}
      </React.Fragment>
    );
  }
}
