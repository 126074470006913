import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { selectSaving } from '../selectors/requestSelectors';
import intl from '../utils/intl';

const useSaving = () => {
  const isSaving = useSelector(selectSaving);

  const isSavingInProcess = () => {
    if (isSaving) {
      toast.warning(intl.formatMessage({ id: 'common.savingWarning' }));
    }
    return isSaving;
  };
  return [isSaving, isSavingInProcess];
};

export default useSaving;
