import { sortBy as _sortBy, isEmpty as _isEmpty } from 'lodash';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import intl from '../../utils/intl';
import { useDispatch, useSelector } from 'react-redux';
import { selectBootcamps } from '../../selectors/bootcampSelectors';
import { fetchBootcamps } from '../../actions/bootcampActions';
import { bootcampOptionValue, bootcampOptionLabel } from '../../helpers/bootcampHelper';

const BootcampSelector = ({ bootcamps, selected, onSelect }) => {
  const dispatch = useDispatch();
  const storeBootcamps = useSelector(selectBootcamps);
  const [sortedBootcamps, setSortedBootcamps] = useState([]);
  const [selectedBootcamp, setSelectedBootcamp] = useState(null);

  useEffect(() => {
    // fetch all bootcamps for scenario where not all bootcamps were fetched prior
    // e.g. If user goes straight to lesson page after login (by navigating directly with url),
    //   user would not have gone through cohorts page so not all bootcamps would have been fetched
    // Note: may want to pull this out and move to a higher level component,
    //   so that this component can be more presentational and only deal with bootcamps passed in
    dispatch(fetchBootcamps());
  }, []);

  useEffect(() => {
    setSortedBootcamps(
      _sortBy(bootcamps || storeBootcamps, bc => {
        return (bc.cohort_title || bc.title).toLowerCase();
      }),
    );
  }, [bootcamps, storeBootcamps]);

  useEffect(() => {
    if (!selectedBootcamp && !_isEmpty(sortedBootcamps)) {
      setSelectedBootcamp(sortedBootcamps[0]);
    }
  }, [sortedBootcamps]);

  useEffect(() => {
    if (!selectedBootcamp && selected) {
      setSelectedBootcamp(selected);
    }
  }, [selected]);

  useEffect(() => {
    if (selectedBootcamp) {
      onSelect && onSelect(selectedBootcamp);
    }
  }, [selectedBootcamp]);

  const bootcampOptions = () => {
    return sortedBootcamps.map(bootcamp => ({
      value: bootcampOptionValue(bootcamp),
      label: bootcampOptionLabel(bootcamp),
      bootcamp,
    }));
  };

  const handleSelect = option => {
    setSelectedBootcamp(option.bootcamp);
  };

  const isLoading = _isEmpty(sortedBootcamps);

  return (
    <Select
      className="Select--simple"
      value={bootcampOptionValue(selectedBootcamp)}
      isLoading={isLoading}
      clearable={false}
      name="bootcamps"
      options={bootcampOptions()}
      onChange={handleSelect}
      placeholder={intl.formatMessage({ id: 'common.selectBootcamp' })}
    />
  );
};

BootcampSelector.propTypes = {
  selected: PropTypes.object,
  bootcamps: PropTypes.array,
  onSelect: PropTypes.func,
};

export default BootcampSelector;
