/* eslint-disable max-lines */
import React, { Component } from 'react';

export default class TermsOfService extends Component {
  render() {
    return (
      <div className="terms-of-service-content">
        <h1>Terms of Use</h1>
        <p><i>Effective Date: January 30, 2023</i></p>
        <p>Welcome to Pathstream. Please read on to learn the rules and restrictions that govern your use of our website(s), products, services and applications (the “Services”). If you have any questions, comments, or concerns regarding these terms or the Services, please contact us at:</p>
        <ul>
          <li><b>Email:&nbsp;</b><a href="mailto:privacy@pathstream.com">privacy@pathstream.com</a></li>
          <li><b>Phone:</b>&nbsp;(650) 437-5825</li>
          <li><b>Address:&nbsp;</b>580 Market Street, Suite 400, San Francisco, CA 94104</li>
        </ul>
        <p>These Terms of Use (the “Terms”) are a binding contract between you and&nbsp;<b>PATHSTREAM, INC.</b>&nbsp;(“Pathstream,” “we” and “us”). Your use of the Services in any way means that you agree to all of these Terms, and these Terms will remain in effect while you use the Services. These Terms include the provisions in this document as well as those in the&nbsp;<a href="https://www.pathstream.com/privacy-policy/?utm_source=app_pathstream">Privacy Policy</a>,&nbsp;<a href="https://www.pathstream.com/terms-of-use/#copyright-dispute">Copyright Dispute Policy</a>, and any other relevant policies.<b>&nbsp;Your use of or participation in certain Services may also be subject to additional policies, rules and/or conditions (“Additional Terms”), which are incorporated herein by reference, and you understand and agree that by using or participating in any such Services, you agree to also comply with these Additional Terms.</b></p>
        <p><b>Please read these Terms carefully.&nbsp;</b>They cover important information about Services provided to you and any charges, taxes, and fees we bill you.&nbsp;<b>These Terms include information about&nbsp;</b><b>future changes to these Terms</b><b>,&nbsp;</b><b>automatic renewals</b><b>,&nbsp;</b><b>limitations of liability</b><b>,&nbsp;</b><b>a class action waiver and resolution of disputes by arbitration instead of in court</b><b>. PLEASE NOTE THAT YOUR USE OF AND ACCESS TO OUR SERVICES ARE SUBJECT TO THE FOLLOWING TERMS; IF YOU DO NOT AGREE TO ALL OF THE FOLLOWING, YOU MAY NOT USE OR ACCESS THE SERVICES IN ANY MANNER.</b></p>
        <p><b>ARBITRATION NOTICE AND CLASS ACTION WAIVER:</b>&nbsp;EXCEPT FOR CERTAIN TYPES OF DISPUTES DESCRIBED IN THE&nbsp;ARBITRATION AGREEMENT SECTION BELOW, YOU AGREE THAT DISPUTES BETWEEN YOU AND US WILL BE RESOLVED BY BINDING, INDIVIDUAL ARBITRATION AND YOU WAIVE YOUR RIGHT TO PARTICIPATE IN A CLASS ACTION LAWSUIT OR CLASS-WIDE ARBITRATION.</p>
        <h3><b>Will these Terms ever change?</b></h3>
        <p>We are constantly trying to improve our Services, so these Terms may need to change along with our Services. We reserve the right to change the Terms at any time, but if we do, we will place a notice on our site located at&nbsp;<a href="https://www.pathstream.com/?utm_source=app_pathstream">www.pathstream.com</a>, send you an email, and/or notify you by some other means.</p>
        <p>If you don’t agree with the new Terms, you are free to reject them; unfortunately, that means you will no longer be able to use the Services. If you use the Services in any way after a change to the Terms is effective, that means you agree to all of the changes.</p>
        <p>Except for changes by us as described here, no other amendment or modification of these Terms will be effective unless in writing and signed by both you and us.</p>
        <h3><b>What about my privacy?</b></h3>
        <p>Pathstream takes the privacy of its users very seriously. Here is the current Pathstream&nbsp;<a href="https://www.pathstream.com/privacy-policy/?utm_source=app_pathstream">Privacy Policy</a>.</p>
        <h3><b>What are the basics of using Pathstream?</b></h3>
        <p>You may be required to sign up for an account, select a password and user name (“Pathstream User ID”), and provide us with certain information or data, such as your contact information. You promise to provide us with accurate, complete, and updated registration information about yourself. You may not select as your Pathstream User ID a name that you do not have the right to use, or another person’s name with the intent to impersonate that person. You may not transfer your account to anyone else without our prior written permission.</p>
        <p>Additionally, you may be able to access certain parts or features of the Services by using your account credentials from other services (each, a “Third Party Account”), such as those offered by Canvas Learning Management System. By using the Services through a Third Party Account, you permit us to access certain information from such account for use by the Services. You are ultimately in control of how much information is accessible to us and may exercise such control by adjusting your privacy settings on your Third Party Account.</p>
        <p>You must be at least sixteen (16) years of age to use our Services. You represent and warrant that you are an individual of legal age to form a binding contract (or if not, you’ve received your parent’s or guardian’s permission to use the Services, you may only use our Services under the supervision of such parent or legal guardian, and have gotten your parent or guardian to agree to these Terms on your behalf).</p>
        <p>You will only use the Services for your own internal, personal, non-commercial use, and not on behalf of or for the benefit of any third party and only in a manner that complies with all laws that apply to you. If your use of the Services is prohibited by applicable laws, then you aren’t authorized to use the Services. We can’t and won’t be responsible for your using the Services in a way that breaks the law.</p>
        <p>You will not share your Pathstream User ID, account or password with anyone, and you must protect the security of your Pathstream User ID, account, password and any other access tools or credentials. You’re responsible for any activity associated with your Pathstream User ID and account.</p>
        <h4><i>Children’s Online Privacy Protection Act</i></h4>
        <p>The Children’s Online Privacy Protection Act (“COPPA”) requires that online service providers obtain parental consent before they knowingly collect personally identifiable information online from children who are under thirteen (13). We do not knowingly collect or solicit personally identifiable information from children under thirteen (13); if you are a child under thirteen (13), please do not attempt to register for or otherwise use the Services or send us any personal information. If we learn we have collected personal information from a child under thirteen (13), we will delete that information as quickly as possible. If you believe that a child under thirteen (13) may have provided us personal information, please contact us at&nbsp;<a href="mailto:privacy@pathstream.com">privacy@pathstream.com</a>.</p>
        <p>If you are a school, district, or teacher affiliated with, and using the Services on behalf of, a school or school district, you represent and warrant that you are solely responsible for complying with COPPA, meaning that you must obtain advance written consent from all parents or guardians whose children will be accessing the Services. You are responsible for understanding how our Services may collect and use information of users of the Services, including children. When obtaining consent from parents or guardians, you must provide them with our&nbsp;<a href="https://www.pathstream.com/privacy-policy/?utm_source=app_pathstream">Privacy Policy</a>. If you are a teacher, you represent and warrant that you have permission and authorization from your school and/or district to use the Services as part of your curriculum. If you are entering into this agreement on behalf of a school or school district, you will only grant access codes to teachers and staff members who are current employees of your school or district. Upon termination of a teacher or other staff member’s employment with you, you will require such individual to return and cease using all access codes he or she has in his or her possession. If at any time you learn a user of the Services claims to be affiliated with your school or district who is not, in fact, affiliated with your school or district, you will notify us immediately.&nbsp;For more information on COPPA, please see&nbsp;<a href="https://www.ftc.gov/tips-advice/business-center/guidance/complying-coppa-frequently-asked-questions?utm_source=app_pathstream">https://www.ftc.gov/tips-advice/business-center/guidance/complying-coppa-frequently-asked-questions</a>.</p>
        <h4><i>Family Education Rights and Privacy Act</i></h4>
        <p>If you are a teacher, or entering into this agreement on behalf of a school or school district, you are solely responsible for ensuring that you and your school are compliant with all applicable laws and regulations related to your disclosure of student information (including directory information) to Pathstream in connection with the Services, including but not limited to the Family Educational Rights and Privacy Act (“FERPA”).&nbsp; To the extent that you disclose any student directory information to Pathstream, you represent and warrant that, that your school has (a) provided notice to the student of your use of third-party services, including Pathstream, and the disclosure of student records and directory information in connection with such use; and (b) obtained the appropriate consent(s) from such students.&nbsp; If a student is under the age of eighteen (18), the notice and consent requirements of (a) and (b) shall apply to the students’ parent or legal guardian. You must keep all consents on file and provide them to us if we request them. For this section, we use the term “directory information” as it is defined by FERPA (20 USC §1232g).</p>
        <h3><b>What about messaging?</b></h3>
        <p>As part of the Services, you may receive communications through the Services, including messages that Pathstream sends you (for example, via email or SMS). When signing up for the Services, you will receive a welcome message and instructions on how to stop receiving messages.&nbsp;<b>By signing up for the Services and providing us with your wireless number, you confirm that you want Pathstream to send you information that we think may be of interest to you, which may include Pathstream using automated dialing technology to text you at the wireless number you provided, and you agree to receive communications from Pathstream, and you represent and warrant that each person you register for the Services or for whom you provide a wireless phone number has consented to receive communications from Pathstream.</b>&nbsp;You agree to indemnify and hold Pathstream harmless from and against any and all claims, liabilities, damages (actual and consequential), losses and expenses (including attorneys’ fees) arising from or in any way related to your breach of the foregoing.</p>
        <h3><b>Are there restrictions in how I can use the Services?</b></h3>
        <p>You represent, warrant, and agree that you will not contribute any Content or User Submission (each of those terms is defined below) or otherwise use the Services or interact with the Services in a manner that:</p>
        <ul>
          <li>infringes or violates the intellectual property rights or any other rights of anyone else (including Pathstream);</li>
          <li>violates any law or regulation, including, without limitation, any applicable export control laws, privacy laws or any other purpose not reasonably intended by Pathstream;</li>
          <li>is dangerous, harmful, fraudulent, deceptive, threatening, harassing, defamatory, obscene, or otherwise objectionable;</li>
          <li>jeopardizes the security of your Pathstream User ID, account or anyone else’s (such as allowing someone else to log in to the Services as you);</li>
          <li>attempts, in any manner, to obtain the password, account, or other security information from any other user;</li>
          <li>violates the security of any computer network, or cracks any passwords or security encryption codes;</li>
          <li>impersonate or post on behalf or any person or entity or otherwise misrepresent your affiliation with a person or entity;</li>
          <li>runs Maillist, Listserv, any form of auto-responder or “spam” on the Services, or any processes that run or are activated while you are not logged into the Services, or that otherwise interfere with the proper working of the Services (including by placing an unreasonable load on the Services’ infrastructure);</li>
          <li>“crawls,” “scrapes,” or “spiders” any page, data, or portion of or relating to the Services or Content (through use of manual or automated means);</li>
          <li>copies or stores any significant portion of the Content;</li>
          <li>decompiles, reverse engineers, or otherwise attempts to obtain the source code or underlying ideas or information of or relating to the Services; or</li>
          <li>in our sole judgment, is objectionable, restricts or inhibits any other person from using or enjoying our Services, or may expose Pathstream or others to any harm or liability of any type.</li>
        </ul>
        <p>A violation of any of the foregoing is grounds for termination of your right to use or access the Services.</p>
        <h3><b>What are my rights in the Services?</b></h3>
        <p>The materials displayed or performed or available on or through the Services, including, but not limited to, text, graphics, data, articles, photos, images, illustrations, User Submissions (as defined below) and so forth (all of the foregoing, the “Content”) are protected by copyright and/or other intellectual property laws. You promise to abide by all copyright notices, trademark rules, information, and restrictions contained in any Content you access through the Services, and you won’t use, copy, reproduce, modify, translate, publish, broadcast, transmit, distribute, perform, upload, display, license, sell, commercialize or otherwise exploit for any purpose any Content not owned by you, (i) without the prior consent of the owner of that Content or (ii) in a way that violates someone else’s (including Pathstream’s) rights.</p>
        <p>Subject to these Terms, we grant each user of the Services a worldwide, non-exclusive, non-sublicensable and non-transferable license to use (i.e., to download and display locally) Content solely for purposes of using the Services. Use, reproduction, modification, distribution or storage of any Content for any purpose other than using the Services is expressly prohibited without prior written permission from us. You understand that Pathstream owns the Services. You won’t modify, publish, transmit, participate in the transfer or sale of, reproduce (except as expressly provided in this Section), create derivative works based on, or otherwise exploit any of the Services. The Services may allow you to copy or download certain Content, but please remember that even where these functionalities exist, all the restrictions in this section still apply.</p>
        <h3><b>What about anything I contribute to the Services – do I have to grant any licenses to Pathstream or to other users?</b></h3>
        <h4><i>User Submissions</i></h4>
        <p>Our Services may allow you to create, post, store and share content, including messages, text, photos, assignments, projects, videos, software and other materials. Anything you post, upload, share, store, or otherwise provide through the Services, including any feedback, suggestions, improvements, enhancements, and/or feature requests relating to the Services, is your “User Submission” (including any name, username or likeness provided in connection with your User Submission).&nbsp; Some User Submissions may be viewable by other users depending on your assignment, the course, and the Services that you are using. You are solely responsible for all User Submissions you contribute to the Services. You represent that all User Submissions submitted by you are accurate, complete, up-to-date, and in compliance with all applicable laws, rules and regulations.</p>
        <p>You agree that you will not post, upload, share, store, or otherwise provide through the Services any User Submissions that:</p>
        <ul>
          <li>disclose, share, transmit, or access outside of the Services, any test questions, answers, instructional content, course content, or material that would allow you or others to gain an undue advantage in competitive or graded activities;</li>
          <li>identifies Pathstream, other Pathstream users, or content developed, owned, or licensed by Pathstream, subject to requirements under applicable law;</li>
          <li>violate these Terms;</li>
          <li>infringe any third party’s copyrights or other rights (e.g., trademark, privacy rights, etc.);</li>
          <li>contain sexually explicit content or pornography;</li>
          <li>contain hateful, defamatory, or discriminatory content or incite hatred against any individual or group;</li>
          <li>exploit minors;</li>
          <li>depict unlawful acts or extreme violence;</li>
          <li>depict animal cruelty or extreme violence towards animals;</li>
          <li>promote fraudulent schemes, multi-level marketing (MLM) schemes, get rich quick schemes, online gaming and gambling, cash gifting, work from home businesses, or any other dubious money-making ventures; or</li>
          <li>violate any law.</li>
        </ul>
        <h4><i>Licenses</i></h4>
        <p>In order to display your User Submissions on the Services, and to allow other users to enjoy them (where applicable), you grant us certain rights in those User Submissions (see below for more information). Please note that all of the following licenses are subject to our&nbsp;<a href="https://www.pathstream.com/privacy-policy/?utm_source=app_pathstream">Privacy Policy</a>&nbsp;to the extent they relate to User Submissions that are also your personally-identifiable information.</p>
        <p>By submitting User Submissions through the Services, you hereby do and shall grant Pathstream a worldwide, non-exclusive, perpetual, royalty-free, fully paid, sublicensable and transferable license to use, edit, modify, truncate, aggregate, reproduce, distribute, publish, prepare derivative works of, display, perform, and otherwise fully exploit the User Submissions in connection with this site, the Services and our (and our successors’ and assigns’) businesses, including without limitation for promoting and redistributing part or all of this site or the Services (and derivative works thereof) in any media formats and through any media channels (including, without limitation, third party websites and feeds), and including after your termination of your account or the Services. You also hereby do and shall grant each user of this site and/or the Services a non-exclusive, perpetual license to access your User Submissions through this site and/or the Services, and to use, edit, modify, reproduce, distribute, prepare derivative works of, display and perform such User Submissions, including after your termination of your account or the Services. For clarity, the foregoing license grants to us and our users do not affect your other ownership or license rights in your User Submissions, including the right to grant additional licenses to your User Submissions, unless otherwise agreed in writing. You represent and warrant that you have all rights to grant such licenses to us without infringement or violation of any third party rights, including without limitation, any privacy rights, publicity rights, copyrights, trademarks, contract rights, or any other intellectual property or proprietary rights.</p>
        <p>Certain features of the Services allow you to share information with others, including through your social networks or other Third Party Accounts. When Content is authorized for sharing, we will clearly identify the Content you are authorized to redistribute and the ways you may redistribute it, usually by providing a “share” button on or near the Content. If you share information from the Services with others through your Third Party Accounts you authorize Pathstream to share that information with the applicable Third Party Account provider. Please review the policies of any Third Party Account providers you share information with or through for additional information about how they may use your information. If you redistribute Content, you must be able to edit or delete any Content you redistribute, and you must edit or delete it promptly upon our request.</p>
        <p>Finally, you understand and agree that Pathstream, in performing the required technical steps to provide the Services to our users (including you), may need to make changes to your User Submissions to conform and adapt those User Submissions to the technical requirements of connection networks, devices, services, or media, and the foregoing licenses include the rights to do so.</p>
        <h3><b>What if I see something on the Services that infringes my copyright?</b></h3>
        <p>In accordance with the DMCA, we’ve adopted the following policy toward copyright infringement.&nbsp; We reserve the right to (1) block access to or remove material that we believe in good faith to be copyrighted material that has been illegally copied and distributed by any of our advertisers, affiliates, content providers, members or users and (2) remove and discontinue service to repeat offenders.</p>
        <p><i>(1)&nbsp;</i><u><i>Procedure for Reporting Copyright Infringements</i></u>.&nbsp; If you believe that material or content residing on or accessible through the Services infringes your copyright (or the copyright of someone whom you are authorized to act on behalf of), please send a notice of copyright infringement containing the following information to Pathstream’s Designated Agent to Receive Notification of Claimed Infringement (our “Designated Agent,” whose contact details are listed below):</p>
        <ol>
          <li>A physical or electronic signature of a person authorized to act on behalf of the owner of the copyright that has been allegedly infringed;</li>
          <li>Identification of works or materials being infringed;</li>
          <li>Identification of the material that is claimed to be infringing including information regarding the location of the infringing materials that the copyright owner seeks to have removed, with sufficient detail so that Company is capable of finding and verifying its existence;</li>
          <li>Contact information about the notifier including address, telephone number and, if available, email address;</li>
          <li>A statement that the notifier has a good faith belief that the material identified in (1)(c) is not authorized by the copyright owner, its agent, or the law; and</li>
          <li>A statement made under penalty of perjury that the information provided is accurate and the notifying party is authorized to make the complaint on behalf of the copyright owner.</li>
        </ol>
        <p><i>(2)&nbsp;</i><u><i>Once Proper Bona Fide Infringement Notification is Received by the Designated Agent</i></u>.&nbsp; Upon receipt of a proper notice of copyright infringement, we reserve the right to:</p>
        <ol>
          <li>remove or disable access to the infringing material;</li>
          <li>notify the content provider who is accused of infringement that we have removed or disabled access to the applicable material; and</li>
          <li>terminate such content provider’s access to the Services if he or she is a repeat offender.</li>
        </ol>
        <p><i>(3)&nbsp;</i><u><i>Procedure to Supply a Counter-Notice to the Designated Agent</i></u>.&nbsp; If the content provider believes that the material that was removed (or to which access was disabled) is not infringing, or the content provider believes that it has the right to post and use such material from the copyright owner, the copyright owner’s agent, or, pursuant to the law, the content provider may send us a counter-notice containing the following information to the Designated Agent:</p>
        <ol>
          <li>A physical or electronic signature of the content provider;</li>
          <li>Identification of the material that has been removed or to which access has been disabled and the location at which the material appeared before it was removed or disabled;</li>
          <li>A statement that the content provider has a good faith belief that the material was removed or disabled as a result of mistake or misidentification of the material; and</li>
          <li>Content provider’s name, address, telephone number, and, if available, email address, and a statement that such person or entity consents to the jurisdiction of the Federal Court for the judicial district in which the content provider’s address is located, or, if the content provider’s address is located outside the United States, for any judicial district in which Company is located, and that such person or entity will accept service of process from the person who provided notification of the alleged infringement.</li>
        </ol>
        <p>If a counter-notice is received by the Designated Agent, Company may, in its discretion, send a copy of the counter-notice to the original complaining party informing that person that Company may replace the removed material or cease disabling it in 10 business days. Unless the copyright owner files an action seeking a court order against the content provider accused of committing infringement, the removed material may be replaced or access to it restored in 10 to 14 business days or more after receipt of the counter-notice, at Company’s discretion.&nbsp;&nbsp; Please contact Pathstream’s Designated Agent at the following address:</p>
        <ul>
          <li><b>Designated Agent:</b> DMCA Agent, Amy Ahearn</li>
          <li><b>Address:&nbsp;</b>580 Market Street, Suite 400, San Francisco, CA 94104</li>
          <li><b>Phone:</b>&nbsp;(650) 437-5825</li>
          <li><b>Email:&nbsp;</b><a href="mailto:veronica@pathstream.com">amy@pathstream.com</a></li>
        </ul>
        <h3><b>Who is responsible for what I see and do on the Services?</b></h3>
        <p>Any information or Content publicly posted or privately transmitted through the Services is the sole responsibility of the person from whom such Content originated, and you access all such information and Content at your own risk, and we aren’t liable for any errors or omissions in that information or Content or for any damages or loss you might suffer in connection with it. We cannot control and have no duty to take any action regarding how you may interpret and use the Content or what actions you may take as a result of having been exposed to the Content, and you hereby release us from all liability for you having acquired or not acquired Content through the Services. We can’t guarantee the identity of any users with whom you interact in using the Services and are not responsible for which users gain access to the Services.</p>
        <p>You are responsible for all Content you contribute, in any manner, to the Services, and you represent and warrant you have all rights necessary to do so, in the manner in which you contribute it.</p>
        <p>The Services may contain links or connections to third-party websites or services that are not owned or controlled by Pathstream. When you access third-party websites or use third-party services, you accept that there are risks in doing so, and that Pathstream is not responsible for such risks. Certain features of our Services may contain software provided by a third party pursuant to applicable third-party licenses. By using the Services, you agree to comply with the terms for such third-party software, including but not limited to:&nbsp; Asana, Google Drive, Google Colab, Tableau, Unity Technologies ApS, H5P, MySQL Workbench.</p>
        <p>Pathstream has no control over, and assumes no responsibility for, the content, accuracy, privacy policies, or practices of or opinions expressed in any third-party websites or by any third party that you interact with through the Services. In addition, Pathstream will not and cannot monitor, verify, censor or edit the content of any third-party site or service. We encourage you to be aware when you leave the Services and to read the terms and conditions and privacy policy of each third-party website or service that you visit or utilize. By using the Services, you release and hold us harmless from any and all liability arising from your use of any third-party website or service.</p>
        <p>Your interactions with organizations and/or individuals found on or through the Services, including payment and delivery of goods or services, and any other terms, conditions, warranties or representations associated with such dealings, are solely between you and such organizations and/or individuals. You should make whatever investigation you feel necessary or appropriate before proceeding with any online or offline transaction with any of these third parties. You agree that Pathstream shall not be responsible or liable for any loss or damage of any sort incurred as the result of any such dealings.</p>
        <p>If there is a dispute between participants on this site or Services, or between users and any third party, you agree that Pathstream is under no obligation to become involved. In the event that you have a dispute with one or more other users, you release Pathstream, its directors, officers, employees, agents, and successors from claims, demands, and damages of every kind or nature, known or unknown, suspected or unsuspected, disclosed or undisclosed, arising out of or in any way related to such disputes and/or our Services. You shall and hereby do waive California Civil Code Section 1542 or any similar law of any jurisdiction, which says in substance: “A general release does not extend to claims that the creditor or releasing party does not know or suspect to exist in his or her favor at the time of executing the release and that, if known by him or her, would have materially affected his or her settlement with the debtor or released party.”</p>
        <h3><b>Will Pathstream ever change the Services?</b></h3>
        <p>We’re always trying to improve our Services, so they may change over time. We may suspend or discontinue any part of the Services, or we may introduce new features or impose limits on certain features or restrict access to parts or all of the Services. We reserve the right to remove any Content from the Services at any time, for any reason (including, but not limited to, if someone alleges you contributed that Content in violation of these Terms), in our sole discretion, and without notice.</p>
        <h3><b>Do the Services cost anything?</b></h3>
        <p>The Services may be free or we may charge a fee for using the Services. If you are using a free version of the Services, we will notify you before any Services you are then using begin carrying a fee, and if you wish to continue using such Services, you must pay all applicable fees for such Services. Note that if you elect to receive text messages through the Services, data and message rates may apply. Any and all such charges, fees or costs are your sole responsibility. You should consult with your wireless carrier to determine what rates, charges, fees or costs may apply to your use of the Services.</p>
        <p>(a)&nbsp;<u>Paid Services</u>. Certain of our Services may be subject to payments now or in the future (the “Paid Services”). To the extent applicable, we will provide a description of the Paid Service available during the sign up process. Please note that any payment terms presented to you in the process of using or signing up for a Paid Service are deemed part of these Terms.</p>
        <p>(b)&nbsp;<u>Billing</u>. We use a third-party payment processor (the “Payment Processor”) to bill you through a payment account linked to your account on the Services (your “Billing Account”) for use of the Paid Services. The processing of payments will be subject to the terms, conditions and privacy policies of the Payment Processor in addition to these Terms, including:</p>
        <ul>
          <li><a href="https://stripe.com/checkout/legal?utm_source=app_pathstream">Stripe Legal Terms</a></li>
          <li><a href="https://recurly.com/legal/">Recurly Legal Terms</a></li>
        </ul>
        <p>We are not responsible for any error by, or other acts or omissions of, the Payment Processor. By choosing to use Paid Services, you agree to pay us in accordance with an applicable payment plan (which may or may not include, without limitation, the option to pay for the Paid Services as automated account payments, one-time transactions as payments become due, or as otherwise specified in a pricing page during the signup process), through the Payment Processor, all charges at the prices then in effect for any use of such Paid Services in accordance with the applicable payment terms, and you authorize us, through the Payment Processor, to charge your chosen payment provider (your “Payment Method”). You agree to make payment using that selected Payment Method. We reserve the right to correct any errors or mistakes that the Payment Processor makes even if it has already requested or received payment. (c)&nbsp;<u>Payment Method</u>. The terms of your payment will be based on your Payment Method and may be determined by agreements between you and the financial institution, credit card issuer or other provider of your chosen Payment Method. If we, through the Payment Processor, do not receive payment from you, you agree to pay all amounts due on your Billing Account upon demand.</p>
        <p>(d)&nbsp;<u>Recurring Billing</u>. Some of the Paid Services may consist of an initial period, for which there is a one-time charge, followed by recurring period charges as agreed to by you. By choosing a recurring payment plan, you acknowledge that such Services have an initial and recurring payment feature and you accept responsibility for all recurring charges prior to cancellation. WE MAY SUBMIT PERIODIC CHARGES (E.G., MONTHLY) WITHOUT FURTHER AUTHORIZATION FROM YOU, UNTIL YOU PROVIDE PRIOR NOTICE (RECEIPT OF WHICH IS CONFIRMED BY US) THAT YOU HAVE TERMINATED THIS AUTHORIZATION OR WISH TO CHANGE YOUR PAYMENT METHOD. SUCH NOTICE WILL NOT AFFECT CHARGES SUBMITTED BEFORE WE REASONABLY COULD ACT. TO TERMINATE YOUR AUTHORIZATION OR CHANGE YOUR PAYMENT METHOD, GO TO ACCOUNT SETTINGS.&nbsp; PAID SERVICES CANNOT BE TERMINATED BEFORE THE END OF THE PERIOD FOR WHICH YOU HAVE ALREADY PAID, AND EXCEPT AS EXPRESSLY PROVIDED IN THESE TERMS OR ON THE APPLICABLE PRICING PAGE DURING THE SIGN-UP PROCESS, PATHSTREAM WILL NOT REFUND ANY FEES THAT YOU HAVE ALREADY PAID.</p>
        <p>(e)&nbsp;<u>Current Information Required</u>. YOU MUST PROVIDE CURRENT, COMPLETE AND ACCURATE INFORMATION FOR YOUR BILLING ACCOUNT. YOU MUST PROMPTLY UPDATE ALL INFORMATION TO KEEP YOUR BILLING ACCOUNT CURRENT, COMPLETE AND ACCURATE (SUCH AS A CHANGE IN BILLING ADDRESS, CREDIT CARD NUMBER, OR CREDIT CARD EXPIRATION DATE), AND YOU MUST PROMPTLY NOTIFY US OR OUR PAYMENT PROCESSOR IF YOUR PAYMENT METHOD IS CANCELED (E.G., FOR LOSS OR THEFT) OR IF YOU BECOME AWARE OF A POTENTIAL BREACH OF SECURITY, SUCH AS THE UNAUTHORIZED DISCLOSURE OR USE OF YOUR USER NAME OR PASSWORD. CHANGES TO SUCH INFORMATION CAN BE MADE AT ACCOUNT SETTINGS. IF YOU FAIL TO PROVIDE ANY OF THE FOREGOING INFORMATION, YOU AGREE THAT WE MAY CONTINUE CHARGING YOU FOR ANY USE OF PAID SERVICES UNDER YOUR BILLING ACCOUNT UNLESS YOU HAVE TERMINATED YOUR PAID SERVICES AS SET FORTH ABOVE.</p>
        <p>(f)&nbsp;<u>Change in Amount Authorized</u>. If the amount to be charged to your Billing Account varies from the amount you preauthorized (other than due to the imposition or change in the amount of state sales taxes), you have the right to receive, and we shall provide, notice of the amount to be charged and the date of the charge before the scheduled date of the transaction. Any agreement you have with your payment provider will govern your use of your Payment Method. You agree that we may accumulate charges incurred and submit them as one or more aggregate charges during or at the end of each billing cycle.</p>
        <p>(g)&nbsp;<u>Reaffirmation of Authorization</u>. Your non-termination or continued use of a Paid Service reaffirms that we are authorized to charge your Payment Method for that Paid Service. We may submit those charges for payment and you will be responsible for such charges. This does not waive our right to seek payment directly from you. Your charges may be payable in advance, in arrears, per usage, or as otherwise described when you initially selected to use the Paid Service.</p>
        <p>(h)&nbsp;<u>Free Trials and Other Promotions</u>. Any free trial or other discount or promotion that provides access to a Paid Service must be used within the specified time of the trial. You must stop using a Paid Service before the end of the trial period in order to avoid being charged for that Paid Service. If you cancel prior to the end of the trial period and are inadvertently charged for a Paid Service, please contact us at&nbsp;<a href="mailto:help@pathstream.com">help@pathstream.com</a>.</p>
        <h3><b>What if I want to stop using the Services?</b></h3>
        <p>You’re free to do that at any time by contacting us at&nbsp;<a href="mailto:help@pathstream.com">help@pathstream.com</a>; please refer to our&nbsp;<a href="https://www.pathstream.com/privacy-policy/?utm_source=app_pathstream">Privacy Policy</a>, as well as the licenses above, to understand how we treat information you provide to us after you have stopped using our Services.</p>
        <p>Pathstream is also free to terminate (or suspend access to) your use of the Services or your account for any reason in our discretion, including your breach of these Terms. Pathstream has the sole right to decide whether you are in violation of any of the restrictions set forth in these Terms.</p>
        <p>Account termination may result in destruction of any Content associated with your account, so keep that in mind before you decide to terminate your account. We will try to provide advance notice to you prior to our terminating your account so that you are able to retrieve any important User Submissions you may have stored in your account (to the extent allowed by law and these Terms), but we may not do so if we determine it would be impractical, illegal, not in the interest of someone’s safety or security, or otherwise harmful to the rights or property of Pathstream.</p>
        <p>If you have deleted your account by mistake, contact us immediately at&nbsp;<a href="mailto:help@pathstream.com"><u>help@pathstream.com</u></a>. We will try to help, but unfortunately, we can’t promise that we can recover or restore anything.</p>
        <p>Provisions that, by their nature, should survive termination of these Terms shall survive termination. By way of example, all of the following will survive termination: any obligation you have to pay us or indemnify us, any limitations on our liability, any terms regarding ownership or intellectual property rights, and terms regarding disputes between us, including without limitation the arbitration agreement.</p>
        <h3><b>What else do I need to know?</b></h3>
        <h4><i>Warranty Disclaimer</i></h4>
        <p>Pathstream and its licensors, suppliers, partners, parent, subsidiaries or affiliated entities, and each of their respective officers, directors, members, employees, consultants, contract employees, representatives and agents, and each of their respective successors and assigns (Pathstream and all such parties together, the “Pathstream Parties”) make no representations or warranties concerning the Services, including without limitation regarding any Content contained in or accessed through the Services, and the Pathstream Parties will not be responsible or liable for the accuracy, copyright compliance, legality, or decency of material contained in or accessed through the Services or any claims, actions, suits procedures, costs, expenses, damages or liabilities arising out of use of, or in any way related to your participation in, the Services. The Pathstream Parties make no representations or warranties regarding suggestions or recommendations of services or products offered or purchased through or in connection with the Services. THE SERVICES AND CONTENT ARE PROVIDED BY PATHSTREAM (AND ITS LICENSORS AND SUPPLIERS) ON AN “AS-IS” BASIS, WITHOUT WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING, WITHOUT LIMITATION, IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT, OR THAT USE OF THE SERVICES WILL BE UNINTERRUPTED OR ERROR-FREE. SOME STATES DO NOT ALLOW LIMITATIONS ON HOW LONG AN IMPLIED WARRANTY LASTS, SO THE ABOVE LIMITATIONS MAY NOT APPLY TO YOU.</p>
        <h4><i>Limitation of Liability</i></h4>
        <p>TO THE FULLEST EXTENT ALLOWED BY APPLICABLE LAW, UNDER NO CIRCUMSTANCES AND UNDER NO LEGAL THEORY (INCLUDING, WITHOUT LIMITATION, TORT, CONTRACT, STRICT LIABILITY, OR OTHERWISE) SHALL ANY OF THE PATHSTREAM PARTIES BE LIABLE TO YOU OR TO ANY OTHER PERSON FOR (A) ANY INDIRECT, SPECIAL, INCIDENTAL, PUNITIVE OR CONSEQUENTIAL DAMAGES OF ANY KIND, INCLUDING DAMAGES FOR LOST PROFITS, BUSINESS INTERRUPTION, LOSS OF DATA, LOSS OF GOODWILL, WORK STOPPAGE, ACCURACY OF RESULTS, OR COMPUTER FAILURE OR MALFUNCTION, (B) ANY SUBSTITUTE GOODS, SERVICES OR TECHNOLOGY, (C) ANY AMOUNT, IN THE AGGREGATE, IN EXCESS OF THE GREATER OF (I) ONE-HUNDRED ($100) DOLLARS OR (II) THE AMOUNTS PAID AND/OR PAYABLE BY YOU TO PATHSTREAM IN CONNECTION WITH THE SERVICES IN THE TWELVE (12) MONTH PERIOD PRECEDING THIS APPLICABLE CLAIM OR (D) ANY MATTER BEYOND OUR REASONABLE CONTROL. SOME STATES DO NOT ALLOW THE EXCLUSION OR LIMITATION OF INCIDENTAL OR CONSEQUENTIAL OR CERTAIN OTHER DAMAGES, SO THE ABOVE LIMITATION AND EXCLUSIONS MAY NOT APPLY TO YOU.</p>
        <h4><i>Indemnity</i></h4>
        <p>To the fullest extent allowed by applicable law, you agree to indemnify and hold the Pathstream Parties harmless from and against any and all claims, liabilities, damages (actual and consequential), losses and expenses (including attorneys’ fees) arising from or in any way related to any claims relating to (a) your use of the Services (including any actions taken by a third party using your account), (b) your violation of these Terms, (c) your User Submissions, and (d) your conduct in connection with use of the Services. In the event of such a claim, suit, or action (“Claim”), we will attempt to provide notice of the Claim to the contact information we have for your account (provided that failure to deliver such notice shall not eliminate or reduce your indemnification obligations hereunder).</p>
        <h4><i>Assignment</i></h4>
        <p>You may not assign, delegate or transfer these Terms or your rights or obligations hereunder, or your Services account, in any way (by operation of law or otherwise) without Pathstream’s prior written consent. We may transfer, assign, or delegate these Terms and our rights and obligations without consent.</p>
        <h4><i>Choice of Law</i></h4>
        <p>These Terms are governed by and will be construed under the Federal Arbitration Act, applicable federal law, and the laws of the State of California, without regard to the conflicts of laws provisions thereof.</p>
        <h4><i>California Consumers</i></h4>
        <p>Under California Civil Code Section 1789.3, California consumers are entitled to the following notice with respect to filing complaints with California’s consumer protection bureau:&nbsp; The Complaint Assistance Unit of the Division of Consumer Services of the California Department of Consumer Affairs may be contacted in writing at 1625 N. Market Blvd., Suite N-112, Sacramento, California 95834, or by telephone at 1 (800) 952-5210.</p>
        <h4><i>Arbitration Agreement</i></h4>
        <p>Please read the following ARBITRATION AGREEMENT carefully because it requires you to arbitrate certain disputes and claims with Pathstream and limits the manner in which you can seek relief from Pathstream. Both you and Pathstream acknowledge and agree that for the purposes of any dispute arising out of or relating to the subject matter of these Terms, Pathstream’s officers, directors, employees and independent contractors (“Personnel”) are third-party beneficiaries of these Terms, and that upon your acceptance of these Terms, Personnel will have the right (and will be deemed to have accepted the right) to enforce these Terms against you as the third-party beneficiary hereof.</p>
        <p>(a)&nbsp;<i>Arbitration Rules; Applicability of Arbitration Agreement</i>. The parties shall use their best efforts to settle any dispute, claim, question, or disagreement arising out of or relating to the subject matter of these Terms directly through good-faith negotiations, which shall be a precondition to either party initiating arbitration. If such negotiations do not resolve the dispute, it shall be finally settled by binding arbitration in San Francisco County in the State of California. The arbitration will proceed in the English language, in accordance with the JAMS Streamlined Arbitration Rules and Procedures (the “Rules”) then in effect, by one commercial arbitrator with substantial experience in resolving intellectual property and commercial contract disputes. The arbitrator shall be selected from the appropriate list of JAMS arbitrators in accordance with such Rules. Judgment upon the award rendered by such arbitrator may be entered in any court of competent jurisdiction.</p>
        <p>(b)&nbsp;<i>Costs of Arbitration</i>. The Rules will govern payment of all arbitration fees. Pathstream will pay all arbitration fees for claims less than seventy-five thousand ($75,000) dollars. Pathstream will not seek its attorneys’ fees and costs in arbitration unless the arbitrator determines that your claim is frivolous.</p>
        <p>(c)&nbsp;<i>Small Claims Court; Infringement</i>. Either you or Pathstream may assert claims, if they qualify, in small claims court in San Francisco County or any United States county where you live or work. Furthermore, notwithstanding the foregoing obligation to arbitrate disputes, each party shall have the right to pursue injunctive or other equitable relief at any time, from any court of competent jurisdiction, to prevent the actual or threatened infringement, misappropriation or violation of a party’s copyrights, trademarks, trade secrets, patents or other intellectual property rights.</p>
        <p>(d)&nbsp;<i>Waiver of Jury Trial</i>. YOU AND PATHSTREAM WAIVE ANY CONSTITUTIONAL AND STATUTORY RIGHTS TO GO TO COURT AND HAVE A TRIAL IN FRONT OF A JUDGE OR JURY. You and Pathstream are instead choosing to have claims and disputes resolved by arbitration. Arbitration procedures are typically more limited, more efficient, and less costly than rules applicable in court and are subject to very limited review by a court. In any litigation between you and Pathstream over whether to vacate or enforce an arbitration award, YOU AND PATHSTREAM WAIVE ALL RIGHTS TO A JURY TRIAL, and elect instead to have the dispute be resolved by a judge.</p>
        <p>(e)&nbsp;<i>Waiver of Class or Consolidated Actions</i>. ALL CLAIMS AND DISPUTES WITHIN THE SCOPE OF THIS ARBITRATION AGREEMENT MUST BE ARBITRATED OR LITIGATED ON AN INDIVIDUAL BASIS AND NOT ON A CLASS BASIS. CLAIMS OF MORE THAN ONE CUSTOMER OR USER CANNOT BE ARBITRATED OR LITIGATED JOINTLY OR CONSOLIDATED WITH THOSE OF ANY OTHER CUSTOMER OR USER. If however, this waiver of class or consolidated actions is deemed invalid or unenforceable, neither you nor Pathstream is entitled to arbitration; instead all claims and disputes will be resolved in a court as set forth in (g) below.<b>&nbsp;</b></p>
        <p>(f)&nbsp;<i>Opt-out</i>. You have the right to opt out of the provisions of this Section by sending written notice of your decision to opt out to the following address: 580 Market Street, Suite 400, San Francisco, CA 94104 postmarked within thirty (30) days of first accepting these Terms. You must include (i) your name and residence address, (ii) the email address and/or telephone number associated with your account, and (iii) a clear statement that you want to opt out of these Terms’ arbitration agreement.</p>
        <p>(g)&nbsp;<i>Exclusive Venue</i>. If you send the opt-out notice in (f), and/or in any circumstances where the foregoing arbitration agreement permits either you or Pathstream to litigate any dispute arising out of or relating to the subject matter of these Terms in court, then the foregoing arbitration agreement will not apply to either party, and both you and Pathstream agree that any judicial proceeding (other than small claims actions) will be brought in the state or federal courts located in, respectively, State of California, or the federal district in which that county falls.</p>
        <p>(h)&nbsp;<i>Severability</i>. If the prohibition against class actions and other claims brought on behalf of third parties contained above is found to be unenforceable, then all of the preceding language in this Arbitration Agreement section will be null and void. This arbitration agreement will survive the termination of your relationship with Pathstream.</p>
        <h4><i>Miscellaneous</i></h4>
        <p>You will be responsible for paying, withholding, filing, and reporting all taxes, duties, and other governmental assessments associated with your activity in connection with the Services, provided that the Pathstream may, in its sole discretion, do any of the foregoing on your behalf or for itself as it sees fit. The failure of either you or us to exercise, in any way, any right herein shall not be deemed a waiver of any further rights hereunder. If any provision of these Terms are found to be unenforceable or invalid, that provision will be limited or eliminated, to the minimum extent necessary, so that these Terms shall otherwise remain in full force and effect and enforceable. You and Pathstream agree that these Terms are the complete and exclusive statement of the mutual understanding between you and Pathstream, and that these Terms supersede and cancel all previous written and oral agreements, communications and other understandings relating to the subject matter of these Terms. You hereby acknowledge and agree that you are not an employee, agent, partner, or joint venture of Pathstream, and you do not have any authority of any kind to bind Pathstream in any respect whatsoever.</p>
        <p>Except as expressly set forth in the arbitration agreement, you and Pathstream agree there are no third-party beneficiaries intended under these Terms.</p>
      </div>
    );
  }
}
