import axios from './axios';

export default class Section {
  static save(_, section, pathId) {
    if (section.id) {
      return axios.put(`/paths/sections/${section.id}`, section).then(response => response.data);
    }
    return axios
      .post('/paths/sections', {
        ...section,
        path_id: pathId,
      })
      .then(response => response.data);
  }

  static reorder(_, sectionIds, pathId) {
    return axios
      .put(`/paths/paths/${pathId}/reorder_sections`, { section_ids: sectionIds })
      .then(response => response.data);
  }
}
