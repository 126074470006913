import { each as _each } from 'lodash';
import React, { Component } from 'react';
import { isPrintMode } from '../../helpers/printHelper';

export default class PrintWrapper extends Component {
  hideElements = () => {
    const toHide = document.querySelectorAll('header, .admin-pages-sidebar');
    _each(toHide, el => {
      el.style.display = 'none';
    });

    console.log('fcWidget', window.fcWidget);
    if (window.fcWidget) {
      window.fcWidget.hide();
    }
  };

  componentDidUpdate() {
    if (isPrintMode()) {
      this.hideElements();
    }
  }

  render() {
    return <>{this.props.children}</>;
  }
}
