import { isEmpty } from 'lodash';

export function willReceiveProps(nextProps) {
  if (this.state.block && nextProps.block && this.state.block.seq_num !== nextProps.block.seq_num) {
    this.setState({
      block: {
        ...this.state.block,
        seq_num: nextProps.block.seq_num,
      },
    });
  }
}

export function contentForStudent(content) {
  if (isEmpty(content)) {
    return content;
  }

  return closeOpenedRteSnippets(content);
}

function closeOpenedRteSnippets(content) {
  // find all RTE opened snippets, which look like: <details.....open="undefined".....> ...... </details>
  let matches = content.match(/<details class="pre"[^>]*data-collapse="false"[^>]*open="undefined"[^>]*>/g);
  if (isEmpty(matches)) {
    return content;
  }

  matches.forEach(match => {
    // to close snippet, 'open="undefined"' should be removed
    let replacement = match.replace('open="undefined"', '');
    content = content.replace(match, replacement);
  });
  return content;
}
