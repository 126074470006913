import { isEmpty as _isEmpty, map as _map, orderBy as _orderBy, debounce as _debounce } from 'lodash';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import CohortListItemContainer from './CohortListItemContainer';
import Loading from '../../Utils/Loading';
import AdminWrapper from '../../v2/Nav/Admin/AdminWrapper';
import { getSearchRegex } from '../../../helpers/regexHelper';
import * as analytics from '../../../utils/analytics';
import { setPageTitle } from '../../../helpers/pageHelper';
import intl from '../../../utils/intl';
import showModal from '../../../utils/showModal';
import EditCohortModal from './EditCohortModal';
import CohortFilters from './filter/CohortFilters';
import {
  bootcampFilter,
  selectFilter,
  statusFilter,
  syncedFilter,
  warningFilter,
} from './filter/cohortFiltersFunctions';
import useLtiConsumers from '../../../hooks/useLtiConsumers';

const CohortList = ({
  fetchBootcamps,
  fetchCohorts,
  fetchCohortLookups,
  fetchSchools,
  schools,
  cohorts,
  bootcamps,
}) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [filtersState, setFiltersState] = useState({});
  const { isLoading, ltiConsumers } = useLtiConsumers();

  useEffect(() => {
    fetchBootcamps();
    fetchCohorts();
    fetchSchools();
    fetchCohortLookups(['partnerTypes', 'modalities', 'creditTypes', 'psSemesters']);
    analytics.pageStart('Cohorts List');
    setPageTitle('pageName.cohort.list');
  }, []);

  const debouncedHandleSearch = useRef(_debounce(searchText => setSearchQuery(searchText), 300)).current;

  const handleSearchChange = e => {
    debouncedHandleSearch(e.target.value);
  };

  const getFilteredCohorts = () => {
    let filtered = cohorts;

    if (!_isEmpty(searchQuery)) {
      const regex = getSearchRegex(searchQuery);

      filtered = cohorts.filter(cohort => {
        // eslint-disable-next-line no-shadow
        const bc = bootcamps.filter(bc => bc.id === cohort.bootcamp_id)[0];
        const school = schools.find(school => school.id === cohort.school_id);
        return regex.test(cohort.location) || regex.test(bc.title) || regex.test(school?.name);
      });
    }
    filtered = filterCohorts(filtered);
    return _orderBy(filtered, ['start_date'], ['desc']);
  };

  const filterCohorts = items => {
    let filtered = items;

    Object.keys(filtersState).forEach(name => {
      if (filtersState[name] !== undefined) {
        switch (name) {
          case 'status':
            filtered = statusFilter(filtered, filtersState[name]);
            break;
          case 'synced':
            filtered = syncedFilter(filtered, filtersState[name]);
            break;
          case 'warning':
            filtered = warningFilter(filtered, filtersState[name]);
            break;
          case 'school_id':
          case 'partner_type_id':
          case 'ps_semester_id':
            filtered = selectFilter(filtered, filtersState[name], name);
            break;
          case 'bootcamp_name':
          case 'bootcamp_version':
            filtered = bootcampFilter(filtered, filtersState[name], name, bootcamps);
            break;
          default:
            break;
        }
      }
    });
    return filtered;
  };

  const renderCohorts = () => {
    const filteredCohorts = getFilteredCohorts();

    if (isLoading) {
      return null;
    }
    return _map(filteredCohorts, cohort => (
      <CohortListItemContainer key={cohort.id} cohort={cohort} ltiConsumers={ltiConsumers} />
    ));
  };
  const renderedCohorts = useMemo(() => renderCohorts(), [cohorts, searchQuery, filtersState, isLoading]);

  const handleCreate = () => {
    showModal(EditCohortModal, {
      cohort: {},
      ltiConsumers,
    });
  };

  const renderHeader = () => (
    <div className="user-content">
      <div className="grid-row user-content-items clear">
        <div className="sm-4 user-content-item">
          <span className="title-row-label">
            <FormattedMessage id="cohort.details" />
          </span>
        </div>

        <div className="sm-3 xs-12 user-content-item">
          <span className="title-row-label">
            <FormattedMessage id="cohort.form.location" />
          </span>
        </div>

        <div className="sm-1 sm-offset-1 xs-12 user-content-item">
          <span className="title-row-label">
            <FormattedMessage id="cohort.form.school" />
          </span>
        </div>

        <div className="sm-1 xs-12 user-content-item">
          <span className="title-row-label">
            <FormattedMessage id="cohort.fields.numEnrolled" />
          </span>
        </div>

        <div className="sm-1 xs-12 user-content-button clear">
          <span className="title-row-label">
            <FormattedMessage id="common.action" />
          </span>
        </div>
      </div>
    </div>
  );

  if (_isEmpty(bootcamps) || !cohorts) {
    return (
      <AdminWrapper>
        <Loading />
      </AdminWrapper>
    );
  }

  return (
    <AdminWrapper headerTitle={<FormattedMessage id="nav.cohorts" />}>
      <div className="grid-row">
        <div className="sm-3 sm-offset-1">
          <div className="custom-search-input">
            <label htmlFor="search" className="visually-hidden">
              <FormattedMessage id="common.search" />
            </label>
            <input
              id="search"
              type="text"
              name="search"
              onChange={handleSearchChange}
              placeholder={intl.formatMessage({ id: 'common.searchPlaceholder' })}
              className="custom-search-input__input"
            />
          </div>
        </div>

        <div className="sm-2 sm-offset-5 horizontal-end">
          {/* eslint-disable-next-line react/button-has-type */}
          <button className="button-standard button-standard--blue2 button-standard--short" onClick={handleCreate}>
            <FormattedMessage id="common.addNew" />
          </button>
        </div>
      </div>
      <div className="grid-row mt-20">
        <div className="sm-10 sm-offset-1">
          <CohortFilters setFiltersState={setFiltersState} filtersState={filtersState} />
        </div>
      </div>

      <div className="grid-row">
        <div className="sm-10 sm-offset-1">
          <div className="user-content-table">
            {renderHeader()}
            {renderedCohorts}
          </div>
        </div>
      </div>
    </AdminWrapper>
  );
};

CohortList.propTypes = {
  bootcamps: PropTypes.array,
  cohorts: PropTypes.array,
  schools: PropTypes.array,
  fetchCohorts: PropTypes.func.isRequired,
  fetchBootcamps: PropTypes.func.isRequired,
  fetchCohortLookups: PropTypes.func.isRequired,
  fetchSchools: PropTypes.func.isRequired,
};

export default CohortList;
