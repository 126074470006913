import { COMPONENT_COACH } from '../../actions/constants';

const INITIAL_STATE = {
  currentCohortId: '',
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case COMPONENT_COACH.SET_CURRENT_COHORT_ID: {
      const { currentCohortId } = action.payload;
      return {
        ...state,
        currentCohortId,
      };
    }
    default:
      return state;
  }
}
