import { each as _each } from 'lodash';
import { areTokensValid, getTokens } from './userAuth';

export const postBeacon = (url, params, skipAuth) => {
  if (!skipAuth && !areTokensValid()) {
    console.log('Token expired. Unable to expire session');
    return;
  }
  const { accessToken } = getTokens();
  const formData = new FormData();
  _each(params, (v, k) => {
    formData.append(k, v);
  });
  if (!skipAuth) {
    formData.append('Authorization', `Bearer ${accessToken}`);
  }

  navigator.sendBeacon(url, formData);
};
