import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Row, Col, Pagination, Space, Input, Result } from 'antd';

import EvaluatorQueueItem from './EvaluatorQueueItem';
import EvaluatorLoadingRow from './EvaluatorLoadingRow';
import CertificateTitleFilterControl from './CertificateTitleFilterControl';
import EvaluatorActionButtonGroup from './EvaluatorActionButtonGroup';
import { fetchAllQueueItems } from '../../actions/evaluatorQueueItemActions';
import CertificateTitle from '../../api/certificateTitle';
import useLocalStorageState from '../../hooks/useLocalStorageState';
import intl from '../../utils/intl';

const STORAGE_KEY = 'evaluator_queue_filter_preferences';
const certificateTitles = CertificateTitle.all();

const getInitialFilterValues = () => {
  let filterValues = {};

  certificateTitles.forEach(title => {
    if (title === 'Other' || title === 'Testing') {
      filterValues[title] = false;
    } else {
      filterValues[title] = true;
    }
  });
  return filterValues;
};

const EvaluatorQueueTab = props => {
  const dispatch = useDispatch();
  const [queueItems, setQueueItems] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentTime, setCurrentTime] = useState();
  const [filterValues, setFilterValues] = useState({});
  const [isDataLoading, setIsDataLoading] = useState(false);

  const [initialFilterValues] = useLocalStorageState(STORAGE_KEY, getInitialFilterValues());
  const [itemsCountPerPage, setItemsCountPerPage] = useState(10);

  const [pagination, setPagination] = useState({
    total: 0,
    limit: itemsCountPerPage,
    offset: 0,
  });

  useEffect(() => {
    const certificates = [];
    Object.keys(initialFilterValues).forEach(key => {
      if (initialFilterValues[key]) {
        certificates.push(key);
      }
    });

    updateData({ offset: 0, limit: itemsCountPerPage, certificates: certificates.join(',') });

    const intervalTimeUpdater = setInterval(() => {
      const now = new Date();
      setCurrentTime(now.toISOString());
    }, 1000);
    return () => clearInterval(intervalTimeUpdater);
  }, []);

  useEffect(() => {
    setCurrentPage(0);
    updateData();
  }, [filterValues]);

  const updateData = (params = {}) => {
    if (isDataLoading) {
      return;
    }
    setIsDataLoading(true);
    let defaultParams = {
      offset: 0,
      limit: itemsCountPerPage,
      certificates: filterValues.certificateTitles ? filterValues.certificateTitles.join(',') : '',
    };
    if (filterValues.textSearch) {
      defaultParams.q = filterValues.textSearch;
    }
    const paramsWithDefaults = { ...defaultParams, ...params };

    if (props.queueItemsType === 'graded') {
      getGradedItems(paramsWithDefaults);
    } else {
      getUngradedItems(paramsWithDefaults);
    }
  };

  const handlePaginationChange = page => {
    const params = {
      offset: (page - 1) * itemsCountPerPage,
      limit: itemsCountPerPage,
    };
    if (params.offset < 0) {
      params.offset = 0;
    }
    updateData(params);
    setCurrentPage(page);
  };

  const handleShowSizeChange = (currentCount, newCount) => {
    setItemsCountPerPage(newCount);
    const params = {
      offset: 0,
      limit: newCount,
    };
    updateData(params);
  };

  const getUngradedItems = params => {
    fetchAllQueueItems(params)(dispatch).then(({ entities, meta }) => {
      setQueueItems(entities);
      setPagination(meta.pagination);
      setIsDataLoading(false);
    });
  };

  const getGradedItems = params => {
    const paramsWithDefault = { ...params, status: 'graded', order_by: 'created_at DESC' };
    fetchAllQueueItems(paramsWithDefault)(dispatch).then(({ entities, meta }) => {
      setQueueItems(entities);
      setPagination(meta.pagination);
      setIsDataLoading(false);
    });
  };

  const performTextSearch = value => {
    const output = {
      ...filterValues,
      textSearch: value,
    };
    setFilterValues(output);
  };

  const handleCertificateTitleFilterChange = async filters => {
    const allowedCertificateTitles = Object.keys(filters).filter(key => filters[key]);
    const output = {
      ...filterValues,
      offset: 0,
      certificateTitles: allowedCertificateTitles,
    };
    setFilterValues(output);
  };

  const { Search } = Input;

  const styles = {
    paginationWrapper: {
      margin: 10,
      textAlign: 'center',
    },
    tabTitle: {
      fontSize: 30,
      fontWeight: 700,
    },
  };

  return (
    <>
      <Row>
        <Col span={12}>
          <h2 style={styles.tabTitle}>{props.tabTitle}</h2>
        </Col>
        <Col span={12} style={{ textAlign: 'right' }}>
          <Search
            disabled={isDataLoading}
            placeholder={intl.formatMessage({ id: 'evaluatorQueue.searchPlaceholder' })}
            onSearch={value => performTextSearch(value)}
            size="large"
            allowClear={true}
            addonBefore={
              <CertificateTitleFilterControl
                onCertificateTitleFilterChange={handleCertificateTitleFilterChange}
                disabled={isDataLoading}
              />
            }
          />
        </Col>
      </Row>
      {isDataLoading && <EvaluatorLoadingRow />}
      {!isDataLoading && queueItems.length == 0 && (
        <Result
          title={intl.formatMessage({ id: 'evaluatorQueue.noItemsToDisplay.title' })}
          subTitle={intl.formatMessage({ id: 'evaluatorQueue.noItemsToDisplay.subtitle' })}
        />
      )}
      {!isDataLoading &&
        queueItems.length > 0 &&
        queueItems.map(item => (
          <EvaluatorQueueItem
            key={item.id}
            item={item}
            currentTime={currentTime}
            buttonGroup={<EvaluatorActionButtonGroup {...{ item, updateData }} />}
          />
        ))}
      <Row justify="space-around">
        <Col span={24} style={{ textAlign: 'center' }}>
          <Space direction="vertical">
            <Pagination
              defaultCurrent={1}
              defaultPageSize={10}
              pageSize={itemsCountPerPage}
              current={currentPage}
              total={pagination.total}
              showSizeChanger={true}
              onShowSizeChange={handleShowSizeChange}
              onChange={handlePaginationChange}
              style={styles.paginationWrapper}
            />
          </Space>
        </Col>
      </Row>
    </>
  );
};

EvaluatorQueueTab.propTypes = {
  tabTitle: PropTypes.string,
  queueItemsType: PropTypes.string,
};

export default EvaluatorQueueTab;
