import React from 'react';
import useCurrentUser from '../../hooks/useCurrentUser';
import SavingIndicator from '../Utils/SavingIndicator';
import AuthorEditToggle from '../v2/Nav/AuthorEditToggle';

export default function EditBar() {
  const { canAuthor } = useCurrentUser();

  if (!canAuthor) return '';

  return (
    <div className="header-bar__edit">
      <div className="header-bar__edit-wrapper">
        <div className="header-bar__edit-notification">
          <SavingIndicator />
        </div>
        <AuthorEditToggle className="header-bar__edit-title" />
      </div>
    </div>
  );
}
