/* eslint-disable max-lines */
export default [
  {
    id: '29h257a2-801d-42c8-9577-d819a74561ad',
    name: 'Assessment 1',
    assignmentId: 'assignment-id-1',
    assessment: [
      {
        type: 'ruleset',
        group: [
          {
            type: 'ruleset criterion',
            criterionId: 'criterion-id-1',
            criteria: [
              {
                type: 'rule',
                check: [
                  {
                    type: 'check',
                    name: 'Number of Rows Correct',
                    location: [
                      {
                        within: 'worksheet',
                        dataSource: {
                          type: 'byName',
                          name: 'Prompts',
                        },
                      },
                      {
                        within: 'cell',
                        dataSource: {
                          type: 'cellAddress',
                          name: 'D7',
                          cellAddress: 'D7',
                        },
                      },
                    ],
                    dataSource: ['byName', 'cellAddress'],
                    property: 'value',
                    operator: '==',
                    value: '5,550',
                    fieldKey: '7961db8a-a7a2-4439-988c-a84b5a41f1bb',
                  },
                  {
                    type: 'check',
                    name: 'Number of Columns Correct',
                    property: 'value',
                    operator: '==',
                    value: '15',
                    fieldKey: 'a2d52f7c-7702-4189-934b-09928ecc0d49',
                    location: [
                      {
                        within: 'worksheet',
                        dataSource: {
                          type: 'byName',
                          name: 'Prompts',
                        },
                      },
                      {
                        within: 'cell',
                        dataSource: {
                          type: 'cellAddress',
                          name: 'D8',
                          cellAddress: 'D8',
                        },
                      },
                    ],
                    dataSource: ['cellAddress'],
                    names: ['Prompts'],
                    cellAddress: 'D8',
                  },
                ],
                name: 'Number of Rows and Columns',
                description: 'User has identified the correct number of rows and columns',
                logicFormula: '(a+b)',
                points: '1',
                resubmitOnError: false,
                fieldKey: '15e67fa8-5ba9-4481-82ff-ccc827e4de8f',
              },
            ],
            name: 'Prompt Questions',
            fieldKey: 'fb070724-0f13-4915-8858-909be56a9074',
          },
        ],
        name: 'Step 0: Explore the Data',
        fieldKey: '0e9f83fa-c1a1-4587-88e7-b5c83ed01014',
      },
      {
        type: 'ruleset',
        group: [
          {
            type: 'ruleset criterion',
            criterionId: 'criterion-id-2',
            criteria: [
              {
                type: 'rule',
                check: [
                  {
                    type: 'check',
                    name: '"Summary By City" - Pivot Table Created',
                    location: [
                      {
                        within: 'worksheet',
                        dataSource: {
                          type: 'byName',
                          name: 'Summary By City',
                        },
                      },
                      {
                        within: 'pivotTable',
                        dataSource: {
                          type: 'byTitle',
                          name: 'City',
                        },
                      },
                    ],
                    dataSource: ['byName', 'byTitle'],
                    property: 'exists',
                    operator: '==',
                    value: '',
                    fieldKey: '309e1259-e6b8-4f29-b115-7e49a9454dc0',
                  },
                ],
                name: 'Pivot Table Created',
                description: 'There exists a pivot table',
                logicFormula: 'a',
                points: '1',
                resubmitOnError: false,
                fieldKey: '6c5624f2-881c-4e0a-846c-addd3394a78a',
              },
              {
                type: 'rule',
                check: [
                  {
                    type: 'check',
                    name: 'Pivot Table Value - Bathrooms - Summarize By',
                    property: 'summarizeBy',
                    operator: '==',
                    value: 'AVERAGE',
                    fieldKey: '6c92bd0e-2e43-46eb-9751-b9db8a137dd7',
                    location: [
                      {
                        within: 'worksheet',
                        dataSource: {
                          type: 'byName',
                          name: 'Summary By City',
                        },
                      },
                      {
                        within: 'pivotTable',
                        dataSource: {
                          type: 'byName',
                          name: 'City',
                        },
                      },
                      {
                        within: 'value',
                        dataSource: {
                          type: 'byTitle',
                          name: 'Bathrooms',
                        },
                      },
                    ],
                    dataSource: ['byTitle'],
                    names: ['Summary By City', 'City'],
                    byTitle: 'Bathrooms',
                  },
                  {
                    type: 'check',
                    name: 'Pivot Table Value - Bathrooms - Exists',
                    location: [
                      {
                        within: 'worksheet',
                        dataSource: {
                          type: 'byName',
                          name: 'Summary By City',
                        },
                      },
                      {
                        within: 'pivotTable',
                        dataSource: {
                          type: 'byName',
                          name: 'City',
                        },
                      },
                      {
                        within: 'value',
                        dataSource: {
                          type: 'byTitle',
                          name: 'Bathrooms',
                        },
                      },
                    ],
                    dataSource: ['byName', 'byName', 'byTitle'],
                    property: 'exists',
                    operator: '==',
                    value: '',
                    fieldKey: '6f003df2-7407-4275-ac2a-0af6b417eac3',
                  },
                ],
                name: 'Pivot Table Value - Bathrooms',
                description: 'Pivot Table Value - Bathrooms',
                logicFormula: 'a+b',
                points: '1',
                resubmitOnError: false,
                fieldKey: '11619dfe-7037-4127-ad8b-8d5dd11da4a8',
              },
              {
                type: 'rule',
                check: [
                  {
                    type: 'check',
                    name: 'Pivot Table Value - Bedrooms - Summarize By',
                    location: [
                      {
                        within: 'worksheet',
                        dataSource: {
                          type: 'byName',
                          name: 'Summary By City',
                        },
                      },
                      {
                        within: 'pivotTable',
                        dataSource: {
                          type: 'byName',
                          name: 'City',
                        },
                      },
                      {
                        within: 'value',
                        dataSource: {
                          type: 'byTitle',
                          name: 'Bedrooms',
                        },
                      },
                    ],
                    dataSource: ['byTitle'],
                    property: 'summarizeBy',
                    operator: '==',
                    value: 'AVERAGE',
                    fieldKey: '0c6a6ef5-cede-48c6-ab93-c3a81c5d9614',
                    names: ['Summary By City', 'City'],
                    byTitle: 'Bedrooms',
                  },
                  {
                    type: 'check',
                    name: 'Pivot Table Value - Bedrooms - Summarize By',
                    property: 'exists',
                    operator: '==',
                    value: '',
                    fieldKey: 'dea623cb-9041-4430-93bd-7347ce44447e',
                    location: [
                      {
                        within: 'worksheet',
                        dataSource: {
                          type: 'byName',
                          name: 'Summary By City',
                        },
                      },
                      {
                        within: 'pivotTable',
                        dataSource: {
                          type: 'byName',
                          name: 'City',
                        },
                      },
                      {
                        within: 'value',
                        dataSource: {
                          type: 'byTitle',
                          name: 'Bedrooms',
                        },
                      },
                    ],
                    dataSource: ['byTitle'],
                    names: ['Summary By City', 'City'],
                    byTitle: 'Bedrooms',
                  },
                ],
                name: 'Pivot Table Value - Bedrooms',
                description: 'Pivot Table Value - Bedrooms',
                logicFormula: 'a+b',
                points: '1',
                resubmitOnError: false,
                fieldKey: 'fd952a9e-46c4-46bf-bca0-dfd076bd7b4f',
              },
              {
                type: 'rule',
                check: [
                  {
                    type: 'check',
                    name: 'Pivot Table Value - Beds - Summarize By',
                    property: 'summarizeBy',
                    operator: '==',
                    value: 'AVERAGE',
                    fieldKey: '34141846-be35-4eab-b087-c0c17edebcd9',
                    location: [
                      {
                        within: 'worksheet',
                        dataSource: {
                          type: 'byName',
                          name: 'Summary By City',
                        },
                      },
                      {
                        within: 'pivotTable',
                        dataSource: {
                          type: 'byName',
                          name: 'City',
                        },
                      },
                      {
                        within: 'value',
                        dataSource: {
                          type: 'byTitle',
                          name: 'Beds',
                        },
                      },
                    ],
                    dataSource: ['byTitle'],
                    names: ['Summary By City', 'City'],
                    byTitle: 'Beds',
                  },
                ],
                name: 'Pivot Table Value - Beds',
                description: 'Pivot Table Value - Beds',
                logicFormula: 'a',
                points: '1',
                resubmitOnError: false,
                fieldKey: '80326b6f-9c5c-441d-8ed8-0f8af37916d0',
              },
              {
                type: 'rule',
                check: [
                  {
                    type: 'check',
                    name: 'Pivot Table Value - Price - Summarize By',
                    property: 'summarizeBy',
                    operator: '==',
                    value: 'AVERAGE',
                    fieldKey: 'fe4a835f-4264-4800-acab-3c6112ed25fa',
                    location: [
                      {
                        within: 'worksheet',
                        dataSource: {
                          type: 'byName',
                          name: 'Summary By City',
                        },
                      },
                      {
                        within: 'pivotTable',
                        dataSource: {
                          type: 'byName',
                          name: 'City',
                        },
                      },
                      {
                        within: 'value',
                        dataSource: {
                          type: 'byTitle',
                          name: 'Price',
                        },
                      },
                    ],
                    dataSource: ['byTitle'],
                    names: ['Summary By City', 'City'],
                    byTitle: 'Price',
                  },
                ],
                name: 'Pivot Table Value - Price',
                description: 'Pivot Table Value - Price',
                logicFormula: 'a',
                points: '1',
                resubmitOnError: false,
                fieldKey: '1e4d834a-4e6d-4373-8147-d01ac4ece5c8',
              },
              {
                type: 'rule',
                check: [
                  {
                    type: 'check',
                    name: 'Pivot Table Value - Number of Reviews - Summarize By',
                    property: 'summarizeBy',
                    operator: '==',
                    value: 'SUM',
                    fieldKey: '6cf47453-8742-4d53-92df-befc7fb210f9',
                    location: [
                      {
                        within: 'worksheet',
                        dataSource: {
                          type: 'byName',
                          name: 'Summary By City',
                        },
                      },
                      {
                        within: 'pivotTable',
                        dataSource: {
                          type: 'byName',
                          name: 'City',
                        },
                      },
                      {
                        within: 'value',
                        dataSource: {
                          type: 'byTitle',
                          name: 'Number of Reviews',
                        },
                      },
                    ],
                    dataSource: ['byTitle'],
                    names: ['Summary By City', 'City'],
                    byTitle: 'Number of Reviews',
                  },
                ],
                name: 'Pivot Table Value - Number of Reviews',
                description: 'Pivot Table Value - Number of Reviews',
                logicFormula: 'a',
                points: '1',
                resubmitOnError: false,
                fieldKey: '888c3146-4b40-42ae-8584-a48f2387e1ed',
              },
              {
                type: 'rule',
                check: [
                  {
                    type: 'check',
                    name: 'Pivot Table Value - Days Listed - Summarize By',
                    property: 'summarizeBy',
                    operator: '==',
                    value: 'AVERAGE',
                    fieldKey: 'd0076eb3-80e2-42c5-8651-c98135ec257d',
                    location: [
                      {
                        within: 'worksheet',
                        dataSource: {
                          type: 'byName',
                          name: 'Summary By City',
                        },
                      },
                      {
                        within: 'pivotTable',
                        dataSource: {
                          type: 'byName',
                          name: 'City',
                        },
                      },
                      {
                        within: 'value',
                        dataSource: {
                          type: 'byTitle',
                          name: 'Days Listed',
                        },
                      },
                    ],
                    dataSource: ['byTitle'],
                    names: ['Summary By City', 'City'],
                    byTitle: 'Days Listed',
                  },
                ],
                name: 'Pivot Table Value - Days Listed',
                description: 'Pivot Table Value - Days Listed',
                logicFormula: 'a',
                points: '1',
                resubmitOnError: false,
                fieldKey: 'eb7fe00f-c5b1-4f05-9785-787a0dc3c272',
              },
              {
                type: 'rule',
                check: [
                  {
                    type: 'check',
                    name: 'Pivot Table Value - Review Scores Location - Summarize By',
                    property: 'summarizeBy',
                    operator: '==',
                    value: 'AVERAGE',
                    fieldKey: 'b6071cc6-d9bb-42d5-a5c3-902fa675c1f2',
                    location: [
                      {
                        within: 'worksheet',
                        dataSource: {
                          type: 'byName',
                          name: 'Summary By City',
                        },
                      },
                      {
                        within: 'pivotTable',
                        dataSource: {
                          type: 'byName',
                          name: 'City',
                        },
                      },
                      {
                        within: 'value',
                        dataSource: {
                          type: 'byTitle',
                          name: 'Review Scores Location',
                        },
                      },
                    ],
                    dataSource: ['byTitle'],
                    names: ['Summary By City', 'City'],
                    byTitle: 'Review Scores Location',
                  },
                ],
                name: 'Pivot Table Value - Review Scores Location',
                description: 'Pivot Table Value - Review Scores Location',
                logicFormula: 'a',
                points: '1',
                resubmitOnError: false,
                fieldKey: '8031a601-adad-41b2-bf7f-4b2c63faffa0',
              },
              {
                type: 'rule',
                check: [
                  {
                    type: 'check',
                    name: 'Pivot Table Value - Review Scores Value - Summarize By',
                    property: 'summarizeBy',
                    operator: '==',
                    value: 'AVERAGE',
                    fieldKey: '2261f15e-92e3-45d8-8a27-eb42acfba1c3',
                    location: [
                      {
                        within: 'worksheet',
                        dataSource: {
                          type: 'byName',
                          name: 'Summary By City',
                        },
                      },
                      {
                        within: 'pivotTable',
                        dataSource: {
                          type: 'byName',
                          name: 'City',
                        },
                      },
                      {
                        within: 'value',
                        dataSource: {
                          type: 'byTitle',
                          name: 'Review Scores Value',
                        },
                      },
                    ],
                    dataSource: ['byTitle'],
                    names: ['Summary By City', 'City'],
                    byTitle: 'Review Scores Value',
                  },
                ],
                name: 'Pivot Table Value - Review Scores Value',
                description: 'Pivot Table Value - Review Scores Value',
                logicFormula: 'a',
                points: '1',
                resubmitOnError: false,
                fieldKey: '300788f8-c47b-4892-b867-e970e2e7e893',
              },
              {
                type: 'rule',
                check: [
                  {
                    type: 'check',
                    name: 'Prompt Question is not empty',
                    property: 'value',
                    operator: '!=',
                    value: '',
                    fieldKey: 'd80de734-4fe5-41c7-90fd-6010c41372d0',
                    location: [
                      {
                        within: 'worksheet',
                        dataSource: {
                          type: 'byName',
                          name: 'Prompts',
                        },
                      },
                      {
                        within: 'cell',
                        dataSource: {
                          type: 'cellAddress',
                          name: 'C15',
                          cellAddress: 'C15',
                        },
                      },
                    ],
                    dataSource: ['cellAddress'],
                    names: ['Prompts'],
                    cellAddress: 'C15',
                  },
                ],
                name: 'Prompt Question is not empty',
                description: 'Prompt Question is not empty',
                logicFormula: 'a',
                points: '1',
                resubmitOnError: false,
                fieldKey: '4aa91397-563f-4868-8177-b954c700abb8',
              },
            ],
            name: 'Pivot Table',
            fieldKey: '68a87117-ea7a-4ac2-adb5-af490264a71e',
          },
        ],
        name: 'Step 1: Pivot Table',
        fieldKey: 'd2ee66f0-c87f-4411-b80c-9586faf7619c',
      },
      {
        type: 'ruleset',
        group: [
          {
            type: 'ruleset criterion',
            criteria: [
              {
                type: 'rule',
                check: [
                  {
                    type: 'check',
                    name: "Sheet 'Copy of DataExport_2017Mar' - Column 'Bathrooms' does not include - 'None'",
                    property: 'value',
                    operator: '!=',
                    value: 'None',
                    fieldKey: '426185ac-2e33-46be-85e5-f5fa61a87c47',
                    location: [
                      {
                        within: 'worksheet',
                        dataSource: {
                          type: 'byName',
                          name: 'Copy of DataExport_2017Mar',
                        },
                      },
                      {
                        within: 'columns',
                        dataSource: {
                          type: 'byName',
                          name: 'Bathrooms',
                        },
                      },
                      {
                        within: 'cell',
                        dataSource: {
                          type: 'byAny',
                        },
                      },
                    ],
                    dataSource: ['byAny'],
                    names: ['Copy of DataExport_2017Mar', 'Bathrooms'],
                  },
                ],
                name: "Sheet 'Copy of DataExport_2017Mar' - Column 'Bathrooms' does not include 'None'",
                description: "Sheet 'Copy of DataExport_2017Mar' - Column 'Bathrooms' does not include 'None'",
                logicFormula: 'a',
                points: '1',
                resubmitOnError: false,
                fieldKey: '24fa195e-7f57-4f36-9022-09c48a4b340c',
              },
            ],
            name: '"None" data converted to "0"',
            fieldKey: 'ce22ba37-0e55-4806-9a1f-dc618abc6634',
          },
        ],
        name: 'Missing, Invalid, and Duplicative Data',
        fieldKey: '59b58db1-e710-4715-97a4-bd5858e792dd',
      },
      {
        type: 'ruleset',
        group: [
          {
            type: 'ruleset criterion',
            criteria: [
              {
                type: 'rule',
                check: [
                  {
                    type: 'check',
                    name: "Column 'Internet' exists",
                    location: [
                      {
                        within: 'worksheet',
                        dataSource: {
                          type: 'byName',
                          name: 'Copy of DataExport_2017Mar',
                        },
                      },
                      {
                        within: 'columns',
                        dataSource: {
                          type: 'byName',
                          name: 'Internet',
                        },
                      },
                    ],
                    dataSource: ['byName'],
                    property: 'exists',
                    operator: '',
                    value: '',
                    fieldKey: '485a7cf6-464e-44a6-a35e-86f5c79d2286',
                    names: ['Copy of DataExport_2017Mar'],
                    byName: 'Internet',
                  },
                ],
                name: 'Internet Column',
                description: 'Internet Column, formula, and results are correct',
                logicFormula: '(a+b+c+d)',
                points: '1',
                resubmitOnError: false,
                fieldKey: 'dd246069-e7f9-4a81-b1ef-308705be9d61',
              },
            ],
            name: 'Split Text Column Checks',
            fieldKey: 'b5a97e7a-38f2-4673-bfe3-90b1dfc0a51f',
          },
        ],
        name: 'Split Text Into Columns',
        fieldKey: '4f0b5f73-a3b9-471e-9096-810cc43d31ff',
      },
      {
        type: 'ruleset',
        group: [
          {
            type: 'ruleset criterion',
            criteria: [
              {
                type: 'rule',
                check: [
                  {
                    type: 'check',
                    name: 'Find column=HostID > row value= 60611720 > column=Internet and value should be FALSE',
                    location: [
                      {
                        within: 'worksheet',
                        dataSource: {
                          type: 'byName',
                          name: 'Copy of DataExport_2017Mar',
                        },
                      },
                      {
                        within: 'columns',
                        dataSource: {
                          type: 'byName',
                          name: 'Host ID',
                        },
                      },
                      {
                        within: 'rowValue',
                        dataSource: {
                          type: 'byName',
                          name: '60611720',
                        },
                      },
                      {
                        within: 'columns2',
                        dataSource: {
                          type: 'byName',
                          name: 'Internet',
                        },
                      },
                    ],
                    dataSource: ['byName'],
                    property: 'value',
                    operator: '==',
                    value: 'FALSE',
                    fieldKey: '015959fc-cc23-43d0-9da5-3609e109cb4f',
                    names: ['Copy of DataExport_2017Mar', 'Host ID', '60611720'],
                    byName: 'Internet',
                  },
                ],
                name:
                  'Find the row where the column is HostID and row value is 60611720 and check the Internet column for a FALSE value',
                description: '',
                logicFormula: 'a+b',
                points: '1',
                resubmitOnError: false,
                fieldKey: '8b310c6f-aba3-48c5-a6a4-50ec9b53df9a',
              },
            ],
            name: 'Check column to row value to column value',
            fieldKey: '408dba48-a25e-45ee-9959-328c4a3ead15',
          },
        ],
        name: 'Check column to row value to column value',
        fieldKey: '18def2cd-0be2-422e-b208-2b4192d6ab01',
      },
      {
        type: 'ruleset',
        group: [
          {
            type: 'ruleset criterion',
            criteria: [
              {
                type: 'rule',
                check: [
                  {
                    type: 'check',
                    name: 'Pivot Table Rows is by City',
                    location: [
                      {
                        within: 'worksheet',
                        dataSource: {
                          type: 'byName',
                          name: 'Summary By City',
                        },
                      },
                      {
                        within: 'pivotTable',
                        dataSource: {
                          type: 'byName',
                          name: 'City',
                        },
                      },
                      {
                        within: 'columns',
                        dataSource: {
                          type: 'byName',
                          name: 'City',
                        },
                      },
                      {
                        within: 'rowValue',
                        dataSource: {
                          type: 'byName',
                          name: 'Oakland',
                        },
                      },
                      {
                        within: 'columns2',
                        dataSource: {
                          type: 'byTitle',
                          name: 'State',
                        },
                      },
                    ],
                    dataSource: ['byTitle'],
                    property: 'value',
                    operator: '==',
                    value: 'CA',
                    fieldKey: '028ad86a-d623-4d8c-b3b3-cb57ec1ef06e',
                    names: ['Summary By City', 'City', 'City', 'Oakland'],
                    byTitle: 'State',
                  },
                ],
                name: 'Pivot Table Rows is by City',
                description: '',
                logicFormula: 'a',
                points: '1',
                resubmitOnError: false,
                fieldKey: '01b091bc-3500-4125-b1fd-1634d316a06c',
              },
            ],
            name: 'Pivot Table Rows is by City',
            fieldKey: '1be371d0-b5ba-4407-89c4-1335475644c5',
          },
        ],
        name: 'Pivot Table Rows is by City',
        fieldKey: '16907aa5-ac04-41e1-8dcf-6d449f4b57d9',
      },
    ],
  },
];
