import React from 'react';
import { Badge, Table } from 'antd';
import { CheckCircleFilled, CloseCircleFilled } from '@ant-design/icons';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

import { truncate } from './rulesetCriterionReportHelper';
import RecommendedGrade from './RecommendedGrade';
import intl from '../../../utils/intl';

const RulesetCriterionReport = ({ reportData, recommendedGrade }) => {
  const checksExpandedRowRender = record => {
    const columns = [
      {
        width: 300,
        dataIndex: 'name',
      },
      {
        width: 120,
        dataIndex: 'status',
        align: 'center',
        render: status =>
          status ? (
            <CheckCircleFilled className="ruleset-criterion-report-success" />
          ) : (
            <CloseCircleFilled className="ruleset-criterion-report-fail" />
          ),
      },
      {
        width: 200,
        dataIndex: 'expected',
        align: 'center',
        render: expected => truncate(expected, 30),
      },
      {
        width: 200,
        dataIndex: 'actual',
        align: 'center',
        render: (actual, rec) => (
          <span className={`ruleset-criterion-report-${rec.status ? 'success' : 'fail'}`}>{truncate(actual, 30)}</span>
        ),
      },
    ];

    return <Table showHeader={false} rowKey="id" columns={columns} dataSource={record.checks} pagination={false} />;
  };

  const getRuleColumns = () => {
    return [
      {
        width: 300,
        title: intl.formatMessage({ id: 'autograder.grading.rule.title' }),
        dataIndex: 'name',
        render: name => <b>{name}</b>,
      },
      {
        width: 120,
        title: intl.formatMessage({ id: 'autograder.grading.rule.result' }),
        dataIndex: 'isPassedChecks',
        align: 'center',
        render: isPassedChecks => (
          <span>
            {isPassedChecks ? (
              <Badge color="#4b9b56" text={intl.formatMessage({ id: 'autograder.grading.passed' })} />
            ) : (
              <Badge color="#ffc34d" text={intl.formatMessage({ id: 'autograder.grading.mistakes' })} />
            )}
          </span>
        ),
      },
      {
        width: 200,
        title: intl.formatMessage({ id: 'autograder.grading.rule.idealAnswer' }),
        align: 'center',
        render: (data, rec) =>
          rec.formula ? `${intl.formatMessage({ id: 'autograder.grading.formula' })} : ${rec.formula}` : '',
      },
      {
        width: 200,
        title: intl.formatMessage({ id: 'autograder.grading.rule.submittedAnswer' }),
        align: 'center',
        render: (data, rec) => `Points: ${rec.points}`,
      },
    ];
  };

  return (
    <div className="ruleset-criterion-report">
      <div className="ruleset-criterion-report-header">
        <FormattedMessage id="autograder.grading.reportHeader" />
      </div>
      <Table
        className="ruleset-criterion-report"
        rowKey="id"
        columns={getRuleColumns()}
        dataSource={reportData}
        expandedRowRender={checksExpandedRowRender}
        pagination={false}
      />
      <RecommendedGrade recommendedGrade={recommendedGrade} />
    </div>
  );
};

RulesetCriterionReport.propTypes = {
  reportData: PropTypes.array,
  recommendedGrade: PropTypes.string,
};

export default RulesetCriterionReport;
