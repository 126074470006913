import React from 'react';
import PropTypes from 'prop-types';

import Tutorial from '../../../api/tutorial';
import pathstreamLogoImg from '../../../styles/v2/images/pathstream-grey-logo.png';
import browserImg from '../../../styles/v2/images/browser.png';
import colabImg from '../../../styles/v2/images/colab.png';
import gsuiteImg from '../../../styles/v2/images/gsuite.png';
import mysqlImg from '../../../styles/v2/images/mysql.png';
import tableauImg from '../../../styles/v2/images/tableau.png';
import codioImg from '../../../styles/v2/images/code-slash-sharp.svg';

const getImage = app => {
  switch (app) {
    case Tutorial.CUSTOM_SITE:
      return browserImg;
    case Tutorial.GOOGLE_COLAB:
      return colabImg;
    case Tutorial.GSUITE:
      return gsuiteImg;
    case Tutorial.MYSQL:
      return mysqlImg;
    case Tutorial.TABLEAU:
      return tableauImg;
    case Tutorial.CODIO:
      return codioImg;
    default:
      return null;
  }
};

export default function LabLoadingIcons({ app }) {
  const labImageSrc = getImage(app);

  return (
    <div className="lab-loading-icons">
      <div className="icon icon--overlap">
        <img alt="pathstream" src={pathstreamLogoImg} />
      </div>
      {labImageSrc && (
        <div className="icon icon--overlap">
          <img alt={app} src={labImageSrc} />
        </div>
      )}
    </div>
  );
}

LabLoadingIcons.propTypes = {
  app: PropTypes.string,
};
