/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
/* global RTE */
import React, { useState, useRef, useEffect, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Line } from 'rc-progress';
import { toast } from 'react-toastify';
import Dropzone from 'react-dropzone';
import { FormattedMessage } from 'react-intl';
import Script from 'react-load-script';
import classnames from 'classnames';
import intl from '../../utils/intl';

const FileInput = forwardRef(({ showInput, accept, onChange }, ref) => {
  // clear previous file on click
  // change event doesn't fire if the same file is selected
  const resetInputValue = e => {
    e.target.value = null;
  };

  return (
    <input
      type="file"
      ref={ref}
      aria-label={intl.formatMessage({ id: 'fileUpload.selectFile' })}
      style={{ display: showInput ? '' : 'none' }}
      accept={accept}
      onChange={onChange}
      onClick={resetInputValue}
    />
  );
});

const FileUpload = ({
  children,
  accept,
  isOpen,
  onOpen,
  onBeforeUpload,
  public: publicProp,
  onSuccess,
  onError,
  onComplete,
  showInput,
  showDropZone,
  s3Prefix,
  getS3Key,
  cropImage,
  successMessage,
  uploadType,
  forwardedFileInputRef,
}) => {
  const [isRTELoaded, setIsRTELoaded] = useState(false);
  const pickerElementRef = useRef();
  const toastIdRef = useRef();
  const xhrRef = useRef();

  useEffect(() => {
    if (isOpen) {
      pickerElementRef.current.click();
      if (onOpen) onOpen();
    }
  }, [isOpen, onOpen]);

  useEffect(() => {
    return () => {
      if (xhrRef.current) {
        xhrRef.current.abort();
      }
      cleanup();
    };
  }, []);

  const updateToast = (options, callback) => {
    setTimeout(() => {
      toast.update(toastIdRef.current, options);
      if (callback) {
        callback();
      }
    }, 25);
  };

  const handleDrop = files => {
    const file = files[0];
    beforeUploadFile(file);
  };

  const beforeUploadFile = file => {
    if (onBeforeUpload) onBeforeUpload(file);
    uploadFile(file);
  };

  const cleanup = () => {
    if (toastIdRef.current) {
      toast.dismiss(toastIdRef.current);
      toastIdRef.current = null;
    }

    if (pickerElementRef.current) {
      pickerElementRef.current.value = '';
    }

    if (onComplete) {
      onComplete();
    }
  };

  const uploadFile = file => {
    if (!file) {
      return;
    }

    if (publicProp) {
      file.public = true;
    }

    if (getS3Key) {
      file.getS3Key = true;
    }

    if (s3Prefix) {
      file.keyPrefix = s3Prefix;
    }

    if (cropImage) {
      file.cropImage = true;
    }

    if (uploadType) {
      file.uploadType = uploadType;
    }

    toastIdRef.current = toast(
      <div>
        <FormattedMessage id="fileUpload.uploading" />
      </div>,
      {
        type: toast.TYPE.INFO,
        autoClose: false,
        closeButton: false,
      },
    );

    RTE.upload(file, (e, xhr) => {
      xhrRef.current = xhr;
      const progress = Math.round((e.loaded / e.total) * 100);
      updateToast({
        render: (
          <div>
            <FormattedMessage id="fileUpload.uploading" /> - {progress}%
            <Line percent={progress} strokeWidth="2" strokeColor="#FF7E3A" />
          </div>
        ),
      });
    })
      .catch(err => {
        xhrRef.current = null;
        // eslint-disable-next-line no-console
        console.error('Error uploading file', err);

        if (err.status > 0) {
          const msg =
            err.status === 413 ? (
              <FormattedMessage id="fileUpload.largeFileError" />
            ) : (
              <FormattedMessage id="fileUpload.error" />
            );

          toast(msg, { autoClose: 5000, type: toast.TYPE.ERROR });
        }

        throw err;
      })
      .then(response => {
        // rte only returns url in response for cropImage
        if (cropImage) {
          return { url: response };
        }

        const {
          url,
          data: {
            fields: { key: s3Key },
          },
        } = response;

        return { url, s3Key };
      })
      .then(result => {
        if (successMessage) {
          toast(successMessage, { type: toast.TYPE.SUCCESS });
        }

        if (onSuccess) {
          onSuccess(result.url, result.s3Key);
        }
      })
      .catch(() => {
        if (onError) {
          onError();
        }
      })
      .then(() => cleanup());
  };

  const fileChanged = e => {
    // start uploading the file
    const file = e.target.files[0];

    beforeUploadFile(file);
  };

  const handleRTEOnLoad = () => {
    setIsRTELoaded(true);
  };

  return (
    <div>
      <Script url="/rte/rte.js" onLoad={handleRTEOnLoad} />

      {isRTELoaded && (
        <>
          {showDropZone && (
            <Dropzone onDrop={handleDrop}>
              {({ getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject }) => (
                <section>
                  <div
                    {...getRootProps({
                      className: classnames(
                        'custom-upload-input',
                        isDragActive && 'active',
                        isDragAccept && 'accept',
                        isDragReject && 'reject',
                      ),
                    })}>
                    <input
                      {...getInputProps({
                        multiple: false,
                        accept: accept,
                        'aria-label': intl.formatMessage({ id: 'fileUpload.selectFile' }),
                        className: 'custom-upload-input__input',
                      })}
                    />
                    {children}
                  </div>
                </section>
              )}
            </Dropzone>
          )}
          <FileInput
            ref={forwardedFileInputRef || pickerElementRef}
            showInput={showInput}
            accept={accept}
            onChange={fileChanged}
          />
        </>
      )}
    </div>
  );
};

FileUpload.propTypes = {
  children: PropTypes.node,
  accept: PropTypes.string,
  isOpen: PropTypes.bool,
  onOpen: PropTypes.func,
  onBeforeUpload: PropTypes.func,
  public: PropTypes.bool,
  onSuccess: PropTypes.func,
  onError: PropTypes.func,
  onComplete: PropTypes.func,
  showInput: PropTypes.bool,
  showDropZone: PropTypes.bool,
  s3Prefix: PropTypes.string,
  getS3Key: PropTypes.bool,
  cropImage: PropTypes.bool,
  successMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.element]),
  uploadType: PropTypes.string,
  forwardedFileInputRef: PropTypes.shape({ current: PropTypes.elementType }),
};

FileUpload.defaultProps = {
  showInput: false,
  showDropZone: false,
  cropImage: false,
  successMessage: <FormattedMessage id="fileUpload.uploaded" />,
};

export default FileUpload;

export const getFileName = url => {
  if (!url) {
    return null;
  }
  const parts = url.split('/');
  return parts[parts.length - 1];
};
