import { map as _map } from 'lodash';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import QuestionResponse from './QuestionResponse';
import { isSubmitted, buildSelectableChoices, organizeUserChoices } from '../../helpers/questionHelper';
import RTEContent from '../../helpers/contentMathjaxHelper';

const QuestionSingleSelect = ({
  question,
  questionProgress,
  isLastQuestion,
  submitChoices,
  renderQuestionsNavigation,
}) => {
  const [userChoiceIndex, setUserChoiceIndex] = useState(null);
  const [userChoices] = useState(() => {
    const choices = _map(question.choices, (choice, index) => ({
      ...choice,
      original_index: index,
    }));

    return organizeUserChoices(question, choices);
  });

  useEffect(() => {
    if (isSubmitted(questionProgress)) {
      // clear our answer if retrying question
      const selected = questionProgress.choices.find(choice => choice.is_selected);
      if (selected && questionProgress.completed_at) {
        setUserChoiceIndex(findChoiceIndex(selected));
      }
    }
  }, []);

  const findChoiceIndex = lookingChoice => question.choices.findIndex(choice => choice.id === lookingChoice.id);

  const isCurrentQuestionAnswered = () => userChoiceIndex !== null;

  const handleAnswerQuestion = () => {
    if (!isCurrentQuestionAnswered()) {
      return;
    }

    const selectedChoices = buildSelectableChoices(question.choices);
    selectedChoices[userChoiceIndex].is_selected = true;

    submitChoices(selectedChoices);
  };

  const handleSelectChoice = index => {
    setUserChoiceIndex(index);
  };

  const isChecked = index => userChoiceIndex === index;

  return (
    <>
      <div className="content" style={{ paddingBottom: '0 !important' }}>
        <div className="questions" role="radiogroup" aria-live="polite">
          {_map(userChoices, (choice, index) => {
            const isActive = isChecked(choice.original_index);

            const labelClass = classnames({
              'option-label-graded': true,
              'option-label-graded__active': isActive,
            });

            return (
              <div key={choice.original_index} className="question">
                <input
                  id={`questions-${question.id}-${index}-multiple`}
                  name={`question-${question.id}`}
                  type="radio"
                  checked={isChecked(choice.original_index)}
                  onChange={() => handleSelectChoice(choice.original_index)}
                />
                <div className="option-label-wrapper">
                  <RTEContent
                    content={choice.prompt}
                    Tag="label"
                    classSelector={labelClass}
                    htmlForVar={`questions-${question.id}-${index}-multiple`}
                  />
                </div>
              </div>
            );
          })}
        </div>

        <QuestionResponse
          handleAnswerQuestion={handleAnswerQuestion}
          isCurrentQuestionAnswered={isCurrentQuestionAnswered}
          renderQuestionsNavigation={renderQuestionsNavigation}
          isLastQuestion={isLastQuestion}
        />
      </div>
    </>
  );
};

QuestionSingleSelect.propTypes = {
  question: PropTypes.object.isRequired,
  questionProgress: PropTypes.object,
  submitChoices: PropTypes.func.isRequired,
  isLastQuestion: PropTypes.bool,
};

export default QuestionSingleSelect;
