import io from 'socket.io-client';
import { getTokens, areTokensValid } from '../helpers/userAuth';
import { listenForEvents } from './events';
import './entities';
import intervals from './intervals';

let socket, disconnected;

const CLEAR_INTERVALS_TIMEOUT = 10 * 1000; // time to wait before clearing intervals

/* eslint-disable no-console */
const handleConnect = () => {
  if (!areTokensValid()) {
    console.warn('Token not valid. Not connecting to socket');
    return;
  }
  const { accessToken } = getTokens();

  emit('authenticate', { token: accessToken });
};

export const connect = () => {
  if (socket && socket.connected) {
    socket.disconnect();
  }
  clearAllIntervals();

  console.log('Connecting to Socket.IO');
  socket = io.connect('/', {
    path: '/sockets/ws',
  });
  socket
    .on('connect', handleConnect)
    .on('authenticated', msg => {
      console.log('Socket connected and authenticated', msg);
      listenForEvents(socket);
      disconnected = false;
    })
    .on('unauthorized', function (msg) {
      console.error(`Socket connection not authorized: ${JSON.stringify(msg.data)}`);
      socket.disconnect();
    })
    .on('disconnect', err => {
      console.log('disconnected', err);
      // To account for intermittent network issues, wait some time and check if still
      //   disconnected before clearing intervals
      disconnected = true;
      setTimeout(() => {
        if (disconnected) clearAllIntervals();
      }, CLEAR_INTERVALS_TIMEOUT);
    })
    .on('error', err => {
      console.log('error', err);
    });
};

export const disconnect = () => {
  if (!socket || disconnected) return;

  socket.disconnect();
  socket = null;
};

export const emit = (event, message) => {
  if (!socket || disconnected) return;

  socket.emit(event, message);
};

export const addInterval = (type, callback, milliseconds, args) => {
  intervals.set(type, callback, milliseconds, args);
};

export const clearIntervals = type => {
  intervals.clear(type);
};

export const clearAllIntervals = () => {
  intervals.clearAll();
};

/* eslint-enable no-console */
