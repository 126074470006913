import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import NotFound from '../NotFound';
import { enableEditMode } from '../../actions/contextActions';
import CohortReportsContainer from './CohortReportsContainer';

class Coach extends Component {
  render() {
    const { match } = this.props;

    return (
      <Switch>
        <Route path={`${match.path}/cohort/:cohortId?`} component={CohortReportsContainer} />
        <Route component={NotFound} />
      </Switch>
    );
  }

  componentDidMount() {
    this.props.enableEditMode(false);
  }
}

export default connect(null, { enableEditMode })(Coach);
