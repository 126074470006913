import { find as _find } from 'lodash';
import { connect } from 'react-redux';
import { selectTutorial } from '../../../selectors/tutorialSelectors';
import { selectTutorialSteps } from '../../../selectors/stepSelectors';
import TutorialShow from './TutorialShow';
import { fetchTutorial, saveTutorial } from '../../../actions/tutorialActions';
import { saveStep } from '../../../actions/stepActions';
import { fetchLesson } from '../../../actions/lessonActions';
import { selectComponent } from '../../../selectors/componentSelectors';
import { selectLesson, selectLessonBlocks } from '../../../selectors/lessonSelectors';
import { selectIsEditMode } from '../../../selectors/contextSelectors';
import { fetchBootcamp } from '../../../actions/bootcampActions';
import { selectCurrentUrlObject } from '../../../selectors/urlSelectors';
import { selectBootcamp } from '../../../selectors/bootcampSelectors';
import { selectSection } from '../../../selectors/sectionSelectors';
import { selectUnit } from '../../../selectors/unitSelectors';
import { getFolderPath } from '../../../helpers/labHelper';
import { selectCurrentUser } from '../../../selectors/authSelectors';

const mapStateToProps = (state, ownProps) => {
  const { match } = ownProps;
  const { bootcampId, pathId, sectionId, unitId, componentId, blockId } = ownProps.match.params;
  const component = selectComponent(state, componentId);
  const lessonId = component && component.external_id;
  const lesson = selectLesson(state, lessonId);
  const blocks = selectLessonBlocks(state, lesson && lesson.id);
  const block = _find(blocks, { id: blockId });
  const tutorialId = block && block.external_id;
  const tutorial = selectTutorial(state, tutorialId);
  const steps = selectTutorialSteps(state, tutorialId);
  const isEditMode = selectIsEditMode(state);
  const lessonUrl = match.url.substring(0, match.url.indexOf('/blocks/'));
  const currentUser = selectCurrentUser(state);
  const lessonUrlObject = selectCurrentUrlObject(state, {
    bootcampId,
    pathId,
    sectionId,
    unitId,
    currentUrl: lessonUrl,
    currentUser,
    blockId,
  });
  const bootcamp = selectBootcamp(state, bootcampId);
  const section = selectSection(state, sectionId);
  const unit = selectUnit(state, unitId);
  const projectFolderPath = getFolderPath({ preview: true, bootcamp, user: selectCurrentUser(state) });

  return {
    bootcamp,
    section,
    unit,
    bootcampId,
    lesson,
    lessonId,
    tutorial,
    tutorialId,
    component,
    block,
    steps,
    isEditMode,
    lessonUrlObject,
    projectFolderPath,
  };
};

export default connect(mapStateToProps, {
  fetchTutorial,
  saveTutorial,
  fetchLesson,
  saveStep,
  fetchBootcamp,
})(TutorialShow);
