import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Button, Divider, Input, Switch } from 'antd';
import { v4 as uuidv4 } from 'uuid';
import { FormattedMessage } from 'react-intl';
import classnames from 'classnames';
import { useDispatch } from 'react-redux';

import AdminWrapper from '../../../components/v2/Nav/Admin/AdminWrapper';
import intl from '../../../utils/intl';
import { useAutograderDispatch } from '../../api/store';
import { getPath, getGraderPath } from '../../utils/paths';
import { toastError, toastSuccess } from '../../../utils/toastHelper';
import { TOAST_AUTO_CLOSE_MS } from '../../../common/constants';
import { destroyAutograderRuleset, saveAutograderRuleset } from '../../../actions/autograderRulesetActions';
import { useAutograderRulesets } from '../../hooks/useAutograderRuleset';
import { fetchCohort } from '../../../actions/cohortActions';
import { cohortIds } from '../../utils/testCohorts';
import fbProjectPartA from '../../api/mock/assessment/fbProjectPartA';
import dataC1ProjectPart2 from '../../api/mock/assessment/dataC1ProjectPart2';
import { AutograderLevel } from '../../utils/constants/AutograderEnums';
import { allWrongMockData } from '../../api/mock/assessment/fbAllWrong';

const CreateRulesets = () => {
  const autograderRulesets = useAutograderRulesets();
  const autograderDispatch = useAutograderDispatch();
  const dispatch = useDispatch();

  const [programType] = useState('googleSheets');
  const [name, setName] = useState('');
  const [isEditMode, setIsEditMode] = useState(true);
  const [mockRuleset, setMockRuleset] = useState();
  const [cohorts, setCohorts] = useState();

  const history = useHistory();
  const { assignmentId } = useParams();

  useEffect(() => {
    const getCohorts = async () => {
      const cohortData = cohortIds.map(id => {
        return fetchCohort(id)(dispatch);
      });
      const results = await Promise.all(cohortData);
      setCohorts(results);
    };

    if (!cohorts) {
      getCohorts();
    }
  }, [cohorts, dispatch]);

  const handleCreate = async () => {
    // TODO change fieldKey to id ?
    const newRuleset = {
      group: [],
      type: AutograderLevel.RULESET,
      fieldKey: uuidv4(),
      name,
      assignmentId,
    };
    if (mockRuleset) {
      newRuleset.group = mockRuleset.group;
    }
    try {
      const createdRuleset = await saveAutograderRuleset(assignmentId, newRuleset)(autograderDispatch);
      history.push(getPath(createdRuleset.id));
      toastSuccess(intl.formatMessage({ id: 'autograder.createSuccess' }, { autoClose: TOAST_AUTO_CLOSE_MS }));
    } catch (e) {
      // TODO return proper error from backend, now status 500
      toastError(intl.formatMessage({ id: 'autograder.createError' }, { autoClose: TOAST_AUTO_CLOSE_MS }));
    }
  };

  const handleNameChange = e => setName(e.target.value);

  const handleCreateMock = e => {
    const name = e.target.value;
    if (mockRuleset?.name === name) {
      setMockRuleset();
      setName('');
      return;
    }
    let group;

    if (name === fbProjectPartA[0].name) {
      group = fbProjectPartA[0].assessment[0].group;
    } else if (name === allWrongMockData.name) {
      group = allWrongMockData.group;
    } else {
      group = [];
      dataC1ProjectPart2[0].assessment.forEach(assessment => group.push(...assessment.group));
    }
    setMockRuleset({ name, group });
    setName(name);
    toastSuccess(`${name} mock data set`, { autoClose: TOAST_AUTO_CLOSE_MS });
  };

  const handleEdit = rulesetId => {
    history.push(getPath(rulesetId));
  };

  const handleRemove = async rulesetId => {
    // TODO do we handle errors?
    // eslint-disable-next-line no-console
    await destroyAutograderRuleset(rulesetId)(autograderDispatch).catch(errorMessage => console.trace(errorMessage));
    setIsEditMode(true);
    toastSuccess(intl.formatMessage({ id: 'autograder.removeSuccess' }, { autoClose: TOAST_AUTO_CLOSE_MS }));
  };

  const handleGradeSubs = cohortId => {
    history.push(getGraderPath(cohortId));
  };

  const CreateRuleset = () => {
    return (
      <>
        <Divider className="action-divider logo-color" orientation="left">
          <FormattedMessage id="autograder.action.createRuleset" />
        </Divider>
        <div className="ruleset-name-title">
          {intl.formatMessage({ id: 'autograder.label.nameRuleset' }).toUpperCase()}:
        </div>
        <Input
          className="name-input"
          aria-label="ruleset-name"
          placeholder={intl.formatMessage({ id: 'autograder.label.rulesetPlaceholder' })}
          value={name}
          onChange={handleNameChange}
        />
        <Button className="create-ruleset-btn" type="primary" disabled={!name} onClick={handleCreate}>
          <FormattedMessage id="autograder.action.createRuleset" />
        </Button>
        <div className="action-separator">
          Mock ruleset:
          <br />
          <label>{fbProjectPartA[0].name}</label>
          <input
            type="checkbox"
            value={fbProjectPartA[0].name}
            checked={mockRuleset?.name === fbProjectPartA[0].name}
            onChange={handleCreateMock}
          />
          <br />
          <label>{dataC1ProjectPart2[0].name}</label>
          <input
            type="checkbox"
            value={dataC1ProjectPart2[0].name}
            checked={mockRuleset?.name === dataC1ProjectPart2[0].name}
            onChange={handleCreateMock}
          />
          <br />
          <label>{allWrongMockData.name}</label>
          <input
            type="checkbox"
            value={allWrongMockData.name}
            checked={mockRuleset?.name === allWrongMockData.name}
            onChange={handleCreateMock}
          />
        </div>
      </>
    );
  };

  const UpdateRuleset = () => {
    const rulesets = Object.keys(autograderRulesets).map(key => autograderRulesets[key]);
    return (
      <div className="action-separator">
        <Divider className="action-divider logo-color" orientation="left">
          <FormattedMessage id="autograder.action.updateRuleset" />
        </Divider>
        <Switch
          checkedChildren="Edit"
          unCheckedChildren="Remove"
          checked={isEditMode}
          onChange={checked => setIsEditMode(checked)}
        />
        <div className="ruleset-list" data-testid="ruleset-list">
          {rulesets?.map(({ id, title }) => (
            <Button
              key={id}
              className={classnames('edit-ruleset-btn', { remove: !isEditMode })}
              type="primary"
              ghost
              onClick={() => (isEditMode ? handleEdit(id) : handleRemove(id))}>
              {title}
            </Button>
          ))}
        </div>
      </div>
    );
  };

  const GradePastSubmissions = () => {
    return (
      <div className="action-separator">
        <Divider className="action-divider logo-color" orientation="left">
          <FormattedMessage id="autograder.action.gradePastSubs" />
        </Divider>
        <div className="grade-by-cohorts">
          {cohorts?.map(({ id, location }) => (
            <Button key={id} className="grade-by-cohort-btn" type="primary" ghost onClick={() => handleGradeSubs(id)}>
              {location}
            </Button>
          ))}
        </div>
      </div>
    );
  };

  return (
    <AdminWrapper
      headerTitle={intl.formatMessage({ id: 'autograder.header.createRuleset' }, { projectType: programType })}>
      <div className="autograder-create-rulesets">
        {assignmentId && CreateRuleset()}
        {<UpdateRuleset />}
        {<GradePastSubmissions />}
      </div>
    </AdminWrapper>
  );
};
export default CreateRulesets;
