export default class Tenant {
  static ID = 'dced3cc0-6226-449e-a841-2312378b1b73';

  static current() {
    return {
      id: Tenant.ID,
      title: 'Unity',
      hostname: 'app.pathstream.com',
      name: 'Unity',
      logo_url: null,
      styles: null,
      created_at: '2018-08-01T18:48:16.007Z',
      updated_at: '2019-01-24T17:57:18.151Z',
    };
  }
}
