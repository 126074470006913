export const A_CHAR_CODE = 97;

export const getColAlphaToIndex = colAlpha => {
  if (!colAlpha || !/[a-z]/i.test(colAlpha)) return null;

  const mapper = {
    A: 0,
    B: 1,
    C: 2,
    D: 3,
    E: 4,
    F: 5,
    G: 6,
    H: 7,
    I: 8,
    J: 9,
    K: 10,
    L: 11,
    M: 12,
    N: 13,
    O: 14,
    P: 15,
    Q: 16,
    R: 17,
    S: 18,
    T: 19,
    U: 20,
    V: 21,
    W: 22,
    X: 23,
    Y: 24,
    Z: 25,
  };

  const normalizedAlpha = colAlpha.toUpperCase();
  return mapper[normalizedAlpha];
};

export const getColIndexToAlpha = num => {
  if (num !== null && num >= 0 && num <= 25) {
    return String.fromCharCode(A_CHAR_CODE + num);
  }
  return null;
};

export const getRowColFromRange = range => {
  if (!range) return null;
  const cellRangeRegEx = /([A-Za-z]+)(\d+):([A-Za-z]+)(\d+)$/;
  const matchedCoords = range.match(cellRangeRegEx);

  if (matchedCoords && matchedCoords.length) {
    const [, minCol, minRow, maxCol, maxRow] = matchedCoords;
    return {
      minRow: parseInt(minRow, 10) - 1,
      minCol: getColAlphaToIndex(minCol),
      maxRow: parseInt(maxRow, 10) - 1,
      maxCol: getColAlphaToIndex(maxCol),
    };
  }
  return null;
};

export const getRowColFromAddy = address => {
  if (!address) return null;

  const cellAddressRegEx = /^([A-Za-z]):?(\d+)$/;
  const matchedCoords = address.match(cellAddressRegEx);

  if (matchedCoords && matchedCoords.length) {
    const [, col, row] = matchedCoords;
    return {
      col: getColAlphaToIndex(col),
      row: parseInt(row, 10) - 1,
    };
  }
  return null;
};

export const getAddyFromRowCol = (row, col) => {
  if (!(row >= 0) || row === null) return null;
  const cellColumn = getColIndexToAlpha(col);
  if (!cellColumn) return null;

  return `${cellColumn.toUpperCase()}${row + 1}`;
};

export const getLinearScoreRanges = () => {
  return [
    {
      score: '1/10',
      maxRange: 10,
      rangeUnit: 'percent',
    },
    {
      score: '2/10',
      maxRange: 20,
      rangeUnit: 'percent',
    },
    {
      score: '3/10',
      maxRange: 30,
      rangeUnit: 'percent',
    },
    {
      score: '4/10',
      maxRange: 40,
      rangeUnit: 'percent',
    },
    {
      score: '5/10',
      maxRange: 50,
      rangeUnit: 'percent',
    },
    {
      score: '6/10',
      maxRange: 60,
      rangeUnit: 'percent',
    },
    {
      score: '7/10',
      maxRange: 70,
      rangeUnit: 'percent',
    },
    {
      score: '8/10',
      maxRange: 80,
      rangeUnit: 'percent',
    },
    {
      score: '9/10',
      maxRange: 90,
      rangeUnit: 'percent',
    },
    {
      score: '10/10',
      maxRange: 100,
      rangeUnit: 'percent',
    },
  ];
};

export const getWorkbookId = url => {
  const matches = /\/([\w-_]{15,})\/(.*?gid=(\d+))?/.exec(url);
  return matches && matches[1];
};

export const compareCaseInsensitive = (str1, str2) => str1.toUpperCase() === str2.toUpperCase();

export const forceStringUpperCase = value => (typeof value === 'string' ? value.toUpperCase() : value);

export const removeWhitespace = value => value.split(' ').join('');

export const stripEmptyStringQuotes = val => {
  const regExStringQuotes = /^['"](?=['"])['"]$/g;
  return val.replace(regExStringQuotes, '');
};
