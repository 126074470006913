import { map as _map } from 'lodash';
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import SectionTileContainer from '../../Sections/SectionTileContainer';
import PathTableOfContents from '../PathTableOfContents/PathTableOfContents';
import { FormattedMessage } from 'react-intl';
import { canAdministrate } from '../../../helpers/userAuth';
import showModal from '../../../utils/showModal';
import EditSectionModal from '../../Author/Paths/EditSectionModal';
import LtiConfigDialogContainer from '../../Author/Lti/LtiConfigDialogContainer';
import ConfigureModalsModalContainer from '../../Author/Bootcamps/ConfigureModalsModalContainer';
import EnrollAuthorContainer from '../../Bootcamp/EnrollAuthorContainer';
import { fetchBootcampInstanceAssignments } from '../../../actions/bootcampInstanceAssignmentActions';
import { fetchBootcampInstanceQuizzes } from '../../../actions/bootcampInstanceQuizActions';
import { useDispatch } from 'react-redux';

const EditButtons = ({ currentUser, editSection, bootcamp }) => {
  const handleShowCreateSectionDialog = () => editSection({});

  const handleViewLtiParams = () => {
    showModal(LtiConfigDialogContainer, {
      bootcamp,
    });
  };

  const handleConfigureModals = () => {
    showModal(ConfigureModalsModalContainer, {
      bootcamp,
    });
  };

  const handleAuthorEnrollment = mode => {
    return () => {
      showModal(EnrollAuthorContainer, {
        bootcamp,
        mode,
      });
    };
  };

  return (
    <>
      <div className="horizontal-space-between">
        <button
          className="button-standard button-standard--grey button-standard--short"
          onClick={handleShowCreateSectionDialog}>
          <FormattedMessage id="path.addSection" />
        </button>

        <button className="button-standard button-standard--grey button-standard--short" onClick={handleViewLtiParams}>
          <FormattedMessage id="lti.config.viewParams" />
        </button>

        <button
          className="button-standard button-standard--grey button-standard--short"
          onClick={handleConfigureModals}>
          <FormattedMessage id="bootcamp.modals.button" />
        </button>
      </div>
      {canAdministrate(currentUser) && (
        <div className="horizontal-space-between" style={{ marginTop: 10 }}>
          <button
            className="button-standard button-standard--grey button-standard--short"
            onClick={handleAuthorEnrollment('enroll')}>
            <FormattedMessage id="bootcamp.enrollAuthors" />
          </button>
          <button
            className="button-standard button-standard--grey button-standard--short"
            onClick={handleAuthorEnrollment('unenroll')}>
            <FormattedMessage id="bootcamp.unenrollAuthors" />
          </button>
        </div>
      )}
    </>
  );
};

const CourseOverview = ({
  isEditMode,
  currentUser,
  path,
  saveSection,
  bootcamp,
  sections,
  currentSectionProgress,
  reorderSections,
  sectionProgresses,
  showAsanaButton,
  cohortId,
}) => {
  const dispatch = useDispatch();
  const editSection = section => {
    showModal(EditSectionModal, {
      path: path,
      section: section || {},
      onSave: saveSection,
    });
  };

  const handleReorder = (oldPosition, newPosition) => {
    const sectionIds = _map(sections, 'id');
    const removed = sectionIds.splice(oldPosition, 1);
    sectionIds.splice(newPosition, 0, removed[0]);

    reorderSections(sectionIds, path);
  };

  const sectionProps = section => {
    return {
      bootcamp,
      path,
      pathId: path.id,
      section,
      isEditMode,
      editSection,
      currentSectionProgress,
      onReorder: handleReorder,
    };
  };

  useEffect(() => {
    if (cohortId) {
      fetchBootcampInstanceAssignments(cohortId)(dispatch);
      fetchBootcampInstanceQuizzes(cohortId)(dispatch);
    }
  }, [cohortId, dispatch]);

  return (
    <div className="course-overview-page-content-wrapper">
      <div className="grid-row">
        <div className="md-7 md-offset-1">
          {isEditMode && <EditButtons currentUser={currentUser} editSection={editSection} bootcamp={bootcamp} />}

          {_map(sections, (section, index) => (
            <SectionTileContainer
              key={section.id}
              index={index}
              totalSections={sections.length}
              {...sectionProps(section)}
            />
          ))}
        </div>

        <PathTableOfContents
          sections={sections}
          sectionProgresses={sectionProgresses}
          showAsanaButton={showAsanaButton}
        />
      </div>
    </div>
  );
};
CourseOverview.propTypes = {
  isEditMode: PropTypes.bool,
  currentUser: PropTypes.object.isRequired,
  path: PropTypes.object.isRequired,
  saveSection: PropTypes.func.isRequired,
  bootcamp: PropTypes.object.isRequired,
  sections: PropTypes.array.isRequired,
  currentSectionProgress: PropTypes.object,
  reorderSections: PropTypes.func.isRequired,
  sectionProgresses: PropTypes.array,
  showAsanaButton: PropTypes.bool,
};

export default CourseOverview;
