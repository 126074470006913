import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { isEmpty as _isEmpty } from 'lodash';
import StudentCourseOverview from './StudentCourseOverview';

import { selectEnrollmentForBootcamp } from '../../../selectors/enrollmentSelectors';

import { fetchBootcampInstanceAssignments } from '../../../actions/bootcampInstanceAssignmentActions';
import { fetchBootcampInstanceQuizzes } from '../../../actions/bootcampInstanceQuizActions';
import { fetchBootcampInstanceSections } from '../../../actions/bootcampInstanceSectionActions';
import { selectEnrolledBootcamps, selectEnrollments } from '../../../selectors/enrollmentSelectors';
import { selectCohorts } from '../../../selectors/cohortSelectors';
import { getPathPrefix } from '../../../helpers/pathHelper';

const mapDispatchToProps = dispatch => {
  return {
    fetchAssignments: cohortId => dispatch(fetchBootcampInstanceAssignments(cohortId)),
    fetchQuizzes: cohortId => dispatch(fetchBootcampInstanceQuizzes(cohortId)),
    fetchBootcampInstanceSections: cohortId => dispatch(fetchBootcampInstanceSections(cohortId)),
  };
};

const mapStateToProps = (state, ownProps) => {
  const { bootcamp, currentUser } = ownProps;
  const pathPrefix = getPathPrefix(currentUser);
  const enrollment = selectEnrollmentForBootcamp(state, bootcamp.id);
  const enrollments = selectEnrollments(state);
  const bootcamps = selectEnrolledBootcamps(state);
  const cohorts = selectCohorts(state);

  const { assignment_quiz_progress_calculation: as_percent, lesson_progress_calculation: ls_percent } = enrollment;
  const progresses = [
    { title: 'Lesson completion', percent: ls_percent * 100 },
    { title: 'Assignment completion', percent: as_percent * 100 },
  ];

  return {
    progresses,
    currentUser,
    enrollment,
    enrollments,
    bootcamps,
    cohorts,
    pathPrefix,
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(StudentCourseOverview));
