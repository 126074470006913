import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { selectAppStreamAppSites } from '../selectors/appSiteSelectors';
import { fetchAppStreamAppSites } from '../actions/appSiteActions';
import Tutorial from '../api/tutorial';

export default function useCustomSiteLabs() {
  const appStreamAppSites = useSelector(selectAppStreamAppSites);
  const dispatch = useDispatch();
  const [appStreamAppSitesLoaded, setAppStreamAppSitesLoaded] = useState(false);
  const [appStreamAppSitesFetched, setAppStreamAppSitesFetched] = useState(false);

  useEffect(() => {
    if (appStreamAppSites?.length > 0) {
      setAppStreamAppSitesLoaded(true);
      return;
    }

    if (!appStreamAppSitesFetched) {
      dispatch(fetchAppStreamAppSites());
      setAppStreamAppSitesFetched(true);
    } else {
      setAppStreamAppSitesLoaded(true);
    }
  }, [appStreamAppSites]);

  const shouldUseAppStreamFor = labOrUnit => {
    if (!labOrUnit) {
      return false;
    }

    const isCustomSiteLab = labOrUnit.app === Tutorial.CUSTOM_SITE && !!labOrUnit.app_site_id;

    return isCustomSiteLab || (labOrUnit.title && labOrUnit.title.indexOf('app-stream') > -1);
  };

  return {
    appStreamAppSitesLoaded,
    appStreamAppSites,
    shouldUseAppStreamFor,
  };
}
