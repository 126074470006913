import PropTypes from 'prop-types';
import React from 'react';

import StudentAssignments from './StudentAssignments/StudentAssignments';
import { useSelector } from 'react-redux';
import { selectCohort } from '../../../../selectors/cohortSelectors';
import { selectProjectsForBootcamp } from '../../../../selectors/unitSelectors';
import Loading from '../../../Utils/Loading';
import StudentAnalytics from './StudentAnalytics';
import { map as _map } from 'lodash';

const StudentContent = ({ cohortId, student, cohortReportStudent }) => {
  const cohort = useSelector(state => selectCohort(state, cohortId));
  const cohortUnits = useSelector(state => selectProjectsForBootcamp(state, cohort?.bootcamp_id));

  const hiddenUnits = _map(cohort && cohort.hidden_items, item => item.split('::')[1]);
  const units = cohortUnits && cohortUnits.filter(unit => !hiddenUnits.includes(unit.id));

  if (!units) {
    return <Loading />;
  }
  return (
    <div className="students-page-selected-student-content">
      <StudentAnalytics student={student} cohortId={cohortId} cohortReportStudent={cohortReportStudent} />
      <StudentAssignments student={student} units={units} />
    </div>
  );
};

StudentContent.propTypes = {
  cohortId: PropTypes.string.isRequired,
  student: PropTypes.object.isRequired,
  cohortReportStudent: PropTypes.object.isRequired,
};

export default StudentContent;
