import { selectEntities, select } from './applicationSelectors';

export const selectQuizEntities = selectEntities('quizzes');
export const selectQuiz = select(selectQuizEntities);

export const selectQuizByBlock = (state, block) => selectQuiz(state, block.external_id);
export const selectQuizByQuestion = (state, question) => selectQuiz(state, question.quiz_id);

// No state, only relationships

export const selectQuizIdByBlock = block => block.external_id;
