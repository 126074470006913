import React from 'react';
import PropTypes from 'prop-types';
import Icons from '../Utils/Icons';
import { formatDuration, getProgressStatus } from '../../utils/utils';
import { Link } from 'react-router-dom';

const StudentComponentItem = ({ component, hasLab, match, section, unit, componentProgress }) => {
  const completed = componentProgress ? getProgressStatus(componentProgress).isComplete : false;
  const iconClasses = completed
    ? 'icon-element--fill-green-800 icon-element--background-green-100-opaque'
    : 'icon-element--fill-grey-600 icon-element--background-main-blue-100-opaque';

  const visitLink = `${match.url}/sections/${section.id}/units/${unit.id}/components/${component.id}`;

  return (
    <Link
      className={`student-component-item ${completed ? 'complete' : ''}`}
      to={visitLink}
      data-testid={`student-component-item-${component.id}`}>
      <div className="component-wrapper">
        <div className="component-title">&bull;{component.title}</div>
        {hasLab && (
          <div className={`component-lab icon-element ${iconClasses}  icon-element--size-standard`}>
            <Icons.Elixir />
          </div>
        )}
        <div className="component-duration">{formatDuration(component.duration)}</div>
      </div>
    </Link>
  );
};

StudentComponentItem.propTypes = {
  component: PropTypes.object.isRequired,
};

export default StudentComponentItem;
