import { COMPONENT_GRADE } from '../constants';

export const setCurrentCohortId = currentCohortId => ({
  type: COMPONENT_GRADE.SET_CURRENT_COHORT_ID,
  payload: {
    currentCohortId,
  },
});

export const setCurrentStudentId = currentStudentId => ({
  type: COMPONENT_GRADE.SET_CURRENT_STUDENT_ID,
  payload: {
    currentStudentId,
  },
});
