import React, { useState } from 'react';
import classnames from 'classnames';
import intl from '../../../utils/intl';
import Icons from '../../Utils/Icons';

const SMALL_SIDEBAR = '300px';
const LARGE_SIDEBAR = '400px';

const LabSidebarToggle = () => {
  const [isExpand, setIsExpand] = useState(false);

  const handleCollapseToggle = () => {
    setIsExpand(!isExpand);

    const ss = document.getElementsByClassName('new-lab-page__sidebar')[0];
    if (ss.style.width === LARGE_SIDEBAR) {
      ss.style.width = SMALL_SIDEBAR;
    } else {
      ss.style.width = LARGE_SIDEBAR;
    }
  };

  return (
    <div className={classnames('lab-sidebar-toggle', isExpand && 'collapse', !isExpand && 'expand')}>
      <button
        type="button"
        className="lab-sidebar-collapse"
        aria-label={intl.formatMessage({ id: 'common.sidebarCollapse' })}
        onClick={handleCollapseToggle}>
        {isExpand ? <Icons.LabCollapseSidebar /> : <Icons.LabExpandSidebar />}
      </button>
    </div>
  );
};

export default LabSidebarToggle;
