/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';
import classnames from 'classnames';
import { Tooltip } from 'antd';
import { confirmInput } from '../../../utils/confirmInput';
import Icons from '../../Utils/Icons';
import intl from '../../../utils/intl';

const GradeRow = props => {
  const { completed_at, unit_progress_id, unit_title, graded_at, requires_grading, template_type, score, total_gradable_points } = props;

  const handleResubmissionClick = async () => {
    confirmInput({
      message: <FormattedMessage id="grading.confirmResubmission" />,
      onOk: () => {
        const { onAllowResubmission, ...rest } = props;
        onAllowResubmission(rest);
      },
    });
  };

  const scored = requires_grading && !!graded_at;

  const submittedAt = moment(completed_at).format('h:mm a, DD MMM');
  const gradedAt = graded_at && moment(graded_at).format('h:mm a, DD MMM');
  const showGrading = template_type === 'assignment';
  const showQuizScore = template_type === 'quiz';
  const calculatedScore = Math.round(score / total_gradable_points * 100);

  return (
    <div
      className={classnames('graded-assignment-item', scored && 'graded-assignment-item--graded')}
      data-id={unit_progress_id}>
      <div className={classnames('graded-assignment-item__icon', scored && 'graded-assignment-item__icon--graded')}>
        <Icons.Graded />
      </div>

      <div className="graded-assignment-item__content-wrapper">
        <div className="graded-assignment-item__content">
          <span className="graded-assignment-item__title">{unit_title}</span>
          {showQuizScore && (
            <span className="graded-assignment-item__score">
              <FormattedMessage id="grading.grade" />: {score}/{total_gradable_points} ({calculatedScore}%)
            </span>
          )}
          {scored && (
            <span className="graded-assignment-item__date">
              <FormattedMessage id="grading.gradedAt" />
              :&nbsp;
              {gradedAt}
            </span>
          )}
          <span className="graded-assignment-item__date">
            <FormattedMessage id="grading.submitted" />
            :&nbsp;
            {submittedAt}
          </span>
        </div>

        <div className="graded-assignment-item__buttons">
          <button
            type="button"
            className="graded-assignment-item__button-item graded-assignment-item__button-item--clear"
            onClick={handleResubmissionClick}>
            <span dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'grading.allowNewSubmission' }) }} />
          </button>
          {showGrading && (
            <Tooltip title={!requires_grading && intl.formatMessage({ id: 'grading.viewTooltip' })}>
              <Link
                to={`/coach/grade/${unit_progress_id}?isGrading=true`}
                className={classnames(
                  'graded-assignment-item__button-item',
                  !graded_at && 'graded-assignment-item__button-item--accent',
                )}>
                {(!requires_grading && <FormattedMessage id="grading.view" />) ||
                  (scored ? <FormattedMessage id="grading.regrade" /> : <FormattedMessage id="grading.grade" />)}
              </Link>
            </Tooltip>
          )}
        </div>
      </div>
    </div>
  );
};

GradeRow.propTypes = {
  completed_at: PropTypes.string.isRequired,
  unit_progress_id: PropTypes.string.isRequired,
  assignment_progress_id: PropTypes.string,
  quiz_progress_id: PropTypes.string,
  unit_title: PropTypes.string.isRequired,
  graded_at: PropTypes.string,
  score: PropTypes.number,
  onAllowResubmission: PropTypes.func.isRequired,
  requires_grading: PropTypes.bool,
};

export default GradeRow;
