import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import ProjectInstructions from '../../Projects/ProjectInstructions';
import Loading from '../../Utils/Loading';
import ContentWrapperContainer from '../../v2/Nav/ContentWrapperContainer';
import LabInstructions from '../../Student/LabInstructions';
import LtiConfigDialogContainer from '../Lti/LtiConfigDialogContainer';
import showModal from '../../../utils/showModal';
import * as analytics from '../../../utils/analytics';
import { setPageTitle } from '../../../helpers/pageHelper';
import QuizOnLab from '../../Quiz/QuizOnLab';
import EditQuiz from '../Quizzes/EditQuiz';
import StudentBlockQuizShow from '../../Student/BlockQuizShow';
import { LabAreaIntro, DismissIntro } from '../../Labs/LabIntro';
import LabWrapper from '../../v2/Nav/LabWrapper';
import { ProjectDataProvider } from '../../../context/Project/ProjectContext';
import ViewingAsStudentHeader from '../../Coach/ViewingAsStudentHeader';
import Project from '../../Labs/Project';
import LabToolbar from '../../Labs/LabToolbar';

export default class ProjectShow extends Component {
  constructor(props) {
    super(props);

    this.promises = {};
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.assignmentId !== nextProps.assignmentId) {
      this.promises = {};
      this.trackPageEnd();
      this.fetchData(nextProps);
      analytics.pageStart('Edit Project');
    }
  }

  componentDidMount() {
    const { unit, bootcampId } = this.props;

    if (!unit) {
      this.props.fetchBootcamp(bootcampId).then(() => {
        this.fetchData(this.props);
      });
    } else {
      this.fetchData(this.props);
    }
    analytics.pageStart('Edit Project');
  }

  componentDidUpdate() {
    const { bootcamp, assignment } = this.props;
    if (bootcamp && assignment) {
      const params = {
        course: bootcamp.title,
        project: assignment.title,
      };
      setPageTitle('pageName.course.project', params);
    }
  }

  analyticsParams = () => {
    const { bootcamp, section, unit } = this.props;
    return {
      bootcamp,
      section,
      project: unit,
    };
  };

  trackPageEnd = () => {
    analytics.pageEnd(this.analyticsParams());
  };

  componentWillUnmount() {
    this.trackPageEnd();
  }

  fetchPrerequisite = assignment => {
    const { prerequisiteAssignment } = this.props;
    if (assignment && assignment.prerequisite_id && !prerequisiteAssignment && !this.promises.fetchAssignment) {
      this.promises.fetchAssignment = this.props.fetchAssignment(assignment.prerequisite_id);
    }
  };

  fetchLessonData = props => {
    const { lesson, lessonId } = props;

    if (!lesson && lessonId && !this.promises.fetchLesson) {
      this.promises.fetchLesson = props.fetchLesson(lessonId);
    }
  };

  fetchAssignmentData = props => {
    const { assignment, assignmentId } = props;

    if (!assignment && assignmentId && !this.promises.fetchAssignment) {
      this.promises.fetchAssignment = props.fetchAssignment(assignmentId).then(a => this.fetchPrerequisite(a));
    }
  };

  fetchIntroLessonData = props => {
    const { unit, introLesson } = props;

    if (unit && unit.intro_lesson_id && !introLesson && !this.promises.fetchIntroLesson) {
      this.promises.fetchIntroLesson = props.fetchLesson(unit.intro_lesson_id);
    }
  };

  saveLessonData = props => {
    const { unit, section } = props;

    if (unit && !unit.intro_lesson_id && !this.promises.saveLesson) {
      this.promises.saveLesson = props.saveLesson({ duration: 10 }, section.id).then(l => {
        props.saveUnit({ ...unit, intro_lesson_id: l.id }, section.id);
      });
    }
  };

  fetchData = props => {
    const { assignment } = props;

    this.fetchLessonData(props);
    this.fetchAssignmentData(props);
    this.fetchIntroLessonData(props);
    this.saveLessonData(props);

    if (assignment) {
      this.fetchPrerequisite(assignment);
    }
  };

  handleViewLtiParams = () => {
    const { bootcamp, assignment } = this.props;
    showModal(LtiConfigDialogContainer, {
      bootcamp,
      assignment,
    });
  };

  renderLabContainer = () => {
    const { bootcamp, assignment, prerequisiteAssignment, unit, projectFolderPath } = this.props;

    if (prerequisiteAssignment) {
      return (
        <div className="alert alert-warn">
          <FormattedMessage
            id="project.prerequisiteIncomplete"
            values={{ title: assignment.title, prerequisiteTitle: prerequisiteAssignment.title }}
          />
        </div>
      );
    }

    const projectUrl = assignment.starter_project_url;

    return (
      <Project
        bootcamp={bootcamp}
        projectUrl={projectUrl}
        labInteractable={assignment}
        projectFolderPath={projectFolderPath}
        analyticsParams={this.analyticsParams()}
        unit={unit}
        saveBackups={false}
        useFreshCopy={true}
      />
    );
  };

  render() {
    const { unit, assignment, lesson, match, isEditMode, introLesson, bootcampPath, onHeaderBackClick } = this.props;
    const { bootcampId } = match.params;

    if (!unit || !assignment || !lesson) {
      return (
        <ContentWrapperContainer className="lab-page" hideStaffNav>
          <Loading />
        </ContentWrapperContainer>
      );
    }

    // Set the project url
    unit.starter_project_url = assignment.starter_project_url;

    return (
      <>
        {onHeaderBackClick && <ViewingAsStudentHeader onBackClick={onHeaderBackClick} />}

        <LabWrapper app={assignment.app}>
          <QuizOnLab
            render={(quizOnLab, renderQuiz) => (
              <ProjectDataProvider assignment={assignment} isEditMode={isEditMode}>
                <LabToolbar />
                <div className="new-lab-page">
                  <main className="new-lab-page__main">
                    <LabInstructions title={assignment.title} backLink={bootcampPath}>
                      <ProjectInstructions
                        unit={unit}
                        lesson={lesson}
                        assignment={assignment}
                        quizOnLab={quizOnLab}
                        isEditMode={isEditMode}
                        bootcampId={this.props.bootcampId}
                        showBlockTakeQuiz
                        isSidebar
                      />
                    </LabInstructions>

                    <section className="new-lab-page__content">
                      <LabAreaIntro />
                      <div className="new-lab-page__wrapper">
                        {isEditMode && (
                          <div className="lab-image" style={{ padding: 20 }}>
                            <button
                              type="button"
                              className="button-standard button-standard--grey button-standard--short"
                              onClick={this.handleViewLtiParams}>
                              <FormattedMessage id="lti.config.viewParams" />
                            </button>
                            {introLesson && (
                              <div className="grid-nine">
                                <ProjectInstructions
                                  unit={unit}
                                  lesson={introLesson}
                                  assignment={assignment}
                                  isEditMode={isEditMode}
                                  bootcampId={bootcampId}
                                  isIntroLesson
                                  blockClass="block"
                                  headingClass="heading"
                                />
                              </div>
                            )}
                            {renderQuiz(EditQuiz)}
                          </div>
                        )}
                        {!isEditMode && (
                          <div className="lab-image">
                            {renderQuiz(StudentBlockQuizShow, { questionProgressesEnabled: false })}
                            {this.renderLabContainer()}
                          </div>
                        )}
                      </div>
                    </section>
                  </main>
                </div>
                <DismissIntro />
              </ProjectDataProvider>
            )}
          />
        </LabWrapper>
      </>
    );
  }
}

ProjectShow.propTypes = {
  match: PropTypes.object,
  bootcampId: PropTypes.string,
  assignmentId: PropTypes.string,
  bootcamp: PropTypes.object,
  unit: PropTypes.object,
  section: PropTypes.object,
  lesson: PropTypes.object,
  introLesson: PropTypes.object,
  assignment: PropTypes.object,
  prerequisiteAssignment: PropTypes.object,
  // eslint-disable-next-line react/no-unused-prop-types
  fetchLesson: PropTypes.func.isRequired,
  fetchAssignment: PropTypes.func.isRequired,
  fetchBootcamp: PropTypes.func.isRequired,
  isEditMode: PropTypes.bool,
  projectFolderPath: PropTypes.string,
  bootcampPath: PropTypes.string,
  onHeaderBackClick: PropTypes.func,
};
