import { selectEntities, selectAll, selectFromBy } from './applicationSelectors';
import { keyBy as _keyBy } from 'lodash';
import { createSelector } from 'reselect';
import createCachedSelector from 're-reselect';
import { selectQuestionsByQuizId } from './questionSelectors';

export const selectQuestionProgressEntities = selectEntities('questionProgresses');
export const selectQuestionProgresses = selectAll(selectQuestionProgressEntities);
export const selectProgressForQuestion = selectFromBy(selectQuestionProgresses, 'question_id');

const selectQuestionProgressesByQuestionId = createSelector([selectQuestionProgresses], questionProgresses => {
  return _keyBy(questionProgresses, 'question_id');
});

export const selectQuestionProgressesForQuiz = createCachedSelector(
  [selectQuestionProgressesByQuestionId, (state, quizId) => selectQuestionsByQuizId(state, quizId)],
  (questionProgressesByQuestionId, questions) => {
    const questionIds = questions.map(question => question.id);
    return questionIds.reduce((acc, questionId) => {
      acc[questionId] = questionProgressesByQuestionId[questionId];
      return acc;
    }, {});
  },
)((_state, quizId) => quizId);
