import { isEmpty as _isEmpty } from 'lodash';
import { useSelector } from 'react-redux';
import { selectCurrentUser } from '../selectors/authSelectors';
import { canAuthor, canLearn, canCoach, canAdministrate } from '../helpers/userAuth';
import * as analytics from '../utils/analytics';

export default function useCurrentUser() {
  const currentUser = useSelector(selectCurrentUser);
  const isStudent = canLearn(currentUser);
  const isLtiStudent = isStudent && !_isEmpty(currentUser.lti_user_id);

  return {
    currentUser,
    canAuthor: canAuthor(currentUser),
    canLearn: canLearn(currentUser),
    canCoach: canCoach(currentUser),
    canAdministrate: canAdministrate(currentUser),
    isStudent,
    isLtiStudent,
  };
}
