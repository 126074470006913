import { isEmpty as _isEmpty } from 'lodash';
import classnames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { getProgressStatus } from '../../utils/utils';
import ActionButtons from '../Utils/ActionButtons';
import showModal from '../../utils/showModal';
import EditUnitModalContainer from '../Author/Paths/EditUnitModalContainer';
import { confirmInput } from '../../utils/confirmInput';
import ReorderButtons from '../Utils/ReorderButtons';
import Icons from '../Utils/Icons';
import Tag from '../library/Tag';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { selectBootcampInstanceQuizzes } from '../../selectors/bootcampInstanceAssignmentSelectors';

const useStore = cohort => {
  const allBootcampInstanceQuizzes = useSelector(selectBootcampInstanceQuizzes);
  const bootcampInstanceQuizzes = allBootcampInstanceQuizzes
  ? Object.values(allBootcampInstanceQuizzes).filter(
      bootcampInstanceQuiz => bootcampInstanceQuiz.bootcamp_instance_id === cohort.id,
    )
  : [];
  return {
    bootcampInstanceQuizzes,
  };
};

// eslint-disable-next-line complexity
const GradedQuizTile = ({
  unit,
  section,
  unitProgress,
  unitIndex,
  totalUnits,
  onUnitsReorder,
  isEditMode,
  match,
  saveUnit,
  destroySectionUnit,
  history,
  cohort,
}) => {
  const lessonUrl = `${match.url}/sections/${section.id}/graded_quizzes/${unit.id}`;

  const handleEdit = () => {
    showModal(EditUnitModalContainer, {
      section,
      unit,
      onSave: saveUnit,
    });
  };

  const handleDelete = () => {
    const message = <FormattedMessage id="unit.confirmGradedQuizDelete" values={{ name: unit.title }} />;

    confirmInput({
      message,
      onOk: () => {
        destroySectionUnit(unit, section.id);
      },
    });
  };

  const handleEvent = e => {
    e.preventDefault();

    history.push(lessonUrl);
  };

  const { isComplete, inProgress } = getProgressStatus(unitProgress);

  const unitClassName = classnames(
    'units-group',
    (inProgress || isComplete) && 'units-group--touched',
    inProgress && 'units-group--in-progress',
  );
  const headingClass = classnames(
    'units-group__heading',
    'units-group__heading--graded-quiz',
    isComplete && 'units-group__heading--completed',
  );
  const iconClass = classnames(
    'units-group__icon',
    inProgress && 'units-group__icon--in-progress',
    isComplete && 'units-group__icon--completed',
  );

  let unitsGroupRight;

  if (isEditMode) {
    unitsGroupRight = (
      <>
        <div className="learning-path-item-reorder-button">
          <ReorderButtons index={unitIndex} total={totalUnits} onReorder={onUnitsReorder} />
        </div>
        <span className="learning-path-module-authoring" style={{ marginLeft: 20 }}>
          <ActionButtons
            isEditMode
            className="authoring-edit-delete-button"
            onEdit={handleEdit}
            onDelete={handleDelete}
            showRemove={false}
          />
        </span>
      </>
    );
  } else if (isComplete) {
    unitsGroupRight = (
      <span className="units-group-title--accent">
        <FormattedMessage id="grading.graded" />
      </span>
    );
  } else if (inProgress) {
    unitsGroupRight = (
      <span className="units-group-title--accent">
        <FormattedMessage id="unit.inProgress" />
      </span>
    );
  }

  const { bootcampInstanceQuizzes } = useStore(cohort);
  const bootcampInstanceQuiz = bootcampInstanceQuizzes.find(biq => biq.quiz_id === unit.quiz_id);
  const dueDate = bootcampInstanceQuiz && bootcampInstanceQuiz.quiz_due_at;
  const suggestedMessage = 'SUGGESTED DUE DATE ';
  const formattedDueDate = moment(dueDate).format('MMM D').toUpperCase();
  const yesterday = moment().subtract(1, 'days');
  const suggestedMessageColor = isComplete ? '#45C569' : yesterday < moment(dueDate) ? '#FFB733' : '#FF4F51';

  return (
    <div className={unitClassName}>
      <div className="units-group-heading">
        <div className={headingClass}>
          <div role="button" className="units-group-left" tabIndex="0" onClick={handleEvent} onKeyPress={handleEvent}>
            <span className={iconClass}>
              <Icons.GradedQuiz />
            </span>

            <div className={classnames('units-group-title', (inProgress || isComplete) && 'units-group-title--accent')}>
              <div className='overflow-title-container'>
                <div className='overflow-title'>{unit.title}</div>
                {dueDate && <Tag text={suggestedMessage + formattedDueDate} color={suggestedMessageColor} />}
              </div>
            </div>
          </div>
          <div className="units-group-right">{unitsGroupRight}</div>
        </div>
      </div>
    </div>
  );
};

GradedQuizTile.propTypes = {
  unit: PropTypes.object.isRequired,
  section: PropTypes.object.isRequired,
  unitProgress: PropTypes.object,
  unitIndex: PropTypes.number.isRequired,
  totalUnits: PropTypes.number.isRequired,
  onUnitsReorder: PropTypes.func.isRequired,
  isEditMode: PropTypes.bool,
  match: PropTypes.object.isRequired,
  saveUnit: PropTypes.func.isRequired,
  destroySectionUnit: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
};

export default GradedQuizTile;
