import React from 'react';
import { useSelector } from 'react-redux';

import { Row, Col, Card, Space } from 'antd';
import moment from 'moment';
import { styles } from '@pathstream/ui';
import { STATUS_LEASED, STATUS_GRADED } from './constants';
import { selectAssignmentProgress } from '../../selectors/assignmentProgressSelectors';
import EvaluatorCountUpTimer from './EvaluatorCountUpTimer';
import { selectUser } from '../../selectors/userSelectors';
import { selectCurrentUser } from '../../selectors/authSelectors';

const { colorLibrary } = styles;

const EvaluatorQueueItem = ({ item, currentTime, buttonGroup }) => {
  const currentUser = useSelector(selectCurrentUser);

  const assignmentProgress = useSelector(state => selectAssignmentProgress(state, item.assignment_progress_id));
  const leaseOwnerUser = useSelector(state => selectUser(state, item.lease_owner));
  const leaseOwnerName = leaseOwnerUser ? leaseOwnerUser.name : '';

  const elideMiddle = input => {
    if (input && input.length > 35) {
      return `${input.substr(0, 4)}...${input.substr(input.length - 28, input.length)}`;
    }
    return input;
  };

  const styles = {
    inProgressAndOwner: {
      color: colorLibrary.oranges.warningOrange,
    },
    inProgressAndNotOwner: {
      color: colorLibrary.neutrals.labelGray,
    },
    leaseOwner: {
      fontStyle: 'italic',
      fontWeight: 400,
    },
    grade: {
      fontSize: 20,
      fontWeight: 700,
      color: colorLibrary.neutrals.darkGray,
    },
    assignmentTitle: {
      fontSize: 16,
      fontWeight: 700,
      color: colorLibrary.neutrals.darkGray,
      cursor: 'text',
    },
    courseTitle: {
      fontSize: 14,
      fontSeight: 400,
      color: colorLibrary.neutrals.darkGray,
      cursor: 'text',
    },
  };

  const handleAssignmentTitleClick = item => {
    // TODO: Need to implement supporting destination views and add
    // function that sends user to the new views
    // eslint-disable-next-line no-console
    console.log('handleAssignmentTitleClick', item);
  };
  const handleCourseTitleClick = item => {
    // TODO: Need to implement supporting destination views and add
    // function that sends user to the new views
    // eslint-disable-next-line no-console
    console.log('handleCourseTitleClick', item);
  };

  return (
    <Card>
      <Row>
        <Col span={5}>
          <Space direction="vertical">
            <b>{item.student_name}</b>
            <span>Submitted {moment(assignmentProgress.completed_at).format('h:mma on MMM D')}</span>
          </Space>
        </Col>
        <Col span={7}>
          <Space direction="vertical">
            <a onClick={item => handleAssignmentTitleClick(item)} style={styles.assignmentTitle}>
              {elideMiddle(item.assignment_name)}
            </a>
            <Space>
              <a onClick={item => handleCourseTitleClick(item)} style={styles.courseTitle}>
                <b>{item.certificate_title}:</b> {elideMiddle(item.course_name)}
              </a>
            </Space>
          </Space>
        </Col>
        <Col span={3}>
          {item.status === STATUS_LEASED && item.lease_owner === currentUser.id && (
            <Space direction="vertical" style={styles.inProgressAndOwner}>
              <EvaluatorCountUpTimer startTime={item.lease_started} endTime={currentTime} />
              <span style={styles.leaseOwner}>{leaseOwnerName}</span>
            </Space>
          )}
          {item.status === STATUS_LEASED && item.lease_owner !== currentUser.id && (
            <Space direction="vertical" style={styles.inProgressAndNotOwner}>
              <EvaluatorCountUpTimer startTime={item.lease_started} endTime={currentTime} />
              <span style={styles.leaseOwner}>{leaseOwnerName}</span>
            </Space>
          )}
          {item.status === STATUS_GRADED && (
            <Space direction="vertical" style={styles.inProgressAndNotOwner}>
              <EvaluatorCountUpTimer startTime={item.lease_started} endTime={item.lease_ended} />
              <span style={styles.leaseOwner}>{leaseOwnerName}</span>
            </Space>
          )}
        </Col>
        <Col span={2} style={{ textAlign: 'right' }}>
          {item.status === STATUS_GRADED && item.score && <span style={styles.grade}>{item.score}%</span>}
        </Col>
        <Col span={6} offset={1}>
          {buttonGroup}
        </Col>
      </Row>
    </Card>
  );
};

export default EvaluatorQueueItem;
