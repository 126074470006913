import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { confirmInput } from '../../../utils/confirmInput';
import ProjectDropZone from '../../Utils/ProjectDropZone';
import ProjectVersionChangeModal from '../../Utils/ProjectVersionChangeModal';
import { isTableauApp } from '../../../helpers/labHelper';
import { saveStep } from '../../../actions/stepActions';

class ProjectUpload extends Component {
  static propTypes = {
    step: PropTypes.object.isRequired,
    bootcamp: PropTypes.object.isRequired,
    onSave: PropTypes.func.isRequired,
    title: PropTypes.string,
    attr: PropTypes.string.isRequired,
    app: PropTypes.string.isRequired,
    messageId: PropTypes.string,
  };

  constructor(props) {
    super(props);
    this.state = {
      showProjectVersionChangeModal: false,
      uploadedFileUrl: null,
    };
  }

  fileUploaded = url => {
    this.setState({ uploadedFileUrl: url });

    const isCohortApp = isTableauApp(this.props.app);
    if (url && isCohortApp) {
      this.setShowProjectVersionChangeModal(true);
    } else {
      this.saveStep(url);
    }
  };

  handleLabChangeType = labChangeType => {
    const { uploadedFileUrl } = this.state;
    return this.saveStep(uploadedFileUrl, labChangeType);
  };

  saveStep = (url, labChangeType = null) => {
    this.setShowProjectVersionChangeModal(false);
    const step = {
      ...this.props.step,
      bootcamp_id: this.props.bootcamp.id,
      [this.props.attr]: url,
    };

    if (labChangeType) {
      step['lab_change_type'] = labChangeType;
    }

    return this.props.onSave(step);
  };

  clearProject = () => {
    const { title } = this.props;
    confirmInput({
      message: <FormattedMessage id="tutorial.confirmRemoveProject" values={{ name: title, name_exists: !!title }} />,
      onOk: () => this.fileUploaded(null),
    });
  };

  setShowProjectVersionChangeModal = shouldShow => {
    this.setState({ showProjectVersionChangeModal: shouldShow });
  };

  render() {
    const { step, app, messageId } = this.props;
    const projectUrl = step[this.props.attr];
    const { showProjectVersionChangeModal } = this.state;

    return (
      <>
        <div style={{ marginTop: 20 }}>
          <h3>
            {this.props.title || (
              <FormattedMessage id="step.stageSequence" values={{ seqNum: `${step.seq_num} - ${step.title}` }} />
            )}
          </h3>

          <ProjectDropZone
            onFileUploaded={this.fileUploaded}
            onRemove={this.clearProject}
            projectUrl={projectUrl}
            app={app}
            messageId={messageId}
          />
        </div>
        {showProjectVersionChangeModal && (
          <ProjectVersionChangeModal
            onSubmit={this.handleLabChangeType}
            onHide={() => this.setShowProjectVersionChangeModal(false)}
          />
        )}
      </>
    );
  }
}

export default connect(null, { onSave: saveStep })(ProjectUpload);
