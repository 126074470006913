import { indexOf as _indexOf, values as _values } from 'lodash';
import { createSelector } from 'reselect';
import createCachedSelector from 're-reselect';
import { selectBootcampPaths } from './bootcampSelectors';
import { selectSectionProgressEntities } from './sectionProgressSelectors';
import { selectComponentProgressEntities } from './componentProgressSelectors';
import { selectUnitProgressEntities } from './unitProgressSelectors';
import { selectUnitEntities } from './unitSelectors';
import { selectComponentEntities } from './componentSelectors';
import { selectSectionEntities } from './sectionSelectors';
import { selectPathEntities } from './pathSelectors';

export const selectPathProgressEntities = state => {
  return state.entities.pathProgresses;
};

export const selectPathProgresses = createSelector([selectPathProgressEntities], pathProgressEntities => {
  return _values(pathProgressEntities);
});

export const selectPathProgress = createCachedSelector(
  [selectPathProgressEntities, (_state, pathProgressId) => pathProgressId],
  (pathProgressEntities, pathProgressId) => {
    return pathProgressEntities[pathProgressId];
  },
)((_state, unitProgressId) => unitProgressId);

export const selectProgressForPath = createCachedSelector(
  [selectPathProgressEntities, (_state, pathId) => pathId],
  (pathProgressEntities, pathId) => {
    return _values(pathProgressEntities).filter(pathProgress => pathProgress.path_id === pathId)[0];
  },
)((_state, pathId) => pathId);

export const selectPathProgressesForBootcamp = createCachedSelector(
  [selectBootcampPaths, selectPathProgressEntities],
  (paths, pathProgressEntities) => {
    const pathProgressesById = {};

    paths.forEach(path => {
      pathProgressesById[path.id] = _values(pathProgressEntities).filter(
        pathProgress => pathProgress.path_id === path.id,
      )[0];
    });

    return pathProgressesById;
  },
)((_state, bootcampId) => bootcampId);

export const selectNumCompletedPathsForEnrollment = createCachedSelector(
  [selectPathProgresses, (state, enrollmentId) => enrollmentId],
  (pathProgresses, enrollmentId) => {
    return pathProgresses.filter(pathProgress => {
      return pathProgress.enrollment_id === enrollmentId && pathProgress.completed_at;
    }).length;
  },
)((_state, enrollmentId) => enrollmentId);

export const selectProgressToResume = createCachedSelector(
  [
    selectPathProgressEntities,
    selectSectionProgressEntities,
    selectUnitProgressEntities,
    selectComponentProgressEntities,
    selectPathEntities,
    selectSectionEntities,
    selectUnitEntities,
    selectComponentEntities,
    (_state, pathProgressId) => pathProgressId,
  ],
  (
    pathProgressEntities,
    sectionProgressEntities,
    unitProgressEntites,
    componentProgressEntities,
    pathEntities,
    sectionEntities,
    unitEntities,
    componentEntities,
    pathProgressId,
  ) => {
    const pathProgress = pathProgressEntities[pathProgressId];
    if (!pathProgress) {
      return;
    }
    const path = pathEntities[pathProgress.path_id];

    const sectionProgress = sectionProgressEntities[pathProgress.current_section_progress_id];

    if (!sectionProgress) {
      return;
    }

    const unitProgress = unitProgressEntites[sectionProgress.current_unit_progress_id];

    if (!unitProgress) {
      return;
    }

    if (unitProgress.assignment_progress_id || unitProgress.quiz_progress_id) {
      const sectionId = sectionProgress.section_id;
      const unitId = unitProgress.unit_id;

      return {
        progress: unitProgress,
        pathId: pathProgress.path_id,
        sectionId,
        section: sectionEntities[sectionId],
        sectionIndex: _indexOf(path.section_ids, sectionId),
        unitId,
        unit: unitEntities[unitId],
        unitIndex: _indexOf(sectionEntities[sectionId].unit_ids, unitId),
      };
    }

    const componentProgress = componentProgressEntities[unitProgress.current_component_progress_id];

    if (!componentProgress) {
      return;
    }

    const sectionId = sectionProgress.section_id;
    const unitId = unitProgress.unit_id;
    const componentId = componentProgress.component_id;
    let componentIndex;
    try {
      componentIndex = _indexOf(unitEntities[unitId].component_ids, componentId);
    } catch (e) {
      componentIndex = 0; // probably wrong value, but unlikely to blow up the app
      if (Raven) {
        Raven.captureException(e);
      } else {
        console.error('Raven not defined');
      }
    }

    return {
      progress: componentProgress,
      pathId: pathProgress.path_id,
      sectionId,
      section: sectionEntities[sectionId],
      sectionIndex: _indexOf(path.section_ids, sectionId),
      unitId,
      unit: unitEntities[unitId],
      unitIndex: _indexOf(sectionEntities[sectionId].unit_ids, unitId),
      componentId,
      component: componentEntities[componentId],
      componentIndex,
    };
  },
)((_state, pathProgressId) => pathProgressId);
