import { connect } from 'react-redux';
import { selectSectionUnits } from '../../../../selectors/sectionSelectors';
import SectionTile from './SectionTile';

const mapStateToProps = (state, ownProps) => {
  const { section } = ownProps;

  return {
    units: selectSectionUnits(state, section.id),
  };
};

export default connect(mapStateToProps)(SectionTile);
