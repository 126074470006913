import React from 'react';
import PropTypes from 'prop-types';
import { Route, Switch, matchPath } from 'react-router-dom';
import PathShowContainer from '../../../../components/Paths/PathShowContainer';
import ComponentShow from '../../ComponentShow';
import TutorialShowContainer from '../../TutorialShowContainer';
import NotFound from '../../../NotFound';
import ProjectShowLanding from '../../ProjectShow/ProjectShowLanding';
import GradedQuizShow from '../../GradedQuizShow';
import StudentNavBarContainer from './StudentNavBarContainer';
import LearningHomeContainer from '../../LearningHome/LearningHomeContainer';
import history from '../../../../history';
import BootcampListContainer from '../../BootcampListContainer';
import { getImpersonator } from '../../../../helpers/userAuth';

export const StudentV2 = ({ match }) => {
  const pathname = history.location.pathname;
  const overviewPath = '/bootcamps/:bootcampId/paths/:pathId';
  const lessonPath = '/bootcamps/:bootcampId/paths/:pathId/sections/:sectionId/units/:unitId/components/:componentId';
  const quizPath = '/bootcamps/:bootcampId/paths/:pathId/sections/:sectionId/graded_quizzes/:unitId';
  const showAdminImpersonation = !!getImpersonator();

  let pathsMatch = matchPath(pathname, {
    path: `/student${overviewPath}`,
    exact: true,
    strict: true,
  });
  let lessonMatch = matchPath(pathname, {
    path: `/student${lessonPath}`,
    exact: true,
    strict: true,
  });
  let quizMatch = matchPath(pathname, {
    path: `/student${quizPath}`,
    exact: true,
    strict: true,
  });

  const shouldShowNavigation =
    (pathsMatch ? true : false) ||
    (lessonMatch ? true : false) ||
    (quizMatch ? true : false) ||
    pathname === '/student/bootcamps' ||
    pathname == '/student/';

  return (
    <div className="student-v2-nav ps2">
      <StudentNavBarContainer
        shouldShowNavigation={shouldShowNavigation}
        showAdminImpersonation={showAdminImpersonation}
      />
      <Switch>
        <Route exact path={`${match.path}/`} component={LearningHomeContainer} />
        <Route exact path={`${match.path}/bootcamps`} component={BootcampListContainer} />
        <Route exact path={`${match.path}${overviewPath}`} component={PathShowContainer} />
        <Route exact path={`${match.path}${lessonPath}/blocks/:blockId`} component={TutorialShowContainer} />
        <Route exact path={`${match.path}${lessonPath}`} component={ComponentShow} />
        <Route
          exact
          path={`${match.path}/bootcamps/:bootcampId/paths/:pathId/sections/:sectionId/projects/:unitId`}
          component={ProjectShowLanding}
        />
        <Route exact path={`${match.path}${quizPath}`} component={GradedQuizShow} />
        <Route component={NotFound} />
      </Switch>
    </div>
  );
};

StudentV2.propTypes = {
  match: PropTypes.object.isRequired,
};
