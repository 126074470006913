import { connect } from 'react-redux';
import GradedRow from './GradedRow';
import { selectEntitiesForBootcamp } from '../../../selectors/cohortBootcampSelectors';

const mapStateToProps = (state, ownProps) => {
  const { bootcamp, item, matchUrl, relativeIndex, hideDueDates } = ownProps;
  const { unitsBySection } = selectEntitiesForBootcamp(state, bootcamp.id);
  let url = matchUrl;

  let endpointPart;
  if (item.template_type === 'assignment') {
    endpointPart = 'projects';
  }
  if (item.template_type === 'quiz') {
    endpointPart = 'graded_quizzes';
  }
  const gradableIdAttr = `${item.template_type}_id`;

  let unit;
  const sectionId = Object.keys(unitsBySection).find(key => {
    unit = unitsBySection[key].find(unit => unit[gradableIdAttr] === item.template_id);
    return unit;
  });
  if (sectionId && unit) {
    url = `${matchUrl}/sections/${sectionId}/${endpointPart}/${unit.id}`;
  }

  return {
    item,
    relativeIndex,
    linkUrl: url,
    hideDueDates,
  };
};

export default connect(mapStateToProps, {})(GradedRow);
