import { cloneDeep as _cloneDeep, map as _map } from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import QuestionResponse from './QuestionResponse';
import Icons from '../Utils/Icons';
import {
  findChoice,
  isSubmitted,
  buildSelectableChoices,
  getChoicesFromProgress,
  organizeUserChoices,
} from '../../helpers/questionHelper';
import RTEContent from '../../helpers/contentMathjaxHelper';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';

export default class QuestionMultiSelect extends Component {
  constructor(props) {
    super(props);

    this.state = this.buildState();
  }

  buildState = () => {
    const { questionProgress, question } = this.props;
    if (isSubmitted(questionProgress)) {
      const userChoices = getChoicesFromProgress(questionProgress, question);
      return {
        userChoices: buildSelectableChoices(userChoices),
        questionState: this.getUserChoicesCorrectness(userChoices),
        isSubmitted: true,
      };
    } else {
      const userChoices = organizeUserChoices(question);
      return this.initialState(userChoices);
    }
  };

  initialState = userChoices => ({
    userChoices: buildSelectableChoices(userChoices),
    questionState: 'initial',
    isSubmitted: false,
  });

  toggleUserChoice = userChoice => {
    this.setState(state => {
      const newUserChoices = _cloneDeep(state.userChoices);
      const newUserChoice = this.findMatchingChoice(userChoice, newUserChoices);
      newUserChoice.is_selected = !newUserChoice.is_selected;
      return { userChoices: newUserChoices };
    });
  };

  findMatchingChoice = (userChoice, choices = this.props.question.choices) => findChoice(userChoice, choices);

  getUserChoicesWithResults = (userChoices = this.state.userChoices) =>
    userChoices.map(userChoice => {
      userChoice.result = this.findMatchingChoice(userChoice).is_correct === userChoice.is_selected;
      return userChoice;
    });

  getUserChoicesCorrectness = (userChoices = this.state.userChoices) => {
    const userChoicesWithResults = this.getUserChoicesWithResults(userChoices);

    if (userChoicesWithResults.every(choice => choice.result)) {
      return 'correct';
    }
    if (userChoicesWithResults.every(choice => !choice.result)) {
      return 'incorrect';
    }
    return 'partially_correct';
  };

  handleAnswerQuestion = () => {
    if (!this.isCurrentQuestionAnswered()) {
      return;
    }
    this.setState({ questionState: this.getUserChoicesCorrectness(), isSubmitted: true });
    this.props.submitChoices(this.state.userChoices);
  };

  getCorrectnessNum = () => {
    const selectedUserChoices = this.getUserChoicesWithResults().filter(choice => choice.is_selected);
    const correctNum = selectedUserChoices.filter(choice => choice.result).length;

    return { correctNum, incorrectNum: selectedUserChoices.length - correctNum };
  };

  isCurrentQuestionAnswered = () => this.state.userChoices.some(choice => choice.is_selected === true);

  handleShowAnswer = () => {
    this.setState({ questionState: 'multiple_answer' });
  };

  handleResetQuestion = () => {
    this.setState(state => this.initialState(state.userChoices));
  };

  isChecked = userChoice => {
    const { questionState } = this.state;
    const questionChoice = this.findMatchingChoice(userChoice);

    if (['multiple_answer', 'correct'].includes(questionState)) {
      // show answer mode
      return questionChoice.is_correct;
    }
    return userChoice.is_selected;
  };

  renderIcon(userChoice) {
    const { questionState } = this.state;
    const questionChoice = this.findMatchingChoice(userChoice);

    if (['multiple_answer', 'correct'].includes(questionState)) {
      // show answer mode
      return questionChoice.is_correct ? (
        <span className="checked correct">
          <Icons.TickSquare />
        </span>
      ) : (
        <span className="not-checked">
          <Icons.Square />
        </span>
      );
    }
    if (['incorrect', 'partially_correct'].includes(questionState)) {
      if (userChoice.is_selected) {
        return questionChoice.is_correct ? (
          <span className="checked checked---blue">
            <Icons.TickSquare />
          </span>
        ) : (
          <span className="checked">
            <Icons.CrossSquare />
          </span>
        );
      }
    }

    return userChoice.is_selected ? (
      <span className="checked checked---blue">
        <Icons.TickSquare />
      </span>
    ) : (
      <span className="not-checked">
        <Icons.Square />
      </span>
    );
  }

  render() {
    const { question } = this.props;
    const { isSubmitted, userChoices, questionState } = this.state;

    return (
      <>
        <div className="content" style={{ paddingBottom: '0 !important' }}>
          <div className="questions" role="group" aria-live="polite">
            {/* eslint-disable-next-line complexity */}
            {_map(userChoices, (userChoice, index) => {
              const questionChoice = this.findMatchingChoice(userChoice);

              const isIncorrect = questionState === 'incorrect' && userChoice.is_selected && !questionChoice.is_correct;
              const isActive = !isSubmitted && userChoice.is_selected;
              const isPartiallyIncorrect =
                questionState === 'partially_correct' && userChoice.is_selected && !questionChoice.is_correct;
              const isShowingAnswer = questionState === 'multiple_answer' && questionChoice.is_correct;
              const isCorrect =
                (questionState === 'correct' || questionState === 'partially_correct') &&
                userChoice.is_selected &&
                questionChoice.is_correct;

              const labelClass = classnames({
                'option-label': true,
                'option-label__incorrect': isIncorrect,
                'option-label__active': isActive,
                'option-label__partially-incorrect': isPartiallyIncorrect,
                'option-label__show-answer': isShowingAnswer,
                'option-label__correct': isCorrect,
              });
              return (
                <div key={index} className={classnames('question', isSubmitted && 'question---disabled')}>
                  <input
                    id={`questions-${question.id}-${index}-multiple`}
                    onChange={() => this.toggleUserChoice(userChoice)}
                    type="checkbox"
                    checked={this.isChecked(userChoice)}
                    disabled={isSubmitted}
                  />
                  <div className="option-label-wrapper">
                    <RTEContent
                      content={userChoice.prompt}
                      Tag="label"
                      classSelector={labelClass}
                      htmlForVar={`questions-${question.id}-${index}-multiple`}
                    />
                    {isCorrect && <CheckOutlined className="option-label__icon option-label__icon--correct" />}
                    {isIncorrect && <CloseOutlined className="option-label__icon option-label__icon--incorrect" />}
                  </div>
                </div>
              );
            })}
          </div>

          <QuestionResponse
            question={this.props.question}
            questionState={this.state.questionState}
            handleAnswerQuestion={this.handleAnswerQuestion}
            isCurrentQuestionAnswered={this.isCurrentQuestionAnswered}
            handleShowAnswer={this.handleShowAnswer}
            handleResetQuestion={this.handleResetQuestion}
            getSelectedChoicesCorrectness={this.getCorrectnessNum}
            renderNext={this.props.renderNext}
            renderQuestionsNavigation={this.props.renderQuestionsNavigation}
          />
        </div>
      </>
    );
  }
}

QuestionMultiSelect.propTypes = {
  question: PropTypes.object.isRequired,
  questionProgress: PropTypes.object,
  submitChoices: PropTypes.func.isRequired,
};
