import React, { Component } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { throttle as _throttle } from 'lodash';
import { canAuthor, isStaff } from '../../../helpers/userAuth';
import HeaderContainer from './HeaderContainer';
import AdminSidebarContainer from './Admin/AdminSidebarContainer';
import { isPrintMode } from '../../../helpers/printHelper';
import { isCoach } from '../../../helpers/userAuth';
import * as analytics from '../../../utils/analytics';

export default class ContentWrapper extends Component {
  constructor(props) {
    super(props);

    this.state = {
      scrollTop: 0,
    };
  }

  handleScroll = _throttle(() => {
    const scrollTop = Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop);
    this.setState({
      scrollTop,
    });
  }, 100);

  componentDidMount() {
    document.addEventListener('scroll', this.handleScroll);
    this.setAccessibilityOnTooltips();
  }

  componentWillUnmount() {
    document.removeEventListener('scroll', this.handleScroll);
  }

  componentDidUpdate() {
    this.setAccessibilityOnTooltips();
  }

  setAccessibilityOnTooltips = () => {
    const targetArray = document.querySelectorAll('[data-tip]');

    targetArray.forEach(target => target.setAttribute('aria-label', target.dataset.tip));
  };

  // eslint-disable-next-line complexity
  render() {
    const {
      children,
      className,
      currentPathId,
      currentSectionId,
      currentUnitId,
      currentUser,
      currentComponentId,
      isCollapsed,
      sideNavComponent,
      hideStaffNav,
      headerText,
      isEditMode,
      hideAuthorToggle,
      showFooter,
      afterScrollClass,
      subHeader,
      headerSubHeader,
      showLogo,
      headerContent,
      headerClass,
      printHeader,
      school,
      headerHideProgress,
    } = this.props;

    const { scrollTop } = this.state;
    const scrolledPastHeader = scrollTop > 0;
    const hasSideNav = !!sideNavComponent;
    const isUserStaff = isStaff(currentUser);
    const isAuthor = canAuthor(currentUser);
    const showStaffSidebar =
      !hideStaffNav &&
      isUserStaff &&
      !hasSideNav &&
      !isCoach(currentUser) &&
      (hideAuthorToggle || !isAuthor || isEditMode);
    const wrapperClass = classnames([
      `${className}-wrapper`,
      scrolledPastHeader && afterScrollClass ? afterScrollClass : '',
    ]);
    const subContainerClass = classnames(className, scrolledPastHeader && `${className}--after-scroll`);
    const contentClass = `${className}__content`;

    const containerContentClass = classnames(
      contentClass,
      `${contentClass}--${isEditMode ? 'edit' : 'view'}-mode`,
      scrolledPastHeader && `${contentClass}---after-scroll`,
    );

    const innerStyles = {};
    if (!showFooter) {
      innerStyles.paddingBottom = 0;
    }

    // hide PS2.0 font change behind a feature flag
    const showPS2Styles = analytics.inFlag(currentUser, 'show_ps2_styles');
    const containerClass = showPS2Styles ? 'v2 nav-content-wrapper ps2' : 'v2 nav-content-wrapper';

    return (
      <div className={containerClass}>
        <div className={subContainerClass}>
          {!isCollapsed && hasSideNav && sideNavComponent}
          <main className={`${className}-main`}>
            <HeaderContainer
              currentPathId={currentPathId}
              currentSectionId={currentSectionId}
              currentUnitId={currentUnitId}
              currentComponentId={currentComponentId}
              hasSideNav={hasSideNav}
              headerText={headerText}
              hideAuthorToggle={hideAuthorToggle}
              scrolledPastHeader={scrolledPastHeader}
              className={`${className}-header-wrapper ${headerClass}`}
              SubHeader={headerSubHeader}
              showLogo={showLogo}
              Content={headerContent}
              school={school}
              hideProgress={headerHideProgress}
            />

            {showStaffSidebar && <AdminSidebarContainer />}
            <section id="main" className={containerContentClass}>
              {subHeader && (
                <div className={`${className}-sub-header-wrapper`}>
                  <div className={`${className}-sub-header`}>
                    <div className={`${className}-sub-header__content`}>{subHeader}</div>
                  </div>
                </div>
              )}
              <div className={wrapperClass}>
                <div className={`${className}-wrapper__container`}>
                  {isPrintMode() && printHeader}

                  {children}
                </div>
              </div>
            </section>
          </main>
        </div>
      </div>
    );
  }
}
ContentWrapper.propTypes = {
  children: PropTypes.any,
  location: PropTypes.object,
  isEditMode: PropTypes.bool,
  className: PropTypes.string,
  currentPathId: PropTypes.string,
  currentSectionId: PropTypes.string,
  currentUnitId: PropTypes.string,
  currentUser: PropTypes.object,
  currentComponentId: PropTypes.string,
  isCollapsed: PropTypes.bool.isRequired,
  sideNavComponent: PropTypes.node,
  hideStaffNav: PropTypes.bool,
  headerContent: PropTypes.func,
  headerText: PropTypes.any,
  hideAuthorToggle: PropTypes.bool,
  showFooter: PropTypes.bool,
  afterScrollClass: PropTypes.string,
  subHeader: PropTypes.node,
  headerSubHeader: PropTypes.func,
  showLogo: PropTypes.bool,
  headerClass: PropTypes.string,
  printHeader: PropTypes.any,
};

ContentWrapper.defaultProps = {
  hideStaffNav: false,
  hideAuthorToggle: false,
  showFooter: true,
};
