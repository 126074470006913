import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import intl from '../../utils/intl';
import Icons from '../Utils/Icons';
import TooltipButton from '../Utils/Tooltip/TooltipButton';

export default class ExtraOptions extends Component {
  constructor(props) {
    super(props);

    this.state = { isOpen: false };
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleOutsideClick, false);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleOutsideClick, false);
  }

  handleOutsideClick = e => {
    if (this.node && this.node.contains(e.target)) {
      return;
    }

    if (this.state.isOpen) {
      this.toggle();
    }
  };

  onEdit = () => {
    this.setState({ isOpen: false });
    this.props.onEdit();
  };

  onDestroy = () => {
    this.setState({ isOpen: false });
    this.props.onDestroy();
  };

  toggle = () => {
    this.setState(state => ({ isOpen: !state.isOpen }));
  };

  handleClick = (e, callback) => {
    this.toggle();
    callback(e);
  };

  render() {
    const { isOpen } = this.state;
    if (!this.props.onEdit && !this.props.onDestroy) {
      return null;
    }

    return (
      <>
        <button
          className="course-card__button"
          aria-label={intl.formatMessage({ id: 'common.options' })}
          onClick={this.toggle}>
          <span className="icon-element icon-element--fill-grey-dark icon-element--background-transparent  icon-element--size-auto">
            <Icons.More />
          </span>
        </button>
        {isOpen && (
          <div ref={node => (this.node = node)} className="comment-action-wrapper">
            <div className="comment-options-wrapper">
              <div className="comment-options-inner">
                <span className="comment-options-title">
                  <FormattedMessage id="comment.options" />
                </span>

                <div className="comment-options">
                  {this.props.onEdit && (
                    <TooltipButton
                      icon={<Icons.Pencil />}
                      messageId="common.edit"
                      onClick={e => this.handleClick(e, this.onEdit)}
                      className="tooltip-button__button--split-top"
                    />
                  )}
                  {this.props.onDestroy && (
                    <TooltipButton
                      icon={<Icons.Trash />}
                      messageId="common.delete"
                      onClick={e => this.handleClick(e, this.onDestroy)}
                      className="tooltip-button__button--split-top"
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="comment-options-overlay" />
          </div>
        )}
      </>
    );
  }
}
ExtraOptions.propTypes = {
  onEdit: PropTypes.func,
  onDestroy: PropTypes.func,
};
